import React, { FC, ReactNode } from 'react';
import LabelBox from './LabelBox';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import FieldSetHint from '../atoms/FieldSetHint';
import styled from 'styled-components/macro';
import FieldSet from '../atoms/FieldSet';
import { FlexProps } from '@rebass/grid';

const WizardContent: FC<React.PropsWithChildren<{ label?: ReactNode; hint?: string } & Omit<FlexProps, 'label'>>> = ({
    label,
    hint,
    children,
    ...props
}) => (
    <StyledFieldSet {...props}>
        <StyledLabelBox style={{ justifyContent: 'flex-start' }}>
            <Label>{label}</Label>
            {hint && <FieldSetHint>{hint}</FieldSetHint>}
        </StyledLabelBox>
        {children}
    </StyledFieldSet>
);

export default WizardContent;

const StyledFieldSet = styled(FieldSet)`
    margin: 0 0 1.6rem 0;

    @media (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        margin: 0 0 3.2rem 0;
    }
`;

const StyledLabelBox = styled(LabelBox)`
    margin: 0;
    ${FieldSetHint} {
        margin-bottom: 0.8rem;
    }
`;
