import styled from 'styled-components';
import React, { FC, ReactNode } from 'react';
import { Theme } from '../../constants/theme';
import Info from '../figure/Info';
import { UnreachableCaseError } from '../../entities/UnreachableCaseError';
import { Done } from '../figure/Done';
import { Warning } from '../figure/Warning';
import { Declined } from '../figure/Declined';
import Title from '../primitive/Title';
import { StyledButton } from '../action/Button';

export type ToastVariant = 'info' | 'success' | 'warn' | 'error';
export type ToastSize = 'medium' | 'small';

export interface ToastProps {
    title: ReactNode;
    actions?: ReactNode;

    size?: ToastSize;
    variant?: ToastVariant;
}

const getColor = (variant: ToastVariant, theme: Theme) => {
    switch (variant) {
        case 'info':
            return 'transparent';
        case 'success':
            return theme.colors.positive['40'];
        case 'error':
            return theme.colors.negative['60'];
        case 'warn':
            return theme.colors.warning['30'];
        default:
            throw new UnreachableCaseError(variant);
    }
};

const getIcon = (variant: ToastVariant): ReactNode => {
    switch (variant) {
        case 'info':
            return <Info />;
        case 'success':
            return <Done />;
        case 'warn':
            return <Warning />;
        case 'error':
            return <Declined />;
        default:
            throw new UnreachableCaseError(variant);
    }
};

const Toast: FC<React.PropsWithChildren<ToastProps>> = ({
    title,
    children,
    actions,
    variant = 'info',
    size = 'medium',
}) => {
    const stylingProps = { variant, size };
    return (
        <Container {...stylingProps}>
            <Content {...stylingProps}>
                <Title variant="tiny">
                    {getIcon(variant)}
                    {title}
                </Title>
                {children}
            </Content>
            {actions && <Buttons {...stylingProps}>{actions}</Buttons>}
        </Container>
    );
};

type StylingProps = Required<Pick<ToastProps, 'variant' | 'size'>>;

const Content = styled.div<StylingProps>`
    flex: 1;
    padding: ${({ size }) => (size === 'medium' ? '1.6rem 2.8rem' : '1.2rem 2rem')};

    svg {
        margin-right: 0.6rem;
        position: relative;
        top: 0.3rem;
    }

    h4 {
        margin-bottom: 0.4rem;
    }
`;

const Buttons = styled.div<StylingProps>`
    padding: ${({ size }) => (size === 'medium' ? '1.2rem 2.8rem 1.2rem 0rem' : '0 2rem 1.2rem 2rem')};
`;

const Container = styled.div<StylingProps>`
    max-width: ${({ size }) => (size === 'medium' ? '51.1rem' : '38.8rem')};
    width: 100%;
    background: ${({ theme }) => theme.colors.neutral['0']};
    display: flex;
    flex-direction: ${({ size }) => (size === 'medium' ? 'row' : 'column')};
    align-items: ${({ size }) => (size === 'medium' ? 'center' : 'unset')};
    border: 1px solid ${({ theme }) => theme.colors.neutral['30']};
    border-radius: 2px;

    :after {
        width: 0.4rem;
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
        background: ${({ theme, variant }) => getColor(variant, theme)};
    }

    ${StyledButton} {
        min-width: 9.5rem;
    }
`;

export default Toast;
