import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';

export const useLogout = () => {
    const [error, setError] = useState<Error | null>(null);
    const history = useHistory();
    const client = useApolloClient();

    if (error) {
        throw error;
    }

    return async (redirect = false) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/logout/`, {
                credentials: 'include',
                headers: {
                    Accept: 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error(`Logout failed (HTTP error ${response.status})`);
            }
            await client.clearStore();
            if (redirect) {
                history.push('/login');
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (ex: any) {
            setError(ex);
        }
    };
};
