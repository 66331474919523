import styled, { css } from 'styled-components';
import { UnreachableCaseError } from '../../entities/UnreachableCaseError';

export type LabelVariant = 'large' | 'regular' | 'small';

const getVariantCss = (variant: LabelVariant) => {
    switch (variant) {
        case 'small':
            return css`
                font-size: 12px;
            `;
        case 'regular':
            return css`
                font-size: 14px;
            `;
        case 'large':
            return css`
                font-size: 17px;
            `;
        default:
            throw new UnreachableCaseError(variant);
    }
};

export const Label = styled.label<{ variant?: LabelVariant }>`
    font-weight: 500;
    letter-spacing: 0.15px;
    line-height: 16px;
    margin: 0.4rem 0;

    ${({ variant = 'regular' }) => getVariantCss(variant)};
`;
