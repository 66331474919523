import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Cross from '@oberoninternal/travelbase-ds/components/figure/Cross';
import { Edit } from '@oberoninternal/travelbase-ds/components/figure/Edit';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import useWeekdays from '@oberoninternal/travelbase-ds/hooks/useWeekdays';
import { Box, Flex } from '@rebass/grid';
import format from 'date-fns/format';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getDateOpts } from '../../constants/dateOpts';
import { OperatingHoursFragment } from '../../generated/graphql';
import { weekdays } from '../../utils/createWeekdayRecord';
import getPeriodText from '../../utils/getPeriodText';
import TableActions from '../atoms/TableActions';
import TableRowSeperator from '../atoms/TableRowSeperator';
import { getHeaderTexts } from '../pages/company/hours/SpecialHours';

interface Props {
    specialHours: OperatingHoursFragment;
    onRemove: () => void;
    onEdit: () => void;
}

const SpecialHoursRow: FC<React.PropsWithChildren<Props>> = ({
    specialHours: { startDate, endDate, ...days },
    onEdit,
    onRemove,
}) => {
    const intl = useIntl();
    const { locale } = intl;
    const dateOpts = getDateOpts(locale as 'nl' | 'de' | 'en');
    const weekdaysForDisplay = useWeekdays();
    const headerTexts = getHeaderTexts(intl);
    return (
        <>
            <tr>
                <td data-label={headerTexts.period}>{getPeriodText(startDate, endDate, intl)}</td>

                <td data-label={headerTexts.operationHours}>
                    {weekdays.map((weekday, i) => {
                        const dayOperatingHours = days[weekday];
                        if (!dayOperatingHours) {
                            return null;
                        }
                        return (
                            <Flex mb={[3, null, 4]} key={weekday}>
                                <Box width="20rem">
                                    <Body style={{ textTransform: 'capitalize' }}>
                                        {format(weekdaysForDisplay[i], 'EEEE', dateOpts)}
                                    </Body>
                                </Box>
                                <Box>
                                    {dayOperatingHours.map(({ timeClosed, timeOpen }, timeOpenClosedIndex) => (
                                        <Body key={timeOpenClosedIndex}>
                                            {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                                            {timeOpen} - {timeClosed}
                                        </Body>
                                    ))}
                                    {!dayOperatingHours.length && (
                                        <Body>
                                            <FormattedMessage defaultMessage="Gesloten" />
                                        </Body>
                                    )}
                                </Box>
                            </Flex>
                        );
                    })}
                </td>

                <td data-label={headerTexts.actions}>
                    <TableActions>
                        <TextButton onClick={onEdit} size="tiny">
                            <Edit />
                            <span>
                                <FormattedMessage defaultMessage="Aanpassen" />
                            </span>
                        </TextButton>
                        <TextButton onClick={onRemove} variant="danger" size="tiny">
                            <Cross />
                            <span>
                                <FormattedMessage defaultMessage="Verwijderen" />
                            </span>
                        </TextButton>
                    </TableActions>
                </td>
            </tr>
            <tr>
                <td colSpan={4}>
                    <TableRowSeperator />
                </td>
            </tr>
        </>
    );
};

export default SpecialHoursRow;
