import Cell from './Cell';
import Skeleton from 'react-loading-skeleton';
import React, { FC, HTMLAttributes } from 'react';

const SkeletonCell: FC<React.PropsWithChildren<{ i?: number } & HTMLAttributes<HTMLDivElement>>> = ({
    i = 0,
    ...props
}) => (
    <Cell hasSeparator={i === 0} {...props}>
        <Skeleton width={70} height={24} />
    </Cell>
);

export default SkeletonCell;
