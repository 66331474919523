import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useRandom } from '../../hooks/useRandom';
import ContentWrapper from '../atoms/ContentWrapper';
import FieldSet from '../atoms/FieldSet';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import FieldHeading from '../molecules/FieldHeading';
import LabelBox from '../molecules/LabelBox';

const SkeletonField: FC<React.PropsWithChildren<unknown>> = () => {
    const labelWidth = useRandom(100, 240);
    const elementWidth = useRandom(200, 400);
    const renderHeading = useRandom(1, 4) === 1;

    return (
        <>
            {renderHeading && (
                <FieldHeading title={<Skeleton count={1} width={160} />}>
                    <Skeleton count={3} />
                </FieldHeading>
            )}

            <FieldSet>
                <LabelBox>
                    <Skeleton width={labelWidth} />
                </LabelBox>

                <Skeleton height={48} width={elementWidth} />
            </FieldSet>
        </>
    );
};

type Variant = 'info' | 'default';
// ☠️
interface Props {
    variant?: Variant;
    amountOfFields?: number;
}
const Loading = ({ variant = 'info', amountOfFields = 8 }: Props) => {
    const fakeContent = (
        <>
            <Title>
                <Skeleton />
            </Title>
            <p>
                <Skeleton count={2} />
            </p>

            {new Array(amountOfFields).fill(null).map((_, i) => (
                <SkeletonField key={i} />
            ))}
        </>
    );
    return <ContentWrapper variant={variant}>{fakeContent}</ContentWrapper>;
};

export default Loading;
