import { match as Match, matchPath } from 'react-router-dom';
import { Route } from '../entities/Route';
import { routes as allRoutes } from './routes';
import { join } from 'path';

export const matchRoute = (pathname: string, routes: Route[] = allRoutes, parent?: Match): Route | null => {
    for (const route of routes) {
        let path = route.path ?? route.source;

        if (parent) {
            path = join(parent.path, path);
        }

        const match = matchPath(pathname, { path, exact: route.exact });

        if (match) {
            if (route.subroutes) {
                const matchedRoute = matchRoute(pathname, route.subroutes, match);

                if (matchedRoute) {
                    return matchedRoute;
                }
            }

            return route;
        }
    }

    return null;
};
