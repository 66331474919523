import React, { FC, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

interface Props extends HTMLAttributes<HTMLDivElement> {
    active?: boolean;
}
const PeriodRule: FC<React.PropsWithChildren<Props>> = ({ children, ...props }) => (
    <Container {...props}>
        <Children>{children}</Children>
    </Container>
);

const activeCss = css`
    background: ${({ theme }) => theme.colors.primary['40']};
    color: ${({ theme }) => theme.colors.neutral['0']};
    --border-color: transparent;
`;

const Container = styled.div<Props>`
    background: ${({ theme }) => theme.colors.primary['10']};
    border-radius: 3px;
    --border-color: transparent;
    box-shadow: 0 0 0 1px var(--border-color), inset 0 0 0 1px var(--border-color);
    z-index: 1;
    line-height: 0;

    :hover {
        --border-color: ${({ theme }) => theme.colors.primary['40']};
    }

    :active {
        ${activeCss}
    }

    ${({ active }) => active && activeCss}
`;

const Children = styled.div`
    position: sticky;
    left: 0;
    padding: 0.6rem 1.6rem;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    line-height: 2.4rem;
`;

export default PeriodRule;
