import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Plus from '@oberoninternal/travelbase-ds/components/figure/Plus';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import useMenuState from '@oberoninternal/travelbase-ds/hooks/useMenuState';
import { Box, Flex } from '@rebass/grid';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ActivityParams } from '../../../entities/ActivityParams';
import { NotFoundError } from '../../../entities/NotFoundError';
import {
    CompanyRateGroupFragment,
    UpsertCompanyRateGroupInput,
    useCompanyRatesQuery,
    useDeleteCompanyRateGroupMutation,
    useUpsertCompanyRateGroupMutation,
} from '../../../generated/graphql';
import getOptimisticUIRateGroup from '../../../utils/getOptimisticUIRateGroup';
import ContentWrapper from '../../atoms/ContentWrapper';
import Table from '../../atoms/Table';
import TableHeader, { StyledTableHeader } from '../../atoms/TableHeader';
import CompanyRateGroupRow from '../../molecules/CompanyRateGroupRow';
import CompanyRatesSidebar from '../../molecules/CompanyRatesSidebar';
import Loading from '../../organisms/Loading';

export const query = gql`
    query CompanyRates($companySlug: String!) {
        company(slug: $companySlug) {
            ...CompanyRates
        }
    }

    mutation UpsertCompanyRateGroup($input: UpsertCompanyRateGroupInput!) {
        upsertCompanyRateGroup(input: $input) {
            company {
                ...CompanyRates
            }
        }
    }
    mutation DeleteCompanyRateGroup($input: DeleteCompanyRateGroupInput!) {
        deleteCompanyRateGroup(input: $input) {
            company {
                ...CompanyRates
            }
        }
    }

    fragment CompanyRates on Company {
        id
        companyRateGroups {
            ...CompanyRateGroup
        }
    }

    fragment CompanyRateGroup on CompanyRateGroup {
        id
        startDate
        endDate
        canBuyTickets
        rates {
            ...Rate
        }
    }

    fragment Rate on Rate {
        id
        name(locale: "nl")
        deName: name(locale: "de")
        enName: name(locale: "en")
        price
        position
    }
`;

export const getHeaderTexts = (intl: IntlShape) => ({
    period: intl.formatMessage({ defaultMessage: 'Periode (tot en met)' }),
    sellsTickets: intl.formatMessage({ defaultMessage: 'Ticketverkoop' }),
    label: intl.formatMessage({ defaultMessage: 'Label' }),
    price: intl.formatMessage({ defaultMessage: 'Tarief' }),
    actions: intl.formatMessage({ defaultMessage: 'Acties' }),
});

const Rates: FC<React.PropsWithChildren<unknown>> = () => {
    const { companySlug } = useParams<ActivityParams>();
    const { setOpen, menuProps } = useMenuState(false, { closeClickOnOutside: false });
    const { data, loading } = useCompanyRatesQuery({ variables: { companySlug: companySlug! } });
    const [upsert] = useUpsertCompanyRateGroupMutation();
    const [remove] = useDeleteCompanyRateGroupMutation();
    const [selectedRateGroup, setSelectedRateGroup] = useState<CompanyRateGroupFragment | undefined>(undefined);
    const intl = useIntl();
    const { formatMessage } = intl;
    const headerTexts = getHeaderTexts(intl);

    if (loading) {
        return <Loading variant="default" />;
    }

    if (!data || !data.company) {
        throw new NotFoundError();
    }
    const {
        company: { companyRateGroups, id: companyId },
    } = data;

    const upsertHandler = async (values: Omit<UpsertCompanyRateGroupInput, 'companyId'>) => {
        let newCompanyRateGroups: CompanyRateGroupFragment[] = [];
        // if we're editing a rategroup we want to replace the rategroup with a optimistic one until we get a result
        if (values.rateGroupId) {
            newCompanyRateGroups = companyRateGroups.map((rateGroup): CompanyRateGroupFragment => {
                if (rateGroup.id === values.rateGroupId) {
                    return getOptimisticUIRateGroup(values, 'CompanyRateGroup');
                }
                return rateGroup;
            });
        } else {
            newCompanyRateGroups = [...companyRateGroups, getOptimisticUIRateGroup(values, 'CompanyRateGroup')];
        }

        setOpen(false);

        await upsert({
            variables: {
                input: {
                    companyId,
                    ...values,
                },
            },
            optimisticResponse: {
                __typename: 'PartnerMutation',
                upsertCompanyRateGroup: {
                    __typename: 'UpsertCompanyRateGroupPayload',
                    company: {
                        __typename: 'Company',
                        id: companyId,
                        companyRateGroups: newCompanyRateGroups,
                    },
                },
            },
        });
    };

    const removeHandler = async (rateGroupId: string) => {
        await remove({
            variables: { input: { companyId, rateGroupId } },
            optimisticResponse: {
                __typename: 'PartnerMutation',
                deleteCompanyRateGroup: {
                    __typename: 'DeleteCompanyRateGroupPayload',
                    company: {
                        __typename: 'Company',
                        id: companyId,
                        companyRateGroups: companyRateGroups.filter(group => group.id !== rateGroupId),
                    },
                },
            },
        });
    };

    const editHandler = (rateGroup: CompanyRateGroupFragment) => {
        setSelectedRateGroup(rateGroup);
        setOpen(true);
    };
    return (
        <>
            <ContentWrapper>
                <Pagehead title={formatMessage({ defaultMessage: 'Tarieven' })}>
                    <FormattedMessage defaultMessage="Dit zijn de tarieven voor de onderneming" />
                </Pagehead>
                <Box style={{ overflow: 'auto' }}>
                    <StyledTable>
                        <thead>
                            <tr>
                                <TableHeader variant="small">{headerTexts.period}</TableHeader>
                                <TableHeader variant="small">{headerTexts.sellsTickets}</TableHeader>

                                {/* a little table hack because the data of the day and hours column belong together, so we basically just show them as 1 column */}
                                <StyledTableHeader>
                                    <Flex>
                                        <Box width="20rem">
                                            <Label variant="small" as="span">
                                                {headerTexts.label}
                                            </Label>
                                        </Box>
                                        <Label variant="small" as="span">
                                            {headerTexts.price}
                                        </Label>
                                    </Flex>
                                </StyledTableHeader>
                                <TableHeader variant="small">{headerTexts.actions}</TableHeader>
                            </tr>
                        </thead>
                        <tbody>
                            {companyRateGroups.map(rateGroup => (
                                <CompanyRateGroupRow
                                    key={rateGroup.id}
                                    rateGroup={rateGroup}
                                    handleRemove={removeHandler}
                                    handleEdit={editHandler}
                                />
                            ))}
                            <tr>
                                <td colSpan={5}>
                                    <TextButton
                                        onClick={() => {
                                            // make sure the sidebar is cleaned up when we're adding a new period
                                            setSelectedRateGroup(undefined);
                                            setOpen(true);
                                        }}
                                    >
                                        <Plus />
                                        &nbsp;
                                        <span>
                                            <FormattedMessage defaultMessage="Periode toevoegen" />
                                        </span>
                                    </TextButton>
                                </td>
                            </tr>
                        </tbody>
                    </StyledTable>
                </Box>
            </ContentWrapper>
            <CompanyRatesSidebar
                rateGroups={companyRateGroups}
                handleUpsert={upsertHandler}
                selectedRateGroup={selectedRateGroup}
                {...menuProps}
            />
        </>
    );
};

export default Rates;

const StyledTable = styled(Table)`
    @media (max-width: ${({ theme }) => theme.mediaQueriesValues.s - 1}px) {
        th {
            display: none;
        }
    }
    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        min-width: 80rem;
    }
`;
