import React, { ComponentType, FC, HTMLAttributes, ReactNode, Ref, useCallback } from 'react';
import { Manager, Popper, PopperProps, Reference, ReferenceProps } from 'react-popper';
import styled, { css } from 'styled-components';
import useSesame from '../../hooks/useSesame';
import callAll from '../../utils/callAll';

export interface TooltipProps extends Omit<PopperProps, 'children'>, HTMLAttributes<HTMLDivElement> {
    ArrowComponent?: ComponentType<React.PropsWithChildren<unknown>>;
    label?: ReactNode;
    shouldWrapChildren?: boolean;
    defaultOpen?: boolean;
}

type ChildProps = HTMLAttributes<HTMLElement> & { ref: Ref<HTMLElement> };

const Tooltip: FC<React.PropsWithChildren<TooltipProps>> = ({
    children,
    ArrowComponent,
    eventsEnabled,
    innerRef,
    modifiers,
    placement,
    positionFixed,
    referenceElement,
    shouldWrapChildren,
    label,
    defaultOpen = false,
    ...props
}) => {
    const { onOpen, onClose, open } = useSesame(defaultOpen);

    const shouldWrap = typeof children === 'string' || shouldWrapChildren;
    const renderTriggerElement: ReferenceProps['children'] = useCallback(
        referenceChildProps => {
            const getTriggerProps = (childProps?: ChildProps) => ({
                onMouseEnter: callAll(childProps?.onMouseEnter, onOpen),
                onMouseLeave: callAll(childProps?.onMouseLeave, onClose),
                onFocus: callAll(childProps?.onFocus, onOpen),
                onBlur: callAll(childProps?.onBlur, onClose),
                ref: referenceChildProps.ref,
            });

            if (shouldWrap) {
                return (
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                    <span tabIndex={0} role="tooltip" {...getTriggerProps()}>
                        {children}
                    </span>
                );
            }
            /**
             * Ensure tooltip has only one child node
             */
            const child = React.Children.only(children) as React.ReactElement<ChildProps>;
            const childProps = child.props;
            return React.cloneElement(child, getTriggerProps(childProps));
        },
        [children, onClose, onOpen, shouldWrap]
    );

    return (
        <Manager>
            <Reference>{renderTriggerElement}</Reference>
            <Popper
                {...{
                    eventsEnabled,
                    innerRef,
                    modifiers: {
                        offset: {
                            offset: '0, 10',
                            ...modifiers?.offset,
                        },
                        ...modifiers,
                    },
                    placement,
                    positionFixed,
                    referenceElement,
                }}
            >
                {({ ref, style, placement: calculatedPlacement, arrowProps }) => (
                    <TooltipContainer {...props} style={{ ...props.style, ...style }} ref={ref} open={open}>
                        {ArrowComponent ? (
                            <ArrowComponent {...arrowProps} data-placement={calculatedPlacement} />
                        ) : (
                            <Arrow {...arrowProps} data-placement={calculatedPlacement} />
                        )}
                        {label}
                    </TooltipContainer>
                )}
            </Popper>
        </Manager>
    );
};

export default Tooltip;

export const Arrow = styled.div`
    position: absolute;
    width: 3em;
    height: 3em;
    display: flex;
    pointer-events: none;
    --backgroundColor: ${({ theme }) => theme.colors.neutral['100']};
    &[data-placement*='bottom'] {
        top: 0;
        left: 0;
        margin-top: -2.8rem;
        &::before {
            border-width: 0 0.8rem 1em 0.8rem;
            border-color: transparent transparent var(--backgroundColor) transparent;
        }
    }
    &[data-placement*='top'] {
        bottom: 0;
        left: 0;
        margin-bottom: -2.9em;
        &::before {
            border-width: 1em 0.8rem 0 0.8rem;
            border-color: var(--backgroundColor) transparent transparent transparent;
        }
    }
    &[data-placement*='right'] {
        left: 0;
        margin-left: -3rem;
        &::before {
            border-width: 0.8rem 1em 0.8rem 0;
            border-color: transparent var(--backgroundColor) transparent transparent;
        }
    }
    &[data-placement*='left'] {
        right: 0;
        margin-right: -2.8rem;
        &::before {
            border-width: 0.8rem 0 0.8rem 1em;
            border-color: transparent transparent transparent var(--backgroundColor);
        }
    }
    &::before {
        content: '';
        margin: auto;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
    }
`;

export const TooltipContainer = styled.div<{ open: boolean }>`
    opacity: 0.8;
    color: white;
    width: 30rem;
    padding: 1.6rem;
    background: ${({ theme }) => theme.colors.neutral['100']};
    border-radius: 0.8rem;
    z-index: ${({ theme }) => theme.zIndices.tooltip};
    ${({ open }) =>
        open
            ? css`
                  visibility: visible;
                  pointer-events: unset;
              `
            : css`
                  visibility: hidden;
                  pointer-events: none;
              `};
`;
