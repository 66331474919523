import { OperationVariables, useApolloClient } from '@apollo/client';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import { Box } from '@rebass/grid';
import endOfDay from 'date-fns/endOfDay';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import startOfDay from 'date-fns/startOfDay';
import startOfToday from 'date-fns/startOfToday';
import { debounce } from 'debounce';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import range from 'lodash/range';
import React, { FC, useContext, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import simplur from 'simplur';
import styled, { ThemeContext } from 'styled-components/macro';
import * as Yup from 'yup';
import { getDateOpts } from '../../constants/dateOpts';
import euroFormat from '../../constants/euroFormat';
import unitTypes from '../../constants/unitTypes';
import { useSidebar } from '../../context/sidebar';
import { UnitParams } from '../../entities/UnitParams';
import {
    AvailabilityDocument,
    AvailabilityQuery,
    PriceColumnAllotmentFragment,
    PriceColumnAllotmentFragmentDoc,
    useAllotmentSidebarQuery,
    useEditAllotmentMutation,
} from '../../generated/graphql';
import { EditAllotmentInput } from '../../generated/resetGraphql';
import { formatDuration } from '../../utils/formatDuration';
import { getBookingsStatus } from '../../utils/getBookingsStatus';
import Heading from '../atoms/Heading';
import Link from '../atoms/Link';
import { SidebarSeperator } from '../atoms/Seperator';
import SidebarField from '../atoms/SidebarField';
import SidebarIntro from '../atoms/SidebarIntro';
import Table from '../atoms/Table';
import FormScreen from '../organisms/FormScreen';

export interface AllotmentSidebarData {
    allotment?: PriceColumnAllotmentFragment;
    date: string;
    type: 'ALLOTMENT_SIDEBAR';
    maxAllotment: number;
    variables?: OperationVariables;
    document: DocumentNode;
}

export interface Props {
    data: AllotmentSidebarData;
}

export const query = gql`
    query AllotmentSidebar($unitSlug: String!, $start: Date!, $end: Date!) {
        rentalUnit(slug: $unitSlug) {
            id
            type
            bookings(startDate: $start, endDate: $end) {
                id
                customer {
                    id
                    firstName
                    lastName
                }
                amountAdults
                amountBabies
                amountChildren
                duration
                arrivalDate
                departureDate
                totalPrice
                status
            }
        }
    }

    mutation editAllotment($input: EditAllotmentInput!) {
        editAllotment(input: $input) {
            allotment {
                ...PriceColumnAllotment
            }
        }
    }
`;

const AllotmentSidebar: FC<React.PropsWithChildren<Props>> = ({ data: sidebarData }) => {
    const { locale } = useIntl();
    const dateOpts = getDateOpts(locale as 'nl' | 'de' | 'en');

    const { allotment, maxAllotment, variables, document, ...rest } = sidebarData;
    const theme = useContext(ThemeContext);
    const { formatMessage } = useIntl();
    const { unitSlug, partnerId } = useParams<UnitParams>();
    const client = useApolloClient();
    const { amount, date } = allotment
        ? client.readFragment<PriceColumnAllotmentFragment>({
              fragment: PriceColumnAllotmentFragmentDoc,
              id: `Allotment:${allotment.id}`,
          })!
        : { amount: '', date: rest.date };

    const isInPast = isBefore(new Date(date), startOfToday());

    const { data, loading } = useAllotmentSidebarQuery({
        variables: {
            unitSlug,
            start: startOfDay(new Date(date)),
            end: endOfDay(new Date(date)),
        },
    });

    const rentalUnit = data?.rentalUnit;

    const bookings = data?.rentalUnit?.bookings ?? [];

    const [edit] = useEditAllotmentMutation();

    const unitType = unitTypes.find(({ value }) => value === data?.rentalUnit?.type);
    const formattedDate = format(new Date(date), 'd MMMM', dateOpts);
    const [state, dispatch] = useSidebar();

    const availabilityRef = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        if (state.open) {
            availabilityRef.current?.focus();
        }
    }, [state.open]);

    return (
        <FormScreen
            variant="sidebar"
            bottomChildren={null}
            validationSchema={Yup.object().shape({
                amount: Yup.number()
                    .integer(formatMessage({ defaultMessage: 'Vul een rond getal in' }))
                    .typeError(formatMessage({ defaultMessage: 'Ongeldige waarde' }))
                    .max(
                        maxAllotment,
                        formatMessage(
                            { defaultMessage: `Beschikbaarheid kan niet boven het maximum ({maxAllotment}) liggen` },
                            { maxAllotment }
                        )
                    )
                    .min(0, formatMessage({ defaultMessage: 'Beschikbaarheid moet positief zijn' }))
                    .required(formatMessage({ defaultMessage: 'Beschikbaarheid is verplicht' })),
            })}
            initialValues={{
                amount: amount.toString(),
            }}
            handleSubmit={async values => {
                if (!rentalUnit?.id) {
                    return;
                }
                const input: EditAllotmentInput = {
                    rentalUnitId: rentalUnit?.id,
                    date,
                    amount: parseInt(values.amount, 10),
                };
                await edit({
                    variables: {
                        input,
                    },
                    optimisticResponse: allotment
                        ? {
                              editAllotment: {
                                  __typename: 'EditAllotmentPayload',
                                  allotment: {
                                      __typename: 'Allotment',
                                      amount: input.amount,
                                      id: allotment.id,
                                      date,
                                  },
                              },
                          }
                        : undefined,
                    update: !allotment
                        ? (cache, newData) => {
                              const oldData = cache.readQuery<AvailabilityQuery>({
                                  query: document,
                                  variables,
                              });
                              if (oldData?.rentalUnit && newData.data?.editAllotment.allotment) {
                                  const { allotment: newAllotment } = newData.data.editAllotment;
                                  cache.writeQuery<AvailabilityQuery>({
                                      query: AvailabilityDocument,
                                      variables,
                                      data: {
                                          ...oldData,
                                          rentalUnit: {
                                              ...oldData.rentalUnit,
                                              allotments: (oldData.rentalUnit?.allotments ?? []).concat(
                                                  newData.data.editAllotment.allotment
                                              ),
                                          },
                                      },
                                  });
                                  dispatch({
                                      type: 'set',
                                      data: { ...sidebarData, allotment: newAllotment },
                                  });
                              }
                          }
                        : undefined,
                });
            }}
            skipReset
            skipToast
        >
            {({ submitForm, handleChange, isValid }) => {
                if (!rentalUnit) {
                    return <Skeleton />;
                }

                const onChange = debounce(() => {
                    submitForm();
                }, 250);

                return (
                    <>
                        <SidebarIntro title={formatMessage({ defaultMessage: 'Selectie' })}>
                            {formattedDate}
                        </SidebarIntro>
                        <Body>
                            <TextButton
                                onClick={() => {
                                    dispatch({
                                        type: 'show',
                                        data: {
                                            type: 'ALLOTMENT_BULK_SIDEBAR',
                                            allotment,
                                            maxAllotment,
                                            rentalUnitId: rentalUnit.id,
                                            unitType,
                                            date,
                                        },
                                    });
                                }}
                            >
                                <FormattedMessage defaultMessage="Bulkaanpassing" />
                            </TextButton>
                        </Body>
                        <SidebarSeperator />

                        <Heading
                            title={
                                isInPast
                                    ? formatMessage({ defaultMessage: 'Bekijk de beschikbaarheid' })
                                    : formatMessage({ defaultMessage: 'Pas je beschikbaarheid aan' })
                            }
                        >
                            <FormattedMessage
                                defaultMessage="Hoeveel {unitType} {isInPast, select, true {waren} other {zijn}} er op deze dag beschikbaar?"
                                values={{ unitType: unitType?.plural.toLowerCase(), isInPast }}
                            />
                        </Heading>
                        <SidebarField label={formatMessage({ defaultMessage: 'Beschikbaarheid' })} id="availability">
                            <TextInputField
                                autoFocus
                                variant="small"
                                name="amount"
                                id="availability"
                                disabled={isInPast}
                                ref={availabilityRef}
                                onKeyDown={e => {
                                    if ((e.key === 'Escape' || e.key === 'Enter') && isValid) {
                                        dispatch({
                                            type: 'close',
                                        });
                                    }
                                }}
                                onChange={e => {
                                    handleChange(e);
                                    onChange();
                                }}
                            />
                        </SidebarField>
                        {!isInPast && (
                            <SidebarField>
                                <Body variant="small" style={{ color: theme.colors.neutral[50] }}>
                                    <FormattedMessage defaultMessage="Wijzigingen zijn meteen actief" />
                                </Body>
                            </SidebarField>
                        )}

                        <SidebarSeperator />

                        <Heading title={formatMessage({ defaultMessage: 'Travelbase boekingen' })}>
                            <FormattedMessage
                                defaultMessage="Boekingen die op {formattedDate} {isInPast, select, true {vielen} other {vallen}}."
                                values={{ formattedDate, isInPast }}
                            />
                        </Heading>

                        {loading && (
                            <Table>
                                <tbody>
                                    {range(0, 4).map((_, i) => (
                                        <SkeletonRow key={i} />
                                    ))}
                                </tbody>
                            </Table>
                        )}
                        {!loading && (
                            <>
                                {bookings.length === 0 && (
                                    <Box pt={2}>
                                        <Label>
                                            <FormattedMessage defaultMessage="Geen boekingen gevonden." />
                                        </Label>
                                    </Box>
                                )}
                                {bookings.length > 0 && (
                                    <Table>
                                        <tbody>
                                            {bookings.map(booking => {
                                                const {
                                                    id: key,
                                                    customer,
                                                    totalPrice,
                                                    amountBabies,
                                                    amountChildren,
                                                    amountAdults,
                                                    duration,
                                                    arrivalDate,
                                                    departureDate,
                                                } = booking;
                                                return (
                                                    <TableRow
                                                        key={key}
                                                        as={Link}
                                                        to={`/partner/${partnerId}/bookings/all/${key}`}
                                                    >
                                                        <td>
                                                            {customer && (
                                                                <Body variant="small">
                                                                    {customer.firstName ?? ''} {customer.lastName ?? ''}
                                                                </Body>
                                                            )}
                                                            <Value>
                                                                {simplur`${
                                                                    amountAdults + amountChildren + amountBabies
                                                                } gast[|en]`}
                                                            </Value>
                                                        </td>
                                                        <td>
                                                            <Body variant="small">
                                                                {formatDuration(
                                                                    new Date(arrivalDate),
                                                                    new Date(departureDate)
                                                                )}
                                                            </Body>
                                                            <Value>{simplur`${duration} nacht[|en]`}</Value>
                                                        </td>
                                                        <td>
                                                            <Body variant="small">{euroFormat(totalPrice)}</Body>
                                                            <Value>{getBookingsStatus(booking).text}</Value>
                                                        </td>
                                                    </TableRow>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                )}
                            </>
                        )}
                    </>
                );
            }}
        </FormScreen>
    );
};

const SkeletonRow = () => (
    <TableRow>
        {range(0, 3).map((_, i) => (
            <td key={i}>
                <Body key={i}>
                    <Skeleton width={90} count={2} />
                </Body>
            </td>
        ))}
    </TableRow>
);

const TableRow = styled.tr`
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[20]};
    display: table-row;

    :first-of-type td {
        padding-top: 0 !important;
    }
`;

const Value = styled(Body).attrs({ variant: 'tiny' })`
    color: ${({ theme }) => theme.colors.neutral[50]};
`;

export default AllotmentSidebar;
