import { CheckboxField } from '@oberoninternal/travelbase-ds/components/form/Checkbox';
import TextInput, { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import { Box, Flex } from '@rebass/grid';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import targetAudienceOptions from '../../../../constants/targetAudienceOptions';
import { ActivityParams } from '../../../../entities/ActivityParams';
import { NotFoundError } from '../../../../entities/NotFoundError';
import { useCompanyInfoQuery, useEditCompanyInfoMutation } from '../../../../generated/graphql';
import { toFormikValues, toInput } from '../../../../utils/targetAudiences';
import { toInitialContactInfo } from '../../../../utils/toInitialContactInfo';
import { contactReservationValidation } from '../../../../utils/validations/formValidation';
import { phoneSchema } from '../../../../utils/validations/phoneSchema';
import FieldSet from '../../../atoms/FieldSet';
import FieldSetHint from '../../../atoms/FieldSetHint';
import ArrayTextInput from '../../../molecules/ArrayTextInput';
import FieldHeading from '../../../molecules/FieldHeading';
import LabelBox from '../../../molecules/LabelBox';
import FormScreen from '../../../organisms/FormScreen';
import Loading from '../../../organisms/Loading';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import createPublicLinkProps from '../../../../utils/createPublicLinkProps';
import brandConfig from '../../../../constants/brandConfig';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

export const query = gql`
    query CompanyInfo($companySlug: String!) {
        company(slug: $companySlug) {
            ...CompanyDetails
        }
    }

    mutation EditCompanyInfo($input: EditCompanyInfoInput!) {
        editCompanyInfo(input: $input) {
            company {
                ...CompanyDetails
            }
        }
    }

    fragment CompanyDetails on Company {
        id
        name
        email
        phoneNumber
        websiteUrl
        categories {
            nameNL: name(locale: "nl")
        }
        targetAudiences
        socialsTwitterUrl
        socialsInstagramUrl
        socialsFacebookUrl
        reservations {
            contactName
            phoneNumbers
            emails
        }
    }
`;

export const companyDetailsValidation = (intl: IntlShape) =>
    Yup.object().shape({
        email: Yup.string()
            .email(intl.formatMessage({ defaultMessage: 'Voer een geldig e-mailadres in' }))
            .nullable(),
        phoneNumber: phoneSchema(intl).nullable(),
        websiteUrl: Yup.string().nullable(),
        reservations: contactReservationValidation(intl).nullable(),
    });

const CompanyDetails: FC<React.PropsWithChildren<ActivityParams>> = ({ companySlug }) => {
    const slug = companySlug ?? '';
    const { loading, data } = useCompanyInfoQuery({ variables: { companySlug: slug } });
    const [mutate] = useEditCompanyInfoMutation();
    const intl = useIntl();
    const { formatMessage } = intl;

    if (loading) {
        return <Loading />;
    }

    if (!data || !data.company) {
        throw new NotFoundError();
    }

    const {
        company: {
            id,
            name,
            email,
            phoneNumber,
            reservations,
            websiteUrl,
            categories,
            socialsTwitterUrl,
            socialsInstagramUrl,
            socialsFacebookUrl,
            targetAudiences,
        },
    } = data;

    const initialValues = {
        name: name ?? '',
        email: email ?? '',
        phoneNumber: phoneNumber ?? '',
        reservations: toInitialContactInfo(reservations),
        websiteUrl: websiteUrl ?? '',
        socialsFacebookUrl: socialsFacebookUrl ?? '',
        socialsInstagramUrl: socialsInstagramUrl ?? '',
        socialsTwitterUrl: socialsTwitterUrl ?? '',
        targetAudiences: toFormikValues(targetAudiences ?? [], intl),
    };

    return (
        <FormScreen
            validationSchema={companyDetailsValidation(intl)}
            initialValues={initialValues}
            handleSubmit={async ({
                name: omit,
                reservations: newReservations,
                targetAudiences: newTargetAudiences,
                ...values
            }) => {
                await mutate({
                    variables: {
                        input: {
                            ...values,
                            companyId: id,
                            targetAudiences: toInput(newTargetAudiences),
                            reservations: {
                                ...newReservations,
                                emails: newReservations?.emails?.filter(val => val !== ''),
                                phoneNumbers: newReservations?.phoneNumbers?.filter(val => val !== ''),
                            },
                        },
                    },
                });
            }}
        >
            {() => (
                <>
                    <Flex justifyContent="space-between" flexDirection={['column', 'row']}>
                        <Box>
                            <Pagehead title={formatMessage({ defaultMessage: 'Informatie' })}>
                                <FormattedMessage defaultMessage="Hier beheer je de informatie van de onderneming." />
                            </Pagehead>
                        </Box>
                        <Box>
                            <TextButton
                                style={{ display: 'flex-inline' }}
                                as="a"
                                {...createPublicLinkProps(
                                    `${brandConfig.customSlugs?.company ?? 'onderneming'}/${companySlug}`,
                                    intl
                                )}
                            />
                        </Box>
                    </Flex>

                    <FieldHeading title={formatMessage({ defaultMessage: 'Algemeen' })}>
                        <FormattedMessage defaultMessage="Onder deze gegevens is de onderneming bekend bij ons." />
                    </FieldHeading>
                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="name">
                                <FormattedMessage defaultMessage="Naam" />
                            </Label>
                            <FieldSetHint>
                                <FormattedMessage defaultMessage="Neem contact met ons op als deze gegevens niet kloppen." />
                            </FieldSetHint>
                        </LabelBox>
                        <Box width={1}>
                            <TextInput disabled value={name} />
                        </Box>
                    </FieldSet>

                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="categories">
                                <FormattedMessage defaultMessage="Categorieën" />
                            </Label>
                            <FieldSetHint>
                                <FormattedMessage defaultMessage="Neem contact met ons op als deze gegevens niet kloppen." />
                            </FieldSetHint>
                        </LabelBox>
                        <Box width={1}>
                            {categories.length ? (
                                <StyledList>
                                    {categories.map((category, idx) => (
                                        <li key={`${category.__typename}-${idx}`}>{category.nameNL}</li>
                                    ))}
                                </StyledList>
                            ) : (
                                <div>
                                    <FormattedMessage defaultMessage="Geen categorieën" />
                                </div>
                            )}
                        </Box>
                    </FieldSet>

                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="targetAudiences">
                                <FormattedMessage defaultMessage="Doelgroep" />
                            </Label>
                        </LabelBox>
                        <Box width={1}>
                            {targetAudienceOptions(intl).map(item => (
                                <CheckboxContainer key={item.label}>
                                    <CheckboxField id={item.value} name={`targetAudiences.${item.value}`}>
                                        {item.label}
                                    </CheckboxField>
                                </CheckboxContainer>
                            ))}
                        </Box>
                    </FieldSet>

                    <FieldHeading title={formatMessage({ defaultMessage: 'Contactgegevens algemeen' })}>
                        <FormattedMessage defaultMessage="Hoe is de onderneming bereikbaar?" />
                    </FieldHeading>
                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="websiteUrl">
                                <FormattedMessage defaultMessage="Website" />
                            </Label>
                            <FieldSetHint>
                                <FormattedMessage defaultMessage="Vul volledig URL in, dus met https:// erbij." />
                            </FieldSetHint>
                        </LabelBox>
                        <Box width={1}>
                            <TextInputField id="websiteUrl" name="websiteUrl" />
                        </Box>
                    </FieldSet>
                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="email">
                                <FormattedMessage defaultMessage="E-mailadres" />
                            </Label>
                        </LabelBox>
                        <Box width={1}>
                            <TextInputField
                                id="email"
                                placeholder={formatMessage({ defaultMessage: 'E-mailadres' })}
                                name="email"
                            />
                        </Box>
                    </FieldSet>
                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="phoneNumber">
                                <FormattedMessage defaultMessage="Telefoonnummer" />
                            </Label>
                            <FieldSetHint>
                                <FormattedMessage defaultMessage="Gebruik internationale notatie (+31612345678)" />
                            </FieldSetHint>
                        </LabelBox>
                        <Box width={1}>
                            <TextInputField
                                id="phoneNumber"
                                placeholder={formatMessage({ defaultMessage: 'Telefoonnummer' })}
                                name="phoneNumber"
                            />
                        </Box>
                    </FieldSet>

                    <FieldHeading title={formatMessage({ defaultMessage: 'Social media' })}>
                        <FormattedMessage defaultMessage="Waar op social media is de onderneming te vinden?" />
                    </FieldHeading>
                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="socialsFacebookUrl">
                                <FormattedMessage defaultMessage="Facebook" />
                            </Label>
                            <FieldSetHint>
                                <FormattedMessage defaultMessage="Vul volledig URL in, dus met https:// erbij." />
                            </FieldSetHint>
                        </LabelBox>
                        <Box width={1}>
                            <TextInputField id="socialsFacebookUrl" name="socialsFacebookUrl" />
                        </Box>
                    </FieldSet>

                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="socialsInstagramUrl">
                                <FormattedMessage defaultMessage="Instagram" />
                            </Label>
                            <FieldSetHint>
                                <FormattedMessage defaultMessage="Vul volledig URL in, dus met https:// erbij." />
                            </FieldSetHint>
                        </LabelBox>
                        <Box width={1}>
                            <TextInputField id="socialsInstagramUrl" name="socialsInstagramUrl" />
                        </Box>
                    </FieldSet>

                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="socialsTwitterUrl">
                                <FormattedMessage defaultMessage="Twitter" />
                            </Label>
                            <FieldSetHint>
                                <FormattedMessage defaultMessage="Vul volledig URL in, dus met https:// erbij." />
                            </FieldSetHint>
                        </LabelBox>
                        <Box width={1}>
                            <TextInputField id="socialsTwitterUrl" name="socialsTwitterUrl" />
                        </Box>
                    </FieldSet>

                    <FieldHeading title={formatMessage({ defaultMessage: 'Contactpersoon reserveringen' })}>
                        <FormattedMessage defaultMessage="Wie is de persoon of afdeling die de reserveringen in behandeling neemt? Het is mogelijk meerdere e-mailadressen op te geven. Gegevens van contact reserveringen worden niet met gasten gedeeld." />
                    </FieldHeading>
                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="reservations.contactName">
                                <FormattedMessage defaultMessage="Naam contactpersoon" />
                            </Label>
                        </LabelBox>
                        <Box width={1}>
                            <TextInputField
                                id="reservations.contactName"
                                placeholder={formatMessage({ defaultMessage: 'Volledige naam' })}
                                name="reservations.contactName"
                            />
                        </Box>
                    </FieldSet>
                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="reservations.emails">
                                <FormattedMessage defaultMessage="E-mailadres contactpersoon of afdeling reserveringen" />
                            </Label>
                        </LabelBox>
                        <Box width={1}>
                            <ArrayTextInput
                                name="reservations.emails"
                                placeholder={formatMessage({ defaultMessage: 'E-mail' })}
                                addDescription={formatMessage({ defaultMessage: 'Extra e-mail veld' })}
                            />
                        </Box>
                    </FieldSet>
                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="reservationsPhoneNumber">
                                <FormattedMessage defaultMessage="Telefoonnummer contactpersoon of afdeling reserveringen" />
                            </Label>
                            <FieldSetHint>
                                <FormattedMessage defaultMessage="Gebruik de internationale notatie (+31612345678)." />
                            </FieldSetHint>
                        </LabelBox>
                        <Box width={1}>
                            <ArrayTextInput
                                name="reservations.phoneNumbers"
                                placeholder={formatMessage({ defaultMessage: 'Telefoonnummer' })}
                                addDescription={formatMessage({ defaultMessage: 'Extra telefoonnummer veld' })}
                            />
                        </Box>
                    </FieldSet>
                </>
            )}
        </FormScreen>
    );
};

export default CompanyDetails;

const StyledList = styled.ul`
    li {
        list-style: disc;
    }
`;

const CheckboxContainer = styled.div`
    &:not(:last-child) {
        margin-bottom: 2.5rem;
    }
`;
