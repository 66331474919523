import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ArrayParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import BigListWrapper from '../../atoms/BigListWrapper';
import { STEP } from '../Bookings';
import { PartnerParams } from '../../../entities/PartnerParams';
import gql from 'graphql-tag';
import { NetworkStatus } from '@apollo/client';
import { Box, Flex } from '@rebass/grid';
import isEqual from 'lodash/isEqual';
import { Formik, FormikProps } from 'formik';
import styled from 'styled-components';
import RangePickerInputField from '../../molecules/RangePickerInputField';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Cross from '@oberoninternal/travelbase-ds/components/figure/Cross';
import Download from '@oberoninternal/travelbase-ds/components/figure/Download';
import isValidDate from '../../../utils/isValidDate';
import debounce from 'lodash.debounce';
import { AllVisitorRegistrationsQuery, useAllVisitorRegistrationsQuery } from '../../../generated/graphql';
import VisitorRegistrationHistoryList from './VisitorRegisterHistoryList';
import { FormattedMessage, useIntl } from 'react-intl';

export const query = gql`
    query AllVisitorRegistrations($first: Int, $last: Int, $startDate: Date, $endDate: Date, $partnerId: ID!) {
        partner(id: $partnerId) {
            id
            allVisitorRegistrations(first: $first, last: $last, startDate: $startDate, endDate: $endDate) {
                pageInfo {
                    startCursor
                    endCursor
                    hasNextPage
                    hasPreviousPage
                }
                exportUrl
                totalCount
                edges {
                    node {
                        rentalUnit {
                            ...UnitDetails
                        }
                        arrivalDate
                        departureDate
                        firstName
                        lastName
                        city
                        id
                        __typename
                        identificationType
                    }
                }
            }
        }
    }
`;

const HistoryScreen: FC = () => {
    const [paramQuery, setQuery] = useQueryParams({
        startDate: StringParam,
        endDate: StringParam,
        rentalUnitIds: withDefault(ArrayParam, []),
    });
    const { partnerId } = useParams<PartnerParams>();

    const intl = useIntl();
    const { formatMessage } = intl;

    const { startDate, endDate, ...rest } = paramQuery;

    const validStartDate = paramQuery.startDate && isValidDate(paramQuery.startDate) ? paramQuery.startDate : null;
    const validEndDate = paramQuery.endDate && isValidDate(paramQuery.endDate) ? paramQuery.endDate : null;

    const { fetchMore, loading, data, networkStatus, refetch } = useAllVisitorRegistrationsQuery({
        variables: {
            first: STEP * 4,
            startDate: validStartDate,
            endDate: validEndDate,
            partnerId,
            ...rest,
        },
        notifyOnNetworkStatusChange: true,
    });

    const visitorRegistrations = data?.partner?.allVisitorRegistrations;

    const isNextPageLoading = networkStatus === NetworkStatus.fetchMore;
    const hasNextPage = !!data?.partner?.allVisitorRegistrations?.pageInfo.hasNextPage;
    const [isSearching, setIsSearching] = useState(false);
    const onLoadMore = useCallback(async () => {
        if (loading) {
            return;
        }

        await fetchMore({
            variables: { after: data?.partner?.allVisitorRegistrations?.pageInfo.endCursor },
            updateQuery: (prev: AllVisitorRegistrationsQuery, { fetchMoreResult }): AllVisitorRegistrationsQuery => {
                if (!fetchMoreResult || !prev.partner?.allVisitorRegistrations?.edges) {
                    return prev;
                }
                const newEdges = fetchMoreResult?.partner?.allVisitorRegistrations?.edges;
                const pageInfo = fetchMoreResult?.partner?.allVisitorRegistrations?.pageInfo;
                return {
                    ...prev,
                    partner: {
                        ...prev.partner,
                        allVisitorRegistrations: prev.partner?.allVisitorRegistrations
                            ? {
                                  ...prev.partner.allVisitorRegistrations,
                                  edges: [...prev.partner.allVisitorRegistrations.edges, ...(newEdges ?? [])],
                                  pageInfo: pageInfo ?? prev.partner.allVisitorRegistrations.pageInfo,
                              }
                            : null,
                    },
                };
            },
        });
    }, [loading, data, fetchMore]);
    // TODO: use useMemo instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const performSearch = useCallback(
        debounce(async (variables: HistoryScreenValues) => {
            await refetch({ partnerId, ...variables });
            setIsSearching(false);

            // reflect the search params in the url. Also set the value to undefined if null, cause that's what the library expects
            setQuery(
                Object.assign(
                    {},
                    ...Object.entries(variables).map(([key, value]) => ({
                        [key]: value === null ? undefined : value,
                    }))
                )
            );
        }, 600),
        [refetch]
    );

    return (
        <>
            <Formik
                initialValues={{
                    startDate: validStartDate,
                    endDate: validEndDate,
                    rentalUnitIds: paramQuery.rentalUnitIds as string[],
                }}
                onSubmit={async values => {
                    setIsSearching(true);
                    await performSearch(values);
                }}
            >
                {formikBag => (
                    <>
                        <TableActions
                            downloadUrl={data?.partner?.allVisitorRegistrations?.exportUrl}
                            formikBag={formikBag}
                        />
                        <BigListWrapper>
                            <VisitorRegistrationHistoryList
                                isSearching={isSearching}
                                isNextPageLoading={isNextPageLoading}
                                isLoading={loading}
                                hasNextPage={hasNextPage}
                                entries={visitorRegistrations}
                                onLoadMore={onLoadMore}
                                noResultsText={formatMessage({
                                    defaultMessage:
                                        'Geen resultaten gevonden. Je kan zoeken op verhuureenheid, gast of reserveringsnummer',
                                })}
                            />
                        </BigListWrapper>
                    </>
                )}
            </Formik>
        </>
    );
};

type HistoryScreenValues = {
    startDate: null | string;
    endDate: null | string;
    rentalUnitIds: string[];
};

const initialSearchValues = {
    startDate: null,
    endDate: null,
    rentalUnitIds: [],
};
const TableActions: FC<{ formikBag: FormikProps<HistoryScreenValues>; downloadUrl: string | null | undefined }> = ({
    formikBag: { values, initialValues, submitForm, resetForm },
    downloadUrl,
}) => {
    const ref = useRef(initialValues);

    useEffect(() => {
        // we only want to perform a search if the values have changed.
        if (!isEqual(ref.current, values)) {
            submitForm();
            ref.current = values;
        }
    }, [values, submitForm]);

    return (
        <TableActionsContainer>
            <Flex alignItems="center">
                <Box style={{ zIndex: 2 }} width={[1, '32.8rem']}>
                    <RangePickerInputField size="medium" optionalEndDate enabledPast />
                </Box>
                <Box marginLeft="2.4rem">
                    <TextButton
                        size="tiny"
                        disabled={isEqual(initialSearchValues, values)}
                        onClick={() => resetForm({ values: initialSearchValues })}
                    >
                        <Cross />
                        <span>
                            <FormattedMessage defaultMessage="Reset filters" />
                        </span>
                    </TextButton>
                </Box>
            </Flex>
            {downloadUrl && (
                <Box marginLeft="2.4rem">
                    <TextButton size="tiny" onClick={() => window.open(downloadUrl, '_blank')}>
                        <Download />
                        <span>
                            <FormattedMessage defaultMessage="Download" />
                        </span>
                    </TextButton>
                </Box>
            )}
        </TableActionsContainer>
    );
};

export const TableActionsContainer = styled(Flex)`
    margin-top: ${({ theme }) => theme.spacing['60_Large']};
    margin-bottom: ${({ theme }) => theme.spacing['40_Standard']};
    align-items: center;
    justify-content: space-between;
    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        flex-direction: column;
        align-items: flex-start;
    }

    > div + div {
        @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
            :not(:last-of-type) {
                margin-left: ${({ theme }) => theme.spacing['40_Standard']};
            }
        }
        @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
            margin-top: ${({ theme }) => theme.spacing['30_Small']};
        }
    }
`;

export default HistoryScreen;
