import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { getFontCss } from '../../constants/theme';

export type BodyVariant = 'huge' | 'large' | 'regular' | 'small' | 'tiny' | 'micro';

export interface BodyProps extends HTMLAttributes<HTMLParagraphElement> {
    variant?: BodyVariant;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    elementType?: keyof JSX.IntrinsicElements | React.ComponentType<React.PropsWithChildren<any>>;
}

export const BodyInner = styled.p<BodyProps>`
    font-weight: 400;
    ${({ variant = 'regular', theme }) => getFontCss(theme.fontSizes.body[variant])};
    white-space: pre-line;
    margin: 0;
`;

const Body: FC<React.PropsWithChildren<BodyProps>> = ({ variant = 'regular', children, elementType, ...rest }) => (
    <BodyInner as={elementType} variant={variant} {...rest}>
        {children}
    </BodyInner>
);

export default Body;
