import { AccommodationTypeEnum } from '../generated/graphql';

interface AccommodationType {
    label: string;
    plural: string;
    value: AccommodationTypeEnum;
}

const accommodationTypes: AccommodationType[] = [
    {
        label: 'Appartementencomplex',
        plural: 'Appartementencomplexen',
        value: AccommodationTypeEnum.ApartmentBlock,
    },
    {
        label: 'B&B',
        plural: "B&B's",
        value: AccommodationTypeEnum.BedAndBreakfast,
    },
    {
        label: 'Vakantiepark',
        plural: 'Vakantieparken',
        value: AccommodationTypeEnum.BungalowPark,
    },
    {
        label: 'Camping',
        plural: 'Campings',
        value: AccommodationTypeEnum.Camping,
    },
    {
        label: 'Hotel',
        plural: 'Hotels',
        value: AccommodationTypeEnum.Hotel,
    },
    {
        label: 'Locatie',
        plural: 'Locaties',
        value: AccommodationTypeEnum.Location,
    },
];

export default accommodationTypes;
