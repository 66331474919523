import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import { Box } from '@rebass/grid';
import React from 'react';
import styled from 'styled-components/macro';
import Infobar from './Infobar';
import Link from '../atoms/Link';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const TimeslotsInfobar = () => {
    const { pathname } = useLocation();

    return (
        <Infobar>
            <WizardButtonContainer>
                <Button
                    as={Link}
                    to={{
                        pathname: `${pathname}/wizard`,
                    }}
                    variant="outline"
                >
                    <FormattedMessage defaultMessage="Bulkinvoer" />
                </Button>
            </WizardButtonContainer>
        </Infobar>
    );
};

export default TimeslotsInfobar;

const WizardButtonContainer = styled(Box)`
    bottom: 120px;
    flex-shrink: 0;
    padding: 1.6rem;
    left: 0;
    display: flex;
    justify-content: center;
    position: sticky;
    margin-top: auto;
    > button {
        padding: 0 1.6rem;
        pointer-events: all;
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
        background: white;
        width: 100%;
        border-top: 1px solid ${({ theme }) => theme.colors.neutral[20]};
        bottom: 70px;
        justify-content: flex-start;
    }
    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        bottom: 1.2rem;
    }
    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        bottom: 0;

        > a {
            width: 100%;
        }
    }
`;
