import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

const videoRegex = /https:\/\/(?:www\.)?(vimeo\.com|youtu\.be|www\.youtube\.com)\/(?:watch\?v=)?(.*?)(?:|$|&)/;

type EntityType = 'company' | 'activity' | 'unit' | 'accommodation';

export const imagesValidation = (entity: EntityType, intl: IntlShape) => {
    let shape = {};
    const initShape = {
        listImage: Yup.object().when('type', {
            is: 'rentalUnit',
            then: Yup.object()
                .shape({
                    file: Yup.string().required(intl.formatMessage({ defaultMessage: 'Een lijstfoto is verplicht' })),
                })
                .required(intl.formatMessage({ defaultMessage: 'Een lijstfoto is verplicht' }))
                .nullable(),
        }),
        images: Yup.array().of(
            Yup.object()
                .shape({
                    file: Yup.string().required(),
                    description:
                        entity === 'company' || entity === 'activity'
                            ? Yup.string()
                            : Yup.string().required(
                                  intl.formatMessage({ defaultMessage: 'Een bijschrift is verplicht' })
                              ),
                })
                .nullable()
        ),
        fullCircleImageUrl: Yup.string()
            .url(intl.formatMessage({ defaultMessage: 'Vul een geldig URL in' }))
            .nullable(),
        videoUrl: Yup.string()
            .nullable()
            .matches(videoRegex, intl.formatMessage({ defaultMessage: 'Vul een geldige URL van YouTube of Vimeo in' })),
    };
    if (entity === 'company') {
        const { images } = initShape;
        shape = { images };
    } else {
        shape = { ...initShape };
    }
    return Yup.object().shape(shape);
};
