import React, { SVGAttributes } from 'react';

export default function (props: SVGAttributes<SVGElement>) {
    return (
        <svg width="12" height="15" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g stroke="currentColor" strokeWidth="1.6" fill="none" fillRule="evenodd">
                <path
                    d="M2.333 6h7.334a1 1 0 0 1 1 1v5.333a1 1 0 0 1-1 1H2.333a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1zM6 11.667a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                    fill="currentColor"
                />
                <path d="M3.333 6V3.667a2.333 2.333 0 0 1 2.334-2.334h.666a2.333 2.333 0 0 1 2.334 2.334V6" />
            </g>
        </svg>
    );
}
