import React from 'react';
import { Link as RRLink, LinkProps } from 'react-router-dom';
import styled from 'styled-components/macro';

const Link = (props: LinkProps) => <StyledLink {...props} />;

export default Link;

// why do I need to explicitly define its type all of the sudden...
const StyledLink = styled(RRLink)<LinkProps>`
    color: inherit;
    text-decoration: inherit;
`;
