import addDays from 'date-fns/addDays';
import differenceInDays from 'date-fns/differenceInDays';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import { BulkEditValues } from '../components/molecules/BulkEditForm';
import useWeekdays from '@oberoninternal/travelbase-ds/hooks/useWeekdays';
import { PriceColumnKey } from '../constants/priceRows';
import { useSidebar } from '../context/sidebar';
import parseDate from '@oberoninternal/travelbase-ds/utils/parseDate';
import isWithinInterval from 'date-fns/isWithinInterval';
import isAfter from 'date-fns/isAfter';
import startOfDay from 'date-fns/startOfDay';
import format from 'date-fns/format';

export type Weekday = keyof Omit<BulkEditValues, 'startDate' | 'endDate' | 'rentalUnitId'>;
interface Args {
    date: Date;
    values: BulkEditValues;
    isBulkEdited: (dayName: Weekday) => boolean;
}

/**
 *
 * @param Args.date the columndate
 * @param Args.isBulkEdited method to see if the weekday is edited in bulkmode
 * @param Args.values the current formik values
 *
 */
const useIsBulkEditing = ({
    date,
    isBulkEdited,
    values,
}: Args): ((row: PriceColumnKey) => { width?: number; newValue: number | '' | boolean | null } | undefined) => {
    const days: Weekday[] = useWeekdays({ weekStartsOn: 0 }).map(day => format(day, 'eeee').toLowerCase() as Weekday);
    const [state] = useSidebar();

    const { data } = state;
    return row => {
        if (data?.type === 'BULK_SIDEBAR' && data.row === row) {
            const interval: Interval = {
                start: startOfDay(parseDate(values.startDate)),
                end: parseDate(values.endDate),
            };
            const dayName = days[date.getDay()];

            const value = values[dayName] === null ? null : values[dayName] ?? '';
            const columnDay = startOfDay(date);

            // are we editing this day of the week and does the columnday fall within the input range?
            if (isBulkEdited(dayName) && interval.start && interval.end && isWithinInterval(columnDay, interval)) {
                const prevDay = addDays(columnDay, -1);
                const prevIsEdited = isBulkEdited(days[prevDay.getDay()]);

                // we don't want to display a border if the day before is also edited
                if (prevIsEdited && isAfter(prevDay, addDays(interval.start, -1))) {
                    return { newValue: value, width: 0 };
                }

                let width = 1;
                let consecutiveDay = addDays(columnDay, 1);

                // if its the start of a potential bulk edited period then we want to extend its width reflecting
                // the consecutive days
                for (const day of eachDayOfInterval({ start: consecutiveDay, end: addDays(consecutiveDay, 7) })) {
                    const consecutiveDayIsEdited = isBulkEdited(days[consecutiveDay.getDay()]);
                    if (day && consecutiveDayIsEdited && isWithinInterval(consecutiveDay, interval)) {
                        width += 1;
                        consecutiveDay = addDays(consecutiveDay, 1);
                    }
                }

                if (width > 7) {
                    return { newValue: value, width: differenceInDays(interval.end, interval.start) + 1 };
                }

                return { newValue: value, width };
            }
        }
        return undefined;
    };
};

export default useIsBulkEditing;
