import React, { memo } from 'react';
import format from 'date-fns/format';
import { useParams } from 'react-router-dom';
import { ListChildComponentProps, areEqual } from 'react-window';
import styled from 'styled-components/macro';
import { getDateOpts } from '../../constants/dateOpts';
import euroFormat from '../../constants/euroFormat';
import hourFormat from '../../constants/hourFormat';
import { PartnerParams } from '../../entities/PartnerParams';
import { TicketEdgeFragment } from '../../generated/graphql';
import { CreateRow } from '../../hooks/useBigList';
import ActivityDetails from '../atoms/ActivityDetails';
import dateTextFormat from '@oberoninternal/travelbase-ds/constants/dateTextFormat';
import gql from 'graphql-tag';
import getTicketGroupText from '../../utils/getTicketText';
import getTicketStatus from '../../utils/getTicketStatus';

export const fragment = gql`
    fragment TicketEdge on TicketEdge {
        cursor
        node {
            id
            customer {
                id
                firstName
                lastName
            }

            priceLines {
                label
                quantity
                totalPrice
            }
            rateLines {
                rateName
                amount
                unitPrice
            }
            createdAt
            status
            timeslot {
                activity {
                    ...ActivityPickerEntry
                }
                id
                startDateTime
                endDateTime
                rateGroup {
                    id
                    name
                    rates {
                        id
                        name
                    }
                }
            }
        }
    }
`;

interface TicketItemProps extends ListChildComponentProps {
    data: {
        edges: TicketEdgeFragment[];
        createRow: CreateRow;
    };
}

const TicketItem = (props: TicketItemProps) => {
    const {
        data: { edges, createRow },
        index,
        style,
    } = props;
    const ticket = edges[index].node;
    const { partnerId } = useParams<PartnerParams>();

    if (!ticket) {
        return null;
    }

    const {
        id,
        customer,
        priceLines,
        rateLines,
        timeslot: { startDateTime, endDateTime, activity },
        createdAt,
    } = ticket;

    return createRow(`/partner/${partnerId}/tickets/all/${id}`, style, [
        <ActivityThumbnail key={activity.id} entity={activity} />,
        {
            text: `${format(new Date(startDateTime), hourFormat)} - ${format(new Date(endDateTime), hourFormat)}`,
            subText: format(new Date(startDateTime), `EEEEEE ${dateTextFormat}`, getDateOpts('nl')),
        },
        {
            text: `${customer?.firstName ?? ''} ${customer?.lastName ?? ''}`,
            subText: getTicketGroupText(rateLines),
        },
        format(new Date(createdAt), `EEEEEE ${dateTextFormat}`, getDateOpts('nl')),
        euroFormat(priceLines.reduce((total, next) => total + next.totalPrice, 0)),
        getTicketStatus(ticket),
    ]);
};

export default memo(TicketItem, areEqual);

const ActivityThumbnail = styled(ActivityDetails)`
    min-width: 100%;

    @media (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        > :first-child {
            display: none;
        }
    }
`;
