import { useFormikContext } from 'formik';
import React, { FC, useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ListChildComponentProps } from 'react-window';
import { PartnerParams } from '../../../entities/PartnerParams';
import { usePartnerDataQuery, TicketEdgeFragment } from '../../../generated/graphql';
import useBigList from '../../../hooks/useBigList';
import ActivityDetails from '../../atoms/ActivityDetails';
import BigListWrapper from '../../atoms/BigListWrapper';
import AutoSave from '../../AutoSave';
import BigList from '../../molecules/BigList';
import FilterMenu, { Entry } from '../../molecules/FilterMenu';
import TicketItem from '../../molecules/TicketItem';
import { Box } from '@rebass/grid';
import { FormattedMessage, useIntl } from 'react-intl';

const widths = [
    [3 / 16, null, 2 / 8],
    [3 / 16, null, 1 / 8],
    [3 / 16, null, 3 / 16],
    [3 / 16, null, 1 / 8],
    1 / 8,
    [1 / 8, null, 3 / 16],
];

interface Props<Values extends {}> {
    isItemLoaded: (index: number) => boolean;
    searching?: boolean;
    onSubmit: (values: Values) => void;
    loadMoreItems: (() => void) | (() => Promise<void>);
    totalCount?: number;
    edges: TicketEdgeFragment[];
    loading: boolean;
}
const TicketsList = <Values extends {}>({
    isItemLoaded,
    searching,
    onSubmit,
    loadMoreItems,
    edges,
    loading,
    totalCount,
}: Props<Values>) => {
    const { createHeader, createSkeletonRows, createRow } = useBigList(widths);

    const Item: FC<React.PropsWithChildren<ListChildComponentProps>> = props => {
        if (!isItemLoaded(props.index)) {
            return createSkeletonRows(1, props.style);
        }
        return <TicketItem {...props} />;
    };
    const { setSubmitting } = useFormikContext();

    // totalCount could be undefined when refetching so we want to keep it in state in order to show the correct amount of skeleton rows
    const [itemCount, setTotalCount] = useState(10);
    useEffect(() => {
        if (totalCount) {
            setTotalCount(totalCount);
        }
    }, [totalCount]);
    const noResults = edges.length === 0 && !loading;

    return (
        <BigListWrapper>
            <>
                <AutoSave<Values>
                    enableReinitialize
                    onSave={vals => {
                        setSubmitting(true);
                        onSubmit(vals);
                    }}
                />
                {createHeader([
                    <ActivityFilter key="filter" />,
                    <FormattedMessage key="timeslot" defaultMessage="Tijdslot" />,
                    <FormattedMessage key="guestnumber" defaultMessage="Gast en aantal" />,
                    <FormattedMessage key="created" defaultMessage="Aangemaakt op" />,
                    <FormattedMessage key="price" defaultMessage="Prijs" />,
                    <FormattedMessage key="status" defaultMessage="Status" />,
                ])}
            </>

            {searching && createSkeletonRows(10)}

            {!searching && !noResults && (
                <BigList
                    infiniteLoader={{
                        loadMoreItems,
                        isItemLoaded,
                        itemCount,
                        minimumBatchSize: 10,
                    }}
                    overscanCount={20}
                    itemSize={78}
                    itemCount={itemCount}
                    itemData={{ edges, createRow }}
                >
                    {Item}
                </BigList>
            )}
            {noResults && (
                <Box p={4}>
                    <FormattedMessage defaultMessage="Geen resultaten gevonden" />
                </Box>
            )}
        </BigListWrapper>
    );
};

export default TicketsList;

const ActivityFilter = () => {
    const { partnerId } = useParams<PartnerParams>();
    const { data } = usePartnerDataQuery({ variables: { partnerId } });
    const { companies = [] } = data?.partner ?? {};
    const { formatMessage } = useIntl();
    const entries = useMemo(
        () =>
            companies.map(
                (company): Entry => ({
                    autoSelectChildren: true,
                    thumbnail: <ActivityDetails entity={company} />,
                    id: `company-${company.id}`,
                    children: company.activities.map(
                        (activity): Entry => ({
                            id: `activity-${activity.id}`,
                            fieldValue: activity.id,
                            thumbnail: <ActivityDetails entity={activity} />,
                        })
                    ),
                })
            ),
        [companies]
    );

    if (!data?.partner?.companies.length) {
        return null;
    }

    return (
        <FilterMenu
            // title="Activiteit"
            title={formatMessage({
                defaultMessage: 'Activiteit',
            })}
            entries={entries}
            fieldName="activityIds"
        />
    );
};
