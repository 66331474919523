import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import ButtonGroup from '@oberoninternal/travelbase-ds/components/action/ButtonGroup';
import Arrow from '@oberoninternal/travelbase-ds/components/figure/Arrow';
import Calendar from '@oberoninternal/travelbase-ds/components/figure/Calendar';
import DatePicker from '@oberoninternal/travelbase-ds/components/form/datepicker/DatePicker';
import useIntervalClick from '@oberoninternal/travelbase-ds/hooks/useIntervalClick';
import useSesame from '@oberoninternal/travelbase-ds/hooks/useSesame';
import { differenceInDays } from 'date-fns';
import React, { FC, useState, memo } from 'react';
import styled from 'styled-components/macro';
import epoch from '../../constants/epoch';
import { useDeviceSize, DeviceSize } from '@oberoninternal/travelbase-ds/context/devicesize';
import { useVirtualizerScrollData } from '../../context/virtualizerScrollData';
import { useSidebarState } from '../../context/sidebar';
import { FormattedMessage, useIntl } from 'react-intl';

export const getIndex = (date: Date) => differenceInDays(date, epoch);

const getDaysToScroll = (deviceSize: DeviceSize | null) => {
    switch (deviceSize) {
        case 'tablet':
            return 5;
        case 'mobile':
            return 2;
        default:
            return 7;
    }
};

const PricesNav: FC<React.PropsWithChildren<unknown>> = () => {
    const { open: sbOpen, hidden } = useSidebarState();
    const sidebarOpen = sbOpen || hidden;
    const { locale } = useIntl();
    const deviceSize = useDeviceSize();
    const { columnWidth = 0, scrollTo, scrollBy } = useVirtualizerScrollData() ?? {};

    const scrollAmount = getDaysToScroll(deviceSize) * columnWidth;

    const [date, setDate] = useState<Date | string | null>(null);
    const onClickToday = () => {
        setDate(new Date());
        scrollTo?.('today');
    };

    const decrementScrollProps = useIntervalClick(() => scrollBy?.(-scrollAmount), 500);
    const incrementScrollProps = useIntervalClick(() => scrollBy?.(scrollAmount), 500);
    const { open, onToggle, onClose, ref } = useSesame(false, { closeOnClickOutside: true, closeOnEscape: true });
    return (
        <StyledButtonGroup sidebarOpen={sidebarOpen} ref={ref}>
            <Button variant="outline" {...decrementScrollProps}>
                <LeftArrow />
            </Button>
            <Button variant="outline" onClick={onClickToday}>
                <FormattedMessage defaultMessage="Vandaag" />
            </Button>
            <Button variant="outline" onClick={onToggle}>
                <Calendar style={{ marginTop: '-3px' }} />
            </Button>
            {open && (
                <DatePickerContainer>
                    <DatePicker
                        locale={locale}
                        enabledPast
                        value={date ? new Date(date) : undefined}
                        setValue={(val: string | Date | null) => {
                            setDate(val);
                            if (val) {
                                const index = getIndex(new Date(val));
                                scrollTo?.(index);
                            }
                            onClose();
                        }}
                        variant="day"
                    />
                </DatePickerContainer>
            )}
            <Button variant="outline" {...incrementScrollProps}>
                <RightArrow />
            </Button>
        </StyledButtonGroup>
    );
};

const RightArrow = styled(Arrow).attrs({ strokeWidth: 1.6 })``;
const LeftArrow = styled(RightArrow)`
    transform: rotateY(180deg);
`;
const StyledButtonGroup = styled(ButtonGroup)<{ sidebarOpen: boolean }>`
    @media (max-width: ${({ theme }) => theme.mediaQueries.xs}) {
        bottom: calc(1rem + ${({ theme }) => theme.heights.unitNav}px);
        left: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        right: 0;
        background: transparent;
    }

    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        bottom: calc(1.4rem + ${({ theme }) => theme.heights.unitNav}px);

        button {
            padding: 0 1.6rem;
        }
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        bottom: 2.4rem;
    }
    position: fixed;
    bottom: calc(2.4rem + ${({ theme }) => theme.heights.unitNav}px);
    right: 2.4rem;
    z-index: ${({ theme }) => theme.zIndices.docked};
    background: white;
    transform: translateX(${({ sidebarOpen }) => (sidebarOpen ? '-42.4rem' : '0')});
    transition: transform 400ms cubic-bezier(0.47, 0, 0.745, 0.715);
`;
const DatePickerContainer = styled.div`
    position: absolute;
    box-shadow: 0 20px 40px 0 rgba(16, 36, 48, 0.06);
    bottom: 5rem;
    height: 40rem;
    padding-top: 2rem;
    width: 32rem;
    background: ${({ theme }) => theme.colors.neutral[0]};
    padding-top: 2rem;
    border-radius: 1rem;
    @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
        right: 0;
    }
`;

export default memo(PricesNav);
