import React from 'react';
import { createIntl, RawIntlProvider } from 'react-intl';
import { Route, Redirect, BrowserRouter } from 'react-router-dom';

interface Props {
    children: React.ReactNode;
}
const getDefaultLangFromNavigator = () => {
    const lang = navigator.language.split(/[-_]/)[0];
    if (lang === 'nl' || lang === 'de') {
        return lang;
    }
    return 'en';
};

const requireMessage = require.context('../../lang/compiled/', false, /\.json$/);

const getMessages = (locale: string, brand: string | undefined) => {
    let languageMessages = requireMessage(`./${locale}.json`);
    let brandMessages = {};
    if (brand && ['campercontact', 'zandvoort'].includes(brand)) {
        brandMessages = requireMessage(`./${locale}.${brand}.json`) ?? {};
    }

    if (locale === 'nl') {
        const baseMessages = requireMessage(`./base.json`);
        languageMessages = { ...baseMessages, ...languageMessages };
    }
    return {
        ...languageMessages,
        ...brandMessages,
    };
};

const brand = process.env.REACT_APP_BRAND;
export const LocalizedRouter: React.FC<Props> = ({ children }) => (
    <BrowserRouter>
        <Route path="/:lang(en|nl|de)">
            {({ match, location }) => {
                const params: {
                    lang?: string;
                } = match ? match.params : {};

                const { lang = getDefaultLangFromNavigator() } = params;
                const messages = getMessages(lang, brand);

                const intl = createIntl({
                    locale: lang,
                    messages,
                });

                const { pathname } = location;

                if (!pathname.startsWith(`/${lang}/`)) {
                    return <Redirect to={`/${lang}${pathname}`} />;
                }

                return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
            }}
        </Route>
    </BrowserRouter>
);
