import gql from 'graphql-tag';
import React, { FC } from 'react';
import { generatePath, NavLink, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ActivityParams } from '../../entities/ActivityParams';
import { CompanyPickerEntryFragment, ActivityPickerEntryFragment } from '../../generated/graphql';
import PickerEntryContainer from '../atoms/PickerEntryContainer';
import ActivityDetails from '../atoms/ActivityDetails';
import PickerList from '../atoms/PickerList';
import PickerItem from '../atoms/PickerItem';
import { entryCss } from '../atoms/entryCss';

export type Entity = NonNullable<CompanyPickerEntryFragment> | NonNullable<ActivityPickerEntryFragment>;

interface Props {
    company: CompanyPickerEntryFragment;
    companies: Entity[];
    first?: boolean;
    open: boolean;
    setOpen: (open: boolean) => void;
}

export const companyPickerEntryFragment = gql`
    fragment CompanyPickerEntry on Company {
        id
        name
        slug
        listImage {
            url
        }
        activities {
            ...ActivityPickerEntry
        }
    }
`;

export const activityPickerEntryFragment = gql`
    fragment ActivityPickerEntry on Activity {
        id
        name
        slug
        company {
            id
            name
        }
        listImage {
            url
        }
        hideForPartner
    }
`;

const ActivityPickerEntry: FC<React.PropsWithChildren<Props>> = ({
    companies,
    company,
    first = false,
    open,
    setOpen,
}) => {
    const match = useRouteMatch<ActivityParams>();
    const companyActivities = company.activities || [];

    const firstCompany = companies[0];

    const renderEntity = (entity: Entity, i = 0) => {
        let entryLink;
        const commonParams = { partnerId: match.params.partnerId };

        if (entity.__typename === 'Company') {
            entryLink = generatePath('/partner/:partnerId/company/:companySlug', {
                companySlug: entity.slug,
                ...commonParams,
            });
        } else if (entity.__typename === 'Activity') {
            if (entity.hideForPartner && !first) {
                return null;
            }
            entryLink = generatePath('/partner/:partnerId/activity/:activitySlug', {
                activitySlug: entity.slug,
                ...commonParams,
            });
        }

        const inner = (
            <PickerEntryContainer first={first && open}>
                <ActivityDetails first={first} entity={entity} />
            </PickerEntryContainer>
        );

        const entryProps = {
            key: entity ? entity.slug : i,
            open,
            activeClassName: 'active',
        };

        return first ? (
            <First {...entryProps}>{inner}</First>
        ) : (
            <StyledLink onClick={() => setOpen(false)} exact={first && !open} to={entryLink ?? ''} {...entryProps}>
                {inner}
            </StyledLink>
        );
    };

    if (first) {
        const selectedCompany = companies.find(
            item => item && item.slug === (match.params.companySlug ?? match.params.activitySlug)
        );

        return renderEntity(first && selectedCompany ? selectedCompany : firstCompany);
    }

    return (
        <>
            <PickerList isParent>
                <PickerItem key={company.slug} hidePath>
                    {renderEntity(company)}
                </PickerItem>
            </PickerList>
            <PickerList>
                {companyActivities.map(
                    activity =>
                        activity && (
                            <PickerItem key={activity.slug} single={companyActivities.length === 1}>
                                {renderEntity(activity)}
                            </PickerItem>
                        )
                )}
            </PickerList>
        </>
    );
};

export default ActivityPickerEntry;

const StyledLink = styled(NavLink)<{ open: boolean }>`
    ${entryCss}
`;

const First = styled.div<{ open: boolean }>`
    ${entryCss}
`;
