import { AccommodationTypeEnum, RentalUnitTypeEnum } from '../generated/graphql';
import { BrandConfig } from './brandConfig';
import countryOptions from './countryOptions';

const accommodationTypeOptions = [
    {
        value: AccommodationTypeEnum.Location,
        label: 'Locatie',
        RentalUnitTypeEnum: [RentalUnitTypeEnum.CamperSpot, RentalUnitTypeEnum.CampingPitch],
    },
];

const rentalUnitTypesOptions = [{ value: RentalUnitTypeEnum.CamperSpot, label: 'Camperplaats' }];

const campercontactConfig: BrandConfig = {
    contactUrl: 'https://www.campercontact.com/en/content/contact',
    countryOptions,
    registrationForm: {
        accommodationOptions: accommodationTypeOptions,
        rentalUnitOptions: rentalUnitTypesOptions,
        hasRequiredVatNumber: true,
        hasPackageOptions: true,
    },
};

export default campercontactConfig;
