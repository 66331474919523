import SelectInput from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import { Box, Flex } from '@rebass/grid';
import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';
import React, { FC } from 'react';
import brandConfig from '../../constants/brandConfig';
import { RentalUnitTypeEnum } from '../../generated/resetGraphql';
import { getUnitTypeTranslation } from '../../utils/getUnitTypeTranslation';
import FieldSet from '../atoms/FieldSet';
import FieldSetHint from '../atoms/FieldSetHint';
import ArrayTextInput from './ArrayTextInput';
import FieldHeading from './FieldHeading';
import LabelBox from './LabelBox';
import TranslationInput from './TranslationInput';
import { FormattedMessage, useIntl } from 'react-intl';

interface KeyHolderProps {
    keyHolderExplanationDE: Maybe<string>;
    keyHolderExplanationNL: Maybe<string>;
    keyHolderExplanationEN: Maybe<string>;
    keyHolderExplanationPending: Maybe<string>;
    type: RentalUnitTypeEnum;
    showKeyholderExplanation: boolean;
}

const KeyHolderFields: FC<React.PropsWithChildren<{ data: KeyHolderProps }>> = ({ data }) => {
    const {
        keyHolderExplanationEN,
        keyHolderExplanationNL,
        keyHolderExplanationDE,
        keyHolderExplanationPending,
        type,
        showKeyholderExplanation,
    } = data;
    const { formatMessage } = useIntl();
    return (
        <>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="keyHolder.contactName">
                        <FormattedMessage defaultMessage="Naam contactpersoon sleuteloverdracht" />
                    </Label>
                </LabelBox>
                <Box width={1}>
                    <TextInputField
                        id="keyHolder.contactName"
                        placeholder={formatMessage({
                            defaultMessage: 'Naam',
                        })}
                        name="keyHolder.contactName"
                    />
                </Box>
            </FieldSet>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="keyHolderStreet">
                        <FormattedMessage defaultMessage="Adres (straat en huisnummer)" />
                    </Label>
                    <FieldSetHint>
                        <FormattedMessage defaultMessage="Verplicht" />
                    </FieldSetHint>
                </LabelBox>
                <Flex width={1} flexDirection={['column', 'row']}>
                    <Box flex={1} pr={[null, 4]}>
                        <TextInputField
                            id="keyHolderStreet"
                            placeholder={formatMessage({
                                defaultMessage: 'Straat',
                            })}
                            name="keyHolderAddress.street"
                        />
                    </Box>
                    <Box mt={[4, 0]} width={[1, '20rem']}>
                        <TextInputField
                            maxLength={20}
                            placeholder={formatMessage({
                                defaultMessage: 'Huisnummer',
                            })}
                            name="keyHolderAddress.number"
                        />
                    </Box>
                </Flex>
            </FieldSet>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="keyholderPostalCode">
                        <FormattedMessage defaultMessage="Postcode en plaats" />
                    </Label>
                    <FieldSetHint>
                        <FormattedMessage defaultMessage="Verplicht" />
                    </FieldSetHint>
                </LabelBox>
                <Flex width={1} flexDirection={['column', 'row']}>
                    <Box width={[1, '20rem']}>
                        <TextInputField
                            id="keyholderPostalCode"
                            placeholder={formatMessage({ defaultMessage: 'Postcode' })}
                            name="keyHolderAddress.postalCode"
                            maxLength={20}
                        />
                    </Box>
                    <Box flex={1} mt={[4, 0]} pl={[null, 4]}>
                        <TextInputField
                            id="keyHolderCity"
                            placeholder={formatMessage({ defaultMessage: 'Plaats' })}
                            name="keyHolderAddress.city"
                            maxLength={20}
                        />
                    </Box>
                </Flex>
            </FieldSet>
            {brandConfig.countryOptions.length > 1 && (
                <FieldSet>
                    <LabelBox>
                        <Label htmlFor="countryCode">
                            <FormattedMessage defaultMessage="Land" />
                        </Label>
                    </LabelBox>
                    <Box width={[1, 0.5]} style={{ maxWidth: '40rem' }}>
                        <SelectInput
                            name="keyHolderAddress.countryCode"
                            isSearchable
                            placeholder={formatMessage({ defaultMessage: 'Selecteer land' })}
                            options={brandConfig.countryOptions}
                        />
                    </Box>
                </FieldSet>
            )}
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="keyHolder.phoneNumbers">
                        <FormattedMessage defaultMessage="Telefoonnummer" />
                    </Label>
                    <FieldSetHint>
                        <FormattedMessage defaultMessage="Gebruik de internationale notatie (+31612345678)." />
                    </FieldSetHint>
                </LabelBox>
                <Box width={1}>
                    <ArrayTextInput
                        placeholder={formatMessage({ defaultMessage: 'Telefoonnummer' })}
                        name="keyHolder.phoneNumbers"
                        addDescription={formatMessage({ defaultMessage: 'Extra telefoonnummer veld' })}
                    />
                </Box>
            </FieldSet>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="keyHolder.emails">
                        <FormattedMessage defaultMessage="E-mailadres contactpersoon of afdeling sleuteloverdracht" />
                    </Label>
                </LabelBox>
                <Box width={1}>
                    <ArrayTextInput
                        placeholder={formatMessage({ defaultMessage: 'E-mailadres' })}
                        name="keyHolder.emails"
                        addDescription={formatMessage({ defaultMessage: 'Extra e-mailadres veld' })}
                    />
                </Box>
            </FieldSet>
            {showKeyholderExplanation ? (
                <>
                    <FieldHeading title={formatMessage({ defaultMessage: 'Toelichting sleuteladres' })}>
                        <FormattedMessage defaultMessage="Specifieke toelichting bij het sleuteladres. Deze tekst komt op de bevestiging aan de gast te staan bij het sleuteladres." />
                    </FieldHeading>

                    <TranslationInput
                        name="keyHolderExplanation"
                        placeholder={formatMessage({ defaultMessage: 'Extra toelichting voor je gast' })}
                        data={{
                            en: keyHolderExplanationEN,
                            nl: keyHolderExplanationNL,
                            de: keyHolderExplanationDE,
                            pending: keyHolderExplanationPending,
                        }}
                    />
                </>
            ) : (
                <FieldHeading title={formatMessage({ defaultMessage: 'Toelichting sleuteladres' })}>
                    <FormattedMessage
                        defaultMessage='Specifieke toelichting bij het sleuteladres geef je per accommodatie in. Dit vind je bij "{unitType} informatie"'
                        values={{ unitType: getUnitTypeTranslation(type) }}
                    />
                </FieldHeading>
            )}
        </>
    );
};

export default KeyHolderFields;
