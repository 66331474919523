import { Scalars } from '../generated/graphql';
import { areIntervalsOverlapping, endOfDay, isBefore, isSameDay, startOfDay } from 'date-fns';
import { PLACEHOLDER_ID } from '@oberoninternal/travelbase-ds/components/calendar/Allotment';

export const doesOverlap = (
    // both booking and lockout confirm to this typing, so you can pass either.
    allotment: {
        id: Scalars['ID']['output'];
        startDate: Scalars['Date']['output'];
        endDate: Scalars['Date']['output'];
    },
    startDate: Date,
    endDate = endOfDay(startDate)
): boolean => {
    if (allotment.id === PLACEHOLDER_ID) {
        return false;
    }

    const allotmentStartDate = startOfDay(new Date(allotment.startDate));
    const allotmentEndDate = startOfDay(new Date(allotment.endDate));

    if (isBefore(startDate, allotmentStartDate) && isSameDay(endDate, allotmentStartDate)) {
        return false;
    }

    return areIntervalsOverlapping(
        {
            start: startDate,
            end: endDate,
        },
        {
            start: allotmentStartDate,
            end: allotmentEndDate,
        }
    );
};
