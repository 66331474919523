import { useRef, useState } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { useCloseOnEsc } from './useCloseOnEsc';

export interface MenuStateProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    menuRef: React.MutableRefObject<null>;
}

interface Options {
    closeClickOnOutside?: boolean;
}

/**
 *  @deprecated since version 2.2.1, please use useSesame instead
 */
const useMenuState = (initialState?: boolean, options?: Options) => {
    const { closeClickOnOutside }: Required<Options> = { closeClickOnOutside: true, ...options };
    const [open, setOpen] = useState(!!initialState);
    const menuRef = useRef(null);
    useCloseOnEsc({ open, setOpen });
    useOnClickOutside(menuRef, () => {
        if (closeClickOnOutside) {
            setOpen(false);
        }
    });
    return { open, setOpen, menuProps: { open, setOpen, menuRef } };
};

export default useMenuState;
