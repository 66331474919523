import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import brandConfig from '../../../../constants/brandConfig';
import { NotFoundError } from '../../../../entities/NotFoundError';
import { UnitParams } from '../../../../entities/UnitParams';
import { useEditKeyHolderMutation, useKeyHolderQuery } from '../../../../generated/graphql';
import omitEqualValues from '../../../../utils/omitEqualValues';
import { toInitialContactInfo } from '../../../../utils/toInitialContactInfo';
import { Seperator } from '../../../atoms/Seperator';
import KeyHolderFields from '../../../molecules/KeyHolderFields';
import FormScreen from '../../../organisms/FormScreen';
import Loading from '../../../organisms/Loading';
import { keyHolderValidation } from '../../../../utils/validations/locationValidation';
import { FormattedMessage, useIntl } from 'react-intl';

const keyHolderFragment = gql`
    fragment KeyHolder on RentalUnit {
        id
        type
        slug
        keyHolderExplanationDE: keyHolderExplanation(locale: "de")
        keyHolderExplanationNL: keyHolderExplanation(locale: "nl")
        keyHolderExplanationEN: keyHolderExplanation(locale: "en")
        keyHolderExplanationPending
        accommodation {
            id
            keyHolderAddress {
                city
                street
                postalCode
                number
                countryCode
            }
            keyHolder {
                contactName
                phoneNumbers
                emails
            }
        }
    }
`;

export const keyHolderQuery = gql`
    query KeyHolder($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            ...KeyHolder
        }
    }
    ${keyHolderFragment}
`;

export const editKeyHolderMutation = gql`
    mutation EditKeyHolder($input: EditKeyHolderInput!) {
        editKeyHolder(input: $input) {
            rentalUnit {
                ...KeyHolder
            }
        }
    }
    ${keyHolderFragment}
`;

const KeyHolder: FC<React.PropsWithChildren<UnitParams & { isCompact: boolean }>> = ({ unitSlug, isCompact }) => {
    const { data, loading } = useKeyHolderQuery({ variables: { unitSlug } });
    const [mutate] = useEditKeyHolderMutation();
    const intl = useIntl();

    if (loading) {
        return <Loading />;
    }

    if (!data || !data.rentalUnit || !data.rentalUnit.accommodation) {
        throw new NotFoundError();
    }

    const {
        rentalUnit: {
            id,
            type,
            keyHolderExplanationNL,
            keyHolderExplanationDE,
            keyHolderExplanationEN,
            keyHolderExplanationPending,
            accommodation: { keyHolderAddress, keyHolder },
        },
    } = data;

    const keyHolderCity = keyHolderAddress?.city ?? '';
    const keyHolderPostalCode = keyHolderAddress?.postalCode ?? '';
    const keyHolderStreet = keyHolderAddress?.street ?? '';
    const keyHolderNumber = keyHolderAddress?.number ?? '';
    const { countryOptions } = brandConfig;
    const keyHolderCountryCode = keyHolderAddress?.countryCode
        ? keyHolderAddress.countryCode
        : countryOptions.length === 1
        ? countryOptions[0].value
        : '';
    const initialValues = {
        keyHolderAddress: {
            city: keyHolderCity,
            postalCode: keyHolderPostalCode,
            street: keyHolderStreet,
            number: keyHolderNumber,
            countryCode: keyHolderCountryCode,
        },
        keyHolder: toInitialContactInfo(keyHolder),
        keyHolderExplanation: keyHolderExplanationPending ?? keyHolderExplanationNL ?? '',
    };

    return (
        <FormScreen
            handleSubmit={async values => {
                const { keyHolder: newKeyHolder, ...correctedValues } = omitEqualValues(values, initialValues, [
                    'keyHolderExplanation',
                ]);
                await mutate({
                    variables: {
                        input: {
                            rentalUnitId: id,
                            ...correctedValues,
                            keyHolder: {
                                ...newKeyHolder,
                                emails: newKeyHolder.emails.filter(email => email !== ''),
                                phoneNumbers: newKeyHolder.phoneNumbers.filter(phoneNumber => phoneNumber !== ''),
                            },
                        },
                    },
                });
            }}
            validationSchema={keyHolderValidation(intl)}
            initialValues={initialValues}
        >
            {() => (
                <>
                    <Title variant="regular">
                        <FormattedMessage defaultMessage="Sleuteladres" />
                    </Title>
                    <p>
                        <FormattedMessage
                            defaultMessage="Wie is de persoon of afdeling die de sleuteloverdracht doet van de accommodatie? Deze gegevens
                        worden met de gast gedeeld na het boeken."
                        />
                    </p>
                    <Seperator />

                    <KeyHolderFields
                        data={{
                            keyHolderExplanationDE,
                            keyHolderExplanationEN,
                            keyHolderExplanationNL,
                            keyHolderExplanationPending,
                            type,
                            showKeyholderExplanation: isCompact,
                        }}
                    />
                </>
            )}
        </FormScreen>
    );
};

export default KeyHolder;
