import React, { FC } from 'react';
import styled from 'styled-components/macro';
import Info from '@oberoninternal/travelbase-ds/components/figure/HintInfo';
import Hint from '../molecules/Hint';

export interface ErrorProps {
    title?: string;
    hideIcon?: boolean;
}

const Error: FC<React.PropsWithChildren<ErrorProps>> = ({ title = 'Foutmelding', hideIcon, children }) => (
    <Container>
        <Hint title={title} variant="danger" icon={!hideIcon && <Info />}>
            <p>{children}</p>
        </Hint>
    </Container>
);

const Container = styled.section`
    max-width: ${({ theme }) => theme.maxWidths.m}px;
    margin: 0 auto;
    padding: calc(${({ theme }) => theme.heights.nav}px + 2rem) 2rem 2rem;
`;

export default Error;
