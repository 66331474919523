import { Flex } from '@rebass/grid';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import Thumbnail from './Thumbnail';

interface Props {
    thumbnailUrl?: string | null;
}

const DetailsContainer: FC<React.PropsWithChildren<Props>> = ({ children, thumbnailUrl, ...props }) => (
    <Container flexWrap="nowrap" {...props}>
        <Thumbnail url={thumbnailUrl} />
        <Content>{children}</Content>
    </Container>
);

export default DetailsContainer;

const Container = styled(Flex)`
    min-width: 30rem;
`;

const Content = styled.div`
    display: flex;
    pointer-events: none;
    overflow: hidden;
    flex-direction: column;
    padding-left: ${({ theme }) => theme.spacing['30_Small']};
    justify-content: center;
    > * {
        margin: 0;
        padding: 0;
    }
`;
