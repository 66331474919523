import { BrandConfig } from './brandConfig';
import { nlCountryOption } from './countryOptions';

const terschellingConfig: BrandConfig = {
    contactUrl: 'https://www.vvvterschelling.nl/contact',
    countryOptions: [nlCountryOption],
    customSlugs: {
        activity: 'excursies',
        company: 'bedrijven',
        accommodation: 'accommodaties',
    },
    brandName: 'VVV Terschelling',
};

export default terschellingConfig;
