import { Box, Flex } from '@rebass/grid';
import { addDays, areIntervalsOverlapping, format, startOfToday, endOfDay, startOfDay } from 'date-fns';
import { getIn } from 'formik';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { useApolloClient } from '@apollo/client';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import { UnitParams } from '../../entities/UnitParams';
import {
    DatePricingModifierTypeEnum,
    DatePricingModifierValueTypeEnum,
    PeriodRulesFragment,
    PricesDocument,
    PricesQuery,
    PricesQueryVariables,
    Scalars,
    useAddPeriodRuleMutation,
    useDeletePeriodRuleMutation,
    useEditPeriodRuleMutation,
    ValidatePeriodDocument,
    ValidatePeriodQuery,
} from '../../generated/graphql';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import Effect from '../atoms/Effect';
import ErrorMessage, { ErrorMessageContainer } from '@oberoninternal/travelbase-ds/components/form/ErrorMessage';
import Heading from '../atoms/Heading';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import { RadioField } from '@oberoninternal/travelbase-ds/components/form/Radio';
import { SidebarSeperator } from '../atoms/Seperator';
import SidebarField from '../atoms/SidebarField';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import FormScreen from '../organisms/FormScreen';
import RangePickerInputField from './RangePickerInputField';
import { StepperField } from '@oberoninternal/travelbase-ds/components/form/Stepper';
import { formatDuration } from '../../utils/formatDuration';
import dateFormat from '../../constants/dateFormat';
import { getDateOpts } from '../../constants/dateOpts';
import { validatePeriod } from '../../utils/validatePeriod';
import { useSidebar } from '../../context/sidebar';
import { FormActionsContainer } from '@oberoninternal/travelbase-ds/components/form/FormActions';
import StartdateEffect from '../atoms/StartdateEffect';
import { FormattedMessage, useIntl } from 'react-intl';

export const query = gql`
    query ValidatePeriod($start: Date!, $end: Date!, $unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            id
            datePricingModifiers(startDate: $start, endDate: $end) {
                id
                minDuration
                maxDuration
                startDate
                endDate
            }
        }
    }
`;

export const mutation = gql`
    mutation addPeriodRule($input: CreateDatePricingModifierInput!) {
        createDatePricingModifier(input: $input) {
            datePricingModifier {
                ...PeriodRules
            }
        }
    }

    mutation editPeriodRule($input: EditDatePricingModifierInput!) {
        editDatePricingModifier(input: $input) {
            datePricingModifier {
                ...PeriodRules
            }
        }
    }

    mutation deletePeriodRule($id: ID!) {
        deleteDatePricingModifier(input: { datePricingModifierId: $id }) {
            id
        }
    }
`;

export interface PeriodRuleSidebarData {
    type: 'PERIOD_RULE';
    // required when creating!
    rentalUnitId?: Scalars['ID']['output'];
    // required when editing!
    periodRule?: PeriodRulesFragment;
    pricesVars?: PricesQueryVariables;
}

interface Props {
    data: PeriodRuleSidebarData;
}

const PeriodRuleSidebar: FC<React.PropsWithChildren<Props>> = ({
    data: { rentalUnitId, periodRule: rule, pricesVars },
}) => {
    const periodRule = rule ?? ({} as PeriodRulesFragment);
    const [add] = useAddPeriodRuleMutation();
    const [edit] = useEditPeriodRuleMutation();
    const [remove] = useDeletePeriodRuleMutation();
    const client = useApolloClient();
    const [, dispatch] = useSidebar();
    const params = useParams<UnitParams>();
    const { formatMessage, locale } = useIntl();
    const dateOpts = getDateOpts(locale as 'nl' | 'de' | 'en');
    const initialValues = {
        startDate: periodRule.startDate ? new Date(periodRule.startDate) : format(startOfToday(), dateFormat, dateOpts),
        endDate: periodRule.endDate
            ? new Date(periodRule.endDate)
            : format(addDays(startOfToday(), 7), dateFormat, dateOpts),
        minDuration: periodRule.minDuration || 1,
        maxDuration: periodRule.maxDuration || 1,
        type: periodRule.type || DatePricingModifierTypeEnum.Addition,
        valueType: periodRule.valueType || DatePricingModifierValueTypeEnum.Percentage,
        value: periodRule.value || 10,
    };

    const handleSubmit = async (values: typeof initialValues) => {
        dispatch({ type: 'close' });
        const startDate = format(new Date(values.startDate), dateFormat, dateOpts);
        const endDate = format(new Date(values.endDate), dateFormat, dateOpts);

        try {
            if (rule) {
                await edit({
                    variables: {
                        input: {
                            ...values,
                            datePricingModifierId: periodRule.id,
                            startDate,
                            endDate,
                        },
                    },
                    optimisticResponse: {
                        editDatePricingModifier: {
                            __typename: 'EditDatePricingModifierPayload',
                            datePricingModifier: {
                                __typename: 'DatePricingModifier',
                                id: periodRule.id,
                                ...values,
                            },
                        },
                    },
                });
            } else {
                if (!rentalUnitId) {
                    throw new Error('No rentalUnitId was passed to PeriodRuleSidebar whilst creating');
                }

                const data = client.readQuery<PricesQuery>({
                    query: PricesDocument,
                    variables: pricesVars,
                })!;

                try {
                    // write our fictional modifier to the prices query
                    client.writeQuery<PricesQuery>({
                        query: PricesDocument,
                        variables: pricesVars,
                        data: {
                            rentalUnit: {
                                ...data.rentalUnit!,
                                datePricingModifiers: [
                                    ...data.rentalUnit!.datePricingModifiers,
                                    {
                                        __typename: 'DatePricingModifier',
                                        id: '-1',
                                        ...values,
                                    },
                                ],
                            },
                        },
                    });

                    const { data: result } = await add({
                        variables: {
                            input: {
                                ...values,
                                rentalUnitId,
                                startDate,
                                endDate,
                            },
                        },
                    });

                    // data has arrived, insert real period rule
                    const newPeriodRule = result?.createDatePricingModifier.datePricingModifier;
                    if (newPeriodRule) {
                        client.writeQuery<PricesQuery>({
                            query: PricesDocument,
                            variables: pricesVars,
                            data: {
                                rentalUnit: {
                                    ...data.rentalUnit!,
                                    datePricingModifiers: [...data.rentalUnit!.datePricingModifiers, newPeriodRule],
                                },
                            },
                        });
                    }
                } catch (ex) {
                    // clean up & rethrow
                    client.writeQuery<PricesQuery>({
                        query: PricesDocument,
                        variables: pricesVars,
                        data,
                    });
                    throw ex;
                }
            }
        } catch (ex) {
            dispatch({ type: 'show' });

            // eslint-disable-next-line no-console
            console.error('PeriodRuleSidebar handleSubmit failed:', ex);
        }
    };

    const handleDelete = async () => {
        if (
            !window.confirm(
                formatMessage({ defaultMessage: 'Weet je zeker dat je deze perioderegel wilt verwijderen?' })
            )
        ) {
            return;
        }

        dispatch({ type: 'close' });

        const data = client.readQuery<PricesQuery>({
            query: PricesDocument,
            variables: pricesVars,
        })!;

        client.writeQuery<PricesQuery>({
            query: PricesDocument,
            variables: pricesVars,
            data: {
                rentalUnit: {
                    ...data.rentalUnit!,
                    datePricingModifiers: [
                        ...data.rentalUnit!.datePricingModifiers.filter(({ id }) => id !== rule!.id),
                    ],
                },
            },
        });

        try {
            await remove({
                variables: {
                    id: rule!.id,
                },
            });
        } catch (ex) {
            // clean up & rethrow
            client.writeQuery<PricesQuery>({
                query: PricesDocument,
                variables: pricesVars,
                data,
            });
            dispatch({ type: 'show' });

            throw ex;
        }
    };

    return (
        <FormScreen
            variant="sidebar"
            initialValues={initialValues}
            handleSubmit={handleSubmit}
            validate={async ({ minDuration, maxDuration, startDate, endDate }) => {
                const formikErrors: Record<string, string> = {};
                try {
                    validatePeriod(new Date(startDate), new Date(endDate));
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } catch (ex: any) {
                    formikErrors.startDate = ex.message;
                }

                try {
                    const { data, errors } = await client.query<ValidatePeriodQuery>({
                        query: ValidatePeriodDocument,
                        variables: {
                            unitSlug: params.unitSlug,
                            start: startDate,
                            end: endDate,
                        },
                    });
                    if (errors?.length) {
                        throw new Error(errors.map(({ message }) => message).join('\n'));
                    }

                    const periods = data.rentalUnit?.datePricingModifiers ?? [];

                    for (const period of periods) {
                        if (rule && period.id === rule.id) {
                            continue;
                        }

                        if (period.maxDuration < minDuration || maxDuration < period.minDuration) {
                            continue;
                        }

                        if (
                            areIntervalsOverlapping(
                                { start: startOfDay(new Date(startDate)), end: endOfDay(new Date(endDate)) },
                                {
                                    start: startOfDay(new Date(period.startDate)),
                                    end: endOfDay(new Date(period.endDate)),
                                }
                            )
                        ) {
                            const periodLabel =
                                period.minDuration === period.maxDuration
                                    ? period.minDuration.toString()
                                    : `${period.minDuration}-${period.maxDuration}`;
                            throw new Error(
                                `Deze regel conflicteert met de volgende reeds bestaande perioderegel: ${periodLabel} ${
                                    periodLabel === '1' ? 'nacht' : 'nachten'
                                }, ${formatDuration(
                                    new Date(period.startDate),
                                    new Date(period.endDate)
                                )}\nPas de periode of de verblijfslengte aan.`
                            );
                        }
                    }
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } catch (ex: any) {
                    // eslint-disable-next-line no-console
                    console.error(ex);
                    formikErrors.global = ex.message;
                }
                return formikErrors;
            }}
            bottomChildren={({ dirty }) => (
                <FormActionsContainer alwaysVisible dirty={dirty} variant="sidebar">
                    {rule ? (
                        <Button variant="danger" type="button" onClick={handleDelete}>
                            <FormattedMessage defaultMessage="Verwijderen" />
                        </Button>
                    ) : (
                        <span />
                    )}

                    <Button disabled={!dirty && !!rule} type="submit">
                        <FormattedMessage defaultMessage="Publiceren" />
                    </Button>
                </FormActionsContainer>
            )}
        >
            {({ values, errors, setFieldValue }) => {
                const globalError = getIn(errors, 'global');

                return (
                    <>
                        <StartdateEffect disableEditOnOpen={!!rule} />
                        <SidebarSeperator />
                        <Heading title={formatMessage({ defaultMessage: 'Lang verblijf stimuleren' })}>
                            <FormattedMessage defaultMessage="Met een perioderegel kun je een lang verblijf relatief goedkoper en/of een kort verblijf relatief duurder maken." />
                        </Heading>

                        <Box>
                            <SidebarField label={formatMessage({ defaultMessage: 'Periode (van - t/m)' })}>
                                <RangePickerInputField />
                            </SidebarField>

                            <Effect<typeof initialValues>
                                onChange={({ values: nextValues }, prevState) => {
                                    const prevValues = prevState ? prevState.values : nextValues;

                                    // 'fix' any values that now have exceeded their bounds
                                    if (
                                        nextValues.minDuration > prevValues.minDuration &&
                                        nextValues.maxDuration < nextValues.minDuration
                                    ) {
                                        setFieldValue('maxDuration', nextValues.minDuration);
                                    } else if (
                                        nextValues.maxDuration < prevValues.maxDuration &&
                                        nextValues.minDuration > nextValues.maxDuration
                                    ) {
                                        setFieldValue('minDuration', nextValues.maxDuration);
                                    }
                                }}
                            />

                            <SidebarField label={formatMessage({ defaultMessage: 'Verblijfsduur' })}>
                                <Flex>
                                    <Box width={1 / 2}>
                                        <Label variant="small">
                                            <FormattedMessage defaultMessage="Minimaal" />
                                        </Label>
                                        <StepperField size="small" minimum={1} maximum={21} name="minDuration" />
                                    </Box>
                                    <Box width={1 / 2}>
                                        <Label variant="small">
                                            <FormattedMessage defaultMessage="Maximaal" />
                                        </Label>
                                        <StepperField size="small" minimum={1} maximum={21} name="maxDuration" />
                                    </Box>
                                </Flex>
                                {globalError && <ErrorMessage>{globalError}</ErrorMessage>}
                            </SidebarField>
                            <SidebarField label={formatMessage({ defaultMessage: 'Korting of toeslag?' })}>
                                <SidebarField>
                                    <RadioField name="type" id={DatePricingModifierTypeEnum.Deduction}>
                                        <FormattedMessage defaultMessage="Korting" />
                                    </RadioField>
                                    <RadioField name="type" id={DatePricingModifierTypeEnum.Addition}>
                                        <FormattedMessage defaultMessage="Toeslag" />
                                    </RadioField>
                                </SidebarField>
                            </SidebarField>
                            <SidebarField label={formatMessage({ defaultMessage: 'Percentage of bedrag?' })}>
                                <SidebarField>
                                    <RadioField name="valueType" id={DatePricingModifierValueTypeEnum.Percentage}>
                                        <FormattedMessage defaultMessage="Percentage" />
                                    </RadioField>
                                    <RadioField name="valueType" id={DatePricingModifierValueTypeEnum.Amount}>
                                        <FormattedMessage defaultMessage="Bedrag" />
                                    </RadioField>
                                </SidebarField>
                            </SidebarField>
                            <SidebarField label={formatMessage({ defaultMessage: 'Kortingspercentage' })}>
                                <Flex>
                                    <ValueBox width={1 / 2}>
                                        <TextInputField
                                            validate={value => {
                                                try {
                                                    Yup.number()

                                                        .typeError(
                                                            formatMessage({ defaultMessage: 'Ongeldige waarde' })
                                                        )
                                                        .max(
                                                            values.valueType ===
                                                                DatePricingModifierValueTypeEnum.Percentage
                                                                ? 100
                                                                : Infinity,
                                                            formatMessage({
                                                                defaultMessage: 'Waarde ligt boven het maximum',
                                                            })
                                                        )
                                                        .positive(
                                                            formatMessage({
                                                                defaultMessage: 'Waarde moet boven 0 liggen',
                                                            })
                                                        )
                                                        .validateSync(value);
                                                    return undefined;
                                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                } catch (ex: any) {
                                                    return ex.message;
                                                }
                                            }}
                                            name="value"
                                            type="number"
                                            variant="small"
                                            step={0.1}
                                            onChange={e => {
                                                const val = parseFloat(e.target.value);
                                                if (Number.isNaN(val)) {
                                                    // can't parse value - user is probably still typing - set string value instead.
                                                    setFieldValue('value', e.target.value);
                                                } else {
                                                    setFieldValue('value', Math.round(val * 100) / 100);
                                                }
                                            }}
                                        />
                                    </ValueBox>
                                    <Flex width={1 / 2} pl={12} alignItems="center">
                                        <Body variant="small">
                                            {values.valueType === DatePricingModifierValueTypeEnum.Percentage
                                                ? // eslint-disable-next-line formatjs/no-literal-string-in-jsx
                                                  '%'
                                                : // eslint-disable-next-line formatjs/no-literal-string-in-jsx
                                                  '€'}
                                            &nbsp;
                                            {values.type === DatePricingModifierTypeEnum.Addition ? (
                                                <FormattedMessage defaultMessage="toeslag" />
                                            ) : (
                                                <FormattedMessage defaultMessage="korting" />
                                            )}
                                        </Body>
                                    </Flex>
                                </Flex>
                            </SidebarField>
                        </Box>
                    </>
                );
            }}
        </FormScreen>
    );
};

const ValueBox = styled(Box)`
    ${ErrorMessageContainer} {
        position: absolute;
    }
`;

export default PeriodRuleSidebar;
