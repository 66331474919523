import React from 'react';

export default function () {
    return (
        <svg width="60" height="42" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(2 2)" stroke="#008BB6" fill="none" fillRule="evenodd">
                <rect strokeWidth="3" y="21" width="16" height="17" rx="2" />
                <rect strokeWidth="3" x="20" y="21" width="16" height="17" rx="2" />
                <path d="M28 31.5l-2.5-3h5z" fill="#008BB6" strokeLinejoin="round" strokeWidth="2" />
                <rect strokeWidth="3" x="20" width="16" height="17" rx="2" />
                <rect strokeWidth="3" x="40" y="21" width="16" height="17" rx="2" />
                <path
                    d="M28 6.5l-2.5 3h5zM6 29.5L9 32v-5zM50 29.5L47 32v-5z"
                    fill="#008BB6"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
            </g>
        </svg>
    );
}
