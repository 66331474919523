import { NetworkStatus, useApolloClient, useLazyQuery } from '@apollo/client';
import { useDeviceSize } from '@oberoninternal/travelbase-ds/context/devicesize';
import addDays from 'date-fns/addDays';
import endOfDay from 'date-fns/endOfDay';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isWithinInterval from 'date-fns/isWithinInterval';
import startOfDay from 'date-fns/startOfDay';
import { DocumentNode } from 'graphql';
import mergeWith from 'lodash/mergeWith';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PricingQueryShape } from '../components/organisms/Pricing';
import dateFormat from '../constants/dateFormat';
import epoch from '../constants/epoch';
import { useSidebar } from '../context/sidebar';
import { UnitParams } from '../entities/UnitParams';
import { Scalars } from '../generated/graphql';
import { filterDuplicateFragments } from '../utils/filterDuplicateFragments';
import InfiniteLoader from 'react-window-infinite-loader';

interface PricesReducerState<Q> {
    data: Q | null;
    loadedPeriod: Interval | null;
    loadedPeriods: Interval[];
}

export interface PricingVariables {
    unitSlug: Scalars['String']['output'];
    start: Scalars['Date']['output'];
    end: Scalars['Date']['output'];
}

export const usePricingBag = <Q extends PricingQueryShape>(
    document: DocumentNode,
    mergeFn?: (prev: Q, next: Q) => Q
) => {
    const initialState: PricesReducerState<Q> = {
        data: null,
        loadedPeriod: null,
        loadedPeriods: [],
    };
    const deviceSize = useDeviceSize();
    const columnWidth = deviceSize === 'mobile' ? 200 : 128;
    const client = useApolloClient();
    const requestedPeriod = useRef<Interval>();
    const [state, setState] = useState(initialState);
    const infiniteLoaderRef = useRef<InfiniteLoader>(null);
    const { unitSlug } = useParams<UnitParams>();

    const [fetch, { fetchMore, data, loading, called, variables, networkStatus }] = useLazyQuery<Q, PricingVariables>(
        document,
        {
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-first',
        }
    );

    const sidebar = useSidebar();

    useEffect(() => {
        if (!data || networkStatus !== NetworkStatus.ready) {
            return;
        }

        // state must be updated all at once, to prevent multiple re-renders.
        // this is why we're 'caching' metadata (requestedPeriod, itemCount) in refs until the data changes.
        setState(({ loadedPeriod, loadedPeriods }) => ({
            data,
            loadedPeriod: loadedPeriod
                ? {
                      start: isBefore(requestedPeriod.current!.start, loadedPeriod.start)
                          ? requestedPeriod.current!.start
                          : loadedPeriod.start,
                      end: isAfter(requestedPeriod.current!.end, loadedPeriod.end)
                          ? requestedPeriod.current!.end
                          : loadedPeriod.end,
                  }
                : requestedPeriod.current!,
            loadedPeriods: loadedPeriods.concat(requestedPeriod.current ?? []),
        }));
    }, [data, networkStatus]);

    const loadMoreItems = useCallback(
        async (startIndex: number, endIndex: number) => {
            if (loading) {
                return;
            }

            const startDate = addDays(epoch, startIndex);
            const endDate = addDays(epoch, endIndex);

            requestedPeriod.current = { start: startDate, end: endDate };

            const vars: PricingVariables = {
                unitSlug,
                start: format(requestedPeriod.current.start, dateFormat),
                end: format(addDays(requestedPeriod.current.end, 1), dateFormat),
            };
            if (!called) {
                fetch({ variables: vars });
            } else {
                await fetchMore?.({
                    variables: vars,
                    updateQuery: (prevResult, { fetchMoreResult }): Q => {
                        const prevData = client.readQuery<Q>({ query: document, variables });
                        let previousResult = prevResult;
                        if (!previousResult) {
                            if (!prevData) {
                                return {
                                    rentalUnit: null,
                                } as Q;
                            }
                            previousResult = prevData;
                        }

                        if (!previousResult.rentalUnit) {
                            return {
                                rentalUnit: null,
                            } as Q;
                        }
                        if (mergeFn) {
                            return mergeFn(previousResult, fetchMoreResult as Q);
                        }

                        return {
                            rentalUnit: mergeWith(
                                {},
                                previousResult.rentalUnit,
                                fetchMoreResult?.rentalUnit,
                                filterDuplicateFragments
                            ),
                        } as Q;
                    },
                });
            }
        },
        [called, client, document, fetch, fetchMore, loading, mergeFn, unitSlug, variables]
    );

    const isItemLoaded = useCallback(
        (index: number) => {
            if (!state.loadedPeriods.length) {
                return false;
            }
            const indexDate = addDays(epoch, index);
            return state.loadedPeriods.some(interval => isWithinInterval(indexDate, interval));
        },
        [state.loadedPeriods]
    );

    const getDayEvents = useCallback(
        /**
         * return all events that occur on the given day inclusive or exclusive (enddate)
         */
        (date: Date, exclusive = false) => {
            const events = [...(data?.rentalUnit?.allotmentLockouts ?? []), ...(data?.rentalUnit?.bookings ?? [])];
            return events.filter(event =>
                isWithinInterval(date, {
                    start: startOfDay(new Date(event.startDate)),
                    end: addDays(endOfDay(new Date(event.endDate)), exclusive ? -1 : 0),
                })
            );
        },
        [data]
    );

    /** resets the virtualizer cache and the loadedPeriods state  */
    const invalidate = useCallback((loaded?: Interval) => {
        setState(current => ({ ...current, loadedPeriods: loaded ? [loaded] : [] }));
        infiniteLoaderRef.current?.resetloadMoreItemsCache();
    }, []);

    return {
        ...state,
        variables,
        loadMoreItems,
        sidebar,
        document,
        networkStatus,
        isItemLoaded,
        columnWidth,
        getDayEvents,
        invalidate,
        infiniteLoaderRef,
    };
};

export type PricingBag = ReturnType<typeof usePricingBag>;
