import { Box, Flex } from '@rebass/grid';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { NotFoundError } from '../../../../entities/NotFoundError';
import { UnitParams } from '../../../../entities/UnitParams';
import {
    PriceConfigFragment,
    PricesRowVisibilityFragment,
    useEditDiscountMutation,
    usePriceConfigQuery,
} from '../../../../generated/graphql';
import { CheckboxField } from '@oberoninternal/travelbase-ds/components/form/Checkbox';
import FieldSet from '../../../atoms/FieldSet';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import FieldHeading from '../../../molecules/FieldHeading';
import LabelBox from '../../../molecules/LabelBox';
import FormScreen from '../../../organisms/FormScreen';
import { discountValidation } from './discountValidation';
import Loading from '../../../organisms/Loading';
import { visibilityKeys } from '../../../../utils/getRowVisibility';
import FieldSetHint from '../../../atoms/FieldSetHint';
import { StepperField } from '@oberoninternal/travelbase-ds/components/form/Stepper';
import { FormattedMessage, useIntl } from 'react-intl';

export const fragment = gql`
    fragment PriceConfig on RentalUnit {
        id
        lastminuteDiscountEnabled
        earlyBookDiscountEnabled
        lastminuteDiscount1Week
        lastminuteDiscount2Week
        lastminuteDiscount3Week
        lastminuteDiscount4Week
        earlyBookDiscount6Months
        earlyBookDiscount12Months
        flexibleArrival
        flexibleDeparture
        flexCancellationDays
        ...PricesRowVisibility
    }
`;

export const query = gql`
    query PriceConfig($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            ...PriceConfig
        }
    }
`;

export const editDiscountMutation = gql`
    mutation EditDiscount($input: EditDiscountForUnitInput!) {
        editDiscounts(input: $input) {
            rentalUnit {
                ...PriceConfig
            }
        }
    }
`;

const Discount: FC<React.PropsWithChildren<{ name: string; label: string; enabled: boolean }>> = ({
    name,
    label,
    enabled,
}) => (
    <FieldSet>
        <LabelBox>
            <Label htmlFor={name}>{label}</Label>
        </LabelBox>
        <Flex width="20rem">
            {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
            <TextInputField disabled={!enabled} type="number" id={name} placeholder="0.0" name={name} min={0} />
            <Box pl={4} mt={3}>
                <FormattedMessage defaultMessage="%" />
            </Box>
        </Flex>
    </FieldSet>
);

const PriceConfig: FC<React.PropsWithChildren<UnitParams>> = ({ unitSlug }) => {
    const { data, loading } = usePriceConfigQuery({ variables: { unitSlug } });
    const [mutate] = useEditDiscountMutation();
    const { formatMessage } = useIntl();
    if (loading) {
        return <Loading />;
    }

    if (!data || !data.rentalUnit) {
        throw new NotFoundError();
    }

    const { rentalUnit } = data;

    const initialValues: Required<Omit<PriceConfigFragment, (typeof visibilityKeys)[0]>> & {
        flexibleCancellationEnabled: boolean;
    } = Object.assign(
        {
            flexibleCancellationEnabled: rentalUnit.flexCancellationDays !== null,
            flexCancellationDays: rentalUnit.flexCancellationDays ?? 14,
        },
        ...Object.entries(rentalUnit)
            .filter(
                ([key]) =>
                    key !== '__typename' &&
                    !visibilityKeys.includes(key as keyof PricesRowVisibilityFragment) &&
                    key !== 'flexCancellationDays'
            )
            .map(([key, value]) => ({
                [key]: typeof value !== 'boolean' ? value ?? 0 : value,
            }))
    );

    return (
        <FormScreen
            validationSchema={discountValidation}
            handleSubmit={async ({ id, ...values }) => {
                const { flexibleCancellationEnabled, flexCancellationDays, ...rest } = values;
                await mutate({
                    variables: {
                        input: {
                            rentalUnitId: id,
                            flexCancellationDays: flexibleCancellationEnabled ? flexCancellationDays : null,
                            ...rest,
                        },
                    },
                });
            }}
            initialValues={initialValues}
        >
            {({ values }) => (
                <>
                    <Title>
                        <FormattedMessage defaultMessage="Kortingen en prijsregels" />
                    </Title>
                    <p>
                        <FormattedMessage defaultMessage="Hier kun je ervoor kiezen om je accommodatie met een lastminute- of vroegboekkorting automatisch te voorzien van een prijsaanbieding. Bij Prijsregels zie je welke opties er voor jouw accommodatie beschikbaar zijn." />
                    </p>

                    <FieldHeading title={formatMessage({ defaultMessage: 'Lastminutekorting' })}>
                        <FormattedMessage defaultMessage="Als je dit activeert dan berekent Travelbase automatisch korting als de accommodatie op korte termijn nog beschikbaar is. Je kunt voor de prijzen in de komende 4 weken per week een procentuele korting toekennen. Deze wordt automatisch berekend. Travelbase combineert geen kortingen, de laagste prijs wordt aan de gast getoond." />
                    </FieldHeading>
                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="lastminuteDiscountEnabled">
                                <FormattedMessage defaultMessage="Actief" />
                            </Label>
                        </LabelBox>
                        <Box width={1} my="auto">
                            <CheckboxField name="lastminuteDiscountEnabled" id="lastminuteDiscountEnabled" />
                        </Box>
                    </FieldSet>

                    <Discount
                        name="lastminuteDiscount1Week"
                        label={formatMessage({ defaultMessage: 'Aankomst binnen 0 tot 1 week' })}
                        enabled={values.lastminuteDiscountEnabled}
                    />
                    <Discount
                        name="lastminuteDiscount2Week"
                        label={formatMessage({ defaultMessage: 'Aankomst binnen 1 tot 2 weken' })}
                        enabled={values.lastminuteDiscountEnabled}
                    />
                    <Discount
                        name="lastminuteDiscount3Week"
                        label={formatMessage({ defaultMessage: 'Aankomst binnen 2 tot 3 weken' })}
                        enabled={values.lastminuteDiscountEnabled}
                    />
                    <Discount
                        name="lastminuteDiscount4Week"
                        label={formatMessage({ defaultMessage: 'Aankomst binnen 3 tot 4 weken' })}
                        enabled={values.lastminuteDiscountEnabled}
                    />

                    <FieldHeading title={formatMessage({ defaultMessage: 'Vroegboekkorting' })}>
                        <FormattedMessage
                            defaultMessage="Als je dit activeert berekent Travelbase automatisch een korting voor boekingen die worden
                        gemaakt met een aankomst van 6 tot 12 maanden van te voren of meer dan 12 maanden van te voren.
                        U bepaalt zelf het kortingspercentage. Travelbase combineert geen kortingen, de laagste prijs
                        wordt aan de gast getoond."
                        />
                    </FieldHeading>
                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="earlyBookDiscountEnabled">
                                <FormattedMessage defaultMessage="Actief" />
                            </Label>
                        </LabelBox>
                        <Box my="auto" width={1}>
                            <CheckboxField name="earlyBookDiscountEnabled" id="earlyBookDiscountEnabled" />
                        </Box>
                    </FieldSet>

                    <Discount
                        name="earlyBookDiscount6Months"
                        label={formatMessage({ defaultMessage: 'Aankomst over 6 tot 12 maanden' })}
                        enabled={values.earlyBookDiscountEnabled}
                    />

                    <Discount
                        name="earlyBookDiscount12Months"
                        label={formatMessage({ defaultMessage: 'Aankomst over 12+ maanden' })}
                        enabled={values.earlyBookDiscountEnabled}
                    />

                    <FieldHeading title={formatMessage({ defaultMessage: 'Flexibel annuleren' })}>
                        <FormattedMessage
                            defaultMessage="Door een flexibel beleid te voeren ten aanzien van annuleren vergroot je de kans op boekingen.
                        Je kan hier aangeven tot hoeveel dagen voor aankomst de gast zijn boeking nog gratis mag
                        annuleren."
                        />
                    </FieldHeading>
                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="flexibleCancellationEnabled">
                                <FormattedMessage defaultMessage="Actief" />
                            </Label>
                        </LabelBox>
                        <Box width={1} my="auto">
                            <CheckboxField name="flexibleCancellationEnabled" id="flexibleCancellationEnabled" />
                        </Box>
                    </FieldSet>
                    {values.flexibleCancellationEnabled && (
                        <FieldSet>
                            <LabelBox>
                                <Label>
                                    <FormattedMessage defaultMessage="Aantal dagen" />
                                </Label>
                                <FieldSetHint>
                                    <FormattedMessage
                                        defaultMessage="Dit is het aantal dagen voor aankomst dat de gast gratis mag annuleren. Minimaal 0
                                    (de dag van aankomst) en maximaal 30."
                                    />
                                </FieldSetHint>
                            </LabelBox>
                            <StepperField
                                disabled={!values.flexibleCancellationEnabled}
                                minimum={0}
                                editable
                                value={values.flexCancellationDays ?? 14}
                                name="flexCancellationDays"
                                maximum={30}
                            />
                        </FieldSet>
                    )}
                    <FieldHeading title={formatMessage({ defaultMessage: 'Flexibel boeken' })}>
                        <FormattedMessage
                            defaultMessage="Met het aanvinken van onderstaande opties vergroot je de kans op boekingen en wordt je
                            accommodatie in meer zoekresultaten getoond. Bij het aanvinken van ‘Vertrek’ kan een gast
                            een nacht eerder vertrekken dan ingesteld staat, maar is er geen inkomstenverlies.
                            Bijvoorbeeld je biedt een weekend aan van vrijdag t/m maandag. Door het aanvinken van
                            'vertrek' kan een gast nu ook boeken van vrijdag t/m zondag en betaalt daarbij de
                            weekendprijs. Bij het aanvinken van ‘Aankomst’ kan een gast een nacht later aankomen dan
                            ingesteld staat, maar is er geen inkomstenverlies. Bijvoorbeeld je biedt een weekend van
                            vrijdag t/m maandag aan. Een gast kan nu ook boeken van zaterdag t/m maandag en betaalt
                            daarbij de weekendprijs."
                        />
                    </FieldHeading>
                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="flexibleDeparture">
                                <FormattedMessage defaultMessage="Vertrek" />
                            </Label>
                        </LabelBox>
                        <Box my="auto" width={1}>
                            <Flex>
                                <CheckboxField name="flexibleDeparture" id="flexibleDeparture" />

                                <Label htmlFor="flexibleDeparture">
                                    <FormattedMessage
                                        defaultMessage="Ik wil een grotere kans op bezetting zonder inkomstenverlies en accepteer dat de
                                    gast een dag eerder kan vertrekken."
                                    />
                                </Label>
                            </Flex>
                        </Box>
                    </FieldSet>

                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="flexibleArrival">
                                <FormattedMessage defaultMessage="Aankomst" />
                            </Label>
                        </LabelBox>
                        <Box my="auto" width={1}>
                            <Flex>
                                <CheckboxField name="flexibleArrival" id="flexibleArrival" />

                                <Label htmlFor="flexibleArrival">
                                    <FormattedMessage
                                        defaultMessage="Ik wil een grotere kans op bezetting zonder inkomstenverlies en accepteer dat de
                                    gast een dag later kan aankomen."
                                    />
                                </Label>
                            </Flex>
                        </Box>
                    </FieldSet>
                </>
            )}
        </FormScreen>
    );
};

export default PriceConfig;
