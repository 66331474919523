import { NetworkStatus } from '@apollo/client';
import gql from 'graphql-tag';
import React, { FC, useCallback } from 'react';
import { UpcomingBookingsQuery, useUpcomingBookingsQuery } from '../../../generated/graphql';
import BookingsList from '../../molecules/BookingsList';
import { STEP } from '../Bookings';
import { useParams } from 'react-router-dom';
import { PartnerParams } from '../../../entities/PartnerParams';

export const query = gql`
    query UpcomingBookings($partnerId: ID!, $after: String, $first: Int, $before: String, $last: Int) {
        partner(id: $partnerId) {
            id
            upcomingBookings(first: $first, last: $last, after: $after, before: $before) {
                ...BookingConnection
            }
        }
    }
`;

const Upcoming: FC<React.PropsWithChildren<unknown>> = () => {
    const { partnerId } = useParams<PartnerParams>();

    const { fetchMore, loading, data, networkStatus } = useUpcomingBookingsQuery({
        variables: { first: STEP * 3, partnerId },
        notifyOnNetworkStatusChange: true,
    });

    const bookings = data?.partner?.upcomingBookings;

    const hasNextPage = !!data?.partner?.upcomingBookings?.pageInfo.hasNextPage;

    const isNextPageLoading = networkStatus === NetworkStatus.fetchMore;
    const onLoadMore = useCallback(async () => {
        if (loading) {
            return;
        }

        await fetchMore({
            variables: { after: data?.partner?.upcomingBookings?.pageInfo.endCursor },
            updateQuery: (prev, { fetchMoreResult }): UpcomingBookingsQuery => {
                if (!fetchMoreResult || !prev.partner?.upcomingBookings?.edges) {
                    return prev;
                }
                const newEdges = fetchMoreResult?.partner?.upcomingBookings?.edges;
                const pageInfo = fetchMoreResult?.partner?.upcomingBookings?.pageInfo;
                return {
                    ...prev,
                    partner: {
                        ...prev.partner,
                        upcomingBookings: prev.partner?.upcomingBookings
                            ? {
                                  ...prev.partner.upcomingBookings,
                                  edges: [...prev.partner.upcomingBookings.edges, ...(newEdges ?? [])],
                                  pageInfo: pageInfo ?? prev.partner.upcomingBookings.pageInfo,
                              }
                            : null,
                    },
                };
            },
        });
    }, [loading, data, fetchMore]);

    return (
        <BookingsList
            noResultsText="Geen aankomende boekingen"
            isNextPageLoading={isNextPageLoading}
            isLoading={loading}
            hasNextPage={hasNextPage}
            entries={bookings}
            onLoadMore={onLoadMore}
        />
    );
};

export default Upcoming;
