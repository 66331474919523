import { Box } from '@rebass/grid';
import React, { ComponentPropsWithRef, FC, Ref, forwardRef } from 'react';
import styled from 'styled-components/macro';

const Print = (props: ComponentPropsWithRef<'div'>, ref: Ref<HTMLDivElement>) => (
    <div style={{ display: 'none' }}>
        <Wrapper {...props} ref={ref} />
    </div>
);

export default forwardRef(Print);

export const PrintRow: FC<React.PropsWithChildren<{ label: string }>> = ({ label, children }) => (
    <Box>
        <h5 style={{ margin: '0.8rem 0 0 0' }}>{label}</h5>
        {children}
    </Box>
);

const Wrapper = styled.div`
    @media print {
        @page {
            margin: 25mm;
            size: A4; /* DIN A4 standard, Europe */
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 500;
    }

    table {
        border-collapse: separate;
        border-spacing: 0 0.8rem;

        width: 100%;
        td {
            padding: 0;
        }
        .seperator-row {
            td {
                border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[30]};
                padding-top: 0.8rem;
            }
        }
    }
`;
