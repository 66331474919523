import { Box, BoxProps } from '@rebass/grid';
import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import isToday from 'date-fns/isToday';
import startOfWeek from 'date-fns/startOfWeek';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { getDateOpts } from '../../constants/dateOpts';
import { getFontCss } from '../../constants/theme';
import TextButton, { Props as TextButtonProps } from '../action/TextButton';
import Tooltip from '../feedback/Tooltip';
import Body from '../primitive/Body';
import { Label } from '../primitive/Label';
import { useIntl } from 'react-intl';

interface Props extends BoxProps {
    unbookable?: boolean;
    date: Date;
    disabled?: boolean;
    onInfoClick?: () => void;
    tooltipText?: string;
}

const DayHeader: FC<React.PropsWithChildren<Props>> = ({ ref, children, tooltipText, ...props }) => {
    const { locale } = useIntl();
    const dateOpts = getDateOpts(locale as 'nl' | 'de' | 'en');
    const sameDay = isSameDay(startOfWeek(props.date, dateOpts), props.date);
    const monthDay = format(props.date, 'd MMM', dateOpts);
    return (
        <Container {...props}>
            <WeekNumber variant="small">{sameDay && `WEEK ${format(props.date, ' w', dateOpts)}`}</WeekNumber>
            <Weekday sameDay={sameDay}>{format(props.date, 'EEEE', dateOpts)}</Weekday>
            {!props.unbookable && !props.disabled && tooltipText ? (
                <Tooltip label={<Body variant="small">{tooltipText}</Body>}>
                    <Monthday
                        onClick={e => {
                            if (props.onInfoClick) {
                                e.preventDefault();
                                props.onInfoClick();
                            }
                        }}
                    >
                        {monthDay}
                    </Monthday>
                </Tooltip>
            ) : (
                <Body>
                    <span>{monthDay}</span>
                </Body>
            )}
            {children && <div>{children}</div>}
        </Container>
    );
};

export const Monthday = styled(TextButton)<TextButtonProps>`
    position: relative;
    height: 2.4rem;
    ${({ theme }) => getFontCss(theme.fontSizes.body.regular)};
`;

const WeekNumber = styled(Label).attrs({ variant: 'small' })`
    color: ${({ theme }) => theme.colors.neutral['50']};
    margin-bottom: 0;
    font-weight: 400;
    margin-top: 0.8rem;
`;

const Weekday = styled(Label).attrs({ variant: 'small' })<{ sameDay?: boolean }>`
    color: ${({ theme }) => theme.colors.neutral['50']};
    margin-top: ${({ sameDay }) => (sameDay ? '0.8rem' : '2.4rem')};
`;

const unbookableCss = css`
    background: ${({ theme }) => theme.colors.neutral['10']};

    ${Weekday} {
        color: ${({ theme }) => theme.colors.neutral['30']};
    }

    ${Monthday} {
        color: ${({ theme }) => theme.colors.neutral['30']};

        &:after {
            content: '';
            background: ${({ theme }) => theme.colors.neutral['30']};
            height: 2px;
            top: 50%;
            width: 34px;
            left: calc(50% - 17px);
            right: calc(50% - 17px);
            position: absolute;
            transform: rotate(-45deg);
        }
    }
`;

const disabledCss = css`
    background: ${({ theme }) => theme.colors.neutral['10']};

    ${Weekday} {
        color: ${({ theme }) => theme.colors.neutral['30']};
    }

    ${Monthday} {
        color: ${({ theme }) => theme.colors.neutral['30']};
    }
`;

const todayCss = css`
    :after {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        background: ${({ theme }) => theme.colors.primary['30']};
        height: 4px;
        content: '';
    }
`;

const Container = styled(Box)<Props>`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex-direction: column;

    ${({ date }) => isToday(date) && todayCss}
    ${({ unbookable }) => unbookable && unbookableCss}
    ${({ disabled }) => disabled && disabledCss}

    > div {
        margin-bottom: 2.5rem;
    }
`;

export default DayHeader;
