import dateTextFormat from '@oberoninternal/travelbase-ds/constants/dateTextFormat';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import isSameYear from 'date-fns/isSameYear';
import isWithinInterval from 'date-fns/isWithinInterval';
import gql from 'graphql-tag';
import simplur from 'simplur';
import { getDateOpts } from '../constants/dateOpts';
import { TicketStatusEnum, TicketStatusFragment } from '../generated/graphql';

const dateOpts = getDateOpts('nl');

export const ticketStatuses: Record<TicketStatusEnum, string> = {
    ACCEPTED: 'Geaccepteerd',
    WAIVED: 'Vervallen',
    INIT: 'Initialiseren',
};

// fragment isn't used in any query but forms the shape of the util parameter
export const fragment = gql`
    fragment TicketStatus on Ticket {
        status
        timeslot {
            id
            startDateTime
            endDateTime
        }
    }
`;

const getTicketStatus = ({ timeslot, status }: TicketStatusFragment) => {
    if (status !== TicketStatusEnum.Accepted) {
        return ticketStatuses[status];
    }
    const startDateTime = new Date(timeslot.startDateTime);
    const endDateTime = new Date(timeslot.endDateTime);
    const now = new Date();
    if (isBefore(endDateTime, now)) {
        return 'Afgelopen';
    }

    if (isWithinInterval(now, { start: startDateTime, end: endDateTime })) {
        return 'Bezig';
    }

    if (differenceInCalendarDays(startDateTime, now) <= 7) {
        return `Begint over ${simplur`${differenceInCalendarDays(startDateTime, now)} dag[|en]`}`;
    }
    return `Begint op ${format(
        startDateTime,
        !isSameYear(startDateTime, new Date()) ? `EEEEEE ${dateTextFormat}` : 'EEEEEE d MMM',
        dateOpts
    )}`;
};

export default getTicketStatus;
