import { Box } from '@rebass/grid';
import { Form, Formik, FormikProps } from 'formik';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import Logo from '@oberoninternal/travelbase-ds/components/figure/LogoText';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import { useParams, useHistory } from 'react-router-dom';
import { usePasswordResetMutation } from '../../generated/graphql';
import gql from 'graphql-tag';
import { ApolloError } from '@apollo/client';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

export const mutation = gql`
    mutation passwordReset($token: String!, $newPassword: String!) {
        resetPassword(input: { token: $token, newPassword: $newPassword }) {
            email
        }
    }
`;

const resetPasswordSchema = (intl: IntlShape) =>
    Yup.object().shape({
        password: Yup.string().required(intl.formatMessage({ defaultMessage: 'Wachtwoord is verplicht' })),
        passwordConfirm: Yup.string()
            .oneOf(
                [Yup.ref('password'), null],
                intl.formatMessage({ defaultMessage: 'Wachtwoorden komen niet overeen.' })
            )
            .required(intl.formatMessage({ defaultMessage: 'Wachtwoord is verplicht' })),
    });

interface Values {
    password: string;
    passwordConfirm: string;
}

interface Token {
    token: string;
}

const PasswordReset: FC<React.PropsWithChildren<unknown>> = () => {
    const history = useHistory();
    const { token } = useParams<Token>();
    const intl = useIntl();

    const [mutate, { error }] = usePasswordResetMutation({
        context: {
            reset: true,
            ignoreError: true,
        },
        onCompleted() {
            history.push({
                pathname: '/login',
                state: {
                    successMessage: intl.formatMessage({
                        defaultMessage: 'Wachtwoord resetten is gelukt. Je kan nu inloggen met je nieuwe wachtwoord.',
                    }),
                },
            });
        },
    });

    return (
        <Container>
            <Box width={[7 / 8, null, '48rem']} m="auto">
                <Box mb={2}>
                    <Logo width={250} />
                </Box>
                <>
                    <p>
                        <FormattedMessage defaultMessage="Vul hier jouw nieuwe wachtwoord in." />
                    </p>
                    <Formik<Values>
                        validationSchema={resetPasswordSchema(intl)}
                        initialValues={{ password: '', passwordConfirm: '' }}
                        onSubmit={async ({ password }, { setSubmitting }) => {
                            try {
                                await mutate({
                                    variables: {
                                        token,
                                        newPassword: password,
                                    },
                                });
                            } finally {
                                setSubmitting(false);
                            }
                        }}
                    >
                        {bag => <ResetForm {...bag} mutationError={error} />}
                    </Formik>
                </>
            </Box>
        </Container>
    );
};

const ResetForm: FC<React.PropsWithChildren<FormikProps<Values> & { mutationError?: ApolloError }>> = ({
    isSubmitting,
    setFieldError,
    mutationError,
    errors,
}) => {
    const { formatMessage } = useIntl();
    useEffect(() => {
        if (!errors.passwordConfirm && mutationError) {
            const graphqlError = mutationError?.graphQLErrors[0];

            setFieldError('passwordConfirm', graphqlError ? graphqlError.message : mutationError.message);
        }
    }, [mutationError, errors.passwordConfirm, setFieldError]);

    return (
        <Form>
            <Box mb={4}>
                <TextInputField
                    name="password"
                    type="password"
                    placeholder={formatMessage({ defaultMessage: 'Nieuw wachtwoord' })}
                    disabled={isSubmitting}
                />
            </Box>
            <Box mb={4}>
                <TextInputField
                    name="passwordConfirm"
                    type="password"
                    placeholder={formatMessage({ defaultMessage: 'Herhaal nieuw wachtwoord' })}
                    disabled={isSubmitting}
                />
            </Box>
            <ResetPasswordButton submitting={isSubmitting} disabled={isSubmitting} type="submit">
                <FormattedMessage defaultMessage="Wachtwoord resetten" />
            </ResetPasswordButton>
        </Form>
    );
};

export default PasswordReset;

const Container = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
`;

const ResetPasswordButton = styled(Button)`
    margin-top: 2rem;
    width: 100%;
`;
