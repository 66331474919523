import React, { SVGAttributes } from 'react';

export default function (props: SVGAttributes<SVGElement>) {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd">
                <path d="M5 0v10M0 5h10" />
            </g>
        </svg>
    );
}
