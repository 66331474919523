import { SwitchField } from '@oberoninternal/travelbase-ds/components/form/Switch';
import { MenuStateProps } from '@oberoninternal/travelbase-ds/hooks/useMenuState';
import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping';
import React, { FC, useMemo } from 'react';
import * as Yup from 'yup';
import ratesValidationSchema from '../../constants/ratesValidationSchema';
import { CompanyRateGroupFragment, UpsertCompanyRateGroupInput } from '../../generated/graphql';
import { formatDuration } from '../../utils/formatDuration';
import getInitialRates from '../../utils/getInitialRates';
import parseDate from '@oberoninternal/travelbase-ds/utils/parseDate';

import { SidebarSeperator } from '../atoms/Seperator';
import SidebarField from '../atoms/SidebarField';
import SidebarIntro from '../atoms/SidebarIntro';
import RangePickerInputField from './RangePickerInputField';
import RatesFieldArray from './RatesFieldArray';
import RatesFormScreen from './RatesFormScreen';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props extends MenuStateProps {
    handleUpsert: (values: Omit<UpsertCompanyRateGroupInput, 'companyId'>) => Promise<void>;
    rateGroups: CompanyRateGroupFragment[];
    selectedRateGroup?: CompanyRateGroupFragment;
}

const CompanyRatesSidebar: FC<React.PropsWithChildren<Props>> = ({
    handleUpsert,
    selectedRateGroup,
    rateGroups,
    ...props
}) => {
    const intl = useIntl();
    const { formatMessage } = intl;
    const validationSchema = useMemo(
        () =>
            Yup.object().shape({
                startDate: Yup.string().required(formatMessage({ defaultMessage: 'Vul een datum in' })),
                endDate: Yup.string().required(formatMessage({ defaultMessage: 'Vul een datum in' })),
                rates: ratesValidationSchema(intl),
            }),
        [formatMessage, intl]
    );
    const { id, rates, startDate, endDate, canBuyTickets } = selectedRateGroup ?? {};

    const initialValues = {
        rateGroupId: id,
        rates: getInitialRates(rates),
        startDate: startDate ?? '',
        endDate: endDate ?? '',
        canBuyTickets: !!canBuyTickets,
    };

    return (
        <RatesFormScreen
            validationSchema={validationSchema}
            initialValues={initialValues}
            menuState={props}
            handleSubmit={handleUpsert}
            validate={values => {
                for (const rateGroup of rateGroups) {
                    // don't compare the period with itself
                    if (values.rateGroupId === rateGroup.id || !values.startDate || !values.endDate) {
                        continue;
                    }

                    if (
                        areIntervalsOverlapping(
                            { start: new Date(values.startDate), end: new Date(values.endDate) },
                            { start: new Date(rateGroup.startDate), end: new Date(rateGroup.endDate) },
                            { inclusive: true }
                        )
                    ) {
                        const durationText = formatDuration(
                            parseDate(rateGroup.startDate),
                            parseDate(rateGroup.endDate)
                        );
                        return { startDate: `Deze periode overlapt met de periode van ${durationText}` };
                    }
                }
                return {};
            }}
        >
            {() => (
                <>
                    <SidebarIntro
                        title={
                            id
                                ? formatMessage({ defaultMessage: 'Aanpassen' })
                                : formatMessage({ defaultMessage: 'Toevoegen' })
                        }
                    >
                        <FormattedMessage defaultMessage="Tarieven" />
                    </SidebarIntro>
                    &nbsp;
                    <SidebarField label={formatMessage({ defaultMessage: 'Periode' })}>
                        <RangePickerInputField fromFieldName="startDate" size="small" />
                    </SidebarField>
                    &nbsp;
                    <SidebarField label={formatMessage({ defaultMessage: 'Online ticketverkoop' })} id="canBuyTickets">
                        <SwitchField name="canBuyTickets" id="canBuyTickets" />
                    </SidebarField>
                    <SidebarSeperator />
                    <RatesFieldArray />
                </>
            )}
        </RatesFormScreen>
    );
};

export default CompanyRatesSidebar;
