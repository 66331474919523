import SubNav from '@oberoninternal/travelbase-ds/components/layout/SubNav';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import React, { FC } from 'react';
import { generatePath, Route, useRouteMatch } from 'react-router-dom';
import { PartnerParams } from '../../entities/PartnerParams';
import { ticketRoutes } from '../../utils/routes';
import ContentWrapper from '../atoms/ContentWrapper';
import NotFound from './NotFound';
import NewTickets from './tickets/New';
import AllTickets from './tickets/All';
import { FormattedMessage, useIntl } from 'react-intl';
import { LocalizedSwitch } from '../atoms/LocalizedSwitch';

const Tickets: FC<React.PropsWithChildren<unknown>> = () => {
    const match = useRouteMatch<PartnerParams>();
    const { formatMessage } = useIntl();
    return (
        <ContentWrapper>
            <Pagehead title={formatMessage({ defaultMessage: 'Tickets' })}>
                <FormattedMessage defaultMessage="Bekijk hier de tickets die besteld zijn via Travelbase." />
            </Pagehead>
            <SubNav
                links={ticketRoutes.map(({ title, source, exact }) => ({
                    exact,
                    to: generatePath(source ? `${match.path}/${source}` : match.path, {
                        partnerId: match.params.partnerId,
                    }),
                    children: formatMessage(title),
                }))}
            />
            <LocalizedSwitch>
                <Route path={`${match.path}/all`} exact component={AllTickets} />
                <Route path={`${match.path}`} exact component={NewTickets} />
                <Route component={NotFound} />
            </LocalizedSwitch>
        </ContentWrapper>
    );
};

export default Tickets;
