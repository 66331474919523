import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import Toast from '@oberoninternal/travelbase-ds/components/feedback/Toast';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { toast } from 'react-toastify';
import { useSidebar } from '../../context/sidebar';
import {
    SpecialParticipationFragment,
    SpecialParticipationsQueryVariables,
    useEditSpecialParticipationOptInMutation,
    SpecialParticipationsQuery,
    SpecialParticipationsDocument,
} from '../../generated/graphql';
import { formatDuration } from '../../utils/formatDuration';
import ContentWrapper from '../atoms/ContentWrapper';
import { SidebarSeperator } from '../atoms/Seperator';
import SidebarField from '../atoms/SidebarField';
import SidebarIntro from '../atoms/SidebarIntro';
import { FormActionsContainer } from '@oberoninternal/travelbase-ds/components/form/FormActions';
import { useVirtualizerScrollData } from '../../context/virtualizerScrollData';
import addDays from 'date-fns/addDays';
import epoch from '../../constants/epoch';
import format from 'date-fns/format';
import dateFormat from '../../constants/dateFormat';
import { FormattedMessage, useIntl } from 'react-intl';

export interface SpecialSidebarData {
    type: 'SPECIAL';
    specialParticipation: SpecialParticipationFragment;
    variables?: SpecialParticipationsQueryVariables;
    setOptingForId: (val: string | null) => void;
    optingForId: null | string;
    invalidate: (period: Interval) => void;
}

export const mutation = gql`
    mutation EditSpecialParticipationOptIn($input: EditSpecialParticipationInput!, $end: Date!, $start: Date!) {
        editSpecialParticipationOptIn(input: $input) {
            specialParticipation {
                ...SpecialParticipation
            }
        }
    }
`;

const SpecialSidebar: FC<React.PropsWithChildren<{ data: SpecialSidebarData }>> = ({
    data: {
        specialParticipation: {
            id,
            optInAcceptedAt,
            optInRejectedAt,
            special: { name, description, partnerConditions, optInRequired, arrivalFrom, arrivalUntil },
        },
        variables,
        setOptingForId,
        invalidate,
    },
}) => {
    const [mutate, { loading }] = useEditSpecialParticipationOptInMutation();
    const accepted = optInAcceptedAt ? true : optInRejectedAt ? false : null;
    const [, dispatch] = useSidebar();
    const scrollData = useVirtualizerScrollData();

    const { formatMessage } = useIntl();
    return (
        <>
            <ContentWrapper variant="sidebar">
                <SidebarIntro title={formatDuration(new Date(arrivalFrom), new Date(arrivalUntil))}>
                    {name}
                </SidebarIntro>
                <SidebarSeperator />
                <SidebarField label={formatMessage({ defaultMessage: 'Omschrijving' })}>
                    <Body variant="small">{description}</Body>
                </SidebarField>
                <SidebarField label={formatMessage({ defaultMessage: 'Voorwaarden' })}>
                    <Body variant="small">{partnerConditions}</Body>
                </SidebarField>
            </ContentWrapper>
            {optInRequired && (
                <FormActionsContainer alwaysVisible variant="sidebar">
                    <Button
                        variant={accepted ? 'danger' : 'primary'}
                        type="button"
                        disabled={loading}
                        submitting={loading}
                        onClick={async () => {
                            if (!scrollData?.itemsRendered.current) {
                                return;
                            }
                            if (
                                !accepted ||
                                window.confirm(
                                    formatMessage({
                                        defaultMessage:
                                            'Weet je zeker dat je niet meer wilt deelnemen aan dit arrangement?',
                                    })
                                )
                            ) {
                                try {
                                    const startDate = addDays(
                                        epoch,
                                        scrollData.itemsRendered.current.visibleStartIndex
                                    );
                                    const endDate = addDays(epoch, scrollData.itemsRendered.current.visibleStopIndex);
                                    setOptingForId(id);
                                    await mutate({
                                        variables: {
                                            input: { optIn: !accepted, specialParticipationId: id },
                                            start: format(startDate, dateFormat),
                                            end: format(endDate, dateFormat),
                                        },
                                        update: (cache, result) => {
                                            const opts = { variables, query: SpecialParticipationsDocument };

                                            const prev = cache.readQuery<
                                                SpecialParticipationsQuery,
                                                SpecialParticipationsQueryVariables
                                            >(opts);

                                            if (!prev?.rentalUnit?.id) {
                                                return;
                                            }

                                            cache.writeQuery<
                                                SpecialParticipationsQuery,
                                                SpecialParticipationsQueryVariables
                                            >({
                                                ...opts,
                                                data: {
                                                    ...prev,
                                                    rentalUnit: {
                                                        ...prev.rentalUnit,
                                                        specialParticipations:
                                                            prev.rentalUnit.specialParticipations.map(participation => {
                                                                const { specialParticipation } =
                                                                    result.data?.editSpecialParticipationOptIn ?? {};

                                                                if (
                                                                    !specialParticipation ||
                                                                    participation.id !== specialParticipation.id
                                                                ) {
                                                                    return participation;
                                                                }

                                                                return specialParticipation;
                                                            }),
                                                    },
                                                },
                                            });
                                        },
                                    });
                                    invalidate({ start: startDate, end: endDate });
                                    dispatch({ type: 'close' });

                                    toast(
                                        <Toast
                                            variant="success"
                                            title={formatMessage({ defaultMessage: 'Opgeslagen' })}
                                        >
                                            <Body variant="small">
                                                {accepted ? (
                                                    <FormattedMessage defaultMessage="Je deelname is ongedaan gemaakt" />
                                                ) : (
                                                    <FormattedMessage defaultMessage="Je deelname is bevestigd" />
                                                )}
                                            </Body>
                                        </Toast>,
                                        {
                                            autoClose: 3000,
                                        }
                                    );
                                } catch (error) {
                                    // eslint-disable-next-line no-console
                                    console.error(error);
                                } finally {
                                    setOptingForId(null);
                                }
                            }
                        }}
                    >
                        {accepted ? (
                            <FormattedMessage defaultMessage="Arrangement verlaten" />
                        ) : (
                            <FormattedMessage defaultMessage="Deelnemen" />
                        )}
                    </Button>
                </FormActionsContainer>
            )}
        </>
    );
};

export default SpecialSidebar;
