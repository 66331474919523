import RoundButton from '@oberoninternal/travelbase-ds/components/action/RoundButton';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Cross from '@oberoninternal/travelbase-ds/components/figure/Cross';
import Min from '@oberoninternal/travelbase-ds/components/figure/Min';
import Plus from '@oberoninternal/travelbase-ds/components/figure/Plus';
import { Warning } from '@oberoninternal/travelbase-ds/components/figure/Warning';
import Checkbox from '@oberoninternal/travelbase-ds/components/form/Checkbox';
import SelectInput from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { getFontCss } from '@oberoninternal/travelbase-ds/constants/theme';
import useWeekdays from '@oberoninternal/travelbase-ds/hooks/useWeekdays';
import { Box, Flex } from '@rebass/grid';
import format from 'date-fns/format';
import { FieldArray, useField } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components/macro';
import { getDateOpts } from '../../constants/dateOpts';
import { Maybe, TimeslotByTimeInput } from '../../generated/graphql';
import useTimes from '../../hooks/useTimes';
import WizardContent from './WizardContent';

interface WeekdayItemProps {
    weekday: string;
    weekdayIndex: number;
}

const convertTimeToMinutes = (time: string) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
};

const TimeslotsWizardWeekday = ({ weekday, weekdayIndex }: WeekdayItemProps) => {
    const weekdaysForDisplay = useWeekdays();
    const [{ value }, , { setValue }] = useField<Maybe<Array<TimeslotByTimeInput>>>(weekday);
    const times = useTimes(5);
    const [open, setOpen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const { formatMessage, locale } = useIntl();
    const dateOpts = getDateOpts(locale as 'nl' | 'de' | 'en');
    useEffect(() => {
        if (!ref.current) {
            return;
        }

        ref.current.style.maxHeight = open ? `${ref.current.scrollHeight}px` : '0px';
    }, [open, value]);

    return (
        <StyledWeekdayItem>
            <Header curved={!open}>
                <Flex alignItems="center">
                    <Checkbox
                        id={weekday}
                        checked={!!value}
                        onChange={({ currentTarget: { checked } }) => {
                            const newValue = checked ? [] : null;
                            const askForConfirmation = newValue === null && !!value?.length;
                            if (
                                askForConfirmation &&
                                !window.confirm(
                                    formatMessage({
                                        defaultMessage:
                                            'Als je deze dag uitzet gaan de ingevulde tijdslots verloren. Wil je doorgaan?',
                                    })
                                )
                            ) {
                                return;
                            }

                            setOpen(checked);
                            setTimeout(() => setValue(newValue), !checked && open ? 200 : 0);
                        }}
                    />
                    <Label htmlFor={weekday}>{format(weekdaysForDisplay[weekdayIndex], 'EEEE', dateOpts)}</Label>
                </Flex>
                {value && (
                    <TextButton
                        title={formatMessage({
                            defaultMessage: 'minmaliseren',
                        })}
                        onClick={() => setOpen(!open)}
                    >
                        <span>
                            {open ? (
                                <FormattedMessage defaultMessage="Sluiten" />
                            ) : (
                                <FormattedMessage defaultMessage="Openen" />
                            )}
                        </span>

                        {open ? <Min /> : <Plus />}
                    </TextButton>
                )}
            </Header>
            {value && (
                <TimeslotContent ref={ref} open={open}>
                    <Box>
                        <WizardContent
                            label={formatMessage({ defaultMessage: 'Tijdslots' })}
                            hint={formatMessage({
                                defaultMessage:
                                    'Voor welke tijden wil je tijdslots instellen? Laat leeg indien je geen tijdslots of alle tijdslots voor deze dag wilt verwijderen.',
                            })}
                        >
                            <Box width={1}>
                                <FieldArray name={weekday}>
                                    {helpers => (
                                        <>
                                            {value.map((timeslot, timeslotIndex) => {
                                                const startInMinutes = convertTimeToMinutes(timeslot.startTime);
                                                const endInMinutes = convertTimeToMinutes(timeslot.endTime);

                                                const createFieldName = (name: string) =>
                                                    `${weekday}.${timeslotIndex}.${name}`;
                                                return (
                                                    <Box key={timeslotIndex} mb={4}>
                                                        <Flex>
                                                            <Box flex={1}>
                                                                <SelectInput
                                                                    hideChoices
                                                                    name={createFieldName('startTime')}
                                                                    placeholder={formatMessage({
                                                                        defaultMessage: 'Van',
                                                                    })}
                                                                    options={times}
                                                                    isSearchable
                                                                />
                                                            </Box>
                                                            <Box ml={4} flex={1}>
                                                                <SelectInput
                                                                    hideChoices
                                                                    name={createFieldName('endTime')}
                                                                    // placeholder="Tot"
                                                                    placeholder={formatMessage({
                                                                        defaultMessage: 'Tot',
                                                                    })}
                                                                    options={times}
                                                                    isSearchable
                                                                />
                                                            </Box>

                                                            <StyledRoundButton
                                                                variant="outline"
                                                                onClick={() => {
                                                                    if (
                                                                        window.confirm(
                                                                            formatMessage({
                                                                                defaultMessage:
                                                                                    'Weet je dit tijdslot wilt verwijderen?',
                                                                            })
                                                                        )
                                                                    ) {
                                                                        helpers.remove(timeslotIndex);
                                                                    }
                                                                }}
                                                            >
                                                                <Cross />
                                                            </StyledRoundButton>
                                                        </Flex>
                                                        {endInMinutes <= startInMinutes && (
                                                            <Flex mt={3}>
                                                                <Warning
                                                                    style={{
                                                                        flexShrink: 0,
                                                                        margin: '0.4rem 0.4rem 0 0',
                                                                    }}
                                                                />
                                                                <Body variant="small">
                                                                    <FormattedMessage defaultMessage="Let op: het tijdslot eindigt op de volgende dag" />
                                                                </Body>
                                                            </Flex>
                                                        )}
                                                    </Box>
                                                );
                                            })}

                                            <TextButton
                                                onClick={() => {
                                                    const newValue: TimeslotByTimeInput = {
                                                        startTime: '12:00',
                                                        endTime: '13:00',
                                                        allotment: 0,
                                                    };
                                                    helpers.push(newValue);
                                                }}
                                                style={{
                                                    display: 'inline-flex',
                                                    marginTop: '1.6rem',
                                                }}
                                                size="tiny"
                                            >
                                                <Plus />
                                                &nbsp;
                                                <span>
                                                    <FormattedMessage defaultMessage="Tijdslot toevoegen" />
                                                </span>
                                            </TextButton>
                                        </>
                                    )}
                                </FieldArray>
                            </Box>
                        </WizardContent>
                    </Box>
                </TimeslotContent>
            )}
        </StyledWeekdayItem>
    );
};

export default TimeslotsWizardWeekday;

const StyledRoundButton = styled(RoundButton)`
    margin-top: ${({ theme }) => theme.spacing['20_Tiny']};
    margin-left: ${({ theme }) => theme.spacing['30_Small']};

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        margin-left: ${({ theme }) => theme.spacing['60_Large']};
    }
`;

const Label = styled.label`
    ${({ theme }) => getFontCss(theme.fontSizes.body.large)};
`;

const borderStyles = css`
    --border-color: ${({ theme }) => theme.colors.neutral[20]};
    box-shadow: 0 0 0 1px var(--border-color), inset 0 0 0 1px var(--border-color);
`;

const StyledWeekdayItem = styled.div`
    header {
        padding: 3.2rem 8rem;
    }

    > div:last-of-type {
        border-radius: 0 0 0.7rem 0.7rem;
    }

    @media (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        header {
            padding: 1.6rem 2rem;
        }
    }

    label {
        text-transform: capitalize;
    }

    margin-bottom: 1.6rem;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        margin-bottom: 3.2rem;
    }

    :last-of-type {
        margin-bottom: 10rem;
    }
`;

const TimeslotContent = styled(Box)<{ open: boolean }>`
    max-height: 0;
    overflow: ${({ open }) => (open ? 'visible' : 'hidden')};
    /* overflow: hidden; */

    transition: all 0.2s ease-out;
    opacity: ${({ open }) => (open ? 1 : 0)};
    > div:not(:last-of-type) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.neutral['20']};
    }

    ${borderStyles}

    > div {
        padding: 3.2rem 8rem;
    }

    @media (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        > div {
            padding: 1.6rem 2rem;
        }
    }
`;

const Header = styled.header<{ curved: boolean }>`
    border-radius: 0.7rem 0.7rem ${({ curved }) => (curved ? '0.7rem 0.7rem' : '0 0')};
    height: 7.8rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${borderStyles}
`;
