import React from 'react';

export default function () {
    return (
        <svg width="59" height="53" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(1 1)" stroke="#008BB6" strokeWidth="3" fill="none" fillRule="evenodd">
                <circle cx="21" cy="21" r="20" />
                <path d="M21 41h35M21 1h35" strokeLinecap="round" />
                <path
                    fill="#FFF"
                    strokeLinejoin="round"
                    d="M20 22l7.113 26.08 6.322-5.532 6.323 7.113L43.71 46.5l-6.323-7.113 7.113-4.742z"
                />
            </g>
        </svg>
    );
}
