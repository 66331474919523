import React, { useEffect, useState, createContext, FC, useContext } from 'react';
import { useThemeContext } from '../hooks/useThemeContext';

export type DeviceSize = 'desktop' | 'laptop' | 'tablet' | 'mobile';

const createMaxWidth = (value: number) => `(max-width: ${value - 1}px)`;

const createMinWidth = (value: number) => `(min-width: ${value}px)`;

const DeviceSizeContext = createContext<DeviceSize | undefined | null>(undefined);

const DeviceSizeProvider: FC<React.PropsWithChildren<unknown>> = props => {
    const [deviceSize, setDeviceSize] = useState<DeviceSize | null>(null);
    const { mediaQueriesValues } = useThemeContext();

    useEffect(() => {
        const mqls = {
            laptop: window.matchMedia(
                `${createMinWidth(mediaQueriesValues.m)} and ${createMaxWidth(mediaQueriesValues.xl)}`
            ),
            tablet: window.matchMedia(
                `${createMinWidth(mediaQueriesValues.s)} and ${createMaxWidth(mediaQueriesValues.m)}`
            ),
            mobile: window.matchMedia(createMaxWidth(mediaQueriesValues.s)),
        };
        function checkDeviceSize() {
            if (mqls.mobile.matches) {
                setDeviceSize('mobile');
            } else if (mqls.tablet.matches) {
                setDeviceSize('tablet');
            } else if (mqls.laptop.matches) {
                setDeviceSize('laptop');
            } else {
                setDeviceSize('desktop');
            }
        }
        function mqEvent(this: MediaQueryList) {
            checkDeviceSize();
        }
        checkDeviceSize();
        Object.values(mqls).forEach(mq => {
            if (mq.addEventListener) {
                mq.addEventListener('change', mqEvent);
            } else {
                // older browsers
                mq.addListener(mqEvent);
            }
        });

        return () => {
            Object.values(mqls).forEach(mq => {
                if (mq.removeEventListener) {
                    mq.removeEventListener('change', mqEvent);
                } else {
                    // older browsers
                    mq.removeListener(mqEvent);
                }
            });
        };
    }, [mediaQueriesValues.l, mediaQueriesValues.m, mediaQueriesValues.s, mediaQueriesValues.xl]);

    return <DeviceSizeContext.Provider value={deviceSize} {...props} />;
};

export default DeviceSizeProvider;

export const useDeviceSize = () => {
    const deviceSize = useContext(DeviceSizeContext);

    if (deviceSize === undefined) {
        throw new Error('useDeviceSize must be used within a DeviceSizeProvider');
    }

    return deviceSize;
};
