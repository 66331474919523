import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import BigListWrapper from '../../atoms/BigListWrapper';
import { PartnerParams } from '../../../entities/PartnerParams';
import gql from 'graphql-tag';
import VisitorRegisterList from './VisitorRegisterList';
import { useMissingVisitorRegistrationsQuery } from '../../../generated/graphql';
import { useIntl } from 'react-intl';

export const query = gql`
    query MissingVisitorRegistrations($partnerId: ID!) {
        partner(id: $partnerId) {
            id
            missingVisitorRegistrations {
                ... on Booking {
                    id
                    rentalUnit {
                        ...UnitDetails
                    }
                    arrivalDate
                    departureDate
                    duration
                    amountAdults
                    status
                    amountBabies
                    amountChildren
                    amountPets
                    amountYouths
                    customer {
                        firstName
                        lastName
                        address {
                            city
                            countryCode
                        }
                    }
                }
                ... on AllotmentLockout {
                    id
                    rentalUnit {
                        ...UnitDetails
                    }

                    startDate
                    endDate
                }
            }
        }
    }
`;

const VisitorRegisterScreen: FC = () => {
    const { formatMessage } = useIntl();
    const { partnerId } = useParams<PartnerParams>();

    const { loading, data } = useMissingVisitorRegistrationsQuery({
        variables: {
            partnerId,
        },
        notifyOnNetworkStatusChange: true,
    });

    const missingVisitorRegistrations = data?.partner?.missingVisitorRegistrations;
    const [isSearching] = useState(false);
    return (
        <>
            <BigListWrapper>
                <VisitorRegisterList
                    isSearching={isSearching}
                    isLoading={loading}
                    entries={missingVisitorRegistrations}
                    noResultsText={formatMessage({
                        defaultMessage:
                            'Geen resultaten gevonden. Je kan zoeken op verhuureenheid, gast of reserveringsnummer',
                    })}
                />
            </BigListWrapper>
        </>
    );
};

export default VisitorRegisterScreen;
