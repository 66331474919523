import { ListOnItemsRenderedProps } from 'react-window';
import { useContext, createContext, RefObject } from 'react';

export interface ScrollData {
    columnWidth: number;
    scrollTo: (left: number | 'today') => void;
    scrollBy: (left: number) => void;
    itemsRendered: RefObject<ListOnItemsRenderedProps>;
}

const VirtualizerScrollContext = createContext<null | ScrollData>(null);

export const useVirtualizerScrollData = () => {
    const scrollData = useContext(VirtualizerScrollContext);
    return scrollData;
};

export default VirtualizerScrollContext;
