import React, { FC, Ref, forwardRef, ComponentPropsWithRef } from 'react';
import styled from 'styled-components/macro';

const NavigationWrapper: FC<React.PropsWithChildren<ComponentPropsWithRef<'div'>>> = forwardRef(
    ({ children, ...props }, ref: Ref<HTMLDivElement>) => (
        <Container {...props} ref={ref}>
            {children}
        </Container>
    )
);

export default NavigationWrapper;

const Container = styled.nav`
    z-index: ${({ theme }) => theme.zIndices.sticky - 1};
    position: fixed;
    top: ${({ theme }) => theme.heights.nav}px;
    width: 100%;
    display: flex;
    align-items: center;
    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        padding-left: 1.7rem;
        height: ${({ theme }) => theme.heights.unitNav}px;
    }
    background: ${({ theme }) => theme.colors.primary[100]};
    color: ${({ theme }) => theme.colors.neutral[0]};

    > * + * {
        ::before {
            content: '';
            position: absolute;
            z-index: 2;
            top: 20%;
            left: 0;
            width: 1px;
            height: 60%;
            background: rgba(238, 242, 243, 0.1);
        }
    }

    :last-child {
        align-self: flex-end;
    }
`;
