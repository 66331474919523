import React, { useEffect, useRef } from 'react';

const useIntervalClick = (onLongClick: (e: React.MouseEvent) => void, interval = 300) => {
    const handle = useRef<number>();

    useEffect(() => {
        const clearHandle = () => clearInterval(handle.current);

        document.addEventListener('mouseup', clearHandle);
        return () => {
            document.removeEventListener('mouseup', clearHandle);
        };
    }, []);

    return {
        onMouseDown(e: React.MouseEvent) {
            handle.current = window.setInterval(() => onLongClick(e), interval);
        },
        onClick(e: React.MouseEvent) {
            onLongClick(e);
        },
    };
};

export default useIntervalClick;
