import { MapLocationInputField } from '@oberoninternal/travelbase-ds/components/form/MapLocationInput';
import SelectInput from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import { Box, Flex } from '@rebass/grid';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import brandConfig from '../../../../constants/brandConfig';
import { ActivityParams } from '../../../../entities/ActivityParams';
import { NotFoundError } from '../../../../entities/NotFoundError';
import { useActivityLocationQuery, useEditActivityLocationMutation } from '../../../../generated/graphql';
import omitEqualValues from '../../../../utils/omitEqualValues';
import FieldSet from '../../../atoms/FieldSet';
import FieldSetHint from '../../../atoms/FieldSetHint';
import MapLocationDetermineButton from '../../../atoms/MapLocationDetermineButton';
import FieldHeading from '../../../molecules/FieldHeading';
import LabelBox from '../../../molecules/LabelBox';
import TranslationInput from '../../../molecules/TranslationInput';
import FormScreen from '../../../organisms/FormScreen';
import Loading from '../../../organisms/Loading';
import addressHasValues from '../../../../utils/addressHasValues';
import omit from 'lodash/omit';
import { Warning } from '@oberoninternal/travelbase-ds/components/figure/Warning';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { FormattedMessage, useIntl } from 'react-intl';

const activityLocationFragment = gql`
    fragment ActivityLocation on Activity {
        id
        slug
        coordinates {
            lat
            lon
        }
        descriptionLocationNL: descriptionLocation(locale: "nl")
        descriptionLocationDE: descriptionLocation(locale: "de")
        descriptionLocationEN: descriptionLocation(locale: "en")
        descriptionLocationPending
        locationAddress {
            ...Address
        }
        company {
            coordinates {
                lat
                lon
            }
        }
    }
`;

export const activityLocationQuery = gql`
    query ActivityLocation($activitySlug: String!) {
        activity(slug: $activitySlug) {
            ...ActivityLocation
        }
    }
    ${activityLocationFragment}
`;

export const editActivityLocationMutation = gql`
    mutation EditActivityLocation($input: EditActivityLocationInput!) {
        editActivityLocation(input: $input) {
            activity {
                ...ActivityLocation
            }
        }
    }
    ${activityLocationFragment}
`;

const { countryOptions } = brandConfig;

const ActivityLocation: FC<React.PropsWithChildren<ActivityParams>> = ({ activitySlug }) => {
    const slug = activitySlug ?? '';
    const { data, loading } = useActivityLocationQuery({ variables: { activitySlug: slug } });
    const [mutate] = useEditActivityLocationMutation();
    const { formatMessage } = useIntl();
    if (loading) {
        return <Loading />;
    }

    if (!data || !data.activity) {
        throw new NotFoundError();
    }

    const {
        activity: {
            id,
            company,
            coordinates,
            descriptionLocationNL,
            descriptionLocationDE,
            descriptionLocationEN,
            descriptionLocationPending,
            locationAddress,
        },
    } = data;

    const city = locationAddress?.city ?? '';
    const postalCode = locationAddress?.postalCode ?? '';
    const street = locationAddress?.street ?? '';
    const number = locationAddress?.number ?? '';
    const countryCode = locationAddress?.countryCode
        ? locationAddress.countryCode
        : countryOptions.length === 1
        ? countryOptions[0].value
        : '';

    const initialValues = {
        coordinates: {
            latitude: coordinates?.lat ?? company.coordinates?.lat ?? 0,
            longitude: coordinates?.lon ?? company.coordinates?.lon ?? 0,
        },
        description: descriptionLocationPending ?? descriptionLocationNL ?? '',
        address: { city, postalCode, street, number, countryCode },
    };

    return (
        <FormScreen
            handleSubmit={async values => {
                const {
                    coordinates: newCoordinates,
                    address,
                    ...rest
                } = omitEqualValues(values, initialValues, ['description']);
                await mutate({
                    variables: {
                        input: {
                            activityId: id,
                            coordinates: {
                                lat: newCoordinates.latitude,
                                lon: newCoordinates.longitude,
                            },
                            locationAddress: address,
                            ...rest,
                        },
                    },
                });
            }}
            initialValues={initialValues}
        >
            {({ values }) => (
                <>
                    <Pagehead title={formatMessage({ defaultMessage: 'Locatie' })}>
                        <FormattedMessage defaultMessage="Hier beheer je de locatie van de activiteit." />
                    </Pagehead>
                    <FieldHeading title={formatMessage({ defaultMessage: 'Adres' })}>
                        <FormattedMessage defaultMessage="Geef het adres van de activiteit op." />
                        {!addressHasValues(omit(values.address, 'countryCode')) && (
                            <Flex mt={3}>
                                <Warning style={{ margin: '0.4rem 0.8rem 0 0 ', flexShrink: 0 }} />
                                <Body variant="small" style={{ fontWeight: 500 }}>
                                    <FormattedMessage defaultMessage="Let op: je hebt geen adres ingevoerd. Zorg ervoor dat de beschrijving bij 'Hoe te bereiken' duidelijk en correct is ingevuld." />
                                </Body>
                            </Flex>
                        )}
                    </FieldHeading>
                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="street">
                                <FormattedMessage defaultMessage="Adres (straat en huisnummer)" />
                            </Label>
                        </LabelBox>
                        <Flex width={1} flexDirection={['column', 'row']}>
                            <Box flex={1} pr={[null, 4]}>
                                <TextInputField
                                    id="street"
                                    placeholder={formatMessage({ defaultMessage: 'Adres' })}
                                    name="address.street"
                                />
                            </Box>
                            <Box mt={[4, 0]} width={[1, '20rem']}>
                                <TextInputField
                                    maxLength={20}
                                    placeholder={formatMessage({ defaultMessage: 'Huisnummer' })}
                                    name="address.number"
                                />
                            </Box>
                        </Flex>
                    </FieldSet>

                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="postalCode">
                                <FormattedMessage defaultMessage="Postcode en plaats" />
                            </Label>
                        </LabelBox>
                        <Flex width={1} flexDirection={['column', 'row']}>
                            <Box width={[1, '20rem']}>
                                <TextInputField
                                    maxLength={20}
                                    id="postalCode"
                                    placeholder={formatMessage({ defaultMessage: 'Postcode' })}
                                    name="address.postalCode"
                                />
                            </Box>
                            <Box flex={1} mt={[4, 0]} pl={[null, 4]}>
                                <TextInputField
                                    id="city"
                                    placeholder={formatMessage({ defaultMessage: 'Plaats' })}
                                    name="address.city"
                                />
                            </Box>
                        </Flex>
                    </FieldSet>

                    {countryOptions.length > 1 && (
                        <FieldSet>
                            <LabelBox>
                                <Label htmlFor="countryCode">
                                    <FormattedMessage defaultMessage="Land" />
                                </Label>
                            </LabelBox>
                            <Box width={[1, 0.5]} style={{ maxWidth: '40rem' }}>
                                <SelectInput
                                    id="countryCode"
                                    name="address.countryCode"
                                    isSearchable
                                    placeholder={formatMessage({ defaultMessage: 'Selecteer land' })}
                                    options={countryOptions}
                                />
                            </Box>
                        </FieldSet>
                    )}
                    <FieldSet>
                        <LabelBox>
                            <Label>
                                <FormattedMessage defaultMessage="Locatie (GPS)" />
                            </Label>
                            <FieldSetHint>
                                <FormattedMessage defaultMessage="Plaats de pin op de plek van de activiteit die grenst aan de openbare weg (i.v.m. navigatie). Met de knop kun je de locatie (GPS) laten bepalen aan de hand van het adres." />
                            </FieldSetHint>
                        </LabelBox>
                        <Flex width={1} flexDirection="column">
                            <MapLocationInputField
                                mapBoxAccessToken={process.env.REACT_APP_MAPBOX_KEY ?? ''}
                                name="coordinates"
                            >
                                <MapLocationDetermineButton />
                            </MapLocationInputField>
                        </Flex>
                    </FieldSet>

                    <FieldHeading title={formatMessage({ defaultMessage: 'Hoe te bereiken' })}>
                        <FormattedMessage defaultMessage="Beschrijf hier hoe je de activiteit bereikt." />
                    </FieldHeading>
                    <TranslationInput
                        placeholder={formatMessage({
                            defaultMessage: 'Beschrijving over hoe je de activiteit bereikt',
                        })}
                        name="descriptionLocation"
                        label={formatMessage({ defaultMessage: 'Tekst invoeren of aanpassen' })}
                        hint={formatMessage({
                            defaultMessage:
                                'Plaats hier de tekst(aanpassing) die je op de  website van de VVV wilt tonen. Wij verzorgen de plaatsing en vertaling binnen enkele dagen.',
                        })}
                        data={{
                            nl: descriptionLocationNL,
                            de: descriptionLocationDE,
                            en: descriptionLocationEN,
                            pending: descriptionLocationPending,
                        }}
                    />
                </>
            )}
        </FormScreen>
    );
};
export default ActivityLocation;
