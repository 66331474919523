import {
    addDays,
    endOfDay,
    endOfToday,
    isAfter,
    isSameDay,
    isBefore,
    isWithinInterval,
    startOfToday,
    startOfDay,
    differenceInDays,
} from 'date-fns';
import { ConfigurationError } from '../entities/ConfigurationError';
import { DateType } from '../entities/DateType';

const today = startOfToday();

const isAfterToday = (date: Date) => isAfter(date, today);
const isBeforeToday = (date: Date) => isBefore(startOfDay(date), endOfToday());

const getBulkWizardStartDate = (start: DateType, end: DateType, last: DateType): Date | undefined => {
    if (!start) {
        return undefined;
    }

    const startDate = new Date(start);

    if (end && isBeforeToday(startDate) && isBeforeToday(new Date(end))) {
        return undefined;
    }

    const fixedStartDate = isAfterToday(startDate) ? startDate : today;

    if (!last) {
        return fixedStartDate;
    }

    const lastDate = new Date(last);

    if (end) {
        const endDate = new Date(end);

        if (isSameDay(lastDate, endDate) || isAfter(lastDate, endDate)) {
            return fixedStartDate;
        }
    }

    if (!isAfterToday(lastDate)) {
        return fixedStartDate;
    }

    return addDays(lastDate, 1);
};

/** The primary pricingmodel is active when today is between the start- and enddate, or when
the startdate is before today and there isn't an enddate. */
const getPrimaryPricingsActive = (start: DateType, end: DateType): boolean => {
    if (!start) {
        return false;
    }
    const startDate = new Date(start);

    if (!isSameDay(startDate, today) && isAfterToday(startDate)) {
        return false;
    }

    if (end) {
        const endDate = new Date(end);

        if (!(isSameDay(endDate, today) || isAfterToday(endDate))) {
            return false;
        }

        try {
            return isWithinInterval(endOfToday(), { start: startDate, end: endOfDay(endDate) });
        } catch (ex) {
            throw new ConfigurationError('De einddatum ligt vóór de startdatum van het primaire prijsmodel');
        }
    }

    return true;
};

const getIsWithinPrimaryPricing = (start: DateType, end: DateType, day: Date) => {
    if (start && !end) {
        return isAfter(day, new Date(start)) || isSameDay(day, new Date(start));
    }

    if (start && end) {
        return isWithinInterval(endOfDay(day), {
            start: startOfDay(new Date(start)),
            end: endOfDay(new Date(end)),
        });
    }
    return false;
};

const isIntervalWithinPrimaryPricing = (
    interval: { start: DateType; end: DateType },
    primaryPricingInterval: { start: DateType; end: DateType }
) => {
    const startIsWithin =
        !!interval.start &&
        getIsWithinPrimaryPricing(primaryPricingInterval.start, primaryPricingInterval.end, new Date(interval.start));

    const endIsWithin = interval.end
        ? getIsWithinPrimaryPricing(primaryPricingInterval.start, primaryPricingInterval.end, new Date(interval.end))
        : true;
    return startIsWithin && endIsWithin;
};

const getShouldShowToast = (start: DateType, end: DateType, last: DateType) => {
    const bulkStartDate = getBulkWizardStartDate(start, end, last);

    if (!bulkStartDate) {
        return false;
    }

    if (last && isAfterToday(new Date(last))) {
        if (end && isAfter(new Date(last), new Date(end))) {
            return false;
        }
        return !isSameDay(bulkStartDate, new Date()) && isAfter(bulkStartDate, new Date());
    }

    return true;
};

const tripsAreActive = (start: DateType, end: DateType) => {
    const primaryPricingsActive = getPrimaryPricingsActive(start, end);

    if (!primaryPricingsActive) {
        return true;
    }

    if (!end) {
        return false;
    }

    const endDate = new Date(end);

    return primaryPricingsActive && differenceInDays(endDate, today) <= 30;
};

export {
    getBulkWizardStartDate,
    getPrimaryPricingsActive,
    getIsWithinPrimaryPricing,
    getShouldShowToast,
    tripsAreActive,
    isIntervalWithinPrimaryPricing,
};
