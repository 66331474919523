import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { getFontCss } from '../../constants/theme';

export type TitleVariant = 'large' | 'regular' | 'small' | 'tiny' | 'micro';

interface Props extends HTMLAttributes<{}> {
    variant?: TitleVariant;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    elementType?: keyof JSX.IntrinsicElements | React.ComponentType<React.PropsWithChildren<any>>;
}

const elementMap: Record<TitleVariant, keyof JSX.IntrinsicElements> = {
    large: 'h1',
    regular: 'h2',
    small: 'h3',
    tiny: 'h4',
    micro: 'h5',
};

export const TitleInner = styled.h1<Props>`
    font-family: ${({ theme }) => theme.fontFamily.title};
    margin: 0;
    padding: 0;
    font-weight: 700;
    white-space: pre-line;
    ${({ variant = 'regular', theme }) => getFontCss(theme.fontSizes.title[variant])}
    strong {
        display: block;
    }
`;

const Title: FC<React.PropsWithChildren<Props>> = ({
    variant = 'regular',
    children,
    elementType = elementMap[variant],
    ...rest
}) => (
    <TitleInner as={elementType} variant={variant} {...rest}>
        {children}
    </TitleInner>
);

export default Title;
