import AllotmentIndicator from '@oberoninternal/travelbase-ds/components/calendar/AllotmentIndicator';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { Box, Flex } from '@rebass/grid';
import format from 'date-fns/format';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import hourFormat from '../../constants/hourFormat';
import { SidebarData, useSidebar } from '../../context/sidebar';
import { useVirtualizerScrollData } from '../../context/virtualizerScrollData';
import {
    ActivityTimeslotFragment,
    ActivityTimeslotFragmentDoc,
    useEditActivityTimeslotMutation,
} from '../../generated/graphql';
import getDatesFromScrollContext from '../../utils/getDatesFromScrollContext';
import Cell from './Cell';
import { TimeslotsSidebarData, toInput } from './TimeslotsSidebar';

interface TimeslotProps {
    slot: ActivityTimeslotFragment;
    isUnusable: boolean;
    onClick: (id: string) => void;
}
export const TIMESLOT_CELL_HEIGHT = 128;

const isSlotActive = (open: boolean, currentId: string, data?: SidebarData): data is TimeslotsSidebarData =>
    open && data?.type === 'TIMESLOTS' && data.slot?.id === currentId;

const Timeslot = ({ slot, isUnusable, onClick }: TimeslotProps) => {
    const [edit] = useEditActivityTimeslotMutation();
    const scrollData = useVirtualizerScrollData();
    const dates = getDatesFromScrollContext(scrollData);
    const ref = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState(slot.allotment);
    const [{ open, data }, dispatch] = useSidebar();

    useEffect(() => {
        // if the slot in de sidebar has changed we need to sync it when it's updated through the `AllotmentIndicator` field
        if (isSlotActive(open, slot.id, data) && !isEqual(data.slot, slot)) {
            dispatch({ type: 'set', data: { ...data, slot } as TimeslotsSidebarData });
        }
    }, [data, dispatch, open, slot]);

    useEffect(() => {
        if (slot.allotment !== value) {
            setValue(slot.allotment);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slot.allotment]);

    const onSave = async (val: number | null) => {
        if (isUnusable || !dates) {
            return;
        }

        await edit({
            variables: {
                ...dates,
                input: {
                    timeslot: { ...toInput(slot), allotment: val },
                    timeslotId: slot.id,
                },
            },
            update: cache => {
                const id = cache.identify(slot);

                const opts = {
                    fragment: ActivityTimeslotFragmentDoc,
                    id,
                };
                const current = cache.readFragment<ActivityTimeslotFragment>(opts);
                if (!current) {
                    return;
                }

                cache.writeFragment<ActivityTimeslotFragment>({
                    ...opts,
                    data: { ...current, allotment: val },
                });
            },
        });
    };
    return (
        <SlotCell
            key={slot.id}
            disabled={isUnusable}
            onClick={() => {
                if (!isUnusable) {
                    onClick(slot.id);
                }
            }}
        >
            <Flex flexDirection="column" alignItems="center" justifyContent="space-between" height="100%" width={1}>
                <Body variant="small">
                    {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                    {format(new Date(slot.startDateTime), hourFormat)} -{' '}
                    {format(new Date(slot.endDateTime), hourFormat)}
                </Body>
                {slot.label && <Description>{slot.label}</Description>}

                <Box width={1} style={{ height: '4.2rem', maxWidth: '9rem' }}>
                    <AllotmentIndicator
                        ref={ref}
                        setValue={onSave}
                        onContainerClick={e => e.stopPropagation()}
                        editable
                        nullPlaceholder={!slot.rateGroup.canBuyTickets ? '-' : undefined}
                        disabled={!slot.rateGroup.canBuyTickets || isUnusable}
                        size="fill"
                        value={slot.rateGroup.canBuyTickets ? slot.allotment ?? null : null}
                        max={999}
                    />
                </Box>
            </Flex>
        </SlotCell>
    );
};

export default Timeslot;

const Description = styled(Body).attrs({ variant: 'tiny' })`
    text-align: start;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

const SlotCell = styled(Cell).attrs({ as: 'button' })<{ disabled: boolean }>`
    margin: 0;
    height: ${TIMESLOT_CELL_HEIGHT}px;
    width: 100%;
    position: relative;
    outline: none;
    ${({ disabled }) =>
        !disabled &&
        css`
            ::after {
                content: '';
                position: absolute;
                box-shadow: 0 0 0 1px var(--border-color), inset 0 0 0 1px var(--border-color);
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                --border-color: ${({ theme }) => theme.colors.primary[30]};
                opacity: 0;
                border-radius: 0.4rem;
            }

            :hover,
            :focus {
                ::after {
                    opacity: 1;
                }
            }

            :focus {
                ::after {
                    --border-color: ${({ theme }) => theme.colors.primary[40]};
                }
            }
        `};
`;
