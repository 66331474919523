import SelectInput from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import TextInput, { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import { Box, Flex } from '@rebass/grid';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import * as Yup from 'yup';
import { NotFoundError } from '../../../../entities/NotFoundError';
import { UnitParams } from '../../../../entities/UnitParams';
import {
    EditAccommodationInfoInput,
    useAccommodationInfoQuery,
    useEditAccommodationInfoMutation,
} from '../../../../generated/graphql';
import useTimes from '../../../../hooks/useTimes';
import { getAccommodationTypeTranslation } from '../../../../utils/getAccommodationTypeTranslation';
import { toInitialContactInfo } from '../../../../utils/toInitialContactInfo';
import FieldSet from '../../../atoms/FieldSet';
import FieldSetHint from '../../../atoms/FieldSetHint';
import ArrayTextInput from '../../../molecules/ArrayTextInput';
import FieldHeading from '../../../molecules/FieldHeading';
import LabelBox from '../../../molecules/LabelBox';
import FormScreen from '../../../organisms/FormScreen';
import Loading from '../../../organisms/Loading';
import { contactReservationValidation, contactValidation } from '../../../../utils/validations/formValidation';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import createPublicLinkProps from '../../../../utils/createPublicLinkProps';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

export const query = gql`
    query AccommodationInfo($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            ...AccommodationDetails
        }
    }

    mutation EditAccommodationInfo($input: EditAccommodationInfoInput!) {
        editAccommodationInfo(input: $input) {
            rentalUnit {
                ...AccommodationDetails
            }
        }
    }

    fragment AccommodationDetails on RentalUnit {
        id
        accommodation {
            id
            slug
            hasPublicPage
            name
            type
            reservations {
                contactName
                phoneNumbers
                emails
            }
            caretaker {
                contactName
                phoneNumbers
                emails
            }
            checkInStartTime
            checkInEndTime
            checkOutTime
            publicReservationsEmail
            publicReservationsPhoneNumber
            publicReservationsWebsiteUrl
        }
    }
`;

export const accommodationDetailsValidation = (intl: IntlShape) =>
    Yup.object().shape({
        reservations: contactReservationValidation(intl),
        caretaker: contactValidation(intl),
        checkOutTime: Yup.string().required(intl.formatMessage({ defaultMessage: 'Vul een tijd in' })),
        checkInStartTime: Yup.string().required(intl.formatMessage({ defaultMessage: 'Vul een tijd in' })),
        checkInEndTime: Yup.string().required(intl.formatMessage({ defaultMessage: 'Vul een tijd in' })),
        publicReservationsEmail: Yup.string()
            .email(intl.formatMessage({ defaultMessage: 'Vul een geldig e-mailadres in' }))
            .nullable(),
        publicReservationsPhoneNumber: Yup.string().nullable(),
        publicReservationsWebsiteUrl: Yup.string()
            .url(intl.formatMessage({ defaultMessage: 'Vul een geldige URL in' }))
            .nullable(),
    });

const AccommodationDetails: FC<React.PropsWithChildren<UnitParams>> = ({ unitSlug }) => {
    const { loading, data } = useAccommodationInfoQuery({ variables: { unitSlug } });
    const [mutate] = useEditAccommodationInfoMutation();
    const intl = useIntl();
    const { formatMessage } = intl;

    if (loading) {
        return <Loading />;
    }

    if (!data || !data.rentalUnit?.accommodation) {
        throw new NotFoundError();
    }

    const {
        rentalUnit: {
            id,
            accommodation: {
                name,
                caretaker,
                reservations,
                type,
                checkInEndTime,
                checkInStartTime,
                checkOutTime,
                slug,
                hasPublicPage,
                publicReservationsEmail,
                publicReservationsPhoneNumber,
                publicReservationsWebsiteUrl,
            },
        },
    } = data;

    const initialValues: Omit<EditAccommodationInfoInput, 'rentalUnitId'> = {
        reservations: toInitialContactInfo(reservations),
        caretaker: toInitialContactInfo(caretaker),
        checkInStartTime,
        checkInEndTime,
        checkOutTime,
        publicReservationsEmail,
        publicReservationsPhoneNumber,
        publicReservationsWebsiteUrl,
    };
    const accommodationTypeTranslation = getAccommodationTypeTranslation(type);
    return (
        <FormScreen
            validationSchema={accommodationDetailsValidation(intl)}
            initialValues={initialValues}
            handleSubmit={async ({ caretaker: newCaretaker, ...values }) => {
                await mutate({
                    variables: {
                        input: {
                            ...values,
                            rentalUnitId: id,
                            caretaker: {
                                ...newCaretaker,
                                // as these are not required we need to filter out the empty ones
                                emails: newCaretaker.emails?.filter(email => email !== ''),
                                phoneNumbers: newCaretaker.phoneNumbers?.filter(phoneNumber => phoneNumber !== ''),
                            },
                        },
                    },
                });
            }}
        >
            {() => (
                <>
                    <Flex justifyContent="space-between" flexDirection={['column', 'row']}>
                        <Box>
                            <Pagehead
                                title={formatMessage(
                                    { defaultMessage: '{accommodationTypeTranslation} informatie' },
                                    { accommodationTypeTranslation }
                                )}
                            >
                                <FormattedMessage
                                    defaultMessage="Hier beheer je de informatie van je {accommodationTypeTranslation}"
                                    values={{ accommodationTypeTranslation }}
                                />
                            </Pagehead>
                        </Box>
                        {hasPublicPage && (
                            <Box>
                                <TextButton
                                    style={{ display: 'flex-inline' }}
                                    as="a"
                                    {...createPublicLinkProps(`complex/${slug}`, intl)}
                                />
                            </Box>
                        )}
                    </Flex>

                    <FieldHeading title={formatMessage({ defaultMessage: 'Algemeen' })}>
                        <FormattedMessage
                            defaultMessage="Deze gegevens gelden voor alle accommodaties van je {accommodationTypeTranslation}"
                            values={{ accommodationTypeTranslation }}
                        />
                    </FieldHeading>

                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="name">
                                <FormattedMessage
                                    defaultMessage="Naam {accommodationTypeTranslation}"
                                    values={{ accommodationTypeTranslation }}
                                />
                            </Label>
                            <FieldSetHint>
                                <FormattedMessage
                                    defaultMessage="Deze naam wordt gebruikt in de zoekresultaten en op de detailpagina van je {accommodationTypeTranslation}"
                                    values={{ accommodationTypeTranslation }}
                                />
                            </FieldSetHint>
                        </LabelBox>
                        <Box width={1}>
                            <TextInput disabled value={name} />
                        </Box>
                    </FieldSet>

                    <PublicInfoSection />

                    <ReservationInfoSection />
                </>
            )}
        </FormScreen>
    );
};

export default AccommodationDetails;

// SHARED SECTIONS

export const PublicInfoSection = () => {
    const { formatMessage } = useIntl();
    return (
        <>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="publicReservationsEmail">
                        <FormattedMessage defaultMessage="Publiek e-mailadres" />
                    </Label>
                </LabelBox>
                <Box width={1}>
                    <TextInputField
                        placeholder={formatMessage({ defaultMessage: 'Publiek e-mailadres' })}
                        name="publicReservationsEmail"
                    />
                </Box>
            </FieldSet>

            <FieldSet>
                <LabelBox>
                    <Label htmlFor="publicReservationsPhoneNumber">
                        <FormattedMessage defaultMessage="Publiek telefoonnummer" />
                    </Label>
                    <FieldSetHint>
                        <FormattedMessage defaultMessage="Gebruik de internationale notatie (+31612345678)." />
                    </FieldSetHint>
                </LabelBox>
                <Box width={1}>
                    <TextInputField
                        name="publicReservationsPhoneNumber"
                        placeholder={formatMessage({ defaultMessage: 'Publiek Telefoonnummer' })}
                    />
                </Box>
            </FieldSet>

            <FieldSet>
                <LabelBox>
                    <Label htmlFor="publicReservationsWebsiteUrl">
                        <FormattedMessage defaultMessage="Publieke website URL" />
                    </Label>
                </LabelBox>
                <Box width={1}>
                    <TextInputField
                        name="publicReservationsWebsiteUrl"
                        placeholder={formatMessage({ defaultMessage: 'Publieke website URL' })}
                    />
                </Box>
            </FieldSet>
        </>
    );
};

export const ReservationInfoSection = () => {
    const times = useTimes();
    const { formatMessage } = useIntl();
    return (
        <>
            <FieldHeading title={formatMessage({ defaultMessage: 'Contactpersoon reserveringen' })}>
                <FormattedMessage defaultMessage="Wie is de persoon of afdeling die de reserveringen in behandeling neemt? Het is mogelijk meerdere e-mailadressen op te geven. Gegevens van contact reserveringen worden niet met gasten gedeeld." />
            </FieldHeading>

            <FieldSet>
                <LabelBox>
                    <Label htmlFor="reservations.contactName">
                        <FormattedMessage defaultMessage="Naam contactpersoon reserveringen" />
                    </Label>
                </LabelBox>
                <Box width={1}>
                    <TextInputField
                        id="reservations.contactName"
                        // placeholder="Volledige naam"
                        placeholder={formatMessage({ defaultMessage: 'Volledige naam' })}
                        name="reservations.contactName"
                    />
                </Box>
            </FieldSet>

            <FieldSet>
                <LabelBox>
                    <Label htmlFor="reservations.emails">
                        <FormattedMessage defaultMessage="E-mail contactpersoon of afdeling reserveringen" />
                    </Label>
                </LabelBox>
                <Box width={1}>
                    <ArrayTextInput
                        name="reservations.emails"
                        placeholder={formatMessage({ defaultMessage: 'E-mail' })}
                        addDescription={formatMessage({ defaultMessage: 'Extra e-mail veld' })}
                    />
                </Box>
            </FieldSet>

            <FieldSet>
                <LabelBox>
                    <Label htmlFor="reservationsPhoneNumber">
                        <FormattedMessage defaultMessage="Telefoonnummer contactpersoon of afdeling reserveringen" />
                    </Label>
                    <FieldSetHint>
                        <FormattedMessage defaultMessage="Gebruik de internationale notatie (+31612345678)." />
                    </FieldSetHint>
                </LabelBox>
                <Box width={1}>
                    <ArrayTextInput
                        name="reservations.phoneNumbers"
                        placeholder={formatMessage({ defaultMessage: 'Telefoonnummer' })}
                        addDescription={formatMessage({ defaultMessage: 'Extra telefoonnummer veld' })}
                    />
                </Box>
            </FieldSet>

            <FieldHeading title={formatMessage({ defaultMessage: 'Contactpersoon servicebedrijf' })}>
                <FormattedMessage
                    defaultMessage="Wie is de persoon of het bedrijf die ook de definitieve boekingsbevestiging moet ontvangen (i.v.m. bijv.
                linnenverhuur, schoonmaak, etc.)? Deze persoon heeft geen contact met de gast. Indien niet van
                toepassing deze velden leeg laten."
                />
            </FieldHeading>

            <FieldSet>
                <LabelBox>
                    <Label htmlFor="caretaker.contactName">
                        <FormattedMessage defaultMessage="Naam contactpersoon beheer" />
                    </Label>
                </LabelBox>
                <Box width={1}>
                    <TextInputField
                        id="caretaker.contactName"
                        // placeholder="Volledige naam"
                        placeholder={formatMessage({ defaultMessage: 'Volledige naam' })}
                        name="caretaker.contactName"
                    />
                </Box>
            </FieldSet>

            <FieldSet>
                <LabelBox>
                    <Label htmlFor="caretaker.emails">
                        <FormattedMessage defaultMessage="E-mail contactpersoon" />
                    </Label>
                </LabelBox>
                <Box width={1}>
                    <ArrayTextInput
                        name="caretaker.emails"
                        placeholder={formatMessage({ defaultMessage: 'E-mail' })}
                        addDescription={formatMessage({ defaultMessage: 'Extra e-mail veld' })}
                    />
                </Box>
            </FieldSet>

            <FieldSet>
                <LabelBox>
                    <Label htmlFor="caretaker.phoneNumbers">
                        <FormattedMessage defaultMessage="Telefoonnummer contactpersoon of afdeling beheer" />
                    </Label>
                    <FieldSetHint>
                        <FormattedMessage defaultMessage="Gebruik de internationale notatie (+31612345678)." />
                    </FieldSetHint>
                </LabelBox>
                <Box width={1}>
                    <ArrayTextInput
                        name="caretaker.phoneNumbers"
                        placeholder={formatMessage({ defaultMessage: 'Telefoonnummer' })}
                        addDescription={formatMessage({ defaultMessage: 'Extra telefoonnummer veld' })}
                    />
                </Box>
            </FieldSet>

            <FieldHeading title={formatMessage({ defaultMessage: 'Check-in/out' })}>
                <FormattedMessage defaultMessage="Tussen welke tijden kan jouw gast inchecken bij aankomst en hoe laat moet de gast de accommodatie uiterlijk verlaten bij vertrek?" />
            </FieldHeading>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="checkInStartTime">
                        <FormattedMessage defaultMessage="Check-in vanaf" />
                    </Label>
                    <FieldSetHint>
                        <FormattedMessage defaultMessage="Geef aan vanaf welk tijdstip een gast mag inchecken" />
                    </FieldSetHint>
                </LabelBox>
                <Box width={[1, 1, 1 / 3]}>
                    <SelectInput
                        hideChoices
                        name="checkInStartTime"
                        placeholder={formatMessage({ defaultMessage: 'Check-in starttijd' })}
                        options={times}
                    />
                </Box>
            </FieldSet>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="checkInEndTime">
                        <FormattedMessage defaultMessage="Check-in tot" />
                    </Label>
                    <FieldSetHint>
                        <FormattedMessage defaultMessage="Geef aan tot welk tijdstip een gast mag inchecken" />
                    </FieldSetHint>
                </LabelBox>
                <Box width={[1, 1, 1 / 3]}>
                    <SelectInput
                        hideChoices
                        name="checkInEndTime"
                        placeholder={formatMessage({ defaultMessage: 'Check-in eindtijd' })}
                        options={times}
                    />
                </Box>
            </FieldSet>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="checkOutTime">
                        <FormattedMessage defaultMessage="Check-out tot" />
                    </Label>
                    <FieldSetHint>
                        <FormattedMessage defaultMessage="Geef aan tot welk tijdstip een gast mag uitchecken" />
                    </FieldSetHint>
                </LabelBox>
                <Box width={[1, 1, 1 / 3]}>
                    <SelectInput
                        hideChoices
                        name="checkOutTime"
                        placeholder={formatMessage({ defaultMessage: 'Check-out tijd' })}
                        options={times}
                    />
                </Box>
            </FieldSet>
        </>
    );
};
