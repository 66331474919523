import { isAfter, isBefore, isWithinInterval } from 'date-fns';
import { AllotmentType } from '../entities/AllotmentType';
import shouldHideBooking from './shouldHideBooking';

export default (events: AllotmentType[], interval: Interval) =>
    events.filter(event => {
        const period = { start: new Date(event.startDate), end: new Date(event.endDate) };

        if (event.__typename === 'Booking' && shouldHideBooking(event.status)) {
            return false;
        }
        // show stuff that's either inside our period
        return (
            (isWithinInterval(period.start, interval) && isWithinInterval(period.end, interval)) ||
            // or if our month gets intersected by the period
            (isBefore(period.start, interval.start) && isWithinInterval(interval.start, period)) ||
            (isAfter(period.end, interval.end) && isWithinInterval(interval.end, period))
        );
    });
