import { OperationVariables, useApolloClient } from '@apollo/client';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import { PLACEHOLDER_ID } from '@oberoninternal/travelbase-ds/components/calendar/Allotment';
import Toast from '@oberoninternal/travelbase-ds/components/feedback/Toast';
import { FormActionsContainer } from '@oberoninternal/travelbase-ds/components/form/FormActions';
import { RadioField } from '@oberoninternal/travelbase-ds/components/form/Radio';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import format from 'date-fns/format';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import dateFormat from '../../constants/dateFormat';
import { getDateOpts } from '../../constants/dateOpts';
import { useSidebar } from '../../context/sidebar';
import { UnitParams } from '../../entities/UnitParams';
import {
    AllotmentLockoutTypeEnum,
    AllotmentsLockoutFragment,
    Scalars,
    useCreateLockoutMutation,
    useDeleteLockoutMutation,
    useEditLockoutMutation,
    ValidateLockoutsDocument,
    ValidateLockoutsQuery,
} from '../../generated/graphql';
import { replaceAllotmentAmountForLockoutInterval, useWriteLockout } from '../../hooks/useWriteLockout';
import { doesOverlap } from '../../utils/doesOverlap';
import { formatDuration } from '../../utils/formatDuration';
import parseDate from '@oberoninternal/travelbase-ds/utils/parseDate';

import shouldHideBooking from '../../utils/shouldHideBooking';
import { validatePeriod } from '../../utils/validatePeriod';
import Heading from '../atoms/Heading';
import { SidebarSeperator } from '../atoms/Seperator';
import SidebarField from '../atoms/SidebarField';
import SidebarIntro from '../atoms/SidebarIntro';
import FormScreen from '../organisms/FormScreen';
import { PricingQueryShape } from '../organisms/Pricing';
import RangePickerInputField from './RangePickerInputField';
import { FormattedMessage, useIntl } from 'react-intl';

export interface LockoutSidebarData {
    type: 'LOCKOUT';
    lockout: AllotmentsLockoutFragment;
    variables?: OperationVariables;
    // required when creating!
    rentalUnitId?: Scalars['ID']['output'];
    document: DocumentNode;
}

interface Props {
    data: LockoutSidebarData;
}

export const query = gql`
    query ValidateLockouts($start: Date!, $end: Date!, $unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            id
            allotmentLockouts(startDate: $start, endDate: $end) {
                id
                startDate
                endDate
                type
                comment
                source
            }
            bookings(startDate: $start, endDate: $end) {
                id
                arrivalDate
                departureDate
                status
            }
        }
    }
`;

export const mutation = gql`
    mutation EditLockout($input: EditAllotmentLockoutInput!) {
        editAllotmentLockout(input: $input) {
            allotmentLockout {
                ...AllotmentsLockout
            }
        }
    }

    mutation CreateLockout($input: CreateAllotmentLockoutInput!) {
        createAllotmentLockout(input: $input) {
            allotmentLockout {
                ...AllotmentsLockout
            }
        }
    }

    mutation DeleteLockout($input: DeleteAllotmentLockoutInput!) {
        deleteAllotmentLockout(input: $input) {
            id
        }
    }
`;

const LockoutSidebar: FC<React.PropsWithChildren<Props>> = ({
    data: { variables, rentalUnitId, lockout, document },
}) => {
    const client = useApolloClient();
    const params = useParams<UnitParams>();
    const [state, dispatch] = useSidebar();
    const writeLockout = useWriteLockout(document, variables);

    const { open } = state;
    const prevOpened = useRef(open);
    const prevId = useRef(lockout.id);

    const [edit] = useEditLockoutMutation();
    const [create] = useCreateLockoutMutation();
    const [remove] = useDeleteLockoutMutation();
    const { formatMessage } = useIntl();
    const reset = useCallback(() => {
        if (prevId.current !== PLACEHOLDER_ID) {
            const lockouts =
                client.readQuery<PricingQueryShape>({
                    query,
                    variables,
                })?.rentalUnit?.allotmentLockouts ?? [];
            const cached = lockouts.find(({ id }) => id === prevId.current);

            if (!cached) {
                return;
            }

            writeLockout({
                ...cached,
                isActive: false,
                isHovering: false,
            });
        } else {
            // delete placeholder
            writeLockout(null);
        }
    }, [client, variables, writeLockout]);

    useEffect(() => {
        // did the sidebar close?
        if (prevOpened.current !== open && !open) {
            reset();
        }
        prevOpened.current = open;

        // are we editing a different lockout now?
        if (prevId.current !== lockout.id) {
            reset();
        }
        prevId.current = lockout.id;
    }, [open, client, variables, lockout.id, writeLockout, reset]);

    // We only want to reset on mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => reset, []);

    const handleDelete = async () => {
        if (
            !window.confirm(formatMessage({ defaultMessage: 'Weet je zeker dat je deze blokkade wilt verwijderen?' }))
        ) {
            return;
        }

        dispatch({ type: 'close' });

        const data = client.readQuery<PricingQueryShape>({
            query: document,
            variables,
        })!;

        const lockoutPredicate = ({ id }: AllotmentsLockoutFragment) => id !== lockout.id;

        const newData: PricingQueryShape = {
            rentalUnit: {
                ...data.rentalUnit!,
                allotmentLockouts: data.rentalUnit?.allotmentLockouts?.filter(lockoutPredicate) ?? [],
                // As a side effect of creating a lockout allotments will be set to 0. Therefore, when removing the allotment it should be set
                // to 1 again.
                allotments: replaceAllotmentAmountForLockoutInterval(data.rentalUnit, lockout, 1),
            },
        };
        client.writeQuery<PricingQueryShape>({
            query: document,
            variables,
            data: newData,
        });

        try {
            await remove({
                variables: {
                    input: {
                        allotmentLockoutId: lockout.id,
                    },
                },
            });
            toast(
                <Toast
                    variant="success"
                    // title="Blokkering verwijderd"
                    title={formatMessage({ defaultMessage: 'Blokkering verwijderd' })}
                >
                    <Body variant="small">
                        <FormattedMessage defaultMessage="De blokkering is met succes verwijderd" />
                    </Body>
                </Toast>,
                {
                    autoClose: 3000,
                }
            );
        } catch (ex) {
            // clean up & rethrow
            client.writeQuery<PricingQueryShape>({
                query: document,
                variables,
                data,
            });
            dispatch({ type: 'show' });

            throw ex;
        }
    };

    return (
        <FormScreen
            alwaysPrompt={open}
            initialValues={{
                ...lockout,
                startDate: parseDate(lockout.startDate),
                endDate: parseDate(lockout.endDate),
            }}
            validate={async ({ startDate, endDate }) => {
                const formikErrors: Record<string, string> = {};

                try {
                    const parsedStartDate = parseDate(startDate);
                    const parsedEndDate = parseDate(endDate);

                    if (differenceInCalendarDays(parsedStartDate, parsedEndDate) === 0) {
                        throw new Error('Selecteer een geldige tot datum');
                    }

                    validatePeriod(parsedStartDate, parsedEndDate);

                    const { data, errors } = await client.query<ValidateLockoutsQuery>({
                        query: ValidateLockoutsDocument,
                        variables: {
                            unitSlug: params.unitSlug,
                            start: parsedStartDate,
                            end: parsedEndDate,
                        },
                    });
                    if (errors?.length) {
                        throw new Error(errors.map(({ message }) => message).join('\n'));
                    }

                    const bookings =
                        data.rentalUnit?.bookings
                            .filter(({ status }) => !shouldHideBooking(status))
                            .map(({ arrivalDate, departureDate, ...rest }) => ({
                                startDate: arrivalDate,
                                endDate: departureDate,
                                ...rest,
                            })) ?? [];
                    const lockouts = data?.rentalUnit?.allotmentLockouts ?? [];

                    for (const current of [...lockouts, ...bookings]) {
                        if (current.id !== lockout.id && doesOverlap(current, parsedStartDate, parsedEndDate)) {
                            const typeText = `${
                                current.__typename === 'AllotmentLockout' ? 'reeds bestaande blokkade' : 'boeking'
                            }`;
                            throw new Error(
                                `Deze blokkade conflicteert met een ${typeText} (${formatDuration(
                                    parseDate(current.startDate),
                                    parseDate(current.endDate)
                                )}) \n Pas de periode aan.`
                            );
                        }
                    }
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } catch (ex: any) {
                    // eslint-disable-next-line no-console
                    console.error(ex);

                    formikErrors.startDate = ex.message;
                }
                return formikErrors;
            }}
            handleSubmit={async values => {
                dispatch({ type: 'close' });

                try {
                    const { __typename, id, isDragging, isActive, isHovering, ...input } = values;
                    const startDate = format(parseDate(values.startDate), dateFormat, getDateOpts('nl'));
                    const endDate = format(parseDate(values.endDate), dateFormat, getDateOpts('nl'));
                    if (values.id === PLACEHOLDER_ID) {
                        if (!rentalUnitId) {
                            throw new Error('No rentalUnitId was passed to LockoutSidebar whilst creating');
                        }

                        const { data } = await create({
                            variables: {
                                input: {
                                    ...input,
                                    startDate,
                                    endDate,
                                    rentalUnitId,
                                },
                            },
                        });
                        if (data?.createAllotmentLockout.allotmentLockout) {
                            // delete placeholder
                            writeLockout(null);

                            // add newly created lockout to query
                            writeLockout(data?.createAllotmentLockout.allotmentLockout);
                        }
                    } else {
                        const { data } = await edit({
                            variables: {
                                input: {
                                    ...input,
                                    allotmentLockoutId: values.id,
                                    startDate,
                                    endDate,
                                },
                            },
                        });

                        if (data?.editAllotmentLockout.allotmentLockout) {
                            // add changed lockout to query
                            writeLockout(data?.editAllotmentLockout.allotmentLockout);
                        }
                    }
                } catch (ex) {
                    dispatch({ type: 'show' });
                    throw ex;
                }
            }}
            bottomChildren={({ dirty }) => {
                const isPlaceholder = lockout.id === PLACEHOLDER_ID;
                return (
                    <FormActionsContainer alwaysVisible dirty={dirty} variant="sidebar">
                        {!isPlaceholder ? (
                            <Button variant="danger" type="button" onClick={handleDelete}>
                                <FormattedMessage defaultMessage="Verwijderen" />
                            </Button>
                        ) : (
                            <span />
                        )}
                        <Button disabled={isPlaceholder ? false : !dirty} type="submit">
                            <FormattedMessage defaultMessage="Publiceren" />
                        </Button>
                    </FormActionsContainer>
                );
            }}
            variant="sidebar"
        >
            {({ values }) => {
                const startDate = parseDate(values.startDate);
                const endDate = parseDate(values.endDate);
                const formattedDate = formatDuration(startDate, endDate);

                return (
                    <>
                        <SidebarIntro title={formatMessage({ defaultMessage: 'Selectie' })}>
                            <FormattedMessage defaultMessage="van" /> {formattedDate}
                        </SidebarIntro>
                        <SidebarSeperator />

                        <Heading
                            title={
                                lockout.id === PLACEHOLDER_ID
                                    ? formatMessage({ defaultMessage: 'Blokkering toevoegen' })
                                    : formatMessage({ defaultMessage: 'Blokkering aanpassen' })
                            }
                        >
                            <FormattedMessage defaultMessage="Door een blokkering toe te voegen kunnen er voor die periode geen boekingen geplaatst worden..." />
                        </Heading>

                        <SidebarField label={formatMessage({ defaultMessage: 'Periode' })}>
                            <RangePickerInputField />
                        </SidebarField>

                        <SidebarField label={formatMessage({ defaultMessage: 'Type blokkering' })}>
                            <RadioField name="type" id={AllotmentLockoutTypeEnum.PrivateUse}>
                                <FormattedMessage defaultMessage="Eigen gebruik" />
                            </RadioField>
                            <RadioField name="type" id={AllotmentLockoutTypeEnum.ExternalBooking}>
                                <FormattedMessage defaultMessage="Externe boeking" />
                            </RadioField>
                            <RadioField name="type" id={AllotmentLockoutTypeEnum.Maintenance}>
                                <FormattedMessage defaultMessage="Onderhoud" />
                            </RadioField>
                            <RadioField name="type" id={AllotmentLockoutTypeEnum.Other}>
                                <FormattedMessage defaultMessage="Overig" />
                            </RadioField>
                        </SidebarField>

                        <SidebarField label={formatMessage({ defaultMessage: 'Notities' })}>
                            <TextInputField name="comment" type="textarea" />
                        </SidebarField>
                    </>
                );
            }}
        </FormScreen>
    );
};

export default LockoutSidebar;
