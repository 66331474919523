import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';
import { RentalUnitMetaDataFragment } from '../generated/graphql';

const getRentalUnitMetaData = (unit: Maybe<RentalUnitMetaDataFragment>) => {
    const datePricingStartDate = unit?.datePricingStartDate ? new Date(unit.datePricingStartDate) : undefined;
    const datePricingEndDate = unit?.datePricingEndDate ? new Date(unit.datePricingEndDate) : undefined;

    return {
        ...unit,
        datePricingStartDate,
        datePricingEndDate,
    };
};

export default getRentalUnitMetaData;

export type RentalUnitMetaData = ReturnType<typeof getRentalUnitMetaData>;
