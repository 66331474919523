import { Field, FieldConfig, FieldProps, getIn } from 'formik';
import transparentize from 'polished/lib/color/transparentize';
import React, { HTMLAttributes, InputHTMLAttributes, Ref, TextareaHTMLAttributes } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import styled, { css } from 'styled-components';

import ErrorMessage from './ErrorMessage';
import { getAssignmentColor } from '../../constants/theme';
import Body from '../primitive/Body';

type InputProps = InputHTMLAttributes<HTMLInputElement>;

// need to take out all props that conflict with InputProps
type TextAreaProps = Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange' | keyof HTMLAttributes<{}>>;

export type TextInputVariant = 'small' | 'medium';

export interface TextInputFieldProps extends InputProps, TextAreaProps {
    variant?: TextInputVariant;
    error?: string;
    displayTouched?: boolean;
    iconClickHandler?: () => void;
    hideErrorMessage?: boolean;
}

const TextInput = React.forwardRef<HTMLInputElement | HTMLTextAreaElement, TextInputFieldProps>(
    (
        {
            error,
            rows = 3,
            type,
            variant = 'medium',
            displayTouched,
            children,
            iconClickHandler,
            hideErrorMessage,
            maxLength,
            ...props
        },
        ref
    ) => {
        const inputProps = { error, variant, type };
        return (
            <Container>
                {children && <IconWrapper onClick={iconClickHandler}>{children}</IconWrapper>}
                {type === 'textarea' ? (
                    <Textarea
                        rows={rows}
                        ref={ref as Ref<HTMLTextAreaElement>}
                        {...inputProps}
                        {...(props as TextAreaProps)}
                    />
                ) : (
                    <Input
                        withIcon={!!children}
                        ref={ref as Ref<HTMLInputElement>}
                        displayTouched={displayTouched}
                        maxLength={maxLength ?? 255}
                        {...inputProps}
                        {...props}
                    />
                )}
                {maxLength && type === 'textarea' && (
                    <CharactersCounter variant="small" error={(props.value as string).length > maxLength}>
                        {`${maxLength - ((props.value as string) || '').length}/${maxLength}`}
                    </CharactersCounter>
                )}
                {error && !hideErrorMessage && <ErrorMessage>{error}</ErrorMessage>}
            </Container>
        );
    }
);

export const TextInputField = React.forwardRef<HTMLInputElement, TextInputFieldProps & FieldConfig>(
    ({ name, displayTouched = false, ...props }, ref) => (
        <Field name={name} {...props}>
            {({ field, form: { errors, touched, values } }: FieldProps) => (
                <TextInput
                    ref={ref}
                    displayTouched={displayTouched && getIn(values, field.name)}
                    error={
                        getIn(touched, field.name) && getIn(errors, field.name) ? getIn(errors, field.name) : undefined
                    }
                    {...field}
                    {...props}
                />
            )}
        </Field>
    )
);

export default TextInput;

const CharactersCounter = styled(Body)<{ error: boolean }>`
    position: absolute;
    right: ${({ theme }) => theme.spacing['40_Standard']};
    bottom: ${({ theme }) => theme.spacing['40_Standard']};
    color: ${({ theme, error }) => (error ? theme.colors.negative['50'] : theme.colors.neutral['50'])};
    pointer-events: none;
`;

const IconWrapper = styled.div`
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    left: ${({ theme }) => theme.spacing['40_Standard']};
    color: ${({ theme }) => theme.colors.neutral['30']};
`;

const inputCss = css<TextInputFieldProps>`
    width: 100%;
    background-color: ${({ theme, displayTouched }) =>
        displayTouched ? transparentize(0.9, theme.colors.primary['40']) : theme.colors.neutral['0']};
    padding: ${({ variant, type, theme }) => {
        const radius = type === 'textarea' ? theme.radius.textArea : theme.radius.textInput;
        return variant === 'small' ? `0.6rem max(1.2rem, ${radius})` : `1.2rem max(1.6rem, ${radius})`;
    }};
    margin-bottom: ${({ error }) => (error ? '4px' : '0')};

    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        padding: 0.6rem 1.2rem;
    }

    outline: none;
    border: none;
    border-radius: ${({ type, theme }) => (type === 'textarea' ? theme.radius.textArea : theme.radius.textInput)};
    letter-spacing: 0.15px;
    --border-color: ${({ error, displayTouched, theme }) =>
        error
            ? `${theme.colors.negative['60']} !important`
            : displayTouched
            ? `${theme.colors.primary['40']} !important`
            : theme.colors.neutral['20']};
    box-shadow: 0 0 0 1px var(--border-color), inset 0 0 0 1px var(--border-color);
    -webkit-appearance: none;

    ${({ error }) => error && 'z-index: 1;'}

    ::placeholder {
        color: ${({ theme }) => theme.colors.neutral['40']};
    }

    :focus {
        --border-color: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.input)};
    }

    :disabled {
        background: ${({ theme }) => theme.colors.neutral['10']};
        cursor: not-allowed;
        opacity: 1;
    }

    :hover:not(:focus) {
        --border-color: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.input)};
    }
`;

export const Textarea = styled(TextareaAutosize)<TextInputFieldProps>`
    resize: vertical !important;
    min-height: 4.8rem;
    ${inputCss};
`;

export const Input = styled.input<TextInputFieldProps & { withIcon?: boolean }>`
    ${inputCss}
    ${({ withIcon }) => (withIcon ? 'padding-left: 5rem !important;' : '')}
`;
const Container = styled.div`
    position: relative;
    flex: 1;
`;
