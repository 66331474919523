import { Flex } from '@rebass/grid';
import range from 'lodash/range';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useRandom } from '../../hooks/useRandom';
import { Container as MonthContainer } from './Month';

const SkeletonMonth = () => {
    const days = useRandom(28, 31);
    return (
        <MonthContainer>
            {range(days).map(i => (
                <SkeletonCell key={i} />
            ))}
        </MonthContainer>
    );
};

export default SkeletonMonth;

const SkeletonCell = () => (
    <Flex width={1 / 7} p={3} justifyContent="center">
        <Skeleton width={50} height={70} />
    </Flex>
);
