import { Box } from '@rebass/grid';
import { Form, Formik, FormikProps } from 'formik';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import Logo from '@oberoninternal/travelbase-ds/components/figure/LogoText';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import { useParams, useHistory } from 'react-router-dom';
import { usePasswordResetMutation } from '../../generated/graphql';
import gql from 'graphql-tag';
import { ApolloError } from '@apollo/client';
import brandConfig from '../../constants/brandConfig';
import { FormattedMessage, useIntl } from 'react-intl';

export const mutation = gql`
    mutation passwordReset($token: String!, $newPassword: String!) {
        resetPassword(input: { token: $token, newPassword: $newPassword }) {
            email
        }
    }
`;

interface Values {
    password: string;
    passwordConfirm: string;
}

interface Token {
    token: string;
}

const PasswordSet: FC<React.PropsWithChildren<unknown>> = () => {
    const { formatMessage } = useIntl();
    const setPasswordSchema = Yup.object().shape({
        password: Yup.string().required(formatMessage({ defaultMessage: 'Wachtwoord is verplicht' })),
        passwordConfirm: Yup.string()
            .oneOf([Yup.ref('password'), null], formatMessage({ defaultMessage: 'Wachtwoorden komen niet overeen.' }))
            .required(formatMessage({ defaultMessage: 'Wachtwoord is verplicht' })),
    });
    const history = useHistory();
    const { token } = useParams<Token>();
    const brandName = brandConfig.brandName ?? 'Travelbase';
    const [mutate, { error }] = usePasswordResetMutation({
        context: {
            ignoreError: true,
        },
        onCompleted() {
            history.push({
                pathname: '/login',
                state: {
                    successMessage: 'Opslaan gelukt. Vanaf nu kun je inloggen met het gekozen wachtwoord.',
                },
            });
        },
    });

    return (
        <Container>
            <Box width={[7 / 8, null, '48rem']} m="auto">
                <Box mb={2}>
                    <Logo width={250} />
                </Box>
                <>
                    <p>
                        <FormattedMessage
                            defaultMessage="Welkom bij de Travelbase partnerapplicatie van {brandName}!"
                            values={{ brandName }}
                        />
                    </p>
                    <p>
                        <FormattedMessage defaultMessage="Kies hieronder een wachtwoord voor je gebruikersaccount." />
                    </p>
                    <Formik<Values>
                        validationSchema={setPasswordSchema}
                        initialValues={{ password: '', passwordConfirm: '' }}
                        onSubmit={async ({ password }, { setSubmitting }) => {
                            try {
                                await mutate({
                                    variables: {
                                        token,
                                        newPassword: password,
                                    },
                                });
                            } finally {
                                setSubmitting(false);
                            }
                        }}
                    >
                        {bag => <SetForm {...bag} mutationError={error} />}
                    </Formik>
                </>
            </Box>
        </Container>
    );
};

const SetForm: FC<React.PropsWithChildren<FormikProps<Values> & { mutationError?: ApolloError }>> = ({
    isSubmitting,
    setFieldError,
    mutationError,
    errors,
}) => {
    const { formatMessage } = useIntl();
    useEffect(() => {
        if (!errors.passwordConfirm && mutationError) {
            const graphqlError = mutationError?.graphQLErrors[0];

            setFieldError('passwordConfirm', graphqlError ? graphqlError.message : mutationError.message);
        }
    }, [mutationError, errors.passwordConfirm, setFieldError]);

    return (
        <Form>
            <Box mb={4}>
                <TextInputField
                    name="password"
                    type="password"
                    placeholder={formatMessage({ defaultMessage: 'Nieuw wachtwoord' })}
                    disabled={isSubmitting}
                />
            </Box>
            <Box mb={4}>
                <TextInputField
                    name="passwordConfirm"
                    type="password"
                    placeholder={formatMessage({ defaultMessage: 'Herhaal nieuw wachtwoord' })}
                    disabled={isSubmitting}
                />
            </Box>
            <SetPasswordButton submitting={isSubmitting} disabled={isSubmitting} type="submit">
                <FormattedMessage defaultMessage="Wachtwoord opslaan" />
            </SetPasswordButton>
        </Form>
    );
};

export default PasswordSet;

const Container = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
`;

const SetPasswordButton = styled(Button)`
    margin-top: 2rem;
    width: 100%;
`;
