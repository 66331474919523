import { useQuery } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { NetworkStatus } from '../apollo';
import { PartnerParams } from '../entities/PartnerParams';
import { PageInfo, Partner } from '../generated/graphql';

type ConnectionType = {
    totalCount: number;
    edges?: Maybe<unknown[]>;
    pageInfo: PageInfo;
};

type ListQuery = { __typename?: 'PartnerQuery' } & {
    partner?: Maybe<
        { __typename?: 'Partner' } & Pick<Partner, 'id'> & {
                [type: string]: ConnectionType | null | undefined | string | 'Partner';
            }
    >;
};
type ListQueryVariables = { first?: number | null; partnerId: string };

/**
 * Convenience hook that abstracts common logic among the "list" queries that use the cursor technique.
 */
function useInfiniteListQuery<T extends ListQuery, V extends ListQueryVariables>(opts: {
    query: DocumentNode;
    /**
     * connection field that represents the type of connection
     */
    field: keyof Omit<NonNullable<T['partner']>, 'id' | '__typename'>;
    variables?: Omit<V, 'partnerId'>;
}) {
    const { query, field, variables } = opts;
    const { partnerId } = useParams<PartnerParams>();
    const { fetchMore, loading, data, networkStatus } = useQuery<T, V>(query, {
        variables: { ...variables, partnerId } as V,
    });

    const connection = data?.partner?.[field as string] as ConnectionType | undefined;
    const hasNextPage = !!connection?.pageInfo.hasNextPage;
    const isNextPageLoading = networkStatus === NetworkStatus.fetchMore;
    const onLoadMore = useCallback(async () => {
        if (loading) {
            return;
        }

        await fetchMore({
            variables: { after: connection?.pageInfo.endCursor },
            updateQuery: (prev: T, { fetchMoreResult }): T => {
                const prevConnection = prev.partner?.[field as string] as ConnectionType | undefined;
                if (!fetchMoreResult || !prev.partner || !prevConnection) {
                    return prev;
                }

                const resultConnection = fetchMoreResult?.partner?.[field as string] as ConnectionType | undefined;
                const newEdges = resultConnection?.edges;
                const pageInfo = resultConnection?.pageInfo;

                return {
                    ...prev,
                    partner: {
                        ...prev.partner,
                        [field]: prevConnection
                            ? {
                                  ...prevConnection,
                                  edges: [...(prevConnection.edges ?? []), ...(newEdges ?? [])],
                                  pageInfo: pageInfo ?? prevConnection.pageInfo,
                              }
                            : null,
                    },
                };
            },
        });
    }, [loading, fetchMore, connection, field]);

    const loadMoreItems = isNextPageLoading ? () => null : onLoadMore;

    return {
        loadMoreItems,
        hasNextPage,
        data,
        isRefetching: networkStatus === NetworkStatus.refetch,
        isLoading: loading,
    };
}

export default useInfiniteListQuery;
