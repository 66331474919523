import Sidebar from '@oberoninternal/travelbase-ds/components/layout/Sidebar';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import composeRefs from '@seznam/compose-react-refs';
import gql from 'graphql-tag';
import React, {
    ComponentType,
    DOMAttributes,
    PropsWithChildren,
    RefObject,
    useCallback,
    useRef,
    useState,
} from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { ListChildComponentProps, ListOnItemsRenderedProps } from 'react-window';
import styled from 'styled-components/macro';
import { PriceColumnAllotmentFragment, PricesQueryVariables, PricingFragment } from '../../generated/graphql';
import { PricingBag } from '../../hooks/usePricingBag';
import Virtualizer, { VirtualizerProps } from '../atoms/Virtualizer';
import Allotments from '../molecules/Allotments';
import Infobar from '../molecules/Infobar';
import SidebarContent from '../molecules/SidebarContent';

export const fragment = gql`
    fragment Pricing on RentalUnit {
        id

        ...PricesRowVisibility

        allotments(startDate: $start, endDate: $end) @connection(key: "allotments") {
            ...PriceColumnAllotment
        }

        bookings(startDate: $start, endDate: $end) {
            ...AllotmentsBooking
        }

        allotmentLockouts(startDate: $start, endDate: $end) {
            ...AllotmentsLockout
        }
    }
`;

type PickedVirtualizerProps<D> = Pick<
    VirtualizerProps<ComponentType<React.PropsWithChildren<PricingColumnProps<D>>>>,
    'itemHeight' | 'initialIndex' | 'ColumnComponent' | 'innerRef' | 'infobarChildren' | 'infiniteLoaderRef'
>;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Props<D, Q> extends PickedVirtualizerProps<D> {
    data: D;
    bag: PricingBag;
    onSidebarClose?: () => void;
    onItemsRendered?: (props: ListOnItemsRenderedProps) => void;
    maxAllotment: number;
    initialIndex?: number;
    showAllotmentLockouts?: boolean;
}

interface PricingColumnProps<D> extends ListChildComponentProps {
    data: D;
}

export interface PricingColumnData {
    allotments: PriceColumnAllotmentFragment[];
    maxAllotment: number;
    showAllotmentLockouts?: boolean;
    loadedPeriod: Interval | null;
    loadedPeriods: Interval[];
    lockoutCreationProps: DOMAttributes<HTMLDivElement>;
    initialVars?: PricesQueryVariables;
    getDayEvents: PricingBag['getDayEvents'];
}

export interface PricingQueryShape {
    rentalUnit?: PricingFragment | null;
}

const Pricing = <D extends PricingColumnData, Q extends PricingQueryShape>({
    children,
    infobarChildren,
    onSidebarClose,
    bag,
    ColumnComponent,
    data: itemData,
    onItemsRendered: propsOnItemsRendered,
    maxAllotment,
    innerRef,
    showAllotmentLockouts,
    ...rest
}: PropsWithChildren<Props<D, Q>>) => {
    const {
        sidebar: [state, dispatch],
        loadMoreItems,
        variables,
        data,
        document,
        isItemLoaded,
        columnWidth,
    } = bag;

    const innerContainerRef = useRef<HTMLDivElement>(null);
    const [itemsRendered, setItemsRendered] = useState<ListOnItemsRenderedProps | null>(null);

    const onCloseSidebar = useCallback(() => {
        if (onSidebarClose) {
            onSidebarClose();
        } else {
            dispatch({ type: 'close' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Virtualizer
            isItemLoaded={isItemLoaded}
            loadMoreItems={loadMoreItems}
            ColumnComponent={ColumnComponent}
            columnData={itemData}
            columnWidth={columnWidth}
            innerRef={composeRefs(innerRef, innerContainerRef) as RefObject<HTMLDivElement>}
            infobarChildren={
                <Infobar
                    rentalUnit={data?.rentalUnit}
                    start={variables?.start}
                    end={variables?.end}
                    rentalUnitId={data?.rentalUnit?.id}
                    headerChildren={
                        <AllotmentHeader>
                            <Label>
                                <FormattedMessage defaultMessage="Beschikbaarheid" />
                            </Label>
                        </AllotmentHeader>
                    }
                >
                    {infobarChildren}
                </Infobar>
            }
            onItemsRendered={items => {
                propsOnItemsRendered?.(items);
                setItemsRendered(items);
            }}
            {...rest}
        >
            {children}

            <Sidebar onClose={onCloseSidebar} open={state.open} hidden={state.hidden}>
                <SidebarContent data={state.data} />
            </Sidebar>
            {maxAllotment === 1 &&
                showAllotmentLockouts &&
                itemsRendered &&
                innerContainerRef.current &&
                createPortal(
                    <Allotments
                        columnWidth={columnWidth}
                        bookings={data?.rentalUnit?.bookings ?? []}
                        lockouts={data?.rentalUnit?.allotmentLockouts ?? []}
                        itemsRendered={itemsRendered}
                        variables={variables}
                        document={document}
                    />,
                    innerContainerRef.current
                )}
        </Virtualizer>
    );
};

export default Pricing;

const AllotmentHeader = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    margin-top: 2.6rem;

    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        align-items: flex-start;
        margin-top: 0;
    }
`;
