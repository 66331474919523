import { FormikProps } from 'formik';
import React, { CSSProperties, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import Button, { ButtonSize } from '../action/Button';
import { getAssignmentColor } from '../../constants/theme';

type ContentWrapperVariant = 'sidebar' | 'info' | 'default' | 'wizard';

interface Props {
    confirmMessage?: string | ReactNode;
    dataCyReset?: string;
    dataCyButton?: string;
    resetText?: ReactNode;
    buttonText?: ReactNode;
    buttonSize?: ButtonSize;
    alwaysVisible?: boolean;
    variant?: ContentWrapperVariant;
    style?: CSSProperties;
    isSimpleButton?: boolean;
}

function FormActions<T>({
    resetForm,
    isSubmitting,
    dirty,
    alwaysVisible = false,
    variant = 'default',
    style,
    confirmMessage = 'Zeker weten?',
    resetText,
    buttonSize = 'regular',
    buttonText,
    dataCyButton,
    dataCyReset,
    isSimpleButton,
}: FormikProps<T> & Props) {
    return (
        <FormActionsContainer
            dirty={dirty}
            alwaysVisible={alwaysVisible}
            variant={variant}
            style={style}
            isSimpleButton={isSimpleButton}
        >
            {!isSimpleButton && (
                <Reset
                    href="#"
                    dirty={dirty}
                    data-cy={dataCyReset}
                    onClick={e => {
                        e.preventDefault();
                        if (!dirty) {
                            return;
                        }

                        if (window.confirm(`${confirmMessage}`)) {
                            resetForm();
                        }
                    }}
                >
                    {resetText ?? 'Wijzigingen ongedaan maken'}
                </Reset>
            )}
            <Button
                submitting={isSubmitting}
                disabled={isSubmitting || !dirty}
                variant="primary"
                type="submit"
                size={buttonSize}
                data-cy={dataCyButton}
            >
                {buttonText ?? 'Publiceren'}
            </Button>
        </FormActionsContainer>
    );
}

export const FormActionsContainer = styled.div<{
    dirty?: boolean;
    alwaysVisible?: boolean;
    variant?: ContentWrapperVariant;
    isSimpleButton?: boolean;
}>`
    padding: ${({ isSimpleButton }) => (isSimpleButton ? '2.4rem 0' : '2.4rem 3.4rem')};
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    position: ${({ isSimpleButton }) => (isSimpleButton ? 'relative' : 'sticky')};
    background: ${({ theme }) => theme.colors.neutral[0]};
    box-sizing: border-box;
    bottom: 0;
    box-shadow: ${({ isSimpleButton, theme }) => (isSimpleButton ? 'none' : `0 -1px 0 0 ${theme.colors.neutral[20]}`)};
    @media (max-width: ${({ theme }) => theme.maxWidths.m}px) {
        bottom: ${({ variant = 'default' }) => (variant === 'sidebar' || variant === 'default' ? 0 : '7.2rem')};
        ${({ alwaysVisible, dirty }) =>
            !alwaysVisible &&
            css`
                transition: transform 200ms, opacity 200ms;
                opacity: ${dirty ? 1 : 0};
                transform: translateY(${dirty ? '0%' : '100%'});
            `}
        margin-top: ${({ dirty }) => (dirty ? '8rem' : 0)};
        padding: 1.2rem 1.6rem;
    }
`;

const Reset = styled.a<{ dirty: boolean }>`
    color: ${({ theme, dirty }) =>
        dirty ? getAssignmentColor(theme, theme.colorAssignments.form) : theme.colors.neutral[30]};
    transition: color 200ms;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.4rem;
`;

export default FormActions;
