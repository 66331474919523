import styled from 'styled-components/macro';
import { Seperator } from './Seperator';

const TableRowSeperator = styled(Seperator).attrs({ variant: 'small' })`
    @media (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        margin: 1.6rem 0;
    }
`;

export default TableRowSeperator;
