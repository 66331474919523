import { Field, FieldProps, getIn } from 'formik';
import React, { FC, InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { getAssignmentColor } from '../../constants/theme';
import { ColorAssignment } from '../../themes/default';

interface SwitchProps extends InputHTMLAttributes<HTMLInputElement> {
    error?: boolean;
}

const Switch: FC<React.PropsWithChildren<SwitchProps>> = ({ error, value, id, ...props }) => (
    <label htmlFor={id}>
        <Checkbox {...props} type="checkbox" id={id} />
        <Label hasError={error} htmlFor={id}>
            <Button />
        </Label>
    </label>
);

export const SwitchField: FC<React.PropsWithChildren<InputHTMLAttributes<HTMLInputElement>>> = ({ name, ...props }) => (
    <Field name={name}>
        {({ field, form: { errors, touched, values } }: FieldProps) => (
            <Switch
                checked={getIn(values, field.name)}
                error={touched[field.name] && errors[field.name] ? getIn(errors, field.name) : undefined}
                {...props}
                {...field}
            />
        )}
    </Field>
);

export default Switch;

const Checkbox = styled.input`
    opacity: 0;
    position: absolute;
`;

const Button = styled.span`
    box-shadow: 0 0 0 1px var(--border-color), inset 0 0 0 1px var(--border-color);
    --border-color: ${({ theme }) => theme.colors.primary['5']};
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    transition: transform 0.2s, left 0.2s;
    background: ${({ theme }) => theme.colors.neutral['5']};
`;

const colorHelper = (
    color: { assignment: ColorAssignment; step?: number },
    errorColor: ColorAssignment,
    background?: boolean
) => css<LabelProps>`
    ${({ hasError, theme }) =>
        hasError && background
            ? `background: ${getAssignmentColor(theme, errorColor)};`
            : background
            ? `background: ${getAssignmentColor(theme, color.assignment, color.step)};`
            : ''}
    --border-color: ${({ hasError, theme }) =>
        getAssignmentColor(theme, !hasError ? color.assignment : errorColor, !hasError ? color.step : undefined)};
    ${Button} {
        --border-color: ${({ hasError, theme }) =>
            getAssignmentColor(theme, !hasError ? color.assignment : errorColor, !hasError ? color.step : undefined)};
    }
`;

interface LabelProps {
    hasError?: boolean;
}

const Label = styled.label<LabelProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 36px;
    height: 24px;
    background: ${({ theme, hasError }) =>
        !hasError ? getAssignmentColor(theme, theme.colorAssignments.input, -2) : theme.colors.negative['30']};
    border-radius: 24px;
    position: relative;
    transition: background-color 0.2s;
    box-shadow: 0 0 0 0 var(--border-color), inset 0 0 0 2px var(--border-color);
    user-select: none;

    ${Button} {
        --border-color: ${({ theme, hasError }) =>
            !hasError ? getAssignmentColor(theme, theme.colorAssignments.input, -2) : theme.colors.negative['40']};
    }

    ${Checkbox}:focus + &,
    ${Checkbox}:hover + & {
        ${({ theme }) => colorHelper({ assignment: theme.colorAssignments.input }, ['negative', 50])}
    }

    ${Checkbox}:checked + & {
        ${({ theme }) => colorHelper({ assignment: theme.colorAssignments.input }, ['negative', 50], true)}
        ${Button} {
            transform: translateX(14px);
        }
    }

    ${Checkbox}:checked:hover + &,
    ${Checkbox}:checked:focus + & {
        ${({ theme }) => colorHelper({ assignment: theme.colorAssignments.input, step: 1 }, ['negative', 60])}
    }

    ${Checkbox}:disabled + & {
        cursor: not-allowed;
        ${colorHelper({ assignment: ['neutral', 20] }, ['neutral', 20], true)}
    }

    ${Checkbox}:checked:disabled + & {
        ${colorHelper({ assignment: ['neutral', 30] }, ['neutral', 30], true)}
    }
`;
