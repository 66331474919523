import DocExport from '@oberoninternal/travelbase-ds/components/figure/DocExport';
import DocImport from '@oberoninternal/travelbase-ds/components/figure/DocImport';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components/macro';
import { PRICE_COLUMN_ROW_COUNT } from '../../constants/priceRows';
import { useSidebar } from '../../context/sidebar';
import { Scalars, TripsQueryVariables } from '../../generated/graphql';
import { CELL_HEIGHT } from './Cell';
import { InfobarCell } from './Infobar';
import SkeletonCell from './SkeletonCell';

interface Props {
    tripLabels: string[];
    loading: boolean;
    exportUrl: string | null;
    rentalUnitId: Scalars['ID']['output'] | null;
    variables?: TripsQueryVariables;
    loadedPeriod: Interval | null;
}

const TripInfobar: FC<React.PropsWithChildren<Props>> = ({
    tripLabels,
    loading,
    exportUrl,
    rentalUnitId,
    variables,
    loadedPeriod,
}) => {
    const [, dispatch] = useSidebar();

    return (
        <>
            {loading &&
                new Array(PRICE_COLUMN_ROW_COUNT)
                    .fill(SkeletonCell)
                    .map((E, i) => <E key={i} i={i} style={{ justifyContent: 'flex-start' }} />)}
            {!loading &&
                tripLabels.map(label => (
                    <InfobarCell key={label}>
                        <Label>{label}</Label>
                    </InfobarCell>
                ))}
            <Docs>
                {exportUrl ? (
                    <StyledLabel as="a" href={exportUrl}>
                        <DocExport />
                        <span>
                            <FormattedMessage defaultMessage="Exporteren" />
                        </span>
                    </StyledLabel>
                ) : (
                    <StyledLabel>
                        <Skeleton width={120} />
                    </StyledLabel>
                )}

                {rentalUnitId ? (
                    <StyledLabel
                        role="button"
                        onClick={() =>
                            dispatch({
                                type: 'show',
                                data: {
                                    type: 'IMPORT_INFO',
                                    loadedPeriod,
                                    rentalUnitId,
                                    variables,
                                    hasPrices: !!exportUrl,
                                },
                            })
                        }
                    >
                        <DocImport />
                        <span>
                            <FormattedMessage defaultMessage="Importeren" />
                        </span>
                    </StyledLabel>
                ) : (
                    <StyledLabel>
                        <Skeleton width={120} />
                    </StyledLabel>
                )}
            </Docs>
        </>
    );
};

const Docs = styled.div`
    min-height: ${CELL_HEIGHT}px;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;

    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        padding-top: 2rem;
        display: none;
        justify-content: flex-start;
    }
`;

const StyledLabel = styled(Label)`
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 1rem 1.6rem;
    color: inherit;
    position: relative;
    margin-bottom: 0;
    cursor: pointer;

    * + * {
        margin-left: 0.8rem;
    }
`;

export default TripInfobar;
