import React, { forwardRef } from 'react';
import RoundButton from '../action/RoundButton';
import Expand from '../figure/Expand';

const DragHandle = forwardRef<HTMLButtonElement>((props, ref) => (
    <RoundButton
        className="dragButton"
        style={{ cursor: 'move' }}
        as="span"
        variant="outline-inverted"
        ref={ref}
        onClick={e => e.stopPropagation()}
        {...props}
    >
        <Expand />
    </RoundButton>
));

export default DragHandle;
