import { Flex } from '@rebass/grid';
import React, { FC, useState } from 'react';
import { generatePath, Route, useLocation, useRouteMatch } from 'react-router-dom';
import { ActivityParams } from '../../../entities/ActivityParams';
import InfoContent from '../../atoms/InfoContent';
import ItemDesktopRoute from '../../atoms/ItemDesktopRoute';
import ItemMobileRoute from '../../atoms/ItemMobileRoute';
import InfoMobileMenu from '../../molecules/InfoMobileMenu';
import InfoSideContainer from '../../molecules/InfoSideContainer';
import NotFound from '../NotFound';
import Index from './hours/Index';
import SpecialHours from './hours/SpecialHours';
import ErrorBoundary from '../../organisms/ErrorBoundary';
import { LocalizedSwitch } from '../../atoms/LocalizedSwitch';

const routes = [
    { name: 'Openingstijden', source: '' },
    { name: 'Afwijkingen', source: 'special' },
];

const Hours: FC<React.PropsWithChildren<unknown>> = () => {
    const { pathname } = useLocation();
    const { path, params } = useRouteMatch<ActivityParams>();
    const fullPath = (shortPath: string) => generatePath(shortPath, { ...params });
    const [open, setOpen] = useState(false);

    const first = [...routes].reverse().find(({ source }) => pathname.endsWith(source)) ?? routes[0];

    return (
        <Flex width={1}>
            <InfoSideContainer>
                {routes.map(route => (
                    <ItemDesktopRoute key={route.name} path={fullPath(`${path}/${route.source}`)}>
                        {route.name}
                    </ItemDesktopRoute>
                ))}
            </InfoSideContainer>

            <InfoContent>
                <InfoMobileMenu open={open} setOpen={setOpen}>
                    {[first, ...routes.filter(route => route.name !== first.name)].map(route => (
                        <ItemMobileRoute
                            key={route.name}
                            open={open}
                            setOpen={setOpen}
                            path={fullPath(`${path}/${route.source}`)}
                        >
                            {route.name}
                        </ItemMobileRoute>
                    ))}
                </InfoMobileMenu>
                <ErrorBoundary>
                    <LocalizedSwitch>
                        <Route path={fullPath(`${path}`)} exact render={() => <Index {...params} />} />
                        <Route path={fullPath(`${path}/special`)} component={() => <SpecialHours {...params} />} />
                        <Route component={NotFound} />
                    </LocalizedSwitch>
                </ErrorBoundary>
            </InfoContent>
        </Flex>
    );
};

export default Hours;
