import TextInput, { TextInputFieldProps } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import { FieldConfig, useField } from 'formik';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

export interface NumberInputProps
    extends Omit<TextInputFieldProps, 'name' | 'value' | 'children' | 'type'>,
        FieldConfig {
    isCurrency?: boolean;
    step?: number;
    nullable?: boolean;
}

const NumberInput: FC<React.PropsWithChildren<NumberInputProps>> = ({
    isCurrency,
    step = 0.01,
    nullable,
    ...props
}) => {
    const [{ value, onChange, onBlur }, { error, touched }, { setValue }] = useField({
        name: props.name,
        validate: props.validate,
    });
    const { formatMessage } = useIntl();
    return (
        <TextInput
            value={nullable && value === null ? '' : value}
            onChange={e => {
                if (nullable) {
                    setValue(e.currentTarget.value === '' ? null : parseFloat(e.currentTarget.value));
                } else {
                    onChange(e);
                }
            }}
            onBlur={onBlur}
            error={touched && error ? error : undefined}
            type="number"
            // eslint-disable-next-line formatjs/no-literal-string-in-jsx
            placeholder={isCurrency ? '--,--' : formatMessage({ defaultMessage: 'aantal' })}
            step={isCurrency ? step : 1}
            {...props}
        />
    );
};

export default NumberInput;
