import React, { FC, SyntheticEvent } from 'react';
import styled from 'styled-components/macro';
import BaseCell, { CellProps } from '../molecules/Cell';
import Switch from '@oberoninternal/travelbase-ds/components/form/Switch';

interface Props extends CellProps {
    onClick: () => void;
    skipPreventingDefault?: boolean;
    value: boolean;
    disabled?: boolean;
    switchId: string;
    selected?: { width?: number; newValue: boolean | '' | number | null } | undefined;
}

const SwitchCell: FC<React.PropsWithChildren<Props>> = ({
    onClick,
    value,
    switchId,
    disabled,
    selected,
    skipPreventingDefault,
    ...props
}) => {
    const onSave = (e: SyntheticEvent) => {
        if (!skipPreventingDefault) {
            e.preventDefault();
        }
        onClick();
    };
    return (
        <Cell {...props} selectedWidth={selected?.width}>
            <form onSubmit={onSave}>
                <Switch
                    onChange={onSave}
                    checked={selected ? !!selected.newValue : value}
                    id={switchId}
                    disabled={disabled}
                />
            </form>
        </Cell>
    );
};

const Cell = styled(BaseCell)`
    > * {
        cursor: pointer;
    }
`;

export default SwitchCell;
