import { ImageErrorType } from '@oberoninternal/travelbase-ds/components/form/UploadImage';
import {
    MAIN_IMAGE_MIN_RATIO,
    MAIN_IMAGE_MAX_RATIO,
    DETAIL_IMAGE_MIN_RATIO,
    DETAIL_IMAGE_MAX_RATIO,
    LOGO_IMAGE_MAX_RATIO,
    LOGO_IMAGE_MIN_RATIO,
    DETAIL_IMAGE_TOTAL_PIXELS_ERROR,
    DETAIL_IMAGE_TOTAL_PIXELS_WARNING,
} from '../constants/imageRequirements';

export const messages = {
    LISTIMAGE: {
        SMALL: 'Travelbase accepteert geen lijstfoto kleiner dan 800x600 pixels',
        RATIO: 'De lijstfoto moet liggend zijn en de verhouding tussen standaard (4:3) en breedbeeld (16:9)',
        RESOLUTION: 'We raden je aan om deze foto te vervangen met een foto van hogere resolutie (1600x1200 of hoger)',
    },
    MAINIMAGE: {
        SMALL: 'Travelbase accepteert geen hoofdfoto kleiner dan 1280x720 pixels',
        RATIO: 'De hoofdfoto moet liggend zijn en de verhouding tussen standaard (4:3) en breedbeeld (16:9)',
        RESOLUTION: 'We raden je aan om deze foto te vervangen met een foto van hogere resolutie (1920x1080 of hoger)',
    },
    DETAILIMAGE: {
        SMALL: 'Travelbase accepteert geen foto kleiner dan 800x600 pixels (staand of liggend)',
        RATIO: 'De verhouding tussen de lengte en breedte van de foto mag niet groter dan 1:2 zijn',
        RESOLUTION:
            'We raden je aan om deze foto te vervangen met een foto van hogere resolutie (1600x1200/1200x1600 of hoger)',
    },
    LOGOIMAGE: {
        SMALL: 'Travelbase accepteert geen hoofdfoto kleiner dan 200x200 pixels',
        RATIO: 'De verhouding van het logo moet tussen vierkant (1:1) en liggend zijn (4:3)',
        RESOLUTION:
            'We raden je aan om deze foto te vervangen met een foto van hogere resolutie (500x500 pixels of hoger)',
    },
};

const validateImageResolution = (
    width: number,
    height: number,
    type: 'LISTIMAGE' | 'MAINIMAGE' | 'DETAILIMAGE' | 'LOGOIMAGE',
    isFreshlyUploaded?: boolean
) => {
    const imageErrors: ImageErrorType[] = [];

    switch (type) {
        case 'LISTIMAGE':
            if (width < 800 || height < 600) {
                imageErrors.push({
                    message: messages.LISTIMAGE.SMALL,
                    type: isFreshlyUploaded ? 'error' : 'warning',
                });
            }
            if (width / height < MAIN_IMAGE_MIN_RATIO || width / height > MAIN_IMAGE_MAX_RATIO) {
                imageErrors.push({
                    message: messages.LISTIMAGE.RATIO,
                    type: isFreshlyUploaded ? 'error' : 'warning',
                });
            }
            if (width < 1600 || height < 1200) {
                imageErrors.push({
                    message: messages.LISTIMAGE.RESOLUTION,
                    type: 'warning',
                });
            }
            break;
        case 'MAINIMAGE':
            if (width < 1280 || height < 720) {
                imageErrors.push({
                    message: messages.MAINIMAGE.SMALL,
                    type: isFreshlyUploaded ? 'error' : 'warning',
                });
            }
            if (width / height < MAIN_IMAGE_MIN_RATIO || width / height > MAIN_IMAGE_MAX_RATIO) {
                imageErrors.push({
                    message: messages.MAINIMAGE.RATIO,
                    type: isFreshlyUploaded ? 'error' : 'warning',
                });
            }
            if (width < 1920 || height < 1080) {
                imageErrors.push({
                    message: messages.MAINIMAGE.RESOLUTION,
                    type: 'warning',
                });
            }
            break;
        case 'DETAILIMAGE':
            if (width * height < DETAIL_IMAGE_TOTAL_PIXELS_ERROR) {
                imageErrors.push({
                    message: messages.DETAILIMAGE.SMALL,
                    type: isFreshlyUploaded ? 'error' : 'warning',
                });
            }
            if (width / height < DETAIL_IMAGE_MIN_RATIO || width / height > DETAIL_IMAGE_MAX_RATIO) {
                imageErrors.push({
                    message: messages.DETAILIMAGE.RATIO,
                    type: isFreshlyUploaded ? 'error' : 'warning',
                });
            }
            if (width * height < DETAIL_IMAGE_TOTAL_PIXELS_WARNING) {
                imageErrors.push({
                    message: messages.DETAILIMAGE.RESOLUTION,
                    type: 'warning',
                });
            }
            break;
        case 'LOGOIMAGE':
            if (width < 200 || height < 200) {
                imageErrors.push({
                    message: messages.LOGOIMAGE.SMALL,
                    type: isFreshlyUploaded ? 'error' : 'warning',
                });
            }
            if (width / height < LOGO_IMAGE_MIN_RATIO || width / height > LOGO_IMAGE_MAX_RATIO) {
                imageErrors.push({
                    message: messages.LOGOIMAGE.RATIO,
                    type: isFreshlyUploaded ? 'error' : 'warning',
                });
            }

            if (width < 500 || height < 500) {
                imageErrors.push({
                    message: messages.LOGOIMAGE.RESOLUTION,
                    type: 'warning',
                });
            }
            break;
        default:
            break;
    }
    return imageErrors;
};

export default validateImageResolution;
