import { TitleVariant } from '../components/primitive/Title';
import { BodyVariant } from '../components/primitive/Body';
import { FontStyle } from '../constants/theme';

/**
 * The Partners theme.
 * Other themes generally derive from this theme in one way or another.
 */

const titleStyles: Record<TitleVariant, FontStyle> = {
    large: {
        size: { min: 19, max: 40 },
        line: { min: 21, max: 40 },
        letterSpacing: -1.2,
    },
    regular: {
        size: { min: 19, max: 30 },
        line: { min: 28, max: 32 },
        letterSpacing: -0.6,
    },
    small: {
        size: { min: 16, max: 16 },
        line: { min: 16, max: 16 },
        letterSpacing: -0.2,
    },
    tiny: {
        size: { min: 14, max: 14 },
        line: { min: 16, max: 16 },
        letterSpacing: 0,
    },
    micro: {
        size: { min: 12, max: 12 },
        line: { min: 16, max: 16 },
        letterSpacing: 0,
    },
};

const bodyStyles: Record<BodyVariant, FontStyle> = {
    huge: {
        size: { min: 24, max: 28 },
        line: { min: 30, max: 34 },
    },
    large: {
        size: { min: 20, max: 24 },
        line: { min: 24, max: 32 },
        letterSpacing: 0,
    },
    regular: {
        size: { min: 14, max: 17 },
        line: { min: 20, max: 28 },
        letterSpacing: 0.15,
    },
    small: {
        size: { min: 13, max: 14 },
        line: { min: 24, max: 24 },
        letterSpacing: 0,
    },
    tiny: {
        size: { min: 12, max: 12 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
    },
    micro: {
        size: { min: 10, max: 10 },
        line: { min: 20, max: 20 },
        letterSpacing: 0,
    },
};

export type ColorStep = 0 | 5 | 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90 | 100;

export type ColorEntries = Record<ColorStep, string>;

export type ColorVariant = 'primary' | 'secondary' | 'tertiary' | 'neutral' | 'positive' | 'warning' | 'negative';

export type Color = Record<ColorStep, string>;

export type ColorAssignment = [ColorVariant, ColorStep];

export type Colors = Record<ColorVariant, Color>;

export type SpacingStep =
    | '00_None'
    | '10_Micro'
    | '20_Tiny'
    | '30_Small'
    | '40_Standard'
    | '50_Semi'
    | '60_Large'
    | '70_XLarge'
    | '80_XXLarge';

export type Spacing = Record<SpacingStep, string>;

const spacing: Spacing = {
    '00_None': '0px',
    '10_Micro': '2px',
    '20_Tiny': '4px',
    '30_Small': '8px',
    '40_Standard': '16px',
    '50_Semi': '32px',
    '60_Large': '40px',
    '70_XLarge': '48px',
    '80_XXLarge': '56px',
};

const primary: Color = {
    0: '#f3fbff',
    5: '#d8f1fd',
    10: '#bfe7fc',
    20: '#8cd2f6',
    30: '#5fbded',
    40: '#3ba8e1',
    50: '#2092d0',
    60: '#0d7cbb',
    70: '#01659f',
    80: '#004e7e',
    90: '#003659',
    100: '#001f33',
};

const colors: Colors = {
    primary,
    tertiary: primary,
    // the partnerapp doesn't have secondary colors, so we map them to the primary colors
    secondary: primary,
    neutral: {
        0: '#ffffff',
        5: '#fbfcfc',
        10: '#f7f8f9',
        20: '#e6e9eb',
        30: '#cbd0d4',
        40: '#a4acb2',
        50: '#767f87',
        60: '#48515b',
        70: '#212a36',
        80: '#060e1c',
        90: '#00040d',
        100: '#000208',
    },
    positive: {
        0: '#ebffeb',
        5: '#E2FDE3',
        10: '#d9fbdb',
        20: '#b3f0b5',
        30: '#7ade7e',
        40: '#36c43c',
        50: '#00a308',
        60: '#008207',
        70: '#006606',
        80: '#005206',
        90: '#004705',
        100: '#004206',
    },
    warning: {
        0: '#ffe8be',
        5: '#fed790',
        10: '#fec662',
        20: '#f9ae2c',
        30: '#f1990b',
        40: '#e48800',
        50: '#d37800',
        60: '#be6500',
        70: '#a65000',
        80: '#8c3d00',
        90: '#722c00',
        100: '#591e00',
    },
    negative: {
        0: '#fff1f1',
        5: '#FEEBEB',
        10: '#fde6e6',
        20: '#f8cecd',
        30: '#f2a8a7',
        40: '#e87975',
        50: '#dc463e',
        60: '#cc1a0d',
        70: '#b71100',
        80: '#9f1400',
        90: '#831400',
        100: '#661300',
    },
};

export interface ColorAssignments {
    main: ColorAssignment;
    buttonLight: ColorAssignment;
    buttonPrimary: ColorAssignment;
    textAction: ColorAssignment;
    input: ColorAssignment;
    form: ColorAssignment;
    rating?: ColorAssignment;
    booking?: ColorAssignment;
    special?: ColorAssignment;
    calendar: {
        defaultBackgroundColor: ColorAssignment | string;
        defaultColor: ColorAssignment | string;
        bookable: {
            backgroundColor: ColorAssignment | string;
            color: ColorAssignment | string;
            focusColor: ColorAssignment | string;
        };
        selected: {
            backgroundColor: ColorAssignment | string;
            color: ColorAssignment | string;
            focusColor: ColorAssignment | string;
        };
        bookableOutside: ColorAssignment | string;
        periodBackground: {
            selected: ColorAssignment | string;
            notSelected: ColorAssignment | string;
        };
        withinCss: {
            selected: ColorAssignment | string;
            notSelected: ColorAssignment | string;
        };
        bookableWithin: {
            selected: ColorAssignment | string;
            notSelected: ColorAssignment | string;
        };
        price: ColorAssignment | string;
    };
    searchBox?: {
        color?: ColorAssignment;
        backgroundColor?: ColorAssignment;
    };
    tagButton?: {
        color: ColorAssignment;
        backgroundColor: ColorAssignment;
        borderColor: ColorAssignment;
    };
}

const colorAssignments: ColorAssignments = {
    main: ['primary', 40],
    buttonPrimary: ['primary', 40],
    buttonLight: ['secondary', 30],
    textAction: ['primary', 40],
    input: ['primary', 30],
    form: ['primary', 40],
    calendar: {
        defaultBackgroundColor: ['primary', 40],
        defaultColor: ['neutral', 100],
        bookable: {
            color: ['primary', 60],
            backgroundColor: ['primary', 5],
            focusColor: ['primary', 80],
        },
        selected: {
            backgroundColor: ['primary', 60],
            color: ['neutral', 0],
            focusColor: ['primary', 90],
        },
        bookableOutside: ['primary', 60],
        bookableWithin: {
            selected: ['primary', 60],
            notSelected: ['neutral', 0],
        },
        periodBackground: {
            selected: ['primary', 5],
            notSelected: ['primary', 60],
        },
        withinCss: {
            selected: ['primary', 60],
            notSelected: ['neutral', 0],
        },
        price: ['primary', 60],
    },
};

const theme = {
    name: 'Partners',
    colors,
    colorAssignments,
    fontFamily: {
        body: 'Uniform, sans-serif',
        title: 'Uniform, sans-serif',
    },
    fontWeights: {
        bold: 'bold',
    },
    fontSizes: {
        title: titleStyles,
        body: bodyStyles,
    },
    mediaQueries: {
        xs: '480px',
        s: '768px',
        m: '992px',
        l: '1200px',
        xl: '1440px',
        xxl: '1660px',
        xxxl: '1920px',
    },
    breakpoints: ['480px', '992px', '1440px', '1660px', '1920px'], // rebass
    space: Object.values(spacing),
    spacing,
    mediaQueriesValues: {
        xs: 480,
        s: 768,
        m: 992,
        l: 1200,
        xl: 1440,
        xxl: 1660,
        xxxl: 1920,
    },
    zIndices: {
        hide: -1,
        auto: 'auto',
        base: 0,
        docked: 10,
        dropdown: 1000,
        sticky: 1100,
        banner: 1200,
        overlay: 1300,
        popover: 1500,
        skipLink: 1600,
        toast: 1700,
        tooltip: 1800,
        modal: 99999,
    } as const,
    maxWidths: {
        xs: '464',
        s: '636',
        m: '960',
        l: '1152',
        xl: '1344',
        xxl: '1440',
    },
    heights: {
        nav: 52,
        mobileNav: 64,
        unitNav: 72,
    },
    radius: {
        button: '0.4rem',
        textInput: '0.5rem',
        textArea: '0.5rem',
        dropdown: '0.5rem',
    },
};
export default theme;

const size = {
    xs: '480px',
    s: '768px',
    m: '992px',
    l: '1200px',
    xl: '1440px',
    xxl: '1660px',
    xxxl: '1920px',
};

export const device = {
    xs: `(min-width: ${size.xs})`,
    s: `(min-width: ${size.s})`,
    m: `(min-width: ${size.m})`,
    l: `(min-width: ${size.l})`,
    xl: `(min-width: ${size.xl})`,
    xxl: `(min-width: ${size.xxl})`,
    xxxl: `(min-width: ${size.xxxl})`,
};
