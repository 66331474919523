import styled from 'styled-components/macro';
import { Flex } from '@rebass/grid';

const TableActions = styled(Flex)`
    > button + button {
        margin-left: 2rem;
    }
`;

export default TableActions;
