import { Box } from '@rebass/grid';
import gql from 'graphql-tag';
import React, { FC, ReactElement, useState } from 'react';
import { generatePath, Route, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ActivityParams } from '../../../entities/ActivityParams';
import { CompanyPickerEntryFragment } from '../../../generated/graphql';
import ErrorBoundary from '../../organisms/ErrorBoundary';
import ActivityDetails from './info/ActivityDetails';
import ActivityDescription from './info/ActivityDescription';
import ActivityLocation from './info/ActivityLocation';
import ActivityImages from './info/ActivityImages';
import NotFound from '../NotFound';
import InfoHeading from '../../atoms/InfoHeading';
import InfoMobileMenu from '../../molecules/InfoMobileMenu';
import ItemDesktopRoute from '../../atoms/ItemDesktopRoute';
import ItemMobileRoute from '../../atoms/ItemMobileRoute';
import InfoSideContainer from '../../molecules/InfoSideContainer';
import { LocalizedSwitch } from '../../atoms/LocalizedSwitch';
import { useIntl } from 'react-intl';
import Agreements from "./info/Agreements";

interface InfoRoute {
    name: string;
    source: string;
}

export const activityInfoFragment = gql`
    fragment ActivityInfo on Activity {
        id
        slug
        name
        enabled
    }
`;

interface Props {
    activity?: CompanyPickerEntryFragment;
}

const Info: FC<React.PropsWithChildren<Props>> = ({ activity }) => {
    const { pathname } = useLocation();
    const { path, params } = useRouteMatch<ActivityParams>();
    const [open, setOpen] = useState(false);

    const intl = useIntl();

    const infoRoutes: InfoRoute[] = [
        { name: intl.formatMessage({ defaultMessage: 'Informatie' }), source: '' },
        { name: intl.formatMessage({ defaultMessage: 'Locatie' }), source: 'location' },
        { name: intl.formatMessage({ defaultMessage: 'Beschrijving' }), source: 'description' },
        { name: intl.formatMessage({ defaultMessage: 'Foto’s' }), source: 'photos' },
        { name: intl.formatMessage({ defaultMessage: 'Overeenkomsten' }), source: 'agreements' },
    ];

    const fullPath = (shortPath: string) => generatePath(shortPath, { ...params });

    const renderRouteDesktop = ({ name, source }: InfoRoute) => (
        <ItemDesktopRoute key={`desktop-${source}`} path={fullPath(`${path}/${source}`)}>
            {name}
        </ItemDesktopRoute>
    );

    const renderRouteMobile = ({ name, source }: InfoRoute) => (
        <ItemMobileRoute key={`mobile-${source}`} open={open} setOpen={setOpen} path={fullPath(`${path}/${source}`)}>
            {name}
        </ItemMobileRoute>
    );

    const renderRoutes = (routes: InfoRoute[], renderFunc: (route: InfoRoute) => ReactElement) => [
        activity && <InfoHeading key={`header-${activity?.name}`} name={activity.name} />,
        ...routes.map(renderFunc),
    ];

    const first = [...infoRoutes].reverse().find(({ source }) => pathname.endsWith(source)) ?? infoRoutes[0];

    return (
        <Container>
            <InfoSideContainer>{renderRoutes(infoRoutes, renderRouteDesktop)}</InfoSideContainer>
            <Box pl={[null, null, '31.2rem']} width={1}>
                <InfoMobileMenu open={open} setOpen={setOpen}>
                    {open ? renderRoutes(infoRoutes, renderRouteMobile) : [renderRouteMobile(first)]}
                </InfoMobileMenu>

                <ErrorBoundary>
                    <LocalizedSwitch>
                        <Route path={fullPath(`${path}`)} exact render={() => <ActivityDetails {...params} />} />
                        <Route
                            path={fullPath(`${path}/description`)}
                            render={() => <ActivityDescription {...params} />}
                        />
                        <Route path={fullPath(`${path}/location`)} render={() => <ActivityLocation {...params} />} />
                        <Route path={fullPath(`${path}/photos`)} render={() => <ActivityImages {...params} />} />
                        <Route path={fullPath(`${path}/agreements`)} render={() => <Agreements {...params} />} />
                        <Route component={NotFound} />
                    </LocalizedSwitch>
                </ErrorBoundary>
            </Box>
        </Container>
    );
};

export default Info;

const Container = styled(Box).attrs({ width: '1' })`
    display: flex;
`;
