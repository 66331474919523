import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import dateFormat from '../constants/dateFormat';
import epoch from '../constants/epoch';
import { ScrollData } from '../context/virtualizerScrollData';

const getDatesFromScrollContext = (scrollData: ScrollData | null, overscans = false) => {
    if (!scrollData?.itemsRendered.current) {
        return undefined;
    }
    const startDate = addDays(
        epoch,
        scrollData.itemsRendered.current[overscans ? 'overscanStartIndex' : 'visibleStartIndex']
    );
    const endDate = addDays(
        epoch,
        scrollData.itemsRendered.current[overscans ? 'overscanStopIndex' : 'visibleStopIndex']
    );

    return {
        startDate: format(startDate, dateFormat),
        endDate: format(endDate, dateFormat),
    };
};

export default getDatesFromScrollContext;
