import styled, { css } from 'styled-components';
import { Box, BoxProps } from '@rebass/grid';
import React, { forwardRef } from 'react';
import isBefore from 'date-fns/isBefore';
import isToday from 'date-fns/isToday';
import startOfToday from 'date-fns/startOfToday';
import { AllotmentIndicatorContainer } from './AllotmentIndicator';

interface Props extends Omit<BoxProps, 'css'> {
    // is this day 'outside' of the current month?
    outside?: boolean;

    disabled?: boolean;
    date: Date;
}

const Day = forwardRef(({ children, ...props }: Props, ref) => (
    <Container width={1} data-date={!props.outside ? props.date.toISOString() : undefined} ref={ref} {...props}>
        <p>{props.date.toLocaleDateString('nl-NL', { day: 'numeric' })}</p>
        {children}
    </Container>
));

const pastCss = css`
    background: ${({ theme }) => theme.colors.neutral['10']};

    p {
        color: ${({ theme }) => theme.colors.neutral['30']};
        &:after {
            content: '';
            background: ${({ theme }) => theme.colors.neutral['30']};
            height: 1.23px;
            top: 50%;
            left: 3px;
            right: 3px;
            position: absolute;
            transform: rotate(-43deg);
        }
    }

    ${AllotmentIndicatorContainer} {
        display: none;
    }
`;

const disabledCss = css`
    background: ${({ theme }) => theme.colors.neutral['10']};

    p {
        color: ${({ theme }) => theme.colors.neutral['30']};
        background: inherit;
    }

    ${AllotmentIndicatorContainer} {
        display: none;
    }
`;

const outsideCss = css`
    background: inherit;

    ${AllotmentIndicatorContainer}, p {
        display: none;
    }
`;

const todayCss = css`
    p {
        background: ${({ theme }) => theme.colors.primary['40']};
        border-radius: 50%;
        color: ${({ theme }) => theme.colors.neutral['0']};
    }
`;

const focusedCss = css`
    z-index: 3;

    :after {
        background: ${({ theme }) => theme.colors.primary['0']};
        box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary['40']};
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 4px;
    }
`;

const getCssForDate = (date: Date) => {
    if (isBefore(date, startOfToday())) {
        return pastCss;
    }
    if (isToday(date)) {
        return todayCss;
    }
    return '';
};

export const Container = styled(Box)<Props>`
    outline: none;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 7.2rem;
    flex-direction: column;
    ${({ date }) => getCssForDate(date)}
    ${({ disabled }) => disabled && disabledCss}
    ${({ outside }) => outside && outsideCss}

    &:focus {
        ${focusedCss};
    }

    > * {
        position: relative;
        z-index: 1;
    }

    > p {
        font-size: 16px;
        text-align: center;
        width: 2.6rem;
        height: 2.6rem;
        margin: 0.5rem 0.5rem 0.8rem;
    }
`;

export default Day;
