import Checkbox from '@oberoninternal/travelbase-ds/components/form/Checkbox';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import useWeekdays from '@oberoninternal/travelbase-ds/hooks/useWeekdays';
import { Box, Flex } from '@rebass/grid';
import format from 'date-fns/format';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { getDateOpts } from '../../../../constants/dateOpts';
import operatingHoursValidation from '../../../../constants/operatingHoursValidation';
import { ActivityParams } from '../../../../entities/ActivityParams';
import { NotFoundError } from '../../../../entities/NotFoundError';
import {
    TimeOpenClosed,
    useCompanyHoursQuery,
    useEditCompanyOperatingHoursMutation,
} from '../../../../generated/graphql';
import convertOperatingHoursToInput from '../../../../utils/convertOperatingHoursToInput';
import { weekdays } from '../../../../utils/createWeekdayRecord';
import FieldSet from '../../../atoms/FieldSet';
import LabelBox from '../../../molecules/LabelBox';
import TimeOpenClosedField from '../../../molecules/TimeOpenClosedField';
import FormScreen from '../../../organisms/FormScreen';
import Loading from '../../../organisms/Loading';

export const query = gql`
    query CompanyHours($companySlug: String!) {
        company(slug: $companySlug) {
            ...CompanyHours
        }
    }

    mutation EditCompanyOperatingHours($input: EditCompanyNormalOperatingHoursInput!) {
        editCompanyNormalOperatingHours(input: $input) {
            company {
                ...CompanyHours
            }
        }
    }

    fragment CompanyHours on Company {
        id
        normalOperatingHours {
            ...OperatingHours
        }
    }

    fragment OperatingHours on OperatingHours {
        startDate
        endDate
        monday {
            ...TimesOpenClosed
        }
        tuesday {
            ...TimesOpenClosed
        }
        wednesday {
            ...TimesOpenClosed
        }
        thursday {
            ...TimesOpenClosed
        }
        friday {
            ...TimesOpenClosed
        }
        saturday {
            ...TimesOpenClosed
        }
        sunday {
            ...TimesOpenClosed
        }
    }

    fragment TimesOpenClosed on TimeOpenClosed {
        timeOpen
        timeClosed
    }
`;

const isAlwaysOpen = (val: TimeOpenClosed[]) =>
    val.length === 1 && val[0].timeOpen === '00:00' && val[0].timeClosed === '23:59';

const Index: FC<React.PropsWithChildren<ActivityParams>> = ({ companySlug }) => {
    const [mutate] = useEditCompanyOperatingHoursMutation();
    const { loading, data } = useCompanyHoursQuery({ variables: { companySlug: companySlug! } });
    const weekdaysForDisplay = useWeekdays();
    const intl = useIntl();
    const { formatMessage, locale } = intl;
    const dateOpts = getDateOpts(locale as 'nl' | 'de' | 'en');

    if (loading) {
        return <Loading />;
    }

    if (!data || !data.company) {
        throw new NotFoundError();
    }
    const {
        company: { normalOperatingHours, id },
    } = data;

    const initialValues = convertOperatingHoursToInput(normalOperatingHours);

    return (
        <FormScreen
            validationSchema={operatingHoursValidation(intl)}
            initialValues={initialValues}
            handleSubmit={async values => {
                await mutate({
                    variables: {
                        input: {
                            companyId: id,
                            normalOperatingHours: values,
                        },
                    },
                });
            }}
        >
            {({ values, setFieldValue }) => (
                <>
                    <Pagehead title={formatMessage({ defaultMessage: 'Openingstijden' })}>
                        <FormattedMessage defaultMessage="Dit zijn de normale openingstijden voor de onderneming." />
                    </Pagehead>
                    {weekdays.map((day, i) => {
                        const dayValue = values[day];
                        return (
                            <StyledFieldSet key={day}>
                                <LabelBox>
                                    <Label style={{ textTransform: 'capitalize' }}>
                                        {format(weekdaysForDisplay[i], 'EEEE', dateOpts)}
                                    </Label>
                                </LabelBox>
                                <Box width={1}>
                                    {!isAlwaysOpen(dayValue) && (
                                        <Flex width={1}>
                                            <TimeOpenClosedField name={day} />
                                        </Flex>
                                    )}
                                    <Box mt={4}>
                                        <Checkbox
                                            id={`${day}-always-open`}
                                            checked={isAlwaysOpen(dayValue)}
                                            onChange={e => {
                                                if (
                                                    e.target.checked &&
                                                    !window.confirm(
                                                        formatMessage({
                                                            defaultMessage:
                                                                'Weet je zeker dat je de openingstijden voor deze dag wilt veranderen? Eventuele aanpassingen zullen verloren gaan.',
                                                        })
                                                    )
                                                ) {
                                                    return;
                                                }

                                                const initialValue = isAlwaysOpen(initialValues[day])
                                                    ? []
                                                    : initialValues[day];

                                                setFieldValue(
                                                    day,
                                                    e.target.checked
                                                        ? [{ timeOpen: '00:00', timeClosed: '23:59' }]
                                                        : initialValue
                                                );
                                            }}
                                        >
                                            <FormattedMessage defaultMessage="Altijd open" />
                                        </Checkbox>
                                    </Box>
                                </Box>
                            </StyledFieldSet>
                        );
                    })}
                </>
            )}
        </FormScreen>
    );
};

export default Index;

const StyledFieldSet = styled(FieldSet)`
    :not(:last-of-type) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[20]};
        padding-bottom: ${({ theme }) => theme.spacing['60_Large']};
    }
`;
