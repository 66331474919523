import { defineMessages } from 'react-intl';

export type PriceColumnKey =
    | 'nightPrice'
    | 'extraPersonPrice'
    | 'arrivalAllowed'
    | 'departureAllowed'
    | 'minimumStayDuration'
    | 'minimumStayPrice'
    | 'weekPrice'
    | 'baseStayPrice';

export const PRICE_COLUMN_ROWS: Record<
    PriceColumnKey,
    {
        defaultMessage: string;
    }
> = defineMessages({
    arrivalAllowed: {
        defaultMessage: 'Aankomstdag',
    },
    departureAllowed: {
        defaultMessage: 'Vertrekdag',
    },
    nightPrice: {
        defaultMessage: 'Nachtprijs',
    },
    extraPersonPrice: {
        defaultMessage: 'Prijs extra persoon',
    },
    minimumStayDuration: {
        defaultMessage: 'Minimum verblijfsduur',
    },
    minimumStayPrice: {
        defaultMessage: 'Minimum verblijfsprijs',
    },
    baseStayPrice: {
        defaultMessage: 'Basis verblijfsprijs',
    },
    weekPrice: {
        defaultMessage: 'Weekprijs',
    },
});

export const PRICE_COLUMN_ROW_HINTS: Record<
    PriceColumnKey,
    {
        defaultMessage: string;
    }
> = defineMessages({
    arrivalAllowed: {
        defaultMessage:
            'Door met deze optie per week (of meerdere weken tegelijk) specifieke aankomstdagen in te stellen, bepaal je op ieder moment van het jaar zelf op welke dagen gasten kunnen aankomen.',
    },
    departureAllowed: {
        defaultMessage:
            'Door met deze optie per week (of meerdere weken tegelijk)  specifieke vertrekdagen in te stellen bepaal je op ieder moment van het jaar zelf op welke dagen gasten kunnen vertrekken.',
    },
    nightPrice: {
        defaultMessage:
            'De Travelbase interface toont prijzen per nacht. Nachtprijzen zorgen ervoor dat je te allen tijde flexibel kunt inspelen op de toenemende vraag naar flexibele aakomst- en vertrekdagen, ook voor vakantiehuizen.',
    },
    extraPersonPrice: {
        defaultMessage:
            'De prijs van je accommodatie is gebaseerd op het aantal personen dat hierbij is inbegepen. Het kan zijn dat de capaciteit van je accommodatie groter is. Met deze optie bepaal je de prijs (per persoon) voor extra toegestane personen.',
    },
    minimumStayDuration: {
        defaultMessage:
            'Met deze optie bepaal je per datum, dag of meerdere data/dagen tegelijk de minimale verblijfsduur vanaf die datum.',
    },
    minimumStayPrice: {
        defaultMessage:
            "Met deze optie bepaal je per datum, dag of meerdere data/dagen tegelijk de minimumprijs van een boeking. Zo'n minimale prijs geldt bijvoorbeeld ook als een gast bijvoorbeeld maar 1 nacht zou boeken.",
    },
    baseStayPrice: {
        defaultMessage:
            'Ongeacht de lengte van het verblijf, kun je met deze optie een door jou te bepalen basisbedrag bovenop de huurprijs (ongeacht de verblijfsprijs) van je accommodatie laten berekenen.',
    },
    weekPrice: {
        defaultMessage:
            'Je kunt met deze optie een aparte weekprijs instellen die anders (meestal lager) is dan de optelling van de nachtprijzen.',
    },
});

export const PRICE_COLUMN_ROW_COUNT = Object.keys(PRICE_COLUMN_ROWS).length;
export const PRICE_COLUMN_HEADER_HEIGHT = 154;
