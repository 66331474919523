import React, { FC, HTMLAttributes, useRef } from 'react';
import styled from 'styled-components/macro';
import useOnClickOutside from 'use-onclickoutside';
import { useCloseOnEsc } from '@oberoninternal/travelbase-ds/hooks/useCloseOnEsc';
import ArrowDown from '@oberoninternal/travelbase-ds/components/figure/ArrowDown';

interface Props extends HTMLAttributes<HTMLElement> {
    open: boolean;
    setOpen: (open: boolean) => void;
    height?: string;
    background?: string;
    disableToggle?: boolean;
}
/**
 * Reusable component for creating dropdown menu's. Note that when using the component you should pass
 * more than 1 child. The first child represent its surface item and the consecutive children the
 * menu items
 */
const NavDropdown: FC<React.PropsWithChildren<Props>> = ({
    children,
    height = '7.2rem',
    background,
    open,
    setOpen,
    disableToggle = false,
    ...props
}) => {
    const ref = useRef(null);
    useOnClickOutside(ref, () => open && setOpen(false));
    useCloseOnEsc({ open, setOpen });
    const [surface, ...hiddenItems] = React.Children.toArray(children);
    return (
        <Container ref={ref} open={open} {...props}>
            <SurfaceItem
                onClick={disableToggle ? undefined : () => setOpen(!open)}
                background={background}
                height={height}
                open={open}
            >
                {surface}

                {hiddenItems && !disableToggle && (
                    <ToggleContainer open={open}>
                        <ArrowDown />
                    </ToggleContainer>
                )}
            </SurfaceItem>
            <DropdownList open={open}>
                {hiddenItems.map((el, i) => (
                    <HiddenItem open={open} key={i}>
                        {el}
                    </HiddenItem>
                ))}
            </DropdownList>
        </Container>
    );
};

export default NavDropdown;

export const DropdownList = styled.ul<{ open: boolean }>`
    display: ${({ open }) => (open ? 'block' : 'none')};
    margin: auto 0;
    padding: 0;
    flex-direction: column;
    flex-grow: 1;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    max-height: calc(100vh - ${({ theme }) => theme.heights.unitNav}px);
    overflow-y: auto;
`;

export const ToggleContainer = styled.span<{ open: boolean }>`
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    outline: none;
    width: 6rem;
    justify-content: center;
    color: ${({ open, theme }) => (!open ? theme.colors.neutral[0] : theme.colors.primary[90])};
    background-color: ${({ open, theme }) => (open ? theme.colors.neutral[0] : 'inherit')};
`;

const HiddenItem = styled.li<{ open?: boolean }>`
    background: ${({ open, theme }) => (open ? theme.colors.neutral[0] : 'none')};
    position: relative;
    + li {
        ::before {
            position: absolute;
            content: '';
            height: 2px;
            width: 100%;
            background: ${({ theme }) => theme.colors.neutral[20]};
            top: 0;
            left: 0;
            z-index: 1;
        }
    }

    :last-of-type {
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
    }
`;

export const SurfaceItem = styled.div<{ open?: boolean; height: string; background?: string }>`
    padding-right: 6rem;
    z-index: 1;
    min-height: ${({ height }) => height};
    > * {
        height: ${({ height }) => height} !important;
    }
    background: ${({ open, theme, background = theme.colors.primary[100] }) =>
        !open ? background : theme.colors.neutral[0]};
`;

const Container = styled.div<{ open: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    color: ${({ open, theme }) => (open ? theme.colors.primary[90] : theme.colors.neutral[0])};
    z-index: 1;
    box-shadow: ${({ open }) => (open ? '0 0 40px 0 rgba(0, 0, 0, 0.1)' : 'none')};

    ${HiddenItem}, ${SurfaceItem} {
        :hover {
            ${({ open, theme }) => (open ? `background-color: ${theme.colors.neutral[10]}` : '')};
        }
    }
`;
