import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

export const capacityValidation = (intl: IntlShape) =>
    Yup.object().shape({
        livingArea: Yup.number()
            .min(0, intl.formatMessage({ defaultMessage: 'Dit veld mag niet negatief zijn' }))
            .max(9999, intl.formatMessage({ defaultMessage: 'Dit veld mag niet hoger dan 9999 zijn' }))
            .integer(intl.formatMessage({ defaultMessage: 'Dit veld kan geen cijfers achter de komma bevatten.' })),
        outdoorArea: Yup.number()
            .min(0, intl.formatMessage({ defaultMessage: 'Dit veld mag niet negatief zijn' }))
            .max(9999, intl.formatMessage({ defaultMessage: 'Dit veld mag niet hoger dan 9999 zijn' }))
            .integer(intl.formatMessage({ defaultMessage: 'Dit veld kan geen cijfers achter de komma bevatten.' })),
    });
