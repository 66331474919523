// import Overtitle from '@oberoninternal/travelbase-ds/components/primitive/Overtitle';
import { Box } from '@rebass/grid';
import gql from 'graphql-tag';
import React, { FC, ReactElement, useState } from 'react';
import { generatePath, Redirect, Route, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';
import { UnitParams } from '../../../entities/UnitParams';
import { AccommodationInfoFragment, RentalUnitInfoFragment, RentalUnitTypeEnum } from '../../../generated/graphql';
import { getAccommodationTypeTranslation } from '../../../utils/getAccommodationTypeTranslation';
import { getUnitTypeTranslation } from '../../../utils/getUnitTypeTranslation';
import ErrorBoundary from '../../organisms/ErrorBoundary';
import NotFound from '../NotFound';
import AccommodationDescription from './info/AccommodationDescription';
import AccommodationDetails from './info/AccommodationDetails';
import AccommodationImages from './info/AccommodationImages';
import Attributes from './info/Attributes';
import Capacity from './info/Capacity';
import CompactDetails from './info/CompactDetails';
import Contracts from './info/Contracts';
import KeyHolder from './info/KeyHolder';
import Location from './info/Location';
import Owner from './info/Owner';
import PriceConfig from './info/PriceConfig';
import Surcharges from './info/Surcharges';
import Ical from './info/Ical';
import UnitDescription from './info/UnitDescription';
import UnitImages from './info/UnitImages';
import UnitDetails from './info/UnitDetails';
import InfoHeading from '../../atoms/InfoHeading';
import InfoMobileMenu from '../../molecules/InfoMobileMenu';
import ItemDesktopRoute from '../../atoms/ItemDesktopRoute';
import ItemMobileRoute from '../../atoms/ItemMobileRoute';
import InfoSideContainer from '../../molecules/InfoSideContainer';
import InfoContent from '../../atoms/InfoContent';
import brandConfig from '../../../constants/brandConfig';
import useIsBackofficeuser from '../../../hooks/useIsBackofficeuser';
import { LocalizedSwitch } from '../../atoms/LocalizedSwitch';
import { useIntl } from 'react-intl';

interface InfoRoute {
    name: string;
    source: string;
    level?: 'accommodation' | 'rentalUnit';
    both?: boolean; // whether there is a route for both accommodation and rentalUnit
    only?: boolean;
    showType?: 'after' | 'before';
}

export const infoFragment = gql`
    fragment AccommodationInfo on Accommodation {
        id
        slug
        name
        type
        hasPublicPage
        rentalUnits {
            id
            type
        }
    }
    fragment RentalUnitInfo on RentalUnit {
        id
        slug
        type
        datePricingStartDate
        name
        showAllotmentLockouts
    }
`;

interface Props {
    accommodation?: AccommodationInfoFragment;
    rentalUnit?: RentalUnitInfoFragment;
}

export interface InfoPageProps {
    accommodationType: AccommodationType;
}

type AccommodationType = 'accommodation' | 'rentalUnit' | 'compact';

const Info: FC<React.PropsWithChildren<Props>> = ({ accommodation, rentalUnit }) => {
    const { pathname } = useLocation();
    const { path, params } = useRouteMatch<UnitParams>();
    const [open, setOpen] = useState(false);
    const isBackofficeUser = useIsBackofficeuser();
    const intl = useIntl();

    const accommodationRoutes: InfoRoute[] = [
        {
            name: intl.formatMessage({ defaultMessage: 'Informatie' }),
            source: '',
            showType: 'before',
            level: 'accommodation',
        },
        {
            name: intl.formatMessage({ defaultMessage: 'Ligging en afstanden' }),
            source: 'location',
            level: 'accommodation',
        },
        {
            name: intl.formatMessage({ defaultMessage: 'Gastheer/-vrouw gegevens' }),
            source: 'owner',
            level: 'accommodation',
        },
        {
            name: intl.formatMessage({ defaultMessage: 'Kenmerken' }),
            source: 'accommodation-attributes',
            level: 'accommodation',
            showType: 'after',
        },
        {
            name: intl.formatMessage({ defaultMessage: 'Beschrijving' }),
            source: 'accommodation-description',
            level: 'accommodation',
            showType: 'after',
        },
        {
            name: intl.formatMessage({ defaultMessage: 'Foto’s' }),
            source: 'accommodation-images',
            level: 'accommodation',
            both: true,
            showType: 'after',
        },
        { name: intl.formatMessage({ defaultMessage: 'Sleuteladres' }), source: 'keyholder', level: 'accommodation' },
    ];

    const rentalUnitRoutes: InfoRoute[] = [
        {
            name: intl.formatMessage({ defaultMessage: 'Informatie' }),
            source: 'unit-details',
            showType: 'before',
            level: 'rentalUnit',
        },
        {
            name: intl.formatMessage({ defaultMessage: 'Capaciteit en afmetingen' }),
            source: 'capacity',
            level: 'rentalUnit',
        },
        {
            name: intl.formatMessage({ defaultMessage: 'Kenmerken' }),
            source: 'unit-attributes',
            level: 'rentalUnit',
            showType: 'after',
        },
        {
            name: intl.formatMessage({ defaultMessage: 'Beschrijving' }),
            source: 'unit-description',
            level: 'rentalUnit',
        },
        {
            name: intl.formatMessage({ defaultMessage: 'Foto’s' }),
            source: 'unit-images',
            level: 'rentalUnit',
            showType: 'after',
        },
        { name: intl.formatMessage({ defaultMessage: 'Overeenkomsten' }), source: 'contracts', level: 'rentalUnit' },
        {
            name: intl.formatMessage({ defaultMessage: 'Kortingen en prijsregels' }),
            source: 'priceconfig',
            level: 'rentalUnit',
        },
        {
            name: intl.formatMessage({ defaultMessage: 'Opties en bijkomende kosten' }),
            source: 'surcharges',
            level: 'rentalUnit',
        },
    ];

    const checkoutOnlyAccommodationRoutes: InfoRoute[] = [
        {
            name: intl.formatMessage({ defaultMessage: 'Informatie' }),
            source: '',
            showType: 'before',
            level: 'accommodation',
        },
        {
            name: intl.formatMessage({ defaultMessage: 'Ligging en afstanden' }),
            source: 'location',
            level: 'accommodation',
        },
        { name: intl.formatMessage({ defaultMessage: 'Sleuteladres' }), source: 'keyholder', level: 'accommodation' },
    ];
    const checkoutOnlyRentalUnitRoutes: InfoRoute[] = [
        {
            name: intl.formatMessage({ defaultMessage: 'Informatie' }),
            source: 'unit-details',
            showType: 'before',
            level: 'rentalUnit',
        },
        {
            name: intl.formatMessage({ defaultMessage: 'Capaciteit en afmetingen' }),
            source: 'capacity',
            level: 'rentalUnit',
        },
        {
            name: intl.formatMessage({ defaultMessage: 'Beschrijving' }),
            source: 'unit-description',
            level: 'rentalUnit',
        },
        {
            name: intl.formatMessage({ defaultMessage: 'Foto’s' }),
            source: 'unit-images',
            level: 'rentalUnit',
            showType: 'after',
        },
        { name: intl.formatMessage({ defaultMessage: 'Overeenkomsten' }), source: 'contracts', level: 'rentalUnit' },
        {
            name: intl.formatMessage({ defaultMessage: 'Kortingen en prijsregels' }),
            source: 'priceconfig',
            level: 'rentalUnit',
        },
        {
            name: intl.formatMessage({ defaultMessage: 'Opties en bijkomende kosten' }),
            source: 'surcharges',
            level: 'rentalUnit',
        },
    ];

    const routes: () => InfoRoute[] = () =>
        brandConfig.hasCheckoutOnly && !isBackofficeUser
            ? [...checkoutOnlyAccommodationRoutes, ...checkoutOnlyRentalUnitRoutes]
            : [...accommodationRoutes, ...rentalUnitRoutes];

    const compactRoutes: () => InfoRoute[] = () =>
        brandConfig.hasCheckoutOnly && !isBackofficeUser
            ? [
                  { name: intl.formatMessage({ defaultMessage: 'Accommodatie informatie' }), source: '' },
                  { name: intl.formatMessage({ defaultMessage: 'Ligging en afstanden' }), source: 'location' },
                  { name: intl.formatMessage({ defaultMessage: 'Capaciteit en afmetingen' }), source: 'capacity' },
                  {
                      name: intl.formatMessage({ defaultMessage: 'Beschrijving' }),
                      source: 'unit-description',
                  },
                  {
                      name: intl.formatMessage({ defaultMessage: 'Foto’s' }),
                      source: 'unit-images',
                      showType: 'after',
                  },
                  { name: intl.formatMessage({ defaultMessage: 'Sleuteladres' }), source: 'keyholder' },
                  { name: intl.formatMessage({ defaultMessage: 'Overeenkomsten' }), source: 'contracts' },
                  { name: intl.formatMessage({ defaultMessage: 'Kortingen en prijsregels' }), source: 'priceconfig' },
                  { name: intl.formatMessage({ defaultMessage: 'Opties en bijkomende kosten' }), source: 'surcharges' },
              ]
            : [
                  { name: intl.formatMessage({ defaultMessage: 'Accommodatie informatie' }), source: '' },
                  { name: intl.formatMessage({ defaultMessage: 'Ligging en afstanden' }), source: 'location' },
                  { name: intl.formatMessage({ defaultMessage: 'Gastheer/-vrouw gegevens' }), source: 'owner' },
                  { name: intl.formatMessage({ defaultMessage: 'Capaciteit en afmetingen' }), source: 'capacity' },
                  {
                      name: intl.formatMessage({ defaultMessage: 'Kenmerken' }),
                      source: 'attributes',
                  },
                  {
                      name: intl.formatMessage({ defaultMessage: 'Beschrijving' }),
                      source: 'unit-description',
                  },
                  {
                      name: intl.formatMessage({ defaultMessage: 'Foto’s' }),
                      source: 'unit-images',
                      showType: 'after',
                  },
                  { name: intl.formatMessage({ defaultMessage: 'Sleuteladres' }), source: 'keyholder' },
                  { name: intl.formatMessage({ defaultMessage: 'Overeenkomsten' }), source: 'contracts' },
                  { name: intl.formatMessage({ defaultMessage: 'Kortingen en prijsregels' }), source: 'priceconfig' },
                  { name: intl.formatMessage({ defaultMessage: 'Opties en bijkomende kosten' }), source: 'surcharges' },
              ];

    const fullPath = (shortPath: string) => generatePath(shortPath, { ...params });

    const accommodationType: AccommodationType =
        (accommodation?.rentalUnits ?? []).length === 1 ? 'compact' : 'accommodation';
    const isCompact = accommodationType === 'compact';

    const withAccommodationRoutes = !isCompact || (isCompact && accommodation?.hasPublicPage);

    const renderPathName = (type?: RentalUnitTypeEnum, level?: string, showBefore = false) => {
        if (level === 'rentalUnit' || !withAccommodationRoutes) {
            if (showBefore) {
                const unitTypeTranslation = getUnitTypeTranslation(type);

                return <>{unitTypeTranslation}&nbsp;</>;
            }
            return <>&nbsp;{`${getUnitTypeTranslation(type)?.toLowerCase()}`}</>;
        }
        if (level === 'accommodation' && withAccommodationRoutes) {
            return showBefore ? (
                <>{getAccommodationTypeTranslation(accommodation?.type)}&nbsp;</>
            ) : (
                <>&nbsp;{getAccommodationTypeTranslation(accommodation?.type)?.toLowerCase()}</>
            );
        }
        return '';
    };

    const renderRouteDesktop = ({ name, source, level, showType }: InfoRoute) => (
        <ItemDesktopRoute key={`desktop-${source}`} path={fullPath(`${path}/${source}`)}>
            {showType === 'before' && renderPathName(rentalUnit?.type, level, true)}
            {name}
            {showType === 'after' && renderPathName(rentalUnit?.type, level)}
        </ItemDesktopRoute>
    );

    const renderRouteMobile = ({ name, source }: InfoRoute) => (
        <ItemMobileRoute key={`mobile-${source}`} open={open} setOpen={setOpen} path={fullPath(`${path}/${source}`)}>
            {name}
        </ItemMobileRoute>
    );

    const renderRoutes = (infoRoutes: InfoRoute[], renderFunc: (route: InfoRoute) => ReactElement) => {
        if (!withAccommodationRoutes) {
            return [
                ...routes()
                    .filter(route => !route.level)
                    .map(renderFunc),
                accommodation && (
                    <InfoHeading
                        key={`header-${accommodation?.hasPublicPage ? accommodation.name : rentalUnit?.name}`}
                        name={accommodation?.hasPublicPage ? accommodation.name : rentalUnit?.name}
                    />
                ),
                ...infoRoutes.map(renderFunc),
            ];
        }
        return [
            ...routes()
                .filter(route => !route.level)
                .map(renderFunc),
            accommodation && <InfoHeading key={`header-${accommodation.id}`} name={accommodation.name} />,
            ...infoRoutes
                .filter(route => (route.level === 'accommodation' && route.both) || route.level === 'accommodation')
                .map(renderFunc),
            withAccommodationRoutes && rentalUnit && (
                <InfoHeading key={`header-${rentalUnit.id}`} name={rentalUnit.name} />
            ),
            ...infoRoutes
                .filter(
                    route =>
                        route.level === 'rentalUnit' &&
                        ((route.both && !isCompact) || (!route.both && !route.only) || (!isCompact && route.only))
                )
                .map(renderFunc),
        ];
    };

    const first = [...routes()].reverse().find(({ source }) => pathname.endsWith(source)) ?? routes()[0];

    const routesToRender = !withAccommodationRoutes ? compactRoutes() : routes();
    if (rentalUnit?.showAllotmentLockouts) {
        routesToRender.push({
            name: intl.formatMessage({ defaultMessage: 'Ical' }),
            source: 'ical',
            level: 'rentalUnit',
        });
    }

    return (
        <Container>
            <InfoSideContainer>{renderRoutes(routesToRender, renderRouteDesktop)}</InfoSideContainer>
            <InfoContent>
                <InfoMobileMenu open={open} setOpen={setOpen}>
                    {open ? renderRoutes(routesToRender, renderRouteMobile) : [renderRouteMobile(first)]}
                </InfoMobileMenu>

                <ErrorBoundary>
                    <LocalizedSwitch>
                        <Route
                            path={fullPath(`${path}`)}
                            exact
                            render={() =>
                                isCompact ? <CompactDetails {...params} /> : <AccommodationDetails {...params} />
                            }
                        />
                        {isCompact && !accommodation?.hasPublicPage && (
                            <Redirect from={fullPath(`${path}/unit-details`)} to={fullPath(`${path}`)} />
                        )}
                        {isCompact && !accommodation?.hasPublicPage && (
                            <Redirect from={fullPath(`${path}/accommodation-images`)} to={fullPath(`${path}`)} />
                        )}
                        <Route path={fullPath(`${path}/unit-details`)} render={() => <UnitDetails {...params} />} />
                        <Route path={fullPath(`${path}/location`)} render={() => <Location {...params} />} />
                        <Route path={fullPath(`${path}/capacity`)} render={() => <Capacity {...params} />} />
                        <Route
                            path={fullPath(`${path}/keyholder`)}
                            render={() => <KeyHolder isCompact={isCompact} {...params} />}
                        />

                        <Route
                            path={fullPath(`${path}/attributes`)}
                            render={() => (
                                <Attributes
                                    subtype={rentalUnit?.type}
                                    {...params}
                                    accommodationType="compact"
                                    key="attributes"
                                />
                            )}
                        />
                        <Route
                            path={fullPath(`${path}/unit-attributes`)}
                            render={() => (
                                <Attributes
                                    accommodationType="rentalUnit"
                                    key="unit-attributes"
                                    subtype={rentalUnit?.type}
                                    {...params}
                                />
                            )}
                        />
                        <Route
                            path={fullPath(`${path}/unit-description`)}
                            render={() => (
                                <UnitDescription accommodationType="compact" key="unit-description" {...params} />
                            )}
                        />
                        <Route
                            path={fullPath(`${path}/accommodation-description`)}
                            render={() => (
                                <AccommodationDescription
                                    accommodationType="accommodation"
                                    key="accommodation-description"
                                    {...params}
                                />
                            )}
                        />
                        <Route
                            path={fullPath(`${path}/accommodation-attributes`)}
                            render={() => (
                                <Attributes
                                    accommodationType="accommodation"
                                    key="accommodation-attributes"
                                    {...params}
                                />
                            )}
                        />

                        <Route path={fullPath(`${path}/priceconfig`)} render={() => <PriceConfig {...params} />} />

                        <Route
                            path={fullPath(`${path}/accommodation-images`)}
                            render={() => <AccommodationImages {...params} />}
                        />
                        <Route path={fullPath(`${path}/unit-images`)} render={() => <UnitImages {...params} />} />
                        <Route path={fullPath(`${path}/owner`)} render={() => <Owner {...params} />} />
                        <Route path={fullPath(`${path}/contracts`)} render={() => <Contracts {...params} />} />
                        <Route path={fullPath(`${path}/surcharges`)} render={() => <Surcharges {...params} />} />
                        {rentalUnit?.showAllotmentLockouts && (
                            <Route path={fullPath(`${path}/ical`)} render={() => <Ical {...params} />} />
                        )}
                        <Route component={NotFound} />
                    </LocalizedSwitch>
                </ErrorBoundary>
            </InfoContent>
        </Container>
    );
};

export default Info;

const Container = styled(Box).attrs({ width: '1' })`
    display: flex;
`;
