import { useMemo } from 'react';
import addMinutes from 'date-fns/addMinutes';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import format from 'date-fns/format';
import startOfToday from 'date-fns/startOfToday';
import startOfTomorrow from 'date-fns/startOfTomorrow';
import range from 'lodash/range';

const useTimes = (step = 30) =>
    useMemo(() => {
        const start = startOfToday();
        const end = startOfTomorrow();
        const diff = differenceInMinutes(end, start) / step;

        return range(0, diff)
            .map(i => format(addMinutes(start, i * step), 'HH:mm'))
            .sort()
            .map(time => ({
                label: time,
                value: time,
            }));
    }, [step]);

export default useTimes;
