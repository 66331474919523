import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NotFoundError } from '../../../../entities/NotFoundError';
import { UnitParams } from '../../../../entities/UnitParams';
import { Attribute, useAttributesQuery, useEditAttributesMutation } from '../../../../generated/graphql';
import { RentalUnitTypeEnum } from '../../../../generated/resetGraphql';
import { getAccommodationTypeTranslation } from '../../../../utils/getAccommodationTypeTranslation';
import CategoryAttributes, { attributeCategoryFragment } from '../../../molecules/CategoryAttributes';
import FormScreen from '../../../organisms/FormScreen';
import Loading from '../../../organisms/Loading';
import { InfoPageProps } from '../Info';

export const attributesFragment = gql`
    fragment Attributes on RentalUnit {
        id
        name
        accommodation {
            id
            type
            name

            attributes {
                id
            }
            rentalUnits {
                id
            }
        }

        attributes {
            id
            name
            type
            category {
                __typename
                id
                name
            }
        }
    }
    ${attributeCategoryFragment}
`;

export const attributesQuery = gql`
    query Attributes($unitSlug: String!, $subtype: RentalUnitTypeEnum) {
        rentalUnit(slug: $unitSlug) {
            ...Attributes
        }

        attributeCategories {
            ...AttributeCategory
        }
    }
    ${attributesFragment}
`;

export const editAttributesMutation = gql`
    mutation EditAttributes($input: EditAttributesInput!) {
        editAttributes(input: $input) {
            rentalUnit {
                ...Attributes
            }
        }
    }
    ${attributesFragment}
`;

interface Props extends UnitParams, InfoPageProps {
    subtype?: RentalUnitTypeEnum;
}
const Attributes: FC<React.PropsWithChildren<Props>> = ({ unitSlug, accommodationType, subtype }) => {
    const isRentalUnit = accommodationType === 'rentalUnit';
    const isAccommodation = accommodationType === 'accommodation';
    const isCompact = accommodationType === 'compact';
    const { formatMessage } = useIntl();
    const { data, loading } = useAttributesQuery({
        variables: {
            unitSlug,
            subtype,
        },
    });

    const [mutate] = useEditAttributesMutation();

    if (loading) {
        return <Loading />;
    }

    if (!data?.rentalUnit) {
        throw new NotFoundError();
    }

    const {
        rentalUnit: { id, name, attributes: rentalUnitAttributes, accommodation },
        attributeCategories = [],
    } = data;

    // merge the accommodation and rentalUnit attributes
    let mergedAttributes: Array<Pick<Attribute, 'id'>> = [];
    if (accommodation?.attributes && rentalUnitAttributes) {
        mergedAttributes = [...accommodation.attributes, ...rentalUnitAttributes];
    }

    const initialValues = {
        attributes: mergedAttributes
            .filter((attr): attr is Pick<Attribute, 'id'> => !!attr)
            .map(attr => ({ id: attr.id })),
    };

    const excludedAttributes = rentalUnitAttributes.filter(
        attr => !attributeCategories.find(ctg => ctg.unitAttributes.find(unitAttr => unitAttr.id === attr.id))
    );

    const mergedAttributeCategories = [
        ...attributeCategories.flatMap(ctg => {
            const unitAttributes = excludedAttributes.find(attr => attr.category?.id === ctg.id);

            return unitAttributes
                ? [{ ...ctg, unitAttributes: [...ctg.unitAttributes, { ...unitAttributes, isExcluded: true }] }]
                : [ctg];
        }),
    ];
    return (
        <FormScreen
            handleSubmit={async values => {
                await mutate({
                    variables: {
                        input: {
                            rentalUnitId: id,
                            ...values,
                        },
                    },
                });
            }}
            initialValues={initialValues}
        >
            {({ values }) => (
                <>
                    {isCompact && (
                        <Pagehead title={formatMessage({ defaultMessage: 'Kenmerken' })}>
                            <FormattedMessage defaultMessage="Hier beheer je de kenmerken van je accommodatie." />
                        </Pagehead>
                    )}
                    {isRentalUnit && (
                        <Pagehead title={formatMessage({ defaultMessage: 'Kenmerken' })}>
                            <FormattedMessage
                                defaultMessage="Hier beheer je de kenmerken van je {accommodationType} {name}"
                                values={{
                                    name,
                                }}
                            />
                        </Pagehead>
                    )}
                    {isAccommodation && (
                        <Pagehead title={formatMessage({ defaultMessage: 'Kenmerken' })}>
                            <FormattedMessage
                                defaultMessage="Hier beheer de kenmerken van je {accommodationType} die tevens gelden voor alle accommodaties die hier onder vallen."
                                values={{
                                    accommodationType: getAccommodationTypeTranslation(accommodation.type),
                                }}
                            />
                        </Pagehead>
                    )}

                    {mergedAttributeCategories.map(ctg => (
                        <CategoryAttributes
                            key={ctg.id}
                            accommodationType={accommodationType}
                            attributeValues={values.attributes}
                            category={ctg}
                        />
                    ))}
                </>
            )}
        </FormScreen>
    );
};

export default Attributes;
