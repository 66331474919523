import { OptionType } from '@oberoninternal/travelbase-ds/components/form/SelectInput';

export interface BrandConfig {
    contactUrl: string;
    countryOptions: OptionType[];
    customSlugs?: {
        activity?: string;
        company?: string;
        accommodation?: string;
    };
    registrationForm?: {
        accommodationOptions?: OptionType[];
        rentalUnitOptions?: OptionType[];
        hasRequiredVatNumber?: boolean;
        hasPackageOptions?: boolean;
        hasLocationRentalUnitOptions?: boolean;
    };
    brandName?: string;
    hasCheckoutOnly?: boolean;
}

const brand =
    typeof process !== 'undefined' && 'REACT_APP_BRAND' in process.env ? process.env.REACT_APP_BRAND : 'texel';

// eslint-disable-next-line import/no-dynamic-require,@typescript-eslint/no-var-requires
const brandConfig: BrandConfig = require(`./brandConfig.${brand}.tsx`).default;

export default brandConfig;
