import composeRefs from '@seznam/compose-react-refs';
import React, { ComponentProps, ComponentType, useRef } from 'react';
import { WindowScroller } from 'react-virtualized';
import { FixedSizeList as List, FixedSizeListProps, ListChildComponentProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import styled from 'styled-components/macro';

type BaseComponentType = ComponentType<React.PropsWithChildren<ListChildComponentProps>>;
interface Props<T extends BaseComponentType>
    extends Omit<FixedSizeListProps, 'height' | 'width' | 'itemData' | 'children'> {
    infiniteLoader?: Omit<InfiniteLoader['props'], 'children'>; // why wouldn't you export the types ffs
    width?: number | string;
    itemData: ComponentProps<T>['data'];
    children: T;
}
const BigList = <T extends BaseComponentType>({ infiniteLoader, width = '100%', ...props }: Props<T>) => {
    const listRef = useRef<List>(null);

    const handleScroll = ({ scrollTop }: { scrollTop: number }) => {
        if (listRef.current) {
            listRef.current.scrollTo(scrollTop);
        }
    };

    const listProps = { ...props, width, height: window.innerHeight };

    const list = infiniteLoader ? (
        <InfiniteLoader {...infiniteLoader}>
            {({ ref, onItemsRendered }) => (
                <StyledList>
                    <List
                        {...listProps}
                        // because InfiniteLoader requires that a ref it provides is passed to react-window and we also
                        // need the listRef for window scrolling, we need to merge it
                        ref={composeRefs(ref, listRef)}
                        onItemsRendered={onItemsRendered}
                        useIsScrolling
                    />
                </StyledList>
            )}
        </InfiniteLoader>
    ) : (
        <List {...listProps} ref={listRef} />
    );
    return (
        <>
            <WindowScroller onScroll={handleScroll}>{() => <div />}</WindowScroller>
            {list}
        </>
    );
};

export default BigList;

const StyledList = styled.div`
    & > div {
        height: calc(100% - 15vh) !important;
    }
`;
