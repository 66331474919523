import { TargetAudienceEnum } from '../generated/graphql';
import { IntlShape } from 'react-intl';

export interface TargetAudienceOptionType {
    label: string;
    value: TargetAudienceEnum;
}

const targetAudienceOptions: (intl: IntlShape) => TargetAudienceOptionType[] = (intl: IntlShape) => [
    {
        label: intl.formatMessage({ defaultMessage: 'Alle leeftijden' }),
        value: TargetAudienceEnum.AllAges,
    },
    {
        label: intl.formatMessage({ defaultMessage: 'Kinderen' }),
        value: TargetAudienceEnum.Children,
    },
    {
        label: intl.formatMessage({ defaultMessage: 'Volwassenen' }),
        value: TargetAudienceEnum.Adults,
    },
    {
        label: intl.formatMessage({ defaultMessage: 'Rolstoelvriendelijk' }),
        value: TargetAudienceEnum.WheelchairAccessible,
    },
    {
        label: intl.formatMessage({ defaultMessage: 'Huisdieren welkom' }),
        value: TargetAudienceEnum.Pets,
    },
];

export default targetAudienceOptions;
