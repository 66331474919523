/* eslint-disable react/no-danger */

import { Flex } from '@rebass/grid';
import { OperationVariables, useApolloClient } from '@apollo/client';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import React, { FC, useCallback, useEffect, useRef } from 'react';

import Skeleton from 'react-loading-skeleton';
import { Link, useParams } from 'react-router-dom';
import simplur from 'simplur';
import { AllotmentsBookingFragment, useBookingSidebarQuery } from '../../generated/graphql';
import { useWriteBooking } from '../../hooks/useWriteBooking';
import { formatDuration } from '../../utils/formatDuration';
import SidebarField from '../atoms/SidebarField';
import SidebarIntro from '../atoms/SidebarIntro';
import Right from '@oberoninternal/travelbase-ds/components/figure/Right';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import PriceTableRow from './PriceTableRow';
import { PricingQueryShape } from '../organisms/Pricing';
import { useSidebar } from '../../context/sidebar';
import ContentWrapper from '../atoms/ContentWrapper';
import { UnitParams } from '../../entities/UnitParams';
import { FormattedMessage, useIntl } from 'react-intl';

export interface BookingSidebarData {
    type: 'BOOKING';
    booking: AllotmentsBookingFragment;
    variables?: OperationVariables;
    document: DocumentNode;
}

interface Props {
    data: BookingSidebarData;
}

export const query = gql`
    query BookingSidebar($id: ID!) {
        booking(id: $id) {
            id
            duration
            amountAdults
            amountYouths
            amountChildren
            amountBabies
            amountPets
            customerComment
            partnerPriceLines {
                label
                totalPrice
            }
            totalPrice
        }
    }
`;

type Formatter = (amount: number) => string;
const amountMap: Array<
    ['amountAdults' | 'amountChildren' | 'amountPets' | 'amountBabies' | 'amountYouths', Formatter]
> = [
    ['amountAdults', i => simplur`<strong>${i}</strong> volwassene[|n]`],
    ['amountYouths', i => simplur`<strong>${i}</strong> jongere[|n]`],
    ['amountChildren', i => simplur`<strong>${i}</strong> kind[|eren]`],
    ['amountPets', i => simplur`<strong>${i}</strong> huisdier[|en]`],
    ['amountBabies', i => simplur`<strong>${i}</strong> baby[|'s']`],
];

const BookingSidebar: FC<React.PropsWithChildren<Props>> = ({
    data: {
        document,
        variables,
        booking: { customer, id, totalPrice, ...booking },
    },
}) => {
    const writeBooking = useWriteBooking(document, variables);
    const client = useApolloClient();
    const prevId = useRef(id);
    const { partnerId } = useParams<UnitParams>();
    const { formatMessage } = useIntl();
    const [state] = useSidebar();
    const { open } = state;
    const prevOpened = useRef(open);

    const startDate = new Date(booking.startDate);
    const endDate = new Date(booking.endDate);
    const { data, loading } = useBookingSidebarQuery({
        variables: {
            id,
        },
    });
    const formattedDate = formatDuration(startDate, endDate);
    const fullBooking = data?.booking;

    const getBooking = useCallback(() => {
        const cache = client.readQuery<PricingQueryShape>({
            query: document,
            variables,
        });

        const rentalUnit = cache?.rentalUnit;

        if (!rentalUnit) {
            return null;
        }

        return rentalUnit.bookings?.find(current => current.id === id);
    }, [client, document, id, variables]);

    useEffect(() => {
        const cached = getBooking();
        if (!cached) {
            throw new Error(`Unable to find booking ${id}`);
        }

        const deactivate = () =>
            writeBooking({
                ...cached,
                isActive: false,
            });

        if (prevId.current !== id) {
            deactivate();
        }

        // did the sidebar close?
        if (prevOpened.current !== open && !open) {
            deactivate();
        }
        prevOpened.current = open;
    }, [writeBooking, getBooking, id, open]);

    return (
        <ContentWrapper variant="sidebar">
            <SidebarIntro title={formatMessage({ defaultMessage: 'Bezetting' })}>
                {customer ? (
                    <FormattedMessage defaultMessage="Boeking van" />
                ) : (
                    <FormattedMessage defaultMessage="Aanvraag voor" />
                )}
                &nbsp;
                {formattedDate}
            </SidebarIntro>
            {customer && (
                <SidebarField label={formatMessage({ defaultMessage: 'Naam gast' })}>
                    <Body variant="small">
                        {customer.firstName ?? ''} {customer.lastName ?? ''}
                    </Body>
                </SidebarField>
            )}

            {loading && (
                <>
                    <SidebarField label={<Skeleton />}>
                        <Skeleton />
                    </SidebarField>
                    <SidebarField label={<Skeleton />}>
                        <Skeleton count={3} />
                    </SidebarField>
                    <SidebarField label={<Skeleton />}>
                        <Skeleton count={3} />
                    </SidebarField>
                    <SidebarField label={<Skeleton />}>
                        <Skeleton count={4} />
                    </SidebarField>
                </>
            )}

            {!loading && fullBooking && fullBooking.__typename === 'Booking' && (
                <>
                    <SidebarField label={formatMessage({ defaultMessage: 'Verblijfsduur' })}>
                        <Body variant="small">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: simplur`<strong>${fullBooking.duration}</strong> nacht[|en]`,
                                }}
                            />
                        </Body>
                    </SidebarField>
                    <SidebarField label={formatMessage({ defaultMessage: 'Gasten' })}>
                        <Body variant="small">
                            {amountMap.map(([key, formatter]) => {
                                const value = fullBooking[key];
                                if (value === 0) {
                                    return null;
                                }
                                return <span key={key} dangerouslySetInnerHTML={{ __html: formatter(value) }} />;
                            })}
                        </Body>
                    </SidebarField>
                    {fullBooking.customerComment && (
                        <SidebarField label={formatMessage({ defaultMessage: 'Opmerkingen' })}>
                            <Body variant="small">{fullBooking.customerComment}</Body>
                        </SidebarField>
                    )}
                    <SidebarField label={formatMessage({ defaultMessage: 'Prijs' })}>
                        {fullBooking.partnerPriceLines.map(({ label, ...rest }, i) => (
                            <PriceTableRow key={i} name={label} price={rest.totalPrice} />
                        ))}
                        <PriceTableRow variant="total" name="Totaal" price={totalPrice} />
                    </SidebarField>

                    <Flex>
                        <TextButton variant="primary" as={Link} to={`/partner/${partnerId}/bookings/all/${id}`}>
                            <span>
                                <FormattedMessage
                                    defaultMessage="Bekijk {bookingType}"
                                    values={{
                                        bookingType: customer ? 'boeking' : 'aanvraag',
                                    }}
                                />
                            </span>
                            <Right />
                        </TextButton>
                    </Flex>
                </>
            )}
        </ContentWrapper>
    );
};

export default BookingSidebar;
