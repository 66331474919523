import React, { FC } from 'react';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { Box, Flex } from '@rebass/grid';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import FormScreen from '../organisms/FormScreen';
import FormActions from '@oberoninternal/travelbase-ds/components/form/FormActions';
import FieldSet from '../atoms/FieldSet';
import LabelBox from '../molecules/LabelBox';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import FieldHeading from '../molecules/FieldHeading';
import { RadioField } from '@oberoninternal/travelbase-ds/components/form/Radio';
import { usePartnerDataQuery, useQrSignupMutation } from '../../generated/graphql';
import gql from 'graphql-tag';
import * as Yup from 'yup';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import addWeeks from 'date-fns/addWeeks';
import { addMonths } from 'date-fns';
import { useParams } from 'react-router-dom';
import { PartnerParams } from '../../entities/PartnerParams';
import { toast } from 'react-toastify';
import Toast from '@oberoninternal/travelbase-ds/components/feedback/Toast';
import FieldSetHint from '../atoms/FieldSetHint';
import { FormattedMessage, useIntl } from 'react-intl';

export const query = gql`
    mutation QRSignup($input: InitTicketScanSessionInput!) {
        initTicketScanSession(input: $input) {
            deepLink
        }
    }
`;

interface Values {
    sessionLabel: string;
    email: string;
    expiresAt: 'month' | 'week' | 'unlimited';
}

const QRSignup: FC<React.PropsWithChildren<unknown>> = () => {
    const { formatMessage } = useIntl();
    const validationSchema = Yup.object().shape({
        sessionLabel: Yup.string().required(formatMessage({ defaultMessage: 'Een naam is verplicht' })),
        email: Yup.string()
            .email(formatMessage({ defaultMessage: 'Vul een geldig e-mailadres in' }))
            .required(formatMessage({ defaultMessage: 'Een e-mailadres is verplicht' })),
        expiresAt: Yup.string().required(formatMessage({ defaultMessage: 'Een geldigheidsdatum is verplicht' })),
        rememberMe: Yup.boolean(),
    });
    const [mutate] = useQrSignupMutation();
    const { partnerId } = useParams<PartnerParams>();
    const { data: partnerData } = usePartnerDataQuery({
        variables: {
            partnerId,
        },
    });

    return (
        <>
            <FormScreen<Values>
                validationSchema={validationSchema}
                skipToast
                handleSubmit={async ({ sessionLabel, expiresAt, email }) => {
                    let date: Date | null;
                    switch (expiresAt) {
                        case 'week':
                            date = addWeeks(new Date(), 1);
                            break;
                        case 'month':
                            date = addMonths(new Date(), 1);
                            break;
                        default:
                        case 'unlimited':
                            date = null;
                    }

                    const { data } = await mutate({
                        variables: {
                            input: {
                                email,
                                sessionExpiresAt: date,
                                sessionLabel,
                                partnerId,
                            },
                        },
                    });

                    const deepLink = data?.initTicketScanSession?.deepLink;
                    if (deepLink) {
                        toast(
                            <Toast
                                variant="success"
                                title={formatMessage({ defaultMessage: 'Toegangslink verstuurd' })}
                            >
                                <Body variant="small">
                                    <FormattedMessage defaultMessage="Open de e-mail op het toestel waar de app is geïnstalleerd. Je kan ook de QR-code in de e-mail scannen met de app om in te loggen." />
                                </Body>
                            </Toast>,
                            {
                                autoClose: 5000,
                            }
                        );

                        window.location.href = deepLink;
                    }
                }}
                initialValues={{
                    email: '',
                    sessionLabel: '',
                    expiresAt: 'week',
                }}
                variant="default"
                bottomChildren={p => (
                    <FormActions
                        buttonText={formatMessage({ defaultMessage: 'Verstuur toegangslink' })}
                        resetText={formatMessage({ defaultMessage: 'Wijzigingen ongedaan maken' })}
                        {...p}
                    />
                )}
            >
                {() => (
                    <>
                        <Pagehead title={formatMessage({ defaultMessage: 'QR Scanner' })}>
                            <Box my={3}>
                                <Body>
                                    <FormattedMessage defaultMessage="Travelbase biedt een handige manier om de geldigheid van tickets voor activiteiten te controleren. Bij de activiteit stel je het gebruik van QR-codes in bij de tarieven. Als je dit hebt ingeschakeld hebben de tickets die vanaf dan worden verkocht een unieke QR-code. Met de QR Scanner controleer je of de tickets geldig zijn." />
                                </Body>
                                <Box mt={4}>
                                    <Body>
                                        <FormattedMessage defaultMessage="Download de QR Scanner op je telefoon:" />
                                    </Body>
                                </Box>
                            </Box>
                            <Flex>
                                <Box mr={3}>
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        href="https://apps.apple.com/nl/app/travelbase-qr-scanner/id6449186310"
                                    >
                                        <img
                                            alt={formatMessage({
                                                defaultMessage: 'Download de app op de apple app store',
                                            })}
                                            width={120}
                                            src="/img/app-store-badge.svg"
                                        />
                                    </a>
                                </Box>
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href="https://play.google.com/store/apps/details?id=nl.travelbase.qrscanner"
                                >
                                    <img
                                        alt={formatMessage({
                                            defaultMessage: 'Download de app op de google play store',
                                        })}
                                        width={134}
                                        src="/img/google-play-badge.png"
                                    />
                                </a>
                            </Flex>
                        </Pagehead>

                        <FieldHeading title={formatMessage({ defaultMessage: 'Inloggen' })}>
                            <FormattedMessage defaultMessage="Om tickets te kunnen scannen moet je inloggen in de QR Scanner app. Via onderstaand formulier geef je iemand toegang tot de app. Met dit toestel kunnen alle tickets met een QR-code gescand worden." />
                        </FieldHeading>

                        <FieldSet>
                            <LabelBox>
                                <Label htmlFor="name">
                                    <FormattedMessage defaultMessage="Partner" />
                                </Label>
                            </LabelBox>
                            <Flex style={{ minHeight: '5rem', alignItems: 'center' }}>
                                <Body>{partnerData?.partner?.name}</Body>
                            </Flex>
                        </FieldSet>
                        <FieldSet>
                            <LabelBox>
                                <Label htmlFor="sessionLabel">
                                    <FormattedMessage defaultMessage="Label" />
                                </Label>
                                <FieldSetHint>
                                    <FormattedMessage defaultMessage="Je ziet dit label terug bij de details van het ticket in de Partner App nadat deze gescand is" />
                                </FieldSetHint>
                            </LabelBox>
                            <Box width={1}>
                                <TextInputField
                                    id="sessionLabel"
                                    placeholder={formatMessage({ defaultMessage: 'Label' })}
                                    name="sessionLabel"
                                />
                            </Box>
                        </FieldSet>

                        <FieldSet>
                            <LabelBox>
                                <Label htmlFor="email">
                                    <FormattedMessage defaultMessage="E-mailadres" />
                                </Label>
                                <FieldSetHint>
                                    <FormattedMessage defaultMessage="Deze ontvangt een link om in te loggen in de app" />
                                </FieldSetHint>
                            </LabelBox>
                            <Box width={1}>
                                <TextInputField
                                    id="email"
                                    // placeholder="E-mailadres"
                                    placeholder={formatMessage({ defaultMessage: 'E-mailadres' })}
                                    name="email"
                                />
                            </Box>
                        </FieldSet>

                        <FieldSet>
                            <LabelBox>
                                <Label htmlFor="expiresAt">
                                    <FormattedMessage defaultMessage="Geldig tot" />
                                </Label>
                            </LabelBox>
                            <Flex flexDirection="column" style={{ gap: '1rem' }}>
                                <RadioField name="expiresAt" id="week">
                                    <FormattedMessage defaultMessage="1 week vanaf nu" />
                                </RadioField>
                                <RadioField name="expiresAt" id="month">
                                    <FormattedMessage defaultMessage="1 maand vanaf nu" />
                                </RadioField>
                                <RadioField name="expiresAt" id="unlimited">
                                    <FormattedMessage defaultMessage="Onbeperkt" />
                                </RadioField>
                            </Flex>
                        </FieldSet>
                    </>
                )}
            </FormScreen>
        </>
    );
};

export default QRSignup;
