import React, { SVGAttributes } from 'react';

export default function (props: SVGAttributes<SVGElement>) {
    return (
        <svg width="20" height="19" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g fill="none" fillRule="evenodd">
                <path stroke="#EEF2F3" fill="#FFF" d="M-332.5-22.5h374v63h-374z" />
                <path fill="#091923" d="M-22-23h64v64h-64z" />
                <path
                    d="M17.4246-.046l2.1213 2.1214L12.1211 9.5l7.4248 7.4245-2.1213 2.1213-7.4245-7.4248-7.4247 7.4248L.454 16.9246l7.425-7.4245L.454 2.0754 2.5754-.046 10 7.379l7.4245-7.425z"
                    fill="#FFF"
                />
            </g>
        </svg>
    );
}
