import React, { SVGAttributes } from 'react';

export default function (props: SVGAttributes<SVGElement>) {
    return (
        <svg width="12" height="12" {...props}>
            <path
                d="M6 6L1 1l5 5 5-5-5 5zm0 0l5 5-5-5-5 5 5-5z"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
            />
        </svg>
    );
}
