import { ReactNode, useEffect, useRef } from 'react';
import { toast, ToastId, ToastOptions } from 'react-toastify';

export const useToast = (element: ReactNode, options?: ToastOptions) => {
    const toastId = useRef<ToastId | null>(null);
    const dismiss = () => {
        if (toastId.current) {
            toast.dismiss(toastId.current);
            toastId.current = null;
        }
    };
    const hasElement = !!element;
    useEffect(() => {
        if (element) {
            toastId.current = toast(element, options);
        } else {
            dismiss();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasElement]);

    useEffect(() => dismiss, []);
};
