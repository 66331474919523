import { TargetAudienceEnum } from '../generated/graphql';
import targetAudienceOptions from '../constants/targetAudienceOptions';
import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';
import { IntlShape } from 'react-intl';

export const toFormikValues = (targetAudiences: Maybe<TargetAudienceEnum>[], intl: IntlShape) =>
    Object.assign(
        {},
        ...targetAudienceOptions(intl).map(item => ({ [item.value]: targetAudiences.includes(item.value) ?? false }))
    );

export const toInput = (values: Record<string, boolean>) =>
    Object.entries(values)
        .map(([key, value]) => {
            if (value) return key;
            return null;
        })
        .filter(item => item !== null) as TargetAudienceEnum[];
