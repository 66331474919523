import simplur from 'simplur';
import { Booking } from '../generated/graphql';

export default (
    booking: Pick<Booking, 'amountAdults' | 'amountYouths' | 'amountChildren' | 'amountPets' | 'amountBabies'>
) => {
    const guestsText: string[] = [];

    for (const [key, amount] of Object.entries(booking)) {
        if (amount) {
            switch (key as keyof typeof booking) {
                case 'amountAdults':
                    guestsText.push(simplur`${amount} volwassene[|n]`);
                    break;
                case 'amountYouths':
                    guestsText.push(simplur`${amount} jongere[|n]`);
                    break;
                case 'amountChildren':
                    guestsText.push(simplur`${amount}  kind[|eren]`);
                    break;
                case 'amountPets':
                    guestsText.push(simplur`${amount}  huisdier[|en]`);
                    break;
                case 'amountBabies':
                    guestsText.push(simplur`${amount}  baby[|'s]`);
                    break;
                default:
                    break;
            }
        }
    }

    return guestsText.join(', ');
};
