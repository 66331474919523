import React, { FC } from 'react';
import styled from 'styled-components/macro';

interface Props {
    single?: boolean;
    hidePath?: boolean;
}

const PickerItem: FC<React.PropsWithChildren<Props>> = ({ single = false, children, hidePath = false }) => (
    <Item single={single}>
        {!hidePath && <Path />}
        {children}
    </Item>
);

export default PickerItem;

const Path = styled.span`
    position: absolute;
    content: '';
    height: 6rem;
    width: 1px;
    background: ${({ theme }) => theme.colors.neutral[20]};
    top: 1.2rem;
    left: 1.6rem;
    z-index: 1;

    ::after {
        position: absolute;
        content: '';
        height: 1px;
        width: 1.6rem;
        background: ${({ theme }) => theme.colors.neutral[20]};
        top: calc(50% - 0.6rem);
        left: 0;
        z-index: 1;
    }
`;

const Item = styled.li<{ single?: boolean }>`
    position: relative;
    :not(:first-of-type):not(:last-of-type) {
        ${Path} {
            top: 0;
            height: 100%;
            ::after {
                top: 50%;
            }
        }
    }
    :last-of-type {
        ${Path} {
            height: ${({ single }) => (single ? '30%' : 'calc(50% + 1.2rem)')};
            top: ${({ single }) => (single ? '1.5rem' : '-1.2rem')};
            ::after {
                top: 100%;
            }
        }
    }
`;
