import React, { FC, ThHTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import { Label, LabelVariant } from '@oberoninternal/travelbase-ds/components/primitive/Label';

interface Props extends ThHTMLAttributes<HTMLTableHeaderCellElement> {
    variant?: LabelVariant;
}

const TableHeader: FC<React.PropsWithChildren<Props>> = ({ children, variant, ...props }) => (
    <StyledTableHeader {...props}>
        <Label as="span" variant={variant}>
            {children}
        </Label>
    </StyledTableHeader>
);

export default TableHeader;

export const StyledTableHeader = styled.th`
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[20]};
    vertical-align: center;
    padding-bottom: 2rem;
    font-weight: inherit;
`;
