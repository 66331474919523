import { Box, BoxProps } from '@rebass/grid';
import React, { FC, HTMLAttributes, useEffect, useRef, RefObject } from 'react';
import styled, { css } from 'styled-components';

import { UnreachableCaseError } from '../../entities/UnreachableCaseError';
import { MenuStateProps } from '../../hooks/useMenuState';
import RoundButton, { StyledRoundButton } from '../action/RoundButton';
import Cross from '../figure/Cross';

export type SidebarVariant = 'navigation' | 'content';

export interface SidebarProps extends Partial<MenuStateProps>, HTMLAttributes<HTMLDivElement> {
    variant?: SidebarVariant;
    onClose?: () => void;
    hidden?: boolean;
    disableScrollTop?: boolean;
}
const Sidebar: FC<React.PropsWithChildren<SidebarProps>> = ({
    children,
    menuRef,
    setOpen,
    open = false,
    hidden = false,
    variant = 'content',
    onClose,
    disableScrollTop = false,
    ...props
}) => {
    const openPrevious = useRef(open);
    useEffect(() => {
        if (open && !openPrevious.current && !disableScrollTop) {
            (menuRef as RefObject<HTMLDivElement>)?.current?.scrollTo({ top: 0 });
        }
        openPrevious.current = open;
    }, [menuRef, open, disableScrollTop]);
    return (
        <Container open={open} hidden={hidden} ref={menuRef} variant={variant} {...props}>
            {variant === 'content' && (
                <RoundButton
                    style={{ minHeight: '4rem' }}
                    onClick={() => {
                        if (onClose) {
                            onClose();
                        } else if (setOpen) {
                            setOpen(false);
                        }
                    }}
                    variant="outline"
                >
                    <Cross />
                </RoundButton>
            )}
            <Content>{children}</Content>
        </Container>
    );
};

export default Sidebar;

interface ContainerProps {
    open: boolean;
    hidden: boolean;
    variant: SidebarVariant;
}

const navigationCss = css<ContainerProps>`
    position: fixed;
    z-index: 12;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding: 0 2rem;
    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        display: none;
    }
    transform: translateX(${({ open }) => (open ? '0' : '-100%')});

    > div {
        margin-top: 0;
        padding-top: 32px;
    }
`;

const contentCss = css<ContainerProps>`
    position: fixed;
    z-index: 11;
    bottom: ${({ theme }) => theme.heights.unitNav}px;

    > ${StyledRoundButton} {
        margin-left: ${({ theme }) => theme.spacing['60_Large']};
        margin-top: ${({ theme }) => theme.spacing['60_Large']};

        @media (max-width: ${({ theme }) => theme.mediaQueries.xs}) {
            margin-left: ${({ theme }) => theme.spacing['50_Semi']};
            margin-top: ${({ theme }) => theme.spacing['50_Semi']};
        }

        @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
            position: sticky;
            top: ${({ theme }) => theme.spacing['40_Standard']};
            z-index: ${({ theme }) => theme.zIndices.docked};
            background-color: ${({ theme }) => theme.colors.neutral['0']};
        }
    }

    transform: translateX(${({ open }) => (open ? '0' : '-100%')});
    left: 0;

    @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
        right: 0;
        left: unset;
        transform: translateX(${({ open }) => (open ? '0' : '100%')});
        top: ${({ theme }) => theme.heights.nav + theme.heights.unitNav}px;
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        bottom: 0;
    }
`;

const getCss = (variant: SidebarVariant) => {
    switch (variant) {
        case 'navigation':
            return navigationCss;
        case 'content':
            return contentCss;
        default:
            throw new UnreachableCaseError(variant);
    }
};

const Container = styled.div<ContainerProps>`
    background: ${({ theme }) => theme.colors.neutral['0']};
    transition: transform 400ms cubic-bezier(0.47, 0, 0.745, 0.715);
    width: 42.4rem;
    ${({ variant }) => getCss(variant)};
    box-shadow: 0 20px 40px 0 rgba(16, 36, 48, 0.06);
    overflow: auto;
    display: flex;
    flex-direction: column;
    top: ${({ theme }) => theme.heights.nav}px;

    @media (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        top: ${({ theme }) => theme.heights.mobileNav}px;
    }

    @media (max-width: ${({ theme }) => theme.mediaQueries.xs}) {
        width: 100%;
    }
`;

const Content = styled(Box).attrs(() => ({ mt: 5 } as BoxProps))`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;

    > form {
        flex-direction: column;
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
    }
`;
