import { useEffect, useRef } from 'react';
import { event, EventArgs } from 'react-ga';
import { DocumentNode, OperationDefinitionNode } from 'graphql';
import { matchRoute } from '../utils/matchRoute';
import { useLocation } from 'react-router-dom';
import { MutationResult, OperationVariables } from '@apollo/client';
import { useIntl } from 'react-intl';

// useMutationEvent will look into any of these keys.
// Note that the order matters because it will grab the first one it can find.
// If you have a key that's 'less' important, place it lower in the array, and vice versa.
const LABEL_VARS = ['name', 'comment', 'id', 'allotmentLockoutId', 'datePricingModifierId', 'rentalUnitId'];
const VALUE_VARS = [
    'amount',
    'arrivalAllowed',
    'departureAllowed',
    'nightPrice',
    'extraPersonPrice',
    'minimumStayDuration',
    'weekPrice',
    'baseStayPrice',
    'minimumStayPrice',
    'value',
];

const getVariable = <T = string>(keys: string[], variables: OperationVariables | null): T | undefined => {
    if (!variables) {
        return undefined;
    }

    // first try to look into variables object itself
    const foundKey = Object.keys(variables).find(key => keys.includes(key));

    // if that fails, attempt to search through input
    if (!foundKey && 'input' in variables) {
        return getVariable(keys, variables.input);
    }

    return foundKey ? variables[foundKey] : undefined;
};

const useMutationEvent = ({
    result,
    query,
    variables,
}: {
    result: MutationResult;
    query: DocumentNode;
    variables: OperationVariables | null;
}) => {
    const location = useLocation();
    const prevLoading = useRef(false);
    const { formatMessage } = useIntl();

    useEffect(() => {
        const message = matchRoute(location.pathname)?.title ?? {
            defaultMessage: 'Unknown',
        };
        if (!result.loading && prevLoading.current !== result.loading) {
            const value = getVariable<number | boolean>(VALUE_VARS, variables);
            const args: EventArgs = {
                action:
                    query.definitions.find<OperationDefinitionNode>(
                        (definition): definition is OperationDefinitionNode =>
                            definition.kind === 'OperationDefinition' && definition.operation === 'mutation'
                    )?.name?.value ?? 'Unknown',
                category: message.defaultMessage,
                label: getVariable(LABEL_VARS, variables),
                value: typeof value !== 'undefined' ? +value : undefined,
            };

            if (process.env.NODE_ENV !== 'production') {
                // eslint-disable-next-line no-console
                console.log('[GA Event fire]', args);
            }

            event(args);
        }
        prevLoading.current = result.loading;
    }, [result.loading, query.definitions, variables, location.pathname, formatMessage]);
};

export default useMutationEvent;
