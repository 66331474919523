import React, { FC, memo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import PickerWrapper from './PickerWrapper';
import { CompanyPickerEntryFragment } from '../../generated/graphql';
import ActivityPickerEntry from './ActivityPickerEntry';
import { ActivityParams } from '../../entities/ActivityParams';

interface Props {
    data: CompanyPickerEntryFragment[];
}

const ActivityPicker: FC<React.PropsWithChildren<Props>> = memo(({ data }) => {
    let entries = data;
    const [open, setOpen] = useState(false);
    const match = useRouteMatch<ActivityParams>();

    if (!entries) {
        return null;
    }

    const entriesCount = entries.reduce((acc, next) => acc + 1 + next.activities.length, 0);

    const currentSlug = match.params.activitySlug ?? match.params.companySlug ?? '';

    const selectedActivity = entries
        .flatMap(entry => entry.activities)
        .find(activity => !!match.params.activitySlug && activity.slug.includes(currentSlug));

    const selectedCompany = entries.find(company => !!match.params.companySlug && company.slug.includes(currentSlug));

    const selected = selectedActivity ?? selectedCompany;

    if (currentSlug && selected) {
        entries = [selected as CompanyPickerEntryFragment, ...entries];
    }

    return (
        <PickerWrapper open={open} setOpen={setOpen} disableToggle={entriesCount <= 1}>
            {entries.map(
                (company, i) =>
                    company && (
                        <ActivityPickerEntry
                            open={open}
                            setOpen={setOpen}
                            company={company}
                            companies={entries}
                            key={company.id}
                            first={i === 0}
                        />
                    )
            )}
        </PickerWrapper>
    );
});

export default ActivityPicker;
