import { OptionType, SelectInputField } from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';
import { Box } from '@rebass/grid';
import eachYearOfInterval from 'date-fns/eachYearOfInterval';
import endOfYear from 'date-fns/endOfYear';
import format from 'date-fns/format';
import startOfYear from 'date-fns/startOfYear';
import gql from 'graphql-tag';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import dateFormat from '../../constants/dateFormat';
import { getDateOpts } from '../../constants/dateOpts';
import { PartnerParams } from '../../entities/PartnerParams';
import { useBalancesQuery, BalancesQuery, BalancesQueryVariables } from '../../generated/graphql';
import useBigList from '../../hooks/useBigList';
import BigListWrapper from '../atoms/BigListWrapper';
import ContentWrapper from '../atoms/ContentWrapper';
import BalanceItem, { BalanceItemType } from '../molecules/BalanceItem';
import BigList from '../molecules/BigList';
import parseDate from '@oberoninternal/travelbase-ds/utils/parseDate';
import { FormattedMessage, useIntl } from 'react-intl';

export const query = gql`
    query Balances($partnerId: ID!, $startDate: Date!, $endDate: Date!) {
        partner(id: $partnerId) {
            id
            balance(atDate: $startDate)
            balanceItems(startDate: $startDate, endDate: $endDate) {
                ...BalanceItem
            }
        }
    }
`;

const widths = [1 / 10, 2 / 10, 5 / 10, 1 / 10, 1 / 10];

const startDate = format(startOfYear(new Date()), dateFormat);
const endDate = format(endOfYear(new Date()), dateFormat);

const yearOptions = eachYearOfInterval({ start: new Date('2021-01-01'), end: new Date() })
    .map(year => ({
        value: format(year, dateFormat, getDateOpts('nl')),
        label: format(year, 'yyyy', getDateOpts('nl')),
    }))
    .reverse();

const computeBalanceItems = (data?: BalancesQuery, variables?: BalancesQueryVariables) => {
    let balanceItems: BalanceItemType[] = data?.partner?.balanceItems ?? [];

    if (data?.partner?.balance) {
        // when we do have either a negative or positive balance we'll clarify this by adding a balance item that represents the starting balance
        balanceItems = [
            {
                __typename: 'StartBalance',
                amount: data.partner.balance,
                createdAt: variables?.startDate,
            },
            ...balanceItems,
        ];
    }

    // sort balanceitems from new to old
    return [...balanceItems].reverse();
};

const BalanceItems: FC<React.PropsWithChildren<unknown>> = () => {
    const { partnerId } = useParams<PartnerParams>();
    const [period, setPeriod] = useState<{ startDate: string; endDate: string }>({ startDate, endDate });
    const { data, loading, variables } = useBalancesQuery({ variables: { partnerId, ...period } });
    const balanceItems = useMemo(() => computeBalanceItems(data, variables), [data, variables]);
    const { locale } = useIntl();
    const dateOpts = getDateOpts(locale as 'nl' | 'de' | 'en');

    const onPeriodChange = useCallback(
        (option: Maybe<OptionType>) =>
            option &&
            setPeriod({
                startDate: option.value,
                endDate: format(parseDate(endOfYear(new Date(option.value))), dateFormat, dateOpts),
            }),
        [dateOpts]
    );
    const { createHeader, createRow, createSkeletonRows } = useBigList(widths);
    const skeletonRows = useMemo(createSkeletonRows, [createSkeletonRows]);
    const header = useMemo(
        () =>
            createHeader([
                <FormattedMessage defaultMessage="Datum" key="date" />,
                <FormattedMessage defaultMessage="Type" key="type" />,
                <FormattedMessage defaultMessage="Omschrijving" key="description" />,
                <FormattedMessage defaultMessage="Bedrag" key="amount" />,
                <FormattedMessage defaultMessage="Saldo" key="balance" />,
            ]),
        [createHeader]
    );
    const { formatMessage } = useIntl();
    return (
        <ContentWrapper>
            <Pagehead title={formatMessage({ defaultMessage: 'Verrekening' })}>
                <FormattedMessage defaultMessage="Bekijk hier de afrekeningen van je accommodatie(s) die gemaakt zijn via Travelbase." />
            </Pagehead>

            <Box width={[1, '16rem']} my={5}>
                <SelectInputField
                    value={yearOptions.find(year => year.value === variables?.startDate)}
                    hideChoices
                    options={yearOptions}
                    onChange={onPeriodChange}
                />
            </Box>

            <BigListWrapper>
                {header}

                {loading && skeletonRows}

                {!loading && balanceItems.length > 0 && (
                    <BigList
                        itemData={{
                            balanceItems,
                            createRow,
                            year: period.startDate,
                        }}
                        itemSize={78}
                        itemCount={balanceItems.length}
                    >
                        {BalanceItem}
                    </BigList>
                )}

                {!loading && balanceItems.length === 0 && (
                    <Box p={4}>
                        <FormattedMessage defaultMessage="Geen afrekeningen gevonden." />
                    </Box>
                )}
            </BigListWrapper>
        </ContentWrapper>
    );
};

export default BalanceItems;
