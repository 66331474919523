import { useEffect } from 'react';
import * as ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

const prod = process.env.NODE_ENV === 'production';

export const useGA = () => {
    const { pathname, search } = useLocation();
    const url = pathname + search;

    useEffect(() => {
        if (prod) {
            ReactGA.initialize('UA-58560-18');
        }
    }, []);
    useEffect(() => {
        if (prod) {
            ReactGA.pageview(url);
        }
    }, [url]);
};
