import React, { SVGAttributes } from 'react';
import { useThemeContext } from '../../hooks/useThemeContext';

export function Camera(props: SVGAttributes<SVGElement>) {
    const theme = useThemeContext();
    return (
        <svg width={22} height={19} {...props}>
            <g
                transform="translate(1 1)"
                stroke={theme.colors.neutral['100']}
                fill="none"
                fillRule="evenodd"
                opacity={0.2}
            >
                <rect
                    strokeWidth={1.5}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    y={3}
                    width={20}
                    height={14}
                    rx={1}
                />
                <circle strokeLinecap="round" strokeLinejoin="round" cx={10} cy={10} r={4} />
                <path d="M20 6.5h-1.5a1 1 0 00-1 1v5a1 1 0 001 1H20m-20-7h1.5a1 1 0 011 1v5a1 1 0 01-1 1H0" />
                <path
                    d="M6 3L6.772.684A1 1 0 017.721 0h4.558a1 1 0 01.949.684L14 3"
                    strokeWidth={1.5}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path strokeLinecap="round" strokeLinejoin="round" d="M1.5 3V1h2v2z" />
            </g>
        </svg>
    );
}
