import { BookingStatusEnum } from '../generated/graphql';

const activeBookingStatuses = [BookingStatusEnum.Accepted, BookingStatusEnum.Pending];

const shouldHideBooking = (status: BookingStatusEnum) => !activeBookingStatuses.includes(status);

const statusesToHide = [BookingStatusEnum.Declined];

export const shouldHideBookingCustomer = (status: BookingStatusEnum) => statusesToHide.includes(status);

export default shouldHideBooking;
