import { AccommodationTypeEnum, RentalUnitTypeEnum } from '../generated/resetGraphql';
import { BrandConfig } from './brandConfig';
import { nlCountryOption } from './countryOptions';

const accommodationOptions = [
    { value: AccommodationTypeEnum.Hotel, label: 'Hotel' },
    { value: AccommodationTypeEnum.Location, label: 'Vakantiewoning', rentalUnitsType: RentalUnitTypeEnum.Home },
    { value: AccommodationTypeEnum.BedAndBreakfast, label: 'Bed & Breakfast' },
    { value: AccommodationTypeEnum.Camping, label: 'Camping' },
    { value: AccommodationTypeEnum.BungalowPark, label: 'Bungalowpark' },
    { value: AccommodationTypeEnum.Location, label: 'Boot', rentalUnitsType: RentalUnitTypeEnum.Boat },
];

const rentalUnitOptions = [
    { value: RentalUnitTypeEnum.Room, label: 'Kamer' },
    { value: RentalUnitTypeEnum.Home, label: 'Gehele woning/bungalow/chalet' },
    { value: RentalUnitTypeEnum.CampingPitch, label: 'Kampeerplaats' },
    { value: RentalUnitTypeEnum.Tent, label: '(Ingerichte) tent' },
    { value: RentalUnitTypeEnum.Bed, label: 'Los bed in gedeelde ruimte' },
    { value: RentalUnitTypeEnum.Boat, label: 'Boot' },
];
const schierConfig: BrandConfig = {
    contactUrl: 'https://www.vvvschiermonnikoog.nl/contact/',
    countryOptions: [nlCountryOption],
    brandName: 'VVV Schiermonnikoog',
    registrationForm: {
        accommodationOptions,
        rentalUnitOptions,
    },
};

export default schierConfig;
