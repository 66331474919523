import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Toast from '@oberoninternal/travelbase-ds/components/feedback/Toast';
import gql from 'graphql-tag';
import { addDays, format, startOfToday, isAfter } from 'date-fns';
import { Formik, FormikProps } from 'formik';
import React, { ReactNode, useMemo } from 'react';
import { toast } from 'react-toastify';
import dateFormat from '../../constants/dateFormat';
import { useSidebar } from '../../context/sidebar';
import { Formable } from '../../entities/Formable';
import { UnreachableCaseError } from '../../entities/UnreachableCaseError';
import {
    BulkEditDatePricingArrivalAllowedInput,
    BulkEditDatePricingNightPriceInput,
    useBulkEditDatePricingArrivalAllowedMutation,
    useBulkEditDatePricingBaseStayPriceMutation,
    useBulkEditDatePricingDepartureAllowedMutation,
    useBulkEditDatePricingExtraPersonPriceMutation,
    useBulkEditDatePricingMinimumStayDurationMutation,
    useBulkEditDatePricingMinimumStayPriceMutation,
    useBulkEditDatePricingNightPriceMutation,
    useBulkEditDatePricingWeekPriceMutation,
} from '../../generated/graphql';
import createWeekdayRecord from '../../utils/createWeekdayRecord';
import { BulkEditSidebarData } from './BulkEditSidebar';
import { FormattedMessage, useIntl } from 'react-intl';

export const mutations = gql`
    mutation BulkEditDatePricingNightPrice($input: BulkEditDatePricingNightPriceInput!) {
        bulkEditDatePricingNightPrice(input: $input) {
            datePricings {
                id
                nightPrice
            }
        }
    }
    mutation BulkEditDatePricingExtraPersonPrice($input: BulkEditDatePricingExtraPersonPriceInput!) {
        bulkEditDatePricingExtraPersonPrice(input: $input) {
            datePricings {
                id
                extraPersonPrice
            }
        }
    }
    mutation BulkEditDatePricingArrivalAllowed($input: BulkEditDatePricingArrivalAllowedInput!) {
        bulkEditDatePricingArrivalAllowed(input: $input) {
            datePricings {
                id
                arrivalAllowed
            }
        }
    }
    mutation BulkEditDatePricingDepartureAllowed($input: BulkEditDatePricingDepartureAllowedInput!) {
        bulkEditDatePricingDepartureAllowed(input: $input) {
            datePricings {
                id
                departureAllowed
            }
        }
    }
    mutation BulkEditDatePricingMinimumStayDuration($input: BulkEditDatePricingMinimumStayDurationInput!) {
        bulkEditDatePricingMinimumStayDuration(input: $input) {
            datePricings {
                id
                minimumStayDuration
            }
        }
    }
    mutation BulkEditDatePricingMinimumStayPrice($input: BulkEditDatePricingMinimumStayPriceInput!) {
        bulkEditDatePricingMinimumStayPrice(input: $input) {
            datePricings {
                id
                minimumStayPrice
            }
        }
    }
    mutation BulkEditDatePricingWeekPrice($input: BulkEditDatePricingWeekPriceInput!) {
        bulkEditDatePricingWeekPrice(input: $input) {
            datePricings {
                id
                weekPrice
            }
        }
    }
    mutation BulkEditDatePricingBaseStayPrice($input: BulkEditDatePricingBaseStayPriceInput!) {
        bulkEditDatePricingBaseStayPrice(input: $input) {
            datePricings {
                id
                baseStayPrice
            }
        }
    }
`;

interface Props {
    children: (props: FormikProps<BulkEditValues>) => ReactNode;
    data?: BulkEditSidebarData;
    datePricingStartDate?: Date;
}

export type BulkEditValues = Formable<
    Omit<
        BulkEditDatePricingArrivalAllowedInput | BulkEditDatePricingNightPriceInput,
        'rentalUnitId' | 'startDate' | 'endDate'
    > & { startDate: string; endDate: string } // overwrite these props because otherwise they're of type any
>;

export const BulkEditForm = ({ data, children, datePricingStartDate, ...props }: Props) => {
    const [bulkMutateNightPrice] = useBulkEditDatePricingNightPriceMutation();
    const [bulkMutateExtraPersonPrice] = useBulkEditDatePricingExtraPersonPriceMutation();
    const [bulkMutateArrivalAllowed] = useBulkEditDatePricingArrivalAllowedMutation();
    const [bulkMutateDepartureAllowed] = useBulkEditDatePricingDepartureAllowedMutation();
    const [bulkMutateMinimumStayDuration] = useBulkEditDatePricingMinimumStayDurationMutation();
    const [bulkMutateMinimumStayPrice] = useBulkEditDatePricingMinimumStayPriceMutation();
    const [bulkMutateBaseStayPrice] = useBulkEditDatePricingBaseStayPriceMutation();
    const [bulkMutateWeekPrice] = useBulkEditDatePricingWeekPriceMutation();
    const { formatMessage } = useIntl();
    const [, dispatch] = useSidebar();

    let defaultValue: boolean | '' = '';

    if (data) {
        switch (data.row) {
            case 'arrivalAllowed':
            case 'departureAllowed':
                // the default of switch inputs should be an empty string in order to notice bulk edits
                defaultValue = '';
                break;
            case 'minimumStayDuration':
                defaultValue = '';
                break;
            default:
                defaultValue = '';
        }
    }

    const initialValues: BulkEditValues = useMemo(() => {
        const startDate =
            datePricingStartDate && isAfter(datePricingStartDate, startOfToday()) // if in the future use the datePricingStartDate
                ? datePricingStartDate
                : startOfToday();
        return {
            ...createWeekdayRecord(defaultValue),
            startDate: format(startDate, dateFormat),
            endDate: format(addDays(startDate, 7), dateFormat),
        };
    }, [datePricingStartDate, defaultValue]);

    return (
        <Formik<BulkEditValues>
            initialValues={initialValues}
            enableReinitialize
            onSubmit={async (values, { resetForm }) => {
                try {
                    if (!data) {
                        return;
                    }
                    const { row, unitId } = data;
                    const input = {
                        ...Object.assign(
                            {},
                            ...Object.entries(values)
                                .filter(([key, val]) => val !== '' && key !== 'endDate' && key !== 'startDate')
                                .map(([key, val]) => ({
                                    [key]: typeof val === 'string' ? parseFloat(val) : val,
                                }))
                        ),
                        startDate: values.startDate,
                        endDate: values.endDate,
                        rentalUnitId: unitId,
                    };

                    const variables = { input };

                    switch (row) {
                        case 'nightPrice':
                            await bulkMutateNightPrice({
                                variables,
                            });
                            break;
                        case 'extraPersonPrice':
                            await bulkMutateExtraPersonPrice({
                                variables,
                            });
                            break;
                        case 'arrivalAllowed':
                            await bulkMutateArrivalAllowed({
                                variables,
                            });
                            break;
                        case 'departureAllowed':
                            await bulkMutateDepartureAllowed({
                                variables,
                            });
                            break;
                        case 'minimumStayDuration':
                            await bulkMutateMinimumStayDuration({
                                variables,
                            });
                            break;
                        case 'minimumStayPrice':
                            await bulkMutateMinimumStayPrice({
                                variables,
                            });
                            break;
                        case 'baseStayPrice':
                            await bulkMutateBaseStayPrice({
                                variables,
                            });
                            break;
                        case 'weekPrice':
                            await bulkMutateWeekPrice({
                                variables,
                            });
                            break;
                        default:
                            throw new UnreachableCaseError(row);
                    }
                    toast(
                        <Toast variant="success" title={formatMessage({ defaultMessage: 'Opgeslagen' })}>
                            <Body variant="small">
                                <FormattedMessage defaultMessage="Succesvol opgeslagen" />
                            </Body>
                        </Toast>,
                        {
                            autoClose: 3000,
                        }
                    );

                    dispatch({ type: 'close' });
                    resetForm({ values: { ...initialValues, startDate: values.startDate, endDate: values.endDate } });
                } catch (ex) {
                    // eslint-disable-next-line no-console
                    console.error('BulkEditForm onSubmit failed:', ex);
                }
            }}
            {...props}
        >
            {formikBag => children(formikBag)}
        </Formik>
    );
};
