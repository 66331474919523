import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import gql from 'graphql-tag';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { NotFoundError } from '../../../entities/NotFoundError';
import { PartnerParams } from '../../../entities/PartnerParams';
import { useArchiveQuery } from '../../../generated/graphql';
import ActivityDetails from '../../atoms/ActivityDetails';
import ContentWrapper from '../../atoms/ContentWrapper';
import Link from '../../atoms/Link';
import UnitDetails from '../../atoms/UnitDetails';
import FieldHeading from '../../molecules/FieldHeading';
import Loading from '../../organisms/Loading';

export const query = gql`
    query Archive($partnerId: ID!) {
        partner(id: $partnerId) {
            id
            accommodations {
                id
                rentalUnits {
                    ...UnitDetails
                }
            }
            companies {
                id
                activities {
                    ...ActivityPickerEntry
                }
            }
        }
    }
`;

const isHidden = (obj: { hideForPartner: boolean }) => obj.hideForPartner;

const Archive = ({ partnerId }: PartnerParams) => {
    const { data, loading } = useArchiveQuery({ variables: { partnerId } });
    const { formatMessage } = useIntl();
    if (loading) {
        return <Loading variant="info" />;
    }

    if (!data || !data.partner) {
        throw new NotFoundError();
    }
    const accommodations = data.partner.accommodations
        .flatMap(accommodation => accommodation.rentalUnits)
        .filter(isHidden);
    const activities = data.partner.companies.flatMap(company => company.activities).filter(isHidden);

    return (
        <ContentWrapper variant="info">
            <Pagehead title={formatMessage({ defaultMessage: 'Archief' })}>
                <FormattedMessage defaultMessage="In deze lijst staan alle objecten die op dit moment verborgen zijn in de partner applicatie." />
            </Pagehead>
            {!!accommodations.length && (
                <>
                    <FieldHeading title={formatMessage({ defaultMessage: 'Accommodaties' })}>
                        <FormattedMessage defaultMessage="Dit zijn je gearchiveerde accommodaties:" />
                    </FieldHeading>
                    <Wrapper>
                        {accommodations.map(unit => (
                            <StyledLink key={unit.id} to={`/partner/${partnerId}/unit/${unit.slug}`}>
                                <UnitDetails unit={unit} />
                            </StyledLink>
                        ))}
                    </Wrapper>
                </>
            )}
            {!!activities.length && (
                <>
                    <FieldHeading title={formatMessage({ defaultMessage: 'Activiteiten' })}>
                        <FormattedMessage defaultMessage="Dit zijn je gearchiveerde activiteiten:" />
                    </FieldHeading>
                    <Wrapper>
                        {activities.map(activity => (
                            <StyledLink key={activity.id} to={`/partner/${partnerId}/activity/${activity.slug}`}>
                                <ActivityDetails entity={activity} />
                            </StyledLink>
                        ))}
                    </Wrapper>
                </>
            )}
        </ContentWrapper>
    );
};

export default Archive;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    > * {
        border-top: 1px solid ${({ theme }) => theme.colors.neutral[20]};
        padding: ${({ theme }) => theme.spacing['40_Standard']} 0;
        padding-left: ${({ theme }) => theme.spacing['30_Small']};
    }
`;

const StyledLink = styled(Link)`
    :hover {
        background: ${({ theme }) => theme.colors.neutral[10]};
    }
`;
