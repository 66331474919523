import { NetworkStatus } from '@apollo/client';
import gql from 'graphql-tag';
import React, { FC, useCallback } from 'react';
import { RecentlyUpdatedBookingsQuery, useRecentlyUpdatedBookingsQuery } from '../../../generated/graphql';
import BookingsList from '../../molecules/BookingsList';
import { STEP } from '../Bookings';
import { useParams } from 'react-router-dom';
import { PartnerParams } from '../../../entities/PartnerParams';
import { useIntl } from 'react-intl';

export const query = gql`
    query RecentlyUpdatedBookings($partnerId: ID!, $after: String, $first: Int, $before: String, $last: Int) {
        partner(id: $partnerId) {
            id
            recentlyUpdatedBookings(first: $first, last: $last, after: $after, before: $before) {
                ...BookingConnection
            }
        }
    }
`;

const RecentlyUpdated: FC<React.PropsWithChildren<unknown>> = () => {
    const { partnerId } = useParams<PartnerParams>();
    const { formatMessage } = useIntl();

    const { fetchMore, loading, data, networkStatus } = useRecentlyUpdatedBookingsQuery({
        variables: { first: STEP * 3, partnerId },
        notifyOnNetworkStatusChange: true,
    });

    const bookings = data?.partner?.recentlyUpdatedBookings;

    const hasNextPage = !!data?.partner?.recentlyUpdatedBookings?.pageInfo.hasNextPage;

    const isNextPageLoading = networkStatus === NetworkStatus.fetchMore;
    const onLoadMore = useCallback(async () => {
        if (loading) {
            return;
        }

        await fetchMore({
            variables: { after: data?.partner?.recentlyUpdatedBookings?.pageInfo.endCursor },
            updateQuery: (prev, { fetchMoreResult }): RecentlyUpdatedBookingsQuery => {
                if (!fetchMoreResult || !prev.partner?.recentlyUpdatedBookings?.edges) {
                    return prev;
                }
                const newEdges = fetchMoreResult?.partner?.recentlyUpdatedBookings?.edges;
                const pageInfo = fetchMoreResult?.partner?.recentlyUpdatedBookings?.pageInfo;
                return {
                    ...prev,
                    partner: {
                        ...prev.partner,
                        recentlyUpdatedBookings: prev.partner?.recentlyUpdatedBookings
                            ? {
                                  ...prev.partner.recentlyUpdatedBookings,
                                  edges: [...prev.partner.recentlyUpdatedBookings.edges, ...(newEdges ?? [])],
                                  pageInfo: pageInfo ?? prev.partner.recentlyUpdatedBookings.pageInfo,
                              }
                            : null,
                    },
                };
            },
        });
    }, [loading, data, fetchMore]);

    return (
        <BookingsList
            noResultsText={formatMessage({ defaultMessage: 'Geen nieuwe boekingen' })}
            isNextPageLoading={isNextPageLoading}
            isLoading={loading}
            hasNextPage={hasNextPage}
            entries={bookings}
            onLoadMore={onLoadMore}
        />
    );
};

export default RecentlyUpdated;
