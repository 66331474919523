import React, { FC, Dispatch, SetStateAction } from 'react';
import NavDropdown from './NavDropdown';
import { StyledInfoHeading } from '../atoms/InfoHeading';
import styled from 'styled-components/macro';

interface Props {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

const InfoMobileMenu: FC<React.PropsWithChildren<Props>> = ({ children, open, setOpen }) => (
    <StyledInfoMobileMenu background="#0f2431" height="5.8rem" {...{ open, setOpen }}>
        {children}
    </StyledInfoMobileMenu>
);

export default InfoMobileMenu;

const StyledInfoMobileMenu = styled(NavDropdown)<{ open: boolean }>`
    width: 100%;
    position: fixed;
    top: ${({ theme }) => theme.heights.nav}px;
    bottom: ${({ theme }) => theme.heights.unitNav}px;
    z-index: 2;
    height: ${({ open }) => (!open ? '5.8rem' : 'auto')};

    @media (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        top: ${({ theme }) => theme.heights.mobileNav}px;
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        display: none;
    }

    ${StyledInfoHeading} {
        padding: 3rem 2rem 1rem;
        margin: 0;
        background: ${({ theme }) => theme.colors.neutral[0]};
    }
`;
