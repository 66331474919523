import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import { CheckboxField } from '@oberoninternal/travelbase-ds/components/form/Checkbox';
import { FormActionsContainer } from '@oberoninternal/travelbase-ds/components/form/FormActions';
import { Box } from '@rebass/grid';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { PriceColumnKey, PRICE_COLUMN_ROW_HINTS } from '../../constants/priceRows';
import { ALWAYS_VISIBLE_PRICE_RULES, PRICE_RULE_CONTENT } from '../../constants/priceRules';
import {
    PricesRowVisibilityFragment,
    Scalars,
    useEditDatePricingDisplayOptionsMutation,
} from '../../generated/graphql';
import { visibilityKeys } from '../../utils/getRowVisibility';
import FieldSet from '../atoms/FieldSet';
import FieldSetHint from '../atoms/FieldSetHint';
import FormScreen from '../organisms/FormScreen';
import { RootSidebarData } from './SidebarContent';
import { SidebarSeperator } from '../atoms/Seperator';
import Heading from '../atoms/Heading';
import { FormattedMessage, useIntl } from 'react-intl';

export interface PricingConfigSidebarData extends RootSidebarData, Omit<PricesRowVisibilityFragment, 'id'> {
    type: 'PRICING_CONFIG';
    onClose: () => void;
    rentalUnitId?: string;
    start: Scalars['Date']['output'];
    end: Scalars['Date']['output'];
}

interface Props {
    data: PricingConfigSidebarData;
}

export const mutation = gql`
    mutation EditDatePricingDisplayOptions($input: EditDatePricingDisplayOptionsInput!, $start: Date!, $end: Date!) {
        editDatePricingDisplayOptions(input: $input) {
            rentalUnit {
                id
                slug
                ...Pricing

                datePricings(startDate: $start, endDate: $end) {
                    ...PriceColumnPricing
                }

                datePricingModifiers(startDate: $start, endDate: $end) {
                    ...PeriodRules
                }
            }
        }
    }
`;

const PricingConfigSidebar: FC<React.PropsWithChildren<Props>> = ({ data }) => {
    const [mutate] = useEditDatePricingDisplayOptionsMutation();
    const { formatMessage } = useIntl();
    return (
        <FormScreen
            handleSubmit={async values => {
                const { showBaseStayPriceRow, showExtraPersonPriceRow, showMinimumStayPriceRow, showWeekPriceRow } =
                    values;
                if (data.rentalUnitId) {
                    await mutate({
                        variables: {
                            input: {
                                rentalUnitId: data.rentalUnitId,
                                showBaseStayPriceRow,
                                showExtraPersonPriceRow,
                                showMinimumStayPriceRow,
                                showWeekPriceRow,
                            },
                            start: data.start,
                            end: data.end,
                        },
                    });
                    data.onClose();
                }
            }}
            variant="sidebar"
            initialValues={{
                showBaseStayPriceRow: !!data?.showBaseStayPriceRow,
                showExtraPersonPriceRow: !!data?.showExtraPersonPriceRow,
                showMinimumStayPriceRow: !!data?.showMinimumStayPriceRow,
                showWeekPriceRow: !!data?.showWeekPriceRow,
                showArrivalAllowedRow: !!data?.showArrivalAllowedRow,
                showDepartureAllowedRow: !!data?.showDepartureAllowedRow,
                showNightPriceRow: !!data?.showNightPriceRow,
                showMinimumStayDurationRow: !!data?.showMinimumStayDurationRow,
            }}
            bottomChildren={({ dirty, isSubmitting }) => (
                <FormActionsContainer dirty={dirty} variant="sidebar">
                    <TextButton onClick={data.onClose}>
                        <FormattedMessage defaultMessage="Annuleren" />
                    </TextButton>
                    <Button disabled={isSubmitting || !dirty} submitting={isSubmitting} type="submit">
                        <FormattedMessage defaultMessage="Opslaan" />
                    </Button>
                </FormActionsContainer>
            )}
        >
            {() => (
                <>
                    <SidebarSeperator />
                    <Heading title={formatMessage({ defaultMessage: 'Weergave prijsregels' })}>
                        <FormattedMessage defaultMessage="Met prijsregels kun je extra regels loslaten op de door jou ingestelde prijzen." />
                    </Heading>
                    <FieldSet>
                        <div>
                            {(Object.keys(PRICE_RULE_CONTENT) as PriceColumnKey[]).map((key: PriceColumnKey, i) => (
                                <React.Fragment key={key}>
                                    {ALWAYS_VISIBLE_PRICE_RULES.includes(key) ? (
                                        <></>
                                    ) : (
                                        <Box pt={12} pb={12} key={key}>
                                            <CheckboxField name={visibilityKeys[i]} id={key}>
                                                {PRICE_RULE_CONTENT[key]}
                                                <FieldSetHint>
                                                    {formatMessage(PRICE_COLUMN_ROW_HINTS[key])}
                                                </FieldSetHint>
                                            </CheckboxField>
                                        </Box>
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    </FieldSet>
                </>
            )}
        </FormScreen>
    );
};

export default PricingConfigSidebar;
