import { Box, Flex } from '@rebass/grid';
import endOfYear from 'date-fns/endOfYear';
import format from 'date-fns/format';
import React, { ButtonHTMLAttributes, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { getDateOpts } from '../../constants/dateOpts';
import dateTextFormat from '../../constants/dateTextFormat';
import { getAssignmentColor } from '../../constants/theme';
import useMenuState, { MenuStateProps } from '../../hooks/useMenuState';
import Calendar from '../../svg/streamline-icon-calendar@140x140.svg';
import parseDate from '../../utils/parseDate';
import Arrow from '../figure/Arrow';
import Toggle from '../figure/Toggle';
import DateRangePicker from './datepicker/DateRangePicker';
import DatePicker from './datepicker/DatePicker';
import TextInput, { TextInputVariant } from './TextInput';
import { useIntl } from 'react-intl';

export interface RangeInputModifier {
    from: Date | string | null | undefined;
    to: Date | string | null | undefined;
}

// NOTE: No camelCase because of warning in console:
// React does not recognize the `togglebuttonposition` prop on a DOM element.
// If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `togglebuttonposition` instead.
export type ToggleButtonPosition = 'left' | 'right';

export interface DatePickerInputProps {
    size?: TextInputVariant;
    enabledPast?: boolean;
    staticOnSmallScreen?: { breakPoint?: number }; // we use this prop to render the datepicker statically depending on a height breakpoint
    optionalEndDate?: boolean;
    withDayName?: boolean;
    className?: string;
    withCalendarIcon?: boolean;
    name?: string;
    hideArrow?: boolean;
    placeholderFrom?: string;
    placeholderTo?: string;
    open?: boolean;
    dataCy?: string;
    disabledFuture?: boolean;
}

export interface RangePickerInputProps extends DatePickerInputProps {
    setValue: (value: RangeInputModifier) => void;
    value: RangeInputModifier;
    variant: 'range';
    disableInput?: { from?: boolean; to?: boolean };
    togglebuttonposition?: ToggleButtonPosition;
    // This prop is unfortunately needed because of how the RangePicker is used in the voorkant.
    boxWidth?: string | number;
}

export interface DayPickerInputProps extends DatePickerInputProps {
    setValue: (value: Date | string | null) => void;
    value: Date | string | null;
    variant: 'day';
    disableInput?: boolean;
}

export type FocusType = 'from' | 'to' | null;

function RangePicker({
    value,
    setOpen,
    open,
    size = 'small',
    enabledPast = false,
    withDayName = false,
    staticOnSmallScreen,
    hideArrow = false,
    withCalendarIcon = false,
    togglebuttonposition = 'right',
    className,
    placeholderFrom,
    placeholderTo,
    boxWidth = 1,
    ...props
}: RangePickerInputProps & Omit<MenuStateProps, 'menuRef'>) {
    const fromRef = useRef<HTMLInputElement>(null);
    const toRef = useRef<HTMLInputElement>(null);

    const textFormat = `${withDayName ? 'EEEEEE ' : ''}${dateTextFormat}`;

    const { locale } = useIntl();

    const dateOpts = getDateOpts(locale as 'nl' | 'de' | 'en');

    const from = value.from ? format(parseDate(value.from), `${textFormat}`, dateOpts) : '';
    const to = value.to ? format(parseDate(value.to), `${textFormat}`, dateOpts) : '';

    const [isFocus, setIsFocus] = useState<FocusType>(null);

    const localeToUse = ['nl', 'de'].includes(locale) ? (locale as 'nl' | 'de' | 'enGB') : 'enGB';

    useEffect(() => {
        // when the 'from' is filled in, focus the 'to'
        if (open && value.from && !value.to && toRef.current) {
            toRef.current.focus();
        }
    }, [value, open, toRef]);

    useEffect(() => {
        // when the datepicker closes remove focus
        if (isFocus && !open) {
            setIsFocus(null);
        }
    }, [open, isFocus, setIsFocus]);

    const handleOnFocus = (type: FocusType) => {
        setOpen(true);
        setIsFocus(type);
    };

    return (
        <Box width={boxWidth}>
            <Flex alignItems="center" flexDirection={togglebuttonposition === 'right' ? 'row' : 'row-reverse'}>
                <Flex alignItems="center">
                    <DateInput
                        disabled={props.disableInput?.from}
                        ref={fromRef}
                        value={from}
                        placeholder={placeholderFrom ?? format(new Date(), textFormat, dateOpts)}
                        onFocus={() => handleOnFocus('from')}
                        open={open}
                        variant={size}
                        hasFocus={!!(isFocus && isFocus === 'from')}
                    />
                    <DateInputsDivider mx={2} variant={size} hideArrow={hideArrow}>
                        {!hideArrow && <Arrow />}
                    </DateInputsDivider>
                    <DateInput
                        disabled={props.disableInput?.to}
                        ref={toRef}
                        value={to}
                        placeholder={placeholderTo ?? format(endOfYear(new Date()), textFormat, dateOpts)}
                        onFocus={() => handleOnFocus('to')}
                        open={open}
                        variant={size}
                        hasFocus={!!(isFocus && isFocus === 'to')}
                    />
                </Flex>
                <ToggleButton
                    withCalendarIcon={withCalendarIcon}
                    onClick={() => {
                        if (open) {
                            setOpen(false);
                        } else if (!value.from && !value.to && !props.disableInput?.from) {
                            fromRef.current?.focus();
                        } else {
                            setOpen(true);
                        }
                    }}
                    togglebuttonposition={togglebuttonposition}
                />
            </Flex>

            <DatePickerWrapper variant={size} open={open} staticOnSmallScreen={staticOnSmallScreen}>
                <DateRangePicker
                    size={size}
                    isFocus={isFocus}
                    setIsFocus={setIsFocus}
                    enabledPast={enabledPast}
                    locale={localeToUse}
                    // when the value of an input is empty (''), the value needs to be converted to null
                    // because that's what the library expects
                    value={Object.assign(
                        {},
                        ...Object.entries(value).map(([key, val]) => ({
                            [key]: val === '' ? null : val,
                        }))
                    )}
                    open={open}
                    setOpen={setOpen}
                    {...props}
                />
            </DatePickerWrapper>
        </Box>
    );
}

const DateInputsDivider = styled(Box)<{ variant: TextInputVariant; hideArrow: boolean }>`
    width: 2rem;

    ${({ hideArrow }) =>
        hideArrow &&
        css`
            border-right: 1px solid #ebebeb;
            width: initial;
        `}

    height: ${({ variant, hideArrow }) =>
        hideArrow && variant === 'medium' ? '4.8rem' : hideArrow && variant === 'small' ? '3.6rem' : 'initial'};
`;

function DayPicker({
    setOpen,
    open,
    size = 'small',
    enabledPast = false,
    staticOnSmallScreen,
    value,
    className,
    withCalendarIcon = false,
    ...props
}: DayPickerInputProps & Omit<MenuStateProps, 'menuRef'>) {
    const dayRef = useRef<HTMLInputElement>(null);
    const formattedDate = value ? format(parseDate(value), dateTextFormat, getDateOpts('nl')) : '';

    return (
        <Box width={1} className={className}>
            <Flex alignItems="center" justifyContent="space-between">
                <DateInput
                    disabled={props.disableInput}
                    ref={dayRef}
                    value={formattedDate}
                    placeholder="1 jan. 2019"
                    onFocus={() => setOpen(true)}
                    open={open}
                    fullWidth
                    variant={size}
                />
                <ToggleButton
                    withCalendarIcon={withCalendarIcon}
                    onClick={() => (open ? setOpen(false) : !value ? dayRef.current?.focus() : setOpen(true))}
                />
            </Flex>

            <DatePickerWrapper open={open} variant={size} staticOnSmallScreen={staticOnSmallScreen}>
                <DatePicker
                    enabledPast={enabledPast}
                    value={!value || value === '' ? undefined : parseDate(value)}
                    {...props}
                    onClick={() => setOpen(false)}
                />
            </DatePickerWrapper>
        </Box>
    );
}

function DatePickerInput({
    className,
    dataCy,
    ...props
}: (RangePickerInputProps | DayPickerInputProps) & { error?: string }) {
    const {
        open,
        menuProps: { menuRef, ...menuProps },
    } = useMenuState();
    let inputField = null;

    switch (props.variant) {
        case 'day':
            inputField = <DayPicker {...props} {...menuProps} />;
            break;
        case 'range':
            inputField = <RangePicker {...props} {...menuProps} />;
            break;
        default:
            break;
    }

    return (
        <Container
            className={className}
            open={open}
            variant={props.size}
            error={props.error}
            ref={menuRef}
            staticOnSmallScreen={props.staticOnSmallScreen}
            data-cy={dataCy}
        >
            {inputField}
        </Container>
    );
}

export default DatePickerInput;

interface ToggleButtonProps {
    withCalendarIcon?: boolean;
}

function ToggleButton(
    props: ButtonHTMLAttributes<HTMLButtonElement> &
        ToggleButtonProps &
        Pick<RangePickerInputProps, 'togglebuttonposition'>
) {
    return (
        <ToggleWrapper {...props} type="button" togglebuttonposition={props.togglebuttonposition}>
            {props.withCalendarIcon ? <StyledCalendar togglebuttonposition={props.togglebuttonposition} /> : <Toggle />}
        </ToggleWrapper>
    );
}

const ToggleWrapper = styled.button<{ togglebuttonposition?: ToggleButtonPosition }>`
    height: 3.6rem;
    padding: ${({ togglebuttonposition }) => (togglebuttonposition === 'right' ? '0 1.4rem' : '0 0.8rem 0 1.4rem')};
    outline: none;
`;

interface InputProps {
    open: boolean;
    fullWidth?: boolean;
    hasFocus?: boolean;
}

const borderCss = css<ContainerProps>`
    height: ${({ variant }) => (variant === 'medium' ? '4.8rem' : '3.6rem')};

    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        height: 3.6rem;
    }
    --border-color: ${({ theme, open, error }) =>
        error
            ? `${theme.colors.negative['60']} !important`
            : open
            ? getAssignmentColor(theme, theme.colorAssignments.input)
            : theme.colors.neutral['20']};
    box-shadow: 0 0 0 2px var(--border-color), inset 0 0 0 0 var(--border-color);
    border-radius: 0.5rem;
`;

const DateInput = styled(TextInput).attrs(() => ({ type: 'text', readOnly: true }))<InputProps>`
    line-height: normal;
    min-width: ${({ fullWidth = false }) => (fullWidth ? '100%' : 'auto')};
    --border-color: none;
    ${({ hasFocus, theme }) =>
        hasFocus ? `--border-color: ${getAssignmentColor(theme, theme.colorAssignments.input)} !important` : ''};

    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        width: 100%;
    }

    padding-right: ${({ theme }) => theme.spacing['20_Tiny']};

    :disabled {
        color: ${({ theme }) => theme.colors.neutral['40']};
        background: ${({ theme }) => theme.colors.neutral['0']};
    }
`;

interface ContainerProps {
    open: boolean;
    variant?: TextInputVariant;
    error?: string;
    staticOnSmallScreen?: { breakPoint?: number };
}

const DatePickerWrapper = styled.div<ContainerProps>`
    visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
    position: absolute;
    padding-top: 1rem;
    left: 0;
    box-shadow: 0 20px 40px 0 rgba(16, 36, 48, 0.06);
    margin: 0;
    width: 100%;
    ${({ staticOnSmallScreen, open }) =>
        staticOnSmallScreen &&
        css`
            @media (max-height: ${staticOnSmallScreen.breakPoint}px) {
                position: ${open ? 'static' : 'absolute'};
            }
        `}
`;

const Container = styled(Flex)<ContainerProps>`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 40rem;
    z-index: 1;
    ${({ staticOnSmallScreen }) =>
        staticOnSmallScreen
            ? css<ContainerProps>`
                  @media (max-height: ${staticOnSmallScreen.breakPoint}px) {
                      height: ${({ open, variant }) => (!open ? (variant === 'medium' ? '4.8rem' : '3.6rem') : '100%')};

                      ::after {
                          top: 0;
                          pointer-events: none;
                          position: absolute;
                          content: '';
                          width: 100%;
                          ${borderCss};
                      }
                  }

                  @media (min-height: ${staticOnSmallScreen.breakPoint}px) {
                      ${borderCss}
                  }
              `
            : css`
                  @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
                      width: 100%;
                  }

                  ${borderCss}
              `}
`;

const StyledCalendar = styled(Calendar)<{ togglebuttonposition?: ToggleButtonPosition }>`
    color: ${({ theme }) => theme.colors.neutral['40']};
    width: ${({ theme }) => theme.spacing['40_Standard']};
    height: 100%;
    margin-right: ${({ theme, togglebuttonposition }) =>
        togglebuttonposition === 'left' ? theme.spacing['00_None'] : theme.spacing['40_Standard']};
`;
