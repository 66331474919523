import React, { CSSProperties, FC } from 'react';
import styled, { useTheme } from 'styled-components';
import format from 'date-fns/format';
import { getAssignmentColor } from '../../../constants/theme';
import { FocusType } from '../DatePickerInput';
import useWeekdays from '../../../hooks/useWeekdays';
import { getDateOpts } from '../../../constants/dateOpts';
import { DayPicker, DayPickerProps } from 'react-day-picker';

const dateOpts = getDateOpts('nl');

export const modifiersStyles: Record<string, CSSProperties> = {
    today: {
        color: 'black',
        fontWeight: 'bold',
    },
};

export const commonModifiers = { today: new Date() };

export const useCommonProps = () => {
    const weekdays = useWeekdays({ ...dateOpts, weekStartsOn: 0 }).map(date => format(date, 'EEEEEE', dateOpts));

    return {
        weekdays,
    };
};

const DayPickerComponent: FC<DayPickerProps> = props => {
    const theme = useTheme();
    return (
        <StyledDayPicker
            modifiersClassNames={{
                end: 'DayPicker-Day DayPicker-Day--end DayPicker-Day--selected',
                start: 'DayPicker-Day DayPicker-Day--start DayPicker-Day--selected',
                selected: 'DayPicker-Day--selected',
                today: 'DayPicker-Day--today',
                outside: 'DayPicker-Day--outside',
                range_middle: 'DayPicker-Day--selected',
            }}
            styles={{
                nav: {
                    position: 'absolute',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    top: 0,
                    left: 0,
                },
                nav_button_next: {
                    padding: '0.2rem 1rem',
                    color: getAssignmentColor(theme, theme.colorAssignments.input),
                    height: '45px',
                },
                nav_button_previous: {
                    padding: '0.2rem 1rem',
                    color: getAssignmentColor(theme, theme.colorAssignments.input),
                    height: '45px',
                },
                day: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    borderRadius: 0,
                    outline: 'none',
                },
                caption: {
                    display: 'table-caption',
                    textAlign: 'center',
                    captionSide: 'top',
                    fontFamily: theme.fontFamily.title,
                    margin: 0,
                    padding: 0,
                    fontWeight: 700,
                    whiteSpace: 'pre-line',
                    ...theme.fontSizes.title.small,
                },
            }}
            {...props}
        />
    );
};

export interface Common {
    onClick?: () => void;
    isFocus?: FocusType;
    setIsFocus?: (val: FocusType) => void;
    className?: string;
}

export const StyledDayPicker = styled(DayPicker)<{ single?: boolean }>`
    position: relative;
    display: block !important;
    font-size: 14px !important;
    padding: 1rem 1rem 0;
    background: ${({ theme }) => theme.colors.neutral['0']};
    z-index: 10;
    --rdp-background-color: ${({ theme }) => theme.colors.secondary['30']};
    --rdp-cell-size: 40px;
    --rdp-caption-font-size: 16px;
    @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
        padding: 1rem 1.6rem 0 1.6rem;
    }
    .rdp-nav_button:hover {
        background: none !important;
    }

    .DayPicker-wrapper,
    .DayPicker-Months {
        outline: none;
    }

    .DayPicker-rdWeekdays,
    .DayPicker-WeekdaysRow,
    .DayPicker-Week,
    .rdp-head_row,
    .rdp-month {
        width: 100%;
    }

    .rdp-month {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }

    .rdp-head_cell {
        padding: 0.5em;
        color: #8b9898;
        text-align: center;
        font-size: 0.875em;
        text-transform: lowercase;
        font-weight: normal;
    }
    .rdp-day {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 100% !important;
    }
    .DayPicker-Body {
        display: block;
        width: 100%;
        > div + div {
            margin-top: ${({ theme }) => theme.spacing['20_Tiny']};
        }
    }

    .DayPicker-WeekdaysRow,
    .rdp-head_row {
        > div {
            display: block;
            flex: 1;
        }
    }

    .DayPicker-Month {
        width: 100%;
        margin: 0;
        border-spacing: 0 4px;
        border-collapse: separate;
        display: flex !important;
        flex-direction: column;
        align-items: center;
    }

    .DayPicker-Day,
    .rdp-cell {
        border-radius: 0 !important;
        outline: none;
        flex: 1;
        position: relative;
    }

    .DayPicker-wrapper {
        z-index: 1;
    }

    .DayPicker-Day--start:not(.DayPicker-Day--outside) {
        /* NOTE: secondary works here because the secondary colors of the partner app are mapped to the primary in default.ts.
    Changing this will break the colors in this file. */
        background-color: ${({ theme }) => theme.colors.secondary['0']} !important;
        color: ${({ theme }) => theme.colors.neutral['100']} !important;
        border-radius: 50% !important;
        box-shadow: 0 0 0 0 var(--border-color), inset 0 0 0 2px var(--border-color);
        --border-color: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.input)};
        :hover {
            --border-color: ${({ theme }) => theme.colors.secondary['50']};
        }
    }

    .DayPicker-Day--start:not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
        :after {
            opacity: ${({ single }) => (single ? 1 : 0)};
            position: absolute;
            top: 0;
            left: 50%;
            z-index: -1;
            content: '';
            width: 50%;
            height: 100%;

            background-color: ${({ theme }) => theme.colors.secondary['0']};
        }
    }

    .DayPicker-Day--end:not(.DayPicker-Day--outside):not(.DayPicker-Day--start) {
        border-radius: 50% !important;

        background-color: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.input)} !important;
        ::after {
            position: absolute;
            top: 0;
            right: 50%;
            z-index: -1;
            content: '';
            width: 50%;
            height: 100%;
            background-color: ${({ theme }) => theme.colors.secondary['0']};
        }
        :hover {
            --border-color: ${({ theme }) => theme.colors.secondary['50']};
            box-shadow: 0 0 0 0 var(--border-color), inset 0 0 0 2px var(--border-color);
        }
    }

    .DayPicker-Day--start .DayPicker-Day--end .DayPicker-Day--selected {
        ::after {
            opacity: 0 !important;
        }
    }
    .DayPicker-Day:not(.DayPicker-Day--outside):not(.DayPicker-Day--selected) {
        :hover {
            color: white;
            background: unset !important;
            ::before {
                opacity: 1;
            }
            ::after {
                opacity: 0;
            }
        }
        ::before {
            content: '';
            opacity: 0;
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: -1;
            background: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.input)};
            top: 0;
            left: 0;
            transition: all 0.2s;
            border-radius: 50%;
        }
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
        background-color: ${({ theme }) => theme.colors.secondary['0']} !important;
        color: ${({ theme }) => theme.colors.neutral['100']};
        border-radius: 0 !important;
        --border-color: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.input)};

        ::before {
            content: '';
            opacity: 0;
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 1;
            box-shadow: 0 0 0 0 var(--border-color), inset 0 0 0 2px var(--border-color);
            background-color: transparent;
            top: 0;
            left: 0;
            transition: all 0.2s;
            border-radius: 50%;
        }
        :hover {
            ::before {
                opacity: 1;
            }
        }
    }
`;

export default DayPickerComponent;
