import uniqBy from 'lodash/uniqBy';
import { ApolloCache, FetchResult } from '../apollo';
import {
    ActivityTimeslotFragment,
    TimeslotsDocument,
    TimeslotsQuery,
    TimeslotsQueryVariables,
} from '../generated/graphql';

// convenience method to abstract the cache updating part of the timeslots query
const createTimeslotsUpdater =
    <ResultData extends unknown>(
        variables: TimeslotsQueryVariables,
        // used to get the timeslots from the result
        resolver: (oldSlots: ActivityTimeslotFragment[], resultData: ResultData) => ActivityTimeslotFragment[]
    ) =>
    (cache: ApolloCache<ResultData>, result: FetchResult<ResultData>) => {
        const opts = {
            variables,
            query: TimeslotsDocument,
        };
        const current = cache.readQuery<TimeslotsQuery, TimeslotsQueryVariables>(opts);

        if (!current?.activity?.timeslots || !result.data) {
            return;
        }

        cache.writeQuery<TimeslotsQuery, TimeslotsQueryVariables>({
            ...opts,
            data: {
                ...current,
                activity: {
                    ...current.activity,
                    // remove duplicates as we're merging the current and result timeslots
                    timeslots: uniqBy(resolver(current.activity.timeslots, result.data), ({ id }) => id),
                },
            },
        });
    };

export default createTimeslotsUpdater;
