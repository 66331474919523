import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import RoundButton from '@oberoninternal/travelbase-ds/components/action/RoundButton';
import Cross from '@oberoninternal/travelbase-ds/components/figure/Cross';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import { Box, Flex } from '@rebass/grid';
import { FieldArray, getIn } from 'formik';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

interface Props {
    name: string;
    placeholder: string;
    addDescription: string;
}

export const toFormikArray = (arr: string[] | undefined) => (arr?.length ? arr : ['']);

const ArrayTextInput: FC<React.PropsWithChildren<Props>> = ({ name, placeholder, addDescription }) => (
    <FieldArray name={name}>
        {({ push, remove, form: { values } }) => {
            const array: string[] = getIn(values, name);
            return (
                <Container>
                    <TextInputField id={name} placeholder={placeholder} name={`${name}.0`} />
                    {array?.slice(1)?.map((_, i) => {
                        const index = i + 1;
                        return (
                            <Flex key={`${name}-${index}`} style={{ position: 'relative' }}>
                                <Box flex={1} pr={4}>
                                    <TextInputField placeholder={placeholder} name={`${name}.${index}`} />
                                </Box>
                                <RoundButton
                                    size="small"
                                    style={{ marginTop: 8 }}
                                    variant="outline"
                                    onClick={() => remove(index)}
                                >
                                    <Cross />
                                </RoundButton>
                            </Flex>
                        );
                    })}
                    <Flex>
                        <Button variant="outline" onClick={() => push('')} disabled={array?.length === 0}>
                            {addDescription}
                        </Button>
                    </Flex>
                </Container>
            );
        }}
    </FieldArray>
);

export default ArrayTextInput;

const Container = styled(Box)`
    > * + * {
        margin-top: 16px;
    }
`;
