import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import { Warning } from '@oberoninternal/travelbase-ds/components/figure/Warning';
import { FormActionsContainer } from '@oberoninternal/travelbase-ds/components/form/FormActions';
import SelectInput, { OptionType } from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import dateTextFormat from '@oberoninternal/travelbase-ds/constants/dateTextFormat';
import { Box, Flex } from '@rebass/grid';
import format from 'date-fns/format';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getDateOpts } from '../../../../constants/dateOpts';
import { ActivityParams } from '../../../../entities/ActivityParams';
import { NotFoundError } from '../../../../entities/NotFoundError';
import {
    SetActivityTimeslotsInput,
    useSetActivityTimeslotsMutation,
    useTimeslotsWizardQuery,
    TimeslotByTimeInput,
} from '../../../../generated/graphql';
import createWeekdayRecord, { weekdays, Weekday } from '../../../../utils/createWeekdayRecord';
import parseDate from '@oberoninternal/travelbase-ds/utils/parseDate';
import { Seperator } from '../../../atoms/Seperator';
import TimeslotAllotmentField from '../../../atoms/TimeslotAllotmentField';
import RangePickerInputField from '../../../molecules/RangePickerInputField';
import WizardContent from '../../../molecules/WizardContent';
import FormScreen from '../../../organisms/FormScreen';
import Loading from '../../../organisms/Loading';
import * as Yup from 'yup';
import TimeslotsWizardWeekday from '../../../molecules/TimeslotsWizardWeekday';
import { FormattedMessage, useIntl } from 'react-intl';

export const query = gql`
    query TimeslotsWizard($activitySlug: String!) {
        activity(slug: $activitySlug) {
            ...TimeslotsWizardActivity
        }
    }

    mutation SetActivityTimeslots($input: SetActivityTimeslotsInput!) {
        setActivityTimeslots(input: $input) {
            activity {
                ...TimeslotsWizardActivity
            }
        }
    }

    fragment TimeslotsWizardActivity on Activity {
        id
        activityRateGroups {
            id
            canBuyTickets
            name
        }
    }
`;

const initialValues: Omit<SetActivityTimeslotsInput, 'activityId'> & { allotment: number | null } = {
    startDate: '',
    endDate: '',
    rateGroupId: '',
    translations: [],
    allotment: null,
    ...createWeekdayRecord(null),
};

const validationSchema = Yup.object().shape({
    startDate: Yup.string().required('Kies een startdatum'),
    endDate: Yup.string().required('Kies een einddatum'),
    rateGroupId: Yup.string().required('Kies een tariefgroep'),
});

const formatDate = (date: string) => format(parseDate(date), `EEEE ${dateTextFormat}`, getDateOpts('nl'));

const isWeekdayProperty = (entry: [unknown, unknown]): entry is [Weekday, TimeslotByTimeInput[]] => {
    const [key, value] = entry;
    const isWeekday = weekdays.includes(key as Weekday);
    return isWeekday && !!(value as TimeslotByTimeInput[])?.length;
};

const Wizard: FC<React.PropsWithChildren<unknown>> = () => {
    const history = useHistory();
    const { activitySlug, partnerId } = useParams<ActivityParams>();
    const [mutate] = useSetActivityTimeslotsMutation();
    const { data, loading } = useTimeslotsWizardQuery({ variables: { activitySlug: activitySlug! } });
    const { formatMessage, locale } = useIntl();
    if (loading) {
        return <Loading amountOfFields={0} />;
    }

    if (!data?.activity) {
        throw new NotFoundError();
    }
    const {
        activity: { activityRateGroups, id },
    } = data;

    const rateGroupOptions = activityRateGroups.map(
        (rateGroup): OptionType => ({ label: rateGroup.name!, value: rateGroup.id })
    );

    return (
        <FormScreen
            variant="wizard"
            validationSchema={validationSchema}
            bottomChildren={({ dirty, isSubmitting }) => (
                <FormActionsContainer dirty={dirty} alwaysVisible variant="wizard">
                    <TextButton onClick={history.goBack}>
                        <FormattedMessage defaultMessage="Prijsinstelling sluiten" />
                    </TextButton>
                    <Button disabled={isSubmitting || !dirty} submitting={isSubmitting} type="submit">
                        <FormattedMessage defaultMessage="Wijzigingen publiceren" />
                    </Button>
                </FormActionsContainer>
            )}
            handleSubmit={async ({ allotment, ...values }, resetForm) => {
                // api flaw solution
                // as allotment should be set once and applied to al timeslots we'll manually add it to all timeslots before submitting
                const input = Object.entries(values).reduce<SetActivityTimeslotsInput>(
                    (acc, next) => {
                        if (!isWeekdayProperty(next)) {
                            return acc;
                        }

                        const [weekday, timeslots] = next;

                        return { ...acc, [weekday]: timeslots.map(timeslot => ({ ...timeslot, allotment })) };
                    },
                    { activityId: id, ...values }
                );
                await mutate({ variables: { input } });
                resetForm({ allotment, ...values });
                history.push(`${locale}/partner/${partnerId}/activity/${activitySlug}/planning`);
            }}
            initialValues={initialValues}
        >
            {({ values }) => (
                <>
                    <Pagehead
                        title={formatMessage(
                            { defaultMessage: 'Jouw nieuwe tijdslots van {startDate} tot {endDate}' },

                            {
                                startDate: values.startDate && formatDate(values.startDate),
                                endDate: values.endDate && formatDate(values.endDate),
                            }
                        )}
                    >
                        <FormattedMessage defaultMessage="Hier kun je tijdslots instellen voor een door jou te bepalen periode in de toekomst. Per dag in deze periode kun je tijdslots instellen." />
                    </Pagehead>
                    <Flex my={3}>
                        <Warning style={{ flexShrink: 0, margin: '0.4rem 0.4rem 0 0' }} />
                        <Body variant="small">
                            <FormattedMessage defaultMessage="Let op: alle bestaande tijdslots in de geselecteerde periode worden vervangen op de geselecteerde dagen." />
                        </Body>
                    </Flex>
                    <Seperator />
                    <WizardContent
                        label={formatMessage({ defaultMessage: 'Periode (van - t/m)' })}
                        hint={formatMessage({
                            defaultMessage:
                                'De tijdslots die je in deze periode instelt gelden voor dagen in de door jou gekozen periode.',
                        })}
                    >
                        <RangePickerInputField size="medium" withDayName />
                    </WizardContent>

                    <WizardContent label={formatMessage({ defaultMessage: 'Tariefgroep' })}>
                        <Box style={{ maxWidth: '32rem' }} width={1}>
                            <SelectInput
                                placeholder={formatMessage({ defaultMessage: 'Selecteer' })}
                                name="rateGroupId"
                                options={rateGroupOptions}
                            />
                        </Box>
                    </WizardContent>

                    <TimeslotAllotmentField Container={WizardContent} rateGroups={activityRateGroups} />

                    <Box>
                        {weekdays.map((weekday, weekdayIndex) => (
                            <TimeslotsWizardWeekday key={weekday} weekday={weekday} weekdayIndex={weekdayIndex} />
                        ))}
                    </Box>
                </>
            )}
        </FormScreen>
    );
};

export default Wizard;
