import { Box } from '@rebass/grid';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { UnitParams } from '../../../../entities/UnitParams';
import FieldSet from '../../../atoms/FieldSet';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import LabelBox from '../../../molecules/LabelBox';
import FormScreen from '../../../organisms/FormScreen';
import { iCalValidation } from './iCalValidation';
import { FormattedMessage, useIntl } from 'react-intl';
import ArrayTextInput from '../../../molecules/ArrayTextInput';
import omitEqualValues from '../../../../utils/omitEqualValues';
import Loading from '../../../organisms/Loading';
import { NotFoundError } from '../../../../entities/NotFoundError';
import { useMutation, useQuery } from '@apollo/client';
import { SOURCES } from '@oberoninternal/travelbase-ds/components/calendar/Allotment';
import { Seperator } from '../../../atoms/Seperator';
import styled from 'styled-components/macro';

const fragment = gql`
    fragment Ical on RentalUnit {
        id
        slug
        type
        icalImportFeeds {
            icalImportUrl
            source
        }
        icalFeedUrl
        icalFeedUrlBookings
    }
`;

export const icalQuery = gql`
    query Ical($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            ...Ical
        }
    }
    ${fragment}
`;

export const editIcalImportFeedMutation = gql`
    mutation EditIcalImportFeed($input: EditIcalImportFeedsInput!) {
        editIcalImportFeeds(input: $input) {
            icalImportFeeds {
                icalImportUrl
                source
            }
        }
    }
`;

const Ical: FC<React.PropsWithChildren<UnitParams>> = ({ unitSlug }) => {
    // const { data, loading } = useIcalQuery({ variables: { unitSlug } });
    const { data, loading } = useQuery(icalQuery, { variables: { unitSlug } });
    // const [mutate] = useCreateICalImportFeedMutation();
    const [mutate] = useMutation(editIcalImportFeedMutation, {
        refetchQueries: [
            {
                query: icalQuery,
                variables: { unitSlug },
            },
        ],
    });
    const intl = useIntl();
    const { formatMessage } = intl;
    if (loading) {
        return <Loading />;
    }

    if (!data || !data.rentalUnit) {
        throw new NotFoundError();
    }

    const {
        rentalUnit: { id, icalImportFeeds, icalFeedUrl, icalFeedUrlBookings },
    } = data;

    const initialValues = {
        importFeeds: icalImportFeeds.map((feed: { icalImportUrl: string }) => feed.icalImportUrl),
    };

    const getSourceFromUrl = (url: string) => {
        if (url.includes('booking.com')) {
            return SOURCES.BOOKING;
        }
        if (url.includes('airbnb.nl')) {
            return SOURCES.AIRBNB;
        }
        if (url.includes('natuurhuisje.nl')) {
            return SOURCES.NATUURHUISJES;
        }
        return SOURCES.OTHER;
    };

    return (
        <FormScreen
            validationSchema={iCalValidation(intl)}
            handleSubmit={async values => {
                await mutate({
                    variables: {
                        input: {
                            rentalUnitId: id,
                            ...omitEqualValues(values, initialValues, []),
                            importFeeds: values.importFeeds
                                .filter((icalImportUrl: string) => icalImportUrl !== '')
                                .map((icalImportUrl: string) => ({
                                    icalImportUrl,
                                    source: getSourceFromUrl(icalImportUrl),
                                })),
                        },
                    },
                });
            }}
            initialValues={initialValues}
        >
            {() => (
                <>
                    <Title>
                        <FormattedMessage defaultMessage="Beheer uw Kalender met iCal Feeds" />
                    </Title>
                    <p>
                        <FormattedMessage defaultMessage="Voeg eenvoudig iCal feed URLs toe om automatisch blokkeringen in uw kalender in te stellen. Houd uw agenda up-to-date met de nieuwste afspraken en evenementen, en vermijd dubbele boekingen door real-time synchronisatie. Perfect voor het beheren van persoonlijke en professionele verplichtingen op één plek." />
                    </p>
                    {icalFeedUrl && (
                        <>
                            <strong>
                                <FormattedMessage defaultMessage="iCal export feed URL met reserveringen en blokkeringen" />
                            </strong>
                            &nbsp;
                            <FormattedMessage defaultMessage="(voor wanneer je Travelbase als leidend gebruikt)" />
                            <DivWithMargin>{icalFeedUrl}</DivWithMargin>
                        </>
                    )}
                    {icalFeedUrlBookings && (
                        <>
                            <strong>
                                <FormattedMessage defaultMessage="iCal export feed URL met alleen reserveringen" />
                            </strong>
                            &nbsp;
                            <FormattedMessage defaultMessage="(voor wanneer je een ander systeem, bijv. booking.com of airbnb, gebruikt als leidend)" />
                            <DivWithMargin>{icalFeedUrlBookings}</DivWithMargin>
                        </>
                    )}

                    {(icalFeedUrl || icalFeedUrlBookings) && <Seperator />}

                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="ical">
                                <FormattedMessage defaultMessage="iCal feeds" />
                            </Label>
                        </LabelBox>
                        <Box width={1}>
                            <ArrayTextInput
                                name="importFeeds"
                                placeholder={formatMessage({ defaultMessage: 'ICal feed URL' })}
                                addDescription={formatMessage({ defaultMessage: 'Extra veld' })}
                            />
                        </Box>
                    </FieldSet>
                </>
            )}
        </FormScreen>
    );
};

const DivWithMargin = styled.div`
    margin-bottom: 1.6rem;
`;

export default Ical;
