import React, { FC, useCallback, useState } from 'react';
import isBefore from 'date-fns/isBefore';
import DayPicker, { Common, modifiersStyles } from './DayPicker';
import { DayPickerInputProps } from '../DatePickerInput';
import { DayClickEventHandler } from 'react-day-picker';
import { enUS, de, nl } from 'date-fns/locale';

type DatePickerProps = Omit<DayPickerInputProps, 'value'> & { value?: Date } & Common & {
        locale?: string;
    };

const DatePicker: FC<React.PropsWithChildren<DatePickerProps>> = props => {
    const { value, setValue, onClick, enabledPast, disabledFuture, locale } = props;
    const disabledDays = [];

    if (!enabledPast) {
        disabledDays.push({ before: new Date() });
    }

    if (disabledFuture) {
        disabledDays.push({ after: new Date() });
    }

    const onDayClick: DayClickEventHandler = useCallback(
        day => {
            setValue(day);
            if (onClick) {
                onClick();
            }
        },
        [onClick, setValue]
    );

    const [month, setMonth] = useState<Date | undefined>(value);
    // as disabled days aren't interactive it doesn't really make sense to open
    // the picker on this month, so we adjust it to the current date;
    if (month && isBefore(month, new Date()) && !enabledPast) {
        setMonth(new Date());
    }

    return (
        <DayPicker
            onMonthChange={selectedMonth => {
                setMonth(selectedMonth);
            }}
            month={month}
            weekStartsOn={1}
            className="Selectable"
            disabled={disabledDays}
            modifiersStyles={modifiersStyles}
            onDayClick={onDayClick}
            selected={value}
            mode="single"
            locale={locale === 'nl' ? nl : locale === 'de' ? de : enUS}
        />
    );
};

export default DatePicker;
