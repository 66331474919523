import * as Yup from 'yup';
import internationalPhoneRegex from '../../constants/internationalPhoneRegex';
import { IntlShape } from 'react-intl';

export const languageSchema = Yup.object().shape({
    en: Yup.string().required(),
    nl: Yup.string().required(),
    de: Yup.string().required(),
});

export const phoneSchema = (intl: IntlShape) =>
    Yup.string().matches(
        internationalPhoneRegex,
        intl.formatMessage({
            defaultMessage: 'Vul een geldig telefoonnummer in',
        })
    );
