import { Field, FieldConfig, FieldProps, getIn } from 'formik';
import React, { FC, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { Label } from '../primitive/Label';
import ErrorMessage from './ErrorMessage';
import { getAssignmentColor } from '../../constants/theme';
import createBorderCss from '../../utils/createBorderCss';

interface RadioFieldProps extends InputHTMLAttributes<HTMLInputElement> {
    error?: string;
}

const Radio: FC<React.PropsWithChildren<RadioFieldProps>> = ({ children, error, ...props }) => (
    <>
        <Container hasError={!!error}>
            <HiddenRadio {...props} type="radio" />
            <Checkmark hasError={!!error} />
            <StyledLabel htmlFor={props.id}>{children}</StyledLabel>
        </Container>
        {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
);

export const RadioField: FC<React.PropsWithChildren<InputHTMLAttributes<HTMLInputElement> & FieldConfig>> = ({
    name,
    validate,
    ...props
}) => (
    <Field name={name} validate={validate}>
        {({ field, form: { errors, touched } }: FieldProps) => (
            <Radio
                error={touched[field.name] && errors[field.name] ? getIn(errors, field.name) : undefined}
                checked={props.id === field.value}
                {...props}
                {...field}
                value={props.id}
            />
        )}
    </Field>
);

export default Radio;

const HiddenRadio = styled.input.attrs(() => ({ type: 'radio' }))`
    position: absolute;
    opacity: 0;
`;

const StyledLabel = styled(Label).attrs(() => ({ variant: 'regular' }))`
    font-weight: 400;
    ${HiddenRadio}:disabled ~ & {
        color: ${({ theme }) => theme.colors.neutral['30']};
    }
`;

const Checkmark = styled.span<{ hasError: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: ${({ theme }) => theme.colors.neutral['0']};
    border-radius: 50%;
    ${createBorderCss('2px')};
    --border-color: ${({ theme, hasError }) => (!hasError ? theme.colors.neutral['20'] : theme.colors.negative['60'])};

    ::after {
        top: 6px;
        left: 6px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: white;
        content: '';
        position: absolute;
        display: none;
    }
`;

const Container = styled.label<{ hasError: boolean }>`
    display: flex;
    position: relative;
    padding-left: 35px;
    font-size: 22px;
    user-select: none;
    width: fit-content;
    height: 25px;

    ${HiddenRadio}:hover + ${Checkmark} {
        ::after {
            display: block;
            background: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.input, -3)};
        }
    }

    ${HiddenRadio}:focus + ${Checkmark} {
        --border-color: ${({ theme, hasError }) =>
            !hasError ? getAssignmentColor(theme, theme.colorAssignments.input) : theme.colors.negative['60']};
    }

    ${HiddenRadio}:checked + ${Checkmark} {
        ::after {
            display: block;
            background: ${({ theme, hasError }) =>
                !hasError ? getAssignmentColor(theme, theme.colorAssignments.input) : theme.colors.negative['60']};
        }
    }

    ${HiddenRadio}:disabled + ${Checkmark} {
        --border-color: ${({ theme }) => theme.colors.neutral['30']};
        cursor: not-allowed;
    }

    ${HiddenRadio}:disabled:checked + ${Checkmark} {
        ::after {
            background: ${({ theme }) => theme.colors.neutral['30']};
        }
    }

    ${HiddenRadio}:hover:disabled:not(:checked) + ${Checkmark} {
        ::after {
            display: none;
        }
    }
`;
