import ActionMenu, { Item, MenuList } from '@oberoninternal/travelbase-ds/components/action/ActionMenu';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Toast from '@oberoninternal/travelbase-ds/components/feedback/Toast';
import { FormActionsContainer } from '@oberoninternal/travelbase-ds/components/form/FormActions';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Content from '@oberoninternal/travelbase-ds/components/section/Content';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import dateTextFormat from '@oberoninternal/travelbase-ds/constants/dateTextFormat';
import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';
import { Box, Flex } from '@rebass/grid';
import format from 'date-fns/format';
import gql from 'graphql-tag';
import React, { FC, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';
import { getDateOpts } from '../../../constants/dateOpts';
import euroFormat from '../../../constants/euroFormat';
import { NotFoundError } from '../../../entities/NotFoundError';
import {
    BookingPriceLine,
    BookingStatusEnum,
    Customer,
    PriceLineCategoryEnum,
    useAcceptBookingMutation,
    useBookingQuery,
} from '../../../generated/graphql';
import createGuestsText from '../../../utils/createGuestsText';
import addressHasValues from '../../../utils/addressHasValues';
import { getBookingsStatus } from '../../../utils/getBookingsStatus';
import { shouldHideBookingCustomer } from '../../../utils/shouldHideBooking';
import ContentWrapper from '../../atoms/ContentWrapper';
import { Seperator } from '../../atoms/Seperator';
import Table from '../../atoms/Table';
import UnitDetails from '../../atoms/UnitDetails';
import FieldHeading from '../../molecules/FieldHeading';
import Print, { PrintRow } from '../../molecules/Print';
import Loading from '../../organisms/Loading';
import { FormattedMessage, useIntl } from 'react-intl';

export const fragment = gql`
    fragment BookingDetails on Booking {
        id
        customer {
            ...CustomerDetails
        }
        number
        amountAdults
        amountYouths
        amountChildren
        amountBabies
        amountPets
        departureDate
        arrivalDate
        createdAt
        status
        totalPrice
        partnerPriceLines {
            totalPrice
            label
            modifier
            unitPrice
            totalPrice
            category
        }
        duration
        rentalUnit {
            ...UnitDetails
            accommodation {
                id
                name
                checkInStartTime
                checkInEndTime
                checkOutTime
            }
        }
        customerComment
    }

    fragment CustomerDetails on Customer {
        id
        firstName
        lastName
        birthdate
        phoneNumber
        email
        address {
            city
            countryCode
            countryName
            number
            postalCode
            street
        }
    }
`;

export const query = gql`
    query Booking($id: ID!) {
        booking(id: $id) {
            ...BookingDetails
        }
    }
`;

export const mutation = gql`
    mutation AcceptBooking($input: AcceptBookingInput!) {
        acceptBooking(input: $input) {
            booking {
                ...BookingDetails
            }
        }
    }
`;

export const getGuestTitle = (customer: Maybe<Pick<Customer, 'firstName' | 'lastName'>>, hideInfo: boolean) => {
    if (!customer) {
        return 'Anoniem';
    }

    if (hideInfo) {
        return 'Afgeschermd';
    }
    return `${customer?.firstName ?? ''} ${customer?.lastName ?? ''}`;
};

const Details: FC<React.PropsWithChildren<unknown>> = () => {
    const { formatMessage, locale } = useIntl();
    const dateOpts = getDateOpts(locale as 'nl' | 'de' | 'en');
    const history = useHistory();
    const { bookingId } = useParams<{ bookingId: string }>();
    const { data, loading } = useBookingQuery({ variables: { id: bookingId } });
    const isOnRequest = data?.booking?.status === BookingStatusEnum.Pending;

    const [mutate, { loading: isSubmitting }] = useAcceptBookingMutation();
    const ref = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => ref.current,
        documentTitle: isOnRequest ? 'Aanvraag' : `Boeking ${data?.booking?.number ?? ''}`,
    });
    const [accepted, setAccepted] = useState<boolean | null>();

    if (loading) {
        return <Loading variant="default" />;
    }

    if (!data || !data.booking) {
        throw new NotFoundError();
    }

    const {
        booking: {
            status,
            customer,
            duration,
            partnerPriceLines,
            totalPrice,
            customerComment,
            rentalUnit: {
                accommodation: { checkInStartTime, checkInEndTime, checkOutTime, name },
                ...rentalUnit
            },
            ...booking
        },
    } = data;

    const hideInfo = shouldHideBookingCustomer(status);

    const guestText = getGuestTitle(customer, hideInfo);

    const onActionClick = async (accept: boolean) => {
        try {
            setAccepted(accept);
            await mutate({ variables: { input: { bookingId: booking.id, accept } } });
            toast(
                <Toast
                    variant="success"
                    title={
                        accept
                            ? formatMessage({ defaultMessage: 'De boeking is geaccepteerd' })
                            : formatMessage({ defaultMessage: 'De boeking is afgewezen' })
                    }
                >
                    <Body variant="small">
                        {accept ? (
                            <FormattedMessage defaultMessage="De boeker ontvangt hiervan direct een bevestiging." />
                        ) : (
                            <FormattedMessage defaultMessage="Wij laten de aanvrager weten dat deze aanvraag niet is geaccepteerd. Er is geen verdere actie nodig." />
                        )}
                    </Body>
                </Toast>,
                {
                    autoClose: 10000,
                    closeButton: true,
                }
            );
            history.goBack();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Booking On Request handling failed:', error);
        }
    };

    const departureDate = new Date(booking.departureDate);
    const arrivalDate = new Date(booking.arrivalDate);
    const createdAt = new Date(booking.createdAt);
    const bookingStatus = getBookingsStatus({ arrivalDate, departureDate, status });

    // seperate pricelines that are in the total price and those that are not
    const [relatedPriceLines, unrelatedPriceLines] = partnerPriceLines.reduce<[BookingPriceLine[], BookingPriceLine[]]>(
        ([related, unrelated], next) => {
            if (next.category === PriceLineCategoryEnum.NotInTotal) {
                return [related, unrelated.concat(next)];
            }
            return [related.concat(next), unrelated];
        },
        [[], []]
    );

    const priceLinesContent = (
        <tbody>
            {relatedPriceLines.map(({ label, modifier, unitPrice, ...rest }, i) => (
                <tr key={i}>
                    <td>{label}</td>
                    <td align="right">{unitPrice && euroFormat(unitPrice)}</td>
                    <td align="right">{modifier}</td>
                    <td align="right">
                        <strong>{rest.totalPrice && euroFormat(rest.totalPrice)}</strong>
                    </td>
                </tr>
            ))}
            <tr className="seperator-row">
                <td colSpan={4} />
            </tr>
            <tr>
                <td colSpan={3}>
                    <FormattedMessage defaultMessage="Totaal" />
                </td>
                <td align="right">
                    <strong>{euroFormat(totalPrice)}</strong>
                </td>
            </tr>
            {unrelatedPriceLines.map(line => (
                <tr key={line.label}>
                    <td colSpan={3}>{line.label}</td>
                    <td align="right">
                        <strong>{euroFormat(line.totalPrice)}</strong>
                    </td>
                </tr>
            ))}
        </tbody>
    );

    const bookingsDetails = [
        {
            label: 'Huidige status',
            content: (
                <>
                    {' '}
                    {bookingStatus.text}
                    {bookingStatus.subText && `, ${bookingStatus.subText}`}{' '}
                </>
            ),
        },
        {
            label: 'Accommodatie',
            content: <UnitDetails style={{ marginTop: '-3px' }} unit={rentalUnit} locationName={name} />,
        },
        {
            label: 'Check-in',
            content: (
                <FormattedMessage
                    defaultMessage="{date} {startTime}-{endTime}"
                    values={{
                        date: format(arrivalDate, 'd MMMM yyyy', dateOpts),
                        startTime: checkInStartTime,
                        endTime: checkInEndTime,
                    }}
                />
            ),
        },
        {
            label: 'Check-out',
            content: (
                <FormattedMessage
                    defaultMessage="{date} om {time}"
                    values={{
                        date: format(departureDate, 'd MMMM yyyy', dateOpts),
                        time: checkOutTime,
                    }}
                />
            ),
        },
        {
            label: 'Aantal nachten',
            content: (
                <FormattedMessage
                    defaultMessage="{duration} nachten"
                    values={{
                        duration,
                    }}
                />
            ),
        },
        {
            label: 'Reisgezelschap',
            content: createGuestsText(booking),
        },
        {
            label: 'Datum en tijd reservering',

            content: (
                <FormattedMessage
                    defaultMessage="{date} om {time}"
                    values={{
                        date: format(createdAt, 'd MMMM yyyy', dateOpts),
                        time: format(createdAt, 'HH:mm', dateOpts),
                    }}
                />
            ),
        },
        booking.number && {
            label: 'Boekingsnummer',
            content: booking.number,
        },
        customerComment && {
            label: 'Opmerkingen',
            content: customerComment,
        },
    ];

    return (
        <>
            <ContentWrapper>
                <Pagehead backButtonText="Terug" title={guestText} />
                <Print ref={ref}>
                    <h1>{guestText}</h1>
                    <h3>
                        <FormattedMessage
                            defaultMessage="Aangemaakt op {date}"
                            values={{
                                date: format(createdAt, dateTextFormat, dateOpts),
                            }}
                        />
                    </h3>
                    <h4>
                        <FormattedMessage defaultMessage="Boekingsinformatie" />
                    </h4>
                    {bookingsDetails.map(
                        entry =>
                            entry && (
                                <PrintRow key={entry.label} label={entry.label}>
                                    {entry.content}
                                </PrintRow>
                            )
                    )}
                    <h5 style={{ margin: '0.8rem 0 0 0' }}>
                        <FormattedMessage defaultMessage="Prijsopbouw" />
                    </h5>
                    <table>{priceLinesContent}</table>
                    {customer && !hideInfo && (
                        <>
                            <h4>
                                <FormattedMessage defaultMessage="Gegevens boeker" />
                            </h4>

                            {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                            <PrintRow label="Naam">{`${customer.firstName} ${customer.lastName}`}</PrintRow>
                            {isOnRequest && customer.birthdate && (
                                <PrintRow label={formatMessage({ defaultMessage: 'Geboortedatum' })}>
                                    {format(new Date(customer.birthdate), `d MMMM y`, dateOpts)}
                                </PrintRow>
                            )}
                            {!isOnRequest && (
                                <>
                                    {addressHasValues(customer?.address) && (
                                        <PrintRow label={formatMessage({ defaultMessage: 'Adres' })}>
                                            <Body>
                                                {customer.address.street} {customer.address.number}
                                                <br />
                                                {customer.address.postalCode} {customer.address.city}
                                            </Body>
                                        </PrintRow>
                                    )}

                                    {customer.birthdate && (
                                        <PrintRow label={formatMessage({ defaultMessage: 'Geboortedatum' })}>
                                            {format(new Date(customer.birthdate), `d MMMM y`, dateOpts)}
                                        </PrintRow>
                                    )}

                                    {customer.address.countryName && (
                                        <PrintRow label={formatMessage({ defaultMessage: 'Land' })}>
                                            {customer.address.countryName}
                                        </PrintRow>
                                    )}
                                    {customer.phoneNumber && (
                                        <PrintRow label={formatMessage({ defaultMessage: 'Telefoon' })}>
                                            {customer.phoneNumber}
                                        </PrintRow>
                                    )}
                                    {customer.email && (
                                        <PrintRow label={formatMessage({ defaultMessage: 'E-mail' })}>
                                            {customer.email}
                                        </PrintRow>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </Print>

                <Flex
                    justifyContent="space-between"
                    alignItems={[null, null, 'center']}
                    flexDirection={['column', null, 'row']}
                >
                    <Body>
                        <FormattedMessage
                            defaultMessage="Aangemaakt op {date}"
                            values={{
                                date: format(createdAt, dateTextFormat, dateOpts),
                            }}
                        />
                    </Body>
                    <div style={{ position: 'sticky', top: '10rem' }}>
                        <ActionMenu
                            title={formatMessage({ defaultMessage: 'Acties toepassen' })}
                            withButtonBorder
                            align="right"
                        >
                            <MenuList>
                                <Item>
                                    <TextButton onClick={handlePrint}>
                                        <FormattedMessage defaultMessage="Printen" />
                                    </TextButton>
                                </Item>
                            </MenuList>
                        </ActionMenu>
                    </div>
                </Flex>
                <FieldHeading title={formatMessage({ defaultMessage: 'Boekingsinformatie' })} />
                {bookingsDetails.map(
                    entry =>
                        entry && (
                            <Content key={entry.label} label={entry.label}>
                                {entry.content}
                            </Content>
                        )
                )}
                <Seperator variant="small" />
                <Content label={formatMessage({ defaultMessage: 'Prijsopbouw' })}>
                    <Box style={{ overflow: 'auto' }}>
                        <PriceTable>{priceLinesContent}</PriceTable>
                    </Box>
                </Content>
                {customer && !hideInfo && (
                    <>
                        <FieldHeading title={formatMessage({ defaultMessage: 'Gegevens boeker' })} />
                        {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                        <Content label="Naam">{`${customer.firstName} ${customer.lastName}`}</Content>
                        {isOnRequest && customer.birthdate && (
                            <Content label={formatMessage({ defaultMessage: 'Geboortedatum' })}>
                                {format(new Date(customer.birthdate), `d MMMM y`, dateOpts)}
                            </Content>
                        )}
                        {!isOnRequest && (
                            <>
                                {addressHasValues(customer?.address) && (
                                    <Content label={formatMessage({ defaultMessage: 'Adres' })}>
                                        <Body>
                                            {customer.address.street} {customer.address.number}
                                        </Body>
                                        <Body>
                                            {customer.address.postalCode} {customer.address.city}
                                        </Body>
                                    </Content>
                                )}
                                {customer.birthdate && (
                                    <Content label={formatMessage({ defaultMessage: 'Geboortedatum' })}>
                                        {format(new Date(customer.birthdate), `d MMMM y`, dateOpts)}
                                    </Content>
                                )}
                                {customer.address.countryName && (
                                    <Content label={formatMessage({ defaultMessage: 'Land' })}>
                                        {customer.address.countryName}
                                    </Content>
                                )}
                                {customer.phoneNumber && (
                                    <Content label={formatMessage({ defaultMessage: 'Telefoon' })}>
                                        <Flex>
                                            <TextButton as="a" href={`tel:${customer.phoneNumber}`}>
                                                {customer.phoneNumber}
                                            </TextButton>
                                        </Flex>
                                    </Content>
                                )}
                                {customer.email && (
                                    <Content label={formatMessage({ defaultMessage: 'E-mail' })}>
                                        <Flex>
                                            <TextButton as="a" href={`mailto:${customer.email}`}>
                                                {customer.email}
                                            </TextButton>
                                        </Flex>
                                    </Content>
                                )}
                            </>
                        )}
                    </>
                )}
            </ContentWrapper>
            {isOnRequest && (
                <FormActionsContainer alwaysVisible>
                    <Actions>
                        <Button
                            variant="danger"
                            onClick={() => {
                                if (
                                    window.confirm(
                                        formatMessage({
                                            defaultMessage:
                                                'Weet je zeker dat je de aanvraag wilt afwijzen? Wanneer je de aanvraag afwijst, wordt deze omgezet in een blokkering.',
                                        })
                                    )
                                ) {
                                    onActionClick(false);
                                }
                            }}
                            submitting={isSubmitting && !accepted}
                            disabled={isSubmitting && !!accepted}
                        >
                            <FormattedMessage defaultMessage="Afwijzen" />
                        </Button>
                        <Flex width="46rem" alignItems="center">
                            <Box mr={4}>
                                <Body variant="tiny">
                                    <FormattedMessage defaultMessage="Deze aanvraag wordt omgezet in een boeking. De boeker ontvangt hiervan direct een bevestiging." />
                                </Body>
                            </Box>
                            <Button
                                onClick={() => onActionClick(true)}
                                submitting={isSubmitting && !!accepted}
                                disabled={isSubmitting && !accepted}
                            >
                                <FormattedMessage defaultMessage="Accepteren" />
                            </Button>
                        </Flex>
                    </Actions>
                </FormActionsContainer>
            )}
        </>
    );
};

export default Details;

const Actions = styled(Flex)`
    width: 100%;
    align-items: center;
    justify-content: space-between;
    > * + * {
        margin-left: ${({ theme }) => theme.spacing['40_Standard']};
    }
`;

const PriceTable = styled(Table)`
    min-width: 60rem;
    tr:first-of-type {
        td {
            padding-top: 0;
        }
    }

    .seperator-row {
        border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[20]};
    }
`;
