import { FieldConfig, useField } from 'formik';
import React, { ComponentType, ReactNode, SVGAttributes } from 'react';
import Select, { components, createFilter, OptionProps, SingleValueProps, StylesConfig } from 'react-select';
import { IndicatorContainerProps } from 'react-select/src/components/containers';
import { IndicatorProps as BaseIndicatorProps } from 'react-select/src/components/indicators';
import { Props as SelectProps } from 'react-select/src/Select';
import { ValueType } from 'react-select/src/types';
import styled from 'styled-components';
import { getAssignmentColor } from '../../constants/theme';
import { useThemeContext } from '../../hooks/useThemeContext';
import Toggle from '../figure/Toggle';
import ErrorMessage from './ErrorMessage';

export interface OptionType {
    label: string;
    value: string;
    icon?: ReactNode;
}

// HACK: real typings do not contain isDisabled despite it existing, so we use our own type.
interface IndicatorProps<T> extends BaseIndicatorProps<T> {
    isDisabled: boolean;
}

const DropdownIndicator = (
    baseProps: BaseIndicatorProps<OptionType> & Required<Pick<SelectInputFieldProps, 'ToggleComponent'>>
) => {
    const theme = useThemeContext();
    const { ToggleComponent, ...props } = baseProps;

    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <ToggleComponent
                    style={{
                        color: (props as IndicatorProps<OptionType>).isDisabled
                            ? theme.colors.neutral['30']
                            : props.isFocused
                            ? theme.colors.primary['40']
                            : theme.colors.primary['100'],
                        maxWidth: '1.6rem',
                    }}
                />
            </components.DropdownIndicator>
        )
    );
};

const IndicatorsContainer = ({
    children,
    hideChoices,
    ...props
}: IndicatorContainerProps<OptionType> & { hideChoices: boolean }) => (
    <components.IndicatorsContainer {...props}>
        {!hideChoices && <ChoicesCount>{props.options.length} keuzes</ChoicesCount>}
        {children}
    </components.IndicatorsContainer>
);

const CustomSingleValue = ({ children, ...props }: SingleValueProps<OptionType>) => (
    <components.SingleValue {...props}>
        {props.data.icon && <SvgContainer>{props.data.icon}</SvgContainer>}
        {children}
    </components.SingleValue>
);

const CustomOption = ({ children, ...props }: OptionProps<OptionType>) => (
    <components.Option {...props}>
        <OptionWrap isDisabled={props.isDisabled}>
            {props.data.icon && <SvgContainer>{props.data.icon}</SvgContainer>}
            {children}
        </OptionWrap>
    </components.Option>
);

interface SelectInputFieldProps extends Omit<SelectProps, 'onChange'> {
    error?: string;
    hideChoices?: boolean;
    variant?: 'small' | 'medium';
    position?: 'absolute' | 'static';
    color?: 'neutral' | 'primary';
    ToggleComponent?: ComponentType<React.PropsWithChildren<SVGAttributes<SVGElement>>>;
    filterFromStart?: boolean;
    thinShadow?: boolean;
    rounded?: boolean;
    dataCy?: string;
    styles?: StylesConfig;
    onChange?: (option: OptionType) => void;
}

const getShadow = (color: string, thin: boolean) => `0 0 0 1px ${color}, inset 0 0 0 ${thin ? 0 : '1px'} ${color}`;

export const SelectInputField = ({
    id,
    hideChoices = false,
    error,
    variant = 'medium',
    position = 'absolute',
    ToggleComponent = Toggle,
    filterFromStart = false,
    thinShadow = false,
    rounded = false,
    dataCy,
    styles: propStyles,
    isSearchable = false,
    onChange,
    color = 'neutral',
    ...props
}: SelectInputFieldProps) => {
    const theme = useThemeContext();

    const filterConfig = createFilter({
        matchFrom: filterFromStart ? 'start' : 'any',
    });

    return (
        <Container data-cy={dataCy}>
            <Select
                onChange={onChange as SelectProps['onChange']}
                inputId={id}
                isSearchable={isSearchable}
                styles={{
                    ...propStyles,
                    placeholder: placeHolderProps => ({ ...placeHolderProps, color: theme.colors.neutral['40'] }),
                    indicatorSeparator: () => ({ display: 'none' }),
                    singleValue: (styles, state) => {
                        const newStyles = {
                            ...styles,
                            color: state.isDisabled ? '#545454' : 'inherit',
                            display: 'flex',
                        };

                        return { ...newStyles, ...propStyles?.singleValue?.(newStyles, state) };
                    },
                    control: (styles, state) => {
                        const newStyles = {
                            ...styles,
                            height: variant === 'small' ? '3.6rem' : '4.8rem',
                            // TODO: Add media query again for the small variant for the partner app. For now not needed for voorkant.
                            // [mediaQuery]: { height: '3.6rem' },
                            borderRadius: rounded ? '2.4rem' : '0.5rem',
                            borderWidth: '2px',
                            backgroundColor: state.isDisabled ? theme.colors.neutral['10'] : theme.colors.neutral['0'],
                            border: 'none',
                            boxShadow: error
                                ? getShadow(theme.colors.negative['60'], thinShadow)
                                : state.isFocused
                                ? getShadow(getAssignmentColor(theme, theme.colorAssignments.input), thinShadow)
                                : getShadow(theme.colors.neutral['20'], thinShadow),
                            padding: '0 0.7rem',
                            paddingRight: '1.5rem',
                            '&:hover': {
                                boxShadow: state.isFocused
                                    ? getShadow(getAssignmentColor(theme, theme.colorAssignments.input), thinShadow)
                                    : getShadow(theme.colors.neutral['30'], thinShadow),
                            },
                        };
                        return { ...newStyles, ...propStyles?.control?.(newStyles, state) };
                    },
                    menu: styles => ({
                        ...styles,
                        zIndex: theme.zIndices.dropdown,
                        position,
                        boxShadow: '0 0 40px 0 rgba(16, 36, 48, 0.1)',
                        borderRadius: '0.5rem',
                        overflow: 'hidden',
                        ...props.styles?.menu,
                    }),
                    container: (styles, { isDisabled }) => ({
                        ...styles,
                        cursor: isDisabled ? 'not-allowed' : 'inherit',
                        ...props.styles?.container,
                    }),
                    option: (styles, { isSelected, isFocused }) => ({
                        ...styles,
                        display: 'flex',
                        alignItems: 'center',
                        color:
                            color === 'primary' && isSelected ? theme.colors.neutral[0] : theme.colors.neutral['100'],
                        backgroundColor: (() => {
                            switch (color) {
                                default:
                                case 'neutral':
                                    return isSelected
                                        ? theme.colors.neutral['20']
                                        : isFocused
                                        ? theme.colors.neutral['10']
                                        : styles.backgroundColor;
                                case 'primary':
                                    return isSelected
                                        ? theme.colors.primary['70']
                                        : isFocused
                                        ? theme.colors.primary['10']
                                        : styles.backgroundColor;
                            }
                        })(),
                        '&:hover': {
                            backgroundColor: () => {
                                switch (color) {
                                    default:
                                    case 'neutral':
                                        return !isSelected ? theme.colors.neutral['10'] : theme.colors.neutral['20'];
                                    case 'primary':
                                        return !isSelected ? theme.colors.primary['10'] : theme.colors.primary['20'];
                                }
                            },
                        },
                        ...props.styles?.option,
                    }),
                }}
                isDisabled={props.disabled}
                components={{
                    SingleValue: CustomSingleValue,
                    Option: CustomOption,
                    DropdownIndicator: rest => <DropdownIndicator ToggleComponent={ToggleComponent} {...rest} />,
                    IndicatorSeparator: rest => <IndicatorsContainer hideChoices={hideChoices} {...rest} />,
                }}
                filterOption={filterConfig}
                {...props}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
        </Container>
    );
};

const SelectInput = ({ name, validate, ...props }: SelectInputFieldProps & Omit<FieldConfig<string>, 'value'>) => {
    const [field, { error, touched }, helpers] = useField({ name, validate });
    return (
        <SelectInputField
            name={name}
            onChange={(selectedOption: ValueType<OptionType>) => {
                // mirror mirror on the wall, which has the shittiest typings of them all...
                helpers.setValue((selectedOption as OptionType).value);
            }}
            onBlur={() => {
                setTimeout(() => helpers.setTouched(true));
            }}
            error={touched && error ? error : undefined}
            value={
                (props.options && (props.options as OptionType[]).find(option => option.value === field.value)) || null
            }
            {...props}
        />
    );
};

export default SelectInput;

const Container = styled.div``;

const ChoicesCount = styled.span`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.neutral['30']};
    letter-spacing: 0;
    line-height: 20px;
    margin: 0.4rem;

    @media (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        display: none;
    }
`;

const OptionWrap = styled.div<{ isDisabled: boolean }>`
    display: flex;
    justify-content: center;
    opacity: ${({ isDisabled }) => (isDisabled ? 0.4 : 1)};
`;

const SvgContainer = styled.span.attrs({ className: 'input-select__icon' })`
    display: flex;
    align-items: center;
    margin: 0 1rem 0 0.3rem;
    > svg {
        flex-shrink: 0;
    }
    width: 3rem !important;
`;
