import { FormikErrors, FormikValues, useFormikContext } from 'formik';
import { useEffect } from 'react';

const getKey = (obj: object | string, errorKey?: string) => {
    let selectorKey = '';

    if (obj !== null && typeof obj === 'object') {
        const [[key, value]] = Object.entries(obj);
        selectorKey = selectorKey.concat(getKey(value, key));
    }

    return errorKey ? `${errorKey}${selectorKey && `.${selectorKey}`}` : selectorKey;
};

interface Props {
    scrollIntoViewOptions?: ScrollIntoViewOptions;
}

/**
 *  ErrorFocus can be used in combination with Formik to focus input elements that have an error state by using its formik field name.
 *  It also supports other elements like divs, but in order for this to work you need to pass a *data-error-focus* prop with
 *  the formik field name as value.
 */
function ErrorFocus({
    scrollIntoViewOptions = {
        block: 'center',
        inline: 'center',
    },
}: Props) {
    const { isSubmitting, isValidating, errors } = useFormikContext<{
        errors: FormikErrors<FormikValues> | undefined;
    }>();

    useEffect(() => {
        const hasErrors = Object.keys(errors).length > 0;

        if (hasErrors && isSubmitting && !isValidating) {
            const key = getKey(errors);
            const selector = `[name="${key}"]`;
            const errorElement: HTMLInputElement | null = document.querySelector(selector);

            if (errorElement) {
                // for some input elements that are hidden like react-select, we'll fallback to scroll their parent into view
                if (errorElement.type === 'hidden') {
                    (errorElement.parentElement as HTMLElement | undefined)?.scrollIntoView(scrollIntoViewOptions);
                } else {
                    errorElement.focus();
                }
            } else {
                // we prefer to focus input elements, but sometimes we need to scroll to another element that contains the error
                const elementId: HTMLElement | null = document.querySelector(`[data-error-focus="${key}"]`);
                elementId?.scrollIntoView(scrollIntoViewOptions);
            }
        }
    }, [isSubmitting, isValidating, errors, scrollIntoViewOptions]);

    return null;
}

export default ErrorFocus;
