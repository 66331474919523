import React, { SVGAttributes } from 'react';

// TODO: move this to ds repo
const Forbidden = (props: SVGAttributes<SVGElement>) => (
    <svg
        fill="currentColor"
        width="20px"
        version="1.0"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M12 4c4.4 0 8 3.6 8 8s-3.6 8-8 8-8-3.6-8-8 3.6-8 8-8m0-2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2z" />
        <path fill="currentColor" stroke="currentColor" strokeMiterlimit="10" strokeWidth="2" d="M18.2 18.2L5.8 5.8" />
    </svg>
);
export default Forbidden;
