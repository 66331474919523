import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import dateTextFormat from '@oberoninternal/travelbase-ds/constants/dateTextFormat';
import { Box } from '@rebass/grid';
import { format } from 'date-fns';
import { debounce } from 'debounce';
import { Formik } from 'formik';
import gql from 'graphql-tag';
import isEqual from 'lodash/isEqual';
import React, { FC, memo, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import { areEqual, ListChildComponentProps } from 'react-window';
import styled from 'styled-components/macro';
import { ArrayParam, useQueryParams, withDefault } from 'use-query-params';
import { getDateOpts } from '../../constants/dateOpts';
import { ReviewFragment, useReviewsQuery } from '../../generated/graphql';
import createTable, { CreateRow } from '../../hooks/useBigList';
import BigListWrapper from '../atoms/BigListWrapper';
import ContentWrapper from '../atoms/ContentWrapper';
import Effect from '../atoms/Effect';
import UnitDetails, { unitDetailsFragment } from '../atoms/UnitDetails';
import BigList from '../molecules/BigList';
import FilterUnitMenu from '../molecules/FilterUnitMenu';

export const query = gql`
    query Reviews($partnerId: ID!, $rentalUnitIds: [ID!]) {
        partner(id: $partnerId) {
            id
            reviews(rentalUnitIds: $rentalUnitIds) {
                ...Review
            }
        }
    }

    fragment Review on Review {
        id
        createdAt
        title
        average
        enabled
        language
        city
        reply
        rentalUnit {
            ...UnitDetails
        }
    }

    ${unitDetailsFragment}
`;

interface ReviewItemProps extends ListChildComponentProps {
    data: {
        createRow: CreateRow;
        reviews: ReviewFragment[];
    };
}

const widths = [[3 / 16, null, 2 / 8], [3 / 16, null, 1 / 8], [1 / 8], [3 / 16, null, 1 / 8], 1 / 8, 1 / 8, 1 / 8];

const ReviewItem: FC<React.PropsWithChildren<ReviewItemProps>> = memo(
    ({ index, style, data: { createRow, reviews } }) => {
        const review = reviews[index];
        const { pathname } = useLocation();
        const { id, city, rentalUnit, createdAt, title, average, reply, enabled } = review;

        return createRow(`${pathname}/${id}`, style, [
            <StyledUnitDetails key={id} unit={{ ...rentalUnit }} />,
            format(new Date(createdAt), dateTextFormat, getDateOpts('nl')),
            city ?? 'Onbekend',
            title,
            average,
            reply ? 'Ja' : 'Nee',
            enabled ? 'Nee' : 'Ja',
        ]);
    },
    areEqual
);

const Reviews: FC<React.PropsWithChildren<unknown>> = () => {
    const { partnerId } = useParams<{ partnerId: string }>();
    const { formatMessage } = useIntl();
    const [paramQuery, setQuery] = useQueryParams({
        rentalUnitIds: withDefault(ArrayParam, []),
    });

    const { data, loading, refetch } = useReviewsQuery({ variables: { partnerId, rentalUnitIds: [] } });
    const reviews = data?.partner?.reviews ?? [];

    const [isSearching, setIsSearching] = useState(false);

    const { createHeader, createRow, createSkeletonRows } = createTable(widths);

    // TODO: use useMemo instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const performSearch = useCallback(
        debounce(async (variables: { rentalUnitIds: string[] }) => {
            await refetch({ partnerId, ...variables });
            setIsSearching(false);

            // reflect the search params in the url. Also set the value to undefined if null, cause that's what the library expects
            setQuery({ rentalUnitIds: variables.rentalUnitIds });
        }, 600),
        [refetch]
    );

    return (
        <Formik
            onSubmit={async values => {
                setIsSearching(true);
                await performSearch(values);
            }}
            initialValues={{ rentalUnitIds: paramQuery.rentalUnitIds as string[] }}
        >
            {formikBag => (
                <ContentWrapper>
                    <Effect<{ rentalUnitIds: string[] }>
                        onChange={({ values: nextValues }, prevState) => {
                            const prevValues = prevState?.values ?? nextValues;
                            if (!isEqual(prevValues, nextValues)) {
                                formikBag.submitForm();
                            }
                        }}
                    />
                    <Pagehead title={formatMessage({ defaultMessage: 'Beoordelingen' })}>
                        <FormattedMessage defaultMessage="Hier kun je de beoordelingen op jouw accommodatie(s) bekijken." />
                    </Pagehead>
                    <BigListWrapper>
                        {createHeader([
                            <FilterUnitMenu key={partnerId} />,

                            <FormattedMessage key={partnerId} defaultMessage="Aangemaakt op" />,
                            <FormattedMessage key={partnerId} defaultMessage="Woonplaats" />,
                            <FormattedMessage key={partnerId} defaultMessage="Beoordelingstitel" />,
                            <FormattedMessage key={partnerId} defaultMessage="Gemiddelde" />,
                            <FormattedMessage key={partnerId} defaultMessage="Beantwoord" />,
                            <FormattedMessage key={partnerId} defaultMessage="Geblokkeerd" />,
                        ])}
                        {!isSearching && !loading ? (
                            reviews.length > 0 ? (
                                <BigList itemData={{ reviews, createRow }} itemSize={78} itemCount={reviews.length}>
                                    {ReviewItem}
                                </BigList>
                            ) : (
                                <Box p={4}>
                                    <FormattedMessage defaultMessage="Geen beoordelingen gevonden." />
                                </Box>
                            )
                        ) : (
                            createSkeletonRows()
                        )}
                    </BigListWrapper>
                </ContentWrapper>
            )}
        </Formik>
    );
};

export default Reviews;

const StyledUnitDetails = styled(UnitDetails)`
    min-width: 100%;

    @media (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        > :first-child {
            display: none;
        }
    }
`;
