import { Flex } from '@rebass/grid';
import React, { useEffect, useState } from 'react';
import Checkbox from '@oberoninternal/travelbase-ds/components/form/Checkbox';
import { SwitchField } from '@oberoninternal/travelbase-ds/components/form/Switch';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { FormattedMessage } from 'react-intl';

interface Props {
    name: string;
    setTouched: (val: boolean) => void;
    handleEditable: (val: string | boolean) => void;
    value: boolean | string;
    disabled?: boolean;
}

const BulkSwitch = ({ name, setTouched, handleEditable, value, disabled }: Props) => {
    const [editable, setEditable] = useState(false);
    useEffect(() => {
        // reset checkbox when the value is initial
        if ((editable && value === '') || disabled) {
            setEditable(false);
        }
    }, [editable, setEditable, value, disabled]);

    return (
        <Flex style={{ marginTop: '0.8rem' }} justifyContent="space-between">
            <Flex>
                <Checkbox
                    id={`bulkCheckbox-${name}`}
                    checked={editable}
                    onChange={({ currentTarget: { checked } }) => {
                        setEditable(checked);
                        handleEditable(checked ? false : '');
                        setTouched(checked);
                    }}
                    disabled={disabled}
                />
                <Body variant="small">
                    <FormattedMessage defaultMessage="Aanpassen" />
                </Body>
            </Flex>
            <Flex>
                <Body variant="small" style={{ margin: '0 0.8rem' }}>
                    <label htmlFor={`bulkCheckbox-${name}`}>
                        <FormattedMessage defaultMessage="Nieuwe waarde:" />
                    </label>
                </Body>
                <SwitchField id={`bulkSwitch-${name}`} name={name} disabled={!editable || disabled} />
            </Flex>
        </Flex>
    );
};

export default BulkSwitch;
