import { Box } from '@rebass/grid';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { getDateOpts } from '../../constants/dateOpts';
import { FlexTypeEnum, PriceColumnPricingFragment, TripTypeEnum, useDayInfoQuery } from '../../generated/graphql';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import SidebarIntro from '../atoms/SidebarIntro';
import PriceTableRow from './PriceTableRow';
import gql from 'graphql-tag';
import simplur from 'simplur';
import SidebarField from '../atoms/SidebarField';
import Skeleton from 'react-loading-skeleton';
import { UnitParams } from '../../entities/UnitParams';
import { useParams } from 'react-router-dom';
import ContentWrapper from '../atoms/ContentWrapper';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import styled from 'styled-components/macro';
import { FormattedMessage, useIntl } from 'react-intl';

export interface DayinfoSidebarData {
    columnData: Pick<PriceColumnPricingFragment, 'id' | 'date'>;
    type: 'DAYINFO_SIDEBAR';
}

export interface Props {
    data: DayinfoSidebarData;
}

export const query = gql`
    query DayInfo($slug: String!, $date: Date!) {
        rentalUnit(slug: $slug) {
            id
            trips(date: $date) {
                flexType
                duration
                rentalSumPartner
                type
                special {
                    name(locale: "nl")
                }
            }
        }
    }
`;

const note =
    'Dit verblijf wordt 1 nacht korter aangeboden zonder prijsverschil t.o.v. de reguliere verblijfsduur. Zo zorgt Travelbase ervoor dat deze accommodatie vaker in de zoekresultaten wordt aangeboden.';

const DayinfoSidebar: FC<React.PropsWithChildren<Props>> = ({
    data: {
        columnData: { date },
    },
}) => {
    const { formatMessage, locale } = useIntl();
    const dateOpts = getDateOpts(locale as 'nl' | 'de' | 'en');
    const { unitSlug: slug } = useParams<UnitParams>();
    const { data, loading } = useDayInfoQuery({
        variables: {
            date,
            slug,
        },
    });

    const showNote = data?.rentalUnit?.trips.some(trip => trip.flexType !== FlexTypeEnum.None);

    return (
        <ContentWrapper variant="sidebar">
            <SidebarIntro title={formatMessage({ defaultMessage: 'Selectie' })}>
                {format(new Date(date), 'EEEE d MMMM yyyy', dateOpts)}
            </SidebarIntro>
            <Box>
                {loading && (
                    <SidebarField label={<Skeleton />}>
                        <Skeleton count={3} />
                    </SidebarField>
                )}
                {!loading && data && (
                    <>
                        <Label>
                            <FormattedMessage defaultMessage="Prijsstelling" />
                        </Label>
                        {data.rentalUnit?.trips.map((trip, i) => (
                            <PriceTableRow
                                title={trip.flexType !== FlexTypeEnum.None ? note : undefined}
                                price={trip.rentalSumPartner}
                                name={`${simplur`${trip.duration} nacht[|en] `}${
                                    trip.flexType !== FlexTypeEnum.None ? '*' : ''
                                }${trip.type === TripTypeEnum.Special ? ` (${trip.special?.name})` : ''}`}
                                key={i}
                            />
                        ))}
                    </>
                )}
            </Box>
            {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
            {showNote && <Note>* {note}</Note>}
        </ContentWrapper>
    );
};

export default DayinfoSidebar;

const Note = styled(Body).attrs(() => ({ variant: 'tiny' }))`
    margin-top: auto !important;
`;
