import { Box, Flex } from '@rebass/grid';
import { FieldArrayRenderProps, getIn } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { Image } from '../../entities/Image';
import SelectInput, { OptionType } from './SelectInput';
import { ImageErrorType, UploadImageField } from './UploadImage';
import { Maybe } from '../../entities/Maybe';
import { ImageFile } from '../../entities/ImageFile';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';

export interface SortableImageProps {
    helpers: FieldArrayRenderProps;
    currentIndex: number;
    image: Image;
    name: string;
    options?: OptionType[] | undefined;
    validateImage?: (imageElement: HTMLImageElement | null) => ImageErrorType[];
    handleUpload: (file: ImageFile) => Promise<string>;
    uploadActionHandler: (uploadId: string, file: ImageFile) => void;
    crossActionHandler?: () => void;
    ratio?: number;
    columns?: number;
    id: string | number;
    showHandle?: boolean;
}
const SortableImage = ({
    helpers,
    currentIndex,
    image,
    name,
    options,
    validateImage,
    handleUpload,
    uploadActionHandler,
    crossActionHandler,
    ratio = 2 / 3,
    columns = 2,
    id,
}: SortableImageProps) => {
    const {
        form: { values, errors, touched },
    } = helpers;
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };
    const fieldName = `${name}[${currentIndex}]`;
    const descriptionFieldName = `${fieldName}.description`;
    const currentValue: Maybe<Image> = getIn(values, fieldName);
    const hasOptions = options && options.length > 0;

    return (
        <Container
            style={style}
            ref={setNodeRef}
            width={[1, null, 1 / columns]}
            px={[null, null, 4]}
            py={4}
            flexDirection="column"
        >
            <Inner>
                <UploadImageField
                    validateImage={validateImage}
                    name={`${fieldName}.file`}
                    handleUpload={handleUpload}
                    uploadActionHandler={uploadActionHandler}
                    crossActionHandler={crossActionHandler}
                    image={image?.file}
                    ratio={ratio}
                    variant="list"
                    handleProps={attributes}
                    listeners={listeners}
                />
                {hasOptions && (
                    <SelectInput
                        isDisabled={!currentValue}
                        name={descriptionFieldName}
                        placeholder="Bijschrift"
                        options={options}
                        error={getIn(touched, `${fieldName}.description`) && getIn(errors, `${fieldName}.description`)}
                    />
                )}
            </Inner>
        </Container>
    );
};

export default SortableImage;

const Inner = styled(Box)`
    > * + * {
        margin-top: 2rem;
    }
`;

const Container = styled(Flex)`
    &.dragging-helper-class {
        ${Inner} > div {
            box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.09);
        }

        .dragButton {
            pointer-events: auto !important;
            cursor: move;
            border-color: ${({ theme }) => theme.colors.neutral['0']};
            background: ${({ theme }) => theme.colors.neutral['0']};
            color: ${({ theme }) => theme.colors.primary['40']};
        }
    }
`;
