import Toast from '@oberoninternal/travelbase-ds/components/feedback/Toast';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import startOfDay from 'date-fns/startOfDay';
import startOfToday from 'date-fns/startOfToday';
import isAfter from 'date-fns/isAfter';
import gql from 'graphql-tag';
import isEqual from 'lodash/isEqual';
import React, { Fragment, memo, useState } from 'react';
import { toast } from 'react-toastify';
import { areEqual, ListChildComponentProps } from 'react-window';
import styled, { css } from 'styled-components/macro';
import dateFormat from '../../constants/dateFormat';
import epoch from '../../constants/epoch';
import euroFormat from '../../constants/euroFormat';
import { PRICE_COLUMN_ROW_COUNT } from '../../constants/priceRows';
import {
    EditSpecialParticipationDateBlockInput,
    SpecialParticipationFragment,
    SpecialParticipationsDocument,
    useEditSpecialParticipationDateBlockMutation,
} from '../../generated/graphql';
import groupTripsByDuration from '../../utils/groupTripsByDuration';
import parseDate from '@oberoninternal/travelbase-ds/utils/parseDate';
import SwitchCell from '../atoms/SwitchCell';
import { PricingColumnData } from '../organisms/Pricing';
import Cell from './Cell';
import PricingColumnAllotment from './PricingColumnAllotment';
import PricingColumnContainer from './PricingColumnContainer';
import SkeletonCell from './SkeletonCell';
import { useVirtualizerScrollData } from '../../context/virtualizerScrollData';
import { FormattedMessage, useIntl } from 'react-intl';

export const fragment = gql`
    fragment SpecialParticipation on SpecialParticipation {
        id
        blockedArrivalDates
        optInAcceptedAt
        optInRejectedAt
        special {
            id
            arrivalFrom
            arrivalUntil
            description
            name
            optInRequired
            partnerConditions
        }

        trips(startDate: $start, endDate: $end) {
            date
            duration
            rentalSumPartner
        }
    }
`;

export const mutation = gql`
    mutation EditSpecialParticipationDateBlock(
        $input: EditSpecialParticipationDateBlockInput!
        $start: Date!
        $end: Date!
    ) {
        editSpecialParticipationDateBlock(input: $input) {
            specialParticipation {
                ...SpecialParticipation
            }
        }
    }
`;

export type SpecialTripGroups = Map<string, SpecialParticipationFragment['trips']>;

interface SpecialData extends PricingColumnData {
    specialParticipations: SpecialParticipationFragment[];
    optingForId: string | null;
}

interface Props extends ListChildComponentProps {
    data: SpecialData;
}

const isLoading = (loadedPeriods: Interval[], date: Date): boolean =>
    !loadedPeriods.some(interval => isWithinInterval(date, interval));

const SpecialsColumn = memo(
    ({
        data: {
            maxAllotment,
            specialParticipations,
            loadedPeriods,
            allotments,
            lockoutCreationProps,
            initialVars: variables,
            optingForId,
            getDayEvents,
        },
        style,
        index,
    }: Props) => {
        const { formatMessage } = useIntl();
        const date = addDays(epoch, index);
        const scrollData = useVirtualizerScrollData();
        const hasData = specialParticipations.length > 0;
        const loading = isLoading(loadedPeriods, date);
        const isUnbookable = isBefore(date, startOfDay(new Date()));
        const allotment = allotments.find(allot => isSameDay(new Date(allot.date), date));
        const isMultiple = maxAllotment > 1;
        const [mutate, { loading: isEditing }] = useEditSpecialParticipationDateBlockMutation();
        const [input, setInput] = useState<EditSpecialParticipationDateBlockInput | null>(null);
        const forbidden =
            maxAllotment === 1 && allotment?.amount === 0 && isAfter(date, new Date()) && !getDayEvents(date).length;
        return (
            <PricingColumnContainer style={style} isUnusable={isUnbookable || !hasData}>
                <PricingColumnAllotment
                    document={SpecialParticipationsDocument}
                    variables={variables}
                    allotment={allotment}
                    maxAllotment={maxAllotment}
                    loading={loading}
                    disabled={false}
                    unbookable={isUnbookable}
                    date={date}
                    style={{ cursor: isMultiple ? 'pointer' : 'e-resize' }}
                    data-date={date.toISOString()}
                    forbidden={forbidden}
                    {...lockoutCreationProps}
                />
                {!loading &&
                    specialParticipations?.map(specialParticipation => {
                        const {
                            id,
                            blockedArrivalDates,
                            special: { arrivalFrom, arrivalUntil, optInRequired },
                            trips: specialTrips,
                            optInAcceptedAt,
                            optInRejectedAt,
                        } = specialParticipation;

                        const accepted = optInAcceptedAt ? true : optInRejectedAt ? false : null;

                        const arrivalAllowed = isWithinInterval(date, {
                            start: parseDate(arrivalFrom),
                            end: parseDate(arrivalUntil),
                        });

                        const blockedValue = !!blockedArrivalDates.find(blockedDate =>
                            isSameDay(new Date(blockedDate), date)
                        );
                        const specialTripGroups = [...groupTripsByDuration(specialTrips).entries()];

                        const showSwitch = arrivalAllowed && (accepted || optingForId === id);

                        const disabled =
                            isBefore(date, startOfToday()) ||
                            !optInRequired ||
                            !!optingForId || // when opting-in/out prevent errors by disabling the switchcells
                            !accepted ||
                            (isEditing && input?.specialParticipationId === id); // also preventing errors;

                        return (
                            <Fragment key={id}>
                                {showSwitch ? (
                                    <SwitchCell
                                        hasSeparator
                                        disabled={disabled}
                                        onClick={async () => {
                                            const newInput = {
                                                blocked: !blockedValue,
                                                date: format(date, dateFormat),
                                                specialParticipationId: id,
                                            };
                                            if (!scrollData?.itemsRendered.current) {
                                                return;
                                            }

                                            try {
                                                setInput(newInput);
                                                const startDate = addDays(
                                                    epoch,
                                                    scrollData.itemsRendered.current.visibleStartIndex
                                                );
                                                const endDate = addDays(
                                                    epoch,
                                                    scrollData.itemsRendered.current.visibleStopIndex
                                                );

                                                await mutate({
                                                    variables: {
                                                        start: format(startDate, dateFormat),
                                                        end: format(endDate, dateFormat),
                                                        input: newInput,
                                                    },
                                                    optimisticResponse: {
                                                        editSpecialParticipationDateBlock: {
                                                            __typename: 'EditSpecialParticipationDateBlockPayload',
                                                            specialParticipation: {
                                                                __typename: 'SpecialParticipation',
                                                                ...specialParticipation,
                                                                blockedArrivalDates: newInput.blocked
                                                                    ? [...blockedArrivalDates, newInput.date]
                                                                    : blockedArrivalDates.filter(
                                                                          blockedDate =>
                                                                              !isSameDay(date, parseDate(blockedDate))
                                                                      ),
                                                            },
                                                        },
                                                    },
                                                });

                                                toast(
                                                    !toast.isActive('editSpecialParticipationSucces') ? (
                                                        <Toast
                                                            variant="success"
                                                            title={formatMessage({ defaultMessage: 'Opgeslagen' })}
                                                        >
                                                            <Body variant="small">
                                                                <FormattedMessage defaultMessage="De aanpassing is verwerkt" />
                                                            </Body>
                                                        </Toast>
                                                    ) : null,
                                                    {
                                                        autoClose: 3000,
                                                        toastId: 'editSpecialParticipationSucces',
                                                    }
                                                );
                                            } catch (error) {
                                                // eslint-disable-next-line no-console
                                                console.error(error);
                                            }
                                        }}
                                        switchId={`switch-${id}-${-date})}`}
                                        value={!blockedValue}
                                        skipPreventingDefault
                                    />
                                ) : (
                                    <StyledCell hasSeparator />
                                )}
                                {optingForId === id && arrivalAllowed
                                    ? new Array(
                                          specialTripGroups.length > 0
                                              ? specialTripGroups.length
                                              : PRICE_COLUMN_ROW_COUNT
                                      )
                                          .fill(SkeletonCell)
                                          .map((E, i) => <E key={i} i={i} />)
                                    : specialTripGroups.map(([key, trips]) => {
                                          const trip = trips.find(current => isSameDay(parseDate(current.date), date));

                                          if (isEditing && input?.specialParticipationId === id) {
                                              return <SkeletonCell key={key} />;
                                          }

                                          if ((arrivalAllowed && !trip) || !accepted) {
                                              return (
                                                  <StyledCell key={key} disabled>
                                                      {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                                                      <Price>--,--</Price>
                                                  </StyledCell>
                                              );
                                          }

                                          if (!trip) {
                                              return <StyledCell key={key} disabled />;
                                          }

                                          return (
                                              <StyledCell key={key} disabled={blockedValue}>
                                                  <Price blocked={blockedValue}>
                                                      {euroFormat(trip.rentalSumPartner)}
                                                  </Price>
                                              </StyledCell>
                                          );
                                      })}
                            </Fragment>
                        );
                    })}
                {loading && new Array(PRICE_COLUMN_ROW_COUNT).fill(SkeletonCell).map((E, i) => <E key={i} i={i} />)}
            </PricingColumnContainer>
        );
    },
    ({ data: prevData, ...prev }, { data: nextData, ...next }) => {
        const date = addDays(epoch, prev.index);

        if (!isEqual(prevData.loadedPeriods, nextData.loadedPeriods)) {
            return false;
        }

        // only rerender if relevant data changes deeply
        if (
            !isEqual(
                prevData.allotments.find(allot => isSameDay(new Date(allot.date), date)),
                nextData.allotments.find(allot => isSameDay(new Date(allot.date), date))
            )
        ) {
            return false;
        }

        if (!isEqual(prevData.specialParticipations, nextData.specialParticipations)) {
            return false;
        }

        // shallow compare everything else
        return areEqual({ ...prev, optingForId: prevData.optingForId }, { ...next, optingForId: nextData.optingForId });
    }
);

export default SpecialsColumn;

const StyledCell = styled(Cell)<{ disabled?: boolean }>`
    ${({ disabled = false }) =>
        disabled &&
        css`
            background: ${({ theme }) => theme.colors.neutral[10]};
            color: ${({ theme }) => theme.colors.neutral[30]};
        `};
`;

const Price = styled.span<{ blocked?: boolean }>`
    position: relative;
    ${({ blocked = false }) =>
        blocked &&
        css`
            ::before {
                position: absolute;
                content: '';
                left: 0;
                top: 50%;
                right: 0;
                border-top: 2px solid currentColor;
                transform: rotate(-15deg);
            }
        `};
    font-weight: 500;
`;
