import { Image } from '../entities/Image';
import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';

const upsertImage = (image: Maybe<Image>) => {
    if (image?.uploadId) {
        // we'll upload a new image
        return image.uploadId;
    }

    if (image?.imageId) {
        // we retain the same image
        return undefined;
    }
    // if null, we remove the image
    return null;
};

export default upsertImage;
