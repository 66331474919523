// This variable is used for objects and shouldn't be used to iterate over days in order to display weekdays.
// If you want to achieve this, please use the useWeekdays hook.
export const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] as const;

export type Weekday = typeof weekdays[number];

export default <V extends unknown>(value: V): Record<Weekday, V> => ({
    ...Object.assign(
        {},
        ...weekdays.map(day => ({
            [day]: value,
        }))
    ),
});
