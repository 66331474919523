import differenceInDays from 'date-fns/differenceInDays';
import endOfDay from 'date-fns/endOfDay';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import startOfDay from 'date-fns/startOfDay';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { MenuStateProps } from '../../../hooks/useMenuState';
import { RangePickerInputProps } from '../DatePickerInput';
import DayPicker, { Common, commonModifiers, modifiersStyles } from './DayPicker';
import { nl, enGB, de } from 'date-fns/locale';
import { DayClickEventHandler } from 'react-day-picker';

const locales = {
    nl,
    de,
    enGB,
};

type RangePickerProps = RangePickerInputProps &
    Common &
    Pick<MenuStateProps, 'setOpen' | 'open'> & {
        locale?: keyof typeof locales;
    };

const DateRangePicker: FC<React.PropsWithChildren<RangePickerProps>> = props => {
    const {
        value,
        setValue,
        isFocus,
        setIsFocus,
        setOpen,
        optionalEndDate: endDateOptional = false,
        disableInput,
        open,
        enabledPast,
        locale,
    } = props;
    const localeToUse = locale ? locales[locale] : nl;
    const from = value.from as Date;
    const to = value.to as Date;
    const dayClicks = useRef<{ day: Date; clicks: number } | null>(null);
    const onClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const disabledDays = props.enabledPast ? undefined : [{ before: new Date() }];

    const openRef = useRef(open);

    useEffect(() => {
        // is the datepicker closing?
        if (openRef.current && !open) {
            dayClicks.current = null;
        }
        openRef.current = open;
    }, [onClose, open]);

    const onDayClick: DayClickEventHandler = useCallback(
        (day, dayModifiers) => {
            if (dayModifiers.disabled || dayModifiers.outside) {
                return;
            }

            if (disableInput) {
                // for now only from is supported
                if (disableInput.from) {
                    setValue({ ...value, to: day });
                    return;
                }
                if (disableInput.from && disableInput.to) {
                    return;
                }
            }

            // keep a ref on dayclicks to reset the range when a double click occurs
            dayClicks.current =
                dayClicks.current && isSameDay(dayClicks.current.day, day)
                    ? { ...dayClicks.current, clicks: (dayClicks.current.clicks += 1) }
                    : { day, clicks: 1 };

            if (dayClicks.current && dayClicks.current.clicks === 2) {
                dayClicks.current = null;
                setValue({ from: startOfDay(day), to: endDateOptional ? null : endOfDay(day) });
                onClose();
                return;
            }

            if (isFocus && setIsFocus) {
                if (isFocus === 'from') {
                    // check if the picked day is after the current to, if so swap them
                    if (value.to && isAfter(day, to)) {
                        setValue({ from: day, to: day });
                    } else {
                        setValue({ ...value, from: day });
                    }
                    setIsFocus('to');
                }
                if (isFocus === 'to') {
                    // check if the picked day is before the current from, if so swap them
                    if (value.from && isBefore(day, from)) {
                        setValue({ from: day, to: day });
                    } else {
                        setValue({ ...value, to: day });
                        onClose();
                    }
                    setIsFocus(null);
                }
                return;
            }

            if (from && to && isWithinInterval(day, { start: from, end: to })) {
                // Correctly modify the range according to the difference between the day and to/from
                const fromToDay = differenceInDays(from, day);
                const dayToTo = differenceInDays(day, to);
                if (fromToDay > dayToTo) {
                    setValue({ ...value, from: day });
                } else {
                    setValue({ ...value, to: day });
                }
                onClose();
                return;
            }
            onClose();
        },
        [disableInput, endDateOptional, from, isFocus, onClose, setIsFocus, setValue, to, value]
    );
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    const [selectedMonth, setSelectedMonth] = useState((isFocus ? value[isFocus] || value.from : value.from) as Date);
    useEffect(() => {
        setSelectedMonth(from);
    }, [from]);

    // as disabled days aren't interactive it doesn't really make sense to open
    // the picker on this month, so we adjust it to the current date;
    if (selectedMonth && isBefore(selectedMonth, new Date()) && !enabledPast) {
        // setSelectedMonth(new Date());
    }

    return (
        <>
            <DayPicker
                onMonthChange={month => {
                    setSelectedMonth(month);
                }}
                locale={localeToUse}
                month={selectedMonth ?? new Date()}
                className="Selectable"
                disabled={disabledDays}
                modifiersStyles={modifiersStyles}
                onDayClick={onDayClick}
                weekStartsOn={1}
                selected={from && to ? [from, { from, to }] : undefined}
                modifiers={{
                    ...commonModifiers,
                    start: from ?? undefined,
                    end: to ?? undefined,
                    range_middle: { from, to },
                }}
            />
        </>
    );
};

export default DateRangePicker;
