import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Content from '@oberoninternal/travelbase-ds/components/section/Content';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NotFoundError } from '../../../entities/NotFoundError';
import { PartnerParams } from '../../../entities/PartnerParams';
import { usePartnerProfileQuery } from '../../../generated/graphql';
import ContentWrapper from '../../atoms/ContentWrapper';
import FieldHeading from '../../molecules/FieldHeading';
import Loading from '../../organisms/Loading';

export const query = gql`
    query PartnerProfile($id: ID!) {
        partner(id: $id) {
            id
            name
            address {
                ...Address
            }

            invoiceAddress {
                ...Address
            }
            invoiceEmails
            bankAccountActive
            vatNumber
            contact {
                contactName
                phoneNumbers
                emails
            }
            contactSalutation
            ibanName
            iban
            bic

            paymentByDirectDebit
        }
    }

    fragment Address on Address {
        city
        countryName
        number
        postalCode
        street
        countryCode
    }
`;

const ProfileDetails: FC<React.PropsWithChildren<PartnerParams>> = ({ partnerId }) => {
    const { data, loading } = usePartnerProfileQuery({ variables: { id: partnerId } });
    const { formatMessage } = useIntl();
    if (loading) {
        return <Loading variant="info" />;
    }

    if (!data || !data.partner) {
        throw new NotFoundError();
    }
    const {
        address,
        invoiceAddress,
        contact: { contactName, emails, phoneNumbers },
        name,
        contactSalutation,
        ibanName,
        iban,
        bic,
        bankAccountActive,
        paymentByDirectDebit,
        invoiceEmails,
        vatNumber,
    } = data.partner;
    return (
        <ContentWrapper variant="info">
            <Pagehead title={formatMessage({ defaultMessage: 'Jouw gegevens' })}>
                <FormattedMessage
                    defaultMessage={`Met onderstaande partnergegevens ben je bij ons bekend. Laat ons weten als hierin zaken gewijzigd moeten
                worden.`}
                />
            </Pagehead>
            <FieldHeading title={formatMessage({ defaultMessage: 'Algemeen' })} />
            <Content label={formatMessage({ defaultMessage: 'Naam' })}>{name}</Content>

            {address && (
                <Content label={formatMessage({ defaultMessage: 'Adres' })}>
                    <Body>
                        {address.street} {address.number}
                    </Body>
                    <Body>
                        {address.postalCode} {address.city}
                    </Body>
                    <Body>{address.countryName}</Body>
                </Content>
            )}

            <FieldHeading title={formatMessage({ defaultMessage: 'Contactinformatie' })} />
            {contactSalutation && (
                <Content label={formatMessage({ defaultMessage: 'Aanhef' })}>
                    <Body>{contactSalutation}</Body>
                </Content>
            )}
            {contactName && (
                <Content label={formatMessage({ defaultMessage: 'Naam' })}>
                    <Body>{contactName}</Body>
                </Content>
            )}
            {phoneNumbers.length > 0 && (
                <Content label={formatMessage({ defaultMessage: 'Telefoonnummer' })}>
                    {phoneNumbers.map((number, i) => (
                        <Body key={i}>{number}</Body>
                    ))}
                </Content>
            )}
            {emails.length > 0 && (
                <Content label={formatMessage({ defaultMessage: 'E-mailadres(sen)' })}>
                    {emails.map((email, i) => (
                        <Body key={i}>{email}</Body>
                    ))}
                </Content>
            )}
            <FieldHeading title={formatMessage({ defaultMessage: 'Financiële informatie' })} />
            {iban && (
                <Content label={formatMessage({ defaultMessage: 'IBAN' })}>
                    <Body>{iban}</Body>
                </Content>
            )}
            {ibanName && (
                <Content label={formatMessage({ defaultMessage: 'Tenaamstelling rekening' })}>
                    <Body>{ibanName}</Body>
                </Content>
            )}
            {bic && (
                <Content label={formatMessage({ defaultMessage: 'BIC' })}>
                    <Body>{bic}</Body>
                </Content>
            )}
            <Content label={formatMessage({ defaultMessage: 'Bankrekening actief' })}>
                <YesNo value={bankAccountActive} />
            </Content>
            <Content label={formatMessage({ defaultMessage: 'Betalen per incasso' })}>
                <YesNo value={paymentByDirectDebit} />
            </Content>

            {invoiceAddress && (
                <Content label={formatMessage({ defaultMessage: 'Factuuradres' })}>
                    <Body>
                        {invoiceAddress.street} {invoiceAddress.number}
                    </Body>
                    <Body>
                        {invoiceAddress.postalCode} {invoiceAddress.city}
                    </Body>
                    <Body>{invoiceAddress.countryName}</Body>
                </Content>
            )}
            {invoiceEmails.length > 0 && (
                <Content label={formatMessage({ defaultMessage: 'Factuur e-mailadres(sen)' })}>
                    {invoiceEmails.map((email, i) => (
                        <Body key={i}>{email}</Body>
                    ))}
                </Content>
            )}
            {vatNumber && (
                <Content label={formatMessage({ defaultMessage: 'BTW-nummer' })}>
                    <Body>{vatNumber}</Body>
                </Content>
            )}
        </ContentWrapper>
    );
};

export default ProfileDetails;

// this is probably the most beautiful component one ever wrote
const YesNo = ({ value }: { value: boolean }) => (
    <Body>{value ? <FormattedMessage defaultMessage="Ja" /> : <FormattedMessage defaultMessage="Nee" />}</Body>
);
