import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import endOfWeek from 'date-fns/endOfWeek';
import startOfWeek from 'date-fns/startOfWeek';
import { useMemo } from 'react';
import { getDateOpts } from '../constants/dateOpts';

const dateOpts = getDateOpts('nl');

const useWeekdays = (options?: Parameters<typeof startOfWeek>[1]) =>
    useMemo(
        () =>
            eachDayOfInterval({
                start: startOfWeek(new Date(), options ?? dateOpts),
                end: endOfWeek(new Date(), options ?? dateOpts),
            }),
        [options]
    );

export default useWeekdays;
