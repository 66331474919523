import { Flex, FlexProps } from '@rebass/grid';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import euroFormat from '../../constants/euroFormat';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { Seperator } from '../atoms/Seperator';

type Variant = 'default' | 'total';

interface TableRowProps {
    price: number;
    name: string;
    variant?: Variant;
    title?: string;
}
const PriceTableRow: FC<React.PropsWithChildren<TableRowProps>> = ({ price, name, variant = 'default', title }) => (
    <>
        {variant === 'total' && <StyledSeperator />}
        <Container title={title}>
            <Body>{name}</Body>
            <Body style={{ fontWeight: 500 }}>{euroFormat(price)}</Body>
        </Container>
    </>
);

export default PriceTableRow;

const Container = styled(Flex).attrs(() => ({ my: 3, width: 1, justifyContent: 'space-between' } as FlexProps))``;

const StyledSeperator = styled(Seperator)`
    margin: 2rem 0;
`;
