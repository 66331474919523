import { Box, BoxProps } from '@rebass/grid';
import React, { FC, memo } from 'react';
import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import Body from '../primitive/Body';
import { Theme } from '../../constants/theme';
import { AirbnbIcon, BookingIcon, OtherIcon, NatuurhuisjesIcon } from './SourceIcons';
import Lock from '../figure/Lock';

export type AllotmentPart = 'start' | 'single' | 'between' | 'end';
export type AllotmentVariant = 'booking' | 'lockout';
export const PLACEHOLDER_ID = 'placeholder';

export const SOURCES = {
    BOOKING: 'booking',
    AIRBNB: 'airbnb',
    NATUURHUISJES: 'natuurhuisjes',
    OTHER: 'other',
};

export interface AllotmentProps extends Omit<BoxProps, 'start'> {
    id: string;
    part: AllotmentPart;
    variant: AllotmentVariant;

    title: string;
    subtitle?: string;

    selected?: boolean;
    hovering?: boolean;
    inactive?: boolean;
    sticky?: boolean;

    source?: string;
}

const Allotment: FC<React.PropsWithChildren<AllotmentProps>> = memo(
    ({ title, subtitle, variant, source, part, ref, ...props }) => {
        const shouldRenderText = part === 'start' || part === 'single';

        return (
            <Container part={part} variant={variant} source={source} {...props}>
                {shouldRenderText && (
                    <Children selected={props.selected} sticky={props.sticky}>
                        {variant !== 'booking' ? (
                            source === SOURCES.OTHER ? (
                                <OtherIcon />
                            ) : source === SOURCES.BOOKING ? (
                                <BookingIcon />
                            ) : source === SOURCES.AIRBNB ? (
                                <AirbnbIcon />
                            ) : source === SOURCES.NATUURHUISJES ? (
                                <NatuurhuisjesIcon />
                            ) : (
                                <Lock />
                            )
                        ) : null}
                        <Title>{title}</Title>
                        {subtitle && <Body variant="small">{subtitle}</Body>}
                    </Children>
                )}
            </Container>
        );
    }
);

const getBackground = (theme: Theme, variant: AllotmentVariant, state?: 'hovering' | 'active' | 'selected') => {
    switch (state) {
        case 'hovering':
        case 'active':
            switch (variant) {
                case 'lockout':
                    return theme.colors.neutral['30'];
                default:
                    return theme.colors.primary['50'];
            }
        default:
            switch (variant) {
                case 'lockout':
                    return theme.colors.neutral['20'];
                default:
                    return theme.colors.primary['40'];
            }
    }
};

const getRadius = (part: AllotmentPart): string => {
    switch (part) {
        case 'start':
            return '20px 0 0 20px';
        case 'between':
            return '0';
        case 'end':
            return '0 20px 20px 0';
        default:
            return '20px';
    }
};

const Title = styled.h2`
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 20px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Children = styled.div<Partial<AllotmentProps>>`
    position: ${({ sticky }) => (sticky ? 'sticky' : 'relative')};
    left: 0;
    padding: 0 ${({ selected }) => (selected ? '1.4rem' : '1.6rem')};
    display: flex;
    align-items: center;
    white-space: nowrap;
    max-width: 100%;

    > * + * {
        margin-left: 0.5rem;
    }
`;

const Container = styled(Box)<Omit<AllotmentProps, 'title' | 'subtitle'>>`
    --background: ${({ theme, variant, selected, hovering }) =>
        getBackground(theme, variant, hovering ? 'hovering' : selected ? 'selected' : undefined)};

    border-color: ${({ theme, variant }) =>
        variant === 'booking' ? theme.colors.primary['50'] : theme.colors.neutral['30']};
    border-style: solid;
    color: ${({ theme, variant }) => (variant === 'booking' ? theme.colors.neutral['0'] : theme.colors.neutral['80'])};
    background: var(--background);
    border-radius: ${({ part }) => getRadius(part)};
    border-width: ${({ selected }) => (selected ? '2px' : 0)};
    display: flex;
    height: 4rem;
    z-index: 1;
    position: relative;
    overflow: ${({ sticky }) => (!sticky ? 'hidden' : 'unset')};
    cursor: pointer;
    opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
    pointer-events: ${({ id }) => (id === PLACEHOLDER_ID ? 'none' : 'unset')};
    transition: width 0.25s, border-radius 0.25s;
    ${({ part }) => (part === 'start' || part === 'between' ? 'border-right: 0 !important;' : '')}
    ${({ part }) => (part === 'end' || part === 'between' ? 'border-left: 0 !important; padding: 0;' : '')}

    ${({ source }) =>
        source &&
        css`
            overflow: hidden;
            :before {
                content: '';
                bottom: 0;
                left: 0;
                height: 3px;
                position: absolute;
                width: 100%;
                background-color: ${() => {
                    switch (source) {
                        case SOURCES.BOOKING:
                            return '#003b95';
                        case SOURCES.AIRBNB:
                            return '#E00B41';
                        case SOURCES.NATUURHUISJES:
                            return '#5a125b';
                        case SOURCES.OTHER:
                            return '#060e1c';
                        default:
                            return 'transparent';
                    }
                }};
            }
        `}

    ${({ sticky, variant, theme, selected, hovering }) =>
        !sticky &&
        css`
            :after {
                content: '';
                background-image: linear-gradient(
                    270deg,
                    var(--background) 22%,
                    ${transparentize(
                            1,
                            getBackground(theme, variant, hovering ? 'hovering' : selected ? 'selected' : undefined)
                        )}
                        100%
                );

                width: 48px;
                top: 0;
                right: 0;
                bottom: 0;
                position: absolute;
            }
        `}

    svg {
        min-width: ${({ source }) => (source ? 'auto' : '12px')};
        top: -2px;
        position: relative;
    }
`;

export default Allotment;
