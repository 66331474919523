import React, { SVGAttributes } from 'react';
import { useThemeContext } from '../../hooks/useThemeContext';

export function Declined(props: SVGAttributes<SVGElement>) {
    const theme = useThemeContext();
    return (
        <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g fill="none" fillRule="evenodd">
                <circle fill={theme.colors.negative['60']} cx="8" cy="8" r="8" />
                <g stroke={theme.colors.neutral['0']} strokeWidth="2">
                    <path d="M5.083 5.083l5.834 5.834M10.917 5.083l-5.834 5.834 5.834-5.834z" />
                </g>
            </g>
        </svg>
    );
}
