import { Box } from '@rebass/grid';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { NotFoundError } from '../../../../entities/NotFoundError';
import { UnitParams } from '../../../../entities/UnitParams';
import {
    AvailableSurchargeFragment,
    RentalUnitSurchargeFragment,
    RentalUnitSurchargesDocument,
    RentalUnitSurchargesQuery,
    Scalars,
    Surcharge,
    SurchargeCalculationEnum,
    useContractsQuery,
    useDeleteRentalUnitSurchargeMutation,
    usePartnerDataQuery,
    useRentalUnitSurchargesQuery,
} from '../../../../generated/graphql';
import useMenuState from '@oberoninternal/travelbase-ds/hooks/useMenuState';
import ContentWrapper from '../../../atoms/ContentWrapper';
import FieldSet from '../../../atoms/FieldSet';
import FieldSetHint from '../../../atoms/FieldSetHint';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import { Seperator } from '../../../atoms/Seperator';
import Plus from '@oberoninternal/travelbase-ds/components/figure/Plus';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import FieldHeading from '../../../molecules/FieldHeading';
import LabelBox from '../../../molecules/LabelBox';
import SurchargeSidebar, { CreateSurcharge, SurchargeType } from '../../../molecules/SurchargeSidebar';
import SurchargeTable from '../../../molecules/SurchargeTable';
import Loading from '../../../organisms/Loading';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import BulkSurchargeSidebar from './BulkSurchargeSidebar';
import useSesame from '@oberoninternal/travelbase-ds/hooks/useSesame';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

export const availableSurchargeFragment = gql`
    fragment AvailableSurcharge on Surcharge {
        id
        allowedCalculations
        description
        nameNL: name(locale: "nl")
        nameEN: name(locale: "en")
        nameDE: name(locale: "de")
        canBePaidThroughTor
    }
`;

export const surchargeFragment = gql`
    fragment RentalUnitSurcharge on RentalUnitSurcharge {
        id
        calculation
        endDate
        maxAmount
        minAmount
        startDate
        unitPrice
        handlePayment
        surcharge {
            ...AvailableSurcharge
        }
    }
    ${availableSurchargeFragment}
`;

export const surchargesQuery = gql`
    query RentalUnitSurcharges($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            id
            accommodation {
                rentalUnits {
                    ...RentalUnitInfo
                }
            }
            availableSurcharges {
                ...AvailableSurcharge
            }
            surcharges {
                ...RentalUnitSurcharge
            }
        }
    }

    ${surchargeFragment}
    ${availableSurchargeFragment}
`;

export const surchargesMutation = gql`
    mutation CreateRentalUnitSurcharge($input: CreateRentalUnitSurchargeInput!) {
        createRentalUnitSurcharge(input: $input) {
            rentalUnitSurcharge {
                ...RentalUnitSurcharge
            }
        }
    }

    mutation EditRentalUnitSurcharge($input: EditRentalUnitSurchargeInput!) {
        editRentalUnitSurcharge(input: $input) {
            rentalUnitSurcharge {
                ...RentalUnitSurcharge
            }
        }
    }

    mutation DeleteRentalUnitSurcharge($input: DeleteRentalUnitSurchargeInput!) {
        deleteRentalUnitSurcharge(input: $input) {
            id
        }
    }

    ${surchargeFragment}
`;

type SurchargeNameType = {
    nameNL: string;
    nameDE: string;
    nameEN: string;
};

export const getSurchargeCalculationTexts = (intl: IntlShape): Record<SurchargeCalculationEnum, string> => ({
    PER_PIECE: intl.formatMessage({ defaultMessage: 'eenmalig' }),
    PER_PIECE_PER_NIGHT: intl.formatMessage({ defaultMessage: 'per nacht' }),
    PER_PET: intl.formatMessage({ defaultMessage: 'per huisdier' }),
    PER_PET_PER_NIGHT: intl.formatMessage({ defaultMessage: 'per huisdier per nacht' }),
    PER_PERSON: intl.formatMessage({ defaultMessage: 'per persoon' }),
    PER_PERSON_PER_NIGHT: intl.formatMessage({ defaultMessage: 'per persoon per nacht' }),
    SUBSEQUENT: intl.formatMessage({ defaultMessage: 'Nacalculatie' }),
});

export const getOptionCalculationTexts = (intl: IntlShape): Record<SurchargeCalculationEnum, string> => ({
    ...getSurchargeCalculationTexts(intl),
    PER_PIECE: intl.formatMessage({ defaultMessage: 'per stuk' }),
    PER_PIECE_PER_NIGHT: intl.formatMessage({ defaultMessage: 'per nacht' }),
});

const Surcharges: FC<React.PropsWithChildren<UnitParams>> = ({ unitSlug, partnerId }) => {
    const { data, loading } = useRentalUnitSurchargesQuery({ variables: { unitSlug } });
    const intl = useIntl();
    const locale = intl.locale as 'nl' | 'en' | 'de';
    const { formatMessage } = intl;
    const { data: partnerData } = usePartnerDataQuery({ variables: { partnerId } });
    const allRentalUnits = partnerData?.viewer?.isBackofficeUser
        ? data?.rentalUnit?.accommodation.rentalUnits
        : partnerData?.viewer?.partners.flatMap(partner =>
              partner?.accommodations.flatMap(accommodation => accommodation?.rentalUnits)
          );
    const [remove] = useDeleteRentalUnitSurchargeMutation();
    const { setOpen, menuProps } = useMenuState(false, { closeClickOnOutside: false });
    const sesame = useSesame();
    const [editableSurcharge, setEditableSurcharge] = useState<RentalUnitSurchargeFragment | CreateSurcharge | null>(
        null
    );
    const { data: contractData, loading: contractLoading } = useContractsQuery({ variables: { unitSlug } });

    const handleEdit = (surcharge: RentalUnitSurchargeFragment) => {
        setEditableSurcharge(surcharge);
        setOpen(true);
    };

    const handleCreate = (availableSurcharge: Surcharge, type?: SurchargeType, hideTypeSelect = false) => {
        setEditableSurcharge({ type, hideTypeSelect, ...availableSurcharge });
        setOpen(true);
    };

    const handleRemove = async (id: Scalars['ID']['output']) => {
        if (!window.confirm(formatMessage({ defaultMessage: 'Weet je zeker dat je deze wilt verwijderen?' }))) {
            return;
        }
        await remove({
            variables: {
                input: {
                    rentalUnitSurchargeId: id,
                },
            },
            update: (cache, result) => {
                const cachedData = cache.readQuery<RentalUnitSurchargesQuery>({
                    query: RentalUnitSurchargesDocument,
                    variables: { unitSlug },
                });
                if (cachedData?.rentalUnit?.surcharges) {
                    cache.writeQuery<RentalUnitSurchargesQuery>({
                        query: RentalUnitSurchargesDocument,
                        data: {
                            rentalUnit: {
                                ...cachedData.rentalUnit,
                                surcharges: cachedData.rentalUnit.surcharges.filter(
                                    surcharge => surcharge?.id !== result.data?.deleteRentalUnitSurcharge.id
                                ),
                            },
                        },
                        variables: { unitSlug },
                    });
                }
            },
        });
    };
    if (loading || contractLoading) {
        return <Loading />;
    }

    if (!data || !data.rentalUnit || !data.rentalUnit.availableSurcharges) {
        throw new NotFoundError();
    }
    const { surcharges } = data.rentalUnit;

    const availableSurcharges = data.rentalUnit.availableSurcharges.filter(
        (surcharge): surcharge is AvailableSurchargeFragment => !!surcharge
    );
    const hasMultipleRentalUnits = data.rentalUnit.accommodation.rentalUnits.length > 1;

    availableSurcharges.sort(({ nameNL: nameA }, { nameNL: nameB }) => {
        if (nameA! < nameB!) {
            return -1;
        }
        if (nameA! > nameB!) {
            return 1;
        }
        return 0;
    });

    const ruOptions: RentalUnitSurchargeFragment[] = [];
    const ruSurcharges: RentalUnitSurchargeFragment[] = [];

    for (const surcharge of surcharges || []) {
        if (surcharge) {
            if (surcharge.minAmount === 1 && surcharge.maxAmount === 1) {
                ruSurcharges.push(surcharge);
            } else {
                ruOptions.push(surcharge);
            }
        }
    }

    const handlers = {
        handleEdit,
        handleCreate,
        handleRemove,
    };

    return (
        <ContentWrapper variant="info">
            <Title>
                <FormattedMessage defaultMessage="Opties en bijkomende kosten" />
            </Title>
            <p>
                <FormattedMessage defaultMessage="Welke opties en bijkomende kosten zijn mogelijk of verplicht bij jouw accommodatie? Hier stel je het in." />
            </p>
            {hasMultipleRentalUnits && (
                <Button onClick={sesame.onOpen}>
                    <FormattedMessage defaultMessage="Kopieren" />
                </Button>
            )}

            {ruSurcharges.length > 0 && (
                <>
                    <FieldHeading title={formatMessage({ defaultMessage: 'Toeslagen' })}>
                        <FormattedMessage defaultMessage="Welke verplichte extra's hebben gasten bij het boeken?" />
                    </FieldHeading>

                    <SurchargeTable
                        {...handlers}
                        rentalUnitSurcharges={ruSurcharges}
                        availableSurcharges={availableSurcharges}
                        type="surcharge"
                    />
                </>
            )}

            {ruOptions.length > 0 && (
                <>
                    <FieldHeading title={formatMessage({ defaultMessage: 'Opties' })}>
                        <FormattedMessage defaultMessage="Welke opties kunnen gasten kiezen bij jouw accommodatie? Hier stel je ze in." />
                    </FieldHeading>
                    <SurchargeTable
                        {...handlers}
                        rentalUnitSurcharges={ruOptions}
                        availableSurcharges={availableSurcharges}
                        type="option"
                    />
                </>
            )}

            <FieldHeading title={formatMessage({ defaultMessage: 'Bijkomende kosten' })}>
                <FormattedMessage defaultMessage="Welke zaken kunnen jouw gasten bestellen bij hun boeking? Hier stel je het in." />
            </FieldHeading>
            {availableSurcharges.map(
                surcharge =>
                    surcharge && (
                        <FieldSet key={surcharge.id}>
                            <LabelBox>
                                <Label>{surcharge[`name${locale.toUpperCase()}` as keyof SurchargeNameType]}</Label>
                                {surcharge.description && <FieldSetHint>{surcharge.description}</FieldSetHint>}
                            </LabelBox>
                            <Box width={1}>
                                <TextButton size="tiny" onClick={() => handleCreate(surcharge, 'option')}>
                                    <Plus />
                                    <span>
                                        <FormattedMessage defaultMessage=">Optie of toeslag toevoegen" />
                                    </span>
                                </TextButton>
                                <Seperator variant="small" />
                            </Box>
                        </FieldSet>
                    )
            )}
            {hasMultipleRentalUnits && allRentalUnits && (
                <BulkSurchargeSidebar
                    {...sesame}
                    sourceRentalUnitId={data.rentalUnit.id}
                    rentalUnits={allRentalUnits.filter(rentalUnit => rentalUnit?.id !== data.rentalUnit?.id)}
                />
            )}
            <SurchargeSidebar
                rentalUnitId={data.rentalUnit.id}
                unitSlug={unitSlug}
                key={editableSurcharge?.id}
                data={editableSurcharge}
                contractData={contractData}
                {...menuProps}
            />
        </ContentWrapper>
    );
};

export default Surcharges;
