import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Cross from '@oberoninternal/travelbase-ds/components/figure/Cross';
import { Box, Flex } from '@rebass/grid';
import { debounce } from 'debounce';
import { Formik } from 'formik';
import gql from 'graphql-tag';
import isEqual from 'lodash/isEqual';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ArrayParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import {
    AllTicketsDocument,
    AllTicketsQuery,
    AllTicketsQueryVariables,
    TicketEdgeFragment,
} from '../../../generated/graphql';
import useInfiniteListQuery from '../../../hooks/useInfiniteListQuery';
import RangePickerInputField from '../../molecules/RangePickerInputField';
import { TableActionsContainer } from '../bookings/All';
import TicketsList from './TicketsList';

export const query = gql`
    query AllTickets(
        $partnerId: ID!
        $after: String
        $first: Int
        $before: String
        $last: Int
        $timeslotId: ID
        $activityIds: [ID!]
        $companyId: ID
        $startDate: Date
        $endDate: Date
    ) {
        partner(id: $partnerId) {
            id
            allTickets(
                first: $first
                last: $last
                after: $after
                before: $before
                timeslotId: $timeslotId
                activityIds: $activityIds
                companyId: $companyId
                startDate: $startDate
                endDate: $endDate
            ) {
                ...TicketConnection
            }
        }
    }

    fragment TicketConnection on TicketConnection {
        edges {
            ...TicketEdge
        }
        pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
        }
        totalCount
    }
`;

interface Values {
    activityIds: string[];
    startDate: string | null;
    endDate: string | null;
}
const initialValues: Values = {
    activityIds: [],
    startDate: null,
    endDate: null,
};

const paramsConfig = {
    activityIds: withDefault(ArrayParam, []),
    startDate: StringParam,
    endDate: StringParam,
};

const AllTickets = () => {
    const [
        {
            activityIds = initialValues.activityIds,
            startDate = initialValues.startDate,
            endDate = initialValues.endDate,
        },
        setQuery,
    ] = useQueryParams(paramsConfig);

    const { data, hasNextPage, loadMoreItems, isRefetching, isLoading } = useInfiniteListQuery<
        AllTicketsQuery,
        AllTicketsQueryVariables
    >({
        query: AllTicketsDocument,
        field: 'allTickets',
        variables: {
            activityIds: activityIds as string[],
            startDate,
            endDate,
        },
    });

    const edges =
        data?.partner?.allTickets?.edges?.filter((edge): edge is TicketEdgeFragment => !!edge?.node?.id) ?? [];
    const [submitting, setSubmitting] = useState(false);
    const searching = submitting || isRefetching;
    const isItemLoaded = (index: number) => (!hasNextPage || index < edges.length) && !!edges[index]?.node;

    // TODO: use useMemo instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onSubmit = useCallback(
        debounce((values: Values) => {
            setQuery({
                activityIds: values.activityIds,
                startDate: values.startDate ?? undefined,
                endDate: values.endDate ?? undefined,
            });
            setSubmitting(false);
        }, 250),
        []
    );

    return (
        <Formik
            enableReinitialize
            initialValues={{ activityIds: activityIds as string[], startDate, endDate }}
            onSubmit={onSubmit}
        >
            {({ resetForm, values }) => (
                <>
                    <TableActionsContainer>
                        <Box style={{ zIndex: 2 }}>
                            <RangePickerInputField optionalEndDate enabledPast size="medium" />
                        </Box>
                        <Box>
                            <TextButton
                                size="tiny"
                                disabled={isEqual(initialValues, values)}
                                onClick={() => {
                                    resetForm();
                                    setQuery(
                                        Object.assign(
                                            {},
                                            ...Object.keys(paramsConfig).map(key => ({ [key]: undefined }))
                                        )
                                    );
                                }}
                            >
                                <Cross />
                                <span>
                                    <FormattedMessage defaultMessage="Reset filters" />
                                </span>
                            </TextButton>
                        </Box>
                        <Flex ml="auto" alignItems="center">
                            {/* TODO: implement listactions

                                <ActionMenu align={'right'} withButtonBorder title={'Lijst exporteren'}>
                                    <MenuList>
                                        <Item>Printen</Item>
                                        <Item>Download als PDF</Item>
                                        <Item>Delen</Item>
                                    </MenuList>
                                </ActionMenu> */}
                        </Flex>
                    </TableActionsContainer>
                    <TicketsList
                        edges={edges}
                        isItemLoaded={isItemLoaded}
                        loadMoreItems={loadMoreItems}
                        onSubmit={onSubmit}
                        totalCount={data?.partner?.allTickets?.totalCount}
                        searching={searching}
                        loading={isLoading}
                    />
                </>
            )}
        </Formik>
    );
};

export default AllTickets;
