import { Scalars } from '../generated/graphql';
import unionBy from 'lodash/unionBy';

interface BaseFragment {
    id: Scalars['ID']['output'];
}
export const filterDuplicateFragments = (prev: BaseFragment[], next: BaseFragment[]) => {
    if (!Array.isArray(prev) || !Array.isArray(next)) {
        return next;
    }

    const hasId = (fragment: BaseFragment) => 'id' in fragment;
    if (!prev.every(hasId) || !next.every(hasId)) {
        // next or previous array doesn't have id keys,
        // fall back to just returning the next result directly.
        return next;
    }

    return unionBy(prev, next, ({ id }) => id);
};
