import gql from 'graphql-tag';
import React, { FC } from 'react';
import { NotFoundError } from '../../../../entities/NotFoundError';
import { ActivityParams } from '../../../../entities/ActivityParams';
import {
    CompanyImageFragment,
    useCompanyImagesQuery,
    useEditCompanyImagesMutation,
} from '../../../../generated/graphql';
import Loading from '../../../organisms/Loading';
import CompanyImagesFormScreen, { companyImageFragment } from './CompanyImagesFormScreen';
import upsertImage from '../../../../utils/upsertImage';
import { MAX_UPLOAD_IMAGES } from '../../../../constants/imageRequirements';
import { OtherImage } from '../../unit/info/ImagesFormScreen';
import { FormattedMessage } from 'react-intl';

export const companyImagesFragment = gql`
    fragment CompanyImages on Company {
        id
        name
        videoUrl
        mainImage {
            id
            size
            dimensions
            url
            previewUrl
        }
        logoImage {
            id
            size
            dimensions
            url
            previewUrl
        }
        listImage {
            id
            size
            dimensions
            url
            previewUrl
        }
        images {
            ...CompanyImage
        }
    }
    ${companyImageFragment}
`;

export const companyImagesQuery = gql`
    query CompanyImages($companySlug: String!) {
        company(slug: $companySlug) {
            id
            ...CompanyImages
        }
    }
    ${companyImagesFragment}
`;

export const editCompanyImagesMutation = gql`
    mutation EditCompanyImages($input: EditCompanyImagesInput!) {
        editCompanyImages(input: $input) {
            company {
                ...CompanyImages
            }
        }
    }
    ${companyImagesFragment}
`;

const formContent = {
    header: {
        title: `Foto’s`,
        description: `Hier stel je de foto's voor je onderneming in. Travelbase zorgt voor de juiste maten (en eventueel uitsnede). We raden je aan om foto's te bewerken voor schermweergave, maar waar mogelijk in de originele resolutie te houden (niet vergroten of verkleinen). Je kunt bestanden uploaden tot maximaal 25 MB per bestand.`,
    },
    mainImage: {
        title: `Hoofdfoto`,
        description: `De hoofdfoto toont Travelbase op de detailpagina van je ondermeming (in het groot). De foto is bij voorkeur in een breedbeeld 16:9 (liggende) verhouding. Foto's in standaard 4:3 verhouding worden door Travelbase uitgesneden naar het midden. We raden als resolutie 1920x1080 of hoger aan. Travelbase accepteert minimaal 1280x720.`,
    },
    listImage: {
        title: `Lijstfoto`,
        description: (
            <FormattedMessage defaultMessage="De lijstfoto van je onderneming toont Travelbase in zoekresultaten en lijsten. De foto is bij voorkeur in een standaard 4:3 (liggende) verhouding. We raden als resolutie 1600x1200 of hoger aan. Travelbase accepteert minimaal 800x600. Let op: de lijstfoto wordt niet getoond op de detailpagina van je onderneming, voeg deze ook toe onderaan bij de detailfoto’s om hem te tonen." />
        ),
    },
    logoImage: {
        title: `Logo`,
        description: `Voeg hier het logo van je onderneming toe. De afbeelding is bij voorkeur in een vierkante verhouding en transparant (PNG). We raden als resolutie 500x500 of hoger aan. Travelbase accepteert minimaal 200x200.`,
    },
    images: {
        title: `Foto’s`,
        description: `Voeg tot maximaal 30 foto’s toe van je onderneming. Je kunt de foto’s in de juiste volgorde slepen. Foto’s mogen liggend of staand zijn. Standaard 4:3 (liggende) foto’s komen het beste tot zijn recht. De verhouding tussen breedte en hoogte van de foto’s mag niet groter zijn dan 1:2. We raden als resolutie 1600x1200/1200x1600 of hoger aan. Travelbase accepteert minimaal 800x600/600x800.`,
    },
    video: {
        title: `Video`,
        description: `Plaats hier de URL van de YouTube of Vimeo video van jouw onderneming. De contactgegevens van je onderneming mogen in de film niet zichtbaar zijn.`,
    },
};

const CompanyImages: FC<React.PropsWithChildren<ActivityParams>> = ({ companySlug }) => {
    const slug = companySlug ?? '';
    const { data, loading } = useCompanyImagesQuery({ variables: { companySlug: slug } });
    const [mutate] = useEditCompanyImagesMutation();

    if (loading) {
        return <Loading />;
    }

    if (!data || !data.company) {
        throw new NotFoundError();
    }

    const {
        company: { id, mainImage, listImage, logoImage, images, videoUrl },
    } = data;

    const initialValues = {
        listImage: listImage
            ? {
                  file: listImage.url,
                  imageId: listImage.id,
                  previewUrl: listImage.previewUrl,
                  dimensions: listImage.dimensions,
              }
            : null,
        mainImage: mainImage
            ? {
                  file: mainImage.url,
                  imageId: mainImage.id,
                  previewUrl: mainImage.previewUrl,
                  dimensions: mainImage.dimensions,
              }
            : null,
        logoImage: logoImage
            ? {
                  file: logoImage.url,
                  id: logoImage.id,
                  previewUrl: logoImage.previewUrl,
                  dimensions: logoImage.dimensions,
              }
            : null,
        videoUrl: videoUrl ?? null,
        images: [
            ...images
                .filter((image): image is CompanyImageFragment => !!image)
                .sort((a, b) => a.position - b.position)
                .map(({ url, id: imageId, dimensions, previewUrl }) => ({
                    file: url,
                    id: imageId,
                    dimensions,
                    previewUrl,
                })),
        ] as Array<OtherImage | null>,
    };
    if (initialValues.images.length < MAX_UPLOAD_IMAGES) {
        // `null` will result in an empty upload field
        initialValues.images.push(null);
    }

    return (
        <CompanyImagesFormScreen
            content={formContent}
            handleSubmit={async values => {
                await mutate({
                    variables: {
                        input: {
                            images: values.images
                                .filter((image): image is CompanyImageFragment => !!image)
                                .map(({ id: photoId, previewUrl, dimensions, ...rest }) => ({
                                    imageId: photoId,
                                    ...rest,
                                })),
                            mainImageUploadId: upsertImage(values.mainImage),
                            listImageUploadId: upsertImage(values.listImage),
                            logoImageUploadId: upsertImage(values.logoImage),
                            companyId: id,
                            videoUrl: values.videoUrl,
                        },
                    },
                });
            }}
            initialValues={initialValues}
        />
    );
};

export default CompanyImages;
