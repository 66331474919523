import React, { FC, TableHTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import { StyledButton } from '@oberoninternal/travelbase-ds/components/action/TextButton';

const Table: FC<React.PropsWithChildren<TableHTMLAttributes<HTMLTableElement>>> = props => <StyledTable {...props} />;

export default Table;

const StyledTable = styled.table`
    border-collapse: collapse;
    width: 100%;

    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        tr > * {
            display: block;
        }
    }

    tr:first-of-type {
        @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
            td {
                padding-top: 2.4rem;
            }
        }
    }

    th {
        text-align: left;

        @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
            :not(:first-of-type) {
                display: none;
            }
        }
    }

    td {
        vertical-align: top;
        padding: 0.4rem 0;

        @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
            padding: 1rem 0.5rem;
        }

        @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
            ::before {
                display: block;
                content: attr(data-label);
                font-weight: 500;
                font-size: 12px;
                letter-spacing: 0.15px;
                line-height: 16px;
                margin: 0.4rem 0;
            }
        }
    }

    + ${StyledButton} {
        margin-top: 2rem;
    }
`;
