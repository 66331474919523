import Radio from '@oberoninternal/travelbase-ds/components/form/Radio';
import SelectInput from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import { StepperField } from '@oberoninternal/travelbase-ds/components/form/Stepper';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import Sidebar from '@oberoninternal/travelbase-ds/components/layout/Sidebar';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { MenuStateProps } from '@oberoninternal/travelbase-ds/hooks/useMenuState';
import { FormikProps } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import euroFormat from '../../constants/euroFormat';
import {
    ContractsQuery,
    EditRentalUnitSurchargeInput,
    PaymentTypeEnum,
    RentalUnitSurchargeFragment,
    RentalUnitSurchargesDocument,
    RentalUnitSurchargesQuery,
    Scalars,
    Surcharge,
    SurchargeCalculationEnum,
    useCreateRentalUnitSurchargeMutation,
    useEditRentalUnitSurchargeMutation,
} from '../../generated/graphql';
import parseDate from '@oberoninternal/travelbase-ds/utils/parseDate';
import { Seperator } from '../atoms/Seperator';
import SidebarField from '../atoms/SidebarField';
import SidebarIntro from '../atoms/SidebarIntro';
import FormScreen from '../organisms/FormScreen';
import { getOptionCalculationTexts, getSurchargeCalculationTexts } from '../pages/unit/info/Surcharges';
import DayPickerInputField from './DayPickerInputField';
import RangePickerInputField from './RangePickerInputField';
import { surchargeValidation } from './surchargeValidation';
import styled from 'styled-components/macro';
import { CheckboxField } from '@oberoninternal/travelbase-ds/components/form/Checkbox';
import { Box } from '@rebass/grid';
import { format } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';

export interface CreateSurcharge extends Surcharge {
    type?: SurchargeType;
    hideTypeSelect: boolean;
}

interface Props extends MenuStateProps {
    data: RentalUnitSurchargeFragment | CreateSurcharge | null;
    rentalUnitId: Scalars['ID']['output'];
    unitSlug: string;
    contractData: ContractsQuery | undefined;
}

export type SurchargeType = 'option' | 'surcharge';

type PeriodType = 'fixed' | 'flexible';

const isSurchargePaymentTypeConfigurable = (contractData: ContractsQuery | undefined) => {
    const rentalUnitContracts = contractData?.rentalUnit?.rentalUnitContracts;
    if (!rentalUnitContracts) {
        return false;
    }
    return rentalUnitContracts.some(rentalUnitContract => {
        const today = new Date();
        const contractStartDate = new Date(rentalUnitContract.startDate);
        const contractEndDate = rentalUnitContract.endDate && new Date(rentalUnitContract.endDate);

        const validStartDate = contractStartDate < today;
        const validEndDate = !contractEndDate || contractEndDate > today;

        if (validStartDate && validEndDate) {
            const surchargePaymentType: PaymentTypeEnum | undefined = rentalUnitContract.contract?.surchargePaymentType;
            if (surchargePaymentType === 'CONFIGURABLE') {
                return true;
            }
        }
        return false;
    });
};

const SurchargeSidebar: FC<React.PropsWithChildren<Props>> = ({
    data,
    rentalUnitId,
    unitSlug,
    contractData,
    ...menuProps
}) => {
    const intl = useIntl();
    const { locale } = intl;
    const [create] = useCreateRentalUnitSurchargeMutation();
    const [edit] = useEditRentalUnitSurchargeMutation();

    let surchargePaymentTypeConfigurable = false;
    surchargePaymentTypeConfigurable = isSurchargePaymentTypeConfigurable(contractData);

    let initialValues: Omit<EditRentalUnitSurchargeInput, 'rentalUnitSurchargeId'> = {
        calculation: SurchargeCalculationEnum.PerPiece,
        maxAmount: 1,
        minAmount: 0,
        startDate: null,
        endDate: null,
        unitPrice: 0,
        handlePayment: false,
    };

    let allowedCalculations: SurchargeCalculationEnum[] = [];
    let name: string;

    // When the typename equals RentalUnitSurcharge we are editing
    if (data?.__typename === 'RentalUnitSurcharge') {
        name =
            locale === 'de'
                ? data?.surcharge.nameDE ?? ''
                : locale === 'en'
                ? data?.surcharge.nameEN ?? ''
                : data?.surcharge.nameNL ?? '';
        allowedCalculations = (data.surcharge.allowedCalculations ?? []).filter(
            (calculation): calculation is SurchargeCalculationEnum => !!calculation
        );

        initialValues = Object.assign(
            {},
            ...Object.entries(initialValues).map(([key, val]) => {
                // convert date strings to real Date types
                if (key === 'startDate' || key === 'endDate') {
                    const value = data[key];
                    return { [key]: value ? parseDate(value) : null };
                }
                return {
                    [key]: data[key as keyof RentalUnitSurchargeFragment] || val,
                };
            })
        );
    }

    // When the typename equals Surcharge, we are creating
    if (data && data.__typename === 'Surcharge') {
        allowedCalculations = (data.allowedCalculations ?? []).filter(
            (calculation): calculation is SurchargeCalculationEnum => !!calculation
        );
        if (allowedCalculations.length === 1) {
            const [firstAllowedCalculation] = allowedCalculations;
            initialValues.calculation = firstAllowedCalculation;
        }
        if (surchargePaymentTypeConfigurable) {
            initialValues.handlePayment = true;
        }
        initialValues.minAmount = data.type === 'surcharge' ? 1 : 0;
        name = data.name ?? '';
    }

    return (
        <Sidebar {...menuProps}>
            {data ? (
                <FormScreen
                    variant="sidebar"
                    initialValues={initialValues}
                    validationSchema={surchargeValidation(intl)}
                    handleSubmit={async values => {
                        menuProps.setOpen(false);
                        const { startDate, endDate, ...rest } = values;

                        if (data.__typename === 'RentalUnitSurcharge') {
                            await edit({
                                variables: {
                                    input: {
                                        rentalUnitSurchargeId: data.id,
                                        startDate: values.startDate
                                            ? format(parseDate(values.startDate), 'yyyy-MM-dd')
                                            : null,
                                        endDate: values.endDate
                                            ? format(parseDate(values.endDate), 'yyyy-MM-dd')
                                            : null,
                                        ...rest,
                                    },
                                },
                            });
                        }
                        if (data.__typename === 'Surcharge') {
                            await create({
                                variables: {
                                    input: {
                                        rentalUnitId,
                                        surchargeId: data.id,
                                        ...values,
                                    },
                                },
                                update: (cache, result) => {
                                    const cachedData = cache.readQuery<RentalUnitSurchargesQuery>({
                                        query: RentalUnitSurchargesDocument,
                                        variables: { unitSlug },
                                    });
                                    if (
                                        cachedData?.rentalUnit?.surcharges &&
                                        result.data?.createRentalUnitSurcharge.rentalUnitSurcharge
                                    ) {
                                        cache.writeQuery<RentalUnitSurchargesQuery>({
                                            query: RentalUnitSurchargesDocument,
                                            data: {
                                                rentalUnit: {
                                                    ...cachedData.rentalUnit,
                                                    surcharges: [
                                                        ...cachedData.rentalUnit.surcharges,
                                                        result.data.createRentalUnitSurcharge.rentalUnitSurcharge,
                                                    ],
                                                },
                                            },
                                            variables: { unitSlug },
                                        });
                                    }
                                },
                            });
                        }
                    }}
                >
                    {formikBag => (
                        <FormContent
                            // reinitialize content on selected surcharge change
                            key={`${data.id}`}
                            formikBag={formikBag}
                            allowedCalculations={allowedCalculations}
                            data={data}
                            name={name}
                            surchargePaymentTypeConfigurable={surchargePaymentTypeConfigurable}
                        />
                    )}
                </FormScreen>
            ) : null}
        </Sidebar>
    );
};

interface FormContentProps extends Pick<Props, 'data'> {
    formikBag: FormikProps<Omit<EditRentalUnitSurchargeInput, 'rentalUnitSurchargeId'>>;
    allowedCalculations: SurchargeCalculationEnum[];
    name: string;
    surchargePaymentTypeConfigurable: boolean;
}

const FormContent: FC<React.PropsWithChildren<FormContentProps>> = ({
    data,
    allowedCalculations,
    formikBag: { values, setFieldValue, setValues, validateForm },
    name,
    surchargePaymentTypeConfigurable,
}) => {
    const intl = useIntl();
    const { formatMessage } = intl;

    const [period, setPeriod] = useState<PeriodType>(
        data?.__typename === 'Surcharge' || values.endDate ? 'fixed' : 'flexible'
    );
    const [type, setType] = useState<SurchargeType>(
        data?.__typename === 'Surcharge' && data.type === 'surcharge' ? 'surcharge' : 'option'
    );

    const canBePaidThroughTor =
        (data?.__typename === 'RentalUnitSurcharge' || data?.__typename === 'Surcharge') &&
        surchargePaymentTypeConfigurable;

    const options = Object.entries(
        type === 'surcharge' ? getSurchargeCalculationTexts(intl) : getOptionCalculationTexts(intl)
    )
        .filter(([key]) => allowedCalculations.find(calculation => calculation === key))
        .map(([key, val]) => ({
            label: key !== SurchargeCalculationEnum.Subsequent ? `${euroFormat(values.unitPrice)} ${val}` : val,
            value: key,
        }));

    const hasMultipleOptions = options.length > 1;

    useEffect(() => {
        if (!data) {
            return;
        }
        switch (data.__typename) {
            case 'Surcharge':
                setType(values.minAmount === 1 && values.maxAmount === 1 ? 'surcharge' : 'option');
                break;
            case 'RentalUnitSurcharge':
                setType(data.minAmount === 1 && data.maxAmount === 1 ? 'surcharge' : 'option');
                break;
            default:
                break;
        }
    }, [data, values.maxAmount, values.minAmount]);

    useEffect(() => {
        if (
            values.calculation === SurchargeCalculationEnum.Subsequent &&
            (values.unitPrice > 0 || values.handlePayment)
        ) {
            setValues({ ...values, unitPrice: 0, handlePayment: false });
        }
    }, [setValues, values]);

    // As we depend on type for a certain validation, we want to validate again when its value changes
    useEffect(() => {
        validateForm();
    }, [type, validateForm]);

    if (!data) {
        return null;
    }

    const getTitle = () => {
        if (data.__typename === 'RentalUnitSurcharge') {
            if (type === 'surcharge') {
                return 'Toeslag wijzigen';
            }
            return 'Optie wijzigen';
        }
        if (data.__typename === 'Surcharge' && data.hideTypeSelect) {
            if (type === 'surcharge') {
                return 'Toeslag toevoegen';
            }
            return 'Optie toevoegen';
        }
        return 'Optie of toeslag toevoegen';
    };
    return (
        <>
            <SidebarIntro
                title={
                    type === 'surcharge'
                        ? formatMessage({ defaultMessage: 'Toeslag' })
                        : formatMessage({ defaultMessage: 'Optie' })
                }
            >
                {name}
            </SidebarIntro>
            <Seperator variant="small" style={{ marginBottom: 0 }} />
            <Title variant="small">{getTitle()}</Title>
            {data.__typename === 'Surcharge' && !data.hideTypeSelect && (
                <SidebarField label={formatMessage({ defaultMessage: 'Type' })}>
                    <Radio
                        name="surcharge"
                        id="option"
                        checked={type === 'option'}
                        onChange={() => {
                            setType('option');
                            setFieldValue('minAmount', 0);
                        }}
                    >
                        <FormattedMessage defaultMessage="Optie" />
                    </Radio>
                    <Radio
                        name="surcharge"
                        id="surcharge"
                        checked={type === 'surcharge'}
                        onChange={() => {
                            setType('surcharge');
                            setFieldValue('minAmount', 1);
                        }}
                    >
                        <FormattedMessage defaultMessage="Toeslag" />
                    </Radio>
                </SidebarField>
            )}
            <Body>
                <FormattedMessage
                    defaultMessage="Wil je dat je {type} toegepast wordt over een periode? Hoe wil je dat de kosten berekend worden? Stel het hier in."
                    values={{
                        type:
                            type === 'surcharge' ? (
                                <FormattedMessage defaultMessage="toeslag" />
                            ) : type === 'option' ? (
                                <FormattedMessage defaultMessage="optie" />
                            ) : (
                                <FormattedMessage defaultMessage="optie of toeslag" />
                            ),
                    }}
                />
            </Body>
            <SidebarField label={formatMessage({ defaultMessage: 'Periode' })}>
                <Radio
                    name="fixed"
                    id="fixed"
                    checked={period === 'fixed'}
                    onChange={() => {
                        setPeriod('fixed');
                        setFieldValue('endDate', null);
                    }}
                >
                    <FormattedMessage defaultMessage="Tot en met een bepaalde datum" />
                </Radio>
                <Radio
                    name="fixed"
                    id="flexible"
                    checked={period === 'flexible'}
                    onChange={() => {
                        setPeriod('flexible');
                        if (values.endDate) {
                            setFieldValue('endDate', null);
                        }
                    }}
                >
                    <FormattedMessage defaultMessage="Onbepaalde tijd" />
                </Radio>
            </SidebarField>

            <SidebarField
                label={
                    period === 'fixed'
                        ? formatMessage({ defaultMessage: 'Beschikbaar vanaf-t/m datum' })
                        : formatMessage({ defaultMessage: 'Beschikbaar-vanaf datum' })
                }
            >
                {period === 'fixed' ? <RangePickerInputField optionalEndDate /> : <DayPickerInputField />}
            </SidebarField>

            {values.calculation !== SurchargeCalculationEnum.Subsequent && (
                <SidebarField label={formatMessage({ defaultMessage: 'Bedrag (€)' })}>
                    <TextInputField name="unitPrice" type="number" step={0.01} variant="small" />
                </SidebarField>
            )}

            <SidebarField label={formatMessage({ defaultMessage: 'Berekening' })}>
                {hasMultipleOptions ? (
                    <SelectInput
                        position="static"
                        value={options.find(({ value }) => value === values.calculation) ?? null}
                        variant="small"
                        name="calculation"
                        options={options}
                    />
                ) : (
                    <p>{options[0]?.label}</p>
                )}
            </SidebarField>
            {type === 'option' &&
                options.find(
                    option =>
                        option.value === SurchargeCalculationEnum.PerPiece ||
                        option.value === SurchargeCalculationEnum.PerPiecePerNight
                ) && (
                    <SidebarField label={formatMessage({ defaultMessage: 'Aantal beschikbaar' })}>
                        <StepperField name="maxAmount" minimum={1} />

                        {values.calculation !== SurchargeCalculationEnum.PerPiece && values.handlePayment && (
                            <Body variant="small">
                                <FormattedMessage defaultMessage="Let op: Doorgaans is aantal van 1 voldoende. We vermenigvuldigen dit aantal nog op basis van de verblijfsduur en/of het reisgezelschap." />
                            </Body>
                        )}
                    </SidebarField>
                )}
            {canBePaidThroughTor && values.calculation !== SurchargeCalculationEnum.Subsequent && (
                <SidebarField label={formatMessage({ defaultMessage: 'Betaling via Travelbase' })}>
                    <Box mb={3}>
                        <CheckboxField id="handlePayment" name="handlePayment" />
                    </Box>
                </SidebarField>
            )}
            {/* this formspacer exists in order to avoid the datepicker from getting cut off visually */}
            <FormSpacer />
        </>
    );
};

export default SurchargeSidebar;

const FormSpacer = styled.div`
    margin-bottom: 20rem;
`;
