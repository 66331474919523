import gql from 'graphql-tag';
import React, { FC } from 'react';
import { NotFoundError } from '../../../../entities/NotFoundError';
import { UnitParams } from '../../../../entities/UnitParams';
import { useApproveContractMutation, useContractsQuery } from '../../../../generated/graphql';
import Loading from '../../../organisms/Loading';
import AgreementsForm, { AgreementType } from '../../../molecules/AgreementForm';
import { MutationFunction } from '@apollo/client';

export const query = gql`
    query Contracts($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            id
            rentalUnitContracts {
                ...Contracts
            }
        }
    }
`;

export const fragment = gql`
    fragment Contracts on RentalUnitContract {
        id
        contract {
            name
            bookingType
            toBePaidThroughTor
            commissionPercentage
            contractUrl
            surchargePaymentType
        }
        startDate
        endDate
        approvalDate
    }
`;

export const mutation = gql`
    mutation ApproveContract($id: ID!) {
        approveRentalUnitContract(input: { rentalUnitContractId: $id }) {
            rentalUnitContract {
                ...Contracts
            }
        }
    }
`;

const Contracts: FC<React.PropsWithChildren<UnitParams>> = ({ unitSlug }) => {
    const { data, loading } = useContractsQuery({ variables: { unitSlug } });
    const [mutate] = useApproveContractMutation();
    if (loading) {
        return <Loading />;
    }

    if (!data || !data.rentalUnit) {
        throw new NotFoundError();
    }

    const {
        rentalUnit: { rentalUnitContracts: agreements },
    } = data;

    return <AgreementsForm mutate={mutate as MutationFunction} agreements={agreements as AgreementType[]} />;
};

export default Contracts;
