import React, { AnchorHTMLAttributes, ButtonHTMLAttributes, FC, forwardRef, Ref } from 'react';
import { LinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { getAssignmentColor, getFontCss } from '../../constants/theme';

export type TextButtonVariant = 'primary' | 'danger';

export type TextButtonSize = 'tiny' | 'regular';

export interface Props
    extends ButtonHTMLAttributes<HTMLButtonElement>,
        Partial<Omit<LinkProps, keyof AnchorHTMLAttributes<{}>>> {
    variant?: TextButtonVariant;
    size?: TextButtonSize;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    as?: keyof JSX.IntrinsicElements | React.ComponentType<React.PropsWithChildren<any>>;
    href?: string;
    target?: string;
    rel?: string;
    ref?: Ref<HTMLButtonElement | HTMLAnchorElement>;
    showBorderAlways?: boolean;
    hideBorderOnFocus?: boolean;
}

/**
 * If you use this component as a link and you notice that the hover border is taking up the whole width, just wrap it in a flex div
 *
 */
const TextButton: FC<React.PropsWithChildren<Props>> = forwardRef(
    (
        {
            children,
            size = 'regular',
            variant = 'primary',
            type = 'button',
            href,
            showBorderAlways = false,
            hideBorderOnFocus = false,
            ...props
        },
        ref
    ) => (
        <StyledButton
            {...props}
            size={size}
            variant={variant}
            type={type}
            href={href}
            ref={ref}
            showBorderAlways={showBorderAlways}
            hideBorderOnFocus={hideBorderOnFocus}
        >
            <span>{children}</span>
        </StyledButton>
    )
);

export default TextButton;

export const StyledButton = styled.button<Required<Props>>`
    text-decoration: none;
    padding: 0.4rem 0;
    ${({ theme, size }) => getFontCss(theme.fontSizes.body[size])};
    font-weight: 500;
    text-align: left;
    --max-line: 22;
    color: ${({ theme, variant }) =>
        variant === 'danger'
            ? theme.colors.negative['60']
            : getAssignmentColor(theme, theme.colorAssignments.textAction)};

    > span {
        position: relative;
        align-items: center;
        display: flex;
        > :first-child + :last-child {
            margin-left: 0.5em;
        }

        :after {
            position: absolute;
            content: '';
            left: 0;
            bottom: 0;
            right: 0;
            opacity: 0;
            border-bottom: 1px solid currentColor;
            ${({ size }) => (size === 'tiny' ? 'padding-bottom: 2px;' : '')};
        }

        svg {
            margin-top: ${({ size }) => (size === 'tiny' ? '0' : '-2px')};
        }
    }

    ${({ showBorderAlways }) =>
        showBorderAlways &&
        css`
            > span {
                ::after {
                    opacity: 1;
                }
            }
        `};

    :focus {
        > span {
            ::after {
                opacity: 1;
            }
        }
    }

    :hover {
        > span {
            ::after {
                opacity: 1;
            }
        }
        color: ${({ theme, variant }) =>
            variant === 'danger'
                ? theme.colors.negative['70']
                : getAssignmentColor(theme, theme.colorAssignments.textAction, 1)};
    }

    :disabled {
        color: ${({ theme }) => theme.colors.neutral['40']};
        cursor: not-allowed;
    }

    svg {
        flex-shrink: 0;
    }
`;
