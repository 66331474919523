import ActionMenu from '@oberoninternal/travelbase-ds/components/action/ActionMenu';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Min from '@oberoninternal/travelbase-ds/components/figure/Min';
import Plus from '@oberoninternal/travelbase-ds/components/figure/Plus';
import Checkbox from '@oberoninternal/travelbase-ds/components/form/Checkbox';
import Radio from '@oberoninternal/travelbase-ds/components/form/Radio';
import { Flex } from '@rebass/grid';
import { useField, useFormikContext } from 'formik';
import React, { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components/macro';

interface Base {
    thumbnail: ReactNode;
    id: string;
}

interface Parent extends Base {
    autoSelectChildren: boolean;
    children?: Entry[];
    fieldValue?: never;
}

export interface Child extends Base {
    fieldValue: string;
    autoSelectChildren?: never;
    children?: never;
}

export type Entry = Parent | Child;

interface Props {
    /**
     * `single` renders radio buttons and `multiple` renders checkboxes
     */
    type?: 'single' | 'multiple';
    fieldName: string;
    title: string;
    entries: Entry[];
}

const FilterMenu = (props: Props) => {
    const { entries, title, type, fieldName } = props;
    const { values, setFieldValue } = useFormikContext<Record<string, string[]>>();
    const allChildrenValues = entries.reduce<string[]>(
        (acc, next) => [
            ...acc,
            ...(next.fieldValue ? [next.fieldValue] : []),
            ...(next.children ?? [])
                ?.filter((child): child is Child => !!child.fieldValue)
                .map(child => child.fieldValue),
        ],
        []
    );
    const selectedValues = values[fieldName];
    const allChildrenSelected = allChildrenValues.every(val => selectedValues.includes(val));

    return (
        <StyledActionMenu title={title}>
            <Stack mb={4}>
                <Checkbox
                    disabled={!selectedValues.length}
                    id="select-all"
                    onChange={e => {
                        if (e.currentTarget.checked) {
                            setFieldValue(fieldName, allChildrenValues);
                        } else {
                            setFieldValue(fieldName, []);
                        }
                    }}
                    checked={allChildrenSelected || !selectedValues.length}
                >
                    <FormattedMessage defaultMessage="Selecteer alle" />
                </Checkbox>
            </Stack>
            <PickerList>
                {entries.map(entry => (
                    <FilterMenuEntry fieldName={fieldName} key={entry.id} entry={entry} type={type} />
                ))}
            </PickerList>
        </StyledActionMenu>
    );
};

export default FilterMenu;

interface FilterMenuEntryProps {
    entry: Entry;
    type: Props['type'];
    fieldName: string;
}
const FilterMenuEntry = ({ entry, type, fieldName }: FilterMenuEntryProps) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <PickerItem>
            <Stack>
                <Field entry={entry} type={type} fieldName={fieldName} />
                {!!entry.children?.length && (
                    <TextButton onClick={() => setExpanded(!expanded)} style={{ marginLeft: 'auto' }}>
                        {expanded ? <Min /> : <Plus />}
                    </TextButton>
                )}
            </Stack>
            {!!entry.children?.length && expanded && (
                <PickerList>
                    {entry.children.map(child => (
                        <PickerItem child key={child.id}>
                            <Stack>
                                <Field fieldName={fieldName} type={type} entry={child} />
                            </Stack>
                        </PickerItem>
                    ))}
                </PickerList>
            )}
        </PickerItem>
    );
};

const Field = ({ entry, type, fieldName }: { type: Props['type']; entry: Entry; fieldName: string }) => {
    const FieldComponent = type === 'single' ? Radio : Checkbox;
    const { values, setFieldValue } = useFormikContext<Record<string, string[]>>();
    const [props] = useField({ name: fieldName, value: entry.fieldValue, type: 'checkbox' });
    const hasChildren = !!entry.children?.length;

    if (entry.autoSelectChildren) {
        if (!hasChildren) {
            return null;
        }

        const childrenValues = (entry.children ?? [])
            .filter((child): child is Child => !!child.fieldValue)
            .map(child => child.fieldValue);
        const selectedValues = values[fieldName];
        const allChildrenSelected = childrenValues.every(val => selectedValues.includes(val));

        return (
            <>
                <FieldComponent
                    id={entry.id}
                    onChange={e => {
                        if (e.currentTarget.checked) {
                            setFieldValue(fieldName, [
                                // remove potential duplicates from selected array
                                ...new Set(
                                    selectedValues.concat(
                                        ...(entry.children ?? []).map(child => child.fieldValue ?? '')
                                    )
                                ),
                            ]);
                        } else {
                            setFieldValue(
                                fieldName,
                                selectedValues.filter(value => !childrenValues.includes(value))
                            );
                        }
                    }}
                    checked={allChildrenSelected}
                />
                <label htmlFor={entry.id}>{entry.thumbnail}</label>
            </>
        );
    }

    return (
        <>
            <FieldComponent id={entry.id} {...props} />
            <label htmlFor={entry.id}>{entry.thumbnail}</label>
        </>
    );
};

const Stack = styled(Flex)`
    align-items: center;
    > * + * {
        margin-left: ${({ theme }) => theme.spacing['30_Small']};
    }
`;

const PickerItem = styled.li<{ child?: boolean }>`
    padding: 0;
    margin: 0;
    border-top: 1px solid ${({ theme }) => theme.colors.neutral[20]};
    padding: ${({ theme }) => theme.spacing['40_Standard']} 0;
    ${({ child, theme }) =>
        child &&
        css`
            margin-left: ${theme.spacing['60_Large']};
        `};

    :last-of-type {
        padding-bottom: 0;
    }
`;

const PickerList = styled.ul`
    padding: 0;
    margin: 0;
    margin-top: ${({ theme }) => theme.spacing['40_Standard']};
`;

export const StyledActionMenu = styled(ActionMenu)`
    @media (max-width: ${({ theme }) => theme.mediaQueries.xs}) {
        width: 85vw;
    }
`;
