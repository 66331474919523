import React, { Component, PropsWithChildren, ReactNode } from 'react';
import { toast } from 'react-toastify';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Toast from '@oberoninternal/travelbase-ds/components/feedback/Toast';
import * as Sentry from '@sentry/react';
import { FormattedMessage, defineMessage } from 'react-intl';

const message = defineMessage({
    defaultMessage: 'Wijzigingen opslaan mislukt.',
});
class ToastErrorBoundary extends Component<PropsWithChildren> {
    componentDidCatch(error: Error) {
        Sentry.captureException(error);

        let element: ReactNode;
        switch (error.name) {
            case 'MutationError':
                element = (
                    <Toast variant="error" title={<FormattedMessage {...message} />}>
                        <Body variant="small">
                            <FormattedMessage defaultMessage="Er is iets misgegaan. Probeer het later opnieuw." />
                        </Body>
                    </Toast>
                );
                break;
            default:
                element = (
                    <Toast variant="error" title={error.name}>
                        <Body variant="small">
                            <FormattedMessage defaultMessage="Er is iets misgegaan. Probeer het later opnieuw." />
                        </Body>
                    </Toast>
                );
                break;
        }
        toast(element, { closeButton: true });
    }

    render() {
        return this.props.children;
    }
}

export default ToastErrorBoundary;
