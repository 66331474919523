import isBefore from 'date-fns/isBefore';
import startOfToday from 'date-fns/startOfToday';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { useApolloClient } from '@apollo/client';
import * as Yup from 'yup';
import { UnitType } from '../../constants/unitTypes';
import {
    PriceColumnAllotmentFragment,
    PriceColumnAllotmentFragmentDoc,
    useBulkEditAllotmentsMutation,
} from '../../generated/graphql';
import Heading from '../atoms/Heading';
import SidebarField from '../atoms/SidebarField';
import DayPickerInputField from './DayPickerInputField';
import FormScreen from '../organisms/FormScreen';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import { useSidebar } from '../../context/sidebar';
import styled from 'styled-components/macro';
import { FormActionsContainer } from '@oberoninternal/travelbase-ds/components/form/FormActions';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import { SidebarSeperator } from '../atoms/Seperator';
import { FormattedMessage, useIntl } from 'react-intl';

export interface AllotmentBulkSidebarData {
    allotment?: PriceColumnAllotmentFragment;
    type: 'ALLOTMENT_BULK_SIDEBAR';
    maxAllotment: number;
    rentalUnitId: string;
    unitType?: UnitType;
    date: string;
}

export interface Props {
    data: AllotmentBulkSidebarData;
}

export const mutation = gql`
    mutation bulkEditAllotments($input: BulkEditAllotmentsInput!) {
        bulkEditAllotments(input: $input) {
            allotments {
                ...PriceColumnAllotment
            }
        }
    }
`;

const AllotmentBulkSidebar: FC<React.PropsWithChildren<Props>> = ({
    data: { allotment, maxAllotment, rentalUnitId, unitType, date },
}) => {
    const { formatMessage } = useIntl();
    const validationSchema = Yup.object().shape({
        amount: Yup.number()
            .integer(formatMessage({ defaultMessage: 'Vul een rond getal in' }))
            .typeError(formatMessage({ defaultMessage: 'Ongeldige waarde' }))
            .max(
                maxAllotment,
                formatMessage(
                    { defaultMessage: `Beschikbaarheid kan niet boven het maximum ({maxAllotment}) liggen` },
                    { maxAllotment }
                )
            )
            .min(0, formatMessage({ defaultMessage: 'Beschikbaarheid moet positief zijn' }))
            .required(formatMessage({ defaultMessage: 'Beschikbaarheid is verplicht' })),
        startDate: Yup.date()
            .typeError(formatMessage({ defaultMessage: 'Ongeldige waarde' }))
            .required(formatMessage({ defaultMessage: 'Start datum is verplicht.' })),
        endDate: Yup.date()
            .typeError(formatMessage({ defaultMessage: 'Ongeldige waarde' }))
            .required(formatMessage({ defaultMessage: 'Eind datum is verplicht.' })),
    });

    const client = useApolloClient();
    const [, dispatch] = useSidebar();

    let amount = 0;

    const allotmentData = allotment
        ? client.readFragment<PriceColumnAllotmentFragment>({
              fragment: PriceColumnAllotmentFragmentDoc,
              id: `Allotment:${allotment.id}`,
          })
        : undefined;

    if (allotmentData) {
        amount = allotmentData.amount;
    }

    const [edit] = useBulkEditAllotmentsMutation();
    return (
        <FormScreen
            variant="sidebar"
            bottomChildren={({ dirty, resetForm }) => (
                <FormActionsContainer dirty={dirty} variant="sidebar">
                    <TextButton onClick={() => resetForm()}>
                        <FormattedMessage defaultMessage="Ongedaan maken" />
                    </TextButton>
                    <Button type="submit">
                        <FormattedMessage defaultMessage="Opslaan" />
                    </Button>
                </FormActionsContainer>
            )}
            validationSchema={validationSchema}
            initialValues={{
                amount: amount.toString(),
                startDate: date,
                endDate: date,
            }}
            handleSubmit={async (values, resetForm) => {
                const input = {
                    rentalUnitId,
                    amount: parseInt(values.amount, 10),
                    startDate: values.startDate,
                    endDate: values.endDate,
                };
                await edit({
                    variables: {
                        input,
                    },
                });

                resetForm(values);
                setTimeout(() => dispatch({ type: 'close' }));
            }}
            skipReset
        >
            {({ values }) => {
                const isInPast =
                    isBefore(new Date(values.startDate), startOfToday()) ||
                    isBefore(new Date(values.endDate), startOfToday());

                return (
                    <>
                        <OverlayDatePicker>
                            <SidebarField label={formatMessage({ defaultMessage: 'Startdatum' })}>
                                <DayPickerInputField name="startDate" />
                            </SidebarField>
                        </OverlayDatePicker>
                        <SidebarField label={formatMessage({ defaultMessage: 'Einddatum' })}>
                            <DayPickerInputField name="endDate" />
                        </SidebarField>
                        <SidebarSeperator />
                        <Heading
                            title={
                                isInPast
                                    ? formatMessage({ defaultMessage: 'Bekijk de beschikbaarheid' })
                                    : formatMessage({ defaultMessage: 'Pas je beschikbaarheid aan' })
                            }
                        >
                            <FormattedMessage
                                defaultMessage="Hoeveel {unitType} {isInPast, select, true {waren} other {zijn}} er op deze dag beschikbaar?"
                                values={{
                                    unitType: unitType?.label.toLowerCase() ?? '',
                                    isInPast,
                                }}
                            />
                        </Heading>
                        <SidebarField label={formatMessage({ defaultMessage: 'Beschikbaarheid' })} id="availability">
                            <TextInputField
                                autoFocus
                                variant="small"
                                name="amount"
                                disabled={isInPast}
                                id="availability"
                            />
                        </SidebarField>
                    </>
                );
            }}
        </FormScreen>
    );
};

const OverlayDatePicker = styled.div`
    z-index: ${({ theme }) => theme.zIndices.docked};
    position: relative;
`;

export default AllotmentBulkSidebar;
