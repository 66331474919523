import React, { FC } from 'react';
import styled from 'styled-components/macro';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';

interface Props {
    title: string;
}
const Heading: FC<React.PropsWithChildren<Props>> = ({ title, children }) => (
    <>
        <Title variant="small">{title}</Title>
        <Description>{children}</Description>
    </>
);

export default Heading;

const Description = styled(Body)`
    margin-top: ${({ theme }) => theme.spacing['30_Small']};
`;
