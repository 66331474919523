import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import { CheckboxField } from '@oberoninternal/travelbase-ds/components/form/Checkbox';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import { getFontCss } from '@oberoninternal/travelbase-ds/constants/theme';
import { Box, Flex } from '@rebass/grid';
import Bowser from 'bowser';
import { Form, Formik } from 'formik';
import React, { FC, useEffect, useMemo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import { usePartnerPickerLazyQuery } from '../../generated/graphql';
import LoginContainer from '../molecules/LoginContainer';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

const loginSchema = (intl: IntlShape) =>
    Yup.object().shape({
        email: Yup.string()
            .email(intl.formatMessage({ defaultMessage: 'Vul een geldig e-mailadres in' }))
            .required(intl.formatMessage({ defaultMessage: 'Een e-mailadres is verplicht' })),
        password: Yup.string().required(intl.formatMessage({ defaultMessage: 'Een wachtwoord is verplicht' })),
        rememberMe: Yup.boolean(),
    });

interface Values {
    email: string;
    password: string;
    rememberMe: boolean;
}

const login = (values: Values) =>
    fetch(`${process.env.REACT_APP_API_URL}/login/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(values),
    });

const Login: FC<React.PropsWithChildren> = () => {
    const history = useHistory();

    const [fetchPartners, { called, loading, data }] = usePartnerPickerLazyQuery();

    const showLogin = !called || !data;

    useEffect(() => {
        if (!showLogin) history.push('/');
    }, [showLogin, history]);

    const isInternetExplorer = useMemo(
        () => Bowser.getParser(window.navigator.userAgent).getBrowser().name === 'Internet Explorer',
        []
    );

    return (
        <LoginContainer>
            {isInternetExplorer ? (
                <p>
                    <FormattedMessage
                        defaultMessage="Travelbase ondersteunt geen Internet Explorer. Om het wel te kunnen gebruiken adviseren wij om {chrome}Chrome{chrome} of {firefox}Firefox{firefox} te installeren, of om {edge}Microsoft Edge{edge} te gebruiken."
                        values={{
                            chrome: chunks => (
                                <a href="https://www.google.nl/chrome/" target="_blank" rel="noopener noreferrer">
                                    {chunks}
                                </a>
                            ),
                            firefox: chunks => (
                                <a
                                    href="https://www.mozilla.org/nl/firefox/new/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {chunks}
                                </a>
                            ),
                            edge: chunks => (
                                <a
                                    href="https://www.microsoft.com/nl-nl/windows/microsoft-edge"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {chunks}
                                </a>
                            ),
                        }}
                    />
                </p>
            ) : (
                <LoginForm fetchPartners={fetchPartners} fetchingPartners={loading} />
            )}
        </LoginContainer>
    );
};

export default Login;

interface LoginFormProps {
    fetchPartners: () => void;
    fetchingPartners: boolean;
}

const LoginForm = ({ fetchPartners, fetchingPartners }: LoginFormProps) => {
    const history = useHistory();
    const { state } = useLocation<{ successMessage: string; from: string } | undefined>();
    const intl = useIntl();
    const { formatMessage } = intl;
    return (
        <>
            <p>
                <FormattedMessage defaultMessage="Deze applicatie is voor partners die gebruik maken van Travelbase." />
            </p>
            {state?.successMessage ? <SuccessMessage>{state.successMessage}</SuccessMessage> : null}
            <Formik<Values>
                validationSchema={loginSchema(intl)}
                initialValues={{ email: '', password: '', rememberMe: false }}
                onSubmit={async (user, { setErrors }) => {
                    try {
                        const result = await login(user);
                        if (result.ok) {
                            if (state?.from) {
                                history.push(state?.from);
                            } else {
                                fetchPartners();
                            }
                        } else if (result.status === 401) {
                            setErrors({
                                password: 'De combinatie van e-mailadres en wachtwoord is niet geldig.',
                            });
                        } else {
                            setErrors({
                                password: 'Er is iets misgegaan',
                            });
                        }
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    } catch (ex: any) {
                        setErrors({ password: ex.message });
                    }
                }}
            >
                {({ isSubmitting, values }) => (
                    <Form>
                        <Box mb={4}>
                            <TextInputField
                                name="email"
                                placeholder={formatMessage({ defaultMessage: 'e-mailadres' })}
                            />
                        </Box>
                        <Box mb={4}>
                            <TextInputField
                                name="password"
                                type="password"
                                placeholder={formatMessage({ defaultMessage: 'wachtwoord' })}
                            />
                        </Box>
                        <CheckboxField id="rememberMe" name="rememberMe">
                            <FormattedMessage defaultMessage="Ingelogd blijven" />
                        </CheckboxField>
                        <LoginButton
                            submitting={isSubmitting || fetchingPartners}
                            disabled={isSubmitting}
                            type="submit"
                        >
                            <FormattedMessage defaultMessage="Inloggen" />
                        </LoginButton>
                        <Flex flexDirection="column" mt={4}>
                            <Box mb={2}>
                                <PasswordResetLink to={{ pathname: '/forgot', state: { email: values.email } }}>
                                    <FormattedMessage defaultMessage="Wachtwoord vergeten?" />
                                </PasswordResetLink>
                            </Box>
                            <PasswordResetLink to={{ pathname: '/registration' }}>
                                <FormattedMessage defaultMessage="Aanmelden als nieuwe partner" />
                            </PasswordResetLink>
                        </Flex>
                    </Form>
                )}
            </Formik>
        </>
    );
};

const LoginButton = styled(Button)`
    margin-top: 2rem;
    width: 100%;
`;

const PasswordResetLink = styled(Link)`
    font-size: 12px;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary[70]};
    height: 20px;
    line-height: 20px;
    cursor: pointer;

    :visited,
    :link {
        color: ${({ theme }) => theme.colors.primary[70]};
    }

    :hover {
        text-decoration: underline;
    }

    :active {
        color: ${({ theme }) => theme.colors.primary[70]};
    }
`;

const SuccessMessage = styled(Body)`
    ${({ theme }) => getFontCss(theme.fontSizes.body.regular)};
    text-decoration: none;
    color: green;
    height: 20px;
    line-height: 20px;
    margin: 3.2rem 0 1.6rem 0;

    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        margin: 3.2rem 0 4.2rem 0;
    }
`;
