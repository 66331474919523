import Toast from '@oberoninternal/travelbase-ds/components/feedback/Toast';
import Info from '@oberoninternal/travelbase-ds/components/figure/Info';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import { Flex } from '@rebass/grid';
import gql from 'graphql-tag';
import mergeWith from 'lodash/mergeWith';
import React, { FC, Fragment, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { PRICE_COLUMN_HEADER_HEIGHT, PRICE_COLUMN_ROW_COUNT } from '../../../constants/priceRows';
import { SpecialParticipationsDocument, SpecialParticipationsQuery } from '../../../generated/graphql';
import { useLockoutCreationProps } from '../../../hooks/useLockoutCreationProps';
import { usePricingBag } from '../../../hooks/usePricingBag';
import { useToast } from '../../../hooks/useToast';
import { filterDuplicateFragments } from '../../../utils/filterDuplicateFragments';
import { formatDuration } from '../../../utils/formatDuration';
import { RentalUnitMetaData } from '../../../utils/getRentalUnitMetaData';
import groupTripsByDuration from '../../../utils/groupTripsByDuration';
import { CELL_HEIGHT } from '../../molecules/Cell';
import { InfobarCell } from '../../molecules/Infobar';
import SkeletonCell from '../../molecules/SkeletonCell';
import SpecialsColumn from '../../molecules/SpecialsColumn';
import Pricing from '../../organisms/Pricing';
import ToastErrorBoundary from '../../organisms/ToastErrorBoundary';

export const query = gql`
    query SpecialParticipations($unitSlug: String!, $start: Date!, $end: Date!) {
        rentalUnit(slug: $unitSlug) {
            ...Pricing
            specialParticipations {
                ...SpecialParticipation
            }
        }
    }
`;

interface Props {
    metaData: RentalUnitMetaData;
}

const merge = (prev: SpecialParticipationsQuery, next: SpecialParticipationsQuery): SpecialParticipationsQuery => {
    if (!prev.rentalUnit?.id) {
        return prev;
    }
    return {
        rentalUnit: {
            ...mergeWith({}, prev.rentalUnit, next?.rentalUnit, filterDuplicateFragments),
            specialParticipations: prev.rentalUnit?.specialParticipations.map((participation, i) => {
                const nextTrips = next.rentalUnit?.specialParticipations[i].trips;
                if (!nextTrips) {
                    return participation;
                }
                return { ...participation, trips: [...participation.trips, ...nextTrips] };
            }),
        },
    };
};

const Specials: FC<React.PropsWithChildren<Props>> = ({ metaData: { maxAllotment = 0, showAllotmentLockouts } }) => {
    const bag = usePricingBag<SpecialParticipationsQuery>(SpecialParticipationsDocument, merge);
    const {
        sidebar,
        sidebar: [, dispatch],
        data,
        variables,
        loadedPeriod,
        loadedPeriods,
        getDayEvents,
        invalidate,
    } = bag;

    const rentalUnit = data?.rentalUnit;

    // this hook is used to disable certain fields that could cause errors in the backend
    // and also to conditionally render skeleton cells
    const [optingForId, setOptingForId] = useState<string | null>(null);
    const { formatMessage } = useIntl();
    const lockoutCreationProps = useLockoutCreationProps({
        query: SpecialParticipationsDocument,
        maxAllotment,
        rentalUnitId: rentalUnit?.id,
        variables,
        sidebar,
        showAllotmentLockouts,
    });

    const specialParticipations = useMemo(
        () => data?.rentalUnit?.specialParticipations ?? [],
        [data?.rentalUnit?.specialParticipations]
    );

    const itemCount = useMemo(
        () =>
            specialParticipations.reduce<number>(
                (acc, next) => acc + [...groupTripsByDuration(next.trips).keys()].length + 1,
                0
            ),
        [specialParticipations]
    );

    useToast(
        data && specialParticipations.length === 0 ? (
            <Toast
                variant="info"
                title={formatMessage({
                    defaultMessage: 'Geen arrangementen',
                })}
            >
                <Body variant="small">
                    <FormattedMessage defaultMessage="Er zijn geen arrangementen beschikbaar voor deze verhuureenheid." />
                </Body>
            </Toast>
        ) : null
    );

    return (
        <ToastErrorBoundary>
            <Pricing
                onSidebarClose={() => dispatch({ type: 'close' })}
                bag={bag}
                ColumnComponent={SpecialsColumn}
                maxAllotment={maxAllotment}
                data={{
                    initialVars: variables,
                    loadedPeriods,
                    maxAllotment,
                    loadedPeriod,
                    lockoutCreationProps,
                    allotments: rentalUnit?.allotments ?? [],
                    specialParticipations,
                    variables,
                    optingForId,
                    getDayEvents,
                    showAllotmentLockouts,
                }}
                infobarChildren={
                    <>
                        {!data &&
                            new Array(PRICE_COLUMN_ROW_COUNT)
                                .fill(SkeletonCell)
                                .map((E, i) => <E key={i} i={i} style={{ justifyContent: 'flex-start' }} />)}
                        {specialParticipations.map(specialParticipation => {
                            const {
                                id,
                                trips,
                                special: { name, arrivalFrom, arrivalUntil },
                            } = specialParticipation;

                            const tripKeys = [...groupTripsByDuration(trips).keys()];

                            return (
                                <Fragment key={id}>
                                    <StyledInfobarCell className="header">
                                        <Flex width={1}>
                                            <Label>{name}</Label>
                                            <InfoButton
                                                onClick={() =>
                                                    dispatch({
                                                        type: 'show',
                                                        data: {
                                                            invalidate,
                                                            type: 'SPECIAL',
                                                            specialParticipation,
                                                            variables,
                                                            setOptingForId,
                                                            optingForId,
                                                        },
                                                    })
                                                }
                                            >
                                                <Info />
                                            </InfoButton>
                                        </Flex>
                                        <Body variant="small">
                                            {formatDuration(new Date(arrivalFrom), new Date(arrivalUntil))}
                                        </Body>
                                    </StyledInfobarCell>
                                    {optingForId === id &&
                                        tripKeys.length === 0 &&
                                        new Array(PRICE_COLUMN_ROW_COUNT)
                                            .fill(SkeletonCell)
                                            .map((E, i) => (
                                                <E key={i} i={i} style={{ justifyContent: 'flex-start' }} />
                                            ))}
                                    {tripKeys.map((label, i) => (
                                        <StyledInfobarCell key={i}>
                                            <Label style={{ fontWeight: 'normal' }}>{label}</Label>
                                        </StyledInfobarCell>
                                    ))}
                                </Fragment>
                            );
                        })}
                    </>
                }
                itemHeight={
                    PRICE_COLUMN_HEADER_HEIGHT +
                    (itemCount - 1) * CELL_HEIGHT +
                    80 +
                    (optingForId ? CELL_HEIGHT * PRICE_COLUMN_ROW_COUNT : 0)
                }
            />
        </ToastErrorBoundary>
    );
};

export default Specials;

const InfoButton = styled.button`
    width: 2rem;
    height: 2rem;
    padding: 2px;
    margin-left: 1rem;
    margin-top: -2px;
    pointer-events: all;
`;

const StyledInfobarCell = styled(InfobarCell)`
    &.header {
        > p {
            display: none;
        }
        @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
            position: sticky;
            top: 0;
            background: white;

            > p {
                display: block;
            }
        }
    }

    + .header {
        border-top: 2px solid ${({ theme }) => theme.colors.neutral[20]};
    }
`;
