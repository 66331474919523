import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Cross from '@oberoninternal/travelbase-ds/components/figure/Cross';
import Plus from '@oberoninternal/travelbase-ds/components/figure/Plus';
import { OptionType, SelectInputField } from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { Box, Flex } from '@rebass/grid';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import { RateInput, RateTranslationInput } from '../../generated/graphql';
import { SidebarSeperator } from '../atoms/Seperator';
import SidebarField from '../atoms/SidebarField';

const supportedLocales = ['nl', 'de', 'en'] as const;
type SupportedLocale = (typeof supportedLocales)[number];

// keep the translation objects the same as the supportedLocales array nl, en, de, ...etc
const labelTranslations: Record<string, Record<SupportedLocale, string>> = {
    person: { nl: 'Persoon', en: 'Person', de: 'Person' },
    adult: { nl: 'Volwassene', en: 'Adult', de: 'Erwachsene' },
    junior: { nl: 'Jongere', en: 'Junior', de: 'Jugendliche' },
    child: { nl: 'Kind', en: 'Child', de: 'Kind' },
    baby: { nl: 'Baby', en: 'Baby', de: 'Baby' },
    senior: { nl: 'Senior', en: 'Senior', de: 'Senior' },
    pet: { nl: 'Huisdier', en: 'Pet', de: 'Haustier' },
    group: { nl: 'Groep', en: 'Group', de: 'Gruppe' },
    family: { nl: 'Gezin', en: 'Family', de: 'Familien' },
};

const defaultOptions: OptionType[] = [
    ...Object.entries(labelTranslations).map(([key, value]): OptionType => ({ label: value.nl, value: key })),
    { label: 'Anders...', value: 'other' },
];

const RatesFieldArray: FC<React.PropsWithChildren<unknown>> = () => (
    <FieldArray name="rates">
        {helpers => {
            const rates = helpers.form.values.rates as RateInput[];
            return (
                <>
                    {rates.map((rate, rateIndex) => (
                        <RatesFieldArrayRow key={rateIndex} helpers={helpers} index={rateIndex} rate={rate} />
                    ))}
                    <TextButton
                        onClick={helpers.handlePush({
                            price: '',
                            position: rates.length,
                            translations: [
                                { locale: 'nl', name: '' },
                                { locale: 'de', name: '' },
                                { locale: 'en', name: '' },
                            ],
                        })}
                        style={{ display: 'inline-flex' }}
                        size="tiny"
                    >
                        <Plus />
                        &nbsp;
                        <span>
                            <FormattedMessage defaultMessage="Tarief toevoegen" />
                        </span>
                    </TextButton>
                </>
            );
        }}
    </FieldArray>
);

export default RatesFieldArray;

const getSelectedOption = (rate: RateInput) => {
    if (rate.translations.every(translation => !translation.name)) {
        return null;
    }

    // if all locals translations are equal to one of the default translations we'll select that option
    const selectedOption = defaultOptions.find(option => {
        const defaultTranslations = labelTranslations[option.value];
        if (!labelTranslations[option.value]) {
            return undefined;
        }

        return rate.translations.every(trans => defaultTranslations[trans.locale as SupportedLocale] === trans.name);
    });
    if (selectedOption) {
        return selectedOption;
    }

    return defaultOptions[defaultOptions.length - 1];
};

// convinience function to create a formik array from the input. Args should be in the same order as the supported locales
const createI18nArray = (...args: string[]) => supportedLocales.map((locale, i) => ({ locale, name: args[i] }));

const RatesFieldArrayRow = ({
    rate,
    helpers,
    index,
}: {
    rate: RateInput;
    helpers: FieldArrayRenderProps;
    index: number;
}) => {
    const initialSelectedOption = getSelectedOption(rate);
    const [selectedOption, setSelectedOption] = useState<OptionType | null>(initialSelectedOption);
    const createFieldName = (fieldName: string) => `rates.${index}.${fieldName}`;
    const rateName = rate.translations[0]?.name;
    const { formatMessage } = useIntl();
    const onSelectedOptionChange = (option?: OptionType | null) => {
        if (!option) {
            return;
        }

        setSelectedOption(option);

        const fieldHelpers = helpers.form.getFieldHelpers<RateTranslationInput[]>(createFieldName('translations'));

        if (option.value === 'other') {
            fieldHelpers.setValue(createI18nArray(...supportedLocales.map(() => '')), false);
        } else {
            const labelTranslation = labelTranslations[option.value];
            fieldHelpers.setValue(createI18nArray(...Object.values(labelTranslation)));
        }
    };

    // when we the initial value and current selected option get out of sync we must sync them manually
    // for example when the form resets, this is necessary.
    useEffect(() => {
        if (initialSelectedOption?.value && initialSelectedOption?.value !== selectedOption?.value) {
            setSelectedOption(initialSelectedOption);
        }
    }, [initialSelectedOption, selectedOption]);

    return (
        <>
            <Box>
                <Flex mb={3} alignItems="center" justifyContent="space-between">
                    <Title variant="small">
                        <FormattedMessage defaultMessage="Tarief" />
                        {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                        {rateName && ` (${rateName})`}
                    </Title>

                    <TextButton
                        onClick={() => {
                            if (!window.confirm(`Weet je zeker dat je dit tarief (${rateName}) wilt verwijderen?`)) {
                                return;
                            }
                            helpers.remove(index);
                        }}
                        variant="danger"
                        size="tiny"
                    >
                        <Cross />
                        <span>
                            <FormattedMessage defaultMessage="Verwijderen" />
                        </span>
                    </TextButton>
                </Flex>
                <StyledSidebarField label={formatMessage({ defaultMessage: 'Label' })}>
                    <SelectInputField
                        variant="small"
                        onChange={onSelectedOptionChange}
                        value={selectedOption}
                        placeholder={formatMessage({ defaultMessage: 'Selecteer' })}
                        options={defaultOptions}
                    />
                    {selectedOption?.value === 'other' && (
                        <Box pl={3}>
                            <StyledSidebarField
                                variant="small"
                                label={formatMessage({ defaultMessage: 'Nederlands' })}
                                id="nl"
                            >
                                <TextInputField id="nl" variant="small" name={createFieldName('translations.0.name')} />
                            </StyledSidebarField>
                            <StyledSidebarField
                                variant="small"
                                label={formatMessage({ defaultMessage: 'Duits' })}
                                id="de"
                            >
                                <TextInputField id="de" variant="small" name={createFieldName('translations.1.name')} />
                            </StyledSidebarField>
                            <StyledSidebarField
                                variant="small"
                                label={formatMessage({ defaultMessage: 'Engels' })}
                                id="en"
                            >
                                <TextInputField id="en" variant="small" name={createFieldName('translations.2.name')} />
                            </StyledSidebarField>
                        </Box>
                    )}
                </StyledSidebarField>
            </Box>
            <SidebarField label={formatMessage({ defaultMessage: 'Prijs' })} id="price">
                <TextInputField
                    name={createFieldName('price')}
                    // eslint-disable-next-line formatjs/no-literal-string-in-jsx
                    placeholder="--.--"
                    type="number"
                    step={0.01}
                    variant="small"
                    id="price"
                />
            </SidebarField>
            <SidebarSeperator />
        </>
    );
};

const StyledSidebarField = styled(SidebarField)`
    margin-top: ${({ theme }) => theme.spacing['20_Tiny']};
`;
