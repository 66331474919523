import gql from 'graphql-tag';
import React, { FC } from 'react';
import { NotFoundError } from '../../../../entities/NotFoundError';
import { ActivityParams } from '../../../../entities/ActivityParams';
import {
    ActivityImageFragment,
    useActivityImagesQuery,
    useEditActivityImagesMutation,
} from '../../../../generated/graphql';
import Loading from '../../../organisms/Loading';
import ActivityImagesFormScreen, { activityImageFragment } from './ActivityImagesFormScreen';
import upsertImage from '../../../../utils/upsertImage';
import { MAX_UPLOAD_IMAGES } from '../../../../constants/imageRequirements';
import { OtherImage } from '../../unit/info/ImagesFormScreen';
import { FormattedMessage } from 'react-intl';

export const activityImagesFragment = gql`
    fragment ActivityImages on Activity {
        id
        name
        videoUrl

        mainImage {
            id
            size
            dimensions
            url
            previewUrl
        }
        listImage {
            id
            size
            dimensions
            url
            previewUrl
        }
        images {
            ...ActivityImage
        }
    }
    ${activityImageFragment}
`;

export const activityImagesQuery = gql`
    query ActivityImages($activitySlug: String!) {
        activity(slug: $activitySlug) {
            id
            ...ActivityImages
        }
    }
    ${activityImagesFragment}
`;

export const editActivityImagesMutation = gql`
    mutation EditActivityImages($input: EditActivityImagesInput!) {
        editActivityImages(input: $input) {
            activity {
                ...ActivityImages
            }
        }
    }
    ${activityImagesFragment}
`;

const formContent = {
    header: {
        title: `Foto’s`,
        description: `Hier stel je de foto’s voor je activiteit in. Travelbase zorgt voor de juiste maten (en eventueel uitsnede). We raden je aan om foto's te bewerken voor schermweergave, maar waar mogelijk in de originele resolutie te houden (niet vergroten of verkleinen). Je kunt bestanden uploaden tot maximaal 25 MB per bestand.`,
    },
    mainImage: {
        title: `Hoofdfoto`,
        description: `De hoofdfoto toont Travelbase op de detailpagina van je activiteit (in het groot). De foto is bij voorkeur in een breedbeeld 16:9 (liggende) verhouding. Foto&apos;s in standaard 4:3 verhouding worden door Travelbase uitgesneden naar het midden. We raden als resolutie 1920x1080 of hoger aan. Travelbase accepteert minimaal 1280x720.`,
    },
    listImage: {
        title: `Lijstfoto`,
        description: (
            <FormattedMessage defaultMessage="De lijstfoto van je activiteit toont Travelbase in zoekresultaten en lijsten. De foto is bij voorkeur in een standaard 4:3 (liggende) verhouding. We raden als resolutie 1600x1200 of hoger aan. Travelbase accepteert minimaal 800x600. Let op: de lijstfoto wordt niet getoond op de detailpagina van je activiteit, voeg deze ook toe onderaan bij de detailfoto’s om hem te tonen." />
        ),
    },
    logoImage: {
        title: `Logo`,
        description: `Voeg hier het logo van je activiteit toe. De afbeelding is bij voorkeur in een vierkante verhouding en transparant (PNG). We raden als resolutie 500x500 of hoger aan. Travelbase accepteert minimaal 200x200.`,
    },
    images: {
        title: `Foto’s`,
        description: `Voeg tot maximaal 30 foto’s toe van je activiteit. Je kunt de foto’s in de juiste volgorde slepen. Foto’s mogen liggend of staand zijn. Standaard 4:3 (liggende) foto’s komen het beste tot zijn recht. De verhouding tussen breedte en hoogte van de foto’s mag niet groter zijn dan 1:2. We raden als resolutie 1600x1200/1200x1600 of hoger aan. Travelbase accepteert minimaal 800x600/600x800.`,
    },
    video: {
        title: `Video`,
        description: `Plaats hier de URL van de YouTube of Vimeo video van jouw activiteit. De contactgegevens van je activiteit mogen in de film niet zichtbaar zijn.`,
    },
};

const ActivityImages: FC<React.PropsWithChildren<ActivityParams>> = ({ activitySlug }) => {
    const slug = activitySlug ?? '';
    const { data, loading } = useActivityImagesQuery({ variables: { activitySlug: slug } });
    const [mutate] = useEditActivityImagesMutation();

    if (loading) {
        return <Loading />;
    }

    if (!data || !data.activity) {
        throw new NotFoundError();
    }

    const {
        activity: { id, mainImage, listImage, images, videoUrl },
    } = data;
    const initialValues = {
        listImage: listImage
            ? {
                  dimensions: listImage.dimensions,
                  file: listImage.url,
                  imageId: listImage.id,
                  previewUrl: listImage.previewUrl,
              }
            : null,
        mainImage: mainImage
            ? {
                  dimensions: mainImage.dimensions,
                  file: mainImage.url,
                  imageId: mainImage.id,
                  previewUrl: mainImage.previewUrl,
              }
            : null,
        images: [
            ...images
                .filter((image): image is ActivityImageFragment => !!image)
                .sort((a, b) => a.position - b.position)
                .map(({ url, id: imageId, dimensions, previewUrl }) => ({
                    file: url,
                    id: imageId,
                    dimensions,
                    previewUrl,
                })),
        ] as Array<OtherImage | null>,
        videoUrl: videoUrl ?? null,
    };
    if (initialValues.images.length < MAX_UPLOAD_IMAGES) {
        // `null` will result in an empty upload field
        initialValues.images.push(null);
    }

    return (
        <ActivityImagesFormScreen
            content={formContent}
            handleSubmit={async values => {
                await mutate({
                    variables: {
                        input: {
                            listImageUploadId: upsertImage(values.listImage),
                            mainImageUploadId: upsertImage(values.mainImage),
                            images: values.images
                                .filter((image): image is ActivityImageFragment => !!image)
                                .map(({ id: photoId, previewUrl, dimensions, ...rest }) => ({
                                    imageId: photoId,
                                    ...rest,
                                })),
                            activityId: id,
                            videoUrl: values.videoUrl,
                        },
                    },
                });
            }}
            initialValues={initialValues}
        />
    );
};

export default ActivityImages;
