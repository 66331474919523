import { Field, FieldConfig, FieldProps } from 'formik';
import React, { FC, InputHTMLAttributes, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Flex } from '@rebass/grid';
import { getAssignmentColor } from '../../constants/theme';
import createBorderCss from '../../utils/createBorderCss';
import Body from '../primitive/Body';
import Title from '../primitive/Title';
import Radio from './Radio';

export interface OptionProps extends InputHTMLAttributes<HTMLInputElement> {
    text: string | ReactNode;
    description?: string | ReactNode;
    hint?: string;
    rounded?: boolean;
    price?: ReactNode;
    withMarginTop?: boolean;
}

export interface OptionsProps {
    options: OptionProps[];
    name: string;
    className?: string;
}

const Options: FC<React.PropsWithChildren<OptionsProps>> = ({ options, name, className }) => (
    <Container className={className}>
        {options.map((option, i) => (
            <OptionField key={i} {...option} name={name} />
        ))}
    </Container>
);

const Option: FC<React.PropsWithChildren<OptionProps>> = ({
    description,
    text: title,
    hint,
    rounded,
    price,
    withMarginTop = true,
    ...props
}) => (
    <OptionContainer checked={!!props.checked} htmlFor={props.id} key={props.id} rounded={rounded}>
        <Flex alignItems="center">
            <Radio {...props} />
            <Content withMarginTop={withMarginTop}>
                {hint && <Badge>{hint}</Badge>}
                {typeof title === 'string' ? <Title variant="small">{title}</Title> : title}
                {description && <Body>{description}</Body>}
            </Content>
        </Flex>
        {price && <Price>{price}</Price>}
    </OptionContainer>
);

const OptionField: FC<React.PropsWithChildren<OptionProps & FieldConfig>> = ({ name, ...props }) => (
    <Field name={name}>
        {({ field }: FieldProps) => (
            <Option checked={props.id === field.value} {...props} {...field} value={props.id} />
        )}
    </Field>
);

const Content = styled.div<{ withMarginTop?: boolean }>`
    padding-left: ${({ theme }) => theme.spacing['40_Standard']};
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    > * + * {
        margin-top: ${({ theme, withMarginTop }) => (withMarginTop ? theme.spacing['30_Small'] : '0')};
    }
`;

const OptionContainer = styled.label<{ checked: boolean; rounded?: boolean }>`
    text-align: left;
    width: 100%;
    padding: ${({ theme }) => theme.spacing['40_Standard']} 2.4rem;
    font: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    outline: none;

    ${({ rounded = false }) =>
        !rounded
            ? css`
                  :last-of-type {
                      border-radius: 0 0 0.7rem 0.7rem;
                  }

                  :first-of-type {
                      border-radius: 0.7rem 0.7rem 0 0;
                  }
              `
            : css`
                  border-radius: 0.7rem;
              `}

    --border-color: ${({ theme, checked }) =>
        checked ? getAssignmentColor(theme, theme.colorAssignments.input) : theme.colors.neutral['20']};
    z-index: ${({ checked }) => (checked ? 1 : 'auto')};
    ${createBorderCss('2px')};
`;

const Badge = styled.span`
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    color: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.textAction)};
    letter-spacing: 0.2px;
    line-height: 12px;
`;

const Container = styled.div``;

const Price = styled.div``;

export default Options;

export { Option, OptionField, OptionContainer };
