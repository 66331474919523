/* eslint-disable jsx-a11y/anchor-has-content */
import transparentize from 'polished/lib/color/transparentize';
import React, { FC, HTMLAttributes, AnchorHTMLAttributes } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { UnreachableCaseError } from '../../entities/UnreachableCaseError';

export type SubNavVariant = 'dark' | 'light';

const darkCss = css`
    background: ${({ theme }) => theme.colors.primary['100']};
    > a {
        &.active,
        :hover {
            color: ${({ theme }) => theme.colors.primary['0']};
        }
    }
`;

const lightCss = css`
    background: ${({ theme }) => theme.colors.neutral['0']};
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral['20']};

    > a {
        transition: color 200ms;

        &.active,
        :hover {
            color: ${({ theme }) => theme.colors.primary['100']};
        }
    }
`;

const getCss = (variant: SubNavVariant) => {
    switch (variant) {
        case 'dark':
            return darkCss;
        case 'light':
            return lightCss;
        default:
            throw new UnreachableCaseError(variant);
    }
};

export type InternalLink = NavLinkProps;

export type ExternalLink = AnchorHTMLAttributes<HTMLAnchorElement>;

export interface SubNavProps extends HTMLAttributes<HTMLDivElement> {
    variant?: SubNavVariant;
    links: Array<InternalLink | ExternalLink>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    as?: keyof JSX.IntrinsicElements | React.ComponentType<React.PropsWithChildren<any>>;
}

const SubNav: FC<React.PropsWithChildren<SubNavProps>> = ({ variant = 'light', links, ...props }) => (
    <Container variant={variant} {...props}>
        {links.map(link =>
            'to' in link ? <Link as={NavLink} key={link.to.toString()} {...link} /> : <Link {...link} />
        )}
    </Container>
);

export default SubNav;

const Container = styled.nav<{ variant: SubNavVariant }>`
    height: ${({ theme }) => theme.heights.unitNav}px;
    width: 100%;
    display: flex;
    ${({ variant }) => getCss(variant)}
    position: relative;
`;

const Link = styled.a.attrs(() => ({ activeClassName: 'active' } as NavLinkProps))`
    text-decoration: none;
    color: ${({ theme }) => theme.colors.neutral['40']};
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    position: relative;

    + a {
        margin-left: ${({ theme }) => theme.spacing['60_Large']};
    }

    :hover {
        ::after {
            opacity: 1;
            background: ${({ theme }) => transparentize(0.6, theme.colors.primary['40'])};
        }
    }
    &.active {
        ::after {
            opacity: 1;
            background: ${({ theme }) => theme.colors.primary['30']};
        }
    }

    ::after {
        transition: opacity 200ms;

        opacity: 0;
        content: '';
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        height: 3px;
        animation: in 150ms;
        @keyframes in {
            from {
                transform: scaleX(0);
            }
            to {
                transform: scaleX(1);
            }
        }
    }
`;
