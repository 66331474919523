import * as Yup from 'yup';
import { IntlShape } from 'react-intl';

export const ownerValidation = (intl: IntlShape) =>
    Yup.object().shape({
        ownerProfileVisible: Yup.boolean(),
        ownerName: Yup.string().when(intl.formatMessage({ defaultMessage: 'ownerProfileVisible' }), {
            is: true,
            then: Yup.string().required(intl.formatMessage({ defaultMessage: 'Vul een naam in' })),
        }),
        ownerImage: Yup.string()
            .when('ownerProfileVisible', {
                is: true,
                then: Yup.string()
                    .required(intl.formatMessage({ defaultMessage: 'Een hoofdfoto is verplicht' }))
                    .nullable(),
            })
            .nullable(),
    });
