import { useRouteMatch } from 'react-router-dom';
import { ActivityParams } from '../entities/ActivityParams';

const useActivityParentType = (): 'company' | 'activity' => {
    const match = useRouteMatch<ActivityParams>()!;
    if (match.params.activitySlug) return 'activity';
    return 'company';
};

export default useActivityParentType;
