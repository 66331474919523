import { BrandConfig } from './brandConfig';
import { nlCountryOption } from './countryOptions';

const uitinderegioConfig: BrandConfig = {
    contactUrl: 'https://www.uitinderegio.nl/contact/',
    countryOptions: [nlCountryOption],
    brandName: 'Uit in de regio',
};

export default uitinderegioConfig;
