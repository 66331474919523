import format from 'date-fns/format';
import { Field, FieldConfig, FieldProps, getIn } from 'formik';
import React, { FC } from 'react';
import dateFormat from '../../constants/dateFormat';
import ErrorMessage from '@oberoninternal/travelbase-ds/components/form/ErrorMessage';
import DatePickerInput, { DatePickerInputProps } from '@oberoninternal/travelbase-ds/components/form/DatePickerInput';
import parseDate from '@oberoninternal/travelbase-ds/utils/parseDate';

interface DayPickerInputFieldProps extends Partial<FieldConfig>, DatePickerInputProps {
    onChange?: (date: Date | string | null) => void;
}

const DayPickerInputField: FC<React.PropsWithChildren<DayPickerInputFieldProps>> = ({
    name = 'startDate',
    onChange,
    ...props
}) => (
    <Field name={name}>
        {({ field: { value }, form: { setFieldValue, touched, errors } }: FieldProps) => {
            const error = getIn(touched, name) && getIn(errors, name) ? getIn(errors, name) : undefined;
            return (
                <>
                    <DatePickerInput
                        {...props}
                        variant="day"
                        value={value ? parseDate(value) : null}
                        error={error}
                        setValue={day => {
                            if (onChange) {
                                onChange(day);
                            }
                            setFieldValue(name, day ? format(parseDate(day)!, dateFormat) : '');
                        }}
                    />
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                </>
            );
        }}
    </Field>
);

export default DayPickerInputField;
