import { Box } from '@rebass/grid';
import { Form, Formik, FormikProps } from 'formik';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import Logo from '@oberoninternal/travelbase-ds/components/figure/LogoText';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import { useLocation, useHistory } from 'react-router-dom';
import { usePasswordForgotMutation } from '../../generated/resetGraphql';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import gql from 'graphql-tag';
import { ApolloError } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';

export const mutation = gql`
    mutation passwordForgot($email: String!) {
        sendPasswordResetMail(input: { email: $email }) {
            email
        }
    }
`;

interface Values {
    email: string;
}

const PasswordForgot: FC<React.PropsWithChildren<unknown>> = () => {
    const { formatMessage } = useIntl();
    const passwordForgotSchema = Yup.object().shape({
        // email: Yup.string().email('Vul een geldig e-mailadres in').required('Email adres is verplicht'),
        email: Yup.string()
            .email(formatMessage({ defaultMessage: 'Vul een geldig e-mailadres in' }))
            .required(formatMessage({ defaultMessage: 'E-mailadres is verplicht' })),
    });
    const [mutate, { called, loading, error, data }] = usePasswordForgotMutation({
        context: {
            reset: true,
            ignoreError: true,
        },
    });
    const { state } = useLocation<{ email: string } | undefined>();
    const history = useHistory();
    return (
        <Container>
            <Box width={[7 / 8, null, '48rem']} m="auto">
                <Box mb={2}>
                    <Logo width={250} />
                </Box>
                <>
                    {called && !loading && !error ? (
                        <>
                            <p>
                                <FormattedMessage
                                    defaultMessage="Er is zojuist een e-mail verstuurd naar {email}. Controleer je e-mail en volg de link om je wachtwoord te resetten."
                                    values={{ email: data?.sendPasswordResetMail?.email }}
                                />
                            </p>
                        </>
                    ) : (
                        <>
                            <Box mt={5}>
                                <Title variant="regular">
                                    <FormattedMessage defaultMessage="Wachtwoord resetten" />
                                </Title>
                                {!state?.email ? (
                                    <p>
                                        <FormattedMessage defaultMessage="Vul hier je e‑mailadres in en we helpen je verder." />
                                    </p>
                                ) : (
                                    <p>
                                        <FormattedMessage defaultMessage="Je gaat nu het wachtwoord resetten van je e-mailadres:" />
                                    </p>
                                )}
                            </Box>
                            <Formik<Values>
                                validationSchema={passwordForgotSchema}
                                initialValues={{ email: state?.email ?? '' }}
                                onSubmit={async ({ email }, { setSubmitting }) => {
                                    try {
                                        await mutate({
                                            variables: {
                                                email,
                                            },
                                        });
                                    } finally {
                                        setSubmitting(false);
                                    }
                                }}
                            >
                                {bag => <ResetForm {...bag} mutationError={error} />}
                            </Formik>
                            {state?.email && (
                                <p>
                                    <FormattedMessage
                                        defaultMessage="Per ongeluk hier gekomen? Klik {here}hier{here} om weer in te loggen"
                                        values={{
                                            here: chunks => (
                                                <TextButton onClick={() => history.push('/login')}>{chunks}</TextButton>
                                            ),
                                        }}
                                    />
                                </p>
                            )}
                        </>
                    )}
                </>
            </Box>
        </Container>
    );
};

const ResetForm: FC<React.PropsWithChildren<FormikProps<Values> & { mutationError?: ApolloError }>> = ({
    isSubmitting,
    setFieldError,
    mutationError,
    errors,
    initialValues,
}) => {
    useEffect(() => {
        if (!errors.email && mutationError) {
            const graphqlError = mutationError?.graphQLErrors[0];

            setFieldError('email', graphqlError ? graphqlError.message : mutationError.message);
        }
    }, [mutationError, errors.email, setFieldError]);
    const { formatMessage } = useIntl();
    return (
        <Form>
            <Box mb={4}>
                <TextInputField
                    name="email"
                    placeholder={formatMessage({ defaultMessage: 'e-mailadres' })}
                    disabled={isSubmitting || !!initialValues.email}
                />
            </Box>
            <ResetMailButton submitting={isSubmitting} disabled={isSubmitting} type="submit">
                <FormattedMessage defaultMessage="Ga verder" />
            </ResetMailButton>
        </Form>
    );
};

export default PasswordForgot;

const Container = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
`;

const ResetMailButton = styled(Button)`
    margin-top: 2rem;
    width: 100%;
`;
