/* eslint-disable formatjs/no-literal-string-in-jsx */
import React from 'react';
import Helmet from 'react-helmet';
import { hot } from 'react-hot-loader/root';
import { Route, useLocation, withRouter } from 'react-router-dom';
import { useGA } from '../hooks/useGA';
import { matchRoute } from '../utils/matchRoute';
import Toasts from './atoms/Toasts';
import ErrorBoundary from './organisms/ErrorBoundary';
import Login from './pages/Login';
import Partner from './pages/Partner';
import PartnerPicker from './pages/partner/PartnerPicker';
import PasswordForgot from './pages/PasswordForgot';
import PasswordReset from './pages/PasswordReset';
import PasswordSet from './pages/PasswordSet';
import Registration from './pages/Registration';
import CampercontactRegistration from './pages/CampercontactRegistration';
import NotFound from './pages/NotFound';
import '@oberoninternal/travelbase-ds/dsStyles.css';
import { LocalizedRouter } from './atoms/LocalizedRouter';
import { LocalizedSwitch } from './atoms/LocalizedSwitch';
import { useIntl } from 'react-intl';
import { Route as RouteType } from '../entities/Route';

const App: React.FC = () => {
    useGA();
    const location = useLocation();
    const route = matchRoute(location.pathname);
    return (
        <LocalizedRouter>
            <Helmet>
                <PageTitle route={route} />
                <meta name="description" content="Accommodaties beheren" />
            </Helmet>
            <ErrorBoundary>
                <LocalizedSwitch>
                    <Route path="/login" component={Login} />
                    <Route path="/forgot" component={PasswordForgot} />
                    {process.env.REACT_APP_BRAND === 'campercontact' ? (
                        <Route path="/registration" component={CampercontactRegistration} />
                    ) : (
                        <Route path="/registration" component={Registration} />
                    )}
                    <Route path="/reset-password/:token" component={PasswordReset} />
                    <Route path="/set-password/:token" component={PasswordSet} />
                    <Route path="/partner/:partnerId" component={Partner} />
                    <Route exact path={['/', '/partner']} component={PartnerPicker} />
                    <Route component={NotFound} />
                </LocalizedSwitch>
            </ErrorBoundary>

            <Toasts />
        </LocalizedRouter>
    );
};

const PageTitle: React.FC<{
    route?: RouteType | null;
}> = ({ route }) => {
    const { formatMessage } = useIntl();

    return (
        <title>
            {route ? `${formatMessage(route.title)} - Travelbase Partner Applicatie` : 'Travelbase Partner Applicatie'}
        </title>
    );
};
export default hot(withRouter(App));
