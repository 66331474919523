import React, { FC, useEffect, useState } from 'react';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import LabelBox from '../molecules/LabelBox';
import { StepperField } from '@oberoninternal/travelbase-ds/components/form/Stepper';
import styled from 'styled-components/macro';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import gql from 'graphql-tag';
import { Box, Flex } from '@rebass/grid';
import FieldSet from '../atoms/FieldSet';
import Logo from '@oberoninternal/travelbase-ds/components/figure/LogoText';
import { generatePath } from 'react-router-dom';

import {
    AccommodationRegistrationInput,
    AccommodationTypeEnum,
    ContactInfoInput,
    RentalUnitRegistrationInput,
    RentalUnitTypeEnum,
    useRegisterNewPartnerMutation,
} from '../../generated/graphql';
import SelectInput from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import { Container as NavigationContainer, LogoLink } from '../organisms/Navigation';
import FieldHeading from '../molecules/FieldHeading';
import brandConfig from '../../constants/brandConfig';
import ArrayTextInput from '../molecules/ArrayTextInput';
import { FieldArray, Form, Formik, FormikProps, getIn, useFormikContext } from 'formik';
import { ApolloError } from '@apollo/client';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import ContentWrapper from '../atoms/ContentWrapper';
import FieldSetHint from '../atoms/FieldSetHint';
import { Seperator } from '../atoms/Seperator';
import { FormattedMessage, useIntl } from 'react-intl';
import registrationValidation from '../../utils/validations/registrationValidation';

type RegistrationAccommodation = Omit<AccommodationRegistrationInput, 'type'> & {
    type: AccommodationTypeEnum | 'BOAT' | 'HOME';
};
interface Values {
    accommodations: Array<RegistrationAccommodation>;
    bic?: string;
    comment?: string;
    contact: ContactInfoInput;
    iban?: string;
    ibanName?: string;
    name: string;
    pmsName?: string;
}

interface ArrayRentalInputProps {
    name: string;
    addDescription: string;
}

export const fragment = gql`
    fragment PartnerRegistrationDetails on PartnerRegistration {
        id
    }
`;

export const registerNewPartnerMutation = gql`
    mutation RegisterNewPartner($input: RegisterNewPartnerInput!) {
        registerNewPartner(input: $input) {
            partnerRegistration {
                ...PartnerRegistrationDetails
            }
        }
    }
`;

const accommodationTypeOptions = [
    { value: AccommodationTypeEnum.Hotel, label: 'Hotel' },
    { value: 'HOME', label: 'Vakantiewoning', rentalUnitsType: RentalUnitTypeEnum.Home },
    { value: AccommodationTypeEnum.BedAndBreakfast, label: 'Bed & Breakfast' },
    { value: AccommodationTypeEnum.Camping, label: 'Camping' },
    { value: AccommodationTypeEnum.BungalowPark, label: 'Bungalowpark' },
    { value: 'BOAT', label: 'Motor- en zeiljacht', rentalUnitsType: RentalUnitTypeEnum.Boat },
];

const rentalUnitTypesOptions = [
    { value: RentalUnitTypeEnum.Room, label: 'Kamer' },
    { value: RentalUnitTypeEnum.Home, label: 'Gehele woning/bungalow/chalet' },
    { value: RentalUnitTypeEnum.CampingPitch, label: 'Kampeerplaats' },
    { value: RentalUnitTypeEnum.Tent, label: '(Ingerichte) tent' },
    { value: RentalUnitTypeEnum.Bed, label: 'Los bed in gedeelde ruimte' },
    { value: RentalUnitTypeEnum.CamperSpot, label: 'Camperplaats' },
    { value: RentalUnitTypeEnum.Boat, label: 'Motor- en zeiljacht' },
];

const brandName = brandConfig.brandName ?? 'ons';
const { countryOptions } = brandConfig;

/**
 * This method is used to alter the accommodations object, and only used when
 * the user selects "Vakantiewoning" or "Boot" as the accommodation type.
 * The following logic takes place:
 *
 * - LOCATION is set as accommodation type
 * - Rental units is send as an object, where maxAllotment is always 1, the name is
 *   equal to the Accommodation name and the type is ROOM or BOAT depending on the
 *   accommodation type
 *
 * @param accommodations
 * @returns Modified accommodations object
 */
const getModifiedAccommodations = (
    accommodations: Array<RegistrationAccommodation>
): AccommodationRegistrationInput[] => [
    ...accommodations.map(accommodation => ({
        ...accommodation,
        type: AccommodationTypeEnum.Location,
        rentalUnits: [
            {
                ...accommodation.rentalUnits[0],
                maxAllotment: 1,
                name: accommodation.name,
                type:
                    accommodationTypeOptions.find(accoOption => accoOption.value === accommodation.type)
                        ?.rentalUnitsType === RentalUnitTypeEnum.Boat
                        ? RentalUnitTypeEnum.Boat
                        : accommodationTypeOptions.find(accoOption => accoOption.value === accommodation.type)
                              ?.rentalUnitsType ?? RentalUnitTypeEnum.Room,
            },
        ],
    })),
];

const Registration: FC<React.PropsWithChildren<unknown>> = () => {
    const [mutate, { error }] = useRegisterNewPartnerMutation({});
    const intl = useIntl();
    const [submitSuccessful, setSubmitSuccessful] = useState(false);

    const initialValues = {
        accommodations: [
            {
                address: {
                    city: '',
                    number: '',
                    postalCode: '',
                    street: '',
                    countryCode: countryOptions.length === 1 ? countryOptions[0].value : '',
                },
                name: '',
                rentalUnits: [
                    {
                        maxAllotment: 1,
                        name: '',
                        type: RentalUnitTypeEnum.Room,
                    },
                ],
                publicUrl: 'https://',
                starRating: 1,
                type: AccommodationTypeEnum.Hotel,
            },
        ],
        bic: '',
        comment: '',
        contact: {
            contactName: '',
            emails: [''],
            phoneNumbers: [''],
        },
        iban: '',
        ibanName: '',
        name: '',
        pmsName: '',
    };

    return (
        <>
            <NavigationContainer>
                <LogoLink to={generatePath(`${process.env.PUBLIC_URL}/login`)}>
                    <Logo variant="dark" />
                </LogoLink>
            </NavigationContainer>
            {submitSuccessful ? (
                <ContentWrapper>
                    <Box mt={5}>
                        <Title variant="regular">
                            <FormattedMessage defaultMessage="Bedankt voor je aanmelding!" />
                        </Title>
                        <p>
                            <FormattedMessage defaultMessage="Onze medewerkers gaan de ingevoerde gegevens zo snel mogelijk controleren en verwerken. Je ontvangt binnen enkele werkdagen een reactie van ons." />
                        </p>
                    </Box>
                </ContentWrapper>
            ) : (
                <ContentWrapper variant="info">
                    <Box mt={5}>
                        <Title variant="regular">
                            <FormattedMessage defaultMessage="Aanmelden als partner" />
                        </Title>
                        <p>
                            <FormattedMessage
                                defaultMessage="Via dit formulier kun je je aanmelden als partner zodat jouw accommodatie op de website van {brandName} bekeken en geboekt kan worden. We vragen je om hieronder enige basisinformatie in te vullen. Zodra jouw aanmelding verwerkt en goedgekeurd is, kan je eenvoudig zelf via onze partnerapplicatie aanvullende informatie, afbeeldingen, prijzen, etc. invoeren. Dit formulier is enkel bedoeld voor nieuwe partners. Heb je al toegang tot de partnerapplicatie en wil je een nieuwe accommodatie toevoegen? Neem dan contact op met {brandName}."
                                values={{ brandName }}
                            />
                        </p>

                        <Formik<Values>
                            validationSchema={registrationValidation(intl)}
                            initialValues={initialValues}
                            onSubmit={async (
                                { accommodations, bic, comment, contact, iban, ibanName, name, pmsName },
                                { setSubmitting }
                            ) => {
                                // Adjust accommodations input if accommodation type is "Vakantiewoning" or "Boot"
                                const accommodationsRegistration = accommodations.some(
                                    accommodation => accommodation.type === 'BOAT' || accommodation.type === 'HOME'
                                )
                                    ? getModifiedAccommodations(accommodations)
                                    : (accommodations as AccommodationRegistrationInput[]);

                                // Only pass star rating if accommodations type is Hotel
                                accommodations
                                    .filter(accommodation => accommodation.type !== AccommodationTypeEnum.Hotel)
                                    .forEach(accommodation => {
                                        // eslint-disable-next-line no-param-reassign
                                        delete accommodation.starRating;
                                    });

                                try {
                                    await mutate({
                                        variables: {
                                            input: {
                                                accommodations: accommodationsRegistration,
                                                bic,
                                                comment,
                                                contact,
                                                iban,
                                                ibanName,
                                                name,
                                                pmsName,
                                            },
                                        },
                                    });
                                } finally {
                                    if (!error) {
                                        setSubmitSuccessful(true);
                                    }
                                    setSubmitting(false);
                                }
                            }}
                        >
                            {bag => <RegistrationForm {...bag} mutationError={error} />}
                        </Formik>
                    </Box>
                </ContentWrapper>
            )}
        </>
    );
};

const RegistrationForm: FC<React.PropsWithChildren<FormikProps<Values> & { mutationError?: ApolloError }>> = ({
    isSubmitting,
    setFieldError,
    mutationError,
}) => {
    useEffect(() => {
        if (mutationError) {
            const graphqlError = mutationError?.graphQLErrors[0];

            setFieldError('comment', graphqlError ? graphqlError.message : mutationError.message);
        }
    }, [mutationError, setFieldError]);

    return (
        <Form>
            <PartnerSection />
            <AccommodationSection />
            <FinancialSection />
            <ExtraInformation />
            <RegistrationButton submitting={isSubmitting} disabled={isSubmitting} type="submit">
                <FormattedMessage defaultMessage="Aanmelden" />
            </RegistrationButton>
        </Form>
    );
};

const Accommodation: FC<{
    index: number;
}> = ({ index }) => {
    const { values } = useFormikContext<Values>();
    const { formatMessage } = useIntl();

    return (
        <>
            <StyledAccommodationContainer key={index}>
                <FieldSet>
                    <LabelBox>
                        <Label htmlFor={`accommodations.${index}.type`}>
                            <FormattedMessage defaultMessage="Type" />
                        </Label>
                    </LabelBox>
                    <Box width={1}>
                        <SelectInput
                            id={`accommodations.${index}.type`}
                            name={`accommodations.${index}.type`}
                            placeholder={formatMessage({ defaultMessage: 'Om wat voor accommodatie gaat het?' })}
                            options={accommodationTypeOptions}
                        />
                    </Box>
                </FieldSet>
                <FieldSet>
                    <LabelBox>
                        <Label htmlFor={`accommodations.${index}.name`}>
                            <FormattedMessage defaultMessage="Accomodatienaam" />
                        </Label>
                    </LabelBox>
                    <Box width={1}>
                        <TextInputField id={`accommodations.${index}.name`} name={`accommodations.${index}.name`} />
                    </Box>
                </FieldSet>

                {values.accommodations[index].type === AccommodationTypeEnum.Hotel && (
                    <Box mb={4}>
                        <FieldSet>
                            <LabelBox>
                                <Label>
                                    <FormattedMessage defaultMessage="Sterren" />
                                </Label>
                            </LabelBox>
                            <StepperField
                                minimum={1}
                                maximum={5}
                                name={`
                                    accommodations.${index}.starRating`}
                            />
                        </FieldSet>
                    </Box>
                )}

                <FieldSet>
                    <LabelBox>
                        <Label htmlFor={`accommodations.${index}.address.street`}>
                            <FormattedMessage defaultMessage="Adres (straat en huisnummer)" />
                        </Label>
                    </LabelBox>
                    <Flex width={1} flexDirection={['column', 'row']}>
                        <Box flex={1} pr={[null, 4]}>
                            <TextInputField
                                id={`accommodations.${index}.address.street`}
                                placeholder={formatMessage({ defaultMessage: 'Adres' })}
                                name={`accommodations.${index}.address.street`}
                            />
                        </Box>
                        <Box mt={[4, 0]} width={[1, '20rem']}>
                            <TextInputField
                                maxLength={20}
                                placeholder={formatMessage({ defaultMessage: 'Huisnummer' })}
                                name={`accommodations.${index}.address.number`}
                            />
                        </Box>
                    </Flex>
                </FieldSet>
                <FieldSet>
                    <LabelBox>
                        <Label htmlFor={`accommodations.${index}.address.postalCode`}>
                            <FormattedMessage defaultMessage="Postcode en plaats" />
                        </Label>
                    </LabelBox>
                    <Flex width={1} flexDirection={['column', 'row']}>
                        <Box width={[1, '20rem']}>
                            <TextInputField
                                maxLength={20}
                                id={`accommodations.${index}.address.postalCode`}
                                placeholder={formatMessage({ defaultMessage: 'Postcode' })}
                                name={`accommodations.${index}.address.postalCode`}
                            />
                        </Box>
                        <Box flex={1} mt={[4, 0]} pl={[null, 4]}>
                            <TextInputField
                                id={`accommodations.${index}.address.city`}
                                placeholder={formatMessage({ defaultMessage: 'Plaats' })}
                                name={`accommodations.${index}.address.city`}
                            />
                        </Box>
                    </Flex>
                </FieldSet>
                {countryOptions.length > 1 && (
                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor={`accommodations.${index}.address.countryCode`}>
                                <FormattedMessage defaultMessage="Land" />
                            </Label>
                        </LabelBox>
                        <Box width={[1, 0.5]} style={{ maxWidth: '40rem' }}>
                            <SelectInput
                                id={`accommodations.${index}.address.countryCode`}
                                name={`accommodations.${index}.address.countryCode`}
                                isSearchable
                                placeholder={formatMessage({ defaultMessage: 'Selecteer land' })}
                                options={countryOptions}
                            />
                        </Box>
                    </FieldSet>
                )}
                <FieldSet>
                    <LabelBox>
                        <Label htmlFor={`accommodations.${index}.publicUrl`}>
                            <FormattedMessage defaultMessage="Publieke URL" />
                        </Label>
                        <FieldSetHint>
                            <FormattedMessage defaultMessage="Graag een URL invoeren waar informatie specifiek over deze accommodatie te vinden is. (https://www.travelbase.nl/)" />
                        </FieldSetHint>
                    </LabelBox>
                    <Box width={1}>
                        <TextInputField
                            id={`accommodations.${index}.publicUrl`}
                            name={`accommodations.${index}.publicUrl`}
                            placeholder={formatMessage({
                                defaultMessage:
                                    'Graag een URL invoeren waar informatie specifiek over deze accommodatie te vinden is.',
                            })}
                        />
                    </Box>
                </FieldSet>
                {values.accommodations[index].type !== 'BOAT' && values.accommodations[index].type !== 'HOME' && (
                    <RentalUnitsSection index={index} />
                )}
            </StyledAccommodationContainer>
        </>
    );
};

const AccommodationSection: FC = () => {
    const { values } = useFormikContext<Values>();
    const { formatMessage } = useIntl();
    return (
        <FieldArray name="accommodations">
            {({ push, remove }) => {
                const array = getIn(values, 'accommodations');

                return (
                    <>
                        <FieldHeading
                            title={formatMessage({
                                defaultMessage: 'Accommodatie',
                            })}
                        >
                            <FormattedMessage
                                defaultMessage="Gegevens over de accommodatie die je via {brandName} wil gaan verhuren."
                                values={{ brandName }}
                            />
                        </FieldHeading>
                        <Accommodation index={0} />
                        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                        {array.slice(1).map((_: any, i: any) => {
                            const index = i + 1;
                            return (
                                <React.Fragment key={index}>
                                    <Accommodation index={index} />

                                    <Button
                                        style={{
                                            marginBottom: '8px',
                                        }}
                                        variant="outline"
                                        onClick={() => remove(index)}
                                    >
                                        <FormattedMessage defaultMessage="Accommodatie verwijderen" />
                                    </Button>
                                </React.Fragment>
                            );
                        })}

                        <Button
                            variant="outline"
                            onClick={() =>
                                push({
                                    address: {
                                        city: '',
                                        number: '',
                                        postalCode: '',
                                        street: '',
                                        countryCode: countryOptions.length === 1 ? countryOptions[0].value : '',
                                    },
                                    name: '',
                                    rentalUnits: [
                                        {
                                            maxAllotment: 1,
                                            name: '',
                                            type: RentalUnitTypeEnum.Room,
                                        },
                                    ],
                                    publicUrl: 'https://',
                                    starRating: 1,
                                    type: AccommodationTypeEnum.Hotel,
                                })
                            }
                        >
                            {array.length === 0 ? (
                                <FormattedMessage defaultMessage="Accommodatie toevoegen" />
                            ) : (
                                <FormattedMessage defaultMessage="Nog een accommodatie toevoegen" />
                            )}
                        </Button>
                    </>
                );
            }}
        </FieldArray>
    );
};

const PartnerSection = () => {
    const { formatMessage } = useIntl();
    return (
        <>
            <FieldHeading
                title={formatMessage({
                    defaultMessage: 'Partner',
                })}
            >
                <FormattedMessage defaultMessage="Gegevens over de organisatie of persoon die je wil registreren als partner." />
            </FieldHeading>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="name">
                        <FormattedMessage defaultMessage="Bedrijfsnaam" />
                    </Label>
                    <FieldSetHint>
                        <FormattedMessage defaultMessage="Registreer je je als particulier? Vul dan je eigen naam in." />
                    </FieldSetHint>
                </LabelBox>
                <Box width={1}>
                    <TextInputField id="name" name="name" />
                </Box>
            </FieldSet>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="contact.contactName">
                        <FormattedMessage defaultMessage="Naam contactpersoon" />
                    </Label>
                </LabelBox>
                <Box width={1}>
                    <TextInputField
                        id="contact.contactName"
                        placeholder={formatMessage({ defaultMessage: 'Voor- en achternaam.' })}
                        name="contact.contactName"
                    />
                </Box>
            </FieldSet>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="contact.phoneNumbers">
                        <FormattedMessage defaultMessage="Telefoonnummer" />
                    </Label>
                    <FieldSetHint>
                        <FormattedMessage defaultMessage="Gebruik internationale notatie (+31612345678)." />
                    </FieldSetHint>
                </LabelBox>
                <Box width={1}>
                    <ArrayTextInput
                        placeholder=""
                        name="contact.phoneNumbers"
                        addDescription={formatMessage({ defaultMessage: 'Extra telefoonnummer' })}
                    />
                </Box>
            </FieldSet>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="contact.emails">
                        <FormattedMessage defaultMessage="E-mailadres" />
                    </Label>
                </LabelBox>
                <Box width={1}>
                    <ArrayTextInput
                        name="contact.emails"
                        placeholder={formatMessage({ defaultMessage: 'E-mail' })}
                        addDescription={formatMessage({ defaultMessage: 'Extra e-mailadres' })}
                    />
                </Box>
            </FieldSet>
        </>
    );
};

const RentalUnitsSection: FC<{ index: number }> = ({ index }) => {
    const { formatMessage } = useIntl();
    return (
        <>
            <FieldHeading
                title={formatMessage({
                    defaultMessage: 'Verblijf',
                })}
            >
                <FormattedMessage defaultMessage="Het verblijf is het type object dat je verhuurt, bijvoorbeeld een kamertype in een hotel" />
            </FieldHeading>

            <ArrayRentalInput
                name={`accommodations.${index}.rentalUnits`}
                addDescription={formatMessage({ defaultMessage: 'Nog een verblijf toevoegen' })}
            />
        </>
    );
};

const ArrayRentalInput: FC<React.PropsWithChildren<ArrayRentalInputProps>> = ({ name, addDescription }) => {
    const { formatMessage } = useIntl();
    return (
        <FieldArray name={name}>
            {({ push, remove, form: { values } }) => {
                const array: RentalUnitRegistrationInput[] = getIn(values, name);

                return (
                    <ArrayRentalInputContainer>
                        <FieldSet>
                            <LabelBox>
                                <Label htmlFor={`${name}.0.type`}>
                                    <FormattedMessage defaultMessage="Soort" />
                                </Label>
                            </LabelBox>
                            <Box width={1}>
                                <SelectInput
                                    name={`${name}.0.type`}
                                    placeholder={formatMessage({ defaultMessage: 'Wat voor object is dit?' })}
                                    options={rentalUnitTypesOptions}
                                />
                            </Box>
                        </FieldSet>
                        <FieldSet>
                            <LabelBox>
                                <Label htmlFor={`${name}.0.name`}>
                                    <FormattedMessage defaultMessage="Naam" />
                                </Label>
                                <FieldSetHint>
                                    <FormattedMessage defaultMessage="De naam van dit specifieke object/type, bijvoorbeeld: Tweepersoons kamer met zeezicht." />
                                </FieldSetHint>
                            </LabelBox>
                            <Box width={1}>
                                <TextInputField
                                    id={`${name}.0.name`}
                                    placeholder={formatMessage({
                                        defaultMessage: 'De naam van dit specifieke object/type.',
                                    })}
                                    name={`${name}.0.name`}
                                />
                            </Box>
                        </FieldSet>
                        <FieldSet>
                            <LabelBox>
                                <Label>
                                    <FormattedMessage defaultMessage="Maximaal beschikbaar" />
                                </Label>
                            </LabelBox>
                            <StepperField editable minimum={1} name={`${name}.0.maxAllotment`} />
                        </FieldSet>

                        {array?.slice(1)?.map((_, i) => {
                            const index = i + 1;
                            return (
                                <ArrayRentalInputContainer key={`${name}-${index}`} style={{ position: 'relative' }}>
                                    <Seperator />
                                    <FieldSet>
                                        <LabelBox>
                                            <Label htmlFor={`${name}.${index}.type`} />
                                        </LabelBox>
                                        <Box width={1}>
                                            <SelectInput
                                                name={`${name}.${index}.type`}
                                                placeholder={formatMessage({
                                                    defaultMessage: 'Wat voor object is dit?',
                                                })}
                                                options={rentalUnitTypesOptions}
                                            />
                                        </Box>
                                    </FieldSet>
                                    <FieldSet>
                                        <LabelBox>
                                            <Label htmlFor={`${name}.${index}.name`}>
                                                <FormattedMessage defaultMessage="Naam" />
                                            </Label>
                                        </LabelBox>
                                        <Box width={1}>
                                            <TextInputField
                                                id={`${name}.${index}.name`}
                                                placeholder={formatMessage({
                                                    defaultMessage: 'De naam van dit specifieke object/type.',
                                                })}
                                                name={`${name}.${index}.name`}
                                            />
                                        </Box>
                                    </FieldSet>
                                    <FieldSet>
                                        <LabelBox>
                                            <Label htmlFor={`${name}.${index}.maxAllotment`}>
                                                <FormattedMessage defaultMessage="Maximaal beschikbaar" />
                                            </Label>
                                        </LabelBox>
                                        <StepperField editable minimum={1} name={`${name}.${index}.maxAllotment`} />
                                    </FieldSet>

                                    <Button variant="outline" onClick={() => remove(index)}>
                                        <FormattedMessage defaultMessage="Verblijf verwijderen" />
                                    </Button>
                                </ArrayRentalInputContainer>
                            );
                        })}
                        <Flex>
                            <Button
                                variant="outline"
                                onClick={() => push({ type: RentalUnitTypeEnum.Room, name: '', maxAllotment: 1 })}
                                disabled={array?.length === 0}
                            >
                                {addDescription}
                            </Button>
                        </Flex>
                    </ArrayRentalInputContainer>
                );
            }}
        </FieldArray>
    );
};

const FinancialSection = () => {
    const { formatMessage } = useIntl();
    return (
        <>
            <FieldHeading title={formatMessage({ defaultMessage: 'Financiele gegevens' })}>
                <FormattedMessage defaultMessage="Voor het afhandelen van uitbetalingen hebben we bankgegevens nodig." />
            </FieldHeading>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="iban">
                        <FormattedMessage defaultMessage="IBAN" />
                    </Label>
                </LabelBox>
                <Box width={1}>
                    <TextInputField id="iban" name="iban" />
                </Box>
            </FieldSet>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="ibanName">
                        <FormattedMessage defaultMessage="Tenaamstelling rekening" />
                    </Label>
                </LabelBox>
                <Box width={1}>
                    <TextInputField id="ibanName" name="ibanName" />
                </Box>
            </FieldSet>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="bic">
                        <FormattedMessage defaultMessage="BIC" />
                    </Label>
                    <FieldSetHint>
                        <FormattedMessage defaultMessage="Invullen als rekening niet Nederlands is." />
                    </FieldSetHint>
                </LabelBox>
                <Box width={1}>
                    <TextInputField id="bic" name="bic" />
                </Box>
            </FieldSet>
        </>
    );
};

const ExtraInformation = () => {
    const { formatMessage } = useIntl();
    return (
        <>
            <FieldHeading title={formatMessage({ defaultMessage: 'Extra informatie' })} />
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="pmsName">
                        <FormattedMessage defaultMessage="Naam PMS" />
                    </Label>
                    <FieldSetHint>
                        <FormattedMessage defaultMessage="Welk softwaresysteem gebruik je om je prijzen/beschikbaarheid/etc. in te beheren?" />
                    </FieldSetHint>
                </LabelBox>
                <Box width={1}>
                    <TextInputField id="pmsName" name="pmsName" />
                </Box>
            </FieldSet>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="comment">
                        <FormattedMessage defaultMessage="Opmerkingen" />
                    </Label>
                    <FieldSetHint>
                        <FormattedMessage defaultMessage="Vul hier eventuele aanvullende informatie in die relevant is voor deze registratie." />
                    </FieldSetHint>
                </LabelBox>
                <Box width={1}>
                    <TextInputField id="comment" name="comment" type="textarea" />
                </Box>
            </FieldSet>
        </>
    );
};

const RegistrationButton = styled(Button)`
    margin-top: 2rem;
    width: 100%;
`;

const ArrayRentalInputContainer = styled(Box)`
    > * + * {
        margin-top: 16px;
    }
`;

const StyledAccommodationContainer = styled(Box)`
    position: relative;
    background: ${({ theme }) => theme.colors.neutral[5]};
    padding: 32px;
    margin-bottom: 32px;
    border-radius: ${({ theme }) => theme.radius.button};
`;

export default Registration;
