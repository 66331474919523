import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import useMutationEvent from './hooks/useMutationEvent';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { OperationVariables } from '@apollo/client/core';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { QueryFunctionOptions } from '@apollo/client/react/types/types';

export * from '@apollo/client';

export interface SuspenseQueryHookOptions<TData = never, TVariables = OperationVariables>
    extends QueryFunctionOptions<TData, TVariables> {
    query?: DocumentNode | TypedDocumentNode<TData, TVariables>;
}

/**
 * This file is mostly a re-export of the apollo hooks, with the minor difference that it throws
 * an error if any of the hooks have errors in their results.
 * Also it sends GA events.
 */

export const useQuery = <TData, TVariables>(
    query: DocumentNode,
    options?: Apollo.QueryHookOptions<TData, TVariables>
) => {
    const history = useHistory();
    const result = Apollo.useQuery<TData, TVariables>(query, options);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (result.data && 'viewer' in (result.data as any) && !(result.data as any).viewer) {
        history.push('/login');
    }

    if (result.error) {
        throw result.error;
    }

    return result;
};

export const useSuspenseQuery = <TData, TVariables>(
    query: DocumentNode,
    options?: Apollo.QueryHookOptions<TData, TVariables>
) => {
    const history = useHistory();
    const result = Apollo.useQuery<TData, TVariables>(query, options);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (result.data && 'viewer' in (result.data as any) && !(result.data as any).viewer) {
        history.push('/login');
    }

    if (result.error) {
        throw result.error;
    }

    return result;
};

export const useMutation = <TData, TVariables>(
    query: DocumentNode,
    options?: Apollo.MutationHookOptions<TData, TVariables>
) => {
    const [mutationFunction, result] = Apollo.useMutation<TData, TVariables>(query, options);

    // we patch the real mutate func with our own, so we can extract the variables from it, if it gets called.
    const [variables, setVariables] = useState<TVariables | null>(null);

    useMutationEvent({ result, variables, query });

    const patched = (funcOptions?: Apollo.MutationFunctionOptions<TData, TVariables>) => {
        setVariables(funcOptions?.variables ?? null);
        return mutationFunction(funcOptions);
    };

    if (!options?.context?.ignoreError && result.error) {
        result.error.name = 'MutationError';
        throw result.error;
    }

    return [patched, result] as const;
};
