import { Box } from '@rebass/grid';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';

interface Props {
    title: ReactNode;
}

const SidebarIntro: FC<React.PropsWithChildren<Props>> = ({ title, children }) => (
    <Box>
        <Label>{title}</Label>
        <StyledBody variant="large">{children}</StyledBody>
    </Box>
);

export default SidebarIntro;

const StyledBody = styled(Body).attrs(() => ({ variant: 'large' }))``;
