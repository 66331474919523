import { Field, FieldConfig, FieldProps } from 'formik';
import isEqual from 'lodash/isEqual';
import React, { FC, useEffect, useRef, useState } from 'react';
import MapGL, { MapRef, Marker as MapGLMarker, MarkerDragEvent, NavigationControl } from 'react-map-gl';
import styled from 'styled-components';
import MarkerIcon from '../figure/Marker';
import { Position } from '../misc/StaticMap';
import ErrorOverlay from './ErrorOverlay';

export const TEXEL_CENTER_COORDS: Position = { latitude: 53.09193366460183, longitude: 4.813783623076006 };

export const MapLocationInputField: FC<
    React.PropsWithChildren<
        Partial<Omit<MapLocationInputProps, 'mapBoxAccessToken'>> &
            Pick<MapLocationInputProps, 'mapBoxAccessToken'> &
            FieldConfig
    >
> = props => (
    <Field {...props}>
        {({ field: { value, name }, form: { getFieldHelpers }, meta: { error, touched } }: FieldProps<Coordinates>) => {
            const { setValue } = getFieldHelpers(name);
            return (
                <MapLocationInput
                    {...props}
                    mapBoxAccessToken={props.mapBoxAccessToken ?? ''}
                    value={value}
                    onChange={setValue}
                    onNewMarkerClicked={setValue}
                    zoom={17}
                    error={touched && error ? error : undefined}
                />
            );
        }}
    </Field>
);

export interface Coordinates {
    latitude: number;
    longitude: number;
}
export interface MapLocationInputProps {
    error?: string;
    zoom: number;
    onChange: (position: Coordinates) => void;
    onNewMarkerClicked: (position: Coordinates) => void;
    value?: Coordinates;
    mapBoxAccessToken: string;
}

const defaultValue: Coordinates = {
    latitude: Number(TEXEL_CENTER_COORDS.latitude) ?? 0,
    longitude: Number(TEXEL_CENTER_COORDS.longitude) ?? 0,
};

const MapLocationInput: FC<React.PropsWithChildren<MapLocationInputProps>> = ({
    children,
    zoom,
    error,
    onChange,
    onNewMarkerClicked,
    value,
    mapBoxAccessToken,
}) => {
    const initialCoordinates = value ?? defaultValue;
    const mapRef = useRef<MapRef>(null);

    const [viewport, setViewport] = useState({
        zoom,
        ...initialCoordinates,
    });

    const handleDragEnd = (e: MarkerDragEvent) => {
        const { lngLat } = e;
        onChange({
            longitude: lngLat.lng,
            latitude: lngLat.lat,
        });
    };

    const oldValue = useRef(value);

    useEffect(() => {
        if (!isEqual(oldValue.current, value)) {
            setViewport(oldViewport => ({
                ...oldViewport,
                ...value,
                zoom,
                transitionDuration: 200,
            }));
            oldValue.current = value;
        }
    }, [value, zoom]);

    return (
        <Container>
            <MapGL
                ref={mapRef}
                initialViewState={viewport}
                {...viewport}
                mapStyle="mapbox://styles/mapbox/outdoors-v11"
                mapboxAccessToken={mapBoxAccessToken}
                minZoom={10.5}
                onClick={({ lngLat: { lat, lng } }) => {
                    if (!value) {
                        onNewMarkerClicked({ latitude: lat, longitude: lng });
                    }
                }}
                {...viewport}
            >
                <MapNav>
                    {children}
                    <StaticNavigationControl />
                </MapNav>
                {value && (
                    <MapGLMarker draggable onDragEnd={handleDragEnd} {...value}>
                        <MarkerContainer>
                            <MarkerIcon />
                        </MarkerContainer>
                    </MapGLMarker>
                )}
            </MapGL>
            {error && (
                <ErrorOverlay>
                    <p>{error}</p>
                </ErrorOverlay>
            )}
        </Container>
    );
};

export default MapLocationInput;

const Container = styled.div`
    height: 50vh;
    width: 100%;
    position: relative;
`;

const MarkerContainer = styled.div`
    color: ${({ theme }) => theme.colors.primary['40']};
    position: absolute;
    perspective-origin: center;
    transform-origin: center;
    height: 5.6rem;
    width: 3rem;
    display: flex;
    left: -15px;
    top: -27px;
    align-items: center;
    justify-content: center;
`;

const MapNav = styled.div`
    position: absolute;
    top: 1.6rem;
    left: 1.6rem;

    > * + * {
        margin-top: 0.4rem;
    }
`;

const StaticNavigationControl = styled(NavigationControl)`
    position: absolute;
    margin-top: 16px;
    width: 29px;
    height: 87px;
`;
