import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Toast from '@oberoninternal/travelbase-ds/components/feedback/Toast';
import Plus from '@oberoninternal/travelbase-ds/components/figure/Plus';
import { FormActionsContainer } from '@oberoninternal/travelbase-ds/components/form/FormActions';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import dateTextFormat from '@oberoninternal/travelbase-ds/constants/dateTextFormat';
import { Box } from '@rebass/grid';
import { FieldArray, FormikErrors, yupToFormErrors } from 'formik';
import gql from 'graphql-tag';
import React, { FC, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import dateFormat from '../../../../constants/dateFormat';
import { getDateOpts } from '../../../../constants/dateOpts';
import { ConfigurationError } from '../../../../entities/ConfigurationError';
import { Formable } from '../../../../entities/Formable';
import { UnitParams } from '../../../../entities/UnitParams';
import {
    BulkUpsertDatePricingInput,
    DatePricingFlex,
    DatePricingHybrid,
    DatePricingPeriod,
    DatePricingWeek,
    FlexPricingDay,
    PricesRowVisibilityFragment,
    RentalUnitInfoFragment,
    useBulkUpsertDatePricingMutation,
    usePricesWizardQuery,
} from '../../../../generated/graphql';
import { useToast } from '../../../../hooks/useToast';
import createWeekdayRecord from '../../../../utils/createWeekdayRecord';
import getRowVisibility from '../../../../utils/getRowVisibility';
import parseDate from '@oberoninternal/travelbase-ds/utils/parseDate';
import { getBulkWizardStartDate } from '../../../../utils/primarypricing';
import PricesWizardPeriod from '../../../molecules/PricesWizardPeriod';
import FormScreen from '../../../organisms/FormScreen';
import Loading from '../../../organisms/Loading';
import isAfter from 'date-fns/isAfter';
import isWithinInterval from 'date-fns/isWithinInterval';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import startOfToday from 'date-fns/startOfToday';
import { FormattedMessage, useIntl } from 'react-intl';

export const query = gql`
    query PricesWizard($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            ...PricesWizardRentalUnit
        }
    }

    fragment PricesWizardRentalUnit on RentalUnit {
        id
        lastDatePricingDate
        datePricingStartDate
        datePricingEndDate
        ...PricesRowVisibility
    }
`;

export const mutation = gql`
    mutation BulkUpsertDatePricing($input: BulkUpsertDatePricingInput!) {
        bulkUpsertDatePricing(input: $input) {
            rentalUnit {
                ...PricesWizardRentalUnit
            }
            datePricings {
                ...PriceColumnPricing
            }
            allotments {
                ...PriceColumnAllotment
            }
        }
    }
`;

type FlexType = Formable<DatePricingFlex> & { type: 'flex' };
type HybridType = Formable<DatePricingHybrid> & { type: 'hybrid' };
type WeekType = Formable<DatePricingWeek> & { type: 'week' };
type CopyType = { sourceRentalUnit: string; type: 'sourceRentalUnit' };

export type DatePricingPeriodType = FlexType | HybridType | WeekType | CopyType;

export type DatePricingType = DatePricingPeriodType['type'];
export interface PriceWizardValues {
    periods: Array<{ pricing: DatePricingPeriodType; startDate: Date; endDate: Date }>;
}

export const getNewFlexPeriod = ({
    showBaseStayPriceRow,
    showExtraPersonPriceRow,
    showMinimumStayPriceRow,
    showWeekPriceRow,
}: PricesRowVisibilityFragment) => {
    const initialFlexPricingDay: Formable<FlexPricingDay> = {
        nightPrice: '',
        arrivalAllowed: true,
        minimumStayDuration: 1,
    };

    const flex: DatePricingPeriodType = {
        ...createWeekdayRecord(initialFlexPricingDay),
        type: 'flex',
    };

    if (showBaseStayPriceRow) {
        flex.baseStayPrice = '';
    }
    if (showExtraPersonPriceRow) {
        flex.extraPersonPrice = '';
    }
    if (showMinimumStayPriceRow) {
        flex.minimumStayPrice = '';
    }
    if (showWeekPriceRow) {
        flex.weekPrice = null;
    }

    return flex;
};

const Wizard: FC<React.PropsWithChildren<{ rentalUnit?: RentalUnitInfoFragment }>> = () => {
    const { partnerId, unitSlug } = useParams<UnitParams>();
    const [mutate] = useBulkUpsertDatePricingMutation();
    const { data: wizardData, loading } = usePricesWizardQuery({ variables: { unitSlug } });
    const rowVisibility = useMemo(() => getRowVisibility(wizardData), [wizardData]);

    const { state } = useLocation<{ type?: 'prices' | 'availability' } | null>();
    const history = useHistory();
    const { formatMessage, locale } = useIntl();
    const dateOpts = getDateOpts(locale as 'nl' | 'de' | 'en');
    useToast(
        wizardData?.rentalUnit?.datePricingStartDate
            ? isAfter(new Date(wizardData.rentalUnit.datePricingStartDate), startOfToday()) && (
                  <Toast variant="info" title={formatMessage({ defaultMessage: 'Let op:' })}>
                      <Body variant="small">
                          <FormattedMessage
                              defaultMessage="Travelbase gebruikt ingelezen prijzen tot {date}"
                              values={{
                                  date: format(
                                      parseDate(wizardData.rentalUnit.datePricingStartDate),
                                      dateTextFormat,
                                      dateOpts
                                  ),
                              }}
                          />
                      </Body>
                  </Toast>
              )
            : null,
        { closeButton: true }
    );

    if (loading) {
        return <Loading amountOfFields={0} />;
    }

    // You can only open the wizard if the primary pricing model is active
    if (!wizardData?.rentalUnit || !wizardData.rentalUnit.datePricingStartDate) {
        throw new ConfigurationError('Het nachtprijsmodel is niet actief voor deze accommodatie.');
    }

    const {
        rentalUnit: { id: rentalUnitId, lastDatePricingDate, datePricingStartDate, datePricingEndDate },
    } = wizardData;

    const bulkStartDate = getBulkWizardStartDate(datePricingStartDate, datePricingEndDate, lastDatePricingDate);

    if (!bulkStartDate) {
        throw new ConfigurationError('Het nachtprijsmodel is op dit moment niet actief voor deze accommodatie.');
    }

    let startDateValidation = Yup.date().min(
        parseDate(datePricingStartDate),
        `De startdatum mag niet vóór ${format(parseDate(bulkStartDate), dateTextFormat, dateOpts)} liggen`
    );

    if (!lastDatePricingDate || isAfter(new Date(lastDatePricingDate), startOfToday())) {
        startDateValidation = startDateValidation.max(
            parseDate(bulkStartDate),
            `De startdatum mag niet ná ${format(parseDate(bulkStartDate), dateTextFormat, dateOpts)} liggen`
        );
    }

    const allPeriodsSchema =
        datePricingEndDate && isAfter(new Date(datePricingEndDate), new Date())
            ? Yup.object().shape({
                  periods: Yup.array().of(
                      Yup.object().shape({
                          endDate: Yup.date().max(
                              parseDate(datePricingEndDate),
                              `De einddatum mag niet ná de laatste dag van het nachtprijsmodel liggen (${format(
                                  parseDate(datePricingEndDate),
                                  dateTextFormat,
                                  dateOpts
                              )})`
                          ),
                      })
                  ),
              })
            : undefined;

    const firstPeriodSchema = Yup.object().shape({
        startDate: startDateValidation,
    });

    return (
        <FormScreen<PriceWizardValues>
            variant="wizard"
            initialValues={{ periods: [] }}
            skipReset
            skipToast
            validationSchema={allPeriodsSchema}
            validate={values => {
                const errors: FormikErrors<PriceWizardValues> = {};
                const firstPeriod = values.periods[0];
                // we only want to validate the startDate for the first period
                if (firstPeriod) {
                    try {
                        firstPeriodSchema.validateSync({
                            ...firstPeriod,
                            startDate: parseDate(firstPeriod.startDate),
                            endDate: parseDate(firstPeriod.endDate),
                        });
                    } catch (ex) {
                        errors.periods = [yupToFormErrors(ex)];
                    }
                }
                return errors;
            }}
            bottomChildren={({ dirty, isSubmitting }) => (
                <FormActionsContainer dirty={dirty} alwaysVisible variant="wizard">
                    <TextButton onClick={history.goBack}>
                        <FormattedMessage defaultMessage="Prijsinstelling sluiten" />
                    </TextButton>
                    <Button disabled={isSubmitting || !dirty} submitting={isSubmitting} type="submit">
                        <FormattedMessage defaultMessage="Wijzigingen publiceren" />
                    </Button>
                </FormActionsContainer>
            )}
            // Huh, but you're setting '' as initial values? Its because
            // we want to show a placeholder.
            handleSubmit={async (values: PriceWizardValues, resetForm) => {
                const firstPeriod = values.periods[0];
                const willOverwrite =
                    lastDatePricingDate &&
                    isAfter(new Date(lastDatePricingDate), new Date(datePricingStartDate)) &&
                    isWithinInterval(new Date(firstPeriod.startDate), {
                        start: new Date(datePricingStartDate),
                        end: new Date(lastDatePricingDate),
                    });
                if (
                    willOverwrite &&
                    !window.confirm(
                        formatMessage({
                            defaultMessage:
                                'De huidige prijzen worden overschreven. Weet je zeker dat je wilt doorgaan?',
                        })
                    )
                ) {
                    return;
                }

                const input: BulkUpsertDatePricingInput = {
                    rentalUnitId,
                    datePricingPeriods: values.periods.map(
                        ({ pricing: { type, ...pricing }, ...rest }): DatePricingPeriod => ({
                            [type]: type === 'sourceRentalUnit' ? (pricing as CopyType).sourceRentalUnit : pricing,
                            ...rest,
                        })
                    ),
                };

                await mutate({
                    variables: { input },
                });
                toast(
                    <Toast variant="success" title={formatMessage({ defaultMessage: 'Opgeslagen' })}>
                        <Body variant="small">
                            <FormattedMessage defaultMessage="Succesvol opgeslagen" />
                        </Body>
                    </Toast>,
                    {
                        autoClose: 3000,
                    }
                );

                resetForm(values);

                history.push(`/${locale}/partner/${partnerId}/unit/${unitSlug}/${state?.type ?? 'prices'}`, {
                    start: parseDate(firstPeriod.startDate),
                });
            }}
        >
            {({ values: { periods } }) => (
                <>
                    <Pagehead
                        title={formatMessage(
                            { defaultMessage: 'Jouw nieuwe prijzen vanaf {date}' },
                            {
                                date: format(
                                    parseDate(periods?.[0]?.startDate ?? bulkStartDate),
                                    `EEEE ${dateTextFormat}`,
                                    dateOpts
                                ),
                            }
                        )}
                    >
                        <FormattedMessage
                            defaultMessage="Hier bepaal je de prijzen voor een door jou te bepalen periode in de toekomst. Kies (per
                        periode) hoe flexibel je wilt omgaan met aankomst- en vertrekdagen."
                        />
                    </Pagehead>
                    {rowVisibility && (
                        <FieldArray name="periods">
                            {helpers => (
                                <Box>
                                    {periods.map((_, i) => (
                                        <PricesWizardPeriod
                                            key={i}
                                            helpers={helpers}
                                            index={i}
                                            rowVisibility={rowVisibility}
                                        />
                                    ))}
                                    <AddPeriodButton
                                        onClick={() => {
                                            // the startDate should be the day after the previous period's endDate, if
                                            // there is one.
                                            const startDate =
                                                periods.length > 0
                                                    ? addDays(parseDate(periods[periods.length - 1].endDate), 1)
                                                    : parseDate(bulkStartDate);

                                            const newPeriod = {
                                                startDate: format(startDate, dateFormat),
                                                endDate: format(addMonths(startDate, 1), dateFormat),
                                                pricing: getNewFlexPeriod(rowVisibility),
                                            };

                                            helpers.push(newPeriod);
                                        }}
                                    >
                                        <Plus />
                                        <span>
                                            <FormattedMessage defaultMessage="Voeg periode toe" />
                                        </span>
                                    </AddPeriodButton>
                                </Box>
                            )}
                        </FieldArray>
                    )}
                </>
            )}
        </FormScreen>
    );
};

export default Wizard;

const AddPeriodButton = styled(Button).attrs(() => ({ variant: 'outline' }))`
    height: 7.2rem;
    width: 100%;
    color: ${({ theme }) => theme.colors.primary[40]};
    margin-bottom: 2rem;
`;
