import dateTextFormat from '@oberoninternal/travelbase-ds/constants/dateTextFormat';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isSameYear from 'date-fns/isSameYear';
import isToday from 'date-fns/isToday';
import isWithinInterval from 'date-fns/isWithinInterval';
import simplur from 'simplur';
import { getDateOpts } from '../constants/dateOpts';
import { Booking, BookingStatusEnum } from '../generated/graphql';

const bookingStatuses: Record<BookingStatusEnum, string> = {
    ACCEPTED: 'Geaccepteerd',
    WAIVED: 'Afgezien',
    CANCELLED_GUEST: 'Geannuleerd door gast',
    CANCELLED_PARTNER: 'Geannuleerd door partner',
    DECLINED: 'Afgewezen',
    PENDING: 'Aanvraag',
    CANCELLED_EXTERNAL: 'Geannuleerd door Travelbase',
    INIT: 'Initialiseren',
};

export const getBookingsStatus = (
    booking: Pick<Booking, 'arrivalDate' | 'departureDate' | 'status'>
): { text: string; subText?: string } => {
    // For now only do something smart if the booking is accepted.
    if (booking.status === BookingStatusEnum.Accepted) {
        const arrival = new Date(booking.arrivalDate);
        const departure = new Date(booking.departureDate);
        const today = new Date();

        if (isToday(arrival)) {
            return { text: 'Check-in', subText: `vandaag` };
        }
        if (isToday(departure)) {
            return { text: 'Check-out', subText: `vandaag` };
        }

        if (isBefore(today, arrival)) {
            if (differenceInCalendarDays(arrival, today) <= 7) {
                return {
                    text: 'Boeking',
                    subText: `check-in over ${simplur`${differenceInCalendarDays(arrival, today)} dag[|en]`}`,
                };
            }
            return {
                text: 'Boeking',
                subText: `aankomst op ${format(
                    arrival,
                    !isSameYear(arrival, new Date()) ? `EEEEEE ${dateTextFormat}` : 'EEEEEE d MMM',
                    getDateOpts('nl')
                )}`,
            };
        }

        if (isAfter(today, departure)) {
            return { text: 'Vertrokken' };
        }

        if (isWithinInterval(today, { start: arrival, end: departure })) {
            return {
                text: 'Aanwezig',
            };
        }
    }
    return { text: bookingStatuses[booking.status] };
};

export default bookingStatuses;
