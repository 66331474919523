import React from 'react';
import styled from 'styled-components/macro';
import { Field, FieldProps } from 'formik';

const UploadField = () => (
    <Field name="file">
        {({ form, field: { onBlur, name } }: FieldProps) => (
            <File
                name={name}
                onBlur={onBlur}
                accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv"
                onChange={e => {
                    const file = e.target.files?.item(0);
                    form.setFieldValue(name, file);
                    setTimeout(() => {
                        form.submitForm();
                    });
                }}
            />
        )}
    </Field>
);

const File = styled.input.attrs({ type: 'file' })`
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
`;

export default UploadField;
