import React, { FC, useEffect, useMemo } from 'react';
import { Redirect, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';
import { SidebarProvider } from '../../context/sidebar';
import { ConfigurationError } from '../../entities/ConfigurationError';
import { ActivityParams } from '../../entities/ActivityParams';
import { PartnerDataQuery } from '../../generated/graphql';
import ErrorBoundary from '../organisms/ErrorBoundary';
import ActivityNavigation, { ActivityNavigationProps } from '../organisms/ActivityNavigation';
import NotFound from './NotFound';
import Hours from './company/Hours';
import CompanyRates from './company/Rates';
import ActivityRates from './activity/Rates';

import CompanyInfo from './company/Info';
import Planning from './activity/Planning';
import ActivityInfo from './activity/Info';
import Wizard from './activity/planning/TimeslotsWizard';
import { LocalizedSwitch } from '../atoms/LocalizedSwitch';

const Activity: FC<React.PropsWithChildren<{ data?: PartnerDataQuery }>> = ({ data }) => {
    const match = useRouteMatch<ActivityParams>()!;
    const history = useHistory();
    const location = useLocation<{ to?: string }>();

    const companies = useMemo(() => data?.partner?.companies ?? [], [data?.partner?.companies]);

    // do a redirect if no company or activity is passed in the url.
    useEffect(() => {
        const firstCompany = companies?.[0];
        const firstActivity = firstCompany.activities[0];

        if (!match.params.companySlug && !match.params.activitySlug && firstCompany) {
            if (firstActivity) {
                history.replace({
                    pathname: `/partner/${match.params.partnerId}/activity/${firstActivity.slug}/${
                        location?.state?.to ?? ''
                    }`,
                });
                return;
            }

            history.replace({
                pathname: `/partner/${match.params.partnerId}/company/${firstCompany.slug}/${
                    location?.state?.to ?? ''
                }`,
            });
        }
    }, [match, companies, history, location]);

    const selectedCompany = companies.find(company => company.slug.includes(match.params.companySlug ?? ''));

    if (companies.length === 0) {
        throw new ConfigurationError('Deze partner heeft geen toegang tot ondernemingen.');
    }

    const navProps: ActivityNavigationProps = {
        activityPickerEntries: companies,
    };

    return (
        <>
            {selectedCompany && <ActivityNavigation {...navProps} />}
            <SidebarProvider>
                <Container>
                    <ErrorBoundary>
                        <LocalizedSwitch>
                            {match.params.companySlug && (
                                <Redirect from={`${match.path}`} exact to={`${match.path}/hours`} />
                            )}
                            {match.params.activitySlug && (
                                <Redirect from={`${match.path}`} exact to={`${match.path}/planning`} />
                            )}
                            <Route
                                path="/partner/:partnerId/company/:companySlug/info"
                                render={props => (
                                    <CompanyInfo key={selectedCompany?.id} company={selectedCompany} {...props} />
                                )}
                            />
                            <Route path={`${match.path}/hours`} render={() => <Hours key={selectedCompany?.id} />} />
                            <Route
                                path="/partner/:partnerId/company/:companySlug/rates"
                                render={props => <CompanyRates key={selectedCompany?.id} {...props} />}
                            />
                            <Route
                                path="/partner/:partnerId/activity/:activitySlug/info"
                                render={props => <ActivityInfo key={selectedCompany?.id} {...props} />}
                            />
                            <Route
                                path="/partner/:partnerId/activity/:activitySlug/rates"
                                render={props => <ActivityRates key={selectedCompany?.id} {...props} />}
                            />
                            <Route exact path={`${match.path}/planning`} render={() => <Planning key="planning" />} />
                            <Route path={`${match.path}/planning/wizard`} render={() => <Wizard />} />

                            <Route component={NotFound} />
                        </LocalizedSwitch>
                    </ErrorBoundary>
                </Container>
            </SidebarProvider>
        </>
    );
};

export default Activity;

const Container = React.memo(styled.main`
    padding-top: ${({ theme }) => theme.heights.nav}px;

    @media (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        padding-top: ${({ theme }) => theme.heights.mobileNav}px;
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        padding-top: ${({ theme }) => theme.heights.unitNav + theme.heights.nav}px;
    }
`);
