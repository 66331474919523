import { Field, FieldConfig, FieldProps, getIn } from 'formik';
import React, { FC, SyntheticEvent, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled, { css } from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { ImageFile } from '../../entities/ImageFile';
import { isBlob } from '../../utils/isBlob';
import Button from '../action/Button';
import DragHandle from './DragHandle';
import FieldErrorMessage from './ErrorMessage';
import RoundButton from '../action/RoundButton';
import Cross from '../figure/Cross';
import ErrorOverlay from './ErrorOverlay';
import { getFontCss } from '../../constants/theme';
import { DraggableSyntheticListeners, DraggableAttributes } from '@dnd-kit/core';
import Download from '../figure/Download';

type Variant = 'main' | 'list';

export type ImageErrorType = {
    message: string;
    type: 'error' | 'warning';
};

interface UploadImageProps
    extends Omit<UploadImageFieldProps, 'imageErrors' | 'setImageErrors' | 'imageUploading' | 'uploadActionHandler'>,
        FieldConfig {
    name: string;
    listeners?: DraggableSyntheticListeners;
    handleProps?: DraggableAttributes;
    handleUpload: (file: ImageFile) => Promise<string>;
    uploadActionHandler: (uploadId: string, file: ImageFile) => void;
}
export const UploadImageField: FC<React.PropsWithChildren<UploadImageProps>> = ({ uploadActionHandler, ...props }) => {
    const [imageErrors, setImageErrors] = useState<ImageErrorType[]>([]);
    const [imageUploading, setImageUploading] = useState(false);

    const validate = () =>
        imageErrors.filter(({ type }) => type === 'error').length > 0 ? 'Upload een geldige foto' : undefined;
    return (
        <Field {...props} validate={validate}>
            {({ field: { name, value }, form: { setFieldValue, setFieldTouched, touched, errors } }: FieldProps) => (
                <UploadImage
                    imageErrors={imageErrors}
                    setImageErrors={setImageErrors}
                    image={value ?? undefined}
                    crossActionHandler={() => {
                        setFieldValue(name, null);
                        setFieldTouched(name, true);
                    }}
                    error={getIn(touched, name) && getIn(errors, name) ? getIn(errors, name) : undefined}
                    imageUploading={imageUploading}
                    uploadActionHandler={async file => {
                        setImageUploading(true);
                        try {
                            const imageId = await props.handleUpload(file);
                            uploadActionHandler(imageId, file);
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        } catch (ex: any) {
                            imageErrors.push({
                                type: 'error',
                                message: ex.message,
                            });
                        } finally {
                            setImageUploading(false);
                        }
                    }}
                    {...props}
                />
            )}
        </Field>
    );
};
interface UploadImageFieldProps {
    variant: Variant;
    ratio: number;
    image?: ImageFile | string;
    uploadActionHandler?: (file: ImageFile) => void;
    crossActionHandler?: () => void;
    error?: string;
    validateImage?: (imageElement: HTMLImageElement | null) => ImageErrorType[];
    maxSize?: number;
    imageErrors?: ImageErrorType[];
    setImageErrors?: (errors: ImageErrorType[]) => void;
    imageUploading?: boolean;
    name?: string;
    previewUrl?: string | null;
    listeners?: DraggableSyntheticListeners;
    handleProps?: DraggableAttributes;
    showDragHandle?: boolean;
}
const DownloadImage: FC<{ url: string }> = ({ url }) => (
    <StyledDownloadImage
        variant="outline-inverted"
        onClick={e => {
            e.stopPropagation();
            window.open(url);
        }}
    >
        <Download />
    </StyledDownloadImage>
);
const UploadImage: FC<React.PropsWithChildren<UploadImageFieldProps>> = ({
    variant,
    ratio,
    image,
    crossActionHandler,
    uploadActionHandler,
    error,
    validateImage,
    maxSize = 25000000,
    imageErrors,
    setImageErrors,
    imageUploading,
    name,
    previewUrl,
    listeners,
    handleProps,
    showDragHandle = true,
}) => {
    const onImageLoad = (event: SyntheticEvent<HTMLImageElement, Event>) => {
        if (validateImage && setImageErrors) {
            setImageErrors(validateImage(event.currentTarget));
        }
    };
    const bgImage = image && (isBlob(image) ? image.preview : previewUrl ?? image);
    const maxSizeMb = maxSize / 1000000;

    const { getRootProps, getInputProps, isDragActive, fileRejections, open } = useDropzone({
        noKeyboard: true,
        noClick: true,
        accept: 'image/jpeg, image/png, image/webp',
        maxSize,
        onDrop: (acceptedFiles, rejected) => {
            const accepted = acceptedFiles.map(file =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            );
            if (rejected.length < 1) {
                uploadActionHandler?.(accepted[0]);
            }
        },
    });

    const imgErrors = imageErrors?.filter(err => err.type === 'error');
    const imgWarnings = imageErrors?.filter(err => err.type === 'warning');
    return (
        <>
            <Dropzone
                data-error-focus={name}
                hasImage={!!bgImage}
                {...getRootProps()}
                isDragActive={isDragActive}
                ratio={ratio}
            >
                <Content
                    onClick={() => {
                        if (bgImage) {
                            open();
                        }
                    }}
                >
                    <input {...getInputProps({ disabled: false })} />
                    {!bgImage ? (
                        isDragActive ? (
                            'Sleep de foto hier naar toe...'
                        ) : (
                            <>
                                <Button size={variant === 'list' ? 'small' : 'regular'} onClick={open}>
                                    Kies een foto
                                </Button>
                                <Explanation>of sleep hier een foto heen</Explanation>
                                {fileRejections.length > 0 && (
                                    <ErrorMessage tiny={variant === 'list'}>
                                        U mag alleen fotobestanden uploaden van maximaal {maxSizeMb}MB
                                    </ErrorMessage>
                                )}
                            </>
                        )
                    ) : (
                        <ActionGroup>
                            <RoundButton
                                onClick={e => {
                                    e.stopPropagation(); // prevents container from firing onClick event
                                    if (bgImage) {
                                        crossActionHandler?.();
                                        setImageErrors?.([]);
                                        URL.revokeObjectURL(bgImage);
                                    }
                                }}
                                variant="outline-inverted"
                            >
                                <Cross />
                            </RoundButton>
                            {variant === 'list' && showDragHandle && <DragHandle {...handleProps} {...listeners} />}
                        </ActionGroup>
                    )}
                    {!!image && !isBlob(image) && <DownloadImage url={image} />}
                </Content>
                {bgImage && <Image onLoad={onImageLoad} src={bgImage} />}
                {imageUploading && (
                    <Loader>
                        <Skeleton width="100%" height="100%" />
                    </Loader>
                )}
                {imgErrors && imgErrors.length > 0 && (
                    <ErrorOverlay>
                        {imgErrors.map((err, i) => (
                            <p key={`imgError-${i}`}>{err.message}</p>
                        ))}
                    </ErrorOverlay>
                )}
                {!(imgErrors && imgErrors?.length > 0) && imgWarnings && imgWarnings.length > 0 && (
                    <ErrorOverlay isWarning>
                        {imgWarnings.map((warn, i) => (
                            <p key={`imgWarn-${i}`}>{warn.message}</p>
                        ))}
                    </ErrorOverlay>
                )}
            </Dropzone>
            {error && <FieldErrorMessage>{error}</FieldErrorMessage>}
        </>
    );
};

export default UploadImage;

const bgCss = css`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
`;

const Image = styled.img`
    border-radius: 0.5rem;
    object-fit: contain;
    background: ${({ theme }) => theme.colors.neutral['10']};
    ${bgCss}
`;

const Loader = styled.div`
    ${bgCss};
    z-index: 1;

    > span > span {
        display: block;
    }
`;

const Explanation = styled.p`
    ${({ theme }) => getFontCss(theme.fontSizes.body.tiny)}
    margin-bottom: 0;
`;

const ErrorMessage = styled.p<{ tiny?: boolean }>`
    color: ${({ theme }) => theme.colors.negative['60']};
    margin-bottom: 0;
    font: ${({ tiny, theme }) => (tiny ? getFontCss(theme.fontSizes.body.tiny) : 'inherit')};
`;

const Dropzone = styled.div<{ isDragActive: boolean; hasImage?: boolean; ratio: number }>`
    padding: 0;
    border-radius: 0.5rem;
    color: ${({ theme }) => theme.colors.neutral['30']};
    border: ${({ theme, isDragActive, hasImage }) =>
        !hasImage && `2px dashed ${isDragActive ? theme.colors.primary['40'] : theme.colors.neutral['30']}`};
    position: relative;
    &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: ${({ ratio }) => Math.round(ratio * 100)}%;
    }
`;

const Content = styled.div`
    padding: 0 2rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

const ActionGroup = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 1rem;
    height: 10rem;
    > * {
        z-index: 1;
    }
`;

const StyledDownloadImage = styled(RoundButton)`
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    color: white;
    padding: 10px;
    margin-bottom: ${({ theme }) => theme.spacing['30_Small']};
    margin-left: ${({ theme }) => theme.spacing['40_Standard']};

    display: flex;
`;
