import { CheckboxField } from '@oberoninternal/travelbase-ds/components/form/Checkbox';
import { StepperField } from '@oberoninternal/travelbase-ds/components/form/Stepper';
import TextInput, { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import { Box, Flex } from '@rebass/grid';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import targetAudiencesTypes from '../../../../constants/targetAudienceOptions';
import { ActivityParams } from '../../../../entities/ActivityParams';
import { NotFoundError } from '../../../../entities/NotFoundError';
import { useActivityDetailsQuery, useEditActivityInfoMutation } from '../../../../generated/graphql';
import omitEqualValues from '../../../../utils/omitEqualValues';
import { toFormikValues, toInput } from '../../../../utils/targetAudiences';
import FieldSet from '../../../atoms/FieldSet';
import FieldSetHint from '../../../atoms/FieldSetHint';
import { Seperator } from '../../../atoms/Seperator';
import FieldHeading from '../../../molecules/FieldHeading';
import LabelBox from '../../../molecules/LabelBox';
import FormScreen from '../../../organisms/FormScreen';
import Loading from '../../../organisms/Loading';
import createPublicLinkProps from '../../../../utils/createPublicLinkProps';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import brandConfig from '../../../../constants/brandConfig';
import { FormattedMessage, useIntl } from 'react-intl';

export const activityDetailsFragment = gql`
    fragment ActivityDetails on Activity {
        id
        nameNL: name(locale: "nl")
        nameDE: name(locale: "de")
        nameEN: name(locale: "en")
        themes {
            nameNL: name(locale: "nl")
        }
        targetAudiences
        maxCapacity
        minCapacity
        externalBookingUrl
        hideForPartner
    }
`;

export const query = gql`
    query ActivityDetails($activitySlug: String!) {
        activity(slug: $activitySlug) {
            ...ActivityDetails
        }
    }
`;

export const editActivityDetailsMutation = gql`
    mutation EditActivityInfo($input: EditActivityInfoInput!) {
        editActivityInfo(input: $input) {
            activity {
                ...ActivityDetails
            }
        }
    }
`;

const ActivityDetails: FC<React.PropsWithChildren<ActivityParams>> = ({ activitySlug }) => {
    const slug = activitySlug ?? '';
    const { loading, data } = useActivityDetailsQuery({ variables: { activitySlug: slug } });
    const [mutate] = useEditActivityInfoMutation();
    const intl = useIntl();
    const { formatMessage } = intl;
    if (loading) {
        return <Loading />;
    }

    if (!data || !data.activity) {
        throw new NotFoundError();
    }

    const {
        activity: {
            id,
            nameDE,
            nameEN,
            nameNL,
            themes,
            targetAudiences,
            maxCapacity,
            minCapacity,
            externalBookingUrl,
            hideForPartner,
        },
    } = data;

    const initialValues = {
        nameDE: nameDE ?? '',
        nameNL: nameNL ?? '',
        nameEN: nameEN ?? '',
        targetAudiences: toFormikValues(targetAudiences, intl),
        maxCapacity: maxCapacity ?? 0,
        minCapacity: minCapacity ?? 1,
        externalBookingUrl: externalBookingUrl ?? '',
        hideForPartner,
    };

    return (
        <FormScreen
            initialValues={initialValues}
            handleSubmit={async ({ targetAudiences: newTargetAudiences, ...values }) => {
                const targetAudiencesInput = toInput(newTargetAudiences);
                await mutate({
                    variables: {
                        input: {
                            activityId: id,
                            targetAudiences: targetAudiencesInput,
                            ...omitEqualValues(values, initialValues),
                        },
                    },
                });
            }}
        >
            {({ values }) => (
                <>
                    <Flex justifyContent="space-between" flexDirection={['column', 'row']}>
                        <Box>
                            <Pagehead title={formatMessage({ defaultMessage: 'Informatie' })}>
                                <FormattedMessage defaultMessage="Hier beheer je de informatie van de activiteit." />
                            </Pagehead>
                        </Box>
                        <Box>
                            <TextButton
                                style={{ display: 'flex-inline' }}
                                as="a"
                                {...createPublicLinkProps(
                                    `${brandConfig.customSlugs?.activity ?? 'activiteit'}/${activitySlug}`,
                                    intl
                                )}
                            />
                        </Box>
                    </Flex>

                    <FieldHeading title={formatMessage({ defaultMessage: 'Naam' })}>
                        <FormattedMessage defaultMessage="Onder deze naam tonen we de activiteit. Neem contact met ons op als deze gegevens niet kloppen." />
                    </FieldHeading>
                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="nameNL">
                                <FormattedMessage defaultMessage="Nederlands" />
                            </Label>
                        </LabelBox>
                        <Box width={1}>
                            <TextInput disabled value={values.nameNL} />
                        </Box>
                    </FieldSet>
                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="nameDE">
                                <FormattedMessage defaultMessage="Duits" />
                            </Label>
                        </LabelBox>
                        <Box width={1}>
                            <TextInput disabled value={values.nameDE} />
                        </Box>
                    </FieldSet>
                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="nameEN">
                                <FormattedMessage defaultMessage="Engels" />
                            </Label>
                        </LabelBox>
                        <Box width={1}>
                            <TextInput disabled value={values.nameEN} />
                        </Box>
                    </FieldSet>

                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="themes">
                                <FormattedMessage defaultMessage="Thema" />
                            </Label>
                            <FieldSetHint>
                                <FormattedMessage defaultMessage="Neem contact met ons op als deze gegevens niet kloppen." />
                            </FieldSetHint>
                        </LabelBox>
                        <Box width={1}>
                            {themes.length ? (
                                <StyledList>
                                    {themes.map((theme, idx) => (
                                        <li key={`${theme.__typename}-${idx}`}>{theme.nameNL}</li>
                                    ))}
                                </StyledList>
                            ) : (
                                <div>
                                    <FormattedMessage defaultMessage="Geen thema's" />
                                </div>
                            )}
                        </Box>
                    </FieldSet>

                    <Seperator />

                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="targetAudiences">
                                <FormattedMessage defaultMessage="Doelgroep" />
                            </Label>
                        </LabelBox>
                        <Box width={1}>
                            {targetAudiencesTypes(intl).map(item => (
                                <CheckboxContainer key={item.label}>
                                    <CheckboxField id={item.value} name={`targetAudiences.${item.value}`}>
                                        {item.label}
                                    </CheckboxField>
                                </CheckboxContainer>
                            ))}
                        </Box>
                    </FieldSet>

                    <Seperator />

                    <FieldSet>
                        <LabelBox>
                            <Label>
                                <FormattedMessage defaultMessage="Maximum aantal personen" />
                            </Label>
                            <FieldSetHint>
                                <FormattedMessage defaultMessage="Hoeveel personen kunnen maximaal tegelijk deelnemen." />
                            </FieldSetHint>
                        </LabelBox>
                        <StepperField editable minimum={values.minCapacity} name="maxCapacity" maximum={120} />
                    </FieldSet>

                    <FieldSet>
                        <LabelBox>
                            <Label>
                                <FormattedMessage defaultMessage="Minimum aantal personen" />
                            </Label>
                            <FieldSetHint>
                                <FormattedMessage defaultMessage="Hoeveel personen moeten er minimaal deelnemen om de activiteit door te laten gaan." />
                            </FieldSetHint>
                        </LabelBox>
                        <StepperField editable minimum={1} maximum={values.maxCapacity} name="minCapacity" />
                    </FieldSet>

                    {externalBookingUrl && (
                        <>
                            <Seperator />
                            <FieldSet>
                                <LabelBox>
                                    <Label htmlFor="externalBookingUrl">
                                        <FormattedMessage defaultMessage="Reserveringssysteem" />
                                    </Label>
                                    <FieldSetHint>
                                        <FormattedMessage defaultMessage="Vul volledig URL in, dus met https:// erbij." />
                                    </FieldSetHint>
                                </LabelBox>
                                <Box width={1}>
                                    <TextInputField
                                        id="externalBookingUrl"
                                        placeholder={formatMessage({ defaultMessage: 'Reserveringssysteem url' })}
                                        name="externalBookingUrl"
                                    />
                                </Box>
                            </FieldSet>
                        </>
                    )}

                    <FieldSet>
                        <LabelBox>
                            <Label>
                                <FormattedMessage defaultMessage="Verbergen in menu" />
                            </Label>
                            <FieldSetHint>
                                <FormattedMessage defaultMessage="Hiermee kan je ervoor zorgen dat een activiteit niet meer in het menu verschijnt." />
                            </FieldSetHint>
                        </LabelBox>
                        <CheckboxField id="hideForPartner" name="hideForPartner">
                            <FormattedMessage defaultMessage="Verbergen" />
                        </CheckboxField>
                    </FieldSet>
                </>
            )}
        </FormScreen>
    );
};

export default ActivityDetails;

const StyledList = styled.ul`
    li {
        list-style: disc;
    }
`;

const CheckboxContainer = styled.div`
    &:not(:last-child) {
        margin-bottom: 2.5rem;
    }
`;
