import SelectInput, { OptionType } from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import { Box } from '@rebass/grid';
import gql from 'graphql-tag';
import compareAsc from 'date-fns/compareAsc';
import isSameDay from 'date-fns/isSameDay';
import { useField, useFormikContext } from 'formik';
import React, { FC, useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { UnitParams } from '../../entities/UnitParams';
import {
    PartnerDataQuery,
    PricesRowVisibilityFragment,
    usePartnerDataQuery,
    usePricesWizardQuery,
    useWizardPriceCopierLazyQuery,
} from '../../generated/graphql';
import getRowVisibility from '../../utils/getRowVisibility';
import { isIntervalWithinPrimaryPricing } from '../../utils/primarypricing';
import { useIntl } from 'react-intl';

export const query = gql`
    query WizardPriceCopier($id: ID!, $startDate: Date!, $endDate: Date!) {
        rentalUnit(id: $id) {
            id
            datePricingStartDate
            datePricingEndDate
            datePricings(startDate: $startDate, endDate: $endDate) {
                id
                date
            }
            ...PricesRowVisibility
        }
    }
`;

interface Props {
    name: string;
}

const hasSamePriceOptions = (
    selected: PricesRowVisibilityFragment | null,
    source: PricesRowVisibilityFragment | null
) => {
    if (!selected || !source) {
        return null;
    }
    return Object.entries(selected).every(([key, value]) => source[key as keyof PricesRowVisibilityFragment] === value);
};

const getAccommodationOptions = (partner: PartnerDataQuery['partner'] | undefined, unitSlug: string) =>
    partner?.accommodations.reduce<OptionType[]>(
        (options, next) => [
            ...options,
            ...next.rentalUnits
                .filter(({ slug, datePricingStartDate }) => {
                    if (slug === unitSlug) {
                        return false;
                    }
                    // only show units which have a primary pricing model
                    return !!datePricingStartDate;
                })
                .map((unit): OptionType => ({ label: `${next.name} - ${unit.name}`, value: unit.id })),
        ],
        []
    );

const WizardPriceCopier: FC<React.PropsWithChildren<Props>> = ({ name }) => {
    const { unitSlug, partnerId } = useParams<UnitParams>();
    const { formatMessage } = useIntl();
    const { validateField } = useFormikContext();
    const [
        {
            value: { startDate, endDate },
        },
    ] = useField<{ startDate: string; endDate: string }>(name);
    const [{ value: sourceRentalUnit }, { error, touched }] = useField<string>(`${name}.pricing.sourceRentalUnit`);

    const { data: partnerData } = usePartnerDataQuery({
        variables: {
            partnerId,
            slug: unitSlug,
        },
    });

    const { data: selectedData } = usePricesWizardQuery({
        variables: {
            unitSlug,
        },
    });

    const [fetch, { loading, data: sourceData }] = useWizardPriceCopierLazyQuery({
        onCompleted: () => {
            // we need to wait for the onChange of the select to be handled in order to show the correct error message
            setTimeout(() => validateField(`${name}.pricing.sourceRentalUnit`));
        },
    });

    const datePricings = useMemo(
        () =>
            [...(sourceData?.rentalUnit?.datePricings ?? [])].sort((a, b) =>
                compareAsc(new Date(a.date), new Date(b.date))
            ),
        [sourceData]
    );

    useEffect(() => {
        if (sourceRentalUnit) {
            fetch({
                variables: {
                    id: sourceRentalUnit,
                    startDate,
                    endDate,
                },
            });
        }
    }, [endDate, fetch, sourceRentalUnit, startDate]);

    const samePriceOptions = useMemo(
        () => hasSamePriceOptions(getRowVisibility(selectedData), getRowVisibility(sourceData)),
        [selectedData, sourceData]
    );
    const accOptions = useMemo(() => getAccommodationOptions(partnerData?.partner, unitSlug), [partnerData, unitSlug]);

    const validator = useCallback(
        (selectedValue: string) => {
            if (selectedValue === '') {
                return 'Selecteer een accommodatie';
            }
            if (!sourceData) {
                return undefined;
            }
            if (datePricings.length === 0 || !sourceData.rentalUnit?.datePricingStartDate) {
                return 'Er zijn geen prijzen gevonden voor de accommodatie in deze periode';
            }

            if (!samePriceOptions) {
                return 'Deze accommodatie heeft niet dezelfde prijsopties';
            }

            if (
                !isIntervalWithinPrimaryPricing(
                    { start: startDate, end: endDate },
                    {
                        start: sourceData.rentalUnit.datePricingStartDate,
                        end: sourceData.rentalUnit.datePricingEndDate,
                    }
                )
            ) {
                return 'De geselecteerde periode valt buiten het nachtprijsmodel van de accommodatie';
            }
            if (
                !isSameDay(new Date(datePricings[0].date), new Date(startDate)) ||
                !isSameDay(new Date(datePricings[datePricings.length - 1].date), new Date(endDate))
            ) {
                return 'De periode die je wilt overnemen is niet (volledig) beschikbaar';
            }
            return undefined;
        },
        [datePricings, endDate, samePriceOptions, sourceData, startDate]
    );

    return (
        <Box width={[1, 0.4]}>
            <SelectInput
                name={`${name}.pricing.sourceRentalUnit`}
                options={accOptions}
                isLoading={loading}
                validate={validator}
                error={(touched && error) || (sourceRentalUnit && error) ? error : undefined}
                placeholder={formatMessage({
                    defaultMessage: 'Selecteer accommodatie',
                })}
            />
        </Box>
    );
};

export default WizardPriceCopier;
