import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

const ratesValidationSchema = (intl: IntlShape) =>
    Yup.array().of(
        Yup.object().shape({
            price: Yup.number().required(intl.formatMessage({ defaultMessage: 'Vul een prijs in' })),
            translations: Yup.array().of(
                Yup.object().shape({
                    name: Yup.string().required(intl.formatMessage({ defaultMessage: 'Vul een naam in' })),
                })
            ),
        })
    );

export default ratesValidationSchema;
