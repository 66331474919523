import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Checkbox, { CheckboxField } from '@oberoninternal/travelbase-ds/components/form/Checkbox';
import TextInput from '@oberoninternal/travelbase-ds/components/form/TextInput';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';
import { Box, Flex } from '@rebass/grid';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import * as Yup from 'yup';
import brandConfig from '../../../../constants/brandConfig';
import unitTypes from '../../../../constants/unitTypes';
import { NotFoundError } from '../../../../entities/NotFoundError';
import { UnitParams } from '../../../../entities/UnitParams';
import { useEditRentalUnitInfoMutation, useRentalUnitInfoQuery } from '../../../../generated/graphql';
import { EditRentalUnitInfoInput, RentalUnitTypeEnum } from '../../../../generated/resetGraphql';
import createPublicLinkProps from '../../../../utils/createPublicLinkProps';
import { getUnitTypeTranslation } from '../../../../utils/getUnitTypeTranslation';
import omitEqualValues from '../../../../utils/omitEqualValues';
import FieldSet from '../../../atoms/FieldSet';
import FieldSetHint from '../../../atoms/FieldSetHint';
import NumberInput from '../../../atoms/NumberInput';
import FieldHeading from '../../../molecules/FieldHeading';
import LabelBox from '../../../molecules/LabelBox';
import TranslationInput from '../../../molecules/TranslationInput';
import FormScreen from '../../../organisms/FormScreen';
import Loading from '../../../organisms/Loading';

export const query = gql`
    query RentalUnitInfo($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            ...RentalUnitDetails
        }
    }

    mutation EditRentalUnitInfo($input: EditRentalUnitInfoInput!) {
        editRentalUnitInfo(input: $input) {
            rentalUnit {
                ...RentalUnitDetails
            }
        }
    }

    fragment RentalUnitDetails on RentalUnit {
        id
        slug
        code
        type
        securityDeposit
        handleSecurityDepositPayment
        releaseDays
        name
        tagline: tagline(locale: "nl")
        taglineDE: tagline(locale: "de")
        taglineEN: tagline(locale: "en")
        taglinePending
        keyHolderExplanationDE: keyHolderExplanation(locale: "de")
        keyHolderExplanationNL: keyHolderExplanation(locale: "nl")
        keyHolderExplanationEN: keyHolderExplanation(locale: "en")
        keyHolderExplanationPending
        hideReviews
        hideForPartner
    }
`;

export const unitDetailsValidation = (intl: IntlShape) =>
    Yup.object().shape({
        securityDeposit: Yup.number()
            .required(intl.formatMessage({ defaultMessage: 'Vul een borgsom in' }))
            .min(0, intl.formatMessage({ defaultMessage: 'Bedrag mag niet negatief zijn' })),
        releaseDays: Yup.number()
            .required(intl.formatMessage({ defaultMessage: 'Vul het aantal dagen in' }))
            .min(0, intl.formatMessage({ defaultMessage: 'Aantal mag niet minder zijn dan 0' })),
    });

const UnitDetails: FC<React.PropsWithChildren<UnitParams>> = ({ unitSlug }) => {
    const { loading, data } = useRentalUnitInfoQuery({ variables: { unitSlug } });
    const [mutate] = useEditRentalUnitInfoMutation();
    const intl = useIntl();
    const { formatMessage } = intl;
    if (loading) {
        return <Loading />;
    }

    if (!data || !data.rentalUnit) {
        throw new NotFoundError();
    }

    const {
        rentalUnit: {
            id,
            code,
            type,
            securityDeposit,
            handleSecurityDepositPayment,
            name,
            tagline,
            taglinePending,
            taglineDE,
            taglineEN,
            releaseDays,
            keyHolderExplanationNL,
            keyHolderExplanationDE,
            keyHolderExplanationEN,
            keyHolderExplanationPending,
            hideReviews,
            hideForPartner,
        },
    } = data;

    const initialValues: Omit<EditRentalUnitInfoInput, 'rentalUnitId'> = {
        tagline: taglinePending ?? tagline,
        securityDeposit: securityDeposit ?? 0,
        handleSecurityDepositPayment,
        releaseDays: releaseDays ?? 0,
        keyHolderExplanation: keyHolderExplanationPending ?? keyHolderExplanationNL ?? '',
        hideForPartner,
    };

    const unitTypeTranslation = getUnitTypeTranslation(type);

    return (
        <FormScreen
            validationSchema={unitDetailsValidation(intl)}
            initialValues={initialValues}
            handleSubmit={async values => {
                await mutate({
                    variables: {
                        input: {
                            ...omitEqualValues(values, initialValues, ['keyHolderExplanation', 'tagline']),
                            rentalUnitId: id,
                        },
                    },
                });
            }}
        >
            {() => (
                <>
                    <Flex justifyContent="space-between" flexDirection={['column', 'row']}>
                        <Box>
                            <Pagehead
                                title={formatMessage(
                                    { defaultMessage: '{unitTypeTranslation} informatie' },
                                    {
                                        unitTypeTranslation,
                                    }
                                )}
                            >
                                <FormattedMessage
                                    defaultMessage="Hier beheer je de informatie van je {unitTypeTranslation}"
                                    values={{ unitTypeTranslation: unitTypeTranslation?.toLowerCase() }}
                                />
                            </Pagehead>
                        </Box>
                        <Box>
                            <TextButton
                                style={{ display: 'flex-inline' }}
                                as="a"
                                {...createPublicLinkProps(
                                    `${
                                        brandConfig.customSlugs?.accommodation
                                            ? brandConfig.customSlugs?.accommodation
                                            : 'accommodatie'
                                    }/${unitSlug}`,
                                    intl
                                )}
                            />
                        </Box>
                    </Flex>

                    <FieldHeading title={formatMessage({ defaultMessage: 'Algemeen' })}>
                        <FormattedMessage defaultMessage="Onder deze gegevens is de accommodatie bekend bij ons." />
                    </FieldHeading>
                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="name">
                                <FormattedMessage defaultMessage="Naam accommodatie" />
                            </Label>
                            <FieldSetHint>
                                <FormattedMessage defaultMessage="Zoals vermeld in jouw Travelbase overeenkomst. Neem contact met ons op met eventuele vragen." />
                            </FieldSetHint>
                        </LabelBox>
                        <Box width={1}>
                            <TextInput value={name} disabled />
                        </Box>
                    </FieldSet>

                    <GeneralInfoSection
                        {...{ type, code, hideReviews, tagline, taglineDE, taglineEN, taglinePending }}
                    />

                    <ReservationMetaInfoSection />

                    <FieldHeading title={formatMessage({ defaultMessage: 'Toelichting sleuteladres' })}>
                        <FormattedMessage defaultMessage="Specifieke toelichting bij het sleuteladres. Deze tekst komt op de bevestiging aan de gast te staan bij het sleuteladres." />
                    </FieldHeading>
                    <TranslationInput
                        name="keyHolderExplanation"
                        placeholder={formatMessage({ defaultMessage: 'Extra toelichting voor je gast' })}
                        data={{
                            en: keyHolderExplanationEN,
                            nl: keyHolderExplanationNL,
                            de: keyHolderExplanationDE,
                            pending: keyHolderExplanationPending,
                        }}
                    />
                </>
            )}
        </FormScreen>
    );
};

export default UnitDetails;

// SHARED SECTIONS

export const GeneralInfoSection = ({
    code,
    tagline,
    taglineDE,
    taglineEN,
    taglinePending,
    hideReviews,
    type,
}: {
    code: string;
    hideReviews: boolean;
    tagline: string;
    taglineEN: string;
    taglineDE: string;
    taglinePending: Maybe<string>;
    type: RentalUnitTypeEnum;
}) => {
    const { formatMessage } = useIntl();
    return (
        <>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="type">
                        <FormattedMessage defaultMessage="Type accommodatie" />
                    </Label>
                    <FieldSetHint>
                        <FormattedMessage defaultMessage="Zoals vermeld in jouw Travelbase overeenkomst. Neem contact met ons op met eventuele vragen." />
                    </FieldSetHint>
                </LabelBox>
                <Box width={1}>
                    <TextInput disabled value={unitTypes.find(unitType => unitType.value === type)?.label} />
                </Box>
            </FieldSet>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="code">
                        <FormattedMessage defaultMessage="Accommodatiecode" />
                    </Label>
                    <FieldSetHint>
                        <FormattedMessage defaultMessage="Dit nummer wordt automatisch bepaald" />
                    </FieldSetHint>
                </LabelBox>
                <Box width={[1, 1, 1 / 3]}>
                    <TextInput disabled value={code} />
                </Box>
            </FieldSet>

            <FieldSet>
                <LabelBox>
                    <Label htmlFor="hideReviews">
                        <FormattedMessage defaultMessage="Beoordelingen uitgeschakeld?" />
                    </Label>
                    <FieldSetHint>
                        <FormattedMessage defaultMessage="Neem contact met ons op met eventuele vragen of veranderingen." />
                    </FieldSetHint>
                </LabelBox>
                <Box width={1}>
                    <Checkbox checked={hideReviews} disabled />
                </Box>
            </FieldSet>

            <FieldSet>
                <LabelBox>
                    <Label>
                        <FormattedMessage defaultMessage="Verbergen in menu" />
                    </Label>
                    <FieldSetHint>
                        <FormattedMessage defaultMessage="Hiermee kan je ervoor zorgen dat een verhuureenheid niet meer in het menu verschijnt." />
                    </FieldSetHint>
                </LabelBox>
                <CheckboxField id="hideForPartner" name="hideForPartner">
                    <FormattedMessage defaultMessage="Verbergen" />
                </CheckboxField>
            </FieldSet>

            <FieldHeading title={formatMessage({ defaultMessage: 'Tagline' })}>
                <FormattedMessage defaultMessage="In deze zin beschrijf je dé reden voor gasten om juist jouw accommodatie te kiezen. De tagline wordt op de website in de lijst en op detailniveau getoond. Beschrijf je accommodatie in één korte zin (max 70 karakters)." />
            </FieldHeading>

            <TranslationInput
                maxLength={70}
                name="tagline"
                hint={formatMessage({
                    defaultMessage:
                        'Plaats hier de tekst die je op de website van de VVV wilt tonen. Wij verzorgen de plaatsing en vertaling binnen enkele dagen.',
                })}
                placeholder={formatMessage({
                    defaultMessage: 'Beschrijf de accommodatie in één zin',
                })}
                data={{
                    en: taglineEN,
                    nl: tagline,
                    de: taglineDE,
                    pending: taglinePending,
                }}
            />
        </>
    );
};

export const ReservationMetaInfoSection = () => {
    const { formatMessage } = useIntl();
    return (
        <>
            <FieldHeading title={formatMessage({ defaultMessage: 'Release dagen' })}>
                <FormattedMessage
                    defaultMessage="Hoeveel dagen moeten er minimaal zitten tussen vandaag en aankomst bij nieuwe boekingen {breakingLine} (1 betekent dat Travelbase boekingen voor morgen accepteert)."
                    values={{
                        breakingLine: <br />,
                    }}
                />
            </FieldHeading>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="releaseDays">
                        <FormattedMessage defaultMessage="Release dagen" />
                    </Label>
                </LabelBox>
                <Box width={1}>
                    <NumberInput
                        name="releaseDays"
                        id="releaseDays"
                        placeholder={formatMessage({ defaultMessage: 'Release dagen' })}
                        step={1}
                        min={0}
                    />
                </Box>
            </FieldSet>

            <FieldHeading title={formatMessage({ defaultMessage: 'Borgsom' })}>
                <FormattedMessage defaultMessage="Geef het bedrag van de borgsom op als deze van toepassing is." />
            </FieldHeading>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="securityDeposit">
                        <FormattedMessage defaultMessage="Borgsom" />
                    </Label>
                </LabelBox>
                <Box width={1}>
                    <NumberInput
                        name="securityDeposit"
                        id="securityDeposit"
                        placeholder={formatMessage({ defaultMessage: 'Borgsom' })}
                        isCurrency
                        step={1}
                        min={0}
                    />
                </Box>
            </FieldSet>
            <FieldSet>
                <LabelBox>
                    <Label htmlFor="handleSecurityDepositPayment">
                        <FormattedMessage defaultMessage="Gast betaalt borgsom via Travelbase" />
                    </Label>
                    <FieldSetHint>
                        <FormattedMessage defaultMessage="De betaling van de borgsom loopt alléén via Travelbase als dit in de overeenkomst is toegestaan." />
                    </FieldSetHint>
                </LabelBox>
                <Box>
                    <CheckboxField id="handleSecurityDepositPayment" name="handleSecurityDepositPayment" />
                </Box>
            </FieldSet>
        </>
    );
};
