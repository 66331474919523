import Cross from '@oberoninternal/travelbase-ds/components/figure/Cross';
import Search from '@oberoninternal/travelbase-ds/components/figure/Search';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import { Box, Flex } from '@rebass/grid';
import { debounce } from 'debounce';
import { Formik, FormikProps } from 'formik';
import isEqual from 'lodash/isEqual';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ArrayParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { AllBookingsQuery, useAllBookingsQuery } from '../../../generated/graphql';
import isValidDate from '../../../utils/isValidDate';
import BigListWrapper from '../../atoms/BigListWrapper';
import BookingsList from '../../molecules/BookingsList';
import RangePickerInputField from '../../molecules/RangePickerInputField';
import { BookingSearchValues, STEP } from '../Bookings';
import { PartnerParams } from '../../../entities/PartnerParams';
import gql from 'graphql-tag';
import { NetworkStatus } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';

export const query = gql`
    query AllBookings(
        $after: String
        $first: Int
        $before: String
        $last: Int
        $startDate: Date
        $endDate: Date
        $searchQuery: String
        $partnerId: ID!
        $rentalUnitIds: [ID!]
    ) {
        partner(id: $partnerId) {
            id
            allBookings(
                first: $first
                last: $last
                after: $after
                before: $before
                startDate: $startDate
                endDate: $endDate
                searchQuery: $searchQuery
                rentalUnitIds: $rentalUnitIds
            ) {
                ...BookingConnection
            }
        }
    }
`;

const initialSearchValues: BookingSearchValues = {
    searchQuery: '',
    startDate: null,
    endDate: null,
    rentalUnitIds: [],
};

const All: FC<React.PropsWithChildren<unknown>> = () => {
    const intl = useIntl();
    const { formatMessage } = intl;
    const [paramQuery, setQuery] = useQueryParams({
        startDate: StringParam,
        searchQuery: StringParam,
        endDate: StringParam,
        rentalUnitIds: withDefault(ArrayParam, []),
    });
    const { partnerId } = useParams<PartnerParams>();

    const { startDate, endDate, rentalUnitIds, ...rest } = paramQuery;
    const validStartDate = paramQuery.startDate && isValidDate(paramQuery.startDate) ? paramQuery.startDate : null;
    const validEndDate = paramQuery.endDate && isValidDate(paramQuery.endDate) ? paramQuery.endDate : null;

    const { fetchMore, loading, data, networkStatus, refetch } = useAllBookingsQuery({
        variables: {
            first: STEP * 4,
            startDate: validStartDate,
            endDate: validEndDate,
            partnerId,
            rentalUnitIds: rentalUnitIds as string[],
            ...rest,
        },
        notifyOnNetworkStatusChange: true,
    });

    const bookings = data?.partner?.allBookings;

    const isNextPageLoading = networkStatus === NetworkStatus.fetchMore;
    const hasNextPage = !!data?.partner?.allBookings?.pageInfo.hasNextPage;
    const [isSearching, setIsSearching] = useState(false);

    const onLoadMore = useCallback(async () => {
        if (loading) {
            return;
        }

        await fetchMore({
            variables: { after: data?.partner?.allBookings?.pageInfo.endCursor },
            updateQuery: (prev, { fetchMoreResult }): AllBookingsQuery => {
                if (!fetchMoreResult || !prev.partner?.allBookings?.edges) {
                    return prev;
                }
                const newEdges = fetchMoreResult?.partner?.allBookings?.edges;
                const pageInfo = fetchMoreResult?.partner?.allBookings?.pageInfo;
                return {
                    ...prev,
                    partner: {
                        ...prev.partner,
                        allBookings: prev.partner?.allBookings
                            ? {
                                  ...prev.partner.allBookings,
                                  edges: [...prev.partner.allBookings.edges, ...(newEdges ?? [])],
                                  pageInfo: pageInfo ?? prev.partner.allBookings.pageInfo,
                              }
                            : null,
                    },
                };
            },
        });
    }, [loading, data, fetchMore]);

    // TODO: use useMemo instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const performSearch = useCallback(
        debounce(async (variables: BookingSearchValues) => {
            await refetch({ partnerId, ...variables });
            setIsSearching(false);

            // reflect the search params in the url. Also set the value to undefined if null, cause that's what the library expects
            setQuery(
                Object.assign(
                    {},
                    ...Object.entries(variables).map(([key, value]) => ({
                        [key]: value === null ? undefined : value,
                    }))
                )
            );
        }, 600),
        [refetch]
    );

    return (
        <>
            <Formik<BookingSearchValues>
                initialValues={{
                    searchQuery: paramQuery.searchQuery ?? '',
                    startDate: validStartDate,
                    endDate: validEndDate,
                    rentalUnitIds: paramQuery.rentalUnitIds as string[],
                }}
                onSubmit={async values => {
                    setIsSearching(true);
                    await performSearch(values);
                }}
            >
                {formikBag => (
                    <>
                        <TableActions formikBag={formikBag} />
                        <BigListWrapper>
                            <BookingsList
                                isSearching={isSearching}
                                isNextPageLoading={isNextPageLoading}
                                isLoading={loading}
                                hasNextPage={hasNextPage}
                                entries={bookings}
                                onLoadMore={onLoadMore}
                                withUnitFilter
                                noResultsText={formatMessage({
                                    defaultMessage:
                                        'Geen resultaten gevonden. Je kan zoeken op verhuureenheid, gast of reserveringsnummer',
                                })}
                            />
                        </BigListWrapper>
                    </>
                )}
            </Formik>
        </>
    );
};

export default All;

const TableActions: FC<React.PropsWithChildren<{ formikBag: FormikProps<BookingSearchValues> }>> = ({
    formikBag: { values, initialValues, submitForm, resetForm },
}) => {
    const ref = useRef<BookingSearchValues | null>(initialValues);
    const { formatMessage } = useIntl();
    useEffect(() => {
        // we only want to perform a search if the values have changed.
        if (!isEqual(ref.current, values)) {
            submitForm();
            ref.current = values;
        }
    }, [values, submitForm]);

    return (
        <TableActionsContainer>
            <Box flex={[1, null, 'none']} width={[1, null, 4 / 10]}>
                <TextInputField
                    name="searchQuery"
                    placeholder={formatMessage({
                        defaultMessage: 'Filter op verhuureenheid, gast of reserveringsnummer',
                    })}
                >
                    <Search />
                </TextInputField>
            </Box>
            <Box style={{ zIndex: 2 }} width={[1, '32.8rem']}>
                <RangePickerInputField size="medium" optionalEndDate enabledPast />
            </Box>
            <Box>
                <TextButton
                    size="tiny"
                    disabled={isEqual(initialSearchValues, values)}
                    onClick={() => resetForm({ values: initialSearchValues })}
                >
                    <Cross />
                    <span>
                        <FormattedMessage defaultMessage="Reset filters" />
                    </span>
                </TextButton>
            </Box>

            <Flex ml="auto" alignItems="center">
                {/* TODO: implement listactions

                <ActionMenu align={'right'} withButtonBorder title={'Lijst exporteren'}>
                    <MenuList>
                        <Item>Printen</Item>
                        <Item>Download als PDF</Item>
                        <Item>Delen</Item>
                    </MenuList>
                </ActionMenu> */}
            </Flex>
        </TableActionsContainer>
    );
};

export const TableActionsContainer = styled(Flex)`
    margin-top: ${({ theme }) => theme.spacing['60_Large']};
    margin-bottom: ${({ theme }) => theme.spacing['40_Standard']};
    align-items: center;
    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        flex-direction: column;
        align-items: flex-start;
    }

    > div + div {
        @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
            :not(:last-of-type) {
                margin-left: ${({ theme }) => theme.spacing['40_Standard']};
            }
        }
        @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
            margin-top: ${({ theme }) => theme.spacing['30_Small']};
        }
    }
`;
