import React, { FC } from 'react';
import { Box, Flex } from '@rebass/grid';
import ContentWrapper from '../atoms/ContentWrapper';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

const helpItems = (intl: IntlShape) => {
    const { formatMessage } = intl;
    return [
        {
            links: [
                {
                    text: formatMessage({ defaultMessage: 'Jouw gegevens en wachtwoord wijzigen' }),
                    link: 'https://youtu.be/TUSoFv8KMec',
                },
                {
                    text: formatMessage({ defaultMessage: 'Boekingen' }),
                    link: 'https://youtu.be/PcGiFrdkWuc',
                },
                {
                    text: formatMessage({ defaultMessage: 'Beoordelingen' }),
                    link: 'https://youtu.be/KrwtFQ6U-sI',
                },
                {
                    text: formatMessage({ defaultMessage: 'Beschikbaarheid accommodatie' }),
                    link: 'https://youtu.be/y4mZJUk8dKA',
                },
            ],
        },
        {
            title: formatMessage({ defaultMessage: 'Prijzen' }),
            links: [
                {
                    text: formatMessage({ defaultMessage: 'Inleiding uitleg prijzen (voor alle prijsvarianten)' }),
                    link: 'https://youtu.be/1UFjvgePHxk',
                },
                {
                    text: formatMessage({ defaultMessage: 'Alleen weekverhuur' }),
                    link: 'https://youtu.be/RcEJI2hmY_0',
                },
                {
                    text: formatMessage({ defaultMessage: 'Midweek / weekend / weekverhuur' }),
                    link: 'https://youtu.be/qT2j3gX7QZc',
                },
                {
                    text: formatMessage({ defaultMessage: 'Flexplanning' }),
                    link: 'https://youtu.be/tW-aYS0eVW0',
                },
                {
                    text: formatMessage({
                        defaultMessage: 'Aanpassen wisseldagen, nachtprijzen, minimum verblijf en weekprijs',
                    }),
                    link: 'https://youtu.be/_X4xybFknxg',
                },
                {
                    text: formatMessage({ defaultMessage: 'Afwijkende prijzen met toeslagen of kortingen' }),
                    link: 'https://youtu.be/WqxRneu9f3A',
                },
                {
                    text: formatMessage({
                        defaultMessage: 'Prijsberekening per persoon met of zonder 1 persoonstoeslag',
                    }),
                    link: 'https://youtu.be/3P9pigYtlZE',
                },
                {
                    text: formatMessage({ defaultMessage: 'Uitleg 1 nachttoeslag' }),
                    link: 'https://youtu.be/awu0i-na-yM',
                },
                {
                    text: formatMessage({ defaultMessage: 'Prijsberekening kamperen' }),
                    link: 'https://youtu.be/_lWDkq0pozE',
                },
                {
                    text: formatMessage({ defaultMessage: 'Prijsberekening met basisprijs+ nachtprijzen' }),
                    link: 'https://youtu.be/aSMtzP9TuEs',
                },
                {
                    text: formatMessage({ defaultMessage: 'Ingelezen prijzen' }),
                    link: 'https://youtu.be/Ruq8QjjAl4g',
                },
            ],
        },
        {
            title: formatMessage({ defaultMessage: 'Gegevens bij 1 of meerdere accommodaties' }),
            links: [
                {
                    text: formatMessage({ defaultMessage: 'Inleiding gegevens (voor 1 of meerdere accommodaties)' }),
                    link: 'https://youtu.be/tGe3My8i8Zs',
                },
                {
                    text: formatMessage({ defaultMessage: 'Accommodatie informatie' }),
                    link: 'https://youtu.be/fy5WzXgQgqo',
                },
                {
                    text: formatMessage({ defaultMessage: 'Ligging en afstanden' }),
                    link: 'https://youtu.be/Q_4dieY1YEw',
                },
                {
                    text: formatMessage({ defaultMessage: 'Gastheer/-vrouw gegevens' }),
                    link: 'https://youtu.be/_xMzfYm1dhA',
                },
                {
                    text: formatMessage({ defaultMessage: 'Capaciteit en afmetingen' }),
                    link: 'https://youtu.be/e8SCymrWe8g',
                },
                {
                    text: formatMessage({ defaultMessage: 'Kenmerken' }),
                    link: 'https://youtu.be/b9WTVFUY3pc',
                },
                {
                    text: formatMessage({ defaultMessage: 'Beschrijving' }),
                    link: 'https://youtu.be/_3U49qsa_Q8',
                },
                {
                    text: formatMessage({ defaultMessage: 'Foto’s vakantiehuis' }),
                    link: 'https://youtu.be/SVkoqMgsuTQ',
                },
                {
                    text: formatMessage({ defaultMessage: 'Sleuteladres' }),
                    link: 'https://youtu.be/0bgGp_OBnXo',
                },
                {
                    text: formatMessage({ defaultMessage: 'Overeenkomsten' }),
                    link: 'https://www.youtube.com/watch?v=jtkjkFw9Q38',
                },
                {
                    text: formatMessage({ defaultMessage: 'Kortingen en prijsregels' }),
                    link: 'https://youtu.be/TP2wqwrw520',
                },
                {
                    text: formatMessage({ defaultMessage: 'Opties en bijkomende kosten' }),
                    link: 'https://youtu.be/lbZgNWD5X7k',
                },
            ],
        },
    ];
};

const Help: FC<React.PropsWithChildren<unknown>> = () => {
    const intl = useIntl();
    const { formatMessage } = intl;
    return (
        <ContentWrapper>
            <Pagehead title={formatMessage({ defaultMessage: 'Uitleg' })}>
                <Box my={3}>
                    <Body>
                        <FormattedMessage defaultMessage="Hier is voor alle Travelbase gebruikers een uitgebreide handleiding te vinden:" />
                    </Body>
                    <ul>
                        <li>
                            <TextButton
                                style={{ display: 'inline-flex' }}
                                as="a"
                                href="/travelbase_handleiding.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FormattedMessage defaultMessage="Handleiding Travelbase" />
                            </TextButton>
                        </li>
                        <li>
                            <TextButton
                                style={{ display: 'inline-flex' }}
                                as="a"
                                href="/travelbase_activiteiten_en_tickets_handleiding.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FormattedMessage defaultMessage="Handleiding Tickets en Activiteiten" />
                            </TextButton>
                        </li>
                    </ul>
                </Box>
                <br />
                <FormattedMessage defaultMessage="Ook hebben we videotutorials (korte instructiefilmpjes) gemaakt:" />
            </Pagehead>

            {helpItems(intl).map((category, idxCat) => (
                <div key={`${category.title}_${idxCat}`}>
                    {category.title && <Title variant="small">{category.title}</Title>}
                    <ul>
                        {category.links.map((item, idxItem) => (
                            <li key={`${item.text}_${idxItem}`}>
                                <Flex>
                                    <TextButton as="a" href={item.link} target="_blank" rel="noopener noreferrer">
                                        {item.text}
                                    </TextButton>
                                </Flex>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </ContentWrapper>
    );
};

export default Help;
