import nl from 'date-fns/locale/nl';
import en from 'date-fns/locale/en-GB';
import de from 'date-fns/locale/de';

const locales = {
    nl,
    en,
    de,
};
export const getDateOpts = (locale: 'nl' | 'de' | 'en') => ({ locale: locales[locale] });
