import { createGlobalStyle } from 'styled-components';
import { getFontCss, Theme } from './constants/theme';

const GlobalStyle = createGlobalStyle<{ theme: Theme }>`

html {
        font-size: 10px;
        --min-viewport: 320;
        --max-viewport: ${({ theme }) => theme.mediaQueriesValues.xl};
    }

    * {
        box-sizing: border-box;
    }

    strong{
        font-weight: 500;
    }

    body {
        margin: 0;
        padding: 0;
        color: ${({ theme }) => theme.colors.neutral['70']};
        font-family: ${({ theme }) => theme.fontFamily.body};
        ${({ theme }) => getFontCss(theme.fontSizes.body.regular)};
    }

    p {
        margin: 2.4rem 0;
    }

    li {
        list-style: none;
    }

    input,
    textarea {
        font: inherit;
    }

    button {
        background: none;
        border: none;
        cursor: pointer;
        font-family: ${({ theme }) => theme.fontFamily.body};
    }
`;

export default GlobalStyle;
