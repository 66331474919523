import pick from 'lodash/pick';
import { PriceColumnPricingFragment, PricesRowVisibilityFragment } from '../generated/graphql';

// NOTE: this order matters and should match the order showed in the UI
export const visibilityKeys: Array<keyof PricesRowVisibilityFragment> = [
    'showArrivalAllowedRow',
    'showDepartureAllowedRow',
    'showNightPriceRow',
    'showExtraPersonPriceRow',
    'showMinimumStayDurationRow',
    'showMinimumStayPriceRow',
    'showBaseStayPriceRow',
    'showWeekPriceRow',
];

export const pricingAndVisibilityKeys: Record<
    keyof Omit<PricesRowVisibilityFragment, '__typename'>,
    keyof PriceColumnPricingFragment
> = {
    showArrivalAllowedRow: 'arrivalAllowed',
    showDepartureAllowedRow: 'departureAllowed',
    showNightPriceRow: 'nightPrice',
    showExtraPersonPriceRow: 'extraPersonPrice',
    showMinimumStayDurationRow: 'minimumStayDuration',
    showMinimumStayPriceRow: 'minimumStayPrice',
    showBaseStayPriceRow: 'baseStayPrice',
    showWeekPriceRow: 'weekPrice',
};

export default (
    data?: { rentalUnit?: PricesRowVisibilityFragment | null } | null
): PricesRowVisibilityFragment | null => (data?.rentalUnit ? pick(data.rentalUnit, visibilityKeys) : null);
