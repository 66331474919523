import * as React from 'react';
import { SVGAttributes } from 'react';

const Waterland = (props: SVGAttributes<SVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" id="Laag_2" viewBox="0 0 282.21 102.88" {...props}>
        <defs>
            <style>{'.cls-1{fill:#0072bc}'}</style>
        </defs>
        <g id="Layer_1">
            <path
                d="M151.18 71.98a9.146 9.146 0 0 1-3.48 1.36c-.3.05-.61.05-.91 0a9.17 9.17 0 0 1-4.6-2.18 8.328 8.328 0 0 1-1.57-1.84c-.81-1.29-1.23-2.68-1.24-4.18 0-.95.27-1.83.86-2.61.57-.75 1.33-1.26 2.3-1.46 1.07-.21 1.99.06 2.76.78.66.62 1.03 1.38 1.22 2.22.04.19.07.38.09.58.04.27.28.48.57.47.29 0 .53-.21.55-.49.07-.87.34-1.68.89-2.4.39-.51.88-.9 1.51-1.13.51-.18 1.04-.19 1.57-.09.74.13 1.37.45 1.89.94.93.87 1.36 1.93 1.37 3.13.02 1.46-.37 2.84-1.13 4.12-.44.74-.99 1.39-1.65 1.97-.32.28-.66.55-1.03.78"
                style={{
                    fill: '#ed1c24',
                }}
            />
            <path
                d="M134.57 67.03h-47.3v3.54h50.98M159.54 67.03l47.17.06v3.48h-50.59M64.06 77.48l.91 6.35.89-6.35h1.82l-1.82 12.7h-1.81l-1.81-12.7h1.81ZM73.86 90.18h-1.81v-3.63h-1.83v3.63h-1.81v-8.39l1.72-4.31h2.01l1.72 4.31v8.39Zm-3.63-5.46h1.83v-2.94l-.91-2.26-.91 2.26v2.94ZM75.78 77.48h1.81l1.83 6.35v-6.35h1.81v12.7h-1.81l-1.83-6.35v6.35h-1.81v-12.7ZM87.27 77.48h10.87v3.65h-7.23v5.44h5.44v3.61h-5.44v12.7h-3.65v-25.4ZM101.43 77.48h9.05c1.02 0 1.82.84 1.82 1.82v10.88c0 1.02-.8 1.82-1.82 1.82h-1.24l3.79 10.88h-3.83L105.33 92h-.29v10.88h-3.61v-25.4Zm7.26 10.91v-7.26h-3.65v7.26h3.65ZM116.5 77.48h3.61v25.4h-3.61v-25.4ZM127.92 81.13v5.44h5.4v3.61h-5.4v9.09h7.26v3.61h-10.87v-25.4h10.87v3.65h-7.26ZM138.1 97.44h3.65v1.82h3.61v-3.65l-6.71-8.83c-.33-.4-.55-.77-.55-1.28v-6.2c0-.99.84-1.82 1.82-1.82h7.26c.99 0 1.79.84 1.79 1.82v3.65h-3.61v-1.83h-3.61v3.61l6.71 8.87c.33.4.51.77.51 1.28v6.17c0 1.02-.8 1.82-1.79 1.82h-7.26c-.99 0-1.82-.8-1.82-1.82v-3.61ZM152.44 77.48h3.61v21.79h7.26v3.61h-10.87v-25.4ZM176.93 102.88h-3.61v-7.26h-3.65v7.26h-3.61V86.09l3.43-8.61h4.02l3.43 8.61v16.79Zm-7.26-10.91h3.65v-5.88l-1.82-4.53-1.82 4.53v5.88ZM180.76 77.48h3.61l3.65 12.7v-12.7h3.61v25.4h-3.61l-3.65-12.7v12.7h-3.61v-25.4ZM195.83 77.48h9.09c.98 0 1.79.84 1.79 1.82v21.75c0 1.02-.8 1.82-1.79 1.82h-9.09v-25.4Zm7.26 21.79V81.13h-3.61v18.14h3.61Z"
                className="cls-1"
            />
            <path d="M38.63 0v35.75l-7.38 18.34h-8.47l-3.5-8.63-3.42 8.63H7.31L0 35.75V0h7.7v35.75l3.89 9.72 3.89-9.72V0h7.69v35.75l3.89 9.72 3.81-9.72V0h7.77ZM69.56 54.1h-7.69V38.63H54.1V54.1h-7.7V18.34L53.71 0h8.55l7.31 18.34V54.1ZM54.09 30.86h7.77V18.35l-3.89-9.64-3.89 9.64v12.51ZM73.06 0h23.16v7.77h-7.69V54.1h-7.77V7.77h-7.69V0ZM111.3 7.77v11.58h11.5v7.69h-11.5v19.35h15.47v7.69h-23.16V0h23.16v7.77H111.3ZM134.54 0h19.28c2.18 0 3.89 1.79 3.89 3.89v23.16a3.85 3.85 0 0 1-3.89 3.89h-2.64l8.08 23.16h-8.16l-8.24-23.16h-.62V54.1h-7.7V0Zm15.47 23.24V7.77h-7.77v15.47h7.77ZM166.64 0h7.69v46.4h15.47v7.69h-23.16V0ZM218.79 54.1h-7.69V38.63h-7.77V54.1h-7.7V18.34L202.93 0h8.55l7.31 18.34V54.1Zm-15.47-23.24h7.77V18.35l-3.89-9.64-3.89 9.64v12.51ZM226.95 0h7.69l7.77 27.05V0h7.7v54.1h-7.7l-7.77-27.05V54.1h-7.69V0ZM259.04 0h19.35c2.1 0 3.81 1.79 3.81 3.89v46.32c0 2.18-1.71 3.89-3.81 3.89h-19.35V0Zm15.47 46.4V7.77h-7.7V46.4h7.7Z" />
        </g>
    </svg>
);
export default Waterland;
