import { FieldConfig, useField } from 'formik';
import React, { FC, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { getAssignmentColor } from '../../constants/theme';
import createBorderCss from '../../utils/createBorderCss';
import { Label } from '../primitive/Label';
import ErrorMessage from './ErrorMessage';

interface CheckboxFieldProps extends InputHTMLAttributes<HTMLInputElement> {
    error?: string;
}

const Checkbox: FC<React.PropsWithChildren<CheckboxFieldProps>> = ({ value, error, children, ...props }) => {
    const hasError = !!error;
    return (
        <label>
            <Container hasError={hasError}>
                <HiddenCheckbox checked={!!value} value={value} {...props} type="checkbox" />
                <StyledCheckbox hasError={hasError}>
                    <Icon>
                        <path d="M1 4.95l4.786 4.786L13.833 1.5" />
                    </Icon>
                </StyledCheckbox>

                <StyledLabel htmlFor={props.id}>{children}</StyledLabel>
            </Container>

            {error && <ErrorMessage>{error}</ErrorMessage>}
        </label>
    );
};

export const CheckboxField: FC<React.PropsWithChildren<InputHTMLAttributes<HTMLInputElement> & FieldConfig>> = ({
    name,
    validate,
    ...props
}) => {
    const [field, { touched, error }] = useField({ name, validate });
    return <Checkbox error={touched && error ? error : undefined} {...props} {...field} />;
};

const Icon = styled.svg`
    fill: none;
    stroke: white;
    stroke-width: 2px;
    width: 15px;
    height: 12px;
`;

const HiddenCheckbox = styled.input`
    position: absolute;
    left: 14px;
    bottom: 0;
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

const StyledLabel = styled(Label).attrs(() => ({ variant: 'regular' }))`
    margin-left: 1rem;
    font-weight: 400;
    user-select: none;
    ${HiddenCheckbox}:disabled ~ & {
        color: ${({ theme }) => theme.colors.neutral['30']};
    }
`;

const StyledCheckbox = styled.div<{ hasError: boolean }>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 25px;
    height: 25px;
    background: ${({ theme }) => theme.colors.neutral['0']};
    border-radius: 4px;
    transition: all 0.15s;
    border: none;
    ${createBorderCss('2px')}
    --border-color: ${({ theme, hasError }) => (!hasError ? theme.colors.neutral['20'] : theme.colors.negative['50'])};
`;

const Container = styled.div<{ hasError: boolean }>`
    display: flex;
    align-content: center;
    vertical-align: middle;
    cursor: default;
    position: relative;

    ${Icon} {
        visibility: hidden;
    }

    ${HiddenCheckbox}:focus + ${StyledCheckbox} {
        --border-color: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.input)};
    }

    ${HiddenCheckbox}:hover + ${StyledCheckbox} {
        --border-color: ${({ theme, hasError }) =>
            !hasError ? getAssignmentColor(theme, theme.colorAssignments.input) : theme.colors.negative['50']};
    }

    ${HiddenCheckbox}:checked + ${StyledCheckbox} {
        background: ${({ theme, hasError }) =>
            !hasError ? getAssignmentColor(theme, theme.colorAssignments.input) : theme.colors.negative['50']};
        --border-color: ${({ theme, hasError }) =>
            !hasError ? getAssignmentColor(theme, theme.colorAssignments.input) : theme.colors.negative['50']};

        ${Icon} {
            visibility: visible;
        }
    }

    ${HiddenCheckbox}:disabled + ${StyledCheckbox} {
        --border-color: ${({ theme }) => theme.colors.neutral['30']};
        cursor: not-allowed;
    }

    ${HiddenCheckbox}:checked:disabled + ${StyledCheckbox} {
        background: ${({ theme }) => theme.colors.neutral['30']};
    }
`;

export default Checkbox;
