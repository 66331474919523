import React, { FC, memo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import PickerWrapper from './PickerWrapper';
import { AccPickerEntryFragment } from '../../generated/graphql';
import AccPickerEntry from './AccPickerEntry';
import { UnitParams } from '../../entities/UnitParams';

interface Props {
    data: AccPickerEntryFragment[];
}

const AccPicker: FC<React.PropsWithChildren<Props>> = memo(({ data }) => {
    let entries = data.filter(entry => entry.rentalUnits.length > 0);
    const [open, setOpen] = useState(false);
    const match = useRouteMatch<UnitParams>();

    if (!entries) {
        return null;
    }

    const hasExactlyOne = entries.length === 1 && entries[0].rentalUnits.length === 1;

    const selectedAccommodation = entries.find(
        acc => match && acc.rentalUnits && acc.rentalUnits.map(unit => unit?.slug).includes(match.params.unitSlug)
    );

    if (selectedAccommodation) {
        entries = [selectedAccommodation, ...entries];
    }

    return (
        <PickerWrapper open={open} setOpen={setOpen} disableToggle={hasExactlyOne}>
            {entries.map(
                (accommodation, i) =>
                    accommodation && (
                        <AccPickerEntry
                            open={open}
                            setOpen={setOpen}
                            accommodation={accommodation}
                            key={accommodation.id}
                            first={i === 0}
                        />
                    )
            )}
        </PickerWrapper>
    );
});

export default AccPicker;
