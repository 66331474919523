import { Flex } from '@rebass/grid';
import transparentize from 'polished/lib/color/transparentize';
import React, { FC } from 'react';
import styled from 'styled-components';
import Overtitle from '../primitive/Overtitle';
import { Declined } from '../figure/Declined';
import { Warning } from '../figure/Warning';

interface ErrorOverlayProps {
    isWarning?: boolean;
}

const ErrorOverlay: FC<React.PropsWithChildren<ErrorOverlayProps>> = ({ children, isWarning }) => (
    <Container>
        <Flex mb={3} alignItems="center">
            {isWarning ? <WarningComponent /> : <ErrorComponent />}
        </Flex>
        {children}
    </Container>
);

function WarningComponent() {
    return (
        <>
            <Warning />
            <Overtitle style={{ marginLeft: '1rem' }}>waarschuwing</Overtitle>
        </>
    );
}

function ErrorComponent() {
    return (
        <>
            <Declined />
            <Overtitle style={{ marginLeft: '1rem' }}>foutmelding</Overtitle>
        </>
    );
}

export default ErrorOverlay;

const Container = styled.div`
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 2rem;
    background: ${({ theme }) => transparentize(0.25, theme.colors.neutral['70'])};
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.neutral['0']};

    > p {
        text-align: center;
        margin: 0;
        :not(:last-child) {
            ::after {
                content: ',';
            }
        }
    }
`;
