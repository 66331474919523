import { AccommodationImageCategoryEnum } from '../generated/graphql';

export const imageDescriptions: Record<AccommodationImageCategoryEnum, string> = {
    FRONT: 'Vooraanzicht',
    LIVING_ROOM: 'Woonkamer',
    KITCHEN: 'Keuken',
    BEDROOM: 'Slapen',
    ENTRANCE: 'Entree/Receptie',
    BATHROOM: 'Sanitair',
    OUTDOOR: 'Buitenruimte',
    VIEW: 'Uitzicht',
    FACILITIES: 'Faciliteiten',
    SURROUNDINGS: 'Omgeving',
    FLOOR_PLAN: 'Plattegrond',
    OTHER: 'Overig',
};
