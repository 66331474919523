import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import { Box } from '@rebass/grid';
import React, { FC, memo } from 'react';
import { areEqual, ListChildComponentProps } from 'react-window';
import styled from 'styled-components/macro';
import useBigList, { CreateRow, CreateSkeletonRows } from '../../../hooks/useBigList';
import { formatDuration } from '../../../utils/formatDuration';
import BigListWrapper from '../../atoms/BigListWrapper';

import BigTableHeader from '../../atoms/BigTableHeader';
import UnitDetails from '../../atoms/UnitDetails';
import BigList from '../../molecules/BigList';
import FilterUnitMenu from '../../molecules/FilterUnitMenu';
import { STEP } from '../Bookings';
import { getGuestTitle } from '../bookings/Details';

import {
    useDeleteVisitorRegistrationMutation,
    VisitorRegistrationConnection,
    RentalUnit,
    IdentificationTypeEnum,
    AllVisitorRegistrationsDocument,
    MissingVisitorRegistrationsDocument,
} from '../../../generated/graphql';
import Cross from '@oberoninternal/travelbase-ds/components/figure/Cross';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import gql from 'graphql-tag';
import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';
import { useParams } from 'react-router-dom';
import { useQueryParams, StringParam, withDefault, ArrayParam } from 'use-query-params';
import isValidDate from '../../../utils/isValidDate';
import { FormattedMessage, useIntl } from 'react-intl';

export const identificationTypeTranslations: Record<IdentificationTypeEnum, string> = {
    CARD: 'Identiteitskaart',
    DRIVING_LICENSE: 'Rijbewijs',
    PASSPORT: 'Paspoort',
};

export const mutation = gql`
    mutation DeleteVisitorRegistration($id: ID!) {
        deleteVisitorRegistration(input: { visitorRegistrationId: $id }) {
            id
        }
    }
`;

type VisitorRegistration = Pick<VisitorRegistrationConnection, 'totalCount'> & {
    edges?:
        | Maybe<{
              node?: Maybe<{
                  rentalUnit: Pick<RentalUnit, 'id' | 'name' | 'slug' | 'code' | 'thumbnailUrl' | 'hideForPartner'>;
                  arrivalDate: string;
                  departureDate: string;
                  firstName: string;
                  lastName: string;
                  city: string;
                  id: string;
                  identificationType: IdentificationTypeEnum;
              }>;
          }>[]
        | null;
    pageInfo: Pick<
        VisitorRegistrationConnection['pageInfo'],
        'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'
    >;
};

interface RegisterHistoryListProps {
    entries?: Maybe<VisitorRegistration>;
    isLoading: boolean;
    isSearching?: boolean;
    noResultsText: string;

    onLoadMore?: (startIndex: number, stopIndex: number) => Promise<void>;
    hasNextPage?: boolean;
    isNextPageLoading?: boolean;
}

const widths = [
    [3 / 16, null, 2 / 8],
    [3 / 16, null, 1 / 8],
    [3 / 16, null, 3 / 16],
    [3 / 16, null, 1 / 8],
    [3 / 16, null, 3 / 16],
    [3 / 16, null, 1 / 8],
];

const VisitorRegistrationHistoryList: FC<RegisterHistoryListProps> = memo(
    ({ entries, isLoading, noResultsText, isSearching }) => {
        const { createSkeletonRows, createRow } = useBigList(widths);
        const { formatMessage } = useIntl();

        const Item: FC<ListChildComponentProps> = props => <RegistrationItem {...props} />;
        const visitorRegistrations = entries?.edges;
        return (
            <BigListWrapper>
                <BigTableHeader>
                    {[
                        <FilterUnitMenu key="unit-filter" />,
                        formatMessage({ defaultMessage: 'Periode' }),
                        formatMessage({ defaultMessage: 'Gast ' }),
                        formatMessage({ defaultMessage: 'Woonplaats' }),
                        formatMessage({ defaultMessage: 'Identiteitsbewijs' }),
                        '',
                    ].map((header, i) => (
                        <Box key={i} width={widths[i]}>
                            {typeof header === 'string' ? <Label>{header}</Label> : header}
                        </Box>
                    ))}
                </BigTableHeader>

                {!isSearching ? (
                    !isLoading && visitorRegistrations?.length === 0 ? (
                        <Box p={4}>{noResultsText}</Box>
                    ) : (
                        <BigList
                            overscanCount={STEP * 2}
                            itemCount={visitorRegistrations?.length ?? 0}
                            itemSize={78}
                            itemData={{ edges: visitorRegistrations, createRow, createSkeletonRows }}
                        >
                            {Item}
                        </BigList>
                    )
                ) : (
                    createSkeletonRows(STEP)
                )}
            </BigListWrapper>
        );
    }
);

export default VisitorRegistrationHistoryList;

interface RegistrationItemProps extends ListChildComponentProps {
    data: {
        edges: VisitorRegistration['edges'];
        createRow: CreateRow;
        createSkeletonRows: CreateSkeletonRows;
    };
}

const RegistrationItem: FC<RegistrationItemProps> = memo(
    ({ index, style, data: { createRow, createSkeletonRows, edges } }) => {
        const registration = edges?.[index]?.node;
        const { partnerId } = useParams<{ partnerId: string }>();
        const [paramQuery] = useQueryParams({
            startDate: StringParam,
            endDate: StringParam,
            rentalUnitIds: withDefault(ArrayParam, []),
        });
        const { formatMessage } = useIntl();
        const validStartDate = paramQuery.startDate && isValidDate(paramQuery.startDate) ? paramQuery.startDate : null;
        const validEndDate = paramQuery.endDate && isValidDate(paramQuery.endDate) ? paramQuery.endDate : null;
        const [deleteMutate] = useDeleteVisitorRegistrationMutation({
            refetchQueries: [
                {
                    query: MissingVisitorRegistrationsDocument,
                    variables: {
                        partnerId,
                    },
                },
                {
                    query: AllVisitorRegistrationsDocument,
                    variables: {
                        partnerId,
                        startDate: validStartDate,
                        endDate: validEndDate,
                        rentalUnitIds: paramQuery.rentalUnitIds,
                        first: STEP * 4,
                    },
                },
            ],
        });

        if (!registration) {
            return createSkeletonRows(1, style);
        }
        const { id, rentalUnit, arrivalDate, departureDate, identificationType, firstName, lastName, city } =
            registration;

        const startDate = new Date(arrivalDate);
        const endDate = new Date(departureDate);
        const periodText = formatDuration(startDate, endDate);
        return createRow(undefined, style, [
            <StyledUnitDetails key={id} unit={{ ...rentalUnit }} />,
            { text: periodText },
            {
                text: getGuestTitle(
                    {
                        firstName,
                        lastName,
                    },
                    false
                ),
            },
            {
                text: city ?? '-',
            },
            {
                text: identificationTypeTranslations[identificationType as keyof typeof identificationTypeTranslations],
            },
            <TextButton
                key={id}
                onClick={async () => {
                    if (
                        !window.confirm(
                            formatMessage({
                                defaultMessage: `Weet je zeker dat je dit nachtregister wilt verwijderen?`,
                            })
                        )
                    ) {
                        return;
                    }

                    await deleteMutate({
                        variables: {
                            id,
                        },
                    });
                }}
                variant="danger"
                size="tiny"
            >
                <Cross />
                <span>
                    <FormattedMessage defaultMessage="Verwijderen" />
                </span>
            </TextButton>,
        ]);
    },
    areEqual
);

const StyledUnitDetails = styled(UnitDetails)`
    min-width: 100%;

    @media (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        > :first-child {
            display: none;
        }
    }
`;
