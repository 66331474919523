import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { UnreachableCaseError } from '../../entities/UnreachableCaseError';

export type OvertitleVariant = 'large' | 'regular' | 'small';

interface Props extends HTMLAttributes<{}> {
    variant?: OvertitleVariant;
    as?: keyof JSX.IntrinsicElements;
}

export const largeCss = css`
    font-size: 13px;
    letter-spacing: 0.4px;
    line-height: 16px;
`;

export const regularCss = css`
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 12px;
`;

export const smallCss = css`
    font-size: 10px;
    letter-spacing: 0.2px;
    line-height: 12px;
`;

const getCss = (variant: OvertitleVariant) => {
    switch (variant) {
        case 'large':
            return largeCss;
        case 'regular':
            return regularCss;
        case 'small':
            return smallCss;
        default:
            // ensures this switch is exhaustive
            throw new UnreachableCaseError(variant);
    }
};

export default styled.p<Props>`
    margin: 0;
    font-weight: 700;
    text-transform: uppercase;
    ${({ variant = 'regular' }) => getCss(variant)};
`;
