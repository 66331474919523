import { debounce } from 'debounce';
import { Formik } from 'formik';
import gql from 'graphql-tag';
import React, { useCallback, useState } from 'react';
import { ArrayParam, useQueryParams, withDefault } from 'use-query-params';
import {
    NewTicketsDocument,
    NewTicketsQuery,
    NewTicketsQueryVariables,
    TicketEdgeFragment,
} from '../../../generated/graphql';
import useInfiniteListQuery from '../../../hooks/useInfiniteListQuery';
import TicketsList from './TicketsList';

export const query = gql`
    query NewTickets(
        $partnerId: ID!
        $after: String
        $first: Int
        $before: String
        $last: Int
        $timeslotId: ID
        $activityIds: [ID!]
        $companyId: ID
    ) {
        partner(id: $partnerId) {
            id
            newTickets(
                first: $first
                last: $last
                after: $after
                before: $before
                timeslotId: $timeslotId
                activityIds: $activityIds
                companyId: $companyId
            ) {
                ...TicketConnection
            }
        }
    }
`;

interface Values {
    activityIds: string[];
}

const NewTickets = () => {
    const [{ activityIds = [] }, setQuery] = useQueryParams({ activityIds: withDefault(ArrayParam, []) });
    const { data, hasNextPage, loadMoreItems, isRefetching, isLoading } = useInfiniteListQuery<
        NewTicketsQuery,
        NewTicketsQueryVariables
    >({
        query: NewTicketsDocument,
        field: 'newTickets',
        variables: {
            activityIds: activityIds as string[],
        },
    });
    const edges =
        data?.partner?.newTickets?.edges?.filter((edge): edge is TicketEdgeFragment => !!edge?.node?.id) ?? [];
    const [submitting, setSubmitting] = useState(false);
    const searching = submitting || isRefetching;

    const isItemLoaded = (index: number) =>
        !searching && (!hasNextPage || index < edges.length) && !!edges[index]?.node;

    // TODO: use useMemo instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onSubmit = useCallback(
        debounce((values: Values) => {
            setQuery({ activityIds: values.activityIds });
            setSubmitting(false);
        }, 250),
        []
    );

    return (
        <Formik<Values> initialValues={{ activityIds: activityIds as string[] }} onSubmit={onSubmit}>
            <TicketsList
                edges={edges}
                isItemLoaded={isItemLoaded}
                loadMoreItems={loadMoreItems}
                onSubmit={onSubmit}
                totalCount={data?.partner?.newTickets?.totalCount}
                searching={searching}
                loading={isLoading}
            />
        </Formik>
    );
};

export default NewTickets;
