import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import { StepperField } from '@oberoninternal/travelbase-ds/components/form/Stepper';
import { Box, Flex } from '@rebass/grid';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { NotFoundError } from '../../../../entities/NotFoundError';
import { UnitParams } from '../../../../entities/UnitParams';
import { useCapacityQuery, useEditCapacityMutation, RentalUnitTypeEnum } from '../../../../generated/graphql';
import omitEqualValues from '../../../../utils/omitEqualValues';
import FieldSet from '../../../atoms/FieldSet';
import FieldSetHint from '../../../atoms/FieldSetHint';
import FieldHeading from '../../../molecules/FieldHeading';
import LabelBox from '../../../molecules/LabelBox';
import FormScreen from '../../../organisms/FormScreen';
import Loading from '../../../organisms/Loading';
import { capacityValidation } from './capacityValidation';
import unitTypes from '../../../../constants/unitTypes';
import { FormattedMessage, useIntl } from 'react-intl';

export const capacityFragment = gql`
    fragment Capacity on RentalUnit {
        id
        slug
        code
        type
        name
        minOccupancy
        maxOccupancy
        maxExtraBabyOccupancy
        includedOccupancy
        petsAllowed
        amountBedrooms
        amountBeds
        amountBathrooms
        amountToilets
        livingArea
        outdoorArea
        maxVehicleSize
    }
`;

export const capacityQuery = gql`
    query Capacity($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            ...Capacity
        }
    }
    ${capacityFragment}
`;

export const editCapacityMutation = gql`
    mutation EditCapacity($input: EditCapacityAndSpaceInput!) {
        editCapacityAndSpace(input: $input) {
            rentalUnit {
                ...Capacity
            }
        }
    }
    ${capacityFragment}
`;

const Capacity: FC<React.PropsWithChildren<UnitParams>> = ({ unitSlug }) => {
    const { data, loading } = useCapacityQuery({ variables: { unitSlug } });
    const [mutate] = useEditCapacityMutation();
    const intl = useIntl();
    const { formatMessage } = intl;
    if (loading) {
        return <Loading />;
    }

    if (!data || !data.rentalUnit) {
        throw new NotFoundError();
    }

    const {
        rentalUnit: {
            id,
            name,
            type,
            amountBathrooms,
            amountBedrooms,
            amountBeds,
            amountToilets,
            includedOccupancy,
            livingArea,
            maxOccupancy,
            minOccupancy,
            outdoorArea,
            petsAllowed,
            maxExtraBabyOccupancy,
            maxVehicleSize,
        },
    } = data;

    const initialValues = {
        amountBathrooms: amountBathrooms ?? null,
        amountBedrooms: amountBedrooms ?? null,
        amountBeds: amountBeds ?? null,
        amountToilets: amountToilets ?? null,
        includedOccupancy: includedOccupancy ?? 0,
        livingArea: livingArea ?? 0,
        maxOccupancy: maxOccupancy ?? 0,
        minOccupancy: minOccupancy ?? 0,
        maxExtraBabyOccupancy: maxExtraBabyOccupancy ?? 0,
        outdoorArea: outdoorArea ?? 0,
        petsAllowed: petsAllowed ?? 0,
        maxVehicleSize: maxVehicleSize ?? 6,
    };

    return (
        <FormScreen
            validationSchema={capacityValidation(intl)}
            handleSubmit={async values => {
                await mutate({
                    variables: {
                        input: {
                            rentalUnitId: id,
                            ...omitEqualValues(values, initialValues, []),
                            livingArea: values.livingArea === 0 ? null : values.livingArea,
                        },
                    },
                });
            }}
            initialValues={initialValues}
        >
            {({ values }) => (
                <>
                    <Pagehead title={formatMessage({ defaultMessage: 'Capaciteit en afmetingen' })}>
                        <FormattedMessage
                            defaultMessage="Hier beheer je een aantal basiskenmerken van {name}."
                            values={{ name }}
                        />
                    </Pagehead>
                    <FieldHeading title={formatMessage({ defaultMessage: 'Capaciteit' })}>
                        <FormattedMessage defaultMessage="Voor hoeveel personen en huisdieren is jouw accommodatie maximaal geschikt? Let op: als je baby's toegestaan als extra persoon gebruikt en je vult hier bijvoorbeeld 1 in, wordt je 4 persoons accommodatie ook boekbaar voor mensen die met 4 personen + 1 baby zoeken." />
                    </FieldHeading>

                    <FieldSet>
                        <LabelBox>
                            <Label>
                                <FormattedMessage defaultMessage="Maximum aantal personen" />
                            </Label>
                        </LabelBox>
                        <StepperField editable minimum={values.minOccupancy} name="maxOccupancy" maximum={120} />
                    </FieldSet>
                    <FieldSet>
                        <LabelBox>
                            <Label>
                                <FormattedMessage defaultMessage="Minimum aantal personen" />
                            </Label>
                        </LabelBox>
                        <StepperField editable minimum={1} maximum={values.maxOccupancy} name="minOccupancy" />
                    </FieldSet>
                    <FieldSet>
                        <LabelBox>
                            <Label>
                                <FormattedMessage defaultMessage="Inbegrepen personen" />
                            </Label>
                            <FieldSetHint>
                                <FormattedMessage
                                    defaultMessage=' Dit is standaard het maximum aantal personen. Als je inbegrepen personen lager maakt dan
                                het maximum aantal personen, kun je met de prijsoptie "Prijs extra persoon"
                                een toeslag voor iedere extra persoon (tot de maximale capaciteit van je accommodatie)
                                instellen.'
                                />
                            </FieldSetHint>
                        </LabelBox>
                        <StepperField editable minimum={0} maximum={120} name="includedOccupancy" />
                    </FieldSet>
                    <FieldSet>
                        <LabelBox>
                            <Label>
                                <FormattedMessage defaultMessage="Baby toegestaan" />
                            </Label>
                            <FieldSetHint>
                                <FormattedMessage defaultMessage="Als extra persoon in een babybed (zonder bedlinnen)." />
                            </FieldSetHint>
                        </LabelBox>
                        <StepperField maximum={2} name="maxExtraBabyOccupancy" />
                    </FieldSet>
                    <FieldSet>
                        <LabelBox>
                            <Label>
                                <FormattedMessage defaultMessage="Maximaal aantal huisdieren" />
                            </Label>
                        </LabelBox>
                        <StepperField minimum={0} name="petsAllowed" />
                    </FieldSet>
                    <FieldHeading title={formatMessage({ defaultMessage: 'Afmetingen binnen- en buitenruimte' })}>
                        <FormattedMessage defaultMessage=" Geef de oppervlakte op van de onderstaande ruimte(s)." />
                    </FieldHeading>
                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="livingArea">
                                {unitTypes.find(unitType => type === unitType.value)?.label}
                            </Label>
                            <FieldSetHint>
                                <FormattedMessage
                                    defaultMessage="Het gaat hier om de totale bewoonbare oppervlakte van je accommodatie (alle verdiepingen
                                bij elkaar opgeteld)."
                                />
                            </FieldSetHint>
                        </LabelBox>
                        <Flex width="20rem">
                            {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                            <TextInputField type="number" id="livingArea" placeholder="0" name="livingArea" />
                            <Box pl={4} mt={3}>
                                <FormattedMessage defaultMessage="m2" />
                            </Box>
                        </Flex>
                    </FieldSet>
                    {(type === RentalUnitTypeEnum.CamperSpot || type === RentalUnitTypeEnum.CampingPitch) && (
                        <FieldSet>
                            <LabelBox>
                                <Label htmlFor="maxVehicleSize">
                                    <FormattedMessage defaultMessage="Maximale lengte voertuig" />
                                </Label>
                                <FieldSetHint>
                                    <FormattedMessage defaultMessage="Geef de maximale lengte van het voertuig op dat op deze plek past." />
                                </FieldSetHint>
                            </LabelBox>
                            <Flex width="20rem">
                                <TextInputField
                                    type="number"
                                    id="maxVehicleSize"
                                    // eslint-disable-next-line formatjs/no-literal-string-in-jsx
                                    placeholder="6"
                                    name="maxVehicleSize"
                                    min={6}
                                    max={12}
                                />
                                <Box pl={4} mt={3}>
                                    <FormattedMessage defaultMessage="m" />
                                </Box>
                            </Flex>
                        </FieldSet>
                    )}
                    {(type === RentalUnitTypeEnum.Home || type === RentalUnitTypeEnum.Tent) && (
                        <FieldSet>
                            <LabelBox>
                                <Label htmlFor="outdoorArea">
                                    <FormattedMessage defaultMessage="Buitenruimte" />
                                </Label>
                                <FieldSetHint>
                                    <FormattedMessage
                                        defaultMessage="Het gaat hier om de buitenruimte die jouw gasten kunnen gebruiken. Dat kan alleen
                                        een balkon of terras zijn, maar ook een groot terrein rond je boerderij."
                                    />
                                </FieldSetHint>
                            </LabelBox>
                            <Flex width="20rem">
                                {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                                <TextInputField type="number" id="outdoorArea" placeholder="0" name="outdoorArea" />
                                <Box pl={4} mt={3}>
                                    <FormattedMessage defaultMessage="m2" />
                                </Box>
                            </Flex>
                        </FieldSet>
                    )}
                    {(type === RentalUnitTypeEnum.Home || type === RentalUnitTypeEnum.Tent) && (
                        <>
                            <FieldHeading title={formatMessage({ defaultMessage: 'Slapen' })}>
                                <FormattedMessage defaultMessage="Geef het aantal slaapkamers en bedden op." />
                            </FieldHeading>
                            <FieldSet>
                                <LabelBox>
                                    <Label>
                                        <FormattedMessage defaultMessage="Aantal slaapkamers" />
                                    </Label>
                                    <FieldSetHint>
                                        <FormattedMessage
                                            defaultMessage="Aantal kamers die puur bedoeld {br} zijn als slaapkamer"
                                            values={{ br: <br /> }}
                                        />
                                    </FieldSetHint>
                                </LabelBox>
                                <StepperField nullable minimum={0} name="amountBedrooms" />
                            </FieldSet>
                            <FieldSet>
                                <LabelBox>
                                    <Label>
                                        <FormattedMessage defaultMessage="Aantal bedden" />
                                    </Label>
                                    <FieldSetHint>
                                        <FormattedMessage defaultMessage="Een aaneengesloten tweepersoonsbed telt hier als één bed" />
                                    </FieldSetHint>
                                </LabelBox>
                                <StepperField nullable minimum={0} name="amountBeds" />
                            </FieldSet>
                        </>
                    )}
                    {(type === RentalUnitTypeEnum.Home || type === RentalUnitTypeEnum.Tent) && (
                        <>
                            <FieldHeading title={formatMessage({ defaultMessage: 'Sanitair' })}>
                                <FormattedMessage defaultMessage="Geef het aantal badkamers en toiletten op." />
                            </FieldHeading>
                            <FieldSet>
                                <LabelBox>
                                    <Label>
                                        <FormattedMessage defaultMessage="Aantal badkamers" />
                                    </Label>
                                </LabelBox>
                                <StepperField nullable minimum={0} name="amountBathrooms" />
                            </FieldSet>
                            <FieldSet>
                                <LabelBox>
                                    <Label>
                                        <FormattedMessage defaultMessage="Aantal toiletten" />
                                    </Label>
                                    <FieldSetHint>
                                        <FormattedMessage defaultMessage="Tel ook toiletten in badkamers mee" />
                                    </FieldSetHint>
                                </LabelBox>
                                <StepperField nullable minimum={0} name="amountToilets" />
                            </FieldSet>
                        </>
                    )}
                </>
            )}
        </FormScreen>
    );
};

export default Capacity;
