import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Hook for triggering a callback when the route changed
 * @param callback the callback that should be triggered after a route change
 *
 */
const useOnRouteChange = (callback: () => void) => {
    const history = useHistory();
    const locationRef = useRef(history.location);

    useEffect(
        () =>
            history.listen(location => {
                if (locationRef.current !== location) {
                    callback();
                    locationRef.current = location;
                }
            }),
        [callback, history]
    );
};

export default useOnRouteChange;
