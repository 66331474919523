import { PriceColumnKey } from './priceRows';

export const PRICE_RULE_CONTENT: Record<PriceColumnKey, string> = {
    arrivalAllowed: 'Aankomstdagen',
    departureAllowed: 'Vertrekdagen',
    nightPrice: 'Nachtprijs (€)',
    extraPersonPrice: 'Prijs extra persoon',
    minimumStayDuration: 'Minimum verblijfsduur',
    minimumStayPrice: 'Minimum verblijfsprijs',
    baseStayPrice: 'Basis verblijfsprijs',
    weekPrice: 'Weekprijs',
};

export const ALWAYS_VISIBLE_PRICE_RULES: PriceColumnKey[] = [
    'arrivalAllowed',
    'departureAllowed',
    'nightPrice',
    'minimumStayDuration',
];
