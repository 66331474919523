import { IntlShape } from 'react-intl';

const createPublicLinkProps = (path: string, intl: IntlShape) => ({
    href: `${process.env.REACT_APP_PUBLIC_URL}/${path}`,
    target: '_blank',
    rel: 'noopener noreferrer',
    children: intl.formatMessage({ defaultMessage: `Bekijk op website` }),
});

export default createPublicLinkProps;
