import { css } from 'styled-components/macro';

export const entryCss = css<{ open: boolean }>`
    color: unset;
    text-decoration: none;
    height: inherit;
    width: 100%;
    position: relative;
    display: block;
    background: ${({ open, theme }) => (open ? theme.colors.neutral[0] : 'inherit')};

    :hover {
        background: ${({ open, theme }) => (open ? theme.colors.neutral[10] : 'inherit')};
    }
`;
