import React from 'react';
import CamperContactLogo from '../img/logos/logo_campercontact';
import Schier from '../img/logos/logo_schier';
import Terschelling from '../img/logos/logo_terschelling';
import Texel from '../img/logos/logo_texel';
import Travelbase from '../img/logos/logo_travelbase';
import UitInDeRegio from '../img/logos/logo_uitinderegio';
import Waterland from '../img/logos/logo_waterland';

const logoMap = {
    texel: Texel,
    terschelling: Terschelling,
    ameland: '../img/logos/logo_ameland.png',
    schier: Schier,
    uitinderegio: UitInDeRegio,
    waterland: Waterland,
    travelbase: Travelbase,
    campercontact: CamperContactLogo,
};

const getLogoForBrand = (): JSX.Element => {
    const brand = process.env.REACT_APP_BRAND ?? 'travelbase';
    const Logo = logoMap[brand as keyof typeof logoMap] ?? logoMap.travelbase;
    if (typeof Logo === 'string') {
        return <img src={(logoMap[brand as keyof typeof logoMap] as string) ?? ''} alt={brand} />;
    }

    return <Logo width={300} />;
};

export default getLogoForBrand;
