import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { getFontCss } from '@oberoninternal/travelbase-ds/constants/theme';
import { Box, Flex } from '@rebass/grid';
import {
    differenceInMonths,
    endOfMonth,
    getYear,
    isWithinInterval,
    setMonth,
    setYear,
    startOfMonth,
    startOfYear,
} from 'date-fns';
import range from 'lodash/range';
import React, { FC, memo } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components/macro';
import { AllotmentType } from '../../entities/AllotmentType';
import {
    AvailabilityDocument,
    AvailabilityMetaDataFragment,
    PriceColumnAllotmentFragment,
} from '../../generated/graphql';
import { useAllotmentProps } from '../../hooks/useAllotmentProps';
import getActiveEvents from '../../utils/getActiveEvents';
import Month, { MonthProps } from '../molecules/Month';
import SkeletonMonth from '../molecules/SkeletonMonth';
import propsAreEqual from '@oberoninternal/travelbase-ds/utils/propsAreEqual';
import { FormattedMessage } from 'react-intl';

interface Props
    extends Pick<
        MonthProps,
        | 'lockoutCreationProps'
        | 'isMultiple'
        | 'rowVisibility'
        | 'variables'
        | 'keysPressed'
        | 'lastFocused'
        | keyof AvailabilityMetaDataFragment
    > {
    yearIndex: number;
    events: AllotmentType[];
    allotments: PriceColumnAllotmentFragment[];
    onLoadMore?: () => void;
    isLoadingMore: boolean;
}

const Year: FC<React.PropsWithChildren<Props>> = memo(
    ({ yearIndex, events, allotments, onLoadMore, isLoadingMore, ...props }) => {
        const start = startOfYear(setYear(new Date(), yearIndex));
        const end = startOfYear(setYear(new Date(), yearIndex + 1));
        const diff = differenceInMonths(end, start);

        const allotmentProps = useAllotmentProps(AvailabilityDocument, props.variables);
        return (
            <Container>
                <TitleWrapper>
                    <StyledTitle variant="large">
                        <FormattedMessage defaultMessage="Beschikbaarheid" />
                        {getYear(start)}
                    </StyledTitle>
                </TitleWrapper>
                <Months>
                    {range(0, diff).map(i => {
                        const monthDate = startOfMonth(setMonth(start, i));

                        const interval: Interval = {
                            start: monthDate,
                            end: endOfMonth(monthDate),
                        };

                        // only pass events that are applicable to this date to avoid unnecessary rerenders
                        const activeEvents = getActiveEvents(events, interval);

                        const activeAllotments = allotments.filter(allotment =>
                            isWithinInterval(new Date(allotment.date), interval)
                        );

                        return (
                            <Month
                                key={i}
                                start={new Date(interval.start)}
                                events={activeEvents}
                                allotments={activeAllotments}
                                allotmentProps={allotmentProps}
                                onLoadMore={i === diff - 1 ? onLoadMore : undefined}
                                {...props}
                            />
                        );
                    })}
                    {isLoadingMore && range(3).map(i => <SkeletonMonth key={i} />)}
                </Months>
            </Container>
        );
    },
    propsAreEqual<Props>({
        events: 'deep',
        allotments: 'deep',
        variables: 'deep',
        rowVisibility: 'deep',
        lockoutCreationProps: (prev, next) => Object.keys(prev).length === Object.keys(next).length,
    })
);

export const SkeletonYear = () => (
    <Container>
        <TitleWrapper>
            <Skeleton width={300} height={32} />
        </TitleWrapper>
        <Months>
            {range(12).map(i => (
                <SkeletonMonth key={i} />
            ))}
        </Months>
    </Container>
);

const Container = styled(Flex).attrs({ flexDirection: 'column', px: [3, null, 4] })`
    flex: 1;
    position: relative;

    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        margin-right: 42.4rem;
    }
`;

const Months = styled(Flex).attrs({
    alignItems: 'flex-start',
    py: [null, null, 20, 20],
    flexWrap: 'wrap',
    flexDirection: ['column', null, 'row'],
})``;

const StyledTitle = styled(Title)`
    ${({ theme }) => getFontCss(theme.fontSizes.title.regular)};
    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        ${({ theme }) => getFontCss(theme.fontSizes.title.large)};
        margin-left: 0.7rem;
    }
`;

const TitleWrapper = styled(Box)`
    position: sticky;
    top: ${({ theme }) => theme.heights.mobileNav}px;
    padding: 2rem 0;

    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        top: ${({ theme }) => theme.heights.nav + theme.heights.unitNav}px;
    }
    z-index: 10;
    background: white;
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[20]};
`;

export default Year;
