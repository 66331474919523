import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Tooltip from '@oberoninternal/travelbase-ds/components/feedback/Tooltip';
import { Edit } from '@oberoninternal/travelbase-ds/components/figure/Edit';
import Plus from '@oberoninternal/travelbase-ds/components/figure/Plus';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import propsAreEqual from '@oberoninternal/travelbase-ds/utils/propsAreEqual';
import { Box } from '@rebass/grid';
import React, { FC, memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { PriceColumnKey, PRICE_COLUMN_ROWS } from '../../constants/priceRows';
import { useSidebar } from '../../context/sidebar';
import { PricesQueryVariables, PricesRowVisibilityFragment, Scalars } from '../../generated/graphql';
import { visibilityKeys } from '../../utils/getRowVisibility';
import FieldSetHint from '../atoms/FieldSetHint';
import Link from '../atoms/Link';
import { InfobarCell } from './Infobar';
import { PERIOD_RULE_GAP, PERIOD_RULE_HEIGHT } from './PeriodRules';

interface Props {
    periodLabels: string[];
    rentalUnitId?: Scalars['ID']['output'];
    pricesVars?: PricesQueryVariables;
    resetForm: () => void;
    rowVisibility: PricesRowVisibilityFragment | null;
    datePricingStartDate?: Date;
    withWizardButton: boolean;
}

const priceRowHints: Record<PriceColumnKey, string> = {
    extraPersonPrice: '',
    minimumStayDuration: 'vanaf aankomstdag',
    minimumStayPrice: '',
    baseStayPrice: '',
    weekPrice: 'optioneel',
    nightPrice: '',
    arrivalAllowed: '',
    departureAllowed: '',
};

const PricesInfobar: FC<React.PropsWithChildren<Props>> = memo(
    ({ periodLabels, rentalUnitId, pricesVars, resetForm, rowVisibility, withWizardButton }) => {
        const [, dispatch] = useSidebar();
        const { pathname } = useLocation();
        const { formatMessage } = useIntl();
        const handleOnBulkClick = (row: PriceColumnKey) => {
            resetForm();
            if (rentalUnitId) {
                dispatch({
                    type: 'show',
                    data: {
                        type: 'BULK_SIDEBAR',
                        row,
                        unitId: rentalUnitId,
                    },
                });
            }
        };
        return (
            <>
                {Object.entries(PRICE_COLUMN_ROWS)
                    .filter((_, i) => (rowVisibility ? rowVisibility[visibilityKeys[i]] : true))
                    .map(([key, label]) => {
                        const buttonProps = {
                            onClick: () => handleOnBulkClick(key as PriceColumnKey),
                            disabled: !rowVisibility,
                            size: 'tiny' as const,
                        };
                        return (
                            <InfobarCell key={key} style={{ position: 'relative' }}>
                                <Label>
                                    {!rowVisibility ? (
                                        <Skeleton width={formatMessage(label).length * 6} />
                                    ) : (
                                        formatMessage(label)
                                    )}
                                    &nbsp;
                                    <FieldSetHint>{priceRowHints[key as PriceColumnKey]}</FieldSetHint>
                                </Label>
                                <Tooltip
                                    key={`tooltip-${label}`}
                                    placement="right"
                                    style={{
                                        maxWidth: '18rem',
                                    }}
                                    modifiers={{
                                        offset: {
                                            offset: '0, 20',
                                        },
                                    }}
                                    label={
                                        <Body variant="small">
                                            <FormattedMessage defaultMessage="Meerdere dagen tegelijk aanpassen" />
                                        </Body>
                                    }
                                >
                                    <DesktopBulkButton {...buttonProps}>
                                        <FormattedMessage defaultMessage="Bulkaanpassing" />
                                    </DesktopBulkButton>
                                </Tooltip>
                                <MobileBulkButton {...buttonProps}>
                                    <Edit />
                                </MobileBulkButton>
                            </InfobarCell>
                        );
                    })}

                <PeriodLabels>
                    <Label>
                        <FormattedMessage defaultMessage="Korting of toeslag" />
                    </Label>
                    {periodLabels.map(label => (
                        <PeriodLabel key={label}>
                            {label}&nbsp;
                            {label === '1' ? (
                                <FormattedMessage defaultMessage="nacht" />
                            ) : (
                                <FormattedMessage defaultMessage="nachten" />
                            )}
                        </PeriodLabel>
                    ))}
                    {rentalUnitId && (
                        <Box pt={20} pb={[6, null]}>
                            <TextButton
                                style={{ pointerEvents: 'all' }}
                                onClick={() =>
                                    dispatch({
                                        type: 'show',
                                        data: {
                                            rentalUnitId,
                                            pricesVars,
                                            type: 'PERIOD_RULE',
                                        },
                                    })
                                }
                                size="tiny"
                            >
                                <Plus />
                                &nbsp;
                                <span>
                                    <FormattedMessage defaultMessage="toevoegen" />
                                </span>
                            </TextButton>
                        </Box>
                    )}
                </PeriodLabels>
                {withWizardButton && (
                    <WizardButtonContainer>
                        <Button
                            variant="outline"
                            style={{ padding: '0 1.6rem', pointerEvents: 'all', maxWidth: '16rem' }}
                            as={Link}
                            to={{
                                pathname: `${pathname}/wizard`,
                            }}
                        >
                            <FormattedMessage defaultMessage="Periode instellen" />
                        </Button>
                    </WizardButtonContainer>
                )}
            </>
        );
    },
    propsAreEqual<Props>({
        resetForm: 'skip',
        periodLabels: 'deep',
    })
);

const MobileBulkButton = styled(TextButton)`
    pointer-events: all;
    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        display: none;
    }
`;

const ToolTip = styled.span`
    display: none;
    width: 120px;
    background-color: ${({ theme }) => theme.colors.primary[100]};
    color: #fff;
    text-align: center;
    padding: 10px 0;

    position: absolute;
    z-index: 1;
    width: 12rem;
    top: -10px;
    left: 110%;
    border-radius: 0.5rem;

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 100%;
        margin-top: -10px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent ${({ theme }) => theme.colors.primary[100]} transparent transparent;
    }
`;

const DesktopBulkButton = styled(TextButton)`
    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        display: none;
    }
    > span {
        position: relative;
        &:hover ${ToolTip} {
            display: block;
        }
    }
`;

const PeriodLabels = styled.div`
    padding: 3.2rem 1.9rem;
    border-top: 2px solid ${({ theme }) => theme.colors.neutral[20]};

    ${Label} {
        height: 8px;
    }

    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        padding-bottom: 13rem;
    }
`;

const PeriodLabel = styled.div`
    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        height: ${PERIOD_RULE_HEIGHT * 2}px;
        align-items: flex-start;
        margin-top: ${PERIOD_RULE_GAP}px;
    }
    height: ${PERIOD_RULE_HEIGHT + PERIOD_RULE_GAP}px;
    display: flex;
    align-items: center;
`;

const WizardButtonContainer = styled(Box)`
    bottom: 120px;
    flex-shrink: 0;
    position: sticky;
    padding: 1.6rem;
    left: 0;
    display: flex;
    justify-content: center;
    @media (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
        background: white;
        width: 100%;
        border-top: 1px solid ${({ theme }) => theme.colors.neutral[20]};
        bottom: 70px;
        justify-content: flex-start;
    }
    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        bottom: 1.2rem;
    }
    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        bottom: 0;
        margin-top: auto;
    }
`;

export default PricesInfobar;
