import styled from 'styled-components';

const Spinner = styled.div`
    border: 2px solid rgba(16, 36, 48, 0.1);
    animation: spin 1s linear infinite;
    border-top: 2px solid currentColor;
    border-radius: 50%;
    margin: 0 0.4rem;
    width: 24px;
    height: 24px;
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export default Spinner;
