import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import { Warning } from '@oberoninternal/travelbase-ds/components/figure/Warning';
import { FormActionsContainer } from '@oberoninternal/travelbase-ds/components/form/FormActions';
import SelectInput, { OptionType } from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import dateTextFormat from '@oberoninternal/travelbase-ds/constants/dateTextFormat';
import { Box, Flex } from '@rebass/grid';
import parseISO from 'date-fns/parseISO';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import React, { FC } from 'react';
import { generatePath, Link, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import dateFormat from '../../constants/dateFormat';
import { getDateOpts } from '../../constants/dateOpts';
import hourFormat from '../../constants/hourFormat';
import { useSidebar } from '../../context/sidebar';
import { useVirtualizerScrollData } from '../../context/virtualizerScrollData';
import { ActivityParams } from '../../entities/ActivityParams';
import {
    ActivityTimeslotFragment,
    ActivityTimeslotsFragment,
    CreateActivityTimeslotMutation,
    DeleteActivityTimeslotMutation,
    TimeslotInput,
    TimeslotsQueryVariables,
    useCreateActivityTimeslotMutation,
    useDeleteActivityTimeslotMutation,
    useEditActivityTimeslotMutation,
} from '../../generated/graphql';
import useTimes from '../../hooks/useTimes';
import createTimeslotsUpdater from '../../utils/createTimeslotsUpdater';
import getDatesFromScrollContext from '../../utils/getDatesFromScrollContext';
import SidebarField from '../atoms/SidebarField';
import SidebarIntro from '../atoms/SidebarIntro';
import TimeslotAllotmentField from '../atoms/TimeslotAllotmentField';
import FormScreen from '../organisms/FormScreen';
import { RootSidebarData } from './SidebarContent';
import { FormattedMessage, useIntl } from 'react-intl';

export interface TimeslotsRootSidebarData extends RootSidebarData {
    type: 'TIMESLOTS';
    rateGroups: ActivityTimeslotsFragment['activityRateGroups'];
    variables: TimeslotsQueryVariables;
    activityId: string;
}

interface AddData {
    date: Date;
    slot?: never;
}

interface EditData {
    slot: ActivityTimeslotFragment;
    date?: never;
}

export type TimeslotsSidebarData = TimeslotsRootSidebarData & (AddData | EditData);

interface Props {
    data: TimeslotsSidebarData;
}

const getInitialValues = (date: Date): TimeslotInput => ({
    allotment: 0,
    startDateTime: `${format(date, dateFormat)} 12:00`,
    endDateTime: `${format(date, dateFormat)} 13:00`,
    rateGroupId: '',
    translations: [
        { label: '', locale: 'nl' },
        { label: '', locale: 'de' },
        { label: '', locale: 'en' },
    ],
});

export const toInput = (slot: ActivityTimeslotFragment) => ({
    allotment: slot.allotment,
    rateGroupId: slot.rateGroup.id,
    startDateTime: format(new Date(slot.startDateTime), `${dateFormat} ${hourFormat}`),
    endDateTime: format(new Date(slot.endDateTime), `${dateFormat} ${hourFormat}`),
    translations: [
        { label: slot.label ?? '', locale: 'nl' },
        { label: slot.deLabel ?? '', locale: 'de' },
        { label: slot.enLabel ?? '', locale: 'en' },
    ],
});

const TimeslotsSidebar: FC<React.PropsWithChildren<Props>> = ({
    data: { rateGroups, slot, activityId, variables, ...data },
}) => {
    const scrollData = useVirtualizerScrollData();
    const [create] = useCreateActivityTimeslotMutation();
    const [remove] = useDeleteActivityTimeslotMutation();
    const [edit] = useEditActivityTimeslotMutation();
    const params = useParams<ActivityParams>();
    const times = useTimes(5);
    const [state, dispatch] = useSidebar();
    const { formatMessage, locale } = useIntl();
    const dateOpts = getDateOpts(locale as 'nl' | 'de' | 'en');
    const validationSchema = Yup.object().shape({
        allotment: Yup.number().nullable(),
        startDateTime: Yup.string().required(formatMessage({ defaultMessage: 'Kies een starttijd' })),
        endDateTime: Yup.string().required(formatMessage({ defaultMessage: 'Kies een eindtijd' })),
        rateGroupId: Yup.string().required(formatMessage({ defaultMessage: 'Kies een tariefgroep' })),
    });
    const date = data.date ?? new Date(slot?.startDateTime);
    const timeOptions = times.map(
        (time): OptionType => ({ label: time.label, value: `${format(date, dateFormat)} ${time.value}` })
    );
    const rateGroupOptions = rateGroups.map(
        (rateGroup): OptionType => ({ label: rateGroup.name!, value: rateGroup.id })
    );
    const hasRateGroups = !!rateGroupOptions.length;
    const currDates = getDatesFromScrollContext(scrollData);

    const handleEditTimeslot = async (timeslotId: string, { translations, ...values }: TimeslotInput) => {
        const timeslot: TimeslotInput = {
            ...values,
            translations: translations?.filter(translation => !!translation.label),
        };
        if (!currDates) {
            return;
        }
        await edit({
            variables: {
                ...currDates,
                input: {
                    timeslotId,
                    timeslot,
                },
            },
        });
        dispatch({ type: 'close' });
    };

    const handleDeleteTimeslot = async (timeslotId: string) => {
        if (!window.confirm(formatMessage({ defaultMessage: 'Weet je zeker dat je dit tijdslot wilt verwijderen?' }))) {
            return;
        }
        if (!currDates) {
            return;
        }
        const result = await remove({
            variables: {
                ...currDates,
                input: {
                    timeslotId,
                },
            },
            update: (cache, response) => {
                // did we actually delete anything?
                if (!response.data?.deleteActivityTimeslot?.id) {
                    return null;
                }
                const updater = createTimeslotsUpdater<DeleteActivityTimeslotMutation>(variables, oldSlots =>
                    oldSlots.filter(oldSlot => oldSlot.id !== timeslotId)
                );
                return updater(cache, response);
            },
        });

        if (!result.data?.deleteActivityTimeslot?.id) {
            window.alert(`Het is niet mogelijk om dit tijdslot te verwijderen omdat er een (vervallen) boeking op dit tijdslot is gemaakt.
Tip: zet de voorraad op 0 als je dit tijdslot niet (meer) via Travelbase wilt aanbieden.
`);
            return;
        }

        dispatch({ type: 'close' });
    };

    const handleCreateTimeslot = async ({ translations, ...values }: TimeslotInput) => {
        const timeslot: TimeslotInput = {
            ...values,
            translations: translations?.filter(translation => !!translation.label),
        };
        if (!currDates) {
            return;
        }
        await create({
            variables: {
                ...currDates,
                input: {
                    activityId,
                    timeslot,
                },
            },
            update: createTimeslotsUpdater<CreateActivityTimeslotMutation>(variables, (oldSlots, resultData) =>
                oldSlots.concat(resultData.createActivityTimeslot.timeslot ?? [])
            ),
        });
        dispatch({ type: 'close' });
    };

    return (
        <FormScreen
            // reinitialize form on date or id change
            key={date ? +date : slot?.id}
            alwaysPrompt={state.open}
            variant="sidebar"
            initialValues={slot ? toInput(slot) : getInitialValues(date)}
            validationSchema={validationSchema}
            handleSubmit={values => {
                const endDateTime = new Date(parseISO(values.endDateTime));
                const correctedValues = {
                    ...values,
                    endDateTime: format(
                        addDays(endDateTime, isBefore(endDateTime, new Date(values.startDateTime)) ? 1 : 0),
                        `${dateFormat} ${hourFormat}`
                    ),
                };
                if (!slot?.id) {
                    return handleCreateTimeslot(correctedValues);
                }
                return handleEditTimeslot(slot.id, correctedValues);
            }}
            bottomChildren={({ dirty }) => (
                <FormActionsContainer alwaysVisible dirty={dirty} variant="sidebar">
                    {slot ? (
                        <Button variant="danger" type="button" onClick={() => handleDeleteTimeslot(slot.id)}>
                            <FormattedMessage defaultMessage="Verwijderen" />
                        </Button>
                    ) : (
                        <span />
                    )}

                    <Button disabled={!dirty} type="submit">
                        <FormattedMessage defaultMessage="Publiceren" />
                    </Button>
                </FormActionsContainer>
            )}
        >
            {({ values }) => (
                <>
                    {!hasRateGroups && (
                        <>
                            <Body>
                                <FormattedMessage defaultMessage="Er zijn nog geen tariefgroepen ingesteld" />
                            </Body>
                            <TextButton
                                style={{ display: 'inline-flex' }}
                                as={Link}
                                to={generatePath('/partner/:partnerId/activity/:activitySlug/rates', params)}
                            >
                                <FormattedMessage defaultMessage="Tariefgroep instellen" />
                            </TextButton>
                        </>
                    )}
                    {hasRateGroups && (
                        <>
                            <SidebarIntro title={formatMessage({ defaultMessage: 'Toevoegen' })}>
                                {format(date, dateTextFormat, dateOpts)}
                            </SidebarIntro>

                            <SidebarField label={formatMessage({ defaultMessage: 'Tariefgroep' })}>
                                <SelectInput variant="small" name="rateGroupId" options={rateGroupOptions} />
                            </SidebarField>

                            <SidebarField label={formatMessage({ defaultMessage: 'Tijden' })}>
                                <Flex>
                                    <Box flex={1}>
                                        <SelectInput
                                            variant="small"
                                            hideChoices
                                            name="startDateTime"
                                            placeholder={formatMessage({ defaultMessage: 'Van' })}
                                            options={timeOptions}
                                            isSearchable
                                        />
                                    </Box>
                                    <Box ml={4} flex={1}>
                                        <SelectInput
                                            variant="small"
                                            hideChoices
                                            name="endDateTime"
                                            placeholder={formatMessage({ defaultMessage: 'Tot' })}
                                            options={timeOptions}
                                            isSearchable
                                        />
                                    </Box>
                                </Flex>
                                {isBefore(new Date(values.endDateTime), new Date(values.startDateTime)) && (
                                    <Flex>
                                        <Warning style={{ flexShrink: 0, margin: '0.4rem 0.4rem 0 0' }} />
                                        <Body variant="small">
                                            <FormattedMessage defaultMessage="Let op: het tijdslot eindigt op de volgende dag" />
                                        </Body>
                                    </Flex>
                                )}
                            </SidebarField>

                            <TimeslotAllotmentField rateGroups={rateGroups} />
                        </>
                    )}
                </>
            )}
        </FormScreen>
    );
};

export default TimeslotsSidebar;
