import * as Yup from 'yup';

const discount = Yup.number()
    .min(0, 'Dit veld kan niet negatief zijn')
    .max(100, 'Een korting kan niet meer dan 100% bedragen');

export const discountValidation = Yup.object().shape({
    lastminuteDiscount1Week: discount,
    lastminuteDiscount2Week: discount,
    lastminuteDiscount3Week: discount,
    lastminuteDiscount4Week: discount,
    earlyBookDiscount6Months: discount,
    earlyBookDiscount12Months: discount,
});
