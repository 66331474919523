import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import { Option } from '@oberoninternal/travelbase-ds/components/form/Options';
import { Box } from '@rebass/grid';
import gql from 'graphql-tag';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ConfigurationError } from '../../../entities/ConfigurationError';
import { usePartnerPickerQuery } from '../../../generated/graphql';
import Loading from '../../atoms/Loading';
import LoginContainer from '../../molecules/LoginContainer';
import styled from 'styled-components/macro';
import { useLogout } from '../../../hooks/useLogout';
import { FormattedMessage, useIntl } from 'react-intl';

export const query = gql`
    query PartnerPicker {
        viewer {
            id
            isBackofficeUser
            partners {
                id
                name
                contact {
                    contactName
                }
            }
        }
    }
`;

const PartnerPicker: FC<React.PropsWithChildren<unknown>> = () => {
    const { data, loading } = usePartnerPickerQuery({ fetchPolicy: 'network-only' });
    const history = useHistory();
    const partners = data?.viewer?.partners ?? [];

    const [selected, setSelected] = useState<string | null>(null);
    const logout = useLogout();
    const { formatMessage } = useIntl();
    useEffect(() => {
        if (!loading && !data?.viewer) {
            history.push('/login');
        }
    });

    if (loading) {
        return <Loading />;
    }

    if (!data?.viewer) {
        return null;
    }

    if (partners?.length === 0 && !data?.viewer?.isBackofficeUser) {
        throw new ConfigurationError('Dit account heeft geen toegang tot partners.');
    }

    // When there's only one partner, no need for picking oneel
    if (partners?.length === 1) {
        history.push(`/partner/${partners[0].id}`);
    }

    const handleLogout = () => {
        logout(true);
    };

    return (
        <LoginContainer>
            <Body>
                <FormattedMessage defaultMessage="Kies hier de partner die je wilt beheren." />
            </Body>
            <Box width={1}>
                {partners?.map(({ id, name, contact }) => (
                    <Option
                        onChange={({ currentTarget: { value } }) => setSelected(value)}
                        value={id}
                        name="partner"
                        key={id}
                        text={
                            name ??
                            formatMessage({
                                defaultMessage: 'Partner',
                            })
                        }
                        description={contact.contactName}
                    />
                ))}
            </Box>
            <StyledButton disabled={!selected} onClick={() => history.push(`/partner/${selected}`)}>
                <FormattedMessage defaultMessage="Bevestigen" />
            </StyledButton>
            <StyledButton variant="outline" onClick={handleLogout}>
                <FormattedMessage defaultMessage="Uitloggen" />
            </StyledButton>
        </LoginContainer>
    );
};

export default PartnerPicker;

const StyledButton = styled(Button)`
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing['40_Standard']};
`;
