/* eslint-disable */
/* tslint:disable */
import { disableFragmentWarnings } from 'graphql-tag';
disableFragmentWarnings();
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '../apollo';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    /** A string representing a date, formatted as YYYY-MM-DD. */
    Date: { input: any; output: any };
    /** A string representing a date and time, in RFC3339 format (for example: 2020-01-23T12:34:56+02:00). */
    DateTime: { input: any; output: any };
    FileUpload: { input: any; output: any };
    Time: { input: any; output: any };
};

export type AcceptBookingInput = {
    accept: Scalars['Boolean']['input'];
    bookingId: Scalars['ID']['input'];
};

export type AcceptBookingPayload = {
    __typename?: 'AcceptBookingPayload';
    booking?: Maybe<Booking>;
};

/** An accommodation represents a building or location that contains one or more rental units. In many cases an accommodation has only one rental unit. In the case of larger accommodations like hotels or apartment buildings, the accommodation has rental units for each room type or apartment. */
export type Accommodation = {
    __typename?: 'Accommodation';
    address?: Maybe<Address>;
    attributes: Array<Attribute>;
    caretaker?: Maybe<ContactInfo>;
    checkInEndTime?: Maybe<Scalars['Time']['output']>;
    checkInStartTime?: Maybe<Scalars['Time']['output']>;
    checkOutTime?: Maybe<Scalars['Time']['output']>;
    coordinates?: Maybe<LatLon>;
    descriptionGeneral?: Maybe<Scalars['String']['output']>;
    descriptionGeneralPending?: Maybe<Scalars['String']['output']>;
    descriptionLocation?: Maybe<Scalars['String']['output']>;
    descriptionLocationPending?: Maybe<Scalars['String']['output']>;
    /** Whether the accommodation is enabled. */
    enabled: Scalars['Boolean']['output'];
    hasPublicPage: Scalars['Boolean']['output'];
    /** Unique identifier of the accommodation. */
    id: Scalars['ID']['output'];
    images: Array<AccommodationImage>;
    keyHolder?: Maybe<ContactInfo>;
    keyHolderAddress?: Maybe<Address>;
    listImage?: Maybe<AccommodationListImage>;
    mainImage?: Maybe<AccommodationMainImage>;
    /** Name of the accommodation. */
    name: Scalars['String']['output'];
    ownerImage?: Maybe<AvatarImage>;
    ownerIntroduction?: Maybe<Scalars['String']['output']>;
    ownerIntroductionPending?: Maybe<Scalars['String']['output']>;
    ownerName?: Maybe<Scalars['String']['output']>;
    ownerProfileVisible: Scalars['Boolean']['output'];
    ownerTips?: Maybe<Scalars['String']['output']>;
    ownerTipsPending?: Maybe<Scalars['String']['output']>;
    partner?: Maybe<Partner>;
    place?: Maybe<Place>;
    publicReservationsEmail?: Maybe<Scalars['String']['output']>;
    publicReservationsPhoneNumber?: Maybe<Scalars['String']['output']>;
    publicReservationsWebsiteUrl?: Maybe<Scalars['String']['output']>;
    /** Rental units that belong to the accommodation. */
    rentalUnits: Array<RentalUnit>;
    /** Name of contact person (required), at least one phone number, and at least one email address */
    reservations: ContactInfo;
    reviewsEmail?: Maybe<Scalars['String']['output']>;
    slug: Scalars['String']['output'];
    smsNotificationNumbers: Array<Scalars['String']['output']>;
    starRating?: Maybe<Scalars['Int']['output']>;
    /** Type of the accommodation. */
    type: AccommodationTypeEnum;
    webhookUrl?: Maybe<Scalars['String']['output']>;
};

/** An accommodation represents a building or location that contains one or more rental units. In many cases an accommodation has only one rental unit. In the case of larger accommodations like hotels or apartment buildings, the accommodation has rental units for each room type or apartment. */
export type AccommodationDescriptionGeneralArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
};

/** An accommodation represents a building or location that contains one or more rental units. In many cases an accommodation has only one rental unit. In the case of larger accommodations like hotels or apartment buildings, the accommodation has rental units for each room type or apartment. */
export type AccommodationDescriptionLocationArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
};

/** An accommodation represents a building or location that contains one or more rental units. In many cases an accommodation has only one rental unit. In the case of larger accommodations like hotels or apartment buildings, the accommodation has rental units for each room type or apartment. */
export type AccommodationOwnerIntroductionArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
};

/** An accommodation represents a building or location that contains one or more rental units. In many cases an accommodation has only one rental unit. In the case of larger accommodations like hotels or apartment buildings, the accommodation has rental units for each room type or apartment. */
export type AccommodationOwnerTipsArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
};

export type AccommodationImage = Image & {
    __typename?: 'AccommodationImage';
    category: AccommodationImageCategoryEnum;
    dimensions: Array<Scalars['Int']['output']>;
    id: Scalars['ID']['output'];
    position: Scalars['Int']['output'];
    previewUrl?: Maybe<Scalars['String']['output']>;
    size: Scalars['Int']['output'];
    url: Scalars['String']['output'];
};

export enum AccommodationImageCategoryEnum {
    Bathroom = 'BATHROOM',
    Bedroom = 'BEDROOM',
    Entrance = 'ENTRANCE',
    Facilities = 'FACILITIES',
    FloorPlan = 'FLOOR_PLAN',
    Front = 'FRONT',
    Kitchen = 'KITCHEN',
    LivingRoom = 'LIVING_ROOM',
    Other = 'OTHER',
    Outdoor = 'OUTDOOR',
    Surroundings = 'SURROUNDINGS',
    View = 'VIEW',
}

export type AccommodationImageInput = {
    category: AccommodationImageCategoryEnum;
    imageId?: InputMaybe<Scalars['ID']['input']>;
    uploadId?: InputMaybe<Scalars['ID']['input']>;
};

export type AccommodationListImage = Image & {
    __typename?: 'AccommodationListImage';
    dimensions: Array<Scalars['Int']['output']>;
    id: Scalars['ID']['output'];
    previewUrl?: Maybe<Scalars['String']['output']>;
    size: Scalars['Int']['output'];
    url: Scalars['String']['output'];
};

export type AccommodationMainImage = Image & {
    __typename?: 'AccommodationMainImage';
    dimensions: Array<Scalars['Int']['output']>;
    id: Scalars['ID']['output'];
    previewUrl?: Maybe<Scalars['String']['output']>;
    size: Scalars['Int']['output'];
    url: Scalars['String']['output'];
};

/** Registration object of an accommodation: An accommodation represents a building or location that contains one or more rental units. In many cases an accommodation has only one rental unit. In the case of larger accommodations like hotels or apartment buildings, the accommodation has rental units for each room type or apartment. */
export type AccommodationRegistration = {
    __typename?: 'AccommodationRegistration';
    /** Unique identifier of the accommodation registration. */
    id: Scalars['ID']['output'];
    /** Name of the accommodation registration. */
    name: Scalars['String']['output'];
    partnerRegistration: PartnerRegistration;
    place?: Maybe<Place>;
    placeRegistration?: Maybe<Scalars['String']['output']>;
    /** Rental units that belong to the accommodation registration. */
    rentalUnitRegistrations: Array<RentalUnitRegistration>;
    slug: Scalars['String']['output'];
    type: AccommodationTypeEnum;
};

export type AccommodationRegistrationInput = {
    address: AddressInput;
    name: Scalars['String']['input'];
    publicUrl?: InputMaybe<Scalars['String']['input']>;
    rentalUnits: Array<RentalUnitRegistrationInput>;
    starRating?: InputMaybe<Scalars['Int']['input']>;
    type: AccommodationTypeEnum;
};

export enum AccommodationTypeEnum {
    ApartmentBlock = 'APARTMENT_BLOCK',
    BedAndBreakfast = 'BED_AND_BREAKFAST',
    BungalowPark = 'BUNGALOW_PARK',
    Camping = 'CAMPING',
    Hotel = 'HOTEL',
    Location = 'LOCATION',
}

/** An activity. */
export type Activity = {
    __typename?: 'Activity';
    activityAgreements: Array<ActivityAgreement>;
    /** Rate groups which determine the price for a ticket */
    activityRateGroups: Array<ActivityRateGroup>;
    company: Company;
    /** Coordinates of the location of the activity. */
    coordinates?: Maybe<LatLon>;
    /** Date and time the activity was created. */
    createdAt: Scalars['DateTime']['output'];
    description?: Maybe<Scalars['String']['output']>;
    descriptionLocation?: Maybe<Scalars['String']['output']>;
    descriptionLocationPending?: Maybe<Scalars['String']['output']>;
    descriptionParticulars?: Maybe<Scalars['String']['output']>;
    descriptionParticularsPending?: Maybe<Scalars['String']['output']>;
    descriptionPending?: Maybe<Scalars['String']['output']>;
    /** Whether the activity is enabled. */
    enabled: Scalars['Boolean']['output'];
    externalBookingUrl?: Maybe<Scalars['String']['output']>;
    externalInfoUrl?: Maybe<Scalars['String']['output']>;
    hideForPartner: Scalars['Boolean']['output'];
    /** Whether the activity is highlighted. */
    highlighted: Scalars['Boolean']['output'];
    /** Unique identifier of the activity. */
    id: Scalars['ID']['output'];
    images: Array<ActivityImage>;
    listImage?: Maybe<ActivityListImage>;
    /** Address of the location where the activity takes place. */
    locationAddress?: Maybe<Address>;
    mainImage?: Maybe<ActivityMainImage>;
    maxCapacity?: Maybe<Scalars['Int']['output']>;
    minCapacity?: Maybe<Scalars['Int']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    place?: Maybe<Place>;
    public: Scalars['Boolean']['output'];
    showExternalBookingUrl: Scalars['Boolean']['output'];
    /** @deprecated Use `showExternalBookingUrl` instead */
    showUrl: Scalars['Boolean']['output'];
    slug: Scalars['String']['output'];
    targetAudiences: Array<Maybe<TargetAudienceEnum>>;
    themes: Array<ActivityTheme>;
    timeslots: Array<Timeslot>;
    /** Date and time of the last update to this activity. */
    updatedAt: Scalars['DateTime']['output'];
    videoUrl?: Maybe<Scalars['String']['output']>;
};

/** An activity. */
export type ActivityDescriptionArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
};

/** An activity. */
export type ActivityDescriptionLocationArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
};

/** An activity. */
export type ActivityDescriptionParticularsArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
};

/** An activity. */
export type ActivityNameArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
};

/** An activity. */
export type ActivityTimeslotsArgs = {
    endDate: Scalars['Date']['input'];
    startDate: Scalars['Date']['input'];
};

export type ActivityAgreement = {
    __typename?: 'ActivityAgreement';
    activity: Activity;
    agreement: Agreement;
    approvalDate?: Maybe<Scalars['DateTime']['output']>;
    endDate?: Maybe<Scalars['Date']['output']>;
    id: Scalars['ID']['output'];
    startDate: Scalars['Date']['output'];
};

export enum ActivityBookingTypeEnum {
    Direct = 'DIRECT',
    External = 'EXTERNAL',
    NotBookable = 'NOT_BOOKABLE',
}

export type ActivityImage = Image & {
    __typename?: 'ActivityImage';
    dimensions: Array<Scalars['Int']['output']>;
    id: Scalars['ID']['output'];
    position: Scalars['Int']['output'];
    previewUrl?: Maybe<Scalars['String']['output']>;
    size: Scalars['Int']['output'];
    url: Scalars['String']['output'];
};

export type ActivityImageInput = {
    imageId?: InputMaybe<Scalars['ID']['input']>;
    uploadId?: InputMaybe<Scalars['ID']['input']>;
};

export type ActivityListImage = Image & {
    __typename?: 'ActivityListImage';
    dimensions: Array<Scalars['Int']['output']>;
    id: Scalars['ID']['output'];
    previewUrl?: Maybe<Scalars['String']['output']>;
    size: Scalars['Int']['output'];
    url: Scalars['String']['output'];
};

export type ActivityMainImage = Image & {
    __typename?: 'ActivityMainImage';
    dimensions: Array<Scalars['Int']['output']>;
    id: Scalars['ID']['output'];
    previewUrl?: Maybe<Scalars['String']['output']>;
    size: Scalars['Int']['output'];
    url: Scalars['String']['output'];
};

/** A rate group for an activity. */
export type ActivityRateGroup = RateGroup & {
    __typename?: 'ActivityRateGroup';
    canBuyTickets: Scalars['Boolean']['output'];
    /** Unique identifier of the rate group. */
    id: Scalars['ID']['output'];
    /** Name of the group for internal use. */
    name?: Maybe<Scalars['String']['output']>;
    qrScansEnabled: Scalars['Boolean']['output'];
    rates: Array<Rate>;
    /** Tickets can be sold via Travelbase until this amount of hours prior to the Timeslot's start time. */
    releaseHours?: Maybe<Scalars['Int']['output']>;
};

/** An activity theme. */
export type ActivityTheme = {
    __typename?: 'ActivityTheme';
    /** Unique identifier of the activity theme. */
    id: Scalars['ID']['output'];
    name?: Maybe<Scalars['String']['output']>;
    namePending?: Maybe<Scalars['String']['output']>;
    slug: Scalars['String']['output'];
};

/** An activity theme. */
export type ActivityThemeNameArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
};

/** An address describing a real world location. */
export type Address = {
    __typename?: 'Address';
    /** Name of the city. */
    city?: Maybe<Scalars['String']['output']>;
    /** ISO 3166-1 Alpha-2 country code. */
    countryCode?: Maybe<Scalars['String']['output']>;
    countryName?: Maybe<Scalars['String']['output']>;
    /** House number and extension (if applicable). */
    number?: Maybe<Scalars['String']['output']>;
    /** National postal code. */
    postalCode?: Maybe<Scalars['String']['output']>;
    /** Name of the street. */
    street?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
    city?: InputMaybe<Scalars['String']['input']>;
    countryCode?: InputMaybe<Scalars['String']['input']>;
    number?: InputMaybe<Scalars['String']['input']>;
    postalCode?: InputMaybe<Scalars['String']['input']>;
    street?: InputMaybe<Scalars['String']['input']>;
};

export type Agreement = {
    __typename?: 'Agreement';
    agreementUrl?: Maybe<Scalars['String']['output']>;
    bookingFeeAmount: Scalars['Float']['output'];
    bookingType: ActivityBookingTypeEnum;
    commissionPercentage: Scalars['Float']['output'];
    invoiceTotalAt: InvoiceTotalAtEnum;
    name: Scalars['String']['output'];
};

/** Allotment for a specific date. */
export type Allotment = {
    __typename?: 'Allotment';
    /** Amount of units available on the specified date. */
    amount: Scalars['Int']['output'];
    /** Date to which the allotment applies. */
    date: Scalars['Date']['output'];
    id: Scalars['ID']['output'];
    rentalUnit: RentalUnit;
};

export type AllotmentLockout = {
    __typename?: 'AllotmentLockout';
    comment?: Maybe<Scalars['String']['output']>;
    endDate: Scalars['Date']['output'];
    id: Scalars['ID']['output'];
    isActive: Scalars['Boolean']['output'];
    isDragging: Scalars['Boolean']['output'];
    isHovering: Scalars['Boolean']['output'];
    rentalUnit: RentalUnit;
    source?: Maybe<Scalars['String']['output']>;
    startDate: Scalars['Date']['output'];
    type: AllotmentLockoutTypeEnum;
};

export enum AllotmentLockoutTypeEnum {
    ExternalBooking = 'EXTERNAL_BOOKING',
    Maintenance = 'MAINTENANCE',
    ManagementApi = 'MANAGEMENT_API',
    Other = 'OTHER',
    PrivateUse = 'PRIVATE_USE',
}

export type ApproveActivityAgreementInput = {
    activityAgreementId: Scalars['ID']['input'];
};

export type ApproveActivityAgreementPayload = {
    __typename?: 'ApproveActivityAgreementPayload';
    activityAgreement?: Maybe<ActivityAgreement>;
};

export type ApproveRentalUnitContractInput = {
    rentalUnitContractId: Scalars['ID']['input'];
};

export type ApproveRentalUnitContractPayload = {
    __typename?: 'ApproveRentalUnitContractPayload';
    rentalUnitContract?: Maybe<RentalUnitContract>;
};

export type Attribute = {
    __typename?: 'Attribute';
    category: AttributeCategory;
    id: Scalars['ID']['output'];
    name?: Maybe<Scalars['String']['output']>;
    position: Scalars['Int']['output'];
    subtypes: Array<RentalUnitTypeEnum>;
    type: AttributeTypeEnum;
};

export type AttributeNameArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
};

export type AttributeCategory = {
    __typename?: 'AttributeCategory';
    allowMultiple: Scalars['Boolean']['output'];
    attributes: Array<Attribute>;
    id: Scalars['ID']['output'];
    name?: Maybe<Scalars['String']['output']>;
    position: Scalars['Int']['output'];
};

export type AttributeCategoryAttributesArgs = {
    subtype?: InputMaybe<RentalUnitTypeEnum>;
    type?: InputMaybe<AttributeTypeEnum>;
};

export type AttributeCategoryNameArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
};

export type AttributeInput = {
    id: Scalars['ID']['input'];
};

export enum AttributeTypeEnum {
    Accommodation = 'ACCOMMODATION',
    RentalUnit = 'RENTAL_UNIT',
}

export type AvatarImage = Image & {
    __typename?: 'AvatarImage';
    dimensions: Array<Scalars['Int']['output']>;
    id: Scalars['ID']['output'];
    previewUrl?: Maybe<Scalars['String']['output']>;
    size: Scalars['Int']['output'];
    url: Scalars['String']['output'];
};

/** Details of a booking for a rental unit. */
export type Booking = {
    __typename?: 'Booking';
    accommodationSum: Scalars['Float']['output'];
    /** Additions for this booking. */
    additions: Array<BookingAddition>;
    /** Amount of adults staying in the rental unit. */
    amountAdults: Scalars['Int']['output'];
    /** Amount of babies staying in the rental unit. */
    amountBabies: Scalars['Int']['output'];
    /** Amount of children staying in the rental unit. */
    amountChildren: Scalars['Int']['output'];
    /** Amount of pets staying in the rental unit. */
    amountPets: Scalars['Int']['output'];
    /** Amount of youths staying in the rental unit. */
    amountYouths: Scalars['Int']['output'];
    /** Date of arrival. */
    arrivalDate: Scalars['Date']['output'];
    /** The Connector this Booking is linked to, if applicable. */
    connector?: Maybe<ConnectorEnum>;
    /** External reference for this Booking in the external system linked by the Connector. */
    connectorReference?: Maybe<Scalars['String']['output']>;
    /** Date and time the booking was created. */
    createdAt: Scalars['DateTime']['output'];
    /** The customer who made this booking. */
    customer?: Maybe<Customer>;
    /** Comment added by the customer. */
    customerComment?: Maybe<Scalars['String']['output']>;
    /** Date of departure. */
    departureDate: Scalars['Date']['output'];
    /** Total value of the security deposit. */
    deposit: Scalars['Float']['output'];
    /** The portion of the security deposit for which payment is handled by Travelbase. */
    depositPaid: Scalars['Float']['output'];
    /** Duration of stay (number of nights). */
    duration: Scalars['Int']['output'];
    /** Last date and time when cancellation is free for the customer */
    flexCancellationUntil?: Maybe<Scalars['DateTime']['output']>;
    /** Unique identifier of the booking. */
    id: Scalars['ID']['output'];
    /** The address associated with this booking, may or may not be the same as customer.address */
    invoiceAddress: Address;
    isActive: Scalars['Boolean']['output'];
    isHovering: Scalars['Boolean']['output'];
    /** Booking number as used in communications. */
    number?: Maybe<Scalars['String']['output']>;
    partnerPriceLines: Array<BookingPriceLine>;
    /** The rental unit for this booking. */
    rentalUnit: RentalUnit;
    /** Special selected by the customer (if applicable). */
    special?: Maybe<Special>;
    /** Status of this booking. */
    status: BookingStatusEnum;
    /** Total price for this booking. The total price is determined by adding up the rental sum, tourist tax and additions (if any). */
    totalPrice: Scalars['Float']['output'];
    /** The portion of the total price for which payment is handled by Travelbase. */
    totalPricePaid: Scalars['Float']['output'];
    /** The tourist tax due for this booking. */
    touristTax: Scalars['Float']['output'];
    /** The portion of the tourist tax for which payment is handled by Travelbase. */
    touristTaxPaid: Scalars['Float']['output'];
    /** Date and time of the last update to this booking. */
    updatedAt: Scalars['DateTime']['output'];
};

/** Additions are non-generic costs added to the booking, such as (optional or mandatory) surcharges. */
export type BookingAddition = {
    __typename?: 'BookingAddition';
    /** Amount selected by the customer. */
    amount: Scalars['Int']['output'];
    /** Calculation used to determine the total price of this addition. */
    calculation: SurchargeCalculationEnum;
    /** The surcharge this addition originates from. This will be empty in the case of a custom addition. */
    surcharge?: Maybe<Surcharge>;
    /** Title of this addition. This should be identical to the surcharge name (at the time of booking) if this addition originates from a surcharge, or contain custom input in the case of a custom addition. */
    title?: Maybe<Scalars['String']['output']>;
    /** Total price charged to the customer for this addition. */
    totalPrice: Scalars['Float']['output'];
    /** Price per unit for this addition. */
    unitPrice: Scalars['Float']['output'];
};

/** Additions are non-generic costs added to the booking, such as (optional or mandatory) surcharges. */
export type BookingAdditionTitleArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of items. */
export type BookingConnection = {
    __typename?: 'BookingConnection';
    /** Information to aid in pagination. */
    edges?: Maybe<Array<Maybe<BookingEdge>>>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** Total bookings for this query. */
    totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type BookingEdge = {
    __typename?: 'BookingEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String']['output'];
    /** The item at the end of the edge. */
    node?: Maybe<Booking>;
};

export type BookingPriceLine = {
    __typename?: 'BookingPriceLine';
    category: PriceLineCategoryEnum;
    label: Scalars['String']['output'];
    modifier?: Maybe<Scalars['String']['output']>;
    totalPrice: Scalars['Float']['output'];
    unitPrice?: Maybe<Scalars['Float']['output']>;
};

export enum BookingStatusEnum {
    Accepted = 'ACCEPTED',
    CancelledExternal = 'CANCELLED_EXTERNAL',
    CancelledGuest = 'CANCELLED_GUEST',
    CancelledPartner = 'CANCELLED_PARTNER',
    Declined = 'DECLINED',
    Init = 'INIT',
    Pending = 'PENDING',
    Waived = 'WAIVED',
}

export enum BookingTypeEnum {
    Direct = 'DIRECT',
    NotBookable = 'NOT_BOOKABLE',
    OnRequest = 'ON_REQUEST',
}

export type BulkEditAllotmentsInput = {
    amount: Scalars['Int']['input'];
    endDate: Scalars['Date']['input'];
    rentalUnitId: Scalars['ID']['input'];
    startDate: Scalars['Date']['input'];
};

export type BulkEditAllotmentsPayload = {
    __typename?: 'BulkEditAllotmentsPayload';
    allotments?: Maybe<Array<Allotment>>;
};

export type BulkEditDatePricingArrivalAllowedInput = {
    endDate: Scalars['Date']['input'];
    friday?: InputMaybe<Scalars['Boolean']['input']>;
    monday?: InputMaybe<Scalars['Boolean']['input']>;
    rentalUnitId: Scalars['ID']['input'];
    saturday?: InputMaybe<Scalars['Boolean']['input']>;
    startDate: Scalars['Date']['input'];
    sunday?: InputMaybe<Scalars['Boolean']['input']>;
    thursday?: InputMaybe<Scalars['Boolean']['input']>;
    tuesday?: InputMaybe<Scalars['Boolean']['input']>;
    wednesday?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BulkEditDatePricingArrivalAllowedPayload = {
    __typename?: 'BulkEditDatePricingArrivalAllowedPayload';
    datePricings?: Maybe<Array<DatePricing>>;
};

export type BulkEditDatePricingBaseStayPriceInput = {
    endDate: Scalars['Date']['input'];
    friday?: InputMaybe<Scalars['Float']['input']>;
    monday?: InputMaybe<Scalars['Float']['input']>;
    rentalUnitId: Scalars['ID']['input'];
    saturday?: InputMaybe<Scalars['Float']['input']>;
    startDate: Scalars['Date']['input'];
    sunday?: InputMaybe<Scalars['Float']['input']>;
    thursday?: InputMaybe<Scalars['Float']['input']>;
    tuesday?: InputMaybe<Scalars['Float']['input']>;
    wednesday?: InputMaybe<Scalars['Float']['input']>;
};

export type BulkEditDatePricingBaseStayPricePayload = {
    __typename?: 'BulkEditDatePricingBaseStayPricePayload';
    datePricings?: Maybe<Array<DatePricing>>;
};

export type BulkEditDatePricingDepartureAllowedInput = {
    endDate: Scalars['Date']['input'];
    friday?: InputMaybe<Scalars['Boolean']['input']>;
    monday?: InputMaybe<Scalars['Boolean']['input']>;
    rentalUnitId: Scalars['ID']['input'];
    saturday?: InputMaybe<Scalars['Boolean']['input']>;
    startDate: Scalars['Date']['input'];
    sunday?: InputMaybe<Scalars['Boolean']['input']>;
    thursday?: InputMaybe<Scalars['Boolean']['input']>;
    tuesday?: InputMaybe<Scalars['Boolean']['input']>;
    wednesday?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BulkEditDatePricingDepartureAllowedPayload = {
    __typename?: 'BulkEditDatePricingDepartureAllowedPayload';
    datePricings?: Maybe<Array<DatePricing>>;
};

export type BulkEditDatePricingExtraPersonPriceInput = {
    endDate: Scalars['Date']['input'];
    friday?: InputMaybe<Scalars['Float']['input']>;
    monday?: InputMaybe<Scalars['Float']['input']>;
    rentalUnitId: Scalars['ID']['input'];
    saturday?: InputMaybe<Scalars['Float']['input']>;
    startDate: Scalars['Date']['input'];
    sunday?: InputMaybe<Scalars['Float']['input']>;
    thursday?: InputMaybe<Scalars['Float']['input']>;
    tuesday?: InputMaybe<Scalars['Float']['input']>;
    wednesday?: InputMaybe<Scalars['Float']['input']>;
};

export type BulkEditDatePricingExtraPersonPricePayload = {
    __typename?: 'BulkEditDatePricingExtraPersonPricePayload';
    datePricings?: Maybe<Array<DatePricing>>;
};

export type BulkEditDatePricingMinimumStayDurationInput = {
    endDate: Scalars['Date']['input'];
    friday?: InputMaybe<Scalars['Int']['input']>;
    monday?: InputMaybe<Scalars['Int']['input']>;
    rentalUnitId: Scalars['ID']['input'];
    saturday?: InputMaybe<Scalars['Int']['input']>;
    startDate: Scalars['Date']['input'];
    sunday?: InputMaybe<Scalars['Int']['input']>;
    thursday?: InputMaybe<Scalars['Int']['input']>;
    tuesday?: InputMaybe<Scalars['Int']['input']>;
    wednesday?: InputMaybe<Scalars['Int']['input']>;
};

export type BulkEditDatePricingMinimumStayDurationPayload = {
    __typename?: 'BulkEditDatePricingMinimumStayDurationPayload';
    datePricings?: Maybe<Array<DatePricing>>;
};

export type BulkEditDatePricingMinimumStayPriceInput = {
    endDate: Scalars['Date']['input'];
    friday?: InputMaybe<Scalars['Float']['input']>;
    monday?: InputMaybe<Scalars['Float']['input']>;
    rentalUnitId: Scalars['ID']['input'];
    saturday?: InputMaybe<Scalars['Float']['input']>;
    startDate: Scalars['Date']['input'];
    sunday?: InputMaybe<Scalars['Float']['input']>;
    thursday?: InputMaybe<Scalars['Float']['input']>;
    tuesday?: InputMaybe<Scalars['Float']['input']>;
    wednesday?: InputMaybe<Scalars['Float']['input']>;
};

export type BulkEditDatePricingMinimumStayPricePayload = {
    __typename?: 'BulkEditDatePricingMinimumStayPricePayload';
    datePricings?: Maybe<Array<DatePricing>>;
};

export type BulkEditDatePricingNightPriceInput = {
    endDate: Scalars['Date']['input'];
    friday?: InputMaybe<Scalars['Float']['input']>;
    monday?: InputMaybe<Scalars['Float']['input']>;
    rentalUnitId: Scalars['ID']['input'];
    saturday?: InputMaybe<Scalars['Float']['input']>;
    startDate: Scalars['Date']['input'];
    sunday?: InputMaybe<Scalars['Float']['input']>;
    thursday?: InputMaybe<Scalars['Float']['input']>;
    tuesday?: InputMaybe<Scalars['Float']['input']>;
    wednesday?: InputMaybe<Scalars['Float']['input']>;
};

export type BulkEditDatePricingNightPricePayload = {
    __typename?: 'BulkEditDatePricingNightPricePayload';
    datePricings?: Maybe<Array<DatePricing>>;
};

export type BulkEditDatePricingWeekPriceInput = {
    endDate: Scalars['Date']['input'];
    friday?: InputMaybe<Scalars['Float']['input']>;
    monday?: InputMaybe<Scalars['Float']['input']>;
    rentalUnitId: Scalars['ID']['input'];
    saturday?: InputMaybe<Scalars['Float']['input']>;
    startDate: Scalars['Date']['input'];
    sunday?: InputMaybe<Scalars['Float']['input']>;
    thursday?: InputMaybe<Scalars['Float']['input']>;
    tuesday?: InputMaybe<Scalars['Float']['input']>;
    wednesday?: InputMaybe<Scalars['Float']['input']>;
};

export type BulkEditDatePricingWeekPricePayload = {
    __typename?: 'BulkEditDatePricingWeekPricePayload';
    datePricings?: Maybe<Array<DatePricing>>;
};

export type BulkUpsertDatePricingInput = {
    datePricingPeriods: Array<DatePricingPeriod>;
    rentalUnitId: Scalars['ID']['input'];
};

export type BulkUpsertDatePricingPayload = {
    __typename?: 'BulkUpsertDatePricingPayload';
    allotments?: Maybe<Array<Allotment>>;
    datePricings?: Maybe<Array<DatePricing>>;
    rentalUnit?: Maybe<RentalUnit>;
};

/** A company. */
export type Company = {
    __typename?: 'Company';
    activities: Array<Activity>;
    address?: Maybe<Address>;
    capacity?: Maybe<Scalars['Int']['output']>;
    categories: Array<CompanyCategory>;
    /** Rate groups which determine the price for a ticket */
    companyRateGroups: Array<CompanyRateGroup>;
    /** Coordinates of the location of the company. */
    coordinates?: Maybe<LatLon>;
    /** Date and time the company was created. */
    createdAt: Scalars['DateTime']['output'];
    description?: Maybe<Scalars['String']['output']>;
    descriptionParticulars?: Maybe<Scalars['String']['output']>;
    descriptionParticularsPending?: Maybe<Scalars['String']['output']>;
    descriptionPending?: Maybe<Scalars['String']['output']>;
    /** Primary contact email of the company. */
    email?: Maybe<Scalars['String']['output']>;
    /** Whether the company is enabled. */
    enabled: Scalars['Boolean']['output'];
    fullCircleImageUrl?: Maybe<Scalars['String']['output']>;
    /** Unique identifier of the company. */
    id: Scalars['ID']['output'];
    images: Array<CompanyImage>;
    listImage?: Maybe<CompanyListImage>;
    logoImage?: Maybe<CompanyLogoImage>;
    mainImage?: Maybe<CompanyMainImage>;
    /** The name of the company. */
    name: Scalars['String']['output'];
    /** General operating hours of the company. */
    normalOperatingHours?: Maybe<OperatingHours>;
    /** Primary contact phone number of the company. */
    phoneNumber?: Maybe<Scalars['String']['output']>;
    place: Place;
    /** Contact information for reservations. */
    reservations?: Maybe<ContactInfo>;
    showInCalendar: Scalars['Boolean']['output'];
    showInCompanyGuide: Scalars['Boolean']['output'];
    slug: Scalars['String']['output'];
    socialsFacebookUrl?: Maybe<Scalars['String']['output']>;
    socialsInstagramUrl?: Maybe<Scalars['String']['output']>;
    socialsTwitterUrl?: Maybe<Scalars['String']['output']>;
    /**
     * Temporary operating hours which override the normal operating hours of the company.
     *
     *
     *                     An array with hours define the hours for that day, an empty array means that the company is closed on that day, a null value means that we should fall back to the default operating hours
     */
    specialOperatingHours: Array<OperatingHours>;
    targetAudiences?: Maybe<Array<TargetAudienceEnum>>;
    themes: Array<ActivityTheme>;
    /** Date and time of the last update to this company. */
    updatedAt: Scalars['DateTime']['output'];
    videoUrl?: Maybe<Scalars['String']['output']>;
    websiteUrl?: Maybe<Scalars['String']['output']>;
};

/** A company. */
export type CompanyDescriptionArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
};

/** A company. */
export type CompanyDescriptionParticularsArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
};

/** An company category. */
export type CompanyCategory = {
    __typename?: 'CompanyCategory';
    children: Array<CompanyCategory>;
    /** Unique identifier of the company category. */
    id: Scalars['ID']['output'];
    name?: Maybe<Scalars['String']['output']>;
    position: Scalars['Int']['output'];
    slug: Scalars['String']['output'];
};

/** An company category. */
export type CompanyCategoryNameArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyImage = Image & {
    __typename?: 'CompanyImage';
    dimensions: Array<Scalars['Int']['output']>;
    id: Scalars['ID']['output'];
    position: Scalars['Int']['output'];
    previewUrl?: Maybe<Scalars['String']['output']>;
    size: Scalars['Int']['output'];
    url: Scalars['String']['output'];
};

export type CompanyImageInput = {
    imageId?: InputMaybe<Scalars['ID']['input']>;
    uploadId?: InputMaybe<Scalars['ID']['input']>;
};

export type CompanyListImage = Image & {
    __typename?: 'CompanyListImage';
    dimensions: Array<Scalars['Int']['output']>;
    id: Scalars['ID']['output'];
    previewUrl?: Maybe<Scalars['String']['output']>;
    size: Scalars['Int']['output'];
    url: Scalars['String']['output'];
};

export type CompanyLogoImage = Image & {
    __typename?: 'CompanyLogoImage';
    dimensions: Array<Scalars['Int']['output']>;
    id: Scalars['ID']['output'];
    previewUrl?: Maybe<Scalars['String']['output']>;
    size: Scalars['Int']['output'];
    url: Scalars['String']['output'];
};

export type CompanyMainImage = Image & {
    __typename?: 'CompanyMainImage';
    dimensions: Array<Scalars['Int']['output']>;
    id: Scalars['ID']['output'];
    previewUrl?: Maybe<Scalars['String']['output']>;
    size: Scalars['Int']['output'];
    url: Scalars['String']['output'];
};

/** A rate group for a company. */
export type CompanyRateGroup = RateGroup & {
    __typename?: 'CompanyRateGroup';
    canBuyTickets: Scalars['Boolean']['output'];
    endDate?: Maybe<Scalars['Date']['output']>;
    /** Unique identifier of the rate group. */
    id: Scalars['ID']['output'];
    /** Name of the group for internal use. */
    name?: Maybe<Scalars['String']['output']>;
    rates: Array<Rate>;
    startDate?: Maybe<Scalars['Date']['output']>;
};

export type ConnectorCredentialsConfig = MaxxtonCredentialsConfig | MewsCredentialsConfig;

export enum ConnectorEnum {
    Maxxton = 'MAXXTON',
    Mews = 'MEWS',
}

export type ConnectorRentalUnitConfig = MaxxtonResourceConfig | MewsSpaceTypeConfig;

export type ConnectorSurchargeConfig = MaxxtonResourceConfig | MewsProductConfig;

export type ConnectorSurchargeConfigInput = {
    /** The Connector for which configuration will be provided. */
    connector: ConnectorEnum;
    /** The relevant settings for the selected Connector. This must be submitted as a JSON string because this input is polymorphic. For the fields that need to be provided for each Connector, see the corresponding subtype of `ConnectorSurchargeConfig`. */
    settings: Scalars['String']['input'];
};

/** Name, phone numbers, and email addresses */
export type ContactInfo = {
    __typename?: 'ContactInfo';
    /** Name of contact person */
    contactName?: Maybe<Scalars['String']['output']>;
    /** Email addresses */
    emails: Array<Scalars['String']['output']>;
    /** Phone numbers */
    phoneNumbers: Array<Scalars['String']['output']>;
};

export type ContactInfoInput = {
    contactName?: InputMaybe<Scalars['String']['input']>;
    emails?: InputMaybe<Array<Scalars['String']['input']>>;
    phoneNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Contract = {
    __typename?: 'Contract';
    bookingType: BookingTypeEnum;
    commissionPercentage: Scalars['Float']['output'];
    contractUrl?: Maybe<Scalars['String']['output']>;
    mayHandleSecurityDepositPayment: Scalars['Boolean']['output'];
    name: Scalars['String']['output'];
    surchargePaymentType: PaymentTypeEnum;
    toBePaidThroughTor: Scalars['Boolean']['output'];
};

export type CreateActivityTimeslotInput = {
    activityId: Scalars['ID']['input'];
    timeslot: TimeslotInput;
};

export type CreateActivityTimeslotPayload = {
    __typename?: 'CreateActivityTimeslotPayload';
    /** @deprecated Replaced with Timeslot for consistency */
    activity?: Maybe<Activity>;
    timeslot?: Maybe<Timeslot>;
};

export type CreateAllotmentLockoutInput = {
    comment?: InputMaybe<Scalars['String']['input']>;
    endDate: Scalars['Date']['input'];
    rentalUnitId: Scalars['ID']['input'];
    source?: InputMaybe<Scalars['String']['input']>;
    startDate: Scalars['Date']['input'];
    type: AllotmentLockoutTypeEnum;
};

export type CreateAllotmentLockoutPayload = {
    __typename?: 'CreateAllotmentLockoutPayload';
    allotmentLockout?: Maybe<AllotmentLockout>;
};

export type CreateDatePricingModifierInput = {
    endDate: Scalars['Date']['input'];
    maxDuration: Scalars['Int']['input'];
    minDuration: Scalars['Int']['input'];
    rentalUnitId: Scalars['ID']['input'];
    startDate: Scalars['Date']['input'];
    type: DatePricingModifierTypeEnum;
    value: Scalars['Float']['input'];
    valueType: DatePricingModifierValueTypeEnum;
};

export type CreateDatePricingModifierPayload = {
    __typename?: 'CreateDatePricingModifierPayload';
    datePricingModifier?: Maybe<DatePricingModifier>;
};

export type CreateImageUploadPayload = {
    __typename?: 'CreateImageUploadPayload';
    putUrl: Scalars['String']['output'];
    uploadId: Scalars['ID']['output'];
};

export type CreateRentalUnitSurchargeInput = {
    calculation: SurchargeCalculationEnum;
    /** Configuration for linking this Surcharge via a Connector. Submit `null` to clear existing configuration. */
    connectorConfig?: InputMaybe<ConnectorSurchargeConfigInput>;
    endDate?: InputMaybe<Scalars['Date']['input']>;
    handlePayment: Scalars['Boolean']['input'];
    maxAmount: Scalars['Int']['input'];
    minAmount: Scalars['Int']['input'];
    rentalUnitId: Scalars['ID']['input'];
    startDate: Scalars['Date']['input'];
    surchargeId: Scalars['ID']['input'];
    unitPrice: Scalars['Float']['input'];
};

export type CreateRentalUnitSurchargePayload = {
    __typename?: 'CreateRentalUnitSurchargePayload';
    rentalUnitSurcharge?: Maybe<RentalUnitSurcharge>;
};

export type CreateVisitorRegistrationInput = {
    arrivalDate: Scalars['Date']['input'];
    bookingId?: InputMaybe<Scalars['ID']['input']>;
    city: Scalars['String']['input'];
    /** ISO 3166-1 Alpha-2 country code. */
    countryCode: Scalars['String']['input'];
    departureDate: Scalars['Date']['input'];
    firstName: Scalars['String']['input'];
    identificationType: IdentificationTypeEnum;
    lastName: Scalars['String']['input'];
    lockoutId?: InputMaybe<Scalars['ID']['input']>;
    rentalUnitId: Scalars['ID']['input'];
    totalPersons: Scalars['Int']['input'];
};

export type CreateVisitorRegistrationPayload = {
    __typename?: 'CreateVisitorRegistrationPayload';
    visitorRegistration?: Maybe<VisitorRegistration>;
};

/** Customer details */
export type Customer = {
    __typename?: 'Customer';
    /** Current address of the customer. */
    address: Address;
    /** Date of birth of the customer. */
    birthdate?: Maybe<Scalars['Date']['output']>;
    /** Email address of the customer. */
    email?: Maybe<Scalars['String']['output']>;
    /** First name of the customer. */
    firstName?: Maybe<Scalars['String']['output']>;
    /** Unique identifier of the customer. */
    id: Scalars['ID']['output'];
    /** Last name of the customer. */
    lastName?: Maybe<Scalars['String']['output']>;
    /** Locale preference. Represented as a 2-letter language code. */
    locale: Scalars['String']['output'];
    /** Phone number of the customer. */
    phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type DatePricing = {
    __typename?: 'DatePricing';
    arrivalAllowed: Scalars['Boolean']['output'];
    baseStayPrice: Scalars['Float']['output'];
    date: Scalars['Date']['output'];
    departureAllowed: Scalars['Boolean']['output'];
    extraPersonPrice: Scalars['Float']['output'];
    id: Scalars['ID']['output'];
    minimumStayDuration: Scalars['Int']['output'];
    minimumStayPrice: Scalars['Float']['output'];
    nightPrice: Scalars['Float']['output'];
    rentalUnit: RentalUnit;
    weekPrice?: Maybe<Scalars['Float']['output']>;
};

export type DatePricingFlex = {
    baseStayPrice?: InputMaybe<Scalars['Float']['input']>;
    extraPersonPrice?: InputMaybe<Scalars['Float']['input']>;
    friday: FlexPricingDay;
    minimumStayPrice?: InputMaybe<Scalars['Float']['input']>;
    monday: FlexPricingDay;
    saturday: FlexPricingDay;
    sunday: FlexPricingDay;
    thursday: FlexPricingDay;
    tuesday: FlexPricingDay;
    wednesday: FlexPricingDay;
    weekPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type DatePricingHybrid = {
    baseStayPrice?: InputMaybe<Scalars['Float']['input']>;
    extraPersonPrice?: InputMaybe<Scalars['Float']['input']>;
    midweekPrice: Scalars['Float']['input'];
    minimumStayDuration?: InputMaybe<Scalars['Int']['input']>;
    minimumStayPrice?: InputMaybe<Scalars['Float']['input']>;
    /** Will default to TRUE if not submitted as this is the default start day for week stays. */
    offerWeekFriday?: InputMaybe<Scalars['Boolean']['input']>;
    offerWeekMonday?: InputMaybe<Scalars['Boolean']['input']>;
    offerWeekSaturday?: InputMaybe<Scalars['Boolean']['input']>;
    weekPrice?: InputMaybe<Scalars['Float']['input']>;
    weekendPrice: Scalars['Float']['input'];
};

export type DatePricingModifier = {
    __typename?: 'DatePricingModifier';
    endDate: Scalars['Date']['output'];
    id: Scalars['ID']['output'];
    maxDuration: Scalars['Int']['output'];
    minDuration: Scalars['Int']['output'];
    rentalUnit: RentalUnit;
    startDate: Scalars['Date']['output'];
    type: DatePricingModifierTypeEnum;
    value: Scalars['Float']['output'];
    valueType: DatePricingModifierValueTypeEnum;
};

export enum DatePricingModifierTypeEnum {
    Addition = 'ADDITION',
    Deduction = 'DEDUCTION',
}

export enum DatePricingModifierValueTypeEnum {
    Amount = 'AMOUNT',
    Percentage = 'PERCENTAGE',
}

export type DatePricingPeriod = {
    endDate: Scalars['Date']['input'];
    flex?: InputMaybe<DatePricingFlex>;
    hybrid?: InputMaybe<DatePricingHybrid>;
    sourceRentalUnit?: InputMaybe<Scalars['ID']['input']>;
    startDate: Scalars['Date']['input'];
    week?: InputMaybe<DatePricingWeek>;
};

export type DatePricingWeek = {
    extraPersonPrice?: InputMaybe<Scalars['Float']['input']>;
    friday: WeekPricingDay;
    monday: WeekPricingDay;
    saturday: WeekPricingDay;
    sunday: WeekPricingDay;
    thursday: WeekPricingDay;
    tuesday: WeekPricingDay;
    wednesday: WeekPricingDay;
    weekPrice: Scalars['Float']['input'];
};

export type DeleteActivityRateGroupInput = {
    activityId: Scalars['ID']['input'];
    rateGroupId: Scalars['ID']['input'];
};

export type DeleteActivityRateGroupPayload = {
    __typename?: 'DeleteActivityRateGroupPayload';
    activity?: Maybe<Activity>;
};

export type DeleteActivityTimeslotInput = {
    timeslotId: Scalars['ID']['input'];
};

export type DeleteActivityTimeslotPayload = {
    __typename?: 'DeleteActivityTimeslotPayload';
    /** @deprecated Replaced with ID for consistency */
    activity?: Maybe<Activity>;
    id?: Maybe<Scalars['ID']['output']>;
};

export type DeleteAllotmentLockoutInput = {
    allotmentLockoutId: Scalars['ID']['input'];
};

export type DeleteAllotmentLockoutPayload = {
    __typename?: 'DeleteAllotmentLockoutPayload';
    id?: Maybe<Scalars['ID']['output']>;
};

export type DeleteCompanyRateGroupInput = {
    companyId: Scalars['ID']['input'];
    rateGroupId: Scalars['ID']['input'];
};

export type DeleteCompanyRateGroupPayload = {
    __typename?: 'DeleteCompanyRateGroupPayload';
    company?: Maybe<Company>;
};

export type DeleteDatePricingModifierInput = {
    datePricingModifierId: Scalars['ID']['input'];
};

export type DeleteDatePricingModifierPayload = {
    __typename?: 'DeleteDatePricingModifierPayload';
    id?: Maybe<Scalars['Int']['output']>;
};

export type DeleteRentalUnitSurchargeInput = {
    rentalUnitSurchargeId: Scalars['ID']['input'];
};

export type DeleteRentalUnitSurchargePayload = {
    __typename?: 'DeleteRentalUnitSurchargePayload';
    id?: Maybe<Scalars['ID']['output']>;
};

export type EditAccommodationDescriptionInput = {
    accommodationId: Scalars['ID']['input'];
    descriptionGeneral?: InputMaybe<Scalars['String']['input']>;
};

export type EditAccommodationDescriptionPayload = {
    __typename?: 'EditAccommodationDescriptionPayload';
    accommodation?: Maybe<Accommodation>;
};

export type EditAccommodationImagesInput = {
    accommodationId: Scalars['ID']['input'];
    images: Array<AccommodationImageInput>;
    listImageUploadId?: InputMaybe<Scalars['ID']['input']>;
    mainImageUploadId?: InputMaybe<Scalars['ID']['input']>;
};

export type EditAccommodationImagesPayload = {
    __typename?: 'EditAccommodationImagesPayload';
    accommodation?: Maybe<Accommodation>;
};

export type EditAccommodationInfoInput = {
    caretaker: ContactInfoInput;
    checkInEndTime: Scalars['Time']['input'];
    checkInStartTime: Scalars['Time']['input'];
    checkOutTime: Scalars['Time']['input'];
    publicReservationsEmail?: InputMaybe<Scalars['String']['input']>;
    publicReservationsPhoneNumber?: InputMaybe<Scalars['String']['input']>;
    publicReservationsWebsiteUrl?: InputMaybe<Scalars['String']['input']>;
    rentalUnitId: Scalars['ID']['input'];
    reservations: ContactInfoInput;
    smsNotificationNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EditAccommodationInfoPayload = {
    __typename?: 'EditAccommodationInfoPayload';
    rentalUnit?: Maybe<RentalUnit>;
};

export type EditActivityDescriptionInput = {
    activityId: Scalars['ID']['input'];
    description?: InputMaybe<Scalars['String']['input']>;
    descriptionParticulars?: InputMaybe<Scalars['String']['input']>;
};

export type EditActivityDescriptionPayload = {
    __typename?: 'EditActivityDescriptionPayload';
    activity?: Maybe<Activity>;
};

export type EditActivityImagesInput = {
    activityId: Scalars['ID']['input'];
    images: Array<ActivityImageInput>;
    listImageUploadId?: InputMaybe<Scalars['ID']['input']>;
    mainImageUploadId?: InputMaybe<Scalars['ID']['input']>;
    videoUrl?: InputMaybe<Scalars['String']['input']>;
};

export type EditActivityImagesPayload = {
    __typename?: 'EditActivityImagesPayload';
    activity?: Maybe<Activity>;
};

export type EditActivityInfoInput = {
    activityId: Scalars['ID']['input'];
    externalBookingUrl?: InputMaybe<Scalars['String']['input']>;
    hideForPartner?: InputMaybe<Scalars['Boolean']['input']>;
    maxCapacity?: InputMaybe<Scalars['Int']['input']>;
    minCapacity?: InputMaybe<Scalars['Int']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    targetAudiences?: InputMaybe<Array<TargetAudienceEnum>>;
};

export type EditActivityInfoPayload = {
    __typename?: 'EditActivityInfoPayload';
    activity?: Maybe<Activity>;
};

export type EditActivityLocationInput = {
    activityId: Scalars['ID']['input'];
    coordinates?: InputMaybe<LatLonInput>;
    descriptionLocation?: InputMaybe<Scalars['String']['input']>;
    locationAddress?: InputMaybe<AddressInput>;
};

export type EditActivityLocationPayload = {
    __typename?: 'EditActivityLocationPayload';
    activity?: Maybe<Activity>;
};

export type EditActivityTimeslotInput = {
    timeslot: TimeslotInput;
    timeslotId: Scalars['ID']['input'];
};

export type EditActivityTimeslotPayload = {
    __typename?: 'EditActivityTimeslotPayload';
    /** @deprecated Replaced with Timeslot for consistency */
    activity?: Maybe<Activity>;
    timeslot?: Maybe<Timeslot>;
};

export type EditAllotmentInput = {
    amount: Scalars['Int']['input'];
    date: Scalars['Date']['input'];
    rentalUnitId: Scalars['ID']['input'];
};

export type EditAllotmentLockoutInput = {
    allotmentLockoutId: Scalars['ID']['input'];
    comment?: InputMaybe<Scalars['String']['input']>;
    endDate: Scalars['Date']['input'];
    source?: InputMaybe<Scalars['String']['input']>;
    startDate: Scalars['Date']['input'];
    type: AllotmentLockoutTypeEnum;
};

export type EditAllotmentLockoutPayload = {
    __typename?: 'EditAllotmentLockoutPayload';
    allotmentLockout?: Maybe<AllotmentLockout>;
};

export type EditAllotmentPayload = {
    __typename?: 'EditAllotmentPayload';
    allotment?: Maybe<Allotment>;
};

export type EditAttributesInput = {
    attributes: Array<AttributeInput>;
    rentalUnitId: Scalars['ID']['input'];
};

export type EditAttributesPayload = {
    __typename?: 'EditAttributesPayload';
    rentalUnit?: Maybe<RentalUnit>;
};

export type EditCapacityAndSpaceInput = {
    amountBathrooms?: InputMaybe<Scalars['Int']['input']>;
    amountBedrooms?: InputMaybe<Scalars['Int']['input']>;
    amountBeds?: InputMaybe<Scalars['Int']['input']>;
    amountToilets?: InputMaybe<Scalars['Int']['input']>;
    includedOccupancy: Scalars['Int']['input'];
    livingArea?: InputMaybe<Scalars['Int']['input']>;
    maxExtraBabyOccupancy: Scalars['Int']['input'];
    maxOccupancy: Scalars['Int']['input'];
    maxVehicleSize?: InputMaybe<Scalars['Int']['input']>;
    minOccupancy: Scalars['Int']['input'];
    outdoorArea?: InputMaybe<Scalars['Int']['input']>;
    petsAllowed: Scalars['Int']['input'];
    rentalUnitId: Scalars['ID']['input'];
};

export type EditCapacityAndSpacePayload = {
    __typename?: 'EditCapacityAndSpacePayload';
    rentalUnit?: Maybe<RentalUnit>;
};

export type EditCompanyDescriptionInput = {
    companyId: Scalars['ID']['input'];
    description?: InputMaybe<Scalars['String']['input']>;
    descriptionParticulars?: InputMaybe<Scalars['String']['input']>;
};

export type EditCompanyDescriptionPayload = {
    __typename?: 'EditCompanyDescriptionPayload';
    company?: Maybe<Company>;
};

export type EditCompanyImagesInput = {
    companyId: Scalars['ID']['input'];
    fullCircleImageUrl?: InputMaybe<Scalars['String']['input']>;
    images: Array<CompanyImageInput>;
    listImageUploadId?: InputMaybe<Scalars['ID']['input']>;
    logoImageUploadId?: InputMaybe<Scalars['ID']['input']>;
    mainImageUploadId?: InputMaybe<Scalars['ID']['input']>;
    videoUrl?: InputMaybe<Scalars['String']['input']>;
};

export type EditCompanyImagesPayload = {
    __typename?: 'EditCompanyImagesPayload';
    company?: Maybe<Company>;
};

export type EditCompanyInfoInput = {
    capacity?: InputMaybe<Scalars['Int']['input']>;
    companyId: Scalars['ID']['input'];
    email?: InputMaybe<Scalars['String']['input']>;
    phoneNumber?: InputMaybe<Scalars['String']['input']>;
    reservations: ContactInfoInput;
    socialsFacebookUrl?: InputMaybe<Scalars['String']['input']>;
    socialsInstagramUrl?: InputMaybe<Scalars['String']['input']>;
    socialsTwitterUrl?: InputMaybe<Scalars['String']['input']>;
    targetAudiences?: InputMaybe<Array<TargetAudienceEnum>>;
    websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type EditCompanyInfoPayload = {
    __typename?: 'EditCompanyInfoPayload';
    company?: Maybe<Company>;
};

export type EditCompanyLocationInput = {
    address?: InputMaybe<AddressInput>;
    companyId: Scalars['ID']['input'];
    coordinates?: InputMaybe<LatLonInput>;
};

export type EditCompanyLocationPayload = {
    __typename?: 'EditCompanyLocationPayload';
    company?: Maybe<Company>;
};

export type EditCompanyNormalOperatingHoursInput = {
    companyId: Scalars['ID']['input'];
    normalOperatingHours: NormalOperatingHoursInput;
};

export type EditCompanyNormalOperatingHoursPayload = {
    __typename?: 'EditCompanyNormalOperatingHoursPayload';
    company?: Maybe<Company>;
};

export type EditCompanySpecialOperatingHoursInput = {
    companyId: Scalars['ID']['input'];
    specialOperatingHours: Array<SpecialOperatingHoursInput>;
};

export type EditCompanySpecialOperatingHoursPayload = {
    __typename?: 'EditCompanySpecialOperatingHoursPayload';
    company?: Maybe<Company>;
};

export type EditDatePricingArrivalAllowedInput = {
    arrivalAllowed: Scalars['Boolean']['input'];
    datePricingId: Scalars['ID']['input'];
};

export type EditDatePricingArrivalAllowedPayload = {
    __typename?: 'EditDatePricingArrivalAllowedPayload';
    datePricing?: Maybe<DatePricing>;
};

export type EditDatePricingBaseStayPriceInput = {
    baseStayPrice: Scalars['Float']['input'];
    datePricingId: Scalars['ID']['input'];
};

export type EditDatePricingBaseStayPricePayload = {
    __typename?: 'EditDatePricingBaseStayPricePayload';
    datePricing?: Maybe<DatePricing>;
};

export type EditDatePricingDepartureAllowedInput = {
    datePricingId: Scalars['ID']['input'];
    departureAllowed: Scalars['Boolean']['input'];
};

export type EditDatePricingDepartureAllowedPayload = {
    __typename?: 'EditDatePricingDepartureAllowedPayload';
    datePricing?: Maybe<DatePricing>;
};

export type EditDatePricingDisplayOptionsInput = {
    rentalUnitId: Scalars['ID']['input'];
    showBaseStayPriceRow: Scalars['Boolean']['input'];
    showExtraPersonPriceRow: Scalars['Boolean']['input'];
    showMinimumStayPriceRow: Scalars['Boolean']['input'];
    showWeekPriceRow: Scalars['Boolean']['input'];
};

export type EditDatePricingDisplayOptionsPayload = {
    __typename?: 'EditDatePricingDisplayOptionsPayload';
    rentalUnit?: Maybe<RentalUnit>;
};

export type EditDatePricingExtraPersonPriceInput = {
    datePricingId: Scalars['ID']['input'];
    extraPersonPrice: Scalars['Float']['input'];
};

export type EditDatePricingExtraPersonPricePayload = {
    __typename?: 'EditDatePricingExtraPersonPricePayload';
    datePricing?: Maybe<DatePricing>;
};

export type EditDatePricingMinimumStayDurationInput = {
    datePricingId: Scalars['ID']['input'];
    minimumStayDuration: Scalars['Int']['input'];
};

export type EditDatePricingMinimumStayDurationPayload = {
    __typename?: 'EditDatePricingMinimumStayDurationPayload';
    datePricing?: Maybe<DatePricing>;
};

export type EditDatePricingMinimumStayPriceInput = {
    datePricingId: Scalars['ID']['input'];
    minimumStayPrice: Scalars['Float']['input'];
};

export type EditDatePricingMinimumStayPricePayload = {
    __typename?: 'EditDatePricingMinimumStayPricePayload';
    datePricing?: Maybe<DatePricing>;
};

export type EditDatePricingModifierInput = {
    datePricingModifierId: Scalars['ID']['input'];
    endDate: Scalars['Date']['input'];
    maxDuration: Scalars['Int']['input'];
    minDuration: Scalars['Int']['input'];
    startDate: Scalars['Date']['input'];
    type: DatePricingModifierTypeEnum;
    value: Scalars['Float']['input'];
    valueType: DatePricingModifierValueTypeEnum;
};

export type EditDatePricingModifierPayload = {
    __typename?: 'EditDatePricingModifierPayload';
    datePricingModifier?: Maybe<DatePricingModifier>;
};

export type EditDatePricingNightPriceInput = {
    datePricingId: Scalars['ID']['input'];
    nightPrice: Scalars['Float']['input'];
};

export type EditDatePricingNightPricePayload = {
    __typename?: 'EditDatePricingNightPricePayload';
    datePricing?: Maybe<DatePricing>;
};

export type EditDatePricingWeekPriceInput = {
    datePricingId: Scalars['ID']['input'];
    weekPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type EditDatePricingWeekPricePayload = {
    __typename?: 'EditDatePricingWeekPricePayload';
    datePricing?: Maybe<DatePricing>;
};

export type EditDiscountForUnitInput = {
    earlyBookDiscount6Months?: InputMaybe<Scalars['Float']['input']>;
    earlyBookDiscount12Months?: InputMaybe<Scalars['Float']['input']>;
    earlyBookDiscountEnabled: Scalars['Boolean']['input'];
    flexCancellationDays?: InputMaybe<Scalars['Int']['input']>;
    flexibleArrival: Scalars['Boolean']['input'];
    flexibleDeparture?: InputMaybe<Scalars['Boolean']['input']>;
    lastminuteDiscount1Week?: InputMaybe<Scalars['Float']['input']>;
    lastminuteDiscount2Week?: InputMaybe<Scalars['Float']['input']>;
    lastminuteDiscount3Week?: InputMaybe<Scalars['Float']['input']>;
    lastminuteDiscount4Week?: InputMaybe<Scalars['Float']['input']>;
    lastminuteDiscountEnabled: Scalars['Boolean']['input'];
    rentalUnitId: Scalars['ID']['input'];
};

export type EditDiscountForUnitPayload = {
    __typename?: 'EditDiscountForUnitPayload';
    rentalUnit?: Maybe<RentalUnit>;
};

export type EditIcalImportFeedsInput = {
    importFeeds: Array<IcalEditImportFeedInput>;
    rentalUnitId: Scalars['ID']['input'];
};

export type EditIcalImportFeedsPayload = {
    __typename?: 'EditIcalImportFeedsPayload';
    icalImportFeeds?: Maybe<Array<IcalImportFeed>>;
};

export type EditKeyHolderInput = {
    keyHolder: ContactInfoInput;
    keyHolderAddress: AddressInput;
    keyHolderExplanation?: InputMaybe<Scalars['String']['input']>;
    rentalUnitId: Scalars['ID']['input'];
};

export type EditKeyHolderPayload = {
    __typename?: 'EditKeyHolderPayload';
    rentalUnit?: Maybe<RentalUnit>;
};

export type EditLocationAndDistanceInput = {
    address?: InputMaybe<AddressInput>;
    coordinates?: InputMaybe<LatLonInput>;
    descriptionLocation?: InputMaybe<Scalars['String']['input']>;
    rentalUnitId: Scalars['ID']['input'];
};

export type EditLocationAndDistancePayload = {
    __typename?: 'EditLocationAndDistancePayload';
    rentalUnit?: Maybe<RentalUnit>;
};

export type EditOwnerInformationInput = {
    ownerImageUploadId?: InputMaybe<Scalars['ID']['input']>;
    ownerIntroduction?: InputMaybe<Scalars['String']['input']>;
    ownerName?: InputMaybe<Scalars['String']['input']>;
    ownerProfileVisible: Scalars['Boolean']['input'];
    ownerTips?: InputMaybe<Scalars['String']['input']>;
    rentalUnitId: Scalars['ID']['input'];
};

export type EditOwnerInformationPayload = {
    __typename?: 'EditOwnerInformationPayload';
    rentalUnit?: Maybe<RentalUnit>;
};

export type EditPartnerConnectorSettingsInput = {
    /** The Connector for which configuration will be provided. Submit `null` to clear existing settings. */
    connector?: InputMaybe<ConnectorEnum>;
    partnerId: Scalars['ID']['input'];
    /** The relevant settings for the selected Connector. This must be submitted as a JSON string because this input is polymorphic. For the fields that need to be provided for each Connector, see the corresponding subtype of `ConnectorCredentialsConfig`. */
    settings?: InputMaybe<Scalars['String']['input']>;
};

export type EditPartnerConnectorSettingsPayload = {
    __typename?: 'EditPartnerConnectorSettingsPayload';
    /** The Partner object, if it was succesfully found and updated. */
    partner?: Maybe<Partner>;
};

export type EditRentalUnitAndAccommodationInfoInput = {
    caretaker: ContactInfoInput;
    checkInEndTime: Scalars['Time']['input'];
    checkInStartTime: Scalars['Time']['input'];
    checkOutTime: Scalars['Time']['input'];
    handleSecurityDepositPayment: Scalars['Boolean']['input'];
    publicReservationsEmail?: InputMaybe<Scalars['String']['input']>;
    publicReservationsPhoneNumber?: InputMaybe<Scalars['String']['input']>;
    publicReservationsWebsiteUrl?: InputMaybe<Scalars['String']['input']>;
    releaseDays: Scalars['Int']['input'];
    rentalUnitId: Scalars['ID']['input'];
    reservations: ContactInfoInput;
    securityDeposit: Scalars['Float']['input'];
    tagline?: InputMaybe<Scalars['String']['input']>;
};

export type EditRentalUnitAndAccommodationInfoPayload = {
    __typename?: 'EditRentalUnitAndAccommodationInfoPayload';
    rentalUnit?: Maybe<RentalUnit>;
};

export type EditRentalUnitConnectorSettingsInput = {
    /** The Connector for which configuration will be provided. Submit `null` to clear existing settings. */
    connector?: InputMaybe<ConnectorEnum>;
    rentalUnitId: Scalars['ID']['input'];
    /** The relevant settings for the selected Connector. This must be submitted as a JSON string because this input is polymorphic. For the fields that need to be provided for each Connector, see the corresponding subtype of `ConnectorRentalUnitConfig`. */
    settings?: InputMaybe<Scalars['String']['input']>;
};

export type EditRentalUnitConnectorSettingsPayload = {
    __typename?: 'EditRentalUnitConnectorSettingsPayload';
    /** The RentalUnit object, if it was succesfully found and updated. */
    rentalUnit?: Maybe<RentalUnit>;
};

export type EditRentalUnitDescriptionsInput = {
    descriptionExtras?: InputMaybe<Scalars['String']['input']>;
    descriptionLayout?: InputMaybe<Scalars['String']['input']>;
    descriptionParticulars?: InputMaybe<Scalars['String']['input']>;
    rentalUnitId: Scalars['ID']['input'];
};

export type EditRentalUnitDescriptionsPayload = {
    __typename?: 'EditRentalUnitDescriptionsPayload';
    rentalUnit?: Maybe<RentalUnit>;
};

export type EditRentalUnitImagesInput = {
    fullCircleImageUrl?: InputMaybe<Scalars['String']['input']>;
    images: Array<AccommodationImageInput>;
    listImageUploadId?: InputMaybe<Scalars['ID']['input']>;
    mainImageUploadId?: InputMaybe<Scalars['ID']['input']>;
    rentalUnitId: Scalars['ID']['input'];
    videoUrl?: InputMaybe<Scalars['String']['input']>;
};

export type EditRentalUnitImagesPayload = {
    __typename?: 'EditRentalUnitImagesPayload';
    rentalUnit?: Maybe<RentalUnit>;
};

export type EditRentalUnitInfoInput = {
    handleSecurityDepositPayment: Scalars['Boolean']['input'];
    hideForPartner?: InputMaybe<Scalars['Boolean']['input']>;
    keyHolderExplanation?: InputMaybe<Scalars['String']['input']>;
    releaseDays: Scalars['Int']['input'];
    rentalUnitId: Scalars['ID']['input'];
    securityDeposit: Scalars['Float']['input'];
    tagline?: InputMaybe<Scalars['String']['input']>;
};

export type EditRentalUnitInfoPayload = {
    __typename?: 'EditRentalUnitInfoPayload';
    rentalUnit?: Maybe<RentalUnit>;
};

export type EditRentalUnitSurchargeInput = {
    calculation: SurchargeCalculationEnum;
    /** Configuration for linking this Surcharge via a Connector. Submit `null` to clear existing configuration. */
    connectorConfig?: InputMaybe<ConnectorSurchargeConfigInput>;
    endDate?: InputMaybe<Scalars['Date']['input']>;
    handlePayment: Scalars['Boolean']['input'];
    maxAmount: Scalars['Int']['input'];
    minAmount: Scalars['Int']['input'];
    rentalUnitSurchargeId: Scalars['ID']['input'];
    startDate: Scalars['Date']['input'];
    unitPrice: Scalars['Float']['input'];
};

export type EditRentalUnitSurchargePayload = {
    __typename?: 'EditRentalUnitSurchargePayload';
    rentalUnitSurcharge?: Maybe<RentalUnitSurcharge>;
};

export type EditReviewReplyInput = {
    reply: Scalars['String']['input'];
    reviewId: Scalars['ID']['input'];
};

export type EditReviewReplyPayload = {
    __typename?: 'EditReviewReplyPayload';
    review?: Maybe<Review>;
};

export type EditSpecialParticipationDateBlockInput = {
    blocked: Scalars['Boolean']['input'];
    date: Scalars['Date']['input'];
    specialParticipationId: Scalars['ID']['input'];
};

export type EditSpecialParticipationDateBlockPayload = {
    __typename?: 'EditSpecialParticipationDateBlockPayload';
    specialParticipation?: Maybe<SpecialParticipation>;
};

export type EditSpecialParticipationInput = {
    optIn: Scalars['Boolean']['input'];
    specialParticipationId: Scalars['ID']['input'];
};

export type EditSpecialParticipationPayload = {
    __typename?: 'EditSpecialParticipationPayload';
    specialParticipation?: Maybe<SpecialParticipation>;
};

export type FlexPricingDay = {
    arrivalAllowed: Scalars['Boolean']['input'];
    minimumStayDuration: Scalars['Int']['input'];
    nightPrice: Scalars['Float']['input'];
};

export enum FlexTypeEnum {
    Arrival = 'ARRIVAL',
    Both = 'BOTH',
    Departure = 'DEPARTURE',
    None = 'NONE',
}

export type IcalEditImportFeedInput = {
    icalImportUrl: Scalars['String']['input'];
    source?: InputMaybe<Scalars['String']['input']>;
};

export type IcalImportFeed = {
    __typename?: 'IcalImportFeed';
    createdAt: Scalars['Date']['output'];
    icalImportUrl: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    source?: Maybe<Scalars['String']['output']>;
    updatedAt: Scalars['Date']['output'];
};

export enum IdentificationTypeEnum {
    Card = 'CARD',
    DrivingLicense = 'DRIVING_LICENSE',
    Passport = 'PASSPORT',
}

export type Image = {
    dimensions: Array<Scalars['Int']['output']>;
    id: Scalars['ID']['output'];
    previewUrl?: Maybe<Scalars['String']['output']>;
    size: Scalars['Int']['output'];
    url: Scalars['String']['output'];
};

export type InitTicketScanSessionInput = {
    email: Scalars['String']['input'];
    partnerId: Scalars['ID']['input'];
    sessionExpiresAt?: InputMaybe<Scalars['DateTime']['input']>;
    sessionLabel: Scalars['String']['input'];
};

export type InitTicketScanSessionPayload = {
    __typename?: 'InitTicketScanSessionPayload';
    deepLink?: Maybe<Scalars['String']['output']>;
};

export enum InvoiceTotalAtEnum {
    ActivityEnd = 'ACTIVITY_END',
    Booking = 'BOOKING',
}

export type LatLon = {
    __typename?: 'LatLon';
    lat: Scalars['Float']['output'];
    lon: Scalars['Float']['output'];
};

export type LatLonInput = {
    lat: Scalars['Float']['input'];
    lon: Scalars['Float']['input'];
};

export type MaxxtonCredentialsConfig = {
    __typename?: 'MaxxtonCredentialsConfig';
    clientId?: Maybe<Scalars['String']['output']>;
    clientSecret?: Maybe<Scalars['String']['output']>;
    distributionChannelCode?: Maybe<Scalars['String']['output']>;
    scope?: Maybe<Scalars['String']['output']>;
};

export type MaxxtonResourceConfig = {
    __typename?: 'MaxxtonResourceConfig';
    resourceId?: Maybe<Scalars['Int']['output']>;
};

export type MewsCredentialsConfig = {
    __typename?: 'MewsCredentialsConfig';
    connectionToken?: Maybe<Scalars['String']['output']>;
};

export type MewsProductConfig = {
    __typename?: 'MewsProductConfig';
    code?: Maybe<Scalars['String']['output']>;
};

export type MewsSpaceTypeConfig = {
    __typename?: 'MewsSpaceTypeConfig';
    ratePlanCode?: Maybe<Scalars['String']['output']>;
    spaceTypeCode?: Maybe<Scalars['String']['output']>;
};

export type MissingVisitorRegistration = AllotmentLockout | Booking;

export type NormalOperatingHoursInput = {
    friday: Array<TimeOpenClosedInput>;
    monday: Array<TimeOpenClosedInput>;
    saturday: Array<TimeOpenClosedInput>;
    sunday: Array<TimeOpenClosedInput>;
    thursday: Array<TimeOpenClosedInput>;
    tuesday: Array<TimeOpenClosedInput>;
    wednesday: Array<TimeOpenClosedInput>;
};

/**
 * One week of operating hours, optionally with a start and end date. Companies can have one default set of operating hours and multiple overrides. The operating hours are defined per day and are nullable. The different states have different meanings:
 *
 *
 *             An array with hours define the hours for that day, an empty array means that the company is closed on that day, a null value means that we should fall back to the default operating hours
 */
export type OperatingHours = {
    __typename?: 'OperatingHours';
    /** End of special operating hours period. */
    endDate?: Maybe<Scalars['Date']['output']>;
    friday?: Maybe<Array<TimeOpenClosed>>;
    monday?: Maybe<Array<TimeOpenClosed>>;
    saturday?: Maybe<Array<TimeOpenClosed>>;
    /** Start of special operating hours period. */
    startDate?: Maybe<Scalars['Date']['output']>;
    sunday?: Maybe<Array<TimeOpenClosed>>;
    thursday?: Maybe<Array<TimeOpenClosed>>;
    tuesday?: Maybe<Array<TimeOpenClosed>>;
    wednesday?: Maybe<Array<TimeOpenClosed>>;
};

export type OverwriteRentalUnitSurchargesInput = {
    sourceRentalUnitId: Scalars['ID']['input'];
    targetRentalUnitIds: Array<Scalars['ID']['input']>;
};

export type OverwriteRentalUnitSurchargesPayload = {
    __typename?: 'OverwriteRentalUnitSurchargesPayload';
    targetRentalUnits?: Maybe<Array<RentalUnit>>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
    __typename?: 'PageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']['output']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean']['output'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean']['output'];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']['output']>;
};

/** A partner represents the legal entity that has ownership over other objects in Travelbase (such as accommodations and bookings). */
export type Partner = {
    __typename?: 'Partner';
    /** Accommodations that belong to the partner. */
    accommodations: Array<Accommodation>;
    address?: Maybe<Address>;
    /** Paginated response of all bookings for this partner. */
    allBookings?: Maybe<BookingConnection>;
    allTickets?: Maybe<TicketConnection>;
    /** Paginated response of all visitor registrations for this partner. */
    allVisitorRegistrations?: Maybe<VisitorRegistrationConnection>;
    balance: Scalars['Float']['output'];
    balanceItems: Array<PartnerBalanceItem>;
    bankAccountActive: Scalars['Boolean']['output'];
    bic?: Maybe<Scalars['String']['output']>;
    /** Companies that belong to the partner. */
    companies: Array<Company>;
    connectorConfig?: Maybe<ConnectorCredentialsConfig>;
    /** Name of contact person (required), phone numbers (optional) and email addresses (optional) */
    contact: ContactInfo;
    contactSalutation?: Maybe<Scalars['String']['output']>;
    /** Whether the partner is enabled. */
    enabled: Scalars['Boolean']['output'];
    iban?: Maybe<Scalars['String']['output']>;
    ibanName?: Maybe<Scalars['String']['output']>;
    /** Unique identifier of the partner. */
    id: Scalars['ID']['output'];
    invoiceAddress?: Maybe<Address>;
    invoiceEmails: Array<Scalars['String']['output']>;
    /** All recent stays known to Travelbase for which no visitor registration exists yet. */
    missingVisitorRegistrations: Array<MissingVisitorRegistration>;
    /** Name of the partner. */
    name: Scalars['String']['output'];
    newTickets?: Maybe<TicketConnection>;
    paymentByDirectDebit: Scalars['Boolean']['output'];
    recentlyUpdatedBookings?: Maybe<BookingConnection>;
    reviews: Array<Review>;
    upcomingBookings?: Maybe<BookingConnection>;
    /** Get all bookings that were created or updated after the specified date and time. The result is limited to a maximum of 100 bookings. */
    updatedBookings: Array<Booking>;
    vatNumber?: Maybe<Scalars['String']['output']>;
};

/** A partner represents the legal entity that has ownership over other objects in Travelbase (such as accommodations and bookings). */
export type PartnerAllBookingsArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    endDate?: InputMaybe<Scalars['Date']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    rentalUnitIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    searchQuery?: InputMaybe<Scalars['String']['input']>;
    startDate?: InputMaybe<Scalars['Date']['input']>;
};

/** A partner represents the legal entity that has ownership over other objects in Travelbase (such as accommodations and bookings). */
export type PartnerAllTicketsArgs = {
    activityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    companyId?: InputMaybe<Scalars['ID']['input']>;
    endDate?: InputMaybe<Scalars['Date']['input']>;
    externalTimeslotId?: InputMaybe<Scalars['ID']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    startDate?: InputMaybe<Scalars['Date']['input']>;
    timeslotId?: InputMaybe<Scalars['ID']['input']>;
};

/** A partner represents the legal entity that has ownership over other objects in Travelbase (such as accommodations and bookings). */
export type PartnerAllVisitorRegistrationsArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    endDate?: InputMaybe<Scalars['Date']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    rentalUnitIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    startDate?: InputMaybe<Scalars['Date']['input']>;
};

/** A partner represents the legal entity that has ownership over other objects in Travelbase (such as accommodations and bookings). */
export type PartnerBalanceArgs = {
    atDate?: InputMaybe<Scalars['Date']['input']>;
};

/** A partner represents the legal entity that has ownership over other objects in Travelbase (such as accommodations and bookings). */
export type PartnerBalanceItemsArgs = {
    endDate: Scalars['Date']['input'];
    startDate: Scalars['Date']['input'];
};

/** A partner represents the legal entity that has ownership over other objects in Travelbase (such as accommodations and bookings). */
export type PartnerNewTicketsArgs = {
    activityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    companyId?: InputMaybe<Scalars['ID']['input']>;
    externalTimeslotId?: InputMaybe<Scalars['ID']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    timeslotId?: InputMaybe<Scalars['ID']['input']>;
};

/** A partner represents the legal entity that has ownership over other objects in Travelbase (such as accommodations and bookings). */
export type PartnerRecentlyUpdatedBookingsArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
};

/** A partner represents the legal entity that has ownership over other objects in Travelbase (such as accommodations and bookings). */
export type PartnerReviewsArgs = {
    rentalUnitIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** A partner represents the legal entity that has ownership over other objects in Travelbase (such as accommodations and bookings). */
export type PartnerUpcomingBookingsArgs = {
    after?: InputMaybe<Scalars['String']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
};

/** A partner represents the legal entity that has ownership over other objects in Travelbase (such as accommodations and bookings). */
export type PartnerUpdatedBookingsArgs = {
    since: Scalars['DateTime']['input'];
};

export type PartnerBalanceCorrection = PartnerBalanceItem & {
    __typename?: 'PartnerBalanceCorrection';
    amount: Scalars['Float']['output'];
    createdAt: Scalars['DateTime']['output'];
    description: Scalars['String']['output'];
    id: Scalars['ID']['output'];
};

export type PartnerBalanceItem = {
    amount: Scalars['Float']['output'];
    createdAt: Scalars['DateTime']['output'];
    id: Scalars['ID']['output'];
};

export type PartnerBalancePayment = PartnerBalanceItem & {
    __typename?: 'PartnerBalancePayment';
    amount: Scalars['Float']['output'];
    batchType?: Maybe<PartnerPaymentBatchTypeEnum>;
    createdAt: Scalars['DateTime']['output'];
    id: Scalars['ID']['output'];
};

export type PartnerBookingInvoice = PartnerBalanceItem & {
    __typename?: 'PartnerBookingInvoice';
    amount: Scalars['Float']['output'];
    createdAt: Scalars['DateTime']['output'];
    downloadPdfUrl: Scalars['String']['output'];
    downloadXlsxUrl: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    invoiceNumber: Scalars['String']['output'];
    periodEndDate: Scalars['Date']['output'];
    periodStartDate: Scalars['Date']['output'];
};

export type PartnerMutation = {
    __typename?: 'PartnerMutation';
    acceptBooking: AcceptBookingPayload;
    approveActivityAgreement: ApproveActivityAgreementPayload;
    approveRentalUnitContract: ApproveRentalUnitContractPayload;
    bulkEditAllotments: BulkEditAllotmentsPayload;
    bulkEditDatePricingArrivalAllowed: BulkEditDatePricingArrivalAllowedPayload;
    bulkEditDatePricingBaseStayPrice: BulkEditDatePricingBaseStayPricePayload;
    bulkEditDatePricingDepartureAllowed: BulkEditDatePricingDepartureAllowedPayload;
    bulkEditDatePricingExtraPersonPrice: BulkEditDatePricingExtraPersonPricePayload;
    bulkEditDatePricingMinimumStayDuration: BulkEditDatePricingMinimumStayDurationPayload;
    bulkEditDatePricingMinimumStayPrice: BulkEditDatePricingMinimumStayPricePayload;
    bulkEditDatePricingNightPrice: BulkEditDatePricingNightPricePayload;
    bulkEditDatePricingWeekPrice: BulkEditDatePricingWeekPricePayload;
    bulkUpsertDatePricing: BulkUpsertDatePricingPayload;
    createActivityTimeslot: CreateActivityTimeslotPayload;
    createAllotmentLockout: CreateAllotmentLockoutPayload;
    createDatePricingModifier: CreateDatePricingModifierPayload;
    createImageUpload: CreateImageUploadPayload;
    createRentalUnitSurcharge: CreateRentalUnitSurchargePayload;
    createVisitorRegistration: CreateVisitorRegistrationPayload;
    deleteActivityRateGroup: DeleteActivityRateGroupPayload;
    deleteActivityTimeslot: DeleteActivityTimeslotPayload;
    deleteAllotmentLockout: DeleteAllotmentLockoutPayload;
    deleteCompanyRateGroup: DeleteCompanyRateGroupPayload;
    deleteDatePricingModifier: DeleteDatePricingModifierPayload;
    deleteRentalUnitSurcharge: DeleteRentalUnitSurchargePayload;
    deleteVisitorRegistration: DeleteVisitorRegistrationPayload;
    editAccommodationDescription: EditAccommodationDescriptionPayload;
    editAccommodationImages: EditAccommodationImagesPayload;
    editAccommodationInfo: EditAccommodationInfoPayload;
    editActivityDescription: EditActivityDescriptionPayload;
    editActivityImages: EditActivityImagesPayload;
    editActivityInfo: EditActivityInfoPayload;
    editActivityLocation: EditActivityLocationPayload;
    editActivityTimeslot: EditActivityTimeslotPayload;
    editAllotment: EditAllotmentPayload;
    editAllotmentLockout: EditAllotmentLockoutPayload;
    editAttributes: EditAttributesPayload;
    editCapacityAndSpace: EditCapacityAndSpacePayload;
    editCompanyDescription: EditCompanyDescriptionPayload;
    editCompanyImages: EditCompanyImagesPayload;
    editCompanyInfo: EditCompanyInfoPayload;
    editCompanyLocation: EditCompanyLocationPayload;
    editCompanyNormalOperatingHours: EditCompanyNormalOperatingHoursPayload;
    editCompanySpecialOperatingHours: EditCompanySpecialOperatingHoursPayload;
    editDatePricingArrivalAllowed: EditDatePricingArrivalAllowedPayload;
    editDatePricingBaseStayPrice: EditDatePricingBaseStayPricePayload;
    editDatePricingDepartureAllowed: EditDatePricingDepartureAllowedPayload;
    editDatePricingDisplayOptions: EditDatePricingDisplayOptionsPayload;
    editDatePricingExtraPersonPrice: EditDatePricingExtraPersonPricePayload;
    editDatePricingMinimumStayDuration: EditDatePricingMinimumStayDurationPayload;
    editDatePricingMinimumStayPrice: EditDatePricingMinimumStayPricePayload;
    editDatePricingModifier: EditDatePricingModifierPayload;
    editDatePricingNightPrice: EditDatePricingNightPricePayload;
    editDatePricingWeekPrice: EditDatePricingWeekPricePayload;
    editDiscounts: EditDiscountForUnitPayload;
    editIcalImportFeeds: EditIcalImportFeedsPayload;
    editKeyHolder: EditKeyHolderPayload;
    editLocationAndDistance: EditLocationAndDistancePayload;
    editOwnerInformation: EditOwnerInformationPayload;
    editPartnerConnectorSettings: EditPartnerConnectorSettingsPayload;
    editRentalUnitAndAccommodationInfo: EditRentalUnitAndAccommodationInfoPayload;
    editRentalUnitConnectorSettings: EditRentalUnitConnectorSettingsPayload;
    editRentalUnitDescriptions: EditRentalUnitDescriptionsPayload;
    editRentalUnitImages: EditRentalUnitImagesPayload;
    editRentalUnitInfo: EditRentalUnitInfoPayload;
    editRentalUnitSurcharge: EditRentalUnitSurchargePayload;
    editReviewReply: EditReviewReplyPayload;
    editSpecialParticipationDateBlock: EditSpecialParticipationDateBlockPayload;
    editSpecialParticipationOptIn: EditSpecialParticipationPayload;
    initTicketScanSession: InitTicketScanSessionPayload;
    overwriteRentalUnitSurcharges: OverwriteRentalUnitSurchargesPayload;
    registerNewPartner: RegisterNewPartnerPayload;
    resetPassword: ResetPasswordPayload;
    sendPasswordResetMail: SendPasswordResetMailPayload;
    setActivityTimeslots: SetActivityTimeslotsPayload;
    uploadTripPricings: UploadTripPricingsPayload;
    upsertActivityRateGroup: UpsertActivityRateGroupPayload;
    upsertCompanyRateGroup: UpsertCompanyRateGroupPayload;
    validatePartnerConnectorSettings: ValidatePartnerConnectorSettingsPayload;
};

export type PartnerMutationAcceptBookingArgs = {
    input: AcceptBookingInput;
};

export type PartnerMutationApproveActivityAgreementArgs = {
    input: ApproveActivityAgreementInput;
};

export type PartnerMutationApproveRentalUnitContractArgs = {
    input: ApproveRentalUnitContractInput;
};

export type PartnerMutationBulkEditAllotmentsArgs = {
    input: BulkEditAllotmentsInput;
};

export type PartnerMutationBulkEditDatePricingArrivalAllowedArgs = {
    input: BulkEditDatePricingArrivalAllowedInput;
};

export type PartnerMutationBulkEditDatePricingBaseStayPriceArgs = {
    input: BulkEditDatePricingBaseStayPriceInput;
};

export type PartnerMutationBulkEditDatePricingDepartureAllowedArgs = {
    input: BulkEditDatePricingDepartureAllowedInput;
};

export type PartnerMutationBulkEditDatePricingExtraPersonPriceArgs = {
    input: BulkEditDatePricingExtraPersonPriceInput;
};

export type PartnerMutationBulkEditDatePricingMinimumStayDurationArgs = {
    input: BulkEditDatePricingMinimumStayDurationInput;
};

export type PartnerMutationBulkEditDatePricingMinimumStayPriceArgs = {
    input: BulkEditDatePricingMinimumStayPriceInput;
};

export type PartnerMutationBulkEditDatePricingNightPriceArgs = {
    input: BulkEditDatePricingNightPriceInput;
};

export type PartnerMutationBulkEditDatePricingWeekPriceArgs = {
    input: BulkEditDatePricingWeekPriceInput;
};

export type PartnerMutationBulkUpsertDatePricingArgs = {
    input: BulkUpsertDatePricingInput;
};

export type PartnerMutationCreateActivityTimeslotArgs = {
    input: CreateActivityTimeslotInput;
};

export type PartnerMutationCreateAllotmentLockoutArgs = {
    input: CreateAllotmentLockoutInput;
};

export type PartnerMutationCreateDatePricingModifierArgs = {
    input: CreateDatePricingModifierInput;
};

export type PartnerMutationCreateRentalUnitSurchargeArgs = {
    input: CreateRentalUnitSurchargeInput;
};

export type PartnerMutationCreateVisitorRegistrationArgs = {
    input: CreateVisitorRegistrationInput;
};

export type PartnerMutationDeleteActivityRateGroupArgs = {
    input: DeleteActivityRateGroupInput;
};

export type PartnerMutationDeleteActivityTimeslotArgs = {
    input: DeleteActivityTimeslotInput;
};

export type PartnerMutationDeleteAllotmentLockoutArgs = {
    input: DeleteAllotmentLockoutInput;
};

export type PartnerMutationDeleteCompanyRateGroupArgs = {
    input: DeleteCompanyRateGroupInput;
};

export type PartnerMutationDeleteDatePricingModifierArgs = {
    input: DeleteDatePricingModifierInput;
};

export type PartnerMutationDeleteRentalUnitSurchargeArgs = {
    input: DeleteRentalUnitSurchargeInput;
};

export type PartnerMutationDeleteVisitorRegistrationArgs = {
    input: DeleteVisitorRegistrationInput;
};

export type PartnerMutationEditAccommodationDescriptionArgs = {
    input: EditAccommodationDescriptionInput;
};

export type PartnerMutationEditAccommodationImagesArgs = {
    input: EditAccommodationImagesInput;
};

export type PartnerMutationEditAccommodationInfoArgs = {
    input: EditAccommodationInfoInput;
};

export type PartnerMutationEditActivityDescriptionArgs = {
    input: EditActivityDescriptionInput;
};

export type PartnerMutationEditActivityImagesArgs = {
    input: EditActivityImagesInput;
};

export type PartnerMutationEditActivityInfoArgs = {
    input: EditActivityInfoInput;
};

export type PartnerMutationEditActivityLocationArgs = {
    input: EditActivityLocationInput;
};

export type PartnerMutationEditActivityTimeslotArgs = {
    input: EditActivityTimeslotInput;
};

export type PartnerMutationEditAllotmentArgs = {
    input: EditAllotmentInput;
};

export type PartnerMutationEditAllotmentLockoutArgs = {
    input: EditAllotmentLockoutInput;
};

export type PartnerMutationEditAttributesArgs = {
    input: EditAttributesInput;
};

export type PartnerMutationEditCapacityAndSpaceArgs = {
    input: EditCapacityAndSpaceInput;
};

export type PartnerMutationEditCompanyDescriptionArgs = {
    input: EditCompanyDescriptionInput;
};

export type PartnerMutationEditCompanyImagesArgs = {
    input: EditCompanyImagesInput;
};

export type PartnerMutationEditCompanyInfoArgs = {
    input: EditCompanyInfoInput;
};

export type PartnerMutationEditCompanyLocationArgs = {
    input: EditCompanyLocationInput;
};

export type PartnerMutationEditCompanyNormalOperatingHoursArgs = {
    input: EditCompanyNormalOperatingHoursInput;
};

export type PartnerMutationEditCompanySpecialOperatingHoursArgs = {
    input: EditCompanySpecialOperatingHoursInput;
};

export type PartnerMutationEditDatePricingArrivalAllowedArgs = {
    input: EditDatePricingArrivalAllowedInput;
};

export type PartnerMutationEditDatePricingBaseStayPriceArgs = {
    input: EditDatePricingBaseStayPriceInput;
};

export type PartnerMutationEditDatePricingDepartureAllowedArgs = {
    input: EditDatePricingDepartureAllowedInput;
};

export type PartnerMutationEditDatePricingDisplayOptionsArgs = {
    input: EditDatePricingDisplayOptionsInput;
};

export type PartnerMutationEditDatePricingExtraPersonPriceArgs = {
    input: EditDatePricingExtraPersonPriceInput;
};

export type PartnerMutationEditDatePricingMinimumStayDurationArgs = {
    input: EditDatePricingMinimumStayDurationInput;
};

export type PartnerMutationEditDatePricingMinimumStayPriceArgs = {
    input: EditDatePricingMinimumStayPriceInput;
};

export type PartnerMutationEditDatePricingModifierArgs = {
    input: EditDatePricingModifierInput;
};

export type PartnerMutationEditDatePricingNightPriceArgs = {
    input: EditDatePricingNightPriceInput;
};

export type PartnerMutationEditDatePricingWeekPriceArgs = {
    input: EditDatePricingWeekPriceInput;
};

export type PartnerMutationEditDiscountsArgs = {
    input: EditDiscountForUnitInput;
};

export type PartnerMutationEditIcalImportFeedsArgs = {
    input: EditIcalImportFeedsInput;
};

export type PartnerMutationEditKeyHolderArgs = {
    input: EditKeyHolderInput;
};

export type PartnerMutationEditLocationAndDistanceArgs = {
    input: EditLocationAndDistanceInput;
};

export type PartnerMutationEditOwnerInformationArgs = {
    input: EditOwnerInformationInput;
};

export type PartnerMutationEditPartnerConnectorSettingsArgs = {
    input: EditPartnerConnectorSettingsInput;
};

export type PartnerMutationEditRentalUnitAndAccommodationInfoArgs = {
    input: EditRentalUnitAndAccommodationInfoInput;
};

export type PartnerMutationEditRentalUnitConnectorSettingsArgs = {
    input: EditRentalUnitConnectorSettingsInput;
};

export type PartnerMutationEditRentalUnitDescriptionsArgs = {
    input: EditRentalUnitDescriptionsInput;
};

export type PartnerMutationEditRentalUnitImagesArgs = {
    input: EditRentalUnitImagesInput;
};

export type PartnerMutationEditRentalUnitInfoArgs = {
    input: EditRentalUnitInfoInput;
};

export type PartnerMutationEditRentalUnitSurchargeArgs = {
    input: EditRentalUnitSurchargeInput;
};

export type PartnerMutationEditReviewReplyArgs = {
    input: EditReviewReplyInput;
};

export type PartnerMutationEditSpecialParticipationDateBlockArgs = {
    input: EditSpecialParticipationDateBlockInput;
};

export type PartnerMutationEditSpecialParticipationOptInArgs = {
    input: EditSpecialParticipationInput;
};

export type PartnerMutationInitTicketScanSessionArgs = {
    input: InitTicketScanSessionInput;
};

export type PartnerMutationOverwriteRentalUnitSurchargesArgs = {
    input: OverwriteRentalUnitSurchargesInput;
};

export type PartnerMutationRegisterNewPartnerArgs = {
    input: RegisterNewPartnerInput;
};

export type PartnerMutationResetPasswordArgs = {
    input: ResetPasswordInput;
};

export type PartnerMutationSendPasswordResetMailArgs = {
    input: SendPasswordResetMailInput;
};

export type PartnerMutationSetActivityTimeslotsArgs = {
    input: SetActivityTimeslotsInput;
};

export type PartnerMutationUploadTripPricingsArgs = {
    input: UploadTripPricingsInput;
};

export type PartnerMutationUpsertActivityRateGroupArgs = {
    input: UpsertActivityRateGroupInput;
};

export type PartnerMutationUpsertCompanyRateGroupArgs = {
    input: UpsertCompanyRateGroupInput;
};

export type PartnerMutationValidatePartnerConnectorSettingsArgs = {
    input: ValidatePartnerConnectorSettingsInput;
};

export enum PartnerPaymentBatchTypeEnum {
    Credit = 'CREDIT',
    Debit = 'DEBIT',
    Manual = 'MANUAL',
}

export type PartnerQuery = {
    __typename?: 'PartnerQuery';
    accommodation?: Maybe<Accommodation>;
    activity?: Maybe<Activity>;
    attributeCategories: Array<AttributeCategory>;
    booking?: Maybe<Booking>;
    company?: Maybe<Company>;
    icalImportFeed?: Maybe<IcalImportFeed>;
    partner?: Maybe<Partner>;
    rentalUnit?: Maybe<RentalUnit>;
    review?: Maybe<Review>;
    ticket?: Maybe<Ticket>;
    timeslot?: Maybe<Timeslot>;
    viewer?: Maybe<User>;
};

export type PartnerQueryAccommodationArgs = {
    slug: Scalars['String']['input'];
};

export type PartnerQueryActivityArgs = {
    id?: InputMaybe<Scalars['ID']['input']>;
    slug?: InputMaybe<Scalars['String']['input']>;
};

export type PartnerQueryAttributeCategoriesArgs = {
    subtype?: InputMaybe<RentalUnitTypeEnum>;
    type?: InputMaybe<AttributeTypeEnum>;
};

export type PartnerQueryBookingArgs = {
    id: Scalars['ID']['input'];
};

export type PartnerQueryCompanyArgs = {
    id?: InputMaybe<Scalars['ID']['input']>;
    slug?: InputMaybe<Scalars['String']['input']>;
};

export type PartnerQueryIcalImportFeedArgs = {
    id?: InputMaybe<Scalars['ID']['input']>;
};

export type PartnerQueryPartnerArgs = {
    id: Scalars['ID']['input'];
};

export type PartnerQueryRentalUnitArgs = {
    id?: InputMaybe<Scalars['ID']['input']>;
    slug?: InputMaybe<Scalars['String']['input']>;
};

export type PartnerQueryReviewArgs = {
    id: Scalars['ID']['input'];
};

export type PartnerQueryTicketArgs = {
    id: Scalars['ID']['input'];
};

export type PartnerQueryTimeslotArgs = {
    id: Scalars['ID']['input'];
};

/** The registration of a partner. */
export type PartnerRegistration = {
    __typename?: 'PartnerRegistration';
    /** Accommodation registrations that belong to the partner registration. */
    accommodationRegistrations: Array<AccommodationRegistration>;
    /** Name of contact person (required), phone numbers (optional) and email addresses (optional) */
    contact: ContactInfo;
    contactSalutation?: Maybe<Scalars['String']['output']>;
    /** Unique identifier of the partner registration. */
    id: Scalars['ID']['output'];
    /** Name of the partner registration. */
    name: Scalars['String']['output'];
};

export type PartnerTicketInvoice = PartnerBalanceItem & {
    __typename?: 'PartnerTicketInvoice';
    amount: Scalars['Float']['output'];
    createdAt: Scalars['DateTime']['output'];
    downloadPdfUrl: Scalars['String']['output'];
    downloadXlsxUrl: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    invoiceNumber: Scalars['String']['output'];
    periodEndDate: Scalars['Date']['output'];
    periodStartDate: Scalars['Date']['output'];
};

export enum PaymentTypeEnum {
    Configurable = 'CONFIGURABLE',
    Partner = 'PARTNER',
    Travelbase = 'TRAVELBASE',
}

export type Place = {
    __typename?: 'Place';
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
};

export enum PriceLineCategoryEnum {
    Accommodation = 'ACCOMMODATION',
    AdminCosts = 'ADMIN_COSTS',
    Charges = 'CHARGES',
    Insurance = 'INSURANCE',
    NotInTotal = 'NOT_IN_TOTAL',
    Special = 'SPECIAL',
    Surcharges = 'SURCHARGES',
}

/** Statistics for QR scans for a specific rate */
export type QrScanStats = {
    __typename?: 'QrScanStats';
    /** The name of the rate (at time of sale) these statistics apply to. */
    rateName: Scalars['String']['output'];
    /** The number of QR tickets scanned for this rate. */
    scannedCount: Scalars['Int']['output'];
    /** The number of QR tickets sold for this rate. */
    soldCount: Scalars['Int']['output'];
};

/** A rate. */
export type Rate = {
    __typename?: 'Rate';
    /** Unique identifier of the rate. */
    id: Scalars['ID']['output'];
    name?: Maybe<Scalars['String']['output']>;
    position: Scalars['Int']['output'];
    price: Scalars['Float']['output'];
};

/** A rate. */
export type RateNameArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
};

export type RateGroup = {
    canBuyTickets: Scalars['Boolean']['output'];
    /** Unique identifier of the rate group. */
    id: Scalars['ID']['output'];
    /** Name of the group for internal use. */
    name?: Maybe<Scalars['String']['output']>;
    rates: Array<Rate>;
};

export type RateInput = {
    /** If provided, will update the existing Rate object with the specified ID. Otherwise, a new object will be created. */
    id?: InputMaybe<Scalars['ID']['input']>;
    position: Scalars['Int']['input'];
    price: Scalars['Float']['input'];
    translations: Array<RateTranslationInput>;
};

export type RateTranslationInput = {
    locale: Scalars['String']['input'];
    name: Scalars['String']['input'];
};

export type RegisterNewPartnerInput = {
    accommodations: Array<AccommodationRegistrationInput>;
    bic?: InputMaybe<Scalars['String']['input']>;
    comment?: InputMaybe<Scalars['String']['input']>;
    contact: ContactInfoInput;
    iban?: InputMaybe<Scalars['String']['input']>;
    ibanName?: InputMaybe<Scalars['String']['input']>;
    invoiceAddress?: InputMaybe<AddressInput>;
    invoiceEmails?: InputMaybe<Array<Scalars['String']['input']>>;
    name: Scalars['String']['input'];
    pmsName?: InputMaybe<Scalars['String']['input']>;
    vatNumber?: InputMaybe<Scalars['String']['input']>;
};

export type RegisterNewPartnerPayload = {
    __typename?: 'RegisterNewPartnerPayload';
    /** The partner registration object that was created, if successful */
    partnerRegistration?: Maybe<PartnerRegistration>;
};

/** A rental unit represents the private guest space in an accommodation. In many cases this is the same as the accommodation. For larger accommodations like hotels and apartment buildings, this represents the room type or apartment in the building. */
export type RentalUnit = {
    __typename?: 'RentalUnit';
    accommodation: Accommodation;
    allotmentLockouts: Array<AllotmentLockout>;
    allotments: Array<Allotment>;
    amountBathrooms?: Maybe<Scalars['Int']['output']>;
    amountBedrooms?: Maybe<Scalars['Int']['output']>;
    amountBeds?: Maybe<Scalars['Int']['output']>;
    amountToilets?: Maybe<Scalars['Int']['output']>;
    attributes: Array<Attribute>;
    availableSurcharges: Array<Surcharge>;
    /** Limited to a maximum of 500 results. If you could conceivably need more, you should probably use the root query field "bookings". */
    bookings: Array<Booking>;
    /** Assigned code of this rental unit as used in communications. */
    code: Scalars['String']['output'];
    connectorConfig?: Maybe<ConnectorRentalUnitConfig>;
    datePricingEndDate?: Maybe<Scalars['Date']['output']>;
    datePricingModifiers: Array<DatePricingModifier>;
    datePricingStartDate?: Maybe<Scalars['Date']['output']>;
    datePricings: Array<DatePricing>;
    descriptionExtras?: Maybe<Scalars['String']['output']>;
    descriptionExtrasPending?: Maybe<Scalars['String']['output']>;
    descriptionLayout?: Maybe<Scalars['String']['output']>;
    descriptionLayoutPending?: Maybe<Scalars['String']['output']>;
    descriptionParticulars?: Maybe<Scalars['String']['output']>;
    descriptionParticularsPending?: Maybe<Scalars['String']['output']>;
    earlyBookDiscount6Months?: Maybe<Scalars['Float']['output']>;
    earlyBookDiscount12Months?: Maybe<Scalars['Float']['output']>;
    earlyBookDiscountEnabled: Scalars['Boolean']['output'];
    /** Whether the rental unit is enabled. */
    enabled: Scalars['Boolean']['output'];
    flexCancellationDays?: Maybe<Scalars['Int']['output']>;
    flexibleArrival: Scalars['Boolean']['output'];
    flexibleDeparture: Scalars['Boolean']['output'];
    fullCircleImageUrl?: Maybe<Scalars['String']['output']>;
    handleSecurityDepositPayment: Scalars['Boolean']['output'];
    hideForPartner: Scalars['Boolean']['output'];
    hideReviews: Scalars['Boolean']['output'];
    /** Feed url for the Ical export feed */
    icalFeedUrl?: Maybe<Scalars['String']['output']>;
    icalImportFeeds: Array<IcalImportFeed>;
    /** Token for the Ical export feed */
    icalToken?: Maybe<Scalars['String']['output']>;
    /** Unique identifier of the rental unit. */
    id: Scalars['ID']['output'];
    images: Array<AccommodationImage>;
    /** Amount of persons included in the rental unit's prices. */
    includedOccupancy?: Maybe<Scalars['Int']['output']>;
    keyHolderExplanation?: Maybe<Scalars['String']['output']>;
    keyHolderExplanationPending?: Maybe<Scalars['String']['output']>;
    lastDatePricingDate?: Maybe<Scalars['Date']['output']>;
    lastminuteDiscount1Week?: Maybe<Scalars['Float']['output']>;
    lastminuteDiscount2Week?: Maybe<Scalars['Float']['output']>;
    lastminuteDiscount3Week?: Maybe<Scalars['Float']['output']>;
    lastminuteDiscount4Week?: Maybe<Scalars['Float']['output']>;
    lastminuteDiscountEnabled: Scalars['Boolean']['output'];
    listImage?: Maybe<AccommodationListImage>;
    livingArea?: Maybe<Scalars['Int']['output']>;
    mainImage?: Maybe<AccommodationMainImage>;
    /** Maximum available allotment of this rental unit. */
    maxAllotment: Scalars['Int']['output'];
    maxExtraBabyOccupancy?: Maybe<Scalars['Int']['output']>;
    maxOccupancy?: Maybe<Scalars['Int']['output']>;
    maxVehicleSize?: Maybe<Scalars['Int']['output']>;
    minOccupancy?: Maybe<Scalars['Int']['output']>;
    /** Name of the rental unit. */
    name: Scalars['String']['output'];
    outdoorArea?: Maybe<Scalars['Int']['output']>;
    petsAllowed?: Maybe<Scalars['Int']['output']>;
    public: Scalars['Boolean']['output'];
    releaseDays?: Maybe<Scalars['Int']['output']>;
    rentalUnitContracts: Array<RentalUnitContract>;
    securityDeposit?: Maybe<Scalars['Float']['output']>;
    showAllotmentLockouts: Scalars['Boolean']['output'];
    /** @deprecated Added support for night pricing options to the Management API: relevant info might be hidden */
    showArrivalAllowedRow: Scalars['Boolean']['output'];
    showBaseStayPriceRow: Scalars['Boolean']['output'];
    /** @deprecated Added support for night pricing options to the Management API: relevant info might be hidden */
    showDepartureAllowedRow: Scalars['Boolean']['output'];
    showExtraPersonPriceRow: Scalars['Boolean']['output'];
    /** @deprecated Added support for night pricing options to the Management API: relevant info might be hidden */
    showMinimumStayDurationRow: Scalars['Boolean']['output'];
    showMinimumStayPriceRow: Scalars['Boolean']['output'];
    /** @deprecated Added support for night pricing options to the Management API: relevant info might be hidden */
    showNightPriceRow: Scalars['Boolean']['output'];
    showWeekPriceRow: Scalars['Boolean']['output'];
    slug: Scalars['String']['output'];
    specialParticipations: Array<SpecialParticipation>;
    surcharges: Array<RentalUnitSurcharge>;
    tagline: Scalars['String']['output'];
    taglinePending?: Maybe<Scalars['String']['output']>;
    thumbnailUrl?: Maybe<Scalars['String']['output']>;
    tripPricingExportUrl?: Maybe<Scalars['String']['output']>;
    tripPricings: Array<TripPricing>;
    trips: Array<Trip>;
    /** Type of the rental unit. */
    type: RentalUnitTypeEnum;
    videoUrl?: Maybe<Scalars['String']['output']>;
};

/** A rental unit represents the private guest space in an accommodation. In many cases this is the same as the accommodation. For larger accommodations like hotels and apartment buildings, this represents the room type or apartment in the building. */
export type RentalUnitAllotmentLockoutsArgs = {
    endDate: Scalars['Date']['input'];
    startDate: Scalars['Date']['input'];
};

/** A rental unit represents the private guest space in an accommodation. In many cases this is the same as the accommodation. For larger accommodations like hotels and apartment buildings, this represents the room type or apartment in the building. */
export type RentalUnitAllotmentsArgs = {
    endDate: Scalars['Date']['input'];
    startDate: Scalars['Date']['input'];
};

/** A rental unit represents the private guest space in an accommodation. In many cases this is the same as the accommodation. For larger accommodations like hotels and apartment buildings, this represents the room type or apartment in the building. */
export type RentalUnitBookingsArgs = {
    endDate: Scalars['Date']['input'];
    startDate: Scalars['Date']['input'];
};

/** A rental unit represents the private guest space in an accommodation. In many cases this is the same as the accommodation. For larger accommodations like hotels and apartment buildings, this represents the room type or apartment in the building. */
export type RentalUnitDatePricingModifiersArgs = {
    endDate: Scalars['Date']['input'];
    startDate: Scalars['Date']['input'];
};

/** A rental unit represents the private guest space in an accommodation. In many cases this is the same as the accommodation. For larger accommodations like hotels and apartment buildings, this represents the room type or apartment in the building. */
export type RentalUnitDatePricingsArgs = {
    endDate: Scalars['Date']['input'];
    startDate: Scalars['Date']['input'];
};

/** A rental unit represents the private guest space in an accommodation. In many cases this is the same as the accommodation. For larger accommodations like hotels and apartment buildings, this represents the room type or apartment in the building. */
export type RentalUnitDescriptionExtrasArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
};

/** A rental unit represents the private guest space in an accommodation. In many cases this is the same as the accommodation. For larger accommodations like hotels and apartment buildings, this represents the room type or apartment in the building. */
export type RentalUnitDescriptionLayoutArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
};

/** A rental unit represents the private guest space in an accommodation. In many cases this is the same as the accommodation. For larger accommodations like hotels and apartment buildings, this represents the room type or apartment in the building. */
export type RentalUnitDescriptionParticularsArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
};

/** A rental unit represents the private guest space in an accommodation. In many cases this is the same as the accommodation. For larger accommodations like hotels and apartment buildings, this represents the room type or apartment in the building. */
export type RentalUnitKeyHolderExplanationArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
};

/** A rental unit represents the private guest space in an accommodation. In many cases this is the same as the accommodation. For larger accommodations like hotels and apartment buildings, this represents the room type or apartment in the building. */
export type RentalUnitTaglineArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
};

/** A rental unit represents the private guest space in an accommodation. In many cases this is the same as the accommodation. For larger accommodations like hotels and apartment buildings, this represents the room type or apartment in the building. */
export type RentalUnitTripPricingsArgs = {
    endDate: Scalars['Date']['input'];
    startDate: Scalars['Date']['input'];
};

/** A rental unit represents the private guest space in an accommodation. In many cases this is the same as the accommodation. For larger accommodations like hotels and apartment buildings, this represents the room type or apartment in the building. */
export type RentalUnitTripsArgs = {
    date: Scalars['Date']['input'];
    persons?: InputMaybe<Scalars['Int']['input']>;
};

export type RentalUnitContract = {
    __typename?: 'RentalUnitContract';
    approvalDate?: Maybe<Scalars['DateTime']['output']>;
    contract: Contract;
    endDate?: Maybe<Scalars['Date']['output']>;
    id: Scalars['ID']['output'];
    rentalUnit: RentalUnit;
    startDate: Scalars['Date']['output'];
};

/** Registration object of a rental unit. A rental unit presents the private guest space in an accommodation. In many cases this is the same as the accommodation. For larger accommodations like hotels and apartment buildings, this represents the room type or apartment in the building. */
export type RentalUnitRegistration = {
    __typename?: 'RentalUnitRegistration';
    accommodationRegistration: AccommodationRegistration;
    /** Assigned code of this rental unit registration as used in communications. */
    code: Scalars['String']['output'];
    /** Unique identifier of the rental unit registration. */
    id: Scalars['ID']['output'];
    /** Maximum available allotment of this rental unit registration. */
    maxAllotment: Scalars['Int']['output'];
    /** Maximum vehicle size for this rental unit registration, if applicable. */
    maxVehicleSize?: Maybe<Scalars['Int']['output']>;
    /** Name of the rental unit registration. */
    name: Scalars['String']['output'];
    slug: Scalars['String']['output'];
    type?: Maybe<RentalUnitTypeEnum>;
};

export type RentalUnitRegistrationInput = {
    maxAllotment: Scalars['Int']['input'];
    maxVehicleSize?: InputMaybe<Scalars['Int']['input']>;
    name: Scalars['String']['input'];
    type: RentalUnitTypeEnum;
};

export type RentalUnitSurcharge = {
    __typename?: 'RentalUnitSurcharge';
    calculation: SurchargeCalculationEnum;
    connectorConfig?: Maybe<ConnectorSurchargeConfig>;
    endDate?: Maybe<Scalars['Date']['output']>;
    handlePayment: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    maxAmount: Scalars['Int']['output'];
    minAmount: Scalars['Int']['output'];
    rentalUnit: RentalUnit;
    startDate: Scalars['Date']['output'];
    surcharge: Surcharge;
    unitPrice: Scalars['Float']['output'];
};

export enum RentalUnitTypeEnum {
    Bed = 'BED',
    Boat = 'BOAT',
    CamperSpot = 'CAMPER_SPOT',
    CampingPitch = 'CAMPING_PITCH',
    Home = 'HOME',
    Room = 'ROOM',
    Tent = 'TENT',
}

export type ResetPasswordInput = {
    newPassword: Scalars['String']['input'];
    token: Scalars['String']['input'];
};

export type ResetPasswordPayload = {
    __typename?: 'ResetPasswordPayload';
    email?: Maybe<Scalars['String']['output']>;
};

export type Review = {
    __typename?: 'Review';
    /** @deprecated Customer names have been removed, so all reviews are essentially anonymous now. */
    anonymous: Scalars['Boolean']['output'];
    average: Scalars['Float']['output'];
    city?: Maybe<Scalars['String']['output']>;
    comment?: Maybe<Scalars['String']['output']>;
    createdAt: Scalars['DateTime']['output'];
    displayName?: Maybe<Scalars['String']['output']>;
    enabled: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    language: Scalars['String']['output'];
    ratingHospitality: Scalars['Int']['output'];
    ratingLayout: Scalars['Int']['output'];
    ratingLocation: Scalars['Int']['output'];
    ratingMaintenance: Scalars['Int']['output'];
    ratingValueForMoney: Scalars['Int']['output'];
    rentalUnit: RentalUnit;
    reply?: Maybe<Scalars['String']['output']>;
    tips?: Maybe<Scalars['String']['output']>;
    title: Scalars['String']['output'];
    travelGroupType?: Maybe<TravelGroupTypeEnum>;
};

export type SendPasswordResetMailInput = {
    email: Scalars['String']['input'];
};

export type SendPasswordResetMailPayload = {
    __typename?: 'SendPasswordResetMailPayload';
    email?: Maybe<Scalars['String']['output']>;
};

export type SetActivityTimeslotsInput = {
    activityId: Scalars['ID']['input'];
    /** End of date range to which this mutation is applied to, inclusive. */
    endDate: Scalars['Date']['input'];
    friday?: InputMaybe<Array<TimeslotByTimeInput>>;
    monday?: InputMaybe<Array<TimeslotByTimeInput>>;
    rateGroupId: Scalars['ID']['input'];
    saturday?: InputMaybe<Array<TimeslotByTimeInput>>;
    /** Start of date range to which this mutation is applied to. */
    startDate: Scalars['Date']['input'];
    sunday?: InputMaybe<Array<TimeslotByTimeInput>>;
    thursday?: InputMaybe<Array<TimeslotByTimeInput>>;
    translations: Array<TimeslotTranslationInput>;
    tuesday?: InputMaybe<Array<TimeslotByTimeInput>>;
    wednesday?: InputMaybe<Array<TimeslotByTimeInput>>;
};

export type SetActivityTimeslotsPayload = {
    __typename?: 'SetActivityTimeslotsPayload';
    activity?: Maybe<Activity>;
};

/** A special represents a special offer that may be selected in a booking. Specials can affect the price of the booking. */
export type Special = {
    __typename?: 'Special';
    arrivalFrom: Scalars['Date']['output'];
    arrivalUntil: Scalars['Date']['output'];
    availableFrom: Scalars['Date']['output'];
    availableUntil: Scalars['Date']['output'];
    description?: Maybe<Scalars['String']['output']>;
    /** Unique identifier of the special. */
    id: Scalars['ID']['output'];
    /** Name of the special. */
    name?: Maybe<Scalars['String']['output']>;
    optInRequired: Scalars['Boolean']['output'];
    partnerConditions?: Maybe<Scalars['String']['output']>;
};

/** A special represents a special offer that may be selected in a booking. Specials can affect the price of the booking. */
export type SpecialDescriptionArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
};

/** A special represents a special offer that may be selected in a booking. Specials can affect the price of the booking. */
export type SpecialNameArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
};

export type SpecialOperatingHoursInput = {
    /** End of special operating hours period. */
    endDate: Scalars['Date']['input'];
    friday?: InputMaybe<Array<TimeOpenClosedInput>>;
    monday?: InputMaybe<Array<TimeOpenClosedInput>>;
    saturday?: InputMaybe<Array<TimeOpenClosedInput>>;
    /** Start of special operating hours period. */
    startDate: Scalars['Date']['input'];
    sunday?: InputMaybe<Array<TimeOpenClosedInput>>;
    thursday?: InputMaybe<Array<TimeOpenClosedInput>>;
    tuesday?: InputMaybe<Array<TimeOpenClosedInput>>;
    wednesday?: InputMaybe<Array<TimeOpenClosedInput>>;
};

export type SpecialParticipation = {
    __typename?: 'SpecialParticipation';
    blockedArrivalDates: Array<Scalars['Date']['output']>;
    id: Scalars['ID']['output'];
    optInAcceptedAt?: Maybe<Scalars['DateTime']['output']>;
    optInRejectedAt?: Maybe<Scalars['DateTime']['output']>;
    rentalUnit: RentalUnit;
    special: Special;
    trips: Array<Trip>;
};

export type SpecialParticipationTripsArgs = {
    endDate: Scalars['Date']['input'];
    startDate: Scalars['Date']['input'];
};

/** Surcharges are (mandatory or optional) costs included in a booking which are configured by the partner. */
export type Surcharge = {
    __typename?: 'Surcharge';
    allowedCalculations: Array<SurchargeCalculationEnum>;
    /** @deprecated No longer a setting per Surcharge, but is now configured on Contract level. */
    canBePaidThroughTor: Scalars['Boolean']['output'];
    description?: Maybe<Scalars['String']['output']>;
    /** Unique identifier of the surcharge. */
    id: Scalars['ID']['output'];
    /** Name of the surcharge. */
    name?: Maybe<Scalars['String']['output']>;
};

/** Surcharges are (mandatory or optional) costs included in a booking which are configured by the partner. */
export type SurchargeDescriptionArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
};

/** Surcharges are (mandatory or optional) costs included in a booking which are configured by the partner. */
export type SurchargeNameArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
};

export enum SurchargeCalculationEnum {
    PerPerson = 'PER_PERSON',
    PerPersonPerNight = 'PER_PERSON_PER_NIGHT',
    PerPet = 'PER_PET',
    PerPetPerNight = 'PER_PET_PER_NIGHT',
    PerPiece = 'PER_PIECE',
    PerPiecePerNight = 'PER_PIECE_PER_NIGHT',
    Subsequent = 'SUBSEQUENT',
}

export enum TargetAudienceEnum {
    Adults = 'ADULTS',
    AllAges = 'ALL_AGES',
    Children = 'CHILDREN',
    Pets = 'PETS',
    WheelchairAccessible = 'WHEELCHAIR_ACCESSIBLE',
}

/** A ticket. */
export type Ticket = {
    __typename?: 'Ticket';
    /** Date and time the ticket was created. */
    createdAt: Scalars['DateTime']['output'];
    /** The customer who bought this ticket. */
    customer?: Maybe<Customer>;
    /** @deprecated Duplicate, use timeslot.endDateTime instead. */
    endDateTime: Scalars['DateTime']['output'];
    /** Unique identifier of the ticket. */
    id: Scalars['ID']['output'];
    /** Number of the ticket as integrated in de bar code on the ticket PDF */
    number: Scalars['String']['output'];
    priceLines: Array<TicketPriceLine>;
    /** Whether or not QR scanning through the Travelbase scan app is enabled for this Ticket. */
    qrScansEnabled: Scalars['Boolean']['output'];
    rateLines: Array<TicketRateLine>;
    requiredAllotment: Scalars['Int']['output'];
    /** @deprecated Duplicate, use timeslot.startDateTime instead. */
    startDateTime: Scalars['DateTime']['output'];
    /** Status of this ticket. */
    status: TicketStatusEnum;
    /** The timeslot this ticket is valid for. */
    timeslot: Timeslot;
};

/** A connection to a list of items. */
export type TicketConnection = {
    __typename?: 'TicketConnection';
    /** Information to aid in pagination. */
    edges?: Maybe<Array<Maybe<TicketEdge>>>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** Total tickets for this query. */
    totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type TicketEdge = {
    __typename?: 'TicketEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String']['output'];
    /** The item at the end of the edge. */
    node?: Maybe<Ticket>;
};

export type TicketPriceLine = {
    __typename?: 'TicketPriceLine';
    label: Scalars['String']['output'];
    quantity: Scalars['Int']['output'];
    totalPrice: Scalars['Float']['output'];
    unitPrice?: Maybe<Scalars['Float']['output']>;
};

/** A single QR code provided for a specific TicketRateLine. */
export type TicketQrCode = {
    __typename?: 'TicketQrCode';
    /** The code embedded in the QR. */
    code: Scalars['String']['output'];
    /** The number of this QR code within the TicketRateLine. */
    number: Scalars['Int']['output'];
    /** The date and time this QR was scanned, or NULL if it has not been scanned (yet). */
    scannedAt?: Maybe<Scalars['DateTime']['output']>;
    /** The label for the authenticated user that scanned this QR, or NULL if it has not been scanned (yet). */
    scannedBy?: Maybe<Scalars['String']['output']>;
};

/** A ticket rate line. */
export type TicketRateLine = {
    __typename?: 'TicketRateLine';
    amount: Scalars['Int']['output'];
    /** The QR codes provided for this TicketRateLine. Will be NULL if QR scanning is not enabled for this Ticket. */
    qrCodes?: Maybe<Array<TicketQrCode>>;
    rate?: Maybe<Rate>;
    rateName?: Maybe<Scalars['String']['output']>;
    unitPrice: Scalars['Float']['output'];
};

/** A ticket rate line. */
export type TicketRateLineRateNameArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
};

export enum TicketStatusEnum {
    Accepted = 'ACCEPTED',
    Init = 'INIT',
    Waived = 'WAIVED',
}

/** A time range of operating hours. */
export type TimeOpenClosed = {
    __typename?: 'TimeOpenClosed';
    /** Closing time. */
    timeClosed: Scalars['Time']['output'];
    /** Opening time. */
    timeOpen: Scalars['Time']['output'];
};

export type TimeOpenClosedInput = {
    timeClosed: Scalars['Time']['input'];
    timeOpen: Scalars['Time']['input'];
};

/** A timeslot. */
export type Timeslot = {
    __typename?: 'Timeslot';
    activity: Activity;
    /** Number of tickets available for this timeslot. If null, an unlimited number is available. */
    allotment?: Maybe<Scalars['Int']['output']>;
    endDateTime: Scalars['DateTime']['output'];
    externalId?: Maybe<Scalars['String']['output']>;
    /** Unique identifier of the timeslot. */
    id: Scalars['ID']['output'];
    label?: Maybe<Scalars['String']['output']>;
    /** Statistics on QR codes scanned for this Timeslot. Will be NULL if QR scanning is not enabled for this Timeslot. */
    qrScanStats?: Maybe<Array<QrScanStats>>;
    rateGroup: RateGroup;
    startDateTime: Scalars['DateTime']['output'];
};

/** A timeslot. */
export type TimeslotLabelArgs = {
    locale?: InputMaybe<Scalars['String']['input']>;
};

export type TimeslotByTimeInput = {
    allotment?: InputMaybe<Scalars['Int']['input']>;
    endTime: Scalars['Time']['input'];
    externalId?: InputMaybe<Scalars['String']['input']>;
    startTime: Scalars['Time']['input'];
};

export type TimeslotInput = {
    /** The amount of available tickets for this Timeslot. Leaving this blank/NULL means unlimited tickets are available. */
    allotment?: InputMaybe<Scalars['Int']['input']>;
    /** The date and time at which this Timeslot ends. */
    endDateTime: Scalars['DateTime']['input'];
    /** A reference value used to uniquely identify this Timeslot and match it to an object in an external system. */
    externalId?: InputMaybe<Scalars['String']['input']>;
    /** ID of the RateGroup which should apply to tickets sold for this Timeslot. */
    rateGroupId: Scalars['ID']['input'];
    /** The date and time at which this Timeslot starts. */
    startDateTime: Scalars['DateTime']['input'];
    /** CURRENTLY UNUSED - A description specific to this individual Timeslot. */
    translations?: InputMaybe<Array<TimeslotTranslationInput>>;
};

export type TimeslotTranslationInput = {
    label?: InputMaybe<Scalars['String']['input']>;
    locale: Scalars['String']['input'];
};

export enum TravelGroupTypeEnum {
    Alone = 'ALONE',
    BusinessTrip = 'BUSINESS_TRIP',
    FamilyWithOlderChildren = 'FAMILY_WITH_OLDER_CHILDREN',
    FamilyWithYoungChildren = 'FAMILY_WITH_YOUNG_CHILDREN',
    GroupOfFriends = 'GROUP_OF_FRIENDS',
    GroupTrip = 'GROUP_TRIP',
    OlderCouple = 'OLDER_COUPLE',
    YoungCouple = 'YOUNG_COUPLE',
}

export type Trip = {
    __typename?: 'Trip';
    date: Scalars['Date']['output'];
    duration: Scalars['Int']['output'];
    /** Whether trip was created with flex arrival and/or departure */
    flexType: FlexTypeEnum;
    rentalSumPartner: Scalars['Float']['output'];
    special?: Maybe<Special>;
    type: TripTypeEnum;
};

export type TripPricing = {
    __typename?: 'TripPricing';
    date: Scalars['Date']['output'];
    duration: Scalars['Int']['output'];
    extraPersonPrice: Scalars['Float']['output'];
    id: Scalars['ID']['output'];
    minimumStayPrice: Scalars['Float']['output'];
    price: Scalars['Float']['output'];
    rentalUnit: RentalUnit;
};

export enum TripTypeEnum {
    EarlyBooking = 'EARLY_BOOKING',
    LastMinute = 'LAST_MINUTE',
    Regular = 'REGULAR',
    Special = 'SPECIAL',
}

export type UploadTripPricingsInput = {
    file: Scalars['FileUpload']['input'];
    rentalUnitId: Scalars['ID']['input'];
};

export type UploadTripPricingsPayload = {
    __typename?: 'UploadTripPricingsPayload';
    rentalUnit?: Maybe<RentalUnit>;
};

export type UpsertActivityRateGroupInput = {
    activityId: Scalars['ID']['input'];
    canBuyTickets: Scalars['Boolean']['input'];
    name?: InputMaybe<Scalars['String']['input']>;
    qrScansEnabled?: InputMaybe<Scalars['Boolean']['input']>;
    rateGroupId?: InputMaybe<Scalars['ID']['input']>;
    rates: Array<RateInput>;
    releaseHours?: InputMaybe<Scalars['Int']['input']>;
};

export type UpsertActivityRateGroupPayload = {
    __typename?: 'UpsertActivityRateGroupPayload';
    activity?: Maybe<Activity>;
};

export type UpsertCompanyRateGroupInput = {
    canBuyTickets: Scalars['Boolean']['input'];
    companyId: Scalars['ID']['input'];
    endDate?: InputMaybe<Scalars['Date']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    rateGroupId?: InputMaybe<Scalars['ID']['input']>;
    rates: Array<RateInput>;
    startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type UpsertCompanyRateGroupPayload = {
    __typename?: 'UpsertCompanyRateGroupPayload';
    company?: Maybe<Company>;
};

export type User = {
    __typename?: 'User';
    email: Scalars['String']['output'];
    fullName: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    /** @deprecated Ability to impersonate specific PartnerUsers has been removed. */
    impersonatedBy?: Maybe<User>;
    isBackofficeUser: Scalars['Boolean']['output'];
    lastLogin?: Maybe<Scalars['String']['output']>;
    partners: Array<Partner>;
};

export type ValidatePartnerConnectorSettingsInput = {
    connector: ConnectorEnum;
    settings: Scalars['String']['input'];
};

export type ValidatePartnerConnectorSettingsPayload = {
    __typename?: 'ValidatePartnerConnectorSettingsPayload';
    /** Indicates whether a connection could be made with the provided settings. */
    success: Scalars['Boolean']['output'];
};

export type VisitorRegistration = {
    __typename?: 'VisitorRegistration';
    arrivalDate: Scalars['Date']['output'];
    booking?: Maybe<Booking>;
    city: Scalars['String']['output'];
    /** ISO 3166-1 Alpha-2 country code. */
    countryCode: Scalars['String']['output'];
    departureDate: Scalars['Date']['output'];
    firstName: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    identificationType: IdentificationTypeEnum;
    lastName: Scalars['String']['output'];
    lockout?: Maybe<AllotmentLockout>;
    rentalUnit: RentalUnit;
    totalPersons: Scalars['Int']['output'];
};

/** A connection to a list of items. */
export type VisitorRegistrationConnection = {
    __typename?: 'VisitorRegistrationConnection';
    /** Information to aid in pagination. */
    edges?: Maybe<Array<Maybe<VisitorRegistrationEdge>>>;
    exportUrl: Scalars['String']['output'];
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** Total visitor registrations for this query. */
    totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type VisitorRegistrationEdge = {
    __typename?: 'VisitorRegistrationEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String']['output'];
    /** The item at the end of the edge. */
    node?: Maybe<VisitorRegistration>;
};

export type WeekPricingDay = {
    arrivalAllowed: Scalars['Boolean']['input'];
};

export type DeleteVisitorRegistrationInput = {
    visitorRegistrationId: Scalars['ID']['input'];
};

export type DeleteVisitorRegistrationPayload = {
    __typename?: 'deleteVisitorRegistrationPayload';
    id?: Maybe<Scalars['ID']['output']>;
};

export type UnitDetailsFragment = {
    __typename?: 'RentalUnit';
    id: string;
    name: string;
    slug: string;
    code: string;
    thumbnailUrl?: string | null;
    hideForPartner: boolean;
};

export type AccPickerEntryFragment = {
    __typename?: 'Accommodation';
    id: string;
    name: string;
    slug: string;
    rentalUnits: Array<{
        __typename?: 'RentalUnit';
        slug: string;
        id: string;
        name: string;
        code: string;
        thumbnailUrl?: string | null;
        hideForPartner: boolean;
    }>;
};

export type CompanyPickerEntryFragment = {
    __typename?: 'Company';
    id: string;
    name: string;
    slug: string;
    listImage?: { __typename?: 'CompanyListImage'; url: string } | null;
    activities: Array<{
        __typename?: 'Activity';
        id: string;
        name?: string | null;
        slug: string;
        hideForPartner: boolean;
        company: { __typename?: 'Company'; id: string; name: string };
        listImage?: { __typename?: 'ActivityListImage'; url: string } | null;
    }>;
};

export type ActivityPickerEntryFragment = {
    __typename?: 'Activity';
    id: string;
    name?: string | null;
    slug: string;
    hideForPartner: boolean;
    company: { __typename?: 'Company'; id: string; name: string };
    listImage?: { __typename?: 'ActivityListImage'; url: string } | null;
};

export type BulkEditAllotmentsMutationVariables = Exact<{
    input: BulkEditAllotmentsInput;
}>;

export type BulkEditAllotmentsMutation = {
    __typename?: 'PartnerMutation';
    bulkEditAllotments: {
        __typename?: 'BulkEditAllotmentsPayload';
        allotments?: Array<{ __typename?: 'Allotment'; amount: number; id: string; date: any }> | null;
    };
};

export type AllotmentSidebarQueryVariables = Exact<{
    unitSlug: Scalars['String']['input'];
    start: Scalars['Date']['input'];
    end: Scalars['Date']['input'];
}>;

export type AllotmentSidebarQuery = {
    __typename?: 'PartnerQuery';
    rentalUnit?: {
        __typename?: 'RentalUnit';
        id: string;
        type: RentalUnitTypeEnum;
        bookings: Array<{
            __typename?: 'Booking';
            id: string;
            amountAdults: number;
            amountBabies: number;
            amountChildren: number;
            duration: number;
            arrivalDate: any;
            departureDate: any;
            totalPrice: number;
            status: BookingStatusEnum;
            customer?: {
                __typename?: 'Customer';
                id: string;
                firstName?: string | null;
                lastName?: string | null;
            } | null;
        }>;
    } | null;
};

export type EditAllotmentMutationVariables = Exact<{
    input: EditAllotmentInput;
}>;

export type EditAllotmentMutation = {
    __typename?: 'PartnerMutation';
    editAllotment: {
        __typename?: 'EditAllotmentPayload';
        allotment?: { __typename?: 'Allotment'; amount: number; id: string; date: any } | null;
    };
};

export type AllotmentsLockoutFragment = {
    __typename?: 'AllotmentLockout';
    id: string;
    type: AllotmentLockoutTypeEnum;
    comment?: string | null;
    source?: string | null;
    startDate: any;
    endDate: any;
    isDragging: boolean;
    isActive: boolean;
    isHovering: boolean;
};

export type AllotmentsBookingFragment = {
    __typename?: 'Booking';
    id: string;
    totalPrice: number;
    status: BookingStatusEnum;
    isActive: boolean;
    isHovering: boolean;
    startDate: any;
    endDate: any;
    customer?: { __typename?: 'Customer'; id: string; firstName?: string | null; lastName?: string | null } | null;
};

type BalanceItem_PartnerBalanceCorrection_Fragment = {
    __typename?: 'PartnerBalanceCorrection';
    description: string;
    id: string;
    amount: number;
    createdAt: any;
};

type BalanceItem_PartnerBalancePayment_Fragment = {
    __typename?: 'PartnerBalancePayment';
    batchType?: PartnerPaymentBatchTypeEnum | null;
    id: string;
    amount: number;
    createdAt: any;
};

type BalanceItem_PartnerBookingInvoice_Fragment = {
    __typename?: 'PartnerBookingInvoice';
    invoiceNumber: string;
    downloadPdfUrl: string;
    downloadXlsxUrl: string;
    periodStartDate: any;
    periodEndDate: any;
    id: string;
    amount: number;
    createdAt: any;
};

type BalanceItem_PartnerTicketInvoice_Fragment = {
    __typename?: 'PartnerTicketInvoice';
    invoiceNumber: string;
    downloadPdfUrl: string;
    downloadXlsxUrl: string;
    periodStartDate: any;
    periodEndDate: any;
    id: string;
    amount: number;
    createdAt: any;
};

export type BalanceItemFragment =
    | BalanceItem_PartnerBalanceCorrection_Fragment
    | BalanceItem_PartnerBalancePayment_Fragment
    | BalanceItem_PartnerBookingInvoice_Fragment
    | BalanceItem_PartnerTicketInvoice_Fragment;

export type BookingSidebarQueryVariables = Exact<{
    id: Scalars['ID']['input'];
}>;

export type BookingSidebarQuery = {
    __typename?: 'PartnerQuery';
    booking?: {
        __typename?: 'Booking';
        id: string;
        duration: number;
        amountAdults: number;
        amountYouths: number;
        amountChildren: number;
        amountBabies: number;
        amountPets: number;
        customerComment?: string | null;
        totalPrice: number;
        partnerPriceLines: Array<{ __typename?: 'BookingPriceLine'; label: string; totalPrice: number }>;
    } | null;
};

export type BookingListItemFragment = {
    __typename?: 'Booking';
    id: string;
    number?: string | null;
    amountAdults: number;
    amountYouths: number;
    amountChildren: number;
    amountBabies: number;
    amountPets: number;
    departureDate: any;
    arrivalDate: any;
    updatedAt: any;
    customerComment?: string | null;
    status: BookingStatusEnum;
    duration: number;
    totalPrice: number;
    customer?: { __typename?: 'Customer'; id: string; firstName?: string | null; lastName?: string | null } | null;
    partnerPriceLines: Array<{ __typename?: 'BookingPriceLine'; category: PriceLineCategoryEnum; totalPrice: number }>;
    rentalUnit: {
        __typename?: 'RentalUnit';
        id: string;
        name: string;
        slug: string;
        code: string;
        thumbnailUrl?: string | null;
        hideForPartner: boolean;
    };
};

export type BulkEditDatePricingNightPriceMutationVariables = Exact<{
    input: BulkEditDatePricingNightPriceInput;
}>;

export type BulkEditDatePricingNightPriceMutation = {
    __typename?: 'PartnerMutation';
    bulkEditDatePricingNightPrice: {
        __typename?: 'BulkEditDatePricingNightPricePayload';
        datePricings?: Array<{ __typename?: 'DatePricing'; id: string; nightPrice: number }> | null;
    };
};

export type BulkEditDatePricingExtraPersonPriceMutationVariables = Exact<{
    input: BulkEditDatePricingExtraPersonPriceInput;
}>;

export type BulkEditDatePricingExtraPersonPriceMutation = {
    __typename?: 'PartnerMutation';
    bulkEditDatePricingExtraPersonPrice: {
        __typename?: 'BulkEditDatePricingExtraPersonPricePayload';
        datePricings?: Array<{ __typename?: 'DatePricing'; id: string; extraPersonPrice: number }> | null;
    };
};

export type BulkEditDatePricingArrivalAllowedMutationVariables = Exact<{
    input: BulkEditDatePricingArrivalAllowedInput;
}>;

export type BulkEditDatePricingArrivalAllowedMutation = {
    __typename?: 'PartnerMutation';
    bulkEditDatePricingArrivalAllowed: {
        __typename?: 'BulkEditDatePricingArrivalAllowedPayload';
        datePricings?: Array<{ __typename?: 'DatePricing'; id: string; arrivalAllowed: boolean }> | null;
    };
};

export type BulkEditDatePricingDepartureAllowedMutationVariables = Exact<{
    input: BulkEditDatePricingDepartureAllowedInput;
}>;

export type BulkEditDatePricingDepartureAllowedMutation = {
    __typename?: 'PartnerMutation';
    bulkEditDatePricingDepartureAllowed: {
        __typename?: 'BulkEditDatePricingDepartureAllowedPayload';
        datePricings?: Array<{ __typename?: 'DatePricing'; id: string; departureAllowed: boolean }> | null;
    };
};

export type BulkEditDatePricingMinimumStayDurationMutationVariables = Exact<{
    input: BulkEditDatePricingMinimumStayDurationInput;
}>;

export type BulkEditDatePricingMinimumStayDurationMutation = {
    __typename?: 'PartnerMutation';
    bulkEditDatePricingMinimumStayDuration: {
        __typename?: 'BulkEditDatePricingMinimumStayDurationPayload';
        datePricings?: Array<{ __typename?: 'DatePricing'; id: string; minimumStayDuration: number }> | null;
    };
};

export type BulkEditDatePricingMinimumStayPriceMutationVariables = Exact<{
    input: BulkEditDatePricingMinimumStayPriceInput;
}>;

export type BulkEditDatePricingMinimumStayPriceMutation = {
    __typename?: 'PartnerMutation';
    bulkEditDatePricingMinimumStayPrice: {
        __typename?: 'BulkEditDatePricingMinimumStayPricePayload';
        datePricings?: Array<{ __typename?: 'DatePricing'; id: string; minimumStayPrice: number }> | null;
    };
};

export type BulkEditDatePricingWeekPriceMutationVariables = Exact<{
    input: BulkEditDatePricingWeekPriceInput;
}>;

export type BulkEditDatePricingWeekPriceMutation = {
    __typename?: 'PartnerMutation';
    bulkEditDatePricingWeekPrice: {
        __typename?: 'BulkEditDatePricingWeekPricePayload';
        datePricings?: Array<{ __typename?: 'DatePricing'; id: string; weekPrice?: number | null }> | null;
    };
};

export type BulkEditDatePricingBaseStayPriceMutationVariables = Exact<{
    input: BulkEditDatePricingBaseStayPriceInput;
}>;

export type BulkEditDatePricingBaseStayPriceMutation = {
    __typename?: 'PartnerMutation';
    bulkEditDatePricingBaseStayPrice: {
        __typename?: 'BulkEditDatePricingBaseStayPricePayload';
        datePricings?: Array<{ __typename?: 'DatePricing'; id: string; baseStayPrice: number }> | null;
    };
};

export type AttributeCategoryFragment = {
    __typename?: 'AttributeCategory';
    id: string;
    allowMultiple: boolean;
    name?: string | null;
    unitAttributes: Array<{ __typename?: 'Attribute'; id: string; name?: string | null; type: AttributeTypeEnum }>;
    accoAttributes: Array<{ __typename?: 'Attribute'; id: string; name?: string | null; type: AttributeTypeEnum }>;
};

export type DayInfoQueryVariables = Exact<{
    slug: Scalars['String']['input'];
    date: Scalars['Date']['input'];
}>;

export type DayInfoQuery = {
    __typename?: 'PartnerQuery';
    rentalUnit?: {
        __typename?: 'RentalUnit';
        id: string;
        trips: Array<{
            __typename?: 'Trip';
            flexType: FlexTypeEnum;
            duration: number;
            rentalSumPartner: number;
            type: TripTypeEnum;
            special?: { __typename?: 'Special'; name?: string | null } | null;
        }>;
    } | null;
};

export type UploadImportMutationVariables = Exact<{
    input: UploadTripPricingsInput;
    start: Scalars['Date']['input'];
    end: Scalars['Date']['input'];
}>;

export type UploadImportMutation = {
    __typename?: 'PartnerMutation';
    uploadTripPricings: {
        __typename?: 'UploadTripPricingsPayload';
        rentalUnit?: {
            __typename?: 'RentalUnit';
            id: string;
            tripPricings: Array<{ __typename?: 'TripPricing'; duration: number; id: string; price: number; date: any }>;
        } | null;
    };
};

export type ValidateLockoutsQueryVariables = Exact<{
    start: Scalars['Date']['input'];
    end: Scalars['Date']['input'];
    unitSlug: Scalars['String']['input'];
}>;

export type ValidateLockoutsQuery = {
    __typename?: 'PartnerQuery';
    rentalUnit?: {
        __typename?: 'RentalUnit';
        id: string;
        allotmentLockouts: Array<{
            __typename?: 'AllotmentLockout';
            id: string;
            startDate: any;
            endDate: any;
            type: AllotmentLockoutTypeEnum;
            comment?: string | null;
            source?: string | null;
        }>;
        bookings: Array<{
            __typename?: 'Booking';
            id: string;
            arrivalDate: any;
            departureDate: any;
            status: BookingStatusEnum;
        }>;
    } | null;
};

export type EditLockoutMutationVariables = Exact<{
    input: EditAllotmentLockoutInput;
}>;

export type EditLockoutMutation = {
    __typename?: 'PartnerMutation';
    editAllotmentLockout: {
        __typename?: 'EditAllotmentLockoutPayload';
        allotmentLockout?: {
            __typename?: 'AllotmentLockout';
            id: string;
            type: AllotmentLockoutTypeEnum;
            comment?: string | null;
            source?: string | null;
            startDate: any;
            endDate: any;
            isDragging: boolean;
            isActive: boolean;
            isHovering: boolean;
        } | null;
    };
};

export type CreateLockoutMutationVariables = Exact<{
    input: CreateAllotmentLockoutInput;
}>;

export type CreateLockoutMutation = {
    __typename?: 'PartnerMutation';
    createAllotmentLockout: {
        __typename?: 'CreateAllotmentLockoutPayload';
        allotmentLockout?: {
            __typename?: 'AllotmentLockout';
            id: string;
            type: AllotmentLockoutTypeEnum;
            comment?: string | null;
            source?: string | null;
            startDate: any;
            endDate: any;
            isDragging: boolean;
            isActive: boolean;
            isHovering: boolean;
        } | null;
    };
};

export type DeleteLockoutMutationVariables = Exact<{
    input: DeleteAllotmentLockoutInput;
}>;

export type DeleteLockoutMutation = {
    __typename?: 'PartnerMutation';
    deleteAllotmentLockout: { __typename?: 'DeleteAllotmentLockoutPayload'; id?: string | null };
};

export type ValidatePeriodQueryVariables = Exact<{
    start: Scalars['Date']['input'];
    end: Scalars['Date']['input'];
    unitSlug: Scalars['String']['input'];
}>;

export type ValidatePeriodQuery = {
    __typename?: 'PartnerQuery';
    rentalUnit?: {
        __typename?: 'RentalUnit';
        id: string;
        datePricingModifiers: Array<{
            __typename?: 'DatePricingModifier';
            id: string;
            minDuration: number;
            maxDuration: number;
            startDate: any;
            endDate: any;
        }>;
    } | null;
};

export type AddPeriodRuleMutationVariables = Exact<{
    input: CreateDatePricingModifierInput;
}>;

export type AddPeriodRuleMutation = {
    __typename?: 'PartnerMutation';
    createDatePricingModifier: {
        __typename?: 'CreateDatePricingModifierPayload';
        datePricingModifier?: {
            __typename?: 'DatePricingModifier';
            id: string;
            startDate: any;
            endDate: any;
            minDuration: number;
            maxDuration: number;
            type: DatePricingModifierTypeEnum;
            value: number;
            valueType: DatePricingModifierValueTypeEnum;
        } | null;
    };
};

export type EditPeriodRuleMutationVariables = Exact<{
    input: EditDatePricingModifierInput;
}>;

export type EditPeriodRuleMutation = {
    __typename?: 'PartnerMutation';
    editDatePricingModifier: {
        __typename?: 'EditDatePricingModifierPayload';
        datePricingModifier?: {
            __typename?: 'DatePricingModifier';
            id: string;
            startDate: any;
            endDate: any;
            minDuration: number;
            maxDuration: number;
            type: DatePricingModifierTypeEnum;
            value: number;
            valueType: DatePricingModifierValueTypeEnum;
        } | null;
    };
};

export type DeletePeriodRuleMutationVariables = Exact<{
    id: Scalars['ID']['input'];
}>;

export type DeletePeriodRuleMutation = {
    __typename?: 'PartnerMutation';
    deleteDatePricingModifier: { __typename?: 'DeleteDatePricingModifierPayload'; id?: number | null };
};

export type PeriodRulesFragment = {
    __typename?: 'DatePricingModifier';
    id: string;
    startDate: any;
    endDate: any;
    minDuration: number;
    maxDuration: number;
    type: DatePricingModifierTypeEnum;
    value: number;
    valueType: DatePricingModifierValueTypeEnum;
};

export type EditDatePricingDisplayOptionsMutationVariables = Exact<{
    input: EditDatePricingDisplayOptionsInput;
    start: Scalars['Date']['input'];
    end: Scalars['Date']['input'];
}>;

export type EditDatePricingDisplayOptionsMutation = {
    __typename?: 'PartnerMutation';
    editDatePricingDisplayOptions: {
        __typename?: 'EditDatePricingDisplayOptionsPayload';
        rentalUnit?: {
            __typename?: 'RentalUnit';
            id: string;
            slug: string;
            showArrivalAllowedRow: boolean;
            showBaseStayPriceRow: boolean;
            showDepartureAllowedRow: boolean;
            showExtraPersonPriceRow: boolean;
            showMinimumStayDurationRow: boolean;
            showMinimumStayPriceRow: boolean;
            showNightPriceRow: boolean;
            showWeekPriceRow: boolean;
            datePricings: Array<{
                __typename?: 'DatePricing';
                id: string;
                date: any;
                arrivalAllowed: boolean;
                departureAllowed: boolean;
                nightPrice: number;
                extraPersonPrice: number;
                minimumStayDuration: number;
                weekPrice?: number | null;
                baseStayPrice: number;
                minimumStayPrice: number;
            }>;
            datePricingModifiers: Array<{
                __typename?: 'DatePricingModifier';
                id: string;
                startDate: any;
                endDate: any;
                minDuration: number;
                maxDuration: number;
                type: DatePricingModifierTypeEnum;
                value: number;
                valueType: DatePricingModifierValueTypeEnum;
            }>;
            allotments: Array<{ __typename?: 'Allotment'; amount: number; id: string; date: any }>;
            bookings: Array<{
                __typename?: 'Booking';
                id: string;
                totalPrice: number;
                status: BookingStatusEnum;
                isActive: boolean;
                isHovering: boolean;
                startDate: any;
                endDate: any;
                customer?: {
                    __typename?: 'Customer';
                    id: string;
                    firstName?: string | null;
                    lastName?: string | null;
                } | null;
            }>;
            allotmentLockouts: Array<{
                __typename?: 'AllotmentLockout';
                id: string;
                type: AllotmentLockoutTypeEnum;
                comment?: string | null;
                source?: string | null;
                startDate: any;
                endDate: any;
                isDragging: boolean;
                isActive: boolean;
                isHovering: boolean;
            }>;
        } | null;
    };
};

export type EditSpecialParticipationOptInMutationVariables = Exact<{
    input: EditSpecialParticipationInput;
    end: Scalars['Date']['input'];
    start: Scalars['Date']['input'];
}>;

export type EditSpecialParticipationOptInMutation = {
    __typename?: 'PartnerMutation';
    editSpecialParticipationOptIn: {
        __typename?: 'EditSpecialParticipationPayload';
        specialParticipation?: {
            __typename?: 'SpecialParticipation';
            id: string;
            blockedArrivalDates: Array<any>;
            optInAcceptedAt?: any | null;
            optInRejectedAt?: any | null;
            special: {
                __typename?: 'Special';
                id: string;
                arrivalFrom: any;
                arrivalUntil: any;
                description?: string | null;
                name?: string | null;
                optInRequired: boolean;
                partnerConditions?: string | null;
            };
            trips: Array<{ __typename?: 'Trip'; date: any; duration: number; rentalSumPartner: number }>;
        } | null;
    };
};

export type SpecialParticipationFragment = {
    __typename?: 'SpecialParticipation';
    id: string;
    blockedArrivalDates: Array<any>;
    optInAcceptedAt?: any | null;
    optInRejectedAt?: any | null;
    special: {
        __typename?: 'Special';
        id: string;
        arrivalFrom: any;
        arrivalUntil: any;
        description?: string | null;
        name?: string | null;
        optInRequired: boolean;
        partnerConditions?: string | null;
    };
    trips: Array<{ __typename?: 'Trip'; date: any; duration: number; rentalSumPartner: number }>;
};

export type EditSpecialParticipationDateBlockMutationVariables = Exact<{
    input: EditSpecialParticipationDateBlockInput;
    start: Scalars['Date']['input'];
    end: Scalars['Date']['input'];
}>;

export type EditSpecialParticipationDateBlockMutation = {
    __typename?: 'PartnerMutation';
    editSpecialParticipationDateBlock: {
        __typename?: 'EditSpecialParticipationDateBlockPayload';
        specialParticipation?: {
            __typename?: 'SpecialParticipation';
            id: string;
            blockedArrivalDates: Array<any>;
            optInAcceptedAt?: any | null;
            optInRejectedAt?: any | null;
            special: {
                __typename?: 'Special';
                id: string;
                arrivalFrom: any;
                arrivalUntil: any;
                description?: string | null;
                name?: string | null;
                optInRequired: boolean;
                partnerConditions?: string | null;
            };
            trips: Array<{ __typename?: 'Trip'; date: any; duration: number; rentalSumPartner: number }>;
        } | null;
    };
};

export type TicketEdgeFragment = {
    __typename?: 'TicketEdge';
    cursor: string;
    node?: {
        __typename?: 'Ticket';
        id: string;
        createdAt: any;
        status: TicketStatusEnum;
        customer?: { __typename?: 'Customer'; id: string; firstName?: string | null; lastName?: string | null } | null;
        priceLines: Array<{ __typename?: 'TicketPriceLine'; label: string; quantity: number; totalPrice: number }>;
        rateLines: Array<{
            __typename?: 'TicketRateLine';
            rateName?: string | null;
            amount: number;
            unitPrice: number;
        }>;
        timeslot: {
            __typename?: 'Timeslot';
            id: string;
            startDateTime: any;
            endDateTime: any;
            activity: {
                __typename?: 'Activity';
                id: string;
                name?: string | null;
                slug: string;
                hideForPartner: boolean;
                company: { __typename?: 'Company'; id: string; name: string };
                listImage?: { __typename?: 'ActivityListImage'; url: string } | null;
            };
            rateGroup:
                | {
                      __typename?: 'ActivityRateGroup';
                      id: string;
                      name?: string | null;
                      rates: Array<{ __typename?: 'Rate'; id: string; name?: string | null }>;
                  }
                | {
                      __typename?: 'CompanyRateGroup';
                      id: string;
                      name?: string | null;
                      rates: Array<{ __typename?: 'Rate'; id: string; name?: string | null }>;
                  };
        };
    } | null;
};

export type TripColumnFragment = { __typename?: 'TripPricing'; duration: number; id: string; price: number; date: any };

export type WizardPriceCopierQueryVariables = Exact<{
    id: Scalars['ID']['input'];
    startDate: Scalars['Date']['input'];
    endDate: Scalars['Date']['input'];
}>;

export type WizardPriceCopierQuery = {
    __typename?: 'PartnerQuery';
    rentalUnit?: {
        __typename?: 'RentalUnit';
        id: string;
        datePricingStartDate?: any | null;
        datePricingEndDate?: any | null;
        showArrivalAllowedRow: boolean;
        showBaseStayPriceRow: boolean;
        showDepartureAllowedRow: boolean;
        showExtraPersonPriceRow: boolean;
        showMinimumStayDurationRow: boolean;
        showMinimumStayPriceRow: boolean;
        showNightPriceRow: boolean;
        showWeekPriceRow: boolean;
        datePricings: Array<{ __typename?: 'DatePricing'; id: string; date: any }>;
    } | null;
};

export type NavigationUserFragment = {
    __typename?: 'User';
    id: string;
    fullName: string;
    isBackofficeUser: boolean;
    email: string;
    impersonatedBy?: { __typename?: 'User'; fullName: string } | null;
    partners: Array<{
        __typename?: 'Partner';
        id: string;
        accommodations: Array<{
            __typename?: 'Accommodation';
            rentalUnits: Array<{ __typename?: 'RentalUnit'; id: string; name: string }>;
        }>;
    }>;
};

export type PriceColumnPricingFragment = {
    __typename?: 'DatePricing';
    id: string;
    date: any;
    arrivalAllowed: boolean;
    departureAllowed: boolean;
    nightPrice: number;
    extraPersonPrice: number;
    minimumStayDuration: number;
    weekPrice?: number | null;
    baseStayPrice: number;
    minimumStayPrice: number;
};

export type PriceColumnAllotmentFragment = { __typename?: 'Allotment'; amount: number; id: string; date: any };

export type EditArrivalAllowedMutationVariables = Exact<{
    id: Scalars['ID']['input'];
    arrivalAllowed: Scalars['Boolean']['input'];
}>;

export type EditArrivalAllowedMutation = {
    __typename?: 'PartnerMutation';
    editDatePricingArrivalAllowed: {
        __typename?: 'EditDatePricingArrivalAllowedPayload';
        datePricing?: { __typename?: 'DatePricing'; id: string; arrivalAllowed: boolean } | null;
    };
};

export type EditDepartureAllowedMutationVariables = Exact<{
    id: Scalars['ID']['input'];
    departureAllowed: Scalars['Boolean']['input'];
}>;

export type EditDepartureAllowedMutation = {
    __typename?: 'PartnerMutation';
    editDatePricingDepartureAllowed: {
        __typename?: 'EditDatePricingDepartureAllowedPayload';
        datePricing?: { __typename?: 'DatePricing'; id: string; departureAllowed: boolean } | null;
    };
};

export type EditNightPriceMutationVariables = Exact<{
    id: Scalars['ID']['input'];
    nightPrice: Scalars['Float']['input'];
}>;

export type EditNightPriceMutation = {
    __typename?: 'PartnerMutation';
    editDatePricingNightPrice: {
        __typename?: 'EditDatePricingNightPricePayload';
        datePricing?: { __typename?: 'DatePricing'; id: string; nightPrice: number } | null;
    };
};

export type EditExtraPersonPriceMutationVariables = Exact<{
    id: Scalars['ID']['input'];
    extraPersonPrice: Scalars['Float']['input'];
}>;

export type EditExtraPersonPriceMutation = {
    __typename?: 'PartnerMutation';
    editDatePricingExtraPersonPrice: {
        __typename?: 'EditDatePricingExtraPersonPricePayload';
        datePricing?: { __typename?: 'DatePricing'; id: string; extraPersonPrice: number } | null;
    };
};

export type EditMinimumStayDurationMutationVariables = Exact<{
    id: Scalars['ID']['input'];
    minimumStayDuration: Scalars['Int']['input'];
}>;

export type EditMinimumStayDurationMutation = {
    __typename?: 'PartnerMutation';
    editDatePricingMinimumStayDuration: {
        __typename?: 'EditDatePricingMinimumStayDurationPayload';
        datePricing?: { __typename?: 'DatePricing'; id: string; minimumStayDuration: number } | null;
    };
};

export type EditWeekPriceMutationVariables = Exact<{
    id: Scalars['ID']['input'];
    weekPrice?: InputMaybe<Scalars['Float']['input']>;
}>;

export type EditWeekPriceMutation = {
    __typename?: 'PartnerMutation';
    editDatePricingWeekPrice: {
        __typename?: 'EditDatePricingWeekPricePayload';
        datePricing?: { __typename?: 'DatePricing'; id: string; weekPrice?: number | null } | null;
    };
};

export type EditBaseStayPriceMutationVariables = Exact<{
    id: Scalars['ID']['input'];
    baseStayPrice: Scalars['Float']['input'];
}>;

export type EditBaseStayPriceMutation = {
    __typename?: 'PartnerMutation';
    editDatePricingBaseStayPrice: {
        __typename?: 'EditDatePricingBaseStayPricePayload';
        datePricing?: { __typename?: 'DatePricing'; id: string; baseStayPrice: number } | null;
    };
};

export type EditMinimumStayPriceMutationVariables = Exact<{
    id: Scalars['ID']['input'];
    minimumStayPrice: Scalars['Float']['input'];
}>;

export type EditMinimumStayPriceMutation = {
    __typename?: 'PartnerMutation';
    editDatePricingMinimumStayPrice: {
        __typename?: 'EditDatePricingMinimumStayPricePayload';
        datePricing?: { __typename?: 'DatePricing'; id: string; minimumStayPrice: number } | null;
    };
};

export type PricingFragment = {
    __typename?: 'RentalUnit';
    id: string;
    showArrivalAllowedRow: boolean;
    showBaseStayPriceRow: boolean;
    showDepartureAllowedRow: boolean;
    showExtraPersonPriceRow: boolean;
    showMinimumStayDurationRow: boolean;
    showMinimumStayPriceRow: boolean;
    showNightPriceRow: boolean;
    showWeekPriceRow: boolean;
    allotments: Array<{ __typename?: 'Allotment'; amount: number; id: string; date: any }>;
    bookings: Array<{
        __typename?: 'Booking';
        id: string;
        totalPrice: number;
        status: BookingStatusEnum;
        isActive: boolean;
        isHovering: boolean;
        startDate: any;
        endDate: any;
        customer?: { __typename?: 'Customer'; id: string; firstName?: string | null; lastName?: string | null } | null;
    }>;
    allotmentLockouts: Array<{
        __typename?: 'AllotmentLockout';
        id: string;
        type: AllotmentLockoutTypeEnum;
        comment?: string | null;
        source?: string | null;
        startDate: any;
        endDate: any;
        isDragging: boolean;
        isActive: boolean;
        isHovering: boolean;
    }>;
};

export type TimeslotsQueryVariables = Exact<{
    activitySlug: Scalars['String']['input'];
    startDate: Scalars['Date']['input'];
    endDate: Scalars['Date']['input'];
}>;

export type TimeslotsQuery = {
    __typename?: 'PartnerQuery';
    activity?: {
        __typename?: 'Activity';
        id: string;
        timeslots: Array<{
            __typename?: 'Timeslot';
            id: string;
            startDateTime: any;
            endDateTime: any;
            label?: string | null;
            allotment?: number | null;
            deLabel?: string | null;
            enLabel?: string | null;
            rateGroup:
                | { __typename?: 'ActivityRateGroup'; id: string; canBuyTickets: boolean }
                | { __typename?: 'CompanyRateGroup'; id: string; canBuyTickets: boolean };
        }>;
        activityRateGroups: Array<{
            __typename?: 'ActivityRateGroup';
            id: string;
            name?: string | null;
            canBuyTickets: boolean;
        }>;
    } | null;
};

export type CreateActivityTimeslotMutationVariables = Exact<{
    input: CreateActivityTimeslotInput;
}>;

export type CreateActivityTimeslotMutation = {
    __typename?: 'PartnerMutation';
    createActivityTimeslot: {
        __typename?: 'CreateActivityTimeslotPayload';
        timeslot?: {
            __typename?: 'Timeslot';
            id: string;
            startDateTime: any;
            endDateTime: any;
            label?: string | null;
            allotment?: number | null;
            deLabel?: string | null;
            enLabel?: string | null;
            rateGroup:
                | { __typename?: 'ActivityRateGroup'; id: string; canBuyTickets: boolean }
                | { __typename?: 'CompanyRateGroup'; id: string; canBuyTickets: boolean };
        } | null;
    };
};

export type DeleteActivityTimeslotMutationVariables = Exact<{
    input: DeleteActivityTimeslotInput;
}>;

export type DeleteActivityTimeslotMutation = {
    __typename?: 'PartnerMutation';
    deleteActivityTimeslot: { __typename?: 'DeleteActivityTimeslotPayload'; id?: string | null };
};

export type EditActivityTimeslotMutationVariables = Exact<{
    input: EditActivityTimeslotInput;
}>;

export type EditActivityTimeslotMutation = {
    __typename?: 'PartnerMutation';
    editActivityTimeslot: {
        __typename?: 'EditActivityTimeslotPayload';
        timeslot?: {
            __typename?: 'Timeslot';
            id: string;
            startDateTime: any;
            endDateTime: any;
            label?: string | null;
            allotment?: number | null;
            deLabel?: string | null;
            enLabel?: string | null;
            rateGroup:
                | { __typename?: 'ActivityRateGroup'; id: string; canBuyTickets: boolean }
                | { __typename?: 'CompanyRateGroup'; id: string; canBuyTickets: boolean };
        } | null;
    };
};

export type ActivityTimeslotsFragment = {
    __typename?: 'Activity';
    id: string;
    timeslots: Array<{
        __typename?: 'Timeslot';
        id: string;
        startDateTime: any;
        endDateTime: any;
        label?: string | null;
        allotment?: number | null;
        deLabel?: string | null;
        enLabel?: string | null;
        rateGroup:
            | { __typename?: 'ActivityRateGroup'; id: string; canBuyTickets: boolean }
            | { __typename?: 'CompanyRateGroup'; id: string; canBuyTickets: boolean };
    }>;
    activityRateGroups: Array<{
        __typename?: 'ActivityRateGroup';
        id: string;
        name?: string | null;
        canBuyTickets: boolean;
    }>;
};

export type TimeslotsRateGroupsFragment = {
    __typename?: 'ActivityRateGroup';
    id: string;
    name?: string | null;
    canBuyTickets: boolean;
};

export type ActivityTimeslotFragment = {
    __typename?: 'Timeslot';
    id: string;
    startDateTime: any;
    endDateTime: any;
    label?: string | null;
    allotment?: number | null;
    deLabel?: string | null;
    enLabel?: string | null;
    rateGroup:
        | { __typename?: 'ActivityRateGroup'; id: string; canBuyTickets: boolean }
        | { __typename?: 'CompanyRateGroup'; id: string; canBuyTickets: boolean };
};

export type BalancesQueryVariables = Exact<{
    partnerId: Scalars['ID']['input'];
    startDate: Scalars['Date']['input'];
    endDate: Scalars['Date']['input'];
}>;

export type BalancesQuery = {
    __typename?: 'PartnerQuery';
    partner?: {
        __typename?: 'Partner';
        id: string;
        balance: number;
        balanceItems: Array<
            | {
                  __typename?: 'PartnerBalanceCorrection';
                  description: string;
                  id: string;
                  amount: number;
                  createdAt: any;
              }
            | {
                  __typename?: 'PartnerBalancePayment';
                  batchType?: PartnerPaymentBatchTypeEnum | null;
                  id: string;
                  amount: number;
                  createdAt: any;
              }
            | {
                  __typename?: 'PartnerBookingInvoice';
                  invoiceNumber: string;
                  downloadPdfUrl: string;
                  downloadXlsxUrl: string;
                  periodStartDate: any;
                  periodEndDate: any;
                  id: string;
                  amount: number;
                  createdAt: any;
              }
            | {
                  __typename?: 'PartnerTicketInvoice';
                  invoiceNumber: string;
                  downloadPdfUrl: string;
                  downloadXlsxUrl: string;
                  periodStartDate: any;
                  periodEndDate: any;
                  id: string;
                  amount: number;
                  createdAt: any;
              }
        >;
    } | null;
};

export type BookingConnectionFragment = {
    __typename?: 'BookingConnection';
    totalCount: number;
    edges?: Array<{
        __typename?: 'BookingEdge';
        cursor: string;
        node?: {
            __typename?: 'Booking';
            id: string;
            number?: string | null;
            amountAdults: number;
            amountYouths: number;
            amountChildren: number;
            amountBabies: number;
            amountPets: number;
            departureDate: any;
            arrivalDate: any;
            updatedAt: any;
            customerComment?: string | null;
            status: BookingStatusEnum;
            duration: number;
            totalPrice: number;
            customer?: {
                __typename?: 'Customer';
                id: string;
                firstName?: string | null;
                lastName?: string | null;
            } | null;
            partnerPriceLines: Array<{
                __typename?: 'BookingPriceLine';
                category: PriceLineCategoryEnum;
                totalPrice: number;
            }>;
            rentalUnit: {
                __typename?: 'RentalUnit';
                id: string;
                name: string;
                slug: string;
                code: string;
                thumbnailUrl?: string | null;
                hideForPartner: boolean;
            };
        } | null;
    } | null> | null;
    pageInfo: {
        __typename?: 'PageInfo';
        startCursor?: string | null;
        endCursor?: string | null;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
    };
};

export type BookingEdgeFragment = {
    __typename?: 'BookingEdge';
    cursor: string;
    node?: {
        __typename?: 'Booking';
        id: string;
        number?: string | null;
        amountAdults: number;
        amountYouths: number;
        amountChildren: number;
        amountBabies: number;
        amountPets: number;
        departureDate: any;
        arrivalDate: any;
        updatedAt: any;
        customerComment?: string | null;
        status: BookingStatusEnum;
        duration: number;
        totalPrice: number;
        customer?: { __typename?: 'Customer'; id: string; firstName?: string | null; lastName?: string | null } | null;
        partnerPriceLines: Array<{
            __typename?: 'BookingPriceLine';
            category: PriceLineCategoryEnum;
            totalPrice: number;
        }>;
        rentalUnit: {
            __typename?: 'RentalUnit';
            id: string;
            name: string;
            slug: string;
            code: string;
            thumbnailUrl?: string | null;
            hideForPartner: boolean;
        };
    } | null;
};

export type PartnerDataQueryVariables = Exact<{
    slug?: InputMaybe<Scalars['String']['input']>;
    partnerId: Scalars['ID']['input'];
}>;

export type PartnerDataQuery = {
    __typename?: 'PartnerQuery';
    viewer?: {
        __typename?: 'User';
        id: string;
        fullName: string;
        isBackofficeUser: boolean;
        email: string;
        impersonatedBy?: { __typename?: 'User'; fullName: string } | null;
        partners: Array<{
            __typename?: 'Partner';
            id: string;
            accommodations: Array<{
                __typename?: 'Accommodation';
                rentalUnits: Array<{ __typename?: 'RentalUnit'; id: string; name: string }>;
            }>;
        }>;
    } | null;
    partner?: {
        __typename?: 'Partner';
        id: string;
        name: string;
        accommodations: Array<{
            __typename?: 'Accommodation';
            id: string;
            slug: string;
            name: string;
            type: AccommodationTypeEnum;
            hasPublicPage: boolean;
            rentalUnits: Array<{
                __typename?: 'RentalUnit';
                slug: string;
                id: string;
                type: RentalUnitTypeEnum;
                datePricingStartDate?: any | null;
                name: string;
                showAllotmentLockouts: boolean;
                code: string;
                thumbnailUrl?: string | null;
                hideForPartner: boolean;
            }>;
        }>;
        companies: Array<{
            __typename?: 'Company';
            id: string;
            name: string;
            slug: string;
            enabled: boolean;
            listImage?: { __typename?: 'CompanyListImage'; url: string } | null;
            activities: Array<{
                __typename?: 'Activity';
                id: string;
                name?: string | null;
                slug: string;
                hideForPartner: boolean;
                company: { __typename?: 'Company'; id: string; name: string };
                listImage?: { __typename?: 'ActivityListImage'; url: string } | null;
            }>;
        }>;
    } | null;
    rentalUnit?: {
        __typename?: 'RentalUnit';
        id: string;
        showAllotmentLockouts: boolean;
        lastDatePricingDate?: any | null;
        datePricingStartDate?: any | null;
        datePricingEndDate?: any | null;
        type: RentalUnitTypeEnum;
        maxAllotment: number;
        rentalUnitContracts: Array<{
            __typename?: 'RentalUnitContract';
            id: string;
            approvalDate?: any | null;
            startDate: any;
        }>;
    } | null;
};

export type AccommodationEntryFragment = {
    __typename?: 'Accommodation';
    id: string;
    name: string;
    slug: string;
    rentalUnits: Array<{
        __typename?: 'RentalUnit';
        slug: string;
        id: string;
        type: RentalUnitTypeEnum;
        datePricingStartDate?: any | null;
        name: string;
        showAllotmentLockouts: boolean;
        code: string;
        thumbnailUrl?: string | null;
        hideForPartner: boolean;
    }>;
};

export type RentalUnitMetaDataFragment = {
    __typename?: 'RentalUnit';
    id: string;
    showAllotmentLockouts: boolean;
    lastDatePricingDate?: any | null;
    datePricingStartDate?: any | null;
    datePricingEndDate?: any | null;
    type: RentalUnitTypeEnum;
    maxAllotment: number;
    rentalUnitContracts: Array<{
        __typename?: 'RentalUnitContract';
        id: string;
        approvalDate?: any | null;
        startDate: any;
    }>;
};

export type PasswordResetMutationVariables = Exact<{
    token: Scalars['String']['input'];
    newPassword: Scalars['String']['input'];
}>;

export type PasswordResetMutation = {
    __typename?: 'PartnerMutation';
    resetPassword: { __typename?: 'ResetPasswordPayload'; email?: string | null };
};

export type QrSignupMutationVariables = Exact<{
    input: InitTicketScanSessionInput;
}>;

export type QrSignupMutation = {
    __typename?: 'PartnerMutation';
    initTicketScanSession: { __typename?: 'InitTicketScanSessionPayload'; deepLink?: string | null };
};

export type PartnerRegistrationDetailsFragment = { __typename?: 'PartnerRegistration'; id: string };

export type RegisterNewPartnerMutationVariables = Exact<{
    input: RegisterNewPartnerInput;
}>;

export type RegisterNewPartnerMutation = {
    __typename?: 'PartnerMutation';
    registerNewPartner: {
        __typename?: 'RegisterNewPartnerPayload';
        partnerRegistration?: { __typename?: 'PartnerRegistration'; id: string } | null;
    };
};

export type ReviewsQueryVariables = Exact<{
    partnerId: Scalars['ID']['input'];
    rentalUnitIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type ReviewsQuery = {
    __typename?: 'PartnerQuery';
    partner?: {
        __typename?: 'Partner';
        id: string;
        reviews: Array<{
            __typename?: 'Review';
            id: string;
            createdAt: any;
            title: string;
            average: number;
            enabled: boolean;
            language: string;
            city?: string | null;
            reply?: string | null;
            rentalUnit: {
                __typename?: 'RentalUnit';
                id: string;
                name: string;
                slug: string;
                code: string;
                thumbnailUrl?: string | null;
                hideForPartner: boolean;
            };
        }>;
    } | null;
};

export type ReviewFragment = {
    __typename?: 'Review';
    id: string;
    createdAt: any;
    title: string;
    average: number;
    enabled: boolean;
    language: string;
    city?: string | null;
    reply?: string | null;
    rentalUnit: {
        __typename?: 'RentalUnit';
        id: string;
        name: string;
        slug: string;
        code: string;
        thumbnailUrl?: string | null;
        hideForPartner: boolean;
    };
};

export type ActivityInfoFragment = {
    __typename?: 'Activity';
    id: string;
    slug: string;
    name?: string | null;
    enabled: boolean;
};

export type ActivityRatesQueryVariables = Exact<{
    activitySlug: Scalars['String']['input'];
}>;

export type ActivityRatesQuery = {
    __typename?: 'PartnerQuery';
    activity?: {
        __typename?: 'Activity';
        id: string;
        activityRateGroups: Array<{
            __typename?: 'ActivityRateGroup';
            id: string;
            name?: string | null;
            canBuyTickets: boolean;
            releaseHours?: number | null;
            qrScansEnabled: boolean;
            rates: Array<{
                __typename?: 'Rate';
                id: string;
                name?: string | null;
                price: number;
                position: number;
                deName?: string | null;
                enName?: string | null;
            }>;
        }>;
    } | null;
};

export type UpsertActivityRateGroupMutationVariables = Exact<{
    input: UpsertActivityRateGroupInput;
}>;

export type UpsertActivityRateGroupMutation = {
    __typename?: 'PartnerMutation';
    upsertActivityRateGroup: {
        __typename?: 'UpsertActivityRateGroupPayload';
        activity?: {
            __typename?: 'Activity';
            id: string;
            activityRateGroups: Array<{
                __typename?: 'ActivityRateGroup';
                id: string;
                name?: string | null;
                canBuyTickets: boolean;
                releaseHours?: number | null;
                qrScansEnabled: boolean;
                rates: Array<{
                    __typename?: 'Rate';
                    id: string;
                    name?: string | null;
                    price: number;
                    position: number;
                    deName?: string | null;
                    enName?: string | null;
                }>;
            }>;
        } | null;
    };
};

export type DeleteActivityRateGroupMutationVariables = Exact<{
    input: DeleteActivityRateGroupInput;
}>;

export type DeleteActivityRateGroupMutation = {
    __typename?: 'PartnerMutation';
    deleteActivityRateGroup: {
        __typename?: 'DeleteActivityRateGroupPayload';
        activity?: {
            __typename?: 'Activity';
            id: string;
            activityRateGroups: Array<{
                __typename?: 'ActivityRateGroup';
                id: string;
                name?: string | null;
                canBuyTickets: boolean;
                releaseHours?: number | null;
                qrScansEnabled: boolean;
                rates: Array<{
                    __typename?: 'Rate';
                    id: string;
                    name?: string | null;
                    price: number;
                    position: number;
                    deName?: string | null;
                    enName?: string | null;
                }>;
            }>;
        } | null;
    };
};

export type ActivityRatesFragment = {
    __typename?: 'Activity';
    id: string;
    activityRateGroups: Array<{
        __typename?: 'ActivityRateGroup';
        id: string;
        name?: string | null;
        canBuyTickets: boolean;
        releaseHours?: number | null;
        qrScansEnabled: boolean;
        rates: Array<{
            __typename?: 'Rate';
            id: string;
            name?: string | null;
            price: number;
            position: number;
            deName?: string | null;
            enName?: string | null;
        }>;
    }>;
};

export type ActivityRateGroupFragment = {
    __typename?: 'ActivityRateGroup';
    id: string;
    name?: string | null;
    canBuyTickets: boolean;
    releaseHours?: number | null;
    qrScansEnabled: boolean;
    rates: Array<{
        __typename?: 'Rate';
        id: string;
        name?: string | null;
        price: number;
        position: number;
        deName?: string | null;
        enName?: string | null;
    }>;
};

export type ActivityDescriptionFragment = {
    __typename?: 'Activity';
    id: string;
    descriptionPending?: string | null;
    descriptionParticularsPending?: string | null;
    descriptionNL?: string | null;
    descriptionDE?: string | null;
    descriptionEN?: string | null;
    descriptionParticularsNL?: string | null;
    descriptionParticularsDE?: string | null;
    descriptionParticularsEN?: string | null;
};

export type ActivityDescriptionQueryVariables = Exact<{
    activitySlug: Scalars['String']['input'];
}>;

export type ActivityDescriptionQuery = {
    __typename?: 'PartnerQuery';
    activity?: {
        __typename?: 'Activity';
        id: string;
        descriptionPending?: string | null;
        descriptionParticularsPending?: string | null;
        descriptionNL?: string | null;
        descriptionDE?: string | null;
        descriptionEN?: string | null;
        descriptionParticularsNL?: string | null;
        descriptionParticularsDE?: string | null;
        descriptionParticularsEN?: string | null;
    } | null;
};

export type EditActivityDescriptionsMutationVariables = Exact<{
    input: EditActivityDescriptionInput;
}>;

export type EditActivityDescriptionsMutation = {
    __typename?: 'PartnerMutation';
    editActivityDescription: {
        __typename?: 'EditActivityDescriptionPayload';
        activity?: {
            __typename?: 'Activity';
            id: string;
            descriptionPending?: string | null;
            descriptionParticularsPending?: string | null;
            descriptionNL?: string | null;
            descriptionDE?: string | null;
            descriptionEN?: string | null;
            descriptionParticularsNL?: string | null;
            descriptionParticularsDE?: string | null;
            descriptionParticularsEN?: string | null;
        } | null;
    };
};

export type ActivityDetailsFragment = {
    __typename?: 'Activity';
    id: string;
    targetAudiences: Array<TargetAudienceEnum | null>;
    maxCapacity?: number | null;
    minCapacity?: number | null;
    externalBookingUrl?: string | null;
    hideForPartner: boolean;
    nameNL?: string | null;
    nameDE?: string | null;
    nameEN?: string | null;
    themes: Array<{ __typename?: 'ActivityTheme'; nameNL?: string | null }>;
};

export type ActivityDetailsQueryVariables = Exact<{
    activitySlug: Scalars['String']['input'];
}>;

export type ActivityDetailsQuery = {
    __typename?: 'PartnerQuery';
    activity?: {
        __typename?: 'Activity';
        id: string;
        targetAudiences: Array<TargetAudienceEnum | null>;
        maxCapacity?: number | null;
        minCapacity?: number | null;
        externalBookingUrl?: string | null;
        hideForPartner: boolean;
        nameNL?: string | null;
        nameDE?: string | null;
        nameEN?: string | null;
        themes: Array<{ __typename?: 'ActivityTheme'; nameNL?: string | null }>;
    } | null;
};

export type EditActivityInfoMutationVariables = Exact<{
    input: EditActivityInfoInput;
}>;

export type EditActivityInfoMutation = {
    __typename?: 'PartnerMutation';
    editActivityInfo: {
        __typename?: 'EditActivityInfoPayload';
        activity?: {
            __typename?: 'Activity';
            id: string;
            targetAudiences: Array<TargetAudienceEnum | null>;
            maxCapacity?: number | null;
            minCapacity?: number | null;
            externalBookingUrl?: string | null;
            hideForPartner: boolean;
            nameNL?: string | null;
            nameDE?: string | null;
            nameEN?: string | null;
            themes: Array<{ __typename?: 'ActivityTheme'; nameNL?: string | null }>;
        } | null;
    };
};

export type ActivityImagesFragment = {
    __typename?: 'Activity';
    id: string;
    name?: string | null;
    videoUrl?: string | null;
    mainImage?: {
        __typename?: 'ActivityMainImage';
        id: string;
        size: number;
        dimensions: Array<number>;
        url: string;
        previewUrl?: string | null;
    } | null;
    listImage?: {
        __typename?: 'ActivityListImage';
        id: string;
        size: number;
        dimensions: Array<number>;
        url: string;
        previewUrl?: string | null;
    } | null;
    images: Array<{
        __typename?: 'ActivityImage';
        id: string;
        size: number;
        url: string;
        dimensions: Array<number>;
        position: number;
        previewUrl?: string | null;
    }>;
};

export type ActivityImagesQueryVariables = Exact<{
    activitySlug: Scalars['String']['input'];
}>;

export type ActivityImagesQuery = {
    __typename?: 'PartnerQuery';
    activity?: {
        __typename?: 'Activity';
        id: string;
        name?: string | null;
        videoUrl?: string | null;
        mainImage?: {
            __typename?: 'ActivityMainImage';
            id: string;
            size: number;
            dimensions: Array<number>;
            url: string;
            previewUrl?: string | null;
        } | null;
        listImage?: {
            __typename?: 'ActivityListImage';
            id: string;
            size: number;
            dimensions: Array<number>;
            url: string;
            previewUrl?: string | null;
        } | null;
        images: Array<{
            __typename?: 'ActivityImage';
            id: string;
            size: number;
            url: string;
            dimensions: Array<number>;
            position: number;
            previewUrl?: string | null;
        }>;
    } | null;
};

export type EditActivityImagesMutationVariables = Exact<{
    input: EditActivityImagesInput;
}>;

export type EditActivityImagesMutation = {
    __typename?: 'PartnerMutation';
    editActivityImages: {
        __typename?: 'EditActivityImagesPayload';
        activity?: {
            __typename?: 'Activity';
            id: string;
            name?: string | null;
            videoUrl?: string | null;
            mainImage?: {
                __typename?: 'ActivityMainImage';
                id: string;
                size: number;
                dimensions: Array<number>;
                url: string;
                previewUrl?: string | null;
            } | null;
            listImage?: {
                __typename?: 'ActivityListImage';
                id: string;
                size: number;
                dimensions: Array<number>;
                url: string;
                previewUrl?: string | null;
            } | null;
            images: Array<{
                __typename?: 'ActivityImage';
                id: string;
                size: number;
                url: string;
                dimensions: Array<number>;
                position: number;
                previewUrl?: string | null;
            }>;
        } | null;
    };
};

export type ActivityImageFragment = {
    __typename?: 'ActivityImage';
    id: string;
    size: number;
    url: string;
    dimensions: Array<number>;
    position: number;
    previewUrl?: string | null;
};

export type ActivityLocationFragment = {
    __typename?: 'Activity';
    id: string;
    slug: string;
    descriptionLocationPending?: string | null;
    descriptionLocationNL?: string | null;
    descriptionLocationDE?: string | null;
    descriptionLocationEN?: string | null;
    coordinates?: { __typename?: 'LatLon'; lat: number; lon: number } | null;
    locationAddress?: {
        __typename?: 'Address';
        city?: string | null;
        countryName?: string | null;
        number?: string | null;
        postalCode?: string | null;
        street?: string | null;
        countryCode?: string | null;
    } | null;
    company: { __typename?: 'Company'; coordinates?: { __typename?: 'LatLon'; lat: number; lon: number } | null };
};

export type ActivityLocationQueryVariables = Exact<{
    activitySlug: Scalars['String']['input'];
}>;

export type ActivityLocationQuery = {
    __typename?: 'PartnerQuery';
    activity?: {
        __typename?: 'Activity';
        id: string;
        slug: string;
        descriptionLocationPending?: string | null;
        descriptionLocationNL?: string | null;
        descriptionLocationDE?: string | null;
        descriptionLocationEN?: string | null;
        coordinates?: { __typename?: 'LatLon'; lat: number; lon: number } | null;
        locationAddress?: {
            __typename?: 'Address';
            city?: string | null;
            countryName?: string | null;
            number?: string | null;
            postalCode?: string | null;
            street?: string | null;
            countryCode?: string | null;
        } | null;
        company: { __typename?: 'Company'; coordinates?: { __typename?: 'LatLon'; lat: number; lon: number } | null };
    } | null;
};

export type EditActivityLocationMutationVariables = Exact<{
    input: EditActivityLocationInput;
}>;

export type EditActivityLocationMutation = {
    __typename?: 'PartnerMutation';
    editActivityLocation: {
        __typename?: 'EditActivityLocationPayload';
        activity?: {
            __typename?: 'Activity';
            id: string;
            slug: string;
            descriptionLocationPending?: string | null;
            descriptionLocationNL?: string | null;
            descriptionLocationDE?: string | null;
            descriptionLocationEN?: string | null;
            coordinates?: { __typename?: 'LatLon'; lat: number; lon: number } | null;
            locationAddress?: {
                __typename?: 'Address';
                city?: string | null;
                countryName?: string | null;
                number?: string | null;
                postalCode?: string | null;
                street?: string | null;
                countryCode?: string | null;
            } | null;
            company: {
                __typename?: 'Company';
                coordinates?: { __typename?: 'LatLon'; lat: number; lon: number } | null;
            };
        } | null;
    };
};

export type AgreementsQueryVariables = Exact<{
    activitySlug: Scalars['String']['input'];
}>;

export type AgreementsQuery = {
    __typename?: 'PartnerQuery';
    activity?: {
        __typename?: 'Activity';
        id: string;
        activityAgreements: Array<{
            __typename?: 'ActivityAgreement';
            id: string;
            startDate: any;
            endDate?: any | null;
            approvalDate?: any | null;
            agreement: {
                __typename?: 'Agreement';
                name: string;
                commissionPercentage: number;
                agreementUrl?: string | null;
            };
        }>;
    } | null;
};

export type AgreementsFragment = {
    __typename?: 'ActivityAgreement';
    id: string;
    startDate: any;
    endDate?: any | null;
    approvalDate?: any | null;
    agreement: { __typename?: 'Agreement'; name: string; commissionPercentage: number; agreementUrl?: string | null };
};

export type ApproveAgreementMutationVariables = Exact<{
    id: Scalars['ID']['input'];
}>;

export type ApproveAgreementMutation = {
    __typename?: 'PartnerMutation';
    approveActivityAgreement: {
        __typename?: 'ApproveActivityAgreementPayload';
        activityAgreement?: {
            __typename?: 'ActivityAgreement';
            id: string;
            startDate: any;
            endDate?: any | null;
            approvalDate?: any | null;
            agreement: {
                __typename?: 'Agreement';
                name: string;
                commissionPercentage: number;
                agreementUrl?: string | null;
            };
        } | null;
    };
};

export type TimeslotsWizardQueryVariables = Exact<{
    activitySlug: Scalars['String']['input'];
}>;

export type TimeslotsWizardQuery = {
    __typename?: 'PartnerQuery';
    activity?: {
        __typename?: 'Activity';
        id: string;
        activityRateGroups: Array<{
            __typename?: 'ActivityRateGroup';
            id: string;
            canBuyTickets: boolean;
            name?: string | null;
        }>;
    } | null;
};

export type SetActivityTimeslotsMutationVariables = Exact<{
    input: SetActivityTimeslotsInput;
}>;

export type SetActivityTimeslotsMutation = {
    __typename?: 'PartnerMutation';
    setActivityTimeslots: {
        __typename?: 'SetActivityTimeslotsPayload';
        activity?: {
            __typename?: 'Activity';
            id: string;
            activityRateGroups: Array<{
                __typename?: 'ActivityRateGroup';
                id: string;
                canBuyTickets: boolean;
                name?: string | null;
            }>;
        } | null;
    };
};

export type TimeslotsWizardActivityFragment = {
    __typename?: 'Activity';
    id: string;
    activityRateGroups: Array<{
        __typename?: 'ActivityRateGroup';
        id: string;
        canBuyTickets: boolean;
        name?: string | null;
    }>;
};

export type AllBookingsQueryVariables = Exact<{
    after?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    startDate?: InputMaybe<Scalars['Date']['input']>;
    endDate?: InputMaybe<Scalars['Date']['input']>;
    searchQuery?: InputMaybe<Scalars['String']['input']>;
    partnerId: Scalars['ID']['input'];
    rentalUnitIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type AllBookingsQuery = {
    __typename?: 'PartnerQuery';
    partner?: {
        __typename?: 'Partner';
        id: string;
        allBookings?: {
            __typename?: 'BookingConnection';
            totalCount: number;
            edges?: Array<{
                __typename?: 'BookingEdge';
                cursor: string;
                node?: {
                    __typename?: 'Booking';
                    id: string;
                    number?: string | null;
                    amountAdults: number;
                    amountYouths: number;
                    amountChildren: number;
                    amountBabies: number;
                    amountPets: number;
                    departureDate: any;
                    arrivalDate: any;
                    updatedAt: any;
                    customerComment?: string | null;
                    status: BookingStatusEnum;
                    duration: number;
                    totalPrice: number;
                    customer?: {
                        __typename?: 'Customer';
                        id: string;
                        firstName?: string | null;
                        lastName?: string | null;
                    } | null;
                    partnerPriceLines: Array<{
                        __typename?: 'BookingPriceLine';
                        category: PriceLineCategoryEnum;
                        totalPrice: number;
                    }>;
                    rentalUnit: {
                        __typename?: 'RentalUnit';
                        id: string;
                        name: string;
                        slug: string;
                        code: string;
                        thumbnailUrl?: string | null;
                        hideForPartner: boolean;
                    };
                } | null;
            } | null> | null;
            pageInfo: {
                __typename?: 'PageInfo';
                startCursor?: string | null;
                endCursor?: string | null;
                hasNextPage: boolean;
                hasPreviousPage: boolean;
            };
        } | null;
    } | null;
};

export type BookingDetailsFragment = {
    __typename?: 'Booking';
    id: string;
    number?: string | null;
    amountAdults: number;
    amountYouths: number;
    amountChildren: number;
    amountBabies: number;
    amountPets: number;
    departureDate: any;
    arrivalDate: any;
    createdAt: any;
    status: BookingStatusEnum;
    totalPrice: number;
    duration: number;
    customerComment?: string | null;
    customer?: {
        __typename?: 'Customer';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        birthdate?: any | null;
        phoneNumber?: string | null;
        email?: string | null;
        address: {
            __typename?: 'Address';
            city?: string | null;
            countryCode?: string | null;
            countryName?: string | null;
            number?: string | null;
            postalCode?: string | null;
            street?: string | null;
        };
    } | null;
    partnerPriceLines: Array<{
        __typename?: 'BookingPriceLine';
        totalPrice: number;
        label: string;
        modifier?: string | null;
        unitPrice?: number | null;
        category: PriceLineCategoryEnum;
    }>;
    rentalUnit: {
        __typename?: 'RentalUnit';
        id: string;
        name: string;
        slug: string;
        code: string;
        thumbnailUrl?: string | null;
        hideForPartner: boolean;
        accommodation: {
            __typename?: 'Accommodation';
            id: string;
            name: string;
            checkInStartTime?: any | null;
            checkInEndTime?: any | null;
            checkOutTime?: any | null;
        };
    };
};

export type CustomerDetailsFragment = {
    __typename?: 'Customer';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    birthdate?: any | null;
    phoneNumber?: string | null;
    email?: string | null;
    address: {
        __typename?: 'Address';
        city?: string | null;
        countryCode?: string | null;
        countryName?: string | null;
        number?: string | null;
        postalCode?: string | null;
        street?: string | null;
    };
};

export type BookingQueryVariables = Exact<{
    id: Scalars['ID']['input'];
}>;

export type BookingQuery = {
    __typename?: 'PartnerQuery';
    booking?: {
        __typename?: 'Booking';
        id: string;
        number?: string | null;
        amountAdults: number;
        amountYouths: number;
        amountChildren: number;
        amountBabies: number;
        amountPets: number;
        departureDate: any;
        arrivalDate: any;
        createdAt: any;
        status: BookingStatusEnum;
        totalPrice: number;
        duration: number;
        customerComment?: string | null;
        customer?: {
            __typename?: 'Customer';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            birthdate?: any | null;
            phoneNumber?: string | null;
            email?: string | null;
            address: {
                __typename?: 'Address';
                city?: string | null;
                countryCode?: string | null;
                countryName?: string | null;
                number?: string | null;
                postalCode?: string | null;
                street?: string | null;
            };
        } | null;
        partnerPriceLines: Array<{
            __typename?: 'BookingPriceLine';
            totalPrice: number;
            label: string;
            modifier?: string | null;
            unitPrice?: number | null;
            category: PriceLineCategoryEnum;
        }>;
        rentalUnit: {
            __typename?: 'RentalUnit';
            id: string;
            name: string;
            slug: string;
            code: string;
            thumbnailUrl?: string | null;
            hideForPartner: boolean;
            accommodation: {
                __typename?: 'Accommodation';
                id: string;
                name: string;
                checkInStartTime?: any | null;
                checkInEndTime?: any | null;
                checkOutTime?: any | null;
            };
        };
    } | null;
};

export type AcceptBookingMutationVariables = Exact<{
    input: AcceptBookingInput;
}>;

export type AcceptBookingMutation = {
    __typename?: 'PartnerMutation';
    acceptBooking: {
        __typename?: 'AcceptBookingPayload';
        booking?: {
            __typename?: 'Booking';
            id: string;
            number?: string | null;
            amountAdults: number;
            amountYouths: number;
            amountChildren: number;
            amountBabies: number;
            amountPets: number;
            departureDate: any;
            arrivalDate: any;
            createdAt: any;
            status: BookingStatusEnum;
            totalPrice: number;
            duration: number;
            customerComment?: string | null;
            customer?: {
                __typename?: 'Customer';
                id: string;
                firstName?: string | null;
                lastName?: string | null;
                birthdate?: any | null;
                phoneNumber?: string | null;
                email?: string | null;
                address: {
                    __typename?: 'Address';
                    city?: string | null;
                    countryCode?: string | null;
                    countryName?: string | null;
                    number?: string | null;
                    postalCode?: string | null;
                    street?: string | null;
                };
            } | null;
            partnerPriceLines: Array<{
                __typename?: 'BookingPriceLine';
                totalPrice: number;
                label: string;
                modifier?: string | null;
                unitPrice?: number | null;
                category: PriceLineCategoryEnum;
            }>;
            rentalUnit: {
                __typename?: 'RentalUnit';
                id: string;
                name: string;
                slug: string;
                code: string;
                thumbnailUrl?: string | null;
                hideForPartner: boolean;
                accommodation: {
                    __typename?: 'Accommodation';
                    id: string;
                    name: string;
                    checkInStartTime?: any | null;
                    checkInEndTime?: any | null;
                    checkOutTime?: any | null;
                };
            };
        } | null;
    };
};

export type RecentlyUpdatedBookingsQueryVariables = Exact<{
    partnerId: Scalars['ID']['input'];
    after?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}>;

export type RecentlyUpdatedBookingsQuery = {
    __typename?: 'PartnerQuery';
    partner?: {
        __typename?: 'Partner';
        id: string;
        recentlyUpdatedBookings?: {
            __typename?: 'BookingConnection';
            totalCount: number;
            edges?: Array<{
                __typename?: 'BookingEdge';
                cursor: string;
                node?: {
                    __typename?: 'Booking';
                    id: string;
                    number?: string | null;
                    amountAdults: number;
                    amountYouths: number;
                    amountChildren: number;
                    amountBabies: number;
                    amountPets: number;
                    departureDate: any;
                    arrivalDate: any;
                    updatedAt: any;
                    customerComment?: string | null;
                    status: BookingStatusEnum;
                    duration: number;
                    totalPrice: number;
                    customer?: {
                        __typename?: 'Customer';
                        id: string;
                        firstName?: string | null;
                        lastName?: string | null;
                    } | null;
                    partnerPriceLines: Array<{
                        __typename?: 'BookingPriceLine';
                        category: PriceLineCategoryEnum;
                        totalPrice: number;
                    }>;
                    rentalUnit: {
                        __typename?: 'RentalUnit';
                        id: string;
                        name: string;
                        slug: string;
                        code: string;
                        thumbnailUrl?: string | null;
                        hideForPartner: boolean;
                    };
                } | null;
            } | null> | null;
            pageInfo: {
                __typename?: 'PageInfo';
                startCursor?: string | null;
                endCursor?: string | null;
                hasNextPage: boolean;
                hasPreviousPage: boolean;
            };
        } | null;
    } | null;
};

export type UpcomingBookingsQueryVariables = Exact<{
    partnerId: Scalars['ID']['input'];
    after?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
}>;

export type UpcomingBookingsQuery = {
    __typename?: 'PartnerQuery';
    partner?: {
        __typename?: 'Partner';
        id: string;
        upcomingBookings?: {
            __typename?: 'BookingConnection';
            totalCount: number;
            edges?: Array<{
                __typename?: 'BookingEdge';
                cursor: string;
                node?: {
                    __typename?: 'Booking';
                    id: string;
                    number?: string | null;
                    amountAdults: number;
                    amountYouths: number;
                    amountChildren: number;
                    amountBabies: number;
                    amountPets: number;
                    departureDate: any;
                    arrivalDate: any;
                    updatedAt: any;
                    customerComment?: string | null;
                    status: BookingStatusEnum;
                    duration: number;
                    totalPrice: number;
                    customer?: {
                        __typename?: 'Customer';
                        id: string;
                        firstName?: string | null;
                        lastName?: string | null;
                    } | null;
                    partnerPriceLines: Array<{
                        __typename?: 'BookingPriceLine';
                        category: PriceLineCategoryEnum;
                        totalPrice: number;
                    }>;
                    rentalUnit: {
                        __typename?: 'RentalUnit';
                        id: string;
                        name: string;
                        slug: string;
                        code: string;
                        thumbnailUrl?: string | null;
                        hideForPartner: boolean;
                    };
                } | null;
            } | null> | null;
            pageInfo: {
                __typename?: 'PageInfo';
                startCursor?: string | null;
                endCursor?: string | null;
                hasNextPage: boolean;
                hasPreviousPage: boolean;
            };
        } | null;
    } | null;
};

export type CompanyInfoFragment = { __typename?: 'Company'; id: string; slug: string; name: string; enabled: boolean };

export type CompanyRatesQueryVariables = Exact<{
    companySlug: Scalars['String']['input'];
}>;

export type CompanyRatesQuery = {
    __typename?: 'PartnerQuery';
    company?: {
        __typename?: 'Company';
        id: string;
        companyRateGroups: Array<{
            __typename?: 'CompanyRateGroup';
            id: string;
            startDate?: any | null;
            endDate?: any | null;
            canBuyTickets: boolean;
            rates: Array<{
                __typename?: 'Rate';
                id: string;
                name?: string | null;
                price: number;
                position: number;
                deName?: string | null;
                enName?: string | null;
            }>;
        }>;
    } | null;
};

export type UpsertCompanyRateGroupMutationVariables = Exact<{
    input: UpsertCompanyRateGroupInput;
}>;

export type UpsertCompanyRateGroupMutation = {
    __typename?: 'PartnerMutation';
    upsertCompanyRateGroup: {
        __typename?: 'UpsertCompanyRateGroupPayload';
        company?: {
            __typename?: 'Company';
            id: string;
            companyRateGroups: Array<{
                __typename?: 'CompanyRateGroup';
                id: string;
                startDate?: any | null;
                endDate?: any | null;
                canBuyTickets: boolean;
                rates: Array<{
                    __typename?: 'Rate';
                    id: string;
                    name?: string | null;
                    price: number;
                    position: number;
                    deName?: string | null;
                    enName?: string | null;
                }>;
            }>;
        } | null;
    };
};

export type DeleteCompanyRateGroupMutationVariables = Exact<{
    input: DeleteCompanyRateGroupInput;
}>;

export type DeleteCompanyRateGroupMutation = {
    __typename?: 'PartnerMutation';
    deleteCompanyRateGroup: {
        __typename?: 'DeleteCompanyRateGroupPayload';
        company?: {
            __typename?: 'Company';
            id: string;
            companyRateGroups: Array<{
                __typename?: 'CompanyRateGroup';
                id: string;
                startDate?: any | null;
                endDate?: any | null;
                canBuyTickets: boolean;
                rates: Array<{
                    __typename?: 'Rate';
                    id: string;
                    name?: string | null;
                    price: number;
                    position: number;
                    deName?: string | null;
                    enName?: string | null;
                }>;
            }>;
        } | null;
    };
};

export type CompanyRatesFragment = {
    __typename?: 'Company';
    id: string;
    companyRateGroups: Array<{
        __typename?: 'CompanyRateGroup';
        id: string;
        startDate?: any | null;
        endDate?: any | null;
        canBuyTickets: boolean;
        rates: Array<{
            __typename?: 'Rate';
            id: string;
            name?: string | null;
            price: number;
            position: number;
            deName?: string | null;
            enName?: string | null;
        }>;
    }>;
};

export type CompanyRateGroupFragment = {
    __typename?: 'CompanyRateGroup';
    id: string;
    startDate?: any | null;
    endDate?: any | null;
    canBuyTickets: boolean;
    rates: Array<{
        __typename?: 'Rate';
        id: string;
        name?: string | null;
        price: number;
        position: number;
        deName?: string | null;
        enName?: string | null;
    }>;
};

export type RateFragment = {
    __typename?: 'Rate';
    id: string;
    name?: string | null;
    price: number;
    position: number;
    deName?: string | null;
    enName?: string | null;
};

export type CompanyHoursQueryVariables = Exact<{
    companySlug: Scalars['String']['input'];
}>;

export type CompanyHoursQuery = {
    __typename?: 'PartnerQuery';
    company?: {
        __typename?: 'Company';
        id: string;
        normalOperatingHours?: {
            __typename?: 'OperatingHours';
            startDate?: any | null;
            endDate?: any | null;
            monday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
            tuesday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
            wednesday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
            thursday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
            friday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
            saturday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
            sunday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
        } | null;
    } | null;
};

export type EditCompanyOperatingHoursMutationVariables = Exact<{
    input: EditCompanyNormalOperatingHoursInput;
}>;

export type EditCompanyOperatingHoursMutation = {
    __typename?: 'PartnerMutation';
    editCompanyNormalOperatingHours: {
        __typename?: 'EditCompanyNormalOperatingHoursPayload';
        company?: {
            __typename?: 'Company';
            id: string;
            normalOperatingHours?: {
                __typename?: 'OperatingHours';
                startDate?: any | null;
                endDate?: any | null;
                monday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
                tuesday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
                wednesday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
                thursday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
                friday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
                saturday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
                sunday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
            } | null;
        } | null;
    };
};

export type CompanyHoursFragment = {
    __typename?: 'Company';
    id: string;
    normalOperatingHours?: {
        __typename?: 'OperatingHours';
        startDate?: any | null;
        endDate?: any | null;
        monday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
        tuesday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
        wednesday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
        thursday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
        friday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
        saturday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
        sunday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
    } | null;
};

export type OperatingHoursFragment = {
    __typename?: 'OperatingHours';
    startDate?: any | null;
    endDate?: any | null;
    monday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
    tuesday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
    wednesday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
    thursday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
    friday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
    saturday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
    sunday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
};

export type TimesOpenClosedFragment = { __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any };

export type CompanySpecialHoursQueryVariables = Exact<{
    companySlug: Scalars['String']['input'];
}>;

export type CompanySpecialHoursQuery = {
    __typename?: 'PartnerQuery';
    company?: {
        __typename?: 'Company';
        id: string;
        specialOperatingHours: Array<{
            __typename?: 'OperatingHours';
            startDate?: any | null;
            endDate?: any | null;
            monday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
            tuesday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
            wednesday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
            thursday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
            friday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
            saturday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
            sunday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
        }>;
    } | null;
};

export type EditCompanySpecialOperatingHoursMutationVariables = Exact<{
    input: EditCompanySpecialOperatingHoursInput;
}>;

export type EditCompanySpecialOperatingHoursMutation = {
    __typename?: 'PartnerMutation';
    editCompanySpecialOperatingHours: {
        __typename?: 'EditCompanySpecialOperatingHoursPayload';
        company?: {
            __typename?: 'Company';
            id: string;
            specialOperatingHours: Array<{
                __typename?: 'OperatingHours';
                startDate?: any | null;
                endDate?: any | null;
                monday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
                tuesday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
                wednesday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
                thursday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
                friday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
                saturday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
                sunday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
            }>;
        } | null;
    };
};

export type CompanySpecialHoursFragment = {
    __typename?: 'Company';
    id: string;
    specialOperatingHours: Array<{
        __typename?: 'OperatingHours';
        startDate?: any | null;
        endDate?: any | null;
        monday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
        tuesday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
        wednesday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
        thursday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
        friday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
        saturday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
        sunday?: Array<{ __typename?: 'TimeOpenClosed'; timeOpen: any; timeClosed: any }> | null;
    }>;
};

export type CompanyDescriptionFragment = {
    __typename?: 'Company';
    id: string;
    descriptionPending?: string | null;
    descriptionParticularsPending?: string | null;
    descriptionNL?: string | null;
    descriptionDE?: string | null;
    descriptionEN?: string | null;
    descriptionParticularsNL?: string | null;
    descriptionParticularsDE?: string | null;
    descriptionParticularsEN?: string | null;
};

export type CompanyDescriptionQueryVariables = Exact<{
    companySlug: Scalars['String']['input'];
}>;

export type CompanyDescriptionQuery = {
    __typename?: 'PartnerQuery';
    company?: {
        __typename?: 'Company';
        id: string;
        descriptionPending?: string | null;
        descriptionParticularsPending?: string | null;
        descriptionNL?: string | null;
        descriptionDE?: string | null;
        descriptionEN?: string | null;
        descriptionParticularsNL?: string | null;
        descriptionParticularsDE?: string | null;
        descriptionParticularsEN?: string | null;
    } | null;
};

export type EditCompanyDescriptionsMutationVariables = Exact<{
    input: EditCompanyDescriptionInput;
}>;

export type EditCompanyDescriptionsMutation = {
    __typename?: 'PartnerMutation';
    editCompanyDescription: {
        __typename?: 'EditCompanyDescriptionPayload';
        company?: {
            __typename?: 'Company';
            id: string;
            descriptionPending?: string | null;
            descriptionParticularsPending?: string | null;
            descriptionNL?: string | null;
            descriptionDE?: string | null;
            descriptionEN?: string | null;
            descriptionParticularsNL?: string | null;
            descriptionParticularsDE?: string | null;
            descriptionParticularsEN?: string | null;
        } | null;
    };
};

export type CompanyInfoQueryVariables = Exact<{
    companySlug: Scalars['String']['input'];
}>;

export type CompanyInfoQuery = {
    __typename?: 'PartnerQuery';
    company?: {
        __typename?: 'Company';
        id: string;
        name: string;
        email?: string | null;
        phoneNumber?: string | null;
        websiteUrl?: string | null;
        targetAudiences?: Array<TargetAudienceEnum> | null;
        socialsTwitterUrl?: string | null;
        socialsInstagramUrl?: string | null;
        socialsFacebookUrl?: string | null;
        categories: Array<{ __typename?: 'CompanyCategory'; nameNL?: string | null }>;
        reservations?: {
            __typename?: 'ContactInfo';
            contactName?: string | null;
            phoneNumbers: Array<string>;
            emails: Array<string>;
        } | null;
    } | null;
};

export type EditCompanyInfoMutationVariables = Exact<{
    input: EditCompanyInfoInput;
}>;

export type EditCompanyInfoMutation = {
    __typename?: 'PartnerMutation';
    editCompanyInfo: {
        __typename?: 'EditCompanyInfoPayload';
        company?: {
            __typename?: 'Company';
            id: string;
            name: string;
            email?: string | null;
            phoneNumber?: string | null;
            websiteUrl?: string | null;
            targetAudiences?: Array<TargetAudienceEnum> | null;
            socialsTwitterUrl?: string | null;
            socialsInstagramUrl?: string | null;
            socialsFacebookUrl?: string | null;
            categories: Array<{ __typename?: 'CompanyCategory'; nameNL?: string | null }>;
            reservations?: {
                __typename?: 'ContactInfo';
                contactName?: string | null;
                phoneNumbers: Array<string>;
                emails: Array<string>;
            } | null;
        } | null;
    };
};

export type CompanyDetailsFragment = {
    __typename?: 'Company';
    id: string;
    name: string;
    email?: string | null;
    phoneNumber?: string | null;
    websiteUrl?: string | null;
    targetAudiences?: Array<TargetAudienceEnum> | null;
    socialsTwitterUrl?: string | null;
    socialsInstagramUrl?: string | null;
    socialsFacebookUrl?: string | null;
    categories: Array<{ __typename?: 'CompanyCategory'; nameNL?: string | null }>;
    reservations?: {
        __typename?: 'ContactInfo';
        contactName?: string | null;
        phoneNumbers: Array<string>;
        emails: Array<string>;
    } | null;
};

export type CompanyImagesFragment = {
    __typename?: 'Company';
    id: string;
    name: string;
    videoUrl?: string | null;
    mainImage?: {
        __typename?: 'CompanyMainImage';
        id: string;
        size: number;
        dimensions: Array<number>;
        url: string;
        previewUrl?: string | null;
    } | null;
    logoImage?: {
        __typename?: 'CompanyLogoImage';
        id: string;
        size: number;
        dimensions: Array<number>;
        url: string;
        previewUrl?: string | null;
    } | null;
    listImage?: {
        __typename?: 'CompanyListImage';
        id: string;
        size: number;
        dimensions: Array<number>;
        url: string;
        previewUrl?: string | null;
    } | null;
    images: Array<{
        __typename?: 'CompanyImage';
        id: string;
        size: number;
        url: string;
        dimensions: Array<number>;
        position: number;
        previewUrl?: string | null;
    }>;
};

export type CompanyImagesQueryVariables = Exact<{
    companySlug: Scalars['String']['input'];
}>;

export type CompanyImagesQuery = {
    __typename?: 'PartnerQuery';
    company?: {
        __typename?: 'Company';
        id: string;
        name: string;
        videoUrl?: string | null;
        mainImage?: {
            __typename?: 'CompanyMainImage';
            id: string;
            size: number;
            dimensions: Array<number>;
            url: string;
            previewUrl?: string | null;
        } | null;
        logoImage?: {
            __typename?: 'CompanyLogoImage';
            id: string;
            size: number;
            dimensions: Array<number>;
            url: string;
            previewUrl?: string | null;
        } | null;
        listImage?: {
            __typename?: 'CompanyListImage';
            id: string;
            size: number;
            dimensions: Array<number>;
            url: string;
            previewUrl?: string | null;
        } | null;
        images: Array<{
            __typename?: 'CompanyImage';
            id: string;
            size: number;
            url: string;
            dimensions: Array<number>;
            position: number;
            previewUrl?: string | null;
        }>;
    } | null;
};

export type EditCompanyImagesMutationVariables = Exact<{
    input: EditCompanyImagesInput;
}>;

export type EditCompanyImagesMutation = {
    __typename?: 'PartnerMutation';
    editCompanyImages: {
        __typename?: 'EditCompanyImagesPayload';
        company?: {
            __typename?: 'Company';
            id: string;
            name: string;
            videoUrl?: string | null;
            mainImage?: {
                __typename?: 'CompanyMainImage';
                id: string;
                size: number;
                dimensions: Array<number>;
                url: string;
                previewUrl?: string | null;
            } | null;
            logoImage?: {
                __typename?: 'CompanyLogoImage';
                id: string;
                size: number;
                dimensions: Array<number>;
                url: string;
                previewUrl?: string | null;
            } | null;
            listImage?: {
                __typename?: 'CompanyListImage';
                id: string;
                size: number;
                dimensions: Array<number>;
                url: string;
                previewUrl?: string | null;
            } | null;
            images: Array<{
                __typename?: 'CompanyImage';
                id: string;
                size: number;
                url: string;
                dimensions: Array<number>;
                position: number;
                previewUrl?: string | null;
            }>;
        } | null;
    };
};

export type CompanyImageFragment = {
    __typename?: 'CompanyImage';
    id: string;
    size: number;
    url: string;
    dimensions: Array<number>;
    position: number;
    previewUrl?: string | null;
};

export type CompanyLocationFragment = {
    __typename?: 'Company';
    id: string;
    slug: string;
    address?: {
        __typename?: 'Address';
        city?: string | null;
        countryName?: string | null;
        number?: string | null;
        postalCode?: string | null;
        street?: string | null;
        countryCode?: string | null;
    } | null;
    coordinates?: { __typename?: 'LatLon'; lat: number; lon: number } | null;
};

export type CompanyLocationQueryVariables = Exact<{
    companySlug: Scalars['String']['input'];
}>;

export type CompanyLocationQuery = {
    __typename?: 'PartnerQuery';
    company?: {
        __typename?: 'Company';
        id: string;
        slug: string;
        address?: {
            __typename?: 'Address';
            city?: string | null;
            countryName?: string | null;
            number?: string | null;
            postalCode?: string | null;
            street?: string | null;
            countryCode?: string | null;
        } | null;
        coordinates?: { __typename?: 'LatLon'; lat: number; lon: number } | null;
    } | null;
};

export type EditCompanyLocationMutationVariables = Exact<{
    input: EditCompanyLocationInput;
}>;

export type EditCompanyLocationMutation = {
    __typename?: 'PartnerMutation';
    editCompanyLocation: {
        __typename?: 'EditCompanyLocationPayload';
        company?: {
            __typename?: 'Company';
            id: string;
            slug: string;
            address?: {
                __typename?: 'Address';
                city?: string | null;
                countryName?: string | null;
                number?: string | null;
                postalCode?: string | null;
                street?: string | null;
                countryCode?: string | null;
            } | null;
            coordinates?: { __typename?: 'LatLon'; lat: number; lon: number } | null;
        } | null;
    };
};

export type PartnerPickerQueryVariables = Exact<{ [key: string]: never }>;

export type PartnerPickerQuery = {
    __typename?: 'PartnerQuery';
    viewer?: {
        __typename?: 'User';
        id: string;
        isBackofficeUser: boolean;
        partners: Array<{
            __typename?: 'Partner';
            id: string;
            name: string;
            contact: { __typename?: 'ContactInfo'; contactName?: string | null };
        }>;
    } | null;
};

export type ArchiveQueryVariables = Exact<{
    partnerId: Scalars['ID']['input'];
}>;

export type ArchiveQuery = {
    __typename?: 'PartnerQuery';
    partner?: {
        __typename?: 'Partner';
        id: string;
        accommodations: Array<{
            __typename?: 'Accommodation';
            id: string;
            rentalUnits: Array<{
                __typename?: 'RentalUnit';
                id: string;
                name: string;
                slug: string;
                code: string;
                thumbnailUrl?: string | null;
                hideForPartner: boolean;
            }>;
        }>;
        companies: Array<{
            __typename?: 'Company';
            id: string;
            activities: Array<{
                __typename?: 'Activity';
                id: string;
                name?: string | null;
                slug: string;
                hideForPartner: boolean;
                company: { __typename?: 'Company'; id: string; name: string };
                listImage?: { __typename?: 'ActivityListImage'; url: string } | null;
            }>;
        }>;
    } | null;
};

export type PartnerProfileQueryVariables = Exact<{
    id: Scalars['ID']['input'];
}>;

export type PartnerProfileQuery = {
    __typename?: 'PartnerQuery';
    partner?: {
        __typename?: 'Partner';
        id: string;
        name: string;
        invoiceEmails: Array<string>;
        bankAccountActive: boolean;
        vatNumber?: string | null;
        contactSalutation?: string | null;
        ibanName?: string | null;
        iban?: string | null;
        bic?: string | null;
        paymentByDirectDebit: boolean;
        address?: {
            __typename?: 'Address';
            city?: string | null;
            countryName?: string | null;
            number?: string | null;
            postalCode?: string | null;
            street?: string | null;
            countryCode?: string | null;
        } | null;
        invoiceAddress?: {
            __typename?: 'Address';
            city?: string | null;
            countryName?: string | null;
            number?: string | null;
            postalCode?: string | null;
            street?: string | null;
            countryCode?: string | null;
        } | null;
        contact: {
            __typename?: 'ContactInfo';
            contactName?: string | null;
            phoneNumbers: Array<string>;
            emails: Array<string>;
        };
    } | null;
};

export type AddressFragment = {
    __typename?: 'Address';
    city?: string | null;
    countryName?: string | null;
    number?: string | null;
    postalCode?: string | null;
    street?: string | null;
    countryCode?: string | null;
};

export type ReviewQueryVariables = Exact<{
    id: Scalars['ID']['input'];
}>;

export type ReviewQuery = {
    __typename?: 'PartnerQuery';
    review?: {
        __typename?: 'Review';
        id: string;
        createdAt: any;
        title: string;
        comment?: string | null;
        tips?: string | null;
        average: number;
        ratingLocation: number;
        ratingHospitality: number;
        ratingLayout: number;
        ratingMaintenance: number;
        ratingValueForMoney: number;
        city?: string | null;
        enabled: boolean;
        language: string;
        reply?: string | null;
        rentalUnit: {
            __typename?: 'RentalUnit';
            id: string;
            name: string;
            slug: string;
            code: string;
            thumbnailUrl?: string | null;
            hideForPartner: boolean;
            accommodation: { __typename?: 'Accommodation'; id: string; name: string };
        };
    } | null;
};

export type AddReplyMutationVariables = Exact<{
    reviewId: Scalars['ID']['input'];
    reply: Scalars['String']['input'];
}>;

export type AddReplyMutation = {
    __typename?: 'PartnerMutation';
    editReviewReply: {
        __typename?: 'EditReviewReplyPayload';
        review?: { __typename?: 'Review'; id: string; reply?: string | null } | null;
    };
};

export type AllTicketsQueryVariables = Exact<{
    partnerId: Scalars['ID']['input'];
    after?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    timeslotId?: InputMaybe<Scalars['ID']['input']>;
    activityIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
    companyId?: InputMaybe<Scalars['ID']['input']>;
    startDate?: InputMaybe<Scalars['Date']['input']>;
    endDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export type AllTicketsQuery = {
    __typename?: 'PartnerQuery';
    partner?: {
        __typename?: 'Partner';
        id: string;
        allTickets?: {
            __typename?: 'TicketConnection';
            totalCount: number;
            edges?: Array<{
                __typename?: 'TicketEdge';
                cursor: string;
                node?: {
                    __typename?: 'Ticket';
                    id: string;
                    createdAt: any;
                    status: TicketStatusEnum;
                    customer?: {
                        __typename?: 'Customer';
                        id: string;
                        firstName?: string | null;
                        lastName?: string | null;
                    } | null;
                    priceLines: Array<{
                        __typename?: 'TicketPriceLine';
                        label: string;
                        quantity: number;
                        totalPrice: number;
                    }>;
                    rateLines: Array<{
                        __typename?: 'TicketRateLine';
                        rateName?: string | null;
                        amount: number;
                        unitPrice: number;
                    }>;
                    timeslot: {
                        __typename?: 'Timeslot';
                        id: string;
                        startDateTime: any;
                        endDateTime: any;
                        activity: {
                            __typename?: 'Activity';
                            id: string;
                            name?: string | null;
                            slug: string;
                            hideForPartner: boolean;
                            company: { __typename?: 'Company'; id: string; name: string };
                            listImage?: { __typename?: 'ActivityListImage'; url: string } | null;
                        };
                        rateGroup:
                            | {
                                  __typename?: 'ActivityRateGroup';
                                  id: string;
                                  name?: string | null;
                                  rates: Array<{ __typename?: 'Rate'; id: string; name?: string | null }>;
                              }
                            | {
                                  __typename?: 'CompanyRateGroup';
                                  id: string;
                                  name?: string | null;
                                  rates: Array<{ __typename?: 'Rate'; id: string; name?: string | null }>;
                              };
                    };
                } | null;
            } | null> | null;
            pageInfo: {
                __typename?: 'PageInfo';
                startCursor?: string | null;
                endCursor?: string | null;
                hasNextPage: boolean;
                hasPreviousPage: boolean;
            };
        } | null;
    } | null;
};

export type TicketConnectionFragment = {
    __typename?: 'TicketConnection';
    totalCount: number;
    edges?: Array<{
        __typename?: 'TicketEdge';
        cursor: string;
        node?: {
            __typename?: 'Ticket';
            id: string;
            createdAt: any;
            status: TicketStatusEnum;
            customer?: {
                __typename?: 'Customer';
                id: string;
                firstName?: string | null;
                lastName?: string | null;
            } | null;
            priceLines: Array<{ __typename?: 'TicketPriceLine'; label: string; quantity: number; totalPrice: number }>;
            rateLines: Array<{
                __typename?: 'TicketRateLine';
                rateName?: string | null;
                amount: number;
                unitPrice: number;
            }>;
            timeslot: {
                __typename?: 'Timeslot';
                id: string;
                startDateTime: any;
                endDateTime: any;
                activity: {
                    __typename?: 'Activity';
                    id: string;
                    name?: string | null;
                    slug: string;
                    hideForPartner: boolean;
                    company: { __typename?: 'Company'; id: string; name: string };
                    listImage?: { __typename?: 'ActivityListImage'; url: string } | null;
                };
                rateGroup:
                    | {
                          __typename?: 'ActivityRateGroup';
                          id: string;
                          name?: string | null;
                          rates: Array<{ __typename?: 'Rate'; id: string; name?: string | null }>;
                      }
                    | {
                          __typename?: 'CompanyRateGroup';
                          id: string;
                          name?: string | null;
                          rates: Array<{ __typename?: 'Rate'; id: string; name?: string | null }>;
                      };
            };
        } | null;
    } | null> | null;
    pageInfo: {
        __typename?: 'PageInfo';
        startCursor?: string | null;
        endCursor?: string | null;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
    };
};

export type TicketQueryVariables = Exact<{
    id: Scalars['ID']['input'];
}>;

export type TicketQuery = {
    __typename?: 'PartnerQuery';
    ticket?: {
        __typename?: 'Ticket';
        id: string;
        createdAt: any;
        status: TicketStatusEnum;
        timeslot: {
            __typename?: 'Timeslot';
            id: string;
            startDateTime: any;
            endDateTime: any;
            activity: {
                __typename?: 'Activity';
                id: string;
                name?: string | null;
                slug: string;
                hideForPartner: boolean;
                company: { __typename?: 'Company'; id: string; name: string };
                listImage?: { __typename?: 'ActivityListImage'; url: string } | null;
            };
        };
        rateLines: Array<{
            __typename?: 'TicketRateLine';
            rateName?: string | null;
            amount: number;
            unitPrice: number;
            qrCodes?: Array<{
                __typename?: 'TicketQrCode';
                code: string;
                number: number;
                scannedAt?: any | null;
                scannedBy?: string | null;
            }> | null;
        }>;
        priceLines: Array<{
            __typename?: 'TicketPriceLine';
            label: string;
            quantity: number;
            totalPrice: number;
            unitPrice?: number | null;
        }>;
        customer?: {
            __typename?: 'Customer';
            id: string;
            firstName?: string | null;
            lastName?: string | null;
            birthdate?: any | null;
            phoneNumber?: string | null;
            email?: string | null;
            address: {
                __typename?: 'Address';
                city?: string | null;
                countryCode?: string | null;
                countryName?: string | null;
                number?: string | null;
                postalCode?: string | null;
                street?: string | null;
            };
        } | null;
    } | null;
};

export type NewTicketsQueryVariables = Exact<{
    partnerId: Scalars['ID']['input'];
    after?: InputMaybe<Scalars['String']['input']>;
    first?: InputMaybe<Scalars['Int']['input']>;
    before?: InputMaybe<Scalars['String']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    timeslotId?: InputMaybe<Scalars['ID']['input']>;
    activityIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
    companyId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type NewTicketsQuery = {
    __typename?: 'PartnerQuery';
    partner?: {
        __typename?: 'Partner';
        id: string;
        newTickets?: {
            __typename?: 'TicketConnection';
            totalCount: number;
            edges?: Array<{
                __typename?: 'TicketEdge';
                cursor: string;
                node?: {
                    __typename?: 'Ticket';
                    id: string;
                    createdAt: any;
                    status: TicketStatusEnum;
                    customer?: {
                        __typename?: 'Customer';
                        id: string;
                        firstName?: string | null;
                        lastName?: string | null;
                    } | null;
                    priceLines: Array<{
                        __typename?: 'TicketPriceLine';
                        label: string;
                        quantity: number;
                        totalPrice: number;
                    }>;
                    rateLines: Array<{
                        __typename?: 'TicketRateLine';
                        rateName?: string | null;
                        amount: number;
                        unitPrice: number;
                    }>;
                    timeslot: {
                        __typename?: 'Timeslot';
                        id: string;
                        startDateTime: any;
                        endDateTime: any;
                        activity: {
                            __typename?: 'Activity';
                            id: string;
                            name?: string | null;
                            slug: string;
                            hideForPartner: boolean;
                            company: { __typename?: 'Company'; id: string; name: string };
                            listImage?: { __typename?: 'ActivityListImage'; url: string } | null;
                        };
                        rateGroup:
                            | {
                                  __typename?: 'ActivityRateGroup';
                                  id: string;
                                  name?: string | null;
                                  rates: Array<{ __typename?: 'Rate'; id: string; name?: string | null }>;
                              }
                            | {
                                  __typename?: 'CompanyRateGroup';
                                  id: string;
                                  name?: string | null;
                                  rates: Array<{ __typename?: 'Rate'; id: string; name?: string | null }>;
                              };
                    };
                } | null;
            } | null> | null;
            pageInfo: {
                __typename?: 'PageInfo';
                startCursor?: string | null;
                endCursor?: string | null;
                hasNextPage: boolean;
                hasPreviousPage: boolean;
            };
        } | null;
    } | null;
};

export type AvailabilityQueryVariables = Exact<{
    unitSlug: Scalars['String']['input'];
    start: Scalars['Date']['input'];
    allotmentStart: Scalars['Date']['input'];
    end: Scalars['Date']['input'];
}>;

export type AvailabilityQuery = {
    __typename?: 'PartnerQuery';
    rentalUnit?: {
        __typename?: 'RentalUnit';
        id: string;
        showAllotmentLockouts: boolean;
        datePricingStartDate?: any | null;
        datePricingEndDate?: any | null;
        maxAllotment: number;
        lastDatePricingDate?: any | null;
        showArrivalAllowedRow: boolean;
        showBaseStayPriceRow: boolean;
        showDepartureAllowedRow: boolean;
        showExtraPersonPriceRow: boolean;
        showMinimumStayDurationRow: boolean;
        showMinimumStayPriceRow: boolean;
        showNightPriceRow: boolean;
        showWeekPriceRow: boolean;
        allotments: Array<{ __typename?: 'Allotment'; amount: number; id: string; date: any }>;
        bookings: Array<{
            __typename?: 'Booking';
            id: string;
            totalPrice: number;
            status: BookingStatusEnum;
            isActive: boolean;
            isHovering: boolean;
            startDate: any;
            endDate: any;
            customer?: {
                __typename?: 'Customer';
                id: string;
                firstName?: string | null;
                lastName?: string | null;
            } | null;
        }>;
        allotmentLockouts: Array<{
            __typename?: 'AllotmentLockout';
            id: string;
            type: AllotmentLockoutTypeEnum;
            comment?: string | null;
            source?: string | null;
            startDate: any;
            endDate: any;
            isDragging: boolean;
            isActive: boolean;
            isHovering: boolean;
        }>;
    } | null;
};

export type AvailabilityMetaDataFragment = {
    __typename?: 'RentalUnit';
    showAllotmentLockouts: boolean;
    datePricingStartDate?: any | null;
    datePricingEndDate?: any | null;
    maxAllotment: number;
    lastDatePricingDate?: any | null;
};

export type AccommodationInfoFragment = {
    __typename?: 'Accommodation';
    id: string;
    slug: string;
    name: string;
    type: AccommodationTypeEnum;
    hasPublicPage: boolean;
    rentalUnits: Array<{ __typename?: 'RentalUnit'; id: string; type: RentalUnitTypeEnum }>;
};

export type RentalUnitInfoFragment = {
    __typename?: 'RentalUnit';
    id: string;
    slug: string;
    type: RentalUnitTypeEnum;
    datePricingStartDate?: any | null;
    name: string;
    showAllotmentLockouts: boolean;
};

export type PricesQueryVariables = Exact<{
    unitSlug: Scalars['String']['input'];
    start: Scalars['Date']['input'];
    end: Scalars['Date']['input'];
}>;

export type PricesQuery = {
    __typename?: 'PartnerQuery';
    rentalUnit?: {
        __typename?: 'RentalUnit';
        id: string;
        showArrivalAllowedRow: boolean;
        showBaseStayPriceRow: boolean;
        showDepartureAllowedRow: boolean;
        showExtraPersonPriceRow: boolean;
        showMinimumStayDurationRow: boolean;
        showMinimumStayPriceRow: boolean;
        showNightPriceRow: boolean;
        showWeekPriceRow: boolean;
        datePricings: Array<{
            __typename?: 'DatePricing';
            id: string;
            date: any;
            arrivalAllowed: boolean;
            departureAllowed: boolean;
            nightPrice: number;
            extraPersonPrice: number;
            minimumStayDuration: number;
            weekPrice?: number | null;
            baseStayPrice: number;
            minimumStayPrice: number;
        }>;
        datePricingModifiers: Array<{
            __typename?: 'DatePricingModifier';
            id: string;
            startDate: any;
            endDate: any;
            minDuration: number;
            maxDuration: number;
            type: DatePricingModifierTypeEnum;
            value: number;
            valueType: DatePricingModifierValueTypeEnum;
        }>;
        allotments: Array<{ __typename?: 'Allotment'; amount: number; id: string; date: any }>;
        bookings: Array<{
            __typename?: 'Booking';
            id: string;
            totalPrice: number;
            status: BookingStatusEnum;
            isActive: boolean;
            isHovering: boolean;
            startDate: any;
            endDate: any;
            customer?: {
                __typename?: 'Customer';
                id: string;
                firstName?: string | null;
                lastName?: string | null;
            } | null;
        }>;
        allotmentLockouts: Array<{
            __typename?: 'AllotmentLockout';
            id: string;
            type: AllotmentLockoutTypeEnum;
            comment?: string | null;
            source?: string | null;
            startDate: any;
            endDate: any;
            isDragging: boolean;
            isActive: boolean;
            isHovering: boolean;
        }>;
    } | null;
};

export type PricesMetaDataQueryVariables = Exact<{
    unitSlug: Scalars['String']['input'];
}>;

export type PricesMetaDataQuery = {
    __typename?: 'PartnerQuery';
    rentalUnit?: {
        __typename?: 'RentalUnit';
        showArrivalAllowedRow: boolean;
        showBaseStayPriceRow: boolean;
        showDepartureAllowedRow: boolean;
        showExtraPersonPriceRow: boolean;
        showMinimumStayDurationRow: boolean;
        showMinimumStayPriceRow: boolean;
        showNightPriceRow: boolean;
        showWeekPriceRow: boolean;
    } | null;
};

export type PricesRowVisibilityFragment = {
    __typename?: 'RentalUnit';
    showArrivalAllowedRow: boolean;
    showBaseStayPriceRow: boolean;
    showDepartureAllowedRow: boolean;
    showExtraPersonPriceRow: boolean;
    showMinimumStayDurationRow: boolean;
    showMinimumStayPriceRow: boolean;
    showNightPriceRow: boolean;
    showWeekPriceRow: boolean;
};

export type SpecialParticipationsQueryVariables = Exact<{
    unitSlug: Scalars['String']['input'];
    start: Scalars['Date']['input'];
    end: Scalars['Date']['input'];
}>;

export type SpecialParticipationsQuery = {
    __typename?: 'PartnerQuery';
    rentalUnit?: {
        __typename?: 'RentalUnit';
        id: string;
        showArrivalAllowedRow: boolean;
        showBaseStayPriceRow: boolean;
        showDepartureAllowedRow: boolean;
        showExtraPersonPriceRow: boolean;
        showMinimumStayDurationRow: boolean;
        showMinimumStayPriceRow: boolean;
        showNightPriceRow: boolean;
        showWeekPriceRow: boolean;
        specialParticipations: Array<{
            __typename?: 'SpecialParticipation';
            id: string;
            blockedArrivalDates: Array<any>;
            optInAcceptedAt?: any | null;
            optInRejectedAt?: any | null;
            special: {
                __typename?: 'Special';
                id: string;
                arrivalFrom: any;
                arrivalUntil: any;
                description?: string | null;
                name?: string | null;
                optInRequired: boolean;
                partnerConditions?: string | null;
            };
            trips: Array<{ __typename?: 'Trip'; date: any; duration: number; rentalSumPartner: number }>;
        }>;
        allotments: Array<{ __typename?: 'Allotment'; amount: number; id: string; date: any }>;
        bookings: Array<{
            __typename?: 'Booking';
            id: string;
            totalPrice: number;
            status: BookingStatusEnum;
            isActive: boolean;
            isHovering: boolean;
            startDate: any;
            endDate: any;
            customer?: {
                __typename?: 'Customer';
                id: string;
                firstName?: string | null;
                lastName?: string | null;
            } | null;
        }>;
        allotmentLockouts: Array<{
            __typename?: 'AllotmentLockout';
            id: string;
            type: AllotmentLockoutTypeEnum;
            comment?: string | null;
            source?: string | null;
            startDate: any;
            endDate: any;
            isDragging: boolean;
            isActive: boolean;
            isHovering: boolean;
        }>;
    } | null;
};

export type TripsQueryVariables = Exact<{
    unitSlug: Scalars['String']['input'];
    start: Scalars['Date']['input'];
    end: Scalars['Date']['input'];
}>;

export type TripsQuery = {
    __typename?: 'PartnerQuery';
    rentalUnit?: {
        __typename?: 'RentalUnit';
        tripPricingExportUrl?: string | null;
        id: string;
        showArrivalAllowedRow: boolean;
        showBaseStayPriceRow: boolean;
        showDepartureAllowedRow: boolean;
        showExtraPersonPriceRow: boolean;
        showMinimumStayDurationRow: boolean;
        showMinimumStayPriceRow: boolean;
        showNightPriceRow: boolean;
        showWeekPriceRow: boolean;
        tripPricings: Array<{ __typename?: 'TripPricing'; duration: number; id: string; price: number; date: any }>;
        allotments: Array<{ __typename?: 'Allotment'; amount: number; id: string; date: any }>;
        bookings: Array<{
            __typename?: 'Booking';
            id: string;
            totalPrice: number;
            status: BookingStatusEnum;
            isActive: boolean;
            isHovering: boolean;
            startDate: any;
            endDate: any;
            customer?: {
                __typename?: 'Customer';
                id: string;
                firstName?: string | null;
                lastName?: string | null;
            } | null;
        }>;
        allotmentLockouts: Array<{
            __typename?: 'AllotmentLockout';
            id: string;
            type: AllotmentLockoutTypeEnum;
            comment?: string | null;
            source?: string | null;
            startDate: any;
            endDate: any;
            isDragging: boolean;
            isActive: boolean;
            isHovering: boolean;
        }>;
    } | null;
};

export type AccommodationDescriptionFragment = {
    __typename?: 'Accommodation';
    id: string;
    descriptionGeneralPending?: string | null;
    type: AccommodationTypeEnum;
    descriptionGeneralNL?: string | null;
    descriptionGeneralDE?: string | null;
    descriptionGeneralEN?: string | null;
};

export type AccommodationDescriptionQueryVariables = Exact<{
    unitSlug: Scalars['String']['input'];
}>;

export type AccommodationDescriptionQuery = {
    __typename?: 'PartnerQuery';
    rentalUnit?: {
        __typename?: 'RentalUnit';
        id: string;
        accommodation: {
            __typename?: 'Accommodation';
            id: string;
            descriptionGeneralPending?: string | null;
            type: AccommodationTypeEnum;
            descriptionGeneralNL?: string | null;
            descriptionGeneralDE?: string | null;
            descriptionGeneralEN?: string | null;
        };
    } | null;
};

export type EditAccommodationDescriptionMutationVariables = Exact<{
    input: EditAccommodationDescriptionInput;
}>;

export type EditAccommodationDescriptionMutation = {
    __typename?: 'PartnerMutation';
    editAccommodationDescription: {
        __typename?: 'EditAccommodationDescriptionPayload';
        accommodation?: {
            __typename?: 'Accommodation';
            id: string;
            descriptionGeneralPending?: string | null;
            type: AccommodationTypeEnum;
            descriptionGeneralNL?: string | null;
            descriptionGeneralDE?: string | null;
            descriptionGeneralEN?: string | null;
        } | null;
    };
};

export type AccommodationInfoQueryVariables = Exact<{
    unitSlug: Scalars['String']['input'];
}>;

export type AccommodationInfoQuery = {
    __typename?: 'PartnerQuery';
    rentalUnit?: {
        __typename?: 'RentalUnit';
        id: string;
        accommodation: {
            __typename?: 'Accommodation';
            id: string;
            slug: string;
            hasPublicPage: boolean;
            name: string;
            type: AccommodationTypeEnum;
            checkInStartTime?: any | null;
            checkInEndTime?: any | null;
            checkOutTime?: any | null;
            publicReservationsEmail?: string | null;
            publicReservationsPhoneNumber?: string | null;
            publicReservationsWebsiteUrl?: string | null;
            reservations: {
                __typename?: 'ContactInfo';
                contactName?: string | null;
                phoneNumbers: Array<string>;
                emails: Array<string>;
            };
            caretaker?: {
                __typename?: 'ContactInfo';
                contactName?: string | null;
                phoneNumbers: Array<string>;
                emails: Array<string>;
            } | null;
        };
    } | null;
};

export type EditAccommodationInfoMutationVariables = Exact<{
    input: EditAccommodationInfoInput;
}>;

export type EditAccommodationInfoMutation = {
    __typename?: 'PartnerMutation';
    editAccommodationInfo: {
        __typename?: 'EditAccommodationInfoPayload';
        rentalUnit?: {
            __typename?: 'RentalUnit';
            id: string;
            accommodation: {
                __typename?: 'Accommodation';
                id: string;
                slug: string;
                hasPublicPage: boolean;
                name: string;
                type: AccommodationTypeEnum;
                checkInStartTime?: any | null;
                checkInEndTime?: any | null;
                checkOutTime?: any | null;
                publicReservationsEmail?: string | null;
                publicReservationsPhoneNumber?: string | null;
                publicReservationsWebsiteUrl?: string | null;
                reservations: {
                    __typename?: 'ContactInfo';
                    contactName?: string | null;
                    phoneNumbers: Array<string>;
                    emails: Array<string>;
                };
                caretaker?: {
                    __typename?: 'ContactInfo';
                    contactName?: string | null;
                    phoneNumbers: Array<string>;
                    emails: Array<string>;
                } | null;
            };
        } | null;
    };
};

export type AccommodationDetailsFragment = {
    __typename?: 'RentalUnit';
    id: string;
    accommodation: {
        __typename?: 'Accommodation';
        id: string;
        slug: string;
        hasPublicPage: boolean;
        name: string;
        type: AccommodationTypeEnum;
        checkInStartTime?: any | null;
        checkInEndTime?: any | null;
        checkOutTime?: any | null;
        publicReservationsEmail?: string | null;
        publicReservationsPhoneNumber?: string | null;
        publicReservationsWebsiteUrl?: string | null;
        reservations: {
            __typename?: 'ContactInfo';
            contactName?: string | null;
            phoneNumbers: Array<string>;
            emails: Array<string>;
        };
        caretaker?: {
            __typename?: 'ContactInfo';
            contactName?: string | null;
            phoneNumbers: Array<string>;
            emails: Array<string>;
        } | null;
    };
};

export type AccommodationImagesFragment = {
    __typename?: 'Accommodation';
    id: string;
    name: string;
    type: AccommodationTypeEnum;
    mainImage?: {
        __typename?: 'AccommodationMainImage';
        id: string;
        size: number;
        url: string;
        dimensions: Array<number>;
        previewUrl?: string | null;
    } | null;
    listImage?: {
        __typename?: 'AccommodationListImage';
        id: string;
        size: number;
        url: string;
        dimensions: Array<number>;
        previewUrl?: string | null;
    } | null;
    images: Array<{
        __typename?: 'AccommodationImage';
        id: string;
        size: number;
        url: string;
        dimensions: Array<number>;
        position: number;
        category: AccommodationImageCategoryEnum;
        previewUrl?: string | null;
    }>;
    rentalUnits: Array<{ __typename?: 'RentalUnit'; id: string }>;
};

export type AccommodationImagesQueryVariables = Exact<{
    unitSlug: Scalars['String']['input'];
}>;

export type AccommodationImagesQuery = {
    __typename?: 'PartnerQuery';
    rentalUnit?: {
        __typename?: 'RentalUnit';
        id: string;
        accommodation: {
            __typename?: 'Accommodation';
            id: string;
            name: string;
            type: AccommodationTypeEnum;
            mainImage?: {
                __typename?: 'AccommodationMainImage';
                id: string;
                size: number;
                url: string;
                dimensions: Array<number>;
                previewUrl?: string | null;
            } | null;
            listImage?: {
                __typename?: 'AccommodationListImage';
                id: string;
                size: number;
                url: string;
                dimensions: Array<number>;
                previewUrl?: string | null;
            } | null;
            images: Array<{
                __typename?: 'AccommodationImage';
                id: string;
                size: number;
                url: string;
                dimensions: Array<number>;
                position: number;
                category: AccommodationImageCategoryEnum;
                previewUrl?: string | null;
            }>;
            rentalUnits: Array<{ __typename?: 'RentalUnit'; id: string }>;
        };
    } | null;
};

export type EditAccommodationImagesMutationVariables = Exact<{
    input: EditAccommodationImagesInput;
}>;

export type EditAccommodationImagesMutation = {
    __typename?: 'PartnerMutation';
    editAccommodationImages: {
        __typename?: 'EditAccommodationImagesPayload';
        accommodation?: {
            __typename?: 'Accommodation';
            id: string;
            name: string;
            type: AccommodationTypeEnum;
            mainImage?: {
                __typename?: 'AccommodationMainImage';
                id: string;
                size: number;
                url: string;
                dimensions: Array<number>;
                previewUrl?: string | null;
            } | null;
            listImage?: {
                __typename?: 'AccommodationListImage';
                id: string;
                size: number;
                url: string;
                dimensions: Array<number>;
                previewUrl?: string | null;
            } | null;
            images: Array<{
                __typename?: 'AccommodationImage';
                id: string;
                size: number;
                url: string;
                dimensions: Array<number>;
                position: number;
                category: AccommodationImageCategoryEnum;
                previewUrl?: string | null;
            }>;
            rentalUnits: Array<{ __typename?: 'RentalUnit'; id: string }>;
        } | null;
    };
};

export type AttributesFragment = {
    __typename?: 'RentalUnit';
    id: string;
    name: string;
    accommodation: {
        __typename?: 'Accommodation';
        id: string;
        type: AccommodationTypeEnum;
        name: string;
        attributes: Array<{ __typename?: 'Attribute'; id: string }>;
        rentalUnits: Array<{ __typename?: 'RentalUnit'; id: string }>;
    };
    attributes: Array<{
        __typename?: 'Attribute';
        id: string;
        name?: string | null;
        type: AttributeTypeEnum;
        category: { __typename: 'AttributeCategory'; id: string; name?: string | null };
    }>;
};

export type AttributesQueryVariables = Exact<{
    unitSlug: Scalars['String']['input'];
    subtype?: InputMaybe<RentalUnitTypeEnum>;
}>;

export type AttributesQuery = {
    __typename?: 'PartnerQuery';
    rentalUnit?: {
        __typename?: 'RentalUnit';
        id: string;
        name: string;
        accommodation: {
            __typename?: 'Accommodation';
            id: string;
            type: AccommodationTypeEnum;
            name: string;
            attributes: Array<{ __typename?: 'Attribute'; id: string }>;
            rentalUnits: Array<{ __typename?: 'RentalUnit'; id: string }>;
        };
        attributes: Array<{
            __typename?: 'Attribute';
            id: string;
            name?: string | null;
            type: AttributeTypeEnum;
            category: { __typename: 'AttributeCategory'; id: string; name?: string | null };
        }>;
    } | null;
    attributeCategories: Array<{
        __typename?: 'AttributeCategory';
        id: string;
        allowMultiple: boolean;
        name?: string | null;
        unitAttributes: Array<{ __typename?: 'Attribute'; id: string; name?: string | null; type: AttributeTypeEnum }>;
        accoAttributes: Array<{ __typename?: 'Attribute'; id: string; name?: string | null; type: AttributeTypeEnum }>;
    }>;
};

export type EditAttributesMutationVariables = Exact<{
    input: EditAttributesInput;
}>;

export type EditAttributesMutation = {
    __typename?: 'PartnerMutation';
    editAttributes: {
        __typename?: 'EditAttributesPayload';
        rentalUnit?: {
            __typename?: 'RentalUnit';
            id: string;
            name: string;
            accommodation: {
                __typename?: 'Accommodation';
                id: string;
                type: AccommodationTypeEnum;
                name: string;
                attributes: Array<{ __typename?: 'Attribute'; id: string }>;
                rentalUnits: Array<{ __typename?: 'RentalUnit'; id: string }>;
            };
            attributes: Array<{
                __typename?: 'Attribute';
                id: string;
                name?: string | null;
                type: AttributeTypeEnum;
                category: { __typename: 'AttributeCategory'; id: string; name?: string | null };
            }>;
        } | null;
    };
};

export type OverwriteRentalUnitSurchargesMutationVariables = Exact<{
    input: OverwriteRentalUnitSurchargesInput;
}>;

export type OverwriteRentalUnitSurchargesMutation = {
    __typename?: 'PartnerMutation';
    overwriteRentalUnitSurcharges: {
        __typename?: 'OverwriteRentalUnitSurchargesPayload';
        targetRentalUnits?: Array<{
            __typename?: 'RentalUnit';
            id: string;
            surcharges: Array<{ __typename?: 'RentalUnitSurcharge'; id: string }>;
        }> | null;
    };
};

export type CapacityFragment = {
    __typename?: 'RentalUnit';
    id: string;
    slug: string;
    code: string;
    type: RentalUnitTypeEnum;
    name: string;
    minOccupancy?: number | null;
    maxOccupancy?: number | null;
    maxExtraBabyOccupancy?: number | null;
    includedOccupancy?: number | null;
    petsAllowed?: number | null;
    amountBedrooms?: number | null;
    amountBeds?: number | null;
    amountBathrooms?: number | null;
    amountToilets?: number | null;
    livingArea?: number | null;
    outdoorArea?: number | null;
    maxVehicleSize?: number | null;
};

export type CapacityQueryVariables = Exact<{
    unitSlug: Scalars['String']['input'];
}>;

export type CapacityQuery = {
    __typename?: 'PartnerQuery';
    rentalUnit?: {
        __typename?: 'RentalUnit';
        id: string;
        slug: string;
        code: string;
        type: RentalUnitTypeEnum;
        name: string;
        minOccupancy?: number | null;
        maxOccupancy?: number | null;
        maxExtraBabyOccupancy?: number | null;
        includedOccupancy?: number | null;
        petsAllowed?: number | null;
        amountBedrooms?: number | null;
        amountBeds?: number | null;
        amountBathrooms?: number | null;
        amountToilets?: number | null;
        livingArea?: number | null;
        outdoorArea?: number | null;
        maxVehicleSize?: number | null;
    } | null;
};

export type EditCapacityMutationVariables = Exact<{
    input: EditCapacityAndSpaceInput;
}>;

export type EditCapacityMutation = {
    __typename?: 'PartnerMutation';
    editCapacityAndSpace: {
        __typename?: 'EditCapacityAndSpacePayload';
        rentalUnit?: {
            __typename?: 'RentalUnit';
            id: string;
            slug: string;
            code: string;
            type: RentalUnitTypeEnum;
            name: string;
            minOccupancy?: number | null;
            maxOccupancy?: number | null;
            maxExtraBabyOccupancy?: number | null;
            includedOccupancy?: number | null;
            petsAllowed?: number | null;
            amountBedrooms?: number | null;
            amountBeds?: number | null;
            amountBathrooms?: number | null;
            amountToilets?: number | null;
            livingArea?: number | null;
            outdoorArea?: number | null;
            maxVehicleSize?: number | null;
        } | null;
    };
};

export type CompactDetailsQueryVariables = Exact<{
    unitSlug: Scalars['String']['input'];
}>;

export type CompactDetailsQuery = {
    __typename?: 'PartnerQuery';
    rentalUnit?: {
        __typename?: 'RentalUnit';
        id: string;
        slug: string;
        code: string;
        type: RentalUnitTypeEnum;
        securityDeposit?: number | null;
        handleSecurityDepositPayment: boolean;
        releaseDays?: number | null;
        name: string;
        taglinePending?: string | null;
        hideReviews: boolean;
        tagline: string;
        taglineDE: string;
        taglineEN: string;
        accommodation: {
            __typename?: 'Accommodation';
            id: string;
            publicReservationsEmail?: string | null;
            publicReservationsPhoneNumber?: string | null;
            publicReservationsWebsiteUrl?: string | null;
            checkInStartTime?: any | null;
            checkInEndTime?: any | null;
            checkOutTime?: any | null;
            reservations: {
                __typename?: 'ContactInfo';
                contactName?: string | null;
                phoneNumbers: Array<string>;
                emails: Array<string>;
            };
            caretaker?: {
                __typename?: 'ContactInfo';
                contactName?: string | null;
                phoneNumbers: Array<string>;
                emails: Array<string>;
            } | null;
        };
    } | null;
};

export type EditRentalUnitAndAccommodationInfoMutationVariables = Exact<{
    input: EditRentalUnitAndAccommodationInfoInput;
}>;

export type EditRentalUnitAndAccommodationInfoMutation = {
    __typename?: 'PartnerMutation';
    editRentalUnitAndAccommodationInfo: {
        __typename?: 'EditRentalUnitAndAccommodationInfoPayload';
        rentalUnit?: {
            __typename?: 'RentalUnit';
            id: string;
            slug: string;
            code: string;
            type: RentalUnitTypeEnum;
            securityDeposit?: number | null;
            handleSecurityDepositPayment: boolean;
            releaseDays?: number | null;
            name: string;
            taglinePending?: string | null;
            hideReviews: boolean;
            tagline: string;
            taglineDE: string;
            taglineEN: string;
            accommodation: {
                __typename?: 'Accommodation';
                id: string;
                publicReservationsEmail?: string | null;
                publicReservationsPhoneNumber?: string | null;
                publicReservationsWebsiteUrl?: string | null;
                checkInStartTime?: any | null;
                checkInEndTime?: any | null;
                checkOutTime?: any | null;
                reservations: {
                    __typename?: 'ContactInfo';
                    contactName?: string | null;
                    phoneNumbers: Array<string>;
                    emails: Array<string>;
                };
                caretaker?: {
                    __typename?: 'ContactInfo';
                    contactName?: string | null;
                    phoneNumbers: Array<string>;
                    emails: Array<string>;
                } | null;
            };
        } | null;
    };
};

export type CompactInfoFragment = {
    __typename?: 'RentalUnit';
    id: string;
    slug: string;
    code: string;
    type: RentalUnitTypeEnum;
    securityDeposit?: number | null;
    handleSecurityDepositPayment: boolean;
    releaseDays?: number | null;
    name: string;
    taglinePending?: string | null;
    hideReviews: boolean;
    tagline: string;
    taglineDE: string;
    taglineEN: string;
    accommodation: {
        __typename?: 'Accommodation';
        id: string;
        publicReservationsEmail?: string | null;
        publicReservationsPhoneNumber?: string | null;
        publicReservationsWebsiteUrl?: string | null;
        checkInStartTime?: any | null;
        checkInEndTime?: any | null;
        checkOutTime?: any | null;
        reservations: {
            __typename?: 'ContactInfo';
            contactName?: string | null;
            phoneNumbers: Array<string>;
            emails: Array<string>;
        };
        caretaker?: {
            __typename?: 'ContactInfo';
            contactName?: string | null;
            phoneNumbers: Array<string>;
            emails: Array<string>;
        } | null;
    };
};

export type ContractsQueryVariables = Exact<{
    unitSlug: Scalars['String']['input'];
}>;

export type ContractsQuery = {
    __typename?: 'PartnerQuery';
    rentalUnit?: {
        __typename?: 'RentalUnit';
        id: string;
        rentalUnitContracts: Array<{
            __typename?: 'RentalUnitContract';
            id: string;
            startDate: any;
            endDate?: any | null;
            approvalDate?: any | null;
            contract: {
                __typename?: 'Contract';
                name: string;
                bookingType: BookingTypeEnum;
                toBePaidThroughTor: boolean;
                commissionPercentage: number;
                contractUrl?: string | null;
                surchargePaymentType: PaymentTypeEnum;
            };
        }>;
    } | null;
};

export type ContractsFragment = {
    __typename?: 'RentalUnitContract';
    id: string;
    startDate: any;
    endDate?: any | null;
    approvalDate?: any | null;
    contract: {
        __typename?: 'Contract';
        name: string;
        bookingType: BookingTypeEnum;
        toBePaidThroughTor: boolean;
        commissionPercentage: number;
        contractUrl?: string | null;
        surchargePaymentType: PaymentTypeEnum;
    };
};

export type ApproveContractMutationVariables = Exact<{
    id: Scalars['ID']['input'];
}>;

export type ApproveContractMutation = {
    __typename?: 'PartnerMutation';
    approveRentalUnitContract: {
        __typename?: 'ApproveRentalUnitContractPayload';
        rentalUnitContract?: {
            __typename?: 'RentalUnitContract';
            id: string;
            startDate: any;
            endDate?: any | null;
            approvalDate?: any | null;
            contract: {
                __typename?: 'Contract';
                name: string;
                bookingType: BookingTypeEnum;
                toBePaidThroughTor: boolean;
                commissionPercentage: number;
                contractUrl?: string | null;
                surchargePaymentType: PaymentTypeEnum;
            };
        } | null;
    };
};

export type IcalFragment = {
    __typename?: 'RentalUnit';
    id: string;
    slug: string;
    type: RentalUnitTypeEnum;
    icalFeedUrl?: string | null;
    icalImportFeeds: Array<{ __typename?: 'IcalImportFeed'; icalImportUrl: string; source?: string | null }>;
};

export type IcalQueryVariables = Exact<{
    unitSlug: Scalars['String']['input'];
}>;

export type IcalQuery = {
    __typename?: 'PartnerQuery';
    rentalUnit?: {
        __typename?: 'RentalUnit';
        id: string;
        slug: string;
        type: RentalUnitTypeEnum;
        icalFeedUrl?: string | null;
        icalImportFeeds: Array<{ __typename?: 'IcalImportFeed'; icalImportUrl: string; source?: string | null }>;
    } | null;
};

export type EditIcalImportFeedMutationVariables = Exact<{
    input: EditIcalImportFeedsInput;
}>;

export type EditIcalImportFeedMutation = {
    __typename?: 'PartnerMutation';
    editIcalImportFeeds: {
        __typename?: 'EditIcalImportFeedsPayload';
        icalImportFeeds?: Array<{
            __typename?: 'IcalImportFeed';
            icalImportUrl: string;
            source?: string | null;
        }> | null;
    };
};

export type ImageFragment = {
    __typename?: 'AccommodationImage';
    id: string;
    size: number;
    url: string;
    dimensions: Array<number>;
    position: number;
    category: AccommodationImageCategoryEnum;
    previewUrl?: string | null;
};

export type KeyHolderFragment = {
    __typename?: 'RentalUnit';
    id: string;
    type: RentalUnitTypeEnum;
    slug: string;
    keyHolderExplanationPending?: string | null;
    keyHolderExplanationDE?: string | null;
    keyHolderExplanationNL?: string | null;
    keyHolderExplanationEN?: string | null;
    accommodation: {
        __typename?: 'Accommodation';
        id: string;
        keyHolderAddress?: {
            __typename?: 'Address';
            city?: string | null;
            street?: string | null;
            postalCode?: string | null;
            number?: string | null;
            countryCode?: string | null;
        } | null;
        keyHolder?: {
            __typename?: 'ContactInfo';
            contactName?: string | null;
            phoneNumbers: Array<string>;
            emails: Array<string>;
        } | null;
    };
};

export type KeyHolderQueryVariables = Exact<{
    unitSlug: Scalars['String']['input'];
}>;

export type KeyHolderQuery = {
    __typename?: 'PartnerQuery';
    rentalUnit?: {
        __typename?: 'RentalUnit';
        id: string;
        type: RentalUnitTypeEnum;
        slug: string;
        keyHolderExplanationPending?: string | null;
        keyHolderExplanationDE?: string | null;
        keyHolderExplanationNL?: string | null;
        keyHolderExplanationEN?: string | null;
        accommodation: {
            __typename?: 'Accommodation';
            id: string;
            keyHolderAddress?: {
                __typename?: 'Address';
                city?: string | null;
                street?: string | null;
                postalCode?: string | null;
                number?: string | null;
                countryCode?: string | null;
            } | null;
            keyHolder?: {
                __typename?: 'ContactInfo';
                contactName?: string | null;
                phoneNumbers: Array<string>;
                emails: Array<string>;
            } | null;
        };
    } | null;
};

export type EditKeyHolderMutationVariables = Exact<{
    input: EditKeyHolderInput;
}>;

export type EditKeyHolderMutation = {
    __typename?: 'PartnerMutation';
    editKeyHolder: {
        __typename?: 'EditKeyHolderPayload';
        rentalUnit?: {
            __typename?: 'RentalUnit';
            id: string;
            type: RentalUnitTypeEnum;
            slug: string;
            keyHolderExplanationPending?: string | null;
            keyHolderExplanationDE?: string | null;
            keyHolderExplanationNL?: string | null;
            keyHolderExplanationEN?: string | null;
            accommodation: {
                __typename?: 'Accommodation';
                id: string;
                keyHolderAddress?: {
                    __typename?: 'Address';
                    city?: string | null;
                    street?: string | null;
                    postalCode?: string | null;
                    number?: string | null;
                    countryCode?: string | null;
                } | null;
                keyHolder?: {
                    __typename?: 'ContactInfo';
                    contactName?: string | null;
                    phoneNumbers: Array<string>;
                    emails: Array<string>;
                } | null;
            };
        } | null;
    };
};

export type LocationFragment = {
    __typename?: 'RentalUnit';
    id: string;
    slug: string;
    type: RentalUnitTypeEnum;
    accommodation: {
        __typename?: 'Accommodation';
        id: string;
        name: string;
        descriptionNL?: string | null;
        descriptionDE?: string | null;
        descriptionEN?: string | null;
        descriptionPending?: string | null;
        address?: {
            __typename?: 'Address';
            city?: string | null;
            street?: string | null;
            number?: string | null;
            postalCode?: string | null;
            countryCode?: string | null;
        } | null;
        coordinates?: { __typename?: 'LatLon'; lat: number; lon: number } | null;
    };
};

export type LocationQueryVariables = Exact<{
    unitSlug: Scalars['String']['input'];
}>;

export type LocationQuery = {
    __typename?: 'PartnerQuery';
    rentalUnit?: {
        __typename?: 'RentalUnit';
        id: string;
        slug: string;
        type: RentalUnitTypeEnum;
        accommodation: {
            __typename?: 'Accommodation';
            id: string;
            name: string;
            descriptionNL?: string | null;
            descriptionDE?: string | null;
            descriptionEN?: string | null;
            descriptionPending?: string | null;
            address?: {
                __typename?: 'Address';
                city?: string | null;
                street?: string | null;
                number?: string | null;
                postalCode?: string | null;
                countryCode?: string | null;
            } | null;
            coordinates?: { __typename?: 'LatLon'; lat: number; lon: number } | null;
        };
    } | null;
};

export type EditLocationMutationVariables = Exact<{
    input: EditLocationAndDistanceInput;
}>;

export type EditLocationMutation = {
    __typename?: 'PartnerMutation';
    editLocationAndDistance: {
        __typename?: 'EditLocationAndDistancePayload';
        rentalUnit?: {
            __typename?: 'RentalUnit';
            id: string;
            slug: string;
            type: RentalUnitTypeEnum;
            accommodation: {
                __typename?: 'Accommodation';
                id: string;
                name: string;
                descriptionNL?: string | null;
                descriptionDE?: string | null;
                descriptionEN?: string | null;
                descriptionPending?: string | null;
                address?: {
                    __typename?: 'Address';
                    city?: string | null;
                    street?: string | null;
                    number?: string | null;
                    postalCode?: string | null;
                    countryCode?: string | null;
                } | null;
                coordinates?: { __typename?: 'LatLon'; lat: number; lon: number } | null;
            };
        } | null;
    };
};

export type OwnerFragment = {
    __typename?: 'RentalUnit';
    id: string;
    accommodation: {
        __typename?: 'Accommodation';
        id: string;
        name: string;
        ownerName?: string | null;
        ownerProfileVisible: boolean;
        ownerIntroduction?: string | null;
        ownerIntroductionPending?: string | null;
        ownerTips?: string | null;
        ownerTipsPending?: string | null;
        ownerIntroductionEN?: string | null;
        ownerIntroductionDE?: string | null;
        ownerTipsEN?: string | null;
        ownerTipsDE?: string | null;
        ownerImage?: { __typename?: 'AvatarImage'; id: string; url: string } | null;
        rentalUnits: Array<{ __typename?: 'RentalUnit'; id: string }>;
    };
};

export type OwnerQueryVariables = Exact<{
    unitSlug: Scalars['String']['input'];
}>;

export type OwnerQuery = {
    __typename?: 'PartnerQuery';
    rentalUnit?: {
        __typename?: 'RentalUnit';
        id: string;
        accommodation: {
            __typename?: 'Accommodation';
            id: string;
            name: string;
            ownerName?: string | null;
            ownerProfileVisible: boolean;
            ownerIntroduction?: string | null;
            ownerIntroductionPending?: string | null;
            ownerTips?: string | null;
            ownerTipsPending?: string | null;
            ownerIntroductionEN?: string | null;
            ownerIntroductionDE?: string | null;
            ownerTipsEN?: string | null;
            ownerTipsDE?: string | null;
            ownerImage?: { __typename?: 'AvatarImage'; id: string; url: string } | null;
            rentalUnits: Array<{ __typename?: 'RentalUnit'; id: string }>;
        };
    } | null;
};

export type EditOwnerMutationVariables = Exact<{
    input: EditOwnerInformationInput;
}>;

export type EditOwnerMutation = {
    __typename?: 'PartnerMutation';
    editOwnerInformation: {
        __typename?: 'EditOwnerInformationPayload';
        rentalUnit?: {
            __typename?: 'RentalUnit';
            id: string;
            accommodation: {
                __typename?: 'Accommodation';
                id: string;
                name: string;
                ownerName?: string | null;
                ownerProfileVisible: boolean;
                ownerIntroduction?: string | null;
                ownerIntroductionPending?: string | null;
                ownerTips?: string | null;
                ownerTipsPending?: string | null;
                ownerIntroductionEN?: string | null;
                ownerIntroductionDE?: string | null;
                ownerTipsEN?: string | null;
                ownerTipsDE?: string | null;
                ownerImage?: { __typename?: 'AvatarImage'; id: string; url: string } | null;
                rentalUnits: Array<{ __typename?: 'RentalUnit'; id: string }>;
            };
        } | null;
    };
};

export type PriceConfigFragment = {
    __typename?: 'RentalUnit';
    id: string;
    lastminuteDiscountEnabled: boolean;
    earlyBookDiscountEnabled: boolean;
    lastminuteDiscount1Week?: number | null;
    lastminuteDiscount2Week?: number | null;
    lastminuteDiscount3Week?: number | null;
    lastminuteDiscount4Week?: number | null;
    earlyBookDiscount6Months?: number | null;
    earlyBookDiscount12Months?: number | null;
    flexibleArrival: boolean;
    flexibleDeparture: boolean;
    flexCancellationDays?: number | null;
    showArrivalAllowedRow: boolean;
    showBaseStayPriceRow: boolean;
    showDepartureAllowedRow: boolean;
    showExtraPersonPriceRow: boolean;
    showMinimumStayDurationRow: boolean;
    showMinimumStayPriceRow: boolean;
    showNightPriceRow: boolean;
    showWeekPriceRow: boolean;
};

export type PriceConfigQueryVariables = Exact<{
    unitSlug: Scalars['String']['input'];
}>;

export type PriceConfigQuery = {
    __typename?: 'PartnerQuery';
    rentalUnit?: {
        __typename?: 'RentalUnit';
        id: string;
        lastminuteDiscountEnabled: boolean;
        earlyBookDiscountEnabled: boolean;
        lastminuteDiscount1Week?: number | null;
        lastminuteDiscount2Week?: number | null;
        lastminuteDiscount3Week?: number | null;
        lastminuteDiscount4Week?: number | null;
        earlyBookDiscount6Months?: number | null;
        earlyBookDiscount12Months?: number | null;
        flexibleArrival: boolean;
        flexibleDeparture: boolean;
        flexCancellationDays?: number | null;
        showArrivalAllowedRow: boolean;
        showBaseStayPriceRow: boolean;
        showDepartureAllowedRow: boolean;
        showExtraPersonPriceRow: boolean;
        showMinimumStayDurationRow: boolean;
        showMinimumStayPriceRow: boolean;
        showNightPriceRow: boolean;
        showWeekPriceRow: boolean;
    } | null;
};

export type EditDiscountMutationVariables = Exact<{
    input: EditDiscountForUnitInput;
}>;

export type EditDiscountMutation = {
    __typename?: 'PartnerMutation';
    editDiscounts: {
        __typename?: 'EditDiscountForUnitPayload';
        rentalUnit?: {
            __typename?: 'RentalUnit';
            id: string;
            lastminuteDiscountEnabled: boolean;
            earlyBookDiscountEnabled: boolean;
            lastminuteDiscount1Week?: number | null;
            lastminuteDiscount2Week?: number | null;
            lastminuteDiscount3Week?: number | null;
            lastminuteDiscount4Week?: number | null;
            earlyBookDiscount6Months?: number | null;
            earlyBookDiscount12Months?: number | null;
            flexibleArrival: boolean;
            flexibleDeparture: boolean;
            flexCancellationDays?: number | null;
            showArrivalAllowedRow: boolean;
            showBaseStayPriceRow: boolean;
            showDepartureAllowedRow: boolean;
            showExtraPersonPriceRow: boolean;
            showMinimumStayDurationRow: boolean;
            showMinimumStayPriceRow: boolean;
            showNightPriceRow: boolean;
            showWeekPriceRow: boolean;
        } | null;
    };
};

export type AvailableSurchargeFragment = {
    __typename?: 'Surcharge';
    id: string;
    allowedCalculations: Array<SurchargeCalculationEnum>;
    description?: string | null;
    canBePaidThroughTor: boolean;
    nameNL?: string | null;
    nameEN?: string | null;
    nameDE?: string | null;
};

export type RentalUnitSurchargeFragment = {
    __typename?: 'RentalUnitSurcharge';
    id: string;
    calculation: SurchargeCalculationEnum;
    endDate?: any | null;
    maxAmount: number;
    minAmount: number;
    startDate: any;
    unitPrice: number;
    handlePayment: boolean;
    surcharge: {
        __typename?: 'Surcharge';
        id: string;
        allowedCalculations: Array<SurchargeCalculationEnum>;
        description?: string | null;
        canBePaidThroughTor: boolean;
        nameNL?: string | null;
        nameEN?: string | null;
        nameDE?: string | null;
    };
};

export type RentalUnitSurchargesQueryVariables = Exact<{
    unitSlug: Scalars['String']['input'];
}>;

export type RentalUnitSurchargesQuery = {
    __typename?: 'PartnerQuery';
    rentalUnit?: {
        __typename?: 'RentalUnit';
        id: string;
        accommodation: {
            __typename?: 'Accommodation';
            rentalUnits: Array<{
                __typename?: 'RentalUnit';
                id: string;
                slug: string;
                type: RentalUnitTypeEnum;
                datePricingStartDate?: any | null;
                name: string;
                showAllotmentLockouts: boolean;
            }>;
        };
        availableSurcharges: Array<{
            __typename?: 'Surcharge';
            id: string;
            allowedCalculations: Array<SurchargeCalculationEnum>;
            description?: string | null;
            canBePaidThroughTor: boolean;
            nameNL?: string | null;
            nameEN?: string | null;
            nameDE?: string | null;
        }>;
        surcharges: Array<{
            __typename?: 'RentalUnitSurcharge';
            id: string;
            calculation: SurchargeCalculationEnum;
            endDate?: any | null;
            maxAmount: number;
            minAmount: number;
            startDate: any;
            unitPrice: number;
            handlePayment: boolean;
            surcharge: {
                __typename?: 'Surcharge';
                id: string;
                allowedCalculations: Array<SurchargeCalculationEnum>;
                description?: string | null;
                canBePaidThroughTor: boolean;
                nameNL?: string | null;
                nameEN?: string | null;
                nameDE?: string | null;
            };
        }>;
    } | null;
};

export type CreateRentalUnitSurchargeMutationVariables = Exact<{
    input: CreateRentalUnitSurchargeInput;
}>;

export type CreateRentalUnitSurchargeMutation = {
    __typename?: 'PartnerMutation';
    createRentalUnitSurcharge: {
        __typename?: 'CreateRentalUnitSurchargePayload';
        rentalUnitSurcharge?: {
            __typename?: 'RentalUnitSurcharge';
            id: string;
            calculation: SurchargeCalculationEnum;
            endDate?: any | null;
            maxAmount: number;
            minAmount: number;
            startDate: any;
            unitPrice: number;
            handlePayment: boolean;
            surcharge: {
                __typename?: 'Surcharge';
                id: string;
                allowedCalculations: Array<SurchargeCalculationEnum>;
                description?: string | null;
                canBePaidThroughTor: boolean;
                nameNL?: string | null;
                nameEN?: string | null;
                nameDE?: string | null;
            };
        } | null;
    };
};

export type EditRentalUnitSurchargeMutationVariables = Exact<{
    input: EditRentalUnitSurchargeInput;
}>;

export type EditRentalUnitSurchargeMutation = {
    __typename?: 'PartnerMutation';
    editRentalUnitSurcharge: {
        __typename?: 'EditRentalUnitSurchargePayload';
        rentalUnitSurcharge?: {
            __typename?: 'RentalUnitSurcharge';
            id: string;
            calculation: SurchargeCalculationEnum;
            endDate?: any | null;
            maxAmount: number;
            minAmount: number;
            startDate: any;
            unitPrice: number;
            handlePayment: boolean;
            surcharge: {
                __typename?: 'Surcharge';
                id: string;
                allowedCalculations: Array<SurchargeCalculationEnum>;
                description?: string | null;
                canBePaidThroughTor: boolean;
                nameNL?: string | null;
                nameEN?: string | null;
                nameDE?: string | null;
            };
        } | null;
    };
};

export type DeleteRentalUnitSurchargeMutationVariables = Exact<{
    input: DeleteRentalUnitSurchargeInput;
}>;

export type DeleteRentalUnitSurchargeMutation = {
    __typename?: 'PartnerMutation';
    deleteRentalUnitSurcharge: { __typename?: 'DeleteRentalUnitSurchargePayload'; id?: string | null };
};

export type UnitDescriptionFragment = {
    __typename?: 'RentalUnit';
    id: string;
    descriptionLayoutPending?: string | null;
    descriptionExtrasPending?: string | null;
    descriptionParticularsPending?: string | null;
    descriptionLayoutNL?: string | null;
    descriptionLayoutDE?: string | null;
    descriptionLayoutEN?: string | null;
    descriptionExtrasNL?: string | null;
    descriptionExtrasDE?: string | null;
    descriptionExtrasEN?: string | null;
    descriptionParticularsNL?: string | null;
    descriptionParticularsDE?: string | null;
    descriptionParticularsEN?: string | null;
};

export type UnitDescriptionQueryVariables = Exact<{
    unitSlug: Scalars['String']['input'];
}>;

export type UnitDescriptionQuery = {
    __typename?: 'PartnerQuery';
    rentalUnit?: {
        __typename?: 'RentalUnit';
        id: string;
        descriptionLayoutPending?: string | null;
        descriptionExtrasPending?: string | null;
        descriptionParticularsPending?: string | null;
        descriptionLayoutNL?: string | null;
        descriptionLayoutDE?: string | null;
        descriptionLayoutEN?: string | null;
        descriptionExtrasNL?: string | null;
        descriptionExtrasDE?: string | null;
        descriptionExtrasEN?: string | null;
        descriptionParticularsNL?: string | null;
        descriptionParticularsDE?: string | null;
        descriptionParticularsEN?: string | null;
    } | null;
};

export type EditRentalUnitDescriptionsMutationVariables = Exact<{
    input: EditRentalUnitDescriptionsInput;
}>;

export type EditRentalUnitDescriptionsMutation = {
    __typename?: 'PartnerMutation';
    editRentalUnitDescriptions: {
        __typename?: 'EditRentalUnitDescriptionsPayload';
        rentalUnit?: {
            __typename?: 'RentalUnit';
            id: string;
            descriptionLayoutPending?: string | null;
            descriptionExtrasPending?: string | null;
            descriptionParticularsPending?: string | null;
            descriptionLayoutNL?: string | null;
            descriptionLayoutDE?: string | null;
            descriptionLayoutEN?: string | null;
            descriptionExtrasNL?: string | null;
            descriptionExtrasDE?: string | null;
            descriptionExtrasEN?: string | null;
            descriptionParticularsNL?: string | null;
            descriptionParticularsDE?: string | null;
            descriptionParticularsEN?: string | null;
        } | null;
    };
};

export type RentalUnitInfoQueryVariables = Exact<{
    unitSlug: Scalars['String']['input'];
}>;

export type RentalUnitInfoQuery = {
    __typename?: 'PartnerQuery';
    rentalUnit?: {
        __typename?: 'RentalUnit';
        id: string;
        slug: string;
        code: string;
        type: RentalUnitTypeEnum;
        securityDeposit?: number | null;
        handleSecurityDepositPayment: boolean;
        releaseDays?: number | null;
        name: string;
        taglinePending?: string | null;
        keyHolderExplanationPending?: string | null;
        hideReviews: boolean;
        hideForPartner: boolean;
        tagline: string;
        taglineDE: string;
        taglineEN: string;
        keyHolderExplanationDE?: string | null;
        keyHolderExplanationNL?: string | null;
        keyHolderExplanationEN?: string | null;
    } | null;
};

export type EditRentalUnitInfoMutationVariables = Exact<{
    input: EditRentalUnitInfoInput;
}>;

export type EditRentalUnitInfoMutation = {
    __typename?: 'PartnerMutation';
    editRentalUnitInfo: {
        __typename?: 'EditRentalUnitInfoPayload';
        rentalUnit?: {
            __typename?: 'RentalUnit';
            id: string;
            slug: string;
            code: string;
            type: RentalUnitTypeEnum;
            securityDeposit?: number | null;
            handleSecurityDepositPayment: boolean;
            releaseDays?: number | null;
            name: string;
            taglinePending?: string | null;
            keyHolderExplanationPending?: string | null;
            hideReviews: boolean;
            hideForPartner: boolean;
            tagline: string;
            taglineDE: string;
            taglineEN: string;
            keyHolderExplanationDE?: string | null;
            keyHolderExplanationNL?: string | null;
            keyHolderExplanationEN?: string | null;
        } | null;
    };
};

export type RentalUnitDetailsFragment = {
    __typename?: 'RentalUnit';
    id: string;
    slug: string;
    code: string;
    type: RentalUnitTypeEnum;
    securityDeposit?: number | null;
    handleSecurityDepositPayment: boolean;
    releaseDays?: number | null;
    name: string;
    taglinePending?: string | null;
    keyHolderExplanationPending?: string | null;
    hideReviews: boolean;
    hideForPartner: boolean;
    tagline: string;
    taglineDE: string;
    taglineEN: string;
    keyHolderExplanationDE?: string | null;
    keyHolderExplanationNL?: string | null;
    keyHolderExplanationEN?: string | null;
};

export type UnitImagesFragment = {
    __typename?: 'RentalUnit';
    id: string;
    type: RentalUnitTypeEnum;
    fullCircleImageUrl?: string | null;
    videoUrl?: string | null;
    mainImage?: {
        __typename?: 'AccommodationMainImage';
        id: string;
        size: number;
        url: string;
        dimensions: Array<number>;
        previewUrl?: string | null;
    } | null;
    listImage?: {
        __typename?: 'AccommodationListImage';
        id: string;
        size: number;
        url: string;
        dimensions: Array<number>;
        previewUrl?: string | null;
    } | null;
    images: Array<{
        __typename?: 'AccommodationImage';
        id: string;
        size: number;
        url: string;
        dimensions: Array<number>;
        position: number;
        category: AccommodationImageCategoryEnum;
        previewUrl?: string | null;
    }>;
};

export type UnitImagesQueryVariables = Exact<{
    unitSlug: Scalars['String']['input'];
}>;

export type UnitImagesQuery = {
    __typename?: 'PartnerQuery';
    rentalUnit?: {
        __typename?: 'RentalUnit';
        id: string;
        type: RentalUnitTypeEnum;
        fullCircleImageUrl?: string | null;
        videoUrl?: string | null;
        mainImage?: {
            __typename?: 'AccommodationMainImage';
            id: string;
            size: number;
            url: string;
            dimensions: Array<number>;
            previewUrl?: string | null;
        } | null;
        listImage?: {
            __typename?: 'AccommodationListImage';
            id: string;
            size: number;
            url: string;
            dimensions: Array<number>;
            previewUrl?: string | null;
        } | null;
        images: Array<{
            __typename?: 'AccommodationImage';
            id: string;
            size: number;
            url: string;
            dimensions: Array<number>;
            position: number;
            category: AccommodationImageCategoryEnum;
            previewUrl?: string | null;
        }>;
    } | null;
};

export type EditRentalUnitImagesMutationVariables = Exact<{
    input: EditRentalUnitImagesInput;
}>;

export type EditRentalUnitImagesMutation = {
    __typename?: 'PartnerMutation';
    editRentalUnitImages: {
        __typename?: 'EditRentalUnitImagesPayload';
        rentalUnit?: {
            __typename?: 'RentalUnit';
            id: string;
            type: RentalUnitTypeEnum;
            fullCircleImageUrl?: string | null;
            videoUrl?: string | null;
            mainImage?: {
                __typename?: 'AccommodationMainImage';
                id: string;
                size: number;
                url: string;
                dimensions: Array<number>;
                previewUrl?: string | null;
            } | null;
            listImage?: {
                __typename?: 'AccommodationListImage';
                id: string;
                size: number;
                url: string;
                dimensions: Array<number>;
                previewUrl?: string | null;
            } | null;
            images: Array<{
                __typename?: 'AccommodationImage';
                id: string;
                size: number;
                url: string;
                dimensions: Array<number>;
                position: number;
                category: AccommodationImageCategoryEnum;
                previewUrl?: string | null;
            }>;
        } | null;
    };
};

export type PricesWizardQueryVariables = Exact<{
    unitSlug: Scalars['String']['input'];
}>;

export type PricesWizardQuery = {
    __typename?: 'PartnerQuery';
    rentalUnit?: {
        __typename?: 'RentalUnit';
        id: string;
        lastDatePricingDate?: any | null;
        datePricingStartDate?: any | null;
        datePricingEndDate?: any | null;
        showArrivalAllowedRow: boolean;
        showBaseStayPriceRow: boolean;
        showDepartureAllowedRow: boolean;
        showExtraPersonPriceRow: boolean;
        showMinimumStayDurationRow: boolean;
        showMinimumStayPriceRow: boolean;
        showNightPriceRow: boolean;
        showWeekPriceRow: boolean;
    } | null;
};

export type PricesWizardRentalUnitFragment = {
    __typename?: 'RentalUnit';
    id: string;
    lastDatePricingDate?: any | null;
    datePricingStartDate?: any | null;
    datePricingEndDate?: any | null;
    showArrivalAllowedRow: boolean;
    showBaseStayPriceRow: boolean;
    showDepartureAllowedRow: boolean;
    showExtraPersonPriceRow: boolean;
    showMinimumStayDurationRow: boolean;
    showMinimumStayPriceRow: boolean;
    showNightPriceRow: boolean;
    showWeekPriceRow: boolean;
};

export type BulkUpsertDatePricingMutationVariables = Exact<{
    input: BulkUpsertDatePricingInput;
}>;

export type BulkUpsertDatePricingMutation = {
    __typename?: 'PartnerMutation';
    bulkUpsertDatePricing: {
        __typename?: 'BulkUpsertDatePricingPayload';
        rentalUnit?: {
            __typename?: 'RentalUnit';
            id: string;
            lastDatePricingDate?: any | null;
            datePricingStartDate?: any | null;
            datePricingEndDate?: any | null;
            showArrivalAllowedRow: boolean;
            showBaseStayPriceRow: boolean;
            showDepartureAllowedRow: boolean;
            showExtraPersonPriceRow: boolean;
            showMinimumStayDurationRow: boolean;
            showMinimumStayPriceRow: boolean;
            showNightPriceRow: boolean;
            showWeekPriceRow: boolean;
        } | null;
        datePricings?: Array<{
            __typename?: 'DatePricing';
            id: string;
            date: any;
            arrivalAllowed: boolean;
            departureAllowed: boolean;
            nightPrice: number;
            extraPersonPrice: number;
            minimumStayDuration: number;
            weekPrice?: number | null;
            baseStayPrice: number;
            minimumStayPrice: number;
        }> | null;
        allotments?: Array<{ __typename?: 'Allotment'; amount: number; id: string; date: any }> | null;
    };
};

export type AllVisitorRegistrationsQueryVariables = Exact<{
    first?: InputMaybe<Scalars['Int']['input']>;
    last?: InputMaybe<Scalars['Int']['input']>;
    startDate?: InputMaybe<Scalars['Date']['input']>;
    endDate?: InputMaybe<Scalars['Date']['input']>;
    partnerId: Scalars['ID']['input'];
}>;

export type AllVisitorRegistrationsQuery = {
    __typename?: 'PartnerQuery';
    partner?: {
        __typename?: 'Partner';
        id: string;
        allVisitorRegistrations?: {
            __typename?: 'VisitorRegistrationConnection';
            exportUrl: string;
            totalCount: number;
            pageInfo: {
                __typename?: 'PageInfo';
                startCursor?: string | null;
                endCursor?: string | null;
                hasNextPage: boolean;
                hasPreviousPage: boolean;
            };
            edges?: Array<{
                __typename?: 'VisitorRegistrationEdge';
                node?: {
                    __typename: 'VisitorRegistration';
                    arrivalDate: any;
                    departureDate: any;
                    firstName: string;
                    lastName: string;
                    city: string;
                    id: string;
                    identificationType: IdentificationTypeEnum;
                    rentalUnit: {
                        __typename?: 'RentalUnit';
                        id: string;
                        name: string;
                        slug: string;
                        code: string;
                        thumbnailUrl?: string | null;
                        hideForPartner: boolean;
                    };
                } | null;
            } | null> | null;
        } | null;
    } | null;
};

export type DeleteVisitorRegistrationMutationVariables = Exact<{
    id: Scalars['ID']['input'];
}>;

export type DeleteVisitorRegistrationMutation = {
    __typename?: 'PartnerMutation';
    deleteVisitorRegistration: { __typename?: 'deleteVisitorRegistrationPayload'; id?: string | null };
};

export type CreateVisitorRegistrationMutationVariables = Exact<{
    firstName: Scalars['String']['input'];
    lastName: Scalars['String']['input'];
    city: Scalars['String']['input'];
    countryCode: Scalars['String']['input'];
    totalPersons: Scalars['Int']['input'];
    identificationType: IdentificationTypeEnum;
    arrivalDate: Scalars['Date']['input'];
    departureDate: Scalars['Date']['input'];
    rentalUnitId: Scalars['ID']['input'];
    bookingId?: InputMaybe<Scalars['ID']['input']>;
    lockoutId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type CreateVisitorRegistrationMutation = {
    __typename?: 'PartnerMutation';
    createVisitorRegistration: {
        __typename?: 'CreateVisitorRegistrationPayload';
        visitorRegistration?: { __typename: 'VisitorRegistration'; id: string } | null;
    };
};

export type MissingVisitorRegistrationsQueryVariables = Exact<{
    partnerId: Scalars['ID']['input'];
}>;

export type MissingVisitorRegistrationsQuery = {
    __typename?: 'PartnerQuery';
    partner?: {
        __typename?: 'Partner';
        id: string;
        missingVisitorRegistrations: Array<
            | {
                  __typename?: 'AllotmentLockout';
                  id: string;
                  startDate: any;
                  endDate: any;
                  rentalUnit: {
                      __typename?: 'RentalUnit';
                      id: string;
                      name: string;
                      slug: string;
                      code: string;
                      thumbnailUrl?: string | null;
                      hideForPartner: boolean;
                  };
              }
            | {
                  __typename?: 'Booking';
                  id: string;
                  arrivalDate: any;
                  departureDate: any;
                  duration: number;
                  amountAdults: number;
                  status: BookingStatusEnum;
                  amountBabies: number;
                  amountChildren: number;
                  amountPets: number;
                  amountYouths: number;
                  rentalUnit: {
                      __typename?: 'RentalUnit';
                      id: string;
                      name: string;
                      slug: string;
                      code: string;
                      thumbnailUrl?: string | null;
                      hideForPartner: boolean;
                  };
                  customer?: {
                      __typename?: 'Customer';
                      firstName?: string | null;
                      lastName?: string | null;
                      address: { __typename?: 'Address'; city?: string | null; countryCode?: string | null };
                  } | null;
              }
        >;
    } | null;
};

export type CreateUploadMutationVariables = Exact<{ [key: string]: never }>;

export type CreateUploadMutation = {
    __typename?: 'PartnerMutation';
    createImageUpload: { __typename?: 'CreateImageUploadPayload'; putUrl: string; uploadId: string };
};

export type TicketStatusFragment = {
    __typename?: 'Ticket';
    status: TicketStatusEnum;
    timeslot: { __typename?: 'Timeslot'; id: string; startDateTime: any; endDateTime: any };
};

export const ActivityPickerEntryFragmentDoc = gql`
    fragment ActivityPickerEntry on Activity {
        id
        name
        slug
        company {
            id
            name
        }
        listImage {
            url
        }
        hideForPartner
    }
`;
export const CompanyPickerEntryFragmentDoc = gql`
    fragment CompanyPickerEntry on Company {
        id
        name
        slug
        listImage {
            url
        }
        activities {
            ...ActivityPickerEntry
        }
    }
    ${ActivityPickerEntryFragmentDoc}
`;
export const BalanceItemFragmentDoc = gql`
    fragment BalanceItem on PartnerBalanceItem {
        id
        amount
        createdAt
        ... on PartnerBookingInvoice {
            invoiceNumber
            downloadPdfUrl
            downloadXlsxUrl
            periodStartDate
            periodEndDate
        }
        ... on PartnerTicketInvoice {
            invoiceNumber
            downloadPdfUrl
            downloadXlsxUrl
            periodStartDate
            periodEndDate
        }
        ... on PartnerBalancePayment {
            batchType
        }
        ... on PartnerBalanceCorrection {
            description
        }
    }
`;
export const AttributeCategoryFragmentDoc = gql`
    fragment AttributeCategory on AttributeCategory {
        id
        allowMultiple
        name
        unitAttributes: attributes(type: RENTAL_UNIT, subtype: $subtype) {
            id
            name
            type
        }
        accoAttributes: attributes(type: ACCOMMODATION) {
            id
            name
            type
        }
    }
`;
export const PeriodRulesFragmentDoc = gql`
    fragment PeriodRules on DatePricingModifier {
        id
        startDate
        endDate
        minDuration
        maxDuration
        type
        value
        valueType
    }
`;
export const SpecialParticipationFragmentDoc = gql`
    fragment SpecialParticipation on SpecialParticipation {
        id
        blockedArrivalDates
        optInAcceptedAt
        optInRejectedAt
        special {
            id
            arrivalFrom
            arrivalUntil
            description
            name
            optInRequired
            partnerConditions
        }
        trips(startDate: $start, endDate: $end) {
            date
            duration
            rentalSumPartner
        }
    }
`;
export const TripColumnFragmentDoc = gql`
    fragment TripColumn on TripPricing {
        duration
        id
        price
        date
    }
`;
export const NavigationUserFragmentDoc = gql`
    fragment NavigationUser on User {
        id
        fullName
        isBackofficeUser
        email
        impersonatedBy {
            fullName
        }
        partners {
            id
            accommodations {
                rentalUnits {
                    id
                    name
                }
            }
        }
    }
`;
export const PriceColumnPricingFragmentDoc = gql`
    fragment PriceColumnPricing on DatePricing {
        id
        date
        arrivalAllowed
        departureAllowed
        nightPrice
        extraPersonPrice
        minimumStayDuration
        weekPrice
        baseStayPrice
        minimumStayPrice
    }
`;
export const PricesRowVisibilityFragmentDoc = gql`
    fragment PricesRowVisibility on RentalUnit {
        showArrivalAllowedRow
        showBaseStayPriceRow
        showDepartureAllowedRow
        showExtraPersonPriceRow
        showMinimumStayDurationRow
        showMinimumStayPriceRow
        showNightPriceRow
        showWeekPriceRow
    }
`;
export const PriceColumnAllotmentFragmentDoc = gql`
    fragment PriceColumnAllotment on Allotment {
        amount
        id
        date
    }
`;
export const AllotmentsBookingFragmentDoc = gql`
    fragment AllotmentsBooking on Booking {
        id
        totalPrice
        customer {
            id
            firstName
            lastName
        }
        status
        startDate: arrivalDate
        endDate: departureDate
        isActive @client
        isHovering @client
    }
`;
export const AllotmentsLockoutFragmentDoc = gql`
    fragment AllotmentsLockout on AllotmentLockout {
        id
        type
        comment
        source
        startDate
        endDate
        isDragging @client
        isActive @client
        isHovering @client
    }
`;
export const PricingFragmentDoc = gql`
    fragment Pricing on RentalUnit {
        id
        ...PricesRowVisibility
        allotments(startDate: $start, endDate: $end) @connection(key: "allotments") {
            ...PriceColumnAllotment
        }
        bookings(startDate: $start, endDate: $end) {
            ...AllotmentsBooking
        }
        allotmentLockouts(startDate: $start, endDate: $end) {
            ...AllotmentsLockout
        }
    }
    ${PricesRowVisibilityFragmentDoc}
    ${PriceColumnAllotmentFragmentDoc}
    ${AllotmentsBookingFragmentDoc}
    ${AllotmentsLockoutFragmentDoc}
`;
export const ActivityTimeslotFragmentDoc = gql`
    fragment ActivityTimeslot on Timeslot {
        id
        startDateTime
        endDateTime
        label(locale: "nl")
        deLabel: label(locale: "de")
        enLabel: label(locale: "en")
        allotment
        rateGroup {
            id
            canBuyTickets
        }
    }
`;
export const TimeslotsRateGroupsFragmentDoc = gql`
    fragment TimeslotsRateGroups on ActivityRateGroup {
        id
        name
        canBuyTickets
    }
`;
export const ActivityTimeslotsFragmentDoc = gql`
    fragment ActivityTimeslots on Activity {
        id
        timeslots(startDate: $startDate, endDate: $endDate) {
            ...ActivityTimeslot
        }
        activityRateGroups {
            ...TimeslotsRateGroups
        }
    }
    ${ActivityTimeslotFragmentDoc}
    ${TimeslotsRateGroupsFragmentDoc}
`;
export const UnitDetailsFragmentDoc = gql`
    fragment UnitDetails on RentalUnit {
        id
        name
        slug
        code
        thumbnailUrl
        hideForPartner
    }
`;
export const BookingListItemFragmentDoc = gql`
    fragment BookingListItem on Booking {
        id
        number
        amountAdults
        amountYouths
        amountChildren
        amountBabies
        amountPets
        departureDate
        arrivalDate
        updatedAt
        customerComment
        status
        customer {
            id
            firstName
            lastName
        }
        partnerPriceLines {
            category
            totalPrice
        }
        duration
        rentalUnit {
            ...UnitDetails
        }
        totalPrice
    }
    ${UnitDetailsFragmentDoc}
`;
export const BookingEdgeFragmentDoc = gql`
    fragment BookingEdge on BookingEdge {
        cursor
        node {
            ...BookingListItem
        }
    }
    ${BookingListItemFragmentDoc}
`;
export const BookingConnectionFragmentDoc = gql`
    fragment BookingConnection on BookingConnection {
        edges {
            ...BookingEdge
        }
        pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
        }
        totalCount
    }
    ${BookingEdgeFragmentDoc}
`;
export const AccPickerEntryFragmentDoc = gql`
    fragment AccPickerEntry on Accommodation {
        id
        name
        slug
        id
        rentalUnits {
            slug
            ...UnitDetails
        }
    }
    ${UnitDetailsFragmentDoc}
`;
export const RentalUnitInfoFragmentDoc = gql`
    fragment RentalUnitInfo on RentalUnit {
        id
        slug
        type
        datePricingStartDate
        name
        showAllotmentLockouts
    }
`;
export const AccommodationEntryFragmentDoc = gql`
    fragment AccommodationEntry on Accommodation {
        ...AccPickerEntry
        rentalUnits {
            ...RentalUnitInfo
            slug
        }
    }
    ${AccPickerEntryFragmentDoc}
    ${RentalUnitInfoFragmentDoc}
`;
export const RentalUnitMetaDataFragmentDoc = gql`
    fragment RentalUnitMetaData on RentalUnit {
        id
        showAllotmentLockouts
        lastDatePricingDate
        datePricingStartDate
        datePricingEndDate
        type
        maxAllotment
        rentalUnitContracts {
            id
            approvalDate
            startDate
        }
    }
`;
export const PartnerRegistrationDetailsFragmentDoc = gql`
    fragment PartnerRegistrationDetails on PartnerRegistration {
        id
    }
`;
export const ReviewFragmentDoc = gql`
    fragment Review on Review {
        id
        createdAt
        title
        average
        enabled
        language
        city
        reply
        rentalUnit {
            ...UnitDetails
        }
    }
    ${UnitDetailsFragmentDoc}
`;
export const ActivityInfoFragmentDoc = gql`
    fragment ActivityInfo on Activity {
        id
        slug
        name
        enabled
    }
`;
export const RateFragmentDoc = gql`
    fragment Rate on Rate {
        id
        name(locale: "nl")
        deName: name(locale: "de")
        enName: name(locale: "en")
        price
        position
    }
`;
export const ActivityRateGroupFragmentDoc = gql`
    fragment ActivityRateGroup on ActivityRateGroup {
        id
        name
        canBuyTickets
        releaseHours
        qrScansEnabled
        rates {
            ...Rate
        }
    }
    ${RateFragmentDoc}
`;
export const ActivityRatesFragmentDoc = gql`
    fragment ActivityRates on Activity {
        id
        activityRateGroups {
            ...ActivityRateGroup
        }
    }
    ${ActivityRateGroupFragmentDoc}
`;
export const ActivityDescriptionFragmentDoc = gql`
    fragment ActivityDescription on Activity {
        id
        descriptionNL: description(locale: "nl")
        descriptionDE: description(locale: "de")
        descriptionEN: description(locale: "en")
        descriptionPending
        descriptionParticularsNL: descriptionParticulars(locale: "nl")
        descriptionParticularsDE: descriptionParticulars(locale: "de")
        descriptionParticularsEN: descriptionParticulars(locale: "en")
        descriptionParticularsPending
    }
`;
export const ActivityDetailsFragmentDoc = gql`
    fragment ActivityDetails on Activity {
        id
        nameNL: name(locale: "nl")
        nameDE: name(locale: "de")
        nameEN: name(locale: "en")
        themes {
            nameNL: name(locale: "nl")
        }
        targetAudiences
        maxCapacity
        minCapacity
        externalBookingUrl
        hideForPartner
    }
`;
export const ActivityImageFragmentDoc = gql`
    fragment ActivityImage on ActivityImage {
        id
        size
        url
        dimensions
        position
        previewUrl
    }
`;
export const ActivityImagesFragmentDoc = gql`
    fragment ActivityImages on Activity {
        id
        name
        videoUrl
        mainImage {
            id
            size
            dimensions
            url
            previewUrl
        }
        listImage {
            id
            size
            dimensions
            url
            previewUrl
        }
        images {
            ...ActivityImage
        }
    }
    ${ActivityImageFragmentDoc}
`;
export const AddressFragmentDoc = gql`
    fragment Address on Address {
        city
        countryName
        number
        postalCode
        street
        countryCode
    }
`;
export const ActivityLocationFragmentDoc = gql`
    fragment ActivityLocation on Activity {
        id
        slug
        coordinates {
            lat
            lon
        }
        descriptionLocationNL: descriptionLocation(locale: "nl")
        descriptionLocationDE: descriptionLocation(locale: "de")
        descriptionLocationEN: descriptionLocation(locale: "en")
        descriptionLocationPending
        locationAddress {
            ...Address
        }
        company {
            coordinates {
                lat
                lon
            }
        }
    }
    ${AddressFragmentDoc}
`;
export const AgreementsFragmentDoc = gql`
    fragment Agreements on ActivityAgreement {
        id
        agreement {
            name
            commissionPercentage
            agreementUrl
        }
        startDate
        endDate
        approvalDate
    }
`;
export const TimeslotsWizardActivityFragmentDoc = gql`
    fragment TimeslotsWizardActivity on Activity {
        id
        activityRateGroups {
            id
            canBuyTickets
            name
        }
    }
`;
export const CustomerDetailsFragmentDoc = gql`
    fragment CustomerDetails on Customer {
        id
        firstName
        lastName
        birthdate
        phoneNumber
        email
        address {
            city
            countryCode
            countryName
            number
            postalCode
            street
        }
    }
`;
export const BookingDetailsFragmentDoc = gql`
    fragment BookingDetails on Booking {
        id
        customer {
            ...CustomerDetails
        }
        number
        amountAdults
        amountYouths
        amountChildren
        amountBabies
        amountPets
        departureDate
        arrivalDate
        createdAt
        status
        totalPrice
        partnerPriceLines {
            totalPrice
            label
            modifier
            unitPrice
            totalPrice
            category
        }
        duration
        rentalUnit {
            ...UnitDetails
            accommodation {
                id
                name
                checkInStartTime
                checkInEndTime
                checkOutTime
            }
        }
        customerComment
    }
    ${CustomerDetailsFragmentDoc}
    ${UnitDetailsFragmentDoc}
`;
export const CompanyInfoFragmentDoc = gql`
    fragment CompanyInfo on Company {
        id
        slug
        name
        enabled
    }
`;
export const CompanyRateGroupFragmentDoc = gql`
    fragment CompanyRateGroup on CompanyRateGroup {
        id
        startDate
        endDate
        canBuyTickets
        rates {
            ...Rate
        }
    }
    ${RateFragmentDoc}
`;
export const CompanyRatesFragmentDoc = gql`
    fragment CompanyRates on Company {
        id
        companyRateGroups {
            ...CompanyRateGroup
        }
    }
    ${CompanyRateGroupFragmentDoc}
`;
export const TimesOpenClosedFragmentDoc = gql`
    fragment TimesOpenClosed on TimeOpenClosed {
        timeOpen
        timeClosed
    }
`;
export const OperatingHoursFragmentDoc = gql`
    fragment OperatingHours on OperatingHours {
        startDate
        endDate
        monday {
            ...TimesOpenClosed
        }
        tuesday {
            ...TimesOpenClosed
        }
        wednesday {
            ...TimesOpenClosed
        }
        thursday {
            ...TimesOpenClosed
        }
        friday {
            ...TimesOpenClosed
        }
        saturday {
            ...TimesOpenClosed
        }
        sunday {
            ...TimesOpenClosed
        }
    }
    ${TimesOpenClosedFragmentDoc}
`;
export const CompanyHoursFragmentDoc = gql`
    fragment CompanyHours on Company {
        id
        normalOperatingHours {
            ...OperatingHours
        }
    }
    ${OperatingHoursFragmentDoc}
`;
export const CompanySpecialHoursFragmentDoc = gql`
    fragment CompanySpecialHours on Company {
        id
        specialOperatingHours {
            ...OperatingHours
        }
    }
    ${OperatingHoursFragmentDoc}
`;
export const CompanyDescriptionFragmentDoc = gql`
    fragment CompanyDescription on Company {
        id
        descriptionNL: description(locale: "nl")
        descriptionDE: description(locale: "de")
        descriptionEN: description(locale: "en")
        descriptionPending
        descriptionParticularsNL: descriptionParticulars(locale: "nl")
        descriptionParticularsDE: descriptionParticulars(locale: "de")
        descriptionParticularsEN: descriptionParticulars(locale: "en")
        descriptionParticularsPending
    }
`;
export const CompanyDetailsFragmentDoc = gql`
    fragment CompanyDetails on Company {
        id
        name
        email
        phoneNumber
        websiteUrl
        categories {
            nameNL: name(locale: "nl")
        }
        targetAudiences
        socialsTwitterUrl
        socialsInstagramUrl
        socialsFacebookUrl
        reservations {
            contactName
            phoneNumbers
            emails
        }
    }
`;
export const CompanyImageFragmentDoc = gql`
    fragment CompanyImage on CompanyImage {
        id
        size
        url
        dimensions
        position
        previewUrl
    }
`;
export const CompanyImagesFragmentDoc = gql`
    fragment CompanyImages on Company {
        id
        name
        videoUrl
        mainImage {
            id
            size
            dimensions
            url
            previewUrl
        }
        logoImage {
            id
            size
            dimensions
            url
            previewUrl
        }
        listImage {
            id
            size
            dimensions
            url
            previewUrl
        }
        images {
            ...CompanyImage
        }
    }
    ${CompanyImageFragmentDoc}
`;
export const CompanyLocationFragmentDoc = gql`
    fragment CompanyLocation on Company {
        id
        slug
        address {
            ...Address
        }
        coordinates {
            lat
            lon
        }
    }
    ${AddressFragmentDoc}
`;
export const TicketEdgeFragmentDoc = gql`
    fragment TicketEdge on TicketEdge {
        cursor
        node {
            id
            customer {
                id
                firstName
                lastName
            }
            priceLines {
                label
                quantity
                totalPrice
            }
            rateLines {
                rateName
                amount
                unitPrice
            }
            createdAt
            status
            timeslot {
                activity {
                    ...ActivityPickerEntry
                }
                id
                startDateTime
                endDateTime
                rateGroup {
                    id
                    name
                    rates {
                        id
                        name
                    }
                }
            }
        }
    }
    ${ActivityPickerEntryFragmentDoc}
`;
export const TicketConnectionFragmentDoc = gql`
    fragment TicketConnection on TicketConnection {
        edges {
            ...TicketEdge
        }
        pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
        }
        totalCount
    }
    ${TicketEdgeFragmentDoc}
`;
export const AvailabilityMetaDataFragmentDoc = gql`
    fragment AvailabilityMetaData on RentalUnit {
        showAllotmentLockouts
        datePricingStartDate
        datePricingEndDate
        maxAllotment
        lastDatePricingDate
    }
`;
export const AccommodationInfoFragmentDoc = gql`
    fragment AccommodationInfo on Accommodation {
        id
        slug
        name
        type
        hasPublicPage
        rentalUnits {
            id
            type
        }
    }
`;
export const AccommodationDescriptionFragmentDoc = gql`
    fragment AccommodationDescription on Accommodation {
        id
        descriptionGeneralNL: descriptionGeneral(locale: "nl")
        descriptionGeneralDE: descriptionGeneral(locale: "de")
        descriptionGeneralEN: descriptionGeneral(locale: "en")
        descriptionGeneralPending
        type
    }
`;
export const AccommodationDetailsFragmentDoc = gql`
    fragment AccommodationDetails on RentalUnit {
        id
        accommodation {
            id
            slug
            hasPublicPage
            name
            type
            reservations {
                contactName
                phoneNumbers
                emails
            }
            caretaker {
                contactName
                phoneNumbers
                emails
            }
            checkInStartTime
            checkInEndTime
            checkOutTime
            publicReservationsEmail
            publicReservationsPhoneNumber
            publicReservationsWebsiteUrl
        }
    }
`;
export const ImageFragmentDoc = gql`
    fragment Image on AccommodationImage {
        id
        size
        url
        dimensions
        position
        category
        previewUrl
    }
`;
export const AccommodationImagesFragmentDoc = gql`
    fragment AccommodationImages on Accommodation {
        id
        name
        type
        mainImage {
            id
            size
            url
            dimensions
            previewUrl
        }
        listImage {
            id
            size
            url
            dimensions
            previewUrl
        }
        images {
            ...Image
        }
        rentalUnits {
            id
        }
    }
    ${ImageFragmentDoc}
`;
export const AttributesFragmentDoc = gql`
    fragment Attributes on RentalUnit {
        id
        name
        accommodation {
            id
            type
            name
            attributes {
                id
            }
            rentalUnits {
                id
            }
        }
        attributes {
            id
            name
            type
            category {
                __typename
                id
                name
            }
        }
    }
`;
export const CapacityFragmentDoc = gql`
    fragment Capacity on RentalUnit {
        id
        slug
        code
        type
        name
        minOccupancy
        maxOccupancy
        maxExtraBabyOccupancy
        includedOccupancy
        petsAllowed
        amountBedrooms
        amountBeds
        amountBathrooms
        amountToilets
        livingArea
        outdoorArea
        maxVehicleSize
    }
`;
export const CompactInfoFragmentDoc = gql`
    fragment CompactInfo on RentalUnit {
        id
        slug
        code
        type
        securityDeposit
        handleSecurityDepositPayment
        releaseDays
        name
        tagline: tagline(locale: "nl")
        taglineDE: tagline(locale: "de")
        taglineEN: tagline(locale: "en")
        taglinePending
        hideReviews
        accommodation {
            id
            publicReservationsEmail
            publicReservationsPhoneNumber
            publicReservationsWebsiteUrl
            reservations {
                contactName
                phoneNumbers
                emails
            }
            caretaker {
                contactName
                phoneNumbers
                emails
            }
            checkInStartTime
            checkInEndTime
            checkOutTime
        }
    }
`;
export const ContractsFragmentDoc = gql`
    fragment Contracts on RentalUnitContract {
        id
        contract {
            name
            bookingType
            toBePaidThroughTor
            commissionPercentage
            contractUrl
            surchargePaymentType
        }
        startDate
        endDate
        approvalDate
    }
`;
export const IcalFragmentDoc = gql`
    fragment Ical on RentalUnit {
        id
        slug
        type
        icalImportFeeds {
            icalImportUrl
            source
        }
        icalFeedUrl
    }
`;
export const KeyHolderFragmentDoc = gql`
    fragment KeyHolder on RentalUnit {
        id
        type
        slug
        keyHolderExplanationDE: keyHolderExplanation(locale: "de")
        keyHolderExplanationNL: keyHolderExplanation(locale: "nl")
        keyHolderExplanationEN: keyHolderExplanation(locale: "en")
        keyHolderExplanationPending
        accommodation {
            id
            keyHolderAddress {
                city
                street
                postalCode
                number
                countryCode
            }
            keyHolder {
                contactName
                phoneNumbers
                emails
            }
        }
    }
`;
export const LocationFragmentDoc = gql`
    fragment Location on RentalUnit {
        id
        slug
        type
        accommodation {
            id
            name
            address {
                city
                street
                number
                postalCode
                countryCode
            }
            descriptionNL: descriptionLocation(locale: "nl")
            descriptionDE: descriptionLocation(locale: "de")
            descriptionEN: descriptionLocation(locale: "en")
            descriptionPending: descriptionLocationPending
            coordinates {
                lat
                lon
            }
        }
    }
`;
export const OwnerFragmentDoc = gql`
    fragment Owner on RentalUnit {
        id
        accommodation {
            id
            name
            ownerName
            ownerProfileVisible
            ownerIntroduction(locale: "nl")
            ownerIntroductionEN: ownerIntroduction(locale: "en")
            ownerIntroductionDE: ownerIntroduction(locale: "de")
            ownerIntroductionPending
            ownerTips(locale: "nl")
            ownerTipsEN: ownerTips(locale: "en")
            ownerTipsDE: ownerTips(locale: "de")
            ownerTipsPending
            ownerName
            ownerImage {
                id
                url
            }
            rentalUnits {
                id
            }
        }
    }
`;
export const PriceConfigFragmentDoc = gql`
    fragment PriceConfig on RentalUnit {
        id
        lastminuteDiscountEnabled
        earlyBookDiscountEnabled
        lastminuteDiscount1Week
        lastminuteDiscount2Week
        lastminuteDiscount3Week
        lastminuteDiscount4Week
        earlyBookDiscount6Months
        earlyBookDiscount12Months
        flexibleArrival
        flexibleDeparture
        flexCancellationDays
        ...PricesRowVisibility
    }
    ${PricesRowVisibilityFragmentDoc}
`;
export const AvailableSurchargeFragmentDoc = gql`
    fragment AvailableSurcharge on Surcharge {
        id
        allowedCalculations
        description
        nameNL: name(locale: "nl")
        nameEN: name(locale: "en")
        nameDE: name(locale: "de")
        canBePaidThroughTor
    }
`;
export const RentalUnitSurchargeFragmentDoc = gql`
    fragment RentalUnitSurcharge on RentalUnitSurcharge {
        id
        calculation
        endDate
        maxAmount
        minAmount
        startDate
        unitPrice
        handlePayment
        surcharge {
            ...AvailableSurcharge
        }
    }
    ${AvailableSurchargeFragmentDoc}
`;
export const UnitDescriptionFragmentDoc = gql`
    fragment UnitDescription on RentalUnit {
        id
        descriptionLayoutNL: descriptionLayout(locale: "nl")
        descriptionLayoutDE: descriptionLayout(locale: "de")
        descriptionLayoutEN: descriptionLayout(locale: "en")
        descriptionLayoutPending
        descriptionExtrasNL: descriptionExtras(locale: "nl")
        descriptionExtrasDE: descriptionExtras(locale: "de")
        descriptionExtrasEN: descriptionExtras(locale: "en")
        descriptionExtrasPending
        descriptionParticularsNL: descriptionParticulars(locale: "nl")
        descriptionParticularsDE: descriptionParticulars(locale: "de")
        descriptionParticularsEN: descriptionParticulars(locale: "en")
        descriptionParticularsPending
    }
`;
export const RentalUnitDetailsFragmentDoc = gql`
    fragment RentalUnitDetails on RentalUnit {
        id
        slug
        code
        type
        securityDeposit
        handleSecurityDepositPayment
        releaseDays
        name
        tagline: tagline(locale: "nl")
        taglineDE: tagline(locale: "de")
        taglineEN: tagline(locale: "en")
        taglinePending
        keyHolderExplanationDE: keyHolderExplanation(locale: "de")
        keyHolderExplanationNL: keyHolderExplanation(locale: "nl")
        keyHolderExplanationEN: keyHolderExplanation(locale: "en")
        keyHolderExplanationPending
        hideReviews
        hideForPartner
    }
`;
export const UnitImagesFragmentDoc = gql`
    fragment UnitImages on RentalUnit {
        id
        type
        mainImage {
            id
            size
            url
            dimensions
            previewUrl
        }
        listImage {
            id
            size
            url
            dimensions
            previewUrl
        }
        images {
            ...Image
        }
        fullCircleImageUrl
        videoUrl
    }
    ${ImageFragmentDoc}
`;
export const PricesWizardRentalUnitFragmentDoc = gql`
    fragment PricesWizardRentalUnit on RentalUnit {
        id
        lastDatePricingDate
        datePricingStartDate
        datePricingEndDate
        ...PricesRowVisibility
    }
    ${PricesRowVisibilityFragmentDoc}
`;
export const TicketStatusFragmentDoc = gql`
    fragment TicketStatus on Ticket {
        status
        timeslot {
            id
            startDateTime
            endDateTime
        }
    }
`;
export const BulkEditAllotmentsDocument = gql`
    mutation bulkEditAllotments($input: BulkEditAllotmentsInput!) {
        bulkEditAllotments(input: $input) {
            allotments {
                ...PriceColumnAllotment
            }
        }
    }
    ${PriceColumnAllotmentFragmentDoc}
`;

/**
 * __useBulkEditAllotmentsMutation__
 *
 * To run a mutation, you first call `useBulkEditAllotmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditAllotmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditAllotmentsMutation, { data, loading, error }] = useBulkEditAllotmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkEditAllotmentsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<BulkEditAllotmentsMutation, BulkEditAllotmentsMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<BulkEditAllotmentsMutation, BulkEditAllotmentsMutationVariables>(
        BulkEditAllotmentsDocument,
        options
    );
}
export type BulkEditAllotmentsMutationHookResult = ReturnType<typeof useBulkEditAllotmentsMutation>;
export type BulkEditAllotmentsMutationResult = Apollo.MutationResult<BulkEditAllotmentsMutation>;
export type BulkEditAllotmentsMutationOptions = Apollo.BaseMutationOptions<
    BulkEditAllotmentsMutation,
    BulkEditAllotmentsMutationVariables
>;
export const AllotmentSidebarDocument = gql`
    query AllotmentSidebar($unitSlug: String!, $start: Date!, $end: Date!) {
        rentalUnit(slug: $unitSlug) {
            id
            type
            bookings(startDate: $start, endDate: $end) {
                id
                customer {
                    id
                    firstName
                    lastName
                }
                amountAdults
                amountBabies
                amountChildren
                duration
                arrivalDate
                departureDate
                totalPrice
                status
            }
        }
    }
`;

/**
 * __useAllotmentSidebarQuery__
 *
 * To run a query within a React component, call `useAllotmentSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllotmentSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllotmentSidebarQuery({
 *   variables: {
 *      unitSlug: // value for 'unitSlug'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useAllotmentSidebarQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<AllotmentSidebarQuery, AllotmentSidebarQueryVariables> &
        ({ variables: AllotmentSidebarQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<AllotmentSidebarQuery, AllotmentSidebarQueryVariables>(
        AllotmentSidebarDocument,
        options
    );
}
export function useAllotmentSidebarLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllotmentSidebarQuery, AllotmentSidebarQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<AllotmentSidebarQuery, AllotmentSidebarQueryVariables>(
        AllotmentSidebarDocument,
        options
    );
}
export function useAllotmentSidebarSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<AllotmentSidebarQuery, AllotmentSidebarQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<AllotmentSidebarQuery, AllotmentSidebarQueryVariables>(
        AllotmentSidebarDocument,
        options
    );
}
export type AllotmentSidebarQueryHookResult = ReturnType<typeof useAllotmentSidebarQuery>;
export type AllotmentSidebarLazyQueryHookResult = ReturnType<typeof useAllotmentSidebarLazyQuery>;
export type AllotmentSidebarSuspenseQueryHookResult = ReturnType<typeof useAllotmentSidebarSuspenseQuery>;
export type AllotmentSidebarQueryResult = Apollo.QueryResult<AllotmentSidebarQuery, AllotmentSidebarQueryVariables>;
export const EditAllotmentDocument = gql`
    mutation editAllotment($input: EditAllotmentInput!) {
        editAllotment(input: $input) {
            allotment {
                ...PriceColumnAllotment
            }
        }
    }
    ${PriceColumnAllotmentFragmentDoc}
`;

/**
 * __useEditAllotmentMutation__
 *
 * To run a mutation, you first call `useEditAllotmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAllotmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAllotmentMutation, { data, loading, error }] = useEditAllotmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAllotmentMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<EditAllotmentMutation, EditAllotmentMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditAllotmentMutation, EditAllotmentMutationVariables>(
        EditAllotmentDocument,
        options
    );
}
export type EditAllotmentMutationHookResult = ReturnType<typeof useEditAllotmentMutation>;
export type EditAllotmentMutationResult = Apollo.MutationResult<EditAllotmentMutation>;
export type EditAllotmentMutationOptions = Apollo.BaseMutationOptions<
    EditAllotmentMutation,
    EditAllotmentMutationVariables
>;
export const BookingSidebarDocument = gql`
    query BookingSidebar($id: ID!) {
        booking(id: $id) {
            id
            duration
            amountAdults
            amountYouths
            amountChildren
            amountBabies
            amountPets
            customerComment
            partnerPriceLines {
                label
                totalPrice
            }
            totalPrice
        }
    }
`;

/**
 * __useBookingSidebarQuery__
 *
 * To run a query within a React component, call `useBookingSidebarQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingSidebarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingSidebarQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookingSidebarQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<BookingSidebarQuery, BookingSidebarQueryVariables> &
        ({ variables: BookingSidebarQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<BookingSidebarQuery, BookingSidebarQueryVariables>(
        BookingSidebarDocument,
        options
    );
}
export function useBookingSidebarLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookingSidebarQuery, BookingSidebarQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<BookingSidebarQuery, BookingSidebarQueryVariables>(
        BookingSidebarDocument,
        options
    );
}
export function useBookingSidebarSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<BookingSidebarQuery, BookingSidebarQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<BookingSidebarQuery, BookingSidebarQueryVariables>(
        BookingSidebarDocument,
        options
    );
}
export type BookingSidebarQueryHookResult = ReturnType<typeof useBookingSidebarQuery>;
export type BookingSidebarLazyQueryHookResult = ReturnType<typeof useBookingSidebarLazyQuery>;
export type BookingSidebarSuspenseQueryHookResult = ReturnType<typeof useBookingSidebarSuspenseQuery>;
export type BookingSidebarQueryResult = Apollo.QueryResult<BookingSidebarQuery, BookingSidebarQueryVariables>;
export const BulkEditDatePricingNightPriceDocument = gql`
    mutation BulkEditDatePricingNightPrice($input: BulkEditDatePricingNightPriceInput!) {
        bulkEditDatePricingNightPrice(input: $input) {
            datePricings {
                id
                nightPrice
            }
        }
    }
`;

/**
 * __useBulkEditDatePricingNightPriceMutation__
 *
 * To run a mutation, you first call `useBulkEditDatePricingNightPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditDatePricingNightPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditDatePricingNightPriceMutation, { data, loading, error }] = useBulkEditDatePricingNightPriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkEditDatePricingNightPriceMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        BulkEditDatePricingNightPriceMutation,
        BulkEditDatePricingNightPriceMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        BulkEditDatePricingNightPriceMutation,
        BulkEditDatePricingNightPriceMutationVariables
    >(BulkEditDatePricingNightPriceDocument, options);
}
export type BulkEditDatePricingNightPriceMutationHookResult = ReturnType<
    typeof useBulkEditDatePricingNightPriceMutation
>;
export type BulkEditDatePricingNightPriceMutationResult = Apollo.MutationResult<BulkEditDatePricingNightPriceMutation>;
export type BulkEditDatePricingNightPriceMutationOptions = Apollo.BaseMutationOptions<
    BulkEditDatePricingNightPriceMutation,
    BulkEditDatePricingNightPriceMutationVariables
>;
export const BulkEditDatePricingExtraPersonPriceDocument = gql`
    mutation BulkEditDatePricingExtraPersonPrice($input: BulkEditDatePricingExtraPersonPriceInput!) {
        bulkEditDatePricingExtraPersonPrice(input: $input) {
            datePricings {
                id
                extraPersonPrice
            }
        }
    }
`;

/**
 * __useBulkEditDatePricingExtraPersonPriceMutation__
 *
 * To run a mutation, you first call `useBulkEditDatePricingExtraPersonPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditDatePricingExtraPersonPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditDatePricingExtraPersonPriceMutation, { data, loading, error }] = useBulkEditDatePricingExtraPersonPriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkEditDatePricingExtraPersonPriceMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        BulkEditDatePricingExtraPersonPriceMutation,
        BulkEditDatePricingExtraPersonPriceMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        BulkEditDatePricingExtraPersonPriceMutation,
        BulkEditDatePricingExtraPersonPriceMutationVariables
    >(BulkEditDatePricingExtraPersonPriceDocument, options);
}
export type BulkEditDatePricingExtraPersonPriceMutationHookResult = ReturnType<
    typeof useBulkEditDatePricingExtraPersonPriceMutation
>;
export type BulkEditDatePricingExtraPersonPriceMutationResult =
    Apollo.MutationResult<BulkEditDatePricingExtraPersonPriceMutation>;
export type BulkEditDatePricingExtraPersonPriceMutationOptions = Apollo.BaseMutationOptions<
    BulkEditDatePricingExtraPersonPriceMutation,
    BulkEditDatePricingExtraPersonPriceMutationVariables
>;
export const BulkEditDatePricingArrivalAllowedDocument = gql`
    mutation BulkEditDatePricingArrivalAllowed($input: BulkEditDatePricingArrivalAllowedInput!) {
        bulkEditDatePricingArrivalAllowed(input: $input) {
            datePricings {
                id
                arrivalAllowed
            }
        }
    }
`;

/**
 * __useBulkEditDatePricingArrivalAllowedMutation__
 *
 * To run a mutation, you first call `useBulkEditDatePricingArrivalAllowedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditDatePricingArrivalAllowedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditDatePricingArrivalAllowedMutation, { data, loading, error }] = useBulkEditDatePricingArrivalAllowedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkEditDatePricingArrivalAllowedMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        BulkEditDatePricingArrivalAllowedMutation,
        BulkEditDatePricingArrivalAllowedMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        BulkEditDatePricingArrivalAllowedMutation,
        BulkEditDatePricingArrivalAllowedMutationVariables
    >(BulkEditDatePricingArrivalAllowedDocument, options);
}
export type BulkEditDatePricingArrivalAllowedMutationHookResult = ReturnType<
    typeof useBulkEditDatePricingArrivalAllowedMutation
>;
export type BulkEditDatePricingArrivalAllowedMutationResult =
    Apollo.MutationResult<BulkEditDatePricingArrivalAllowedMutation>;
export type BulkEditDatePricingArrivalAllowedMutationOptions = Apollo.BaseMutationOptions<
    BulkEditDatePricingArrivalAllowedMutation,
    BulkEditDatePricingArrivalAllowedMutationVariables
>;
export const BulkEditDatePricingDepartureAllowedDocument = gql`
    mutation BulkEditDatePricingDepartureAllowed($input: BulkEditDatePricingDepartureAllowedInput!) {
        bulkEditDatePricingDepartureAllowed(input: $input) {
            datePricings {
                id
                departureAllowed
            }
        }
    }
`;

/**
 * __useBulkEditDatePricingDepartureAllowedMutation__
 *
 * To run a mutation, you first call `useBulkEditDatePricingDepartureAllowedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditDatePricingDepartureAllowedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditDatePricingDepartureAllowedMutation, { data, loading, error }] = useBulkEditDatePricingDepartureAllowedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkEditDatePricingDepartureAllowedMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        BulkEditDatePricingDepartureAllowedMutation,
        BulkEditDatePricingDepartureAllowedMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        BulkEditDatePricingDepartureAllowedMutation,
        BulkEditDatePricingDepartureAllowedMutationVariables
    >(BulkEditDatePricingDepartureAllowedDocument, options);
}
export type BulkEditDatePricingDepartureAllowedMutationHookResult = ReturnType<
    typeof useBulkEditDatePricingDepartureAllowedMutation
>;
export type BulkEditDatePricingDepartureAllowedMutationResult =
    Apollo.MutationResult<BulkEditDatePricingDepartureAllowedMutation>;
export type BulkEditDatePricingDepartureAllowedMutationOptions = Apollo.BaseMutationOptions<
    BulkEditDatePricingDepartureAllowedMutation,
    BulkEditDatePricingDepartureAllowedMutationVariables
>;
export const BulkEditDatePricingMinimumStayDurationDocument = gql`
    mutation BulkEditDatePricingMinimumStayDuration($input: BulkEditDatePricingMinimumStayDurationInput!) {
        bulkEditDatePricingMinimumStayDuration(input: $input) {
            datePricings {
                id
                minimumStayDuration
            }
        }
    }
`;

/**
 * __useBulkEditDatePricingMinimumStayDurationMutation__
 *
 * To run a mutation, you first call `useBulkEditDatePricingMinimumStayDurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditDatePricingMinimumStayDurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditDatePricingMinimumStayDurationMutation, { data, loading, error }] = useBulkEditDatePricingMinimumStayDurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkEditDatePricingMinimumStayDurationMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        BulkEditDatePricingMinimumStayDurationMutation,
        BulkEditDatePricingMinimumStayDurationMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        BulkEditDatePricingMinimumStayDurationMutation,
        BulkEditDatePricingMinimumStayDurationMutationVariables
    >(BulkEditDatePricingMinimumStayDurationDocument, options);
}
export type BulkEditDatePricingMinimumStayDurationMutationHookResult = ReturnType<
    typeof useBulkEditDatePricingMinimumStayDurationMutation
>;
export type BulkEditDatePricingMinimumStayDurationMutationResult =
    Apollo.MutationResult<BulkEditDatePricingMinimumStayDurationMutation>;
export type BulkEditDatePricingMinimumStayDurationMutationOptions = Apollo.BaseMutationOptions<
    BulkEditDatePricingMinimumStayDurationMutation,
    BulkEditDatePricingMinimumStayDurationMutationVariables
>;
export const BulkEditDatePricingMinimumStayPriceDocument = gql`
    mutation BulkEditDatePricingMinimumStayPrice($input: BulkEditDatePricingMinimumStayPriceInput!) {
        bulkEditDatePricingMinimumStayPrice(input: $input) {
            datePricings {
                id
                minimumStayPrice
            }
        }
    }
`;

/**
 * __useBulkEditDatePricingMinimumStayPriceMutation__
 *
 * To run a mutation, you first call `useBulkEditDatePricingMinimumStayPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditDatePricingMinimumStayPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditDatePricingMinimumStayPriceMutation, { data, loading, error }] = useBulkEditDatePricingMinimumStayPriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkEditDatePricingMinimumStayPriceMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        BulkEditDatePricingMinimumStayPriceMutation,
        BulkEditDatePricingMinimumStayPriceMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        BulkEditDatePricingMinimumStayPriceMutation,
        BulkEditDatePricingMinimumStayPriceMutationVariables
    >(BulkEditDatePricingMinimumStayPriceDocument, options);
}
export type BulkEditDatePricingMinimumStayPriceMutationHookResult = ReturnType<
    typeof useBulkEditDatePricingMinimumStayPriceMutation
>;
export type BulkEditDatePricingMinimumStayPriceMutationResult =
    Apollo.MutationResult<BulkEditDatePricingMinimumStayPriceMutation>;
export type BulkEditDatePricingMinimumStayPriceMutationOptions = Apollo.BaseMutationOptions<
    BulkEditDatePricingMinimumStayPriceMutation,
    BulkEditDatePricingMinimumStayPriceMutationVariables
>;
export const BulkEditDatePricingWeekPriceDocument = gql`
    mutation BulkEditDatePricingWeekPrice($input: BulkEditDatePricingWeekPriceInput!) {
        bulkEditDatePricingWeekPrice(input: $input) {
            datePricings {
                id
                weekPrice
            }
        }
    }
`;

/**
 * __useBulkEditDatePricingWeekPriceMutation__
 *
 * To run a mutation, you first call `useBulkEditDatePricingWeekPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditDatePricingWeekPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditDatePricingWeekPriceMutation, { data, loading, error }] = useBulkEditDatePricingWeekPriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkEditDatePricingWeekPriceMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        BulkEditDatePricingWeekPriceMutation,
        BulkEditDatePricingWeekPriceMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        BulkEditDatePricingWeekPriceMutation,
        BulkEditDatePricingWeekPriceMutationVariables
    >(BulkEditDatePricingWeekPriceDocument, options);
}
export type BulkEditDatePricingWeekPriceMutationHookResult = ReturnType<typeof useBulkEditDatePricingWeekPriceMutation>;
export type BulkEditDatePricingWeekPriceMutationResult = Apollo.MutationResult<BulkEditDatePricingWeekPriceMutation>;
export type BulkEditDatePricingWeekPriceMutationOptions = Apollo.BaseMutationOptions<
    BulkEditDatePricingWeekPriceMutation,
    BulkEditDatePricingWeekPriceMutationVariables
>;
export const BulkEditDatePricingBaseStayPriceDocument = gql`
    mutation BulkEditDatePricingBaseStayPrice($input: BulkEditDatePricingBaseStayPriceInput!) {
        bulkEditDatePricingBaseStayPrice(input: $input) {
            datePricings {
                id
                baseStayPrice
            }
        }
    }
`;

/**
 * __useBulkEditDatePricingBaseStayPriceMutation__
 *
 * To run a mutation, you first call `useBulkEditDatePricingBaseStayPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditDatePricingBaseStayPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditDatePricingBaseStayPriceMutation, { data, loading, error }] = useBulkEditDatePricingBaseStayPriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkEditDatePricingBaseStayPriceMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        BulkEditDatePricingBaseStayPriceMutation,
        BulkEditDatePricingBaseStayPriceMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        BulkEditDatePricingBaseStayPriceMutation,
        BulkEditDatePricingBaseStayPriceMutationVariables
    >(BulkEditDatePricingBaseStayPriceDocument, options);
}
export type BulkEditDatePricingBaseStayPriceMutationHookResult = ReturnType<
    typeof useBulkEditDatePricingBaseStayPriceMutation
>;
export type BulkEditDatePricingBaseStayPriceMutationResult =
    Apollo.MutationResult<BulkEditDatePricingBaseStayPriceMutation>;
export type BulkEditDatePricingBaseStayPriceMutationOptions = Apollo.BaseMutationOptions<
    BulkEditDatePricingBaseStayPriceMutation,
    BulkEditDatePricingBaseStayPriceMutationVariables
>;
export const DayInfoDocument = gql`
    query DayInfo($slug: String!, $date: Date!) {
        rentalUnit(slug: $slug) {
            id
            trips(date: $date) {
                flexType
                duration
                rentalSumPartner
                type
                special {
                    name(locale: "nl")
                }
            }
        }
    }
`;

/**
 * __useDayInfoQuery__
 *
 * To run a query within a React component, call `useDayInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDayInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDayInfoQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useDayInfoQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<DayInfoQuery, DayInfoQueryVariables> &
        ({ variables: DayInfoQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<DayInfoQuery, DayInfoQueryVariables>(DayInfoDocument, options);
}
export function useDayInfoLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DayInfoQuery, DayInfoQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<DayInfoQuery, DayInfoQueryVariables>(DayInfoDocument, options);
}
export function useDayInfoSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<DayInfoQuery, DayInfoQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<DayInfoQuery, DayInfoQueryVariables>(DayInfoDocument, options);
}
export type DayInfoQueryHookResult = ReturnType<typeof useDayInfoQuery>;
export type DayInfoLazyQueryHookResult = ReturnType<typeof useDayInfoLazyQuery>;
export type DayInfoSuspenseQueryHookResult = ReturnType<typeof useDayInfoSuspenseQuery>;
export type DayInfoQueryResult = Apollo.QueryResult<DayInfoQuery, DayInfoQueryVariables>;
export const UploadImportDocument = gql`
    mutation uploadImport($input: UploadTripPricingsInput!, $start: Date!, $end: Date!) {
        uploadTripPricings(input: $input) {
            rentalUnit {
                id
                tripPricings(startDate: $start, endDate: $end) {
                    ...TripColumn
                }
            }
        }
    }
    ${TripColumnFragmentDoc}
`;

/**
 * __useUploadImportMutation__
 *
 * To run a mutation, you first call `useUploadImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImportMutation, { data, loading, error }] = useUploadImportMutation({
 *   variables: {
 *      input: // value for 'input'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useUploadImportMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<UploadImportMutation, UploadImportMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<UploadImportMutation, UploadImportMutationVariables>(
        UploadImportDocument,
        options
    );
}
export type UploadImportMutationHookResult = ReturnType<typeof useUploadImportMutation>;
export type UploadImportMutationResult = Apollo.MutationResult<UploadImportMutation>;
export type UploadImportMutationOptions = Apollo.BaseMutationOptions<
    UploadImportMutation,
    UploadImportMutationVariables
>;
export const ValidateLockoutsDocument = gql`
    query ValidateLockouts($start: Date!, $end: Date!, $unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            id
            allotmentLockouts(startDate: $start, endDate: $end) {
                id
                startDate
                endDate
                type
                comment
                source
            }
            bookings(startDate: $start, endDate: $end) {
                id
                arrivalDate
                departureDate
                status
            }
        }
    }
`;

/**
 * __useValidateLockoutsQuery__
 *
 * To run a query within a React component, call `useValidateLockoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateLockoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateLockoutsQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      unitSlug: // value for 'unitSlug'
 *   },
 * });
 */
export function useValidateLockoutsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<ValidateLockoutsQuery, ValidateLockoutsQueryVariables> &
        ({ variables: ValidateLockoutsQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ValidateLockoutsQuery, ValidateLockoutsQueryVariables>(
        ValidateLockoutsDocument,
        options
    );
}
export function useValidateLockoutsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ValidateLockoutsQuery, ValidateLockoutsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ValidateLockoutsQuery, ValidateLockoutsQueryVariables>(
        ValidateLockoutsDocument,
        options
    );
}
export function useValidateLockoutsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<ValidateLockoutsQuery, ValidateLockoutsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<ValidateLockoutsQuery, ValidateLockoutsQueryVariables>(
        ValidateLockoutsDocument,
        options
    );
}
export type ValidateLockoutsQueryHookResult = ReturnType<typeof useValidateLockoutsQuery>;
export type ValidateLockoutsLazyQueryHookResult = ReturnType<typeof useValidateLockoutsLazyQuery>;
export type ValidateLockoutsSuspenseQueryHookResult = ReturnType<typeof useValidateLockoutsSuspenseQuery>;
export type ValidateLockoutsQueryResult = Apollo.QueryResult<ValidateLockoutsQuery, ValidateLockoutsQueryVariables>;
export const EditLockoutDocument = gql`
    mutation EditLockout($input: EditAllotmentLockoutInput!) {
        editAllotmentLockout(input: $input) {
            allotmentLockout {
                ...AllotmentsLockout
            }
        }
    }
    ${AllotmentsLockoutFragmentDoc}
`;

/**
 * __useEditLockoutMutation__
 *
 * To run a mutation, you first call `useEditLockoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditLockoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editLockoutMutation, { data, loading, error }] = useEditLockoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditLockoutMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<EditLockoutMutation, EditLockoutMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditLockoutMutation, EditLockoutMutationVariables>(
        EditLockoutDocument,
        options
    );
}
export type EditLockoutMutationHookResult = ReturnType<typeof useEditLockoutMutation>;
export type EditLockoutMutationResult = Apollo.MutationResult<EditLockoutMutation>;
export type EditLockoutMutationOptions = Apollo.BaseMutationOptions<EditLockoutMutation, EditLockoutMutationVariables>;
export const CreateLockoutDocument = gql`
    mutation CreateLockout($input: CreateAllotmentLockoutInput!) {
        createAllotmentLockout(input: $input) {
            allotmentLockout {
                ...AllotmentsLockout
            }
        }
    }
    ${AllotmentsLockoutFragmentDoc}
`;

/**
 * __useCreateLockoutMutation__
 *
 * To run a mutation, you first call `useCreateLockoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLockoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLockoutMutation, { data, loading, error }] = useCreateLockoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLockoutMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<CreateLockoutMutation, CreateLockoutMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<CreateLockoutMutation, CreateLockoutMutationVariables>(
        CreateLockoutDocument,
        options
    );
}
export type CreateLockoutMutationHookResult = ReturnType<typeof useCreateLockoutMutation>;
export type CreateLockoutMutationResult = Apollo.MutationResult<CreateLockoutMutation>;
export type CreateLockoutMutationOptions = Apollo.BaseMutationOptions<
    CreateLockoutMutation,
    CreateLockoutMutationVariables
>;
export const DeleteLockoutDocument = gql`
    mutation DeleteLockout($input: DeleteAllotmentLockoutInput!) {
        deleteAllotmentLockout(input: $input) {
            id
        }
    }
`;

/**
 * __useDeleteLockoutMutation__
 *
 * To run a mutation, you first call `useDeleteLockoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLockoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLockoutMutation, { data, loading, error }] = useDeleteLockoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteLockoutMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteLockoutMutation, DeleteLockoutMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<DeleteLockoutMutation, DeleteLockoutMutationVariables>(
        DeleteLockoutDocument,
        options
    );
}
export type DeleteLockoutMutationHookResult = ReturnType<typeof useDeleteLockoutMutation>;
export type DeleteLockoutMutationResult = Apollo.MutationResult<DeleteLockoutMutation>;
export type DeleteLockoutMutationOptions = Apollo.BaseMutationOptions<
    DeleteLockoutMutation,
    DeleteLockoutMutationVariables
>;
export const ValidatePeriodDocument = gql`
    query ValidatePeriod($start: Date!, $end: Date!, $unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            id
            datePricingModifiers(startDate: $start, endDate: $end) {
                id
                minDuration
                maxDuration
                startDate
                endDate
            }
        }
    }
`;

/**
 * __useValidatePeriodQuery__
 *
 * To run a query within a React component, call `useValidatePeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidatePeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidatePeriodQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      unitSlug: // value for 'unitSlug'
 *   },
 * });
 */
export function useValidatePeriodQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<ValidatePeriodQuery, ValidatePeriodQueryVariables> &
        ({ variables: ValidatePeriodQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ValidatePeriodQuery, ValidatePeriodQueryVariables>(
        ValidatePeriodDocument,
        options
    );
}
export function useValidatePeriodLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ValidatePeriodQuery, ValidatePeriodQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ValidatePeriodQuery, ValidatePeriodQueryVariables>(
        ValidatePeriodDocument,
        options
    );
}
export function useValidatePeriodSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<ValidatePeriodQuery, ValidatePeriodQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<ValidatePeriodQuery, ValidatePeriodQueryVariables>(
        ValidatePeriodDocument,
        options
    );
}
export type ValidatePeriodQueryHookResult = ReturnType<typeof useValidatePeriodQuery>;
export type ValidatePeriodLazyQueryHookResult = ReturnType<typeof useValidatePeriodLazyQuery>;
export type ValidatePeriodSuspenseQueryHookResult = ReturnType<typeof useValidatePeriodSuspenseQuery>;
export type ValidatePeriodQueryResult = Apollo.QueryResult<ValidatePeriodQuery, ValidatePeriodQueryVariables>;
export const AddPeriodRuleDocument = gql`
    mutation addPeriodRule($input: CreateDatePricingModifierInput!) {
        createDatePricingModifier(input: $input) {
            datePricingModifier {
                ...PeriodRules
            }
        }
    }
    ${PeriodRulesFragmentDoc}
`;

/**
 * __useAddPeriodRuleMutation__
 *
 * To run a mutation, you first call `useAddPeriodRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPeriodRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPeriodRuleMutation, { data, loading, error }] = useAddPeriodRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPeriodRuleMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<AddPeriodRuleMutation, AddPeriodRuleMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<AddPeriodRuleMutation, AddPeriodRuleMutationVariables>(
        AddPeriodRuleDocument,
        options
    );
}
export type AddPeriodRuleMutationHookResult = ReturnType<typeof useAddPeriodRuleMutation>;
export type AddPeriodRuleMutationResult = Apollo.MutationResult<AddPeriodRuleMutation>;
export type AddPeriodRuleMutationOptions = Apollo.BaseMutationOptions<
    AddPeriodRuleMutation,
    AddPeriodRuleMutationVariables
>;
export const EditPeriodRuleDocument = gql`
    mutation editPeriodRule($input: EditDatePricingModifierInput!) {
        editDatePricingModifier(input: $input) {
            datePricingModifier {
                ...PeriodRules
            }
        }
    }
    ${PeriodRulesFragmentDoc}
`;

/**
 * __useEditPeriodRuleMutation__
 *
 * To run a mutation, you first call `useEditPeriodRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPeriodRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPeriodRuleMutation, { data, loading, error }] = useEditPeriodRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPeriodRuleMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<EditPeriodRuleMutation, EditPeriodRuleMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditPeriodRuleMutation, EditPeriodRuleMutationVariables>(
        EditPeriodRuleDocument,
        options
    );
}
export type EditPeriodRuleMutationHookResult = ReturnType<typeof useEditPeriodRuleMutation>;
export type EditPeriodRuleMutationResult = Apollo.MutationResult<EditPeriodRuleMutation>;
export type EditPeriodRuleMutationOptions = Apollo.BaseMutationOptions<
    EditPeriodRuleMutation,
    EditPeriodRuleMutationVariables
>;
export const DeletePeriodRuleDocument = gql`
    mutation deletePeriodRule($id: ID!) {
        deleteDatePricingModifier(input: { datePricingModifierId: $id }) {
            id
        }
    }
`;

/**
 * __useDeletePeriodRuleMutation__
 *
 * To run a mutation, you first call `useDeletePeriodRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePeriodRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePeriodRuleMutation, { data, loading, error }] = useDeletePeriodRuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePeriodRuleMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePeriodRuleMutation, DeletePeriodRuleMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<DeletePeriodRuleMutation, DeletePeriodRuleMutationVariables>(
        DeletePeriodRuleDocument,
        options
    );
}
export type DeletePeriodRuleMutationHookResult = ReturnType<typeof useDeletePeriodRuleMutation>;
export type DeletePeriodRuleMutationResult = Apollo.MutationResult<DeletePeriodRuleMutation>;
export type DeletePeriodRuleMutationOptions = Apollo.BaseMutationOptions<
    DeletePeriodRuleMutation,
    DeletePeriodRuleMutationVariables
>;
export const EditDatePricingDisplayOptionsDocument = gql`
    mutation EditDatePricingDisplayOptions($input: EditDatePricingDisplayOptionsInput!, $start: Date!, $end: Date!) {
        editDatePricingDisplayOptions(input: $input) {
            rentalUnit {
                id
                slug
                ...Pricing
                datePricings(startDate: $start, endDate: $end) {
                    ...PriceColumnPricing
                }
                datePricingModifiers(startDate: $start, endDate: $end) {
                    ...PeriodRules
                }
            }
        }
    }
    ${PricingFragmentDoc}
    ${PriceColumnPricingFragmentDoc}
    ${PeriodRulesFragmentDoc}
`;

/**
 * __useEditDatePricingDisplayOptionsMutation__
 *
 * To run a mutation, you first call `useEditDatePricingDisplayOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDatePricingDisplayOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDatePricingDisplayOptionsMutation, { data, loading, error }] = useEditDatePricingDisplayOptionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useEditDatePricingDisplayOptionsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        EditDatePricingDisplayOptionsMutation,
        EditDatePricingDisplayOptionsMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        EditDatePricingDisplayOptionsMutation,
        EditDatePricingDisplayOptionsMutationVariables
    >(EditDatePricingDisplayOptionsDocument, options);
}
export type EditDatePricingDisplayOptionsMutationHookResult = ReturnType<
    typeof useEditDatePricingDisplayOptionsMutation
>;
export type EditDatePricingDisplayOptionsMutationResult = Apollo.MutationResult<EditDatePricingDisplayOptionsMutation>;
export type EditDatePricingDisplayOptionsMutationOptions = Apollo.BaseMutationOptions<
    EditDatePricingDisplayOptionsMutation,
    EditDatePricingDisplayOptionsMutationVariables
>;
export const EditSpecialParticipationOptInDocument = gql`
    mutation EditSpecialParticipationOptIn($input: EditSpecialParticipationInput!, $end: Date!, $start: Date!) {
        editSpecialParticipationOptIn(input: $input) {
            specialParticipation {
                ...SpecialParticipation
            }
        }
    }
    ${SpecialParticipationFragmentDoc}
`;

/**
 * __useEditSpecialParticipationOptInMutation__
 *
 * To run a mutation, you first call `useEditSpecialParticipationOptInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSpecialParticipationOptInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSpecialParticipationOptInMutation, { data, loading, error }] = useEditSpecialParticipationOptInMutation({
 *   variables: {
 *      input: // value for 'input'
 *      end: // value for 'end'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useEditSpecialParticipationOptInMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        EditSpecialParticipationOptInMutation,
        EditSpecialParticipationOptInMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        EditSpecialParticipationOptInMutation,
        EditSpecialParticipationOptInMutationVariables
    >(EditSpecialParticipationOptInDocument, options);
}
export type EditSpecialParticipationOptInMutationHookResult = ReturnType<
    typeof useEditSpecialParticipationOptInMutation
>;
export type EditSpecialParticipationOptInMutationResult = Apollo.MutationResult<EditSpecialParticipationOptInMutation>;
export type EditSpecialParticipationOptInMutationOptions = Apollo.BaseMutationOptions<
    EditSpecialParticipationOptInMutation,
    EditSpecialParticipationOptInMutationVariables
>;
export const EditSpecialParticipationDateBlockDocument = gql`
    mutation EditSpecialParticipationDateBlock(
        $input: EditSpecialParticipationDateBlockInput!
        $start: Date!
        $end: Date!
    ) {
        editSpecialParticipationDateBlock(input: $input) {
            specialParticipation {
                ...SpecialParticipation
            }
        }
    }
    ${SpecialParticipationFragmentDoc}
`;

/**
 * __useEditSpecialParticipationDateBlockMutation__
 *
 * To run a mutation, you first call `useEditSpecialParticipationDateBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSpecialParticipationDateBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSpecialParticipationDateBlockMutation, { data, loading, error }] = useEditSpecialParticipationDateBlockMutation({
 *   variables: {
 *      input: // value for 'input'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useEditSpecialParticipationDateBlockMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        EditSpecialParticipationDateBlockMutation,
        EditSpecialParticipationDateBlockMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        EditSpecialParticipationDateBlockMutation,
        EditSpecialParticipationDateBlockMutationVariables
    >(EditSpecialParticipationDateBlockDocument, options);
}
export type EditSpecialParticipationDateBlockMutationHookResult = ReturnType<
    typeof useEditSpecialParticipationDateBlockMutation
>;
export type EditSpecialParticipationDateBlockMutationResult =
    Apollo.MutationResult<EditSpecialParticipationDateBlockMutation>;
export type EditSpecialParticipationDateBlockMutationOptions = Apollo.BaseMutationOptions<
    EditSpecialParticipationDateBlockMutation,
    EditSpecialParticipationDateBlockMutationVariables
>;
export const WizardPriceCopierDocument = gql`
    query WizardPriceCopier($id: ID!, $startDate: Date!, $endDate: Date!) {
        rentalUnit(id: $id) {
            id
            datePricingStartDate
            datePricingEndDate
            datePricings(startDate: $startDate, endDate: $endDate) {
                id
                date
            }
            ...PricesRowVisibility
        }
    }
    ${PricesRowVisibilityFragmentDoc}
`;

/**
 * __useWizardPriceCopierQuery__
 *
 * To run a query within a React component, call `useWizardPriceCopierQuery` and pass it any options that fit your needs.
 * When your component renders, `useWizardPriceCopierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWizardPriceCopierQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useWizardPriceCopierQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<WizardPriceCopierQuery, WizardPriceCopierQueryVariables> &
        ({ variables: WizardPriceCopierQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<WizardPriceCopierQuery, WizardPriceCopierQueryVariables>(
        WizardPriceCopierDocument,
        options
    );
}
export function useWizardPriceCopierLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WizardPriceCopierQuery, WizardPriceCopierQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<WizardPriceCopierQuery, WizardPriceCopierQueryVariables>(
        WizardPriceCopierDocument,
        options
    );
}
export function useWizardPriceCopierSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<WizardPriceCopierQuery, WizardPriceCopierQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<WizardPriceCopierQuery, WizardPriceCopierQueryVariables>(
        WizardPriceCopierDocument,
        options
    );
}
export type WizardPriceCopierQueryHookResult = ReturnType<typeof useWizardPriceCopierQuery>;
export type WizardPriceCopierLazyQueryHookResult = ReturnType<typeof useWizardPriceCopierLazyQuery>;
export type WizardPriceCopierSuspenseQueryHookResult = ReturnType<typeof useWizardPriceCopierSuspenseQuery>;
export type WizardPriceCopierQueryResult = Apollo.QueryResult<WizardPriceCopierQuery, WizardPriceCopierQueryVariables>;
export const EditArrivalAllowedDocument = gql`
    mutation editArrivalAllowed($id: ID!, $arrivalAllowed: Boolean!) {
        editDatePricingArrivalAllowed(input: { datePricingId: $id, arrivalAllowed: $arrivalAllowed }) {
            datePricing {
                id
                arrivalAllowed
            }
        }
    }
`;

/**
 * __useEditArrivalAllowedMutation__
 *
 * To run a mutation, you first call `useEditArrivalAllowedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditArrivalAllowedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editArrivalAllowedMutation, { data, loading, error }] = useEditArrivalAllowedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      arrivalAllowed: // value for 'arrivalAllowed'
 *   },
 * });
 */
export function useEditArrivalAllowedMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<EditArrivalAllowedMutation, EditArrivalAllowedMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditArrivalAllowedMutation, EditArrivalAllowedMutationVariables>(
        EditArrivalAllowedDocument,
        options
    );
}
export type EditArrivalAllowedMutationHookResult = ReturnType<typeof useEditArrivalAllowedMutation>;
export type EditArrivalAllowedMutationResult = Apollo.MutationResult<EditArrivalAllowedMutation>;
export type EditArrivalAllowedMutationOptions = Apollo.BaseMutationOptions<
    EditArrivalAllowedMutation,
    EditArrivalAllowedMutationVariables
>;
export const EditDepartureAllowedDocument = gql`
    mutation editDepartureAllowed($id: ID!, $departureAllowed: Boolean!) {
        editDatePricingDepartureAllowed(input: { datePricingId: $id, departureAllowed: $departureAllowed }) {
            datePricing {
                id
                departureAllowed
            }
        }
    }
`;

/**
 * __useEditDepartureAllowedMutation__
 *
 * To run a mutation, you first call `useEditDepartureAllowedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDepartureAllowedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDepartureAllowedMutation, { data, loading, error }] = useEditDepartureAllowedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      departureAllowed: // value for 'departureAllowed'
 *   },
 * });
 */
export function useEditDepartureAllowedMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        EditDepartureAllowedMutation,
        EditDepartureAllowedMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditDepartureAllowedMutation, EditDepartureAllowedMutationVariables>(
        EditDepartureAllowedDocument,
        options
    );
}
export type EditDepartureAllowedMutationHookResult = ReturnType<typeof useEditDepartureAllowedMutation>;
export type EditDepartureAllowedMutationResult = Apollo.MutationResult<EditDepartureAllowedMutation>;
export type EditDepartureAllowedMutationOptions = Apollo.BaseMutationOptions<
    EditDepartureAllowedMutation,
    EditDepartureAllowedMutationVariables
>;
export const EditNightPriceDocument = gql`
    mutation editNightPrice($id: ID!, $nightPrice: Float!) {
        editDatePricingNightPrice(input: { datePricingId: $id, nightPrice: $nightPrice }) {
            datePricing {
                id
                nightPrice
            }
        }
    }
`;

/**
 * __useEditNightPriceMutation__
 *
 * To run a mutation, you first call `useEditNightPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditNightPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editNightPriceMutation, { data, loading, error }] = useEditNightPriceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      nightPrice: // value for 'nightPrice'
 *   },
 * });
 */
export function useEditNightPriceMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<EditNightPriceMutation, EditNightPriceMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditNightPriceMutation, EditNightPriceMutationVariables>(
        EditNightPriceDocument,
        options
    );
}
export type EditNightPriceMutationHookResult = ReturnType<typeof useEditNightPriceMutation>;
export type EditNightPriceMutationResult = Apollo.MutationResult<EditNightPriceMutation>;
export type EditNightPriceMutationOptions = Apollo.BaseMutationOptions<
    EditNightPriceMutation,
    EditNightPriceMutationVariables
>;
export const EditExtraPersonPriceDocument = gql`
    mutation editExtraPersonPrice($id: ID!, $extraPersonPrice: Float!) {
        editDatePricingExtraPersonPrice(input: { datePricingId: $id, extraPersonPrice: $extraPersonPrice }) {
            datePricing {
                id
                extraPersonPrice
            }
        }
    }
`;

/**
 * __useEditExtraPersonPriceMutation__
 *
 * To run a mutation, you first call `useEditExtraPersonPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditExtraPersonPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editExtraPersonPriceMutation, { data, loading, error }] = useEditExtraPersonPriceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      extraPersonPrice: // value for 'extraPersonPrice'
 *   },
 * });
 */
export function useEditExtraPersonPriceMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        EditExtraPersonPriceMutation,
        EditExtraPersonPriceMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditExtraPersonPriceMutation, EditExtraPersonPriceMutationVariables>(
        EditExtraPersonPriceDocument,
        options
    );
}
export type EditExtraPersonPriceMutationHookResult = ReturnType<typeof useEditExtraPersonPriceMutation>;
export type EditExtraPersonPriceMutationResult = Apollo.MutationResult<EditExtraPersonPriceMutation>;
export type EditExtraPersonPriceMutationOptions = Apollo.BaseMutationOptions<
    EditExtraPersonPriceMutation,
    EditExtraPersonPriceMutationVariables
>;
export const EditMinimumStayDurationDocument = gql`
    mutation editMinimumStayDuration($id: ID!, $minimumStayDuration: Int!) {
        editDatePricingMinimumStayDuration(input: { datePricingId: $id, minimumStayDuration: $minimumStayDuration }) {
            datePricing {
                id
                minimumStayDuration
            }
        }
    }
`;

/**
 * __useEditMinimumStayDurationMutation__
 *
 * To run a mutation, you first call `useEditMinimumStayDurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMinimumStayDurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMinimumStayDurationMutation, { data, loading, error }] = useEditMinimumStayDurationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      minimumStayDuration: // value for 'minimumStayDuration'
 *   },
 * });
 */
export function useEditMinimumStayDurationMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        EditMinimumStayDurationMutation,
        EditMinimumStayDurationMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditMinimumStayDurationMutation, EditMinimumStayDurationMutationVariables>(
        EditMinimumStayDurationDocument,
        options
    );
}
export type EditMinimumStayDurationMutationHookResult = ReturnType<typeof useEditMinimumStayDurationMutation>;
export type EditMinimumStayDurationMutationResult = Apollo.MutationResult<EditMinimumStayDurationMutation>;
export type EditMinimumStayDurationMutationOptions = Apollo.BaseMutationOptions<
    EditMinimumStayDurationMutation,
    EditMinimumStayDurationMutationVariables
>;
export const EditWeekPriceDocument = gql`
    mutation editWeekPrice($id: ID!, $weekPrice: Float) {
        editDatePricingWeekPrice(input: { datePricingId: $id, weekPrice: $weekPrice }) {
            datePricing {
                id
                weekPrice
            }
        }
    }
`;

/**
 * __useEditWeekPriceMutation__
 *
 * To run a mutation, you first call `useEditWeekPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditWeekPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editWeekPriceMutation, { data, loading, error }] = useEditWeekPriceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      weekPrice: // value for 'weekPrice'
 *   },
 * });
 */
export function useEditWeekPriceMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<EditWeekPriceMutation, EditWeekPriceMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditWeekPriceMutation, EditWeekPriceMutationVariables>(
        EditWeekPriceDocument,
        options
    );
}
export type EditWeekPriceMutationHookResult = ReturnType<typeof useEditWeekPriceMutation>;
export type EditWeekPriceMutationResult = Apollo.MutationResult<EditWeekPriceMutation>;
export type EditWeekPriceMutationOptions = Apollo.BaseMutationOptions<
    EditWeekPriceMutation,
    EditWeekPriceMutationVariables
>;
export const EditBaseStayPriceDocument = gql`
    mutation editBaseStayPrice($id: ID!, $baseStayPrice: Float!) {
        editDatePricingBaseStayPrice(input: { datePricingId: $id, baseStayPrice: $baseStayPrice }) {
            datePricing {
                id
                baseStayPrice
            }
        }
    }
`;

/**
 * __useEditBaseStayPriceMutation__
 *
 * To run a mutation, you first call `useEditBaseStayPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditBaseStayPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editBaseStayPriceMutation, { data, loading, error }] = useEditBaseStayPriceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      baseStayPrice: // value for 'baseStayPrice'
 *   },
 * });
 */
export function useEditBaseStayPriceMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<EditBaseStayPriceMutation, EditBaseStayPriceMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditBaseStayPriceMutation, EditBaseStayPriceMutationVariables>(
        EditBaseStayPriceDocument,
        options
    );
}
export type EditBaseStayPriceMutationHookResult = ReturnType<typeof useEditBaseStayPriceMutation>;
export type EditBaseStayPriceMutationResult = Apollo.MutationResult<EditBaseStayPriceMutation>;
export type EditBaseStayPriceMutationOptions = Apollo.BaseMutationOptions<
    EditBaseStayPriceMutation,
    EditBaseStayPriceMutationVariables
>;
export const EditMinimumStayPriceDocument = gql`
    mutation editMinimumStayPrice($id: ID!, $minimumStayPrice: Float!) {
        editDatePricingMinimumStayPrice(input: { datePricingId: $id, minimumStayPrice: $minimumStayPrice }) {
            datePricing {
                id
                minimumStayPrice
            }
        }
    }
`;

/**
 * __useEditMinimumStayPriceMutation__
 *
 * To run a mutation, you first call `useEditMinimumStayPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMinimumStayPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMinimumStayPriceMutation, { data, loading, error }] = useEditMinimumStayPriceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      minimumStayPrice: // value for 'minimumStayPrice'
 *   },
 * });
 */
export function useEditMinimumStayPriceMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        EditMinimumStayPriceMutation,
        EditMinimumStayPriceMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditMinimumStayPriceMutation, EditMinimumStayPriceMutationVariables>(
        EditMinimumStayPriceDocument,
        options
    );
}
export type EditMinimumStayPriceMutationHookResult = ReturnType<typeof useEditMinimumStayPriceMutation>;
export type EditMinimumStayPriceMutationResult = Apollo.MutationResult<EditMinimumStayPriceMutation>;
export type EditMinimumStayPriceMutationOptions = Apollo.BaseMutationOptions<
    EditMinimumStayPriceMutation,
    EditMinimumStayPriceMutationVariables
>;
export const TimeslotsDocument = gql`
    query Timeslots($activitySlug: String!, $startDate: Date!, $endDate: Date!) {
        activity(slug: $activitySlug) {
            ...ActivityTimeslots
        }
    }
    ${ActivityTimeslotsFragmentDoc}
`;

/**
 * __useTimeslotsQuery__
 *
 * To run a query within a React component, call `useTimeslotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeslotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeslotsQuery({
 *   variables: {
 *      activitySlug: // value for 'activitySlug'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useTimeslotsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<TimeslotsQuery, TimeslotsQueryVariables> &
        ({ variables: TimeslotsQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<TimeslotsQuery, TimeslotsQueryVariables>(TimeslotsDocument, options);
}
export function useTimeslotsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TimeslotsQuery, TimeslotsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<TimeslotsQuery, TimeslotsQueryVariables>(TimeslotsDocument, options);
}
export function useTimeslotsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<TimeslotsQuery, TimeslotsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<TimeslotsQuery, TimeslotsQueryVariables>(TimeslotsDocument, options);
}
export type TimeslotsQueryHookResult = ReturnType<typeof useTimeslotsQuery>;
export type TimeslotsLazyQueryHookResult = ReturnType<typeof useTimeslotsLazyQuery>;
export type TimeslotsSuspenseQueryHookResult = ReturnType<typeof useTimeslotsSuspenseQuery>;
export type TimeslotsQueryResult = Apollo.QueryResult<TimeslotsQuery, TimeslotsQueryVariables>;
export const CreateActivityTimeslotDocument = gql`
    mutation CreateActivityTimeslot($input: CreateActivityTimeslotInput!) {
        createActivityTimeslot(input: $input) {
            timeslot {
                ...ActivityTimeslot
            }
        }
    }
    ${ActivityTimeslotFragmentDoc}
`;

/**
 * __useCreateActivityTimeslotMutation__
 *
 * To run a mutation, you first call `useCreateActivityTimeslotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActivityTimeslotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActivityTimeslotMutation, { data, loading, error }] = useCreateActivityTimeslotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateActivityTimeslotMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateActivityTimeslotMutation,
        CreateActivityTimeslotMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<CreateActivityTimeslotMutation, CreateActivityTimeslotMutationVariables>(
        CreateActivityTimeslotDocument,
        options
    );
}
export type CreateActivityTimeslotMutationHookResult = ReturnType<typeof useCreateActivityTimeslotMutation>;
export type CreateActivityTimeslotMutationResult = Apollo.MutationResult<CreateActivityTimeslotMutation>;
export type CreateActivityTimeslotMutationOptions = Apollo.BaseMutationOptions<
    CreateActivityTimeslotMutation,
    CreateActivityTimeslotMutationVariables
>;
export const DeleteActivityTimeslotDocument = gql`
    mutation DeleteActivityTimeslot($input: DeleteActivityTimeslotInput!) {
        deleteActivityTimeslot(input: $input) {
            id
        }
    }
`;

/**
 * __useDeleteActivityTimeslotMutation__
 *
 * To run a mutation, you first call `useDeleteActivityTimeslotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActivityTimeslotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActivityTimeslotMutation, { data, loading, error }] = useDeleteActivityTimeslotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteActivityTimeslotMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteActivityTimeslotMutation,
        DeleteActivityTimeslotMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<DeleteActivityTimeslotMutation, DeleteActivityTimeslotMutationVariables>(
        DeleteActivityTimeslotDocument,
        options
    );
}
export type DeleteActivityTimeslotMutationHookResult = ReturnType<typeof useDeleteActivityTimeslotMutation>;
export type DeleteActivityTimeslotMutationResult = Apollo.MutationResult<DeleteActivityTimeslotMutation>;
export type DeleteActivityTimeslotMutationOptions = Apollo.BaseMutationOptions<
    DeleteActivityTimeslotMutation,
    DeleteActivityTimeslotMutationVariables
>;
export const EditActivityTimeslotDocument = gql`
    mutation EditActivityTimeslot($input: EditActivityTimeslotInput!) {
        editActivityTimeslot(input: $input) {
            timeslot {
                ...ActivityTimeslot
            }
        }
    }
    ${ActivityTimeslotFragmentDoc}
`;

/**
 * __useEditActivityTimeslotMutation__
 *
 * To run a mutation, you first call `useEditActivityTimeslotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditActivityTimeslotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editActivityTimeslotMutation, { data, loading, error }] = useEditActivityTimeslotMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditActivityTimeslotMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        EditActivityTimeslotMutation,
        EditActivityTimeslotMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditActivityTimeslotMutation, EditActivityTimeslotMutationVariables>(
        EditActivityTimeslotDocument,
        options
    );
}
export type EditActivityTimeslotMutationHookResult = ReturnType<typeof useEditActivityTimeslotMutation>;
export type EditActivityTimeslotMutationResult = Apollo.MutationResult<EditActivityTimeslotMutation>;
export type EditActivityTimeslotMutationOptions = Apollo.BaseMutationOptions<
    EditActivityTimeslotMutation,
    EditActivityTimeslotMutationVariables
>;
export const BalancesDocument = gql`
    query Balances($partnerId: ID!, $startDate: Date!, $endDate: Date!) {
        partner(id: $partnerId) {
            id
            balance(atDate: $startDate)
            balanceItems(startDate: $startDate, endDate: $endDate) {
                ...BalanceItem
            }
        }
    }
    ${BalanceItemFragmentDoc}
`;

/**
 * __useBalancesQuery__
 *
 * To run a query within a React component, call `useBalancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBalancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBalancesQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useBalancesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<BalancesQuery, BalancesQueryVariables> &
        ({ variables: BalancesQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<BalancesQuery, BalancesQueryVariables>(BalancesDocument, options);
}
export function useBalancesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BalancesQuery, BalancesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<BalancesQuery, BalancesQueryVariables>(BalancesDocument, options);
}
export function useBalancesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<BalancesQuery, BalancesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<BalancesQuery, BalancesQueryVariables>(BalancesDocument, options);
}
export type BalancesQueryHookResult = ReturnType<typeof useBalancesQuery>;
export type BalancesLazyQueryHookResult = ReturnType<typeof useBalancesLazyQuery>;
export type BalancesSuspenseQueryHookResult = ReturnType<typeof useBalancesSuspenseQuery>;
export type BalancesQueryResult = Apollo.QueryResult<BalancesQuery, BalancesQueryVariables>;
export const PartnerDataDocument = gql`
    query PartnerData($slug: String, $partnerId: ID!) {
        viewer {
            ...NavigationUser
        }
        partner(id: $partnerId) {
            id
            name
            accommodations {
                ...AccommodationEntry
                ...AccommodationInfo
            }
            companies {
                ...CompanyPickerEntry
                ...CompanyInfo
            }
        }
        rentalUnit(slug: $slug) {
            ...RentalUnitMetaData
        }
    }
    ${NavigationUserFragmentDoc}
    ${AccommodationEntryFragmentDoc}
    ${AccommodationInfoFragmentDoc}
    ${CompanyPickerEntryFragmentDoc}
    ${CompanyInfoFragmentDoc}
    ${RentalUnitMetaDataFragmentDoc}
`;

/**
 * __usePartnerDataQuery__
 *
 * To run a query within a React component, call `usePartnerDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerDataQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function usePartnerDataQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<PartnerDataQuery, PartnerDataQueryVariables> &
        ({ variables: PartnerDataQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PartnerDataQuery, PartnerDataQueryVariables>(PartnerDataDocument, options);
}
export function usePartnerDataLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PartnerDataQuery, PartnerDataQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<PartnerDataQuery, PartnerDataQueryVariables>(PartnerDataDocument, options);
}
export function usePartnerDataSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<PartnerDataQuery, PartnerDataQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<PartnerDataQuery, PartnerDataQueryVariables>(PartnerDataDocument, options);
}
export type PartnerDataQueryHookResult = ReturnType<typeof usePartnerDataQuery>;
export type PartnerDataLazyQueryHookResult = ReturnType<typeof usePartnerDataLazyQuery>;
export type PartnerDataSuspenseQueryHookResult = ReturnType<typeof usePartnerDataSuspenseQuery>;
export type PartnerDataQueryResult = Apollo.QueryResult<PartnerDataQuery, PartnerDataQueryVariables>;
export const PasswordResetDocument = gql`
    mutation passwordReset($token: String!, $newPassword: String!) {
        resetPassword(input: { token: $token, newPassword: $newPassword }) {
            email
        }
    }
`;

/**
 * __usePasswordResetMutation__
 *
 * To run a mutation, you first call `usePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetMutation, { data, loading, error }] = usePasswordResetMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function usePasswordResetMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<PasswordResetMutation, PasswordResetMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<PasswordResetMutation, PasswordResetMutationVariables>(
        PasswordResetDocument,
        options
    );
}
export type PasswordResetMutationHookResult = ReturnType<typeof usePasswordResetMutation>;
export type PasswordResetMutationResult = Apollo.MutationResult<PasswordResetMutation>;
export type PasswordResetMutationOptions = Apollo.BaseMutationOptions<
    PasswordResetMutation,
    PasswordResetMutationVariables
>;
export const QrSignupDocument = gql`
    mutation QRSignup($input: InitTicketScanSessionInput!) {
        initTicketScanSession(input: $input) {
            deepLink
        }
    }
`;

/**
 * __useQrSignupMutation__
 *
 * To run a mutation, you first call `useQrSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQrSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [qrSignupMutation, { data, loading, error }] = useQrSignupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQrSignupMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<QrSignupMutation, QrSignupMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<QrSignupMutation, QrSignupMutationVariables>(QrSignupDocument, options);
}
export type QrSignupMutationHookResult = ReturnType<typeof useQrSignupMutation>;
export type QrSignupMutationResult = Apollo.MutationResult<QrSignupMutation>;
export type QrSignupMutationOptions = Apollo.BaseMutationOptions<QrSignupMutation, QrSignupMutationVariables>;
export const RegisterNewPartnerDocument = gql`
    mutation RegisterNewPartner($input: RegisterNewPartnerInput!) {
        registerNewPartner(input: $input) {
            partnerRegistration {
                ...PartnerRegistrationDetails
            }
        }
    }
    ${PartnerRegistrationDetailsFragmentDoc}
`;

/**
 * __useRegisterNewPartnerMutation__
 *
 * To run a mutation, you first call `useRegisterNewPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterNewPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerNewPartnerMutation, { data, loading, error }] = useRegisterNewPartnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterNewPartnerMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterNewPartnerMutation, RegisterNewPartnerMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<RegisterNewPartnerMutation, RegisterNewPartnerMutationVariables>(
        RegisterNewPartnerDocument,
        options
    );
}
export type RegisterNewPartnerMutationHookResult = ReturnType<typeof useRegisterNewPartnerMutation>;
export type RegisterNewPartnerMutationResult = Apollo.MutationResult<RegisterNewPartnerMutation>;
export type RegisterNewPartnerMutationOptions = Apollo.BaseMutationOptions<
    RegisterNewPartnerMutation,
    RegisterNewPartnerMutationVariables
>;
export const ReviewsDocument = gql`
    query Reviews($partnerId: ID!, $rentalUnitIds: [ID!]) {
        partner(id: $partnerId) {
            id
            reviews(rentalUnitIds: $rentalUnitIds) {
                ...Review
            }
        }
    }
    ${ReviewFragmentDoc}
`;

/**
 * __useReviewsQuery__
 *
 * To run a query within a React component, call `useReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewsQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      rentalUnitIds: // value for 'rentalUnitIds'
 *   },
 * });
 */
export function useReviewsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<ReviewsQuery, ReviewsQueryVariables> &
        ({ variables: ReviewsQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ReviewsQuery, ReviewsQueryVariables>(ReviewsDocument, options);
}
export function useReviewsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewsQuery, ReviewsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ReviewsQuery, ReviewsQueryVariables>(ReviewsDocument, options);
}
export function useReviewsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<ReviewsQuery, ReviewsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<ReviewsQuery, ReviewsQueryVariables>(ReviewsDocument, options);
}
export type ReviewsQueryHookResult = ReturnType<typeof useReviewsQuery>;
export type ReviewsLazyQueryHookResult = ReturnType<typeof useReviewsLazyQuery>;
export type ReviewsSuspenseQueryHookResult = ReturnType<typeof useReviewsSuspenseQuery>;
export type ReviewsQueryResult = Apollo.QueryResult<ReviewsQuery, ReviewsQueryVariables>;
export const ActivityRatesDocument = gql`
    query ActivityRates($activitySlug: String!) {
        activity(slug: $activitySlug) {
            ...ActivityRates
        }
    }
    ${ActivityRatesFragmentDoc}
`;

/**
 * __useActivityRatesQuery__
 *
 * To run a query within a React component, call `useActivityRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityRatesQuery({
 *   variables: {
 *      activitySlug: // value for 'activitySlug'
 *   },
 * });
 */
export function useActivityRatesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<ActivityRatesQuery, ActivityRatesQueryVariables> &
        ({ variables: ActivityRatesQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ActivityRatesQuery, ActivityRatesQueryVariables>(ActivityRatesDocument, options);
}
export function useActivityRatesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActivityRatesQuery, ActivityRatesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ActivityRatesQuery, ActivityRatesQueryVariables>(
        ActivityRatesDocument,
        options
    );
}
export function useActivityRatesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<ActivityRatesQuery, ActivityRatesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<ActivityRatesQuery, ActivityRatesQueryVariables>(
        ActivityRatesDocument,
        options
    );
}
export type ActivityRatesQueryHookResult = ReturnType<typeof useActivityRatesQuery>;
export type ActivityRatesLazyQueryHookResult = ReturnType<typeof useActivityRatesLazyQuery>;
export type ActivityRatesSuspenseQueryHookResult = ReturnType<typeof useActivityRatesSuspenseQuery>;
export type ActivityRatesQueryResult = Apollo.QueryResult<ActivityRatesQuery, ActivityRatesQueryVariables>;
export const UpsertActivityRateGroupDocument = gql`
    mutation UpsertActivityRateGroup($input: UpsertActivityRateGroupInput!) {
        upsertActivityRateGroup(input: $input) {
            activity {
                ...ActivityRates
            }
        }
    }
    ${ActivityRatesFragmentDoc}
`;

/**
 * __useUpsertActivityRateGroupMutation__
 *
 * To run a mutation, you first call `useUpsertActivityRateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertActivityRateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertActivityRateGroupMutation, { data, loading, error }] = useUpsertActivityRateGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertActivityRateGroupMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpsertActivityRateGroupMutation,
        UpsertActivityRateGroupMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<UpsertActivityRateGroupMutation, UpsertActivityRateGroupMutationVariables>(
        UpsertActivityRateGroupDocument,
        options
    );
}
export type UpsertActivityRateGroupMutationHookResult = ReturnType<typeof useUpsertActivityRateGroupMutation>;
export type UpsertActivityRateGroupMutationResult = Apollo.MutationResult<UpsertActivityRateGroupMutation>;
export type UpsertActivityRateGroupMutationOptions = Apollo.BaseMutationOptions<
    UpsertActivityRateGroupMutation,
    UpsertActivityRateGroupMutationVariables
>;
export const DeleteActivityRateGroupDocument = gql`
    mutation DeleteActivityRateGroup($input: DeleteActivityRateGroupInput!) {
        deleteActivityRateGroup(input: $input) {
            activity {
                ...ActivityRates
            }
        }
    }
    ${ActivityRatesFragmentDoc}
`;

/**
 * __useDeleteActivityRateGroupMutation__
 *
 * To run a mutation, you first call `useDeleteActivityRateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActivityRateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActivityRateGroupMutation, { data, loading, error }] = useDeleteActivityRateGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteActivityRateGroupMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteActivityRateGroupMutation,
        DeleteActivityRateGroupMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<DeleteActivityRateGroupMutation, DeleteActivityRateGroupMutationVariables>(
        DeleteActivityRateGroupDocument,
        options
    );
}
export type DeleteActivityRateGroupMutationHookResult = ReturnType<typeof useDeleteActivityRateGroupMutation>;
export type DeleteActivityRateGroupMutationResult = Apollo.MutationResult<DeleteActivityRateGroupMutation>;
export type DeleteActivityRateGroupMutationOptions = Apollo.BaseMutationOptions<
    DeleteActivityRateGroupMutation,
    DeleteActivityRateGroupMutationVariables
>;
export const ActivityDescriptionDocument = gql`
    query ActivityDescription($activitySlug: String!) {
        activity(slug: $activitySlug) {
            ...ActivityDescription
        }
    }
    ${ActivityDescriptionFragmentDoc}
`;

/**
 * __useActivityDescriptionQuery__
 *
 * To run a query within a React component, call `useActivityDescriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityDescriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityDescriptionQuery({
 *   variables: {
 *      activitySlug: // value for 'activitySlug'
 *   },
 * });
 */
export function useActivityDescriptionQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<ActivityDescriptionQuery, ActivityDescriptionQueryVariables> &
        ({ variables: ActivityDescriptionQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ActivityDescriptionQuery, ActivityDescriptionQueryVariables>(
        ActivityDescriptionDocument,
        options
    );
}
export function useActivityDescriptionLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActivityDescriptionQuery, ActivityDescriptionQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ActivityDescriptionQuery, ActivityDescriptionQueryVariables>(
        ActivityDescriptionDocument,
        options
    );
}
export function useActivityDescriptionSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<ActivityDescriptionQuery, ActivityDescriptionQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<ActivityDescriptionQuery, ActivityDescriptionQueryVariables>(
        ActivityDescriptionDocument,
        options
    );
}
export type ActivityDescriptionQueryHookResult = ReturnType<typeof useActivityDescriptionQuery>;
export type ActivityDescriptionLazyQueryHookResult = ReturnType<typeof useActivityDescriptionLazyQuery>;
export type ActivityDescriptionSuspenseQueryHookResult = ReturnType<typeof useActivityDescriptionSuspenseQuery>;
export type ActivityDescriptionQueryResult = Apollo.QueryResult<
    ActivityDescriptionQuery,
    ActivityDescriptionQueryVariables
>;
export const EditActivityDescriptionsDocument = gql`
    mutation EditActivityDescriptions($input: EditActivityDescriptionInput!) {
        editActivityDescription(input: $input) {
            activity {
                ...ActivityDescription
            }
        }
    }
    ${ActivityDescriptionFragmentDoc}
`;

/**
 * __useEditActivityDescriptionsMutation__
 *
 * To run a mutation, you first call `useEditActivityDescriptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditActivityDescriptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editActivityDescriptionsMutation, { data, loading, error }] = useEditActivityDescriptionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditActivityDescriptionsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        EditActivityDescriptionsMutation,
        EditActivityDescriptionsMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditActivityDescriptionsMutation, EditActivityDescriptionsMutationVariables>(
        EditActivityDescriptionsDocument,
        options
    );
}
export type EditActivityDescriptionsMutationHookResult = ReturnType<typeof useEditActivityDescriptionsMutation>;
export type EditActivityDescriptionsMutationResult = Apollo.MutationResult<EditActivityDescriptionsMutation>;
export type EditActivityDescriptionsMutationOptions = Apollo.BaseMutationOptions<
    EditActivityDescriptionsMutation,
    EditActivityDescriptionsMutationVariables
>;
export const ActivityDetailsDocument = gql`
    query ActivityDetails($activitySlug: String!) {
        activity(slug: $activitySlug) {
            ...ActivityDetails
        }
    }
    ${ActivityDetailsFragmentDoc}
`;

/**
 * __useActivityDetailsQuery__
 *
 * To run a query within a React component, call `useActivityDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityDetailsQuery({
 *   variables: {
 *      activitySlug: // value for 'activitySlug'
 *   },
 * });
 */
export function useActivityDetailsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<ActivityDetailsQuery, ActivityDetailsQueryVariables> &
        ({ variables: ActivityDetailsQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ActivityDetailsQuery, ActivityDetailsQueryVariables>(
        ActivityDetailsDocument,
        options
    );
}
export function useActivityDetailsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActivityDetailsQuery, ActivityDetailsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ActivityDetailsQuery, ActivityDetailsQueryVariables>(
        ActivityDetailsDocument,
        options
    );
}
export function useActivityDetailsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<ActivityDetailsQuery, ActivityDetailsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<ActivityDetailsQuery, ActivityDetailsQueryVariables>(
        ActivityDetailsDocument,
        options
    );
}
export type ActivityDetailsQueryHookResult = ReturnType<typeof useActivityDetailsQuery>;
export type ActivityDetailsLazyQueryHookResult = ReturnType<typeof useActivityDetailsLazyQuery>;
export type ActivityDetailsSuspenseQueryHookResult = ReturnType<typeof useActivityDetailsSuspenseQuery>;
export type ActivityDetailsQueryResult = Apollo.QueryResult<ActivityDetailsQuery, ActivityDetailsQueryVariables>;
export const EditActivityInfoDocument = gql`
    mutation EditActivityInfo($input: EditActivityInfoInput!) {
        editActivityInfo(input: $input) {
            activity {
                ...ActivityDetails
            }
        }
    }
    ${ActivityDetailsFragmentDoc}
`;

/**
 * __useEditActivityInfoMutation__
 *
 * To run a mutation, you first call `useEditActivityInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditActivityInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editActivityInfoMutation, { data, loading, error }] = useEditActivityInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditActivityInfoMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<EditActivityInfoMutation, EditActivityInfoMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditActivityInfoMutation, EditActivityInfoMutationVariables>(
        EditActivityInfoDocument,
        options
    );
}
export type EditActivityInfoMutationHookResult = ReturnType<typeof useEditActivityInfoMutation>;
export type EditActivityInfoMutationResult = Apollo.MutationResult<EditActivityInfoMutation>;
export type EditActivityInfoMutationOptions = Apollo.BaseMutationOptions<
    EditActivityInfoMutation,
    EditActivityInfoMutationVariables
>;
export const ActivityImagesDocument = gql`
    query ActivityImages($activitySlug: String!) {
        activity(slug: $activitySlug) {
            id
            ...ActivityImages
        }
    }
    ${ActivityImagesFragmentDoc}
`;

/**
 * __useActivityImagesQuery__
 *
 * To run a query within a React component, call `useActivityImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityImagesQuery({
 *   variables: {
 *      activitySlug: // value for 'activitySlug'
 *   },
 * });
 */
export function useActivityImagesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<ActivityImagesQuery, ActivityImagesQueryVariables> &
        ({ variables: ActivityImagesQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ActivityImagesQuery, ActivityImagesQueryVariables>(
        ActivityImagesDocument,
        options
    );
}
export function useActivityImagesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActivityImagesQuery, ActivityImagesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ActivityImagesQuery, ActivityImagesQueryVariables>(
        ActivityImagesDocument,
        options
    );
}
export function useActivityImagesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<ActivityImagesQuery, ActivityImagesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<ActivityImagesQuery, ActivityImagesQueryVariables>(
        ActivityImagesDocument,
        options
    );
}
export type ActivityImagesQueryHookResult = ReturnType<typeof useActivityImagesQuery>;
export type ActivityImagesLazyQueryHookResult = ReturnType<typeof useActivityImagesLazyQuery>;
export type ActivityImagesSuspenseQueryHookResult = ReturnType<typeof useActivityImagesSuspenseQuery>;
export type ActivityImagesQueryResult = Apollo.QueryResult<ActivityImagesQuery, ActivityImagesQueryVariables>;
export const EditActivityImagesDocument = gql`
    mutation EditActivityImages($input: EditActivityImagesInput!) {
        editActivityImages(input: $input) {
            activity {
                ...ActivityImages
            }
        }
    }
    ${ActivityImagesFragmentDoc}
`;

/**
 * __useEditActivityImagesMutation__
 *
 * To run a mutation, you first call `useEditActivityImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditActivityImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editActivityImagesMutation, { data, loading, error }] = useEditActivityImagesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditActivityImagesMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<EditActivityImagesMutation, EditActivityImagesMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditActivityImagesMutation, EditActivityImagesMutationVariables>(
        EditActivityImagesDocument,
        options
    );
}
export type EditActivityImagesMutationHookResult = ReturnType<typeof useEditActivityImagesMutation>;
export type EditActivityImagesMutationResult = Apollo.MutationResult<EditActivityImagesMutation>;
export type EditActivityImagesMutationOptions = Apollo.BaseMutationOptions<
    EditActivityImagesMutation,
    EditActivityImagesMutationVariables
>;
export const ActivityLocationDocument = gql`
    query ActivityLocation($activitySlug: String!) {
        activity(slug: $activitySlug) {
            ...ActivityLocation
        }
    }
    ${ActivityLocationFragmentDoc}
`;

/**
 * __useActivityLocationQuery__
 *
 * To run a query within a React component, call `useActivityLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityLocationQuery({
 *   variables: {
 *      activitySlug: // value for 'activitySlug'
 *   },
 * });
 */
export function useActivityLocationQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<ActivityLocationQuery, ActivityLocationQueryVariables> &
        ({ variables: ActivityLocationQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ActivityLocationQuery, ActivityLocationQueryVariables>(
        ActivityLocationDocument,
        options
    );
}
export function useActivityLocationLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActivityLocationQuery, ActivityLocationQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ActivityLocationQuery, ActivityLocationQueryVariables>(
        ActivityLocationDocument,
        options
    );
}
export function useActivityLocationSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<ActivityLocationQuery, ActivityLocationQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<ActivityLocationQuery, ActivityLocationQueryVariables>(
        ActivityLocationDocument,
        options
    );
}
export type ActivityLocationQueryHookResult = ReturnType<typeof useActivityLocationQuery>;
export type ActivityLocationLazyQueryHookResult = ReturnType<typeof useActivityLocationLazyQuery>;
export type ActivityLocationSuspenseQueryHookResult = ReturnType<typeof useActivityLocationSuspenseQuery>;
export type ActivityLocationQueryResult = Apollo.QueryResult<ActivityLocationQuery, ActivityLocationQueryVariables>;
export const EditActivityLocationDocument = gql`
    mutation EditActivityLocation($input: EditActivityLocationInput!) {
        editActivityLocation(input: $input) {
            activity {
                ...ActivityLocation
            }
        }
    }
    ${ActivityLocationFragmentDoc}
`;

/**
 * __useEditActivityLocationMutation__
 *
 * To run a mutation, you first call `useEditActivityLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditActivityLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editActivityLocationMutation, { data, loading, error }] = useEditActivityLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditActivityLocationMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        EditActivityLocationMutation,
        EditActivityLocationMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditActivityLocationMutation, EditActivityLocationMutationVariables>(
        EditActivityLocationDocument,
        options
    );
}
export type EditActivityLocationMutationHookResult = ReturnType<typeof useEditActivityLocationMutation>;
export type EditActivityLocationMutationResult = Apollo.MutationResult<EditActivityLocationMutation>;
export type EditActivityLocationMutationOptions = Apollo.BaseMutationOptions<
    EditActivityLocationMutation,
    EditActivityLocationMutationVariables
>;
export const AgreementsDocument = gql`
    query Agreements($activitySlug: String!) {
        activity(slug: $activitySlug) {
            id
            activityAgreements {
                ...Agreements
            }
        }
    }
    ${AgreementsFragmentDoc}
`;

/**
 * __useAgreementsQuery__
 *
 * To run a query within a React component, call `useAgreementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgreementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgreementsQuery({
 *   variables: {
 *      activitySlug: // value for 'activitySlug'
 *   },
 * });
 */
export function useAgreementsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<AgreementsQuery, AgreementsQueryVariables> &
        ({ variables: AgreementsQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<AgreementsQuery, AgreementsQueryVariables>(AgreementsDocument, options);
}
export function useAgreementsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AgreementsQuery, AgreementsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<AgreementsQuery, AgreementsQueryVariables>(AgreementsDocument, options);
}
export function useAgreementsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<AgreementsQuery, AgreementsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<AgreementsQuery, AgreementsQueryVariables>(AgreementsDocument, options);
}
export type AgreementsQueryHookResult = ReturnType<typeof useAgreementsQuery>;
export type AgreementsLazyQueryHookResult = ReturnType<typeof useAgreementsLazyQuery>;
export type AgreementsSuspenseQueryHookResult = ReturnType<typeof useAgreementsSuspenseQuery>;
export type AgreementsQueryResult = Apollo.QueryResult<AgreementsQuery, AgreementsQueryVariables>;
export const ApproveAgreementDocument = gql`
    mutation ApproveAgreement($id: ID!) {
        approveActivityAgreement(input: { activityAgreementId: $id }) {
            activityAgreement {
                ...Agreements
            }
        }
    }
    ${AgreementsFragmentDoc}
`;

/**
 * __useApproveAgreementMutation__
 *
 * To run a mutation, you first call `useApproveAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveAgreementMutation, { data, loading, error }] = useApproveAgreementMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveAgreementMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveAgreementMutation, ApproveAgreementMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<ApproveAgreementMutation, ApproveAgreementMutationVariables>(
        ApproveAgreementDocument,
        options
    );
}
export type ApproveAgreementMutationHookResult = ReturnType<typeof useApproveAgreementMutation>;
export type ApproveAgreementMutationResult = Apollo.MutationResult<ApproveAgreementMutation>;
export type ApproveAgreementMutationOptions = Apollo.BaseMutationOptions<
    ApproveAgreementMutation,
    ApproveAgreementMutationVariables
>;
export const TimeslotsWizardDocument = gql`
    query TimeslotsWizard($activitySlug: String!) {
        activity(slug: $activitySlug) {
            ...TimeslotsWizardActivity
        }
    }
    ${TimeslotsWizardActivityFragmentDoc}
`;

/**
 * __useTimeslotsWizardQuery__
 *
 * To run a query within a React component, call `useTimeslotsWizardQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeslotsWizardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeslotsWizardQuery({
 *   variables: {
 *      activitySlug: // value for 'activitySlug'
 *   },
 * });
 */
export function useTimeslotsWizardQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<TimeslotsWizardQuery, TimeslotsWizardQueryVariables> &
        ({ variables: TimeslotsWizardQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<TimeslotsWizardQuery, TimeslotsWizardQueryVariables>(
        TimeslotsWizardDocument,
        options
    );
}
export function useTimeslotsWizardLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TimeslotsWizardQuery, TimeslotsWizardQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<TimeslotsWizardQuery, TimeslotsWizardQueryVariables>(
        TimeslotsWizardDocument,
        options
    );
}
export function useTimeslotsWizardSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<TimeslotsWizardQuery, TimeslotsWizardQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<TimeslotsWizardQuery, TimeslotsWizardQueryVariables>(
        TimeslotsWizardDocument,
        options
    );
}
export type TimeslotsWizardQueryHookResult = ReturnType<typeof useTimeslotsWizardQuery>;
export type TimeslotsWizardLazyQueryHookResult = ReturnType<typeof useTimeslotsWizardLazyQuery>;
export type TimeslotsWizardSuspenseQueryHookResult = ReturnType<typeof useTimeslotsWizardSuspenseQuery>;
export type TimeslotsWizardQueryResult = Apollo.QueryResult<TimeslotsWizardQuery, TimeslotsWizardQueryVariables>;
export const SetActivityTimeslotsDocument = gql`
    mutation SetActivityTimeslots($input: SetActivityTimeslotsInput!) {
        setActivityTimeslots(input: $input) {
            activity {
                ...TimeslotsWizardActivity
            }
        }
    }
    ${TimeslotsWizardActivityFragmentDoc}
`;

/**
 * __useSetActivityTimeslotsMutation__
 *
 * To run a mutation, you first call `useSetActivityTimeslotsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetActivityTimeslotsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setActivityTimeslotsMutation, { data, loading, error }] = useSetActivityTimeslotsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetActivityTimeslotsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        SetActivityTimeslotsMutation,
        SetActivityTimeslotsMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<SetActivityTimeslotsMutation, SetActivityTimeslotsMutationVariables>(
        SetActivityTimeslotsDocument,
        options
    );
}
export type SetActivityTimeslotsMutationHookResult = ReturnType<typeof useSetActivityTimeslotsMutation>;
export type SetActivityTimeslotsMutationResult = Apollo.MutationResult<SetActivityTimeslotsMutation>;
export type SetActivityTimeslotsMutationOptions = Apollo.BaseMutationOptions<
    SetActivityTimeslotsMutation,
    SetActivityTimeslotsMutationVariables
>;
export const AllBookingsDocument = gql`
    query AllBookings(
        $after: String
        $first: Int
        $before: String
        $last: Int
        $startDate: Date
        $endDate: Date
        $searchQuery: String
        $partnerId: ID!
        $rentalUnitIds: [ID!]
    ) {
        partner(id: $partnerId) {
            id
            allBookings(
                first: $first
                last: $last
                after: $after
                before: $before
                startDate: $startDate
                endDate: $endDate
                searchQuery: $searchQuery
                rentalUnitIds: $rentalUnitIds
            ) {
                ...BookingConnection
            }
        }
    }
    ${BookingConnectionFragmentDoc}
`;

/**
 * __useAllBookingsQuery__
 *
 * To run a query within a React component, call `useAllBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBookingsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      searchQuery: // value for 'searchQuery'
 *      partnerId: // value for 'partnerId'
 *      rentalUnitIds: // value for 'rentalUnitIds'
 *   },
 * });
 */
export function useAllBookingsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<AllBookingsQuery, AllBookingsQueryVariables> &
        ({ variables: AllBookingsQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<AllBookingsQuery, AllBookingsQueryVariables>(AllBookingsDocument, options);
}
export function useAllBookingsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllBookingsQuery, AllBookingsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<AllBookingsQuery, AllBookingsQueryVariables>(AllBookingsDocument, options);
}
export function useAllBookingsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<AllBookingsQuery, AllBookingsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<AllBookingsQuery, AllBookingsQueryVariables>(AllBookingsDocument, options);
}
export type AllBookingsQueryHookResult = ReturnType<typeof useAllBookingsQuery>;
export type AllBookingsLazyQueryHookResult = ReturnType<typeof useAllBookingsLazyQuery>;
export type AllBookingsSuspenseQueryHookResult = ReturnType<typeof useAllBookingsSuspenseQuery>;
export type AllBookingsQueryResult = Apollo.QueryResult<AllBookingsQuery, AllBookingsQueryVariables>;
export const BookingDocument = gql`
    query Booking($id: ID!) {
        booking(id: $id) {
            ...BookingDetails
        }
    }
    ${BookingDetailsFragmentDoc}
`;

/**
 * __useBookingQuery__
 *
 * To run a query within a React component, call `useBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookingQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<BookingQuery, BookingQueryVariables> &
        ({ variables: BookingQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<BookingQuery, BookingQueryVariables>(BookingDocument, options);
}
export function useBookingLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BookingQuery, BookingQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<BookingQuery, BookingQueryVariables>(BookingDocument, options);
}
export function useBookingSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<BookingQuery, BookingQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<BookingQuery, BookingQueryVariables>(BookingDocument, options);
}
export type BookingQueryHookResult = ReturnType<typeof useBookingQuery>;
export type BookingLazyQueryHookResult = ReturnType<typeof useBookingLazyQuery>;
export type BookingSuspenseQueryHookResult = ReturnType<typeof useBookingSuspenseQuery>;
export type BookingQueryResult = Apollo.QueryResult<BookingQuery, BookingQueryVariables>;
export const AcceptBookingDocument = gql`
    mutation AcceptBooking($input: AcceptBookingInput!) {
        acceptBooking(input: $input) {
            booking {
                ...BookingDetails
            }
        }
    }
    ${BookingDetailsFragmentDoc}
`;

/**
 * __useAcceptBookingMutation__
 *
 * To run a mutation, you first call `useAcceptBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptBookingMutation, { data, loading, error }] = useAcceptBookingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptBookingMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptBookingMutation, AcceptBookingMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<AcceptBookingMutation, AcceptBookingMutationVariables>(
        AcceptBookingDocument,
        options
    );
}
export type AcceptBookingMutationHookResult = ReturnType<typeof useAcceptBookingMutation>;
export type AcceptBookingMutationResult = Apollo.MutationResult<AcceptBookingMutation>;
export type AcceptBookingMutationOptions = Apollo.BaseMutationOptions<
    AcceptBookingMutation,
    AcceptBookingMutationVariables
>;
export const RecentlyUpdatedBookingsDocument = gql`
    query RecentlyUpdatedBookings($partnerId: ID!, $after: String, $first: Int, $before: String, $last: Int) {
        partner(id: $partnerId) {
            id
            recentlyUpdatedBookings(first: $first, last: $last, after: $after, before: $before) {
                ...BookingConnection
            }
        }
    }
    ${BookingConnectionFragmentDoc}
`;

/**
 * __useRecentlyUpdatedBookingsQuery__
 *
 * To run a query within a React component, call `useRecentlyUpdatedBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentlyUpdatedBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentlyUpdatedBookingsQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useRecentlyUpdatedBookingsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        RecentlyUpdatedBookingsQuery,
        RecentlyUpdatedBookingsQueryVariables
    > &
        ({ variables: RecentlyUpdatedBookingsQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<RecentlyUpdatedBookingsQuery, RecentlyUpdatedBookingsQueryVariables>(
        RecentlyUpdatedBookingsDocument,
        options
    );
}
export function useRecentlyUpdatedBookingsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        RecentlyUpdatedBookingsQuery,
        RecentlyUpdatedBookingsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<RecentlyUpdatedBookingsQuery, RecentlyUpdatedBookingsQueryVariables>(
        RecentlyUpdatedBookingsDocument,
        options
    );
}
export function useRecentlyUpdatedBookingsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        RecentlyUpdatedBookingsQuery,
        RecentlyUpdatedBookingsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<RecentlyUpdatedBookingsQuery, RecentlyUpdatedBookingsQueryVariables>(
        RecentlyUpdatedBookingsDocument,
        options
    );
}
export type RecentlyUpdatedBookingsQueryHookResult = ReturnType<typeof useRecentlyUpdatedBookingsQuery>;
export type RecentlyUpdatedBookingsLazyQueryHookResult = ReturnType<typeof useRecentlyUpdatedBookingsLazyQuery>;
export type RecentlyUpdatedBookingsSuspenseQueryHookResult = ReturnType<typeof useRecentlyUpdatedBookingsSuspenseQuery>;
export type RecentlyUpdatedBookingsQueryResult = Apollo.QueryResult<
    RecentlyUpdatedBookingsQuery,
    RecentlyUpdatedBookingsQueryVariables
>;
export const UpcomingBookingsDocument = gql`
    query UpcomingBookings($partnerId: ID!, $after: String, $first: Int, $before: String, $last: Int) {
        partner(id: $partnerId) {
            id
            upcomingBookings(first: $first, last: $last, after: $after, before: $before) {
                ...BookingConnection
            }
        }
    }
    ${BookingConnectionFragmentDoc}
`;

/**
 * __useUpcomingBookingsQuery__
 *
 * To run a query within a React component, call `useUpcomingBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingBookingsQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useUpcomingBookingsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<UpcomingBookingsQuery, UpcomingBookingsQueryVariables> &
        ({ variables: UpcomingBookingsQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<UpcomingBookingsQuery, UpcomingBookingsQueryVariables>(
        UpcomingBookingsDocument,
        options
    );
}
export function useUpcomingBookingsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UpcomingBookingsQuery, UpcomingBookingsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<UpcomingBookingsQuery, UpcomingBookingsQueryVariables>(
        UpcomingBookingsDocument,
        options
    );
}
export function useUpcomingBookingsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<UpcomingBookingsQuery, UpcomingBookingsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<UpcomingBookingsQuery, UpcomingBookingsQueryVariables>(
        UpcomingBookingsDocument,
        options
    );
}
export type UpcomingBookingsQueryHookResult = ReturnType<typeof useUpcomingBookingsQuery>;
export type UpcomingBookingsLazyQueryHookResult = ReturnType<typeof useUpcomingBookingsLazyQuery>;
export type UpcomingBookingsSuspenseQueryHookResult = ReturnType<typeof useUpcomingBookingsSuspenseQuery>;
export type UpcomingBookingsQueryResult = Apollo.QueryResult<UpcomingBookingsQuery, UpcomingBookingsQueryVariables>;
export const CompanyRatesDocument = gql`
    query CompanyRates($companySlug: String!) {
        company(slug: $companySlug) {
            ...CompanyRates
        }
    }
    ${CompanyRatesFragmentDoc}
`;

/**
 * __useCompanyRatesQuery__
 *
 * To run a query within a React component, call `useCompanyRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyRatesQuery({
 *   variables: {
 *      companySlug: // value for 'companySlug'
 *   },
 * });
 */
export function useCompanyRatesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<CompanyRatesQuery, CompanyRatesQueryVariables> &
        ({ variables: CompanyRatesQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CompanyRatesQuery, CompanyRatesQueryVariables>(CompanyRatesDocument, options);
}
export function useCompanyRatesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyRatesQuery, CompanyRatesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CompanyRatesQuery, CompanyRatesQueryVariables>(CompanyRatesDocument, options);
}
export function useCompanyRatesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<CompanyRatesQuery, CompanyRatesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<CompanyRatesQuery, CompanyRatesQueryVariables>(
        CompanyRatesDocument,
        options
    );
}
export type CompanyRatesQueryHookResult = ReturnType<typeof useCompanyRatesQuery>;
export type CompanyRatesLazyQueryHookResult = ReturnType<typeof useCompanyRatesLazyQuery>;
export type CompanyRatesSuspenseQueryHookResult = ReturnType<typeof useCompanyRatesSuspenseQuery>;
export type CompanyRatesQueryResult = Apollo.QueryResult<CompanyRatesQuery, CompanyRatesQueryVariables>;
export const UpsertCompanyRateGroupDocument = gql`
    mutation UpsertCompanyRateGroup($input: UpsertCompanyRateGroupInput!) {
        upsertCompanyRateGroup(input: $input) {
            company {
                ...CompanyRates
            }
        }
    }
    ${CompanyRatesFragmentDoc}
`;

/**
 * __useUpsertCompanyRateGroupMutation__
 *
 * To run a mutation, you first call `useUpsertCompanyRateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCompanyRateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCompanyRateGroupMutation, { data, loading, error }] = useUpsertCompanyRateGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertCompanyRateGroupMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        UpsertCompanyRateGroupMutation,
        UpsertCompanyRateGroupMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<UpsertCompanyRateGroupMutation, UpsertCompanyRateGroupMutationVariables>(
        UpsertCompanyRateGroupDocument,
        options
    );
}
export type UpsertCompanyRateGroupMutationHookResult = ReturnType<typeof useUpsertCompanyRateGroupMutation>;
export type UpsertCompanyRateGroupMutationResult = Apollo.MutationResult<UpsertCompanyRateGroupMutation>;
export type UpsertCompanyRateGroupMutationOptions = Apollo.BaseMutationOptions<
    UpsertCompanyRateGroupMutation,
    UpsertCompanyRateGroupMutationVariables
>;
export const DeleteCompanyRateGroupDocument = gql`
    mutation DeleteCompanyRateGroup($input: DeleteCompanyRateGroupInput!) {
        deleteCompanyRateGroup(input: $input) {
            company {
                ...CompanyRates
            }
        }
    }
    ${CompanyRatesFragmentDoc}
`;

/**
 * __useDeleteCompanyRateGroupMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyRateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyRateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyRateGroupMutation, { data, loading, error }] = useDeleteCompanyRateGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCompanyRateGroupMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteCompanyRateGroupMutation,
        DeleteCompanyRateGroupMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<DeleteCompanyRateGroupMutation, DeleteCompanyRateGroupMutationVariables>(
        DeleteCompanyRateGroupDocument,
        options
    );
}
export type DeleteCompanyRateGroupMutationHookResult = ReturnType<typeof useDeleteCompanyRateGroupMutation>;
export type DeleteCompanyRateGroupMutationResult = Apollo.MutationResult<DeleteCompanyRateGroupMutation>;
export type DeleteCompanyRateGroupMutationOptions = Apollo.BaseMutationOptions<
    DeleteCompanyRateGroupMutation,
    DeleteCompanyRateGroupMutationVariables
>;
export const CompanyHoursDocument = gql`
    query CompanyHours($companySlug: String!) {
        company(slug: $companySlug) {
            ...CompanyHours
        }
    }
    ${CompanyHoursFragmentDoc}
`;

/**
 * __useCompanyHoursQuery__
 *
 * To run a query within a React component, call `useCompanyHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyHoursQuery({
 *   variables: {
 *      companySlug: // value for 'companySlug'
 *   },
 * });
 */
export function useCompanyHoursQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<CompanyHoursQuery, CompanyHoursQueryVariables> &
        ({ variables: CompanyHoursQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CompanyHoursQuery, CompanyHoursQueryVariables>(CompanyHoursDocument, options);
}
export function useCompanyHoursLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyHoursQuery, CompanyHoursQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CompanyHoursQuery, CompanyHoursQueryVariables>(CompanyHoursDocument, options);
}
export function useCompanyHoursSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<CompanyHoursQuery, CompanyHoursQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<CompanyHoursQuery, CompanyHoursQueryVariables>(
        CompanyHoursDocument,
        options
    );
}
export type CompanyHoursQueryHookResult = ReturnType<typeof useCompanyHoursQuery>;
export type CompanyHoursLazyQueryHookResult = ReturnType<typeof useCompanyHoursLazyQuery>;
export type CompanyHoursSuspenseQueryHookResult = ReturnType<typeof useCompanyHoursSuspenseQuery>;
export type CompanyHoursQueryResult = Apollo.QueryResult<CompanyHoursQuery, CompanyHoursQueryVariables>;
export const EditCompanyOperatingHoursDocument = gql`
    mutation EditCompanyOperatingHours($input: EditCompanyNormalOperatingHoursInput!) {
        editCompanyNormalOperatingHours(input: $input) {
            company {
                ...CompanyHours
            }
        }
    }
    ${CompanyHoursFragmentDoc}
`;

/**
 * __useEditCompanyOperatingHoursMutation__
 *
 * To run a mutation, you first call `useEditCompanyOperatingHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCompanyOperatingHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCompanyOperatingHoursMutation, { data, loading, error }] = useEditCompanyOperatingHoursMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCompanyOperatingHoursMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        EditCompanyOperatingHoursMutation,
        EditCompanyOperatingHoursMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditCompanyOperatingHoursMutation, EditCompanyOperatingHoursMutationVariables>(
        EditCompanyOperatingHoursDocument,
        options
    );
}
export type EditCompanyOperatingHoursMutationHookResult = ReturnType<typeof useEditCompanyOperatingHoursMutation>;
export type EditCompanyOperatingHoursMutationResult = Apollo.MutationResult<EditCompanyOperatingHoursMutation>;
export type EditCompanyOperatingHoursMutationOptions = Apollo.BaseMutationOptions<
    EditCompanyOperatingHoursMutation,
    EditCompanyOperatingHoursMutationVariables
>;
export const CompanySpecialHoursDocument = gql`
    query CompanySpecialHours($companySlug: String!) {
        company(slug: $companySlug) {
            ...CompanySpecialHours
        }
    }
    ${CompanySpecialHoursFragmentDoc}
`;

/**
 * __useCompanySpecialHoursQuery__
 *
 * To run a query within a React component, call `useCompanySpecialHoursQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySpecialHoursQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySpecialHoursQuery({
 *   variables: {
 *      companySlug: // value for 'companySlug'
 *   },
 * });
 */
export function useCompanySpecialHoursQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<CompanySpecialHoursQuery, CompanySpecialHoursQueryVariables> &
        ({ variables: CompanySpecialHoursQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CompanySpecialHoursQuery, CompanySpecialHoursQueryVariables>(
        CompanySpecialHoursDocument,
        options
    );
}
export function useCompanySpecialHoursLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanySpecialHoursQuery, CompanySpecialHoursQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CompanySpecialHoursQuery, CompanySpecialHoursQueryVariables>(
        CompanySpecialHoursDocument,
        options
    );
}
export function useCompanySpecialHoursSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<CompanySpecialHoursQuery, CompanySpecialHoursQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<CompanySpecialHoursQuery, CompanySpecialHoursQueryVariables>(
        CompanySpecialHoursDocument,
        options
    );
}
export type CompanySpecialHoursQueryHookResult = ReturnType<typeof useCompanySpecialHoursQuery>;
export type CompanySpecialHoursLazyQueryHookResult = ReturnType<typeof useCompanySpecialHoursLazyQuery>;
export type CompanySpecialHoursSuspenseQueryHookResult = ReturnType<typeof useCompanySpecialHoursSuspenseQuery>;
export type CompanySpecialHoursQueryResult = Apollo.QueryResult<
    CompanySpecialHoursQuery,
    CompanySpecialHoursQueryVariables
>;
export const EditCompanySpecialOperatingHoursDocument = gql`
    mutation EditCompanySpecialOperatingHours($input: EditCompanySpecialOperatingHoursInput!) {
        editCompanySpecialOperatingHours(input: $input) {
            company {
                ...CompanySpecialHours
            }
        }
    }
    ${CompanySpecialHoursFragmentDoc}
`;

/**
 * __useEditCompanySpecialOperatingHoursMutation__
 *
 * To run a mutation, you first call `useEditCompanySpecialOperatingHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCompanySpecialOperatingHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCompanySpecialOperatingHoursMutation, { data, loading, error }] = useEditCompanySpecialOperatingHoursMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCompanySpecialOperatingHoursMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        EditCompanySpecialOperatingHoursMutation,
        EditCompanySpecialOperatingHoursMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        EditCompanySpecialOperatingHoursMutation,
        EditCompanySpecialOperatingHoursMutationVariables
    >(EditCompanySpecialOperatingHoursDocument, options);
}
export type EditCompanySpecialOperatingHoursMutationHookResult = ReturnType<
    typeof useEditCompanySpecialOperatingHoursMutation
>;
export type EditCompanySpecialOperatingHoursMutationResult =
    Apollo.MutationResult<EditCompanySpecialOperatingHoursMutation>;
export type EditCompanySpecialOperatingHoursMutationOptions = Apollo.BaseMutationOptions<
    EditCompanySpecialOperatingHoursMutation,
    EditCompanySpecialOperatingHoursMutationVariables
>;
export const CompanyDescriptionDocument = gql`
    query CompanyDescription($companySlug: String!) {
        company(slug: $companySlug) {
            ...CompanyDescription
        }
    }
    ${CompanyDescriptionFragmentDoc}
`;

/**
 * __useCompanyDescriptionQuery__
 *
 * To run a query within a React component, call `useCompanyDescriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyDescriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyDescriptionQuery({
 *   variables: {
 *      companySlug: // value for 'companySlug'
 *   },
 * });
 */
export function useCompanyDescriptionQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<CompanyDescriptionQuery, CompanyDescriptionQueryVariables> &
        ({ variables: CompanyDescriptionQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CompanyDescriptionQuery, CompanyDescriptionQueryVariables>(
        CompanyDescriptionDocument,
        options
    );
}
export function useCompanyDescriptionLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyDescriptionQuery, CompanyDescriptionQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CompanyDescriptionQuery, CompanyDescriptionQueryVariables>(
        CompanyDescriptionDocument,
        options
    );
}
export function useCompanyDescriptionSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<CompanyDescriptionQuery, CompanyDescriptionQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<CompanyDescriptionQuery, CompanyDescriptionQueryVariables>(
        CompanyDescriptionDocument,
        options
    );
}
export type CompanyDescriptionQueryHookResult = ReturnType<typeof useCompanyDescriptionQuery>;
export type CompanyDescriptionLazyQueryHookResult = ReturnType<typeof useCompanyDescriptionLazyQuery>;
export type CompanyDescriptionSuspenseQueryHookResult = ReturnType<typeof useCompanyDescriptionSuspenseQuery>;
export type CompanyDescriptionQueryResult = Apollo.QueryResult<
    CompanyDescriptionQuery,
    CompanyDescriptionQueryVariables
>;
export const EditCompanyDescriptionsDocument = gql`
    mutation EditCompanyDescriptions($input: EditCompanyDescriptionInput!) {
        editCompanyDescription(input: $input) {
            company {
                ...CompanyDescription
            }
        }
    }
    ${CompanyDescriptionFragmentDoc}
`;

/**
 * __useEditCompanyDescriptionsMutation__
 *
 * To run a mutation, you first call `useEditCompanyDescriptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCompanyDescriptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCompanyDescriptionsMutation, { data, loading, error }] = useEditCompanyDescriptionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCompanyDescriptionsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        EditCompanyDescriptionsMutation,
        EditCompanyDescriptionsMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditCompanyDescriptionsMutation, EditCompanyDescriptionsMutationVariables>(
        EditCompanyDescriptionsDocument,
        options
    );
}
export type EditCompanyDescriptionsMutationHookResult = ReturnType<typeof useEditCompanyDescriptionsMutation>;
export type EditCompanyDescriptionsMutationResult = Apollo.MutationResult<EditCompanyDescriptionsMutation>;
export type EditCompanyDescriptionsMutationOptions = Apollo.BaseMutationOptions<
    EditCompanyDescriptionsMutation,
    EditCompanyDescriptionsMutationVariables
>;
export const CompanyInfoDocument = gql`
    query CompanyInfo($companySlug: String!) {
        company(slug: $companySlug) {
            ...CompanyDetails
        }
    }
    ${CompanyDetailsFragmentDoc}
`;

/**
 * __useCompanyInfoQuery__
 *
 * To run a query within a React component, call `useCompanyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyInfoQuery({
 *   variables: {
 *      companySlug: // value for 'companySlug'
 *   },
 * });
 */
export function useCompanyInfoQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<CompanyInfoQuery, CompanyInfoQueryVariables> &
        ({ variables: CompanyInfoQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CompanyInfoQuery, CompanyInfoQueryVariables>(CompanyInfoDocument, options);
}
export function useCompanyInfoLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyInfoQuery, CompanyInfoQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CompanyInfoQuery, CompanyInfoQueryVariables>(CompanyInfoDocument, options);
}
export function useCompanyInfoSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<CompanyInfoQuery, CompanyInfoQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<CompanyInfoQuery, CompanyInfoQueryVariables>(CompanyInfoDocument, options);
}
export type CompanyInfoQueryHookResult = ReturnType<typeof useCompanyInfoQuery>;
export type CompanyInfoLazyQueryHookResult = ReturnType<typeof useCompanyInfoLazyQuery>;
export type CompanyInfoSuspenseQueryHookResult = ReturnType<typeof useCompanyInfoSuspenseQuery>;
export type CompanyInfoQueryResult = Apollo.QueryResult<CompanyInfoQuery, CompanyInfoQueryVariables>;
export const EditCompanyInfoDocument = gql`
    mutation EditCompanyInfo($input: EditCompanyInfoInput!) {
        editCompanyInfo(input: $input) {
            company {
                ...CompanyDetails
            }
        }
    }
    ${CompanyDetailsFragmentDoc}
`;

/**
 * __useEditCompanyInfoMutation__
 *
 * To run a mutation, you first call `useEditCompanyInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCompanyInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCompanyInfoMutation, { data, loading, error }] = useEditCompanyInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCompanyInfoMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<EditCompanyInfoMutation, EditCompanyInfoMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditCompanyInfoMutation, EditCompanyInfoMutationVariables>(
        EditCompanyInfoDocument,
        options
    );
}
export type EditCompanyInfoMutationHookResult = ReturnType<typeof useEditCompanyInfoMutation>;
export type EditCompanyInfoMutationResult = Apollo.MutationResult<EditCompanyInfoMutation>;
export type EditCompanyInfoMutationOptions = Apollo.BaseMutationOptions<
    EditCompanyInfoMutation,
    EditCompanyInfoMutationVariables
>;
export const CompanyImagesDocument = gql`
    query CompanyImages($companySlug: String!) {
        company(slug: $companySlug) {
            id
            ...CompanyImages
        }
    }
    ${CompanyImagesFragmentDoc}
`;

/**
 * __useCompanyImagesQuery__
 *
 * To run a query within a React component, call `useCompanyImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyImagesQuery({
 *   variables: {
 *      companySlug: // value for 'companySlug'
 *   },
 * });
 */
export function useCompanyImagesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<CompanyImagesQuery, CompanyImagesQueryVariables> &
        ({ variables: CompanyImagesQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CompanyImagesQuery, CompanyImagesQueryVariables>(CompanyImagesDocument, options);
}
export function useCompanyImagesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyImagesQuery, CompanyImagesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CompanyImagesQuery, CompanyImagesQueryVariables>(
        CompanyImagesDocument,
        options
    );
}
export function useCompanyImagesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<CompanyImagesQuery, CompanyImagesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<CompanyImagesQuery, CompanyImagesQueryVariables>(
        CompanyImagesDocument,
        options
    );
}
export type CompanyImagesQueryHookResult = ReturnType<typeof useCompanyImagesQuery>;
export type CompanyImagesLazyQueryHookResult = ReturnType<typeof useCompanyImagesLazyQuery>;
export type CompanyImagesSuspenseQueryHookResult = ReturnType<typeof useCompanyImagesSuspenseQuery>;
export type CompanyImagesQueryResult = Apollo.QueryResult<CompanyImagesQuery, CompanyImagesQueryVariables>;
export const EditCompanyImagesDocument = gql`
    mutation EditCompanyImages($input: EditCompanyImagesInput!) {
        editCompanyImages(input: $input) {
            company {
                ...CompanyImages
            }
        }
    }
    ${CompanyImagesFragmentDoc}
`;

/**
 * __useEditCompanyImagesMutation__
 *
 * To run a mutation, you first call `useEditCompanyImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCompanyImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCompanyImagesMutation, { data, loading, error }] = useEditCompanyImagesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCompanyImagesMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<EditCompanyImagesMutation, EditCompanyImagesMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditCompanyImagesMutation, EditCompanyImagesMutationVariables>(
        EditCompanyImagesDocument,
        options
    );
}
export type EditCompanyImagesMutationHookResult = ReturnType<typeof useEditCompanyImagesMutation>;
export type EditCompanyImagesMutationResult = Apollo.MutationResult<EditCompanyImagesMutation>;
export type EditCompanyImagesMutationOptions = Apollo.BaseMutationOptions<
    EditCompanyImagesMutation,
    EditCompanyImagesMutationVariables
>;
export const CompanyLocationDocument = gql`
    query CompanyLocation($companySlug: String!) {
        company(slug: $companySlug) {
            ...CompanyLocation
        }
    }
    ${CompanyLocationFragmentDoc}
`;

/**
 * __useCompanyLocationQuery__
 *
 * To run a query within a React component, call `useCompanyLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyLocationQuery({
 *   variables: {
 *      companySlug: // value for 'companySlug'
 *   },
 * });
 */
export function useCompanyLocationQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<CompanyLocationQuery, CompanyLocationQueryVariables> &
        ({ variables: CompanyLocationQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CompanyLocationQuery, CompanyLocationQueryVariables>(
        CompanyLocationDocument,
        options
    );
}
export function useCompanyLocationLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompanyLocationQuery, CompanyLocationQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CompanyLocationQuery, CompanyLocationQueryVariables>(
        CompanyLocationDocument,
        options
    );
}
export function useCompanyLocationSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<CompanyLocationQuery, CompanyLocationQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<CompanyLocationQuery, CompanyLocationQueryVariables>(
        CompanyLocationDocument,
        options
    );
}
export type CompanyLocationQueryHookResult = ReturnType<typeof useCompanyLocationQuery>;
export type CompanyLocationLazyQueryHookResult = ReturnType<typeof useCompanyLocationLazyQuery>;
export type CompanyLocationSuspenseQueryHookResult = ReturnType<typeof useCompanyLocationSuspenseQuery>;
export type CompanyLocationQueryResult = Apollo.QueryResult<CompanyLocationQuery, CompanyLocationQueryVariables>;
export const EditCompanyLocationDocument = gql`
    mutation EditCompanyLocation($input: EditCompanyLocationInput!) {
        editCompanyLocation(input: $input) {
            company {
                ...CompanyLocation
            }
        }
    }
    ${CompanyLocationFragmentDoc}
`;

/**
 * __useEditCompanyLocationMutation__
 *
 * To run a mutation, you first call `useEditCompanyLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCompanyLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCompanyLocationMutation, { data, loading, error }] = useEditCompanyLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCompanyLocationMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        EditCompanyLocationMutation,
        EditCompanyLocationMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditCompanyLocationMutation, EditCompanyLocationMutationVariables>(
        EditCompanyLocationDocument,
        options
    );
}
export type EditCompanyLocationMutationHookResult = ReturnType<typeof useEditCompanyLocationMutation>;
export type EditCompanyLocationMutationResult = Apollo.MutationResult<EditCompanyLocationMutation>;
export type EditCompanyLocationMutationOptions = Apollo.BaseMutationOptions<
    EditCompanyLocationMutation,
    EditCompanyLocationMutationVariables
>;
export const PartnerPickerDocument = gql`
    query PartnerPicker {
        viewer {
            id
            isBackofficeUser
            partners {
                id
                name
                contact {
                    contactName
                }
            }
        }
    }
`;

/**
 * __usePartnerPickerQuery__
 *
 * To run a query within a React component, call `usePartnerPickerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerPickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerPickerQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartnerPickerQuery(
    baseOptions?: ApolloReactHooks.QueryHookOptions<PartnerPickerQuery, PartnerPickerQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PartnerPickerQuery, PartnerPickerQueryVariables>(PartnerPickerDocument, options);
}
export function usePartnerPickerLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PartnerPickerQuery, PartnerPickerQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<PartnerPickerQuery, PartnerPickerQueryVariables>(
        PartnerPickerDocument,
        options
    );
}
export function usePartnerPickerSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<PartnerPickerQuery, PartnerPickerQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<PartnerPickerQuery, PartnerPickerQueryVariables>(
        PartnerPickerDocument,
        options
    );
}
export type PartnerPickerQueryHookResult = ReturnType<typeof usePartnerPickerQuery>;
export type PartnerPickerLazyQueryHookResult = ReturnType<typeof usePartnerPickerLazyQuery>;
export type PartnerPickerSuspenseQueryHookResult = ReturnType<typeof usePartnerPickerSuspenseQuery>;
export type PartnerPickerQueryResult = Apollo.QueryResult<PartnerPickerQuery, PartnerPickerQueryVariables>;
export const ArchiveDocument = gql`
    query Archive($partnerId: ID!) {
        partner(id: $partnerId) {
            id
            accommodations {
                id
                rentalUnits {
                    ...UnitDetails
                }
            }
            companies {
                id
                activities {
                    ...ActivityPickerEntry
                }
            }
        }
    }
    ${UnitDetailsFragmentDoc}
    ${ActivityPickerEntryFragmentDoc}
`;

/**
 * __useArchiveQuery__
 *
 * To run a query within a React component, call `useArchiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchiveQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useArchiveQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<ArchiveQuery, ArchiveQueryVariables> &
        ({ variables: ArchiveQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ArchiveQuery, ArchiveQueryVariables>(ArchiveDocument, options);
}
export function useArchiveLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArchiveQuery, ArchiveQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ArchiveQuery, ArchiveQueryVariables>(ArchiveDocument, options);
}
export function useArchiveSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<ArchiveQuery, ArchiveQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<ArchiveQuery, ArchiveQueryVariables>(ArchiveDocument, options);
}
export type ArchiveQueryHookResult = ReturnType<typeof useArchiveQuery>;
export type ArchiveLazyQueryHookResult = ReturnType<typeof useArchiveLazyQuery>;
export type ArchiveSuspenseQueryHookResult = ReturnType<typeof useArchiveSuspenseQuery>;
export type ArchiveQueryResult = Apollo.QueryResult<ArchiveQuery, ArchiveQueryVariables>;
export const PartnerProfileDocument = gql`
    query PartnerProfile($id: ID!) {
        partner(id: $id) {
            id
            name
            address {
                ...Address
            }
            invoiceAddress {
                ...Address
            }
            invoiceEmails
            bankAccountActive
            vatNumber
            contact {
                contactName
                phoneNumbers
                emails
            }
            contactSalutation
            ibanName
            iban
            bic
            paymentByDirectDebit
        }
    }
    ${AddressFragmentDoc}
`;

/**
 * __usePartnerProfileQuery__
 *
 * To run a query within a React component, call `usePartnerProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePartnerProfileQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<PartnerProfileQuery, PartnerProfileQueryVariables> &
        ({ variables: PartnerProfileQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PartnerProfileQuery, PartnerProfileQueryVariables>(
        PartnerProfileDocument,
        options
    );
}
export function usePartnerProfileLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PartnerProfileQuery, PartnerProfileQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<PartnerProfileQuery, PartnerProfileQueryVariables>(
        PartnerProfileDocument,
        options
    );
}
export function usePartnerProfileSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<PartnerProfileQuery, PartnerProfileQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<PartnerProfileQuery, PartnerProfileQueryVariables>(
        PartnerProfileDocument,
        options
    );
}
export type PartnerProfileQueryHookResult = ReturnType<typeof usePartnerProfileQuery>;
export type PartnerProfileLazyQueryHookResult = ReturnType<typeof usePartnerProfileLazyQuery>;
export type PartnerProfileSuspenseQueryHookResult = ReturnType<typeof usePartnerProfileSuspenseQuery>;
export type PartnerProfileQueryResult = Apollo.QueryResult<PartnerProfileQuery, PartnerProfileQueryVariables>;
export const ReviewDocument = gql`
    query Review($id: ID!) {
        review(id: $id) {
            id
            createdAt
            rentalUnit {
                ...UnitDetails
                accommodation {
                    id
                    name
                }
            }
            title
            comment
            tips
            average
            ratingLocation
            ratingHospitality
            ratingLayout
            ratingMaintenance
            ratingValueForMoney
            city
            enabled
            language
            reply
        }
    }
    ${UnitDetailsFragmentDoc}
`;

/**
 * __useReviewQuery__
 *
 * To run a query within a React component, call `useReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReviewQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<ReviewQuery, ReviewQueryVariables> &
        ({ variables: ReviewQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ReviewQuery, ReviewQueryVariables>(ReviewDocument, options);
}
export function useReviewLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewQuery, ReviewQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ReviewQuery, ReviewQueryVariables>(ReviewDocument, options);
}
export function useReviewSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<ReviewQuery, ReviewQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<ReviewQuery, ReviewQueryVariables>(ReviewDocument, options);
}
export type ReviewQueryHookResult = ReturnType<typeof useReviewQuery>;
export type ReviewLazyQueryHookResult = ReturnType<typeof useReviewLazyQuery>;
export type ReviewSuspenseQueryHookResult = ReturnType<typeof useReviewSuspenseQuery>;
export type ReviewQueryResult = Apollo.QueryResult<ReviewQuery, ReviewQueryVariables>;
export const AddReplyDocument = gql`
    mutation addReply($reviewId: ID!, $reply: String!) {
        editReviewReply(input: { reviewId: $reviewId, reply: $reply }) {
            review {
                id
                reply
            }
        }
    }
`;

/**
 * __useAddReplyMutation__
 *
 * To run a mutation, you first call `useAddReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReplyMutation, { data, loading, error }] = useAddReplyMutation({
 *   variables: {
 *      reviewId: // value for 'reviewId'
 *      reply: // value for 'reply'
 *   },
 * });
 */
export function useAddReplyMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<AddReplyMutation, AddReplyMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<AddReplyMutation, AddReplyMutationVariables>(AddReplyDocument, options);
}
export type AddReplyMutationHookResult = ReturnType<typeof useAddReplyMutation>;
export type AddReplyMutationResult = Apollo.MutationResult<AddReplyMutation>;
export type AddReplyMutationOptions = Apollo.BaseMutationOptions<AddReplyMutation, AddReplyMutationVariables>;
export const AllTicketsDocument = gql`
    query AllTickets(
        $partnerId: ID!
        $after: String
        $first: Int
        $before: String
        $last: Int
        $timeslotId: ID
        $activityIds: [ID!]
        $companyId: ID
        $startDate: Date
        $endDate: Date
    ) {
        partner(id: $partnerId) {
            id
            allTickets(
                first: $first
                last: $last
                after: $after
                before: $before
                timeslotId: $timeslotId
                activityIds: $activityIds
                companyId: $companyId
                startDate: $startDate
                endDate: $endDate
            ) {
                ...TicketConnection
            }
        }
    }
    ${TicketConnectionFragmentDoc}
`;

/**
 * __useAllTicketsQuery__
 *
 * To run a query within a React component, call `useAllTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTicketsQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      timeslotId: // value for 'timeslotId'
 *      activityIds: // value for 'activityIds'
 *      companyId: // value for 'companyId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useAllTicketsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<AllTicketsQuery, AllTicketsQueryVariables> &
        ({ variables: AllTicketsQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<AllTicketsQuery, AllTicketsQueryVariables>(AllTicketsDocument, options);
}
export function useAllTicketsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllTicketsQuery, AllTicketsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<AllTicketsQuery, AllTicketsQueryVariables>(AllTicketsDocument, options);
}
export function useAllTicketsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<AllTicketsQuery, AllTicketsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<AllTicketsQuery, AllTicketsQueryVariables>(AllTicketsDocument, options);
}
export type AllTicketsQueryHookResult = ReturnType<typeof useAllTicketsQuery>;
export type AllTicketsLazyQueryHookResult = ReturnType<typeof useAllTicketsLazyQuery>;
export type AllTicketsSuspenseQueryHookResult = ReturnType<typeof useAllTicketsSuspenseQuery>;
export type AllTicketsQueryResult = Apollo.QueryResult<AllTicketsQuery, AllTicketsQueryVariables>;
export const TicketDocument = gql`
    query Ticket($id: ID!) {
        ticket(id: $id) {
            id
            createdAt
            status
            timeslot {
                id
                startDateTime
                endDateTime
                activity {
                    ...ActivityPickerEntry
                }
            }
            rateLines {
                rateName
                amount
                unitPrice
                qrCodes {
                    code
                    number
                    scannedAt
                    scannedBy
                }
            }
            priceLines {
                label
                quantity
                totalPrice
                unitPrice
            }
            customer {
                ...CustomerDetails
            }
        }
    }
    ${ActivityPickerEntryFragmentDoc}
    ${CustomerDetailsFragmentDoc}
`;

/**
 * __useTicketQuery__
 *
 * To run a query within a React component, call `useTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTicketQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<TicketQuery, TicketQueryVariables> &
        ({ variables: TicketQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<TicketQuery, TicketQueryVariables>(TicketDocument, options);
}
export function useTicketLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TicketQuery, TicketQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<TicketQuery, TicketQueryVariables>(TicketDocument, options);
}
export function useTicketSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<TicketQuery, TicketQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<TicketQuery, TicketQueryVariables>(TicketDocument, options);
}
export type TicketQueryHookResult = ReturnType<typeof useTicketQuery>;
export type TicketLazyQueryHookResult = ReturnType<typeof useTicketLazyQuery>;
export type TicketSuspenseQueryHookResult = ReturnType<typeof useTicketSuspenseQuery>;
export type TicketQueryResult = Apollo.QueryResult<TicketQuery, TicketQueryVariables>;
export const NewTicketsDocument = gql`
    query NewTickets(
        $partnerId: ID!
        $after: String
        $first: Int
        $before: String
        $last: Int
        $timeslotId: ID
        $activityIds: [ID!]
        $companyId: ID
    ) {
        partner(id: $partnerId) {
            id
            newTickets(
                first: $first
                last: $last
                after: $after
                before: $before
                timeslotId: $timeslotId
                activityIds: $activityIds
                companyId: $companyId
            ) {
                ...TicketConnection
            }
        }
    }
    ${TicketConnectionFragmentDoc}
`;

/**
 * __useNewTicketsQuery__
 *
 * To run a query within a React component, call `useNewTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewTicketsQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      timeslotId: // value for 'timeslotId'
 *      activityIds: // value for 'activityIds'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useNewTicketsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<NewTicketsQuery, NewTicketsQueryVariables> &
        ({ variables: NewTicketsQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<NewTicketsQuery, NewTicketsQueryVariables>(NewTicketsDocument, options);
}
export function useNewTicketsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NewTicketsQuery, NewTicketsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<NewTicketsQuery, NewTicketsQueryVariables>(NewTicketsDocument, options);
}
export function useNewTicketsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<NewTicketsQuery, NewTicketsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<NewTicketsQuery, NewTicketsQueryVariables>(NewTicketsDocument, options);
}
export type NewTicketsQueryHookResult = ReturnType<typeof useNewTicketsQuery>;
export type NewTicketsLazyQueryHookResult = ReturnType<typeof useNewTicketsLazyQuery>;
export type NewTicketsSuspenseQueryHookResult = ReturnType<typeof useNewTicketsSuspenseQuery>;
export type NewTicketsQueryResult = Apollo.QueryResult<NewTicketsQuery, NewTicketsQueryVariables>;
export const AvailabilityDocument = gql`
    query Availability($unitSlug: String!, $start: Date!, $allotmentStart: Date!, $end: Date!) {
        rentalUnit(slug: $unitSlug) {
            id
            ...AvailabilityMetaData
            allotments(startDate: $allotmentStart, endDate: $end) @connection(key: "allotments") {
                ...PriceColumnAllotment
            }
            bookings(startDate: $start, endDate: $end) {
                ...AllotmentsBooking
            }
            allotmentLockouts(startDate: $start, endDate: $end) {
                ...AllotmentsLockout
            }
            ...PricesRowVisibility
        }
    }
    ${AvailabilityMetaDataFragmentDoc}
    ${PriceColumnAllotmentFragmentDoc}
    ${AllotmentsBookingFragmentDoc}
    ${AllotmentsLockoutFragmentDoc}
    ${PricesRowVisibilityFragmentDoc}
`;

/**
 * __useAvailabilityQuery__
 *
 * To run a query within a React component, call `useAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilityQuery({
 *   variables: {
 *      unitSlug: // value for 'unitSlug'
 *      start: // value for 'start'
 *      allotmentStart: // value for 'allotmentStart'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useAvailabilityQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<AvailabilityQuery, AvailabilityQueryVariables> &
        ({ variables: AvailabilityQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<AvailabilityQuery, AvailabilityQueryVariables>(AvailabilityDocument, options);
}
export function useAvailabilityLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AvailabilityQuery, AvailabilityQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<AvailabilityQuery, AvailabilityQueryVariables>(AvailabilityDocument, options);
}
export function useAvailabilitySuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<AvailabilityQuery, AvailabilityQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<AvailabilityQuery, AvailabilityQueryVariables>(
        AvailabilityDocument,
        options
    );
}
export type AvailabilityQueryHookResult = ReturnType<typeof useAvailabilityQuery>;
export type AvailabilityLazyQueryHookResult = ReturnType<typeof useAvailabilityLazyQuery>;
export type AvailabilitySuspenseQueryHookResult = ReturnType<typeof useAvailabilitySuspenseQuery>;
export type AvailabilityQueryResult = Apollo.QueryResult<AvailabilityQuery, AvailabilityQueryVariables>;
export const PricesDocument = gql`
    query Prices($unitSlug: String!, $start: Date!, $end: Date!) {
        rentalUnit(slug: $unitSlug) {
            ...Pricing
            datePricings(startDate: $start, endDate: $end) {
                ...PriceColumnPricing
            }
            datePricingModifiers(startDate: $start, endDate: $end) {
                ...PeriodRules
            }
        }
    }
    ${PricingFragmentDoc}
    ${PriceColumnPricingFragmentDoc}
    ${PeriodRulesFragmentDoc}
`;

/**
 * __usePricesQuery__
 *
 * To run a query within a React component, call `usePricesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricesQuery({
 *   variables: {
 *      unitSlug: // value for 'unitSlug'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function usePricesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<PricesQuery, PricesQueryVariables> &
        ({ variables: PricesQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PricesQuery, PricesQueryVariables>(PricesDocument, options);
}
export function usePricesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PricesQuery, PricesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<PricesQuery, PricesQueryVariables>(PricesDocument, options);
}
export function usePricesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<PricesQuery, PricesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<PricesQuery, PricesQueryVariables>(PricesDocument, options);
}
export type PricesQueryHookResult = ReturnType<typeof usePricesQuery>;
export type PricesLazyQueryHookResult = ReturnType<typeof usePricesLazyQuery>;
export type PricesSuspenseQueryHookResult = ReturnType<typeof usePricesSuspenseQuery>;
export type PricesQueryResult = Apollo.QueryResult<PricesQuery, PricesQueryVariables>;
export const PricesMetaDataDocument = gql`
    query PricesMetaData($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            ...PricesRowVisibility
        }
    }
    ${PricesRowVisibilityFragmentDoc}
`;

/**
 * __usePricesMetaDataQuery__
 *
 * To run a query within a React component, call `usePricesMetaDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricesMetaDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricesMetaDataQuery({
 *   variables: {
 *      unitSlug: // value for 'unitSlug'
 *   },
 * });
 */
export function usePricesMetaDataQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<PricesMetaDataQuery, PricesMetaDataQueryVariables> &
        ({ variables: PricesMetaDataQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PricesMetaDataQuery, PricesMetaDataQueryVariables>(
        PricesMetaDataDocument,
        options
    );
}
export function usePricesMetaDataLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PricesMetaDataQuery, PricesMetaDataQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<PricesMetaDataQuery, PricesMetaDataQueryVariables>(
        PricesMetaDataDocument,
        options
    );
}
export function usePricesMetaDataSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<PricesMetaDataQuery, PricesMetaDataQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<PricesMetaDataQuery, PricesMetaDataQueryVariables>(
        PricesMetaDataDocument,
        options
    );
}
export type PricesMetaDataQueryHookResult = ReturnType<typeof usePricesMetaDataQuery>;
export type PricesMetaDataLazyQueryHookResult = ReturnType<typeof usePricesMetaDataLazyQuery>;
export type PricesMetaDataSuspenseQueryHookResult = ReturnType<typeof usePricesMetaDataSuspenseQuery>;
export type PricesMetaDataQueryResult = Apollo.QueryResult<PricesMetaDataQuery, PricesMetaDataQueryVariables>;
export const SpecialParticipationsDocument = gql`
    query SpecialParticipations($unitSlug: String!, $start: Date!, $end: Date!) {
        rentalUnit(slug: $unitSlug) {
            ...Pricing
            specialParticipations {
                ...SpecialParticipation
            }
        }
    }
    ${PricingFragmentDoc}
    ${SpecialParticipationFragmentDoc}
`;

/**
 * __useSpecialParticipationsQuery__
 *
 * To run a query within a React component, call `useSpecialParticipationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecialParticipationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecialParticipationsQuery({
 *   variables: {
 *      unitSlug: // value for 'unitSlug'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useSpecialParticipationsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<SpecialParticipationsQuery, SpecialParticipationsQueryVariables> &
        ({ variables: SpecialParticipationsQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<SpecialParticipationsQuery, SpecialParticipationsQueryVariables>(
        SpecialParticipationsDocument,
        options
    );
}
export function useSpecialParticipationsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SpecialParticipationsQuery, SpecialParticipationsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<SpecialParticipationsQuery, SpecialParticipationsQueryVariables>(
        SpecialParticipationsDocument,
        options
    );
}
export function useSpecialParticipationsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        SpecialParticipationsQuery,
        SpecialParticipationsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<SpecialParticipationsQuery, SpecialParticipationsQueryVariables>(
        SpecialParticipationsDocument,
        options
    );
}
export type SpecialParticipationsQueryHookResult = ReturnType<typeof useSpecialParticipationsQuery>;
export type SpecialParticipationsLazyQueryHookResult = ReturnType<typeof useSpecialParticipationsLazyQuery>;
export type SpecialParticipationsSuspenseQueryHookResult = ReturnType<typeof useSpecialParticipationsSuspenseQuery>;
export type SpecialParticipationsQueryResult = Apollo.QueryResult<
    SpecialParticipationsQuery,
    SpecialParticipationsQueryVariables
>;
export const TripsDocument = gql`
    query Trips($unitSlug: String!, $start: Date!, $end: Date!) {
        rentalUnit(slug: $unitSlug) {
            ...Pricing
            tripPricings(startDate: $start, endDate: $end) {
                ...TripColumn
            }
            tripPricingExportUrl
        }
    }
    ${PricingFragmentDoc}
    ${TripColumnFragmentDoc}
`;

/**
 * __useTripsQuery__
 *
 * To run a query within a React component, call `useTripsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTripsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTripsQuery({
 *   variables: {
 *      unitSlug: // value for 'unitSlug'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useTripsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<TripsQuery, TripsQueryVariables> &
        ({ variables: TripsQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<TripsQuery, TripsQueryVariables>(TripsDocument, options);
}
export function useTripsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TripsQuery, TripsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<TripsQuery, TripsQueryVariables>(TripsDocument, options);
}
export function useTripsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<TripsQuery, TripsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<TripsQuery, TripsQueryVariables>(TripsDocument, options);
}
export type TripsQueryHookResult = ReturnType<typeof useTripsQuery>;
export type TripsLazyQueryHookResult = ReturnType<typeof useTripsLazyQuery>;
export type TripsSuspenseQueryHookResult = ReturnType<typeof useTripsSuspenseQuery>;
export type TripsQueryResult = Apollo.QueryResult<TripsQuery, TripsQueryVariables>;
export const AccommodationDescriptionDocument = gql`
    query AccommodationDescription($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            id
            accommodation {
                ...AccommodationDescription
            }
        }
    }
    ${AccommodationDescriptionFragmentDoc}
`;

/**
 * __useAccommodationDescriptionQuery__
 *
 * To run a query within a React component, call `useAccommodationDescriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccommodationDescriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccommodationDescriptionQuery({
 *   variables: {
 *      unitSlug: // value for 'unitSlug'
 *   },
 * });
 */
export function useAccommodationDescriptionQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        AccommodationDescriptionQuery,
        AccommodationDescriptionQueryVariables
    > &
        ({ variables: AccommodationDescriptionQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<AccommodationDescriptionQuery, AccommodationDescriptionQueryVariables>(
        AccommodationDescriptionDocument,
        options
    );
}
export function useAccommodationDescriptionLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        AccommodationDescriptionQuery,
        AccommodationDescriptionQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<AccommodationDescriptionQuery, AccommodationDescriptionQueryVariables>(
        AccommodationDescriptionDocument,
        options
    );
}
export function useAccommodationDescriptionSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        AccommodationDescriptionQuery,
        AccommodationDescriptionQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<AccommodationDescriptionQuery, AccommodationDescriptionQueryVariables>(
        AccommodationDescriptionDocument,
        options
    );
}
export type AccommodationDescriptionQueryHookResult = ReturnType<typeof useAccommodationDescriptionQuery>;
export type AccommodationDescriptionLazyQueryHookResult = ReturnType<typeof useAccommodationDescriptionLazyQuery>;
export type AccommodationDescriptionSuspenseQueryHookResult = ReturnType<
    typeof useAccommodationDescriptionSuspenseQuery
>;
export type AccommodationDescriptionQueryResult = Apollo.QueryResult<
    AccommodationDescriptionQuery,
    AccommodationDescriptionQueryVariables
>;
export const EditAccommodationDescriptionDocument = gql`
    mutation EditAccommodationDescription($input: EditAccommodationDescriptionInput!) {
        editAccommodationDescription(input: $input) {
            accommodation {
                ...AccommodationDescription
            }
        }
    }
    ${AccommodationDescriptionFragmentDoc}
`;

/**
 * __useEditAccommodationDescriptionMutation__
 *
 * To run a mutation, you first call `useEditAccommodationDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAccommodationDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAccommodationDescriptionMutation, { data, loading, error }] = useEditAccommodationDescriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAccommodationDescriptionMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        EditAccommodationDescriptionMutation,
        EditAccommodationDescriptionMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        EditAccommodationDescriptionMutation,
        EditAccommodationDescriptionMutationVariables
    >(EditAccommodationDescriptionDocument, options);
}
export type EditAccommodationDescriptionMutationHookResult = ReturnType<typeof useEditAccommodationDescriptionMutation>;
export type EditAccommodationDescriptionMutationResult = Apollo.MutationResult<EditAccommodationDescriptionMutation>;
export type EditAccommodationDescriptionMutationOptions = Apollo.BaseMutationOptions<
    EditAccommodationDescriptionMutation,
    EditAccommodationDescriptionMutationVariables
>;
export const AccommodationInfoDocument = gql`
    query AccommodationInfo($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            ...AccommodationDetails
        }
    }
    ${AccommodationDetailsFragmentDoc}
`;

/**
 * __useAccommodationInfoQuery__
 *
 * To run a query within a React component, call `useAccommodationInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccommodationInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccommodationInfoQuery({
 *   variables: {
 *      unitSlug: // value for 'unitSlug'
 *   },
 * });
 */
export function useAccommodationInfoQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<AccommodationInfoQuery, AccommodationInfoQueryVariables> &
        ({ variables: AccommodationInfoQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<AccommodationInfoQuery, AccommodationInfoQueryVariables>(
        AccommodationInfoDocument,
        options
    );
}
export function useAccommodationInfoLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccommodationInfoQuery, AccommodationInfoQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<AccommodationInfoQuery, AccommodationInfoQueryVariables>(
        AccommodationInfoDocument,
        options
    );
}
export function useAccommodationInfoSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<AccommodationInfoQuery, AccommodationInfoQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<AccommodationInfoQuery, AccommodationInfoQueryVariables>(
        AccommodationInfoDocument,
        options
    );
}
export type AccommodationInfoQueryHookResult = ReturnType<typeof useAccommodationInfoQuery>;
export type AccommodationInfoLazyQueryHookResult = ReturnType<typeof useAccommodationInfoLazyQuery>;
export type AccommodationInfoSuspenseQueryHookResult = ReturnType<typeof useAccommodationInfoSuspenseQuery>;
export type AccommodationInfoQueryResult = Apollo.QueryResult<AccommodationInfoQuery, AccommodationInfoQueryVariables>;
export const EditAccommodationInfoDocument = gql`
    mutation EditAccommodationInfo($input: EditAccommodationInfoInput!) {
        editAccommodationInfo(input: $input) {
            rentalUnit {
                ...AccommodationDetails
            }
        }
    }
    ${AccommodationDetailsFragmentDoc}
`;

/**
 * __useEditAccommodationInfoMutation__
 *
 * To run a mutation, you first call `useEditAccommodationInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAccommodationInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAccommodationInfoMutation, { data, loading, error }] = useEditAccommodationInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAccommodationInfoMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        EditAccommodationInfoMutation,
        EditAccommodationInfoMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditAccommodationInfoMutation, EditAccommodationInfoMutationVariables>(
        EditAccommodationInfoDocument,
        options
    );
}
export type EditAccommodationInfoMutationHookResult = ReturnType<typeof useEditAccommodationInfoMutation>;
export type EditAccommodationInfoMutationResult = Apollo.MutationResult<EditAccommodationInfoMutation>;
export type EditAccommodationInfoMutationOptions = Apollo.BaseMutationOptions<
    EditAccommodationInfoMutation,
    EditAccommodationInfoMutationVariables
>;
export const AccommodationImagesDocument = gql`
    query AccommodationImages($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            id
            accommodation {
                ...AccommodationImages
            }
        }
    }
    ${AccommodationImagesFragmentDoc}
`;

/**
 * __useAccommodationImagesQuery__
 *
 * To run a query within a React component, call `useAccommodationImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccommodationImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccommodationImagesQuery({
 *   variables: {
 *      unitSlug: // value for 'unitSlug'
 *   },
 * });
 */
export function useAccommodationImagesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<AccommodationImagesQuery, AccommodationImagesQueryVariables> &
        ({ variables: AccommodationImagesQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<AccommodationImagesQuery, AccommodationImagesQueryVariables>(
        AccommodationImagesDocument,
        options
    );
}
export function useAccommodationImagesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccommodationImagesQuery, AccommodationImagesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<AccommodationImagesQuery, AccommodationImagesQueryVariables>(
        AccommodationImagesDocument,
        options
    );
}
export function useAccommodationImagesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<AccommodationImagesQuery, AccommodationImagesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<AccommodationImagesQuery, AccommodationImagesQueryVariables>(
        AccommodationImagesDocument,
        options
    );
}
export type AccommodationImagesQueryHookResult = ReturnType<typeof useAccommodationImagesQuery>;
export type AccommodationImagesLazyQueryHookResult = ReturnType<typeof useAccommodationImagesLazyQuery>;
export type AccommodationImagesSuspenseQueryHookResult = ReturnType<typeof useAccommodationImagesSuspenseQuery>;
export type AccommodationImagesQueryResult = Apollo.QueryResult<
    AccommodationImagesQuery,
    AccommodationImagesQueryVariables
>;
export const EditAccommodationImagesDocument = gql`
    mutation EditAccommodationImages($input: EditAccommodationImagesInput!) {
        editAccommodationImages(input: $input) {
            accommodation {
                ...AccommodationImages
            }
        }
    }
    ${AccommodationImagesFragmentDoc}
`;

/**
 * __useEditAccommodationImagesMutation__
 *
 * To run a mutation, you first call `useEditAccommodationImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAccommodationImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAccommodationImagesMutation, { data, loading, error }] = useEditAccommodationImagesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAccommodationImagesMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        EditAccommodationImagesMutation,
        EditAccommodationImagesMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditAccommodationImagesMutation, EditAccommodationImagesMutationVariables>(
        EditAccommodationImagesDocument,
        options
    );
}
export type EditAccommodationImagesMutationHookResult = ReturnType<typeof useEditAccommodationImagesMutation>;
export type EditAccommodationImagesMutationResult = Apollo.MutationResult<EditAccommodationImagesMutation>;
export type EditAccommodationImagesMutationOptions = Apollo.BaseMutationOptions<
    EditAccommodationImagesMutation,
    EditAccommodationImagesMutationVariables
>;
export const AttributesDocument = gql`
    query Attributes($unitSlug: String!, $subtype: RentalUnitTypeEnum) {
        rentalUnit(slug: $unitSlug) {
            ...Attributes
        }
        attributeCategories {
            ...AttributeCategory
        }
    }
    ${AttributesFragmentDoc}
    ${AttributeCategoryFragmentDoc}
`;

/**
 * __useAttributesQuery__
 *
 * To run a query within a React component, call `useAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttributesQuery({
 *   variables: {
 *      unitSlug: // value for 'unitSlug'
 *      subtype: // value for 'subtype'
 *   },
 * });
 */
export function useAttributesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<AttributesQuery, AttributesQueryVariables> &
        ({ variables: AttributesQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<AttributesQuery, AttributesQueryVariables>(AttributesDocument, options);
}
export function useAttributesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AttributesQuery, AttributesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<AttributesQuery, AttributesQueryVariables>(AttributesDocument, options);
}
export function useAttributesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<AttributesQuery, AttributesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<AttributesQuery, AttributesQueryVariables>(AttributesDocument, options);
}
export type AttributesQueryHookResult = ReturnType<typeof useAttributesQuery>;
export type AttributesLazyQueryHookResult = ReturnType<typeof useAttributesLazyQuery>;
export type AttributesSuspenseQueryHookResult = ReturnType<typeof useAttributesSuspenseQuery>;
export type AttributesQueryResult = Apollo.QueryResult<AttributesQuery, AttributesQueryVariables>;
export const EditAttributesDocument = gql`
    mutation EditAttributes($input: EditAttributesInput!) {
        editAttributes(input: $input) {
            rentalUnit {
                ...Attributes
            }
        }
    }
    ${AttributesFragmentDoc}
`;

/**
 * __useEditAttributesMutation__
 *
 * To run a mutation, you first call `useEditAttributesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAttributesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAttributesMutation, { data, loading, error }] = useEditAttributesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAttributesMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<EditAttributesMutation, EditAttributesMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditAttributesMutation, EditAttributesMutationVariables>(
        EditAttributesDocument,
        options
    );
}
export type EditAttributesMutationHookResult = ReturnType<typeof useEditAttributesMutation>;
export type EditAttributesMutationResult = Apollo.MutationResult<EditAttributesMutation>;
export type EditAttributesMutationOptions = Apollo.BaseMutationOptions<
    EditAttributesMutation,
    EditAttributesMutationVariables
>;
export const OverwriteRentalUnitSurchargesDocument = gql`
    mutation overwriteRentalUnitSurcharges($input: OverwriteRentalUnitSurchargesInput!) {
        overwriteRentalUnitSurcharges(input: $input) {
            targetRentalUnits {
                id
                surcharges {
                    id
                }
            }
        }
    }
`;

/**
 * __useOverwriteRentalUnitSurchargesMutation__
 *
 * To run a mutation, you first call `useOverwriteRentalUnitSurchargesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOverwriteRentalUnitSurchargesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [overwriteRentalUnitSurchargesMutation, { data, loading, error }] = useOverwriteRentalUnitSurchargesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOverwriteRentalUnitSurchargesMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        OverwriteRentalUnitSurchargesMutation,
        OverwriteRentalUnitSurchargesMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        OverwriteRentalUnitSurchargesMutation,
        OverwriteRentalUnitSurchargesMutationVariables
    >(OverwriteRentalUnitSurchargesDocument, options);
}
export type OverwriteRentalUnitSurchargesMutationHookResult = ReturnType<
    typeof useOverwriteRentalUnitSurchargesMutation
>;
export type OverwriteRentalUnitSurchargesMutationResult = Apollo.MutationResult<OverwriteRentalUnitSurchargesMutation>;
export type OverwriteRentalUnitSurchargesMutationOptions = Apollo.BaseMutationOptions<
    OverwriteRentalUnitSurchargesMutation,
    OverwriteRentalUnitSurchargesMutationVariables
>;
export const CapacityDocument = gql`
    query Capacity($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            ...Capacity
        }
    }
    ${CapacityFragmentDoc}
`;

/**
 * __useCapacityQuery__
 *
 * To run a query within a React component, call `useCapacityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCapacityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCapacityQuery({
 *   variables: {
 *      unitSlug: // value for 'unitSlug'
 *   },
 * });
 */
export function useCapacityQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<CapacityQuery, CapacityQueryVariables> &
        ({ variables: CapacityQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CapacityQuery, CapacityQueryVariables>(CapacityDocument, options);
}
export function useCapacityLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CapacityQuery, CapacityQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CapacityQuery, CapacityQueryVariables>(CapacityDocument, options);
}
export function useCapacitySuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<CapacityQuery, CapacityQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<CapacityQuery, CapacityQueryVariables>(CapacityDocument, options);
}
export type CapacityQueryHookResult = ReturnType<typeof useCapacityQuery>;
export type CapacityLazyQueryHookResult = ReturnType<typeof useCapacityLazyQuery>;
export type CapacitySuspenseQueryHookResult = ReturnType<typeof useCapacitySuspenseQuery>;
export type CapacityQueryResult = Apollo.QueryResult<CapacityQuery, CapacityQueryVariables>;
export const EditCapacityDocument = gql`
    mutation EditCapacity($input: EditCapacityAndSpaceInput!) {
        editCapacityAndSpace(input: $input) {
            rentalUnit {
                ...Capacity
            }
        }
    }
    ${CapacityFragmentDoc}
`;

/**
 * __useEditCapacityMutation__
 *
 * To run a mutation, you first call `useEditCapacityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCapacityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCapacityMutation, { data, loading, error }] = useEditCapacityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditCapacityMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<EditCapacityMutation, EditCapacityMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditCapacityMutation, EditCapacityMutationVariables>(
        EditCapacityDocument,
        options
    );
}
export type EditCapacityMutationHookResult = ReturnType<typeof useEditCapacityMutation>;
export type EditCapacityMutationResult = Apollo.MutationResult<EditCapacityMutation>;
export type EditCapacityMutationOptions = Apollo.BaseMutationOptions<
    EditCapacityMutation,
    EditCapacityMutationVariables
>;
export const CompactDetailsDocument = gql`
    query CompactDetails($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            ...CompactInfo
        }
    }
    ${CompactInfoFragmentDoc}
`;

/**
 * __useCompactDetailsQuery__
 *
 * To run a query within a React component, call `useCompactDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompactDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompactDetailsQuery({
 *   variables: {
 *      unitSlug: // value for 'unitSlug'
 *   },
 * });
 */
export function useCompactDetailsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<CompactDetailsQuery, CompactDetailsQueryVariables> &
        ({ variables: CompactDetailsQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<CompactDetailsQuery, CompactDetailsQueryVariables>(
        CompactDetailsDocument,
        options
    );
}
export function useCompactDetailsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CompactDetailsQuery, CompactDetailsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<CompactDetailsQuery, CompactDetailsQueryVariables>(
        CompactDetailsDocument,
        options
    );
}
export function useCompactDetailsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<CompactDetailsQuery, CompactDetailsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<CompactDetailsQuery, CompactDetailsQueryVariables>(
        CompactDetailsDocument,
        options
    );
}
export type CompactDetailsQueryHookResult = ReturnType<typeof useCompactDetailsQuery>;
export type CompactDetailsLazyQueryHookResult = ReturnType<typeof useCompactDetailsLazyQuery>;
export type CompactDetailsSuspenseQueryHookResult = ReturnType<typeof useCompactDetailsSuspenseQuery>;
export type CompactDetailsQueryResult = Apollo.QueryResult<CompactDetailsQuery, CompactDetailsQueryVariables>;
export const EditRentalUnitAndAccommodationInfoDocument = gql`
    mutation EditRentalUnitAndAccommodationInfo($input: EditRentalUnitAndAccommodationInfoInput!) {
        editRentalUnitAndAccommodationInfo(input: $input) {
            rentalUnit {
                ...CompactInfo
            }
        }
    }
    ${CompactInfoFragmentDoc}
`;

/**
 * __useEditRentalUnitAndAccommodationInfoMutation__
 *
 * To run a mutation, you first call `useEditRentalUnitAndAccommodationInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRentalUnitAndAccommodationInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRentalUnitAndAccommodationInfoMutation, { data, loading, error }] = useEditRentalUnitAndAccommodationInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditRentalUnitAndAccommodationInfoMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        EditRentalUnitAndAccommodationInfoMutation,
        EditRentalUnitAndAccommodationInfoMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        EditRentalUnitAndAccommodationInfoMutation,
        EditRentalUnitAndAccommodationInfoMutationVariables
    >(EditRentalUnitAndAccommodationInfoDocument, options);
}
export type EditRentalUnitAndAccommodationInfoMutationHookResult = ReturnType<
    typeof useEditRentalUnitAndAccommodationInfoMutation
>;
export type EditRentalUnitAndAccommodationInfoMutationResult =
    Apollo.MutationResult<EditRentalUnitAndAccommodationInfoMutation>;
export type EditRentalUnitAndAccommodationInfoMutationOptions = Apollo.BaseMutationOptions<
    EditRentalUnitAndAccommodationInfoMutation,
    EditRentalUnitAndAccommodationInfoMutationVariables
>;
export const ContractsDocument = gql`
    query Contracts($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            id
            rentalUnitContracts {
                ...Contracts
            }
        }
    }
    ${ContractsFragmentDoc}
`;

/**
 * __useContractsQuery__
 *
 * To run a query within a React component, call `useContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractsQuery({
 *   variables: {
 *      unitSlug: // value for 'unitSlug'
 *   },
 * });
 */
export function useContractsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<ContractsQuery, ContractsQueryVariables> &
        ({ variables: ContractsQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<ContractsQuery, ContractsQueryVariables>(ContractsDocument, options);
}
export function useContractsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContractsQuery, ContractsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<ContractsQuery, ContractsQueryVariables>(ContractsDocument, options);
}
export function useContractsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<ContractsQuery, ContractsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<ContractsQuery, ContractsQueryVariables>(ContractsDocument, options);
}
export type ContractsQueryHookResult = ReturnType<typeof useContractsQuery>;
export type ContractsLazyQueryHookResult = ReturnType<typeof useContractsLazyQuery>;
export type ContractsSuspenseQueryHookResult = ReturnType<typeof useContractsSuspenseQuery>;
export type ContractsQueryResult = Apollo.QueryResult<ContractsQuery, ContractsQueryVariables>;
export const ApproveContractDocument = gql`
    mutation ApproveContract($id: ID!) {
        approveRentalUnitContract(input: { rentalUnitContractId: $id }) {
            rentalUnitContract {
                ...Contracts
            }
        }
    }
    ${ContractsFragmentDoc}
`;

/**
 * __useApproveContractMutation__
 *
 * To run a mutation, you first call `useApproveContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveContractMutation, { data, loading, error }] = useApproveContractMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveContractMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveContractMutation, ApproveContractMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<ApproveContractMutation, ApproveContractMutationVariables>(
        ApproveContractDocument,
        options
    );
}
export type ApproveContractMutationHookResult = ReturnType<typeof useApproveContractMutation>;
export type ApproveContractMutationResult = Apollo.MutationResult<ApproveContractMutation>;
export type ApproveContractMutationOptions = Apollo.BaseMutationOptions<
    ApproveContractMutation,
    ApproveContractMutationVariables
>;
export const IcalDocument = gql`
    query Ical($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            ...Ical
        }
    }
    ${IcalFragmentDoc}
`;

/**
 * __useIcalQuery__
 *
 * To run a query within a React component, call `useIcalQuery` and pass it any options that fit your needs.
 * When your component renders, `useIcalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIcalQuery({
 *   variables: {
 *      unitSlug: // value for 'unitSlug'
 *   },
 * });
 */
export function useIcalQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<IcalQuery, IcalQueryVariables> &
        ({ variables: IcalQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<IcalQuery, IcalQueryVariables>(IcalDocument, options);
}
export function useIcalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IcalQuery, IcalQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<IcalQuery, IcalQueryVariables>(IcalDocument, options);
}
export function useIcalSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<IcalQuery, IcalQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<IcalQuery, IcalQueryVariables>(IcalDocument, options);
}
export type IcalQueryHookResult = ReturnType<typeof useIcalQuery>;
export type IcalLazyQueryHookResult = ReturnType<typeof useIcalLazyQuery>;
export type IcalSuspenseQueryHookResult = ReturnType<typeof useIcalSuspenseQuery>;
export type IcalQueryResult = Apollo.QueryResult<IcalQuery, IcalQueryVariables>;
export const EditIcalImportFeedDocument = gql`
    mutation EditIcalImportFeed($input: EditIcalImportFeedsInput!) {
        editIcalImportFeeds(input: $input) {
            icalImportFeeds {
                icalImportUrl
                source
            }
        }
    }
`;

/**
 * __useEditIcalImportFeedMutation__
 *
 * To run a mutation, you first call `useEditIcalImportFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditIcalImportFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editIcalImportFeedMutation, { data, loading, error }] = useEditIcalImportFeedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditIcalImportFeedMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<EditIcalImportFeedMutation, EditIcalImportFeedMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditIcalImportFeedMutation, EditIcalImportFeedMutationVariables>(
        EditIcalImportFeedDocument,
        options
    );
}
export type EditIcalImportFeedMutationHookResult = ReturnType<typeof useEditIcalImportFeedMutation>;
export type EditIcalImportFeedMutationResult = Apollo.MutationResult<EditIcalImportFeedMutation>;
export type EditIcalImportFeedMutationOptions = Apollo.BaseMutationOptions<
    EditIcalImportFeedMutation,
    EditIcalImportFeedMutationVariables
>;
export const KeyHolderDocument = gql`
    query KeyHolder($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            ...KeyHolder
        }
    }
    ${KeyHolderFragmentDoc}
`;

/**
 * __useKeyHolderQuery__
 *
 * To run a query within a React component, call `useKeyHolderQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeyHolderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeyHolderQuery({
 *   variables: {
 *      unitSlug: // value for 'unitSlug'
 *   },
 * });
 */
export function useKeyHolderQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<KeyHolderQuery, KeyHolderQueryVariables> &
        ({ variables: KeyHolderQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<KeyHolderQuery, KeyHolderQueryVariables>(KeyHolderDocument, options);
}
export function useKeyHolderLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KeyHolderQuery, KeyHolderQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<KeyHolderQuery, KeyHolderQueryVariables>(KeyHolderDocument, options);
}
export function useKeyHolderSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<KeyHolderQuery, KeyHolderQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<KeyHolderQuery, KeyHolderQueryVariables>(KeyHolderDocument, options);
}
export type KeyHolderQueryHookResult = ReturnType<typeof useKeyHolderQuery>;
export type KeyHolderLazyQueryHookResult = ReturnType<typeof useKeyHolderLazyQuery>;
export type KeyHolderSuspenseQueryHookResult = ReturnType<typeof useKeyHolderSuspenseQuery>;
export type KeyHolderQueryResult = Apollo.QueryResult<KeyHolderQuery, KeyHolderQueryVariables>;
export const EditKeyHolderDocument = gql`
    mutation EditKeyHolder($input: EditKeyHolderInput!) {
        editKeyHolder(input: $input) {
            rentalUnit {
                ...KeyHolder
            }
        }
    }
    ${KeyHolderFragmentDoc}
`;

/**
 * __useEditKeyHolderMutation__
 *
 * To run a mutation, you first call `useEditKeyHolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditKeyHolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editKeyHolderMutation, { data, loading, error }] = useEditKeyHolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditKeyHolderMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<EditKeyHolderMutation, EditKeyHolderMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditKeyHolderMutation, EditKeyHolderMutationVariables>(
        EditKeyHolderDocument,
        options
    );
}
export type EditKeyHolderMutationHookResult = ReturnType<typeof useEditKeyHolderMutation>;
export type EditKeyHolderMutationResult = Apollo.MutationResult<EditKeyHolderMutation>;
export type EditKeyHolderMutationOptions = Apollo.BaseMutationOptions<
    EditKeyHolderMutation,
    EditKeyHolderMutationVariables
>;
export const LocationDocument = gql`
    query Location($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            ...Location
        }
    }
    ${LocationFragmentDoc}
`;

/**
 * __useLocationQuery__
 *
 * To run a query within a React component, call `useLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationQuery({
 *   variables: {
 *      unitSlug: // value for 'unitSlug'
 *   },
 * });
 */
export function useLocationQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<LocationQuery, LocationQueryVariables> &
        ({ variables: LocationQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<LocationQuery, LocationQueryVariables>(LocationDocument, options);
}
export function useLocationLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationQuery, LocationQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<LocationQuery, LocationQueryVariables>(LocationDocument, options);
}
export function useLocationSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<LocationQuery, LocationQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<LocationQuery, LocationQueryVariables>(LocationDocument, options);
}
export type LocationQueryHookResult = ReturnType<typeof useLocationQuery>;
export type LocationLazyQueryHookResult = ReturnType<typeof useLocationLazyQuery>;
export type LocationSuspenseQueryHookResult = ReturnType<typeof useLocationSuspenseQuery>;
export type LocationQueryResult = Apollo.QueryResult<LocationQuery, LocationQueryVariables>;
export const EditLocationDocument = gql`
    mutation EditLocation($input: EditLocationAndDistanceInput!) {
        editLocationAndDistance(input: $input) {
            rentalUnit {
                ...Location
            }
        }
    }
    ${LocationFragmentDoc}
`;

/**
 * __useEditLocationMutation__
 *
 * To run a mutation, you first call `useEditLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editLocationMutation, { data, loading, error }] = useEditLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditLocationMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<EditLocationMutation, EditLocationMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditLocationMutation, EditLocationMutationVariables>(
        EditLocationDocument,
        options
    );
}
export type EditLocationMutationHookResult = ReturnType<typeof useEditLocationMutation>;
export type EditLocationMutationResult = Apollo.MutationResult<EditLocationMutation>;
export type EditLocationMutationOptions = Apollo.BaseMutationOptions<
    EditLocationMutation,
    EditLocationMutationVariables
>;
export const OwnerDocument = gql`
    query Owner($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            ...Owner
        }
    }
    ${OwnerFragmentDoc}
`;

/**
 * __useOwnerQuery__
 *
 * To run a query within a React component, call `useOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnerQuery({
 *   variables: {
 *      unitSlug: // value for 'unitSlug'
 *   },
 * });
 */
export function useOwnerQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<OwnerQuery, OwnerQueryVariables> &
        ({ variables: OwnerQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<OwnerQuery, OwnerQueryVariables>(OwnerDocument, options);
}
export function useOwnerLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OwnerQuery, OwnerQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<OwnerQuery, OwnerQueryVariables>(OwnerDocument, options);
}
export function useOwnerSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<OwnerQuery, OwnerQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<OwnerQuery, OwnerQueryVariables>(OwnerDocument, options);
}
export type OwnerQueryHookResult = ReturnType<typeof useOwnerQuery>;
export type OwnerLazyQueryHookResult = ReturnType<typeof useOwnerLazyQuery>;
export type OwnerSuspenseQueryHookResult = ReturnType<typeof useOwnerSuspenseQuery>;
export type OwnerQueryResult = Apollo.QueryResult<OwnerQuery, OwnerQueryVariables>;
export const EditOwnerDocument = gql`
    mutation EditOwner($input: EditOwnerInformationInput!) {
        editOwnerInformation(input: $input) {
            rentalUnit {
                ...Owner
            }
        }
    }
    ${OwnerFragmentDoc}
`;

/**
 * __useEditOwnerMutation__
 *
 * To run a mutation, you first call `useEditOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOwnerMutation, { data, loading, error }] = useEditOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditOwnerMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<EditOwnerMutation, EditOwnerMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditOwnerMutation, EditOwnerMutationVariables>(EditOwnerDocument, options);
}
export type EditOwnerMutationHookResult = ReturnType<typeof useEditOwnerMutation>;
export type EditOwnerMutationResult = Apollo.MutationResult<EditOwnerMutation>;
export type EditOwnerMutationOptions = Apollo.BaseMutationOptions<EditOwnerMutation, EditOwnerMutationVariables>;
export const PriceConfigDocument = gql`
    query PriceConfig($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            ...PriceConfig
        }
    }
    ${PriceConfigFragmentDoc}
`;

/**
 * __usePriceConfigQuery__
 *
 * To run a query within a React component, call `usePriceConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `usePriceConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePriceConfigQuery({
 *   variables: {
 *      unitSlug: // value for 'unitSlug'
 *   },
 * });
 */
export function usePriceConfigQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<PriceConfigQuery, PriceConfigQueryVariables> &
        ({ variables: PriceConfigQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PriceConfigQuery, PriceConfigQueryVariables>(PriceConfigDocument, options);
}
export function usePriceConfigLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PriceConfigQuery, PriceConfigQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<PriceConfigQuery, PriceConfigQueryVariables>(PriceConfigDocument, options);
}
export function usePriceConfigSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<PriceConfigQuery, PriceConfigQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<PriceConfigQuery, PriceConfigQueryVariables>(PriceConfigDocument, options);
}
export type PriceConfigQueryHookResult = ReturnType<typeof usePriceConfigQuery>;
export type PriceConfigLazyQueryHookResult = ReturnType<typeof usePriceConfigLazyQuery>;
export type PriceConfigSuspenseQueryHookResult = ReturnType<typeof usePriceConfigSuspenseQuery>;
export type PriceConfigQueryResult = Apollo.QueryResult<PriceConfigQuery, PriceConfigQueryVariables>;
export const EditDiscountDocument = gql`
    mutation EditDiscount($input: EditDiscountForUnitInput!) {
        editDiscounts(input: $input) {
            rentalUnit {
                ...PriceConfig
            }
        }
    }
    ${PriceConfigFragmentDoc}
`;

/**
 * __useEditDiscountMutation__
 *
 * To run a mutation, you first call `useEditDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDiscountMutation, { data, loading, error }] = useEditDiscountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditDiscountMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<EditDiscountMutation, EditDiscountMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditDiscountMutation, EditDiscountMutationVariables>(
        EditDiscountDocument,
        options
    );
}
export type EditDiscountMutationHookResult = ReturnType<typeof useEditDiscountMutation>;
export type EditDiscountMutationResult = Apollo.MutationResult<EditDiscountMutation>;
export type EditDiscountMutationOptions = Apollo.BaseMutationOptions<
    EditDiscountMutation,
    EditDiscountMutationVariables
>;
export const RentalUnitSurchargesDocument = gql`
    query RentalUnitSurcharges($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            id
            accommodation {
                rentalUnits {
                    ...RentalUnitInfo
                }
            }
            availableSurcharges {
                ...AvailableSurcharge
            }
            surcharges {
                ...RentalUnitSurcharge
            }
        }
    }
    ${RentalUnitInfoFragmentDoc}
    ${AvailableSurchargeFragmentDoc}
    ${RentalUnitSurchargeFragmentDoc}
`;

/**
 * __useRentalUnitSurchargesQuery__
 *
 * To run a query within a React component, call `useRentalUnitSurchargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRentalUnitSurchargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRentalUnitSurchargesQuery({
 *   variables: {
 *      unitSlug: // value for 'unitSlug'
 *   },
 * });
 */
export function useRentalUnitSurchargesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<RentalUnitSurchargesQuery, RentalUnitSurchargesQueryVariables> &
        ({ variables: RentalUnitSurchargesQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<RentalUnitSurchargesQuery, RentalUnitSurchargesQueryVariables>(
        RentalUnitSurchargesDocument,
        options
    );
}
export function useRentalUnitSurchargesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RentalUnitSurchargesQuery, RentalUnitSurchargesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<RentalUnitSurchargesQuery, RentalUnitSurchargesQueryVariables>(
        RentalUnitSurchargesDocument,
        options
    );
}
export function useRentalUnitSurchargesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        RentalUnitSurchargesQuery,
        RentalUnitSurchargesQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<RentalUnitSurchargesQuery, RentalUnitSurchargesQueryVariables>(
        RentalUnitSurchargesDocument,
        options
    );
}
export type RentalUnitSurchargesQueryHookResult = ReturnType<typeof useRentalUnitSurchargesQuery>;
export type RentalUnitSurchargesLazyQueryHookResult = ReturnType<typeof useRentalUnitSurchargesLazyQuery>;
export type RentalUnitSurchargesSuspenseQueryHookResult = ReturnType<typeof useRentalUnitSurchargesSuspenseQuery>;
export type RentalUnitSurchargesQueryResult = Apollo.QueryResult<
    RentalUnitSurchargesQuery,
    RentalUnitSurchargesQueryVariables
>;
export const CreateRentalUnitSurchargeDocument = gql`
    mutation CreateRentalUnitSurcharge($input: CreateRentalUnitSurchargeInput!) {
        createRentalUnitSurcharge(input: $input) {
            rentalUnitSurcharge {
                ...RentalUnitSurcharge
            }
        }
    }
    ${RentalUnitSurchargeFragmentDoc}
`;

/**
 * __useCreateRentalUnitSurchargeMutation__
 *
 * To run a mutation, you first call `useCreateRentalUnitSurchargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRentalUnitSurchargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRentalUnitSurchargeMutation, { data, loading, error }] = useCreateRentalUnitSurchargeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRentalUnitSurchargeMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateRentalUnitSurchargeMutation,
        CreateRentalUnitSurchargeMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<CreateRentalUnitSurchargeMutation, CreateRentalUnitSurchargeMutationVariables>(
        CreateRentalUnitSurchargeDocument,
        options
    );
}
export type CreateRentalUnitSurchargeMutationHookResult = ReturnType<typeof useCreateRentalUnitSurchargeMutation>;
export type CreateRentalUnitSurchargeMutationResult = Apollo.MutationResult<CreateRentalUnitSurchargeMutation>;
export type CreateRentalUnitSurchargeMutationOptions = Apollo.BaseMutationOptions<
    CreateRentalUnitSurchargeMutation,
    CreateRentalUnitSurchargeMutationVariables
>;
export const EditRentalUnitSurchargeDocument = gql`
    mutation EditRentalUnitSurcharge($input: EditRentalUnitSurchargeInput!) {
        editRentalUnitSurcharge(input: $input) {
            rentalUnitSurcharge {
                ...RentalUnitSurcharge
            }
        }
    }
    ${RentalUnitSurchargeFragmentDoc}
`;

/**
 * __useEditRentalUnitSurchargeMutation__
 *
 * To run a mutation, you first call `useEditRentalUnitSurchargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRentalUnitSurchargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRentalUnitSurchargeMutation, { data, loading, error }] = useEditRentalUnitSurchargeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditRentalUnitSurchargeMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        EditRentalUnitSurchargeMutation,
        EditRentalUnitSurchargeMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditRentalUnitSurchargeMutation, EditRentalUnitSurchargeMutationVariables>(
        EditRentalUnitSurchargeDocument,
        options
    );
}
export type EditRentalUnitSurchargeMutationHookResult = ReturnType<typeof useEditRentalUnitSurchargeMutation>;
export type EditRentalUnitSurchargeMutationResult = Apollo.MutationResult<EditRentalUnitSurchargeMutation>;
export type EditRentalUnitSurchargeMutationOptions = Apollo.BaseMutationOptions<
    EditRentalUnitSurchargeMutation,
    EditRentalUnitSurchargeMutationVariables
>;
export const DeleteRentalUnitSurchargeDocument = gql`
    mutation DeleteRentalUnitSurcharge($input: DeleteRentalUnitSurchargeInput!) {
        deleteRentalUnitSurcharge(input: $input) {
            id
        }
    }
`;

/**
 * __useDeleteRentalUnitSurchargeMutation__
 *
 * To run a mutation, you first call `useDeleteRentalUnitSurchargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRentalUnitSurchargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRentalUnitSurchargeMutation, { data, loading, error }] = useDeleteRentalUnitSurchargeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRentalUnitSurchargeMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteRentalUnitSurchargeMutation,
        DeleteRentalUnitSurchargeMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<DeleteRentalUnitSurchargeMutation, DeleteRentalUnitSurchargeMutationVariables>(
        DeleteRentalUnitSurchargeDocument,
        options
    );
}
export type DeleteRentalUnitSurchargeMutationHookResult = ReturnType<typeof useDeleteRentalUnitSurchargeMutation>;
export type DeleteRentalUnitSurchargeMutationResult = Apollo.MutationResult<DeleteRentalUnitSurchargeMutation>;
export type DeleteRentalUnitSurchargeMutationOptions = Apollo.BaseMutationOptions<
    DeleteRentalUnitSurchargeMutation,
    DeleteRentalUnitSurchargeMutationVariables
>;
export const UnitDescriptionDocument = gql`
    query UnitDescription($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            ...UnitDescription
        }
    }
    ${UnitDescriptionFragmentDoc}
`;

/**
 * __useUnitDescriptionQuery__
 *
 * To run a query within a React component, call `useUnitDescriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitDescriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitDescriptionQuery({
 *   variables: {
 *      unitSlug: // value for 'unitSlug'
 *   },
 * });
 */
export function useUnitDescriptionQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<UnitDescriptionQuery, UnitDescriptionQueryVariables> &
        ({ variables: UnitDescriptionQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<UnitDescriptionQuery, UnitDescriptionQueryVariables>(
        UnitDescriptionDocument,
        options
    );
}
export function useUnitDescriptionLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnitDescriptionQuery, UnitDescriptionQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<UnitDescriptionQuery, UnitDescriptionQueryVariables>(
        UnitDescriptionDocument,
        options
    );
}
export function useUnitDescriptionSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<UnitDescriptionQuery, UnitDescriptionQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<UnitDescriptionQuery, UnitDescriptionQueryVariables>(
        UnitDescriptionDocument,
        options
    );
}
export type UnitDescriptionQueryHookResult = ReturnType<typeof useUnitDescriptionQuery>;
export type UnitDescriptionLazyQueryHookResult = ReturnType<typeof useUnitDescriptionLazyQuery>;
export type UnitDescriptionSuspenseQueryHookResult = ReturnType<typeof useUnitDescriptionSuspenseQuery>;
export type UnitDescriptionQueryResult = Apollo.QueryResult<UnitDescriptionQuery, UnitDescriptionQueryVariables>;
export const EditRentalUnitDescriptionsDocument = gql`
    mutation EditRentalUnitDescriptions($input: EditRentalUnitDescriptionsInput!) {
        editRentalUnitDescriptions(input: $input) {
            rentalUnit {
                ...UnitDescription
            }
        }
    }
    ${UnitDescriptionFragmentDoc}
`;

/**
 * __useEditRentalUnitDescriptionsMutation__
 *
 * To run a mutation, you first call `useEditRentalUnitDescriptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRentalUnitDescriptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRentalUnitDescriptionsMutation, { data, loading, error }] = useEditRentalUnitDescriptionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditRentalUnitDescriptionsMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        EditRentalUnitDescriptionsMutation,
        EditRentalUnitDescriptionsMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<
        EditRentalUnitDescriptionsMutation,
        EditRentalUnitDescriptionsMutationVariables
    >(EditRentalUnitDescriptionsDocument, options);
}
export type EditRentalUnitDescriptionsMutationHookResult = ReturnType<typeof useEditRentalUnitDescriptionsMutation>;
export type EditRentalUnitDescriptionsMutationResult = Apollo.MutationResult<EditRentalUnitDescriptionsMutation>;
export type EditRentalUnitDescriptionsMutationOptions = Apollo.BaseMutationOptions<
    EditRentalUnitDescriptionsMutation,
    EditRentalUnitDescriptionsMutationVariables
>;
export const RentalUnitInfoDocument = gql`
    query RentalUnitInfo($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            ...RentalUnitDetails
        }
    }
    ${RentalUnitDetailsFragmentDoc}
`;

/**
 * __useRentalUnitInfoQuery__
 *
 * To run a query within a React component, call `useRentalUnitInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useRentalUnitInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRentalUnitInfoQuery({
 *   variables: {
 *      unitSlug: // value for 'unitSlug'
 *   },
 * });
 */
export function useRentalUnitInfoQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<RentalUnitInfoQuery, RentalUnitInfoQueryVariables> &
        ({ variables: RentalUnitInfoQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<RentalUnitInfoQuery, RentalUnitInfoQueryVariables>(
        RentalUnitInfoDocument,
        options
    );
}
export function useRentalUnitInfoLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RentalUnitInfoQuery, RentalUnitInfoQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<RentalUnitInfoQuery, RentalUnitInfoQueryVariables>(
        RentalUnitInfoDocument,
        options
    );
}
export function useRentalUnitInfoSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<RentalUnitInfoQuery, RentalUnitInfoQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<RentalUnitInfoQuery, RentalUnitInfoQueryVariables>(
        RentalUnitInfoDocument,
        options
    );
}
export type RentalUnitInfoQueryHookResult = ReturnType<typeof useRentalUnitInfoQuery>;
export type RentalUnitInfoLazyQueryHookResult = ReturnType<typeof useRentalUnitInfoLazyQuery>;
export type RentalUnitInfoSuspenseQueryHookResult = ReturnType<typeof useRentalUnitInfoSuspenseQuery>;
export type RentalUnitInfoQueryResult = Apollo.QueryResult<RentalUnitInfoQuery, RentalUnitInfoQueryVariables>;
export const EditRentalUnitInfoDocument = gql`
    mutation EditRentalUnitInfo($input: EditRentalUnitInfoInput!) {
        editRentalUnitInfo(input: $input) {
            rentalUnit {
                ...RentalUnitDetails
            }
        }
    }
    ${RentalUnitDetailsFragmentDoc}
`;

/**
 * __useEditRentalUnitInfoMutation__
 *
 * To run a mutation, you first call `useEditRentalUnitInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRentalUnitInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRentalUnitInfoMutation, { data, loading, error }] = useEditRentalUnitInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditRentalUnitInfoMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<EditRentalUnitInfoMutation, EditRentalUnitInfoMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditRentalUnitInfoMutation, EditRentalUnitInfoMutationVariables>(
        EditRentalUnitInfoDocument,
        options
    );
}
export type EditRentalUnitInfoMutationHookResult = ReturnType<typeof useEditRentalUnitInfoMutation>;
export type EditRentalUnitInfoMutationResult = Apollo.MutationResult<EditRentalUnitInfoMutation>;
export type EditRentalUnitInfoMutationOptions = Apollo.BaseMutationOptions<
    EditRentalUnitInfoMutation,
    EditRentalUnitInfoMutationVariables
>;
export const UnitImagesDocument = gql`
    query UnitImages($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            ...UnitImages
        }
    }
    ${UnitImagesFragmentDoc}
`;

/**
 * __useUnitImagesQuery__
 *
 * To run a query within a React component, call `useUnitImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitImagesQuery({
 *   variables: {
 *      unitSlug: // value for 'unitSlug'
 *   },
 * });
 */
export function useUnitImagesQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<UnitImagesQuery, UnitImagesQueryVariables> &
        ({ variables: UnitImagesQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<UnitImagesQuery, UnitImagesQueryVariables>(UnitImagesDocument, options);
}
export function useUnitImagesLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnitImagesQuery, UnitImagesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<UnitImagesQuery, UnitImagesQueryVariables>(UnitImagesDocument, options);
}
export function useUnitImagesSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<UnitImagesQuery, UnitImagesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<UnitImagesQuery, UnitImagesQueryVariables>(UnitImagesDocument, options);
}
export type UnitImagesQueryHookResult = ReturnType<typeof useUnitImagesQuery>;
export type UnitImagesLazyQueryHookResult = ReturnType<typeof useUnitImagesLazyQuery>;
export type UnitImagesSuspenseQueryHookResult = ReturnType<typeof useUnitImagesSuspenseQuery>;
export type UnitImagesQueryResult = Apollo.QueryResult<UnitImagesQuery, UnitImagesQueryVariables>;
export const EditRentalUnitImagesDocument = gql`
    mutation EditRentalUnitImages($input: EditRentalUnitImagesInput!) {
        editRentalUnitImages(input: $input) {
            rentalUnit {
                ...UnitImages
            }
        }
    }
    ${UnitImagesFragmentDoc}
`;

/**
 * __useEditRentalUnitImagesMutation__
 *
 * To run a mutation, you first call `useEditRentalUnitImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRentalUnitImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRentalUnitImagesMutation, { data, loading, error }] = useEditRentalUnitImagesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditRentalUnitImagesMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        EditRentalUnitImagesMutation,
        EditRentalUnitImagesMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<EditRentalUnitImagesMutation, EditRentalUnitImagesMutationVariables>(
        EditRentalUnitImagesDocument,
        options
    );
}
export type EditRentalUnitImagesMutationHookResult = ReturnType<typeof useEditRentalUnitImagesMutation>;
export type EditRentalUnitImagesMutationResult = Apollo.MutationResult<EditRentalUnitImagesMutation>;
export type EditRentalUnitImagesMutationOptions = Apollo.BaseMutationOptions<
    EditRentalUnitImagesMutation,
    EditRentalUnitImagesMutationVariables
>;
export const PricesWizardDocument = gql`
    query PricesWizard($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            ...PricesWizardRentalUnit
        }
    }
    ${PricesWizardRentalUnitFragmentDoc}
`;

/**
 * __usePricesWizardQuery__
 *
 * To run a query within a React component, call `usePricesWizardQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricesWizardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricesWizardQuery({
 *   variables: {
 *      unitSlug: // value for 'unitSlug'
 *   },
 * });
 */
export function usePricesWizardQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<PricesWizardQuery, PricesWizardQueryVariables> &
        ({ variables: PricesWizardQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<PricesWizardQuery, PricesWizardQueryVariables>(PricesWizardDocument, options);
}
export function usePricesWizardLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PricesWizardQuery, PricesWizardQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<PricesWizardQuery, PricesWizardQueryVariables>(PricesWizardDocument, options);
}
export function usePricesWizardSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<PricesWizardQuery, PricesWizardQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<PricesWizardQuery, PricesWizardQueryVariables>(
        PricesWizardDocument,
        options
    );
}
export type PricesWizardQueryHookResult = ReturnType<typeof usePricesWizardQuery>;
export type PricesWizardLazyQueryHookResult = ReturnType<typeof usePricesWizardLazyQuery>;
export type PricesWizardSuspenseQueryHookResult = ReturnType<typeof usePricesWizardSuspenseQuery>;
export type PricesWizardQueryResult = Apollo.QueryResult<PricesWizardQuery, PricesWizardQueryVariables>;
export const BulkUpsertDatePricingDocument = gql`
    mutation BulkUpsertDatePricing($input: BulkUpsertDatePricingInput!) {
        bulkUpsertDatePricing(input: $input) {
            rentalUnit {
                ...PricesWizardRentalUnit
            }
            datePricings {
                ...PriceColumnPricing
            }
            allotments {
                ...PriceColumnAllotment
            }
        }
    }
    ${PricesWizardRentalUnitFragmentDoc}
    ${PriceColumnPricingFragmentDoc}
    ${PriceColumnAllotmentFragmentDoc}
`;

/**
 * __useBulkUpsertDatePricingMutation__
 *
 * To run a mutation, you first call `useBulkUpsertDatePricingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpsertDatePricingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpsertDatePricingMutation, { data, loading, error }] = useBulkUpsertDatePricingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpsertDatePricingMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        BulkUpsertDatePricingMutation,
        BulkUpsertDatePricingMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<BulkUpsertDatePricingMutation, BulkUpsertDatePricingMutationVariables>(
        BulkUpsertDatePricingDocument,
        options
    );
}
export type BulkUpsertDatePricingMutationHookResult = ReturnType<typeof useBulkUpsertDatePricingMutation>;
export type BulkUpsertDatePricingMutationResult = Apollo.MutationResult<BulkUpsertDatePricingMutation>;
export type BulkUpsertDatePricingMutationOptions = Apollo.BaseMutationOptions<
    BulkUpsertDatePricingMutation,
    BulkUpsertDatePricingMutationVariables
>;
export const AllVisitorRegistrationsDocument = gql`
    query AllVisitorRegistrations($first: Int, $last: Int, $startDate: Date, $endDate: Date, $partnerId: ID!) {
        partner(id: $partnerId) {
            id
            allVisitorRegistrations(first: $first, last: $last, startDate: $startDate, endDate: $endDate) {
                pageInfo {
                    startCursor
                    endCursor
                    hasNextPage
                    hasPreviousPage
                }
                exportUrl
                totalCount
                edges {
                    node {
                        rentalUnit {
                            ...UnitDetails
                        }
                        arrivalDate
                        departureDate
                        firstName
                        lastName
                        city
                        id
                        __typename
                        identificationType
                    }
                }
            }
        }
    }
    ${UnitDetailsFragmentDoc}
`;

/**
 * __useAllVisitorRegistrationsQuery__
 *
 * To run a query within a React component, call `useAllVisitorRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllVisitorRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllVisitorRegistrationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useAllVisitorRegistrationsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        AllVisitorRegistrationsQuery,
        AllVisitorRegistrationsQueryVariables
    > &
        ({ variables: AllVisitorRegistrationsQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<AllVisitorRegistrationsQuery, AllVisitorRegistrationsQueryVariables>(
        AllVisitorRegistrationsDocument,
        options
    );
}
export function useAllVisitorRegistrationsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        AllVisitorRegistrationsQuery,
        AllVisitorRegistrationsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<AllVisitorRegistrationsQuery, AllVisitorRegistrationsQueryVariables>(
        AllVisitorRegistrationsDocument,
        options
    );
}
export function useAllVisitorRegistrationsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        AllVisitorRegistrationsQuery,
        AllVisitorRegistrationsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<AllVisitorRegistrationsQuery, AllVisitorRegistrationsQueryVariables>(
        AllVisitorRegistrationsDocument,
        options
    );
}
export type AllVisitorRegistrationsQueryHookResult = ReturnType<typeof useAllVisitorRegistrationsQuery>;
export type AllVisitorRegistrationsLazyQueryHookResult = ReturnType<typeof useAllVisitorRegistrationsLazyQuery>;
export type AllVisitorRegistrationsSuspenseQueryHookResult = ReturnType<typeof useAllVisitorRegistrationsSuspenseQuery>;
export type AllVisitorRegistrationsQueryResult = Apollo.QueryResult<
    AllVisitorRegistrationsQuery,
    AllVisitorRegistrationsQueryVariables
>;
export const DeleteVisitorRegistrationDocument = gql`
    mutation DeleteVisitorRegistration($id: ID!) {
        deleteVisitorRegistration(input: { visitorRegistrationId: $id }) {
            id
        }
    }
`;

/**
 * __useDeleteVisitorRegistrationMutation__
 *
 * To run a mutation, you first call `useDeleteVisitorRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVisitorRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVisitorRegistrationMutation, { data, loading, error }] = useDeleteVisitorRegistrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVisitorRegistrationMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        DeleteVisitorRegistrationMutation,
        DeleteVisitorRegistrationMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<DeleteVisitorRegistrationMutation, DeleteVisitorRegistrationMutationVariables>(
        DeleteVisitorRegistrationDocument,
        options
    );
}
export type DeleteVisitorRegistrationMutationHookResult = ReturnType<typeof useDeleteVisitorRegistrationMutation>;
export type DeleteVisitorRegistrationMutationResult = Apollo.MutationResult<DeleteVisitorRegistrationMutation>;
export type DeleteVisitorRegistrationMutationOptions = Apollo.BaseMutationOptions<
    DeleteVisitorRegistrationMutation,
    DeleteVisitorRegistrationMutationVariables
>;
export const CreateVisitorRegistrationDocument = gql`
    mutation createVisitorRegistration(
        $firstName: String!
        $lastName: String!
        $city: String!
        $countryCode: String!
        $totalPersons: Int!
        $identificationType: IdentificationTypeEnum!
        $arrivalDate: Date!
        $departureDate: Date!
        $rentalUnitId: ID!
        $bookingId: ID
        $lockoutId: ID
    ) {
        createVisitorRegistration(
            input: {
                firstName: $firstName
                lastName: $lastName
                city: $city
                countryCode: $countryCode
                totalPersons: $totalPersons
                identificationType: $identificationType
                arrivalDate: $arrivalDate
                departureDate: $departureDate
                rentalUnitId: $rentalUnitId
                bookingId: $bookingId
                lockoutId: $lockoutId
            }
        ) {
            visitorRegistration {
                id
                __typename
            }
        }
    }
`;

/**
 * __useCreateVisitorRegistrationMutation__
 *
 * To run a mutation, you first call `useCreateVisitorRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVisitorRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVisitorRegistrationMutation, { data, loading, error }] = useCreateVisitorRegistrationMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      city: // value for 'city'
 *      countryCode: // value for 'countryCode'
 *      totalPersons: // value for 'totalPersons'
 *      identificationType: // value for 'identificationType'
 *      arrivalDate: // value for 'arrivalDate'
 *      departureDate: // value for 'departureDate'
 *      rentalUnitId: // value for 'rentalUnitId'
 *      bookingId: // value for 'bookingId'
 *      lockoutId: // value for 'lockoutId'
 *   },
 * });
 */
export function useCreateVisitorRegistrationMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<
        CreateVisitorRegistrationMutation,
        CreateVisitorRegistrationMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<CreateVisitorRegistrationMutation, CreateVisitorRegistrationMutationVariables>(
        CreateVisitorRegistrationDocument,
        options
    );
}
export type CreateVisitorRegistrationMutationHookResult = ReturnType<typeof useCreateVisitorRegistrationMutation>;
export type CreateVisitorRegistrationMutationResult = Apollo.MutationResult<CreateVisitorRegistrationMutation>;
export type CreateVisitorRegistrationMutationOptions = Apollo.BaseMutationOptions<
    CreateVisitorRegistrationMutation,
    CreateVisitorRegistrationMutationVariables
>;
export const MissingVisitorRegistrationsDocument = gql`
    query MissingVisitorRegistrations($partnerId: ID!) {
        partner(id: $partnerId) {
            id
            missingVisitorRegistrations {
                ... on Booking {
                    id
                    rentalUnit {
                        ...UnitDetails
                    }
                    arrivalDate
                    departureDate
                    duration
                    amountAdults
                    status
                    amountBabies
                    amountChildren
                    amountPets
                    amountYouths
                    customer {
                        firstName
                        lastName
                        address {
                            city
                            countryCode
                        }
                    }
                }
                ... on AllotmentLockout {
                    id
                    rentalUnit {
                        ...UnitDetails
                    }
                    startDate
                    endDate
                }
            }
        }
    }
    ${UnitDetailsFragmentDoc}
`;

/**
 * __useMissingVisitorRegistrationsQuery__
 *
 * To run a query within a React component, call `useMissingVisitorRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMissingVisitorRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMissingVisitorRegistrationsQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useMissingVisitorRegistrationsQuery(
    baseOptions: ApolloReactHooks.QueryHookOptions<
        MissingVisitorRegistrationsQuery,
        MissingVisitorRegistrationsQueryVariables
    > &
        ({ variables: MissingVisitorRegistrationsQueryVariables; skip?: boolean } | { skip: boolean })
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery<MissingVisitorRegistrationsQuery, MissingVisitorRegistrationsQueryVariables>(
        MissingVisitorRegistrationsDocument,
        options
    );
}
export function useMissingVisitorRegistrationsLazyQuery(
    baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
        MissingVisitorRegistrationsQuery,
        MissingVisitorRegistrationsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery<MissingVisitorRegistrationsQuery, MissingVisitorRegistrationsQueryVariables>(
        MissingVisitorRegistrationsDocument,
        options
    );
}
export function useMissingVisitorRegistrationsSuspenseQuery(
    baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
        MissingVisitorRegistrationsQuery,
        MissingVisitorRegistrationsQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery<
        MissingVisitorRegistrationsQuery,
        MissingVisitorRegistrationsQueryVariables
    >(MissingVisitorRegistrationsDocument, options);
}
export type MissingVisitorRegistrationsQueryHookResult = ReturnType<typeof useMissingVisitorRegistrationsQuery>;
export type MissingVisitorRegistrationsLazyQueryHookResult = ReturnType<typeof useMissingVisitorRegistrationsLazyQuery>;
export type MissingVisitorRegistrationsSuspenseQueryHookResult = ReturnType<
    typeof useMissingVisitorRegistrationsSuspenseQuery
>;
export type MissingVisitorRegistrationsQueryResult = Apollo.QueryResult<
    MissingVisitorRegistrationsQuery,
    MissingVisitorRegistrationsQueryVariables
>;
export const CreateUploadDocument = gql`
    mutation createUpload {
        createImageUpload {
            putUrl
            uploadId
        }
    }
`;

/**
 * __useCreateUploadMutation__
 *
 * To run a mutation, you first call `useCreateUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUploadMutation, { data, loading, error }] = useCreateUploadMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateUploadMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUploadMutation, CreateUploadMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<CreateUploadMutation, CreateUploadMutationVariables>(
        CreateUploadDocument,
        options
    );
}
export type CreateUploadMutationHookResult = ReturnType<typeof useCreateUploadMutation>;
export type CreateUploadMutationResult = Apollo.MutationResult<CreateUploadMutation>;
export type CreateUploadMutationOptions = Apollo.BaseMutationOptions<
    CreateUploadMutation,
    CreateUploadMutationVariables
>;
