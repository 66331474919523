import { FlexProps } from '@rebass/grid';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import Overtitle from '@oberoninternal/travelbase-ds/components/primitive/Overtitle';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import DetailsContainer from './DetailsContainer';
import { CompanyPickerEntryFragment, ActivityPickerEntryFragment } from '../../generated/graphql';
import { FormattedMessage } from 'react-intl';

interface Props extends FlexProps {
    entity: CompanyPickerEntryFragment | ActivityPickerEntryFragment;
    first?: boolean;
}

const ActivityDetails: FC<React.PropsWithChildren<Props>> = ({ entity, first, ...props }) => {
    const thumbnailUrl = entity.listImage?.url;
    return (
        <DetailsContainer thumbnailUrl={thumbnailUrl} {...props}>
            {first && entity.__typename === 'Activity' && <StyledOvertitle>{entity.company.name}</StyledOvertitle>}
            <StyledLabel as="p" variant="small">
                {entity.name}
            </StyledLabel>
            <SubText as="p" variant="small">
                {entity.__typename === 'Activity' ? (
                    <FormattedMessage defaultMessage="Activiteit" />
                ) : (
                    <FormattedMessage defaultMessage="Onderneming" />
                )}
            </SubText>
        </DetailsContainer>
    );
};

export default ActivityDetails;

const StyledOvertitle = styled(Overtitle)`
    color: ${({ theme }) => theme.colors.neutral[50]};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const StyledLabel = styled(Label)`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
const SubText = styled(Label)`
    color: ${({ theme }) => theme.colors.neutral[50]};
`;
