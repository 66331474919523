import {
    RateFragment,
    UpsertActivityRateGroupInput,
    UpsertCompanyRateGroupInput,
    ActivityRateGroupFragment,
    CompanyRateGroupFragment,
} from '../generated/graphql';

type Values = Omit<UpsertActivityRateGroupInput, 'activityId'> | Omit<UpsertCompanyRateGroupInput, 'companyId'>;

type RateGroupType<T> = T extends 'ActivityRateGroup' ? ActivityRateGroupFragment : CompanyRateGroupFragment;

const getOptimisticUIRateGroup = <T extends 'ActivityRateGroup' | 'CompanyRateGroup'>(
    values: Values,
    type: T
): RateGroupType<T> => {
    const { rates, rateGroupId, ...rest } = values;
    const newRates = values.rates.map((rate, i): RateFragment => {
        const nl = rate.translations[0].name;
        const de = rate.translations[1].name;
        const en = rate.translations[2].name;
        return {
            __typename: 'Rate',
            name: nl,
            deName: de,
            enName: en,
            id: String(i),
            position: rate.position,
            price: rate.price,
        };
    });

    return {
        __typename: type,
        id: rateGroupId ?? 'placeholder',
        rates: newRates,
        ...rest,
    } as unknown as RateGroupType<T>;
};

export default getOptimisticUIRateGroup;
