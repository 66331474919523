import React, { FC } from 'react';
import { Flex, Box } from '@rebass/grid';
import Logo from '@oberoninternal/travelbase-ds/components/figure/LogoText';
import styled from 'styled-components/macro';

const LoginContainer: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
    <Flex width={1} height="100vh">
        <Wrapper>
            <Box my={4}>
                <Logo width={250} />
            </Box>
            {children}
        </Wrapper>
    </Flex>
);

export default LoginContainer;

const Wrapper = styled(Box).attrs(() => ({ m: 'auto', width: [7 / 8, null, '48rem'] }))`
    > * + * {
        margin-top: ${({ theme }) => theme.spacing['40_Standard']};
    }
`;
