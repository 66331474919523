import gql from 'graphql-tag';
import flatten from 'lodash/flatten';
import isEqual from 'lodash/isEqual';
import React, { FC, memo, useMemo } from 'react';
import { ListOnItemsRenderedProps } from 'react-window';
import euroFormat from '../../constants/euroFormat';
import numberFormat from '../../constants/numberFormat';
import {
    DatePricingModifierTypeEnum,
    DatePricingModifierValueTypeEnum,
    PeriodRulesFragment,
    PricesQueryVariables,
    PricesRowVisibilityFragment,
} from '../../generated/graphql';
import PeriodRule from '@oberoninternal/travelbase-ds/components/calendar/PeriodRule';
import { CELL_HEIGHT } from './Cell';
import { PRICE_COLUMN_HEADER_HEIGHT, PRICE_COLUMN_ROW_COUNT } from '../../constants/priceRows';
import { getPosition } from '../../utils/getPosition';
import styled from 'styled-components/macro';
import { useSidebar } from '../../context/sidebar';
import propsAreEqual from '@oberoninternal/travelbase-ds/utils/propsAreEqual';
import { FormattedMessage } from 'react-intl';

export const periodRulesFragment = gql`
    fragment PeriodRules on DatePricingModifier {
        id
        startDate
        endDate
        minDuration
        maxDuration
        type
        value
        valueType
    }
`;
export type PeriodRuleGroups = Map<string, PeriodRulesFragment[]>;

export const PERIOD_RULE_HEIGHT = 32;
export const PERIOD_RULE_GAP = 8;

interface Props {
    ruleGroups: PeriodRuleGroups;
    itemsRendered: ListOnItemsRenderedProps;
    pricesVars?: PricesQueryVariables;
    rowVisibility: PricesRowVisibilityFragment | null;
    columnWidth: number;
}

const PeriodRules: FC<React.PropsWithChildren<Props>> = memo(
    ({ ruleGroups, itemsRendered, pricesVars, rowVisibility, columnWidth }) => {
        const [state, dispatch] = useSidebar();
        const { open, data } = state;

        const rowCount = useMemo(
            () => (rowVisibility ? Object.values(rowVisibility).filter(show => show).length : PRICE_COLUMN_ROW_COUNT),
            [rowVisibility]
        );
        const PERIOD_RULES_START = PRICE_COLUMN_HEADER_HEIGHT + PERIOD_RULE_GAP * 8 + CELL_HEIGHT * rowCount;

        return (
            <>
                {[...ruleGroups.values()].map((rules, verticalIndex) =>
                    rules.map(rule => {
                        const { startIndex, endIndex } = getPosition(rule, itemsRendered);
                        return (
                            <StyledPeriodRule
                                key={rule.id}
                                onClick={() => {
                                    dispatch({
                                        type: 'show',
                                        data: {
                                            type: 'PERIOD_RULE',
                                            pricesVars,
                                            periodRule: rule,
                                        },
                                    });
                                }}
                                endIndex={endIndex}
                                startIndex={startIndex}
                                periodRulesStart={PERIOD_RULES_START}
                                verticalIndex={verticalIndex}
                                active={open && data?.type === 'PERIOD_RULE' && data.periodRule?.id === rule.id}
                                columnWidth={columnWidth}
                            >
                                {rule.valueType === DatePricingModifierValueTypeEnum.Amount
                                    ? euroFormat(rule.value)
                                    : numberFormat(rule.value)}
                                {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                                {rule.valueType === DatePricingModifierValueTypeEnum.Percentage && '%'}{' '}
                                {rule.type === DatePricingModifierTypeEnum.Addition ? (
                                    <FormattedMessage defaultMessage="toeslag" />
                                ) : (
                                    <FormattedMessage defaultMessage="korting" />
                                )}
                            </StyledPeriodRule>
                        );
                    })
                )}
            </>
        );
    },
    propsAreEqual<Props>((prev, next) => {
        const prevPositions = flatten([...prev.ruleGroups.values()]).map(rule => getPosition(rule, prev.itemsRendered));
        const nextPositions = flatten([...next.ruleGroups.values()]).map(rule => getPosition(rule, prev.itemsRendered));
        return isEqual(prevPositions, nextPositions);
    }, 'deep')
);

export default PeriodRules;
interface StyledProps {
    periodRulesStart: number;
    verticalIndex: number;
    startIndex: number;
    endIndex: number;
    columnWidth: number;
}

const StyledPeriodRule = styled(PeriodRule)<StyledProps>`
    position: absolute;
    top: ${({ periodRulesStart, verticalIndex }) =>
        periodRulesStart + verticalIndex * (PERIOD_RULE_HEIGHT + PERIOD_RULE_GAP)}px;
    left: ${({ startIndex, columnWidth }) => 3 + startIndex * columnWidth}px;
    width: ${({ endIndex, startIndex, columnWidth }) => (endIndex - startIndex) * columnWidth - 7}px;

    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        margin-top: 3rem;
        top: ${({ periodRulesStart, verticalIndex }) =>
            periodRulesStart + verticalIndex * (PERIOD_RULE_HEIGHT + PERIOD_RULE_HEIGHT + PERIOD_RULE_GAP)}px;
    }
`;
