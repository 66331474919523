import React, { FormEvent, KeyboardEvent, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import { numberFormatFixedTwo } from '../../constants/numberFormat';
import BaseCell, { CellProps } from '../molecules/Cell';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { ErrorMessageContainer } from '@oberoninternal/travelbase-ds/components/form/ErrorMessage';
import TextInput, { Input } from '@oberoninternal/travelbase-ds/components/form/TextInput';

interface Props<T> extends CellProps {
    onChange: (value: T) => void;
    value: T;
    isCurrency?: boolean;
    isNullable?: boolean;
    disabled?: boolean;
    id?: string;
    onClick?: (e: SyntheticEvent<HTMLDivElement>) => void;
    selected?: { width?: number; newValue: number | '' | boolean | null } | undefined;
}

function TextInputCell<T extends number | null>({
    value: initial,
    onChange,
    selected,
    isCurrency,
    isNullable,
    onClick,
    disabled = false,
    ...props
}: Props<T>) {
    const initialValue = initial === null ? null : isCurrency ? initial.toFixed(2) : initial.toString();
    const [value, setValue] = useState(initialValue);
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const validator = useMemo(() => {
        let schema: Yup.NumberSchema<null | number> = Yup.number().min(0);

        if (!isNullable) {
            schema = schema.required();
        } else {
            schema = schema.nullable().min(0.01);
        }

        if (!isCurrency) {
            schema = schema.integer();
        }

        return schema;
    }, [isNullable, isCurrency]);

    const isValid = validator.isValidSync(value);

    const onSave = (e: FormEvent) => {
        e.preventDefault();

        if (isValid && value !== initialValue) {
            onChange((value === null ? null : parseFloat(value)) as T);
        }
    };

    return (
        <Cell
            onClick={e => {
                if (e.currentTarget.id !== 'text-input' && onClick) {
                    onClick(e);
                }
            }}
            selectedWidth={selected?.width}
            disabled={disabled}
            {...props}
        >
            {(selected && typeof selected.newValue !== 'boolean' && (
                <>
                    <NewPrice>
                        {isCurrency
                            ? selected.newValue === null
                                ? // eslint-disable-next-line formatjs/no-literal-string-in-jsx
                                  '--,--'
                                : numberFormatFixedTwo(parseFloat(selected.newValue.toString()))
                            : selected.newValue}
                    </NewPrice>
                    {initialValue !== null && (
                        <OldPrice>
                            {isCurrency ? numberFormatFixedTwo(parseFloat(initialValue)) : initialValue}
                        </OldPrice>
                    )}
                </>
            )) ?? (
                <form
                    style={{ zIndex: 1 }}
                    onSubmit={e => {
                        e.preventDefault();
                        if (ref.current) {
                            ref.current.blur();
                        }
                    }}
                >
                    <TextInput
                        id="text-input"
                        type="number"
                        step={isCurrency ? 0.01 : 1}
                        disabled={disabled}
                        value={value ?? ''}
                        // eslint-disable-next-line formatjs/no-literal-string-in-jsx
                        placeholder={value === null ? '--,--' : undefined}
                        onClick={e => e.stopPropagation()}
                        onChange={e => setValue(e.target.value === '' ? null : e.target.value)}
                        onBlur={onSave}
                        ref={ref}
                        onFocus={e => e.target.select()}
                        error={!isValid ? 'ERR!' : undefined}
                        onKeyDown={(e: KeyboardEvent<HTMLInputElement> & { target: HTMLInputElement }) => {
                            if (e.key === 'Escape') {
                                setValue(initialValue);

                                // need to wait for react to update the value.
                                setTimeout(() => ref.current?.blur());
                            }
                        }}
                    />
                </form>
            )}
        </Cell>
    );
}

const OldPrice = styled(Body)`
    text-decoration: line-through;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.neutral[50]};
    font-weight: 500;
`;

const NewPrice = styled(Body)`
    font-weight: 500;
    color: ${({ theme }) => theme.colors.primary[40]};
`;

const Cell = styled(BaseCell)<{ disabled: boolean; selectedWidth?: number }>`
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'unset')};
    flex-direction: column;

    ${ErrorMessageContainer} {
        display: none;
    }

    ${Input} {
        flex: 1;
        text-align: center;
        padding: 0.7rem 1.6rem;
        font-weight: 500;
        -moz-appearance: textfield;
        cursor: pointer;
        --border-color: transparent;
        background: transparent;
        pointer-events: ${({ disabled }) => (disabled ? 'none' : 'unset')};

        :hover:not(:focus) {
            --border-color: ${({ theme }) => theme.colors.neutral[20]};
        }
        :focus {
            --border-color: ${({ theme }) => theme.colors.primary[40]};
        }
    }

    ${Input}::-webkit-inner-spin-button, ${Input}::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

export default TextInputCell;
