import React, { FC } from 'react';
import gql from 'graphql-tag';
import * as Yup from 'yup';
import { ActivityParams } from '../../../../entities/ActivityParams';
import FormScreen from '../../../organisms/FormScreen';
import FieldHeading from '../../../molecules/FieldHeading';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import Loading from '../../../organisms/Loading';
import TranslationInput from '../../../molecules/TranslationInput';
import { useActivityDescriptionQuery, useEditActivityDescriptionsMutation } from '../../../../generated/graphql';
import { NotFoundError } from '../../../../entities/NotFoundError';
import omitEqualValues from '../../../../utils/omitEqualValues';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

export const activityDescriptionFragment = gql`
    fragment ActivityDescription on Activity {
        id
        descriptionNL: description(locale: "nl")
        descriptionDE: description(locale: "de")
        descriptionEN: description(locale: "en")
        descriptionPending
        descriptionParticularsNL: descriptionParticulars(locale: "nl")
        descriptionParticularsDE: descriptionParticulars(locale: "de")
        descriptionParticularsEN: descriptionParticulars(locale: "en")
        descriptionParticularsPending
    }
`;

export const query = gql`
    query ActivityDescription($activitySlug: String!) {
        activity(slug: $activitySlug) {
            ...ActivityDescription
        }
    }
`;

export const editActivityDescriptionsMutation = gql`
    mutation EditActivityDescriptions($input: EditActivityDescriptionInput!) {
        editActivityDescription(input: $input) {
            activity {
                ...ActivityDescription
            }
        }
    }
`;

const activityDescriptionValidation = (intl: IntlShape) =>
    Yup.object().shape({
        description: Yup.string().required(intl.formatMessage({ defaultMessage: 'Dit veld mag niet leeg zijn' })),
    });

const ActivityDescription: FC<React.PropsWithChildren<ActivityParams>> = ({ activitySlug }) => {
    const intl = useIntl();
    const { formatMessage } = intl;
    const slug = activitySlug ?? '';
    const { loading, data } = useActivityDescriptionQuery({ variables: { activitySlug: slug } });
    const [mutate] = useEditActivityDescriptionsMutation();

    if (loading) {
        return <Loading />;
    }

    if (!data || !data.activity) {
        throw new NotFoundError();
    }

    const {
        activity: {
            id,
            descriptionDE,
            descriptionEN,
            descriptionNL,
            descriptionPending,
            descriptionParticularsDE,
            descriptionParticularsEN,
            descriptionParticularsNL,
            descriptionParticularsPending,
        },
    } = data;

    const initialValues = {
        description: descriptionPending ?? descriptionNL ?? '',
        descriptionParticulars: descriptionParticularsPending ?? descriptionParticularsNL ?? '',
    };

    return (
        <FormScreen
            validationSchema={activityDescriptionValidation(intl)}
            initialValues={initialValues}
            handleSubmit={async values => {
                await mutate({
                    variables: {
                        input: {
                            activityId: id,
                            ...omitEqualValues(values, initialValues),
                        },
                    },
                });
            }}
        >
            {() => (
                <>
                    <Pagehead title={formatMessage({ defaultMessage: 'Beschrijving' })}>
                        <FormattedMessage defaultMessage="Hier beheer je de verschillende beschrijvingen van de activiteit." />
                    </Pagehead>

                    <FieldHeading title={formatMessage({ defaultMessage: 'Algemeen' })}>
                        <FormattedMessage defaultMessage=" Dit is de algemene beschrijving van de activiteit." />
                    </FieldHeading>
                    <TranslationInput
                        placeholder={formatMessage({ defaultMessage: 'Omschrijving' })}
                        name="description"
                        label={formatMessage({ defaultMessage: 'Tekst invoeren of aanpassen' })}
                        hint={formatMessage({
                            defaultMessage:
                                'Plaats hier de tekst(aanpassing) die je op de website van de VVV wilt tonen. Wij verzorgen de plaatsing en vertaling binnen enkele dagen.',
                        })}
                        data={{
                            nl: descriptionNL,
                            de: descriptionDE,
                            en: descriptionEN,
                            pending: descriptionPending,
                        }}
                    />

                    <FieldHeading title={formatMessage({ defaultMessage: 'Belangrijk om te weten' })}>
                        <FormattedMessage defaultMessage="Hier beschrijf je de specifieke informatie die van belang is voor deelnemers" />
                    </FieldHeading>
                    <TranslationInput
                        placeholder={formatMessage({ defaultMessage: 'Omschrijving' })}
                        name="descriptionParticulars"
                        label={formatMessage({ defaultMessage: 'Tekst invoeren of aanpassen' })}
                        hint={formatMessage({
                            defaultMessage:
                                'Plaats hier de tekst(aanpassing) die je op de website van de VVV wilt tonen. Wij verzorgen de plaatsing en vertaling binnen enkele dagen.',
                        })}
                        data={{
                            nl: descriptionParticularsNL,
                            de: descriptionParticularsDE,
                            en: descriptionParticularsEN,
                            pending: descriptionParticularsPending,
                        }}
                    />
                </>
            )}
        </FormScreen>
    );
};

export default ActivityDescription;
