import { DOMAttributes, MouseEvent } from 'react';
import { PLACEHOLDER_ID } from '@oberoninternal/travelbase-ds/components/calendar/Allotment';
import addDays from 'date-fns/addDays';
import isBefore from 'date-fns/isBefore';
import startOfDay from 'date-fns/startOfDay';
import startOfToday from 'date-fns/startOfToday';
import { AllotmentLockoutTypeEnum, AllotmentsLockoutFragment, Scalars } from '../generated/graphql';
import { useWriteLockout } from './useWriteLockout';
import { DocumentNode } from 'graphql';
import { OperationVariables, useApolloClient } from '@apollo/client';
import { doesOverlap } from '../utils/doesOverlap';
import { PricingQueryShape } from '../components/organisms/Pricing';
import { SidebarHook } from '../context/sidebar';
import shouldHideBooking from '../utils/shouldHideBooking';

const getPlaceholder = (date: Date): AllotmentsLockoutFragment => ({
    __typename: 'AllotmentLockout',
    id: PLACEHOLDER_ID,
    startDate: startOfDay(date),
    endDate: startOfDay(addDays(date, 1)),
    type: AllotmentLockoutTypeEnum.PrivateUse,
    comment: '',
    isDragging: false,
    isActive: false,
    isHovering: false,
    source: '',
});

const getDate = (element: HTMLDivElement) => {
    const { date } = element.dataset;
    if (!date) {
        throw new Error(`Unable to extract date from element, did you forget to set 'data-date'?`);
    }
    return new Date(date);
};

interface LockoutCreationContext<V> {
    variables?: V;
    rentalUnitId?: Scalars['ID']['output'];
    maxAllotment?: number;
    query: DocumentNode;
    sidebar: SidebarHook;
    showAllotmentLockouts?: boolean;
}

export const useLockoutCreationProps = <V extends OperationVariables>({
    maxAllotment,
    query,
    rentalUnitId,
    variables,
    showAllotmentLockouts,
    sidebar: [, dispatch],
}: LockoutCreationContext<V>): DOMAttributes<HTMLDivElement> => {
    const client = useApolloClient();
    const writeLockout = useWriteLockout(query, variables);

    if (!variables || !maxAllotment || maxAllotment > 1 || !showAllotmentLockouts) {
        return {};
    }

    const getLockouts = () =>
        client.readQuery<PricingQueryShape>({
            query,
            variables,
        })?.rentalUnit?.allotmentLockouts ?? [];

    const getBookings = () =>
        client
            .readQuery<PricingQueryShape>({
                query,
                variables,
            })
            ?.rentalUnit?.bookings.filter(({ status }) => !shouldHideBooking(status)) ?? [];

    const deriveStateFromElement = (
        element: HTMLDivElement
    ): {
        shouldIgnoreEvent: boolean;
        placeholder: AllotmentsLockoutFragment;
        lockouts: AllotmentsLockoutFragment[];
        date: Date;
    } => {
        const date = getDate(element);
        let shouldIgnoreEvent = false;
        const lockouts = getLockouts();
        const bookings = getBookings();
        const placeholder = getLockouts().find(({ id }) => id === PLACEHOLDER_ID) ?? getPlaceholder(date);
        const startDate = placeholder.isDragging ? placeholder.startDate : date;
        const endDate = placeholder.isDragging ? date : undefined;

        if (
            isBefore(date, new Date(placeholder.startDate)) ||
            [...lockouts, ...bookings].some(current => doesOverlap(current, startDate, endDate))
        ) {
            shouldIgnoreEvent = true;
        }

        if (lockouts.some(({ isActive }) => isActive)) {
            shouldIgnoreEvent = true;
        }

        if (isBefore(date, startOfToday())) {
            shouldIgnoreEvent = true;
        }

        return {
            placeholder,
            lockouts,
            shouldIgnoreEvent,
            date,
        };
    };

    return {
        onMouseEnter(e: MouseEvent<HTMLDivElement>) {
            const { date, placeholder, shouldIgnoreEvent } = deriveStateFromElement(e.currentTarget);
            if (shouldIgnoreEvent) {
                return;
            }

            let lockout = placeholder;

            if (lockout.isDragging) {
                lockout = {
                    ...lockout,
                    endDate: date,
                };
            }

            writeLockout(lockout);
        },
        onMouseLeave(e: MouseEvent<HTMLDivElement>) {
            const { shouldIgnoreEvent } = deriveStateFromElement(e.currentTarget);
            if (shouldIgnoreEvent) {
                return;
            }

            const existing = getLockouts().find(({ id }) => id === PLACEHOLDER_ID);
            if (existing?.isDragging) {
                // don't delete placeholder; we're dragging
                return;
            }

            writeLockout(null);
        },
        onMouseDown(e: MouseEvent<HTMLDivElement>) {
            const { shouldIgnoreEvent, placeholder } = deriveStateFromElement(e.currentTarget);
            if (shouldIgnoreEvent) {
                return;
            }

            e.preventDefault();
            writeLockout({
                ...placeholder,
                isDragging: true,
            });
        },
        onMouseUp(e: MouseEvent<HTMLDivElement>) {
            const { placeholder, shouldIgnoreEvent } = deriveStateFromElement(e.currentTarget);
            if (shouldIgnoreEvent) {
                writeLockout(null);
                return;
            }

            e.preventDefault();

            const existing = {
                ...(getLockouts().find(({ id }) => id === PLACEHOLDER_ID) ?? placeholder),
                isDragging: false,
                isActive: true,
            };

            writeLockout(existing);

            dispatch({
                type: 'show',
                data: {
                    type: 'LOCKOUT',
                    lockout: existing,
                    rentalUnitId,
                    document: query,
                    variables,
                },
            });
        },
    };
};
