import * as Yup from 'yup';
import internationalPhoneRegex from '../../constants/internationalPhoneRegex';
import { phoneSchema } from './phoneSchema';
import { IntlShape } from 'react-intl';

const zipRegEx = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;

export const addressValidation = (intl: IntlShape) =>
    Yup.object().shape({
        city: Yup.string().required(intl.formatMessage({ defaultMessage: 'Vul een plaats in.' })),
        postalCode: Yup.string()
            .required(intl.formatMessage({ defaultMessage: 'Vul een postcode in.' }))
            .when('countryCode', {
                is: 'NL',
                then: Yup.string().matches(zipRegEx, intl.formatMessage({ defaultMessage: 'Geen geldige postcode' })),
            }),
        street: Yup.string().required(intl.formatMessage({ defaultMessage: 'Vul een adres in.' })),
        number: Yup.string().required(intl.formatMessage({ defaultMessage: 'Vul een huisnummer in' })),
        countryCode: Yup.string().required(intl.formatMessage({ defaultMessage: 'Kies een land' })),
    });

export const locationValidation = (intl: IntlShape) =>
    Yup.object().shape({
        address: addressValidation(intl),
        showKeyHolder: Yup.boolean(),
        keyHolderAddress: Yup.object().when('showKeyHolder', { is: true, then: addressValidation }),
        keyHolderName: Yup.string().when('showKeyHolder', {
            is: true,
            then: Yup.string().required(intl.formatMessage({ defaultMessage: 'Vul een naam in' })),
        }),
        keyHolderPhoneNumber: Yup.string().matches(
            internationalPhoneRegex,
            intl.formatMessage({ defaultMessage: 'Vul een geldig telefoonnummer in' })
        ),
    });

export const keyHolderValidation = (intl: IntlShape) =>
    Yup.object().shape({
        keyHolder: Yup.object().shape({
            phoneNumbers: Yup.array().of(phoneSchema(intl)),
        }),
        keyHolderAddress: addressValidation(intl),
    });
