import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import Toast from '@oberoninternal/travelbase-ds/components/feedback/Toast';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import addDays from 'date-fns/addDays';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import startOfDay from 'date-fns/startOfDay';
import startOfToday from 'date-fns/startOfToday';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import React, { useMemo, useRef } from 'react';
import { generatePath, Link, useLocation, useParams } from 'react-router-dom';
import { ListOnItemsRenderedProps } from 'react-window';
import epoch from '../constants/epoch';
import { PriceColumnPricingFragment, PricesRowVisibilityFragment, Scalars } from '../generated/graphql';
import { useToast } from './useToast';
import { getBulkWizardStartDate, getShouldShowToast } from '../utils/primarypricing';
import { useSidebar } from '../context/sidebar';
import { UnitParams } from '../entities/UnitParams';
import { RentalUnitMetaData } from '../utils/getRentalUnitMetaData';
import { pricingAndVisibilityKeys } from '../utils/getRowVisibility';
import { FormattedMessage, useIntl } from 'react-intl';

export const usePricesToasts = (
    metaData: RentalUnitMetaData,
    rowVisibility: PricesRowVisibilityFragment | null,
    itemsRendered: ListOnItemsRenderedProps | null,
    datePricings?: PriceColumnPricingFragment[],
    start?: Scalars['Date']['output'],
    end?: Scalars['Date']['output']
) => {
    const params = useParams<UnitParams>();
    const [{ open }, dispatch] = useSidebar();
    const { formatMessage } = useIntl();
    const { pathname } = useLocation();
    const hasUnapprovedContracts = (metaData?.rentalUnitContracts ?? []).some(
        ({ approvalDate, startDate }) => !approvalDate && isBefore(new Date(startDate), startOfToday())
    );
    const prevItemsRendered = useRef<ListOnItemsRenderedProps | null>(null);
    const noNewPrices = useRef(false);
    const hasInvisiblePrices = useRef(false);

    if (!isEqual(prevItemsRendered.current, itemsRendered) && itemsRendered) {
        const visibleDays = range(itemsRendered.visibleStartIndex, itemsRendered.visibleStopIndex).map(
            index => +startOfDay(addDays(epoch, index))
        );

        const dayIsAfterVisibleDay = (date: Date, every?: boolean) =>
            !every ? visibleDays.some(day => isAfter(day, date)) : visibleDays.every(day => isAfter(day, date));

        const getNoNewPrices = (): boolean => {
            if (!metaData) {
                return false;
            }
            const { lastDatePricingDate, datePricingStartDate, datePricingEndDate } = metaData;

            const bulkDate = getBulkWizardStartDate(datePricingStartDate, datePricingEndDate, lastDatePricingDate);
            const shouldShowToast = getShouldShowToast(datePricingStartDate, datePricingEndDate, lastDatePricingDate);

            if (
                (datePricingEndDate && dayIsAfterVisibleDay(new Date(datePricingEndDate), true)) ||
                (datePricingStartDate && !dayIsAfterVisibleDay(new Date(datePricingStartDate)))
            ) {
                return false;
            }

            return !!bulkDate && shouldShowToast && dayIsAfterVisibleDay(bulkDate);
        };

        const getHasInvisiblePrices = (): boolean => {
            if (!metaData) {
                return false;
            }
            const { datePricingStartDate, datePricingEndDate } = metaData;

            if (
                (datePricingEndDate && dayIsAfterVisibleDay(new Date(datePricingEndDate), true)) ||
                (datePricingStartDate && !dayIsAfterVisibleDay(new Date(datePricingStartDate)))
            ) {
                return false;
            }

            const invisiblePrices = Object.entries(pricingAndVisibilityKeys).some(([key, value]) =>
                datePricings?.some(datePricing => {
                    if (!!datePricing?.[value] && !rowVisibility?.[key as keyof PricesRowVisibilityFragment]) {
                        return true;
                    }
                    return false;
                })
            );

            return invisiblePrices;
        };

        noNewPrices.current = getNoNewPrices();
        hasInvisiblePrices.current = getHasInvisiblePrices();
    }

    const noPricesVisible = useMemo(
        () => (rowVisibility ? Object.values(rowVisibility).every(show => !show) : false),
        [rowVisibility]
    );
    useToast(
        hasInvisiblePrices.current ? (
            <Toast
                variant="info"
                title={formatMessage({
                    defaultMessage: 'In deze periode zijn er prijzen ingesteld op onzichtbare velden.',
                })}
                actions={
                    <Button
                        onClick={() => {
                            dispatch({
                                type: 'show',
                                data: {
                                    type: 'PRICING_CONFIG',
                                    rentalUnitId: metaData?.id,
                                    showBaseStayPriceRow: !!rowVisibility?.showBaseStayPriceRow,
                                    showExtraPersonPriceRow: !!rowVisibility?.showExtraPersonPriceRow,
                                    showMinimumStayPriceRow: !!rowVisibility?.showMinimumStayPriceRow,
                                    showWeekPriceRow: !!rowVisibility?.showWeekPriceRow,
                                    showArrivalAllowedRow: !!rowVisibility?.showArrivalAllowedRow,
                                    showDepartureAllowedRow: !!rowVisibility?.showDepartureAllowedRow,
                                    showNightPriceRow: !!rowVisibility?.showNightPriceRow,
                                    showMinimumStayDurationRow: !!rowVisibility?.showMinimumStayDurationRow,
                                    start,
                                    end,
                                    onClose: () =>
                                        dispatch({
                                            type: 'close',
                                        }),
                                },
                            });
                        }}
                    >
                        <FormattedMessage defaultMessage="Instellen" />
                    </Button>
                }
            >
                <Body variant="small">
                    <FormattedMessage defaultMessage="Wil je deze nu weergeven?" />
                </Body>
            </Toast>
        ) : null
    );

    useToast(
        noNewPrices.current && !noPricesVisible && !open ? (
            <Toast
                variant="info"
                title={formatMessage({ defaultMessage: 'Vanaf deze datum heb je nog geen nieuwe prijzen.' })}
                actions={
                    <Button
                        as={Link}
                        to={{
                            pathname: `${pathname}/wizard`,
                            state: { type: 'prices' },
                        }}
                    >
                        <FormattedMessage defaultMessage="Invoeren" />
                    </Button>
                }
            >
                <Body variant="small">
                    <FormattedMessage defaultMessage="Wil je deze nu invoeren?" />
                </Body>
            </Toast>
        ) : null
    );

    useToast(
        hasUnapprovedContracts ? (
            <Toast
                variant="warn"
                title={formatMessage({ defaultMessage: 'Instellingen zijn nog niet actief' })}
                actions={
                    <Button as={Link} to={generatePath(`/partner/:partnerId/unit/:unitSlug/info/contracts`, params)}>
                        <FormattedMessage defaultMessage="Bekijk" />
                    </Button>
                }
            >
                <Body variant="small">
                    <FormattedMessage defaultMessage="Je activeert je instellingen door eerst akkoord te geven op de overeenkomsten." />
                </Body>
            </Toast>
        ) : null
    );

    useToast(
        noPricesVisible ? (
            <Toast variant="warn" title={formatMessage({ defaultMessage: 'Geen prijsregels zichtbaar' })}>
                <Body variant="small">
                    <FormattedMessage
                        defaultMessage="Deze verhuureenheid heeft geen ingeschakelde prijsregels. {breakingLine} Dit is een configuratiefout, neem contact op met ons."
                        values={{
                            breakingLine: <br />,
                        }}
                    />
                </Body>
            </Toast>
        ) : null
    );
};
