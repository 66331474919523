import React, { SVGAttributes } from 'react';

export function Edit(props: SVGAttributes<SVGElement>) {
    return (
        <svg width="12" height="12" {...props}>
            <g fill="none" fillRule="evenodd">
                <path fill="#FFF" d="M-1364-1300H556v4320h-1920z" />
                <path
                    d="M10.1248 2.0419c.6509.6509.6509 1.7061 0 2.357l-5.7405 5.7405h0l-2.509.152.152-2.509 5.7405-5.7405c.6508-.6509 1.7061-.6509 2.357 0z"
                    stroke="currentColor"
                    strokeWidth="2"
                />
            </g>
        </svg>
    );
}
