import React, { ButtonHTMLAttributes, forwardRef, ForwardRefRenderFunction } from 'react';
import styled, { css } from 'styled-components';
import { getAssignmentColor } from '../../constants/theme';
import { UnreachableCaseError } from '../../entities/UnreachableCaseError';
import createBorderCss from '../../utils/createBorderCss';

export type RoundButtonVariant = 'outline' | 'outline-inverted' | 'primary';
export type RoundButtonSize = 'medium' | 'small' | 'large';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: RoundButtonVariant;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    as?: keyof JSX.IntrinsicElements | React.ComponentType<React.PropsWithChildren<any>>;
    size?: RoundButtonSize;
}

const RoundButton: ForwardRefRenderFunction<HTMLButtonElement, Props> = (
    { type = 'button', children, as, ...props },
    ref
) => (
    <StyledRoundButton ref={ref} as={as} type={type} {...props}>
        {children}
    </StyledRoundButton>
);

const primaryCss = css`
    color: ${({ theme }) => theme.colors.neutral['0']};
    background: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.main)};
    --border-color: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.main)};

    :focus,
    :hover {
        ${createBorderCss('2px')};
        --border-color: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.main, 1)};
    }

    :active {
        background: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.main, 1)};
        --border-color: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.main, 1)};
    }

    :disabled {
        background: ${({ theme }) => theme.colors.neutral['0']};
        --border-color: ${({ theme }) => theme.colors.neutral['30']} !important;
        color: ${({ theme }) => theme.colors.neutral['40']} !important;
    }
`;

const outlinePrimaryCss = css`
    color: ${({ theme }) => theme.colors.neutral['70']};
    --border-color: ${({ theme }) => theme.colors.neutral['30']};

    :hover,
    :focus,
    :active {
        ${createBorderCss('2px')};
        --border-color: ${({ theme }) => theme.colors.primary['40']};
    }

    :active {
        background: ${({ theme }) => theme.colors.primary['5']};
    }

    :disabled {
        --border-color: ${({ theme }) => theme.colors.neutral['30']} !important;
        color: ${({ theme }) => theme.colors.neutral['40']} !important;
        background: #e0e0e0 !important;
    }
`;

const outlineInvertedCss = css`
    color: ${({ theme }) => theme.colors.neutral['0']};
    --border-color: currentColor;
    background: rgba(0, 0, 0, 0.2);

    :hover,
    :focus {
        ${createBorderCss('2px')};
    }

    :active {
        --border-color: ${({ theme }) => theme.colors.neutral['0']};
        background: ${({ theme }) => theme.colors.neutral['0']};
        color: ${({ theme }) => theme.colors.neutral['70']};
    }

    :disabled {
        color: ${({ theme }) => theme.colors.neutral['30']} !important;
        --border-color: ${({ theme }) => theme.colors.neutral['30']} !important;
    }
`;

const getCss = (variant: RoundButtonVariant) => {
    switch (variant) {
        case 'primary':
            return primaryCss;
        case 'outline':
            return outlinePrimaryCss;
        case 'outline-inverted':
            return outlineInvertedCss;
        default:
            // ensures this switch is exhaustive
            throw new UnreachableCaseError(variant);
    }
};

const getSizeCss = (size: RoundButtonSize) => {
    switch (size) {
        case 'small':
            return css`
                height: 3.2rem;
                width: 3.2rem;
            `;
        case 'medium':
            return css`
                height: 4rem;
                width: 4rem;
            `;
        case 'large':
            return css`
                height: ${({ theme }) => theme.spacing['70_XLarge']};
                width: ${({ theme }) => theme.spacing['70_XLarge']};
            `;
        default:
            throw new UnreachableCaseError(size);
    }
};

export const StyledRoundButton = styled.button<Props>`
    background: none;
    color: currentColor;
    border: none;
    border-radius: 50%;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    outline: none;
    -webkit-font-smoothing: antialiased;
    ${({ variant = 'primary' }) => getCss(variant)};
    ${createBorderCss('1px')};
    :disabled {
        cursor: not-allowed;
    }

    ${({ size = 'medium' }) => getSizeCss(size)};
`;

export default forwardRef(RoundButton);
