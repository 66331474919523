import React, { FC } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Left from '../figure/Left';
import TextButton from '../action/TextButton';
import Title from '../primitive/Title';

interface Props {
    title: string;
    backButtonText?: string;
}

const Pagehead: FC<React.PropsWithChildren<Props>> = ({ title, backButtonText, children }) => {
    const { goBack, length } = useHistory();

    return (
        <>
            {backButtonText && length && (
                <StyledTextButton onClick={goBack}>
                    <Left />
                    <span>{backButtonText}</span>
                </StyledTextButton>
            )}
            <Title variant="regular">{title}</Title>
            <p>{children}</p>
        </>
    );
};

export default Pagehead;

const StyledTextButton = styled(TextButton)`
    + h2 {
        padding-top: ${({ theme }) => theme.spacing['50_Semi']};
    }
`;
