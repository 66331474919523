import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import { Box } from '@rebass/grid';
import React, { FC, memo, useCallback } from 'react';
import { areEqual, ListChildComponentProps } from 'react-window';
import styled from 'styled-components/macro';
import useBigList, { CreateRow, CreateSkeletonRows } from '../../../hooks/useBigList';
import createGuestsText from '../../../utils/createGuestsText';
import { formatDuration } from '../../../utils/formatDuration';
import { shouldHideBookingCustomer } from '../../../utils/shouldHideBooking';
import BigListWrapper from '../../atoms/BigListWrapper';
import * as Yup from 'yup';
import BigTableHeader from '../../atoms/BigTableHeader';
import UnitDetails from '../../atoms/UnitDetails';
import BigList from '../../molecules/BigList';
import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';
import { STEP } from '../Bookings';
import { getGuestTitle } from '../bookings/Details';

import {
    AllotmentLockout,
    AllVisitorRegistrationsDocument,
    Booking,
    IdentificationTypeEnum,
    MissingVisitorRegistrationsDocument,
    useCreateVisitorRegistrationMutation,
    usePartnerDataQuery,
} from '../../../generated/graphql';
import SelectInput from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import Sidebar from '@oberoninternal/travelbase-ds/components/layout/Sidebar';
import useSesame, { Sesame } from '@oberoninternal/travelbase-ds/hooks/useSesame';
import SidebarField from '../../atoms/SidebarField';
import FormScreen from '../../organisms/FormScreen';
import { gql } from 'graphql-tag';
import countryOptions from '../../../constants/countryOptions';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import NumberInput from '../../atoms/NumberInput';
import { identificationTypeTranslations } from './VisitorRegisterHistoryList';
import { useParams } from 'react-router-dom';
import { PartnerParams } from '../../../entities/PartnerParams';

import DayPickerInputField from '../../molecules/DayPickerInputField';
import { SidebarSeperator } from '../../atoms/Seperator';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import Plus from '@oberoninternal/travelbase-ds/components/figure/Plus';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

type VisitorRegistrationBooking = Pick<
    Booking,
    | 'id'
    | '__typename'
    | 'arrivalDate'
    | 'departureDate'
    | 'duration'
    | 'amountAdults'
    | 'amountBabies'
    | 'amountChildren'
    | 'amountPets'
    | 'amountYouths'
    | 'status'
> & {
    rentalUnit: Pick<Booking['rentalUnit'], 'id' | 'name' | 'slug' | 'code' | 'thumbnailUrl' | 'hideForPartner'>;
    customer?: Maybe<{
        firstName?: Maybe<string>;
        lastName?: Maybe<string>;
        address?: Maybe<{
            city?: Maybe<string>;
            countryCode?: Maybe<string>;
        }>;
    }>;
};

type VisitorRegistrationLockout = Pick<AllotmentLockout, 'id' | '__typename' | 'startDate' | 'endDate'> & {
    rentalUnit: Pick<Booking['rentalUnit'], 'id' | 'name' | 'slug' | 'code' | 'thumbnailUrl' | 'hideForPartner'>;
};

interface RegistrationListProps {
    entries?: (VisitorRegistrationBooking | VisitorRegistrationLockout)[];
    isLoading: boolean;
    isSearching?: boolean;
    noResultsText: string;
}

const widths = [
    [3 / 16, null, 2 / 8],
    [3 / 16, null, 1 / 8],
    [3 / 16, null, 3 / 16],
    [3 / 16, null, 1 / 8],
    [3 / 16, null, 3 / 16],
];

export const mutation = gql`
    mutation createVisitorRegistration(
        $firstName: String!
        $lastName: String!
        $city: String!
        $countryCode: String!
        $totalPersons: Int!
        $identificationType: IdentificationTypeEnum!
        $arrivalDate: Date!
        $departureDate: Date!
        $rentalUnitId: ID!
        $bookingId: ID
        $lockoutId: ID
    ) {
        createVisitorRegistration(
            input: {
                firstName: $firstName
                lastName: $lastName
                city: $city
                countryCode: $countryCode
                totalPersons: $totalPersons
                identificationType: $identificationType
                arrivalDate: $arrivalDate
                departureDate: $departureDate
                rentalUnitId: $rentalUnitId
                bookingId: $bookingId
                lockoutId: $lockoutId
            }
        ) {
            visitorRegistration {
                id
                __typename
            }
        }
    }
`;
const RegistrationsList: FC<RegistrationListProps> = memo(({ entries, isLoading, noResultsText, isSearching }) => {
    const { createSkeletonRows, createRow } = useBigList(widths);
    const sesame = useSesame(false, {
        closeOnClickOutside: true,
        closeOnEscape: true,
    });

    const { formatMessage } = useIntl();

    const [activeRegistrationId, setActiveRegistrationId] = React.useState<string | undefined>(undefined);

    const onOpenSidebarWithoutId = useCallback(() => {
        setActiveRegistrationId(undefined);
        sesame.onOpen();
    }, [sesame]);

    const onOpenVisitorRegistrationSidebar = useCallback(
        (id: string) => {
            setActiveRegistrationId(id);
            sesame.onOpen();
        },
        [sesame, setActiveRegistrationId]
    );
    const Item: FC<ListChildComponentProps> = props => (
        <BookingItem onOpen={onOpenVisitorRegistrationSidebar} {...props} />
    );
    return (
        <BigListWrapper>
            <AddVisitorRegisterButton onClick={onOpenSidebarWithoutId}>
                <Plus />
                <span>
                    <FormattedMessage defaultMessage="Voeg handmatig toe" />
                </span>
            </AddVisitorRegisterButton>
            <BigTableHeader>
                {[
                    formatMessage({ defaultMessage: 'Accommodaties' }),
                    formatMessage({ defaultMessage: 'Periode' }),
                    formatMessage({ defaultMessage: 'Gast en samenstelling' }),
                    formatMessage({ defaultMessage: 'Woonplaats' }),
                    formatMessage({ defaultMessage: 'Identiteitsbewijs' }),
                ].map((header, i) => (
                    <Box key={i} width={widths[i]}>
                        <Label>{header}</Label>
                    </Box>
                ))}
            </BigTableHeader>

            {!isSearching ? (
                !isLoading && entries?.length === 0 ? (
                    <Box p={4}>{noResultsText}</Box>
                ) : (
                    <BigList
                        overscanCount={STEP * 2}
                        itemCount={entries?.length ?? 0}
                        itemSize={78}
                        itemData={{ edges: entries, createRow, createSkeletonRows }}
                    >
                        {Item}
                    </BigList>
                )
            ) : (
                createSkeletonRows(STEP)
            )}
            <VisitorRegistrationSidebar
                activeRegistration={entries?.find(registration => registration.id === activeRegistrationId)}
                sesame={sesame}
                setActiveRegistrationId={setActiveRegistrationId}
            />
        </BigListWrapper>
    );
});

export default RegistrationsList;

interface BookingItemProps extends ListChildComponentProps {
    onOpen: (id: string) => void;
    data: {
        edges: (VisitorRegistrationBooking | VisitorRegistrationLockout)[];
        createRow: CreateRow;
        createSkeletonRows: CreateSkeletonRows;
    };
}

const BookingItem: FC<BookingItemProps> = memo(
    ({ onOpen, index, style, data: { createRow, createSkeletonRows, edges } }) => {
        const booking = edges[index];
        if (!booking) {
            return createSkeletonRows(1, style);
        }
        if (booking.__typename === 'AllotmentLockout') {
            const startDate = new Date(booking.startDate);
            const endDate = new Date(booking.endDate);
            const periodText = formatDuration(startDate, endDate);
            return createRow(
                '#',
                style,
                [
                    <StyledUnitDetails key={booking.id} unit={{ ...booking.rentalUnit }} />,
                    { text: periodText },
                    {
                        text: '-',
                    },
                    {
                        text: '-',
                    },
                    {
                        text: '-',
                    },
                ],

                () => onOpen(booking.id)
            );
        }
        if (booking.__typename === 'Booking') {
            const { id, rentalUnit, arrivalDate, departureDate, duration, customer, ...rest } = booking;

            const startDate = new Date(arrivalDate);
            const endDate = new Date(departureDate);
            const periodText = formatDuration(startDate, endDate);

            return createRow(
                '#',
                style,
                [
                    <StyledUnitDetails key={id} unit={{ ...rentalUnit }} />,
                    { text: periodText, subText: `${duration} nachten` },
                    {
                        text: getGuestTitle(customer, shouldHideBookingCustomer(booking.status)),
                        subText: customer ? createGuestsText(rest) : undefined,
                    },
                    {
                        text: customer?.address?.city ?? '-',
                    },
                    {
                        text: '-',
                    },
                ],
                () => onOpen(id)
            );
        }

        return null;
    },
    areEqual
);

type FormValues = {
    firstName: string;
    lastName: string;
    city: string;
    countryCode: string;
    totalPersons: number;
    identificationType?: string;
    arrivalDate: Date;
    departureDate: Date;
    rentalUnitId?: string;
};

function isSameDate(date1: Date, date2: Date) {
    return date1.getTime() === date2.getTime();
}

const validationSchema = (intl: IntlShape) =>
    Yup.object().shape({
        identificationType: Yup.string().required(
            intl.formatMessage({ defaultMessage: 'Type identiteitsbewijs is verplicht' })
        ),
        firstName: Yup.string().required(intl.formatMessage({ defaultMessage: 'Voornaam is verplicht' })),
        lastName: Yup.string().required(intl.formatMessage({ defaultMessage: 'Achternaam is verplicht' })),
        city: Yup.string().required(intl.formatMessage({ defaultMessage: 'Woonplaats is verplicht' })),
        countryCode: Yup.string().required(intl.formatMessage({ defaultMessage: 'Land is verplicht' })),
        totalPersons: Yup.number().required(intl.formatMessage({ defaultMessage: 'Aantal personen is verplicht' })),
        arrivalDate: Yup.date().required(intl.formatMessage({ defaultMessage: 'Aankomstdatum is verplicht' })),
        departureDate: Yup.date()
            .required(intl.formatMessage({ defaultMessage: 'Vertrekdatum is verplicht' }))
            .min(Yup.ref('arrivalDate'), 'Vertrekdatum moet na aankomstdatum zijn')
            .test({
                name: 'same',
                exclusive: false,
                params: {},
                message: 'Vertrekdatum moet na aankomstdatum zijn',
                test(value) {
                    const startDate = new Date(this.parent.arrivalDate);
                    const endDate = new Date(value);
                    return !isSameDate(startDate, endDate);
                },
            }),
    });

const VisitorRegistrationSidebar: FC<{
    sesame: Sesame;
    activeRegistration?: VisitorRegistrationBooking | VisitorRegistrationLockout;
    setActiveRegistrationId: (id: string | undefined) => void;
}> = ({ sesame, activeRegistration, setActiveRegistrationId }) => {
    const { partnerId } = useParams<PartnerParams>();
    const { data } = usePartnerDataQuery({ variables: { partnerId } });
    const accommodations = data?.partner?.accommodations ?? [];
    const rentalUnits = accommodations.flatMap(accomodation => accomodation?.rentalUnits ?? []);
    const accomodationOptions = rentalUnits.map(rentalUnit => ({
        label: `${rentalUnit.name} (${rentalUnit.code})`,
        value: rentalUnit.id,
    }));
    const intl = useIntl();
    const { formatMessage } = intl;
    const [mutate] = useCreateVisitorRegistrationMutation({
        refetchQueries: [
            {
                query: MissingVisitorRegistrationsDocument,
                variables: {
                    partnerId,
                },
            },
            {
                query: AllVisitorRegistrationsDocument,
                variables: {
                    partnerId,
                    first: 40,
                    rentalUnitIds: [],
                    startDate: null,
                    endDate: null,
                },
            },
        ],
    });

    const getInitialValues = () => {
        if (activeRegistration?.__typename === 'Booking') {
            return {
                firstName: activeRegistration?.customer?.firstName ?? '',
                lastName: activeRegistration?.customer?.lastName ?? '',
                city: activeRegistration?.customer?.address?.city ?? '',
                countryCode: activeRegistration?.customer?.address?.countryCode ?? '',
                identificationType: '',
                totalPersons:
                    (activeRegistration?.amountAdults ?? 0) +
                    (activeRegistration?.amountChildren ?? 0) +
                    (activeRegistration?.amountBabies ?? 0),
                arrivalDate: activeRegistration?.arrivalDate,
                departureDate: activeRegistration?.departureDate,
            };
        }
        if (activeRegistration?.__typename === 'AllotmentLockout') {
            return {
                firstName: '',
                lastName: '',
                city: '',
                countryCode: '',
                identificationType: '',
                totalPersons: 1,
                arrivalDate: activeRegistration.startDate,
                departureDate: activeRegistration.endDate,
            };
        }
        return {
            firstName: '',
            lastName: '',
            city: '',
            countryCode: 'NL',
            totalPersons: 1,
            arrivalDate: new Date(),
            departureDate: new Date(),
            identificationType: '',
        };
    };
    return (
        <Sidebar {...sesame}>
            <FormScreen<FormValues>
                validationSchema={validationSchema(intl)}
                handleSubmit={async values => {
                    const typeKey = activeRegistration?.__typename === 'Booking' ? 'bookingId' : 'lockoutId';
                    await mutate({
                        variables: {
                            firstName: values.firstName,
                            lastName: values.lastName,
                            city: values.city,
                            countryCode: values.countryCode,
                            totalPersons: values.totalPersons,
                            identificationType: values?.identificationType
                                ? (values?.identificationType as IdentificationTypeEnum)
                                : IdentificationTypeEnum.Passport,
                            arrivalDate: values.arrivalDate,
                            departureDate: values.departureDate,
                            rentalUnitId: values.rentalUnitId
                                ? values.rentalUnitId
                                : activeRegistration?.rentalUnit?.id ?? '',
                            [typeKey]: activeRegistration?.id,
                        },
                    });
                    sesame.onClose();
                    setActiveRegistrationId(undefined);
                }}
                variant="sidebar"
                initialValues={getInitialValues()}
            >
                {() => (
                    <>
                        {activeRegistration?.id === undefined && (
                            <>
                                <SidebarField variant="small">
                                    <Label htmlFor="rentalUnitId">
                                        <FormattedMessage defaultMessage="Accommodatie" />
                                    </Label>
                                    <SelectInput
                                        name="rentalUnitId"
                                        isSearchable
                                        placeholder={formatMessage({ defaultMessage: 'Selecteer accomodatie' })}
                                        options={accomodationOptions}
                                    />
                                </SidebarField>
                                <SidebarSeperator />
                                <OverlayDatePicker>
                                    <SidebarField label={formatMessage({ defaultMessage: 'Startdatum' })}>
                                        <DayPickerInputField enabledPast disabledFuture name="arrivalDate" />
                                    </SidebarField>
                                </OverlayDatePicker>
                                <SidebarField label={formatMessage({ defaultMessage: 'Einddatum' })}>
                                    <DayPickerInputField enabledPast name="departureDate" />
                                </SidebarField>
                                <SidebarSeperator />
                            </>
                        )}

                        <SidebarField variant="small">
                            <Label htmlFor="identificationType">
                                <FormattedMessage defaultMessage="Type identiteitsbewijs" />
                            </Label>
                            <SelectInput
                                placeholder={formatMessage({ defaultMessage: 'Selecteer' })}
                                id="identificationType"
                                options={[
                                    {
                                        label: identificationTypeTranslations[IdentificationTypeEnum.Passport],
                                        value: IdentificationTypeEnum.Passport,
                                    },
                                    {
                                        label: identificationTypeTranslations[IdentificationTypeEnum.DrivingLicense],
                                        value: IdentificationTypeEnum.DrivingLicense,
                                    },
                                    {
                                        label: identificationTypeTranslations[IdentificationTypeEnum.Card],
                                        value: IdentificationTypeEnum.Card,
                                    },
                                ]}
                                name="identificationType"
                            />
                        </SidebarField>
                        {(activeRegistration?.__typename === 'AllotmentLockout' || !activeRegistration) && (
                            <>
                                <SidebarField variant="small">
                                    <Label htmlFor="firstName">
                                        <FormattedMessage defaultMessage="Voornaam gast" />
                                    </Label>
                                    <TextInputField name="firstName" />
                                </SidebarField>
                                <SidebarField variant="small">
                                    <Label htmlFor="lastName">
                                        <FormattedMessage defaultMessage="Achternaam gast" />
                                    </Label>
                                    <TextInputField name="lastName" />
                                </SidebarField>
                                <SidebarField variant="small">
                                    <Label htmlFor="totalPersons">
                                        <FormattedMessage defaultMessage="Aantal personen" />
                                    </Label>
                                    <NumberInput name="totalPersons" />
                                </SidebarField>
                                <SidebarField variant="small">
                                    <Label htmlFor="city">
                                        <FormattedMessage defaultMessage="Woonplaats gast" />
                                    </Label>
                                    <TextInputField name="city" />
                                </SidebarField>

                                <SidebarField variant="small">
                                    <Label htmlFor="countryCode">
                                        <FormattedMessage defaultMessage="Land gast" />
                                    </Label>
                                    <SelectInput
                                        id="countryCode"
                                        name="countryCode"
                                        isSearchable
                                        placeholder={formatMessage({ defaultMessage: 'Selecteer land' })}
                                        options={countryOptions}
                                    />
                                </SidebarField>
                            </>
                        )}
                    </>
                )}
            </FormScreen>
        </Sidebar>
    );
};
const StyledUnitDetails = styled(UnitDetails)`
    min-width: 100%;

    @media (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        > :first-child {
            display: none;
        }
    }
`;
const OverlayDatePicker = styled.div`
    z-index: ${({ theme }) => theme.zIndices.docked};
    position: relative;
`;

const AddVisitorRegisterButton = styled(Button).attrs(() => ({ variant: 'outline' }))`
    height: 7.2rem;
    width: 100%;
    color: ${({ theme }) => theme.colors.primary[40]};
    margin-bottom: 2rem;
    margin-top: 2rem;
`;
