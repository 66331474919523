import { FormikHelpers, FormikValues, useFormikContext } from 'formik';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useRef } from 'react';

interface Props<V> {
    onSave: (values: V, helpers: FormikHelpers<V>) => Promise<void> | void;
    enableReinitialize?: boolean;
}

// TODO: move this to shared library as its used in multiple projects
const AutoSave = <V extends FormikValues>({ onSave, enableReinitialize }: Props<V>) => {
    const { values, dirty, ...rest } = useFormikContext<V>();
    const previousValues = useRef(values);

    useEffect(() => {
        if (
            ((enableReinitialize && dirty) || !enableReinitialize) &&
            previousValues &&
            Object.keys(previousValues.current).length &&
            !isEqual(previousValues.current, values)
        ) {
            onSave(values, rest);
            previousValues.current = values;
        }
    }, [dirty, enableReinitialize, onSave, previousValues, rest, values]);

    return <></>;
};

export default AutoSave;
