import { css } from 'styled-components';
import defaultTheme, { ColorAssignment } from '../themes/default';

export * from '../themes/default';

interface Range {
    min: number;
    max: number;
}
export interface FontStyle {
    size: Range;
    line: Range;
    fontFamily?: string;
    weight?: number;
    letterSpacing?: number;
    color?: string;
}

const responsiveFontCss = css`
    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        select,
        textarea,
        input {
            font-size: 1.6rem;
        }
    }

    font-size: calc(
        var(--min-size) * 1px + (var(--max-size) - var(--min-size)) * (100vw - (var(--min-viewport) * 1px)) /
            (var(--max-viewport) - var(--min-viewport))
    );

    line-height: calc(
        var(--min-line) * 1px + (var(--max-line) - var(--min-line)) * (100vw - (var(--min-viewport) * 1px)) /
            (var(--max-viewport) - var(--min-viewport))
    );

    @media (min-width: ${({ theme }) => theme.mediaQueriesValues.xl}px) {
        font-size: calc(var(--max-size) * 1px);
        line-height: calc(var(--max-line) * 1px);
    }
`;

export const getFontCss = (style: FontStyle) => css`
    --min-size: ${style.size.min};
    --max-size: ${style.size.max};
    --min-line: ${style.line.min};
    --max-line: ${style.line.max};
    ${responsiveFontCss};
    ${style.fontFamily
        ? css`
              font-family: ${style.fontFamily};
          `
        : ''}
    ${style.weight
        ? css`
              font-weight: ${style.weight};
          `
        : ''}
        ${style.letterSpacing
        ? css`
              letter-spacing: ${style.letterSpacing}px;
          `
        : ''}
        ${style.color
        ? css`
              color: ${style.color};
          `
        : ''}
`;

export type Theme = typeof defaultTheme;

export const getAssignmentColor = ({ colors }: Theme, assignment: ColorAssignment | string, step = 0): string => {
    if (typeof assignment === 'string') {
        return assignment;
    }
    const [variant, currentStep] = assignment;
    const colorIndex = Object.keys(colors[variant]).indexOf(String(currentStep));

    if (colorIndex === -1) {
        throw new Error('The requested color is not found');
    }

    const colorValues = Object.values(colors[variant]);

    const nextColor =
        colorIndex + step < 0
            ? colorValues[0]
            : colorIndex + step >= colorValues.length
            ? colorValues[colorValues.length - 1]
            : colorValues[colorIndex + step];

    if (!nextColor) {
        throw new Error('The calculated color computed from the step is out of bounds');
    }

    return nextColor;
};
