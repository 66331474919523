import React, { FC } from 'react';
import gql from 'graphql-tag';
import { Route, useRouteMatch, generatePath } from 'react-router-dom';
import ContentWrapper from '../atoms/ContentWrapper';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import SubNav from '@oberoninternal/travelbase-ds/components/layout/SubNav';
import All from './bookings/All';
import RecentlyUpdated from './bookings/Recent';
import Upcoming from './bookings/Upcoming';
import NotFound from './NotFound';
import { PartnerParams } from '../../entities/PartnerParams';
import { bookingRoutes } from '../../utils/routes';
import { FormattedMessage, useIntl } from 'react-intl';
import { LocalizedSwitch } from '../atoms/LocalizedSwitch';

export const fragment = gql`
    fragment BookingConnection on BookingConnection {
        edges {
            ...BookingEdge
        }
        pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
        }
        totalCount
    }

    fragment BookingEdge on BookingEdge {
        cursor
        node {
            ...BookingListItem
        }
    }
`;

export const STEP = 10;

export interface BookingSearchValues {
    searchQuery: string;
    startDate: null | string;
    endDate: null | string;
    rentalUnitIds: string[];
}

const Booking: FC<React.PropsWithChildren<unknown>> = () => {
    const match = useRouteMatch<PartnerParams>();
    const { formatMessage } = useIntl();
    return (
        <ContentWrapper>
            <Pagehead title={formatMessage({ defaultMessage: 'Boekingen' })}>
                <FormattedMessage defaultMessage="Bekijk hier de boekingen van je accommodatie(s) die gemaakt zijn via Travelbase." />
            </Pagehead>
            <SubNav
                links={bookingRoutes.map(({ title, source, exact }) => ({
                    exact,
                    to: generatePath(source ? `${match.path}/${source}` : match.path, {
                        partnerId: match.params.partnerId,
                    }),
                    children: formatMessage(title),
                }))}
            />
            <LocalizedSwitch>
                <Route path={`${match.path}/all`} exact component={All} />
                <Route path={`${match.path}/upcoming`} exact component={Upcoming} />
                <Route path={`${match.path}`} exact component={RecentlyUpdated} />
                <Route component={NotFound} />
            </LocalizedSwitch>
        </ContentWrapper>
    );
};

export default Booking;
