import React, { FC, memo } from 'react';
import styled, { css } from 'styled-components/macro';
import NavDropdown, { DropdownList, ToggleContainer, SurfaceItem } from './NavDropdown';

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    disableToggle: boolean;
}

const PickerWrapper: FC<React.PropsWithChildren<Props>> = memo(({ children, ...props }) => (
    <Container {...props}>{children}</Container>
));

export default PickerWrapper;

const Container = styled(NavDropdown)<{ open: boolean }>`
    width: 40rem;
    z-index: 2;
    max-height: 60vh;
    border-radius: 0 0 1rem 1rem;

    ${SurfaceItem} {
        position: relative;
        ${({ open }) =>
            open &&
            css`
                ::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 2px;
                    width: 100%;
                    background: ${({ theme }) => theme.colors.neutral[20]};
                }
            `}
    }

    @media (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        ${({ theme, open }) => open && `border-top: 2px solid ${theme.colors.neutral[20]}`};
        position: fixed !important;
        width: 100%;
        bottom: 0;
        left: 0;
        background: ${({ theme }) => theme.colors.primary[100]};
        flex-direction: column-reverse;

        ${SurfaceItem} {
            ${({ open }) =>
                open &&
                css`
                    ::after {
                        bottom: unset;
                        top: 0;
                    }
                `}
        }

        ${ToggleContainer} {
            bottom: 0;
            top: unset;
        }
        ${DropdownList} {
            border-radius: 0;
        }
    }
`;
