import { Box, Flex, FlexProps } from '@rebass/grid';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Label } from '../primitive/Label';

export interface ContentProps extends Omit<FlexProps, 'label'> {
    label: string | ReactNode;
}

const Content: FC<React.PropsWithChildren<ContentProps>> = ({ label, children, ...props }) => (
    <Container flexDirection={['column', null, 'row']} {...props}>
        <Box width="26.4rem" mb={[3, null, 0]}>
            {typeof label === 'string' ? <Label>{label}</Label> : label}
        </Box>
        <ChildrenWrapper>{children}</ChildrenWrapper>
    </Container>
);

export default Content;

const ChildrenWrapper = styled(Box).attrs(() => ({ width: 1 }))`
    line-height: 24px;
`;

const Container = styled(Flex)`
    margin-bottom: ${({ theme }) => theme.spacing['50_Semi']};
`;
