import { connect, FormikContextType } from 'formik';
import { ReactElement, useEffect, useRef } from 'react';

// my attempt at recreating formik-effect (RIP ⚰️)

interface OuterProps<Values> {
    onChange: (currState: FormikContextType<Values>, prevState: FormikContextType<Values> | null) => void;
}

interface Props<Values> extends OuterProps<Values> {
    formik: FormikContextType<Values>;
}

const Effect = <Values>({ formik, onChange }: Props<Values>) => {
    const ref = useRef<FormikContextType<Values> | null>(null);
    useEffect(() => {
        onChange(formik, ref.current);
        ref.current = formik;
    }, [formik, onChange]);

    return null;
};

export default connect(Effect) as <Values>(props: OuterProps<Values>) => ReactElement;
