import React, { FC } from 'react';
import styled from 'styled-components/macro';
import Logo from '@oberoninternal/travelbase-ds/components/figure/LogoText';

// TODO: implement a spinner
const Loading: FC<React.PropsWithChildren<unknown>> = () => (
    <Container>
        <Wrapper>
            <Logo />
            <Spinner />
        </Wrapper>
    </Container>
);

export default Loading;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Container = styled.div`
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.colors.neutral[0]};
`;

const Spinner = styled.span`
    margin-top: 2rem;
    width: 40px;
    height: 40px;
    background-color: #05dbf5;

    border-radius: 100%;
    animation: scaleout 1s infinite ease-in-out;

    transform: translate(-50%, -50%);
    @keyframes scaleout {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
            opacity: 0;
        }
    }
`;
