import TextInput from '@oberoninternal/travelbase-ds/components/form/TextInput';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import { Box, Flex } from '@rebass/grid';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { NotFoundError } from '../../../../entities/NotFoundError';
import { UnitParams } from '../../../../entities/UnitParams';
import {
    EditRentalUnitAndAccommodationInfoInput,
    useCompactDetailsQuery,
    useEditRentalUnitAndAccommodationInfoMutation,
} from '../../../../generated/graphql';
import omitEqualValues from '../../../../utils/omitEqualValues';
import FieldSet from '../../../atoms/FieldSet';
import FieldSetHint from '../../../atoms/FieldSetHint';
import FieldHeading from '../../../molecules/FieldHeading';
import LabelBox from '../../../molecules/LabelBox';
import FormScreen from '../../../organisms/FormScreen';
import Loading from '../../../organisms/Loading';
import { accommodationDetailsValidation, PublicInfoSection, ReservationInfoSection } from './AccommodationDetails';
import { GeneralInfoSection, ReservationMetaInfoSection, unitDetailsValidation } from './UnitDetails';
import { toInitialContactInfo } from '../../../../utils/toInitialContactInfo';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import createPublicLinkProps from '../../../../utils/createPublicLinkProps';
import brandConfig from '../../../../constants/brandConfig';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

export const query = gql`
    query CompactDetails($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            ...CompactInfo
        }
    }

    mutation EditRentalUnitAndAccommodationInfo($input: EditRentalUnitAndAccommodationInfoInput!) {
        editRentalUnitAndAccommodationInfo(input: $input) {
            rentalUnit {
                ...CompactInfo
            }
        }
    }

    fragment CompactInfo on RentalUnit {
        id
        slug
        code
        type
        securityDeposit
        handleSecurityDepositPayment
        releaseDays
        name
        tagline: tagline(locale: "nl")
        taglineDE: tagline(locale: "de")
        taglineEN: tagline(locale: "en")
        taglinePending
        hideReviews

        accommodation {
            id
            publicReservationsEmail
            publicReservationsPhoneNumber
            publicReservationsWebsiteUrl
            reservations {
                contactName
                phoneNumbers
                emails
            }
            caretaker {
                contactName
                phoneNumbers
                emails
            }
            checkInStartTime
            checkInEndTime
            checkOutTime
        }
    }
`;

export const compactDetailsValidation = (intl: IntlShape) =>
    accommodationDetailsValidation(intl).concat(unitDetailsValidation(intl));

const CompactDetails: FC<React.PropsWithChildren<UnitParams>> = ({ unitSlug }) => {
    const { loading, data } = useCompactDetailsQuery({ variables: { unitSlug } });
    const [mutate] = useEditRentalUnitAndAccommodationInfoMutation();
    const intl = useIntl();
    const { formatMessage } = intl;
    if (loading) {
        return <Loading />;
    }

    if (!data || !data.rentalUnit?.accommodation) {
        throw new NotFoundError();
    }

    const {
        rentalUnit: {
            id,
            code,
            type,
            name,
            tagline,
            taglinePending,
            taglineDE,
            taglineEN,
            hideReviews,
            handleSecurityDepositPayment,
            releaseDays,
            securityDeposit,
            accommodation: {
                reservations,
                caretaker,
                checkInEndTime,
                checkInStartTime,
                checkOutTime,
                publicReservationsEmail,
                publicReservationsPhoneNumber,
                publicReservationsWebsiteUrl,
            },
        },
    } = data;

    const initialValues: Omit<EditRentalUnitAndAccommodationInfoInput, 'rentalUnitId'> = {
        reservations: toInitialContactInfo(reservations),
        caretaker: toInitialContactInfo(caretaker),
        checkInStartTime,
        checkInEndTime,
        checkOutTime,
        tagline: taglinePending ?? tagline,
        handleSecurityDepositPayment,
        releaseDays: releaseDays ?? 0,
        securityDeposit: securityDeposit ?? 0,
        publicReservationsEmail,
        publicReservationsPhoneNumber,
        publicReservationsWebsiteUrl,
    };

    return (
        <FormScreen
            validationSchema={compactDetailsValidation(intl)}
            initialValues={initialValues}
            handleSubmit={async ({ caretaker: newCaretaker, ...values }) => {
                await mutate({
                    variables: {
                        input: {
                            ...omitEqualValues(values, initialValues, ['tagline']),
                            rentalUnitId: id,
                            caretaker: {
                                ...newCaretaker,
                                // as these are not required we need to filter out the empty ones
                                emails: newCaretaker.emails?.filter(email => email !== ''),
                                phoneNumbers: newCaretaker.phoneNumbers?.filter(phoneNumber => phoneNumber !== ''),
                            },
                        },
                    },
                });
            }}
        >
            {() => (
                <>
                    <Flex justifyContent="space-between" flexDirection={['column', 'row']}>
                        <Box>
                            <Pagehead title={formatMessage({ defaultMessage: 'Accommodatie informatie' })}>
                                <FormattedMessage defaultMessage="Hier beheer je de informatie van de accommodatie" />
                            </Pagehead>
                        </Box>
                        <Box>
                            <TextButton
                                style={{ display: 'flex-inline' }}
                                as="a"
                                {...createPublicLinkProps(
                                    `${
                                        brandConfig.customSlugs?.accommodation
                                            ? brandConfig.customSlugs?.accommodation
                                            : 'accommodatie'
                                    }/${unitSlug}`,
                                    intl
                                )}
                            />
                        </Box>
                    </Flex>

                    <FieldHeading title={formatMessage({ defaultMessage: 'Algemeen' })}>
                        <FormattedMessage defaultMessage="Onder deze gegevens is de accommodatie bekend bij ons." />
                    </FieldHeading>
                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="name">
                                <FormattedMessage defaultMessage="Naam accommodatie" />
                            </Label>
                            <FieldSetHint>
                                <FormattedMessage
                                    defaultMessage="Zoals vermeld in jouw Travelbase overeenkomst. Neem contact met ons op met eventuele
                                vragen."
                                />
                            </FieldSetHint>
                        </LabelBox>
                        <Box width={1}>
                            <TextInput value={name} disabled />
                        </Box>
                    </FieldSet>
                    <PublicInfoSection />
                    <GeneralInfoSection
                        {...{ type, code, hideReviews, tagline, taglineDE, taglineEN, taglinePending }}
                    />

                    <ReservationInfoSection />

                    <ReservationMetaInfoSection />
                </>
            )}
        </FormScreen>
    );
};

export default CompactDetails;
