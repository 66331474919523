import transparentize from 'polished/lib/color/transparentize';
import styled, { css } from 'styled-components/macro';

export interface CellProps {
    hasSeparator?: boolean;
    selectedWidth?: number;
}

export const CELL_HEIGHT = 88;

const getSelectedStyles = (selectedWidth: number | undefined) => {
    if (!selectedWidth) {
        return null;
    }
    return css`
        ::after {
            opacity: 1;
            z-index: 1;
            background: ${({ theme }) => transparentize(0.9, theme.colors.primary[30])};
            width: calc(${selectedWidth * 100}% + ${selectedWidth * 1}px);
            --border-color: ${({ theme }) => theme.colors.primary[30]};
        }
    `;
};

const Cell = styled.div<CellProps>`
    position: relative;
    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        :first-of-type {
            padding: 3rem 1.6rem 1rem;
        }
        padding: 2rem 0.6rem 0rem;
    }

    padding: 2rem 1.6rem;

    height: ${CELL_HEIGHT}px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[20]};
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ hasSeparator }) =>
        hasSeparator &&
        css`
            border-top: 1px solid ${({ theme }) => theme.colors.neutral[20]};
        `};

    ::after {
        pointer-events: none;
        content: '';
        opacity: 0;
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        box-shadow: 0 0 0 1px var(--border-color), inset 0 0 0 1px var(--border-color);
        --border-color: transparent;
        border-radius: 4px;
    }
    ${({ selectedWidth }) => getSelectedStyles(selectedWidth)}
`;

export default Cell;
