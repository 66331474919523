import { StepperField } from '@oberoninternal/travelbase-ds/components/form/Stepper';
import Switch from '@oberoninternal/travelbase-ds/components/form/Switch';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import { Box, Flex } from '@rebass/grid';
import { useField } from 'formik';
import React, { ComponentType, ReactNode, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ActivityTimeslotsFragment } from '../../generated/graphql';
import SidebarField from './SidebarField';

interface Options {
    rateGroups: ActivityTimeslotsFragment['activityRateGroups'];
    rateGroupFieldName?: string;
    allotmentFieldName?: string;
}

const useTimeslotAllotmentProps = ({
    rateGroups,
    allotmentFieldName = 'allotment',
    rateGroupFieldName = 'rateGroupId',
}: Options) => {
    const [rateGroupField, { initialValue }] = useField<string>(rateGroupFieldName);
    const [{ value }, , { setValue }] = useField<number | null>(allotmentFieldName);
    const checked = typeof value === 'number';
    const canBuyTickets = rateGroups.find(rateGroup => rateGroup.id === rateGroupField.value)?.canBuyTickets;

    const dirty = initialValue !== rateGroupField.value;

    useEffect(() => {
        if (dirty && typeof canBuyTickets === 'boolean') {
            setValue(canBuyTickets ? null : 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canBuyTickets, dirty]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.currentTarget.checked ? 0 : null);
    };

    return { name: allotmentFieldName, checked, value, canBuyTickets, onChange };
};

const TimeslotAllotmentField = ({
    Container = SidebarField,
    ...options
}: Options & { Container?: ComponentType<React.PropsWithChildren<{ label?: ReactNode }>> }) => {
    const { canBuyTickets, checked, value, name, onChange } = useTimeslotAllotmentProps(options);
    const { formatMessage } = useIntl();

    if (!canBuyTickets) {
        return null;
    }

    return (
        <Container label={formatMessage({ defaultMessage: 'Beschikbaarheid' })}>
            <Box>
                <Flex mb={4}>
                    <Label htmlFor={name} style={{ marginRight: '0.4rem' }} variant="small">
                        <FormattedMessage defaultMessage="Beperken" />
                    </Label>
                    <Switch id={name} checked={checked} onChange={onChange} />
                </Flex>
                {typeof value === 'number' && <StepperField maximum={999} editable name={name} size="medium" />}
            </Box>
        </Container>
    );
};
export default TimeslotAllotmentField;
