import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { TextInputField, TextInputFieldProps } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import useWeekdays from '@oberoninternal/travelbase-ds/hooks/useWeekdays';
import { Flex, FlexProps, Box } from '@rebass/grid';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import { connect, Form, FormikContextType, getIn, useField } from 'formik';
import React, { FC, ReactNode, useMemo } from 'react';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import { getDateOpts } from '../../constants/dateOpts';
import { PriceColumnKey, PRICE_COLUMN_ROWS } from '../../constants/priceRows';
import { UnreachableCaseError } from '../../entities/UnreachableCaseError';
import { Weekday } from '../../hooks/useIsBulkEditing';
import parseDate from '@oberoninternal/travelbase-ds/utils/parseDate';

import ContentWrapper from '../atoms/ContentWrapper';
import Heading from '../atoms/Heading';
import { SidebarSeperator, Seperator } from '../atoms/Seperator';
import SidebarField from '../atoms/SidebarField';
import { BulkEditValues } from './BulkEditForm';
import BulkSwitch from './BulkSwitch';
import { RootSidebarData } from './SidebarContent';
import RangePickerInputField from './RangePickerInputField';
import { useSidebar } from '../../context/sidebar';
import FormActions from '@oberoninternal/travelbase-ds/components/form/FormActions';
import Checkbox from '@oberoninternal/travelbase-ds/components/form/Checkbox';
import StartdateEffect from '../atoms/StartdateEffect';
import { FormattedMessage, useIntl } from 'react-intl';

export interface BulkEditSidebarData extends RootSidebarData {
    type: 'BULK_SIDEBAR';
    row: PriceColumnKey;
    unitId: string;
}

interface FormikPartProps {
    formik: FormikContextType<BulkEditValues>;
}
interface Props {
    data: BulkEditSidebarData;
}

const BulkEditSidebar: FC<React.PropsWithChildren<Props & FormikPartProps>> = props => {
    const {
        data: { row },
        formik: formikBag,
    } = props;
    const weekdays = useWeekdays();
    const { formatMessage, locale } = useIntl();
    // if formikBag is empty, it probably means the route has changed so clear & close the sidebar.
    const [, dispatch] = useSidebar();
    const dateOpts = getDateOpts(locale as 'nl' | 'de' | 'en');

    if (!formikBag || Object.entries(formikBag).length === 0) {
        dispatch({ type: 'clear' });
        return null;
    }

    const {
        setFieldValue,
        values: { startDate: start, endDate: end, ...values },
        setFieldTouched,
    } = formikBag;
    const interval: Interval = { start: parseDate(start), end: parseDate(end) };

    return (
        <Form>
            <StartdateEffect />
            <ContentWrapper variant="sidebar">
                <SidebarSeperator />

                <Heading
                    title={formatMessage(
                        {
                            defaultMessage: '{row} aanpassen',
                        },
                        { row: formatMessage(PRICE_COLUMN_ROWS[row]) }
                    )}
                >
                    <FormattedMessage defaultMessage="Selecteer een periode en de nieuwe waardes." />
                </Heading>
                <SidebarField label={formatMessage({ defaultMessage: 'Periode (t/m)' })}>
                    <RangePickerInputField />
                </SidebarField>
                <SidebarSeperator />
                <Heading title={formatMessage({ defaultMessage: 'Nieuwe waardes voor weekdagen' })} />
                <InputWrapper>
                    {weekdays.map((day, i) => {
                        const name = format(day, 'eeee').toLowerCase() as Weekday;

                        let field: ReactNode;

                        const disabled =
                            interval?.start && interval.end
                                ? !eachDayOfInterval(interval).find(d => getDay(day) === getDay(d))
                                : undefined;

                        switch (row) {
                            case 'arrivalAllowed':
                            case 'departureAllowed':
                                field = (
                                    <>
                                        <BulkSwitch
                                            handleEditable={val => setFieldValue(name, val)}
                                            setTouched={val => setFieldTouched(name, val)}
                                            name={name}
                                            value={getIn(values, name)}
                                            disabled={disabled}
                                        />
                                        {i !== weekdays.length - 1 && <BulkSwitchSeperator variant="small" />}
                                    </>
                                );
                                break;
                            case 'extraPersonPrice':
                            case 'nightPrice':
                            case 'minimumStayDuration':
                            case 'minimumStayPrice':
                            case 'baseStayPrice':
                                field = (
                                    <BulkInput
                                        isInteger={row === 'minimumStayDuration'}
                                        isCurrency={row !== 'minimumStayDuration'}
                                        displayTouched
                                        onInput={() => setFieldTouched(name, true)}
                                        disabled={disabled}
                                        name={name}
                                    />
                                );
                                break;
                            case 'weekPrice':
                                field = (
                                    <Box mt={3}>
                                        <Flex alignItems="center" justifyContent="space-between">
                                            <Checkbox
                                                onChange={e => setFieldValue(name, e.currentTarget.checked ? null : '')}
                                                checked={getIn(values, name) !== ''}
                                            >
                                                <Body style={{ marginTop: '-4px' }} variant="small">
                                                    <FormattedMessage defaultMessage="aanpassen" />
                                                </Body>
                                            </Checkbox>
                                            <Box ml={5} width={1}>
                                                <BulkInput
                                                    id={`bulkInput-${name}`}
                                                    isCurrency
                                                    displayTouched
                                                    nullable
                                                    onInput={() => setFieldTouched(name, true)}
                                                    disabled={getIn(values, name) === '' || disabled}
                                                    name={name}
                                                />
                                            </Box>
                                        </Flex>
                                    </Box>
                                );
                                break;
                            default:
                                throw new UnreachableCaseError(row);
                        }
                        return (
                            <FormGroup key={`bulkPriceDay-${i}`} row={row}>
                                <StyledLabel>{format(day, 'eeee', dateOpts)}</StyledLabel>
                                {field}
                            </FormGroup>
                        );
                    })}
                </InputWrapper>
            </ContentWrapper>
            <FormActions
                resetText={formatMessage({ defaultMessage: 'Wijzigingen ongedaan maken' })}
                {...formikBag}
                variant="sidebar"
            />
        </Form>
    );
};

export default connect<Props, BulkEditValues>(BulkEditSidebar);

const BulkInput: FC<
    React.PropsWithChildren<
        TextInputFieldProps & {
            isCurrency: boolean;
            isInteger?: boolean;
            nullable?: boolean;
            name: string;
        }
    >
> = ({ isCurrency, isInteger, nullable, ...props }) => {
    const [{ value, onChange, onBlur, name }, { error, touched }, { setValue }] = useField({
        name: props.name,
    });
    const { formatMessage } = useIntl();
    const schema = useMemo(() => {
        let initial = Yup.number()
            .nullable()
            .typeError('Vul een geldige waarde in.')
            .min(isCurrency ? 0.0 : 0, 'Waarde ligt onder het minimum')
            .max(isCurrency ? 10000 : 365, 'Waarde ligt boven het maximum');

        if (isInteger) {
            initial = initial.integer('Vul een rond getal in');
        }
        return initial;
    }, [isCurrency, isInteger]);

    return (
        <TextInputField
            {...props}
            value={nullable && value === null ? '' : value}
            name={name}
            step={isCurrency ? 0.01 : 1}
            validate={newValue => {
                try {
                    schema.validateSync(newValue === '' ? null : newValue);
                    return undefined;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } catch (ex: any) {
                    // eslint-disable-next-line no-throw-literal
                    return ex.message;
                }
            }}
            onChange={e => {
                if (nullable) {
                    setValue(e.currentTarget.value === '' ? null : e.currentTarget.value);
                } else {
                    onChange(e);
                }
            }}
            onBlur={onBlur}
            error={touched && error ? error : undefined}
            type="number"
            variant="small"
            // eslint-disable-next-line formatjs/no-literal-string-in-jsx
            placeholder={isCurrency ? '--,--' : formatMessage({ defaultMessage: 'aantal' })}
        />
    );
};

const StyledLabel = styled(Body).attrs(() => ({ variant: 'small' }))`
    width: 9rem;
    font-weight: 500;
    align-self: flex-start;
    margin-top: 0.4rem;
`;

const InputWrapper = styled.div`
    margin-top: 0 !important;

    > * + * {
        margin-top: 1.2rem;
    }
`;
const FormGroup = styled(Flex).attrs(() => ({ justifyContent: 'space-between' } as FlexProps))<{
    row: PriceColumnKey;
}>`
    align-items: ${({ row }) =>
        row === 'arrivalAllowed' || row === 'departureAllowed' || row === 'weekPrice' ? 'initial' : 'center'};
    flex-direction: ${({ row }) =>
        row === 'arrivalAllowed' || row === 'departureAllowed' || row === 'weekPrice' ? 'column' : 'row'};
`;

const BulkSwitchSeperator = styled(Seperator)`
    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        margin-top: ${({ theme }) => theme.spacing['40_Standard']};
        margin-bottom: ${({ theme }) => theme.spacing['00_None']};
    }

    margin-bottom: ${({ theme }) => theme.spacing['00_None']};
    margin-top: ${({ theme }) => theme.spacing['40_Standard']};
`;
