import { RateFragment, RateInput } from '../generated/graphql';

const getInitialRates = (rates?: RateFragment[]) =>
    rates?.map(
        (rate): RateInput => ({
            id: rate.id,
            position: rate.position,
            price: rate.price,
            translations: [
                { name: rate.name ?? '', locale: 'nl' },
                { name: rate.deName ?? '', locale: 'de' },
                { name: rate.enName ?? '', locale: 'en' },
            ],
        })
    ) ?? [];

export default getInitialRates;
