import { Box, BoxProps, Flex } from '@rebass/grid';
import styled from 'styled-components/macro';
import React, { FC } from 'react';
import { UnreachableCaseError } from '../../entities/UnreachableCaseError';
import { useThemeContext } from '../../hooks/useThemeContext';
import SidebarContent from '@oberoninternal/travelbase-ds/components/layout/SidebarContent';

export type ContentWrapperVariant = 'sidebar' | 'info' | 'default' | 'wizard';

interface Props extends BoxProps {
    variant?: ContentWrapperVariant;
}

const ContentWrapper: FC<React.PropsWithChildren<Props>> = ({ variant = 'default', ...props }) => {
    switch (variant) {
        case 'info':
            return <InfoContent {...props} />;
        case 'default':
            return <DefaultContent {...props} />;
        case 'wizard':
            return <DefaultContent pb={0} {...props} />;
        case 'sidebar':
            return <SidebarContent {...props} />;
        default:
            throw new UnreachableCaseError(variant);
    }
};

export default ContentWrapper;

const InfoContent = styled(Box).attrs(() => ({
    mx: ['auto', null, null, null, 0],
    ml: [null, null, null, null, '18rem'],
    p: [4, 5, null, 8],
    pb: 2,
    pt: ['8rem', '9rem', '10rem', '10rem'],
}))`
    overflow-y: auto;
    min-height: calc(100vh - ${({ theme }) => theme.heights.unitNav}px - 88px);
    max-width: 124.6rem;

    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        padding-left: 56px;
        padding-right: 56px;
    }
`;

const DefaultContent: FC<React.PropsWithChildren<BoxProps>> = ({ children, ...props }) => {
    const {
        maxWidths: { xl },
    } = useThemeContext();
    return (
        <Flex justifyContent="center">
            <Wrapper px={[4, null, null, 0]} pb="10rem" width={[1, null, null, `${xl}px`]} {...props}>
                {children}
            </Wrapper>
        </Flex>
    );
};

const Wrapper = styled(Box)`
    padding-top: calc(${({ theme }) => theme.heights.nav}px + ${({ theme }) => theme.spacing['70_XLarge']});
    position: relative;

    @media (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        min-height: calc(100vh - ${({ theme }) => theme.heights.unitNav + theme.heights.mobileNav}px);
    }

    min-height: calc(100vh - ${({ theme }) => theme.heights.unitNav + theme.heights.nav}px);
`;
