/* eslint-disable no-console */
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import DeviceSizeProvider from '@oberoninternal/travelbase-ds/context/devicesize';
import theme from '@oberoninternal/travelbase-ds/themes/default';
import { createUploadLink } from 'apollo-upload-client';
import 'core-js/es';
import { loader } from 'graphql.macro';
import 'isomorphic-fetch';
import 'promise-polyfill/src/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components/macro';
import { QueryParamProvider } from 'use-query-params';
import App from './components/App';
import ScrollToTop from './components/atoms/ScrollToTop';
import GlobalStyle from './globalStyling';
import { initializeSentry } from './sentry';
import fragmentTypes from './generated/fragmentTypes';
import 'intersection-observer';

initializeSentry();

const client = new ApolloClient({
    resolvers: {
        AllotmentLockout: {
            isDragging: rootValue => rootValue.isDragging || false,
            isActive: rootValue => rootValue.isActive || false,
            isHovering: rootValue => rootValue.isHovering || false,
            source: rootValue => rootValue.source || '',
        },
        Booking: {
            isActive: rootValue => rootValue.isActive || false,
            isHovering: rootValue => rootValue.isHovering || false,
        },
    },
    link: ApolloLink.from([
        onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                graphQLErrors.forEach(({ message, locations, path }) =>
                    console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
                );
            }
            if (networkError) {
                console.log(`[Network error]: ${networkError}`);
            }
        }),
        createUploadLink({
            uri: `${process.env.REACT_APP_API_URL}/graphql/`,
            credentials: 'include',
        }) as unknown as ApolloLink,
    ]),

    cache: new InMemoryCache({
        // necessary in order to help apollo know what types our graphql unions consist of
        possibleTypes: fragmentTypes.possibleTypes,
    }),

    // this helps apollo devtools understand our client schema
    typeDefs: loader('./schemas/client.graphqls'),
});

ReactDOM.render(
    <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <BrowserRouter>
                <Route component={ScrollToTop} />
                <QueryParamProvider ReactRouterRoute={Route}>
                    <DeviceSizeProvider>
                        <App />
                    </DeviceSizeProvider>
                </QueryParamProvider>
            </BrowserRouter>
        </ThemeProvider>
    </ApolloProvider>,
    document.getElementById('root')
);
