import React, { FC } from 'react';
import styled from 'styled-components/macro';

interface Props {
    isParent?: boolean;
}

const PickerList: FC<React.PropsWithChildren<Props>> = ({ children, isParent = false }) => (
    <List isParent={isParent}>{children}</List>
);

export default PickerList;

const List = styled.ul<{ isParent: boolean }>`
    margin: 0;
    padding: 0;
    > li > a {
        padding-left: ${({ isParent }) => (isParent ? '0' : '3.2rem')};
    }
`;
