import gql from 'graphql-tag';
import React, { FC } from 'react';
import { UnitParams } from '../../../../entities/UnitParams';
import { InfoPageProps } from '../Info';
import {
    useAccommodationDescriptionQuery,
    useEditAccommodationDescriptionMutation,
} from '../../../../generated/graphql';
import Loading from '../../../organisms/Loading';
import { NotFoundError } from '../../../../entities/NotFoundError';
import FormScreen from '../../../organisms/FormScreen';
import omitEqualValues from '../../../../utils/omitEqualValues';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import FieldHeading from '../../../molecules/FieldHeading';
import TranslationInput from '../../../molecules/TranslationInput';
import { getAccommodationTypeTranslation } from '../../../../utils/getAccommodationTypeTranslation';
import { FormattedMessage, useIntl } from 'react-intl';

export const fragment = gql`
    fragment AccommodationDescription on Accommodation {
        id
        descriptionGeneralNL: descriptionGeneral(locale: "nl")
        descriptionGeneralDE: descriptionGeneral(locale: "de")
        descriptionGeneralEN: descriptionGeneral(locale: "en")
        descriptionGeneralPending
        type
    }
`;

export const query = gql`
    query AccommodationDescription($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            id
            accommodation {
                ...AccommodationDescription
            }
        }
    }
`;

export const editDescriptionsMutation = gql`
    mutation EditAccommodationDescription($input: EditAccommodationDescriptionInput!) {
        editAccommodationDescription(input: $input) {
            accommodation {
                ...AccommodationDescription
            }
        }
    }
`;
const AccommodationDescription: FC<React.PropsWithChildren<UnitParams & InfoPageProps>> = ({ unitSlug }) => {
    const { data, loading } = useAccommodationDescriptionQuery({ variables: { unitSlug } });
    const [mutate] = useEditAccommodationDescriptionMutation();
    const { formatMessage } = useIntl();

    if (loading) {
        return <Loading />;
    }

    if (!data || !data.rentalUnit) {
        throw new NotFoundError();
    }

    const {
        rentalUnit: {
            accommodation: {
                id,
                descriptionGeneralNL,
                descriptionGeneralEN,
                descriptionGeneralDE,
                descriptionGeneralPending,
                type,
            },
        },
    } = data;

    const initialValues = {
        descriptionGeneral: descriptionGeneralPending ?? descriptionGeneralNL ?? '',
    };
    const accommodationTypeTranslation = getAccommodationTypeTranslation(type)?.toLowerCase();
    return (
        <FormScreen
            handleSubmit={async values => {
                await mutate({
                    variables: {
                        input: {
                            accommodationId: id,
                            ...omitEqualValues(values, initialValues, ['descriptionGeneral']),
                        },
                    },
                });
            }}
            initialValues={initialValues}
        >
            {() => (
                <>
                    <Pagehead
                        title={formatMessage(
                            { defaultMessage: 'Beschrijving {accommodationTypeTranslation}' },
                            { accommodationTypeTranslation }
                        )}
                    >
                        <FormattedMessage
                            defaultMessage="Hier beheer je de beschrijving van je {accommodationTypeTranslation}"
                            values={{
                                accommodationTypeTranslation,
                            }}
                        />
                    </Pagehead>

                    <FieldHeading title={formatMessage({ defaultMessage: 'Algemene beschrijving' })}>
                        <FormattedMessage
                            defaultMessage="Dit is de algemene beschrijving van je {accommodationTypeTranslation}."
                            values={{ accommodationTypeTranslation }}
                        />
                    </FieldHeading>
                    <TranslationInput
                        placeholder={formatMessage({ defaultMessage: 'Algemene omschrijving' })}
                        name="descriptionGeneral"
                        label={formatMessage({ defaultMessage: 'Tekst invoeren of aanpassen' })}
                        data={{
                            nl: descriptionGeneralNL,
                            de: descriptionGeneralDE,
                            en: descriptionGeneralEN,
                            pending: descriptionGeneralPending,
                        }}
                    />
                </>
            )}
        </FormScreen>
    );
};

export default AccommodationDescription;
