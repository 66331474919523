import React, { HTMLAttributes } from 'react';
import { useThemeContext } from '../../hooks/useThemeContext';

interface Props extends HTMLAttributes<SVGElement> {
    variant?: 'light' | 'dark';
}
export default function ({ variant = 'light', ...props }: Props) {
    const theme = useThemeContext();
    return (
        <svg {...props} width="26px" height="17px" viewBox="0 0 26 17" version="1.1">
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="navbar-/-inverted"
                    transform="translate(-37.000000, -28.000000)"
                    fill={variant === 'light' ? theme.colors.neutral['0'] : theme.colors.neutral['100']}
                >
                    <g id="Nav">
                        <g id="Group" transform="translate(37.000000, 25.000000)">
                            <path
                                d="M0,3 L26,3 L26,6 L0,6 L0,3 Z M0,10 L26,10 L26,13 L0,13 L0,10 Z M0,17 L26,17 L26,20 L0,20 L0,17 Z"
                                id="Combined-Shape"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
