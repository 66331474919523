import React, { FC, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { generatePath, Route, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ActivityParams } from '../../../entities/ActivityParams';
import InfoContent from '../../atoms/InfoContent';
import ItemDesktopRoute from '../../atoms/ItemDesktopRoute';
import ItemMobileRoute from '../../atoms/ItemMobileRoute';
import { LocalizedSwitch } from '../../atoms/LocalizedSwitch';
import InfoMobileMenu from '../../molecules/InfoMobileMenu';
import InfoSideContainer from '../../molecules/InfoSideContainer';
import ErrorBoundary from '../../organisms/ErrorBoundary';
import NotFound from '../NotFound';
import Archive from '../profile/Archive';
import ProfileDetails from '../profile/Details';

interface InfoRoute {
    name: {
        defaultMessage: string;
    };
    source: string;
}

const infoRouteMessages = defineMessages({
    data: {
        defaultMessage: 'Gegevens',
    },
    archive: {
        defaultMessage: 'Archief',
    },
});
const infoRoutes: InfoRoute[] = [
    { name: infoRouteMessages.data, source: '' },
    { name: infoRouteMessages.archive, source: 'archive' },
];

const Profile: FC<React.PropsWithChildren<unknown>> = () => {
    const [open, setOpen] = useState(false);
    const { pathname } = useLocation();
    const { path, params } = useRouteMatch<ActivityParams>();
    const fullPath = (shortPath: string) => generatePath(shortPath, { ...params });
    const first = [...infoRoutes].reverse().find(({ source }) => pathname.endsWith(source)) ?? infoRoutes[0];
    const { formatMessage } = useIntl();
    return (
        <Container>
            <InfoSideContainer>
                {infoRoutes.map(({ name, source }) => (
                    <ItemDesktopRoute key={`desktop-${source}`} path={fullPath(`${path}/${source}`)}>
                        {formatMessage(name)}
                    </ItemDesktopRoute>
                ))}
            </InfoSideContainer>

            <InfoContent>
                <InfoMobileMenu open={open} setOpen={setOpen}>
                    {open ? (
                        infoRoutes.map(({ name, source }) => (
                            <ItemMobileRoute
                                key={source}
                                open={open}
                                setOpen={setOpen}
                                path={fullPath(`${path}/${source}`)}
                            >
                                {formatMessage(name)}
                            </ItemMobileRoute>
                        ))
                    ) : (
                        <ItemMobileRoute open={open} setOpen={setOpen} path={fullPath(`${path}/${first.source}`)}>
                            {formatMessage(first.name)}
                        </ItemMobileRoute>
                    )}
                </InfoMobileMenu>
                <ErrorBoundary>
                    <LocalizedSwitch>
                        <Route path={fullPath(path)} exact render={() => <ProfileDetails {...params} />} />
                        <Route path={fullPath(`${path}/archive`)} render={() => <Archive {...params} />} />

                        <Route component={NotFound} />
                    </LocalizedSwitch>
                </ErrorBoundary>
            </InfoContent>
        </Container>
    );
};

export default Profile;

const Container = styled.main`
    padding-top: ${({ theme }) => theme.heights}px;

    @media (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        padding-top: ${({ theme }) => theme.heights.mobileNav}px;
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        padding-top: ${({ theme }) => theme.heights.nav}px;
    }
`;
