import React, { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import { RouteProps, Switch } from 'react-router-dom';

export const LocalizedSwitch: React.FC<PropsWithChildren> = ({ children }) => {
    /**
     * inject params and formatMessage through hooks, so we can localize the route
     */
    const { locale } = useIntl();

    /**
     * Apply localization to all routes
     * Also checks if all children elements are <Route /> components
     */
    return (
        <Switch>
            {React.Children.map(children, child =>
                React.isValidElement<RouteProps>(child)
                    ? React.cloneElement(child, {
                          ...child.props,
                          path: localizeRoutePath(child.props.path),
                      })
                    : child
            )}
        </Switch>
    );

    /**
     *
     * @param path can be string, undefined or string array
     * @returns Localized string path or path array
     */
    function localizeRoutePath(path?: RouteProps['path']) {
        switch (typeof path) {
            case 'undefined':
                return undefined;
            case 'object':
                return [...path].map(key => (key.startsWith(`/${locale}`) ? key : `/${locale}${key}`));
            default: {
                const isFallbackRoute = path === '*';
                if (!path.startsWith(`/${locale}`)) {
                    return isFallbackRoute ? path : `/${locale}${path}`;
                }
                return isFallbackRoute ? path : `${path}`;
            }
        }
    }
};
