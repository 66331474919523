import { Flex, FlexProps } from '@rebass/grid';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

const FieldSet: FC<React.PropsWithChildren<FlexProps>> = props => (
    <Group flexDirection={['column', null, 'row']} {...props} />
);

const Group = styled(Flex)<Pick<FlexProps, 'alignItems'>>`
    align-items: ${({ alignItems }) => alignItems ?? 'flex-start'};

    margin-bottom: ${({ theme }) => theme.spacing['40_Standard']};
    &:first-child {
        margin-top: ${({ theme }) => theme.spacing['40_Standard']};
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        margin-bottom: ${({ theme }) => theme.spacing['60_Large']};
        &:first-child {
            margin-top: ${({ theme }) => theme.spacing['60_Large']};
        }
    }
`;

export default FieldSet;
