import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import RoundButton from '@oberoninternal/travelbase-ds/components/action/RoundButton';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Cross from '@oberoninternal/travelbase-ds/components/figure/Cross';
import Plus from '@oberoninternal/travelbase-ds/components/figure/Plus';
import SelectInput, { OptionType } from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { Box, Flex } from '@rebass/grid';
import { FieldArray, FieldConfig, useField } from 'formik';
import React, { ButtonHTMLAttributes, FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components/macro';
import { TimeOpenClosed } from '../../generated/graphql';
import useTimes from '../../hooks/useTimes';

const getIndex = (options: OptionType[], value: string | undefined) => {
    const result = options.findIndex(option => option.value === value);
    return result >= 0 ? result : null;
};

type Variant = 'small' | 'medium';

const TimeOpenClosedField: FC<React.PropsWithChildren<FieldConfig<TimeOpenClosed[]> & { variant?: Variant }>> = ({
    variant = 'medium',
    name,
}) => {
    const [{ value: timeOpenClosedRows }] = useField<TimeOpenClosed[] | null>({ name });

    const timeOptions = useTimes(5);

    const lastTimeOpenClosed = timeOpenClosedRows?.[timeOpenClosedRows.length - 1];
    const lastClosingTime = lastTimeOpenClosed?.timeClosed;
    const lastTimeOpenClosedIndex = getIndex(timeOptions, lastClosingTime);

    const newRowValue = {
        timeOpen: lastTimeOpenClosedIndex ? timeOptions[lastTimeOpenClosedIndex + 1]?.value : '',
        timeClosed: '',
    };

    const addButtonDisabled =
        lastClosingTime === timeOptions[timeOptions.length - 1].value ||
        (!!timeOpenClosedRows?.length && (!lastClosingTime || !lastTimeOpenClosed?.timeOpen));

    const { formatMessage } = useIntl();
    return (
        <FieldArray name={name}>
            {helpers => (
                <Times>
                    {timeOpenClosedRows?.map((_, index) => (
                        <Flex key={index} justifyContent="space-between">
                            <Time variant={variant}>
                                <Box flex={1}>
                                    <SelectInput
                                        isSearchable
                                        variant={variant}
                                        hideChoices
                                        name={`${name}.${index}.timeOpen`}
                                        placeholder={formatMessage({ defaultMessage: 'Tijd' })}
                                        options={timeOptions}
                                    />
                                </Box>
                                {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                                <span>-</span>
                                <Box flex={1}>
                                    <SelectInput
                                        isSearchable
                                        variant={variant}
                                        hideChoices
                                        name={`${name}.${index}.timeClosed`}
                                        placeholder={formatMessage({ defaultMessage: 'Tijd' })}
                                        options={timeOptions}
                                    />
                                </Box>
                            </Time>
                            <StyledRoundButton
                                fieldVariant={variant}
                                size="small"
                                variant="outline"
                                onClick={() => {
                                    if (
                                        window.confirm(
                                            formatMessage({
                                                defaultMessage: 'Weet je zeker dat je deze tijden wilt verwijderen?',
                                            })
                                        )
                                    ) {
                                        helpers.remove(index);
                                    }
                                }}
                            >
                                <Cross />
                            </StyledRoundButton>
                        </Flex>
                    ))}
                    {!timeOpenClosedRows?.length && (
                        <Box py={3}>
                            <Body>
                                <FormattedMessage defaultMessage="Gesloten" />
                            </Body>
                        </Box>
                    )}
                    <Flex flexDirection="column" style={{ display: 'inline-flex' }}>
                        <AddButton
                            disabled={addButtonDisabled}
                            variant={variant}
                            onClick={helpers.handlePush(newRowValue)}
                        >
                            <FormattedMessage defaultMessage="Tijden toevoegen" />
                        </AddButton>
                    </Flex>
                </Times>
            )}
        </FieldArray>
    );
};

export default TimeOpenClosedField;

const AddButton = ({ variant, ...props }: ButtonHTMLAttributes<HTMLButtonElement> & { variant: Variant }) =>
    variant === 'medium' ? (
        <Button variant="outline" {...props} />
    ) : (
        <TextButton size="tiny" {...props}>
            <Plus />
            <span>{props.children}</span>
        </TextButton>
    );

const StyledRoundButton = styled(RoundButton)<{ fieldVariant: Variant }>`
    ${({ fieldVariant }) =>
        fieldVariant === 'small'
            ? css`
                  margin-top: ${({ theme }) => theme.spacing['20_Tiny']};
                  margin-left: ${({ theme }) => theme.spacing['30_Small']};
              `
            : css`
                  margin-top: ${({ theme }) => theme.spacing['30_Small']};
                  margin-left: ${({ theme }) => theme.spacing['40_Standard']};

                  @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
                      margin-left: ${({ theme }) => theme.spacing['60_Large']};
                  }
              `};
`;

const Time = styled(Flex)<{ variant?: Variant }>`
    flex: 1;

    > span {
        margin: ${({ theme }) => theme.spacing['30_Small']} ${({ theme }) => theme.spacing['30_Small']} 0;
    }

    ${({ variant }) =>
        variant === 'medium' &&
        css`
            @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
                > span {
                    margin: ${({ theme }) => theme.spacing['30_Small']} ${({ theme }) => theme.spacing['40_Standard']} 0;
                }
            }
        `}
`;

const Times = styled.div`
    width: 100%;
    > * + * {
        margin-top: ${({ theme }) => theme.spacing['40_Standard']};
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        flex: 1;
        width: unset;
    }
`;
