import { FlexProps } from '@rebass/grid';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import DetailsContainer from './DetailsContainer';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import Overtitle from '@oberoninternal/travelbase-ds/components/primitive/Overtitle';
import gql from 'graphql-tag';
import { UnitDetailsFragment } from '../../generated/graphql';

export const unitDetailsFragment = gql`
    fragment UnitDetails on RentalUnit {
        id
        name
        slug
        code
        thumbnailUrl
        hideForPartner
    }
`;

interface Props extends FlexProps {
    locationName?: string;
    unit: UnitDetailsFragment;
}

const UnitDetails: FC<React.PropsWithChildren<Props>> = ({
    locationName,
    unit: { code, thumbnailUrl, name },
    ...props
}) => (
    <DetailsContainer thumbnailUrl={thumbnailUrl} {...props}>
        {locationName && <StyledOvertitle>{locationName}</StyledOvertitle>}
        <Label as="p" variant="small">
            {name}
        </Label>
        <SubText as="p" variant="small">
            {code}
        </SubText>
    </DetailsContainer>
);

export default UnitDetails;

const SubText = styled(Label)`
    color: ${({ theme }) => theme.colors.neutral[50]};
`;

const StyledOvertitle = styled(Overtitle)`
    color: ${({ theme }) => theme.colors.neutral[50]};
`;
