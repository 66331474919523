import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';
import isAfter from 'date-fns/isAfter';
import isSameYear from 'date-fns/isSameYear';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import dateTextFormat from '@oberoninternal/travelbase-ds/constants/dateTextFormat';
import { getDateOpts } from '../constants/dateOpts';
import { IntlShape } from 'react-intl';

const getPeriodText = (startDate: Maybe<string>, endDate: Maybe<string>, intl: IntlShape) => {
    const dateOpts = getDateOpts(intl.locale as 'nl' | 'en' | 'de');
    const to = intl.formatMessage({ defaultMessage: 't/m' });
    const undefinedTime = intl.formatMessage({ defaultMessage: 'Onbepaalde tijd' });

    if (
        endDate &&
        startDate &&
        isAfter(new Date(startDate), new Date()) &&
        isSameYear(new Date(startDate), new Date(endDate))
    ) {
        return `${format(new Date(startDate), 'd MMM', dateOpts)} ${to} ${format(
            new Date(endDate),
            dateTextFormat,
            dateOpts
        )}`;
    }

    if (endDate && startDate && isAfter(new Date(startDate), new Date())) {
        return `${format(new Date(startDate), dateTextFormat, dateOpts)} ${to} ${format(
            new Date(endDate),
            dateTextFormat,
            dateOpts
        )}`;
    }

    if (endDate && startDate && isBefore(new Date(startDate), new Date())) {
        return `${to} ${format(new Date(endDate), dateTextFormat, dateOpts)}`;
    }

    if (!endDate && startDate && isAfter(new Date(startDate), new Date())) {
        return `${format(new Date(startDate), dateTextFormat, dateOpts)} - ${undefinedTime}`;
    }

    return undefinedTime;
};

export default getPeriodText;
