import { StepperField } from '@oberoninternal/travelbase-ds/components/form/Stepper';
import { SwitchField } from '@oberoninternal/travelbase-ds/components/form/Switch';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import { MenuStateProps } from '@oberoninternal/travelbase-ds/hooks/useMenuState';
import React, { FC } from 'react';
import * as Yup from 'yup';
import ratesValidationSchema from '../../constants/ratesValidationSchema';
import { ActivityRateGroupFragment, UpsertActivityRateGroupInput } from '../../generated/graphql';
import getInitialRates from '../../utils/getInitialRates';
import { SidebarSeperator } from '../atoms/Seperator';
import SidebarField from '../atoms/SidebarField';
import SidebarIntro from '../atoms/SidebarIntro';
import RatesFieldArray from './RatesFieldArray';
import RatesFormScreen from './RatesFormScreen';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import { Flex } from '@rebass/grid';
import Info from '@oberoninternal/travelbase-ds/components/figure/Info';
import { useParams } from 'react-router-dom';
import { PartnerParams } from '../../entities/PartnerParams';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props extends MenuStateProps {
    handleUpsert: (values: Omit<UpsertActivityRateGroupInput, 'activityId'>) => Promise<void>;
    rateGroups: ActivityRateGroupFragment[];
    selectedRateGroup?: ActivityRateGroupFragment;
}

const ActivityRatesSidebar: FC<React.PropsWithChildren<Props>> = ({
    handleUpsert,
    selectedRateGroup,
    rateGroups,
    ...props
}) => {
    const { id, name, rates, canBuyTickets, releaseHours, qrScansEnabled } = selectedRateGroup ?? {};
    const { partnerId } = useParams<PartnerParams>();
    const intl = useIntl();
    const { formatMessage } = intl;
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(formatMessage({ defaultMessage: 'Vul een naam in' })),
        rates: ratesValidationSchema(intl),
    });

    const initialValues = {
        rateGroupId: id,
        name: name ?? '',
        rates: getInitialRates(rates),
        canBuyTickets: !!canBuyTickets,
        qrScansEnabled: !!qrScansEnabled,
        releaseHours,
    };

    return (
        <RatesFormScreen
            menuState={props}
            validationSchema={validationSchema}
            initialValues={initialValues}
            handleSubmit={values =>
                // releaseHours only apply when tickets can be bought through travelbase
                handleUpsert({
                    ...values,
                    qrScansEnabled: values.canBuyTickets ? values.qrScansEnabled : false,
                    releaseHours: values.canBuyTickets ? values.releaseHours : null,
                })
            }
        >
            {({ values }) => (
                <>
                    <SidebarIntro
                        title={
                            id
                                ? formatMessage({ defaultMessage: 'Aanpassen' })
                                : formatMessage({ defaultMessage: 'Toevoegen' })
                        }
                    >
                        <FormattedMessage defaultMessage="Tarieven" />
                    </SidebarIntro>
                    <SidebarField label={formatMessage({ defaultMessage: 'Naam' })} id="name">
                        <TextInputField
                            placeholder={formatMessage({ defaultMessage: 'Naam' })}
                            name="name"
                            variant="small"
                            id="name"
                        />
                    </SidebarField>
                    <SidebarField label={formatMessage({ defaultMessage: 'Online ticketverkoop' })} id="canBuyTickets">
                        <SwitchField name="canBuyTickets" id="canBuyTickets" />
                    </SidebarField>
                    {values.canBuyTickets && (
                        <>
                            <SidebarField id="qrScansEnabled">
                                <Flex justifyContent="space-between">
                                    <Label htmlFor="qrScansEnabled">
                                        <FormattedMessage defaultMessage="Gebruik QR Scanner" />
                                    </Label>
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        href={`/partner/${partnerId}/qr-signup`}
                                        type="button"
                                    >
                                        <Info />
                                    </a>
                                </Flex>
                                <SwitchField name="qrScansEnabled" id="qrScansEnabled" />
                            </SidebarField>
                            <SidebarField
                                label={formatMessage({ defaultMessage: 'Verkoop tot aantal uur voor aanvang' })}
                                id="releaseHours"
                            >
                                <StepperField editable nullable minimum={0} size="small" name="releaseHours" />
                            </SidebarField>
                        </>
                    )}
                    <SidebarSeperator />
                    <RatesFieldArray />
                </>
            )}
        </RatesFormScreen>
    );
};

export default ActivityRatesSidebar;
