import { Flex } from '@rebass/grid';
import styled from 'styled-components';

export default styled(Flex).attrs(() => ({ px: 7, pb: 5 }))`
    flex-grow: 1;
    flex-shrink: 0;
    flex-direction: column;

    @media (max-width: ${({ theme }) => theme.mediaQueries.xs}) {
        padding-left: 32px;
        padding-right: 32px;
    }

    > * + * {
        margin-top: 3.2rem !important;
    }
`;
