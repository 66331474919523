import { FC, useRef, useEffect, memo, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { getIndex } from '../molecules/VirtualizerNavigation';
import { useSidebar } from '../../context/sidebar';
import format from 'date-fns/format';
import dateFormat from '../../constants/dateFormat';
import { getDateOpts } from '../../constants/dateOpts';
import addDays from 'date-fns/addDays';
import epoch from '../../constants/epoch';
import { useVirtualizerScrollData } from '../../context/virtualizerScrollData';
import parseDate from '@oberoninternal/travelbase-ds/utils/parseDate';
import { useIntl } from 'react-intl';

/**
 * This is basically a (hacky) util component that scrolls to use within a Pricing screen.
 * When doing some `startDate` changing we'll scroll to that date so that it shows as the first date
 */
const StartdateEffect: FC<React.PropsWithChildren<{ disableEditOnOpen?: boolean }>> = ({
    disableEditOnOpen = false,
}) => {
    const formikContext = useFormikContext<{ startDate: string; endDate: string }>();
    const { scrollTo, itemsRendered } = useVirtualizerScrollData() ?? {};
    const [{ open }] = useSidebar();
    const openRef = useRef(false);

    const { locale } = useIntl();
    const dateOpts = getDateOpts(locale as 'nl' | 'de' | 'en');

    if (!formikContext) {
        throw new Error('This component should only be used within a formik component ');
    }

    const {
        values: { startDate },
        values,
        setValues,
    } = formikContext;
    const startDateRef = useRef(formikContext.values.startDate);

    const sideBarIsOpening = !openRef.current && open;

    const scrollToStartDate = useCallback(() => {
        if (!startDate) {
            return;
        }
        if (startDateRef.current !== startDate || sideBarIsOpening) {
            scrollTo?.(getIndex(parseDate(startDate)));
            startDateRef.current = startDate;
        }
    }, [scrollTo, sideBarIsOpening, startDate]);

    useEffect(() => {
        scrollToStartDate();
    }, [scrollToStartDate]);

    useEffect(() => {
        // the sidebar is opening
        if (sideBarIsOpening) {
            if (itemsRendered?.current && !disableEditOnOpen) {
                const newStartDate = addDays(epoch, itemsRendered.current.visibleStartIndex);

                setValues(
                    {
                        ...values,
                        startDate: format(newStartDate, dateFormat, dateOpts),
                        endDate: format(addDays(newStartDate, 7), dateFormat, dateOpts),
                    },
                    false
                );
            }
        }
        openRef.current = open;
    }, [disableEditOnOpen, itemsRendered, open, setValues, sideBarIsOpening, values, dateOpts]);
    return null;
};

export default memo(StartdateEffect);
