import format from 'date-fns/format';
import isSameMonth from 'date-fns/isSameMonth';
import isSameYear from 'date-fns/isSameYear';

import { getDateOpts } from '../constants/dateOpts';

/* tslint:disable:prefer-template */

const getFormatString = (startDate: Date, endDate: Date, isEnd = false) => {
    let formatString = 'EEEEEE d';

    if (!isSameMonth(startDate, endDate) || isEnd) {
        formatString += ' MMM';
    }

    if (!isSameYear(new Date(), isEnd ? endDate : startDate)) {
        const periodIsSameYear = isSameYear(startDate, endDate);
        if (!periodIsSameYear || (periodIsSameYear && isEnd)) {
            formatString += " ''yy";
        }
    }
    return formatString;
};

// example output: 20 nov - 16 dec '20
export const formatDuration = (startDate: Date, endDate: Date) =>
    `${format(startDate, getFormatString(startDate, endDate), getDateOpts('nl'))} - ${format(
        endDate,
        getFormatString(startDate, endDate, true),
        getDateOpts('nl')
    )}`;
