import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import ContentWrapper from '../atoms/ContentWrapper';

const Settlements: FC<React.PropsWithChildren<unknown>> = () => (
    <ContentWrapper>
        <h1>
            <FormattedMessage defaultMessage="Afrekeningen" />
        </h1>
    </ContentWrapper>
);

export default Settlements;
