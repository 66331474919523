import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

interface Props {
    path: string;
}

const ItemDesktopRoute: FC<React.PropsWithChildren<Props>> = ({ children, path }) => (
    <Item>
        <Link exact to={path}>
            {children}
        </Link>
    </Item>
);

export default ItemDesktopRoute;

const Item = styled.li`
    height: 4.8rem;
    border-bottom: ${({ theme }) => theme.colors.neutral[20]} 1px solid;
`;

const Link = styled(NavLink).attrs({ activeClassName: 'active' })`
    color: ${({ theme }) => theme.colors.neutral[50]};
    text-decoration: none;
    font-size: 1.4rem;
    line-height: 2rem;
    display: flex;
    align-items: center;
    height: 100%;
    font-weight: 500;

    &.active {
        font-weight: bold;
        color: ${({ theme }) => theme.colors.primary[100]};
    }
`;
