import { Box, Flex } from '@rebass/grid';
import gql from 'graphql-tag';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { getDateOpts } from '../../../constants/dateOpts';
import dateTextFormat from '@oberoninternal/travelbase-ds/constants/dateTextFormat';
import { NotFoundError } from '../../../entities/NotFoundError';
import { useReviewQuery, useAddReplyMutation } from '../../../generated/graphql';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import ContentWrapper from '../../atoms/ContentWrapper';
import UnitDetails from '../../atoms/UnitDetails';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import Loading from '../../organisms/Loading';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import styled from 'styled-components/macro';
import FormActions from '@oberoninternal/travelbase-ds/components/form/FormActions';
import { Seperator } from '../../atoms/Seperator';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import FieldSetHint from '../../atoms/FieldSetHint';
import LabelBox from '../../molecules/LabelBox';
import FieldSet from '../../atoms/FieldSet';
import { FormattedMessage, useIntl } from 'react-intl';
import FieldHeading from '../../molecules/FieldHeading';

export const query = gql`
    query Review($id: ID!) {
        review(id: $id) {
            id
            createdAt
            rentalUnit {
                ...UnitDetails
                accommodation {
                    id
                    name
                }
            }
            title
            comment
            tips
            average
            ratingLocation
            ratingHospitality
            ratingLayout
            ratingMaintenance
            ratingValueForMoney
            city
            enabled
            language
            reply
        }
    }
`;

export const mutation = gql`
    mutation addReply($reviewId: ID!, $reply: String!) {
        editReviewReply(input: { reviewId: $reviewId, reply: $reply }) {
            review {
                id
                reply
            }
        }
    }
`;

const ReviewDetails: FC<React.PropsWithChildren<unknown>> = () => {
    const { reviewId } = useParams<{ reviewId: string }>();
    const [mutate] = useAddReplyMutation();

    const { data, loading } = useReviewQuery({ variables: { id: reviewId } });
    const { formatMessage, locale } = useIntl();

    const dateOpts = getDateOpts(locale as 'nl' | 'en' | 'de');

    if (loading) {
        return <Loading variant="default" />;
    }

    if (!data || !data.review) {
        throw new NotFoundError();
    }

    const {
        review: {
            id,
            createdAt,
            rentalUnit: {
                accommodation: { name: locationName },
                ...rentalUnit
            },
            title,
            comment,
            tips,
            average,
            ratingLocation,
            ratingHospitality,
            ratingLayout,
            ratingMaintenance,
            ratingValueForMoney,
            city,
            enabled,
            language,
            reply: reviewReply,
        },
    } = data;

    const reviewDate = new Date(createdAt);
    const reviewLanguage = language === 'nl' ? 'Nederlands' : language === 'de' ? 'Duits' : 'Engels';
    const initialValues = {
        reply: '',
    };

    return (
        <ContentWrapper pb={[0, null, '8rem']}>
            <Pagehead backButtonText="Terug" title={title} />
            <Flex
                justifyContent="space-between"
                alignItems={[null, null, 'center']}
                flexDirection={['column', null, 'row']}
            >
                <Body>
                    <FormattedMessage
                        defaultMessage="Aangemaakt op {date}"
                        values={{
                            date: format(reviewDate, dateTextFormat, dateOpts),
                        }}
                    />
                </Body>
            </Flex>
            <FieldSet title={formatMessage({ defaultMessage: 'Algemeen' })} />
            <FieldSet alignItems="baseline">
                <LabelBox justifyContent="flex-start">
                    <Label>
                        <FormattedMessage defaultMessage="Taal" />
                    </Label>
                </LabelBox>
                <Box width={1}>{reviewLanguage}</Box>
            </FieldSet>

            {/* TODO: implement this after the API supports this */}
            <FieldSet>
                <LabelBox>
                    <Label>
                        <FormattedMessage defaultMessage="Accommodatie" />
                    </Label>
                </LabelBox>
                <Box width={1}>
                    <UnitDetails style={{ marginTop: '-3px' }} unit={rentalUnit} locationName={locationName} />
                </Box>
            </FieldSet>

            {city && (
                <FieldSet alignItems="baseline">
                    <LabelBox>
                        <Label>
                            <FormattedMessage defaultMessage="Woonplaats" />
                        </Label>
                    </LabelBox>
                    <Box width={1}>{city}</Box>
                </FieldSet>
            )}

            <FieldSet alignItems="baseline">
                <LabelBox>
                    <Label>
                        <FormattedMessage defaultMessage="Gemiddelde" />
                    </Label>
                </LabelBox>
                <Box width={1}>{average}</Box>
            </FieldSet>

            <FieldSet alignItems="baseline">
                <LabelBox>
                    <Label>
                        <FormattedMessage defaultMessage="Geblokkeerd door Travelbase" />
                    </Label>
                </LabelBox>
                <Box width={1}>
                    {enabled ? <FormattedMessage defaultMessage="Nee" /> : <FormattedMessage defaultMessage="Ja" />}
                </Box>
            </FieldSet>

            <FieldHeading title={formatMessage({ defaultMessage: 'Cijfers' })} />

            <FieldSet alignItems="baseline">
                <LabelBox>
                    <Label>
                        <FormattedMessage defaultMessage="Ligging" />
                    </Label>
                </LabelBox>
                <Box width={1}>{ratingLocation}</Box>
            </FieldSet>

            <FieldSet alignItems="baseline">
                <LabelBox>
                    <Label>
                        <FormattedMessage defaultMessage="Gastvrijheid" />
                    </Label>
                </LabelBox>
                <Box width={1}>{ratingHospitality}</Box>
            </FieldSet>

            <FieldSet alignItems="baseline">
                <LabelBox>
                    <Label>
                        <FormattedMessage defaultMessage="Inrichting" />
                    </Label>
                </LabelBox>
                <Box width={1}>{ratingLayout}</Box>
            </FieldSet>

            <FieldSet alignItems="baseline">
                <LabelBox>
                    <Label>
                        <FormattedMessage defaultMessage="Onderhoud" />
                    </Label>
                </LabelBox>
                <Box width={1}>{ratingMaintenance}</Box>
            </FieldSet>

            <FieldSet alignItems="baseline">
                <LabelBox>
                    <Label>
                        <FormattedMessage defaultMessage="Prijs/kwaliteit" />
                    </Label>
                </LabelBox>
                <Box width={1}>{ratingValueForMoney}</Box>
            </FieldSet>

            <FieldSet title={formatMessage({ defaultMessage: 'Beoordeling en tip(s) van jouw gast' })} />

            <FieldSet alignItems="baseline">
                <LabelBox>
                    <Label>
                        <FormattedMessage defaultMessage="Titel" />
                    </Label>
                </LabelBox>
                <Box width={1}>{title}</Box>
            </FieldSet>

            <FieldSet alignItems="baseline">
                <LabelBox>
                    <Label>
                        <FormattedMessage defaultMessage="Beoordeling" />
                    </Label>
                </LabelBox>
                {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                <Box width={1}>{comment ?? '-'}</Box>
            </FieldSet>

            <FieldSet alignItems="baseline">
                <LabelBox>
                    <Label>
                        <FormattedMessage defaultMessage="Tip(s)" />
                    </Label>
                </LabelBox>
                {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                <Box width={1}>{tips ?? '-'}</Box>
            </FieldSet>

            <Seperator />

            <FieldSet alignItems="baseline">
                <LabelBox>
                    <Label>
                        <FormattedMessage defaultMessage="Jouw reactie" />
                    </Label>
                </LabelBox>
                {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                <Box width={1}>{reviewReply ?? '-'}</Box>
            </FieldSet>

            {!reviewReply ? (
                <FieldSet>
                    <LabelBox>
                        <Label htmlFor="name">
                            <FormattedMessage defaultMessage="Reactie op de beoordeling" />
                        </Label>
                        <FieldSetHint>
                            <FormattedMessage defaultMessage="Deze verschijnt online." />
                        </FieldSetHint>
                        <FieldSetHint>
                            <FormattedMessage defaultMessage="De tip(s) van jouw gast verschijnen niet online. Reageer niet op de tip(s)." />
                        </FieldSetHint>
                    </LabelBox>
                    <Box width={1}>
                        <Formik
                            onSubmit={async ({ reply }, { setSubmitting }) => {
                                await mutate({
                                    variables: {
                                        reviewId: id,
                                        reply,
                                    },
                                });
                                setSubmitting(false);
                            }}
                            initialValues={initialValues}
                            validationSchema={Yup.object({
                                reply: Yup.string(),
                            })}
                        >
                            {formikBag => (
                                <Form>
                                    <StyledTextInputField
                                        id="reply"
                                        placeholder={formatMessage({
                                            defaultMessage: 'Schrijf een reactie op de beoordeling.',
                                        })}
                                        name="reply"
                                        type="textarea"
                                    />
                                    <FormActions
                                        {...formikBag}
                                        style={{ position: 'static', marginTop: 0 }}
                                        alwaysVisible
                                        buttonText={formatMessage({ defaultMessage: 'Publiceren' })}
                                        resetText={formatMessage({ defaultMessage: 'Wijzigingen ongedaan maken' })}
                                    />
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </FieldSet>
            ) : null}
        </ContentWrapper>
    );
};

const StyledTextInputField = styled(TextInputField)`
    margin-bottom: 3rem;
`;

export default ReviewDetails;
