import { Flex } from '@rebass/grid';
import { FieldArrayRenderProps, getIn } from 'formik';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Image } from '../../entities/Image';
import { OptionType } from './SelectInput';
import SortableImage from './SortableImage';
import { ImageErrorType } from './UploadImage';
import { ImageFile } from '../../entities/ImageFile';
import {
    DraggableSyntheticListeners,
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

interface Props {
    name: string;
    helpers: FieldArrayRenderProps;
    options?: OptionType[] | undefined;
    validateImage?: (imageElement: HTMLImageElement | null) => ImageErrorType[];
    handleUpload: (file: ImageFile) => Promise<string>;
    maxNumber?: number;
    ratio?: number;
    columns?: number;
    listeners?: DraggableSyntheticListeners;
}

const ImagesList = ({
    images,
    maxNumber,
    helpers,
    onDragEnd,
    ...rest
}: {
    images: Image[];
    onDragEnd: ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => void;
} & Props) => {
    const { replace, push, remove } = helpers;
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const items = images.map(image => image?.id ?? image?.imageId ?? image?.uploadId ?? 0);
    return (
        <Container ml={[null, null, -4]} width={1}>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={e => {
                    if (!e?.over?.data?.current?.sortable || !e?.active?.data?.current?.sortable) return;
                    onDragEnd({
                        oldIndex: e.active.data.current.sortable.index,
                        newIndex: e.over.data.current.sortable?.index,
                    });
                }}
            >
                <SortableContext items={items}>
                    {images.map((image, i) => (
                        <SortableImage
                            key={image?.id ?? image?.imageId ?? image?.uploadId ?? 0}
                            image={image}
                            currentIndex={i}
                            id={image?.id ?? image?.imageId ?? image?.uploadId ?? 0}
                            showHandle={images !== null}
                            helpers={helpers}
                            uploadActionHandler={(uploadId, file) => {
                                replace(i, {
                                    file,
                                    uploadId,
                                    id: undefined,
                                    description: image ? image.description : undefined,
                                });
                                if ((!image || !image.file) && (!maxNumber || images.length < maxNumber)) {
                                    // add a new blank field for uploading an additional image
                                    push(null);
                                }
                            }}
                            crossActionHandler={() => {
                                if (images.length === maxNumber) {
                                    // when at max number of images, after deleting you should be able to add an image again
                                    // so add a blank field for uploading
                                    push(null);
                                }
                                remove(i);
                            }}
                            {...rest}
                        />
                    ))}
                </SortableContext>
            </DndContext>
        </Container>
    );
};
const SortableImages: FC<React.PropsWithChildren<Props>> = ({ helpers, name, ...rest }) => {
    const images = getIn(helpers.form.values, name);

    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        helpers.form.setFieldValue(name, arrayMove(images, oldIndex, newIndex));
    };

    return (
        <ImagesList
            // onSortStart={(_, e) => e.preventDefault()} // otherwise it will select other stuff in safari
            onDragEnd={onSortEnd}
            images={images}
            helpers={helpers}
            name={name}
            {...rest}
        />
    );
};

export default SortableImages;
const Container = styled(Flex)`
    flex-wrap: wrap;
`;
