import React from 'react';
import ContentWrapper from '../atoms/ContentWrapper';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import { generatePath, Route, useRouteMatch } from 'react-router-dom';
import { registerRoutes } from '../../utils/routes';
import NotFound from './NotFound';
import SubNav from '@oberoninternal/travelbase-ds/components/layout/SubNav';
import { PartnerParams } from '../../entities/PartnerParams';
import VisitorRegisterScreen from './visitorRegister/VisitorRegisterScreen';
import HistoryScreen from './visitorRegister/HistoryScreen';
import { FormattedMessage, useIntl } from 'react-intl';
import { LocalizedSwitch } from '../atoms/LocalizedSwitch';

const VisitorRegister = () => {
    const match = useRouteMatch<PartnerParams>();
    const { formatMessage } = useIntl();
    return (
        <ContentWrapper>
            <Pagehead title={formatMessage({ defaultMessage: 'Nachtregister' })}>
                <FormattedMessage defaultMessage="Bekijk hier het nachtregister van de boekingen die zijn gemaakt via Travelbase." />
            </Pagehead>
            <SubNav
                links={registerRoutes.map(({ title, source, exact }) => ({
                    exact,
                    to: generatePath(source ? `${match.path}/${source}` : match.path, {
                        partnerId: match.params.partnerId,
                    }),
                    children: formatMessage(title),
                }))}
            />
            <LocalizedSwitch>
                <Route path={`${match.path}/`} exact component={VisitorRegisterScreen} />
                <Route path={`${match.path}/history`} exact component={HistoryScreen} />
                <Route component={NotFound} />
            </LocalizedSwitch>
        </ContentWrapper>
    );
};

export default VisitorRegister;
