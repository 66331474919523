import { StyledRoundButton } from '@oberoninternal/travelbase-ds/components/action/RoundButton';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import DayHeader from '@oberoninternal/travelbase-ds/components/calendar/DayHeader';
import Plus from '@oberoninternal/travelbase-ds/components/figure/Plus';
import addDays from 'date-fns/addDays';
import compareAsc from 'date-fns/compareAsc';
import isAfter from 'date-fns/isAfter';
import isSameDay from 'date-fns/isSameDay';
import isSameMinute from 'date-fns/isSameMinute';
import startOfToday from 'date-fns/startOfToday';
import React, { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { areEqual, ListChildComponentProps } from 'react-window';
import styled from 'styled-components/macro';
import epoch from '../../constants/epoch';
import { TimeslotsQuery } from '../../generated/graphql';
import Cell from './Cell';
import PricingColumnContainer from './PricingColumnContainer';
import SkeletonCell from './SkeletonCell';
import Timeslot, { TIMESLOT_CELL_HEIGHT } from './Timeslot';

export interface TimeslotsColumnProps extends Omit<ListChildComponentProps, 'data'> {
    data: {
        isLoaded: (index: number) => boolean;
        data: TimeslotsQuery | undefined;
        onClickAdd: (date: Date) => void;
        onClickTimeslot: (id: string) => void;
    };
}

export const TIMESLOT_HEADER_HEIGHT = 154;
export const TIMESLOT_MIN_CELLS = 4;

const getColumnTimeslots = (timeSlotsData: TimeslotsQuery | undefined, date: Date) =>
    timeSlotsData?.activity?.timeslots
        .filter(timeslot => isSameDay(date, new Date(timeslot.startDateTime)))
        .sort((a, b) =>
            // if the startDateTimes are at the same times we'll look at the endDateTimes in order to sort the slots
            isSameMinute(new Date(a.startDateTime), new Date(b.startDateTime))
                ? compareAsc(new Date(a.endDateTime), new Date(b.endDateTime))
                : compareAsc(new Date(a.startDateTime), new Date(a.startDateTime))
        );

const TimeslotsColumn = ({ index, style, data }: TimeslotsColumnProps) => {
    const { isLoaded, data: timeSlotsData, onClickAdd, onClickTimeslot } = data;
    const loaded = isLoaded(index);

    // memoized as its an object that's used to compute the sorted columnTimeSlots
    const date = useMemo(() => addDays(epoch, index), [index]);

    const columnTimeslots = useMemo(() => getColumnTimeslots(timeSlotsData, date), [date, timeSlotsData]);

    const empty = loaded && !columnTimeslots?.length;
    const isUnusable = isAfter(startOfToday(), date);

    return (
        <StyledPricingColumnContainer key={+date} style={style} isUnusable={isUnusable || empty}>
            <StyledDayHeader unbookable={empty} date={date} onClick={() => onClickAdd(date)}>
                {!isUnusable && (
                    <MobileTextButton size="tiny">
                        <Plus />
                        <span>
                            <FormattedMessage defaultMessage="toevoegen" />
                        </span>
                    </MobileTextButton>
                )}
            </StyledDayHeader>
            {!loaded &&
                new Array(TIMESLOT_MIN_CELLS)
                    .fill(SkeletonCell)
                    .map((E, i) => <E key={i} i={i} style={{ height: TIMESLOT_CELL_HEIGHT }} />)}
            {columnTimeslots?.map(slot => (
                <Timeslot key={slot.id} slot={slot} isUnusable={isUnusable} onClick={onClickTimeslot} />
            ))}
            {!isUnusable && (
                <AddCell>
                    <StyledRoundButton variant="outline" onClick={() => onClickAdd(date)}>
                        <Plus />
                    </StyledRoundButton>
                </AddCell>
            )}
        </StyledPricingColumnContainer>
    );
};

export default memo(TimeslotsColumn, areEqual);

const StyledDayHeader = styled(DayHeader)`
    height: ${TIMESLOT_HEADER_HEIGHT}px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[20]};
`;

const MobileTextButton = styled(TextButton)``;

const StyledPricingColumnContainer = styled(PricingColumnContainer)`
    ${StyledRoundButton} {
        display: none;
        background: white;
    }
    /* on touch devices like the mobile the button that appears on hover is replace with a textbutton in the header */
    @media (hover: hover) {
        :hover {
            ${StyledRoundButton} {
                display: flex;
            }
        }

        ${MobileTextButton} {
            display: none;
        }
    }
`;

const AddCell = styled(Cell)`
    height: ${TIMESLOT_CELL_HEIGHT}px;
    border-bottom: none;
`;
