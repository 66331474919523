import React, { FC, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

interface Props {
    path: string;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

const ItemMobileRoute: FC<React.PropsWithChildren<Props>> = ({ open, setOpen, path, children }) => (
    <MobileLink
        onClick={e => {
            if (!open) {
                e.preventDefault();
            }
            setOpen(false);
        }}
        to={path}
        exact
    >
        {children}
    </MobileLink>
);

export default ItemMobileRoute;

const MobileLink = styled(NavLink).attrs({ activeClassName: 'active' })`
    height: 4.8rem;
    color: unset;
    display: flex;
    align-items: center;
    padding-left: 2rem;
    text-decoration: none;
    position: relative;

    &.active {
        font-weight: bold;
    }

    :not(:first-child)::before {
        position: absolute;
        content: '';
        height: 2px;
        width: 100%;
        background: ${({ theme }) => theme.colors.neutral[20]};
        top: 0;
        left: 0;
        z-index: 1;
    }
`;
