import { useDeviceSize } from '@oberoninternal/travelbase-ds/context/devicesize';
import composeRefs from '@seznam/compose-react-refs';
import addDays from 'date-fns/addDays';
import addYears from 'date-fns/addYears';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import startOfToday from 'date-fns/startOfToday';
import startOfMonth from 'date-fns/startOfMonth';
import { debounce } from 'debounce';
import React, {
    ComponentProps,
    ComponentType,
    ReactNode,
    RefObject,
    useCallback,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useScrollBoost } from 'react-scrollbooster';
import { AutoSizer } from 'react-virtualized';
import { FixedSizeList, ListChildComponentProps, ListOnItemsRenderedProps, ListOnScrollProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import styled from 'styled-components/macro';
import epoch from '../../constants/epoch';
import VirtualizerScrollContext from '../../context/virtualizerScrollData';
import { InfoBarContext } from '../molecules/Infobar';
import PricesNav from '../molecules/VirtualizerNavigation';
import { STEP } from '../pages/unit/Prices';

export const todayIndex = differenceInCalendarDays(startOfToday(), epoch);

const useScrollers = (
    containerRef: RefObject<HTMLDivElement>,
    listRef: RefObject<FixedSizeList>,
    columnWidth: number
) => {
    const scrollTo = useCallback(
        (option: number | 'today') => {
            if (!containerRef.current) {
                return;
            }

            let index: number;

            if (option === 'today') {
                index = todayIndex;
            } else {
                index = option;
            }
            const offsetLeft = index * columnWidth;
            const diff = Math.abs(offsetLeft - containerRef.current.scrollLeft);

            // after about 30 days we want to scroll directly instead of smooth, otherwise it'll take too long
            if (diff > 30 * columnWidth) {
                listRef.current?.scrollToItem(index, 'start');
            } else {
                containerRef.current.scrollTo({
                    left: offsetLeft,
                    behavior: 'smooth',
                });
            }
        },
        [columnWidth, containerRef, listRef]
    );

    const scrollBy = useCallback(
        (left: number) =>
            containerRef.current?.scrollBy({
                left,
                behavior: 'smooth',
            }),
        [containerRef]
    );

    return { scrollTo, scrollBy };
};

// how many columns should there be in total?
const itemCount = differenceInCalendarDays(addYears(new Date(), 2), epoch); // spans 4 years in total

type OnItemsRenderedFn = (itemsRendered: ListOnItemsRenderedProps) => void;
export interface VirtualizerProps<T extends ComponentType<React.PropsWithChildren<ListChildComponentProps>>> {
    columnWidth?: number;
    ColumnComponent: T;
    infobarChildren?: ReactNode;
    columnData?: ComponentProps<T>['data'];
    onItemsRendered?: OnItemsRenderedFn;
    itemHeight: number;
    isItemLoaded: (index: number) => boolean;
    loadMoreItems: (startIndex: number, stopIndex: number) => Promise<void>;
    innerRef?: RefObject<HTMLDivElement>;
    infiniteLoaderRef?: RefObject<InfiniteLoader>;
    children?: ReactNode;
    initialIndex?: number;
}

function Virtualizer<T extends ComponentType<React.PropsWithChildren<ListChildComponentProps>>>(
    props: VirtualizerProps<T>
) {
    const {
        itemHeight,
        isItemLoaded,
        loadMoreItems,
        ColumnComponent,
        columnData,
        innerRef,
        children,
        infobarChildren,
        initialIndex = todayIndex,
        infiniteLoaderRef,
    } = props;
    const containerRef = useRef<HTMLDivElement>(null);
    const innerContainerRef = useRef<HTMLDivElement>(null);
    const listRef = useRef<FixedSizeList | null>(null);
    const deviceSize = useDeviceSize();
    const columnWidth = props.columnWidth ?? (deviceSize === 'mobile' ? 200 : 128);
    const prevItemsRendered = useRef<ListOnItemsRenderedProps | null>(null);
    useScrollBoost({
        direction: 'horizontal',
        friction: 0.2,
        scrollMode: 'native',
        viewportRef: containerRef,
        shouldScroll: (_, event) =>
            // we don't want to scroll when creating a lockout
            !(event.target as HTMLDivElement)?.dataset.date,
        onUpdate: ({ isDragging }) => {
            if (!containerRef.current) {
                return;
            }
            if (isDragging && containerRef.current?.style.cursor !== 'grabbing') {
                containerRef.current.style.cursor = 'grabbing';
            } else if (!isDragging && containerRef.current?.style.cursor !== 'grab') {
                containerRef.current.style.cursor = 'grab';
            }
        },
    });
    const { scrollTo, scrollBy } = useScrollers(containerRef, listRef, columnWidth);

    const initialScrollOffset = initialIndex * columnWidth;
    const [activeMonth, setActiveMonth] = useState(+addDays(epoch, initialIndex));

    const onScroll = useCallback(
        ({ scrollOffset }: ListOnScrollProps) => {
            const days = Math.floor(scrollOffset / columnWidth);
            const activeDay = addDays(epoch, days);
            const newActiveMonth = startOfMonth(activeDay);
            if (+activeMonth !== +newActiveMonth) {
                setActiveMonth(+newActiveMonth);
            }
        },
        [activeMonth, columnWidth]
    );

    const scrollData = useMemo(
        () => ({ scrollTo, columnWidth, itemsRendered: prevItemsRendered, scrollBy, initialIndex }),
        [columnWidth, initialIndex, scrollBy, scrollTo]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onItemsRendered = useCallback(
        debounce((itemProps: ListOnItemsRenderedProps) => {
            // performance optimization: this callback renders too often, values seem
            // to flip back and forth while scrolling. When both visible start and stop
            // change, that seems to be when the callback should have been triggered.
            if (
                itemProps.visibleStartIndex !== prevItemsRendered.current?.visibleStartIndex &&
                itemProps.visibleStopIndex !== prevItemsRendered.current?.visibleStopIndex
            ) {
                props.onItemsRendered?.(itemProps);
            }
            prevItemsRendered.current = itemProps;
        }),
        [props.onItemsRendered]
    );

    const infobarContextValue = useMemo(() => ({ activeMonth, minHeight: itemHeight }), [activeMonth, itemHeight]);

    return (
        <VirtualizerScrollContext.Provider value={scrollData}>
            <Page>
                <PricesNav />
                <Grid>
                    <InfoBarContext.Provider value={infobarContextValue}>{infobarChildren}</InfoBarContext.Provider>
                    <Overview>
                        <AutoSizer disableHeight>
                            {size => (
                                <InfiniteLoader
                                    isItemLoaded={isItemLoaded}
                                    // as we depend on deviceSize we need to wait until its known to load in the correct index range
                                    loadMoreItems={!deviceSize ? () => {} : loadMoreItems}
                                    itemCount={itemCount}
                                    minimumBatchSize={STEP}
                                    ref={infiniteLoaderRef}
                                >
                                    {({ ref, onItemsRendered: infinityOnItemsRendered }) => (
                                        <FixedSizeListContainer>
                                            <FixedSizeList
                                                // little hack to get a new instance when the columnWidth changes (columnWidth could depend on the size of the device)
                                                key={columnWidth}
                                                ref={composeRefs(ref, listRef)}
                                                outerRef={containerRef}
                                                innerRef={composeRefs(innerContainerRef, innerRef)}
                                                initialScrollOffset={initialScrollOffset}
                                                itemCount={itemCount}
                                                itemData={columnData}
                                                height={itemHeight}
                                                itemSize={columnWidth}
                                                overscanCount={7}
                                                width={size.width}
                                                layout="horizontal"
                                                onScroll={onScroll}
                                                onItemsRendered={items => {
                                                    infinityOnItemsRendered(items);
                                                    onItemsRendered(items);
                                                }}
                                            >
                                                {ColumnComponent}
                                            </FixedSizeList>
                                        </FixedSizeListContainer>
                                    )}
                                </InfiniteLoader>
                            )}
                        </AutoSizer>
                    </Overview>
                </Grid>
                {children}
            </Page>
        </VirtualizerScrollContext.Provider>
    );
}

export default Virtualizer;
const FixedSizeListContainer = styled.div`
    & > div {
        scrollbar-width: none;
        scrollbar-color: transparent transparent;
        min-height: 100%;
        overflow-y: hidden;

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: transparent;
        }

        ::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }
    }
`;
const Page = styled.div`
    height: calc(100vh - ${({ theme }) => theme.heights.nav + theme.heights.unitNav}px);
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-x: hidden;
    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        overflow-x: unset;
    }
`;

const Grid = styled.div`
    display: flex;
    flex: 1;
`;

const Overview = styled.div`
    flex: 1;

    > div {
        height: 100%;
    }

    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        margin-left: -100vw;
    }
`;
