import SortableImages from '@oberoninternal/travelbase-ds/components/form/SortableImages';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import { UploadImageField } from '@oberoninternal/travelbase-ds/components/form/UploadImage';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { isBlob } from '@oberoninternal/travelbase-ds/utils/isBlob';
import { Box } from '@rebass/grid';
import { FieldArray } from 'formik';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { imageDescriptions } from '../../../../constants/imageDescriptions';
import { Image } from '../../../../entities/Image';
import { AccommodationImageCategoryEnum } from '../../../../generated/resetGraphql';
import useUpload from '../../../../hooks/useUpload';
import FieldSet from '../../../atoms/FieldSet';
import FieldSetHint from '../../../atoms/FieldSetHint';
import FieldHeading from '../../../molecules/FieldHeading';
import LabelBox from '../../../molecules/LabelBox';
import FormScreen from '../../../organisms/FormScreen';
import { imagesValidation } from '../../../../utils/validations/imagesValidation';
import { getImageFormError, transformImage } from '../../../../utils/imageFormUtils';
import { ImageContent } from '../../../../entities/ImageContent';
import { MAX_UPLOAD_IMAGES } from '../../../../constants/imageRequirements';
import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';
import validateImageResolution from '../../../../utils/validateImageResolution';
import brandConfig from '../../../../constants/brandConfig';
import { FormattedMessage, useIntl } from 'react-intl';
import useIsBackofficeuser from '../../../../hooks/useIsBackofficeuser';

export interface OtherImage extends Image {
    description: AccommodationImageCategoryEnum;
}

export const imageFragment = gql`
    fragment Image on AccommodationImage {
        id
        size
        url
        dimensions
        position
        category
        previewUrl
    }
`;

interface Values {
    mainImage: Image | null;
    listImage: Image | null;
    images: Array<OtherImage | null>;
}

interface RentalUnitValues extends Values {
    fullCircleImageUrl: Maybe<string>;
    videoUrl: Maybe<string>;
}

type AccommodationValues = Values;
interface Props {
    isCompact?: boolean;
    showCaptionSelect?: boolean;
    content: {
        header: ImageContent;
        mainImage: ImageContent;
        listImage: ImageContent;
        images: ImageContent;
    };
}

interface RentalUnitProps extends Props {
    level: 'rentalUnit';
    initialValues: RentalUnitValues;
    handleSubmit: (input: RentalUnitValues) => Promise<void>;
    content: Props['content'] & { fullCircleImage: ImageContent; video: ImageContent };
}

interface AccommodationProps extends Props {
    level: 'accommodation';
    initialValues: AccommodationValues;
    handleSubmit: (input: AccommodationValues) => Promise<void>;
}

const options = Object.entries(imageDescriptions).map(([key, val]) => ({
    label: val,
    value: key as AccommodationImageCategoryEnum,
}));

export const accommodationOptionsEnum: AccommodationImageCategoryEnum[] = [
    AccommodationImageCategoryEnum.Front,
    AccommodationImageCategoryEnum.Entrance,
    AccommodationImageCategoryEnum.Facilities,
    AccommodationImageCategoryEnum.Surroundings,
    AccommodationImageCategoryEnum.FloorPlan,
    AccommodationImageCategoryEnum.Other,
];

export const rentalUnitOptionsEnum: AccommodationImageCategoryEnum[] = [
    AccommodationImageCategoryEnum.Front,
    AccommodationImageCategoryEnum.LivingRoom,
    AccommodationImageCategoryEnum.Kitchen,
    AccommodationImageCategoryEnum.Bedroom,
    AccommodationImageCategoryEnum.Bathroom,
    AccommodationImageCategoryEnum.Outdoor,
    AccommodationImageCategoryEnum.View,
    AccommodationImageCategoryEnum.Facilities,
    AccommodationImageCategoryEnum.FloorPlan,
    AccommodationImageCategoryEnum.Other,
];

export type ImageLevelType = 'accommodation' | 'rentalUnit' | undefined;

const filterOptionsByLevel = (level: ImageLevelType) =>
    options.filter(option => {
        if (level === 'accommodation') {
            return accommodationOptionsEnum.includes(option.value);
        }
        if (level === 'rentalUnit') {
            return rentalUnitOptionsEnum.includes(option.value);
        }
        return option;
    });

const ImagesFormScreen: FC<React.PropsWithChildren<RentalUnitProps | AccommodationProps>> = ({
    initialValues,
    isCompact,
    ...props
}) => {
    const isBackofficeUser = useIsBackofficeuser();
    const intl = useIntl();
    const { formatMessage } = intl;
    const validationSchema = imagesValidation('accommodation', intl);

    const upload = useUpload();
    return (
        <>
            <FormScreen
                initialValues={{ ...initialValues, type: props.level }}
                validationSchema={validationSchema}
                handleSubmit={async values => {
                    let allImages: Array<Image | null> = [];

                    const mainImage = values.mainImage?.file ? transformImage(values.mainImage) : null;
                    const listImage = values.listImage?.file ? transformImage(values.listImage) : null;
                    const images = values.images
                        .filter((image): image is OtherImage => !!image?.file)
                        .map(transformImage);
                    allImages = allImages.concat([...images, mainImage]);

                    if (props.level === 'accommodation') {
                        await props.handleSubmit({ images, mainImage, listImage });
                    } else if (props.level === 'rentalUnit' && 'fullCircleImageUrl' in values) {
                        const { fullCircleImageUrl, videoUrl } = values;

                        allImages.push(listImage);
                        await props.handleSubmit({ images, listImage, mainImage, fullCircleImageUrl, videoUrl });
                    }

                    // to avoid memory leaks we need to revoke the blob files
                    allImages.forEach(
                        image => image?.file && isBlob(image.file) && URL.revokeObjectURL(image.file.preview)
                    );
                }}
            >
                {({ values, setFieldValue, errors, touched }) => (
                    <>
                        <Title>{props.content.header.title}</Title>
                        <p>{props.content.header.description}</p>
                        <FieldHeading title={props.content.listImage.title}>
                            {props.content.listImage.description}
                        </FieldHeading>
                        <FieldSet>
                            <LabelBox>
                                <Label>
                                    <FormattedMessage defaultMessage="Stel je lijstfoto in" />
                                </Label>
                                <FieldSetHint>
                                    <FormattedMessage defaultMessage="Verplicht <br /> Maximaal 25MB" />
                                </FieldSetHint>
                            </LabelBox>
                            <Box width={[1, null, 1 / 2]}>
                                <Box mb={4}>
                                    <UploadImageField
                                        name="listImage.file"
                                        uploadActionHandler={(uploadId, file) =>
                                            'listImage' in values &&
                                            setFieldValue('listImage', {
                                                ...values.listImage,
                                                imageId: undefined,
                                                file,
                                                uploadId,
                                            })
                                        }
                                        handleUpload={upload}
                                        error={getImageFormError('listImage', errors, touched)}
                                        variant="main"
                                        previewUrl={values.listImage?.previewUrl}
                                        ratio={2 / 3}
                                        validateImage={image => {
                                            if (values.listImage?.file && image) {
                                                if (isBlob(values.listImage.file)) {
                                                    const { naturalWidth, naturalHeight } = image;
                                                    return validateImageResolution(
                                                        naturalWidth,
                                                        naturalHeight,
                                                        'LISTIMAGE',
                                                        true
                                                    );
                                                }
                                            }

                                            if (values.listImage) {
                                                const [width, height] = values.listImage.dimensions;
                                                return validateImageResolution(width, height, 'LISTIMAGE');
                                            }

                                            return [];
                                        }}
                                    />
                                </Box>
                            </Box>
                        </FieldSet>
                        {(!brandConfig.hasCheckoutOnly || isBackofficeUser) && (
                            <>
                                <FieldHeading title={props.content.mainImage.title}>
                                    {props.content.mainImage.description}
                                </FieldHeading>
                                <FieldSet>
                                    <LabelBox>
                                        <Label>
                                            {/* Stel je hoofdfoto in */}
                                            <FormattedMessage defaultMessage="Stel je hoofdfoto in" />
                                        </Label>
                                        <FieldSetHint>
                                            {/* Maximaal 25MB */}

                                            <FormattedMessage defaultMessage="Maximaal 25MB" />
                                        </FieldSetHint>
                                    </LabelBox>
                                    <Box width={[1, null, 2 / 3]}>
                                        <Box mb={4}>
                                            <UploadImageField
                                                name="mainImage.file"
                                                uploadActionHandler={(uploadId, file) =>
                                                    setFieldValue('mainImage', {
                                                        ...values.mainImage,
                                                        id: undefined,
                                                        file,
                                                        uploadId,
                                                    })
                                                }
                                                handleUpload={upload}
                                                variant="main"
                                                previewUrl={values.mainImage?.previewUrl}
                                                ratio={9 / 16}
                                                error={getImageFormError('mainImage', errors, touched)}
                                                validateImage={image => {
                                                    if (
                                                        image &&
                                                        values.mainImage?.file &&
                                                        isBlob(values.mainImage.file)
                                                    ) {
                                                        const { naturalWidth, naturalHeight } = image;
                                                        return validateImageResolution(
                                                            naturalWidth,
                                                            naturalHeight,
                                                            'MAINIMAGE',
                                                            true
                                                        );
                                                    }

                                                    if (values.mainImage) {
                                                        const [width, height] = values.mainImage.dimensions;
                                                        return validateImageResolution(width, height, 'MAINIMAGE');
                                                    }

                                                    return [];
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </FieldSet>
                                <FieldHeading title={props.content.images.title}>
                                    {props.content.images.description}
                                </FieldHeading>
                                <Box pl={[null, null, '26.4rem']}>
                                    <FieldArray name="images">
                                        {helpers => (
                                            <SortableImages
                                                options={isCompact ? options : filterOptionsByLevel(values.type)}
                                                name="images"
                                                helpers={helpers}
                                                handleUpload={upload}
                                                maxNumber={MAX_UPLOAD_IMAGES}
                                                validateImage={image => {
                                                    const imageInValues = values.images.find(
                                                        img => img?.previewUrl === image?.src
                                                    )?.file;

                                                    if (image && imageInValues && isBlob(imageInValues)) {
                                                        const { naturalWidth, naturalHeight } = image;
                                                        return validateImageResolution(
                                                            naturalWidth,
                                                            naturalHeight,
                                                            'DETAILIMAGE',
                                                            true
                                                        );
                                                    }

                                                    if (imageInValues) {
                                                        const imageDimensions =
                                                            values.images.find(img => img?.previewUrl === image?.src)
                                                                ?.dimensions ?? [];
                                                        const [width, height] = imageDimensions;
                                                        return validateImageResolution(width, height, 'DETAILIMAGE');
                                                    }

                                                    return [];
                                                }}
                                            />
                                        )}
                                    </FieldArray>
                                </Box>
                                {props.level === 'rentalUnit' && (
                                    <>
                                        <FieldHeading title={props.content.fullCircleImage.title}>
                                            {props.content.fullCircleImage.description}
                                        </FieldHeading>
                                        <FieldSet>
                                            <LabelBox>
                                                <Label htmlFor="fullCircleImageUrl">
                                                    <FormattedMessage defaultMessage="URL van foto" />
                                                </Label>
                                            </LabelBox>
                                            <Box width={1}>
                                                <TextInputField
                                                    id="fullCircleImageUrl"
                                                    placeholder={formatMessage({
                                                        defaultMessage: 'URL van foto',
                                                    })}
                                                    name="fullCircleImageUrl"
                                                />
                                            </Box>
                                        </FieldSet>

                                        <FieldHeading title={props.content.video.title}>
                                            {props.content.video.description}
                                        </FieldHeading>
                                        <FieldSet>
                                            <LabelBox>
                                                <Label htmlFor="videoUrl">
                                                    <FormattedMessage defaultMessage="URL van video" />
                                                </Label>
                                            </LabelBox>
                                            <Box width={1}>
                                                <TextInputField
                                                    id="videoUrl"
                                                    placeholder={formatMessage({
                                                        defaultMessage: 'URL van video',
                                                    })}
                                                    name="videoUrl"
                                                />
                                            </Box>
                                        </FieldSet>
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </FormScreen>
        </>
    );
};

export default ImagesFormScreen;
