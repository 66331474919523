import React, { forwardRef, Ref } from 'react';
import { generatePath, NavLinkProps, useRouteMatch } from 'react-router-dom';
import { UnitParams } from '../../entities/UnitParams';
import { AccPickerEntryFragment } from '../../generated/graphql';
import AccPicker from '../molecules/AccPicker';
import { createPriceRoutingFilter } from './Navigation';
import { unitRoutes } from '../../utils/routes';
import NavigationWrapper from '../atoms/NavigationWrapper';
import NavigationDesktopMenu from '../atoms/NavigationDesktopMenu';
import { useIntl } from 'react-intl';

export interface UnitNavigationProps {
    accPickerEntries: AccPickerEntryFragment[];
    datePricingStartDate?: Date;
    datePricingEndDate?: Date;
    className?: string;
}

const UnitNavigation = (
    { accPickerEntries, datePricingStartDate, datePricingEndDate, className }: UnitNavigationProps,
    ref: Ref<HTMLDivElement>
) => {
    const {
        params: { partnerId, ...params },
    } = useRouteMatch<UnitParams>();

    const routeFilter = createPriceRoutingFilter(datePricingStartDate, datePricingEndDate);
    const unitSlug = params.unitSlug ?? accPickerEntries[0]?.rentalUnits[0]?.slug;
    const { locale, formatMessage } = useIntl();
    return (
        <NavigationWrapper className={className} ref={ref}>
            <AccPicker data={accPickerEntries} />
            <NavigationDesktopMenu
                variant="dark"
                links={unitRoutes.filter(routeFilter).map(
                    ({ title: name, source }) =>
                        ({
                            to: generatePath(`/${locale}/partner/:partnerId/unit/:unitSlug/${source}`, {
                                unitSlug,
                                partnerId,
                            }),
                            children: formatMessage(name),
                        } as NavLinkProps)
                )}
            />
        </NavigationWrapper>
    );
};

export default forwardRef(UnitNavigation);
