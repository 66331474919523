import { Box, Flex } from '@rebass/grid';
import gql from 'graphql-tag';
import { FieldArray } from 'formik';
import React, { FC } from 'react';
import { AttributeCategory, AttributeCategoryFragment } from '../../generated/graphql';
import Checkbox from '@oberoninternal/travelbase-ds/components/form/Checkbox';
import Radio from '@oberoninternal/travelbase-ds/components/form/Radio';
import { InfoPageProps } from '../pages/unit/Info';
import FieldSet from '../atoms/FieldSet';
import LabelBox from './LabelBox';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import FieldSetHint from '../atoms/FieldSetHint';
import { Seperator } from '../atoms/Seperator';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';

type UnitAttribute = Pick<AttributeCategoryFragment, 'unitAttributes'>['unitAttributes'][number] & {
    isExcluded?: boolean;
};

interface Props extends InfoPageProps {
    category: Pick<AttributeCategory, 'allowMultiple' | 'name'> & {
        accoAttributes: UnitAttribute[];
        unitAttributes: UnitAttribute[];
    };
    attributeValues: Array<{
        id: string;
    }>;
}

export const attributeCategoryFragment = gql`
    fragment AttributeCategory on AttributeCategory {
        id
        allowMultiple
        name
        unitAttributes: attributes(type: RENTAL_UNIT, subtype: $subtype) {
            id
            name
            type
        }
        accoAttributes: attributes(type: ACCOMMODATION) {
            id
            name
            type
        }
    }
`;

const CategoryAttributes: FC<React.PropsWithChildren<Props>> = ({
    category: { unitAttributes, accoAttributes, allowMultiple, name },
    attributeValues,
    accommodationType,
}) => {
    const attributes = {
        accommodation: accoAttributes,
        rentalUnit: unitAttributes,
        compact: [...unitAttributes, ...accoAttributes],
    }[accommodationType];
    if (attributes.length === 0) {
        return null;
    }

    return (
        <Container>
            <FieldSet>
                <LabelBox>
                    <Label>{name}</Label>
                    {!allowMultiple && (
                        <FieldSetHint>
                            <FormattedMessage defaultMessage="Je kan hier slechts één optie kiezen" />
                        </FieldSetHint>
                    )}
                </LabelBox>
                <Flex width={1} flexWrap="wrap">
                    <FieldArray name="attributes">
                        {helpers =>
                            attributes?.map(attribute => {
                                if (!attribute || !attribute.name) {
                                    return null;
                                }

                                const { name: attrName, id: attrId, isExcluded } = attribute;

                                const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (e.target.checked) {
                                        switch (e.target.type) {
                                            case 'checkbox':
                                                helpers.push({ id: attrId });
                                                break;
                                            case 'radio':
                                                // First check if there's already a radiobutton selected for this category
                                                for (const ctgAttr of attributes) {
                                                    if (
                                                        ctgAttr &&
                                                        attributeValues.some(attr => attr.id === ctgAttr.id)
                                                    ) {
                                                        const idx = attributeValues.findIndex(
                                                            attr => attr.id === ctgAttr.id
                                                        );
                                                        helpers.replace(idx, {
                                                            id: attrId,
                                                        });
                                                        return;
                                                    }
                                                }
                                                helpers.push({ id: attrId });
                                                break;
                                            default:
                                                break;
                                        }
                                    } else {
                                        const idx = attributeValues.findIndex(attr => attr.id === attrId);
                                        helpers.remove(idx);
                                    }
                                };

                                const fieldProps = {
                                    id: attrName,
                                    name: attrName,
                                    checked: attributeValues.some(attr => attr.id === attrId),
                                    onChange: onChangeHandler,
                                };
                                return (
                                    <Box width={[1, 1, 1 / 2, 1 / 3]} mt={4} mb={3} pr={2} key={`attr-${attrId}`}>
                                        {allowMultiple ? (
                                            <Checkbox {...fieldProps}>
                                                {attrName}
                                                {isExcluded && (
                                                    <>
                                                        <br />
                                                        <small>
                                                            <FormattedMessage defaultMessage="Dit kenmerk hoort bij een ander type accommodatie" />
                                                        </small>
                                                    </>
                                                )}
                                            </Checkbox>
                                        ) : (
                                            <Radio {...fieldProps}>
                                                {attrName}
                                                {isExcluded && (
                                                    <>
                                                        <br />
                                                        <small>
                                                            <FormattedMessage defaultMessage="Dit kenmerk hoort bij een ander type accommodatie" />
                                                        </small>
                                                    </>
                                                )}
                                            </Radio>
                                        )}
                                    </Box>
                                );
                            })
                        }
                    </FieldArray>
                </Flex>
            </FieldSet>
            <Box className="seperator" pl={[null, null, '26.4rem']}>
                <Seperator />
            </Box>
        </Container>
    );
};

export default CategoryAttributes;

const Container = styled.div`
    :last-of-type {
        div.seperator {
            display: none;
        }
    }
`;
