import { RentalUnitTypeEnum } from '../generated/graphql';

export interface UnitType {
    label: string;
    plural: string;
    value: RentalUnitTypeEnum;
}

const unitTypes: UnitType[] = [
    {
        label: 'Vakantiehuis',
        plural: 'Vakantiehuizen',
        value: RentalUnitTypeEnum.Home,
    },
    {
        label: 'Kampeerplaats',
        plural: 'Kampeerplaatsen',
        value: RentalUnitTypeEnum.CampingPitch,
    },
    {
        label: 'Kamer',
        plural: 'Kamers',
        value: RentalUnitTypeEnum.Room,
    },
    {
        label: 'Tent',
        plural: 'Tenten',
        value: RentalUnitTypeEnum.Tent,
    },
    {
        label: 'Bed',
        plural: 'Bedden',
        value: RentalUnitTypeEnum.Bed,
    },
    {
        label: 'Camperplaats',
        plural: 'Camperplaatsen',
        value: RentalUnitTypeEnum.CamperSpot,
    },
    {
        label: 'Boot',
        plural: 'Boten',
        value: RentalUnitTypeEnum.Boat,
    },
];

export default unitTypes;
