import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// We want to scroll the page to the top when the pathname changes
const ScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => window.scrollTo(0, 0), [pathname]);
    return null;
};

export default ScrollToTop;
