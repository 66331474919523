import React, { FC } from 'react';
import gql from 'graphql-tag';
import * as Yup from 'yup';
import { ActivityParams } from '../../../../entities/ActivityParams';
import FormScreen from '../../../organisms/FormScreen';
import FieldHeading from '../../../molecules/FieldHeading';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import Loading from '../../../organisms/Loading';
import TranslationInput from '../../../molecules/TranslationInput';
import { useCompanyDescriptionQuery, useEditCompanyDescriptionsMutation } from '../../../../generated/graphql';
import { NotFoundError } from '../../../../entities/NotFoundError';
import omitEqualValues from '../../../../utils/omitEqualValues';
import { FormattedMessage, useIntl } from 'react-intl';

export const descriptionFragment = gql`
    fragment CompanyDescription on Company {
        id
        descriptionNL: description(locale: "nl")
        descriptionDE: description(locale: "de")
        descriptionEN: description(locale: "en")
        descriptionPending
        descriptionParticularsNL: descriptionParticulars(locale: "nl")
        descriptionParticularsDE: descriptionParticulars(locale: "de")
        descriptionParticularsEN: descriptionParticulars(locale: "en")
        descriptionParticularsPending
    }
`;

export const query = gql`
    query CompanyDescription($companySlug: String!) {
        company(slug: $companySlug) {
            ...CompanyDescription
        }
    }
    ${descriptionFragment}
`;

export const editDescriptionsMutation = gql`
    mutation EditCompanyDescriptions($input: EditCompanyDescriptionInput!) {
        editCompanyDescription(input: $input) {
            company {
                ...CompanyDescription
            }
        }
    }
`;

const CompanyDescription: FC<React.PropsWithChildren<ActivityParams>> = ({ companySlug }) => {
    const slug = companySlug ?? '';
    const { formatMessage } = useIntl();

    const companyDescriptionValidation = Yup.object().shape({
        description: Yup.string().required(formatMessage({ defaultMessage: 'Dit veld mag niet leeg zijn' })),
    });

    const { loading, data } = useCompanyDescriptionQuery({ variables: { companySlug: slug } });
    const [mutate] = useEditCompanyDescriptionsMutation();

    if (loading) {
        return <Loading />;
    }

    if (!data || !data.company) {
        throw new NotFoundError();
    }

    const {
        company: {
            id,
            descriptionDE,
            descriptionEN,
            descriptionNL,
            descriptionPending,
            descriptionParticularsDE,
            descriptionParticularsEN,
            descriptionParticularsNL,
            descriptionParticularsPending,
        },
    } = data;

    const initialValues = {
        description: descriptionPending ?? descriptionNL ?? '',
        descriptionParticulars: descriptionParticularsPending ?? descriptionParticularsNL ?? '',
    };

    return (
        <FormScreen
            validationSchema={companyDescriptionValidation}
            initialValues={initialValues}
            handleSubmit={async values => {
                await mutate({
                    variables: {
                        input: {
                            companyId: id,
                            ...omitEqualValues(values, initialValues, ['description', 'descriptionParticulars']),
                        },
                    },
                });
            }}
        >
            {() => (
                <>
                    <Pagehead title={formatMessage({ defaultMessage: 'Beschrijving' })}>
                        <FormattedMessage defaultMessage="Hier beheer je de verschillende beschrijvingen van je onderneming." />
                    </Pagehead>

                    <FieldHeading title={formatMessage({ defaultMessage: 'Algemeen' })}>
                        <FormattedMessage defaultMessage="Dit is de algemene beschrijving van de onderneming." />
                    </FieldHeading>
                    <TranslationInput
                        placeholder={formatMessage({ defaultMessage: 'Omschrijving van de indeling' })}
                        name="description"
                        label={formatMessage({ defaultMessage: 'Tekst invoeren of aanpassen' })}
                        hint={formatMessage({
                            defaultMessage:
                                'Plaats hier de tekst(aanpassing) die je op de website van de VVV wilt tonen. Wij verzorgen de plaatsing en vertaling binnen enkele dagen.',
                        })}
                        data={{
                            nl: descriptionNL,
                            de: descriptionDE,
                            en: descriptionEN,
                            pending: descriptionPending,
                        }}
                    />

                    <FieldHeading title={formatMessage({ defaultMessage: 'Belangrijk om te weten' })}>
                        <FormattedMessage defaultMessage="Hier beschrijf je de specifieke informatie die van belang is voor bezoekers." />
                    </FieldHeading>
                    <TranslationInput
                        placeholder={formatMessage({ defaultMessage: 'Omschrijving van de indeling' })}
                        name="descriptionParticulars"
                        label={formatMessage({ defaultMessage: 'Tekst invoeren of aanpassen' })}
                        hint={formatMessage({
                            defaultMessage:
                                'Plaats hier de tekst(aanpassing) die je op de website van de VVV wilt tonen. Wij verzorgen de plaatsing en vertaling binnen enkele dagen.',
                        })}
                        data={{
                            nl: descriptionParticularsNL,
                            de: descriptionParticularsDE,
                            en: descriptionParticularsEN,
                            pending: descriptionParticularsPending,
                        }}
                    />
                </>
            )}
        </FormScreen>
    );
};

export default CompanyDescription;
