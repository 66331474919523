import React, { FC } from 'react';
import { InfoPageProps } from '../Info';
import FormScreen from '../../../organisms/FormScreen';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import FieldHeading from '../../../molecules/FieldHeading';
import TranslationInput from '../../../molecules/TranslationInput';
import gql from 'graphql-tag';
import { useEditRentalUnitDescriptionsMutation, useUnitDescriptionQuery } from '../../../../generated/graphql';
import { UnitParams } from '../../../../entities/UnitParams';
import Loading from '../../../organisms/Loading';
import { NotFoundError } from '../../../../entities/NotFoundError';
import omitEqualValues from '../../../../utils/omitEqualValues';
import { unitDescriptionValidation } from './unitDescriptionValidation';
import { FormattedMessage, useIntl } from 'react-intl';
import useIsBackofficeuser from '../../../../hooks/useIsBackofficeuser';
import brandConfig from '../../../../constants/brandConfig';

export const descriptionFragment = gql`
    fragment UnitDescription on RentalUnit {
        id
        descriptionLayoutNL: descriptionLayout(locale: "nl")
        descriptionLayoutDE: descriptionLayout(locale: "de")
        descriptionLayoutEN: descriptionLayout(locale: "en")
        descriptionLayoutPending
        descriptionExtrasNL: descriptionExtras(locale: "nl")
        descriptionExtrasDE: descriptionExtras(locale: "de")
        descriptionExtrasEN: descriptionExtras(locale: "en")
        descriptionExtrasPending
        descriptionParticularsNL: descriptionParticulars(locale: "nl")
        descriptionParticularsDE: descriptionParticulars(locale: "de")
        descriptionParticularsEN: descriptionParticulars(locale: "en")
        descriptionParticularsPending
    }
`;

export const query = gql`
    query UnitDescription($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            ...UnitDescription
        }
    }
    ${descriptionFragment}
`;

export const editDescriptionsMutation = gql`
    mutation EditRentalUnitDescriptions($input: EditRentalUnitDescriptionsInput!) {
        editRentalUnitDescriptions(input: $input) {
            rentalUnit {
                ...UnitDescription
            }
        }
    }
`;

const UnitDescription: FC<React.PropsWithChildren<UnitParams & InfoPageProps>> = ({ unitSlug, partnerId }) => {
    const { data, loading } = useUnitDescriptionQuery({ variables: { unitSlug } });
    const [mutate] = useEditRentalUnitDescriptionsMutation();
    const intl = useIntl();
    const { formatMessage } = intl;
    const isBackofficeUser = useIsBackofficeuser(partnerId);

    if (loading) {
        return <Loading />;
    }

    if (!data || !data.rentalUnit) {
        throw new NotFoundError();
    }
    const {
        rentalUnit: {
            id,
            descriptionLayoutDE,
            descriptionLayoutEN,
            descriptionLayoutNL,
            descriptionLayoutPending,
            descriptionExtrasNL,
            descriptionExtrasEN,
            descriptionExtrasDE,
            descriptionParticularsDE,
            descriptionParticularsEN,
            descriptionParticularsNL,
            descriptionParticularsPending,
            descriptionExtrasPending,
        },
    } = data;

    const initialValues = {
        descriptionLayout: descriptionLayoutPending ?? descriptionLayoutNL ?? '',
        descriptionExtras: descriptionExtrasPending ?? descriptionExtrasNL ?? '',
        descriptionParticulars: descriptionParticularsPending ?? descriptionParticularsNL ?? '',
    };
    return (
        <FormScreen
            validationSchema={!brandConfig.hasCheckoutOnly ? unitDescriptionValidation(intl) : undefined}
            handleSubmit={async values => {
                await mutate({
                    variables: {
                        input: {
                            rentalUnitId: id,
                            ...omitEqualValues(values, initialValues, [
                                'descriptionExtras',
                                'descriptionParticulars',
                                'descriptionLayout',
                            ]),
                        },
                    },
                });
            }}
            initialValues={initialValues}
        >
            {() => (
                <>
                    <Pagehead title={formatMessage({ defaultMessage: 'Beschrijving' })}>
                        <FormattedMessage defaultMessage="Hier beheer je verschillende beschrijvingen van je accommodatie." />
                    </Pagehead>
                    {(!brandConfig.hasCheckoutOnly || isBackofficeUser) && (
                        <>
                            <FieldHeading title={formatMessage({ defaultMessage: 'Indeling' })}>
                                <FormattedMessage defaultMessage="Dit is de beschrijving van de indeling, inrichting en buitenruimte van je accommodatie." />
                            </FieldHeading>
                            <TranslationInput
                                placeholder={formatMessage({ defaultMessage: 'Omschrijving van de indeling' })}
                                name="descriptionLayout"
                                label={formatMessage({ defaultMessage: 'Tekst invoeren of aanpassen' })}
                                hint={formatMessage({
                                    defaultMessage:
                                        'Plaats hier de tekst(aanpassing) die je op de website van de VVV wilt tonen. Wij verzorgen de plaatsing en vertaling binnen enkele dagen.',
                                })}
                                data={{
                                    nl: descriptionLayoutNL,
                                    de: descriptionLayoutDE,
                                    en: descriptionLayoutEN,
                                    pending: descriptionLayoutPending,
                                }}
                            />
                            <FieldHeading title={formatMessage({ defaultMessage: 'Bijzonder aan deze accommodatie' })}>
                                <FormattedMessage
                                    defaultMessage="Hier worden de zaken vermeld die je accommodatie extra bijzonder maken. Denk bijvoorbeeld aan
                            wellness faciliteiten, speeltoestellen voor de kinderen of het gratis gebruik maken van een
                            strandhuisje door jouw gasten."
                                />
                            </FieldHeading>
                            <TranslationInput
                                placeholder={formatMessage({
                                    defaultMessage: `Wat voor extra's kan de gast verwachten`,
                                })}
                                name="descriptionExtras"
                                label={formatMessage({ defaultMessage: 'Tekst invoeren of aanpassen' })}
                                hint={formatMessage({
                                    defaultMessage:
                                        'Plaats hier de tekst(aanpassing) die je op de website van de VVV wilt tonen. Wij verzorgen de plaatsing en vertaling binnen enkele dagen.',
                                })}
                                data={{
                                    nl: descriptionExtrasNL,
                                    de: descriptionExtrasDE,
                                    pending: descriptionExtrasPending,
                                    en: descriptionExtrasEN,
                                }}
                            />
                        </>
                    )}
                    <FieldHeading title={formatMessage({ defaultMessage: 'Belangrijk om te weten' })}>
                        <FormattedMessage
                            defaultMessage="Hier beschrijf je de specifieke voorwaarden die van belang zijn voor bepaalde doelgroep bij het
                        boeken van je accommodatie. Denk aan een gewenste minimale leeftijd van de huurder, een steile
                        trap naar de eerste verdieping of een niet omheinde tuin of sloot in de tuin."
                        />
                    </FieldHeading>
                    <TranslationInput
                        placeholder={formatMessage({
                            defaultMessage: 'Omschrijving van de bijzonderheden',
                        })}
                        name="descriptionParticulars"
                        label={formatMessage({ defaultMessage: 'Tekst invoeren of aanpassen' })}
                        hint={formatMessage({
                            defaultMessage:
                                'Plaats hier de tekst(aanpassing) die je op de website van de VVV wilt tonen. Wij verzorgen de plaatsing en vertaling binnen enkele dagen.',
                        })}
                        data={{
                            nl: descriptionParticularsNL,
                            de: descriptionParticularsDE,
                            pending: descriptionParticularsPending,
                            en: descriptionParticularsEN,
                        }}
                    />
                </>
            )}
        </FormScreen>
    );
};

export default UnitDescription;
