import React, { FC } from 'react';

const ArrowDown: FC<React.PropsWithChildren<unknown>> = ({ ...props }) => (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.664 9.89568L16.3047 8.53632L11.904 12.96L7.5034 8.53632L6.14404 9.89568L11.904 15.6557L17.664 9.89568Z"
            fill="currentColor"
        />
    </svg>
);

export default ArrowDown;
