
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ConnectorCredentialsConfig": [
      "MaxxtonCredentialsConfig",
      "MewsCredentialsConfig"
    ],
    "ConnectorRentalUnitConfig": [
      "MaxxtonResourceConfig",
      "MewsSpaceTypeConfig"
    ],
    "ConnectorSurchargeConfig": [
      "MaxxtonResourceConfig",
      "MewsProductConfig"
    ],
    "Image": [
      "AccommodationImage",
      "AccommodationListImage",
      "AccommodationMainImage",
      "ActivityImage",
      "ActivityListImage",
      "ActivityMainImage",
      "AvatarImage",
      "CompanyImage",
      "CompanyListImage",
      "CompanyLogoImage",
      "CompanyMainImage"
    ],
    "MissingVisitorRegistration": [
      "AllotmentLockout",
      "Booking"
    ],
    "PartnerBalanceItem": [
      "PartnerBalanceCorrection",
      "PartnerBalancePayment",
      "PartnerBookingInvoice",
      "PartnerTicketInvoice"
    ],
    "RateGroup": [
      "ActivityRateGroup",
      "CompanyRateGroup"
    ]
  }
};
      export default result;
    