import React, { SVGAttributes } from 'react';

export default function (props: SVGAttributes<SVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
            <path
                stroke="currentColor"
                strokeLinecap="square"
                strokeLinejoin="round"
                strokeWidth={1.478}
                d="M20.13 17.465v1.414A2.121 2.121 0 0 1 18.01 21H5.99a2.12 2.12 0 0 1-2.12-2.12v-1.415M12 17.044V3"
            />
            <path
                stroke="currentColor"
                strokeLinecap="square"
                strokeWidth={1.478}
                d="M17.174 12.609 12 17.783l-5.174-5.174"
            />
        </svg>
    );
}
