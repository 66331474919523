import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import { Box, BoxProps, Flex } from '@rebass/grid';
import range from 'lodash/range';
import React, { CSSProperties, FC, ReactElement, ReactNode, useMemo, memo } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components/macro';
import BigTableHeader from '../components/atoms/BigTableHeader';
import Link from '../components/atoms/Link';
import { Maybe } from '../generated/graphql';
import { useRandom } from './useRandom';

export type CreateHeader = (headers: Array<string | ReactNode>) => ReactElement;

export type CreateRow = (
    to: string | undefined,
    style: CSSProperties,
    cells: Array<ReactElement | { text: string; subText?: string } | Maybe<string | number | undefined>>,
    onRowClick?: () => void
) => ReactElement;

export type CreateSkeletonRows = (amount?: number, style?: CSSProperties) => ReactElement;

export interface BigListUtils {
    createHeader: CreateHeader;
    createRow: CreateRow;
    createSkeletonRows: CreateSkeletonRows;
}

type Width = BoxProps['width'];

/**
 * A hook that can be used in combination with the BigList component to easily create a big list
 * with width consistency. Make sure the lengths of the arrays given to the util functions are the same as the widths
 * array.
 *
 * @param widths the widths of the columns. Make sure it's memoized if needed.
 */
const useBigList = (widths: Width[]): BigListUtils =>
    useMemo(() => {
        const validateArrayLength = (arr: unknown[]) => {
            if (arr.length !== widths.length) {
                throw new Error('The lengths of the arrays must be the same');
            }
        };
        return {
            createHeader: headers => {
                validateArrayLength(headers);
                return (
                    <BigTableHeader>
                        {headers.map((header, i) => (
                            <Box key={i} width={widths[i]}>
                                {typeof header === 'string' ? <Label>{header}</Label> : header}
                            </Box>
                        ))}
                    </BigTableHeader>
                );
            },
            createRow: (to, style, cells, onRowClick) => {
                validateArrayLength(cells);

                const rowContent = (
                    <Row onClick={onRowClick} alignItems="center">
                        {cells.map((cell, i) => (
                            <Box key={i} width={widths[i]}>
                                {typeof cell === 'string' || typeof cell === 'number' ? (
                                    <Body
                                        variant="small"
                                        style={{
                                            WebkitLineClamp: 3,
                                            textOverflow: 'ellipsis',
                                            display: '-webkit-box',
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        {cell}
                                    </Body>
                                ) : cell && 'text' in cell ? (
                                    <>
                                        <Body variant="small">{cell.text}</Body>
                                        {cell.subText && <SubText>{cell.subText} </SubText>}
                                    </>
                                ) : (
                                    cell
                                )}
                            </Box>
                        ))}
                    </Row>
                );

                if (!to) {
                    return <div style={style}>{rowContent}</div>;
                }

                return (
                    <StyledLink to={to} style={style}>
                        {rowContent}
                    </StyledLink>
                );
            },
            createSkeletonRows: (amount, style) => (
                <>
                    {range(amount ?? 5).map(i => (
                        <SkeletonRow key={i} widths={widths} style={style} />
                    ))}
                </>
            ),
        };
    }, [widths]);

export default useBigList;

export const SkeletonRow: FC<React.PropsWithChildren<{ widths: Width[]; style?: CSSProperties }>> = memo(
    ({ style, widths }) => {
        const elementHeight = useRandom(20, 48);
        const elementWidth = useRandom(100, 150);
        return (
            <SkellRow width={1} justifyContent="space-between" style={style}>
                {/* The first cell contains unit details */}
                {widths.map((_, i) =>
                    i === 0 ? (
                        <SkellCell width={widths[i]} key={i}>
                            <Flex>
                                <Box px={3}>
                                    <Skeleton width={72} height={48} />
                                </Box>
                                <Skeleton width={elementWidth} height={elementHeight} />
                            </Flex>
                        </SkellCell>
                    ) : (
                        <SkellCell width={widths[i]} key={i}>
                            <Skeleton width={elementWidth} height={elementHeight} />
                        </SkellCell>
                    )
                )}
            </SkellRow>
        );
    },
    () => true
);

const StyledLink = styled(Link)`
    :hover {
        background: ${({ theme }) => theme.colors.neutral[10]};
    }
`;

const Row = styled(Flex)`
    > div:first-of-type {
        padding: 1rem;
    }

    > div {
        overflow: auto;
        padding-right: 1rem;
    }
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[20]};
    min-height: 100%;
`;

const SkellRow = styled(Flex)`
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[20]};
    width: 100%;
    justify-content: space-between;
`;

const SkellCell = styled(Box)`
    padding: ${({ theme }) => theme.spacing['40_Standard']} 0;
`;

const SubText = styled(Body)`
    color: ${({ theme }) => theme.colors.neutral[40]};
`;
