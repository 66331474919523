import styled, { css } from 'styled-components/macro';
import { Monthday } from '@oberoninternal/travelbase-ds/components/calendar/DayHeader';

// Note: isUnusable refers to either a disabled or unbookable state.
// disabled is when there are no prices yet, unbookable is when is the day is in the past.
export default styled.div<{ isUnusable: boolean }>`
    min-width: 12.8rem;
    border-right: 1px solid ${({ theme }) => theme.colors.neutral[20]};
    background: ${({ isUnusable, theme }) => (isUnusable ? theme.colors.neutral[10] : 'unset')};
    color: ${({ theme, isUnusable }) => (isUnusable ? theme.colors.neutral[30] : 'unset')};
    cursor: grab;

    ${({ isUnusable }) =>
        !isUnusable &&
        css`
            @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
                ${Monthday} span {
                    transition: transform 0.25s;
                }

                :hover ${Monthday} span {
                    transform: none;
                }

                :not(:hover) ${Monthday} span {
                    transition-delay: 0.25s;
                }

                ${Monthday} svg {
                    transition: opacity 0.25s;
                    margin-left: 0.4rem;
                    cursor: pointer;
                }

                :hover ${Monthday} svg {
                    opacity: 1;
                    transition-delay: 0.25s;
                }
            }
        `}
`;
