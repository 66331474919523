import styled from 'styled-components/macro';

type SeperatorVariant = 'small' | 'regular';

export const Seperator = styled.hr<{ variant?: SeperatorVariant }>`
    margin: ${({ variant = 'regular' }) => (variant === 'small' ? '3.6rem' : '6.8rem')} 0;
    height: 1px;
    background: ${({ theme }) => theme.colors.neutral[20]};
    border: none;

    @media (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        margin: 3.6rem 0;
    }
`;

export const SidebarSeperator = styled(Seperator)`
    margin: 0;
`;
