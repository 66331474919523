import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import { StyledButton } from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Body, { BodyInner } from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import format from 'date-fns/format';
import React, { createContext, FC, HTMLAttributes, ReactNode, useContext } from 'react';
import styled from 'styled-components/macro';
import { getDateOpts } from '../../constants/dateOpts';
import { PRICE_COLUMN_HEADER_HEIGHT } from '../../constants/priceRows';
import parseDate from '@oberoninternal/travelbase-ds/utils/parseDate';

import { CELL_HEIGHT } from './Cell';
import Cog from '@oberoninternal/travelbase-ds/components/figure/Cog';
import { Flex } from '@rebass/grid';
import { useSidebar } from '../../context/sidebar';
import { PricesRowVisibilityFragment, Scalars } from '../../generated/graphql';
import { FormattedMessage, useIntl } from 'react-intl';

export const InfoBarContext = createContext<{ minHeight: number; activeMonth: number } | null>(null);

interface Props extends HTMLAttributes<HTMLElement> {
    headerChildren?: ReactNode;
    rentalUnit?: PricesRowVisibilityFragment | null;
    start?: Scalars['Date']['output'];
    end?: Scalars['Date']['output'];
    rentalUnitId?: string;
}

const Infobar: FC<React.PropsWithChildren<Props>> = ({
    children,
    headerChildren,
    rentalUnit,
    rentalUnitId,
    start,
    end,
    ...rest
}) => {
    const context = useContext(InfoBarContext);
    const [, dispatch] = useSidebar();
    const { locale } = useIntl();
    const dateOpts = getDateOpts(locale as 'nl' | 'de' | 'en');
    if (!context) {
        throw new Error('The Infobar component cannot be rendered outside the Virtualizer component ');
    }
    const { activeMonth, minHeight } = context;
    return (
        <InfobarContainer minHeight={minHeight} {...rest}>
            <Header>
                <Flex justifyContent="space-between">
                    <div>
                        <Label variant="small">
                            <FormattedMessage defaultMessage="date" />
                        </Label>
                        <Body variant="regular">{format(parseDate(new Date(activeMonth)), 'yyyy', dateOpts)}</Body>
                    </div>
                    {rentalUnit && (
                        <div>
                            <Button
                                variant="outline"
                                onClick={() => {
                                    dispatch({
                                        type: 'show',
                                        data: {
                                            type: 'PRICING_CONFIG',
                                            showBaseStayPriceRow: !!rentalUnit?.showBaseStayPriceRow,
                                            showExtraPersonPriceRow: !!rentalUnit?.showExtraPersonPriceRow,
                                            showMinimumStayPriceRow: !!rentalUnit?.showMinimumStayPriceRow,
                                            showWeekPriceRow: !!rentalUnit?.showWeekPriceRow,
                                            showArrivalAllowedRow: !!rentalUnit?.showArrivalAllowedRow,
                                            showDepartureAllowedRow: !!rentalUnit?.showDepartureAllowedRow,
                                            showNightPriceRow: !!rentalUnit?.showNightPriceRow,
                                            showMinimumStayDurationRow: !!rentalUnit?.showMinimumStayDurationRow,
                                            start,
                                            end,
                                            rentalUnitId,
                                            onClose: () =>
                                                dispatch({
                                                    type: 'close',
                                                }),
                                        },
                                    });
                                }}
                            >
                                <Cog width="18" height="18" style={{ marginTop: '-3px' }} />
                            </Button>
                        </div>
                    )}
                </Flex>
                {headerChildren}
            </Header>

            {children}
        </InfobarContainer>
    );
};

export default Infobar;

export const InfobarCell = styled.div`
    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        flex-direction: row;
        position: relative;
        justify-content: flex-start;

        > button {
            padding: 2rem 1.5rem;
            margin: -1.8rem -0.7rem;
        }

        > ${Label} {
            color: ${({ theme }) => theme.colors.neutral[30]};
        }
    }

    padding: 1rem 1.6rem;
    min-height: ${CELL_HEIGHT}px;
    max-height: ${CELL_HEIGHT}px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;

    > ${Label} {
        margin: 0;

        & + ${StyledButton} {
            margin-top: 0.8rem;
        }
    }
`;

const InfobarContainer = styled.aside<{ minHeight: number }>`
    width: 200px;
    background: ${({ theme }) => theme.colors.neutral[0]};
    box-shadow: 0 20px 40px 0 rgba(16, 36, 48, 0.06);
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        z-index: 8;
        background: none;
        box-shadow: none;
        width: 100vw;
        pointer-events: none;
        min-height: ${({ minHeight }) => minHeight + 150}px;
    }
    min-height: ${({ minHeight }) => minHeight}px;

    ${Label} {
        margin-top: 0;
    }
`;

const Header = styled.div`
    padding: 1.6rem 1.9rem 0;
    min-height: ${PRICE_COLUMN_HEADER_HEIGHT}px;
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid ${({ theme }) => theme.colors.neutral[20]};
    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        padding-top: 1rem;
        > label,
        ${BodyInner} {
            display: none;
        }
    }

    > label {
        color: ${({ theme }) => theme.colors.neutral[50]};
        font-weight: 500;
    }
`;
