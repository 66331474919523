import ActionMenu, { Item, MenuList } from '@oberoninternal/travelbase-ds/components/action/ActionMenu';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Content from '@oberoninternal/travelbase-ds/components/section/Content';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import dateTextFormat from '@oberoninternal/travelbase-ds/constants/dateTextFormat';
import { Box, Flex } from '@rebass/grid';
import { format } from 'date-fns';
import gql from 'graphql-tag';
import React, { FC, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components/macro';
import { getDateOpts } from '../../../constants/dateOpts';
import euroFormat from '../../../constants/euroFormat';
import hourFormat from '../../../constants/hourFormat';
import { NotFoundError } from '../../../entities/NotFoundError';
import { TicketPriceLine, TicketStatusEnum, useTicketQuery, TicketRateLine } from '../../../generated/graphql';
import addressHasValues from '../../../utils/addressHasValues';
import getTicketGroupText from '../../../utils/getTicketText';
import ActivityDetails from '../../atoms/ActivityDetails';
import ContentWrapper from '../../atoms/ContentWrapper';
import { Seperator } from '../../atoms/Seperator';
import Table from '../../atoms/Table';
import FieldHeading from '../../molecules/FieldHeading';
import Print, { PrintRow } from '../../molecules/Print';
import Loading from '../../organisms/Loading';
import { getGuestTitle } from '../bookings/Details';
import getTicketStatus from '../../../utils/getTicketStatus';
import { FormattedMessage, useIntl } from 'react-intl';

export const query = gql`
    query Ticket($id: ID!) {
        ticket(id: $id) {
            id
            createdAt
            status
            timeslot {
                id
                startDateTime
                endDateTime
                activity {
                    ...ActivityPickerEntry
                }
            }
            rateLines {
                rateName
                amount
                unitPrice
                qrCodes {
                    code
                    number
                    scannedAt
                    scannedBy
                }
            }
            priceLines {
                label
                quantity
                totalPrice
                unitPrice
            }
            customer {
                ...CustomerDetails
            }
        }
    }
`;

const computeTotalPrice = (priceLines: TicketPriceLine[]) =>
    euroFormat(priceLines.reduce((total, next) => total + next.totalPrice, 0));

const hasQRCodes = (rateLines: TicketRateLine[]) => rateLines.some(rateLine => rateLine.qrCodes !== null);

const TicketDetails: FC<React.PropsWithChildren<unknown>> = () => {
    const { ticketId } = useParams<{ ticketId: string }>();
    const { data, loading } = useTicketQuery({ variables: { id: ticketId } });
    const ref = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => ref.current,
        documentTitle: `Ticket ${data?.ticket?.id ?? ''}`,
    });
    const { formatMessage, locale } = useIntl();
    const dateOpts = getDateOpts(locale as 'nl' | 'de' | 'en');
    if (loading) {
        return <Loading variant="default" />;
    }

    if (!data || !data.ticket) {
        throw new NotFoundError();
    }

    const {
        ticket: {
            customer,
            status,
            timeslot: { activity, ...timeslot },
            priceLines,
            rateLines,
            ...ticket
        },
    } = data;

    const hideInfo = status !== TicketStatusEnum.Accepted;
    const guestText = getGuestTitle(customer, hideInfo);
    const startDateTime = new Date(timeslot.startDateTime);
    const endDateTime = new Date(timeslot.endDateTime);
    const createdAt = new Date(ticket.createdAt);
    const qrCodesAvailable = hasQRCodes(rateLines);

    const ticketDetails = [
        {
            label: 'Huidige status',
            content: getTicketStatus(data.ticket),
        },
        {
            label: 'Activiteit',
            content: <ActivityDetails style={{ marginTop: '-3px' }} entity={activity} />,
        },
        {
            label: 'Startdatum en tijd',
            content: format(startDateTime, `d MMMM yyyy ${hourFormat}`, dateOpts),
        },
        {
            label: 'Einddatum en tijd',
            content: format(endDateTime, `d MMMM yyyy ${hourFormat}`, dateOpts),
        },
        { label: 'Tickets', content: getTicketGroupText(rateLines) },
    ];
    return (
        <ContentWrapper pb={[0, null, '8rem']}>
            <Pagehead backButtonText="Terug" title={guestText} />
            <Print ref={ref}>
                <h1>{guestText}</h1>
                <h3>
                    <FormattedMessage defaultMessage="Aangemaakt op" />
                    {format(createdAt, dateTextFormat, dateOpts)}
                </h3>
                <h4>
                    <FormattedMessage defaultMessage="Ticketinformatie" />
                </h4>
                {ticketDetails.map(
                    entry =>
                        entry && (
                            <PrintRow key={entry.label} label={entry.label}>
                                {entry.content}
                            </PrintRow>
                        )
                )}

                <h5 style={{ margin: '0.8rem 0 0 0' }}>
                    <FormattedMessage defaultMessage="Prijsopbouw" />
                </h5>
                <table>
                    <tbody>
                        {priceLines.map(({ label, unitPrice, totalPrice, quantity }, i) => (
                            <tr key={i}>
                                <td>{label}</td>
                                <td align="right">{unitPrice && euroFormat(unitPrice)}</td>
                                <td align="right">
                                    <FormattedMessage defaultMessage="{quantity} x" values={{ quantity }} />
                                </td>
                                <td align="right">
                                    <strong>{euroFormat(totalPrice)}</strong>
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td colSpan={3}>
                                <FormattedMessage defaultMessage="Totaal" />
                            </td>
                            <td align="right">
                                <strong>{computeTotalPrice(priceLines)}</strong>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {customer && !hideInfo && (
                    <>
                        <h4>
                            <FormattedMessage defaultMessage="Gegevens koper" />
                        </h4>
                        {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                        <PrintRow label="Naam">{`${customer.firstName} ${customer.lastName}`}</PrintRow>
                        {addressHasValues(customer?.address) && (
                            <PrintRow label={formatMessage({ defaultMessage: 'Adres' })}>
                                <Body>
                                    {customer.address.street} {customer.address.number}
                                    <br />
                                    {customer.address.postalCode} {customer.address.city}
                                </Body>
                            </PrintRow>
                        )}

                        {customer.birthdate && (
                            <PrintRow label={formatMessage({ defaultMessage: 'Geboortedatum' })}>
                                {format(new Date(customer.birthdate), `d MMMM y`, dateOpts)}
                            </PrintRow>
                        )}

                        {customer.address.countryName && (
                            <PrintRow label={formatMessage({ defaultMessage: 'Land' })}>
                                {customer.address.countryName}
                            </PrintRow>
                        )}
                        {customer.phoneNumber && (
                            <PrintRow label={formatMessage({ defaultMessage: 'Telefoon' })}>
                                {customer.phoneNumber}
                            </PrintRow>
                        )}
                        {customer.email && (
                            <PrintRow label={formatMessage({ defaultMessage: 'E-mail' })}>{customer.email}</PrintRow>
                        )}
                    </>
                )}
            </Print>
            <Flex
                justifyContent="space-between"
                alignItems={[null, null, 'center']}
                flexDirection={['column', null, 'row']}
            >
                <Body>
                    <FormattedMessage
                        defaultMessage="Aangemaakt op {date}"
                        values={{ date: format(createdAt, dateTextFormat, dateOpts) }}
                    />
                </Body>
                <ActionMenu
                    title={formatMessage({ defaultMessage: 'Acties toepassen' })}
                    withButtonBorder
                    align="right"
                >
                    <MenuList>
                        <Item>
                            <TextButton onClick={handlePrint}>
                                <FormattedMessage defaultMessage="Printen" />
                            </TextButton>
                        </Item>
                    </MenuList>
                </ActionMenu>
            </Flex>
            <FieldHeading title={formatMessage({ defaultMessage: 'Ticketinformatie' })} />
            {ticketDetails.map(
                entry =>
                    entry && (
                        <Content key={entry.label} label={entry.label}>
                            {entry.content}
                        </Content>
                    )
            )}
            <Seperator variant="small" />
            <Content label={formatMessage({ defaultMessage: 'Prijsopbouw' })}>
                <Box style={{ overflow: 'auto' }}>
                    <PriceTable>
                        <tbody>
                            {priceLines.map(({ label, unitPrice, quantity, totalPrice }, i) => (
                                <tr key={i}>
                                    <td>{label}</td>
                                    <td align="right">{euroFormat(unitPrice ?? 0)}</td>
                                    {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                                    <td align="right">{quantity} x</td>
                                    <td align="right">
                                        <strong>{euroFormat(totalPrice)}</strong>
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan={3}>
                                    <FormattedMessage defaultMessage="Totaal" />
                                </td>
                                <td align="right">
                                    <strong>{computeTotalPrice(priceLines)}</strong>
                                </td>
                            </tr>
                        </tbody>
                    </PriceTable>
                </Box>
            </Content>
            {customer && !hideInfo && (
                <>
                    <FieldHeading title={formatMessage({ defaultMessage: 'Gegevens koper' })} />
                    {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                    <Content label="Naam">{`${customer.firstName} ${customer.lastName}`}</Content>
                    <>
                        {addressHasValues(customer?.address) && (
                            <Content label={formatMessage({ defaultMessage: 'Adres' })}>
                                <Body>
                                    {customer.address.street} {customer.address.number}
                                </Body>
                                <Body>
                                    {customer.address.postalCode} {customer.address.city}
                                </Body>
                            </Content>
                        )}
                        {customer.birthdate && (
                            <Content label={formatMessage({ defaultMessage: 'Geboortedatum' })}>
                                {format(new Date(customer.birthdate), `d MMMM y`, dateOpts)}
                            </Content>
                        )}
                        {customer.address.countryName && (
                            <Content label={formatMessage({ defaultMessage: 'Land' })}>
                                {customer.address.countryName}
                            </Content>
                        )}
                        {customer.phoneNumber && (
                            <Content label={formatMessage({ defaultMessage: 'Telefoon' })}>
                                <Flex>
                                    <TextButton as="a" href={`tel:${customer.phoneNumber}`}>
                                        {customer.phoneNumber}
                                    </TextButton>
                                </Flex>
                            </Content>
                        )}
                        {customer.email && (
                            <Content label={formatMessage({ defaultMessage: 'E-mail' })}>
                                <Flex>
                                    <TextButton as="a" href={`mailto:${customer.email}`}>
                                        {customer.email}
                                    </TextButton>
                                </Flex>
                            </Content>
                        )}
                    </>
                </>
            )}
            {qrCodesAvailable && (
                <>
                    <FieldHeading title={formatMessage({ defaultMessage: 'QR codes' })} />
                    <Box style={{ overflow: 'auto' }}>
                        <QRTable>
                            <thead>
                                <tr>
                                    <th colSpan={4} aria-hidden="true" />
                                    <th colSpan={4}>
                                        <FormattedMessage defaultMessage="Code" />
                                    </th>
                                    <th colSpan={4}>
                                        <FormattedMessage defaultMessage="Gescand op" />
                                    </th>
                                    <th colSpan={4}>
                                        <FormattedMessage defaultMessage="Door" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {rateLines.map(({ qrCodes, rateName }) =>
                                    qrCodes?.map(({ code, scannedAt, scannedBy, number }, j) => (
                                        <tr key={j}>
                                            <td colSpan={4}>
                                                {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                                                {rateName} #{number}
                                            </td>
                                            <td colSpan={4}>{code}</td>
                                            <td colSpan={4}>
                                                {scannedAt
                                                    ? format(new Date(scannedAt), 'd MMMM y HH:mm', dateOpts)
                                                    : // eslint-disable-next-line formatjs/no-literal-string-in-jsx
                                                      '-'}
                                            </td>
                                            {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                                            <td colSpan={4}>{scannedBy ?? '-'}</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </QRTable>
                    </Box>
                </>
            )}
        </ContentWrapper>
    );
};

export default TicketDetails;

const PriceTable = styled(Table)`
    min-width: 60rem;
    tr:first-of-type {
        td {
            padding-top: 0;
        }
    }
    tr:last-of-type {
        border-top: 1px solid ${({ theme }) => theme.colors.neutral[20]};
        td {
            padding-top: 2rem;
        }
    }
`;

const QRTable = styled(Table)`
    min-width: 60rem;
    tr {
        border-top: 1px solid ${({ theme }) => theme.colors.neutral[20]};
    }
    tr:first-child {
        border-top: none;
    }
    tr:last-child {
        border-bottom: none;
    }
`;
