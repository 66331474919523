import { Box, BoxProps } from '@rebass/grid';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

const BigListWrapper: FC<React.PropsWithChildren<BoxProps>> = ({ children, ...props }) => (
    <Container {...props}>
        <Box>{children}</Box>
    </Container>
);

export default BigListWrapper;

const Container = styled(Box)`
    @media (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        width: calc(100% + 1.2rem);
        overflow-x: auto;
        min-height: 50vh;
        margin-left: -1.2rem;
        padding: 0 ${({ theme }) => theme.spacing['40_Standard']};
        > div {
            width: ${({ theme }) => theme.maxWidths.m}px;
        }
    }
`;
