import { useParams } from 'react-router-dom';
import { PartnerParams } from '../entities/PartnerParams';
import { usePartnerDataQuery } from '../generated/graphql';

const useIsBackofficeuser = (initialPartnerId?: string) => {
    const { partnerId = initialPartnerId } = useParams<PartnerParams>();
    const { data: partnerData } = usePartnerDataQuery({ variables: { partnerId: partnerId! }, skip: !partnerId });
    const { isBackofficeUser } = partnerData?.viewer ?? { isBackofficeUser: false };

    return isBackofficeUser;
};

export default useIsBackofficeuser;
