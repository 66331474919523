import { ErrorMessage as FormikErrorMessage } from 'formik';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Label } from '../primitive/Label';

interface Props {
    name?: string;
}

/**
 *
 * ErrorMessage component can be used to display an error in (most often) forms. An optional name prop can be given to
 * make use of Formiks dynamic errormessage.
 */
const ErrorMessage: FC<React.PropsWithChildren<Props>> = ({ name, children }) => (
    <ErrorMessageContainer data-cy="errorMessage">
        {name ? <FormikErrorMessage name={name} /> : children}
    </ErrorMessageContainer>
);

export default ErrorMessage;

export const ErrorMessageContainer = styled(Label)`
    margin-left: 3px;
    margin-bottom: 3px;
    margin-top: 9px;
    color: ${({ theme }) => theme.colors.negative['60']};
`;
