import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import { CheckboxField } from '@oberoninternal/travelbase-ds/components/form/Checkbox';
import { FormActionsContainer } from '@oberoninternal/travelbase-ds/components/form/FormActions';
import Sidebar from '@oberoninternal/travelbase-ds/components/layout/Sidebar';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import { Sesame } from '@oberoninternal/travelbase-ds/hooks/useSesame';
import { Box, Flex } from '@rebass/grid';
import ContentWrapper from '../../../atoms/ContentWrapper';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { RentalUnitInfoFragment, useOverwriteRentalUnitSurchargesMutation } from '../../../../generated/graphql';
import SidebarField from '../../../atoms/SidebarField';
import FormScreen from '../../../organisms/FormScreen';
import { Warning } from '@oberoninternal/travelbase-ds/components/figure/Warning';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { FormattedMessage, useIntl } from 'react-intl';

export const overwriteSurchargesMutation = gql`
    mutation overwriteRentalUnitSurcharges($input: OverwriteRentalUnitSurchargesInput!) {
        overwriteRentalUnitSurcharges(input: $input) {
            targetRentalUnits {
                id
                surcharges {
                    id
                }
            }
        }
    }
`;

interface Props extends Sesame {
    sourceRentalUnitId: string;
    rentalUnits: Pick<RentalUnitInfoFragment, 'name' | 'id'>[];
}

const BulkSurchargeSidebar: FC<React.PropsWithChildren<Props>> = ({ rentalUnits, sourceRentalUnitId, ...props }) => {
    const [overwrite] = useOverwriteRentalUnitSurchargesMutation();
    const { formatMessage } = useIntl();
    return (
        <FormScreen
            variant="sidebar"
            handleSubmit={async values => {
                const targetRentalUnitIds = Object.keys(values).filter(key => values[key as keyof typeof values]);
                await overwrite({
                    variables: {
                        input: {
                            sourceRentalUnitId,
                            targetRentalUnitIds,
                        },
                    },
                });
                props.onClose();
            }}
            initialValues={{}}
            bottomChildren={({ dirty, isSubmitting }) => (
                <FormActionsContainer dirty={dirty} alwaysVisible variant="wizard">
                    <FormActionsContainer variant="sidebar">
                        <TextButton onClick={props.onClose}>
                            <FormattedMessage defaultMessage="Annuleren" />
                        </TextButton>
                        <Button disabled={isSubmitting || !dirty} submitting={isSubmitting} type="submit">
                            <FormattedMessage defaultMessage="Opslaan" />
                        </Button>
                    </FormActionsContainer>
                </FormActionsContainer>
            )}
        >
            {({ dirty, isSubmitting }) => (
                <Sidebar {...props}>
                    <ContentWrapper variant="sidebar">
                        <Box mb={5}>
                            <Label>
                                <FormattedMessage defaultMessage="Bijkomende kosten kopieren" />
                            </Label>
                            <Body>
                                <FormattedMessage defaultMessage="Alle ingestelde bijkomende kosten nemen we over naar de volgende accommodaties:" />
                            </Body>
                            <SidebarField label={formatMessage({ defaultMessage: 'Accommodaties' })}>
                                {rentalUnits.map(rentalUnit => (
                                    <Box key={rentalUnit.id} width={1} my="auto">
                                        <CheckboxField id={rentalUnit.id} name={rentalUnit.id}>
                                            {rentalUnit.name}
                                        </CheckboxField>
                                    </Box>
                                ))}
                            </SidebarField>
                        </Box>
                        <Box mt={4}>
                            <Flex alignItems="center" pb={2}>
                                <Warning style={{ marginRight: '5px' }} />
                                <Title variant="tiny">
                                    <FormattedMessage defaultMessage="Let op!" />
                                </Title>
                            </Flex>
                            <Body variant="tiny">
                                <FormattedMessage defaultMessage="Dit vervangt dus de reeds ingevoerde gegevens." />
                            </Body>
                        </Box>
                    </ContentWrapper>
                    <FormActionsContainer variant="sidebar">
                        <TextButton onClick={props.onClose}>
                            <FormattedMessage defaultMessage="Annuleren" />
                        </TextButton>
                        <Button disabled={isSubmitting || !dirty} submitting={isSubmitting} type="submit">
                            <FormattedMessage defaultMessage="Opslaan" />
                        </Button>
                    </FormActionsContainer>
                </Sidebar>
            )}
        </FormScreen>
    );
};

export default BulkSurchargeSidebar;
