import * as Yup from 'yup';
import { IntlShape } from 'react-intl';

export const iCalValidation = (intl: IntlShape) =>
    Yup.object().shape({
        iCal: Yup.array().of(
            Yup.string()
                .url('Voer een geldige URL in')
                .required(intl.formatMessage({ defaultMessage: 'Dit veld mag niet leeg zijn' }))
        ),
    });
