import isEqual from 'lodash/isEqual';

/**
 * @param values Current values in the form
 * @param initialValues Initial values of the form
 * @param fieldNames An array of keys to check whether the value is  equal to the initial value
 */

function omitEqualValues<T, K extends keyof T>(values: T, initialValues: T, fieldNames?: K[]): T {
    return Object.assign(
        {},
        ...Object.entries(values)
            .filter(([key, val]) => {
                if (fieldNames) {
                    if (fieldNames.find(name => name === key)) {
                        return !isEqual(val, initialValues[key as keyof T]);
                    }
                    // ignore the rest
                    return true;
                }
                return !isEqual(val, initialValues[key as keyof T]);
            })
            .map(([key, val]) => ({ [key]: val }))
    );
}

export default omitEqualValues;
