import React from 'react';
import styled from 'styled-components/macro';
import { useFormikContext } from 'formik';
import { determineMapLocation } from '../../utils/determineMapLocation';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import { Address } from '../../generated/graphql';
import { FormattedMessage } from 'react-intl';

const MapLocationDetermineButton = () => {
    const formikProps = useFormikContext<{ address: Address }>();
    const { values } = formikProps;
    return (
        <StyledButton
            disabled={!values.address.street || !values.address.city}
            variant="primary"
            onClick={() => determineMapLocation(formikProps)}
        >
            <FormattedMessage defaultMessage="Locatie bepalen" />
        </StyledButton>
    );
};

export default MapLocationDetermineButton;

const StyledButton = styled(Button)`
    left: 1rem;
    top: 1rem;
`;
