import React, { FC, useEffect, useMemo } from 'react';
import { Redirect, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';
import { SidebarProvider } from '../../context/sidebar';
import { ConfigurationError } from '../../entities/ConfigurationError';
import { UnitParams } from '../../entities/UnitParams';
import { PartnerDataQuery } from '../../generated/graphql';
import getRentalUnitMetaData from '../../utils/getRentalUnitMetaData';
import { LocalizedSwitch } from '../atoms/LocalizedSwitch';
import ErrorBoundary from '../organisms/ErrorBoundary';
import UnitNavigation, { UnitNavigationProps } from '../organisms/UnitNavigation';
import NotFound from './NotFound';
import Availability from './unit/Availability';
import Info from './unit/Info';
import Prices from './unit/Prices';
import Wizard from './unit/prices/Wizard';
import Specials from './unit/Specials';
import Trips from './unit/Trips';

const Unit: FC<React.PropsWithChildren<{ data?: PartnerDataQuery }>> = ({ data }) => {
    const match = useRouteMatch<UnitParams>()!;
    const history = useHistory();
    const location = useLocation<{ to?: string }>();

    const accommodations = useMemo(() => data?.partner?.accommodations ?? [], [data?.partner?.accommodations]);

    const selectedAccommodation = accommodations.find(acc =>
        acc.rentalUnits?.map(({ slug }) => slug).includes(match.params.unitSlug)
    );
    const rentalUnits = selectedAccommodation?.rentalUnits ?? [];
    const selectedRentalUnit = rentalUnits.find(({ slug }) => slug === match.params.unitSlug);

    // do a redirect if no accommodation is passed in the url
    useEffect(() => {
        const firstAcc = accommodations.find(accommodation => accommodation.rentalUnits.length > 0);
        const firstUnit = firstAcc?.rentalUnits[0];

        if (!match.params.unitSlug && firstAcc && firstUnit) {
            history.replace({
                pathname: `${match.url}/${firstUnit.slug}/${location?.state?.to ?? ''}`,
            });
        }
    }, [match, accommodations, history, location]);

    if (accommodations.length === 0) {
        throw new ConfigurationError('Deze partner heeft geen toegang tot accommodaties.');
    }

    if (
        (match.params.unitSlug && rentalUnits.length === 0) ||
        (!match.params.unitSlug && accommodations.every(accommodation => accommodation.rentalUnits.length === 0))
    ) {
        throw new ConfigurationError(
            `Deze accommodatie (${selectedAccommodation?.name ?? accommodations[0].name}) heeft geen verhuuréénheden.`
        );
    }
    const metaData = getRentalUnitMetaData(data?.rentalUnit);
    const { datePricingStartDate, datePricingEndDate } = metaData;

    const navProps: UnitNavigationProps = {
        accPickerEntries: accommodations,
        datePricingStartDate,
        datePricingEndDate,
    };

    return (
        <>
            {selectedAccommodation && <UnitNavigation {...navProps} />}
            <SidebarProvider>
                <Container>
                    <ErrorBoundary>
                        <LocalizedSwitch>
                            {match.params.unitSlug && (
                                <Redirect from={`${match.path}`} exact to={`${match.path}/availability`} />
                            )}
                            <Route
                                path={`${match.path}/info`}
                                render={props => (
                                    <Info
                                        accommodation={selectedAccommodation}
                                        rentalUnit={selectedRentalUnit}
                                        {...props}
                                    />
                                )}
                            />

                            <Route
                                exact
                                path={[`${match.path}/prices/wizard`, `${match.path}/availability/wizard`]}
                                component={() => <Wizard rentalUnit={selectedRentalUnit} />}
                            />

                            <Route
                                exact
                                path={`${match.path}/prices`}
                                render={() => <Prices metaData={metaData} key={selectedRentalUnit?.id} />}
                            />
                            <Route
                                exact
                                path={`${match.path}/trips`}
                                render={() => <Trips metaData={metaData} key={selectedRentalUnit?.id} />}
                            />
                            <Route
                                exact
                                path={`${match.path}/specials`}
                                render={() => <Specials metaData={metaData} key={selectedRentalUnit?.id} />}
                            />
                            <Route
                                exact
                                path={`${match.path}/availability`}
                                component={() => <Availability key={selectedRentalUnit?.id} />}
                            />
                            <Route component={NotFound} />
                        </LocalizedSwitch>
                    </ErrorBoundary>
                </Container>
            </SidebarProvider>
        </>
    );
};

export default Unit;

const Container = React.memo(styled.main`
    padding-top: ${({ theme }) => theme.heights.nav}px;

    @media (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        padding-top: ${({ theme }) => theme.heights.mobileNav}px;
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        padding-top: ${({ theme }) => theme.heights.unitNav + theme.heights.nav}px;
    }
`);
