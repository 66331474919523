import FormActions from '@oberoninternal/travelbase-ds/components/form/FormActions';
import Sidebar from '@oberoninternal/travelbase-ds/components/layout/Sidebar';
import { MenuStateProps } from '@oberoninternal/travelbase-ds/hooks/useMenuState';
import { FormikValues } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import ContentWrapper from '../atoms/ContentWrapper';
import FormScreen, { FormScreenProps } from '../organisms/FormScreen';

interface Props<Values> extends FormScreenProps<Values> {
    menuState: MenuStateProps;
}

function RatesFormScreen<Values extends FormikValues>({
    menuState: { setOpen, ...menu },
    children,
    ...props
}: Props<Values>) {
    const { formatMessage } = useIntl();
    return (
        <FormScreen {...props} variant="sidebar" bottomChildren={null}>
            {bag => {
                const { resetForm, dirty } = bag;
                const onClose = () => {
                    if (
                        dirty &&
                        !window.confirm(
                            formatMessage({
                                defaultMessage:
                                    'Weet je zeker dat je het panel wilt sluiten? De ingevoerde gegevens gaan verloren',
                            })
                        )
                    ) {
                        return;
                    }
                    setOpen(false);
                    resetForm();
                };
                return (
                    <Sidebar {...menu} setOpen={open => (open ? setOpen(true) : onClose())}>
                        <ContentWrapper style={{ marginBottom: '15rem' }} variant="sidebar">
                            {children(bag)}
                        </ContentWrapper>
                        <FormActions
                            resetText={formatMessage({ defaultMessage: 'Wijzigingen ongedaan maken' })}
                            variant="sidebar"
                            buttonText={formatMessage({ defaultMessage: 'Opslaan' })}
                            {...bag}
                        />
                    </Sidebar>
                );
            }}
        </FormScreen>
    );
}

export default RatesFormScreen;
