import React, { SVGAttributes } from 'react';

export default function (props: SVGAttributes<SVGElement>) {
    return (
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2">
                <circle cx="10.5" cy="10.5" r="6.5" />
                <path d="M15.177 15.177l4.676 4.676" />
            </g>
        </svg>
    );
}
