import gql from 'graphql-tag';
import React, { FC } from 'react';
import { generatePath, NavLink, useRouteMatch, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { UnitParams } from '../../entities/UnitParams';
import { AccPickerEntryFragment } from '../../generated/graphql';
import PickerEntryContainer from '../atoms/PickerEntryContainer';
import UnitDetails from '../atoms/UnitDetails';
import PickerList from '../atoms/PickerList';
import PickerItem from '../atoms/PickerItem';
import { entryCss } from '../atoms/entryCss';

interface Props {
    accommodation: AccPickerEntryFragment;
    first?: boolean;
    open: boolean;
    setOpen: (open: boolean) => void;
}

type RentalUnit = NonNullable<AccPickerEntryFragment['rentalUnits']>[0];

export const accPickerEntryFragment = gql`
    fragment AccPickerEntry on Accommodation {
        id
        name
        slug
        id
        rentalUnits {
            slug
            ...UnitDetails
        }
    }
`;
const AccPickerEntry: FC<React.PropsWithChildren<Props>> = ({
    accommodation: { name, rentalUnits },
    first = false,
    open,
    setOpen,
}) => {
    const match = useRouteMatch<UnitParams>();
    const units = rentalUnits || [];
    const location = useLocation();

    const source = location.pathname.replace(match.url, ''); // get the current route without slugstuff;
    const firstUnit = units[0];
    const isCompact = !units || units.length === 1;

    const onUnitPage = !!match.params.unitSlug;

    const renderUnit = (unit: RentalUnit, i = 0) => {
        if (unit.hideForPartner && !first) {
            return null;
        }
        const entryLink = generatePath(onUnitPage ? match.path + source : `/partner/:partnerId/unit/:unitSlug`, {
            unitSlug: unit ? unit.slug : 'null',
            partnerId: match.params.partnerId,
        });

        const inner = (
            <PickerEntryContainer first={first && open}>
                <UnitDetails locationName={first && !isCompact ? name : undefined} unit={unit} />
            </PickerEntryContainer>
        );

        const entryProps = {
            key: unit ? unit.slug : i,
            open,
            activeClassName: 'active',
        };

        return first ? (
            <First {...entryProps}>{inner}</First>
        ) : (
            <StyledLink onClick={() => setOpen(false)} exact={first && !open} to={entryLink} {...entryProps}>
                {inner}
            </StyledLink>
        );
    };

    if (first || isCompact) {
        const selectedUnit = units.find(unit => unit && unit.slug === match.params.unitSlug);
        return renderUnit(first && selectedUnit ? selectedUnit : firstUnit);
    }

    return (
        <>
            {units.length > 0 && <Header>{name}</Header>}
            <PickerList>
                {units.map((unit, i) => (
                    <PickerItem key={unit ? unit.slug : i}>{renderUnit(unit, i)}</PickerItem>
                ))}
            </PickerList>
        </>
    );
};

export default AccPickerEntry;

const Header = styled.p`
    font-weight: bold;
    padding: 14px 16px 6px;
    margin: 0;
    font-size: 12px;
    line-height: 1;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    background: ${({ theme }) => theme.colors.neutral[0]};
`;

const StyledLink = styled(NavLink)<{ open: boolean }>`
    ${entryCss}
`;

const First = styled.div<{ open: boolean }>`
    ${entryCss}
`;
