import { CheckboxField } from '@oberoninternal/travelbase-ds/components/form/Checkbox';
import ErrorMessage from '@oberoninternal/travelbase-ds/components/form/ErrorMessage';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import { Input } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import useWeekdays from '@oberoninternal/travelbase-ds/hooks/useWeekdays';
import { Box } from '@rebass/grid';
import format from 'date-fns/format';
import { connect, getIn } from 'formik';
import React from 'react';
import styled from 'styled-components/macro';
import { getDateOpts } from '../../constants/dateOpts';
import { UnreachableCaseError } from '../../entities/UnreachableCaseError';
import NumberInput from '../atoms/NumberInput';
import { PriceWizardValues } from '../pages/unit/prices/Wizard';
import { validateNumberInputSync } from '../../constants/numberInputValidation';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

type Variant = 'nightPrice' | 'arrivalAllowed' | 'minimumStayDuration';

const getFormField = (name: string, variant: Variant, intl: IntlShape) => {
    const fieldName = `${name}.${variant}`;
    switch (variant) {
        case 'nightPrice': {
            const validate = validateNumberInputSync(intl, { isCurrency: true });
            return <NumberInput validate={validate} hideErrorMessage name={fieldName} isCurrency min={0} max={10000} />;
        }
        case 'minimumStayDuration': {
            const validate = validateNumberInputSync(intl);
            return <NumberInput step={1} hideErrorMessage name={fieldName} validate={validate} min={1} max={21} />;
        }
        case 'arrivalAllowed':
            return (
                <Box>
                    <CheckboxField name={fieldName} />
                </Box>
            );
        default:
            throw new UnreachableCaseError(variant);
    }
};

const WeekdaysInput = connect<{ name: string; variant: Variant }, PriceWizardValues>(
    ({ name, variant, formik: { errors, touched } }) => {
        const intl = useIntl();
        const weekdays = useWeekdays();
        const pricingErrors = getIn(errors, name)?.pricing;
        const pricingTouched = getIn(touched, name)?.pricing;

        const error = weekdays.some(date => {
            const key = format(date, 'eeee').toLowerCase();
            return pricingErrors?.[key]?.[variant] && pricingTouched?.[key]?.[variant];
        });

        return (
            <>
                <MultipleInputContainer>
                    {weekdays.map(date => {
                        const day = format(date, 'eeee', getDateOpts('nl'));
                        const key = format(date, 'eeee');
                        const dayFieldName = `${name}.pricing.${key.toLowerCase()}`;
                        return (
                            <InputContainer key={key}>
                                <StyledLabel variant="small">{day}</StyledLabel>
                                {getFormField(dayFieldName, variant, intl)}
                            </InputContainer>
                        );
                    })}
                    {error && (
                        <ErrorMessage>
                            <FormattedMessage defaultMessage="Vul alle velden voor de dagen correct in" />
                        </ErrorMessage>
                    )}
                </MultipleInputContainer>
            </>
        );
    }
);

export default WeekdaysInput;

const StyledLabel = styled(Label)`
    text-transform: capitalize;
`;

const InputContainer = styled.div`
    width: ${(1 / 4) * 100}%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        width: ${(1 / 7) * 100}%;
        margin: 0;
    }
    ${Input} {
        position: relative;
        :focus {
            z-index: 2;
        }
        :hover {
            z-index: 1;
        }

        border-radius: 0;
    }
`;

const MultipleInputContainer = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    ${InputContainer} {
        :first-of-type {
            ${Input} {
                border-radius: 0.5rem 0 0 0.5rem;
            }
        }

        :last-of-type {
            ${Input} {
                border-radius: 0 0.5rem 0.5rem 0;
            }
        }
    }
`;
