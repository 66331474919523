import React, { SVGAttributes } from 'react';
import { useThemeContext } from '../../hooks/useThemeContext';

export function Pending(props: SVGAttributes<SVGElement>) {
    const theme = useThemeContext();
    return (
        <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g fill="none" fillRule="evenodd">
                <circle fill={theme.colors.neutral['30']} cx="8" cy="8" r="8" />
                <g stroke={theme.colors.neutral['0']} strokeWidth="2">
                    <path d="M3.917 8h1.701M7.15 8h1.7M10.382 8h1.701" />
                </g>
            </g>
        </svg>
    );
}
