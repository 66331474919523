import React, { FC } from 'react';
import { SidebarProvider } from '../../../context/sidebar';
import Timeslots from '../../organisms/Timeslots';

const Planning: FC<React.PropsWithChildren<unknown>> = () => (
    <SidebarProvider>
        <Timeslots />
    </SidebarProvider>
);

export default Planning;
