import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import { Warning } from '@oberoninternal/travelbase-ds/components/figure/Warning';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import Toast from '@oberoninternal/travelbase-ds/components/feedback/Toast';
import { Box, Flex } from '@rebass/grid';
import { Form, Formik } from 'formik';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { useApolloClient } from '@apollo/client';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import { useSidebar } from '../../context/sidebar';
import {
    Scalars,
    TripsDocument,
    TripsQuery,
    TripsQueryVariables,
    useUploadImportMutation,
} from '../../generated/graphql';
import ContentWrapper from '../atoms/ContentWrapper';
import { SidebarSeperator } from '../atoms/Seperator';
import SidebarField from '../atoms/SidebarField';
import SidebarIntro from '../atoms/SidebarIntro';
import UploadField from '../atoms/UploadField';
import { FormattedMessage, useIntl } from 'react-intl';

export interface ImportInfoSidebarData {
    type: 'IMPORT_INFO';
    rentalUnitId: Scalars['ID']['output'] | null;
    variables?: TripsQueryVariables;
    loadedPeriod: Interval | null;
    hasPrices: boolean;
}

export const mutation = gql`
    mutation uploadImport($input: UploadTripPricingsInput!, $start: Date!, $end: Date!) {
        uploadTripPricings(input: $input) {
            rentalUnit {
                id
                tripPricings(startDate: $start, endDate: $end) {
                    ...TripColumn
                }
            }
        }
    }
`;

interface Values {
    file: File | null;
}

const ImportInfoSidebar: FC<React.PropsWithChildren<{ data: ImportInfoSidebarData }>> = ({
    data: { loadedPeriod, rentalUnitId, variables, hasPrices },
}) => {
    const client = useApolloClient();
    const { formatMessage } = useIntl();
    const [mutate] = useUploadImportMutation({
        context: {
            ignoreError: true,
        },
    });

    const [, dispatch] = useSidebar();

    return (
        <ContentWrapper variant="sidebar">
            <SidebarIntro title={formatMessage({ defaultMessage: 'Ingelezen prijzen' })}>
                <FormattedMessage defaultMessage="Import" />
            </SidebarIntro>
            <SidebarSeperator />
            <SidebarField label={formatMessage({ defaultMessage: 'Belangrijk bij importeren' })}>
                <Body variant="small">
                    <FormattedMessage
                        defaultMessage="Door het uploaden van een CSV/Excel bestand, vervangt Travelbase alle huidige prijzen met de prijzen in het bestand. Dit werkt alleen met een CSV/Excel bestand met in de eerste kolom de datum (MM/DD/JJJJ), in de tweede kolom het aantal nachten en in de derde kolom de prijs. Let op: prijzen in het bestand zijn altijd in centen!
                    {br}
                    {br}
                    We raden je aan aanpassingen te maken in een bestand dat je eerst exporteert."
                        values={{
                            br: <br />,
                        }}
                    />
                </Body>
            </SidebarField>

            {rentalUnitId ? (
                <Formik<Values>
                    initialValues={{ file: null }}
                    validate={({ file }) => {
                        if (!file) {
                            return {
                                file: 'Geen bestand gekozen.',
                            };
                        }
                        return {};
                    }}
                    onSubmit={async ({ file }, { setSubmitting }) => {
                        if (!loadedPeriod) {
                            return;
                        }

                        try {
                            const { data } = await mutate({
                                variables: {
                                    input: {
                                        file,
                                        rentalUnitId,
                                    },
                                    ...loadedPeriod,
                                },
                            });
                            if (data?.uploadTripPricings.rentalUnit?.tripPricings) {
                                const opts = {
                                    query: TripsDocument,
                                    variables,
                                };
                                const cached = client.readQuery<TripsQuery>(opts);

                                if (cached?.rentalUnit) {
                                    client.writeQuery<TripsQuery>({
                                        ...opts,
                                        data: {
                                            ...cached,
                                            rentalUnit: {
                                                ...cached.rentalUnit,
                                                tripPricings: data?.uploadTripPricings.rentalUnit?.tripPricings ?? [],
                                            },
                                        },
                                    });
                                }
                            }
                            toast(
                                <Toast
                                    variant="success"
                                    title={formatMessage({ defaultMessage: 'Gegevens geïmporteerd' })}
                                >
                                    <Body variant="small">
                                        <FormattedMessage defaultMessage="Prijzen succesvol geïmporteerd." />
                                    </Body>
                                </Toast>,
                                {
                                    autoClose: 3000,
                                }
                            );
                            setSubmitting(false);
                            dispatch({ type: 'close' });
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        } catch (error: any) {
                            toast(
                                <Toast variant="error" title={formatMessage({ defaultMessage: 'Upload mislukt' })}>
                                    <Body variant="small">{error.message}</Body>
                                </Toast>,
                                {
                                    closeButton: true,
                                }
                            );
                        }
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <Button style={{ position: 'relative' }} submitting={isSubmitting}>
                                <span>
                                    <FormattedMessage defaultMessage="Importeren" />
                                </span>
                                <UploadField />
                            </Button>
                            {hasPrices && (
                                <Box mt={4}>
                                    <Flex alignItems="center" pb={2}>
                                        <Warning style={{ marginRight: '5px' }} />
                                        <Title variant="tiny">
                                            <FormattedMessage defaultMessage="Let op:" />
                                        </Title>
                                    </Flex>
                                    <Body variant="tiny">
                                        <FormattedMessage defaultMessage="Je hebt al prijzen ingeladen. Wanneer je nieuwe prijzen uploadt, worden de huidige prijzen overschreven." />
                                    </Body>
                                </Box>
                            )}
                        </Form>
                    )}
                </Formik>
            ) : (
                <Label>
                    <Skeleton width={120} />
                </Label>
            )}
        </ContentWrapper>
    );
};

export default ImportInfoSidebar;
