import { BrandConfig } from './brandConfig';
import { nlCountryOption } from './countryOptions';

const amelandConfig: BrandConfig = {
    contactUrl: 'https://www.vvvameland.nl/over-ons/contact/',
    countryOptions: [nlCountryOption],
    brandName: 'VVV Ameland',
};

export default amelandConfig;
