import React, { ComponentProps, ComponentType, forwardRef, HTMLAttributes, ReactNode, Ref } from 'react';
import styled, { css } from 'styled-components';
import { getAssignmentColor } from '../../constants/theme';
import { UnreachableCaseError } from '../../entities/UnreachableCaseError';
import useSesame, { Sesame } from '../../hooks/useSesame';
import createBorderCss from '../../utils/createBorderCss';
import Toggle from '../figure/Toggle';
import { Label } from '../primitive/Label';
import Overtitle from '../primitive/Overtitle';

type ButtonVariant = 'primary' | 'outline';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'open'> {
    title?: string;
    overTitle?: string;
    align?: 'right' | 'left';
    withButtonBorder?: boolean;
    badge?: ReactNode;
    Icon?: ComponentType<React.PropsWithChildren<ComponentProps<'svg'>>>;
    variant?: ButtonVariant;
    // uncontrolled
    onToggle?: never;
    open?: never;
}

type ControlledProps = Omit<Props, keyof Sesame> & Pick<Sesame, 'onToggle' | 'open'>;

const isControlled = (props: Props | ControlledProps): props is ControlledProps =>
    'onToggle' in props && 'open' in props;

function ActionMenu(
    {
        title,
        overTitle,
        withButtonBorder = false,
        badge,
        align,
        children,
        Icon = Toggle,
        variant,
        ...props
    }: Props | ControlledProps,
    ref?: Ref<HTMLDivElement>
) {
    const sesameProps = useSesame(false, { closeOnClickOutside: true, closeOnEscape: true });
    const open = isControlled(props) ? props.open : sesameProps.open;
    const onToggle = isControlled(props) ? props.onToggle : sesameProps.onToggle;
    return (
        <Container {...props} ref={ref ?? sesameProps.ref}>
            <ActionButton
                withBadge={!!badge}
                border={withButtonBorder}
                active={open}
                onClick={e => {
                    e.preventDefault();
                    onToggle();
                }}
                variant={variant}
            >
                {badge && <Badge>{badge}</Badge>}
                {(title || overTitle) && (
                    <TextWrapper>
                        <Label as="span">{title}</Label>
                        <Overtitle variant="small">{overTitle}</Overtitle>
                    </TextWrapper>
                )}
                <Icon />
            </ActionButton>
            <MenuWrapper open={open} align={align}>
                {children}
            </MenuWrapper>
        </Container>
    );
}

export default forwardRef(ActionMenu);

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 0.8rem;
`;

const Badge = styled.div`
    height: ${({ theme }) => theme.spacing['50_Semi']};
    width: ${({ theme }) => theme.spacing['50_Semi']};
    border-radius: 50%;
    overflow: hidden;
    > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`;

const Container = styled.div`
    position: relative;
    display: flex;
`;

const MenuWrapper = styled.div<{ open: boolean; align?: 'right' | 'left' }>`
    background: ${({ theme }) => theme.colors.neutral['0']};
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 0 40px 0 rgba(16, 36, 48, 0.1);
    border-radius: 4px;
    min-width: 24rem;
    position: absolute;
    padding: ${({ theme }) => theme.spacing['40_Standard']} 24px;
    top: ${({ theme }) => theme.spacing['70_XLarge']};
    ${({ align = 'left' }) => (align === 'left' ? 'left: 0' : 'right: 0')};
    opacity: ${({ open }) => (open ? 1 : 0)};
    display: ${({ open }) => (open ? 'block' : 'none')};
    word-wrap: break-word;
    z-index: ${({ open }) => (open ? 10 : 0)};
    max-height: 60rem;
    overflow: auto;
`;

interface ActionButtonProps {
    active: boolean;
    border?: boolean;
    withBadge: boolean;
    variant?: ButtonVariant;
}

const primaryButtonCss = css<ActionButtonProps>`
    color: ${({ theme, active }) => (active ? theme.colors.neutral['0'] : theme.colors.primary['100'])};
    background: ${({ theme, active }) =>
        active ? getAssignmentColor(theme, theme.colorAssignments.main, 1) : theme.colors.neutral['0']};
    > svg {
        color: ${({ theme, active }) =>
            active ? theme.colors.neutral['0'] : getAssignmentColor(theme, theme.colorAssignments.main)};
    }
    --border-color: ${({ border, theme, active }) =>
        border
            ? active
                ? getAssignmentColor(theme, theme.colorAssignments.main, 1)
                : theme.colors.neutral['30']
            : 'transparent'};

    :active {
        --border-color: ${({ border, theme }) =>
            border ? getAssignmentColor(theme, theme.colorAssignments.main, 1) : 'transparent'};
    }

    :focus,
    :hover {
        --border-color: ${({ theme, active }) =>
            active
                ? getAssignmentColor(theme, theme.colorAssignments.main, 2)
                : getAssignmentColor(theme, theme.colorAssignments.main)};
    }
`;

const outlineButtonCss = css<ActionButtonProps>`
    color: currentColor;

    --border-color: ${({ border, theme, active }) =>
        border ? (active ? theme.colors.primary['40'] : theme.colors.neutral['30']) : 'transparent'};

    background: ${({ theme, active }) => (active ? theme.colors.primary['5'] : theme.colors.neutral['0'])};
    :hover,
    :focus {
        --border-color: ${({ theme }) => theme.colors.primary['40']};
    }

    :active {
        --border-color: ${({ theme }) => theme.colors.primary['40']};
        background: ${({ theme }) => theme.colors.primary['5']};
    }
`;

const getButtonCss = (variant: ButtonVariant) => {
    switch (variant) {
        case 'primary':
            return primaryButtonCss;
        case 'outline':
            return outlineButtonCss;
        default:
            throw new UnreachableCaseError(variant);
    }
};

export const ActionButton = styled.button<ActionButtonProps>`
    padding: 0 ${({ theme }) => theme.spacing['40_Standard']};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    height: ${({ theme }) => theme.spacing['60_Large']};
    ${({ variant = 'primary' }) => getButtonCss(variant)};
    text-align: start;
    min-width: 4rem;
    outline: none;
    > * + * {
        margin-left: ${({ theme }) => theme.spacing['30_Small']};
    }

    ${createBorderCss('1px')}

    /* Button text styling */
    span {
        margin: 0;
    }

    ${Overtitle} {
        color: ${({ theme, active }) => (!active ? theme.colors.neutral['30'] : 'currentColor')};
    }

    :hover,
    :focus,
    :active {
        ${createBorderCss('2px')}
    }
`;

export const Item = styled.li`
    min-height: ${({ theme }) => theme.spacing['70_XLarge']};
    padding: 0;
    display: flex;
    align-items: center;
    user-select: none;
    + li {
        border-top: 1px solid ${({ theme }) => theme.colors.neutral['20']};
    }
    :hover {
        font-weight: 500;
    }
`;

export const MenuList = styled.ul`
    padding: 0;
    margin: 0;
`;
