import SortableImages from '@oberoninternal/travelbase-ds/components/form/SortableImages';
import { ImageErrorType, UploadImageField } from '@oberoninternal/travelbase-ds/components/form/UploadImage';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { Box } from '@rebass/grid';
import { FieldArray } from 'formik';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { Image } from '../../../../entities/Image';
import useUpload from '../../../../hooks/useUpload';
import FieldSet from '../../../atoms/FieldSet';
import FieldSetHint from '../../../atoms/FieldSetHint';
import FieldHeading from '../../../molecules/FieldHeading';
import LabelBox from '../../../molecules/LabelBox';
import FormScreen from '../../../organisms/FormScreen';
import { isBlob } from '@oberoninternal/travelbase-ds/utils/isBlob';
import { imagesValidation } from '../../../../utils/validations/imagesValidation';
import { getImageFormError, transformImage } from '../../../../utils/imageFormUtils';
import { Maybe } from '@oberoninternal/travelbase-ds/entities/Maybe';
import { ImageContent } from '../../../../entities/ImageContent';

import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import validateImageResolution from '../../../../utils/validateImageResolution';
import { LOGO_IMAGE_MIN_RATIO, LOGO_IMAGE_MAX_RATIO, MAX_UPLOAD_IMAGES } from '../../../../constants/imageRequirements';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

export const companyImageFragment = gql`
    fragment CompanyImage on CompanyImage {
        id
        size
        url
        dimensions
        position
        previewUrl
    }
`;

interface Values {
    listImage: Image | null;
    mainImage: Image | null;
    logoImage: Image | null;
    images: Array<Image | null>;
    videoUrl: Maybe<string>;
}

interface CompanyProps {
    initialValues: Values;
    handleSubmit: (input: Values) => Promise<void>;
    content: {
        header: ImageContent;
        listImage: ImageContent;
        mainImage: ImageContent;
        logoImage: ImageContent;
        images: ImageContent;
        video: ImageContent;
    };
}

const validationSchema = (intl: IntlShape) => imagesValidation('company', intl);

const ImagesFormScreen: FC<React.PropsWithChildren<CompanyProps>> = ({ initialValues, ...props }) => {
    const upload = useUpload();
    const intl = useIntl();
    const { formatMessage } = useIntl();
    return (
        <FormScreen
            initialValues={{ ...initialValues }}
            validationSchema={validationSchema(intl)}
            handleSubmit={async values => {
                let allImages: Array<Image | null> = [];
                const listImage = values.listImage?.file ? transformImage(values.listImage) : null;
                const mainImage = values.mainImage?.file ? transformImage(values.mainImage) : null;
                const logoImage = values.logoImage?.file ? transformImage(values.logoImage) : null;
                const images = values.images.filter((image): image is Image => !!image?.file).map(transformImage);
                allImages = allImages.concat([listImage, mainImage, logoImage, ...images]);
                const { videoUrl } = values;
                await props.handleSubmit({ listImage, mainImage, logoImage, images, videoUrl });

                // to avoid memory leaks we need to revoke the blob files
                allImages.forEach(
                    image => image?.file && isBlob(image.file) && URL.revokeObjectURL(image.file.preview)
                );
            }}
        >
            {({ values, setFieldValue, errors, touched }) => (
                <>
                    <Title>{props.content.header.title}</Title>
                    <p>{props.content.header.description}</p>

                    <FieldHeading title={props.content.listImage.title}>
                        {props.content.listImage.description}
                    </FieldHeading>
                    <FieldSet>
                        <LabelBox>
                            <Label>
                                <FormattedMessage defaultMessage="Stel je lijstfoto in" />
                            </Label>
                            <FieldSetHint>
                                <FormattedMessage
                                    defaultMessage="Verplicht {br} Maximaal 25MB"
                                    values={{
                                        br: <br />,
                                    }}
                                />
                            </FieldSetHint>
                        </LabelBox>
                        <Box width={[1, null, 1 / 2]}>
                            <Box mb={4}>
                                <UploadImageField
                                    name="listImage.file"
                                    uploadActionHandler={(uploadId, file) =>
                                        'listImage' in values &&
                                        setFieldValue('listImage', {
                                            ...values.listImage,
                                            id: undefined,
                                            file,
                                            uploadId,
                                        } as Image)
                                    }
                                    handleUpload={upload}
                                    error={getImageFormError('listImage', errors, touched)}
                                    previewUrl={values.listImage?.previewUrl}
                                    variant="main"
                                    ratio={2 / 3}
                                    validateImage={image => {
                                        if (values.listImage?.file && image) {
                                            if (isBlob(values.listImage.file)) {
                                                const { naturalWidth, naturalHeight } = image;
                                                return validateImageResolution(
                                                    naturalWidth,
                                                    naturalHeight,
                                                    'LISTIMAGE',
                                                    true
                                                );
                                            }
                                        }

                                        if (values.listImage) {
                                            const [width, height] = values.listImage.dimensions;
                                            return validateImageResolution(width, height, 'LISTIMAGE');
                                        }

                                        return [];
                                    }}
                                />
                            </Box>
                        </Box>
                    </FieldSet>
                    <FieldHeading title={props.content.mainImage.title}>
                        {props.content.mainImage.description}
                    </FieldHeading>
                    <FieldSet>
                        <LabelBox>
                            <Label>
                                <FormattedMessage defaultMessage="Stel je hoofdfoto in" />
                            </Label>
                            <FieldSetHint>
                                <FormattedMessage defaultMessage="Maximaal 25MB" />
                            </FieldSetHint>
                        </LabelBox>
                        <Box width={[1, null, 2 / 3]}>
                            <Box mb={4}>
                                <UploadImageField
                                    name="mainImage.file"
                                    previewUrl={values.mainImage?.previewUrl}
                                    uploadActionHandler={(uploadId, file) =>
                                        setFieldValue('mainImage', {
                                            ...values.mainImage,
                                            id: undefined,
                                            file,
                                            uploadId,
                                        } as Image)
                                    }
                                    handleUpload={upload}
                                    variant="main"
                                    ratio={9 / 16}
                                    error={getImageFormError('mainImage', errors, touched)}
                                    validateImage={image => {
                                        if (image && values.mainImage?.file && isBlob(values.mainImage.file)) {
                                            const { naturalWidth, naturalHeight } = image;
                                            return validateImageResolution(
                                                naturalWidth,
                                                naturalHeight,
                                                'MAINIMAGE',
                                                true
                                            );
                                        }

                                        if (values.mainImage) {
                                            const [width, height] = values.mainImage.dimensions;
                                            return validateImageResolution(width, height, 'MAINIMAGE');
                                        }

                                        return [];
                                    }}
                                />
                            </Box>
                        </Box>
                    </FieldSet>
                    <FieldHeading title={props.content.logoImage.title}>
                        {props.content.logoImage.description}
                    </FieldHeading>
                    <FieldSet>
                        <LabelBox>
                            <Label>{/* Stel je logo in */}</Label>
                            <FieldSetHint>{/* Maximaal 25MB */}</FieldSetHint>
                        </LabelBox>
                        <Box width={[1, null, 2 / 3]}>
                            <Box mb={4} width={1} style={{ maxWidth: '30rem' }}>
                                <UploadImageField
                                    previewUrl={values.logoImage?.previewUrl}
                                    name="logoImage.file"
                                    uploadActionHandler={(uploadId, file) =>
                                        setFieldValue('logoImage', {
                                            ...values.logoImage,
                                            id: undefined,
                                            file,
                                            uploadId,
                                        } as Image)
                                    }
                                    handleUpload={upload}
                                    variant="main"
                                    ratio={1 / 1}
                                    error={getImageFormError('logoImage', errors, touched)}
                                    validateImage={image => {
                                        if (!image) {
                                            return [];
                                        }

                                        const { naturalWidth, naturalHeight } = image;
                                        const imageErrors: ImageErrorType[] = [];
                                        if (naturalWidth < 200 || naturalHeight < 200) {
                                            imageErrors.push({
                                                message:
                                                    'Travelbase accepteert geen hoofdfoto kleiner dan 200x200 pixels',
                                                type: 'error',
                                            });
                                        }
                                        if (
                                            naturalWidth / naturalHeight < LOGO_IMAGE_MIN_RATIO ||
                                            naturalWidth / naturalHeight > LOGO_IMAGE_MAX_RATIO
                                        ) {
                                            imageErrors.push({
                                                message:
                                                    'De verhouding van het logo moet tussen vierkant (1:1) en liggend zijn (4:3)',
                                                type: 'error',
                                            });
                                        }

                                        if (naturalWidth < 500 || naturalHeight < 500) {
                                            imageErrors.push({
                                                message:
                                                    'We raden je aan om deze foto te vervangen met een foto van hogere resolutie (500x500 pixels of hoger)',
                                                type: 'warning',
                                            });
                                        }

                                        return imageErrors;
                                    }}
                                />
                            </Box>
                        </Box>
                    </FieldSet>
                    <FieldHeading title={props.content.images.title}>{props.content.images.description}</FieldHeading>
                    <Box pl={[null, null, '26.4rem']}>
                        <FieldArray name="images">
                            {helpers => (
                                <SortableImages
                                    name="images"
                                    helpers={helpers}
                                    handleUpload={upload}
                                    maxNumber={MAX_UPLOAD_IMAGES}
                                    validateImage={image => {
                                        const imageInValues = values.images.find(
                                            img => img?.previewUrl === image?.src
                                        )?.file;

                                        if (image && imageInValues && isBlob(imageInValues)) {
                                            const { naturalWidth, naturalHeight } = image;
                                            return validateImageResolution(
                                                naturalWidth,
                                                naturalHeight,
                                                'DETAILIMAGE',
                                                true
                                            );
                                        }

                                        if (imageInValues) {
                                            const imageDimensions =
                                                values.images.find(img => img?.previewUrl === image?.src)?.dimensions ??
                                                [];
                                            const [width, height] = imageDimensions;
                                            return validateImageResolution(width, height, 'DETAILIMAGE');
                                        }

                                        return [];
                                    }}
                                />
                            )}
                        </FieldArray>
                    </Box>
                    <FieldHeading title={props.content.video.title}>{props.content.video.description}</FieldHeading>
                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="videoUrl">
                                <FormattedMessage defaultMessage=" URL van video" />
                            </Label>
                        </LabelBox>
                        <Box width={1}>
                            <TextInputField
                                id="videoUrl"
                                placeholder={formatMessage({ defaultMessage: 'URL' })}
                                name="videoUrl"
                            />
                        </Box>
                    </FieldSet>
                </>
            )}
        </FormScreen>
    );
};

export default ImagesFormScreen;
