import React, { Component, PropsWithChildren } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Error, { ErrorProps } from '../pages/Error';
import NotFound from '../pages/NotFound';
import { isApolloError, ServerError } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { FormattedMessage } from 'react-intl';

const isServerError = (error: Error): error is ServerError => error.name === 'ServerError';

class ErrorBoundary extends Component<ErrorProps & RouteComponentProps & PropsWithChildren> {
    state = {
        error: null as Error | null,
    };

    componentDidUpdate(prevProps: RouteComponentProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                error: null,
            });
        }
    }

    componentDidCatch(error: Error) {
        if (
            isApolloError(error) &&
            error.networkError &&
            isServerError(error.networkError) &&
            (error.networkError.statusCode >= 400 || error.networkError.statusCode < 500)
        ) {
            this.props.history.push('/login', { from: this.props.history.location.pathname });
            return;
        }

        Sentry.captureException(error);

        if (error.name === 'MutationError') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

        this.setState({
            error,
        });
    }

    render() {
        const { error } = this.state;
        if (error) {
            switch (error.name) {
                case 'NotFoundError':
                    return <NotFound />;
                case 'MutationError':
                    return (
                        <>
                            <Error {...this.props}>
                                <FormattedMessage defaultMessage="Er is iets misgegaan. Probeer het later opnieuw." />
                            </Error>
                            {this.props.children}
                        </>
                    );
                case 'ConfigurationError':
                    return (
                        <Error {...this.props}>
                            {error.message}
                            <br />
                            <FormattedMessage defaultMessage="Dit is een configuratiefout, neem contact op met ons." />
                        </Error>
                    );
                default:
                    return (
                        <Error {...this.props}>
                            <FormattedMessage defaultMessage="Er is iets misgegaan. Probeer het later opnieuw." />
                        </Error>
                    );
            }
        }

        return this.props.children;
    }
}

export default withRouter(ErrorBoundary);
