import React, { memo } from 'react';
import BulkEditSidebar from './BulkEditSidebar';
import DayinfoSidebar from './DayinfoSidebar';
import PeriodRuleSidebar from './PeriodRuleSidebar';
import AllotmentSidebar from './AllotmentSidebar';
import LockoutSidebar from './LockoutSidebar';
import BookingSidebar from './BookingSidebar';
import { SidebarData } from '../../context/sidebar';
import ImportInfoSidebar from './ImportInfoSidebar';
import SpecialSidebar from './SpecialSidebar';
import AllotmentBulkSidebar from './AllotmentBulkSidebar';
import TimeslotsSidebar from './TimeslotsSidebar';
import PricingConfigSidebar from './PricingConfigSidebar';

export interface RootSidebarData {
    open?: boolean;
}

interface Props {
    data?: SidebarData;
}

const SidebarContent = memo(({ data }: Props) => {
    if (!data) {
        return null;
    }

    switch (data.type) {
        case 'BULK_SIDEBAR':
            return <BulkEditSidebar data={data} />;
        case 'DAYINFO_SIDEBAR':
            return <DayinfoSidebar data={data} />;
        case 'PERIOD_RULE':
            return <PeriodRuleSidebar data={data} />;
        case 'ALLOTMENT_SIDEBAR':
            return <AllotmentSidebar data={data} />;
        case 'ALLOTMENT_BULK_SIDEBAR':
            return <AllotmentBulkSidebar data={data} />;
        case 'LOCKOUT':
            return <LockoutSidebar data={data} />;
        case 'PRICING_CONFIG':
            return <PricingConfigSidebar data={data} />;
        case 'BOOKING':
            return <BookingSidebar data={data} />;
        case 'IMPORT_INFO':
            return <ImportInfoSidebar data={data} />;
        case 'SPECIAL':
            return <SpecialSidebar data={data} />;
        case 'TIMESLOTS':
            return <TimeslotsSidebar data={data} />;
        default:
            return null;
    }
});

export default SidebarContent;
