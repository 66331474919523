import styled from 'styled-components/macro';
import { Box } from '@rebass/grid';
import FieldSetHint from '../atoms/FieldSetHint';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import React, { FC } from 'react';
import {
    ActivityAgreement,
    AgreementsFragment,
    BookingTypeEnum,
    ContractsFragment,
    RentalUnitContract,
} from '../../generated/graphql';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import format from 'date-fns/format';
import { nl } from 'date-fns/locale';
import FieldSet from '../atoms/FieldSet';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { CheckboxField } from '@oberoninternal/travelbase-ds/components/form/Checkbox';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import FormScreen from '../organisms/FormScreen';
import FieldHeading from './FieldHeading';
import { MutationFunction } from '@apollo/client';

export const Agreement: FC<
    React.PropsWithChildren<{ data: AgreementsFragment | ContractsFragment; initialValues: Record<string, boolean> }>
> = ({ data, initialValues }) => {
    const accepted = initialValues[data.id];
    let agreement;
    let agreementUrl;
    let toBePaidThroughTor = true;
    let bookingType = BookingTypeEnum.Direct;
    if (data.__typename === 'RentalUnitContract') {
        agreement = data.contract;
        agreementUrl = agreement.contractUrl;
        toBePaidThroughTor = data.contract.toBePaidThroughTor;
        bookingType = data.contract.bookingType;
    } else if (data.__typename === 'ActivityAgreement') {
        agreement = data.agreement;
        agreementUrl = agreement?.agreementUrl;
    }
    return (
        <>
            <StyledAgreement>
                <AgreementData>
                    <Title variant="small">{agreement?.name}</Title>
                    <AgreementDataBox>
                        <div>
                            <AgreementDataLabel>
                                <FormattedMessage defaultMessage="Ingangsdatum" />
                            </AgreementDataLabel>
                            <AgreementDataText>
                                {format(new Date(data.startDate), 'd MMMM yyyy', {
                                    locale: nl,
                                })}
                            </AgreementDataText>
                            {data.endDate && (
                                <>
                                    <AgreementDataLabel>
                                        <FormattedMessage defaultMessage="Einddatum" />
                                    </AgreementDataLabel>
                                    <AgreementDataText>
                                        {format(new Date(data.endDate), 'd MMMM yyyy', {
                                            locale: nl,
                                        })}
                                    </AgreementDataText>
                                </>
                            )}
                        </div>
                        <div>
                            <AgreementDataLabel>
                                <FormattedMessage defaultMessage="Provisie" />
                            </AgreementDataLabel>
                            <AgreementDataText>
                                <FormattedNumber
                                    value={(agreement?.commissionPercentage ?? 0) / 100}
                                    // eslint-disable-next-line react/style-prop-object
                                    style="percent"
                                    minimumFractionDigits={2}
                                />
                            </AgreementDataText>
                        </div>
                        <div>
                            <AgreementDataLabel>
                                <FormattedMessage defaultMessage="Boekbaarheid" />
                            </AgreementDataLabel>
                            <AgreementDataText>
                                {bookingType === BookingTypeEnum.Direct ? (
                                    <FormattedMessage defaultMessage="Direct" />
                                ) : bookingType === BookingTypeEnum.OnRequest ? (
                                    <FormattedMessage defaultMessage="Op aanvraag" />
                                ) : (
                                    <FormattedMessage defaultMessage="Niet boekbaar" />
                                )}
                            </AgreementDataText>
                        </div>
                        <div>
                            <AgreementDataLabel>
                                <FormattedMessage defaultMessage="Betaling" />
                            </AgreementDataLabel>
                            <AgreementDataText>
                                {toBePaidThroughTor ? (
                                    <FormattedMessage defaultMessage="Via Travelbase" />
                                ) : (
                                    <FormattedMessage defaultMessage="Rechtstreeks" />
                                )}
                            </AgreementDataText>
                        </div>
                    </AgreementDataBox>
                </AgreementData>
                <StyledButton variant="primary">
                    <a href={`${agreementUrl}`}>
                        <FormattedMessage defaultMessage="Lees voorwaarden" />
                    </a>
                </StyledButton>
            </StyledAgreement>
            <FieldSet>
                <Box ml={[0, null, null, null, '26.4rem']}>
                    {!accepted && (
                        <Body style={{ marginBottom: '3.2rem' }}>
                            <FormattedMessage
                                defaultMessage="Verhuurder verklaart volledig geïnformeerd te zijn over de rechten en verplichtingen die
                            voor Verhuurder voortvloeien uit de hiervoor genoemde overeenkomst met ons en deze
                            overeenkomst met de daarop toepasselijke algemene voorwaarden* te hebben gelezen en te
                            aanvaarden."
                            />
                        </Body>
                    )}
                    <Box width="auto" my="auto">
                        <CheckboxField id={data.id} name={data.id} disabled={accepted}>
                            <FormattedMessage defaultMessage="Voor instemming overeenkomst en voor akkoord en ontvangst algemene voorwaardens" />
                        </CheckboxField>
                    </Box>
                </Box>
            </FieldSet>
        </>
    );
};

type SharedAgreementProperties = {
    id: string;
    approvalDate: string | null;
    startDate: string;
    endDate: string | null;
};

export type AgreementType =
    | (RentalUnitContract & SharedAgreementProperties)
    | (ActivityAgreement & SharedAgreementProperties);

type AgreementProps = {
    agreements: AgreementType[];
    mutate: MutationFunction;
};

const AgreementsForm: FC<React.PropsWithChildren<AgreementProps>> = ({ agreements, mutate }) => {
    const { formatMessage } = useIntl();
    let initialValues: Record<string, boolean> = {};
    for (const { id, approvalDate } of agreements) {
        initialValues = {
            ...initialValues,
            [id]: !!approvalDate,
        };
    }

    const currentDay = new Date();

    const unsignedAgreements = agreements.filter(
        (agreement: { approvalDate: null; startDate: string | number | Date }) => {
            if ('approvalDate' in agreement && 'startDate' in agreement) {
                return agreement.approvalDate === null || isAfter(new Date(agreement.startDate), currentDay);
            }
            return false;
        }
    );

    const signedAgreement = agreements.find(
        (agreement: { approvalDate: null; startDate: string | number | Date; endDate: string | number | Date }) => {
            if ('approvalDate' in agreement && 'startDate' in agreement && 'endDate' in agreement) {
                return (
                    agreement.approvalDate !== null &&
                    isBefore(new Date(agreement.startDate), currentDay) &&
                    (isAfter(new Date(agreement.endDate), currentDay) || !agreement.endDate)
                );
            }
            return false;
        }
    );

    return (
        <FormScreen
            initialValues={initialValues}
            handleSubmit={async values => {
                const mutations: Array<Promise<unknown>> = [];
                for (const [id, signed] of Object.entries(values)) {
                    if (signed !== initialValues[id]) {
                        mutations.push(
                            mutate({
                                variables: {
                                    id,
                                },
                            })
                        );
                    }
                }
                await Promise.all(mutations);
            }}
        >
            {() => (
                <>
                    <Title variant="regular">
                        <FormattedMessage defaultMessage="Overeenkomsten" />
                    </Title>
                    <p>
                        {agreements.map(agreement => {
                            if (agreement.__typename === 'RentalUnitContract') {
                                return (
                                    <FormattedMessage
                                        defaultMessage="Hier kun je de samenwerkingsvoorwaarden met de VVV doornemen en accepteren. Je accommodatie
                                        wordt op de website van de VVV getoond als je de overeenkomst met betrekking tot de verhuur van
                                        jouw accommodatie hebt geaccepteerd."
                                    />
                                );
                            }
                            if (agreement.__typename === 'ActivityAgreement') {
                                return (
                                    <FormattedMessage
                                        defaultMessage="Hier kun je de voorwaarden voor samenwerking met onze boekingspartner bekijken en goedkeuren.
                                        Jouw activiteiten worden op onze website getoond zodra je akkoord gaat met de overeenkomst met betrekking tot
                                        het boeken van jouw activiteiten."
                                    />
                                );
                            }
                            return null;
                        })}
                    </p>
                    {unsignedAgreements.length > 0 && (
                        <>
                            <FieldHeading title={formatMessage({ defaultMessage: 'Nieuwe overeenkomsten' })}>
                                <FormattedMessage defaultMessage="Er is een nieuwe overeenkomst beschikbaar. Bekijk hem hier." />
                            </FieldHeading>
                            {unsignedAgreements.map((agreement: AgreementsFragment | ContractsFragment) => (
                                <Agreement data={agreement} initialValues={initialValues} key={agreement.id} />
                            ))}
                        </>
                    )}
                    {signedAgreement && (
                        <>
                            <FieldHeading title={formatMessage({ defaultMessage: 'Huidige overeenkomst' })}>
                                <FormattedMessage defaultMessage="Je huidige Travelbase overeenkomst." />
                            </FieldHeading>
                            <Agreement data={signedAgreement} initialValues={initialValues} />
                        </>
                    )}
                </>
            )}
        </FormScreen>
    );
};

export const StyledAgreement = styled(Box).attrs({ p: '5' })`
    display: flex;
    background: ${({ theme }) => theme.colors.neutral[0]};
    border-radius: 8px;
    border: 1px solid #dce2e3;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.04);
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: ${({ theme }) => theme.spacing['50_Semi']};

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
        max-width: 60vw;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        max-width: none;
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        flex-direction: row;
        min-height: 144px;
        width: 100%;
    }
    @media (min-width: ${({ theme }) => theme.mediaQueries.xxl}) {
        margin-left: 26.4rem;
        width: 80%;
    }
`;

export const AgreementData = styled(Box)`
    display: flex;
    flex-direction: column;
`;

export const AgreementDataBox = styled.div`
    display: flex;
    margin-top: 16px;
    flex-direction: column;
    height: 100%;

    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        flex-direction: row;
        justify-content: space-between;
        max-width: 600px;
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        min-width: 500px;
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.xxl}) {
        min-width: 630px;
        max-width: 800px;
    }

    > div:not(:last-child) {
        margin-right: 56px;
    }

    > div:last-child {
        margin-right: 2.4rem;
    }
`;

export const AgreementDataLabel = styled(FieldSetHint)`
    color: ${({ theme }) => theme.colors.primary[90]};
`;

export const AgreementDataText = styled.p`
    margin-top: 4px;
    @media (min-width: ${({ theme }) => theme.mediaQueries.xxl}) {
        font-size: 20px;
    }
`;

export const StyledButton = styled(Button)`
    align-self: flex-start;
    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        align-self: center;
    }

    a {
        text-decoration: none;
        color: white;
    }
`;

export default AgreementsForm;
