import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Checkbox from '@oberoninternal/travelbase-ds/components/form/Checkbox';
import { FormActionsContainer } from '@oberoninternal/travelbase-ds/components/form/FormActions';
import Sidebar from '@oberoninternal/travelbase-ds/components/layout/Sidebar';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { MenuStateProps } from '@oberoninternal/travelbase-ds/hooks/useMenuState';
import useWeekdays from '@oberoninternal/travelbase-ds/hooks/useWeekdays';
import { Box } from '@rebass/grid';
import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping';
import format from 'date-fns/format';
import { FieldValidator, useField, useFormikContext } from 'formik';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { getDateOpts } from '../../constants/dateOpts';
import { weekdays } from '../../utils/createWeekdayRecord';
import { formatDuration } from '../../utils/formatDuration';
import parseDate from '@oberoninternal/travelbase-ds/utils/parseDate';
import ContentWrapper from '../atoms/ContentWrapper';
import { Seperator } from '../atoms/Seperator';
import SidebarField from '../atoms/SidebarField';
import SidebarIntro from '../atoms/SidebarIntro';
import { SpecialHoursValue } from '../pages/company/hours/SpecialHours';
import RangePickerInputField from './RangePickerInputField';
import TimeOpenClosedField from './TimeOpenClosedField';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props extends MenuStateProps {
    index: number;
}

const SpecialHoursSidebar: FC<React.PropsWithChildren<Props>> = ({ setOpen, index, ...menuStateProps }) => {
    const name = `periods.${index}`;
    const weekdaysForDisplay = useWeekdays();
    const bag = useFormikContext<{ periods: SpecialHoursValue[] }>();
    const { setFieldValue, dirty, resetForm, isSubmitting, values } = bag;
    const { formatMessage, locale } = useIntl();
    const dateOpts = getDateOpts(locale as 'nl' | 'de' | 'en');
    const validate = (val: SpecialHoursValue) => {
        for (const [i, period] of Object.entries(values.periods)) {
            // don't compare the period with itself
            if (Number(i) === index || !val.startDate || !val.endDate) {
                continue;
            }
            if (
                areIntervalsOverlapping(
                    { start: new Date(val.startDate), end: new Date(val.endDate) },
                    { start: new Date(period.startDate), end: new Date(period.endDate) },
                    { inclusive: true }
                )
            ) {
                const durationText = formatDuration(parseDate(period.startDate), parseDate(period.endDate));
                return { startDate: `Deze periode overlapt met de periode van ${durationText}` };
            }
        }
        return undefined;
    };

    const [{ value }, { initialValue }] = useField<SpecialHoursValue>({
        name,
        validate: validate as FieldValidator,
    });

    // if the field doesn't have an initialValue it means it's added after Formik is initialized, thus a new period
    const isNewPeriod = !initialValue;

    const onClose = () => {
        if (dirty) {
            if (
                !window.confirm(
                    formatMessage({ defaultMessage: 'Weet je zeker? Niet opgeslagen gegevens gaan verloren' })
                )
            ) {
                return;
            }
            setTimeout(resetForm, isNewPeriod ? 400 : 0);
        }
        setOpen(false);
    };

    return (
        <Sidebar setOpen={open => (open ? setOpen(true) : onClose())} {...menuStateProps}>
            {value && (
                <ContentWrapper variant="sidebar">
                    <SidebarIntro
                        title={
                            isNewPeriod
                                ? formatMessage({ defaultMessage: 'Toevoegen' })
                                : formatMessage({ defaultMessage: 'Aanpassen' })
                        }
                    >
                        <FormattedMessage defaultMessage="Afwijkende openingstijden" />
                    </SidebarIntro>
                    <Seperator variant="small" style={{ marginBottom: 0 }} />
                    <SidebarField label={formatMessage({ defaultMessage: 'Periode' })}>
                        <RangePickerInputField fromFieldName={`${name}.startDate`} toFieldName={`${name}.endDate`} />
                    </SidebarField>
                    <Box>
                        {weekdays.map((day, weekdayIndex) => {
                            const weekdayValue = value[day];
                            const openClosedFieldName = `${name}.${day}`;
                            return (
                                <WeekdayItem key={day}>
                                    <Checkbox
                                        id={day}
                                        checked={!!weekdayValue}
                                        onChange={({ currentTarget: { checked } }) => {
                                            const newValue = checked ? [] : null;
                                            setFieldValue(openClosedFieldName, newValue);
                                        }}
                                    >
                                        {format(weekdaysForDisplay[weekdayIndex], 'EEEE', dateOpts)}
                                    </Checkbox>

                                    {weekdayValue && (
                                        <>
                                            {!!weekdayValue.length && (
                                                <Body style={{ margin: '0.8rem 0' }}>
                                                    <FormattedMessage defaultMessage="Open van - tot" />
                                                </Body>
                                            )}
                                            <TimeOpenClosedField variant="small" name={openClosedFieldName} />
                                        </>
                                    )}
                                </WeekdayItem>
                            );
                        })}
                    </Box>
                </ContentWrapper>
            )}

            <FormActionsContainer dirty={dirty} variant="sidebar">
                {!isNewPeriod && (
                    <TextButton
                        disabled={!dirty}
                        onClick={() => window.confirm(formatMessage({ defaultMessage: 'Zeker weten?' })) && resetForm()}
                    >
                        <FormattedMessage defaultMessage="Ongedaan maken" />
                    </TextButton>
                )}
                <Button disabled={!dirty} submitting={isSubmitting} style={{ marginLeft: 'auto' }} type="submit">
                    <FormattedMessage defaultMessage="Opslaan" />
                </Button>
            </FormActionsContainer>
        </Sidebar>
    );
};

export default SpecialHoursSidebar;

const WeekdayItem = styled.div`
    padding: ${({ theme }) => theme.spacing['40_Standard']} 0;
    border-top: 1px solid ${({ theme }) => theme.colors.neutral['20']};

    > label {
        text-transform: capitalize;
    }
`;
