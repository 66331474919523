import { PLACEHOLDER_ID } from '@oberoninternal/travelbase-ds/components/calendar/Allotment';
import { AllotmentsLockoutFragment } from '../generated/graphql';
import { DocumentNode } from 'graphql';
import { OperationVariables, useApolloClient } from '@apollo/client';

import { PricingQueryShape } from '../components/organisms/Pricing';
import { useCallback } from 'react';
import isWithinInterval from 'date-fns/isWithinInterval';
import parseDate from '@oberoninternal/travelbase-ds/utils/parseDate';

export const replaceAllotmentAmountForLockoutInterval = (
    rentalUnit: PricingQueryShape['rentalUnit'],
    lockout: AllotmentsLockoutFragment,
    amount: number
) =>
    rentalUnit?.allotments.map(allotment => {
        if (
            isWithinInterval(new Date(allotment.date), {
                start: parseDate(lockout.startDate),

                // lockouts are up to the enddate so we don't include it
                end: parseDate(lockout.endDate),
            })
        ) {
            return { ...allotment, amount };
        }
        return allotment;
    }) ?? [];

export const useWriteLockout = <V extends OperationVariables>(query: DocumentNode, variables?: V) => {
    const client = useApolloClient();

    return useCallback(
        (lockout: AllotmentsLockoutFragment | null) => {
            const data = client.readQuery<PricingQueryShape>({
                query,
                variables,
            });

            const rentalUnit = data?.rentalUnit;

            if (!rentalUnit) {
                return;
            }

            let allotmentLockouts: AllotmentsLockoutFragment[] =
                rentalUnit.allotmentLockouts?.filter(({ id }) =>
                    lockout ? id !== lockout.id : id !== PLACEHOLDER_ID
                ) ?? [];

            if (lockout) {
                allotmentLockouts = allotmentLockouts.filter(({ id }) => id !== lockout.id);
                allotmentLockouts.push(lockout);
            }

            client.writeQuery<PricingQueryShape>({
                query,
                data: {
                    rentalUnit: {
                        ...rentalUnit,
                        allotmentLockouts,
                        allotments:
                            lockout && lockout.id !== PLACEHOLDER_ID
                                ? replaceAllotmentAmountForLockoutInterval(rentalUnit, lockout, 0)
                                : rentalUnit.allotments,
                    },
                },
                variables,
            });
        },
        [client, query, variables]
    );
};
