import Switch from '@oberoninternal/travelbase-ds/components/form/Switch';
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import endOfDay from 'date-fns/endOfDay';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import startOfDay from 'date-fns/startOfDay';
import gql from 'graphql-tag';
import isEqual from 'lodash/isEqual';
import React, { FC, memo, useEffect } from 'react';
import { areEqual, ListChildComponentProps } from 'react-window';
import epoch from '../../constants/epoch';
import { PRICE_COLUMN_ROW_COUNT } from '../../constants/priceRows';
import { useSidebar } from '../../context/sidebar';
import {
    AllotmentLockout,
    AllotmentsBookingFragment,
    AllotmentsLockoutFragment,
    PriceColumnPricingFragment,
    PricesDocument,
    PricesRowVisibilityFragment,
    Scalars,
    useEditArrivalAllowedMutation,
    useEditBaseStayPriceMutation,
    useEditDepartureAllowedMutation,
    useEditExtraPersonPriceMutation,
    useEditMinimumStayDurationMutation,
    useEditMinimumStayPriceMutation,
    useEditNightPriceMutation,
    useEditWeekPriceMutation,
} from '../../generated/graphql';
import useIsBulkEditing, { Weekday } from '../../hooks/useIsBulkEditing';
import { visibilityKeys } from '../../utils/getRowVisibility';
import { getIsWithinPrimaryPricing } from '../../utils/primarypricing';
import SwitchCell from '../atoms/SwitchCell';
import TextInputCell from '../atoms/TextInputCell';
import { BulkEditValues } from '../molecules/BulkEditForm';
import Cell from '../molecules/Cell';
import PricingColumnAllotment from '../molecules/PricingColumnAllotment';
import PricingColumnContainer from '../molecules/PricingColumnContainer';
import SkeletonCell from '../molecules/SkeletonCell';
import { PricingColumnData } from './Pricing';

export interface PriceColumnData extends PricingColumnData {
    datePricings: PriceColumnPricingFragment[];
    values: BulkEditValues;
    initialValues: BulkEditValues;
    rentalUnitId: Scalars['ID']['output'];
    rowVisibility: PricesRowVisibilityFragment | null;
    datePricingStartDate?: Date;
    datePricingEndDate?: Date;
}

interface Props extends ListChildComponentProps {
    data: PriceColumnData;
}

export const priceColumnFragment = gql`
    fragment PriceColumnPricing on DatePricing {
        id
        date
        arrivalAllowed
        departureAllowed
        nightPrice
        extraPersonPrice
        minimumStayDuration
        weekPrice
        baseStayPrice
        minimumStayPrice
    }

    fragment PriceColumnAllotment on Allotment {
        amount
        id
        date
    }

    mutation editArrivalAllowed($id: ID!, $arrivalAllowed: Boolean!) {
        editDatePricingArrivalAllowed(input: { datePricingId: $id, arrivalAllowed: $arrivalAllowed }) {
            datePricing {
                id
                arrivalAllowed
            }
        }
    }
    mutation editDepartureAllowed($id: ID!, $departureAllowed: Boolean!) {
        editDatePricingDepartureAllowed(input: { datePricingId: $id, departureAllowed: $departureAllowed }) {
            datePricing {
                id
                departureAllowed
            }
        }
    }
    mutation editNightPrice($id: ID!, $nightPrice: Float!) {
        editDatePricingNightPrice(input: { datePricingId: $id, nightPrice: $nightPrice }) {
            datePricing {
                id
                nightPrice
            }
        }
    }
    mutation editExtraPersonPrice($id: ID!, $extraPersonPrice: Float!) {
        editDatePricingExtraPersonPrice(input: { datePricingId: $id, extraPersonPrice: $extraPersonPrice }) {
            datePricing {
                id
                extraPersonPrice
            }
        }
    }
    mutation editMinimumStayDuration($id: ID!, $minimumStayDuration: Int!) {
        editDatePricingMinimumStayDuration(input: { datePricingId: $id, minimumStayDuration: $minimumStayDuration }) {
            datePricing {
                id
                minimumStayDuration
            }
        }
    }
    mutation editWeekPrice($id: ID!, $weekPrice: Float) {
        editDatePricingWeekPrice(input: { datePricingId: $id, weekPrice: $weekPrice }) {
            datePricing {
                id
                weekPrice
            }
        }
    }
    mutation editBaseStayPrice($id: ID!, $baseStayPrice: Float!) {
        editDatePricingBaseStayPrice(input: { datePricingId: $id, baseStayPrice: $baseStayPrice }) {
            datePricing {
                id
                baseStayPrice
            }
        }
    }
    mutation editMinimumStayPrice($id: ID!, $minimumStayPrice: Float!) {
        editDatePricingMinimumStayPrice(input: { datePricingId: $id, minimumStayPrice: $minimumStayPrice }) {
            datePricing {
                id
                minimumStayPrice
            }
        }
    }
`;

const getDatePricing = (index: number, datePricings: PriceColumnPricingFragment[], loadedPeriods: Interval[]) => {
    const date = addDays(epoch, index);
    return {
        date,
        data: datePricings.find(pricing => isSameDay(new Date(pricing.date), date)),
        loading: !loadedPeriods.some(interval => isWithinInterval(date, interval)),
    };
};

const eventIsWaivedOrCanceled = (event: AllotmentsBookingFragment) =>
    event.status === 'WAIVED' ||
    event.status === 'CANCELLED_EXTERNAL' ||
    event.status === 'CANCELLED_GUEST' ||
    event.status === 'CANCELLED_PARTNER';

const getBookingEvents = (prevDayEvents: (AllotmentsBookingFragment | AllotmentsLockoutFragment)[]) =>
    prevDayEvents.filter(event => event.__typename === 'Booking') as AllotmentsBookingFragment[];

const getAllotmentLockoutEvents = (prevDayEvents: (AllotmentsBookingFragment | AllotmentsLockoutFragment)[]) =>
    prevDayEvents.filter(event => event.__typename === 'AllotmentLockout') as AllotmentLockout[];

const PriceColumn: FC<React.PropsWithChildren<Props>> = memo(
    ({
        style,
        index,
        data: {
            datePricings,
            values,
            maxAllotment,
            allotments,
            initialValues,
            lockoutCreationProps,
            rowVisibility,
            datePricingStartDate,
            datePricingEndDate,
            loadedPeriods,
            initialVars,
            showAllotmentLockouts,
            getDayEvents,
        },
    }: Props) => {
        const { date, data, loading } = getDatePricing(index, datePricings, loadedPeriods);
        const allotment = allotments.find(allot => isSameDay(new Date(allot.date), date));
        const startDateIsInFuture = !!datePricingStartDate && isAfter(datePricingStartDate, endOfDay(date));
        const outsidePrimaryPricing = !getIsWithinPrimaryPricing(datePricingStartDate, datePricingEndDate, date);
        const isCompact = maxAllotment === 1;
        const prevDay = subDays(date, 1);
        const prevDayAllotment = allotments.find(allot => isSameDay(new Date(allot.date), prevDay));
        const allDayEvents = getDayEvents(date, true);
        const prevDayEvents = getDayEvents(prevDay, true);

        const bookingEvents = getBookingEvents(allDayEvents);

        const hasLockout = isCompact && !!getAllotmentLockoutEvents(allDayEvents).length;
        const isWaivedOrCanceledEvent =
            !!bookingEvents.length && bookingEvents.every(event => eventIsWaivedOrCanceled(event));

        const forbidden =
            (!isWaivedOrCanceledEvent && allotment?.amount === 0 && isAfter(date, new Date())) || hasLockout;
        const isUnbookable = isBefore(date, startOfDay(new Date())) || outsidePrimaryPricing || forbidden;
        const isDisabled = !data && !loading;
        const isMultiple = maxAllotment > 1;
        const departureDayDisabled =
            (hasLockout && prevDayAllotment?.amount === 0) ||
            (!isWaivedOrCanceledEvent &&
                allotment?.amount === 0 &&
                prevDayAllotment?.amount === 0 &&
                (!!prevDayEvents.length || !allDayEvents.some(event => isSameDay(new Date(event.startDate), date))));

        const [mutateArrivalAllowed] = useEditArrivalAllowedMutation();
        const [mutateDepartureAllowed] = useEditDepartureAllowedMutation();
        const [mutateNightPrice] = useEditNightPriceMutation();
        const [mutateExtraPersonPrice] = useEditExtraPersonPriceMutation();
        const [mutateMinimumStayDuration] = useEditMinimumStayDurationMutation();
        const [mutateMinimumStayPrice] = useEditMinimumStayPriceMutation();
        const [mutateBaseStayPrice] = useEditBaseStayPriceMutation();
        const [mutateWeekPrice] = useEditWeekPriceMutation();
        const [state, dispatch] = useSidebar();

        const isBulkEdited = (dayName: Weekday) => values[dayName] !== initialValues[dayName];

        const isBulkEditing = useIsBulkEditing({ date, isBulkEdited, values });
        const handleInfoClick = () => {
            if (data) {
                dispatch({ type: 'show', data: { type: 'DAYINFO_SIDEBAR', columnData: data } });
            }
        };

        // when the column data gets updated the sidebar should also update if it's open.
        useEffect(() => {
            if (!data || !state.open) {
                return;
            }
            if (state.data?.type === 'DAYINFO_SIDEBAR' && state.data?.columnData.id === data.id) {
                if (data !== state.data.columnData) {
                    dispatch({ type: 'show', data: { type: 'DAYINFO_SIDEBAR', columnData: data } });
                }
            }
        }, [state, data, dispatch]);

        const onSave = async (promise: Promise<unknown>) => {
            await promise;
        };

        const cellProps = { disabled: isUnbookable, date };
        return (
            <PricingColumnContainer style={style} isUnusable={isUnbookable || isDisabled || hasLockout}>
                <PricingColumnAllotment
                    showAllotmentLockouts={showAllotmentLockouts}
                    allotment={allotment}
                    maxAllotment={maxAllotment}
                    loading={loading}
                    unbookable={isUnbookable}
                    disabled={isDisabled || startDateIsInFuture}
                    date={date}
                    style={{ cursor: isMultiple || !showAllotmentLockouts ? 'pointer' : 'e-resize' }}
                    data-date={date.toISOString()}
                    onInfoClick={handleInfoClick}
                    tooltipText="Bekijk het tarief van boekbare aankomsten op deze dag"
                    document={PricesDocument}
                    variables={initialVars}
                    forbidden={forbidden}
                    {...lockoutCreationProps}
                />
                {loading &&
                    new Array(PRICE_COLUMN_ROW_COUNT + 1)
                        .fill(SkeletonCell)
                        .filter((_, i) =>
                            rowVisibility && i in visibilityKeys ? rowVisibility[visibilityKeys[i]] : true
                        )
                        .map((E, i) => <E key={i} i={i} />)}

                {isDisabled && !loading && <NoDataColumn rowVisibility={rowVisibility} />}

                {!loading && data && (
                    <>
                        {rowVisibility?.showArrivalAllowedRow && (
                            <SwitchCell
                                selected={isBulkEditing('arrivalAllowed')}
                                hasSeparator
                                value={data.arrivalAllowed}
                                onClick={() => {
                                    const variables = { id: data.id, arrivalAllowed: !data.arrivalAllowed };
                                    onSave(
                                        mutateArrivalAllowed({
                                            variables,
                                            optimisticResponse: {
                                                editDatePricingArrivalAllowed: {
                                                    __typename: 'EditDatePricingArrivalAllowedPayload',
                                                    datePricing: { ...variables, __typename: 'DatePricing' },
                                                },
                                            },
                                        })
                                    );
                                }}
                                switchId={`arrival-${data.id}`}
                                {...cellProps}
                            />
                        )}
                        {rowVisibility?.showDepartureAllowedRow && (
                            <SwitchCell
                                selected={isBulkEditing('departureAllowed')}
                                value={data.departureAllowed}
                                onClick={() => {
                                    const variables = { id: data.id, departureAllowed: !data.departureAllowed };
                                    onSave(
                                        mutateDepartureAllowed({
                                            variables,
                                            optimisticResponse: {
                                                editDatePricingDepartureAllowed: {
                                                    __typename: 'EditDatePricingDepartureAllowedPayload',
                                                    datePricing: { ...variables, __typename: 'DatePricing' },
                                                },
                                            },
                                        })
                                    );
                                }}
                                switchId={`departure-${data.id}`}
                                {...cellProps}
                                disabled={departureDayDisabled}
                            />
                        )}
                        {rowVisibility?.showNightPriceRow && (
                            <TextInputCell
                                value={data.nightPrice}
                                isCurrency
                                selected={isBulkEditing('nightPrice')}
                                onChange={val => {
                                    const variables = { id: data.id, nightPrice: val };
                                    onSave(
                                        mutateNightPrice({
                                            variables,
                                            optimisticResponse: {
                                                editDatePricingNightPrice: {
                                                    __typename: 'EditDatePricingNightPricePayload',
                                                    datePricing: { ...variables, __typename: 'DatePricing' },
                                                },
                                            },
                                        })
                                    );
                                }}
                                {...cellProps}
                            />
                        )}
                        {rowVisibility?.showExtraPersonPriceRow && (
                            <TextInputCell
                                value={data.extraPersonPrice}
                                isCurrency
                                selected={isBulkEditing('extraPersonPrice')}
                                onChange={val => {
                                    const variables = { id: data.id, extraPersonPrice: val };
                                    onSave(
                                        mutateExtraPersonPrice({
                                            variables,
                                            optimisticResponse: {
                                                editDatePricingExtraPersonPrice: {
                                                    __typename: 'EditDatePricingExtraPersonPricePayload',
                                                    datePricing: { ...variables, __typename: 'DatePricing' },
                                                },
                                            },
                                        })
                                    );
                                }}
                                {...cellProps}
                            />
                        )}
                        {rowVisibility?.showMinimumStayDurationRow && (
                            <TextInputCell
                                value={data.minimumStayDuration}
                                onChange={val => {
                                    const variables = { id: data.id, minimumStayDuration: val };
                                    onSave(
                                        mutateMinimumStayDuration({
                                            variables,
                                            optimisticResponse: {
                                                editDatePricingMinimumStayDuration: {
                                                    __typename: 'EditDatePricingMinimumStayDurationPayload',
                                                    datePricing: { ...variables, __typename: 'DatePricing' },
                                                },
                                            },
                                        })
                                    );
                                }}
                                selected={isBulkEditing('minimumStayDuration')}
                                {...cellProps}
                            />
                        )}
                        {rowVisibility?.showMinimumStayPriceRow && (
                            <TextInputCell
                                value={data.minimumStayPrice}
                                isCurrency
                                onChange={val => {
                                    const variables = { id: data.id, minimumStayPrice: val };
                                    onSave(
                                        mutateMinimumStayPrice({
                                            variables,
                                            optimisticResponse: {
                                                editDatePricingMinimumStayPrice: {
                                                    __typename: 'EditDatePricingMinimumStayPricePayload',
                                                    datePricing: { ...variables, __typename: 'DatePricing' },
                                                },
                                            },
                                        })
                                    );
                                }}
                                selected={isBulkEditing('minimumStayPrice')}
                                {...cellProps}
                            />
                        )}
                        {rowVisibility?.showBaseStayPriceRow && (
                            <TextInputCell
                                value={data.baseStayPrice}
                                isCurrency
                                onChange={val => {
                                    const variables = { id: data.id, baseStayPrice: val };
                                    onSave(
                                        mutateBaseStayPrice({
                                            variables,
                                            optimisticResponse: {
                                                editDatePricingBaseStayPrice: {
                                                    __typename: 'EditDatePricingBaseStayPricePayload',
                                                    datePricing: { ...variables, __typename: 'DatePricing' },
                                                },
                                            },
                                        })
                                    );
                                }}
                                selected={isBulkEditing('baseStayPrice')}
                                {...cellProps}
                            />
                        )}
                        {rowVisibility?.showWeekPriceRow && (
                            <TextInputCell
                                value={data.weekPrice ?? null}
                                isNullable
                                isCurrency
                                onChange={val => {
                                    const variables = { id: data.id, weekPrice: val };
                                    onSave(
                                        mutateWeekPrice({
                                            variables,
                                            optimisticResponse: {
                                                editDatePricingWeekPrice: {
                                                    __typename: 'EditDatePricingWeekPricePayload',
                                                    datePricing: { ...variables, __typename: 'DatePricing' },
                                                },
                                            },
                                        })
                                    );
                                }}
                                selected={isBulkEditing('weekPrice')}
                                {...cellProps}
                            />
                        )}
                        <Cell hasSeparator style={{ borderBottom: 'none' }} />
                    </>
                )}
            </PricingColumnContainer>
        );
    },
    (prev, next) => {
        const prevDatePricing = getDatePricing(prev.index, prev.data.datePricings, prev.data.loadedPeriods);
        const nextDatePricing = getDatePricing(next.index, next.data.datePricings, next.data.loadedPeriods);

        if (!isEqual(prevDatePricing, nextDatePricing)) {
            return false;
        }

        if (!isEqual(prev.data.allotments, next.data.allotments)) {
            return false;
        }

        // check other props but don't check data prop
        return (
            areEqual(
                { ...prev, values: prev.data.values, data: null },
                { ...next, values: next.data.values, data: null }
            ) &&
            areEqual(
                { ...prev, values: prev.data.rowVisibility, data: null },
                { ...next, values: next.data.rowVisibility, data: null }
            )
        );
    }
);

const NoDataColumn: FC<React.PropsWithChildren<{ rowVisibility: PricesRowVisibilityFragment | null }>> = ({
    rowVisibility,
}) => (
    <>
        {[false, false, '€ —,—', '€ —,—', '—', '€ —,—', '€ —,—', '€ —,—']
            .filter((_, i) => rowVisibility?.[visibilityKeys[i]])
            .map((placeholder, i) => (
                <Cell key={i}>{typeof placeholder === 'boolean' ? <Switch disabled /> : placeholder}</Cell>
            ))}
    </>
);

export default PriceColumn;
