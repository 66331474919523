import { IntlShape } from 'react-intl';
import * as Yup from 'yup';
import { phoneSchema } from './phoneSchema';
import { addressValidation } from './locationValidation';
import brandConfig from '../../constants/brandConfig';
import yup from '@raisin/yup-validations';

const BIC_REGEX = /^[A-Z0-9]{4}[A-Z]{2}[A-Z0-9]{2}(?:[A-Z0-9]{3})?$/;
const URL_REGEX = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

const registrationValidation = (intl: IntlShape) => {
    const contactValidation = Yup.object().shape({
        emails: Yup.array().of(
            Yup.string().email(intl.formatMessage({ defaultMessage: 'Voer een geldig e-mailadres in' }))
        ),
        phoneNumbers: Yup.array().of(
            phoneSchema(intl)
        ),
        contactName: Yup.string()
            .required(intl.formatMessage({ defaultMessage: 'Naam is verplicht' }))
            .max(255, intl.formatMessage({ defaultMessage: 'Partner naam mag maximaal 255 tekens bevatten' })),
    });

    const rentalUnitsValidation = Yup.object().shape({
        maxAllotment: Yup.string(),
        name: Yup.string().nullable(),
        type: Yup.string(),
    });

    const accommodationsValidation = Yup.object().shape({
        address: addressValidation(intl),
        name: Yup.string()
            .required(intl.formatMessage({ defaultMessage: 'Accommodatienaam is verplicht' }))
            .max(255, intl.formatMessage({ defaultMessage: 'Partner naam mag maximaal 255 tekens bevatten' })),
        publicUrl: Yup.string()
            // .required(intl.formatMessage({ defaultMessage: 'Publieke URL is verplicht' }))
            .matches(URL_REGEX, intl.formatMessage({ defaultMessage: 'URL is niet geldig' })),
        rentalUnits: Yup.array().of(rentalUnitsValidation).nullable(),
        starRating: Yup.string().nullable(),
        type: Yup.string().nullable(),
    });

    return Yup.object().shape({
        contact: contactValidation,
        accommodations: Yup.array().of(accommodationsValidation),
        name: Yup.string()
            .required(intl.formatMessage({ defaultMessage: 'Bedrijfsnaam is verplicht' }))
            .max(255, intl.formatMessage({ defaultMessage: 'Partner naam mag maximaal 255 tekens bevatten' })),
        iban: yup
            .string()
            .required(intl.formatMessage({ defaultMessage: 'IBAN is verplicht' }))
            .iban(
                intl.formatMessage({
                    defaultMessage: 'IBAN is niet geldig. Let op: deze controle is hoofdlettergevoelig',
                })
            ),
        ibanName: Yup.string()
            .required(intl.formatMessage({ defaultMessage: 'Tenaamstelling rekening is verplicht' }))
            .max(255, intl.formatMessage({ defaultMessage: 'Partner naam mag maximaal 255 tekens bevatten' })),
        bic: Yup.string()
            .nullable()
            .matches(BIC_REGEX, intl.formatMessage({ defaultMessage: 'BIC is niet geldig' })),
    });
};

export const camperContactValidation = (intl: IntlShape) => {
    const { formatMessage } = intl;

    const contactValidation = Yup.object().shape({
        emails: Yup.array().of(
            Yup.string()
                .required(formatMessage({ defaultMessage: 'E-mail is verplicht' }))
                .email(formatMessage({ defaultMessage: 'Voer een geldig e-mailadres in' }))
        ),
        phoneNumbers: Yup.array().of(
            phoneSchema(intl)
        ),
        contactName: Yup.string()
            .required(formatMessage({ defaultMessage: 'Naam is verplicht' }))
            .max(255, formatMessage({ defaultMessage: 'Partner naam mag maximaal 255 tekens bevatten' })),
    });

    const rentalUnitsValidation = Yup.object().shape({
        maxAllotment: Yup.string(),
        name: Yup.string().required(formatMessage({ defaultMessage: 'Naam is verplicht' })),
        type: Yup.string(),
    });

    const accommodationsValidation = Yup.object().shape({
        address: addressValidation(intl),
        name: Yup.string()
            .required(formatMessage({ defaultMessage: 'Accommodatienaam is verplicht' }))
            .max(255, formatMessage({ defaultMessage: 'Partner naam mag maximaal 255 tekens bevatten' })),
        publicUrl: Yup.string().matches(URL_REGEX, formatMessage({ defaultMessage: 'URL is niet geldig' })),
        rentalUnits: Yup.array().of(rentalUnitsValidation).nullable(),
        starRating: Yup.string().nullable(),
        type: Yup.string().nullable(),
    });

    return Yup.object().shape({
        contact: contactValidation,
        accommodations: Yup.array().of(accommodationsValidation),
        name: Yup.string()
            .required(formatMessage({ defaultMessage: 'Bedrijfsnaam is verplicht' }))
            .max(255, formatMessage({ defaultMessage: 'Partner naam mag maximaal 255 tekens bevatten' })),
        iban: yup
            .string()
            .required(formatMessage({ defaultMessage: 'IBAN is verplicht' }))
            .iban(formatMessage({ defaultMessage: 'IBAN is niet geldig' })),
        ibanName: Yup.string()
            .required(formatMessage({ defaultMessage: 'Tenaamstelling rekening is verplicht' }))
            .max(255, formatMessage({ defaultMessage: 'Tenaamstelling rekening mag maximaal 255 tekens bevatten' })),
        bic: Yup.string()
            .nullable()
            .matches(BIC_REGEX, formatMessage({ defaultMessage: 'BIC is niet geldig' })),
        pmsName: Yup.string().nullable(),
        comment: Yup.string().nullable(),
        subscriptionPackage: Yup.string().when([], {
            is: () => brandConfig.registrationForm?.hasPackageOptions,
            then: Yup.string().required(formatMessage({ defaultMessage: 'Kies een pakket' })),
            otherwise: Yup.string().nullable(),
        }),
        vatNumber: Yup.string()
            .when([], {
                is: () => brandConfig.registrationForm?.hasRequiredVatNumber,
                then: Yup.string().required(formatMessage({ defaultMessage: 'BTW nummer is verplicht' })),
                otherwise: Yup.string().nullable(),
            })
            .matches(
                /^(AT|BE|BG|CY|CZ|DE|DK|EE|EL|ES|FI|FR|GB|HR|HU|IE|IT|LT|LU|LV|MT|NL|PL|PT|RO|SE|SI|SK)[0-9A-Z]{2,12}$/,
                'BTW nummer is niet geldig'
            ),
        invoiceAddress: Yup.object().when('sameAsAccommodationAddress', {
            is: false,
            then: addressValidation(intl),
            otherwise: Yup.object().nullable(),
        }),
        invoiceEmails: Yup.array().of(
            Yup.string()
                .required(formatMessage({ defaultMessage: 'Email is verplicht' }))
                .email(formatMessage({ defaultMessage: 'Voer een geldig e-mailadres in' }))
        ),
    });
};

export default registrationValidation;
