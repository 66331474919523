import { Box } from '@rebass/grid';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { NotFoundError } from '../../../../entities/NotFoundError';
import { UnitParams } from '../../../../entities/UnitParams';
import { OwnerFragment, useEditOwnerMutation, useOwnerQuery } from '../../../../generated/graphql';
import { isBlob } from '@oberoninternal/travelbase-ds/utils/isBlob';
import omitEqualValues from '../../../../utils/omitEqualValues';
import { CheckboxField } from '@oberoninternal/travelbase-ds/components/form/Checkbox';
import FieldSet from '../../../atoms/FieldSet';
import FieldSetHint from '../../../atoms/FieldSetHint';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import FieldHeading from '../../../molecules/FieldHeading';
import LabelBox from '../../../molecules/LabelBox';
import TranslationInput from '../../../molecules/TranslationInput';
import { UploadImageField } from '@oberoninternal/travelbase-ds/components/form/UploadImage';
import FormScreen from '../../../organisms/FormScreen';
import Loading from '../../../organisms/Loading';
import { ownerValidation } from './ownerValidation';
import { ImageFile } from '@oberoninternal/travelbase-ds/entities/ImageFile';
import useUpload from '../../../../hooks/useUpload';
import { FormattedMessage, useIntl } from 'react-intl';

export const ownerFragment = gql`
    fragment Owner on RentalUnit {
        id
        accommodation {
            id
            name
            ownerName
            ownerProfileVisible
            ownerIntroduction(locale: "nl")
            ownerIntroductionEN: ownerIntroduction(locale: "en")
            ownerIntroductionDE: ownerIntroduction(locale: "de")
            ownerIntroductionPending
            ownerTips(locale: "nl")
            ownerTipsEN: ownerTips(locale: "en")
            ownerTipsDE: ownerTips(locale: "de")
            ownerTipsPending
            ownerName
            ownerImage {
                id
                url
            }
            rentalUnits {
                id
            }
        }
    }
`;

export const discountQuery = gql`
    query Owner($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            ...Owner
        }
    }

    ${ownerFragment}
`;

export const editOwnerMutation = gql`
    mutation EditOwner($input: EditOwnerInformationInput!) {
        editOwnerInformation(input: $input) {
            rentalUnit {
                ...Owner
            }
        }
    }

    ${ownerFragment}
`;

const Owner: FC<React.PropsWithChildren<UnitParams>> = ({ unitSlug }) => {
    const { data, loading } = useOwnerQuery({ variables: { unitSlug } });
    const [mutate] = useEditOwnerMutation();
    const upload = useUpload();
    const intl = useIntl();
    const { formatMessage } = intl;
    if (loading) {
        return <Loading />;
    }

    if (!data || !data.rentalUnit || !data.rentalUnit.accommodation) {
        throw new NotFoundError();
    }

    const {
        rentalUnit: {
            accommodation: {
                rentalUnits,
                ownerIntroductionDE,
                ownerIntroductionEN,
                ownerIntroductionPending,
                ownerIntroduction,
                ownerTipsDE,
                ownerTipsEN,
                ownerTipsPending,
                ownerTips,
                ownerImage: initialOwnerImage,
                name,
                ...accommodation
            },
            id,
        },
    } = data;

    const initialValues: Required<OwnerFragment['accommodation']> & {
        ownerImage: ImageFile | string;
        ownerImageUploadId?: string;
    } = {
        ownerImage: initialOwnerImage?.url,
        ownerIntroduction: ownerIntroductionPending !== null ? ownerIntroductionPending : ownerIntroduction ?? '',
        ownerTips: ownerTipsPending ?? ownerTips ?? '',
        ...Object.assign(
            {},
            ...Object.entries(accommodation)
                .filter(([key]) => key !== '__typename' && key !== 'id' && key !== 'ownerImageUrl')
                .map(([key, value]) => ({
                    [key]: typeof value !== 'boolean' ? value || '' : value,
                }))
        ),
    };

    return (
        <FormScreen
            validationSchema={ownerValidation(intl)}
            handleSubmit={async ({ ownerImageUploadId, ownerImage, ...values }) => {
                await mutate({
                    variables: {
                        input: {
                            rentalUnitId: id,
                            ...omitEqualValues(values, initialValues, ['ownerIntroduction', 'ownerTips']),
                            ownerImageUploadId: ownerImageUploadId ?? (ownerImage?.id ? undefined : null),
                        },
                    },
                });

                // to avoid memory leaks we need to revoke the blob files
                if (isBlob(ownerImage)) {
                    URL.revokeObjectURL(ownerImage.preview);
                }
            }}
            initialValues={initialValues}
        >
            {({ values: { ownerProfileVisible }, setFieldValue }) => (
                <>
                    <Title>
                        <FormattedMessage defaultMessage="Gastheer/-vrouw" />
                    </Title>
                    <p>
                        <FormattedMessage defaultMessage="Op de website van de VVV, bij jouw accommodatie(s) kun je jezelf voorstellen en tips aan je potentiële gasten geven" />
                    </p>

                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="ownerProfileVisible">
                                <FormattedMessage defaultMessage="Toon mijn profiel op de site" />
                            </Label>
                            <FieldSetHint>
                                <FormattedMessage defaultMessage="Gegevens worden alleen getoond als er een introductie en/of tips tekst is ingevuld." />
                            </FieldSetHint>
                        </LabelBox>
                        <Box width={1} my="auto">
                            <CheckboxField id="ownerProfileVisible" name="ownerProfileVisible">
                                <FormattedMessage
                                    defaultMessage="Hiermee geef je aan dat je je foto, naam en teksten via de website van de VVV wilt laten
                                zien."
                                />
                            </CheckboxField>
                        </Box>
                    </FieldSet>
                    <FieldSet>
                        <LabelBox>
                            <Label>
                                <FormattedMessage defaultMessage="Je profielfoto" />
                            </Label>
                            <FieldSetHint>
                                <FormattedMessage defaultMessage="Maximaal 3MB" />
                            </FieldSetHint>
                            <StyledFieldSetHint profileVisible={ownerProfileVisible}>
                                <FormattedMessage defaultMessage="Verplicht" />
                            </StyledFieldSetHint>
                        </LabelBox>
                        <Box width={[1, 1 / 2, 1 / 3, 1 / 2, 1 / 3]} my="auto">
                            <UploadImageField
                                name="ownerImage"
                                variant="main"
                                handleUpload={upload}
                                uploadActionHandler={(uploadId, file) => {
                                    setFieldValue('ownerImageUploadId', uploadId);
                                    setFieldValue('ownerImage', file);
                                }}
                                ratio={1 / 1}
                            />
                        </Box>
                    </FieldSet>
                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="ownerName">
                                <FormattedMessage defaultMessage="Je voor- en achternaam" />
                            </Label>
                            <StyledFieldSetHint profileVisible={ownerProfileVisible}>
                                <FormattedMessage defaultMessage="Verplicht" />
                            </StyledFieldSetHint>
                        </LabelBox>
                        <Box width={1}>
                            <TextInputField
                                id="ownerName"
                                placeholder={formatMessage({
                                    defaultMessage: 'Naam',
                                })}
                                name="ownerName"
                            />
                        </Box>
                    </FieldSet>

                    <FieldHeading
                        title={formatMessage({
                            defaultMessage: 'Even voorstellen',
                        })}
                    >
                        <FormattedMessage defaultMessage="Gasten vinden het leuk om alvast kennis met je te maken. Vertel iets over je ervaringen, je band met het eiland of de reden waarom je het leuk vindt om gastheer/-vrouw te zijn." />
                    </FieldHeading>
                    <TranslationInput
                        name="ownerIntroduction"
                        placeholder={formatMessage({
                            defaultMessage: 'Introduceer jezelf aan je gasten',
                        })}
                        label={formatMessage({
                            defaultMessage: 'Tekst invoeren of aanpassen',
                        })}
                        hint={formatMessage({
                            defaultMessage:
                                'Plaats hier de tekst(aanpassing) die je op de website van de VVV wilt tonen. Wij verzorgen de plaatsing en vertaling binnen enkele dagen.',
                        })}
                        data={{
                            en: ownerIntroductionEN,
                            nl: ownerIntroduction,
                            de: ownerIntroductionDE,
                            pending: ownerIntroductionPending,
                        }}
                    />

                    <FieldHeading
                        title={formatMessage({
                            defaultMessage: 'Mijn tips',
                        })}
                    >
                        <FormattedMessage
                            defaultMessage="Laat hier leuke tips achter voor jouw gasten. Bijvoorbeeld: Het vakantiehuis ligt op loopafstand
                        van natuurgebied De Slufter. In dit gebied kun je prachtige wandelingen maken. Vooral rond
                        zonsopkomst en zonsondergang is een wandeling in dit gebied uniek!"
                        />
                    </FieldHeading>
                    <TranslationInput
                        name="ownerTips"
                        placeholder={formatMessage({
                            defaultMessage: 'Leuke tips voor je gasten',
                        })}
                        label={formatMessage({
                            defaultMessage: 'Tekst invoeren of aanpassen',
                        })}
                        hint={formatMessage({
                            defaultMessage:
                                'Plaats hier de tekst die je op de website van de VVV wilt tonen. Wij verzorgen de plaatsing en vertaling binnen enkele dagen.',
                        })}
                        data={{
                            en: ownerTipsEN,
                            nl: ownerTips,
                            de: ownerTipsDE,
                            pending: ownerTipsPending,
                        }}
                    />
                </>
            )}
        </FormScreen>
    );
};

const StyledFieldSetHint = styled(FieldSetHint)<{ profileVisible: boolean }>`
    visibility: ${({ profileVisible }) => (profileVisible ? 'visible' : 'hidden')};
`;

export default Owner;
