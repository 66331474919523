import { useWriteLockout } from './useWriteLockout';
import { useWriteBooking } from './useWriteBooking';
import { AllotmentProps } from '@oberoninternal/travelbase-ds/components/calendar/Allotment';
import { AllotmentType } from '../entities/AllotmentType';
import euroFormat from '../constants/euroFormat';
import { useSidebar } from '../context/sidebar';

export const useAllotmentProps = (...params: Parameters<typeof useWriteLockout>) => {
    const writeLockout = useWriteLockout(...params);
    const writeBooking = useWriteBooking(...params);
    const [document, variables] = params;
    const [, dispatch] = useSidebar();

    const writeAllotment = (allotment: AllotmentType) => {
        if (allotment.__typename === 'AllotmentLockout') {
            writeLockout(allotment);
        } else if (allotment.__typename === 'Booking') {
            writeBooking(allotment);
        }
    };

    return (allotment: AllotmentType): AllotmentProps => ({
        id: allotment.id,
        selected: (allotment.__typename === 'AllotmentLockout' ? allotment.isDragging : false) || allotment.isActive,
        hovering: allotment.isHovering,
        variant: allotment.__typename === 'Booking' ? 'booking' : 'lockout',
        title:
            allotment.__typename === 'AllotmentLockout'
                ? allotment.comment ?? ''
                : allotment.__typename === 'Booking'
                ? allotment.customer
                    ? `${allotment.customer.firstName ?? ''} ${allotment.customer.lastName ?? ''}`
                    : 'Aanvraag'
                : '',
        source: allotment.__typename === 'AllotmentLockout' ? allotment.source ?? '' : undefined,
        subtitle: allotment.__typename === 'Booking' ? euroFormat(allotment.totalPrice) : '',
        onMouseEnter: () => {
            writeAllotment({
                ...allotment,
                isHovering: true,
            });
        },
        onMouseLeave: () => {
            writeAllotment({
                ...allotment,
                isHovering: false,
            });
        },
        onClick: () => {
            const updated = {
                ...allotment,
                isActive: true,
            };
            if (updated.__typename === 'AllotmentLockout') {
                writeLockout(updated);

                dispatch({
                    type: 'show',
                    data: {
                        type: 'LOCKOUT',
                        lockout: updated,
                        document,
                        variables,
                    },
                });
            } else if (updated.__typename === 'Booking') {
                writeBooking(updated);

                dispatch({
                    type: 'show',
                    data: {
                        type: 'BOOKING',
                        booking: updated,
                        document,
                        variables,
                    },
                });
            }
        },
        part: 'single',
    });
};
