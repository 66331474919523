import React, { FC } from 'react';
import Overtitle from '@oberoninternal/travelbase-ds/components/primitive/Overtitle';
import Truncate from 'react-truncate';
import styled from 'styled-components/macro';

interface Props {
    truncate?: number;
    name?: string;
}

const InfoHeading: FC<React.PropsWithChildren<Props>> = ({ name, truncate = 1 }) => (
    <StyledInfoHeading>
        <Truncate lines={truncate}>{name}</Truncate>
    </StyledInfoHeading>
);

export default InfoHeading;

export const StyledInfoHeading = styled(Overtitle).attrs({ size: 'small' })`
    color: ${({ theme }) => theme.colors.primary[90]};
    text-transform: uppercase;
    margin-top: 3rem;
    margin-bottom: 1rem;
`;
