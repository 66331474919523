import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Overtitle from '@oberoninternal/travelbase-ds/components/primitive/Overtitle';
import SidebarContainer from '@oberoninternal/travelbase-ds/components/layout/SidebarContent';
import AvailabilitySingle from '@oberoninternal/travelbase-ds/components/figure/AvailabilitySingle';
import AvailabilityMultiple from '@oberoninternal/travelbase-ds/components/figure/AvailabilityMultiple';
import { Box } from '@rebass/grid';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';

interface Props {
    isMultiple: boolean;
}

const AvailabilityInfobar: FC<React.PropsWithChildren<Props>> = ({ isMultiple }) => (
    <StyledSidebarContainer>
        <div>
            {!isMultiple ? <AvailabilitySingle /> : <AvailabilityMultiple />}
            <Box mb={4}>
                <Body variant="large">
                    {isMultiple ? (
                        <FormattedMessage defaultMessage="Selecteer een dag om de beschikbaarheid aan te passen" />
                    ) : (
                        <FormattedMessage defaultMessage="Selecteer een boeking, een datumbereik of blokkering" />
                    )}
                </Body>
            </Box>
            <Box>
                {isMultiple ? (
                    <>
                        <Body variant="regular">
                            <FormattedMessage defaultMessage="Klik op een dag om deze aan te passen." />

                            <br />
                            <FormattedMessage defaultMessage="Gebruik je toetsenbord om:" />
                        </Body>
                        <Bullets>
                            <li>
                                <FormattedMessage defaultMessage="Van dagen te wisselen (pijltjestoetsen/WASD)" />
                            </li>
                            <li>
                                <FormattedMessage defaultMessage="Een geselecteerde dag aan te passen (Enter om aan te passen, en opnieuw Enter om op te slaan)" />
                            </li>
                        </Bullets>
                    </>
                ) : (
                    <Body variant="regular">
                        <FormattedMessage defaultMessage="Ze bekijk je boekingen, kun je blokkeringen aanpassen of voeg je nieuwe blokkeringen toe" />
                    </Body>
                )}
            </Box>
        </div>
        {isMultiple && (
            <Box>
                <StyledOvertitle variant="small">
                    <FormattedMessage defaultMessage="Legenda" />
                </StyledOvertitle>
                <StyledBox>
                    <Body variant="small">
                        <FormattedMessage defaultMessage="Beschikbaarheid hoog &rarr; laag" />
                    </Body>
                    <StaticAllotmentIndicator unavailable={false} />
                </StyledBox>
                <StyledBox>
                    <Body variant="small">
                        <FormattedMessage defaultMessage="Niet beschikbaar" />
                    </Body>
                    <StaticAllotmentIndicator unavailable />
                </StyledBox>
            </Box>
        )}
    </StyledSidebarContainer>
);

const Bullets = styled.ul`
    padding-left: 1.2em;

    li {
        list-style: disc;
    }
`;

const StyledSidebarContainer = styled(SidebarContainer)`
    position: fixed;
    right: 0;
    z-index: 3;
    background: ${({ theme }) => theme.colors.neutral[0]};
    width: 42.4rem;
    padding-top: 16rem;
    height: 100%;
    top: 0;
    flex-direction: column;
    justify-content: space-between;
    display: none;

    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        display: flex;
    }
`;

const StyledOvertitle = styled(Overtitle)`
    margin-bottom: 1.6rem;
`;

const StyledBox = styled(Box)`
    margin-bottom: 0.6rem;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
`;

const StaticAllotmentIndicator = styled.span<{ unavailable: boolean }>`
    padding: 0 1.2rem;
    background-color: ${({ theme, unavailable }) => (unavailable ? theme.colors.negative[50] : 'unset')};
    border-radius: 6px;
    height: 2.4rem;
    min-width: ${({ unavailable }) => (unavailable ? '3.6rem' : '6.4rem')};
    background-image: ${({ unavailable }) =>
        unavailable ? 'unset' : 'linear-gradient(270deg, #00a9d0 0%, rgba(0, 169, 208, 0) 100%)'};
`;

export default AvailabilityInfobar;
