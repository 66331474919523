import React, { AnchorHTMLAttributes, ButtonHTMLAttributes, FC, forwardRef, Ref } from 'react';
import { LinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { getAssignmentColor } from '../../constants/theme';
import { UnreachableCaseError } from '../../entities/UnreachableCaseError';
import createBorderCss from '../../utils/createBorderCss';
import Spinner from '../figure/Spinner';

export type ButtonVariant = 'primary' | 'danger' | 'outline' | 'secondary';

export type ButtonSize = 'regular' | 'small' | 'large' | 'xsmall';

export interface Props
    extends ButtonHTMLAttributes<HTMLButtonElement>,
        Partial<Omit<LinkProps, keyof AnchorHTMLAttributes<{}>>> {
    variant?: ButtonVariant;
    size?: ButtonSize;
    submitting?: boolean;
    rounded?: boolean;
    ref?: Ref<HTMLButtonElement | HTMLAnchorElement>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    as?: keyof JSX.IntrinsicElements | React.ComponentType<React.PropsWithChildren<any>>;
    href?: string;
    target?: string;
    rel?: string;
}

const Button: FC<React.PropsWithChildren<Props>> = forwardRef(({ children, type = 'button', ...props }, ref) => (
    <StyledButton type={type} {...props} ref={ref}>
        {props.submitting ? <Spinner /> : <span>{children}</span>}
    </StyledButton>
));

export default Button;

const primaryCss = css`
    background: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.buttonPrimary)};
    --border-color: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.buttonPrimary)};

    :active,
    :hover,
    :focus {
        --border-color: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.buttonPrimary, 1)};
    }

    :active {
        background: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.buttonPrimary, 1)};
        --border-color: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.buttonPrimary, 1)};
    }
`;

const secondaryCss = css`
    background: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.buttonLight)};
    --border-color: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.buttonLight)};

    :active,
    :hover,
    :focus {
        --border-color: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.buttonLight, 1)};
    }

    :active {
        background: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.buttonLight, 1)};
        --border-color: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.buttonLight, 1)};
    }
`;

const outlineCss = css`
    color: ${({ theme }) => theme.colors.neutral['70']};
    background: white;
    --border-color: ${({ theme }) => theme.colors.neutral['30']};
    ${createBorderCss('1px')};
    :hover,
    :focus {
        --border-color: ${({ theme }) => theme.colors.primary['40']};
        background: ${({ theme }) => theme.colors.neutral['0']};
    }

    :active {
        --border-color: ${({ theme }) => theme.colors.primary['40']};
        background: ${({ theme }) => theme.colors.primary['5']};
    }

    :hover,
    :focus,
    :active {
        ${createBorderCss('2px')}
    }
`;

const dangerCss = css`
    color: ${({ theme }) => theme.colors.negative['70']};
    background: white;
    ${createBorderCss('1px')};
    --border-color: ${({ theme }) => theme.colors.negative['20']};

    :active {
        background: ${({ theme }) => theme.colors.negative['0']};
    }

    :hover,
    :active,
    :focus {
        ${createBorderCss('2px')};
        --border-color: ${({ theme }) => theme.colors.negative['50']};
    }
`;

const getCss = (variant: ButtonVariant) => {
    switch (variant) {
        case 'primary':
            return primaryCss;
        case 'secondary':
            return secondaryCss;
        case 'danger':
            return dangerCss;
        case 'outline':
            return outlineCss;
        default:
            throw new UnreachableCaseError(variant);
    }
};

export const getSizeCss = (size: ButtonSize) => {
    switch (size) {
        case 'xsmall':
            return css`
                font-weight: 700;
                font-size: 1.2rem;
                padding: 0.6rem 1.6rem;
            `;
        case 'small':
            return css`
                font-weight: 600;
                font-size: 1.3rem;
                height: ${({ theme }) => theme.spacing['50_Semi']};
                padding: 0 1rem;
            `;
        case 'regular':
            return css`
                font-weight: 500;
                font-size: 1.4rem;
                height: ${({ theme }) => theme.spacing['60_Large']};
                padding: 0 2.4rem;
            `;
        case 'large':
            return css`
                font-weight: 600;
                font-size: 1.6rem;
                height: ${({ theme }) => theme.spacing['70_XLarge']};
                padding: 0 2.4rem;
            `;
        default:
            throw new UnreachableCaseError(size);
    }
};

export const StyledButton = styled.button<Props>`
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${({ theme }) => theme.radius.button};
    outline: none;
    border: none;
    text-decoration: none;
    margin: 0;
    cursor: pointer;

    > span {
        align-items: center;
        display: flex;

        > :first-child + :last-child {
            margin-left: 0.5em;
        }
    }

    ${createBorderCss('2px')};
    ${({ size = 'regular' }) => getSizeCss(size)};
    ${({ variant = 'primary' }) => getCss(variant)};
    ${({ rounded }) =>
        rounded
            ? css`
                  border-radius: 2rem;
              `
            : ''};

    :disabled {
        ${createBorderCss('1px')};
        --border-color: ${({ theme }) => theme.colors.neutral['30']};
        color: ${({ theme }) => theme.colors.neutral['40']};
        background: white;
        cursor: not-allowed;
    }

    svg {
        flex-shrink: 0;
    }
`;
