import React, { SVGAttributes } from 'react';

export default function (props: SVGAttributes<SVGElement>) {
    return (
        <svg width="22" height="22" {...props}>
            <path
                d="M8 17l3 3 3-3m-3 2v-8h8m-2-3l3 3-3 3m-3-9l-3-3-3 3m3-2v8H3m2 3l-3-3 3-3"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
            />
        </svg>
    );
}
