import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

interface Options {
    isCurrency?: boolean;
    nullable?: boolean;
}

const defaultOptions: Required<Options> = { isCurrency: false, nullable: false };

export const numberInputValidation = (intl: IntlShape, options?: Options) => {
    const { isCurrency = defaultOptions.isCurrency, nullable = defaultOptions.nullable }: Options =
        options ?? defaultOptions;
    let schema: Yup.NumberSchema<number | null> = Yup.number()
        .typeError(intl.formatMessage({ defaultMessage: 'Vul een geldige waarde in.' }))
        .min(isCurrency ? 0 : 1, intl.formatMessage({ defaultMessage: 'Waarde ligt onder het minimum' }))
        .max(isCurrency ? 10000 : 21, intl.formatMessage({ defaultMessage: 'Waarde ligt boven het maximum' }));

    if (!isCurrency) {
        schema = schema.integer();
    }
    if (nullable) {
        schema = schema.nullable();
    }
    return schema;
};

export default numberInputValidation;

export const validateNumberInputSync = (intl: IntlShape, options?: Options) => (value: unknown) => {
    try {
        numberInputValidation(intl, options).validateSync(value === '' ? null : value);
        return undefined;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (ex: any) {
        return ex.message;
    }
};
