import * as Yup from 'yup';
import { phoneSchema } from './phoneSchema';
import { IntlShape } from 'react-intl';

export const contactReservationValidation = (intl: IntlShape) =>
    Yup.object().shape({
        contactName: Yup.string().required(intl.formatMessage({ defaultMessage: 'Naam is verplicht' })),
        emails: Yup.array().of(
            Yup.string()
                .required(intl.formatMessage({ defaultMessage: 'Voer een e-mailadres in' }))
                .email(intl.formatMessage({ defaultMessage: 'Voer een geldig e-mailadres in' }))
        ),
        phoneNumbers: Yup.array().of(
            phoneSchema(intl).required(intl.formatMessage({ defaultMessage: 'Vul een telefoonnummer in' }))
        ),
    });

export const contactValidation = (intl: IntlShape) =>
    Yup.object().shape({
        contactName: Yup.string(),
        emails: Yup.array().of(
            Yup.string().email(intl.formatMessage({ defaultMessage: 'Voer een geldig e-mailadres in' }))
        ),
        phoneNumbers: Yup.array().of(phoneSchema(intl)),
    });
