import { Box } from '@rebass/grid';
import gql from 'graphql-tag';
import React, { FC, ReactElement, useState } from 'react';
import { generatePath, Route, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ActivityParams } from '../../../entities/ActivityParams';
import { CompanyPickerEntryFragment } from '../../../generated/graphql';
import ErrorBoundary from '../../organisms/ErrorBoundary';
import CompanyDetails from './info/CompanyDetails';
import CompanyLocation from './info/CompanyLocation';
import CompanyDescription from './info/CompanyDescription';
import CompanyImages from './info/CompanyImages';
import NotFound from '../NotFound';
import InfoHeading from '../../atoms/InfoHeading';
import InfoMobileMenu from '../../molecules/InfoMobileMenu';
import ItemDesktopRoute from '../../atoms/ItemDesktopRoute';
import ItemMobileRoute from '../../atoms/ItemMobileRoute';
import InfoSideContainer from '../../molecules/InfoSideContainer';
import InfoContent from '../../atoms/InfoContent';
import { LocalizedSwitch } from '../../atoms/LocalizedSwitch';
import { useIntl } from 'react-intl';

interface InfoRoute {
    name: string;
    source: string;
}

export const companyInfoFragment = gql`
    fragment CompanyInfo on Company {
        id
        slug
        name
        enabled
    }
`;

interface Props {
    company?: CompanyPickerEntryFragment;
}

const Info: FC<React.PropsWithChildren<Props>> = ({ company }) => {
    const { pathname } = useLocation();
    const { path, params } = useRouteMatch<ActivityParams>();
    const [open, setOpen] = useState(false);

    const intl = useIntl();

    const infoRoutes: InfoRoute[] = [
        { name: intl.formatMessage({ defaultMessage: 'Informatie' }), source: '' },
        { name: intl.formatMessage({ defaultMessage: 'Adres' }), source: 'location' },
        { name: intl.formatMessage({ defaultMessage: 'Beschrijving' }), source: 'description' },
        { name: intl.formatMessage({ defaultMessage: 'Foto’s' }), source: 'photos' },
    ];

    const fullPath = (shortPath: string) => generatePath(shortPath, { ...params });

    const renderRouteDesktop = ({ name, source }: InfoRoute) => (
        <ItemDesktopRoute key={`desktop-${source}`} path={fullPath(`${path}/${source}`)}>
            {name}
        </ItemDesktopRoute>
    );

    const renderRouteMobile = ({ name, source }: InfoRoute) => (
        <ItemMobileRoute key={`mobile-${source}`} open={open} setOpen={setOpen} path={fullPath(`${path}/${source}`)}>
            {name}
        </ItemMobileRoute>
    );

    const renderRoutes = (routes: InfoRoute[], renderFunc: (route: InfoRoute) => ReactElement) => [
        company && <InfoHeading key={`header-${company?.name}`} name={company.name} />,
        ...routes.map(renderFunc),
    ];

    const first = [...infoRoutes].reverse().find(({ source }) => pathname.endsWith(source)) ?? infoRoutes[0];

    return (
        <Container>
            <InfoSideContainer>{renderRoutes(infoRoutes, renderRouteDesktop)}</InfoSideContainer>
            <InfoContent>
                <InfoMobileMenu open={open} setOpen={setOpen}>
                    {open ? renderRoutes(infoRoutes, renderRouteMobile) : [renderRouteMobile(first)]}
                </InfoMobileMenu>

                <ErrorBoundary>
                    <LocalizedSwitch>
                        <Route path={fullPath(`${path}`)} exact render={() => <CompanyDetails {...params} />} />
                        <Route path={fullPath(`${path}/location`)} render={() => <CompanyLocation {...params} />} />
                        <Route
                            path={fullPath(`${path}/description`)}
                            render={() => <CompanyDescription {...params} />}
                        />
                        <Route path={fullPath(`${path}/photos`)} render={() => <CompanyImages {...params} />} />
                        <Route component={NotFound} />
                    </LocalizedSwitch>
                </ErrorBoundary>
            </InfoContent>
        </Container>
    );
};

export default Info;

const Container = styled(Box).attrs({ width: '1' })`
    display: flex;
`;
