import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Plus from '@oberoninternal/travelbase-ds/components/figure/Plus';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import useMenuState from '@oberoninternal/travelbase-ds/hooks/useMenuState';
import { Box, Flex } from '@rebass/grid';
import compareAsc from 'date-fns/compareAsc';
import gql from 'graphql-tag';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import operatingHoursValidation from '../../../../constants/operatingHoursValidation';
import { ActivityParams } from '../../../../entities/ActivityParams';
import { NotFoundError } from '../../../../entities/NotFoundError';
import {
    OperatingHours,
    TimeOpenClosed,
    useCompanySpecialHoursQuery,
    useEditCompanySpecialOperatingHoursMutation,
} from '../../../../generated/graphql';
import { Weekday } from '../../../../hooks/useIsBulkEditing';
import convertOperatingHoursToInput from '../../../../utils/convertOperatingHoursToInput';
import { Seperator } from '../../../atoms/Seperator';
import Table from '../../../atoms/Table';
import TableHeader, { StyledTableHeader } from '../../../atoms/TableHeader';
import SpecialHoursRow from '../../../molecules/SpecialHoursRow';
import FormScreen from '../../../organisms/FormScreen';
import Loading from '../../../organisms/Loading';
import SpecialHoursSidebar from '../../../molecules/SpecialHoursSidebar';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

export const query = gql`
    query CompanySpecialHours($companySlug: String!) {
        company(slug: $companySlug) {
            ...CompanySpecialHours
        }
    }

    mutation EditCompanySpecialOperatingHours($input: EditCompanySpecialOperatingHoursInput!) {
        editCompanySpecialOperatingHours(input: $input) {
            company {
                ...CompanySpecialHours
            }
        }
    }

    fragment CompanySpecialHours on Company {
        id
        specialOperatingHours {
            ...OperatingHours
        }
    }
`;

export const getHeaderTexts = (intl: IntlShape) => ({
    period: intl.formatMessage({ defaultMessage: 'Periode (tot en met)' }),
    actions: intl.formatMessage({ defaultMessage: 'Acties' }),
    operationHours: intl.formatMessage({ defaultMessage: 'Openingstijden' }),
});

export interface SpecialHoursValue extends Record<Weekday, TimeOpenClosed[] | null> {
    startDate: string;
    endDate: string;
}

export const initialValue: SpecialHoursValue = {
    startDate: '',
    endDate: '',
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    sunday: null,
};

interface Values {
    periods: SpecialHoursValue[];
}

export const convertToInput = ({ startDate, endDate, ...operatingHours }: OperatingHours): SpecialHoursValue => ({
    startDate,
    endDate,
    ...convertOperatingHoursToInput(operatingHours, true),
});

const compareHoursAsc = (a: OperatingHours, b: OperatingHours) =>
    compareAsc(new Date(a.startDate), new Date(b.startDate));

const SpecialHours: FC<React.PropsWithChildren<ActivityParams>> = ({ companySlug }) => {
    const [mutate] = useEditCompanySpecialOperatingHoursMutation();
    const { data, loading } = useCompanySpecialHoursQuery({ variables: { companySlug: companySlug! } });
    const { menuProps, setOpen } = useMenuState(false, { closeClickOnOutside: false });
    const [sidebarIndex, setSidebarIndex] = useState<null | number>(null);
    const intl = useIntl();
    const { formatMessage } = intl;
    const headerTexts = getHeaderTexts(intl);
    const validationSchema = Yup.object().shape({
        periods: Yup.array().of(
            Yup.object().shape({
                ...operatingHoursValidation(intl).fields,
                startDate: Yup.string().required(formatMessage({ defaultMessage: 'Vul een datum in' })),
                endDate: Yup.string().required(formatMessage({ defaultMessage: 'Vul een datum in' })),
            })
        ),
    });
    useEffect(() => {
        if (!menuProps.open) {
            setSidebarIndex(null);
        }
    }, [menuProps.open]);

    if (loading) {
        return <Loading />;
    }

    if (!data || !data.company) {
        throw new NotFoundError();
    }

    const {
        company: { id: companyId, ...company },
    } = data;

    const specialOperatingHours = [...company.specialOperatingHours].sort(compareHoursAsc);

    const handleRemove = async (values: Values, hoursIndex: number) => {
        if (window.confirm(formatMessage({ defaultMessage: 'Weet je zeker dat je deze periode wilt verwijderen?' }))) {
            if (!data.company?.id) {
                return;
            }

            const notDeleted = (_: unknown, index: number) => index !== hoursIndex;

            await mutate({
                variables: {
                    input: {
                        specialOperatingHours: values.periods.filter(notDeleted),
                        companyId,
                    },
                },
                optimisticResponse: {
                    __typename: 'PartnerMutation',
                    editCompanySpecialOperatingHours: {
                        __typename: 'EditCompanySpecialOperatingHoursPayload',
                        company: {
                            ...data.company,
                            specialOperatingHours: specialOperatingHours.filter(notDeleted),
                        },
                    },
                },
            });
        }
    };

    const initialValues = {
        periods: specialOperatingHours.reduce<SpecialHoursValue[]>((acc, next) => [...acc, convertToInput(next)], []),
    };
    return (
        <FormScreen
            bottomChildren={null}
            initialValues={initialValues}
            validationSchema={validationSchema}
            handleSubmit={async values => {
                await mutate({
                    variables: {
                        input: {
                            specialOperatingHours: values.periods,
                            companyId,
                        },
                    },
                });
                menuProps.setOpen(false);
            }}
        >
            {({ values, setValues }) => (
                <>
                    <Pagehead title={formatMessage({ defaultMessage: 'Afwijkingen' })}>
                        <FormattedMessage defaultMessage="Geef hier afwijkingen op de standaard openingstijden op. Bijvoorbeeld voor feestdagen en laagseizoen." />
                    </Pagehead>
                    <Box style={{ overflow: 'auto' }}>
                        <StyledTable>
                            <thead>
                                <tr>
                                    <TableHeader variant="small">{headerTexts.period}</TableHeader>
                                    {/* a little table hack because the data of the day and hours column belong together, so we basically just show them as 1 column */}
                                    <StyledTableHeader>
                                        <Flex>
                                            <Box width="20rem">
                                                <Label variant="small" as="span">
                                                    <FormattedMessage defaultMessage="Dag" />
                                                </Label>
                                            </Box>
                                            <Label variant="small" as="span">
                                                {headerTexts.operationHours}
                                            </Label>
                                        </Flex>
                                    </StyledTableHeader>
                                    <TableHeader variant="small">{headerTexts.actions}</TableHeader>
                                </tr>
                            </thead>
                            <tbody>
                                {specialOperatingHours.map((specialHoursRow, hoursIndex) => (
                                    <SpecialHoursRow
                                        key={hoursIndex}
                                        specialHours={specialHoursRow}
                                        onRemove={() => handleRemove(values, hoursIndex)}
                                        onEdit={() => {
                                            setSidebarIndex(hoursIndex);
                                            setOpen(true);
                                        }}
                                    />
                                ))}

                                <tr>
                                    <td colSpan={4}>
                                        <TextButton
                                            size="tiny"
                                            onClick={() => {
                                                setValues({ periods: [...values.periods, initialValue] });
                                                if (typeof sidebarIndex === 'number') {
                                                    setSidebarIndex(null);
                                                }
                                                setOpen(true);
                                            }}
                                        >
                                            <Plus />
                                            <span>
                                                <FormattedMessage defaultMessage="Periode toevoegen" />
                                            </span>
                                        </TextButton>
                                    </td>
                                </tr>
                            </tbody>
                        </StyledTable>
                    </Box>
                    {/* the index is used to determine the field and thus knowing if we're editing or otherwise adding a new period */}
                    <SpecialHoursSidebar {...menuProps} index={sidebarIndex ?? values.periods.length - 1} />
                </>
            )}
        </FormScreen>
    );
};

export default SpecialHours;

const StyledTable = styled(Table)`
    @media (max-width: ${({ theme }) => theme.mediaQueriesValues.s - 1}px) {
        th {
            display: none;
        }
    }
    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        min-width: 80rem;
    }
`;

export const RowSeperator = styled(Seperator).attrs({ variant: 'small' })`
    @media (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        margin: 1.6rem 0;
    }
`;
