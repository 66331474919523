import gql from 'graphql-tag';
import { useCreateUploadMutation } from '../generated/graphql';
import { useCallback } from 'react';
import { ImageFile } from '@oberoninternal/travelbase-ds/entities/ImageFile';

export const mutation = gql`
    mutation createUpload {
        createImageUpload {
            putUrl
            uploadId
        }
    }
`;

const useUpload = () => {
    const [createUpload] = useCreateUploadMutation();

    return useCallback(
        async (file: ImageFile): Promise<string> => {
            const result = (await createUpload()).data?.createImageUpload;
            if (!result) {
                throw new Error('Invalid response from backend');
            }

            const { putUrl, uploadId } = result;
            const s3Response = await fetch(putUrl, {
                method: 'PUT',
                body: file,
            });

            if (!s3Response.ok) {
                throw new Error(`Uploading to S3 failed: ${s3Response.status}: ${s3Response.statusText}`);
            }

            return uploadId;
        },
        [createUpload]
    );
};

export default useUpload;
