// eslint-disable-next-line @typescript-eslint/no-explicit-any
type EventFn = (event: any) => void;
const callAll =
    <T extends EventFn>(...args: Array<T | undefined>) =>
    (e: Parameters<T>[0]) => {
        for (const fn of args) {
            fn?.(e);
        }
    };

export default callAll;
