import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Cross from '@oberoninternal/travelbase-ds/components/figure/Cross';
import { Edit } from '@oberoninternal/travelbase-ds/components/figure/Edit';
import Plus from '@oberoninternal/travelbase-ds/components/figure/Plus';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import { Box } from '@rebass/grid';
import { compareAsc } from 'date-fns';
import React, { FC, Fragment } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import styled, { css } from 'styled-components/macro';
import euroFormat from '../../constants/euroFormat';
import { AvailableSurchargeFragment, RentalUnitSurchargeFragment, Scalars } from '../../generated/graphql';
import getPeriodText from '../../utils/getPeriodText';
import FieldSetHint from '../atoms/FieldSetHint';
import { Seperator } from '../atoms/Seperator';
import Table from '../atoms/Table';
import TableActions from '../atoms/TableActions';
import TableHeader from '../atoms/TableHeader';
import { getOptionCalculationTexts, getSurchargeCalculationTexts } from '../pages/unit/info/Surcharges';
import { SurchargeType } from './SurchargeSidebar';

interface Props {
    rentalUnitSurcharges: RentalUnitSurchargeFragment[];
    availableSurcharges: AvailableSurchargeFragment[];
    handleEdit: (rentalUnitSurcharge: RentalUnitSurchargeFragment) => void;
    handleCreate: (surcharge: AvailableSurchargeFragment, type?: SurchargeType, hideTypeSelect?: boolean) => void;
    handleRemove: (rentalUnitSurchargeId: Scalars['ID']['output']) => void;
    type: 'surcharge' | 'option';
}

const getTableHeaderTexts = (intl: IntlShape) => ({
    period: intl.formatMessage({ defaultMessage: 'Periode (tot en met)' }),
    calculation: intl.formatMessage({ defaultMessage: 'Berekening' }),
    actions: intl.formatMessage({ defaultMessage: 'Acties' }),
    amount: intl.formatMessage({ defaultMessage: 'Aantal' }),
});

const SurchargeTable: FC<React.PropsWithChildren<Props>> = ({
    availableSurcharges,
    rentalUnitSurcharges,
    handleCreate,
    handleEdit,
    handleRemove,
    type,
}) =>
    //  we want to only show surcharges when they exist, otherwise show nothing
    {
        const intl = useIntl();
        const locale = intl.locale as 'nl' | 'de' | 'en';
        const tableHeaderTexts = getTableHeaderTexts(intl);
        const filtered = availableSurcharges.filter(
            availableSurcharge =>
                availableSurcharge &&
                rentalUnitSurcharges &&
                rentalUnitSurcharges.find(
                    rentalUnitSurcharge => rentalUnitSurcharge.surcharge.id === availableSurcharge.id
                )
        );

        return rentalUnitSurcharges.length > 0 ? (
            <Box style={{ overflow: 'auto' }}>
                <StyledTable>
                    <thead>
                        <tr>
                            <TableHeader />
                            <TableHeader variant="small">{tableHeaderTexts.period}</TableHeader>
                            <TableHeader variant="small">{tableHeaderTexts.calculation}</TableHeader>
                            {type === 'option' ? (
                                <>
                                    <TableHeader variant="small">{tableHeaderTexts.amount}</TableHeader>
                                    <TableHeader variant="small">{tableHeaderTexts.actions}</TableHeader>
                                </>
                            ) : (
                                <TableHeader variant="small">{tableHeaderTexts.actions}</TableHeader>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {filtered.map((availableSurcharge, index) => {
                            if (!availableSurcharge) {
                                return null;
                            }
                            const { id, description } = availableSurcharge;
                            const name =
                                locale === 'de'
                                    ? availableSurcharge.nameDE
                                    : locale === 'en'
                                    ? availableSurcharge.nameEN
                                    : availableSurcharge.nameNL;
                            const rentalUnitSurchargesFiltered = rentalUnitSurcharges
                                ? rentalUnitSurcharges
                                      .filter(rentalUnitSurcharge => rentalUnitSurcharge.surcharge.id === id)
                                      .sort((a, b) => compareAsc(new Date(a.startDate), new Date(b.startDate)))
                                : [];
                            return (
                                <Fragment key={id}>
                                    {rentalUnitSurchargesFiltered.map((rentalUnitSurcharge, ruSurchargeIndex) => {
                                        const {
                                            id: ruSurchargeId,
                                            startDate,
                                            endDate,
                                            unitPrice,
                                            calculation,
                                            maxAmount,
                                        } = rentalUnitSurcharge;

                                        return (
                                            <tr key={ruSurchargeId}>
                                                <TableRowHeader>
                                                    {ruSurchargeIndex === 0 && (
                                                        <div>
                                                            <Label>{name}</Label>
                                                            {description && <FieldSetHint>{description}</FieldSetHint>}
                                                        </div>
                                                    )}
                                                </TableRowHeader>
                                                <td data-label={tableHeaderTexts.period}>
                                                    {getPeriodText(startDate, endDate, intl)}
                                                </td>
                                                {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
                                                <td data-label={tableHeaderTexts.calculation}>{`${euroFormat(
                                                    unitPrice
                                                )} ${
                                                    type === 'surcharge'
                                                        ? getSurchargeCalculationTexts(intl)[calculation]
                                                        : getOptionCalculationTexts(intl)[calculation]
                                                }`}</td>
                                                {type === 'option' && (
                                                    // eslint-disable-next-line formatjs/no-literal-string-in-jsx
                                                    <td data-label={tableHeaderTexts.amount}>{maxAmount || '1'}</td>
                                                )}
                                                <td data-label={tableHeaderTexts.actions}>
                                                    <TableActions>
                                                        <TextButton
                                                            size="tiny"
                                                            onClick={() => handleEdit(rentalUnitSurcharge)}
                                                        >
                                                            <Edit />
                                                            <span>
                                                                <FormattedMessage defaultMessage="Aanpassen" />
                                                            </span>
                                                        </TextButton>
                                                        <TextButton
                                                            variant="danger"
                                                            size="tiny"
                                                            onClick={() => handleRemove(ruSurchargeId)}
                                                        >
                                                            <Cross />
                                                            <span>
                                                                <FormattedMessage defaultMessage="Verwijderen" />
                                                            </span>
                                                        </TextButton>
                                                    </TableActions>
                                                </td>
                                                <td>
                                                    {/* Don't display a seperator when last */}
                                                    {ruSurchargeIndex !== rentalUnitSurchargesFiltered.length - 1 && (
                                                        <ExtraSeperator />
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td />
                                        <td colSpan={4}>
                                            <TextButton
                                                size="tiny"
                                                onClick={() => handleCreate(availableSurcharge, type, true)}
                                            >
                                                <Plus />
                                                <span>
                                                    <FormattedMessage defaultMessage="Periode toevoegen" />
                                                </span>
                                            </TextButton>
                                            {/* Don't display a seperator when last */}
                                            {index !== filtered.length - 1 && <Seperator variant="small" />}
                                        </td>
                                    </tr>
                                </Fragment>
                            );
                        })}
                    </tbody>
                </StyledTable>
            </Box>
        ) : null;
    };

export default SurchargeTable;

const TableRowHeader = styled.th`
    position: relative;
    width: 26.4rem;
    padding-bottom: 1.6rem;
    padding-top: 1.2rem;

    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        padding-top: 0;
        padding-bottom: 2.4rem;

        > div {
            padding-bottom: 0;
            position: absolute;
            width: 100%;
            top: 1rem;
            left: 0;
        }
    }
`;

const ExtraSeperator = styled(Seperator)`
    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        margin: 1.6rem 0;
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        display: none;
    }
`;

const StyledTable = styled(Table)`
    ${css`
        @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
            min-width: 80rem;
        }
    `}

    tr:first-of-type {
        td {
            padding-top: 1.2rem;

            @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
                padding-top: 2.4rem;
            }
        }

        ${TableRowHeader} {
            > div {
                top: 2.4rem;
            }
        }
    }
`;
