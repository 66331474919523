import gql from 'graphql-tag';
import React, { FC } from 'react';
import { NotFoundError } from '../../../../entities/NotFoundError';
import { UnitParams } from '../../../../entities/UnitParams';
import { ImageFragment, useEditRentalUnitImagesMutation, useUnitImagesQuery } from '../../../../generated/graphql';
import { getUnitTypeTranslation } from '../../../../utils/getUnitTypeTranslation';
import Loading from '../../../organisms/Loading';
import ImagesFormScreen, { OtherImage, imageFragment } from './ImagesFormScreen';
import upsertImage from '../../../../utils/upsertImage';
import { MAX_UPLOAD_IMAGES } from '../../../../constants/imageRequirements';
import { FormattedMessage } from 'react-intl';

export const unitImagesFragment = gql`
    fragment UnitImages on RentalUnit {
        id
        type
        mainImage {
            id
            size
            url
            dimensions
            previewUrl
        }
        listImage {
            id
            size
            url
            dimensions
            previewUrl
        }
        images {
            ...Image
        }
        fullCircleImageUrl
        videoUrl
    }

    ${imageFragment}
`;

export const imagesQuery = gql`
    query UnitImages($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            ...UnitImages
        }
    }
    ${unitImagesFragment}
`;

export const editRentalUnitImagesMutation = gql`
    mutation EditRentalUnitImages($input: EditRentalUnitImagesInput!) {
        editRentalUnitImages(input: $input) {
            rentalUnit {
                ...UnitImages
            }
        }
    }
    ${unitImagesFragment}
`;

const UnitImages: FC<React.PropsWithChildren<UnitParams>> = ({ unitSlug }) => {
    const { data, loading } = useUnitImagesQuery({ variables: { unitSlug } });
    const [mutate] = useEditRentalUnitImagesMutation();

    if (loading) {
        return <Loading />;
    }

    if (!data || !data.rentalUnit) {
        throw new NotFoundError();
    }

    const {
        rentalUnit: { images, id, mainImage, listImage, type, fullCircleImageUrl, videoUrl },
    } = data;
    const initialValues = {
        mainImage: mainImage
            ? {
                  file: mainImage.url,
                  dimensions: mainImage.dimensions,
                  previewUrl: mainImage.previewUrl,
                  imageId: mainImage.id,
              }
            : null,
        listImage: listImage
            ? {
                  file: listImage.url,
                  previewUrl: listImage.previewUrl,
                  imageId: listImage.id,
                  dimensions: listImage.dimensions,
              }
            : null,
        images: [
            ...images
                .filter((image): image is ImageFragment => !!image)
                .sort((a, b) => a.position - b.position)
                .map(({ url, previewUrl, dimensions, category, id: imageId }) => ({
                    file: url,
                    previewUrl,
                    description: category,
                    imageId,
                    dimensions,
                })),
        ] as Array<OtherImage | null>,
        type: 'rentalUnit' as const,
        fullCircleImageUrl: fullCircleImageUrl ?? null,
        videoUrl: videoUrl ?? null,
    };
    if (initialValues.images.length < MAX_UPLOAD_IMAGES) {
        // `null` will result in an empty upload field
        initialValues.images.push(null);
    }

    const unitType = getUnitTypeTranslation(type)?.toLowerCase();

    const formContent = {
        header: {
            title: `Foto’s ${unitType}`,
            description: `Hier stel je de foto’s in van je ${unitType}. Travelbase zorgt voor de juiste maten (en eventueel uitsnede). We raden je aan om foto’s te bewerken voor schermweergave, maar waar mogelijk in de originele resolutie te houden (niet vergroten of verkleinen). Je kunt bestanden uploaden tot maximaal 25 MB per bestand.`,
        },
        mainImage: {
            title: `Hoofdfoto`,
            description: `De hoofdfoto toont Travelbase op de detailpagina van je ${unitType} (in het groot). De foto is bij voorkeur in een breedbeeld 16:9 (liggende) verhouding. Foto’s in standaard 4:3 verhouding worden door Travelbase uitgesneden naar het midden. We raden als resolutie 1920x1080 of hoger aan. Travelbase accepteert minimaal 1280x720.`,
        },
        listImage: {
            title: `Lijstfoto`,
            description: (
                <>
                    <FormattedMessage
                        defaultMessage="De lijstfoto van je {unitType} toont Travelbase in zoekresultaten en lijsten. De foto is bij voorkeur in een standaard 4:3 (liggende) verhouding. We raden als resolutie 1600x1200 of hoger aan. Travelbase accepteert minimaal 800x600."
                        values={{ unitType }}
                    />
                    <strong>
                        <FormattedMessage
                            defaultMessage="Let op: de lijstfoto wordt niet getoond op de detailpagina van je {unitType}, voeg deze ook toe onderaan bij de detailfoto’s om hem te tonen."
                            values={{ unitType }}
                        />
                    </strong>
                </>
            ),
        },
        images: {
            title: `Detailfoto’s`,
            description: `Voeg tot maximaal ${MAX_UPLOAD_IMAGES} foto’s toe van de verschillende ruimtes van ${unitType}. Je kunt de foto’s in de juiste volgorde slepen en voorzien van het juiste onderschrift. Detailfoto’s mogen liggend of staand zijn. Standaard 4:3 (liggende) foto’s komen het beste tot zijn recht. De verhouding tussen breedte en hoogte van de foto’s mag niet groter zijn dan 1:2. We raden als resolutie 1600x1200/1200x1600 of hoger aan. Travelbase accepteert minimaal 800x600/600x800.`,
        },
        fullCircleImage: {
            title: `360 graden foto`,
            description: `Plaats hier de URL van de 360 graden foto van jouw accommodatie/jouw locatie.`,
        },
        video: {
            title: `Video`,
            description: `Plaats hier de URL van de YouTube of Vimeo video van jouw accommodatie/locatie. De contactgegevens van je accommodatie mogen in de film niet zichtbaar zijn.`,
        },
    };

    return (
        <ImagesFormScreen
            content={formContent}
            handleSubmit={async values => {
                if (values.listImage) {
                    await mutate({
                        variables: {
                            input: {
                                images: values.images
                                    .filter((image): image is NonNullable<typeof image> => !!image)
                                    .map(({ description, file, dimensions, previewUrl, ...rest }) => ({
                                        category: description,
                                        ...rest,
                                    })),
                                listImageUploadId: values.listImage?.uploadId,
                                mainImageUploadId: upsertImage(values.mainImage),
                                fullCircleImageUrl: values.fullCircleImageUrl ?? null,
                                videoUrl: values.videoUrl ?? null,
                                rentalUnitId: id,
                            },
                        },
                    });
                }
            }}
            level="rentalUnit"
            initialValues={initialValues}
            showCaptionSelect={false}
        />
    );
};

export default UnitImages;
