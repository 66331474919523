import { Box } from '@rebass/grid';
import { Field, FieldConfig, FieldProps } from 'formik';
import React, { FC, useRef, useState, useCallback, ChangeEvent, useEffect } from 'react';
import styled from 'styled-components';
import { getAssignmentColor } from '../../constants/theme';
import { Maybe } from '../../entities/Maybe';
import useIntervalClick from '../../hooks/useIntervalClick';
import RoundButton, { RoundButtonVariant } from '../action/RoundButton';
import Min from '../figure/Min';
import Plus from '../figure/Plus';
import ErrorMessage from './ErrorMessage';

export type StepperSize = 'small' | 'medium' | 'large';

interface StepperFieldProps extends FieldConfig {
    minimum?: number;
    maximum?: number;
    disabled?: boolean;
    size?: StepperSize;
    variant?: RoundButtonVariant;
    className?: string;
    nullable?: boolean;
    editable?: boolean;
}

export const StepperField: FC<React.PropsWithChildren<StepperFieldProps>> = ({
    maximum = Number.MAX_VALUE,
    minimum = 0,
    disabled,
    size = 'medium',
    variant = 'outline',
    nullable,
    editable,
    // Stepper provides you with a validator entirely for free,
    // - without you needing to add it to your validation scheme.
    // if this is not behavior you want, you can override it with a nop function in the props.
    validate = val =>
        val === null
            ? undefined
            : val < minimum
            ? 'Waarde ligt onder het minimum'
            : val > maximum
            ? 'Waarde ligt boven het maximum'
            : undefined,

    ...props
}) => (
    <Field {...props} validate={validate}>
        {({ form: { setFieldValue, setFieldTouched }, field: { name, value }, meta: { error } }: FieldProps) => (
            <Stepper
                editable={!!editable}
                disabled={!!disabled}
                nullable={!!nullable}
                value={value}
                maximum={maximum}
                minimum={minimum}
                name={name}
                size={size}
                setValue={val => {
                    setFieldTouched(name, true, false);
                    setFieldValue(name, val, true);
                }}
                variant={variant}
                error={error}
            />
        )}
    </Field>
);

interface StepperProps extends Required<Omit<StepperFieldProps, keyof FieldConfig | 'className'>> {
    setValue: (value: Maybe<number>) => void;
    value: Maybe<number>;
    error?: string;
    name?: string;
    className?: string;
    buttonClassName?: string;
}

const Stepper: FC<React.PropsWithChildren<StepperProps>> = ({
    value,
    setValue,
    minimum,
    maximum,
    name,
    error,
    disabled,
    size = 'medium',
    variant = 'outline',
    className,
    nullable,
    editable,
    buttonClassName,
}) => {
    const dispatch = (type: 'increment' | 'decrement') =>
        setValue(
            (() => {
                switch (type) {
                    case 'increment': {
                        if (value === null) {
                            return minimum;
                        }

                        const newValue = (value ?? minimum) + 1;
                        if (newValue > maximum) {
                            return value;
                        }
                        return newValue;
                    }
                    case 'decrement': {
                        const newValue = (value ?? minimum) - 1;

                        if (newValue < minimum && !nullable) {
                            return value;
                        }

                        if (nullable && newValue < minimum) {
                            return null;
                        }

                        return newValue;
                    }
                    default:
                        throw new Error('Stepper: invalid action dispatched');
                }
            })()
        );
    const [editMode, setEditMode] = useState<boolean>(false);
    const incrementProps = useIntervalClick(() => dispatch('increment'));
    const decrementProps = useIntervalClick(() => dispatch('decrement'));
    const roundButtonProps = { variant, size, roundButtonClassName: buttonClassName };
    const inputRef = useRef<HTMLInputElement>(null);
    const [editableValue, setEditableValue] = useState<Maybe<number | string>>(value);

    useEffect(() => setEditableValue(value), [value]);

    const onEditableInputChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const newValue = event.currentTarget.value !== '' ? parseInt(event.currentTarget.value, 10) : '';

            if (typeof newValue !== 'number') {
                setEditableValue(newValue);
            }

            if (newValue > maximum || newValue < minimum) {
                return;
            }

            setEditableValue(newValue);
        },
        [maximum, minimum]
    );

    const onEditableValueClick = useCallback(() => {
        setEditMode(true);
        setTimeout(() => inputRef.current?.focus());
    }, []);

    return (
        <Box className={className}>
            <Container>
                <RoundButton
                    disabled={nullable ? disabled || value === null : disabled || (value ?? 0) <= minimum}
                    {...roundButtonProps}
                    {...decrementProps}
                >
                    <Min />
                </RoundButton>
                {editMode && editable && (
                    <Box width="4rem" px={2}>
                        <Input
                            max={maximum}
                            min={minimum}
                            ref={inputRef}
                            value={editableValue ?? ''}
                            type="number"
                            onChange={onEditableInputChange}
                            onBlur={() => {
                                setEditMode(false);
                                if (typeof editableValue === 'number') {
                                    setValue(editableValue);
                                }
                            }}
                        />
                    </Box>
                )}
                {!editMode && (
                    <Value onClick={editable ? onEditableValueClick : undefined} disabled={disabled}>
                        {value === null ? '?' : value}
                    </Value>
                )}
                <RoundButton disabled={disabled || value === maximum} {...roundButtonProps} {...incrementProps}>
                    <Plus />
                </RoundButton>
            </Container>
            {error && <ErrorMessage name={name}>{error}</ErrorMessage>}
        </Box>
    );
};

const Container = styled.div`
    display: flex;
    align-items: center;
    margin: 1rem 0;
`;

const Input = styled.input`
    border-radius: ${({ theme }) => theme.radius.textInput};
    width: 100%;
    text-align: center;
    background: none;
    outline: none;
    font: inherit;
    border: none;
    color: currentColor;
    :focus {
        box-shadow: 0 0 0 1px var(--border-color), inset 0 0 0 1px var(--border-color);
        --border-color: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.input)};
    }

    /* styles to remove the arrow buttons */
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    [type='number'] {
        -moz-appearance: textfield;
    }
`;

const Value = styled.span<Pick<StepperProps, 'disabled'>>`
    line-height: 1em;
    width: 4rem;
    text-align: center;
    color: ${({ disabled, theme }) => (disabled ? theme.colors.neutral['30'] : 'inherit')};
    transition: color 200ms;
`;

export default Stepper;
