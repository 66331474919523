import styled from 'styled-components/macro';

export default styled.div<{ justifyContent?: 'center' | 'flex-start' }>`
    min-height: ${({ theme }) => theme.spacing['50_Semi']};
    margin: 0 0 1.6rem 0;

    @media (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        min-height: ${({ theme }) => theme.spacing['70_XLarge']};
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        width: 26.4rem;
        padding-right: 1rem;
        flex-shrink: 0;
    }

    display: flex;
    justify-content: ${({ justifyContent }) => justifyContent};
    flex-direction: column;
`;
