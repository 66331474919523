import { ListOnItemsRenderedProps } from 'react-window';
import { differenceInDays, addDays } from 'date-fns';
import epoch from '../constants/epoch';

interface Position {
    startIndex: number;
    endIndex: number;
}

/**
 * Determines the position of an item on the pricing list.
 * Clamps values that are bigger than the overscan for performance reasons.
 */
export const getPosition = (
    interval: { startDate: string; endDate: string },
    { overscanStartIndex, overscanStopIndex }: ListOnItemsRenderedProps
): Position => {
    let [startIndex, endIndex] = [interval.startDate, addDays(new Date(interval.endDate), 1)].map(val =>
        differenceInDays(new Date(val), epoch)
    );

    if (overscanStartIndex > startIndex && overscanStopIndex < endIndex) {
        if (startIndex < overscanStartIndex) {
            startIndex = overscanStartIndex;
        }

        if (endIndex > overscanStopIndex) {
            endIndex = overscanStopIndex;
        }
    }

    // TODO: don't render items that are fully outside of overscan

    return {
        startIndex,
        endIndex,
    };
};
