import { Address } from '../generated/graphql';
import { FormikProps } from 'formik';

const buildGeocodingForwardUrl = (countryCode: string, ...args: string[]) =>
    `https://mapbox.travelbase.nl/geocoding/v5/mapbox.places/${encodeURIComponent(
        args.join(' ')
    )}.json?country=${countryCode}&access_token=${process.env.REACT_APP_MAPBOX_KEY}`;

const requestLocation = (url: string) =>
    fetch(`${url}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const determineMapLocation = async <Values extends { address: Address }>({
    values,
    setFieldValue,
    setFieldTouched,
    setFieldError,
}: FormikProps<Values>) => {
    const geocodingUrl = buildGeocodingForwardUrl(
        values.address.countryCode ?? 'NL',
        values.address.street ?? '',
        values.address.number ?? '',
        values.address.postalCode ?? '',
        values.address.city ?? ''
    );

    const res = await requestLocation(geocodingUrl);
    setFieldTouched('coordinates', true);

    if (res.ok) {
        const response = await res.json();
        const { features } = response;

        if (features.length > 0) {
            const { center } = features[0];
            const [lng, lat] = center;
            setFieldValue('coordinates', { longitude: lng, latitude: lat });
        } else {
            setFieldError('coordinates', 'Er zijn geen resultaten gevonden met dit adres.');
        }
    } else {
        setFieldError('coordinates', 'De locatie kon niet worden bepaald. Voer een geldig adres in.');
    }
};
