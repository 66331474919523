import React, { createContext, Dispatch, FC, useContext, useReducer, useRef, useEffect } from 'react';
import { AllotmentSidebarData } from '../components/molecules/AllotmentSidebar';
import { AllotmentBulkSidebarData } from '../components/molecules/AllotmentBulkSidebar';
import { BookingSidebarData } from '../components/molecules/BookingSidebar';
import { BulkEditSidebarData } from '../components/molecules/BulkEditSidebar';
import { PricingConfigSidebarData } from '../components/molecules/PricingConfigSidebar';
import { DayinfoSidebarData } from '../components/molecules/DayinfoSidebar';
import { LockoutSidebarData } from '../components/molecules/LockoutSidebar';
import { PeriodRuleSidebarData } from '../components/molecules/PeriodRuleSidebar';
import { UnreachableCaseError } from '../entities/UnreachableCaseError';
import { ImportInfoSidebarData } from '../components/molecules/ImportInfoSidebar';
import { SpecialSidebarData } from '../components/molecules/SpecialSidebar';
import { useLocation } from 'react-router-dom';
import { TimeslotsSidebarData } from '../components/molecules/TimeslotsSidebar';

export interface SidebarContextType {
    state: SidebarState;
    dispatch: Dispatch<SidebarAction>;
}

export type SidebarData =
    | BulkEditSidebarData
    | PricingConfigSidebarData
    | DayinfoSidebarData
    | PeriodRuleSidebarData
    | AllotmentSidebarData
    | AllotmentBulkSidebarData
    | LockoutSidebarData
    | BookingSidebarData
    | ImportInfoSidebarData
    | SpecialSidebarData
    | TimeslotsSidebarData;

export interface SidebarState {
    data?: SidebarData;
    open: boolean;
    hidden: boolean;
}

const initialState: SidebarState = {
    open: false,
    hidden: false,
};

export interface SidebarAction {
    type: 'show' | 'close' | 'hide' | 'clear' | 'set';
    data?: SidebarData;
}

const SidebarStateContext = createContext<SidebarState | null>(null);
const SidebarDispatchContext = createContext<((action: SidebarAction) => void) | undefined>(undefined);

function reducer(state: SidebarState = initialState, action: SidebarAction): SidebarState {
    switch (action.type) {
        case 'show':
            return {
                data: action.data ?? state.data,
                open: true,
                hidden: false,
            };
        case 'hide':
            return { ...state, hidden: true };
        case 'set':
            return { ...state, data: action.data ?? state.data };
        case 'close':
            return { data: state.data, open: false, hidden: false };
        case 'clear':
            return initialState;
        default:
            throw new UnreachableCaseError(action.type);
    }
}

const SidebarProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const { pathname } = useLocation();
    const oldPathname = useRef(pathname);

    // If the route changes we want to close the sidebar if it's open
    useEffect(() => {
        if (pathname !== oldPathname.current) {
            if (state.open) {
                dispatch({ type: 'close' });
            }
            oldPathname.current = pathname;
        }
    }, [pathname, state.open]);

    return (
        <SidebarStateContext.Provider value={state}>
            <SidebarDispatchContext.Provider value={dispatch}>{children}</SidebarDispatchContext.Provider>
        </SidebarStateContext.Provider>
    );
};

const useSidebarState = () => {
    const context = useContext(SidebarStateContext);
    if (!context) {
        throw new Error('useSidebarState must be used within a SidebarProvider');
    }
    return context;
};

const useSidebarDispatch = () => {
    const context = useContext(SidebarDispatchContext);
    if (!context) {
        throw new Error('useSidebarDispatch must be used within a SidebarProvider');
    }
    return context;
};

const useSidebar = () => [useSidebarState(), useSidebarDispatch()] as const;

export { SidebarProvider, useSidebar, useSidebarState, useSidebarDispatch };

export type SidebarHook = ReturnType<typeof useSidebar>;
