import { getIn, FormikErrors, FormikTouched } from 'formik';
import omit from 'lodash/omit';
import { Image } from '../entities/Image';

export const getImageFormError = (name: string, errors: FormikErrors<unknown>, touched: FormikTouched<unknown>) => {
    const error: string | { file: string } = getIn(errors, name);
    return getIn(touched, name) && error ? (typeof error === 'string' ? error : error.file) : undefined;
};

export const transformImage = <T extends Image>(image: T) => ({ imageId: image.id, ...omit(image, 'file') });
