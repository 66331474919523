import React, { HTMLAttributes } from 'react';

export default function (props: HTMLAttributes<SVGElement>) {
    return (
        <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g transform="translate(-28 -18)" fill="none" fillRule="evenodd">
                <rect stroke="#DCE2E3" fill="#FFF" x=".5" y=".5" width="510" height="75" rx="2" />
                <g transform="translate(28 18)">
                    <circle fill="#00C0E0" cx="8" cy="8" r="8" />
                    <g stroke="#FFF" strokeWidth="2">
                        <path d="M8 12.667V6.833M8 5.375v-1.75 1.75z" />
                    </g>
                </g>
            </g>
        </svg>
    );
}
