import { darken } from 'polished';
import transparentize from 'polished/lib/color/transparentize';
import React, {
    ChangeEvent,
    forwardRef,
    ForwardRefRenderFunction,
    InputHTMLAttributes,
    Ref,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import styled, { css } from 'styled-components';
import { Theme } from '../../constants/theme';

export type AllotmentIndicatorSize = 'small' | 'medium' | 'large' | 'fill';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'value' | 'css'> {
    value: number | null;
    nullPlaceholder?: string;
    setValue?: (val: number | null) => void;
    max: number;
    min?: number;
    size?: AllotmentIndicatorSize;
    disabled?: boolean;
    editable?: boolean;
    ref?: Ref<HTMLInputElement>;
    onContainerClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

const getColor = (value: number, max: number, theme: Theme): string => {
    if (value === 0) {
        return theme.colors.negative['50'];
    }

    return transparentize(Math.min(value / max, 0.9), theme.colors.primary['30']);
};

const getTextColor = (value: number, max: number, theme: Theme): string => {
    if (value / max <= 0.5) {
        return theme.colors.neutral['0'];
    }

    return theme.colors.primary['40'];
};

const AllotmentIndicator: ForwardRefRenderFunction<HTMLInputElement, Props> = (
    {
        value,
        setValue,
        max = 999,
        min = 0,
        size = 'medium',
        disabled,
        editable,
        onChange,
        onContainerClick,
        className,
        nullPlaceholder = '∞',
        ...inputProps
    },
    ref
) => {
    const [editableValue, setEditableValue] = useState<number | string | null>(value);

    useEffect(() => {
        setEditableValue(value);
    }, [value]);

    const onEditableInputChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const newValue = event.currentTarget.value !== '' ? parseInt(event.currentTarget.value, 10) : '';

            if (typeof newValue === 'number' && (newValue > max || newValue < min)) {
                return;
            }

            setEditableValue(newValue);
        },
        [max, min]
    );
    const inputRef = useRef<HTMLInputElement>();
    const callbackRef = useCallback(
        (node: HTMLInputElement) => {
            if (!node) {
                return;
            }

            inputRef.current = node;

            if (typeof ref === 'function') {
                ref(node);
            } else {
                if (!ref?.current) {
                    return;
                }
                // eslint-disable-next-line no-param-reassign
                ref.current = node;
            }
        },
        [ref]
    );

    return (
        <AllotmentIndicatorContainer
            value={typeof editableValue === 'number' ? editableValue : max}
            max={max}
            size={size}
            disabled={disabled}
            editable={editable}
            className={className}
            onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                if (!editable) {
                    return;
                }
                onContainerClick?.(event);

                if (typeof value === 'number') {
                    return;
                }
                setEditableValue('');
                // wait for state to be updated
                setTimeout(() => {
                    inputRef.current?.focus();
                    inputRef.current?.select();
                });
            }}
        >
            {editableValue === null && <span>{nullPlaceholder}</span>}
            {editableValue !== null && (
                <form
                    onSubmit={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        inputRef.current?.blur();
                    }}
                >
                    <input
                        onBlur={() => {
                            if (editableValue === value) {
                                return;
                            }
                            setValue?.(typeof editableValue === 'number' ? editableValue : null);
                            // wait for state to be updated
                            setTimeout(() => {
                                if (value === null && editableValue === '') {
                                    setEditableValue(null);
                                }
                            });
                        }}
                        onFocus={e => e.currentTarget.select()}
                        onChange={onEditableInputChange}
                        maxLength={3}
                        max={max}
                        min={min}
                        type="number"
                        ref={callbackRef}
                        value={editableValue}
                        disabled={!editable || disabled}
                        {...inputProps}
                    />
                </form>
            )}
        </AllotmentIndicatorContainer>
    );
};

const smallCss = css`
    height: 2.4rem;
    min-width: 3.6rem;
`;

const mediumCss = css`
    height: 2.8rem;
    min-width: 4rem;
`;

const largeCss = css`
    height: 3.2rem;
    min-width: 4.8rem;
    > input {
        font-size: 18px;
    }
    > span {
        font-size: 24px;
    }
`;

const fillCss = css`
    ${largeCss}
    height: 100%;
    width: 100%;
    > form {
        width: 100%;
        input {
            width: 100%;
            padding: 0.8rem;
            text-align: center;
        }
    }
`;

export const AllotmentIndicatorContainer = styled.span<Omit<Props, 'min' | 'setValue' | 'value'> & { value: number }>`
    :focus-within {
        border-color: ${({ value, theme, max }) => darken(0.2, getColor(value > max ? max - 1 : value, max, theme))};
    }
    input {
        background: none;
        border: none;
        width: 3.2rem;
        padding: 0;
        outline: none;
        color: currentColor;
        text-align: center;
        font-size: 14px;
        height: 100%;
        ::selection {
            background: ${({ value, max, theme, disabled }) =>
                disabled ? theme.colors.neutral['20'] : darken(0.2, getColor(value, max, theme))};
        }
        /* styles to remove the arrow buttons */
        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        [type='number'] {
            -moz-appearance: textfield;
        }
    }
    > span {
        font-size: 20px;
    }
    border-width: 1px;
    border-style: solid;
    border-color: ${({ value, max, theme, disabled }) =>
        disabled ? theme.colors.neutral['20'] : getColor(value > max ? max - 1 : value, max, theme)};
    background-color: ${({ value, max, theme, disabled }) =>
        disabled ? theme.colors.neutral['20'] : getColor(value, max, theme)};
    color: ${({ value, max, theme, disabled }) =>
        disabled ? theme.colors.neutral['0'] : getTextColor(value, max, theme)};
    border-radius: 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    ${({ size }) => size === 'fill' && fillCss}
    ${({ size }) => size === 'large' && largeCss}
    ${({ size }) => size === 'small' && smallCss}
    ${({ size }) => size === 'medium' && mediumCss}
`;

export default forwardRef(AllotmentIndicator);
