import Hamburger from '@oberoninternal/travelbase-ds/components/figure/Hamburger';
import Logo from '@oberoninternal/travelbase-ds/components/figure/LogoText';
import NavCross from '@oberoninternal/travelbase-ds/components/figure/NavCross';
import Right from '@oberoninternal/travelbase-ds/components/figure/Right';
import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import ActionMenu, { Item, MenuList } from '@oberoninternal/travelbase-ds/components/action/ActionMenu';
import Sidebar from '@oberoninternal/travelbase-ds/components/layout/Sidebar';
import useMenuState from '@oberoninternal/travelbase-ds/hooks/useMenuState';
import { Box, Flex } from '@rebass/grid';
import gql from 'graphql-tag';
import React, { FC, Fragment, useCallback } from 'react';
import { generatePath, NavLink, NavLinkProps, useHistory, useRouteMatch } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { PartnerParams } from '../../entities/PartnerParams';
import { Route } from '../../entities/Route';
import { UnitParams } from '../../entities/UnitParams';
import { ActivityParams } from '../../entities/ActivityParams';
import { AccPickerEntryFragment, CompanyPickerEntryFragment, NavigationUserFragment } from '../../generated/graphql';
import UnitNavigation from './UnitNavigation';
import ActivityNavigation from './ActivityNavigation';
import brandConfig from '../../constants/brandConfig';
import useSesame, { SesameOptions } from '@oberoninternal/travelbase-ds/hooks/useSesame';
import useOnRouteChange from '../../hooks/useOnRouteChange';
import Toggle from '@oberoninternal/travelbase-ds/components/figure/Toggle';
import { tripsAreActive } from '../../utils/primarypricing';
import { useLogout } from '../../hooks/useLogout';
import groupBy from 'lodash/groupBy';
import { FormattedMessage, useIntl } from 'react-intl';

export const fragment = gql`
    fragment NavigationUser on User {
        id
        fullName
        isBackofficeUser
        email
        impersonatedBy {
            fullName
        }
        partners {
            id
            accommodations {
                rentalUnits {
                    id
                    name
                }
            }
        }
    }
`;

interface Props {
    navigationItems: Route[];
    mobileNavigationItems: Route[];
    user?: NavigationUserFragment | null;
    datePricingStartDate?: Date;
    datePricingEndDate?: Date;
    canSwitchPartner: boolean;
    accPickerEntries: AccPickerEntryFragment[];
    activityPickerEntries: CompanyPickerEntryFragment[];
}

// determine what pricing model to show
export const createPriceRoutingFilter =
    (start: Date | undefined, end: Date | undefined) =>
    ({ source }: Route): boolean => {
        if (!start) {
            return source !== 'prices';
        }

        if (!tripsAreActive(start, end)) {
            return source !== 'trips';
        }

        return true;
    };

const sesameOptions: SesameOptions = {
    closeOnClickOutside: true,
    closeOnEscape: true,
};

const Navigation: FC<React.PropsWithChildren<Props>> = ({
    navigationItems,
    mobileNavigationItems,
    datePricingStartDate,
    datePricingEndDate,
    canSwitchPartner,
    user,
    accPickerEntries,
    activityPickerEntries,
}) => {
    const logout = useLogout();
    const { formatMessage } = useIntl();
    const {
        path: rootPath,
        params: { partnerId },
    } = useRouteMatch<PartnerParams>();

    const history = useHistory();

    // UnitNavigation Sesame
    const {
        open: unitNavigationIsOpen,
        onToggle: onToggleUnitNavigation,
        onClose: onCloseUnitNavigation,
        ref: unitRef,
    } = useSesame(false, sesameOptions);

    // ActivityNavigation Sesame
    const {
        open: activityNavigationIsOpen,
        onClose: onCloseActivityNavigation,
        ref: activityRef,
    } = useSesame(false, sesameOptions);

    const ref = useCallback((node: HTMLDivElement) => {
        unitRef.current = node;
        activityRef.current = node;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        open: profileMenuIsOpen,
        onClose: onCloseProfileMenu,
        onToggle: onToggleProfileMenu,
        ref: profileMenuRef,
    } = useSesame(false, sesameOptions);

    useOnRouteChange(() => {
        onCloseUnitNavigation();
        onCloseActivityNavigation();
        onCloseProfileMenu();
    });

    // we use unitMatch in order to get slug params and to use its path
    // for the mobile navigation
    const unitMatch = useRouteMatch<UnitParams>({ path: `${rootPath}/unit/:unitSlug` });
    const showUnitNavigation = !unitMatch && accPickerEntries.length > 0 && accPickerEntries[0].rentalUnits.length > 0;

    const activityMatch = useRouteMatch<ActivityParams>({ path: `${rootPath}/activity/:activitySlug` });
    const companyMatch = useRouteMatch<ActivityParams>({ path: `${rootPath}/company/:companySlug` });

    const showActivityNavigation = !activityMatch && activityPickerEntries.length > 0;

    const { open, setOpen, menuProps } = useMenuState(false, { closeClickOnOutside: false });

    const mapRoutesByGroup = new Map(Object.entries(groupBy(mobileNavigationItems, 'group')));
    const routesByGroup = [...mapRoutesByGroup.values()];

    const renderMobileNavItem = (to: NavLinkProps['to'], title: string, compact?: boolean, subroute?: boolean) => (
        <NavLinkItem key={title} to={to} onClick={() => setOpen(false)} compact={compact} subroute={subroute}>
            {title}
            <Right />
        </NavLinkItem>
    );

    const generateMobileNavPath = (route: Route, source: string) => {
        if (unitMatch && route.source === '/unit') {
            return generatePath(`${unitMatch?.path}/${source}`, { partnerId, unitSlug: unitMatch.params.unitSlug });
        }

        if (activityMatch && route.source === '/activity') {
            return generatePath(`${activityMatch?.path}/${source}`, {
                partnerId,
                activitySlug: activityMatch?.params.activitySlug,
            });
        }

        if (companyMatch && route.source === '/company') {
            return generatePath(`${companyMatch?.path}/${source}`, {
                partnerId,
                companySlug: companyMatch?.params.companySlug,
            });
        }

        if (!unitMatch || !activityMatch || !companyMatch) {
            return {
                state: { to: source },
                pathname: generatePath(`${rootPath}${route.source}`, { partnerId }),
            };
        }

        return generatePath(`${rootPath}${route.source}/${source}`, { partnerId });
    };

    const routeFilter = createPriceRoutingFilter(datePricingStartDate, datePricingEndDate);

    const overTitle = user?.isBackofficeUser ? 'backoffice' : user?.impersonatedBy ? `als ${user.fullName}` : undefined;
    const userName = user?.impersonatedBy ? user.impersonatedBy.fullName : user?.fullName;

    const isActivityOrCompanyActive = () => !!activityMatch || !!companyMatch;

    return (
        <>
            <Box ref={ref}>
                <Container>
                    <LogoLink to={generatePath(`${rootPath}/profile`, { partnerId })}>
                        <Logo variant="dark" />
                    </LogoLink>
                    <Seperator />
                    {navigationItems.map(route => {
                        const linkProps = {
                            key: route.source,
                            to: generatePath(`${rootPath}${route.source}`, { partnerId }),
                        };
                        return (
                            <Fragment key={linkProps.key}>
                                {route.seperator && <Seperator />}
                                <Link
                                    {...linkProps}
                                    // the activity link should be active when we're on a activity or company page
                                    isActive={route.source === '/activity' ? isActivityOrCompanyActive : undefined}
                                >
                                    {formatMessage(route.title)}
                                    {route.source === '/unit' && (
                                        <SVGButton
                                            onClick={e => {
                                                e.preventDefault();
                                                if (unitMatch) {
                                                    return;
                                                }
                                                onToggleUnitNavigation();
                                            }}
                                        >
                                            <Toggle />
                                        </SVGButton>
                                    )}

                                    {route.source === '/activity' && (
                                        <SVGButton>
                                            <Toggle />
                                        </SVGButton>
                                    )}
                                </Link>
                            </Fragment>
                        );
                    })}
                    {user && userName && (
                        <Personal>
                            <StyledActionMenu
                                align="right"
                                badge={<Letter>{userName[0]}</Letter>}
                                title={userName}
                                overTitle={overTitle}
                                ref={profileMenuRef}
                                onToggle={onToggleProfileMenu}
                                open={profileMenuIsOpen}
                            >
                                <MenuList>
                                    <Item>
                                        <TextButton onClick={() => history.push(`/partner/${partnerId}/profile`)}>
                                            <FormattedMessage defaultMessage="Jouw gegevens" />
                                        </TextButton>
                                    </Item>
                                    {canSwitchPartner && (
                                        <Item>
                                            <TextButton onClick={() => history.push('/')}>
                                                <FormattedMessage defaultMessage="Wissel van partner" />
                                            </TextButton>
                                        </Item>
                                    )}
                                    <Item>
                                        <TextButton
                                            onClick={async () => {
                                                if (
                                                    window.confirm(
                                                        formatMessage({
                                                            defaultMessage: 'Weet je het zeker? Je wordt nu uitgelogd.',
                                                        })
                                                    )
                                                ) {
                                                    await logout();
                                                    history.push('/forgot', { email: user?.email });
                                                }
                                            }}
                                        >
                                            <FormattedMessage defaultMessage="Wachtwoord wijzigen" />
                                        </TextButton>
                                    </Item>
                                    <Item>
                                        <TextButton onClick={() => history.push(`/partner/${partnerId}/qr-signup`)}>
                                            <FormattedMessage defaultMessage="QR Scanner" />
                                        </TextButton>
                                    </Item>
                                    <Item>
                                        <TextButton onClick={() => history.push(`/partner/${partnerId}/help`)}>
                                            <FormattedMessage defaultMessage="Uitleg" />
                                        </TextButton>
                                    </Item>
                                    <Item>
                                        <TextButton onClick={() => window.open(brandConfig.contactUrl)}>
                                            <FormattedMessage defaultMessage="Contact" />
                                        </TextButton>
                                    </Item>
                                    <Item>
                                        <TextButton onClick={() => logout(true)}>
                                            <FormattedMessage defaultMessage="Uitloggen" />
                                        </TextButton>
                                    </Item>
                                </MenuList>
                            </StyledActionMenu>
                        </Personal>
                    )}
                    <HamburgerButton onClick={() => setOpen(!open)}>
                        {open ? <NavCross /> : <Hamburger />}
                    </HamburgerButton>
                </Container>
                {showUnitNavigation && (
                    <StyledUnitNavigation
                        open={unitNavigationIsOpen}
                        accPickerEntries={accPickerEntries}
                        datePricingStartDate={datePricingStartDate ? new Date(datePricingStartDate) : undefined}
                        datePricingEndDate={datePricingEndDate ? new Date(datePricingEndDate) : undefined}
                    />
                )}
                {showActivityNavigation && (
                    <StyledActivityNavigation
                        open={activityNavigationIsOpen}
                        activityPickerEntries={activityPickerEntries}
                    />
                )}
            </Box>

            {/* Mobile menu */}
            <StyledSidebar variant="navigation" {...menuProps}>
                {routesByGroup.map(
                    (group, idx: number) =>
                        group && (
                            <NavGroup key={idx}>
                                {group.map(
                                    (route, i) =>
                                        route &&
                                        (route.subroutes ? (
                                            <div key={i}>
                                                <NavHeading idx={i}>
                                                    <strong>{formatMessage(route.title)}</strong>
                                                </NavHeading>
                                                {route.subroutes
                                                    .filter(routeFilter)
                                                    .map(({ title, source }) =>
                                                        renderMobileNavItem(
                                                            generateMobileNavPath(route, source),
                                                            formatMessage(title),
                                                            false,
                                                            true
                                                        )
                                                    )}
                                            </div>
                                        ) : (
                                            <div key={i}>
                                                {renderMobileNavItem(
                                                    generatePath(`${rootPath}${route.source}`, { partnerId }),
                                                    formatMessage(route.title),
                                                    true
                                                )}
                                            </div>
                                        ))
                                )}
                            </NavGroup>
                        )
                )}
            </StyledSidebar>
        </>
    );
};

export default Navigation;

const commonNavStyles = css<{ open: boolean }>`
    ${({ open, theme }) =>
        open
            ? css`
                  opacity: 1;
                  transform: translateY(0rem);
              `
            : css`
                  opacity: 0.5;
                  transform: translateY(-${theme.heights.unitNav}px);
              `};
    transition: all 200ms ease-in-out;
    z-index: ${({ theme }) => theme.zIndices.sticky};
`;

const StyledUnitNavigation = styled(UnitNavigation)<{ open: boolean }>`
    ${commonNavStyles}
`;

const StyledActivityNavigation = styled(ActivityNavigation)<{ open: boolean }>`
    ${commonNavStyles}
`;

const SVGButton = styled.button`
    height: 100%;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    color: inherit;
    margin-left: ${({ theme }) => theme.spacing['30_Small']};
`;

const StyledSidebar = styled(Sidebar)`
    padding-bottom: 11rem;
`;

const NavGroup = styled.div`
    margin-bottom: 2rem;
`;

const NavItem = styled(Flex)`
    height: 5.8rem;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[20]};
`;

const NavHeading = styled(NavItem)<{ idx: number }>`
    font-size: 16px;
    ${({ idx }) =>
        idx > 0 &&
        css`
            margin-top: 2rem;
        `};
`;
interface NavLinkItemProps {
    compact?: boolean;
    subroute?: boolean;
}

const NavLinkItem = styled(({ compact, subroute, ...props }) => <NavItem {...props} as={NavLink} />)<NavLinkItemProps>`
    text-decoration: none;
    color: inherit;
    ${({ compact }) =>
        compact &&
        css`
            font-weight: 500;
        `}
    ${({ subroute }) =>
        subroute &&
        css`
            padding-left: 1.4rem;
        `}

    > svg {
        color: ${({ theme }) => theme.colors.primary[40]};
    }

    + div {
        margin-top: 2rem;
    }
`;

const HamburgerButton = styled(Flex).attrs(() => ({ as: 'button' }))`
    height: 64px;
    width: 64px;
    align-items: center;
    justify-content: center;
    display: none;
    background: ${({ theme }) => theme.colors.primary[100]};
    color: white;
    @media (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        display: flex;
    }
`;

const StyledActionMenu = styled(ActionMenu)`
    @media (max-width: ${({ theme }) => theme.mediaQueries.xs}) {
        > button {
            > :nth-child(2) {
                display: none;
            }
        }
        > div {
            position: fixed;
            top: ${({ theme }) => theme.heights.mobileNav}px;
            left: 0;
            right: 0;
        }
    }
` as typeof ActionMenu;

const Letter = styled.div`
    background: ${({ theme }) => theme.colors.primary[40]};
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.neutral[0]};
`;

const Personal = styled(Flex)`
    margin-left: auto;
    align-items: center;
    padding-right: 1.6rem;
`;

export const Seperator = styled.span`
    height: 2.4rem;
    margin: auto 0;
    width: 1px;
    background: ${({ theme }) => theme.colors.neutral[20]};
    @media (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        display: none;
    }
`;

export const Container = styled(Flex).attrs(() => ({ as: 'nav', alignItems: 'center' }))`
    position: fixed;
    height: ${({ theme }) => theme.heights.nav}px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[20]};
    background: ${({ theme }) => theme.colors.neutral[0]};
    width: 100%;
    z-index: ${({ theme }) => theme.zIndices.sticky + 1};

    @media (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        height: ${({ theme }) => theme.heights.mobileNav}px;
    }

    > * + * {
        :not(:first-of-type) {
            margin-left: 2.8rem;
        }
    }
`;

const Link = styled(NavLink).attrs(() => ({ activeClassName: 'active' } as NavLinkProps))`
    text-decoration: none;

    :not(:first-of-type) {
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 14px;
        color: ${({ theme }) => theme.colors.neutral[50]};
        font-weight: 500;

        @media (max-width: ${({ theme }) => theme.mediaQueries.m}) {
            display: none;
        }
    }

    transition: color 200ms;
    &.active {
        color: ${({ theme }) => theme.colors.primary[100]};
    }
`;

export const LogoLink = styled(Link)`
    color: currentColor;
    margin: 0 3rem;
    > svg {
        width: 12rem;
    }

    @media (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        margin: 0 1.6rem;
    }
`;
