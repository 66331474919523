import React, { ComponentProps } from 'react';

export default function (props: ComponentProps<'svg'>) {
    return (
        <svg width="4" height="15" {...props}>
            <path
                d="M2 3a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0 5.333a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0 5.334a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
            />
        </svg>
    );
}
