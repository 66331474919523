import AllotmentIndicator, {
    AllotmentIndicatorContainer,
} from '@oberoninternal/travelbase-ds/components/calendar/AllotmentIndicator';
import DayHeader from '@oberoninternal/travelbase-ds/components/calendar/DayHeader';
import isBefore from 'date-fns/isBefore';
import startOfToday from 'date-fns/startOfToday';
import format from 'date-fns/format';
import { DocumentNode } from 'graphql';
import React, { FC, HTMLAttributes } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled, { css } from 'styled-components/macro';
import dateFormat from '../../constants/dateFormat';
import { PRICE_COLUMN_HEADER_HEIGHT } from '../../constants/priceRows';
import { useSidebar } from '../../context/sidebar';
import { PriceColumnAllotmentFragment } from '../../generated/graphql';
import Forbidden from '../atoms/Forbidden';
import Cell from './Cell';
import { OperationVariables } from '@apollo/client';

interface Props extends HTMLAttributes<HTMLDivElement> {
    allotment?: PriceColumnAllotmentFragment;
    maxAllotment: number;
    loading: boolean;
    unbookable: boolean;
    forbidden: boolean;
    disabled: boolean;
    date: Date;
    onInfoClick?: () => void;
    document: DocumentNode;
    variables?: OperationVariables;
    tooltipText?: string;
    showAllotmentLockouts?: boolean;
}

const PricingColumnAllotment: FC<React.PropsWithChildren<Props>> = ({
    date,
    unbookable,
    allotment,
    loading,
    maxAllotment,
    onInfoClick,
    disabled,
    document,
    variables,
    forbidden,
    tooltipText,
    showAllotmentLockouts,
    ...props
}) => {
    const isMultiple = maxAllotment > 1;
    const [, dispatch] = useSidebar();

    return (
        <Header
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                if (!e.isDefaultPrevented() && (isMultiple || !showAllotmentLockouts)) {
                    dispatch({
                        type: 'show',
                        data: {
                            type: 'ALLOTMENT_SIDEBAR',
                            allotment,
                            maxAllotment,
                            date: format(date, dateFormat),
                            document,
                            variables,
                        },
                    });
                }
            }}
            {...props}
        >
            <DayHeader
                tooltipText={tooltipText}
                onInfoClick={onInfoClick}
                unbookable={unbookable || forbidden}
                date={date}
                disabled={disabled || forbidden}
            />
            <AllotmentContainer date={date}>
                {loading && <Skeleton width={70} height={24} />}
                {(isMultiple || !showAllotmentLockouts) && !loading && (
                    <AllotmentIndicator
                        size="large"
                        value={allotment?.amount ?? 0}
                        max={maxAllotment}
                        disabled={!allotment}
                    />
                )}
                {!loading && forbidden && showAllotmentLockouts && <Forbidden />}
            </AllotmentContainer>
        </Header>
    );
};

export default PricingColumnAllotment;

const Header = styled(Cell)`
    padding: 0;
    height: ${PRICE_COLUMN_HEADER_HEIGHT}px;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
`;

const AllotmentContainer = styled.div<{ date: Date }>`
    flex: 1;
    align-items: center;
    display: flex;

    ${AllotmentIndicatorContainer} {
        ${({ date }) =>
            isBefore(date, startOfToday()) &&
            css`
                opacity: 0.4;
            `}
    }
`;
