import gql from 'graphql-tag';
import React, { FC } from 'react';
import { matchPath, Redirect, Route, useLocation, useRouteMatch } from 'react-router-dom';
import { UnitParams } from '../../entities/UnitParams';
import { ActivityParams } from '../../entities/ActivityParams';
import { usePartnerDataQuery } from '../../generated/graphql';
import Loading from '../atoms/Loading';
import ErrorBoundary from '../organisms/ErrorBoundary';
import Navigation from '../organisms/Navigation';
import Bookings from './Bookings';
import BookingDetails from './bookings/Details';
import NotFound from './NotFound';
import Reviews from './Reviews';
import ReviewDetails from './reviews/Details';
import Unit from './Unit';
import Activity from './Activity';
import Tickets from './Tickets';
import Settlements from './Settlements';
import Help from './Help';
import { NotFoundError } from '../../entities/NotFoundError';
import Profile from './partner/Profile';
import { getMainNavigationItems, getMainNavigationRoutesForMobile } from '../../utils/routes';
import BalanceItems from './BalanceItems';
import { PartnerParams } from '../../entities/PartnerParams';
import TicketDetails from './tickets/Details';
import getRentalUnitMetaData from '../../utils/getRentalUnitMetaData';
import QRSignup from './QRSignup';
import VisitorRegister from './VisitorRegister';
import { LocalizedSwitch } from '../atoms/LocalizedSwitch';
import { useIntl } from 'react-intl';

export const query = gql`
    query PartnerData($slug: String, $partnerId: ID!) {
        viewer {
            ...NavigationUser
        }
        partner(id: $partnerId) {
            id
            name
            accommodations {
                ...AccommodationEntry
                ...AccommodationInfo
            }
            companies {
                ...CompanyPickerEntry
                ...CompanyInfo
            }
        }
        rentalUnit(slug: $slug) {
            ...RentalUnitMetaData
        }
    }

    fragment AccommodationEntry on Accommodation {
        ...AccPickerEntry
        rentalUnits {
            ...RentalUnitInfo
            slug
        }
    }

    fragment RentalUnitMetaData on RentalUnit {
        id
        showAllotmentLockouts
        lastDatePricingDate
        datePricingStartDate
        datePricingEndDate
        type
        maxAllotment
        rentalUnitContracts {
            id
            approvalDate
            startDate
        }
    }
`;

const Partner: FC<React.PropsWithChildren<unknown>> = () => {
    const { pathname } = useLocation();
    const {
        params: { partnerId },
        path,
    } = useRouteMatch<UnitParams>();

    // Check if there's a unit selected
    const unitMatch = matchPath<UnitParams>(pathname, `${path}/unit/:unitSlug`);

    // Check wether we are viewing an activity or company. Set company as default.
    const { path: rootPath } = useRouteMatch<PartnerParams>();
    const activityMatch = useRouteMatch<ActivityParams>({ path: `${rootPath}/activity/:activitySlug` });
    const parentActivityType = activityMatch ? 'activity' : 'company';
    const { locale } = useIntl();
    const { data, loading } = usePartnerDataQuery({
        variables: {
            slug: unitMatch?.params.unitSlug,
            partnerId,
        },
    });

    if (loading) {
        return <Loading />;
    }

    if (!data?.partner) {
        throw new NotFoundError();
    }

    const {
        partner: { accommodations, companies },
        rentalUnit,
        viewer,
    } = data;

    // Check if companies exist for the Activities pages
    const defaultSelectedCompany = companies?.[0];

    // Build the navigation items
    const navigationItems = getMainNavigationItems(accommodations, companies);
    const mobileNavigationItems = getMainNavigationRoutesForMobile(accommodations, companies, parentActivityType);

    const defaultSelectedAccommodation = data.partner.accommodations?.[0];

    const { datePricingStartDate, datePricingEndDate } = getRentalUnitMetaData(rentalUnit);

    const canSwitchPartner = !viewer?.isBackofficeUser && (viewer?.partners ?? []).length > 1;
    return (
        <>
            <Navigation
                navigationItems={navigationItems}
                mobileNavigationItems={mobileNavigationItems}
                user={data.viewer}
                datePricingStartDate={datePricingStartDate}
                datePricingEndDate={datePricingEndDate}
                canSwitchPartner={canSwitchPartner}
                accPickerEntries={[
                    ...(defaultSelectedAccommodation ? [defaultSelectedAccommodation] : []),
                    ...accommodations,
                ]}
                activityPickerEntries={[...(defaultSelectedCompany ? [defaultSelectedCompany] : []), ...companies]}
            />
            <ErrorBoundary>
                <LocalizedSwitch>
                    <Redirect
                        exact
                        from={`/${locale}/partner/${partnerId}`}
                        to={`/${locale}/partner/${partnerId}/${accommodations.length > 0 ? 'unit' : 'profile'}`}
                    />
                    {/* Hopefully one day we'll implement a dashboard so we don't have to redirect everything */}
                    <Route path={`${path}/unit`} exact render={props => <Unit data={data} {...props} />} />
                    <Route path={`${path}/unit/:unitSlug`} render={props => <Unit data={data} {...props} />} />
                    <Route path={`${path}/reviews/:reviewId`} component={ReviewDetails} />
                    <Route path={`${path}/reviews`} component={Reviews} />
                    <Route path={`${path}/visitor-register`} component={VisitorRegister} />
                    <Route path={`${path}/company`} exact render={props => <Activity data={data} {...props} />} />
                    <Route
                        path={`${path}/company/:companySlug`}
                        render={props => <Activity data={data} {...props} />}
                    />
                    <Route path={`${path}/activity`} exact render={props => <Activity data={data} {...props} />} />
                    <Route
                        path={`${path}/activity/:activitySlug`}
                        render={props => <Activity data={data} {...props} />}
                    />
                    <Route path={`${path}/profile`} component={Profile} />
                    <Route path={`${path}/bookings/:category/:bookingId`} component={BookingDetails} />
                    <Route path={`${path}/bookings`} component={Bookings} />
                    <Route path={`${path}/balance`} component={BalanceItems} />
                    <Route path={`${path}/tickets/all/:ticketId`} component={TicketDetails} />
                    <Route path={`${path}/tickets`} component={Tickets} />
                    <Route path={`${path}/settlements`} component={Settlements} />
                    <Route path={`${path}/help`} component={Help} />
                    <Route path={`${path}/qr-signup`} component={QRSignup} />
                    <Route component={NotFound} />
                </LocalizedSwitch>
            </ErrorBoundary>
        </>
    );
};

export default Partner;
