import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Box } from '@rebass/grid';

const InfoSideContainer: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
    <SideContainer>
        <SideNav>{children}</SideNav>
    </SideContainer>
);

export default InfoSideContainer;

const SideContainer = styled(Box).attrs({ width: [4 / 13, null, null, null, 3 / 13] })`
    background: ${({ theme }) => theme.colors.neutral[10]};
    max-width: 31.2rem;
    position: fixed;
    height: 100vh;
    overflow-y: auto;

    @media (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        display: none;
    }
`;

const SideNav = styled.ul`
    margin: 0;
    display: flex;
    flex-direction: column;
    padding: 4rem 4rem 16rem;
    top: ${({ theme }) => theme.heights.unitNav + theme.heights.nav}px;
`;
