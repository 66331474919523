import { MapLocationInputField } from '@oberoninternal/travelbase-ds/components/form/MapLocationInput';
import SelectInput from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import { Box, Flex } from '@rebass/grid';
import gql from 'graphql-tag';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import brandConfig from '../../../../constants/brandConfig';
import { ActivityParams } from '../../../../entities/ActivityParams';
import { NotFoundError } from '../../../../entities/NotFoundError';
import { useCompanyLocationQuery, useEditCompanyLocationMutation } from '../../../../generated/graphql';
import omitEqualValues from '../../../../utils/omitEqualValues';
import { locationValidation } from '../../../../utils/validations/locationValidation';
import FieldSet from '../../../atoms/FieldSet';
import FieldSetHint from '../../../atoms/FieldSetHint';
import MapLocationDetermineButton from '../../../atoms/MapLocationDetermineButton';
import FieldHeading from '../../../molecules/FieldHeading';
import LabelBox from '../../../molecules/LabelBox';
import FormScreen from '../../../organisms/FormScreen';
import Loading from '../../../organisms/Loading';

const companyLocationFragment = gql`
    fragment CompanyLocation on Company {
        id
        slug
        address {
            ...Address
        }
        coordinates {
            lat
            lon
        }
    }
`;

export const companyLocationQuery = gql`
    query CompanyLocation($companySlug: String!) {
        company(slug: $companySlug) {
            ...CompanyLocation
        }
    }
    ${companyLocationFragment}
`;

export const editCompanyLocationMutation = gql`
    mutation EditCompanyLocation($input: EditCompanyLocationInput!) {
        editCompanyLocation(input: $input) {
            company {
                ...CompanyLocation
            }
        }
    }
    ${companyLocationFragment}
`;

const { countryOptions } = brandConfig;

const CompanyLocation: FC<React.PropsWithChildren<ActivityParams>> = ({ companySlug }) => {
    const intl = useIntl();
    const { formatMessage } = intl;
    const slug = companySlug ?? '';
    const { data, loading } = useCompanyLocationQuery({ variables: { companySlug: slug } });
    const [mutate] = useEditCompanyLocationMutation();

    if (loading) {
        return <Loading />;
    }

    if (!data || !data.company) {
        throw new NotFoundError();
    }

    const {
        company: { id, address, coordinates },
    } = data;
    const city = address?.city ?? '';
    const postalCode = address?.postalCode ?? '';
    const street = address?.street ?? '';
    const number = address?.number ?? '';
    const countryCode = address?.countryCode
        ? address.countryCode
        : countryOptions.length === 1
        ? countryOptions[0].value
        : '';

    const initialValues = {
        coordinates: {
            latitude: coordinates?.lat ?? 0,
            longitude: coordinates?.lon ?? 0,
        },
        address: { city, postalCode, street, number, countryCode },
    };

    return (
        <FormScreen
            handleSubmit={async values => {
                const { coordinates: newCoordinates, ...rest } = omitEqualValues(values, initialValues);
                await mutate({
                    variables: {
                        input: {
                            companyId: id,
                            coordinates: {
                                lat: newCoordinates?.latitude ?? 0,
                                lon: newCoordinates?.longitude ?? 0,
                            },
                            ...rest,
                        },
                    },
                });
            }}
            validationSchema={locationValidation(intl)}
            initialValues={initialValues}
        >
            {() => (
                <>
                    <Pagehead title={formatMessage({ defaultMessage: 'Adres' })}>
                        <FormattedMessage defaultMessage="Hier beheer je de adresgegevens van de onderneming." />
                    </Pagehead>
                    <FieldHeading title={formatMessage({ defaultMessage: 'Adres' })}>
                        <FormattedMessage defaultMessage="Geef het adres van de onderneming op." />
                    </FieldHeading>
                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="street">
                                <FormattedMessage defaultMessage="Adres (straat en huisnummer)" />
                            </Label>
                        </LabelBox>
                        <Flex width={1} flexDirection={['column', 'row']}>
                            <Box flex={1} pr={[null, 4]}>
                                <TextInputField
                                    id="street"
                                    placeholder={formatMessage({ defaultMessage: 'Adres' })}
                                    name="address.street"
                                />
                            </Box>
                            <Box mt={[4, 0]} width={[1, '20rem']}>
                                <TextInputField
                                    maxLength={20}
                                    placeholder={formatMessage({ defaultMessage: 'Huisnummer' })}
                                    name="address.number"
                                />
                            </Box>
                        </Flex>
                    </FieldSet>

                    <FieldSet>
                        <LabelBox>
                            <Label htmlFor="postalCode">
                                <FormattedMessage defaultMessage="Postcode en plaats" />
                            </Label>
                        </LabelBox>
                        <Flex width={1} flexDirection={['column', 'row']}>
                            <Box width={[1, '20rem']}>
                                <TextInputField
                                    maxLength={20}
                                    id="postalCode"
                                    placeholder={formatMessage({ defaultMessage: 'Postcode' })}
                                    name="address.postalCode"
                                />
                            </Box>
                            <Box flex={1} mt={[4, 0]} pl={[null, 4]}>
                                <TextInputField
                                    id="city"
                                    placeholder={formatMessage({ defaultMessage: 'Plaats' })}
                                    name="address.city"
                                />
                            </Box>
                        </Flex>
                    </FieldSet>

                    {countryOptions.length > 1 && (
                        <FieldSet>
                            <LabelBox>
                                <Label htmlFor="countryCode">
                                    <FormattedMessage defaultMessage="Land" />
                                </Label>
                            </LabelBox>
                            <Box width={[1, 0.5]} style={{ maxWidth: '40rem' }}>
                                <SelectInput
                                    id="countryCode"
                                    name="address.countryCode"
                                    isSearchable
                                    placeholder={formatMessage({ defaultMessage: 'Selecteer land' })}
                                    options={countryOptions}
                                />
                            </Box>
                        </FieldSet>
                    )}

                    <FieldSet>
                        <LabelBox>
                            <Label>
                                <FormattedMessage defaultMessage="Locatie (GPS)" />
                            </Label>
                            <FieldSetHint>
                                <FormattedMessage defaultMessage="Plaats de pin op de plek van de onderneming die grenst aan de openbare weg (i.v.m. navigatie). Met de knop kun je de locatie (GPS) laten bepalen aan de hand van het adres." />
                            </FieldSetHint>
                        </LabelBox>
                        <Flex width={1} flexDirection="column">
                            <MapLocationInputField
                                mapBoxAccessToken={process.env.REACT_APP_MAPBOX_KEY ?? ''}
                                name="coordinates"
                            >
                                <MapLocationDetermineButton />
                            </MapLocationInputField>
                        </Flex>
                    </FieldSet>
                </>
            )}
        </FormScreen>
    );
};
export default CompanyLocation;
