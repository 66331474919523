import { Box } from '@rebass/grid';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';
import FieldSet from '../atoms/FieldSet';
import { Seperator } from '../atoms/Seperator';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import LabelBox from './LabelBox';

interface Props {
    title: ReactNode;
}

const FieldHeading: FC<React.PropsWithChildren<Props>> = ({ title, children }) => (
    <>
        <Seperator />
        <StyledFieldSet>
            <StyledLabelBox>
                <StyledTitle variant="small">{title}</StyledTitle>
            </StyledLabelBox>
            {children && <Box width={1}>{children}</Box>}
        </StyledFieldSet>
    </>
);

const StyledTitle = styled(Title)`
    line-height: 2.4rem;
`;
const StyledLabelBox = styled(LabelBox)`
    justify-content: flex-start;
`;

const StyledFieldSet = styled(FieldSet)`
    margin-bottom: 0;
    > :nth-child(2) {
        margin-bottom: ${({ theme }) => theme.spacing['60_Large']};
    }
`;

export default FieldHeading;
