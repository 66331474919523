/* eslint-disable */
/* tslint:disable */
import { disableFragmentWarnings } from 'graphql-tag';
disableFragmentWarnings();
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '../apollo';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** A string representing a date, formatted as YYYY-MM-DD. */
    Date: any;
    /** A string representing a date and time, in RFC3339 format (for example: 2020-01-23T12:34:56+02:00). */
    DateTime: any;
    FileUpload: any;
    Time: any;
};

export type AcceptBookingInput = {
    accept: Scalars['Boolean'];
    bookingId: Scalars['ID'];
};

export type AcceptBookingPayload = {
    __typename?: 'AcceptBookingPayload';
    booking?: Maybe<Booking>;
};

/** An accommodation represents a building or location that contains one or more rental units. In many cases an accommodation has only one rental unit. In the case of larger accommodations like hotels or apartment buildings, the accommodation has rental units for each room type or apartment. */
export type Accommodation = {
    __typename?: 'Accommodation';
    address?: Maybe<Address>;
    attributes: Array<Attribute>;
    caretaker?: Maybe<ContactInfo>;
    checkInEndTime?: Maybe<Scalars['Time']>;
    checkInStartTime?: Maybe<Scalars['Time']>;
    checkOutTime?: Maybe<Scalars['Time']>;
    coordinates?: Maybe<LatLon>;
    descriptionGeneral?: Maybe<Scalars['String']>;
    descriptionGeneralPending?: Maybe<Scalars['String']>;
    descriptionLocation?: Maybe<Scalars['String']>;
    descriptionLocationPending?: Maybe<Scalars['String']>;
    /** Whether the accommodation is enabled. */
    enabled: Scalars['Boolean'];
    hasPublicPage: Scalars['Boolean'];
    /** Unique identifier of the accommodation. */
    id: Scalars['ID'];
    images: Array<AccommodationImage>;
    keyHolder?: Maybe<ContactInfo>;
    keyHolderAddress?: Maybe<Address>;
    listImage?: Maybe<AccommodationListImage>;
    mainImage?: Maybe<AccommodationMainImage>;
    /** Name of the accommodation. */
    name: Scalars['String'];
    ownerImage?: Maybe<AvatarImage>;
    ownerIntroduction?: Maybe<Scalars['String']>;
    ownerIntroductionPending?: Maybe<Scalars['String']>;
    ownerName?: Maybe<Scalars['String']>;
    ownerProfileVisible: Scalars['Boolean'];
    ownerTips?: Maybe<Scalars['String']>;
    ownerTipsPending?: Maybe<Scalars['String']>;
    partner?: Maybe<Partner>;
    place?: Maybe<Place>;
    /** Rental units that belong to the accommodation. */
    rentalUnits: Array<RentalUnit>;
    /** Name of contact person (required), at least one phone number, and at least one email address */
    reservations: ContactInfo;
    reviewsEmail?: Maybe<Scalars['String']>;
    slug: Scalars['String'];
    smsNotificationNumbers: Array<Scalars['String']>;
    starRating?: Maybe<Scalars['Int']>;
    /** Type of the accommodation. */
    type: AccommodationTypeEnum;
    webhookUrl?: Maybe<Scalars['String']>;
};

/** An accommodation represents a building or location that contains one or more rental units. In many cases an accommodation has only one rental unit. In the case of larger accommodations like hotels or apartment buildings, the accommodation has rental units for each room type or apartment. */
export type AccommodationDescriptionGeneralArgs = {
    locale?: Maybe<Scalars['String']>;
};

/** An accommodation represents a building or location that contains one or more rental units. In many cases an accommodation has only one rental unit. In the case of larger accommodations like hotels or apartment buildings, the accommodation has rental units for each room type or apartment. */
export type AccommodationDescriptionLocationArgs = {
    locale?: Maybe<Scalars['String']>;
};

/** An accommodation represents a building or location that contains one or more rental units. In many cases an accommodation has only one rental unit. In the case of larger accommodations like hotels or apartment buildings, the accommodation has rental units for each room type or apartment. */
export type AccommodationOwnerIntroductionArgs = {
    locale?: Maybe<Scalars['String']>;
};

/** An accommodation represents a building or location that contains one or more rental units. In many cases an accommodation has only one rental unit. In the case of larger accommodations like hotels or apartment buildings, the accommodation has rental units for each room type or apartment. */
export type AccommodationOwnerTipsArgs = {
    locale?: Maybe<Scalars['String']>;
};

export type AccommodationImage = Image & {
    __typename?: 'AccommodationImage';
    category: AccommodationImageCategoryEnum;
    dimensions: Array<Scalars['Int']>;
    id: Scalars['ID'];
    position: Scalars['Int'];
    size: Scalars['Int'];
    url: Scalars['String'];
};

export enum AccommodationImageCategoryEnum {
    Bathroom = 'BATHROOM',
    Bedroom = 'BEDROOM',
    Entrance = 'ENTRANCE',
    Facilities = 'FACILITIES',
    FloorPlan = 'FLOOR_PLAN',
    Front = 'FRONT',
    Kitchen = 'KITCHEN',
    LivingRoom = 'LIVING_ROOM',
    Other = 'OTHER',
    Outdoor = 'OUTDOOR',
    Surroundings = 'SURROUNDINGS',
    View = 'VIEW',
}

export type AccommodationImageInput = {
    category: AccommodationImageCategoryEnum;
    imageId?: Maybe<Scalars['ID']>;
    uploadId?: Maybe<Scalars['ID']>;
};

export type AccommodationListImage = Image & {
    __typename?: 'AccommodationListImage';
    dimensions: Array<Scalars['Int']>;
    id: Scalars['ID'];
    size: Scalars['Int'];
    url: Scalars['String'];
};

export type AccommodationMainImage = Image & {
    __typename?: 'AccommodationMainImage';
    dimensions: Array<Scalars['Int']>;
    id: Scalars['ID'];
    size: Scalars['Int'];
    url: Scalars['String'];
};

export enum AccommodationTypeEnum {
    ApartmentBlock = 'APARTMENT_BLOCK',
    BedAndBreakfast = 'BED_AND_BREAKFAST',
    BungalowPark = 'BUNGALOW_PARK',
    Camping = 'CAMPING',
    Hotel = 'HOTEL',
    Location = 'LOCATION',
}

/** An activity. */
export type Activity = {
    __typename?: 'Activity';
    /** Rate groups which determine the price for a ticket */
    activityRateGroups: Array<ActivityRateGroup>;
    company: Company;
    /** Coordinates of the location of the activity. */
    coordinates?: Maybe<LatLon>;
    /** Date and time the activity was created. */
    createdAt: Scalars['DateTime'];
    description?: Maybe<Scalars['String']>;
    descriptionLocation?: Maybe<Scalars['String']>;
    descriptionLocationPending?: Maybe<Scalars['String']>;
    descriptionParticulars?: Maybe<Scalars['String']>;
    descriptionParticularsPending?: Maybe<Scalars['String']>;
    descriptionPending?: Maybe<Scalars['String']>;
    /** Whether the activity is enabled. */
    enabled: Scalars['Boolean'];
    externalBookingUrl?: Maybe<Scalars['String']>;
    externalInfoUrl?: Maybe<Scalars['String']>;
    hideForPartner: Scalars['Boolean'];
    /** Whether the activity is highlighted. */
    highlighted: Scalars['Boolean'];
    /** Unique identifier of the activity. */
    id: Scalars['ID'];
    images: Array<ActivityImage>;
    listImage?: Maybe<ActivityListImage>;
    /** Address of the location where the activity takes place. */
    locationAddress?: Maybe<Address>;
    mainImage?: Maybe<ActivityMainImage>;
    maxCapacity?: Maybe<Scalars['Int']>;
    minCapacity?: Maybe<Scalars['Int']>;
    name?: Maybe<Scalars['String']>;
    place?: Maybe<Place>;
    public: Scalars['Boolean'];
    showExternalBookingUrl: Scalars['Boolean'];
    /** @deprecated Use `showExternalBookingUrl` instead */
    showUrl: Scalars['Boolean'];
    slug: Scalars['String'];
    targetAudiences: Array<Maybe<TargetAudienceEnum>>;
    themes: Array<ActivityTheme>;
    timeslots: Array<Timeslot>;
    /** Date and time of the last update to this activity. */
    updatedAt: Scalars['DateTime'];
    videoUrl?: Maybe<Scalars['String']>;
};

/** An activity. */
export type ActivityDescriptionArgs = {
    locale?: Maybe<Scalars['String']>;
};

/** An activity. */
export type ActivityDescriptionLocationArgs = {
    locale?: Maybe<Scalars['String']>;
};

/** An activity. */
export type ActivityDescriptionParticularsArgs = {
    locale?: Maybe<Scalars['String']>;
};

/** An activity. */
export type ActivityNameArgs = {
    locale?: Maybe<Scalars['String']>;
};

/** An activity. */
export type ActivityTimeslotsArgs = {
    endDate: Scalars['Date'];
    startDate: Scalars['Date'];
};

export type ActivityImage = Image & {
    __typename?: 'ActivityImage';
    dimensions: Array<Scalars['Int']>;
    id: Scalars['ID'];
    position: Scalars['Int'];
    size: Scalars['Int'];
    url: Scalars['String'];
};

export type ActivityImageInput = {
    imageId?: Maybe<Scalars['ID']>;
    uploadId?: Maybe<Scalars['ID']>;
};

export type ActivityListImage = Image & {
    __typename?: 'ActivityListImage';
    dimensions: Array<Scalars['Int']>;
    id: Scalars['ID'];
    size: Scalars['Int'];
    url: Scalars['String'];
};

export type ActivityMainImage = Image & {
    __typename?: 'ActivityMainImage';
    dimensions: Array<Scalars['Int']>;
    id: Scalars['ID'];
    size: Scalars['Int'];
    url: Scalars['String'];
};

/** A rate group for an activity. */
export type ActivityRateGroup = RateGroup & {
    __typename?: 'ActivityRateGroup';
    canBuyTickets: Scalars['Boolean'];
    /** Unique identifier of the rate group. */
    id: Scalars['ID'];
    /** Name of the group for internal use. */
    name?: Maybe<Scalars['String']>;
    qrScansEnabled: Scalars['Boolean'];
    rates: Array<Rate>;
    /** Tickets can be sold via Travelbase until this amount of hours prior to the Timeslot's start time. */
    releaseHours?: Maybe<Scalars['Int']>;
};

/** An activity theme. */
export type ActivityTheme = {
    __typename?: 'ActivityTheme';
    /** Unique identifier of the activity theme. */
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    namePending?: Maybe<Scalars['String']>;
    slug: Scalars['String'];
};

/** An activity theme. */
export type ActivityThemeNameArgs = {
    locale?: Maybe<Scalars['String']>;
};

/** An address describing a real world location. */
export type Address = {
    __typename?: 'Address';
    /** Name of the city. */
    city?: Maybe<Scalars['String']>;
    /** ISO 3166-1 Alpha-2 country code. */
    countryCode?: Maybe<Scalars['String']>;
    countryName?: Maybe<Scalars['String']>;
    /** House number and extension (if applicable). */
    number?: Maybe<Scalars['String']>;
    /** National postal code. */
    postalCode?: Maybe<Scalars['String']>;
    /** Name of the street. */
    street?: Maybe<Scalars['String']>;
};

export type AddressInput = {
    city?: Maybe<Scalars['String']>;
    countryCode?: Maybe<Scalars['String']>;
    number?: Maybe<Scalars['String']>;
    postalCode?: Maybe<Scalars['String']>;
    street?: Maybe<Scalars['String']>;
};

/** Allotment for a specific date. */
export type Allotment = {
    __typename?: 'Allotment';
    /** Amount of units available on the specified date. */
    amount: Scalars['Int'];
    /** Date to which the allotment applies. */
    date: Scalars['Date'];
    id: Scalars['ID'];
    rentalUnit: RentalUnit;
};

export type AllotmentLockout = {
    __typename?: 'AllotmentLockout';
    comment?: Maybe<Scalars['String']>;
    endDate: Scalars['Date'];
    id: Scalars['ID'];
    isActive: Scalars['Boolean'];
    isDragging: Scalars['Boolean'];
    isHovering: Scalars['Boolean'];
    rentalUnit: RentalUnit;
    startDate: Scalars['Date'];
    type: AllotmentLockoutTypeEnum;
};

export enum AllotmentLockoutTypeEnum {
    ExternalBooking = 'EXTERNAL_BOOKING',
    Maintenance = 'MAINTENANCE',
    ManagementApi = 'MANAGEMENT_API',
    Other = 'OTHER',
    PrivateUse = 'PRIVATE_USE',
}

export type ApproveRentalUnitContractInput = {
    rentalUnitContractId: Scalars['ID'];
};

export type ApproveRentalUnitContractPayload = {
    __typename?: 'ApproveRentalUnitContractPayload';
    rentalUnitContract?: Maybe<RentalUnitContract>;
};

export type Attribute = {
    __typename?: 'Attribute';
    category: AttributeCategory;
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    position: Scalars['Int'];
    subtypes: Array<RentalUnitTypeEnum>;
    type: AttributeTypeEnum;
};

export type AttributeNameArgs = {
    locale?: Maybe<Scalars['String']>;
};

export type AttributeCategory = {
    __typename?: 'AttributeCategory';
    allowMultiple: Scalars['Boolean'];
    attributes: Array<Attribute>;
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    position: Scalars['Int'];
};

export type AttributeCategoryAttributesArgs = {
    subtype?: Maybe<RentalUnitTypeEnum>;
    type?: Maybe<AttributeTypeEnum>;
};

export type AttributeCategoryNameArgs = {
    locale?: Maybe<Scalars['String']>;
};

export type AttributeInput = {
    id: Scalars['ID'];
};

export enum AttributeTypeEnum {
    Accommodation = 'ACCOMMODATION',
    RentalUnit = 'RENTAL_UNIT',
}

export type AvatarImage = Image & {
    __typename?: 'AvatarImage';
    dimensions: Array<Scalars['Int']>;
    id: Scalars['ID'];
    size: Scalars['Int'];
    url: Scalars['String'];
};

/** Details of a booking for a rental unit. */
export type Booking = {
    __typename?: 'Booking';
    accommodationSum: Scalars['Float'];
    /** Additions for this booking. */
    additions: Array<BookingAddition>;
    /** Amount of adults staying in the rental unit. */
    amountAdults: Scalars['Int'];
    /** Amount of babies staying in the rental unit. */
    amountBabies: Scalars['Int'];
    /** Amount of children staying in the rental unit. */
    amountChildren: Scalars['Int'];
    /** Amount of pets staying in the rental unit. */
    amountPets: Scalars['Int'];
    /** Amount of youths staying in the rental unit. */
    amountYouths: Scalars['Int'];
    /** Date of arrival. */
    arrivalDate: Scalars['Date'];
    /** Date and time the booking was created. */
    createdAt: Scalars['DateTime'];
    /** The customer who made this booking. */
    customer?: Maybe<Customer>;
    /** Comment added by the customer. */
    customerComment?: Maybe<Scalars['String']>;
    /** Date of departure. */
    departureDate: Scalars['Date'];
    /** Total value of the security deposit. */
    deposit: Scalars['Float'];
    /** The portion of the security deposit for which payment is handled by Travelbase. */
    depositPaid: Scalars['Float'];
    /** Duration of stay (number of nights). */
    duration: Scalars['Int'];
    /** Last date and time when cancellation is free for the customer */
    flexCancellationUntil?: Maybe<Scalars['DateTime']>;
    /** Unique identifier of the booking. */
    id: Scalars['ID'];
    /** The address associated with this booking, may or may not be the same as customer.address */
    invoiceAddress: Address;
    isActive: Scalars['Boolean'];
    isHovering: Scalars['Boolean'];
    /** Booking number as used in communications. */
    number?: Maybe<Scalars['String']>;
    partnerPriceLines: Array<BookingPriceLine>;
    /** The rental unit for this booking. */
    rentalUnit: RentalUnit;
    /** Special selected by the customer (if applicable). */
    special?: Maybe<Special>;
    /** Status of this booking. */
    status: BookingStatusEnum;
    /** Total price for this booking. The total price is determined by adding up the rental sum, tourist tax and additions (if any). */
    totalPrice: Scalars['Float'];
    /** The portion of the total price for which payment is handled by Travelbase. */
    totalPricePaid: Scalars['Float'];
    /** The tourist tax due for this booking. */
    touristTax: Scalars['Float'];
    /** The portion of the tourist tax for which payment is handled by Travelbase. */
    touristTaxPaid: Scalars['Float'];
    /** Date and time of the last update to this booking. */
    updatedAt: Scalars['DateTime'];
};

/** Additions are non-generic costs added to the booking, such as (optional or mandatory) surcharges. */
export type BookingAddition = {
    __typename?: 'BookingAddition';
    /** Amount selected by the customer. */
    amount: Scalars['Int'];
    /** Calculation used to determine the total price of this addition. */
    calculation: SurchargeCalculationEnum;
    /** The surcharge this addition originates from. This will be empty in the case of a custom addition. */
    surcharge?: Maybe<Surcharge>;
    /** Title of this addition. This should be identical to the surcharge name (at the time of booking) if this addition originates from a surcharge, or contain custom input in the case of a custom addition. */
    title?: Maybe<Scalars['String']>;
    /** Total price charged to the customer for this addition. */
    totalPrice: Scalars['Float'];
    /** Price per unit for this addition. */
    unitPrice: Scalars['Float'];
};

/** Additions are non-generic costs added to the booking, such as (optional or mandatory) surcharges. */
export type BookingAdditionTitleArgs = {
    locale?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type BookingConnection = {
    __typename?: 'BookingConnection';
    /** Information to aid in pagination. */
    edges?: Maybe<Array<Maybe<BookingEdge>>>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** Total bookings for this query. */
    totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BookingEdge = {
    __typename?: 'BookingEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String'];
    /** The item at the end of the edge. */
    node?: Maybe<Booking>;
};

export type BookingPriceLine = {
    __typename?: 'BookingPriceLine';
    category: PriceLineCategoryEnum;
    label: Scalars['String'];
    modifier?: Maybe<Scalars['String']>;
    totalPrice: Scalars['Float'];
    unitPrice?: Maybe<Scalars['Float']>;
};

export enum BookingStatusEnum {
    Accepted = 'ACCEPTED',
    CancelledExternal = 'CANCELLED_EXTERNAL',
    CancelledGuest = 'CANCELLED_GUEST',
    CancelledPartner = 'CANCELLED_PARTNER',
    Declined = 'DECLINED',
    Init = 'INIT',
    Pending = 'PENDING',
    Waived = 'WAIVED',
}

export enum BookingTypeEnum {
    Direct = 'DIRECT',
    NotBookable = 'NOT_BOOKABLE',
    OnRequest = 'ON_REQUEST',
}

export type BulkEditAllotmentsInput = {
    amount: Scalars['Int'];
    endDate: Scalars['Date'];
    rentalUnitId: Scalars['ID'];
    startDate: Scalars['Date'];
};

export type BulkEditAllotmentsPayload = {
    __typename?: 'BulkEditAllotmentsPayload';
    allotments?: Maybe<Array<Allotment>>;
};

export type BulkEditDatePricingArrivalAllowedInput = {
    endDate: Scalars['Date'];
    friday?: Maybe<Scalars['Boolean']>;
    monday?: Maybe<Scalars['Boolean']>;
    rentalUnitId: Scalars['ID'];
    saturday?: Maybe<Scalars['Boolean']>;
    startDate: Scalars['Date'];
    sunday?: Maybe<Scalars['Boolean']>;
    thursday?: Maybe<Scalars['Boolean']>;
    tuesday?: Maybe<Scalars['Boolean']>;
    wednesday?: Maybe<Scalars['Boolean']>;
};

export type BulkEditDatePricingArrivalAllowedPayload = {
    __typename?: 'BulkEditDatePricingArrivalAllowedPayload';
    datePricings?: Maybe<Array<DatePricing>>;
};

export type BulkEditDatePricingBaseStayPriceInput = {
    endDate: Scalars['Date'];
    friday?: Maybe<Scalars['Float']>;
    monday?: Maybe<Scalars['Float']>;
    rentalUnitId: Scalars['ID'];
    saturday?: Maybe<Scalars['Float']>;
    startDate: Scalars['Date'];
    sunday?: Maybe<Scalars['Float']>;
    thursday?: Maybe<Scalars['Float']>;
    tuesday?: Maybe<Scalars['Float']>;
    wednesday?: Maybe<Scalars['Float']>;
};

export type BulkEditDatePricingBaseStayPricePayload = {
    __typename?: 'BulkEditDatePricingBaseStayPricePayload';
    datePricings?: Maybe<Array<DatePricing>>;
};

export type BulkEditDatePricingDepartureAllowedInput = {
    endDate: Scalars['Date'];
    friday?: Maybe<Scalars['Boolean']>;
    monday?: Maybe<Scalars['Boolean']>;
    rentalUnitId: Scalars['ID'];
    saturday?: Maybe<Scalars['Boolean']>;
    startDate: Scalars['Date'];
    sunday?: Maybe<Scalars['Boolean']>;
    thursday?: Maybe<Scalars['Boolean']>;
    tuesday?: Maybe<Scalars['Boolean']>;
    wednesday?: Maybe<Scalars['Boolean']>;
};

export type BulkEditDatePricingDepartureAllowedPayload = {
    __typename?: 'BulkEditDatePricingDepartureAllowedPayload';
    datePricings?: Maybe<Array<DatePricing>>;
};

export type BulkEditDatePricingExtraPersonPriceInput = {
    endDate: Scalars['Date'];
    friday?: Maybe<Scalars['Float']>;
    monday?: Maybe<Scalars['Float']>;
    rentalUnitId: Scalars['ID'];
    saturday?: Maybe<Scalars['Float']>;
    startDate: Scalars['Date'];
    sunday?: Maybe<Scalars['Float']>;
    thursday?: Maybe<Scalars['Float']>;
    tuesday?: Maybe<Scalars['Float']>;
    wednesday?: Maybe<Scalars['Float']>;
};

export type BulkEditDatePricingExtraPersonPricePayload = {
    __typename?: 'BulkEditDatePricingExtraPersonPricePayload';
    datePricings?: Maybe<Array<DatePricing>>;
};

export type BulkEditDatePricingMinimumStayDurationInput = {
    endDate: Scalars['Date'];
    friday?: Maybe<Scalars['Int']>;
    monday?: Maybe<Scalars['Int']>;
    rentalUnitId: Scalars['ID'];
    saturday?: Maybe<Scalars['Int']>;
    startDate: Scalars['Date'];
    sunday?: Maybe<Scalars['Int']>;
    thursday?: Maybe<Scalars['Int']>;
    tuesday?: Maybe<Scalars['Int']>;
    wednesday?: Maybe<Scalars['Int']>;
};

export type BulkEditDatePricingMinimumStayDurationPayload = {
    __typename?: 'BulkEditDatePricingMinimumStayDurationPayload';
    datePricings?: Maybe<Array<DatePricing>>;
};

export type BulkEditDatePricingMinimumStayPriceInput = {
    endDate: Scalars['Date'];
    friday?: Maybe<Scalars['Float']>;
    monday?: Maybe<Scalars['Float']>;
    rentalUnitId: Scalars['ID'];
    saturday?: Maybe<Scalars['Float']>;
    startDate: Scalars['Date'];
    sunday?: Maybe<Scalars['Float']>;
    thursday?: Maybe<Scalars['Float']>;
    tuesday?: Maybe<Scalars['Float']>;
    wednesday?: Maybe<Scalars['Float']>;
};

export type BulkEditDatePricingMinimumStayPricePayload = {
    __typename?: 'BulkEditDatePricingMinimumStayPricePayload';
    datePricings?: Maybe<Array<DatePricing>>;
};

export type BulkEditDatePricingNightPriceInput = {
    endDate: Scalars['Date'];
    friday?: Maybe<Scalars['Float']>;
    monday?: Maybe<Scalars['Float']>;
    rentalUnitId: Scalars['ID'];
    saturday?: Maybe<Scalars['Float']>;
    startDate: Scalars['Date'];
    sunday?: Maybe<Scalars['Float']>;
    thursday?: Maybe<Scalars['Float']>;
    tuesday?: Maybe<Scalars['Float']>;
    wednesday?: Maybe<Scalars['Float']>;
};

export type BulkEditDatePricingNightPricePayload = {
    __typename?: 'BulkEditDatePricingNightPricePayload';
    datePricings?: Maybe<Array<DatePricing>>;
};

export type BulkEditDatePricingWeekPriceInput = {
    endDate: Scalars['Date'];
    friday?: Maybe<Scalars['Float']>;
    monday?: Maybe<Scalars['Float']>;
    rentalUnitId: Scalars['ID'];
    saturday?: Maybe<Scalars['Float']>;
    startDate: Scalars['Date'];
    sunday?: Maybe<Scalars['Float']>;
    thursday?: Maybe<Scalars['Float']>;
    tuesday?: Maybe<Scalars['Float']>;
    wednesday?: Maybe<Scalars['Float']>;
};

export type BulkEditDatePricingWeekPricePayload = {
    __typename?: 'BulkEditDatePricingWeekPricePayload';
    datePricings?: Maybe<Array<DatePricing>>;
};

export type BulkUpsertDatePricingInput = {
    datePricingPeriods: Array<DatePricingPeriod>;
    rentalUnitId: Scalars['ID'];
};

export type BulkUpsertDatePricingPayload = {
    __typename?: 'BulkUpsertDatePricingPayload';
    allotments?: Maybe<Array<Allotment>>;
    datePricings?: Maybe<Array<DatePricing>>;
    rentalUnit?: Maybe<RentalUnit>;
};

/** A company. */
export type Company = {
    __typename?: 'Company';
    activities: Array<Activity>;
    address?: Maybe<Address>;
    capacity?: Maybe<Scalars['Int']>;
    categories: Array<CompanyCategory>;
    /** Rate groups which determine the price for a ticket */
    companyRateGroups: Array<CompanyRateGroup>;
    /** Coordinates of the location of the company. */
    coordinates?: Maybe<LatLon>;
    /** Date and time the company was created. */
    createdAt: Scalars['DateTime'];
    description?: Maybe<Scalars['String']>;
    descriptionParticulars?: Maybe<Scalars['String']>;
    descriptionParticularsPending?: Maybe<Scalars['String']>;
    descriptionPending?: Maybe<Scalars['String']>;
    /** Primary contact email of the company. */
    email?: Maybe<Scalars['String']>;
    /** Whether the company is enabled. */
    enabled: Scalars['Boolean'];
    fullCircleImageUrl?: Maybe<Scalars['String']>;
    /** Unique identifier of the company. */
    id: Scalars['ID'];
    images: Array<CompanyImage>;
    listImage?: Maybe<CompanyListImage>;
    logoImage?: Maybe<CompanyLogoImage>;
    mainImage?: Maybe<CompanyMainImage>;
    /** The name of the company. */
    name: Scalars['String'];
    /** General operating hours of the company. */
    normalOperatingHours?: Maybe<OperatingHours>;
    /** Primary contact phone number of the company. */
    phoneNumber?: Maybe<Scalars['String']>;
    place: Place;
    /** Contact information for reservations. */
    reservations?: Maybe<ContactInfo>;
    showInCalendar: Scalars['Boolean'];
    showInCompanyGuide: Scalars['Boolean'];
    slug: Scalars['String'];
    socialsFacebookUrl?: Maybe<Scalars['String']>;
    socialsInstagramUrl?: Maybe<Scalars['String']>;
    socialsTwitterUrl?: Maybe<Scalars['String']>;
    /**
     * Temporary operating hours which override the normal operating hours of the company.
     *
     *
     *                     An array with hours define the hours for that day, an empty array means that the company is closed on that day, a null value means that we should fall back to the default operating hours
     */
    specialOperatingHours: Array<OperatingHours>;
    targetAudiences?: Maybe<Array<TargetAudienceEnum>>;
    themes: Array<ActivityTheme>;
    /** Date and time of the last update to this company. */
    updatedAt: Scalars['DateTime'];
    videoUrl?: Maybe<Scalars['String']>;
    websiteUrl?: Maybe<Scalars['String']>;
};

/** A company. */
export type CompanyDescriptionArgs = {
    locale?: Maybe<Scalars['String']>;
};

/** A company. */
export type CompanyDescriptionParticularsArgs = {
    locale?: Maybe<Scalars['String']>;
};

/** An company category. */
export type CompanyCategory = {
    __typename?: 'CompanyCategory';
    children: Array<CompanyCategory>;
    /** Unique identifier of the company category. */
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    position: Scalars['Int'];
    slug: Scalars['String'];
};

/** An company category. */
export type CompanyCategoryNameArgs = {
    locale?: Maybe<Scalars['String']>;
};

export type CompanyImage = Image & {
    __typename?: 'CompanyImage';
    dimensions: Array<Scalars['Int']>;
    id: Scalars['ID'];
    position: Scalars['Int'];
    size: Scalars['Int'];
    url: Scalars['String'];
};

export type CompanyImageInput = {
    imageId?: Maybe<Scalars['ID']>;
    uploadId?: Maybe<Scalars['ID']>;
};

export type CompanyListImage = Image & {
    __typename?: 'CompanyListImage';
    dimensions: Array<Scalars['Int']>;
    id: Scalars['ID'];
    size: Scalars['Int'];
    url: Scalars['String'];
};

export type CompanyLogoImage = Image & {
    __typename?: 'CompanyLogoImage';
    dimensions: Array<Scalars['Int']>;
    id: Scalars['ID'];
    size: Scalars['Int'];
    url: Scalars['String'];
};

export type CompanyMainImage = Image & {
    __typename?: 'CompanyMainImage';
    dimensions: Array<Scalars['Int']>;
    id: Scalars['ID'];
    size: Scalars['Int'];
    url: Scalars['String'];
};

/** A rate group for a company. */
export type CompanyRateGroup = RateGroup & {
    __typename?: 'CompanyRateGroup';
    canBuyTickets: Scalars['Boolean'];
    endDate?: Maybe<Scalars['Date']>;
    /** Unique identifier of the rate group. */
    id: Scalars['ID'];
    /** Name of the group for internal use. */
    name?: Maybe<Scalars['String']>;
    rates: Array<Rate>;
    startDate?: Maybe<Scalars['Date']>;
};

/** Name, phone numbers, and email addresses */
export type ContactInfo = {
    __typename?: 'ContactInfo';
    /** Name of contact person */
    contactName?: Maybe<Scalars['String']>;
    /** Email addresses */
    emails: Array<Scalars['String']>;
    /** Phone numbers */
    phoneNumbers: Array<Scalars['String']>;
};

export type ContactInfoInput = {
    contactName?: Maybe<Scalars['String']>;
    emails?: Maybe<Array<Scalars['String']>>;
    phoneNumbers?: Maybe<Array<Scalars['String']>>;
};

export type Contract = {
    __typename?: 'Contract';
    bookingType: BookingTypeEnum;
    commissionPercentage: Scalars['Float'];
    contractUrl?: Maybe<Scalars['String']>;
    mayHandleSecurityDepositPayment: Scalars['Boolean'];
    name: Scalars['String'];
    surchargePaymentType: PaymentTypeEnum;
    toBePaidThroughTor: Scalars['Boolean'];
};

export type CreateActivityTimeslotInput = {
    activityId: Scalars['ID'];
    timeslot: TimeslotInput;
};

export type CreateActivityTimeslotPayload = {
    __typename?: 'CreateActivityTimeslotPayload';
    /** @deprecated Replaced with Timeslot for consistency */
    activity?: Maybe<Activity>;
    timeslot?: Maybe<Timeslot>;
};

export type CreateAllotmentLockoutInput = {
    comment?: Maybe<Scalars['String']>;
    endDate: Scalars['Date'];
    rentalUnitId: Scalars['ID'];
    startDate: Scalars['Date'];
    type: AllotmentLockoutTypeEnum;
};

export type CreateAllotmentLockoutPayload = {
    __typename?: 'CreateAllotmentLockoutPayload';
    allotmentLockout?: Maybe<AllotmentLockout>;
};

export type CreateDatePricingModifierInput = {
    endDate: Scalars['Date'];
    maxDuration: Scalars['Int'];
    minDuration: Scalars['Int'];
    rentalUnitId: Scalars['ID'];
    startDate: Scalars['Date'];
    type: DatePricingModifierTypeEnum;
    value: Scalars['Float'];
    valueType: DatePricingModifierValueTypeEnum;
};

export type CreateDatePricingModifierPayload = {
    __typename?: 'CreateDatePricingModifierPayload';
    datePricingModifier?: Maybe<DatePricingModifier>;
};

export type CreateImageUploadPayload = {
    __typename?: 'CreateImageUploadPayload';
    putUrl: Scalars['String'];
    uploadId: Scalars['ID'];
};

export type CreateRentalUnitSurchargeInput = {
    calculation: SurchargeCalculationEnum;
    endDate?: Maybe<Scalars['Date']>;
    handlePayment: Scalars['Boolean'];
    maxAmount: Scalars['Int'];
    minAmount: Scalars['Int'];
    rentalUnitId: Scalars['ID'];
    startDate: Scalars['Date'];
    surchargeId: Scalars['ID'];
    unitPrice: Scalars['Float'];
};

export type CreateRentalUnitSurchargePayload = {
    __typename?: 'CreateRentalUnitSurchargePayload';
    rentalUnitSurcharge?: Maybe<RentalUnitSurcharge>;
};

/** Customer details */
export type Customer = {
    __typename?: 'Customer';
    /** Current address of the customer. */
    address: Address;
    /** Date of birth of the customer. */
    birthdate?: Maybe<Scalars['Date']>;
    /** Email address of the customer. */
    email?: Maybe<Scalars['String']>;
    /** First name of the customer. */
    firstName?: Maybe<Scalars['String']>;
    /** Unique identifier of the customer. */
    id: Scalars['ID'];
    /** Last name of the customer. */
    lastName?: Maybe<Scalars['String']>;
    /** Locale preference. Represented as a 2-letter language code. */
    locale: Scalars['String'];
    /** Phone number of the customer. */
    phoneNumber?: Maybe<Scalars['String']>;
};

export type DatePricing = {
    __typename?: 'DatePricing';
    arrivalAllowed: Scalars['Boolean'];
    baseStayPrice: Scalars['Float'];
    date: Scalars['Date'];
    departureAllowed: Scalars['Boolean'];
    extraPersonPrice: Scalars['Float'];
    id: Scalars['ID'];
    minimumStayDuration: Scalars['Int'];
    minimumStayPrice: Scalars['Float'];
    nightPrice: Scalars['Float'];
    rentalUnit: RentalUnit;
    weekPrice?: Maybe<Scalars['Float']>;
};

export type DatePricingFlex = {
    baseStayPrice?: Maybe<Scalars['Float']>;
    extraPersonPrice?: Maybe<Scalars['Float']>;
    friday: FlexPricingDay;
    minimumStayPrice?: Maybe<Scalars['Float']>;
    monday: FlexPricingDay;
    saturday: FlexPricingDay;
    sunday: FlexPricingDay;
    thursday: FlexPricingDay;
    tuesday: FlexPricingDay;
    wednesday: FlexPricingDay;
    weekPrice?: Maybe<Scalars['Float']>;
};

export type DatePricingHybrid = {
    baseStayPrice?: Maybe<Scalars['Float']>;
    extraPersonPrice?: Maybe<Scalars['Float']>;
    midweekPrice: Scalars['Float'];
    minimumStayDuration?: Maybe<Scalars['Int']>;
    minimumStayPrice?: Maybe<Scalars['Float']>;
    /** Will default to TRUE if not submitted as this is the default start day for week stays. */
    offerWeekFriday?: Maybe<Scalars['Boolean']>;
    offerWeekMonday?: Maybe<Scalars['Boolean']>;
    offerWeekSaturday?: Maybe<Scalars['Boolean']>;
    weekPrice?: Maybe<Scalars['Float']>;
    weekendPrice: Scalars['Float'];
};

export type DatePricingModifier = {
    __typename?: 'DatePricingModifier';
    endDate: Scalars['Date'];
    id: Scalars['ID'];
    maxDuration: Scalars['Int'];
    minDuration: Scalars['Int'];
    rentalUnit: RentalUnit;
    startDate: Scalars['Date'];
    type: DatePricingModifierTypeEnum;
    value: Scalars['Float'];
    valueType: DatePricingModifierValueTypeEnum;
};

export enum DatePricingModifierTypeEnum {
    Addition = 'ADDITION',
    Deduction = 'DEDUCTION',
}

export enum DatePricingModifierValueTypeEnum {
    Amount = 'AMOUNT',
    Percentage = 'PERCENTAGE',
}

export type DatePricingPeriod = {
    endDate: Scalars['Date'];
    flex?: Maybe<DatePricingFlex>;
    hybrid?: Maybe<DatePricingHybrid>;
    sourceRentalUnit?: Maybe<Scalars['ID']>;
    startDate: Scalars['Date'];
    week?: Maybe<DatePricingWeek>;
};

export type DatePricingWeek = {
    extraPersonPrice?: Maybe<Scalars['Float']>;
    friday: WeekPricingDay;
    monday: WeekPricingDay;
    saturday: WeekPricingDay;
    sunday: WeekPricingDay;
    thursday: WeekPricingDay;
    tuesday: WeekPricingDay;
    wednesday: WeekPricingDay;
    weekPrice: Scalars['Float'];
};

export type DeleteActivityRateGroupInput = {
    activityId: Scalars['ID'];
    rateGroupId: Scalars['ID'];
};

export type DeleteActivityRateGroupPayload = {
    __typename?: 'DeleteActivityRateGroupPayload';
    activity?: Maybe<Activity>;
};

export type DeleteActivityTimeslotInput = {
    timeslotId: Scalars['ID'];
};

export type DeleteActivityTimeslotPayload = {
    __typename?: 'DeleteActivityTimeslotPayload';
    /** @deprecated Replaced with ID for consistency */
    activity?: Maybe<Activity>;
    id?: Maybe<Scalars['ID']>;
};

export type DeleteAllotmentLockoutInput = {
    allotmentLockoutId: Scalars['ID'];
};

export type DeleteAllotmentLockoutPayload = {
    __typename?: 'DeleteAllotmentLockoutPayload';
    id?: Maybe<Scalars['ID']>;
};

export type DeleteCompanyRateGroupInput = {
    companyId: Scalars['ID'];
    rateGroupId: Scalars['ID'];
};

export type DeleteCompanyRateGroupPayload = {
    __typename?: 'DeleteCompanyRateGroupPayload';
    company?: Maybe<Company>;
};

export type DeleteDatePricingModifierInput = {
    datePricingModifierId: Scalars['ID'];
};

export type DeleteDatePricingModifierPayload = {
    __typename?: 'DeleteDatePricingModifierPayload';
    id?: Maybe<Scalars['Int']>;
};

export type DeleteRentalUnitSurchargeInput = {
    rentalUnitSurchargeId: Scalars['ID'];
};

export type DeleteRentalUnitSurchargePayload = {
    __typename?: 'DeleteRentalUnitSurchargePayload';
    id?: Maybe<Scalars['ID']>;
};

export type EditAccommodationDescriptionInput = {
    accommodationId: Scalars['ID'];
    descriptionGeneral?: Maybe<Scalars['String']>;
};

export type EditAccommodationDescriptionPayload = {
    __typename?: 'EditAccommodationDescriptionPayload';
    accommodation?: Maybe<Accommodation>;
};

export type EditAccommodationImagesInput = {
    accommodationId: Scalars['ID'];
    images: Array<AccommodationImageInput>;
    listImageUploadId?: Maybe<Scalars['ID']>;
    mainImageUploadId?: Maybe<Scalars['ID']>;
};

export type EditAccommodationImagesPayload = {
    __typename?: 'EditAccommodationImagesPayload';
    accommodation?: Maybe<Accommodation>;
};

export type EditAccommodationInfoInput = {
    caretaker: ContactInfoInput;
    checkInEndTime: Scalars['Time'];
    checkInStartTime: Scalars['Time'];
    checkOutTime: Scalars['Time'];
    rentalUnitId: Scalars['ID'];
    reservations: ContactInfoInput;
    smsNotificationNumbers?: Maybe<Array<Scalars['String']>>;
};

export type EditAccommodationInfoPayload = {
    __typename?: 'EditAccommodationInfoPayload';
    rentalUnit?: Maybe<RentalUnit>;
};

export type EditActivityDescriptionInput = {
    activityId: Scalars['ID'];
    description?: Maybe<Scalars['String']>;
    descriptionParticulars?: Maybe<Scalars['String']>;
};

export type EditActivityDescriptionPayload = {
    __typename?: 'EditActivityDescriptionPayload';
    activity?: Maybe<Activity>;
};

export type EditActivityImagesInput = {
    activityId: Scalars['ID'];
    images: Array<ActivityImageInput>;
    listImageUploadId?: Maybe<Scalars['ID']>;
    mainImageUploadId?: Maybe<Scalars['ID']>;
    videoUrl?: Maybe<Scalars['String']>;
};

export type EditActivityImagesPayload = {
    __typename?: 'EditActivityImagesPayload';
    activity?: Maybe<Activity>;
};

export type EditActivityInfoInput = {
    activityId: Scalars['ID'];
    externalBookingUrl?: Maybe<Scalars['String']>;
    hideForPartner?: Maybe<Scalars['Boolean']>;
    maxCapacity?: Maybe<Scalars['Int']>;
    minCapacity?: Maybe<Scalars['Int']>;
    name?: Maybe<Scalars['String']>;
    targetAudiences?: Maybe<Array<TargetAudienceEnum>>;
};

export type EditActivityInfoPayload = {
    __typename?: 'EditActivityInfoPayload';
    activity?: Maybe<Activity>;
};

export type EditActivityLocationInput = {
    activityId: Scalars['ID'];
    coordinates?: Maybe<LatLonInput>;
    descriptionLocation?: Maybe<Scalars['String']>;
    locationAddress?: Maybe<AddressInput>;
};

export type EditActivityLocationPayload = {
    __typename?: 'EditActivityLocationPayload';
    activity?: Maybe<Activity>;
};

export type EditActivityTimeslotInput = {
    timeslot: TimeslotInput;
    timeslotId: Scalars['ID'];
};

export type EditActivityTimeslotPayload = {
    __typename?: 'EditActivityTimeslotPayload';
    /** @deprecated Replaced with Timeslot for consistency */
    activity?: Maybe<Activity>;
    timeslot?: Maybe<Timeslot>;
};

export type EditAllotmentInput = {
    amount: Scalars['Int'];
    date: Scalars['Date'];
    rentalUnitId: Scalars['ID'];
};

export type EditAllotmentLockoutInput = {
    allotmentLockoutId: Scalars['ID'];
    comment?: Maybe<Scalars['String']>;
    endDate: Scalars['Date'];
    startDate: Scalars['Date'];
    type: AllotmentLockoutTypeEnum;
};

export type EditAllotmentLockoutPayload = {
    __typename?: 'EditAllotmentLockoutPayload';
    allotmentLockout?: Maybe<AllotmentLockout>;
};

export type EditAllotmentPayload = {
    __typename?: 'EditAllotmentPayload';
    allotment?: Maybe<Allotment>;
};

export type EditAttributesInput = {
    attributes: Array<AttributeInput>;
    rentalUnitId: Scalars['ID'];
};

export type EditAttributesPayload = {
    __typename?: 'EditAttributesPayload';
    rentalUnit?: Maybe<RentalUnit>;
};

export type EditCapacityAndSpaceInput = {
    amountBathrooms?: Maybe<Scalars['Int']>;
    amountBedrooms?: Maybe<Scalars['Int']>;
    amountBeds?: Maybe<Scalars['Int']>;
    amountToilets?: Maybe<Scalars['Int']>;
    includedOccupancy: Scalars['Int'];
    livingArea?: Maybe<Scalars['Int']>;
    maxExtraBabyOccupancy: Scalars['Int'];
    maxOccupancy: Scalars['Int'];
    minOccupancy: Scalars['Int'];
    outdoorArea?: Maybe<Scalars['Int']>;
    petsAllowed: Scalars['Int'];
    rentalUnitId: Scalars['ID'];
};

export type EditCapacityAndSpacePayload = {
    __typename?: 'EditCapacityAndSpacePayload';
    rentalUnit?: Maybe<RentalUnit>;
};

export type EditCompanyDescriptionInput = {
    companyId: Scalars['ID'];
    description?: Maybe<Scalars['String']>;
    descriptionParticulars?: Maybe<Scalars['String']>;
};

export type EditCompanyDescriptionPayload = {
    __typename?: 'EditCompanyDescriptionPayload';
    company?: Maybe<Company>;
};

export type EditCompanyImagesInput = {
    companyId: Scalars['ID'];
    fullCircleImageUrl?: Maybe<Scalars['String']>;
    images: Array<CompanyImageInput>;
    listImageUploadId?: Maybe<Scalars['ID']>;
    logoImageUploadId?: Maybe<Scalars['ID']>;
    mainImageUploadId?: Maybe<Scalars['ID']>;
    videoUrl?: Maybe<Scalars['String']>;
};

export type EditCompanyImagesPayload = {
    __typename?: 'EditCompanyImagesPayload';
    company?: Maybe<Company>;
};

export type EditCompanyInfoInput = {
    capacity?: Maybe<Scalars['Int']>;
    companyId: Scalars['ID'];
    email?: Maybe<Scalars['String']>;
    phoneNumber?: Maybe<Scalars['String']>;
    reservations: ContactInfoInput;
    socialsFacebookUrl?: Maybe<Scalars['String']>;
    socialsInstagramUrl?: Maybe<Scalars['String']>;
    socialsTwitterUrl?: Maybe<Scalars['String']>;
    targetAudiences?: Maybe<Array<TargetAudienceEnum>>;
    websiteUrl?: Maybe<Scalars['String']>;
};

export type EditCompanyInfoPayload = {
    __typename?: 'EditCompanyInfoPayload';
    company?: Maybe<Company>;
};

export type EditCompanyLocationInput = {
    address?: Maybe<AddressInput>;
    companyId: Scalars['ID'];
    coordinates?: Maybe<LatLonInput>;
};

export type EditCompanyLocationPayload = {
    __typename?: 'EditCompanyLocationPayload';
    company?: Maybe<Company>;
};

export type EditCompanyNormalOperatingHoursInput = {
    companyId: Scalars['ID'];
    normalOperatingHours: NormalOperatingHoursInput;
};

export type EditCompanyNormalOperatingHoursPayload = {
    __typename?: 'EditCompanyNormalOperatingHoursPayload';
    company?: Maybe<Company>;
};

export type EditCompanySpecialOperatingHoursInput = {
    companyId: Scalars['ID'];
    specialOperatingHours: Array<SpecialOperatingHoursInput>;
};

export type EditCompanySpecialOperatingHoursPayload = {
    __typename?: 'EditCompanySpecialOperatingHoursPayload';
    company?: Maybe<Company>;
};

export type EditDatePricingArrivalAllowedInput = {
    arrivalAllowed: Scalars['Boolean'];
    datePricingId: Scalars['ID'];
};

export type EditDatePricingArrivalAllowedPayload = {
    __typename?: 'EditDatePricingArrivalAllowedPayload';
    datePricing?: Maybe<DatePricing>;
};

export type EditDatePricingBaseStayPriceInput = {
    baseStayPrice: Scalars['Float'];
    datePricingId: Scalars['ID'];
};

export type EditDatePricingBaseStayPricePayload = {
    __typename?: 'EditDatePricingBaseStayPricePayload';
    datePricing?: Maybe<DatePricing>;
};

export type EditDatePricingDepartureAllowedInput = {
    datePricingId: Scalars['ID'];
    departureAllowed: Scalars['Boolean'];
};

export type EditDatePricingDepartureAllowedPayload = {
    __typename?: 'EditDatePricingDepartureAllowedPayload';
    datePricing?: Maybe<DatePricing>;
};

export type EditDatePricingDisplayOptionsInput = {
    rentalUnitId: Scalars['ID'];
    showBaseStayPriceRow: Scalars['Boolean'];
    showExtraPersonPriceRow: Scalars['Boolean'];
    showMinimumStayPriceRow: Scalars['Boolean'];
    showWeekPriceRow: Scalars['Boolean'];
};

export type EditDatePricingDisplayOptionsPayload = {
    __typename?: 'EditDatePricingDisplayOptionsPayload';
    rentalUnit?: Maybe<RentalUnit>;
};

export type EditDatePricingExtraPersonPriceInput = {
    datePricingId: Scalars['ID'];
    extraPersonPrice: Scalars['Float'];
};

export type EditDatePricingExtraPersonPricePayload = {
    __typename?: 'EditDatePricingExtraPersonPricePayload';
    datePricing?: Maybe<DatePricing>;
};

export type EditDatePricingMinimumStayDurationInput = {
    datePricingId: Scalars['ID'];
    minimumStayDuration: Scalars['Int'];
};

export type EditDatePricingMinimumStayDurationPayload = {
    __typename?: 'EditDatePricingMinimumStayDurationPayload';
    datePricing?: Maybe<DatePricing>;
};

export type EditDatePricingMinimumStayPriceInput = {
    datePricingId: Scalars['ID'];
    minimumStayPrice: Scalars['Float'];
};

export type EditDatePricingMinimumStayPricePayload = {
    __typename?: 'EditDatePricingMinimumStayPricePayload';
    datePricing?: Maybe<DatePricing>;
};

export type EditDatePricingModifierInput = {
    datePricingModifierId: Scalars['ID'];
    endDate: Scalars['Date'];
    maxDuration: Scalars['Int'];
    minDuration: Scalars['Int'];
    startDate: Scalars['Date'];
    type: DatePricingModifierTypeEnum;
    value: Scalars['Float'];
    valueType: DatePricingModifierValueTypeEnum;
};

export type EditDatePricingModifierPayload = {
    __typename?: 'EditDatePricingModifierPayload';
    datePricingModifier?: Maybe<DatePricingModifier>;
};

export type EditDatePricingNightPriceInput = {
    datePricingId: Scalars['ID'];
    nightPrice: Scalars['Float'];
};

export type EditDatePricingNightPricePayload = {
    __typename?: 'EditDatePricingNightPricePayload';
    datePricing?: Maybe<DatePricing>;
};

export type EditDatePricingWeekPriceInput = {
    datePricingId: Scalars['ID'];
    weekPrice?: Maybe<Scalars['Float']>;
};

export type EditDatePricingWeekPricePayload = {
    __typename?: 'EditDatePricingWeekPricePayload';
    datePricing?: Maybe<DatePricing>;
};

export type EditDiscountForUnitInput = {
    earlyBookDiscount6Months?: Maybe<Scalars['Float']>;
    earlyBookDiscount12Months?: Maybe<Scalars['Float']>;
    earlyBookDiscountEnabled: Scalars['Boolean'];
    flexCancellationDays?: Maybe<Scalars['Int']>;
    flexibleArrival: Scalars['Boolean'];
    flexibleDeparture?: Maybe<Scalars['Boolean']>;
    lastminuteDiscount1Week?: Maybe<Scalars['Float']>;
    lastminuteDiscount2Week?: Maybe<Scalars['Float']>;
    lastminuteDiscount3Week?: Maybe<Scalars['Float']>;
    lastminuteDiscount4Week?: Maybe<Scalars['Float']>;
    lastminuteDiscountEnabled: Scalars['Boolean'];
    rentalUnitId: Scalars['ID'];
};

export type EditDiscountForUnitPayload = {
    __typename?: 'EditDiscountForUnitPayload';
    rentalUnit?: Maybe<RentalUnit>;
};

export type EditKeyHolderInput = {
    keyHolder: ContactInfoInput;
    keyHolderAddress: AddressInput;
    keyHolderExplanation?: Maybe<Scalars['String']>;
    rentalUnitId: Scalars['ID'];
};

export type EditKeyHolderPayload = {
    __typename?: 'EditKeyHolderPayload';
    rentalUnit?: Maybe<RentalUnit>;
};

export type EditLocationAndDistanceInput = {
    address?: Maybe<AddressInput>;
    coordinates?: Maybe<LatLonInput>;
    descriptionLocation?: Maybe<Scalars['String']>;
    rentalUnitId: Scalars['ID'];
};

export type EditLocationAndDistancePayload = {
    __typename?: 'EditLocationAndDistancePayload';
    rentalUnit?: Maybe<RentalUnit>;
};

export type EditOwnerInformationInput = {
    ownerImageUploadId?: Maybe<Scalars['ID']>;
    ownerIntroduction?: Maybe<Scalars['String']>;
    ownerName?: Maybe<Scalars['String']>;
    ownerProfileVisible: Scalars['Boolean'];
    ownerTips?: Maybe<Scalars['String']>;
    rentalUnitId: Scalars['ID'];
};

export type EditOwnerInformationPayload = {
    __typename?: 'EditOwnerInformationPayload';
    rentalUnit?: Maybe<RentalUnit>;
};

export type EditRentalUnitAndAccommodationInfoInput = {
    caretaker: ContactInfoInput;
    checkInEndTime: Scalars['Time'];
    checkInStartTime: Scalars['Time'];
    checkOutTime: Scalars['Time'];
    handleSecurityDepositPayment: Scalars['Boolean'];
    releaseDays: Scalars['Int'];
    rentalUnitId: Scalars['ID'];
    reservations: ContactInfoInput;
    securityDeposit: Scalars['Float'];
    tagline?: Maybe<Scalars['String']>;
};

export type EditRentalUnitAndAccommodationInfoPayload = {
    __typename?: 'EditRentalUnitAndAccommodationInfoPayload';
    rentalUnit?: Maybe<RentalUnit>;
};

export type EditRentalUnitDescriptionsInput = {
    descriptionExtras?: Maybe<Scalars['String']>;
    descriptionLayout?: Maybe<Scalars['String']>;
    descriptionParticulars?: Maybe<Scalars['String']>;
    rentalUnitId: Scalars['ID'];
};

export type EditRentalUnitDescriptionsPayload = {
    __typename?: 'EditRentalUnitDescriptionsPayload';
    rentalUnit?: Maybe<RentalUnit>;
};

export type EditRentalUnitImagesInput = {
    fullCircleImageUrl?: Maybe<Scalars['String']>;
    images: Array<AccommodationImageInput>;
    listImageUploadId?: Maybe<Scalars['ID']>;
    mainImageUploadId?: Maybe<Scalars['ID']>;
    rentalUnitId: Scalars['ID'];
    videoUrl?: Maybe<Scalars['String']>;
};

export type EditRentalUnitImagesPayload = {
    __typename?: 'EditRentalUnitImagesPayload';
    rentalUnit?: Maybe<RentalUnit>;
};

export type EditRentalUnitInfoInput = {
    handleSecurityDepositPayment: Scalars['Boolean'];
    hideForPartner?: Maybe<Scalars['Boolean']>;
    keyHolderExplanation?: Maybe<Scalars['String']>;
    releaseDays: Scalars['Int'];
    rentalUnitId: Scalars['ID'];
    securityDeposit: Scalars['Float'];
    tagline?: Maybe<Scalars['String']>;
};

export type EditRentalUnitInfoPayload = {
    __typename?: 'EditRentalUnitInfoPayload';
    rentalUnit?: Maybe<RentalUnit>;
};

export type EditRentalUnitSurchargeInput = {
    calculation: SurchargeCalculationEnum;
    endDate?: Maybe<Scalars['Date']>;
    handlePayment: Scalars['Boolean'];
    maxAmount: Scalars['Int'];
    minAmount: Scalars['Int'];
    rentalUnitSurchargeId: Scalars['ID'];
    startDate: Scalars['Date'];
    unitPrice: Scalars['Float'];
};

export type EditRentalUnitSurchargePayload = {
    __typename?: 'EditRentalUnitSurchargePayload';
    rentalUnitSurcharge?: Maybe<RentalUnitSurcharge>;
};

export type EditReviewReplyInput = {
    reply: Scalars['String'];
    reviewId: Scalars['ID'];
};

export type EditReviewReplyPayload = {
    __typename?: 'EditReviewReplyPayload';
    review?: Maybe<Review>;
};

export type EditSpecialParticipationDateBlockInput = {
    blocked: Scalars['Boolean'];
    date: Scalars['Date'];
    specialParticipationId: Scalars['ID'];
};

export type EditSpecialParticipationDateBlockPayload = {
    __typename?: 'EditSpecialParticipationDateBlockPayload';
    specialParticipation?: Maybe<SpecialParticipation>;
};

export type EditSpecialParticipationInput = {
    optIn: Scalars['Boolean'];
    specialParticipationId: Scalars['ID'];
};

export type EditSpecialParticipationPayload = {
    __typename?: 'EditSpecialParticipationPayload';
    specialParticipation?: Maybe<SpecialParticipation>;
};

export type FlexPricingDay = {
    arrivalAllowed: Scalars['Boolean'];
    minimumStayDuration: Scalars['Int'];
    nightPrice: Scalars['Float'];
};

export enum FlexTypeEnum {
    Arrival = 'ARRIVAL',
    Both = 'BOTH',
    Departure = 'DEPARTURE',
    None = 'NONE',
}

export type Image = {
    dimensions: Array<Scalars['Int']>;
    id: Scalars['ID'];
    size: Scalars['Int'];
    url: Scalars['String'];
};

export type InitTicketScanSessionInput = {
    email: Scalars['String'];
    partnerId: Scalars['ID'];
    sessionExpiresAt?: Maybe<Scalars['DateTime']>;
    sessionLabel: Scalars['String'];
};

export type InitTicketScanSessionPayload = {
    __typename?: 'InitTicketScanSessionPayload';
    deepLink?: Maybe<Scalars['String']>;
};

export type LatLon = {
    __typename?: 'LatLon';
    lat: Scalars['Float'];
    lon: Scalars['Float'];
};

export type LatLonInput = {
    lat: Scalars['Float'];
    lon: Scalars['Float'];
};

export type NormalOperatingHoursInput = {
    friday: Array<TimeOpenClosedInput>;
    monday: Array<TimeOpenClosedInput>;
    saturday: Array<TimeOpenClosedInput>;
    sunday: Array<TimeOpenClosedInput>;
    thursday: Array<TimeOpenClosedInput>;
    tuesday: Array<TimeOpenClosedInput>;
    wednesday: Array<TimeOpenClosedInput>;
};

/**
 * One week of operating hours, optionally with a start and end date. Companies can have one default set of operating hours and multiple overrides. The operating hours are defined per day and are nullable. The different states have different meanings:
 *
 *
 *             An array with hours define the hours for that day, an empty array means that the company is closed on that day, a null value means that we should fall back to the default operating hours
 */
export type OperatingHours = {
    __typename?: 'OperatingHours';
    /** End of special operating hours period. */
    endDate?: Maybe<Scalars['Date']>;
    friday?: Maybe<Array<TimeOpenClosed>>;
    monday?: Maybe<Array<TimeOpenClosed>>;
    saturday?: Maybe<Array<TimeOpenClosed>>;
    /** Start of special operating hours period. */
    startDate?: Maybe<Scalars['Date']>;
    sunday?: Maybe<Array<TimeOpenClosed>>;
    thursday?: Maybe<Array<TimeOpenClosed>>;
    tuesday?: Maybe<Array<TimeOpenClosed>>;
    wednesday?: Maybe<Array<TimeOpenClosed>>;
};

export type OverwriteRentalUnitSurchargesInput = {
    sourceRentalUnitId: Scalars['ID'];
    targetRentalUnitIds: Array<Scalars['ID']>;
};

export type OverwriteRentalUnitSurchargesPayload = {
    __typename?: 'OverwriteRentalUnitSurchargesPayload';
    targetRentalUnits?: Maybe<Array<RentalUnit>>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
    __typename?: 'PageInfo';
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars['String']>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars['Boolean'];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars['Boolean'];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars['String']>;
};

/** A partner represents the legal entity that has ownership over other objects in Travelbase (such as accommodations and bookings). */
export type Partner = {
    __typename?: 'Partner';
    /** Accommodations that belong to the partner. */
    accommodations: Array<Accommodation>;
    address?: Maybe<Address>;
    /** Paginated response of all bookings for this partner. */
    allBookings?: Maybe<BookingConnection>;
    allTickets?: Maybe<TicketConnection>;
    balance: Scalars['Float'];
    balanceItems: Array<PartnerBalanceItem>;
    bankAccountActive: Scalars['Boolean'];
    bic?: Maybe<Scalars['String']>;
    /** Companies that belong to the partner. */
    companies: Array<Company>;
    /** Name of contact person (required), phone numbers (optional) and email addresses (optional) */
    contact: ContactInfo;
    contactSalutation?: Maybe<Scalars['String']>;
    /** Whether the partner is enabled. */
    enabled: Scalars['Boolean'];
    iban?: Maybe<Scalars['String']>;
    ibanName?: Maybe<Scalars['String']>;
    /** Unique identifier of the partner. */
    id: Scalars['ID'];
    invoiceAddress?: Maybe<Address>;
    invoiceEmails: Array<Scalars['String']>;
    /** Name of the partner. */
    name: Scalars['String'];
    newTickets?: Maybe<TicketConnection>;
    paymentByDirectDebit: Scalars['Boolean'];
    recentlyUpdatedBookings?: Maybe<BookingConnection>;
    reviews: Array<Review>;
    upcomingBookings?: Maybe<BookingConnection>;
    /** Get all bookings that were created or updated after the specified date and time. The result is limited to a maximum of 100 bookings. */
    updatedBookings: Array<Booking>;
};

/** A partner represents the legal entity that has ownership over other objects in Travelbase (such as accommodations and bookings). */
export type PartnerAllBookingsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    endDate?: Maybe<Scalars['Date']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    rentalUnitIds?: Maybe<Array<Scalars['ID']>>;
    searchQuery?: Maybe<Scalars['String']>;
    startDate?: Maybe<Scalars['Date']>;
};

/** A partner represents the legal entity that has ownership over other objects in Travelbase (such as accommodations and bookings). */
export type PartnerAllTicketsArgs = {
    activityIds?: Maybe<Array<Scalars['ID']>>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    companyId?: Maybe<Scalars['ID']>;
    endDate?: Maybe<Scalars['Date']>;
    externalTimeslotId?: Maybe<Scalars['ID']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    startDate?: Maybe<Scalars['Date']>;
    timeslotId?: Maybe<Scalars['ID']>;
};

/** A partner represents the legal entity that has ownership over other objects in Travelbase (such as accommodations and bookings). */
export type PartnerBalanceArgs = {
    atDate?: Maybe<Scalars['Date']>;
};

/** A partner represents the legal entity that has ownership over other objects in Travelbase (such as accommodations and bookings). */
export type PartnerBalanceItemsArgs = {
    endDate: Scalars['Date'];
    startDate: Scalars['Date'];
};

/** A partner represents the legal entity that has ownership over other objects in Travelbase (such as accommodations and bookings). */
export type PartnerNewTicketsArgs = {
    activityIds?: Maybe<Array<Scalars['ID']>>;
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    companyId?: Maybe<Scalars['ID']>;
    externalTimeslotId?: Maybe<Scalars['ID']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
    timeslotId?: Maybe<Scalars['ID']>;
};

/** A partner represents the legal entity that has ownership over other objects in Travelbase (such as accommodations and bookings). */
export type PartnerRecentlyUpdatedBookingsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

/** A partner represents the legal entity that has ownership over other objects in Travelbase (such as accommodations and bookings). */
export type PartnerReviewsArgs = {
    rentalUnitIds?: Maybe<Array<Scalars['ID']>>;
};

/** A partner represents the legal entity that has ownership over other objects in Travelbase (such as accommodations and bookings). */
export type PartnerUpcomingBookingsArgs = {
    after?: Maybe<Scalars['String']>;
    before?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    last?: Maybe<Scalars['Int']>;
};

/** A partner represents the legal entity that has ownership over other objects in Travelbase (such as accommodations and bookings). */
export type PartnerUpdatedBookingsArgs = {
    since: Scalars['DateTime'];
};

export type PartnerBalanceCorrection = PartnerBalanceItem & {
    __typename?: 'PartnerBalanceCorrection';
    amount: Scalars['Float'];
    createdAt: Scalars['DateTime'];
    description: Scalars['String'];
    id: Scalars['ID'];
};

export type PartnerBalanceItem = {
    amount: Scalars['Float'];
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
};

export type PartnerBalancePayment = PartnerBalanceItem & {
    __typename?: 'PartnerBalancePayment';
    amount: Scalars['Float'];
    batchType?: Maybe<PartnerPaymentBatchTypeEnum>;
    createdAt: Scalars['DateTime'];
    id: Scalars['ID'];
};

export type PartnerBookingInvoice = PartnerBalanceItem & {
    __typename?: 'PartnerBookingInvoice';
    amount: Scalars['Float'];
    createdAt: Scalars['DateTime'];
    downloadPdfUrl: Scalars['String'];
    downloadXlsxUrl: Scalars['String'];
    id: Scalars['ID'];
    invoiceNumber: Scalars['String'];
    periodEndDate: Scalars['Date'];
    periodStartDate: Scalars['Date'];
};

export type PartnerMutation = {
    __typename?: 'PartnerMutation';
    acceptBooking: AcceptBookingPayload;
    approveRentalUnitContract: ApproveRentalUnitContractPayload;
    bulkEditAllotments: BulkEditAllotmentsPayload;
    bulkEditDatePricingArrivalAllowed: BulkEditDatePricingArrivalAllowedPayload;
    bulkEditDatePricingBaseStayPrice: BulkEditDatePricingBaseStayPricePayload;
    bulkEditDatePricingDepartureAllowed: BulkEditDatePricingDepartureAllowedPayload;
    bulkEditDatePricingExtraPersonPrice: BulkEditDatePricingExtraPersonPricePayload;
    bulkEditDatePricingMinimumStayDuration: BulkEditDatePricingMinimumStayDurationPayload;
    bulkEditDatePricingMinimumStayPrice: BulkEditDatePricingMinimumStayPricePayload;
    bulkEditDatePricingNightPrice: BulkEditDatePricingNightPricePayload;
    bulkEditDatePricingWeekPrice: BulkEditDatePricingWeekPricePayload;
    bulkUpsertDatePricing: BulkUpsertDatePricingPayload;
    createActivityTimeslot: CreateActivityTimeslotPayload;
    createAllotmentLockout: CreateAllotmentLockoutPayload;
    createDatePricingModifier: CreateDatePricingModifierPayload;
    createImageUpload: CreateImageUploadPayload;
    createRentalUnitSurcharge: CreateRentalUnitSurchargePayload;
    deleteActivityRateGroup: DeleteActivityRateGroupPayload;
    deleteActivityTimeslot: DeleteActivityTimeslotPayload;
    deleteAllotmentLockout: DeleteAllotmentLockoutPayload;
    deleteCompanyRateGroup: DeleteCompanyRateGroupPayload;
    deleteDatePricingModifier: DeleteDatePricingModifierPayload;
    deleteRentalUnitSurcharge: DeleteRentalUnitSurchargePayload;
    editAccommodationDescription: EditAccommodationDescriptionPayload;
    editAccommodationImages: EditAccommodationImagesPayload;
    editAccommodationInfo: EditAccommodationInfoPayload;
    editActivityDescription: EditActivityDescriptionPayload;
    editActivityImages: EditActivityImagesPayload;
    editActivityInfo: EditActivityInfoPayload;
    editActivityLocation: EditActivityLocationPayload;
    editActivityTimeslot: EditActivityTimeslotPayload;
    editAllotment: EditAllotmentPayload;
    editAllotmentLockout: EditAllotmentLockoutPayload;
    editAttributes: EditAttributesPayload;
    editCapacityAndSpace: EditCapacityAndSpacePayload;
    editCompanyDescription: EditCompanyDescriptionPayload;
    editCompanyImages: EditCompanyImagesPayload;
    editCompanyInfo: EditCompanyInfoPayload;
    editCompanyLocation: EditCompanyLocationPayload;
    editCompanyNormalOperatingHours: EditCompanyNormalOperatingHoursPayload;
    editCompanySpecialOperatingHours: EditCompanySpecialOperatingHoursPayload;
    editDatePricingArrivalAllowed: EditDatePricingArrivalAllowedPayload;
    editDatePricingBaseStayPrice: EditDatePricingBaseStayPricePayload;
    editDatePricingDepartureAllowed: EditDatePricingDepartureAllowedPayload;
    editDatePricingDisplayOptions: EditDatePricingDisplayOptionsPayload;
    editDatePricingExtraPersonPrice: EditDatePricingExtraPersonPricePayload;
    editDatePricingMinimumStayDuration: EditDatePricingMinimumStayDurationPayload;
    editDatePricingMinimumStayPrice: EditDatePricingMinimumStayPricePayload;
    editDatePricingModifier: EditDatePricingModifierPayload;
    editDatePricingNightPrice: EditDatePricingNightPricePayload;
    editDatePricingWeekPrice: EditDatePricingWeekPricePayload;
    editDiscounts: EditDiscountForUnitPayload;
    editKeyHolder: EditKeyHolderPayload;
    editLocationAndDistance: EditLocationAndDistancePayload;
    editOwnerInformation: EditOwnerInformationPayload;
    editRentalUnitAndAccommodationInfo: EditRentalUnitAndAccommodationInfoPayload;
    editRentalUnitDescriptions: EditRentalUnitDescriptionsPayload;
    editRentalUnitImages: EditRentalUnitImagesPayload;
    editRentalUnitInfo: EditRentalUnitInfoPayload;
    editRentalUnitSurcharge: EditRentalUnitSurchargePayload;
    editReviewReply: EditReviewReplyPayload;
    editSpecialParticipationDateBlock: EditSpecialParticipationDateBlockPayload;
    editSpecialParticipationOptIn: EditSpecialParticipationPayload;
    initTicketScanSession: InitTicketScanSessionPayload;
    overwriteRentalUnitSurcharges: OverwriteRentalUnitSurchargesPayload;
    setActivityTimeslots: SetActivityTimeslotsPayload;
    uploadTripPricings: UploadTripPricingsPayload;
    upsertActivityRateGroup: UpsertActivityRateGroupPayload;
    upsertCompanyRateGroup: UpsertCompanyRateGroupPayload;
};

export type PartnerMutationAcceptBookingArgs = {
    input: AcceptBookingInput;
};

export type PartnerMutationApproveRentalUnitContractArgs = {
    input: ApproveRentalUnitContractInput;
};

export type PartnerMutationBulkEditAllotmentsArgs = {
    input: BulkEditAllotmentsInput;
};

export type PartnerMutationBulkEditDatePricingArrivalAllowedArgs = {
    input: BulkEditDatePricingArrivalAllowedInput;
};

export type PartnerMutationBulkEditDatePricingBaseStayPriceArgs = {
    input: BulkEditDatePricingBaseStayPriceInput;
};

export type PartnerMutationBulkEditDatePricingDepartureAllowedArgs = {
    input: BulkEditDatePricingDepartureAllowedInput;
};

export type PartnerMutationBulkEditDatePricingExtraPersonPriceArgs = {
    input: BulkEditDatePricingExtraPersonPriceInput;
};

export type PartnerMutationBulkEditDatePricingMinimumStayDurationArgs = {
    input: BulkEditDatePricingMinimumStayDurationInput;
};

export type PartnerMutationBulkEditDatePricingMinimumStayPriceArgs = {
    input: BulkEditDatePricingMinimumStayPriceInput;
};

export type PartnerMutationBulkEditDatePricingNightPriceArgs = {
    input: BulkEditDatePricingNightPriceInput;
};

export type PartnerMutationBulkEditDatePricingWeekPriceArgs = {
    input: BulkEditDatePricingWeekPriceInput;
};

export type PartnerMutationBulkUpsertDatePricingArgs = {
    input: BulkUpsertDatePricingInput;
};

export type PartnerMutationCreateActivityTimeslotArgs = {
    input: CreateActivityTimeslotInput;
};

export type PartnerMutationCreateAllotmentLockoutArgs = {
    input: CreateAllotmentLockoutInput;
};

export type PartnerMutationCreateDatePricingModifierArgs = {
    input: CreateDatePricingModifierInput;
};

export type PartnerMutationCreateRentalUnitSurchargeArgs = {
    input: CreateRentalUnitSurchargeInput;
};

export type PartnerMutationDeleteActivityRateGroupArgs = {
    input: DeleteActivityRateGroupInput;
};

export type PartnerMutationDeleteActivityTimeslotArgs = {
    input: DeleteActivityTimeslotInput;
};

export type PartnerMutationDeleteAllotmentLockoutArgs = {
    input: DeleteAllotmentLockoutInput;
};

export type PartnerMutationDeleteCompanyRateGroupArgs = {
    input: DeleteCompanyRateGroupInput;
};

export type PartnerMutationDeleteDatePricingModifierArgs = {
    input: DeleteDatePricingModifierInput;
};

export type PartnerMutationDeleteRentalUnitSurchargeArgs = {
    input: DeleteRentalUnitSurchargeInput;
};

export type PartnerMutationEditAccommodationDescriptionArgs = {
    input: EditAccommodationDescriptionInput;
};

export type PartnerMutationEditAccommodationImagesArgs = {
    input: EditAccommodationImagesInput;
};

export type PartnerMutationEditAccommodationInfoArgs = {
    input: EditAccommodationInfoInput;
};

export type PartnerMutationEditActivityDescriptionArgs = {
    input: EditActivityDescriptionInput;
};

export type PartnerMutationEditActivityImagesArgs = {
    input: EditActivityImagesInput;
};

export type PartnerMutationEditActivityInfoArgs = {
    input: EditActivityInfoInput;
};

export type PartnerMutationEditActivityLocationArgs = {
    input: EditActivityLocationInput;
};

export type PartnerMutationEditActivityTimeslotArgs = {
    input: EditActivityTimeslotInput;
};

export type PartnerMutationEditAllotmentArgs = {
    input: EditAllotmentInput;
};

export type PartnerMutationEditAllotmentLockoutArgs = {
    input: EditAllotmentLockoutInput;
};

export type PartnerMutationEditAttributesArgs = {
    input: EditAttributesInput;
};

export type PartnerMutationEditCapacityAndSpaceArgs = {
    input: EditCapacityAndSpaceInput;
};

export type PartnerMutationEditCompanyDescriptionArgs = {
    input: EditCompanyDescriptionInput;
};

export type PartnerMutationEditCompanyImagesArgs = {
    input: EditCompanyImagesInput;
};

export type PartnerMutationEditCompanyInfoArgs = {
    input: EditCompanyInfoInput;
};

export type PartnerMutationEditCompanyLocationArgs = {
    input: EditCompanyLocationInput;
};

export type PartnerMutationEditCompanyNormalOperatingHoursArgs = {
    input: EditCompanyNormalOperatingHoursInput;
};

export type PartnerMutationEditCompanySpecialOperatingHoursArgs = {
    input: EditCompanySpecialOperatingHoursInput;
};

export type PartnerMutationEditDatePricingArrivalAllowedArgs = {
    input: EditDatePricingArrivalAllowedInput;
};

export type PartnerMutationEditDatePricingBaseStayPriceArgs = {
    input: EditDatePricingBaseStayPriceInput;
};

export type PartnerMutationEditDatePricingDepartureAllowedArgs = {
    input: EditDatePricingDepartureAllowedInput;
};

export type PartnerMutationEditDatePricingDisplayOptionsArgs = {
    input: EditDatePricingDisplayOptionsInput;
};

export type PartnerMutationEditDatePricingExtraPersonPriceArgs = {
    input: EditDatePricingExtraPersonPriceInput;
};

export type PartnerMutationEditDatePricingMinimumStayDurationArgs = {
    input: EditDatePricingMinimumStayDurationInput;
};

export type PartnerMutationEditDatePricingMinimumStayPriceArgs = {
    input: EditDatePricingMinimumStayPriceInput;
};

export type PartnerMutationEditDatePricingModifierArgs = {
    input: EditDatePricingModifierInput;
};

export type PartnerMutationEditDatePricingNightPriceArgs = {
    input: EditDatePricingNightPriceInput;
};

export type PartnerMutationEditDatePricingWeekPriceArgs = {
    input: EditDatePricingWeekPriceInput;
};

export type PartnerMutationEditDiscountsArgs = {
    input: EditDiscountForUnitInput;
};

export type PartnerMutationEditKeyHolderArgs = {
    input: EditKeyHolderInput;
};

export type PartnerMutationEditLocationAndDistanceArgs = {
    input: EditLocationAndDistanceInput;
};

export type PartnerMutationEditOwnerInformationArgs = {
    input: EditOwnerInformationInput;
};

export type PartnerMutationEditRentalUnitAndAccommodationInfoArgs = {
    input: EditRentalUnitAndAccommodationInfoInput;
};

export type PartnerMutationEditRentalUnitDescriptionsArgs = {
    input: EditRentalUnitDescriptionsInput;
};

export type PartnerMutationEditRentalUnitImagesArgs = {
    input: EditRentalUnitImagesInput;
};

export type PartnerMutationEditRentalUnitInfoArgs = {
    input: EditRentalUnitInfoInput;
};

export type PartnerMutationEditRentalUnitSurchargeArgs = {
    input: EditRentalUnitSurchargeInput;
};

export type PartnerMutationEditReviewReplyArgs = {
    input: EditReviewReplyInput;
};

export type PartnerMutationEditSpecialParticipationDateBlockArgs = {
    input: EditSpecialParticipationDateBlockInput;
};

export type PartnerMutationEditSpecialParticipationOptInArgs = {
    input: EditSpecialParticipationInput;
};

export type PartnerMutationInitTicketScanSessionArgs = {
    input: InitTicketScanSessionInput;
};

export type PartnerMutationOverwriteRentalUnitSurchargesArgs = {
    input: OverwriteRentalUnitSurchargesInput;
};

export type PartnerMutationSetActivityTimeslotsArgs = {
    input: SetActivityTimeslotsInput;
};

export type PartnerMutationUploadTripPricingsArgs = {
    input: UploadTripPricingsInput;
};

export type PartnerMutationUpsertActivityRateGroupArgs = {
    input: UpsertActivityRateGroupInput;
};

export type PartnerMutationUpsertCompanyRateGroupArgs = {
    input: UpsertCompanyRateGroupInput;
};

export enum PartnerPaymentBatchTypeEnum {
    Credit = 'CREDIT',
    Debit = 'DEBIT',
    Manual = 'MANUAL',
}

export type PartnerQuery = {
    __typename?: 'PartnerQuery';
    accommodation?: Maybe<Accommodation>;
    activity?: Maybe<Activity>;
    attributeCategories: Array<AttributeCategory>;
    booking?: Maybe<Booking>;
    company?: Maybe<Company>;
    partner?: Maybe<Partner>;
    rentalUnit?: Maybe<RentalUnit>;
    review?: Maybe<Review>;
    ticket?: Maybe<Ticket>;
    timeslot?: Maybe<Timeslot>;
    viewer?: Maybe<User>;
};

export type PartnerQueryAccommodationArgs = {
    slug: Scalars['String'];
};

export type PartnerQueryActivityArgs = {
    id?: Maybe<Scalars['ID']>;
    slug?: Maybe<Scalars['String']>;
};

export type PartnerQueryAttributeCategoriesArgs = {
    subtype?: Maybe<RentalUnitTypeEnum>;
    type?: Maybe<AttributeTypeEnum>;
};

export type PartnerQueryBookingArgs = {
    id: Scalars['ID'];
};

export type PartnerQueryCompanyArgs = {
    id?: Maybe<Scalars['ID']>;
    slug?: Maybe<Scalars['String']>;
};

export type PartnerQueryPartnerArgs = {
    id: Scalars['ID'];
};

export type PartnerQueryRentalUnitArgs = {
    id?: Maybe<Scalars['ID']>;
    slug?: Maybe<Scalars['String']>;
};

export type PartnerQueryReviewArgs = {
    id: Scalars['ID'];
};

export type PartnerQueryTicketArgs = {
    id: Scalars['ID'];
};

export type PartnerQueryTimeslotArgs = {
    id: Scalars['ID'];
};

export type PartnerTicketInvoice = PartnerBalanceItem & {
    __typename?: 'PartnerTicketInvoice';
    amount: Scalars['Float'];
    createdAt: Scalars['DateTime'];
    downloadPdfUrl: Scalars['String'];
    downloadXlsxUrl: Scalars['String'];
    id: Scalars['ID'];
    invoiceNumber: Scalars['String'];
    periodEndDate: Scalars['Date'];
    periodStartDate: Scalars['Date'];
};

export enum PaymentTypeEnum {
    Configurable = 'CONFIGURABLE',
    Partner = 'PARTNER',
    Travelbase = 'TRAVELBASE',
}

export type Place = {
    __typename?: 'Place';
    id: Scalars['ID'];
    name: Scalars['String'];
};

export enum PriceLineCategoryEnum {
    Accommodation = 'ACCOMMODATION',
    AdminCosts = 'ADMIN_COSTS',
    Charges = 'CHARGES',
    Insurance = 'INSURANCE',
    NotInTotal = 'NOT_IN_TOTAL',
    Special = 'SPECIAL',
    Surcharges = 'SURCHARGES',
}

/** Statistics for QR scans for a specific rate */
export type QrScanStats = {
    __typename?: 'QrScanStats';
    /** The name of the rate (at time of sale) these statistics apply to. */
    rateName: Scalars['String'];
    /** The number of QR tickets scanned for this rate. */
    scannedCount: Scalars['Int'];
    /** The number of QR tickets sold for this rate. */
    soldCount: Scalars['Int'];
};

/** A rate. */
export type Rate = {
    __typename?: 'Rate';
    /** Unique identifier of the rate. */
    id: Scalars['ID'];
    name?: Maybe<Scalars['String']>;
    position: Scalars['Int'];
    price: Scalars['Float'];
};

/** A rate. */
export type RateNameArgs = {
    locale?: Maybe<Scalars['String']>;
};

export type RateGroup = {
    canBuyTickets: Scalars['Boolean'];
    /** Unique identifier of the rate group. */
    id: Scalars['ID'];
    /** Name of the group for internal use. */
    name?: Maybe<Scalars['String']>;
    rates: Array<Rate>;
};

export type RateInput = {
    /** If provided, will update the existing Rate object with the specified ID. Otherwise, a new object will be created. */
    id?: Maybe<Scalars['ID']>;
    position: Scalars['Int'];
    price: Scalars['Float'];
    translations: Array<RateTranslationInput>;
};

export type RateTranslationInput = {
    locale: Scalars['String'];
    name: Scalars['String'];
};

/** A rental unit represents the private guest space in an accommodation. In many cases this is the same as the accommodation. For larger accommodations like hotels and apartment buildings, this represents the room type or apartment in the building. */
export type RentalUnit = {
    __typename?: 'RentalUnit';
    accommodation: Accommodation;
    allotmentLockouts: Array<AllotmentLockout>;
    allotments: Array<Allotment>;
    amountBathrooms?: Maybe<Scalars['Int']>;
    amountBedrooms?: Maybe<Scalars['Int']>;
    amountBeds?: Maybe<Scalars['Int']>;
    amountToilets?: Maybe<Scalars['Int']>;
    attributes: Array<Attribute>;
    availableSurcharges: Array<Surcharge>;
    /** Limited to a maximum of 500 results. If you could conceivably need more, you should probably use the root query field "bookings". */
    bookings: Array<Booking>;
    /** Assigned code of this rental unit as used in communications. */
    code: Scalars['String'];
    datePricingEndDate?: Maybe<Scalars['Date']>;
    datePricingModifiers: Array<DatePricingModifier>;
    datePricingStartDate?: Maybe<Scalars['Date']>;
    datePricings: Array<DatePricing>;
    descriptionExtras?: Maybe<Scalars['String']>;
    descriptionExtrasPending?: Maybe<Scalars['String']>;
    descriptionLayout?: Maybe<Scalars['String']>;
    descriptionLayoutPending?: Maybe<Scalars['String']>;
    descriptionParticulars?: Maybe<Scalars['String']>;
    descriptionParticularsPending?: Maybe<Scalars['String']>;
    earlyBookDiscount6Months?: Maybe<Scalars['Float']>;
    earlyBookDiscount12Months?: Maybe<Scalars['Float']>;
    earlyBookDiscountEnabled: Scalars['Boolean'];
    /** Whether the rental unit is enabled. */
    enabled: Scalars['Boolean'];
    flexCancellationDays?: Maybe<Scalars['Int']>;
    flexibleArrival: Scalars['Boolean'];
    flexibleDeparture: Scalars['Boolean'];
    fullCircleImageUrl?: Maybe<Scalars['String']>;
    handleSecurityDepositPayment: Scalars['Boolean'];
    hideForPartner: Scalars['Boolean'];
    hideReviews: Scalars['Boolean'];
    /** Unique identifier of the rental unit. */
    id: Scalars['ID'];
    images: Array<AccommodationImage>;
    /** Amount of persons included in the rental unit's prices. */
    includedOccupancy?: Maybe<Scalars['Int']>;
    keyHolderExplanation?: Maybe<Scalars['String']>;
    keyHolderExplanationPending?: Maybe<Scalars['String']>;
    lastDatePricingDate?: Maybe<Scalars['Date']>;
    lastminuteDiscount1Week?: Maybe<Scalars['Float']>;
    lastminuteDiscount2Week?: Maybe<Scalars['Float']>;
    lastminuteDiscount3Week?: Maybe<Scalars['Float']>;
    lastminuteDiscount4Week?: Maybe<Scalars['Float']>;
    lastminuteDiscountEnabled: Scalars['Boolean'];
    listImage?: Maybe<AccommodationListImage>;
    livingArea?: Maybe<Scalars['Int']>;
    mainImage?: Maybe<AccommodationMainImage>;
    /** Maximum available allotment of this rental unit. */
    maxAllotment: Scalars['Int'];
    maxExtraBabyOccupancy?: Maybe<Scalars['Int']>;
    maxOccupancy?: Maybe<Scalars['Int']>;
    minOccupancy?: Maybe<Scalars['Int']>;
    /** Name of the rental unit. */
    name: Scalars['String'];
    outdoorArea?: Maybe<Scalars['Int']>;
    petsAllowed?: Maybe<Scalars['Int']>;
    public: Scalars['Boolean'];
    releaseDays?: Maybe<Scalars['Int']>;
    rentalUnitContracts: Array<RentalUnitContract>;
    securityDeposit?: Maybe<Scalars['Float']>;
    showAllotmentLockouts: Scalars['Boolean'];
    /** @deprecated Added support for night pricing options to the Management API: relevant info might be hidden */
    showArrivalAllowedRow: Scalars['Boolean'];
    showBaseStayPriceRow: Scalars['Boolean'];
    /** @deprecated Added support for night pricing options to the Management API: relevant info might be hidden */
    showDepartureAllowedRow: Scalars['Boolean'];
    showExtraPersonPriceRow: Scalars['Boolean'];
    /** @deprecated Added support for night pricing options to the Management API: relevant info might be hidden */
    showMinimumStayDurationRow: Scalars['Boolean'];
    showMinimumStayPriceRow: Scalars['Boolean'];
    /** @deprecated Added support for night pricing options to the Management API: relevant info might be hidden */
    showNightPriceRow: Scalars['Boolean'];
    showWeekPriceRow: Scalars['Boolean'];
    slug: Scalars['String'];
    specialParticipations: Array<SpecialParticipation>;
    surcharges: Array<RentalUnitSurcharge>;
    tagline: Scalars['String'];
    taglinePending?: Maybe<Scalars['String']>;
    thumbnailUrl?: Maybe<Scalars['String']>;
    tripPricingExportUrl?: Maybe<Scalars['String']>;
    tripPricings: Array<TripPricing>;
    trips: Array<Trip>;
    /** Type of the rental unit. */
    type: RentalUnitTypeEnum;
    videoUrl?: Maybe<Scalars['String']>;
};

/** A rental unit represents the private guest space in an accommodation. In many cases this is the same as the accommodation. For larger accommodations like hotels and apartment buildings, this represents the room type or apartment in the building. */
export type RentalUnitAllotmentLockoutsArgs = {
    endDate: Scalars['Date'];
    startDate: Scalars['Date'];
};

/** A rental unit represents the private guest space in an accommodation. In many cases this is the same as the accommodation. For larger accommodations like hotels and apartment buildings, this represents the room type or apartment in the building. */
export type RentalUnitAllotmentsArgs = {
    endDate: Scalars['Date'];
    startDate: Scalars['Date'];
};

/** A rental unit represents the private guest space in an accommodation. In many cases this is the same as the accommodation. For larger accommodations like hotels and apartment buildings, this represents the room type or apartment in the building. */
export type RentalUnitBookingsArgs = {
    endDate: Scalars['Date'];
    startDate: Scalars['Date'];
};

/** A rental unit represents the private guest space in an accommodation. In many cases this is the same as the accommodation. For larger accommodations like hotels and apartment buildings, this represents the room type or apartment in the building. */
export type RentalUnitDatePricingModifiersArgs = {
    endDate: Scalars['Date'];
    startDate: Scalars['Date'];
};

/** A rental unit represents the private guest space in an accommodation. In many cases this is the same as the accommodation. For larger accommodations like hotels and apartment buildings, this represents the room type or apartment in the building. */
export type RentalUnitDatePricingsArgs = {
    endDate: Scalars['Date'];
    startDate: Scalars['Date'];
};

/** A rental unit represents the private guest space in an accommodation. In many cases this is the same as the accommodation. For larger accommodations like hotels and apartment buildings, this represents the room type or apartment in the building. */
export type RentalUnitDescriptionExtrasArgs = {
    locale?: Maybe<Scalars['String']>;
};

/** A rental unit represents the private guest space in an accommodation. In many cases this is the same as the accommodation. For larger accommodations like hotels and apartment buildings, this represents the room type or apartment in the building. */
export type RentalUnitDescriptionLayoutArgs = {
    locale?: Maybe<Scalars['String']>;
};

/** A rental unit represents the private guest space in an accommodation. In many cases this is the same as the accommodation. For larger accommodations like hotels and apartment buildings, this represents the room type or apartment in the building. */
export type RentalUnitDescriptionParticularsArgs = {
    locale?: Maybe<Scalars['String']>;
};

/** A rental unit represents the private guest space in an accommodation. In many cases this is the same as the accommodation. For larger accommodations like hotels and apartment buildings, this represents the room type or apartment in the building. */
export type RentalUnitKeyHolderExplanationArgs = {
    locale?: Maybe<Scalars['String']>;
};

/** A rental unit represents the private guest space in an accommodation. In many cases this is the same as the accommodation. For larger accommodations like hotels and apartment buildings, this represents the room type or apartment in the building. */
export type RentalUnitTaglineArgs = {
    locale?: Maybe<Scalars['String']>;
};

/** A rental unit represents the private guest space in an accommodation. In many cases this is the same as the accommodation. For larger accommodations like hotels and apartment buildings, this represents the room type or apartment in the building. */
export type RentalUnitTripPricingsArgs = {
    endDate: Scalars['Date'];
    startDate: Scalars['Date'];
};

/** A rental unit represents the private guest space in an accommodation. In many cases this is the same as the accommodation. For larger accommodations like hotels and apartment buildings, this represents the room type or apartment in the building. */
export type RentalUnitTripsArgs = {
    date: Scalars['Date'];
    persons?: Maybe<Scalars['Int']>;
};

export type RentalUnitContract = {
    __typename?: 'RentalUnitContract';
    approvalDate?: Maybe<Scalars['DateTime']>;
    contract: Contract;
    endDate?: Maybe<Scalars['Date']>;
    id: Scalars['ID'];
    rentalUnit: RentalUnit;
    startDate: Scalars['Date'];
};

export type RentalUnitSurcharge = {
    __typename?: 'RentalUnitSurcharge';
    calculation: SurchargeCalculationEnum;
    endDate?: Maybe<Scalars['Date']>;
    handlePayment: Scalars['Boolean'];
    id: Scalars['ID'];
    maxAmount: Scalars['Int'];
    minAmount: Scalars['Int'];
    rentalUnit: RentalUnit;
    startDate: Scalars['Date'];
    surcharge: Surcharge;
    unitPrice: Scalars['Float'];
};

export enum RentalUnitTypeEnum {
    Bed = 'BED',
    Boat = 'BOAT',
    CamperSpot = 'CAMPER_SPOT',
    CampingPitch = 'CAMPING_PITCH',
    Home = 'HOME',
    Room = 'ROOM',
    Tent = 'TENT',
}

export type ResetMutation = {
    __typename?: 'ResetMutation';
    resetPassword: ResetPasswordPayload;
    sendPasswordResetMail: SendPasswordResetMailPayload;
};

export type ResetMutationResetPasswordArgs = {
    input: ResetPasswordInput;
};

export type ResetMutationSendPasswordResetMailArgs = {
    input: SendPasswordResetMailInput;
};

export type ResetPasswordInput = {
    newPassword: Scalars['String'];
    token: Scalars['String'];
};

export type ResetPasswordPayload = {
    __typename?: 'ResetPasswordPayload';
    email?: Maybe<Scalars['String']>;
};

export type ResetQuery = {
    __typename?: 'ResetQuery';
    viewer?: Maybe<User>;
};

export type Review = {
    __typename?: 'Review';
    /** @deprecated Customer names have been removed, so all reviews are essentially anonymous now. */
    anonymous: Scalars['Boolean'];
    average: Scalars['Float'];
    city?: Maybe<Scalars['String']>;
    comment?: Maybe<Scalars['String']>;
    createdAt: Scalars['DateTime'];
    displayName?: Maybe<Scalars['String']>;
    enabled: Scalars['Boolean'];
    id: Scalars['ID'];
    language: Scalars['String'];
    ratingHospitality: Scalars['Int'];
    ratingLayout: Scalars['Int'];
    ratingLocation: Scalars['Int'];
    ratingMaintenance: Scalars['Int'];
    ratingValueForMoney: Scalars['Int'];
    rentalUnit: RentalUnit;
    reply?: Maybe<Scalars['String']>;
    tips?: Maybe<Scalars['String']>;
    title: Scalars['String'];
    travelGroupType?: Maybe<TravelGroupTypeEnum>;
};

export type SendPasswordResetMailInput = {
    email: Scalars['String'];
};

export type SendPasswordResetMailPayload = {
    __typename?: 'SendPasswordResetMailPayload';
    email?: Maybe<Scalars['String']>;
};

export type SetActivityTimeslotsInput = {
    activityId: Scalars['ID'];
    /** End of date range to which this mutation is applied to, inclusive. */
    endDate: Scalars['Date'];
    friday?: Maybe<Array<TimeslotByTimeInput>>;
    monday?: Maybe<Array<TimeslotByTimeInput>>;
    rateGroupId: Scalars['ID'];
    saturday?: Maybe<Array<TimeslotByTimeInput>>;
    /** Start of date range to which this mutation is applied to. */
    startDate: Scalars['Date'];
    sunday?: Maybe<Array<TimeslotByTimeInput>>;
    thursday?: Maybe<Array<TimeslotByTimeInput>>;
    translations: Array<TimeslotTranslationInput>;
    tuesday?: Maybe<Array<TimeslotByTimeInput>>;
    wednesday?: Maybe<Array<TimeslotByTimeInput>>;
};

export type SetActivityTimeslotsPayload = {
    __typename?: 'SetActivityTimeslotsPayload';
    activity?: Maybe<Activity>;
};

/** A special represents a special offer that may be selected in a booking. Specials can affect the price of the booking. */
export type Special = {
    __typename?: 'Special';
    arrivalFrom: Scalars['Date'];
    arrivalUntil: Scalars['Date'];
    availableFrom: Scalars['Date'];
    availableUntil: Scalars['Date'];
    description?: Maybe<Scalars['String']>;
    /** Unique identifier of the special. */
    id: Scalars['ID'];
    /** Name of the special. */
    name?: Maybe<Scalars['String']>;
    optInRequired: Scalars['Boolean'];
    partnerConditions?: Maybe<Scalars['String']>;
};

/** A special represents a special offer that may be selected in a booking. Specials can affect the price of the booking. */
export type SpecialDescriptionArgs = {
    locale?: Maybe<Scalars['String']>;
};

/** A special represents a special offer that may be selected in a booking. Specials can affect the price of the booking. */
export type SpecialNameArgs = {
    locale?: Maybe<Scalars['String']>;
};

export type SpecialOperatingHoursInput = {
    /** End of special operating hours period. */
    endDate: Scalars['Date'];
    friday?: Maybe<Array<TimeOpenClosedInput>>;
    monday?: Maybe<Array<TimeOpenClosedInput>>;
    saturday?: Maybe<Array<TimeOpenClosedInput>>;
    /** Start of special operating hours period. */
    startDate: Scalars['Date'];
    sunday?: Maybe<Array<TimeOpenClosedInput>>;
    thursday?: Maybe<Array<TimeOpenClosedInput>>;
    tuesday?: Maybe<Array<TimeOpenClosedInput>>;
    wednesday?: Maybe<Array<TimeOpenClosedInput>>;
};

export type SpecialParticipation = {
    __typename?: 'SpecialParticipation';
    blockedArrivalDates: Array<Scalars['Date']>;
    id: Scalars['ID'];
    optInAcceptedAt?: Maybe<Scalars['DateTime']>;
    optInRejectedAt?: Maybe<Scalars['DateTime']>;
    rentalUnit: RentalUnit;
    special: Special;
    trips: Array<Trip>;
};

export type SpecialParticipationTripsArgs = {
    endDate: Scalars['Date'];
    startDate: Scalars['Date'];
};

/** Surcharges are (mandatory or optional) costs included in a booking which are configured by the partner. */
export type Surcharge = {
    __typename?: 'Surcharge';
    allowedCalculations: Array<SurchargeCalculationEnum>;
    /** @deprecated No longer a setting per Surcharge, but is now configured on Contract level. */
    canBePaidThroughTor: Scalars['Boolean'];
    description?: Maybe<Scalars['String']>;
    /** Unique identifier of the surcharge. */
    id: Scalars['ID'];
    /** Name of the surcharge. */
    name?: Maybe<Scalars['String']>;
};

/** Surcharges are (mandatory or optional) costs included in a booking which are configured by the partner. */
export type SurchargeDescriptionArgs = {
    locale?: Maybe<Scalars['String']>;
};

/** Surcharges are (mandatory or optional) costs included in a booking which are configured by the partner. */
export type SurchargeNameArgs = {
    locale?: Maybe<Scalars['String']>;
};

export enum SurchargeCalculationEnum {
    PerPerson = 'PER_PERSON',
    PerPersonPerNight = 'PER_PERSON_PER_NIGHT',
    PerPet = 'PER_PET',
    PerPetPerNight = 'PER_PET_PER_NIGHT',
    PerPiece = 'PER_PIECE',
    PerPiecePerNight = 'PER_PIECE_PER_NIGHT',
    Subsequent = 'SUBSEQUENT',
}

export enum TargetAudienceEnum {
    Adults = 'ADULTS',
    AllAges = 'ALL_AGES',
    Children = 'CHILDREN',
    Pets = 'PETS',
    WheelchairAccessible = 'WHEELCHAIR_ACCESSIBLE',
}

/** A ticket. */
export type Ticket = {
    __typename?: 'Ticket';
    /** Date and time the ticket was created. */
    createdAt: Scalars['DateTime'];
    /** The customer who bought this ticket. */
    customer?: Maybe<Customer>;
    /** @deprecated Duplicate, use timeslot.endDateTime instead. */
    endDateTime: Scalars['DateTime'];
    /** Unique identifier of the ticket. */
    id: Scalars['ID'];
    /** Number of the ticket as integrated in de bar code on the ticket PDF */
    number: Scalars['String'];
    priceLines: Array<TicketPriceLine>;
    /** Whether or not QR scanning through the Travelbase scan app is enabled for this Ticket. */
    qrScansEnabled: Scalars['Boolean'];
    rateLines: Array<TicketRateLine>;
    requiredAllotment: Scalars['Int'];
    /** @deprecated Duplicate, use timeslot.startDateTime instead. */
    startDateTime: Scalars['DateTime'];
    /** Status of this ticket. */
    status: TicketStatusEnum;
    /** The timeslot this ticket is valid for. */
    timeslot: Timeslot;
};

/** A connection to a list of items. */
export type TicketConnection = {
    __typename?: 'TicketConnection';
    /** Information to aid in pagination. */
    edges?: Maybe<Array<Maybe<TicketEdge>>>;
    /** Information to aid in pagination. */
    pageInfo: PageInfo;
    /** Total tickets for this query. */
    totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type TicketEdge = {
    __typename?: 'TicketEdge';
    /** A cursor for use in pagination. */
    cursor: Scalars['String'];
    /** The item at the end of the edge. */
    node?: Maybe<Ticket>;
};

export type TicketPriceLine = {
    __typename?: 'TicketPriceLine';
    label: Scalars['String'];
    quantity: Scalars['Int'];
    totalPrice: Scalars['Float'];
    unitPrice?: Maybe<Scalars['Float']>;
};

/** A single QR code provided for a specific TicketRateLine. */
export type TicketQrCode = {
    __typename?: 'TicketQrCode';
    /** The code embedded in the QR. */
    code: Scalars['String'];
    /** The number of this QR code within the TicketRateLine. */
    number: Scalars['Int'];
    /** The date and time this QR was scanned, or NULL if it has not been scanned (yet). */
    scannedAt?: Maybe<Scalars['DateTime']>;
    /** The label for the authenticated user that scanned this QR, or NULL if it has not been scanned (yet). */
    scannedBy?: Maybe<Scalars['String']>;
};

/** A ticket rate line. */
export type TicketRateLine = {
    __typename?: 'TicketRateLine';
    amount: Scalars['Int'];
    /** The QR codes provided for this TicketRateLine. Will be NULL if QR scanning is not enabled for this Ticket. */
    qrCodes?: Maybe<Array<TicketQrCode>>;
    rate?: Maybe<Rate>;
    rateName?: Maybe<Scalars['String']>;
    unitPrice: Scalars['Float'];
};

/** A ticket rate line. */
export type TicketRateLineRateNameArgs = {
    locale?: Maybe<Scalars['String']>;
};

export enum TicketStatusEnum {
    Accepted = 'ACCEPTED',
    Init = 'INIT',
    Waived = 'WAIVED',
}

/** A time range of operating hours. */
export type TimeOpenClosed = {
    __typename?: 'TimeOpenClosed';
    /** Closing time. */
    timeClosed: Scalars['Time'];
    /** Opening time. */
    timeOpen: Scalars['Time'];
};

export type TimeOpenClosedInput = {
    timeClosed: Scalars['Time'];
    timeOpen: Scalars['Time'];
};

/** A timeslot. */
export type Timeslot = {
    __typename?: 'Timeslot';
    activity: Activity;
    /** Number of tickets available for this timeslot. If null, an unlimited number is available. */
    allotment?: Maybe<Scalars['Int']>;
    endDateTime: Scalars['DateTime'];
    externalId?: Maybe<Scalars['String']>;
    /** Unique identifier of the timeslot. */
    id: Scalars['ID'];
    label?: Maybe<Scalars['String']>;
    /** Statistics on QR codes scanned for this Timeslot. Will be NULL if QR scanning is not enabled for this Timeslot. */
    qrScanStats?: Maybe<Array<QrScanStats>>;
    rateGroup: RateGroup;
    startDateTime: Scalars['DateTime'];
};

/** A timeslot. */
export type TimeslotLabelArgs = {
    locale?: Maybe<Scalars['String']>;
};

export type TimeslotByTimeInput = {
    allotment?: Maybe<Scalars['Int']>;
    endTime: Scalars['Time'];
    externalId?: Maybe<Scalars['String']>;
    startTime: Scalars['Time'];
};

export type TimeslotInput = {
    /** The amount of available tickets for this Timeslot. Leaving this blank/NULL means unlimited tickets are available. */
    allotment?: Maybe<Scalars['Int']>;
    /** The date and time at which this Timeslot ends. */
    endDateTime: Scalars['DateTime'];
    /** A reference value used to uniquely identify this Timeslot and match it to an object in an external system. */
    externalId?: Maybe<Scalars['String']>;
    /** ID of the RateGroup which should apply to tickets sold for this Timeslot. */
    rateGroupId: Scalars['ID'];
    /** The date and time at which this Timeslot starts. */
    startDateTime: Scalars['DateTime'];
    /** CURRENTLY UNUSED - A description specific to this individual Timeslot. */
    translations?: Maybe<Array<TimeslotTranslationInput>>;
};

export type TimeslotTranslationInput = {
    label?: Maybe<Scalars['String']>;
    locale: Scalars['String'];
};

export enum TravelGroupTypeEnum {
    Alone = 'ALONE',
    BusinessTrip = 'BUSINESS_TRIP',
    FamilyWithOlderChildren = 'FAMILY_WITH_OLDER_CHILDREN',
    FamilyWithYoungChildren = 'FAMILY_WITH_YOUNG_CHILDREN',
    GroupOfFriends = 'GROUP_OF_FRIENDS',
    GroupTrip = 'GROUP_TRIP',
    OlderCouple = 'OLDER_COUPLE',
    YoungCouple = 'YOUNG_COUPLE',
}

export type Trip = {
    __typename?: 'Trip';
    date: Scalars['Date'];
    duration: Scalars['Int'];
    /** Whether trip was created with flex arrival and/or departure */
    flexType: FlexTypeEnum;
    rentalSumPartner: Scalars['Float'];
    special?: Maybe<Special>;
    type: TripTypeEnum;
};

export type TripPricing = {
    __typename?: 'TripPricing';
    date: Scalars['Date'];
    duration: Scalars['Int'];
    extraPersonPrice: Scalars['Float'];
    id: Scalars['ID'];
    minimumStayPrice: Scalars['Float'];
    price: Scalars['Float'];
    rentalUnit: RentalUnit;
};

export enum TripTypeEnum {
    EarlyBooking = 'EARLY_BOOKING',
    LastMinute = 'LAST_MINUTE',
    Regular = 'REGULAR',
    Special = 'SPECIAL',
}

export type UploadTripPricingsInput = {
    file: Scalars['FileUpload'];
    rentalUnitId: Scalars['ID'];
};

export type UploadTripPricingsPayload = {
    __typename?: 'UploadTripPricingsPayload';
    rentalUnit?: Maybe<RentalUnit>;
};

export type UpsertActivityRateGroupInput = {
    activityId: Scalars['ID'];
    canBuyTickets: Scalars['Boolean'];
    name?: Maybe<Scalars['String']>;
    qrScansEnabled?: Maybe<Scalars['Boolean']>;
    rateGroupId?: Maybe<Scalars['ID']>;
    rates: Array<RateInput>;
    releaseHours?: Maybe<Scalars['Int']>;
};

export type UpsertActivityRateGroupPayload = {
    __typename?: 'UpsertActivityRateGroupPayload';
    activity?: Maybe<Activity>;
};

export type UpsertCompanyRateGroupInput = {
    canBuyTickets: Scalars['Boolean'];
    companyId: Scalars['ID'];
    endDate?: Maybe<Scalars['Date']>;
    name?: Maybe<Scalars['String']>;
    rateGroupId?: Maybe<Scalars['ID']>;
    rates: Array<RateInput>;
    startDate?: Maybe<Scalars['Date']>;
};

export type UpsertCompanyRateGroupPayload = {
    __typename?: 'UpsertCompanyRateGroupPayload';
    company?: Maybe<Company>;
};

export type User = {
    __typename?: 'User';
    email: Scalars['String'];
    fullName: Scalars['String'];
    id: Scalars['ID'];
    /** @deprecated Ability to impersonate specific PartnerUsers has been removed. */
    impersonatedBy?: Maybe<User>;
    isBackofficeUser: Scalars['Boolean'];
    lastLogin?: Maybe<Scalars['String']>;
    partners: Array<Partner>;
};

export type WeekPricingDay = {
    arrivalAllowed: Scalars['Boolean'];
};

export type PasswordForgotMutationVariables = Exact<{
    email: Scalars['String'];
}>;

export type PasswordForgotMutation = {
    __typename?: 'ResetMutation';
    sendPasswordResetMail: { __typename?: 'SendPasswordResetMailPayload'; email?: Maybe<string> };
};

export type PasswordResetMutationVariables = Exact<{
    token: Scalars['String'];
    newPassword: Scalars['String'];
}>;

export type PasswordResetMutation = {
    __typename?: 'ResetMutation';
    resetPassword: { __typename?: 'ResetPasswordPayload'; email?: Maybe<string> };
};

export const PasswordForgotDocument = gql`
    mutation passwordForgot($email: String!) {
        sendPasswordResetMail(input: { email: $email }) {
            email
        }
    }
`;

/**
 * __usePasswordForgotMutation__
 *
 * To run a mutation, you first call `usePasswordForgotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordForgotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordForgotMutation, { data, loading, error }] = usePasswordForgotMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function usePasswordForgotMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<PasswordForgotMutation, PasswordForgotMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<PasswordForgotMutation, PasswordForgotMutationVariables>(
        PasswordForgotDocument,
        options
    );
}
export type PasswordForgotMutationHookResult = ReturnType<typeof usePasswordForgotMutation>;
export type PasswordForgotMutationResult = Apollo.MutationResult<PasswordForgotMutation>;
export type PasswordForgotMutationOptions = Apollo.BaseMutationOptions<
    PasswordForgotMutation,
    PasswordForgotMutationVariables
>;
export const PasswordResetDocument = gql`
    mutation passwordReset($token: String!, $newPassword: String!) {
        resetPassword(input: { token: $token, newPassword: $newPassword }) {
            email
        }
    }
`;

/**
 * __usePasswordResetMutation__
 *
 * To run a mutation, you first call `usePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetMutation, { data, loading, error }] = usePasswordResetMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function usePasswordResetMutation(
    baseOptions?: ApolloReactHooks.MutationHookOptions<PasswordResetMutation, PasswordResetMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation<PasswordResetMutation, PasswordResetMutationVariables>(
        PasswordResetDocument,
        options
    );
}
export type PasswordResetMutationHookResult = ReturnType<typeof usePasswordResetMutation>;
export type PasswordResetMutationResult = Apollo.MutationResult<PasswordResetMutation>;
export type PasswordResetMutationOptions = Apollo.BaseMutationOptions<
    PasswordResetMutation,
    PasswordResetMutationVariables
>;
