import isDate from 'date-fns/isDate';
import parse from 'date-fns/parse';

const checkIsDate = (date: Date | string): date is Date => isDate(date);
const parseDate = (date: Date | string) => {
    if (checkIsDate(date)) {
        return date;
    }
    return parse(date, 'yyyy-MM-dd', new Date());
};

export default parseDate;
