import { Box } from '@rebass/grid';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import FieldSet from '../atoms/FieldSet';
import FieldSetHint from '../atoms/FieldSetHint';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import TextInput, {
    TextInputField,
    TextInputFieldProps,
} from '@oberoninternal/travelbase-ds/components/form/TextInput';
import LabelBox from './LabelBox';
import TranslationStatus from './TranslationStatus';
import { FormattedMessage, useIntl } from 'react-intl';

type Maybe = string | null | undefined;

interface Props extends TextInputFieldProps {
    name: string;
    data: { nl: Maybe; de: Maybe; en: Maybe; pending?: Maybe };
    placeholder: string;
    label?: string;
    hint?: string;
    hideInput?: boolean;
}

const languages = { nl: 'Nederlands', de: 'Duits', en: 'Engels' };
/**
 * The language input component can be used to create a field that is used for translation stuff.
 * The displayName needs to be lowercase. It will be formatted according to the design.
 *
 */
const TranslationInput: FC<React.PropsWithChildren<Props>> = ({
    name,
    data,
    placeholder,
    hideInput = false,
    label,
    hint,
    ...props
}) => {
    const { pending, nl } = data;
    const { formatMessage } = useIntl();
    return (
        <Container>
            {Object.entries(languages).map(
                ([short, full], i) =>
                    short === 'nl' && (
                        <StyledFormGroup key={i}>
                            <LabelBox>
                                <StyledLabel>{full}</StyledLabel>
                            </LabelBox>
                            <Box width={1} my="auto">
                                <TextInput
                                    type="textarea"
                                    placeholder={
                                        (nl && !pending) || pending
                                            ? formatMessage({
                                                  defaultMessage: 'Er is nog geen vertaling.',
                                              })
                                            : formatMessage({
                                                  defaultMessage: 'Er is nog geen tekst ingevuld.',
                                              })
                                    }
                                    value={data[short as keyof typeof data] ?? ''}
                                    disabled
                                />
                            </Box>
                        </StyledFormGroup>
                    )
            )}
            {!hideInput && (
                <InputFormGroup>
                    <LabelBox>
                        <Label htmlFor={name}>
                            {label ? <span>{label}</span> : <FormattedMessage defaultMessage="Nieuwe tekst" />}
                        </Label>
                        <FieldSetHint>
                            {hint ? (
                                <span>{hint}</span>
                            ) : (
                                <span>
                                    <FormattedMessage defaultMessage="Wij verzorgen de vertaling binnen enkele dagen" />
                                </span>
                            )}
                        </FieldSetHint>
                        {((nl && !pending) || pending) && <TranslationStatus isTranslated={!!nl && pending === null} />}
                    </LabelBox>

                    <Box width={1}>
                        <TextInputField id={name} name={name} placeholder={placeholder} type="textarea" {...props} />
                    </Box>
                </InputFormGroup>
            )}
            {Object.entries(languages).map(
                ([short, full], i) =>
                    short !== 'nl' && (
                        <StyledFormGroup key={i}>
                            <LabelBox>
                                <StyledLabel>{full}</StyledLabel>
                            </LabelBox>
                            <Box width={1} my="auto">
                                <TextInput
                                    type="textarea"
                                    placeholder={
                                        (nl && !pending) || pending
                                            ? formatMessage({
                                                  defaultMessage: 'Er is nog geen vertaling.',
                                              })
                                            : formatMessage({
                                                  defaultMessage: 'Er is nog geen tekst ingevuld.',
                                              })
                                    }
                                    value={data[short as keyof typeof data] ?? ''}
                                    disabled
                                />
                            </Box>
                        </StyledFormGroup>
                    )
            )}
        </Container>
    );
};

export default TranslationInput;

const InputFormGroup = styled(FieldSet)`
    margin-top: 3rem;
`;

const StyledFormGroup = styled(FieldSet)`
    :first-of-type {
        margin: 0;
    }
`;

const Container = styled.div`
    ${StyledFormGroup} + ${StyledFormGroup} {
        margin: 1rem 0 0 0;
    }
`;

const StyledLabel = styled(Label)``;
