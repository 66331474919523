import React, { SVGAttributes } from 'react';

export default function (props: SVGAttributes<SVGElement>) {
    return (
        <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g fill="none" fillRule="evenodd">
                <path d="M-430-839h1920v2107H-430z" />
                <g transform="translate(-174 -15)">
                    <rect stroke="currentColor" x=".5" y=".5" width="206" height="47" rx="4" />
                    <g
                        fillRule="nonzero"
                        strokeWidth="1.5"
                        transform="translate(175 16)"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <rect x=".5013" y="2.5" width="15" height="13" rx="1.5" />
                        <path d="M.5013 6.5h15M4.5013 4V.5M11.5013 4V.5" />
                    </g>
                </g>
            </g>
        </svg>
    );
}
