import { BrandConfig } from './brandConfig';
import { nlCountryOption } from './countryOptions';

const texelConfig: BrandConfig = {
    contactUrl: 'https://www.texel.net/nl/over-texel/over-ons/contact/',
    countryOptions: [nlCountryOption],
    brandName: 'VVV Texel'
};

export default texelConfig;
