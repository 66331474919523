import React, { SVGAttributes } from 'react';
import { useThemeContext } from '../../hooks/useThemeContext';

export function Done(props: SVGAttributes<SVGElement>) {
    const theme = useThemeContext();
    return (
        <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g fill="none" fillRule="evenodd">
                <circle fill={theme.colors.positive['40']} cx="8" cy="8" r="8" />
                <path d="M4.5 7.387l2.792 2.792 4.694-4.804" stroke={theme.colors.neutral['0']} strokeWidth="2" />
            </g>
        </svg>
    );
}
