import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Done } from '@oberoninternal/travelbase-ds/components/figure/Done';
import { Pending } from '@oberoninternal/travelbase-ds/components/figure/Pending';
import { getFontCss } from '@oberoninternal/travelbase-ds/constants/theme';
import { FormattedMessage } from 'react-intl';

interface Props {
    isTranslated: boolean;
}

const TranslationStatus: FC<React.PropsWithChildren<Props>> = ({ isTranslated }) => (
    <Container>
        {isTranslated ? <Done /> : <Pending />}
        <Explanation>
            {isTranslated ? (
                <FormattedMessage defaultMessage="is vertaald" />
            ) : (
                <FormattedMessage defaultMessage="wordt vertaald" />
            )}
        </Explanation>
    </Container>
);

export default TranslationStatus;

const Container = styled.div`
    height: 1.6rem;
    margin: 1rem 0;
    display: flex;
    align-items: center;
`;

const Explanation = styled.p`
    padding-left: 0.5rem;
    ${({ theme }) => getFontCss(theme.fontSizes.body.tiny)};
    color: ${({ theme }) => theme.colors.neutral[40]};
`;
