import React, { HTMLAttributes } from 'react';

export default function (props: HTMLAttributes<SVGElement>) {
    return (
        <svg width="26" height="26" {...props}>
            <g transform="translate(1 1)" stroke="#091923" strokeWidth="1.6" fill="none" fillRule="evenodd">
                <circle cx="12" cy="12" r="12" />
                <path d="M12 18.5v-9M12 7.5v-2" />
            </g>
        </svg>
    );
}
