import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { Label, LabelVariant } from '@oberoninternal/travelbase-ds/components/primitive/Label';

interface Props {
    label?: ReactNode;
    id?: string;
    variant?: LabelVariant;
    className?: string;
}

const SidebarField: FC<React.PropsWithChildren<Props>> = ({ label, variant = 'regular', children, id, ...props }) => (
    <Container variant={variant} {...props}>
        {label && (
            <Label variant={variant} htmlFor={id}>
                {label}
            </Label>
        )}
        {children}
    </Container>
);

export default SidebarField;

const Container = styled.div<{ variant: LabelVariant }>`
    margin-top: 2.4rem;

    > span {
        display: block;
    }

    > * + * {
        margin-top: ${({ variant }) => {
            switch (variant) {
                case 'large':
                    return '1.6rem';
                case 'small':
                    return '0.4rem';
                default:
                    return '1.2rem';
            }
        }};
    }
`;
