import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import dateTextFormat from '@oberoninternal/travelbase-ds/constants/dateTextFormat';
import format from 'date-fns/format';
import gql from 'graphql-tag';
import React, { memo } from 'react';
import { areEqual, ListChildComponentProps } from 'react-window';
import styled from 'styled-components/macro';
import { getDateOpts } from '../../constants/dateOpts';
import euroFormat from '../../constants/euroFormat';
import { UnreachableCaseError } from '../../entities/UnreachableCaseError';
import { BalanceItemFragment } from '../../generated/graphql';
import { CreateRow } from '../../hooks/useBigList';
import parseDate from '@oberoninternal/travelbase-ds/utils/parseDate';
import { FormattedMessage } from 'react-intl';

export const fragment = gql`
    fragment BalanceItem on PartnerBalanceItem {
        id
        amount
        createdAt
        ... on PartnerBookingInvoice {
            invoiceNumber
            downloadPdfUrl
            downloadXlsxUrl
            periodStartDate
            periodEndDate
        }
        ... on PartnerTicketInvoice {
            invoiceNumber
            downloadPdfUrl
            downloadXlsxUrl
            periodStartDate
            periodEndDate
        }
        ... on PartnerBalancePayment {
            batchType
        }
        ... on PartnerBalanceCorrection {
            description
        }
    }
`;

// `StartBalance` could also be a row which consists of the balance of the start of the year.
export type BalanceItemType = BalanceItemFragment | { __typename: 'StartBalance'; amount: number; createdAt: string };

interface BalanceItemProps extends ListChildComponentProps {
    data: {
        createRow: CreateRow;
        balanceItems: BalanceItemType[];
        year: string;
    };
}

const getTypeText = (item: BalanceItemType) => {
    if (!item.__typename) {
        return '';
    }
    switch (item.__typename) {
        case 'PartnerTicketInvoice':
            return 'Afrekening tickets';
        case 'PartnerBookingInvoice':
            return 'Afrekening boekingen';
        case 'PartnerBalancePayment':
            return 'Verrekening';
        case 'PartnerBalanceCorrection':
            return 'Correctie';
        case 'StartBalance':
            return 'Beginsaldo';
        default:
            throw new UnreachableCaseError(item.__typename);
    }
};

const getDescription = (item: BalanceItemType, year: string) => {
    const dateOpts = getDateOpts('nl');
    switch (item.__typename) {
        case 'PartnerTicketInvoice':
        case 'PartnerBookingInvoice': {
            const startDate = format(parseDate(new Date(item.periodStartDate)), dateTextFormat, dateOpts);
            const endDate = format(parseDate(new Date(item.periodEndDate)), dateTextFormat, dateOpts);
            return (
                <>
                    <Body variant="small">
                        <FormattedMessage
                            defaultMessage="Factuur afrekening {type} {startDate} t/m {endDate} (#{invoiceNumber})"
                            values={{
                                type: item.__typename === 'PartnerTicketInvoice' ? 'tickets' : 'boekingen',
                                startDate,
                                endDate,
                                invoiceNumber: item.invoiceNumber,
                            }}
                        />

                        <br />
                    </Body>
                    <Stack>
                        <Body variant="tiny">
                            <FormattedMessage
                                defaultMessage="
                                Downloads:
                                "
                            />
                        </Body>
                        <TextButton size="tiny" as="a" href={item.downloadPdfUrl}>
                            <FormattedMessage defaultMessage="PDF" />
                        </TextButton>
                        &nbsp;
                        <TextButton size="tiny" as="a" href={item.downloadXlsxUrl}>
                            <FormattedMessage defaultMessage="Excel" />
                        </TextButton>
                    </Stack>
                </>
            );
        }
        case 'PartnerBalancePayment': {
            switch (item.batchType) {
                case 'DEBIT':
                    return 'Incasso';
                case 'CREDIT':
                    return 'Krediet';
                case 'MANUAL':
                    return 'Krediet';
                default:
                    return '';
            }
        }
        case 'PartnerBalanceCorrection':
            return item.description;
        case 'StartBalance':
            return `Dit is het beginsaldo op ${format(parseDate(new Date(year)), dateTextFormat, dateOpts)}`;
        default:
            return '';
    }
};

const BalanceItem = memo(({ style, index, data: { createRow, balanceItems, year } }: BalanceItemProps) => {
    const dateOpts = getDateOpts('nl');
    const balanceItem = balanceItems[index];

    if (!balanceItem) {
        return null;
    }

    const date = format(new Date(balanceItem.createdAt) ?? new Date(), dateTextFormat, dateOpts);

    const type = getTypeText(balanceItem);
    const amount = euroFormat(balanceItem.amount);
    const description = getDescription(balanceItem, year);

    // compute balance up until this row
    const balance = euroFormat(balanceItems.slice(0, index + 1).reduce<number>((acc, next) => acc + next.amount, 0));

    return createRow(undefined, style, [date, type, description, amount, balance]);
}, areEqual);

export default BalanceItem;

const Stack = styled.div`
    display: flex;
    align-items: center;
    > * + * {
        margin-left: ${({ theme }) => theme.spacing['20_Tiny']};
    }
`;
