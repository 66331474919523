import gql from 'graphql-tag';
import isEqual from 'lodash/isEqual';
import React, { FC, memo } from 'react';
import { ListOnItemsRenderedProps } from 'react-window';
import { AllotmentsBookingFragment, AllotmentsLockoutFragment, PricesQueryVariables } from '../../generated/graphql';
import { useAllotmentProps } from '../../hooks/useAllotmentProps';
import Allotment from '@oberoninternal/travelbase-ds/components/calendar/Allotment';
import { isBefore } from 'date-fns';
import { getPosition } from '../../utils/getPosition';
import { DocumentNode } from 'graphql';
import styled from 'styled-components/macro';
import propsAreEqual from '@oberoninternal/travelbase-ds/utils/propsAreEqual';
import shouldHideBooking from '../../utils/shouldHideBooking';

export const allotmentsFragment = gql`
    fragment AllotmentsLockout on AllotmentLockout {
        id
        type
        comment
        source
        startDate
        endDate
        isDragging @client
        isActive @client
        isHovering @client
    }

    fragment AllotmentsBooking on Booking {
        id
        totalPrice
        customer {
            id
            firstName
            lastName
        }
        status
        startDate: arrivalDate
        endDate: departureDate
        isActive @client
        isHovering @client
    }
`;

interface Props {
    lockouts: AllotmentsLockoutFragment[];
    bookings: AllotmentsBookingFragment[];
    itemsRendered: ListOnItemsRenderedProps;
    variables?: PricesQueryVariables;
    document: DocumentNode;
    columnWidth: number;
}

const Allotments: FC<React.PropsWithChildren<Props>> = memo(
    ({ lockouts, bookings, itemsRendered, variables, document, columnWidth }) => {
        const allotmentProps = useAllotmentProps(document, variables);

        // the type is (needlessly) specified here because my IDE does not understand memo's typing
        const allotments: Array<AllotmentsLockoutFragment | AllotmentsBookingFragment> = [
            ...lockouts,
            ...bookings.filter(({ status }) => !shouldHideBooking(status)),
        ];

        return (
            <>
                {allotments.map(allotment => {
                    const { startIndex, endIndex } = getPosition(allotment, itemsRendered);

                    return (
                        <StyledAllotment
                            key={allotment.id}
                            {...allotmentProps(allotment)}
                            style={{
                                position: 'absolute',
                                top: 96,
                                left: 24 + startIndex * columnWidth,
                                width: (endIndex - startIndex - 1) * columnWidth - 5,
                            }}
                            disabled={isBefore(new Date(allotment.endDate), new Date())}
                            sticky
                        />
                    );
                })}
            </>
        );
    },
    propsAreEqual<Props>((prev, next) => {
        const prevPositions = [
            prev.lockouts.map(rule => getPosition(rule, prev.itemsRendered)),
            prev.bookings.map(rule => getPosition(rule, prev.itemsRendered)),
        ];
        const nextPositions = [
            next.lockouts.map(rule => getPosition(rule, next.itemsRendered)),
            next.bookings.map(rule => getPosition(rule, next.itemsRendered)),
        ];

        return isEqual(prevPositions, nextPositions);
    }, 'deep')
);

const StyledAllotment = styled(Allotment)`
    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        transform: translateY(1rem);
    }
`;

export default Allotments;
