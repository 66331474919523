import React, { FC } from 'react';
import styled from 'styled-components/macro';

interface Props {
    first?: boolean;
}

const PickerEntryContainer: FC<React.PropsWithChildren<Props>> = ({ children, first }) => (
    <Container first={first}>{children}</Container>
);

export default PickerEntryContainer;

const Container = styled.div<{ first?: boolean }>`
    height: ${({ theme }) => theme.heights.unitNav}px;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    position: relative;

    :before {
        content: '';
        visibility: ${({ first }) => (first ? 'visible' : 'hidden')};
        opacity: ${({ first }) => (first ? '1' : '0')};
        transition: visibility 0s, opacity 250ms;
        position: absolute;
        left: 0;
        width: 0.4rem;
        height: 100%;
        top: 0;
        background: ${({ theme }) => theme.colors.primary[40]};
    }
`;
