import gql from 'graphql-tag';
import React, { FC } from 'react';
import { NotFoundError } from '../../../../entities/NotFoundError';
import { useApproveAgreementMutation, useAgreementsQuery } from '../../../../generated/graphql';
import Loading from '../../../organisms/Loading';
import { ActivityParams } from '../../../../entities/ActivityParams';
import AgreementsForm, { AgreementType } from '../../../molecules/AgreementForm';
import { MutationFunction } from '@apollo/client';

export const query = gql`
    query Agreements($activitySlug: String!) {
        activity(slug: $activitySlug) {
            id
            activityAgreements {
                ...Agreements
            }
        }
    }
`;

export const fragment = gql`
    fragment Agreements on ActivityAgreement {
        id
        agreement {
            name
            commissionPercentage
            agreementUrl
        }
        startDate
        endDate
        approvalDate
    }
`;

export const mutation = gql`
    mutation ApproveAgreement($id: ID!) {
        approveActivityAgreement(input: { activityAgreementId: $id }) {
            activityAgreement {
                ...Agreements
            }
        }
    }
`;

const Agreements: FC<React.PropsWithChildren<ActivityParams>> = ({ activitySlug }) => {
    const { data, loading } = useAgreementsQuery({ variables: { activitySlug } });
    const [mutate] = useApproveAgreementMutation();
    if (loading) {
        return <Loading />;
    }

    if (!data || !data.activity) {
        throw new NotFoundError();
    }

    const {
        activity: { activityAgreements: agreements },
    } = data;

    return <AgreementsForm mutate={mutate as MutationFunction} agreements={agreements as AgreementType[]} />;
};

export default Agreements;
