import * as Yup from 'yup';
import createWeekdayRecord from '../utils/createWeekdayRecord';
import { IntlShape } from 'react-intl';

const operatingHoursValidation = (intl: IntlShape) =>
    Yup.object().shape(
        createWeekdayRecord(
            Yup.array()
                .of(
                    Yup.object().shape({
                        timeOpen: Yup.string().required(intl.formatMessage({ defaultMessage: 'Vul de tijd in' })),
                        timeClosed: Yup.string().required(intl.formatMessage({ defaultMessage: 'Vul de tijd in' })),
                    })
                )
                .nullable()
        )
    );

export default operatingHoursValidation;
