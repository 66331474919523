import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import Allotment, {
    AllotmentPart,
    AllotmentProps,
    PLACEHOLDER_ID,
} from '@oberoninternal/travelbase-ds/components/calendar/Allotment';
import AllotmentIndicator, {
    AllotmentIndicatorContainer,
} from '@oberoninternal/travelbase-ds/components/calendar/AllotmentIndicator';
import Day, { Container as DayContainer } from '@oberoninternal/travelbase-ds/components/calendar/Day';
import Toast from '@oberoninternal/travelbase-ds/components/feedback/Toast';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import useWeekdays from '@oberoninternal/travelbase-ds/hooks/useWeekdays';
import propsAreEqual from '@oberoninternal/travelbase-ds/utils/propsAreEqual';
import { Box, Flex } from '@rebass/grid';
import addDays from 'date-fns/addDays';
import addWeeks from 'date-fns/addWeeks';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import endOfDay from 'date-fns/endOfDay';
import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import isSameMonth from 'date-fns/isSameMonth';
import isWithinInterval from 'date-fns/isWithinInterval';
import startOfDay from 'date-fns/startOfDay';
import startOfToday from 'date-fns/startOfToday';
import eachWeekOfInterval from 'date-fns/eachWeekOfInterval';

import React, {
    DOMAttributes,
    FC,
    memo,
    MutableRefObject,
    RefObject,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import Confetti from 'react-confetti';
import { useInView } from 'react-intersection-observer';
import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import dateFormat from '../../constants/dateFormat';
import { getDateOpts } from '../../constants/dateOpts';
import { useSidebar } from '../../context/sidebar';
import { AllotmentType } from '../../entities/AllotmentType';
import {
    AvailabilityDocument,
    AvailabilityMetaDataFragment,
    AvailabilityQueryVariables,
    PriceColumnAllotmentFragment,
    PricesRowVisibilityFragment,
} from '../../generated/graphql';
import { useToast } from '../../hooks/useToast';
import { getBulkWizardStartDate, getShouldShowToast } from '../../utils/primarypricing';
import Forbidden from '../atoms/Forbidden';
import { FormattedMessage } from 'react-intl';

export interface MonthProps extends AvailabilityMetaDataFragment {
    start: Date;
    events: AllotmentType[];
    allotments: PriceColumnAllotmentFragment[];
    allotmentProps: (allotment: AllotmentType) => AllotmentProps;
    lockoutCreationProps: DOMAttributes<HTMLDivElement>;
    onLoadMore?: () => void;
    isMultiple: boolean;
    lastAllotmentDate?: string;
    variables: AvailabilityQueryVariables;
    rowVisibility: PricesRowVisibilityFragment | null;
    keysPressed: MutableRefObject<string[]>;
    lastFocused: MutableRefObject<string>;
}

interface Part {
    parent: AllotmentType;
    start: Date;
    end: Date;
    type: AllotmentPart;
}

type Left = number;
type Width = number;

const getBookingPosition = (dayIndex: number, daysCount: number, part: AllotmentPart): [Left, Width] => {
    let left = (dayIndex / 7) * 100;
    let width = (daysCount / 7) * 100;

    switch (part) {
        case 'start':
            left += 3;
            width -= 3;
            break;
        case 'single':
            left += 3;
            width -= 1;
            break;
        case 'end':
            if (dayIndex === 0 && daysCount === 0) {
                width = 2;
            }
            width += 2;
            break;
        default:
            break;
    }

    return [left, width];
};

// 🤫
const KONAMI_CODE = [
    'ArrowUp',
    'ArrowUp',
    'ArrowDown',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'ArrowLeft',
    'ArrowRight',
    'b',
    'a',
];

const computeParts = (events: AllotmentType[], monthInterval: Interval) =>
    events
        .map((parent): Part[] => {
            const period = { start: new Date(parent.startDate), end: new Date(parent.endDate) };
            if (period.start > period.end) {
                return [];
            }
            return eachWeekOfInterval(period, getDateOpts('nl')).map((start): Part => {
                // first step: check if part is inside current month, if not, correct it
                let end = endOfWeek(start, getDateOpts('nl'));

                // correct dates that are outside of our month
                if (!isWithinInterval(start, monthInterval) && isWithinInterval(end, monthInterval)) {
                    // eslint-disable-next-line no-param-reassign
                    start = new Date(monthInterval.start);
                }
                if (!isWithinInterval(end, monthInterval) && isWithinInterval(start, monthInterval)) {
                    end = new Date(monthInterval.end);
                }

                const interval = { start, end };

                // second step: determine which part to render, and correct dates to week start/end
                if (isWithinInterval(period.start, interval) && isWithinInterval(period.end, interval)) {
                    return {
                        type: 'single',
                        start: period.start,
                        end: period.end,
                        parent,
                    };
                }
                if (isWithinInterval(period.start, interval) && !isWithinInterval(period.end, interval)) {
                    return {
                        type: 'start',
                        start: period.start,
                        end: addDays(end, 1),
                        parent,
                    };
                }
                if (!isWithinInterval(period.start, interval) && isWithinInterval(period.end, interval)) {
                    return {
                        type: 'end',
                        start,
                        end: period.end,
                        parent,
                    };
                }
                return {
                    type: 'between',
                    start,
                    end: addDays(end, 1),
                    parent,
                };
            });
        })
        .reduce((prev, curr) => [...prev, ...curr], []);

const scrollToRef = (ref: RefObject<HTMLDivElement>) => {
    if (ref.current && ref.current.id === 'currentMonth') {
        window.scrollTo(0, ref.current.offsetTop - 100);
    }
};

const Month: FC<React.PropsWithChildren<MonthProps>> = memo(
    ({
        events,
        maxAllotment,
        datePricingStartDate,
        datePricingEndDate,
        lastDatePricingDate,
        allotments,
        start,
        lockoutCreationProps,
        allotmentProps,
        onLoadMore,
        keysPressed,
        lastFocused,
        variables,
        isMultiple,
        showAllotmentLockouts,
    }) => {
        const { pathname } = useLocation();
        const end = endOfMonth(start);
        const allParts = useMemo(() => computeParts(events, { start, end }), [end, events, start]);
        const weeks = eachWeekOfInterval({ start, end }, getDateOpts('nl'));
        const weekdays = useWeekdays();
        const dayHasEvent = useCallback(
            (date: Date) =>
                events.some(event => {
                    const period = {
                        start: startOfDay(new Date(event.startDate)),
                        end: addDays(endOfDay(new Date(event.endDate)), -1),
                    };
                    if (period.start > period.end) {
                        return false;
                    }
                    return isWithinInterval(date, period);
                }),
            [events]
        );

        const [, dispatch] = useSidebar();

        // determine whether a next year needs to be loaded by observing the last week of a year
        const [lastWeekRef, lastWeekIsVisible] = useInView({ triggerOnce: true });
        const shouldLoadMore = useRef(true);

        const bulkDate = getBulkWizardStartDate(datePricingStartDate, datePricingEndDate, lastDatePricingDate);
        const [pricelessRef, , entry] = useInView();
        const shouldShowToast = getShouldShowToast(datePricingStartDate, datePricingEndDate, lastDatePricingDate);

        const monthRef = useRef<HTMLDivElement | null>(null);

        const containerRefs = useCallback(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (node: any) => {
                monthRef.current = node;
                if (shouldShowToast && bulkDate && isSameMonth(bulkDate, start)) {
                    pricelessRef(node);
                }
            },
            [shouldShowToast, bulkDate, start, pricelessRef]
        );

        useLayoutEffect(() => scrollToRef(monthRef), []);

        useEffect(() => {
            if (lastWeekIsVisible && onLoadMore && shouldLoadMore.current) {
                onLoadMore();
                shouldLoadMore.current = false;
            }
        }, [lastWeekIsVisible, onLoadMore]);

        const [showToast, setShowToast] = useState(false);

        useEffect(() => {
            if (entry) {
                const {
                    isIntersecting,
                    boundingClientRect: { y },
                } = entry;

                // depending on whether the month is intersecting show or hide the toast
                if (isIntersecting && y > 0 && shouldShowToast && !showToast) {
                    setShowToast(() => true);
                }

                if (!isIntersecting && y > 0 && showToast) {
                    setShowToast(() => false);
                }
            }
        }, [entry, shouldShowToast, showToast]);

        useToast(
            showToast ? (
                <Toast
                    variant="info"
                    title={<FormattedMessage defaultMessage="Deze periode heeft nog geen prijzen" />}
                    actions={
                        <Button
                            as={Link}
                            to={{
                                pathname: `${pathname}/wizard`,
                                // the start of the wizard is the day after the last persisted allotment
                                state: { type: 'availability' },
                            }}
                        >
                            <FormattedMessage defaultMessage="Instellen" />
                        </Button>
                    }
                >
                    <Body variant="small">
                        <FormattedMessage defaultMessage="Wil je alvast je nieuwe prijzen instellen?" />
                    </Body>
                </Toast>
            ) : null
        );

        // Used to scroll to the current month;
        const monthId = useMemo(() => (isSameMonth(startOfToday(), start) ? 'currentMonth' : undefined), [start]);

        const [confetti, setConfetti] = useState(false);

        return (
            <Container ref={containerRefs} id={monthId}>
                <Box width={1}>
                    <Name>{format(start, 'MMMM', getDateOpts('nl'))}</Name>
                </Box>

                <Weekdays>
                    {weekdays
                        .map(date => format(date, 'EEEEEE', getDateOpts('nl')))
                        .map(weekday => (
                            <Weekday key={weekday}>{weekday}</Weekday>
                        ))}
                </Weekdays>
                {weeks.map((startWeek, i) => (
                    <Week key={+startWeek} ref={i === weeks.length - 1 ? lastWeekRef : undefined}>
                        {eachDayOfInterval({
                            start: startWeek,
                            end: endOfWeek(startWeek, getDateOpts('nl')),
                        }).map((day, dayIndex) => {
                            const parts = allParts.filter(curr => isSameDay(day, curr.start));
                            const allotment = allotments.find(current => isSameDay(day, new Date(current.date)));
                            const isOutside = !isWithinInterval(day, { start, end });

                            if (isOutside) {
                                return (
                                    <DayGroup key={+day}>
                                        <Day date={day} outside />
                                    </DayGroup>
                                );
                            }

                            const onEdit = () =>
                                (maxAllotment > 1 || !showAllotmentLockouts) &&
                                dispatch({
                                    type: 'show',
                                    data: {
                                        type: 'ALLOTMENT_SIDEBAR',
                                        allotment,
                                        maxAllotment,
                                        date: format(day, dateFormat),
                                        variables,
                                        document: AvailabilityDocument,
                                    },
                                });

                            const emptyAllotment =
                                maxAllotment === 1 && allotment?.amount === 0 && isAfter(day, new Date());
                            const forbidden = emptyAllotment && !dayHasEvent(day);
                            return (
                                <DayGroup key={+day}>
                                    <StyledDay
                                        date={day}
                                        tabIndex={isMultiple || !showAllotmentLockouts ? 0 : undefined}
                                        onClick={onEdit}
                                        disabled={isBefore(day, startOfToday()) || emptyAllotment}
                                        onFocus={() => {
                                            // eslint-disable-next-line no-param-reassign
                                            lastFocused.current = day.toISOString();
                                        }}
                                        onKeyDown={e => {
                                            keysPressed.current?.push(e.key);

                                            let focusedDay: Date | null = null;
                                            switch (e.key) {
                                                case 'ArrowUp':
                                                case 'w': // for the gamers 🤘😎🤘
                                                case 'k': // for the vim enthusiasts 🤓
                                                    focusedDay = addWeeks(day, -1);
                                                    break;
                                                case 'ArrowDown':
                                                case 's':
                                                case 'j':
                                                    focusedDay = addWeeks(day, 1);
                                                    break;
                                                case 'ArrowRight':
                                                case 'd':
                                                case 'l':
                                                case 'Tab':
                                                    focusedDay = addDays(day, 1);
                                                    break;
                                                case 'ArrowLeft':
                                                case 'a':
                                                case 'h':
                                                    focusedDay = addDays(day, -1);
                                                    break;
                                                case 'Enter':
                                                case ' ': // spacebar
                                                    e.preventDefault();
                                                    onEdit();
                                                    break;
                                                default:
                                                    break;
                                            }

                                            setConfetti(
                                                keysPressed.current
                                                    ?.slice(-KONAMI_CODE.length)
                                                    .every((val, index) => val === KONAMI_CODE[index])
                                            );

                                            if (focusedDay) {
                                                e.preventDefault();
                                                const dayElement = document.querySelector(
                                                    `[data-date="${focusedDay.toISOString()}"]`
                                                );
                                                if (dayElement instanceof HTMLDivElement) {
                                                    dayElement.focus();
                                                }
                                            }
                                        }}
                                        {...lockoutCreationProps}
                                    >
                                        {(maxAllotment > 1 || !showAllotmentLockouts) && (
                                            <AllotmentIndicator
                                                max={maxAllotment}
                                                value={allotment?.amount ?? null}
                                                disabled={!allotment}
                                                nullPlaceholder="..."
                                            />
                                        )}
                                        {forbidden && showAllotmentLockouts && <Forbidden style={{ zIndex: 0 }} />}
                                    </StyledDay>
                                    {parts.map((part, partIndex) => {
                                        const daysCount = differenceInCalendarDays(part.end, part.start);
                                        const [left, width] = getBookingPosition(dayIndex, daysCount, part.type);
                                        return (
                                            <BookingContainer
                                                id={part.parent.id}
                                                key={partIndex}
                                                isMultiple={parts.length > 1 && daysCount === 0}
                                                part={part.type}
                                                left={left}
                                                width={width}
                                            >
                                                <Allotment
                                                    {...allotmentProps(part.parent)}
                                                    id={part.parent.id}
                                                    part={part.type}
                                                    disabled={isBefore(part.end, new Date())}
                                                />
                                            </BookingContainer>
                                        );
                                    })}
                                </DayGroup>
                            );
                        })}
                    </Week>
                ))}
                {confetti && <Confetti style={{ position: 'fixed', left: 0, top: 0 }} />}
            </Container>
        );
    },
    propsAreEqual<MonthProps>({
        events: 'deep',
        allotments: 'deep',
        variables: 'deep',
        rowVisibility: 'skip',
        onLoadMore: 'skip',
        allotmentProps: 'skip',
        start: (prev, next) => +prev === +next,
        lockoutCreationProps: (prev, next) => Object.keys(prev).length === Object.keys(next).length,
    })
);

export default Month;

interface BookingContainerProps {
    id: string;
    left: number;
    width: number;
    part: AllotmentPart;
    isMultiple: boolean;
}

const StyledDay = styled(Day)`
    svg {
        color: ${({ theme }) => theme.colors.neutral['30']};
    }
    && {
        ${AllotmentIndicatorContainer} {
            display: flex;
            ${({ date }) =>
                isBefore(date, startOfToday()) &&
                css`
                    opacity: 0.4;
                `}
        }
    }
`;

const BookingContainer = styled.div<BookingContainerProps>`
    position: absolute;
    bottom: 0;
    left: ${({ left }) => left}%;
    width: ${({ width }) => width}%;
    transition: width 0.25s, border-radius 0.25s;
    right: 0;
    z-index: 1;
    pointer-events: ${({ id }) => (id === PLACEHOLDER_ID ? 'none' : 'unset')};

    ${({ isMultiple, part }) =>
        isMultiple &&
        part === 'end' &&
        css`
            width: 2%;
            > *:first-child {
                border-radius: 0 5px 5px 0;
            }
        `}
`;

const Weekday = styled(Box).attrs({ width: 1 / 7, py: 3 })`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.neutral[50]};
    font-weight: 500;
    letter-spacing: 0.15px;
    text-align: center;
    line-height: 16px;
    text-transform: capitalize;
    height: auto;
    padding: 0;
`;

const Name = styled(Title).attrs({ variant: 'small' })`
    margin: 1.2rem 0;

    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        margin: 0 0 1.2rem;
    }
`;

export const Container = styled(Flex).attrs({
    width: [1, null, 1, 1 / 2, 1 / 3],
    alignItems: 'flex-start',
    p: [null, null, 10, 10],
    flexWrap: 'wrap',
})``;

const DayGroup = styled(Box).attrs({ width: 1 / 7 })``;

const Week = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;

    ${DayGroup} > ${DayContainer}, ${Weekday} {
        border-style: solid;
        border-width: 0;
        border-left-width: 1px;
        border-color: ${({ theme }) => theme.colors.neutral[20]};

        :nth-child(7) {
            border-right-width: 1px;
        }
    }

    ${DayGroup}:nth-child(7n) > ${DayContainer} {
        border-right-width: 1px;
    }
`;

const Weekdays = styled(Week)`
    position: sticky;
    top: 0;
    background: ${({ theme }) => theme.colors.neutral[0]};
    z-index: 2;
`;
