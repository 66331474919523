import { Form, Formik, FormikConfig, FormikProps } from 'formik';
import React, { ReactElement } from 'react';
import { Prompt } from 'react-router-dom';
import ContentWrapper, { ContentWrapperVariant } from '../atoms/ContentWrapper';
import Toast from '@oberoninternal/travelbase-ds/components/feedback/Toast';
import { toast } from 'react-toastify';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import FormActions from '@oberoninternal/travelbase-ds/components/form/FormActions';
import ErrorFocus from '@oberoninternal/travelbase-ds/components/form/ErrorFocus';
import { FormattedMessage, useIntl } from 'react-intl';

/**
 * A higher level abstraction on top of formik that handles general stuff like submission errors,
 * form resets, form submission, unsaved data prompts, etc etc.
 */
export interface FormScreenProps<Values> extends Omit<FormikConfig<Values>, 'onSubmit'> {
    // execute your mutations here, nothing else.
    handleSubmit: (values: Values, resetForm: (nextValues?: Values) => void) => Promise<void>;
    variant?: ContentWrapperVariant;
    skipReset?: boolean;
    skipToast?: boolean;
    bottomChildren?: ((props: FormikProps<Values>) => ReactElement | null) | ReactElement | null;
    // override is needed because we only accept renderprops and nothing else
    children: (props: FormikProps<Values>) => React.ReactNode;
    alwaysPrompt?: boolean;
}

export default function FormScreen<V>({
    handleSubmit,
    children,
    variant = 'info',
    skipReset,
    skipToast,
    bottomChildren,
    alwaysPrompt,
    ...props
}: FormScreenProps<V>) {
    const { formatMessage } = useIntl();
    return (
        <>
            <Formik
                enableReinitialize
                validateOnBlur={false}
                onSubmit={async (values, { resetForm }) => {
                    try {
                        await handleSubmit(values, resetForm);
                        if (!skipToast) {
                            toast(
                                <Toast variant="success" title={formatMessage({ defaultMessage: 'Opgeslagen' })}>
                                    <Body variant="small">
                                        <FormattedMessage defaultMessage="Succesvol opgeslagen" />
                                    </Body>
                                </Toast>,
                                {
                                    autoClose: 3000,
                                }
                            );
                        }
                        if (!skipReset) {
                            resetForm();
                        }
                    } catch (ex) {
                        // eslint-disable-next-line no-console
                        console.error('FormScreen onSubmit failed:', ex);
                    }
                }}
                {...props}
            >
                {formikBag => {
                    const { dirty } = formikBag;

                    return (
                        <>
                            <Form>
                                <Prompt
                                    when={alwaysPrompt ?? dirty}
                                    message="Je hebt je gegevens nog niet opgeslagen, zeker weten?"
                                />
                                <ContentWrapper variant={variant}>{children(formikBag)}</ContentWrapper>
                                {typeof bottomChildren !== 'undefined' ? (
                                    typeof bottomChildren === 'function' ? (
                                        bottomChildren(formikBag)
                                    ) : (
                                        bottomChildren
                                    )
                                ) : (
                                    <FormActions
                                        buttonText={formatMessage({ defaultMessage: 'Publiceren' })}
                                        resetText={formatMessage({ defaultMessage: 'Wijzigingen ongedaan maken' })}
                                        variant={variant}
                                        {...formikBag}
                                    />
                                )}
                            </Form>

                            <ErrorFocus />
                        </>
                    );
                }}
            </Formik>
        </>
    );
}
