import React, { HTMLAttributes } from 'react';

export default function (props: HTMLAttributes<SVGElement>) {
    return (
        <svg width="20" height="21" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g
                transform="translate(1 1)"
                stroke="#00C0E0"
                strokeWidth="2"
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            >
                <circle strokeLinecap="round" cx="13" cy="14" r="5" />
                <path d="M11.875 11.993l2.1 2.1-2.1 2.1M6.114 16.875H1.84c-.678 0-1.227-.56-1.227-1.25V1.875c0-.69.55-1.25 1.227-1.25H8.9c.325 0 .637.132.867.366l2.35 2.393c.23.234.36.552.36.884v2.607" />
            </g>
        </svg>
    );
}
