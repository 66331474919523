import styled from 'styled-components/macro';

export default styled.p`
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.neutral[50]};
    letter-spacing: 0.15px;
    line-height: 16px;

    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        max-width: 22rem;
    }
`;
