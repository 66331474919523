import gql from 'graphql-tag';
import React, { FC, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { PRICE_COLUMN_HEADER_HEIGHT } from '../../../constants/priceRows';
import { TripPricing, TripsDocument, TripsQuery } from '../../../generated/graphql';
import { useLockoutCreationProps } from '../../../hooks/useLockoutCreationProps';
import { usePricingBag } from '../../../hooks/usePricingBag';
import groupTripsByDuration from '../../../utils/groupTripsByDuration';
import { CELL_HEIGHT } from '../../molecules/Cell';
import { TripColumn } from '../../molecules/TripColumn';
import TripInfobar from '../../molecules/TripInfoBar';
import Pricing from '../../organisms/Pricing';
import ToastErrorBoundary from '../../organisms/ToastErrorBoundary';
import { tripsAreActive } from '../../../utils/primarypricing';
import { RentalUnitMetaData } from '../../../utils/getRentalUnitMetaData';

export const query = gql`
    query Trips($unitSlug: String!, $start: Date!, $end: Date!) {
        rentalUnit(slug: $unitSlug) {
            ...Pricing

            tripPricings(startDate: $start, endDate: $end) {
                ...TripColumn
            }

            tripPricingExportUrl
        }
    }
`;

interface Props {
    metaData: RentalUnitMetaData;
}

const Trips: FC<React.PropsWithChildren<Props>> = ({
    metaData: { datePricingStartDate, datePricingEndDate, maxAllotment = 0, showAllotmentLockouts },
}) => {
    const bag = usePricingBag<TripsQuery>(TripsDocument);
    const {
        sidebar,
        sidebar: [, dispatch],
        data,
        variables,
        loadedPeriod,
        loadedPeriods,
        getDayEvents,
    } = bag;

    const rentalUnit = data?.rentalUnit;
    const tripGroups = useMemo(() => groupTripsByDuration(data?.rentalUnit?.tripPricings ?? []), [data]);

    const lockoutCreationProps = useLockoutCreationProps({
        query: TripsDocument,
        maxAllotment,
        rentalUnitId: rentalUnit?.id,
        variables,
        sidebar,
    });

    return (
        <ToastErrorBoundary>
            <Pricing
                bag={bag}
                ColumnComponent={TripColumn}
                maxAllotment={maxAllotment}
                showAllotmentLockouts={showAllotmentLockouts}
                data={{
                    maxAllotment,
                    showAllotmentLockouts,
                    allotments: rentalUnit?.allotments ?? [],
                    loadedPeriod,
                    lockoutCreationProps,
                    durations: [...tripGroups.entries()].map(([, v]) => v[0]?.duration),
                    tripPricings: (rentalUnit?.tripPricings as TripPricing[]) ?? [],
                    loadedPeriods,
                    initialVars: variables,
                    getDayEvents,
                }}
                infobarChildren={
                    <TripInfobar
                        exportUrl={data?.rentalUnit?.tripPricingExportUrl ?? null}
                        tripLabels={[...tripGroups.keys()]}
                        loading={!data}
                        rentalUnitId={rentalUnit?.id ?? null}
                        variables={variables}
                        loadedPeriod={loadedPeriod}
                    />
                }
                onSidebarClose={() => dispatch({ type: 'close' })}
                itemHeight={PRICE_COLUMN_HEADER_HEIGHT + ([...tripGroups.keys()].length + 1) * CELL_HEIGHT + 80}
            />

            {loadedPeriod && !tripsAreActive(datePricingStartDate, datePricingEndDate) && <Redirect to="prices" />}
        </ToastErrorBoundary>
    );
};

export default Trips;
