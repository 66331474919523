import { BrandConfig } from './brandConfig';
import { nlCountryOption } from './countryOptions';

// Placeholder data, as wadden doesn't have its own partner app.
const waddenConfig: BrandConfig = {
    contactUrl: 'https://www.visitwadden.nl/nl/contact',
    countryOptions: [nlCountryOption],
};

export default waddenConfig;
