import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';

export type HintVariant = 'default' | 'danger';

interface Props {
    variant?: HintVariant;
    icon?: ReactNode;
    title: string;
}

const Hint: FC<React.PropsWithChildren<Props>> = ({ title, variant = 'default', icon, children }) => (
    <Container variant={variant}>
        {icon}
        <Content>
            <Title variant="small">{title}</Title>
            {children}
        </Content>
    </Container>
);

const Container = styled.div<Required<Pick<Props, 'variant'>>>`
    padding: 1.6rem 3.2rem;
    background: ${({ variant, theme }) => (variant === 'default' ? theme.colors.primary[5] : theme.colors.warning[0])};
    display: flex;
    flex-direction: row;
    svg {
        min-width: 26px;
    }
`;

const Content = styled.div`
    * + & {
        margin-left: 2.4rem;
    }

    p {
        margin: 0.4rem 0;
        white-space: pre-line;
    }
`;

export default Hint;
