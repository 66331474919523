import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { PartnerParams } from '../../entities/PartnerParams';
import { AccommodationEntryFragment, usePartnerDataQuery } from '../../generated/graphql';
import UnitDetails from '../atoms/UnitDetails';
import FilterMenu, { Entry } from './FilterMenu';
import { useIntl } from 'react-intl';

const FilterUnitMenu = () => {
    const { partnerId } = useParams<PartnerParams>();
    const { data } = usePartnerDataQuery({ variables: { partnerId } });
    const { formatMessage } = useIntl();
    const entries = useMemo(() => {
        const accommodations = data?.partner?.accommodations ?? [];
        return accommodations.map((acco): Entry => {
            const isCompact = acco.rentalUnits.length === 1;
            let entry: Entry | undefined;
            if (isCompact) {
                entry = {
                    thumbnail: <UnitDetails unit={acco.rentalUnits[0]} />,
                    id: `unit-${acco.id}`,
                    fieldValue: acco.rentalUnits[0].id,
                };
            } else {
                entry = {
                    autoSelectChildren: true,
                    thumbnail: acco.name,
                    id: `acco-${acco.id}`,
                    children: acco.rentalUnits.map(
                        (unit): Entry => ({
                            id: `unit-${unit.id}`,
                            fieldValue: unit.id,
                            thumbnail: <UnitDetails unit={unit as AccommodationEntryFragment['rentalUnits'][number]} />,
                        })
                    ),
                };
            }
            return entry;
        });
    }, [data?.partner?.accommodations]);

    if (!data?.partner?.companies.length) {
        return null;
    }

    return (
        <FilterMenu
            title={formatMessage({ defaultMessage: 'Accommodatie' })}
            entries={entries}
            fieldName="rentalUnitIds"
        />
    );
};

export default FilterUnitMenu;
