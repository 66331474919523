import React, { forwardRef, Ref } from 'react';
import { generatePath, NavLinkProps, useRouteMatch } from 'react-router-dom';
import { ActivityParams } from '../../entities/ActivityParams';
import { CompanyPickerEntryFragment } from '../../generated/graphql';
import useActivityParentType from '../../hooks/useActivityParentType';
import ActivityPicker from '../molecules/ActivityPicker';
import { getActivityNavigationRoutes } from '../../utils/routes';
import NavigationWrapper from '../atoms/NavigationWrapper';
import NavigationDesktopMenu from '../atoms/NavigationDesktopMenu';
import { useIntl } from 'react-intl';

export interface ActivityNavigationProps {
    activityPickerEntries: CompanyPickerEntryFragment[];
    className?: string;
}

const ActivityNavigation = (
    { className, activityPickerEntries }: ActivityNavigationProps,
    ref: Ref<HTMLDivElement>
) => {
    const {
        params: { partnerId, ...params },
    } = useRouteMatch<ActivityParams>();
    const activityParentType = useActivityParentType();

    const navigationItems = getActivityNavigationRoutes(activityParentType);
    const activitySlug = params.companySlug ?? params.activitySlug ?? activityPickerEntries[0]?.slug;
    const slugKey = activityParentType === 'company' ? 'companySlug' : 'activitySlug';
    const { locale, formatMessage } = useIntl();
    const pathDependingOnType = (source: string) => {
        if (activityParentType === 'company') {
            return `/${locale}/partner/:partnerId/company/:companySlug/${source}`;
        }
        return `/${locale}/partner/:partnerId/activity/:activitySlug/${source}`;
    };

    return (
        <NavigationWrapper className={className} ref={ref}>
            <ActivityPicker data={activityPickerEntries} />
            <NavigationDesktopMenu
                variant="dark"
                links={navigationItems.map(
                    ({ title: name, source }) =>
                        ({
                            to: generatePath(pathDependingOnType(source), {
                                [slugKey]: activitySlug,
                                partnerId,
                            }),
                            children: formatMessage(name),
                        } as NavLinkProps)
                )}
            />
        </NavigationWrapper>
    );
};

export default forwardRef(ActivityNavigation);
