import { BrandConfig } from './brandConfig';
import { nlCountryOption } from './countryOptions';

const waterlandConfig: BrandConfig = {
    contactUrl: 'https://www.waterlandvanfriesland.nl/nl/organisatie/contact-ons',
    countryOptions: [nlCountryOption],
    brandName: 'Waterland van Friesland',
};

export default waterlandConfig;
