import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Cross from '@oberoninternal/travelbase-ds/components/figure/Cross';
import { Edit } from '@oberoninternal/travelbase-ds/components/figure/Edit';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { Box, Flex } from '@rebass/grid';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import euroFormat from '../../constants/euroFormat';
import { ActivityRateGroupFragment } from '../../generated/graphql';
import TableActions from '../atoms/TableActions';
import TableRowSeperator from '../atoms/TableRowSeperator';
import { getHeaderTexts } from '../pages/activity/Rates';

interface Props {
    rateGroup: ActivityRateGroupFragment;
    handleRemove: (rateGroupId: string) => Promise<void>;
    handleEdit: (rateGroup: ActivityRateGroupFragment) => void;
}

const ActivityRateGroupRow: FC<React.PropsWithChildren<Props>> = ({ rateGroup, handleRemove, handleEdit }) => {
    const { id, name, rates, canBuyTickets, releaseHours, qrScansEnabled } = rateGroup;
    const intl = useIntl();
    const headerTexts = getHeaderTexts(intl);
    return (
        <>
            <tr>
                <td data-label={headerTexts.name}>{name}</td>
                <td data-label={headerTexts.sellsTickets}>
                    {canBuyTickets ? (
                        <FormattedMessage defaultMessage="Ja" />
                    ) : (
                        <FormattedMessage defaultMessage="Nee" />
                    )}
                    {qrScansEnabled ? <FormattedMessage defaultMessage="(QR)" /> : ''}
                </td>
                <td data-label={headerTexts.price}>
                    {rates.map(rate => (
                        <Flex mb={[3, null, 4]} key={rate.id}>
                            <Box width="20rem">
                                <Body>{rate.name}</Body>
                            </Box>
                            <Box>
                                <Body>{euroFormat(rate.price)}</Body>
                            </Box>
                        </Flex>
                    ))}
                </td>
                <td data-label={headerTexts.releaseHours}>
                    {releaseHours ?? <FormattedMessage defaultMessage="Geen" />}
                </td>
                <td data-label={headerTexts.actions}>
                    <TableActions>
                        <TextButton onClick={() => handleEdit(rateGroup)} size="tiny">
                            <Edit />
                            <span>
                                <FormattedMessage defaultMessage="Aanpassen" />
                            </span>
                        </TextButton>
                        <TextButton
                            onClick={() => {
                                if (!window.confirm(`Weet je zeker dat je deze groep (${name}) wilt verwijderen?`)) {
                                    return;
                                }
                                handleRemove(id);
                            }}
                            variant="danger"
                            size="tiny"
                        >
                            <Cross />
                            <span>
                                <FormattedMessage defaultMessage="Verwijderen" />
                            </span>
                        </TextButton>
                    </TableActions>
                </td>
            </tr>
            <tr>
                <td colSpan={5}>
                    <TableRowSeperator />
                </td>
            </tr>
        </>
    );
};

export default ActivityRateGroupRow;
