import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import { Camera } from '@oberoninternal/travelbase-ds/components/figure/Camera';

interface Props extends HTMLAttributes<HTMLImageElement> {
    url?: string | null;
}
const Thumbnail: FC<React.PropsWithChildren<Props>> = ({ url, ...props }) =>
    url ? (
        <StyledThumbnail src={url} {...props} />
    ) : (
        <Placeholder>
            <Camera />
        </Placeholder>
    );

export default Thumbnail;

const StyledThumbnail = styled.img`
    width: 7.2rem;
    height: 4.8rem;
    object-fit: cover;
    border-radius: 0.5rem;
`;

const Placeholder = styled.div`
    min-width: 7.2rem;
    height: 4.8rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.colors.neutral[20]};
`;
