import { AllotmentsBookingFragment, PricesQuery } from '../generated/graphql';
import { DocumentNode } from 'graphql';
import { OperationVariables, useApolloClient } from '@apollo/client';

export const useWriteBooking = <V extends OperationVariables>(query: DocumentNode, variables?: V) => {
    const client = useApolloClient();

    return (booking: AllotmentsBookingFragment) => {
        const data = client.readQuery<PricesQuery>({
            query,
            variables,
        });

        const rentalUnit = data?.rentalUnit;

        if (!rentalUnit) {
            return;
        }

        const bookings: AllotmentsBookingFragment[] = rentalUnit.bookings.map(current =>
            current.id === booking.id ? booking : current
        );

        client.writeQuery<PricesQuery>({
            query,
            data: {
                rentalUnit: {
                    ...rentalUnit,
                    bookings,
                },
            },
            variables,
        });
    };
};
