import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Plus from '@oberoninternal/travelbase-ds/components/figure/Plus';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import Pagehead from '@oberoninternal/travelbase-ds/components/section/Pagehead';
import useMenuState from '@oberoninternal/travelbase-ds/hooks/useMenuState';
import { Box, Flex } from '@rebass/grid';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ActivityParams } from '../../../entities/ActivityParams';
import { NotFoundError } from '../../../entities/NotFoundError';
import {
    ActivityRateGroupFragment,
    UpsertActivityRateGroupInput,
    useActivityRatesQuery,
    useDeleteActivityRateGroupMutation,
    useUpsertActivityRateGroupMutation,
} from '../../../generated/graphql';
import getOptimisticUIRateGroup from '../../../utils/getOptimisticUIRateGroup';
import ContentWrapper from '../../atoms/ContentWrapper';
import Table from '../../atoms/Table';
import TableHeader, { StyledTableHeader } from '../../atoms/TableHeader';
import ActivityRateGroupRow from '../../molecules/ActivityRateGroupRow';
import ActivityRatesSidebar from '../../molecules/ActivityRatesSidebar';
import Loading from '../../organisms/Loading';

export const query = gql`
    query ActivityRates($activitySlug: String!) {
        activity(slug: $activitySlug) {
            ...ActivityRates
        }
    }

    mutation UpsertActivityRateGroup($input: UpsertActivityRateGroupInput!) {
        upsertActivityRateGroup(input: $input) {
            activity {
                ...ActivityRates
            }
        }
    }
    mutation DeleteActivityRateGroup($input: DeleteActivityRateGroupInput!) {
        deleteActivityRateGroup(input: $input) {
            activity {
                ...ActivityRates
            }
        }
    }

    fragment ActivityRates on Activity {
        id
        activityRateGroups {
            ...ActivityRateGroup
        }
    }

    fragment ActivityRateGroup on ActivityRateGroup {
        id
        name
        canBuyTickets
        releaseHours
        qrScansEnabled
        rates {
            ...Rate
        }
    }
`;

export const getHeaderTexts = (intl: IntlShape) => ({
    name: intl.formatMessage({ defaultMessage: 'Tariefgroep' }),
    sellsTickets: intl.formatMessage({ defaultMessage: 'Ticketverkoop' }),
    label: intl.formatMessage({ defaultMessage: 'Label' }),
    price: intl.formatMessage({ defaultMessage: 'Tarief' }),
    actions: intl.formatMessage({ defaultMessage: 'Acties' }),
    releaseHours: intl.formatMessage({ defaultMessage: 'Release uren' }),
});

const Rates: FC<React.PropsWithChildren<unknown>> = () => {
    const { formatMessage } = useIntl();
    const { activitySlug } = useParams<ActivityParams>();
    const { setOpen, menuProps } = useMenuState(false, { closeClickOnOutside: false });
    const { data, loading } = useActivityRatesQuery({ variables: { activitySlug: activitySlug! } });
    const [upsert] = useUpsertActivityRateGroupMutation();
    const [remove] = useDeleteActivityRateGroupMutation();
    const [selectedRateGroup, setSelectedRateGroup] = useState<ActivityRateGroupFragment | undefined>(undefined);

    const intl = useIntl();
    const headerTexts = getHeaderTexts(intl);

    if (loading) {
        return <Loading variant="default" />;
    }

    if (!data || !data.activity) {
        throw new NotFoundError();
    }
    const {
        activity: { activityRateGroups, id: activityId },
    } = data;

    const upsertHandler = async (values: Omit<UpsertActivityRateGroupInput, 'activityId'>) => {
        let newActivityRateGroups: ActivityRateGroupFragment[] = [];
        // if we're editing a rategroup we want to replace the rategroup with a optimistic one until we get a result
        if (values.rateGroupId) {
            newActivityRateGroups = activityRateGroups.map((rateGroup): ActivityRateGroupFragment => {
                if (rateGroup.id === values.rateGroupId) {
                    return getOptimisticUIRateGroup(values, 'ActivityRateGroup');
                }
                return rateGroup;
            });
        } else {
            newActivityRateGroups = [...activityRateGroups, getOptimisticUIRateGroup(values, 'ActivityRateGroup')];
        }

        setOpen(false);

        await upsert({
            variables: {
                input: {
                    activityId,
                    ...values,
                },
            },
            optimisticResponse: {
                __typename: 'PartnerMutation',
                upsertActivityRateGroup: {
                    __typename: 'UpsertActivityRateGroupPayload',
                    activity: {
                        __typename: 'Activity',
                        id: activityId,
                        activityRateGroups: newActivityRateGroups,
                    },
                },
            },
        });
    };

    const removeHandler = async (rateGroupId: string) => {
        await remove({
            variables: { input: { activityId, rateGroupId } },
            optimisticResponse: {
                __typename: 'PartnerMutation',
                deleteActivityRateGroup: {
                    __typename: 'DeleteActivityRateGroupPayload',
                    activity: {
                        __typename: 'Activity',
                        id: activityId,
                        activityRateGroups: activityRateGroups.filter(group => group.id !== rateGroupId),
                    },
                },
            },
        });
    };

    const editHandler = (rateGroup: ActivityRateGroupFragment) => {
        setSelectedRateGroup(rateGroup);
        setOpen(true);
    };

    return (
        <>
            <ContentWrapper>
                <Pagehead title={formatMessage({ defaultMessage: 'Tarieven' })}>
                    <FormattedMessage defaultMessage="Dit zijn de tarieven voor de activiteit." />
                </Pagehead>
                <Box style={{ overflow: 'auto' }}>
                    <StyledTable>
                        <thead>
                            <tr>
                                <TableHeader variant="small">{headerTexts.name}</TableHeader>
                                <TableHeader variant="small">{headerTexts.sellsTickets}</TableHeader>

                                {/* a little table hack because the data of the day and hours column belong together, so we basically just show them as 1 column */}
                                <StyledTableHeader>
                                    <Flex>
                                        <Box width="20rem">
                                            <Label variant="small" as="span">
                                                {headerTexts.label}
                                            </Label>
                                        </Box>
                                        <Label variant="small" as="span">
                                            {headerTexts.price}
                                        </Label>
                                    </Flex>
                                </StyledTableHeader>
                                <TableHeader variant="small">{headerTexts.releaseHours}</TableHeader>
                                <TableHeader variant="small">{headerTexts.actions}</TableHeader>
                            </tr>
                        </thead>
                        <tbody>
                            {activityRateGroups.map(rateGroup => (
                                <ActivityRateGroupRow
                                    key={rateGroup.id}
                                    rateGroup={rateGroup}
                                    handleRemove={removeHandler}
                                    handleEdit={editHandler}
                                />
                            ))}
                            <tr>
                                <td colSpan={5}>
                                    <TextButton
                                        onClick={() => {
                                            // make sure the sidebar is cleaned up when we're adding a new period
                                            setSelectedRateGroup(undefined);
                                            setOpen(true);
                                        }}
                                    >
                                        <Plus />
                                        &nbsp;
                                        <span>
                                            <FormattedMessage defaultMessage="Tariefgroep toevoegen" />
                                        </span>
                                    </TextButton>
                                </td>
                            </tr>
                        </tbody>
                    </StyledTable>
                </Box>
            </ContentWrapper>
            <ActivityRatesSidebar
                rateGroups={activityRateGroups}
                handleUpsert={upsertHandler}
                selectedRateGroup={selectedRateGroup}
                {...menuProps}
            />
        </>
    );
};

export default Rates;

const StyledTable = styled(Table)`
    @media (max-width: ${({ theme }) => theme.mediaQueriesValues.s - 1}px) {
        th {
            display: none;
        }
    }
    @media (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        min-width: 80rem;
    }
`;
