import styled from 'styled-components/macro';
import { Flex, FlexProps } from '@rebass/grid';

const BigTableHeader = styled(Flex).attrs(() => ({ width: 1, alignItems: 'center' } as FlexProps))`
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[20]};
    height: 5.6rem;
    z-index: 1;
    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        position: sticky;
        top: ${({ theme }) => theme.heights.nav}px;
    }
    background: white;
`;

export default BigTableHeader;
