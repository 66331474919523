import Sidebar from '@oberoninternal/travelbase-ds/components/layout/Sidebar';
import addDays from 'date-fns/addDays';
import addYears from 'date-fns/addYears';
import differenceInCalendarYears from 'date-fns/differenceInCalendarYears';
import endOfYear from 'date-fns/endOfYear';
import format from 'date-fns/format';
import getYear from 'date-fns/getYear';
import isWithinInterval from 'date-fns/isWithinInterval';
import startOfToday from 'date-fns/startOfToday';
import startOfYear from 'date-fns/startOfYear';
import differenceInMonths from 'date-fns/differenceInMonths';
import gql from 'graphql-tag';
import mergeWith from 'lodash/mergeWith';
import range from 'lodash/range';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSidebar } from '../../../context/sidebar';
import { UnitParams } from '../../../entities/UnitParams';
import {
    AvailabilityDocument,
    AvailabilityQuery,
    AvailabilityQueryVariables,
    useAvailabilityQuery,
} from '../../../generated/graphql';
import { useLockoutCreationProps } from '../../../hooks/useLockoutCreationProps';
import getActiveEvents from '../../../utils/getActiveEvents';
import getRowVisibility from '../../../utils/getRowVisibility';
import AvailabilityInfobar from '../../molecules/AvailabilityInfobar';
import SidebarContent from '../../molecules/SidebarContent';
import ToastErrorBoundary from '../../organisms/ToastErrorBoundary';
import Year, { SkeletonYear } from '../../organisms/Year';
import dateFormat from '../../../constants/dateFormat';
import { NetworkStatus } from '@apollo/client';

export const query = gql`
    query Availability($unitSlug: String!, $start: Date!, $allotmentStart: Date!, $end: Date!) {
        rentalUnit(slug: $unitSlug) {
            id
            ...AvailabilityMetaData
            allotments(startDate: $allotmentStart, endDate: $end) @connection(key: "allotments") {
                ...PriceColumnAllotment
            }
            bookings(startDate: $start, endDate: $end) {
                ...AllotmentsBooking
            }
            allotmentLockouts(startDate: $start, endDate: $end) {
                ...AllotmentsLockout
            }

            ...PricesRowVisibility
        }
    }

    fragment AvailabilityMetaData on RentalUnit {
        showAllotmentLockouts
        datePricingStartDate
        datePricingEndDate
        maxAllotment
        lastDatePricingDate
    }
`;

const base = startOfYear(new Date());

const Availability = () => {
    const { unitSlug } = useParams<UnitParams>();
    const initialVariables: AvailabilityQueryVariables = useMemo(
        () => ({
            start: format(base, dateFormat),
            allotmentStart: format(base, dateFormat),
            end: format(
                // if we're almost near the end of the current year, we'll just also fetch the next year.
                // this also ensures we can scroll to the correct month properly on render.
                differenceInMonths(endOfYear(new Date()), new Date()) > 3
                    ? endOfYear(base)
                    : endOfYear(addYears(base, 1)),
                dateFormat
            ),
            unitSlug,
        }),
        [unitSlug]
    );
    const [variables, setVariables] = useState(initialVariables);

    const sidebar = useSidebar();
    const [state, dispatch] = sidebar;
    const { hidden, open, data: sidebarData } = state;

    const { data, loading, fetchMore, networkStatus } = useAvailabilityQuery({
        variables: initialVariables,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
    });
    const isLoading = loading && !data;
    const rentalUnit = data?.rentalUnit;

    const {
        maxAllotment = 0,
        datePricingStartDate,
        datePricingEndDate,
        lastDatePricingDate,
        showAllotmentLockouts,
    } = rentalUnit ?? {};
    const isMultiple = (!!maxAllotment && maxAllotment > 1) || !showAllotmentLockouts;
    const rowVisibility = getRowVisibility(data ?? null);
    const years = differenceInCalendarYears(new Date(variables.end), base) + 1;

    const lockoutCreationProps = useLockoutCreationProps({
        variables: initialVariables,
        query: AvailabilityDocument,
        rentalUnitId: data?.rentalUnit?.id,
        maxAllotment,
        sidebar,
        showAllotmentLockouts,
    });

    const keysPressed = useRef<string[]>([]);
    const lastFocused = useRef<string>(startOfToday().toISOString());

    useEffect(() => {
        if (!state.open && !loading) {
            const dayElement = document.querySelector(`[data-date="${lastFocused.current}"]`);
            if (dayElement instanceof HTMLDivElement) {
                dayElement.focus();
            }
        }
    }, [state.open, loading]);

    const onLoadMore = async () => {
        const start = addDays(new Date(variables.end), 1);
        const end = endOfYear(new Date(start));

        await fetchMore({
            variables: {
                ...initialVariables,
                start: format(start, dateFormat),
                end: format(end, dateFormat),
                allotmentStart: format(start, dateFormat),
            },
            updateQuery: (prev, { fetchMoreResult, variables: newVars }): AvailabilityQuery => {
                if (!fetchMoreResult || !prev.rentalUnit) {
                    return prev;
                }

                const newRentalUnit = mergeWith({}, prev.rentalUnit, fetchMoreResult.rentalUnit, (obj, src) =>
                    Array.isArray(obj) ? obj.concat(src) : undefined
                );

                setVariables(newVars ?? initialVariables);
                return { ...prev, rentalUnit: newRentalUnit };
            },
        });
    };

    return (
        <ToastErrorBoundary>
            {!isLoading ? (
                range(years).map(i => {
                    const year = addYears(base, i);

                    const interval: Interval = {
                        start: year,
                        end: endOfYear(year),
                    };

                    const activeEvents = getActiveEvents(
                        !isMultiple ? [...(rentalUnit?.allotmentLockouts ?? []), ...(rentalUnit?.bookings ?? [])] : [],
                        interval
                    );

                    const activeAllotments =
                        rentalUnit?.allotments.filter(allotment =>
                            isWithinInterval(new Date(allotment.date), interval)
                        ) ?? [];

                    // only load the next two years
                    const shouldLoadMore = i === years - 1 && i < 2;

                    return (
                        <Year
                            key={i}
                            showAllotmentLockouts={!!showAllotmentLockouts}
                            yearIndex={getYear(addYears(base, i))}
                            events={activeEvents}
                            allotments={activeAllotments}
                            lockoutCreationProps={lockoutCreationProps}
                            variables={initialVariables}
                            onLoadMore={shouldLoadMore ? onLoadMore : undefined}
                            isLoadingMore={shouldLoadMore && networkStatus === NetworkStatus.fetchMore}
                            rowVisibility={rowVisibility}
                            isMultiple={isMultiple}
                            maxAllotment={maxAllotment}
                            datePricingStartDate={datePricingStartDate}
                            datePricingEndDate={datePricingEndDate}
                            lastDatePricingDate={lastDatePricingDate}
                            keysPressed={keysPressed}
                            lastFocused={lastFocused}
                        />
                    );
                })
            ) : (
                <SkeletonYear />
            )}

            {!isLoading && <AvailabilityInfobar isMultiple={isMultiple} />}

            <Sidebar open={open} hidden={hidden} onClose={() => dispatch({ type: 'close' })}>
                <SidebarContent data={sidebarData} />
            </Sidebar>
        </ToastErrorBoundary>
    );
};

export default Availability;
