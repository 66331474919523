import differenceInDays from 'date-fns/differenceInDays';
import isAfter from 'date-fns/isAfter';
import startOfToday from 'date-fns/startOfToday';
import gql from 'graphql-tag';
import groupBy from 'lodash/groupBy';
import React, { FC, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { ListOnItemsRenderedProps } from 'react-window';
import epoch from '../../../constants/epoch';
import { PRICE_COLUMN_HEADER_HEIGHT, PRICE_COLUMN_ROW_COUNT } from '../../../constants/priceRows';
import { UnitParams } from '../../../entities/UnitParams';
import { PeriodRulesFragment, PricesDocument, PricesQuery, usePricesMetaDataQuery } from '../../../generated/graphql';
import { useLockoutCreationProps } from '../../../hooks/useLockoutCreationProps';
import { usePricesToasts } from '../../../hooks/usePricesToasts';
import { usePricingBag } from '../../../hooks/usePricingBag';
import { RentalUnitMetaData } from '../../../utils/getRentalUnitMetaData';
import getRowVisibility from '../../../utils/getRowVisibility';
import { getBulkWizardStartDate } from '../../../utils/primarypricing';
import { todayIndex } from '../../atoms/Virtualizer';
import { BulkEditForm } from '../../molecules/BulkEditForm';
import { CELL_HEIGHT } from '../../molecules/Cell';
import PeriodRules, { PeriodRuleGroups, PERIOD_RULE_GAP, PERIOD_RULE_HEIGHT } from '../../molecules/PeriodRules';
import PricesInfobar from '../../molecules/PricesInfobar';
import PriceColumn from '../../organisms/PriceColumn';
import Pricing from '../../organisms/Pricing';
import ToastErrorBoundary from '../../organisms/ToastErrorBoundary';
import { useIntl } from 'react-intl';

export const STEP = 28; // 4 weeks

export const query = gql`
    query Prices($unitSlug: String!, $start: Date!, $end: Date!) {
        rentalUnit(slug: $unitSlug) {
            ...Pricing

            datePricings(startDate: $start, endDate: $end) {
                ...PriceColumnPricing
            }

            datePricingModifiers(startDate: $start, endDate: $end) {
                ...PeriodRules
            }
        }
    }

    query PricesMetaData($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            ...PricesRowVisibility
        }
    }

    fragment PricesRowVisibility on RentalUnit {
        showArrivalAllowedRow
        showBaseStayPriceRow
        showDepartureAllowedRow
        showExtraPersonPriceRow
        showMinimumStayDurationRow
        showMinimumStayPriceRow
        showNightPriceRow
        showWeekPriceRow
    }
`;

const groupRulesByDuration = (rules: PeriodRulesFragment[]): PeriodRuleGroups =>
    new Map(
        Object.entries(
            groupBy(rules, ({ minDuration, maxDuration }) =>
                minDuration === maxDuration ? minDuration.toString() : `${minDuration}-${maxDuration}`
            )
        ).sort(([, [prevModifier]], [, [nextModifier]]) => prevModifier.minDuration - nextModifier.minDuration)
    );

interface Props {
    metaData: RentalUnitMetaData;
}

const Prices: FC<React.PropsWithChildren<Props>> = ({
    metaData,
    metaData: {
        datePricingStartDate,
        datePricingEndDate,
        maxAllotment = 0,
        id,
        showAllotmentLockouts,
        lastDatePricingDate,
    },
}) => {
    const bag = usePricingBag<PricesQuery>(PricesDocument);
    const { unitSlug } = useParams<UnitParams>();
    const { state: locationState } = useLocation<{ start?: Date }>();
    const { data: pricesMetaData } = usePricesMetaDataQuery({
        variables: {
            unitSlug,
        },
    });
    const {
        data,
        loadedPeriods,
        loadedPeriod,
        variables,
        sidebar,
        sidebar: [state, dispatch],
        columnWidth,
        getDayEvents,
    } = bag;

    const ruleGroups = useMemo(
        () =>
            groupRulesByDuration(
                data?.rentalUnit?.datePricingModifiers.filter(rule =>
                    isAfter(new Date(rule.endDate), startOfToday())
                ) ?? []
            ),
        [data]
    );

    const { formatMessage } = useIntl();

    const rowVisibility = useMemo(() => getRowVisibility(pricesMetaData), [pricesMetaData]);
    // note: performance impact
    const [itemsRendered, setItemsRendered] = useState<ListOnItemsRenderedProps | null>(null);

    usePricesToasts(
        metaData,
        rowVisibility,
        itemsRendered,
        data?.rentalUnit?.datePricings,
        variables?.end,
        variables?.start
    );
    const innerContainerRef = useRef<HTMLDivElement>(null);
    const initialIndex = useMemo(
        () =>
            locationState?.start
                ? differenceInDays(new Date(locationState.start), epoch)
                : datePricingStartDate && isAfter(datePricingStartDate, startOfToday())
                ? differenceInDays(datePricingStartDate, epoch)
                : todayIndex,
        [datePricingStartDate, locationState]
    );

    const lockoutCreationProps = useLockoutCreationProps({
        query: PricesDocument,
        maxAllotment,
        rentalUnitId: id,
        variables,
        sidebar,
        showAllotmentLockouts,
    });
    const rowCount = useMemo(
        () => (rowVisibility ? Object.values(rowVisibility).filter(show => show).length : PRICE_COLUMN_ROW_COUNT),
        [rowVisibility]
    );

    return (
        <ToastErrorBoundary>
            <BulkEditForm
                data={state.data?.type === 'BULK_SIDEBAR' ? state.data : undefined}
                datePricingStartDate={datePricingStartDate}
            >
                {({ resetForm, values, initialValues, dirty }) => {
                    const bulkEditResetHandler = (closeSidebar?: boolean) => {
                        if (dirty && window.confirm(formatMessage({ defaultMessage: 'Zeker weten?' }))) {
                            resetForm();
                        }
                        if (closeSidebar) {
                            dispatch({ type: 'close' });
                        }
                    };
                    return (
                        <Pricing
                            ColumnComponent={PriceColumn}
                            maxAllotment={maxAllotment}
                            showAllotmentLockouts={showAllotmentLockouts}
                            data={{
                                getDayEvents,
                                datePricings: data?.rentalUnit?.datePricings ?? [],
                                allotments: data?.rentalUnit?.allotments ?? [],
                                initialVars: variables,
                                values,
                                maxAllotment,
                                loadedPeriod,
                                rentalUnitId: id ?? '',
                                initialValues,
                                lockoutCreationProps,
                                rowVisibility,
                                datePricingStartDate,
                                datePricingEndDate,
                                loadedPeriods,
                                showAllotmentLockouts,
                            }}
                            bag={bag}
                            infobarChildren={
                                <PricesInfobar
                                    resetForm={bulkEditResetHandler}
                                    periodLabels={[...ruleGroups.keys()]}
                                    rentalUnitId={id}
                                    pricesVars={variables}
                                    rowVisibility={rowVisibility ?? null}
                                    withWizardButton={
                                        !!getBulkWizardStartDate(
                                            datePricingStartDate,
                                            datePricingEndDate,
                                            lastDatePricingDate
                                        )
                                    }
                                />
                            }
                            onSidebarClose={() => bulkEditResetHandler(true)}
                            itemHeight={
                                PRICE_COLUMN_HEADER_HEIGHT +
                                PERIOD_RULE_GAP * 8 +
                                CELL_HEIGHT * rowCount +
                                (PERIOD_RULE_HEIGHT + PERIOD_RULE_GAP) * [...ruleGroups.keys()].length +
                                // 'toevoegen' button
                                CELL_HEIGHT
                            }
                            onItemsRendered={setItemsRendered}
                            innerRef={innerContainerRef}
                            initialIndex={initialIndex}
                        >
                            {itemsRendered &&
                                innerContainerRef.current &&
                                createPortal(
                                    <PeriodRules
                                        columnWidth={columnWidth}
                                        ruleGroups={ruleGroups}
                                        itemsRendered={itemsRendered}
                                        pricesVars={variables}
                                        rowVisibility={rowVisibility}
                                    />,
                                    innerContainerRef.current
                                )}
                        </Pricing>
                    );
                }}
            </BulkEditForm>

            {loadedPeriod && !datePricingStartDate && <Redirect to="trips" />}
        </ToastErrorBoundary>
    );
};

export default Prices;
