import gql from 'graphql-tag';
import { PricingColumnData } from '../organisms/Pricing';
import { TripColumnFragment, TripPricing, TripsDocument } from '../../generated/graphql';
import React, { memo, useMemo } from 'react';
import PricingColumnContainer from './PricingColumnContainer';
import PricingColumnAllotment from './PricingColumnAllotment';
import { ListChildComponentProps } from 'react-window';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import startOfDay from 'date-fns/startOfDay';
import isAfter from 'date-fns/isAfter';
import epoch from '../../constants/epoch';
import Cell from './Cell';
import euroFormat from '../../constants/euroFormat';
import styled from 'styled-components/macro';
import { PRICE_COLUMN_ROW_COUNT } from '../../constants/priceRows';
import SkeletonCell from './SkeletonCell';
import isEqual from 'lodash/isEqual';

export const fragment = gql`
    fragment TripColumn on TripPricing {
        duration
        id
        price
        date
    }
`;

export type TripGroups = Map<string, TripColumnFragment[]>;

interface TripColumnData extends PricingColumnData {
    durations: number[];
    tripPricings: TripPricing[];
}

interface Props extends ListChildComponentProps {
    data: TripColumnData;
}

const getDate = (index: number): Date => addDays(epoch, index);
const isLoading = (loadedPeriods: Interval[], date: Date): boolean =>
    !loadedPeriods.some(interval => isWithinInterval(date, interval));
const dateToString = (date: Date): string => format(date, 'yyyy-MM-dd');
type ColumnPricingMap = { [duration: number]: TripPricing };
const getColumnPricings = (date: string, pricings: TripPricing[]): ColumnPricingMap => {
    // NOTE: for performance optimisation, this function assumes that the pricings are already sorted by (date,duration)
    // this sorting should be done by the API
    const result: ColumnPricingMap = {};
    let found = false;
    for (const pricing of pricings) {
        if (pricing.date === date) {
            result[pricing.duration] = pricing;
            found = true;
        } else if (found) {
            // pricings are sorted by date, so we won't expect anymore at this point
            break;
        }
    }
    return result;
};

export const TripColumn = memo(
    ({
        data: {
            maxAllotment,
            allotments,
            lockoutCreationProps,
            tripPricings,
            durations,
            loadedPeriods,
            initialVars,
            getDayEvents,
        },
        style,
        index,
    }: Props) => {
        const date = getDate(index);
        const dateString = dateToString(date);
        const columnPricings = useMemo(() => getColumnPricings(dateString, tripPricings), [dateString, tripPricings]);

        const lastTrip = tripPricings[tripPricings.length - 1];

        const isUnbookable = isBefore(date, startOfDay(new Date()));
        const loading = isLoading(loadedPeriods, date);

        const allotment = allotments.find(allot => isSameDay(new Date(allot.date), date));
        const forbidden =
            maxAllotment === 1 && allotment?.amount === 0 && isAfter(date, new Date()) && !getDayEvents(date).length;
        const isMultiple = maxAllotment > 1;

        return (
            <PricingColumnContainer
                style={style}
                isUnusable={isUnbookable || (lastTrip && isAfter(date, new Date(lastTrip.date)))}
            >
                <PricingColumnAllotment
                    forbidden={forbidden}
                    variables={initialVars}
                    document={TripsDocument}
                    allotment={allotment}
                    maxAllotment={maxAllotment}
                    loading={loading}
                    unbookable={isUnbookable}
                    disabled={lastTrip && isAfter(date, new Date(lastTrip.date))}
                    date={date}
                    style={{ cursor: isMultiple ? 'pointer' : 'e-resize' }}
                    data-date={date.toISOString()}
                    {...lockoutCreationProps}
                />

                {!loading &&
                    durations.map(duration => {
                        const pricing = columnPricings[duration];
                        return (
                            <Cell key={duration}>
                                <Price>{pricing ? euroFormat(pricing.price) : null}</Price>
                            </Cell>
                        );
                    })}
                {loading && new Array(PRICE_COLUMN_ROW_COUNT).fill(SkeletonCell).map((E, i) => <E key={i} i={i} />)}
            </PricingColumnContainer>
        );
    },
    (prevProps, nextProps) => {
        if (!isEqual(prevProps.data.tripPricings, nextProps.data.tripPricings)) {
            return false;
        }
        return (
            prevProps.index === nextProps.index &&
            isLoading(prevProps.data.loadedPeriods, getDate(prevProps.index)) ===
                isLoading(nextProps.data.loadedPeriods, getDate(nextProps.index))
        );
    }
);

const Price = styled.span`
    font-weight: 500;
`;
