import gql from 'graphql-tag';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { MAX_UPLOAD_IMAGES } from '../../../../constants/imageRequirements';
import { NotFoundError } from '../../../../entities/NotFoundError';
import { UnitParams } from '../../../../entities/UnitParams';
import {
    ImageFragment,
    useAccommodationImagesQuery,
    useEditAccommodationImagesMutation,
} from '../../../../generated/graphql';
import { getAccommodationTypeTranslation } from '../../../../utils/getAccommodationTypeTranslation';
import upsertImage from '../../../../utils/upsertImage';
import Loading from '../../../organisms/Loading';
import ImagesFormScreen, { OtherImage, imageFragment } from './ImagesFormScreen';

export const accommodationImagesFragment = gql`
    fragment AccommodationImages on Accommodation {
        id
        name
        type
        mainImage {
            id
            size
            url
            dimensions
            previewUrl
        }
        listImage {
            id
            size
            url
            dimensions
            previewUrl
        }
        images {
            ...Image
        }
        rentalUnits {
            id
        }
    }
    ${imageFragment}
`;

export const imagesQuery = gql`
    query AccommodationImages($unitSlug: String!) {
        rentalUnit(slug: $unitSlug) {
            id
            accommodation {
                ...AccommodationImages
            }
        }
    }
    ${accommodationImagesFragment}
`;

export const editAccommodationImagesMutation = gql`
    mutation EditAccommodationImages($input: EditAccommodationImagesInput!) {
        editAccommodationImages(input: $input) {
            accommodation {
                ...AccommodationImages
            }
        }
    }
    ${accommodationImagesFragment}
`;

const AccommodationImages: FC<React.PropsWithChildren<UnitParams>> = ({ unitSlug }) => {
    const { data, loading } = useAccommodationImagesQuery({ variables: { unitSlug } });
    const [mutate] = useEditAccommodationImagesMutation();

    if (loading) {
        return <Loading />;
    }

    if (!data || !data.rentalUnit || !data.rentalUnit.accommodation) {
        throw new NotFoundError();
    }

    const isCompact = data.rentalUnit.accommodation.rentalUnits.length === 1;

    const {
        accommodation: { images: accommodationImages, id, mainImage, type, listImage },
    } = data.rentalUnit;
    const images = accommodationImages;

    const initialValues = {
        mainImage: mainImage
            ? {
                  dimensions: mainImage.dimensions,
                  previewUrl: mainImage.previewUrl,
                  file: mainImage.url,
                  id: mainImage.id,
              }
            : null,
        listImage: listImage
            ? {
                  previewUrl: mainImage?.previewUrl,
                  dimensions: listImage.dimensions,
                  file: listImage.url,
                  id: listImage.id,
              }
            : null,
        images: [
            ...images
                .filter((image): image is ImageFragment => !!image)
                .sort((a, b) => a.position - b.position)
                .map(({ previewUrl, url, category, id: imageId, dimensions }) => ({
                    file: url,
                    description: category,
                    id: imageId,
                    dimensions,
                    previewUrl,
                })),
        ] as Array<OtherImage | null>,
        type: 'accommodation' as const,
    };
    if (initialValues.images.length < MAX_UPLOAD_IMAGES) {
        // `null` will result in an empty upload field
        initialValues.images.push(null);
    }

    const accommodationTypeTranslation = getAccommodationTypeTranslation(type)?.toLowerCase();
    const formContent = {
        header: {
            title: `Foto’s ${accommodationTypeTranslation}`,
            description: `Hier stel je de foto’s voor je ${accommodationTypeTranslation} in. Deze zijn ook zichtbaar bij alle accommodaties die hier onder vallen. Travelbase zorgt voor de juiste maten (en eventueel uitsnede). We raden je aan om foto’s te bewerken voor schermweergave, maar waar mogelijk in de originele resolutie te houden (niet vergroten of verkleinen). Je kunt bestanden uploaden tot maximaal 25 MB per bestand.`,
        },
        mainImage: {
            title: `Hoofdfoto`,
            description: `De hoofdfoto is zichtbaar op de ${accommodationTypeTranslation} pagina. De foto is bij voorkeur in een breedbeeld 16:9 (liggende) verhouding. Foto’s in standaard 4:3 verhouding worden door Travelbase uitgesneden naar het midden. We raden als resolutie 1920x1080 of hoger aan. Travelbase accepteert minimaal 1280x720.`,
        },
        images: {
            title: `Detailfoto’s`,
            description: `Voeg tot maximaal ${MAX_UPLOAD_IMAGES} foto’s toe van de verschillende ruimtes van ${accommodationTypeTranslation}. Je kunt de foto’s in de juiste volgorde slepen en voorzien van het juiste onderschrift. Detailfoto’s mogen liggend of staand zijn. Standaard 4:3 (liggende) foto’s komen het beste tot zijn recht. De verhouding tussen breedte en hoogte van de foto’s mag niet groter zijn dan 1:2. We raden als resolutie 1600x1200/1200x1600 of hoger aan. Travelbase accepteert minimaal 800x600/600x800.`,
        },
        listImage: {
            title: `Lijstfoto`,
            description: (
                <FormattedMessage
                    defaultMessage="De lijstfoto van je {accommodationTypeTranslation} toont Travelbase in zoekresultaten en lijsten. De foto is bij voorkeur in een standaard 4:3 (liggende) verhouding. We raden als resolutie 1600x1200 of hoger aan. Travelbase accepteert minimaal 800x600. Let op: de lijstfoto wordt niet getoond op de detailpagina van je {accommodationTypeTranslation}, voeg deze ook toe onderaan bij de detailfoto’s om hem te tonen."
                    values={{ accommodationTypeTranslation }}
                />
            ),
        },
    };

    return (
        <ImagesFormScreen
            isCompact={isCompact}
            content={formContent}
            handleSubmit={async values => {
                await mutate({
                    variables: {
                        input: {
                            images: values.images
                                .filter((image): image is NonNullable<typeof image> => !!image)
                                .map(({ description, id: photoId, previewUrl, dimensions, ...rest }) => ({
                                    category: description,
                                    imageId: photoId,
                                    ...rest,
                                })),
                            mainImageUploadId: upsertImage(values.mainImage),
                            listImageUploadId: upsertImage(values.listImage),
                            accommodationId: id,
                        },
                    },
                });
            }}
            initialValues={initialValues}
            showCaptionSelect={false}
            level="accommodation"
        />
    );
};

export default AccommodationImages;
