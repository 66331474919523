import { OperatingHours, TimeOpenClosed } from '../generated/graphql';
import { Weekday, weekdays } from './createWeekdayRecord';

/** make the gql response for operating hours formik ready by removing __typename and using defaults  */

const convertOperatingHoursToInput = (
    operatingHours?: OperatingHours | null,
    nullable?: boolean
): Record<Weekday, TimeOpenClosed[]> => {
    const defaultValue = nullable ? null : [];
    return Object.assign(
        {},
        ...weekdays.map(day => ({
            [day]: operatingHours?.[day]?.map(({ timeOpen, timeClosed }) => ({ timeOpen, timeClosed })) ?? defaultValue,
        }))
    );
};

export default convertOperatingHoursToInput;
