import TextButton from '@oberoninternal/travelbase-ds/components/action/TextButton';
import Arrow from '@oberoninternal/travelbase-ds/components/figure/Arrow';
import Cross from '@oberoninternal/travelbase-ds/components/figure/Cross';
import { Edit } from '@oberoninternal/travelbase-ds/components/figure/Edit';
import Min from '@oberoninternal/travelbase-ds/components/figure/Min';
import ErrorMessage from '@oberoninternal/travelbase-ds/components/form/ErrorMessage';
import { Option, OptionContainer } from '@oberoninternal/travelbase-ds/components/form/Options';
import Body, { BodyInner } from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { Label } from '@oberoninternal/travelbase-ds/components/primitive/Label';
import dateTextFormat from '@oberoninternal/travelbase-ds/constants/dateTextFormat';
import { Box, Flex, FlexProps } from '@rebass/grid';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import { FieldArrayRenderProps, FormikProps, getIn } from 'formik';
import isEqual from 'lodash/isEqual';
import React, { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import dateFormat from '../../constants/dateFormat';
import { getDateOpts } from '../../constants/dateOpts';
import { validateNumberInputSync } from '../../constants/numberInputValidation';
import { UnitParams } from '../../entities/UnitParams';
import { UnreachableCaseError } from '../../entities/UnreachableCaseError';
import { PricesRowVisibilityFragment, usePartnerDataQuery, WeekPricingDay } from '../../generated/graphql';
import createWeekdaysRecord from '../../utils/createWeekdayRecord';
import parseDate from '@oberoninternal/travelbase-ds/utils/parseDate';

import NumberInput, { NumberInputProps } from '../atoms/NumberInput';
import { Seperator } from '../atoms/Seperator';
import {
    DatePricingPeriodType,
    DatePricingType,
    getNewFlexPeriod,
    PriceWizardValues,
} from '../pages/unit/prices/Wizard';
import RangePickerInputField from './RangePickerInputField';
import WeekdaysInput from './WeekdaysInput';
import WizardContent from './WizardContent';
import WizardPriceCopier from './WizardPriceCopier';
import { CheckboxField } from '@oberoninternal/travelbase-ds/components/form/Checkbox';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
    index: number;
    helpers: Helpers;
    rowVisibility: PricesRowVisibilityFragment;
}

interface Helpers extends FieldArrayRenderProps {
    form: FormikProps<PriceWizardValues>; // overwrite because its not generic
}

const getInputValues = (type: DatePricingType, rowVisibility: PricesRowVisibilityFragment): DatePricingPeriodType => {
    switch (type) {
        case 'flex': {
            return getNewFlexPeriod(rowVisibility);
        }
        case 'week': {
            const initialWeekPricingDay: WeekPricingDay = { arrivalAllowed: false };
            return {
                weekPrice: '',
                type,
                ...createWeekdaysRecord(initialWeekPricingDay),
                friday: {
                    ...initialWeekPricingDay,
                    arrivalAllowed: true,
                },
            };
        }
        case 'hybrid': {
            return {
                type,
                weekPrice: null,
                midweekPrice: '',
                weekendPrice: '',
                offerWeekFriday: true,
                offerWeekMonday: true,
                offerWeekSaturday: false,
            };
        }
        case 'sourceRentalUnit': {
            return {
                type,
                sourceRentalUnit: '',
            };
        }
        default:
            throw new UnreachableCaseError(type);
    }
};

const hybridSwitchDays = ['offerWeekMonday', 'offerWeekFriday', 'offerWeekSaturday'] as const;
const hybridSwitchDaysTexts = {
    offerWeekMonday: 'Maandag',
    offerWeekFriday: 'Vrijdag',
    offerWeekSaturday: 'Zaterdag',
};

const DatePricingFields = ({
    type,
    name,
    rowVisibility: {
        showBaseStayPriceRow,
        showExtraPersonPriceRow,
        showMinimumStayPriceRow,
        showMinimumStayDurationRow,
        showWeekPriceRow,
    },
}: {
    type: DatePricingType;
    name: string;
    rowVisibility: PricesRowVisibilityFragment;
}) => {
    const { formatMessage } = useIntl();
    switch (type) {
        case 'flex':
            return (
                <>
                    <WizardContent
                        label={formatMessage({ defaultMessage: 'Wisseldag' })}
                        hint={formatMessage({
                            defaultMessage: 'Op welke dagen kunnen gasten aankomen (en vertrekken)?',
                        })}
                    >
                        <WeekdaysInput name={name} variant="arrivalAllowed" />
                    </WizardContent>
                    <WizardContent
                        label={formatMessage({ defaultMessage: 'Prijzen (€)' })}
                        hint={formatMessage({
                            defaultMessage: 'Bepaal je prijs per nacht op de betreffende aankomstdag',
                        })}
                    >
                        <Box>
                            <WeekdaysInput name={name} variant="nightPrice" />
                            {showWeekPriceRow && (
                                <NumberField
                                    label={formatMessage({ defaultMessage: 'Week' })}
                                    name={`${name}.pricing.weekPrice`}
                                    mt={4}
                                    nullable
                                />
                            )}
                        </Box>
                    </WizardContent>
                    {showMinimumStayDurationRow && (
                        <WizardContent
                            label={formatMessage({ defaultMessage: 'Minimum verblijfsduur' })}
                            hint={formatMessage({
                                defaultMessage:
                                    'Bepaal het minimum aantal verblijfsnachten vanaf de betreffende aankomstdag',
                            })}
                        >
                            <WeekdaysInput name={name} variant="minimumStayDuration" />
                        </WizardContent>
                    )}
                    {showBaseStayPriceRow && (
                        // <StyledContent label="Basisbedrag (€)">
                        <StyledContent label={formatMessage({ defaultMessage: 'Basisbedrag (€)' })}>
                            <NumberField name={`${name}.pricing.baseStayPrice`} />
                        </StyledContent>
                    )}
                    {showMinimumStayPriceRow && (
                        // <StyledContent label="Minimale prijs (€)">
                        <StyledContent label={formatMessage({ defaultMessage: 'Minimale prijs (€)' })}>
                            <NumberField name={`${name}.pricing.minimumStayPrice`} />
                        </StyledContent>
                    )}
                    {showExtraPersonPriceRow && (
                        // <StyledContent label="Prijs extra per persoon (€)">
                        <StyledContent label={formatMessage({ defaultMessage: 'Prijs extra per persoon (€)' })}>
                            <NumberField name={`${name}.pricing.extraPersonPrice`} />
                        </StyledContent>
                    )}
                </>
            );
        case 'hybrid':
            return (
                <>
                    <WizardContent
                        label={formatMessage({ defaultMessage: 'Prijzen (€)' })}
                        hint={formatMessage({
                            defaultMessage:
                                'Bepaal je prijs per Midweek (ma-vrij), Weekend (vrij-ma) en week (vrij-vrij).',
                        })}
                    >
                        <Box width={1}>
                            <Flex>
                                <NumberField
                                    label={formatMessage({ defaultMessage: 'Midweek' })}
                                    name={`${name}.pricing.midweekPrice`}
                                />
                                <NumberField
                                    label={formatMessage({ defaultMessage: 'Weekend' })}
                                    ml={4}
                                    name={`${name}.pricing.weekendPrice`}
                                />
                                <NumberField
                                    label={formatMessage({ defaultMessage: 'Week' })}
                                    ml={4}
                                    name={`${name}.pricing.weekPrice`}
                                    nullable
                                />
                            </Flex>
                        </Box>
                    </WizardContent>
                    {/* <StyledContent
                        label="Weekverblijf"
                        hint="Op welke dagen sta je aankomst voor een week toe, waar de weekprijs voor berekend wordt?"
                    > */}
                    <StyledContent
                        label={formatMessage({ defaultMessage: 'Weekverblijf' })}
                        hint={formatMessage({
                            defaultMessage:
                                'Op welke dagen sta je aankomst voor een week toe, waar de weekprijs voor berekend wordt?',
                        })}
                    >
                        <Flex width={1}>
                            {hybridSwitchDays.map(day => (
                                <Box flex={[1, null, 'unset']} width={[null, null, '22.6rem']} key={day}>
                                    <Box mb={3}>
                                        <Label variant="small" htmlFor={day}>
                                            {hybridSwitchDaysTexts[day]}
                                        </Label>
                                    </Box>
                                    <CheckboxField name={`${name}.pricing.${day}`} id={day} />
                                </Box>
                            ))}
                        </Flex>
                    </StyledContent>
                </>
            );
        case 'week':
            return (
                <>
                    <WizardContent
                        label={formatMessage({ defaultMessage: 'Wisseldag' })}
                        hint={formatMessage({
                            defaultMessage: 'Op welke dagen kunnen gasten aankomen (en vertrekken)?',
                        })}
                    >
                        <WeekdaysInput name={name} variant="arrivalAllowed" />
                    </WizardContent>

                    <StyledContent
                        label={formatMessage({ defaultMessage: 'Weekprijs' })}
                        hint={formatMessage({
                            defaultMessage: 'Bepaal de huurprijs per week in de door jou gekozen periode.',
                        })}
                    >
                        <NumberField name={`${name}.pricing.weekPrice`} />
                    </StyledContent>
                </>
            );
        case 'sourceRentalUnit':
            return (
                <WizardContent
                    label={formatMessage({ defaultMessage: 'Accommodatie selecteren' })}
                    hint={formatMessage({
                        defaultMessage: 'Uit welke accommodatie wil je de prijzen overnemen?',
                    })}
                >
                    <WizardPriceCopier name={name} />
                </WizardContent>
            );
        default:
            throw new UnreachableCaseError(type);
    }
};

const PricesWizardPeriod: FC<React.PropsWithChildren<Props>> = ({
    helpers: {
        remove,
        form: {
            values: { periods },
            setFieldValue,
            setErrors,
            setTouched,
            touched,
            errors,
        },
    },
    index,
    rowVisibility,
}) => {
    const [open, setOpen] = useState(true);
    const period = periods[index];
    const previousPeriod = periods[index - 1];
    const startDate = parseDate(period.startDate);
    const endDate = parseDate(period.endDate);
    const name = `periods.${index}`;

    const periodError = getIn(errors, name) && getIn(touched, name);

    // store the initial values in a ref
    const ref = useRef(period.pricing);

    const prevRef = useRef(previousPeriod);
    const { formatMessage, locale } = useIntl();
    const dateOpts = getDateOpts(locale as 'nl' | 'de' | 'en');
    useEffect(() => {
        // the startDate should always be the day after the previous period's endDate
        if (!isEqual(prevRef.current, previousPeriod)) {
            const newStartDate = addDays(parseDate(previousPeriod.endDate), 1);
            setFieldValue(`${name}.startDate`, format(newStartDate, dateFormat));

            let newEndDate = parseDate(endDate);

            if (isBefore(newEndDate, newStartDate)) {
                newEndDate = addMonths(newStartDate, 1);
            }
            setFieldValue(`${name}.endDate`, format(newEndDate, dateFormat));

            prevRef.current = previousPeriod;
        }
    }, [setFieldValue, previousPeriod, name, endDate]);

    const getOptionProps = (type: DatePricingType) => ({
        name: `${name}.pricing.type`,
        id: `${name}-${type}`,
        checked: period.pricing.type === type,
        onChange: ({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => {
            const dirty = !isEqual(ref.current, period.pricing);

            if (
                !dirty ||
                (dirty &&
                    window.confirm(
                        formatMessage({ defaultMessage: 'Weet je zeker dat je wilt veranderen van flexibiliteit?' })
                    ))
            ) {
                const newValues = getInputValues(value as DatePricingType, rowVisibility); // we know it can only be of type DatePricingType
                setErrors({});
                setTouched({});
                setFieldValue(`${name}.pricing`, newValues);
                ref.current = newValues;
            }
        },
        value: type,
    });
    const { partnerId, unitSlug } = useParams<UnitParams>();
    const { data: partnerData } = usePartnerDataQuery({
        variables: {
            partnerId,
            slug: unitSlug,
        },
    });

    const unitCount = useMemo(
        () =>
            partnerData?.partner?.accommodations.reduce(
                (acc, next) =>
                    acc + next.rentalUnits.filter(unit => unit.slug !== unitSlug && !!unit.datePricingStartDate).length,
                0
            ) ?? 0,
        [partnerData, unitSlug]
    );

    const onDeleteHandler = () => {
        if (window.confirm(formatMessage({ defaultMessage: 'Weet je zeker dat je deze periode wilt verwijderen?' }))) {
            const nextPeriod = periods[index + 1];

            // if there's a next period, then we set the startDate of it to this one
            if (nextPeriod) {
                const nextPeriodName = `periods[${index + 1}]`;
                setFieldValue(`${nextPeriodName}.startDate`, startDate);
            }
            remove(index);
        }
    };

    return (
        <Container mb={5} open={open}>
            <Wrapper>
                <Header curved={!open}>
                    <PeriodRange>
                        <Body variant="large">{format(startDate, `EEEEEE ${dateTextFormat}`, dateOpts)}</Body>
                        <Arrow />
                        <Body variant="large">{format(endDate, `EEEEEE ${dateTextFormat}`, dateOpts)}</Body>
                    </PeriodRange>
                    <StyledTextButton key={name} onClick={() => setOpen(!open)}>
                        <span>
                            {open ? (
                                <FormattedMessage defaultMessage="Sluiten" />
                            ) : (
                                <FormattedMessage defaultMessage="Openen" />
                            )}
                        </span>
                        {open ? <Min /> : <Edit />}
                    </StyledTextButton>
                </Header>
                <ContentWrapper visible={open}>
                    <WizardContent
                        label={formatMessage({ defaultMessage: 'Prijsperiode' })}
                        hint={formatMessage({
                            defaultMessage:
                                'De prijzen die je in deze periode instelt gelden voor de aankomstdatums in de door jou gekozen periode.',
                        })}
                        style={{ zIndex: 2, position: 'relative' }}
                    >
                        <Box>
                            <StyledRangePicker
                                size="medium"
                                disableInput={{ from: index === 1 }}
                                fromFieldName={`${name}.startDate`}
                                toFieldName={`${name}.endDate`}
                                withDayName
                            />
                        </Box>
                    </WizardContent>
                    <WizardContent label={formatMessage({ defaultMessage: 'Flexibiliteit' })}>
                        <OptionsWrapper width={1}>
                            <Option
                                text={formatMessage({ defaultMessage: 'Flexplanning' })}
                                description={formatMessage({
                                    defaultMessage:
                                        'Hiermee kun je je accommodatie per nacht verhuren. Dit zorgt voor maximale flexibiliteit voor je gasten, een hoge bezetting en huuropbrengst voor jou.',
                                })}
                                hint={formatMessage({ defaultMessage: 'Aanbevolen' })}
                                {...getOptionProps('flex')}
                            />
                            <Option
                                text={formatMessage({ defaultMessage: 'Midweek, weekend en weekplanning' })}
                                description={formatMessage({
                                    defaultMessage:
                                        'Gasten kunnen je accommodatie per midweek, weekend of week boeken. Overweeg om in een deel van het jaar je accommodatie via flexplanning per nacht te verhuren voor een hogere bezetting.',
                                })}
                                {...getOptionProps('hybrid')}
                            />
                            <Option
                                text={formatMessage({ defaultMessage: 'Weekplanning' })}
                                description={formatMessage({
                                    defaultMessage:
                                        'Gasten kunnen je accommodatie alléén per week boeken. Relatief weinig gasten zijn hier naar op zoek. Overweeg je accommodatie voor een korter verblijf aan te bieden via midweek, weekend of weekplanning of kies (bijv. in het laagseizoen) voor flexplanning.',
                                })}
                                {...getOptionProps('week')}
                            />
                            {unitCount > 0 && (
                                <Option
                                    text={formatMessage({ defaultMessage: 'Prijzen kopiëren' })}
                                    description={formatMessage({
                                        defaultMessage:
                                            'Hiermee kun je prijzen overnemen van andere accommodaties. Dit zorgt ervoor dat de prijzen en bijhorende kortingen en toeslagen toegevoegd worden aan deze accommodatie.',
                                    })}
                                    {...getOptionProps('sourceRentalUnit')}
                                />
                            )}
                        </OptionsWrapper>
                    </WizardContent>
                    <DatePricingFields type={period.pricing.type} name={name} rowVisibility={rowVisibility} />
                    <Seperator variant="small" />
                    <TextButton variant="danger" onClick={onDeleteHandler}>
                        <Cross />
                        &nbsp;
                        <span>
                            <FormattedMessage defaultMessage="Periode verwijderen" />
                        </span>
                    </TextButton>
                </ContentWrapper>
            </Wrapper>
            {periodError && !open && (
                <ErrorMessage>
                    <FormattedMessage defaultMessage="Je hebt deze periode nog niet helemaal correct ingevuld." />
                </ErrorMessage>
            )}
        </Container>
    );
};

export default PricesWizardPeriod;

const NumberField: FC<
    React.PropsWithChildren<{ label?: string; nullable?: boolean } & Pick<NumberInputProps, 'name'> & FlexProps>
> = ({ label, name, nullable, ...props }) => {
    const intl = useIntl();
    const validate = validateNumberInputSync(intl, { isCurrency: true, nullable });
    return (
        <Box width={[1, '21rem']} {...props}>
            {label && (
                <Label htmlFor={name} variant="small">
                    {label}
                </Label>
            )}
            <NumberInput id={name} name={name} isCurrency validate={validate} min={0} max={10000} nullable={nullable} />
        </Box>
    );
};

const OptionsWrapper = styled(Box).attrs({ width: 1 })`
    ${OptionContainer} {
        min-height: 10.6rem;
    }
`;

const StyledContent = styled(WizardContent)`
    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        > div:first-of-type {
            padding-top: 1rem;
        }
    }
`;

const borderStyles = css`
    --border-color: ${({ theme }) => theme.colors.neutral[20]};
    box-shadow: 0 0 0 1px var(--border-color), inset 0 0 0 1px var(--border-color);
`;

const StyledTextButton = styled(TextButton)`
    > svg {
        display: none;
    }
    @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        :hover {
            ::after {
                opacity: 0;
            }
        }
        > svg {
            display: block;
        }
        > span {
            display: none;
        }
    }
`;

export const Wrapper = styled.div`
    border-radius: 0.7rem;

    > * {
        padding: 4rem 8rem;
    }

    @media (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        > * {
            padding: 1.6rem 2rem;
        }
    }

    ${borderStyles}
`;

const ContentWrapper = styled.div<{ visible: boolean }>`
    display: ${({ visible }) => (visible ? 'block' : 'none')};

    > div:last-of-type {
        padding-bottom: 0;
    }
    ${Label} + div {
        margin-top: 1.2rem;

        @media (max-width: ${({ theme }) => theme.mediaQueries.s}) {
            margin-top: 0.8rem;
        }
    }
`;

const Header = styled.header<{ curved: boolean }>`
    border-radius: 0.7rem 0.7rem ${({ curved }) => (curved ? '0.7rem 0.7rem' : '0 0')};
    height: 7.8rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${borderStyles}
`;

const PeriodRange = styled.div`
    height: 100%;
    display: flex;
    align-items: center;

    > svg {
        color: ${({ theme }) => theme.colors.neutral[50]};
        min-width: 2rem;
    }

    @media (max-width: ${({ theme }) => theme.mediaQueries.xs}) {
        > * + * {
            margin-left: 1.8rem;
        }

        > ${BodyInner} {
            font-size: 1.8rem;
        }
    }

    > * + * {
        margin-left: 2.4rem;
    }
`;

const StyledRangePicker = styled(RangePickerInputField)`
    width: 40rem;

    @media (max-width: ${({ theme }) => theme.mediaQueries.xs}) {
        width: 100%;
    }
`;

const Container = styled(Box)<{ open: boolean }>`
    animation: fadein ease-in-out 400ms;
    transition: ease-in-out 400ms;
    transform-origin: top;
    @keyframes fadein {
        from {
            transform: scaleY(0);
            opacity: 0;
        }
        to {
            transform: scaleY(1);

            opacity: 1;
        }
    }
`;
