import DatePickerInput, {
    RangeInputModifier,
    RangePickerInputProps,
} from '@oberoninternal/travelbase-ds/components/form/DatePickerInput';
import ErrorMessage from '@oberoninternal/travelbase-ds/components/form/ErrorMessage';
import { FieldMetaProps, setIn, useField, useFormikContext, FieldHookConfig } from 'formik';
import React, { FC, useCallback } from 'react';
import dateFormat from '../../constants/dateFormat';
import parseDate from '@oberoninternal/travelbase-ds/utils/parseDate';
import format from 'date-fns/format';

interface RangePickerInputFieldProps extends Omit<RangePickerInputProps, 'value' | 'setValue' | 'variant'> {
    fromFieldName?: string | FieldHookConfig<string | null>;
    toFieldName?: string | FieldHookConfig<string | null>;
    onChange?: (event: RangeInputModifier) => void;
    enabledPast?: boolean;
}

const getError = (meta: FieldMetaProps<string | null>) => (meta.touched && meta.error ? meta.error : undefined);

const RangePickerInputField: FC<React.PropsWithChildren<RangePickerInputFieldProps>> = ({
    fromFieldName = 'startDate',
    toFieldName = 'endDate',
    onChange,
    ...props
}) => {
    const { setValues, values, setTouched, touched } = useFormikContext<object>();
    const [fromField, fromMeta] = useField<string | null>(fromFieldName);
    const [toField, toMeta] = useField<string | null>(toFieldName);

    const error = getError(fromMeta) ?? getError(toMeta);
    const changeField = useCallback(
        (...args: Array<[string, Date | string]>) => {
            let newValues = { ...values };
            let newTouched = { ...touched };
            for (const [name, value] of args) {
                newValues = setIn(newValues, name, format(new Date(value), dateFormat));
                newTouched = setIn(newTouched, name, true);
            }
            setValues(newValues);
            setTouched(newTouched, false);
        },
        [setTouched, setValues, touched, values]
    );

    return (
        <>
            <DatePickerInput
                {...props}
                error={error}
                variant="range"
                value={{
                    from: !fromField.value ? null : parseDate(fromField.value),
                    to: !toField.value ? null : parseDate(toField.value),
                }}
                setValue={event => {
                    if (onChange) {
                        onChange(event);
                    }
                    if (event.from && !event.to) {
                        changeField([fromField.name, event.from]);
                    }
                    if (!event.from && event.to) {
                        changeField([toField.name, event.to]);
                    }
                    if (event.from && event.to) {
                        changeField([fromField.name, event.from], [toField.name, event.to]);
                    }
                }}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
        </>
    );
};

export default RangePickerInputField;
