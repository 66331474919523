import Sidebar from '@oberoninternal/travelbase-ds/components/layout/Sidebar';
import { addDays, isWithinInterval } from 'date-fns';
import format from 'date-fns/format';
import gql from 'graphql-tag';
import uniqBy from 'lodash/uniqBy';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NetworkStatus } from '../../apollo';
import dateFormat from '../../constants/dateFormat';
import epoch from '../../constants/epoch';
import { useSidebar } from '../../context/sidebar';
import { ActivityParams } from '../../entities/ActivityParams';
import { useTimeslotsLazyQuery } from '../../generated/graphql';
import Virtualizer from '../atoms/Virtualizer';
import SidebarContent from '../molecules/SidebarContent';
import { TIMESLOT_CELL_HEIGHT } from '../molecules/Timeslot';
import TimeslotsColumn, { TIMESLOT_HEADER_HEIGHT, TIMESLOT_MIN_CELLS } from '../molecules/TimeslotsColumn';
import TimeslotsInfobar from '../molecules/TimeslotsInfobar';
import ToastErrorBoundary from './ToastErrorBoundary';

export const query = gql`
    query Timeslots($activitySlug: String!, $startDate: Date!, $endDate: Date!) {
        activity(slug: $activitySlug) {
            ...ActivityTimeslots
        }
    }

    mutation CreateActivityTimeslot($input: CreateActivityTimeslotInput!) {
        createActivityTimeslot(input: $input) {
            timeslot {
                ...ActivityTimeslot
            }
        }
    }

    mutation DeleteActivityTimeslot($input: DeleteActivityTimeslotInput!) {
        deleteActivityTimeslot(input: $input) {
            id
        }
    }

    mutation EditActivityTimeslot($input: EditActivityTimeslotInput!) {
        editActivityTimeslot(input: $input) {
            timeslot {
                ...ActivityTimeslot
            }
        }
    }

    fragment ActivityTimeslots on Activity {
        id
        timeslots(startDate: $startDate, endDate: $endDate) {
            ...ActivityTimeslot
        }
        activityRateGroups {
            ...TimeslotsRateGroups
        }
    }

    fragment TimeslotsRateGroups on ActivityRateGroup {
        id
        name
        canBuyTickets
    }

    fragment ActivityTimeslot on Timeslot {
        id
        startDateTime
        endDateTime
        label(locale: "nl")
        deLabel: label(locale: "de")
        enLabel: label(locale: "en")
        allotment
        rateGroup {
            id
            canBuyTickets
        }
    }
`;

const Timeslots: FC<React.PropsWithChildren<unknown>> = () => {
    const { activitySlug } = useParams<ActivityParams>();
    const requestedPeriod = useRef<Interval | null>(null);
    const [state, dispatch] = useSidebar();
    const [loadedPeriods, setLoadedPeriods] = useState<Interval[]>([]);

    const [fetch, { data, called, loading, fetchMore, networkStatus, variables }] = useTimeslotsLazyQuery({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        if (!data || !requestedPeriod.current || networkStatus !== NetworkStatus.ready) {
            return;
        }

        setLoadedPeriods(current => current.concat(requestedPeriod.current as Interval));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [networkStatus]);

    const onLoadMore = useCallback(
        async (startIndex: number, endIndex: number) => {
            const startDate = addDays(epoch, startIndex);
            const endDate = addDays(epoch, endIndex);

            const newVariables = {
                startDate: format(startDate, dateFormat),
                endDate: format(endDate, dateFormat),
                activitySlug: activitySlug as string,
            };

            if (loading) {
                return;
            }

            requestedPeriod.current = { start: startDate, end: endDate };

            if (!called) {
                fetch({ variables: newVariables });
            } else {
                await fetchMore?.({
                    variables: newVariables,
                    updateQuery: (prev, { fetchMoreResult }) => {
                        if (!prev.activity?.id) {
                            return prev;
                        }
                        if (!fetchMoreResult?.activity?.timeslots) {
                            return prev;
                        }
                        return {
                            activity: {
                                ...prev.activity,
                                timeslots: uniqBy(
                                    [
                                        ...(prev.activity?.timeslots
                                            ? prev.activity.timeslots.concat(fetchMoreResult.activity.timeslots)
                                            : []),
                                    ],
                                    ({ id }) => id
                                ),
                            },
                        };
                    },
                });
            }
        },
        [activitySlug, called, fetch, fetchMore, loading]
    );

    const isItemLoaded = useCallback(
        (index: number) => {
            const itemDate = addDays(epoch, index);
            return loadedPeriods.some(interval => isWithinInterval(itemDate, interval));
        },
        [loadedPeriods]
    );

    const onClickAdd = useCallback(
        (date: Date) => {
            if (!data?.activity || !variables) {
                return;
            }
            dispatch({
                type: 'show',
                data: {
                    type: 'TIMESLOTS',
                    rateGroups: data.activity.activityRateGroups,
                    date,
                    activityId: data.activity.id,
                    variables,
                },
            });
        },
        [data, dispatch, variables]
    );

    const onClickTimeslot = useCallback(
        (timeslotId: string) => {
            const timeslot = data?.activity?.timeslots.find(slot => slot.id === timeslotId);
            if (!data?.activity || !timeslot || !variables) {
                return;
            }

            dispatch({
                type: 'show',
                data: {
                    type: 'TIMESLOTS',
                    rateGroups: data.activity.activityRateGroups,
                    slot: timeslot,
                    activityId: data.activity.id,
                    variables,
                },
            });
        },
        [data, dispatch, variables]
    );

    // this number is used to determine the height of the columns in react-window
    const largestRowsCount = useMemo(() => {
        const map = new Map<string, number>();
        for (const timeslot of data?.activity?.timeslots ?? []) {
            const date = format(new Date(timeslot.startDateTime), dateFormat);
            // a column always starts with 1 row which contains the add button
            const current = map.get(date) ?? 1;
            map.set(date, current + 1);
        }

        return map.size > 0 ? Math.max(...map.values()) : TIMESLOT_MIN_CELLS;
    }, [data]);

    const itemHeight = TIMESLOT_HEADER_HEIGHT + TIMESLOT_CELL_HEIGHT * largestRowsCount;

    const columnData = useMemo(
        () => ({
            isLoaded: isItemLoaded,
            data,
            onClickAdd,
            onClickTimeslot,
        }),
        [data, isItemLoaded, onClickAdd, onClickTimeslot]
    );

    return (
        <Virtualizer
            itemHeight={itemHeight}
            isItemLoaded={isItemLoaded}
            loadMoreItems={onLoadMore}
            ColumnComponent={TimeslotsColumn}
            columnData={columnData}
            infobarChildren={<TimeslotsInfobar />}
        >
            <ToastErrorBoundary>
                <Sidebar onClose={() => dispatch({ type: 'close' })} open={state.open} hidden={state.hidden}>
                    <SidebarContent data={state.data} />
                </Sidebar>
            </ToastErrorBoundary>
        </Virtualizer>
    );
};

export default Timeslots;
