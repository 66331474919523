import styled from 'styled-components';
import { StyledButton } from './Button';

const StyledButtongroup = styled.div`
    display: inline-flex;
    flex-direction: row;

    > ${StyledButton}:focus, > ${StyledButton}:hover, > ${StyledButton}:active {
        z-index: 1;
    }

    > ${StyledButton} {
        border-radius: 0rem;
    }
    > ${StyledButton}:first-child {
        border-bottom-left-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
    }
    > ${StyledButton}:last-child {
        border-bottom-right-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
    }
`;

export default StyledButtongroup;
