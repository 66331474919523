import React, { FC } from 'react';
import { NavLinkProps } from 'react-router-dom';
import styled from 'styled-components/macro';
import SubNav, { SubNavVariant } from '@oberoninternal/travelbase-ds/components/layout/SubNav';

interface Props {
    links: NavLinkProps[];
    variant: SubNavVariant;
}

const NavigationDesktopMenu: FC<React.PropsWithChildren<Props>> = ({ links, children, ...props }) => (
    <DesktopMenu links={links} {...props}>
        {children}
    </DesktopMenu>
);

export default NavigationDesktopMenu;

const DesktopMenu = styled(SubNav)`
    display: none;
    padding-left: 1rem;
    @media (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        display: flex;
    }
`;
