import { defineMessages } from 'react-intl';
import { Route, Route as RouteType } from '../entities/Route';
import { AccommodationInfoFragment, CompanyInfoFragment } from '../generated/graphql';

const titles = defineMessages({
    availability: {
        defaultMessage: 'Beschikbaarheid',
    },
    prices: {
        defaultMessage: 'Prijzen',
    },
    trips: {
        defaultMessage: 'Ingelezen prijzen',
    },
    specials: {
        defaultMessage: 'Arrangementen',
    },
    info: {
        defaultMessage: 'Gegevens',
    },
    hours: {
        defaultMessage: 'Openingstijden',
    },
    rates: {
        defaultMessage: 'Tarieven',
    },
    planning: {
        defaultMessage: 'Planning',
    },
    history: {
        defaultMessage: 'Historie',
    },
    all: {
        defaultMessage: 'Alle boekingen',
    },
    tickets: {
        defaultMessage: 'Tickets',
    },
    balance: {
        defaultMessage: 'Verrekening',
    },
    newAndChanged: {
        defaultMessage: 'Nieuw en gewijzigd',
    },
    upcoming: {
        defaultMessage: 'Binnenkort',
    },
    register: {
        defaultMessage: 'Registreren',
    },
    visitorRegister: {
        defaultMessage: 'Nachtregister',
    },
    reviews: {
        defaultMessage: 'Beoordelingen',
    },
    bookings: {
        defaultMessage: 'Boekingen',
    },
    accommodations: {
        defaultMessage: 'Accommodaties',
    },
    activities: {
        defaultMessage: 'Activiteiten',
    },
});

export const unitRoutes: Route[] = [
    { title: titles.availability, source: 'availability' },
    { title: titles.prices, source: 'prices' },
    { title: titles.trips, source: 'trips' },
    { title: titles.specials, source: 'specials' },
    { title: titles.info, source: 'info' },
];

export const companyRoutes: Route[] = [
    { title: titles.hours, source: 'hours' },
    { title: titles.rates, source: 'rates' },
    { title: titles.info, source: 'info' },
];

export const activityRoutes: Route[] = [
    { title: titles.planning, source: 'planning' },
    { title: titles.rates, source: 'rates' },
    { title: titles.info, source: 'info' },
];

export const bookingRoutes: RouteType[] = [
    { exact: true, source: '', title: titles.newAndChanged },
    { source: 'upcoming', title: titles.upcoming },
    { source: 'all', title: titles.all },
];

export const registerRoutes: RouteType[] = [
    { exact: true, source: '', title: titles.register },
    { source: 'history', title: titles.history },
];

export const ticketRoutes: RouteType[] = [
    { exact: true, source: '', title: titles.newAndChanged },
    { title: titles.all, source: 'all' },
];

export const routes: Route[] = [
    {
        title: titles.accommodations,
        source: '/unit',
        path: '/unit/:unitSlug',
        subroutes: unitRoutes,
        group: 'accommodation',
    },
    { title: titles.bookings, source: '/bookings', subroutes: bookingRoutes, group: 'accommodation' },
    { title: titles.reviews, source: '/reviews', group: 'accommodation' },
    { title: titles.visitorRegister, source: '/visitor-register', group: 'accommodation' },
    {
        title: titles.activities,
        source: '/activity',
        path: '/activity/:activitySlug',
        subroutes: activityRoutes,
        seperator: true,
        group: 'company',
    },
    { title: titles.tickets, source: '/tickets', group: 'company', subroutes: ticketRoutes },
    { title: titles.balance, source: '/balance', seperator: true },
];

// Get all the navigation items based on active accomodations and active businesses
const hasActiveAccommodations = (acc: Array<AccommodationInfoFragment>) => {
    if (!acc.length) return false;
    const nrOfActive = acc.length;
    return nrOfActive > 0;
};
const hasActiveCompanies = (companies: Array<CompanyInfoFragment>) => {
    if (!companies.length) return false;
    const nrOfActive = companies.filter((company: CompanyInfoFragment) => company.enabled).length;
    return nrOfActive > 0;
};

export const getMainNavigationItems = (
    accommodations: Array<AccommodationInfoFragment>,
    companies: Array<CompanyInfoFragment>
) => {
    const activeAccommodations = hasActiveAccommodations(accommodations);
    const activeCompanies = hasActiveCompanies(companies);

    // If both have inactive, remove the seperator on verrekening
    const routeVerrekening = routes.find(route => route.source === '/balance');
    routeVerrekening!.seperator = !(!activeAccommodations && !activeCompanies);

    return routes.filter(route => {
        if (!activeAccommodations && !activeCompanies) {
            return !route.group;
        }
        if (!activeAccommodations) {
            return route.group === 'company' || !route.group;
        }
        if (!activeCompanies) {
            return route.group === 'accommodation' || !route.group;
        }
        return route;
    });
};

// Build the mobile navigation according to what getMainNavigationItems and what the current selected activity type is.
export const getMainNavigationRoutesForMobile = (
    acc: Array<AccommodationInfoFragment>,
    companies: Array<CompanyInfoFragment>,
    activityParentType: 'activity' | 'company'
) => {
    const allRoutes = getMainNavigationItems(acc, companies);
    if (activityParentType === 'company') {
        // this needs to be improved maybe as this basically determines what sub items to show depending on the selected activity or company
        return allRoutes.map((route): Route => {
            if (route.source === '/activity') {
                return {
                    ...route,
                    path: '/activity/:activitySlug',
                    subroutes: companyRoutes,
                };
            }
            return route;
        });
    }
    return allRoutes;
};

export const getActivityNavigationRoutes = (activityParentType: string | undefined) => {
    if (activityParentType === 'company') {
        return companyRoutes;
    }
    return activityRoutes;
};
